<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="container booking-shipping-instructions-container" [hidden]="isLoading"
  *ngIf="!isViewOrganizationContacts && shipmentInstructionBookingForm && isBookingSubmitted"
  [formGroup]="shipmentInstructionBookingForm">
  <div class="row booking-shipping-instructions-header">
    <span class="booking-shipping-instructions-shippertype">
      {{ getShipperType(shipmentInstruction?.shipperTypeCode) | titlecase }}
    </span>
    <div class="booking-shipping-instructions-heading-title">
      <span class="booking-shipping-instructions-heading">Shipping Instructions</span>
    </div>
  </div>
  <div class="row booking-shipping-instructions-content-container" *ngIf="shipmentInstructionBookingForm">
    <app-booking-instructions-detail [shipmentInstructionBookingForm]="shipmentInstructionBookingForm"
      [shipmentInstruction]="shipmentInstruction"
      (addCommodity)="onAddCommodity($event)"
      (removeCommodity)="onDeleteCommodity($event)" [shipperContact]="shipperContact"
      [consigneeContact]="consigneeContact" [notifyPartyContact]="notifyPartyContact"
      (addAnotherReference)="onAddAnotherReference()" (removeAnotherReference)="onRemoveAnotherReference($event)"
      (generatedRefID)="onGenerateRefID()"
      (viewOrganizationContactEvent)="onViewOrganizationContact($event)"
      (removeBookingContactEvent)="onRemoveBookingContact($event)"
      [isShipperContactDisable]="isShipperContactDisable"
      [isConsigneeContactDisable]="isConsigneeContactDisable"
      [isNotifyPartyContactDisable]="isNotifyPartyContactDisable"
      [isAddCommodityButtonDisable]="isAddCommodityButtonDisable"
      [isAddAnotherRefButtonDisable]="isAddAnotherRefButtonDisable"></app-booking-instructions-detail>
  </div>
  <div class="row booking-shipping-instructions-bottom-wrapper">
    <div class="booking-shipping-instructions-terms-condition-checkbox">
      <mat-checkbox [disabled]="isShippingInstructionsSubmitted" [checked]="isTermsAndConditionsConfirmed" (change)="onConfirmTermsChanged($event.checked)">
        Tick to confirm you have the terms & conditions, goods include no personal effects,
        no refrigerated goods in dry conrainers and all information is accurate.
      </mat-checkbox>
    </div>
    <div class="booking-shipping-instructions-bottom-buttons">
      <button *ngIf="!isShippingInstructionsSubmitted" mat-raised-button class="back-button"
        (click)="onCancelChanges()">Cancel changes</button>
      <button *ngIf="!isShippingInstructionsSubmitted" [disabled]="isSubmitLoading" mat-raised-button class="next-button button-spinner"
        (click)="onSubmit()">
        <span class="button-inner">
          Submit
          <mat-spinner diameter="25" *ngIf="isSubmitLoading"></mat-spinner>
        </span>
      </button>
      <button *ngIf="isShippingInstructionsSubmitted" mat-raised-button mat-raised-button
        class="next-button button-spinner" (click)="onClose()">
        Close
      </button>
    </div>
  </div>
</div>
<div class="select-contact">
  <div class="booking-shipping-instructions-container" *ngIf="isViewOrganizationContacts">
    <app-booking-organization-contacts (BackToBooking)="onBack()"
      (selectedContact)="onSelectedContact($event)"
      [booking]="shipmentInstruction" [contactType]="selectedContactType"
      [backTitle]="'Back to Shipping Instructions'" [containerId]="containerId">
    </app-booking-organization-contacts>
  </div>
</div>