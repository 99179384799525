import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { FileUrl, Page } from '../shared.model';
import {
  Booking,
  BookingPdfRequest,
  BookingsQuery,
  BookingSummary,
  CertificateSummary,
  CreateBooking,
  UpdateBookingIsCanceled,
  UpdateBookingStatus
} from './bookings.model';
import { EncodeUrlCodec } from '../services/encode-url-codec';

@Injectable()
export class BookingsService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getById(id: string): Observable<Booking> {
    return this.http.get<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/${id}`);
  }

  getReuseBooking(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/reuse/${id}`);
  }

  getPage(query: BookingsQuery): Observable<Page<Booking>> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<Page<Booking>>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings`, { params });
  }

  createBooking(booking: CreateBooking): Observable<string | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<string>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings`, booking, httpOptions);
  }

  getPdfUrl(bookingPdfRequest: BookingPdfRequest): Observable<URL | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<URL>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/pdf`, bookingPdfRequest, httpOptions);
  }

  updateBooking(id: string, booking: Booking): Observable<Booking | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/${id}`, booking, httpOptions);
  }

  getEquipment(equipmentCode: string): Observable<Booking | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.get<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/equipment/${equipmentCode}`, httpOptions);
  }

  getSummaryById(id: string): Observable<BookingSummary> {
    return this.http.get<BookingSummary>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/summary/${id}`);
  }

  updateTermsAndConditions(id: string, isEnabled: boolean): Observable<Booking> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/termsAndConditions/${id}?isEnabled=${isEnabled}`,
      httpOptions);
  }

  updateBookingStatus(id: string, bookingStatus: UpdateBookingStatus): Observable<Booking | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/updateStatus/${id}`, bookingStatus, httpOptions);
  }

  updateBookingAndStatus(id: string, booking: Booking, isEnabled: boolean): Observable<Booking | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/updateBookingAndStatus/${id}?isEnabled=${isEnabled}`, booking, httpOptions);
  }

  updateBookingIsCanceled(id: string, updateBookingIsCanceled: UpdateBookingIsCanceled): Observable<Booking | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<Booking>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/${id}/cancel`, updateBookingIsCanceled,
      httpOptions);
  }

  exportBookingsCsv(query: BookingsQuery): Observable<FileUrl> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<FileUrl>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/csv`, { params });
  }

  getInsuranceCertificateById(id: string): Observable<CertificateSummary> {
    return this.http.get<CertificateSummary>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookings/insuranceCertificate/${id}`);
  }

  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
