<div class="container organization-create-container">
  <div class="organization-create-container-title">
    <h1 class="text-center m-0 text-white text-capitalize">Tell Us A Bit About Your Business</h1>
  </div>
  <div class="organization-create-content">
    <form [formGroup]="organizationForm" class="organization-create-form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 mb-4">
              <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'" S4DPlaces>
                <mat-label>Full address</mat-label>
                <div class="input-icon-wrapper" matPrefix>
                  <i class="s4d-icon s4d-location"></i>
                </div>
                <div class="row h-100">
                  <div class="col d-flex align-items-end">
                    <input matInput type="text" placeholder="Full address" ngx-google-places-autocomplete
                      (onAddressChange)="onAddressChange($event)" (input)="
                  onInputCheckIsStartedTyping(organizationForm.get('address'), SignUpCreateOrganizationFields.fullAddress)
                " (focusin)="focusInCheckError(organizationForm.get('address'), SignUpCreateOrganizationFields.fullAddress)"
                      (focusout)="focusOutCheckError(organizationForm.get('address'), SignUpCreateOrganizationFields.fullAddress)"
                      formControlName="address" />
                  </div>
                  <div class="col-auto d-flex align-items-center">
                    <span class="company-selected"
                      *ngIf="form.address.value !== '' && form.address.value !== null && mainAddress !== ''"></span>
                  </div>
                </div>
                <mat-error *ngIf="form.address.invalid && form.address.touched">{{ getErrorMessage('address')
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-4">
              <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'never'">
                <mat-label>Company name</mat-label>
                <div class="input-icon-wrapper" matPrefix>
                  <i class="s4d-icon s4d-building"></i>
                </div>
                <div class="row h-100">
                  <div class="col d-flex align-items-end">
                    <input matInput type="text" placeholder="Start type your company name" formControlName="company"
                      [matAutocomplete]="autoCompany" appAutocompletePosition (input)="
                onInputCheckIsStartedTyping(organizationForm.get('company'), SignUpCreateOrganizationFields.companyName)
              " (focusin)="focusInCheckError(organizationForm.get('company'), SignUpCreateOrganizationFields.companyName)"
                      (focusout)="
                focusOutCheckError(organizationForm.get('company'), SignUpCreateOrganizationFields.companyName)
              " />
                    <mat-autocomplete class="organization-autocomplete" #autoCompany="matAutocomplete"
                      [displayWith]="displayCompanyFn">
                      <mat-option class="city" *ngFor="let item of filteredCompanies | async" [value]="item.companyName"
                        [matTooltip]="getCompanyTooltip(item)" [matTooltipPosition]="'above'"
                        (onSelectionChange)="onSelectCompany(item)">
                        <span class="option-main-color">{{ item.companyName }}</span>, <span
                          class="option-secondary-color">{{
                          item.postalCode }}</span>,
                        <span class="option-secondary-color">{{ item.streetAddress }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <div class="col-auto d-flex align-items-center">
                    <div class="button-spinner autocomplete-spinner" matSuffix>
                      <span class="button-inner">
                        <mat-spinner diameter="25" *ngIf="isCompaniesLoading"></mat-spinner>
                        <span class="company-selected" *ngIf="isCompanySelected"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6 button-panel" *ngIf="!isNoOrganizations">
              <button mat-raised-button type="button" class="back-button mt-2" (click)="onCancel()">Cancel</button>
            </div>
            <div class="col-6 button-panel" [ngClass]="isNoOrganizations ? 'col-12' :'col-6'">
              <button mat-raised-button type="submit" class="next-button button-spinner mt-2" matTooltip="Next"
                [disabled]="isSubmit">
                <span class="button-inner">
                  Next
                  <mat-spinner *ngIf="isSubmit" diameter="25"></mat-spinner>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>