import { Component, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { CreditLinesService } from "../../../shared/creditLines/creditLines.service";
import { AppSettingsService } from "../../../shared/services/app-settings/app-settings.service";
import { CreditLineStatusTransition } from "../../../shared/shared.model";

@Component({
  selector: "app-eligible-credit-line",
  templateUrl: "./eligible-credit-line.component.html",
  styleUrls: ["./eligible-credit-line.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EligibleCreditLineComponent {
  organizationId: string;
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private creditLinesService: CreditLinesService,
    private settingsService: AppSettingsService
  ) {
    this.organizationId = this.settingsService.getSettings().organizationId;
  }

  claimCreditLine(): void {
    this.isLoading = true;
    this.creditLinesService
      .updateCreditLine(this.organizationId, CreditLineStatusTransition.claim)
      .subscribe(
        () => {
          window.location.href = "/creditLine";
        },
        () => {
          this.openPopup(
            "Error. An error occurred while claiming your credit line, try again."
          );
        }
      )
      .add(() => (this.isLoading = false));
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok",
      },
    });
  }
}
