<div class="orders-list-grid-view-finance-container">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort (matSortChange)="onSort()" multiTemplateDataRows>
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <app-quote-search-icons [iconName]="getMainCarriageIcon(item)"></app-quote-search-icons>
      </td>
    </ng-container>
    <ng-container matColumnDef="bolOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip='Bill of Lading' matTooltipPosition="above">BOL/Order№</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.bolOrderNumber }}">
        <span (click)="navigateToBookingDetails(item)">{{
          item.bolOrderNumber
          }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="masterBillOfLading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Master BOL</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.masterBillOfLading }}">
        {{ item.masterBillOfLading ?? "-" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.fromDisplayName }}">
        {{ item.fromDisplayName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.toDisplayName }}">
        {{ item.toDisplayName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="atd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip='Actual Time of Departure' matTooltipPosition="above">ATD</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        {{ item?.atd | date : "MMM dd, yy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updateTimeUtc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>UPDATED</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        <div class="date-time">
          {{ item?.updateTimeUtc | date : "MMM dd, yy" }}
          <span class="time">{{ item?.updateTimeUtc | date : "HH:mm a" }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="bookingPaidStatusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        <lib-traffic-light class="paid-status-color"
          [status]="getIconColor(item.bookingPaidStatusCode)"></lib-traffic-light>
        <span>{{ item.bookingPaidStatusCode | bookingPaidStatusName }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button *ngIf="!isLoading" class="col-auto btn order-actions" matTooltip="Export to CSV"
          (click)="exportBookingsCsv()" [disabled]="isEmptyList">
          <mat-icon>save_alt</mat-icon>
        </button>
        <div class="button-spinner" matSuffix *ngIf="isLoading">
          <span class="button-inner">
            <mat-spinner diameter="25"></mat-spinner>
          </span>
        </div>
      </th>
      <td mat-cell *matCellDef="let item">
        <button appRoleBasedHideOrShow *ngIf="item.bookingPaidStatusCode !== paidStatuses.paid" mat-icon-button class="pay-button"
          (click)="onShowDetails(item); $event.stopPropagation()">
          Pay Order
        </button>
        <button mat-icon-button aria-label="action row" (click)="onShowDetails(item); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== item">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === item">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let item" [attr.colspan]="columns.length">
        <div class="example-element-detail" *ngIf="item === expandedElement"
          [@detailExpand]="item === expandedElement ? 'expanded' : 'collapsed'">
          <app-requests-and-payments-list [booking]="item"
            [organizationId]="item.organizationId"></app-requests-and-payments-list>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let item; columns: columns" class="main-row"></tr>
    <tr mat-row *matRowDef="let item; columns: ['expandedDetail']" class="details-row"></tr>
  </table>
  <div *ngIf="isCreditLine && dataSource?.data?.length === 0" class="no-transaction-yet-content-wrapper">
    <div class="no-transaction-yet-contener-wrapper">
      <h3 class="transaction-title">No transactions yet</h3>
      <p class="transaction-desctiption">
        Your transactions will appear here.
      </p>
      <button class="new-order" (click)="onQuoteSearch()">Create New Order</button>
    </div>
  </div>
</div>