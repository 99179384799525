export class ArrayUtils {
	public static distinctBy<TSource, TDestination>(array: TSource[], keySelector: (value: TSource) => TDestination = null, nameCheck: ((value: TSource) => boolean)[] = null): TSource[] {
		const result = new Map();

		for (const item of array) {
			const key =
				keySelector(item) instanceof Object ?
					Object.values(keySelector(item)).join() :
					keySelector(item);

			if (key && !result.has(key)) {
				result.set(key, item);
			}

			nameCheck?.forEach(condition => {
				if (condition(item)) {
					result.set(key, item);
				}
			})
		}

		return Array.from(result.values());
	};
}