import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import * as uuid from "uuid";
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, startWith, switchMap } from 'rxjs/operators';
import { QuotesService } from '../../../../../quotes/quotes.service';
import { AppSettingsService } from '../../../../../../shared/services/app-settings/app-settings.service';
import { StringCutterService } from '../../../../../../shared/services/text-cutter/stringCutter.service';
import {
  AddressTypeCode,
  GoogleAddress,
  LocationType,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchBaseViewModel,
  TripsSearchFromViewModel,
  TripsSearchLocationAnalyticsViewModel,
  TripsSearchLocationsAddressRequest,
  TripsSearchLocationsRequest,
  TripsSearchViewModelRequest,
  TripsSearchViewModelResponse
} from '../../../../../../shared/shared.model';
import { SupportedCountriesSearch, SupportedCountry } from '../../../../../../shared/supported-countries/supported-countries.model';
import { TripUnLocodeAutocompleteService } from '../../../../../../shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { environment } from '../../../../../../../environments/environment';
import { SupportedCountriesService } from '../../../../../../shared/supported-countries/supported-countries.service';
import { GoogleAnalyticsService } from '../../../../../../shared/google-analytics/google-analytics.service';
import { SalesforceService } from '../../../../../../shared/services/salesforce.service';

@Component({
  selector: 'app-new-flow-shipping-location-fields',
  templateUrl: './new-flow-shipping-location-fields.component.html',
  styleUrls: ['./new-flow-shipping-location-fields.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewFlowShippingLocationFieldsComponent implements OnInit, OnChanges {
  addressTypeCode = AddressTypeCode;
  addressTypeCollections: string[] = [];
  @Input() control: FormControl;
  @Input() type: SearchType = SearchType.from;
  @Input() isSelected: boolean = false;
  @Input() minimumLength: number = 1;
  @Input() shipmentType = ShipmentType.FCL;
  @Input() from = null;
  @Input() to = null;
  @Input() pageSize: number = 10;
  @Input() searchId = '';
  @Input() isReuse: boolean = false;
  @Input() recentQuotes: any = [];
  @Input() isOpened: boolean = false;
  @Input() selectedLocationFromParent: TripsSearchFromViewModel;
  @Input() hasError: boolean = false;
  @Input() supportedCountries: SupportedCountry[];

  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() next: EventEmitter<number> = new EventEmitter();
  @Output() setShipperType: EventEmitter<ShipperType> = new EventEmitter();
  @Output() setSearchId = new EventEmitter<string>();
  @Output() setSelectedLocation = new EventEmitter<{ selectedLocation: TripsSearchFromViewModel, emitNext?: boolean }>();
  @Output() setExpandedValue = new EventEmitter<boolean>();

  searchType = SearchType;
  selectedLocation: TripsSearchFromViewModel = null;
  locationsResponse: TripsSearchViewModelResponse = null;
  locations: Map<LocationType, TripsSearchBaseViewModel[]> = new Map<LocationType, TripsSearchBaseViewModel[]>();
  sortedMap: any = [];
  sortedMap$: any = new Observable<[]>();
  postcodeOptionTitle: string = null;
  suggestedLocality: string = null;
  isLoading: boolean = false;
  isPostcode: boolean = false;
  isLocationAutocompleteSearchEnable: boolean;
  isSearchFromPredefined: boolean = false;
  searchParamChangeSubscription!: Subscription;
  selectedSupportedCountry: string = "";
  selectedAddressType: AddressTypeCode | string = "";
  addressType = AddressTypeCode;
  doorAddressControl = new FormControl();
  googleAddresses: GoogleAddress[] = [];
  googleSessionToken: google.maps.places.AutocompleteSessionToken = undefined;
  locationType = LocationType;
  isCountryLoading: boolean = false;
  isFindingAddressLoader: boolean = false;
  isMissingAddress: boolean = false;
  isDomesticCountry: boolean = false;
  missingAddressText: string = '';
  isExpanded: boolean = false;
  filteredCountries: SupportedCountry[] = [];
  isCountrySelected: boolean = false;
  selectedGoogleAddress: GoogleAddress;
  isStartedTyping: boolean;
  quote: any;
  searchedLocation: string;
  environmentsService: EnvironmentsService;
  environmentName: string;
  mostPopularStates: any;
  isMostPopularActive: boolean = false;
  isMostPopularMoreLoading: boolean = false;
  isMostPopularShowLoadMore: boolean = true;
  mostPopularItemsPageSize: number = 5;
  mostPopularItemsPageNo: number = 1;
  isControlDisable: boolean = false;

  get selectedCountry(): SupportedCountry {
    return this.filteredCountries.find(x => x.countryCode == this.selectedSupportedCountry)
  }

  @ViewChild('trigger', { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private quotesService: QuotesService,
    private location: Location,
    private stringCutterService: StringCutterService,
    private settingsService: AppSettingsService,
    private supportedCountriesService: SupportedCountriesService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private salesforceService: SalesforceService
  ) {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname)
    this.mostPopularStates = this.getIsMostPopularEnabled();
  }

  ngOnInit(): void {
    if (!this.selectedLocationFromParent) {
      this.control?.disable();
    }

    this.addressTypeCollectionsBasedOnShipmentType();
    this.isLocationAutocompleteSearchEnable = NewFlowShippingLocationFieldsComponent.getIsLocationAutocompleteSearch();
    this.onValueChanged();

    if (this.control?.value) {
      this.onSelect(this.control.value, false);
    }

    this.checkIfSearchFromPredefinedRequired();
    this.fillReuseQuote();
    this.clearControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentType) {
      this.selectedAddressType = '';
      this.clearCountry();
      this.clearLocation();
      this.addressTypeCollectionsBasedOnShipmentType();
    }

    if (changes.isSelected) {
      this.isSelected = changes.isSelected?.currentValue;
      this.selectedLocation = this.control?.value;

      if (this.selectedLocation)
        this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(this.selectedLocation?.displayName, 20);
    }

    if (!changes?.isOpened?.currentValue) {
      this.isExpanded = false;
    }

    if (changes?.isOpened?.currentValue && this.selectedLocationFromParent) {
      this.isDomesticCountry = false;
      this.control?.patchValue(this.selectedLocationFromParent, { emitEvent: false });
      const model = this.control.value as any;
      this.from = this.from !== '' && this.from !== null &&
        (typeof this.from === 'string' ? !this.from.includes('*') : !this.from?.value.includes('*'))
        ? this.from
        : this.quote?.from;
      this.to = this.to !== '' && this.to !== null ? this.to : this.quote?.to;

      if (this.type === SearchType.from) {
        this.selectedAddressType = this.getAddressType(model?.locationType);
      } else if (this.type === SearchType.to) {
        this.selectedAddressType = this.getAddressType(model?.locationType);
      }

      this.getFilterSupportedCountries(Number(this.selectedAddressType));
      this.selectedSupportedCountry = model.country;
      this.filteredCountries = [...this.filteredCountries];

      this.isSelected = true;
      this.selectedLocation = this.selectedLocationFromParent;
      this.control.enable({ emitEvent: false });
      this.doorAddressControl.enable();
      if (this.selectedGoogleAddress) {
        this.doorAddressControl.patchValue(this.selectedGoogleAddress);
      } else {
        this.doorAddressControl.patchValue({ description: this.selectedLocation?.fullAddress });
      }
    }
  }

  onSelectAddressType(addressType: AddressTypeCode, isClearDependentControl: boolean = true): void {
    if (isClearDependentControl) {
      this.clearCountry();
      this.clearLocation();
    }

    this.getFilterSupportedCountries(addressType);

    if ([AddressTypeCode.door, AddressTypeCode.warehouse].includes(Number(this.selectedAddressType))) {
      this.isMissingAddress = false;
    }
  }

  onSelectSupportedCountry(event: MatOptionSelectionChange, value: SupportedCountry): void {
    if (!event.isUserInput) {
      return;
    }

    this.mostPopularItemsPageNo = 1;
    this.isCountrySelected = true;
    if (this.isDomesticRoute(value.countryCode)) {
      this.isDomesticCountry = true;
      this.clearLocation();
      this.control.disable();
      this.doorAddressControl.disable();
      this.onAddressAnalyticsCountryError(`complete choose from list`, "Destination cannot be within the same country")
    } else {
      this.isDomesticCountry = false;
      this.clearLocation();
      this.control.enable();
      this.doorAddressControl.enable();
      this.selectedSupportedCountry = value.countryCode;
      this.countryChanged(false);

      this.mostPopularItemsPageSize = 5;
      this.getMostPopularItems();
    }

    this.countryChanged(false)
  }

  displayFn(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.displayName ? tripsSearchBaseViewModel.displayName : null;
  }

  displaySupportedCountryFn = (countryCode: string | null) =>
    countryCode && this.filteredCountries.find(x => x.countryCode === countryCode)?.countryName;

  displayAddressFn(googleAddress: any): string {
    return googleAddress && googleAddress.description ? googleAddress.description : '';
  }

  onSelect(value: TripsSearchFromViewModel, emitNext: boolean = true): void {
    if (this.isDomesticRoute(value.country)) {
      return;
    }
    this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(value, this.type, this.shipmentType);
    this.isSelected = true;
    this.selectedLocation = value;
    this.selectedLocation.searchId = this.locationsResponse?.searchId;

    if (!emitNext) {
      return;
    }

    this.setSelectedLocation.emit({ selectedLocation: this.selectedLocation, emitNext: false });
    if (this.type == SearchType.from) {
      this.googleAnalyticsService.newSearchLocationOriginAddressCodeSelect(this.getNewSearchLocationAnalytics())
    } else if (this.type == SearchType.to) {
      this.googleAnalyticsService.newSearchLocationDestinationAddressCodeSelect(this.getNewSearchLocationAnalytics())
    }
  }

  onClicked(event: Event, isMain: boolean = false): void {
    if (isMain) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.isSelected && this.locationsResponse?.tripsSearchList?.length == undefined) {
      this.locations.clear();
      this.sortedMap = [];
    }

    setTimeout(() => {
      this.isSelected = false;
    }, 0);

    if (this.isPostcode) {
      this.onPostCodeOptionClosed(true);
    }
  }

  onPostCodeOptionClosed(value: boolean): void {
    this.selectedLocation = null;
    this.isPostcode = false;
    this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete'];
    this.autocompleteTrigger.openPanel();
  }

  isDomesticRoute(countryCode: string): boolean {
    if ((this.type == SearchType.from && this.to != null && this.to != '' && countryCode == this.to.country)
      || (this.type == SearchType.to && this.from != null && countryCode == this.from.country)) {
      return true;
    }
    return false;
  }

  panelState(state: boolean): void {
    document.getElementById("search-flow-overlay").style.visibility = state ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = state ? '1' : '0';
    if (document.activeElement == document.getElementById(`location${this.type}`)) return;
  }

  checkLocationType(locationType: LocationType): boolean {
    const ports = [LocationType.seaPort, LocationType.containerTerminal, LocationType.inlandPort];
    const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
    const trains = [LocationType.railwayTerminal];
    const airports = [LocationType.airPort];

    return !ports.includes(locationType) && !others.includes(locationType) && !trains.includes(locationType) && !airports.includes(locationType);
  }

  getLocationTooltipText(location: TripsSearchFromViewModel): string {
    return this.isDomesticRoute(location.country) ? 'Destination cannot be within the same country' : location.displayName;
  }

  disableDomesticOption(event: any, location: TripsSearchBaseViewModel): void {
    if (this.isDomesticRoute(location.country)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onPostCodeOptionSelected(value: any): void {
    if (this.isDomesticRoute(value.location)) {
      return;
    }

    this.selectedLocation = value.location;
    this.postcodeOptionTitle = value.title;

    if (this.isMustVerifyPostCode(value)
      && this.locationsResponse?.tripsSearchList.length > 0) {
      this.isPostcode = true;
      this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete', 'is-postcode'];
    } else {
      this.onPostCodeOptionConfirmed(value.location);
      this.autocompleteTrigger.closePanel();
    }

    this.setSelectedLocation.emit({ selectedLocation: this.selectedLocation });
    this.onSubmitAddress();
  }

  onPostCodeOptionConfirmed(value: TripsSearchFromViewModel): void {
    this.isSelected = true;
    this.selectedLocation = value;
    this.isPostcode = false;
    this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete'];
    this.control.setValue(value)
    this.onSelect(value);
    this.setSelectedLocation.emit({ selectedLocation: this.selectedLocation });
    this.onSubmitAddress();
  }

  onConfirm(isFromRecent = false): void {
    this.setSelectedLocation.emit({ selectedLocation: this.selectedLocation });
    this.onSubmitAddress(isFromRecent);
  }

  getGoogleAddressLocation(googleAddress: GoogleAddress): void {
    this.selectedGoogleAddress = googleAddress;
    this.isMissingAddress = false;
    this.isFindingAddressLoader = true;
    this.selectedLocation = null;
    let map = new google.maps.Map(document.createElement('div'));
    const placeService = new google.maps.places.PlacesService(map);

    var request = {
      placeId: googleAddress.place_id,
      fields: ['address_components', "formatted_address"],
      types: ["address"],
      sessionToken: this.googleSessionToken
    };

    placeService.getDetails(request, (place, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      let address1, postcode, city, sublocality, state, country, street = null;
      address1 = place.formatted_address;
      for (const address of place.address_components) {
        const componentType = address.types[0];

        switch (componentType) {
          case "postal_code": {
            postcode = `${address.long_name}`;
            break;
          }
          case "locality":
            city = address.long_name;
            break;
          case "sublocality_level_1":
            sublocality = address.long_name;
            break;
          case "administrative_area_level_1": {
            state = address.short_name;
            break;
          }
          case "country":
            country = address.short_name;
            break;
          case "street_number":
            street = street ? `${address.long_name} ${street}` : address.long_name;
            break;
          case "route":
            street = street ? `${street} ${address.long_name}` : address.long_name;
            break;
        }
      }

      const query = new TripsSearchLocationsAddressRequest();
      query.country = country;
      query.state = state;
      query.city = city;
      query.sublocality = sublocality;
      query.postcode = postcode;
      query.fullAddress = address1;
      query.shipmentType = this.shipmentType;
      query.from = this.from === '' ? new TripsSearchFromViewModel : this.from;
      query.type = this.type;
      query.addressTypeCode = Number(this.selectedAddressType) as AddressTypeCode;
      query.profile = {
        organizationId: this.settingsService.getSettings().organizationId,
        countryCode: this.settingsService.getSettings().countryCode
      }

      this.tripUnLocodeAutocompleteService.getTripSearchLocationByAddressAsync(query)
        .subscribe((response: TripsSearchViewModelResponse) => {
          if (response.tripsSearchList.length > 0) {
            const locationResult = response.tripsSearchList[0] as TripsSearchFromViewModel;
            if (locationResult.zipCode != null) {
              this.isSelected = true;
              this.control.setValue(locationResult);
              this.selectedLocation = locationResult;
              this.selectedLocation.searchId = response.searchId;
              this.selectedLocation.fullAddress = address1;
              this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(locationResult?.displayName, 20);
              this.selectedLocation.street = street;
              if (city != null) {
                this.selectedLocation.cityName = city;
              }
              this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(locationResult, this.type, this.shipmentType);

              if (this.type == SearchType.from) {
                this.googleAnalyticsService.newSearchLocationOriginAddressCodeSelect(this.getNewSearchLocationAnalytics())
              } else if (this.type == SearchType.to) {
                this.googleAnalyticsService.newSearchLocationDestinationAddressCodeSelect(this.getNewSearchLocationAnalytics())
              }
              this.setSelectedLocation.emit({ selectedLocation: this.selectedLocation, emitNext: false });
            } else {
              this.isMissingAddress = true;
              this.missingAddressText = 'Zip code missing for the selected address, please choose another address';
              this.onAddressAnalyticsError(`select city or port/airport city`, this.missingAddressText)
            }
          } else {
            this.isMissingAddress = true;
            this.missingAddressText = 'Not providing service at selected address. Please choose another address.';
            this.onAddressAnalyticsError(`select city or port/airport city`, this.missingAddressText)
          }
        })
        .add(() => this.isFindingAddressLoader = false);
    });
  }

  setGoogleLocation(googleAddress: GoogleAddress): void {
    this.getGoogleAddressLocation(googleAddress);
  }

  onRecentQuoteSelect(quote): void {
    if (!this.isDomesticRoute(quote.country)) {
      this.isSelected = true;
      this.isExpanded = false;
      this.selectedLocation = quote as TripsSearchFromViewModel;
      this.selectedLocation.searchId = uuid.v4();
      this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(this.selectedLocation?.displayName, 20);
      this.control?.setValue(quote, { emitEvent: false });
      this.doorAddressControl.patchValue({ description: this.selectedLocation?.fullAddress });
      this.selectedAddressType = this.getAddressType(quote?.locationType);
      this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
      this.selectedSupportedCountry = quote?.country;
      this.control.enable({ emitEvent: false });
      this.onConfirm(true);
      this.isDomesticCountry = false;
    } else {
      this.onClickPanel(false);
      this.isDomesticCountry = true;
      this.clearLocation();
      this.control.disable();
      this.doorAddressControl.disable();
      this.onAddressAnalyticsCountryError(`complete choose from list`, "Destination cannot be within the same country");
      this.selectedAddressType = this.getAddressType(quote?.locationType);
      this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
      this.selectedSupportedCountry = quote?.country;
    }
  }

  onClickPanel(state: boolean): void {
    this.isExpanded = state;
    this.setExpandedValue.emit(this.isExpanded);

    if (this.type == SearchType.from) {
      if (this.isExpanded) {
        this.googleAnalyticsService.newSearchLocationRecentOriginsStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationRecentOriginsComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (this.isExpanded) {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onAutocompleteChange(event: any): void {
    if (!this.isCountrySelected) {
      this.clearLocation();
      this.control.disable();
      this.doorAddressControl.disable();
      this.filteredCountries = this.getFilterSupportedCountries(Number(this.selectedAddressType))
        .filter(x => x.countryName.toLocaleLowerCase().includes(event.toLocaleLowerCase()) ||
          x.countryCode.toLocaleLowerCase().includes(event.toLocaleLowerCase()) ||
          x.description?.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    }

    this.isCountrySelected = false;
  }

  setFlagIconBasedOnSelectedCountry(): SupportedCountry {
    return this.filteredCountries?.find(x => x.countryCode == this.selectedSupportedCountry)
  }

  async openEmbeddedChat(): Promise<void> {
    await this.salesforceService.openChat();
    document.body.click();
  }

  addressTypeChanged(isOpened: boolean): void {
    if (this.type == SearchType.from) {
      if (isOpened) {
        this.googleAnalyticsService.newSearchLocationOriginAddressTypeStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginAddressTypeComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isOpened) {
        this.googleAnalyticsService.newSearchLocationDestinationAddressTypeStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationAddressTypeComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  countryChanged(isStarted: boolean): void {
    if (this.type == SearchType.from) {
      if (isStarted) {
        this.googleAnalyticsService.newSearchLocationOriginCountryStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginCountryComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isStarted) {
        this.googleAnalyticsService.newSearchLocationDestinationCountryStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationCountryComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onAutocompleteAddressChange(event: string): void {
    if (!this.isStartedTyping) {
      if (this.type == SearchType.from) {
        this.googleAnalyticsService.newSearchLocationOriginAddressCodeStartTyping(this.getNewSearchLocationAnalytics())
      } else if (this.type == SearchType.to) {
        this.googleAnalyticsService.newSearchLocationDestinationAddressCodeStartTyping(this.getNewSearchLocationAnalytics())
      }
    }

    if (event.length === 0) {
      this.getMostPopularItems();
    }

    this.isStartedTyping = event?.length > 0;
    this.searchedLocation = event;
  }

  onSubmitAddress(isFromRecent = false): void {
    if (this.type == SearchType.from) {
      if (isFromRecent) {
        this.googleAnalyticsService.newSearchLocationRecentOriginsComplete(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginAddressComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isFromRecent) {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsComplete(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationAddressComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onAddressAnalyticsError(actionName: string, error: string): void {
    const newSearchAnalytics = this.getNewSearchLocationAnalytics();
    newSearchAnalytics.actionName = actionName
    if (this.type == SearchType.from) {
      newSearchAnalytics.originCity = this.searchedLocation;
      this.googleAnalyticsService.newSearchLocationOriginAddressError(newSearchAnalytics, error)
    } else if (this.type == SearchType.to) {
      newSearchAnalytics.destinationCity = this.searchedLocation;
      this.googleAnalyticsService.newSearchLocationDestinationAddressError(newSearchAnalytics, error)
    }
  }

  onAddressAnalyticsCountryError(actionName: string, error: string): void {
    const newSearchAnalytics = this.getNewSearchLocationAnalytics();
    newSearchAnalytics.actionName = actionName
    if (this.type == SearchType.from) {
      this.googleAnalyticsService.newSearchLocationOriginAddressCountryError(newSearchAnalytics, error)
    } else if (this.type == SearchType.to) {
      this.googleAnalyticsService.newSearchLocationDestinationAddressCountryError(newSearchAnalytics, error)
    }
  }

  onLoadMore(): void {
    this.isMostPopularMoreLoading = true;
    this.mostPopularItemsPageNo++;
    this.autocompleteTrigger._onChange(this.control.value + '*');
  }

  isShowFullAddress(locationType: LocationType): boolean {
    return locationType === LocationType.townCity;
  }

  private isMustVerifyPostCode(tripsSearchFromViewModel: TripsSearchFromViewModel | any): boolean {
    if (tripsSearchFromViewModel?.location.locationType === LocationType.townCity) {
      if (this.shipmentType === ShipmentType.LCL || this.shipmentType === ShipmentType.AIR) {
        return true;
      }
      else {
        return true;
      }
    }

    return false;
  }

  private onValueChanged(): void {
    this.control?.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          if (this.isSearchFromPredefined && this.control.value?.value && this.selectedAddressType === AddressTypeCode.port.toString()) {
            return this.search((this.control.value as TripsSearchBaseViewModel).value, null);
          }
          const isSearch = this.checkBeforeSearch(val);
          if (isSearch) {
            this.isLoading = true;
            this.locations.clear();
            this.sortedMap = [];
            this.suggestedLocality = null;
          }

          if (typeof val === "object") {
            val = (this.control.value as TripsSearchBaseViewModel).value;
          }

          return (isSearch === true ? this.search(val || '', null) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        this.processTripsResponse(x);
        if (this.isSearchFromPredefined) {
          this.isSearchFromPredefined = false;
        }

        if (this.isControlDisable) {
          this.isControlDisable = false;
          this.control.enable();
        }
      });

    this.doorAddressControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        startWith('')
      ).subscribe((value: any) => {
        this.googleAddresses = [];
        if (value !== "") {
          this.getPlaceAutocomplete(value);
        }
      });
  }

  private processTripsResponse(x: TripsSearchViewModelResponse): void {
    this.locations.clear();
    this.sortedMap = [];
    this.locationsResponse = x;

    if (this.type === SearchType.to && x?.shipperType) this.setShipperType.next(x?.shipperType);

    if (x?.suggestedLocality != undefined) this.suggestedLocality = x.suggestedLocality

    if (x?.tripsSearchList?.length == 0) {
      this.isMostPopularShowLoadMore = false;
    }

    if (x?.tripsSearchList?.length > 0) {
      x.tripsSearchList.forEach((trip: TripsSearchBaseViewModel) => {
        trip.cuttedDisplayName = this.stringCutterService.cutter(trip.displayName, 20);
        this.sortedMap.push(trip);
      })

      if (this.isMostPopularMoreLoading) {
        this.sortedMap$.subscribe((dataArray: any) => {
          let mergeArray = [...dataArray, ...this.sortedMap];
          this.sortedMap$ = of(mergeArray);
        });

        this.isMostPopularMoreLoading = false;
      }
      else {
        this.sortedMap$ = of(this.sortedMap);
      }

    } else if (x && !this.isSearchFromPredefined) {
      this.onAddressAnalyticsError(`select city or port/airport city`, "No results found")
    }

    this.isLoading = false;
  }

  private search(searchValue: string, postcodeSearchValue: string): Observable<TripsSearchViewModelResponse> {
    if (this.isLocationAutocompleteSearchEnable === true) {
      this.isMostPopularActive = searchValue.includes('*');

      if (this.selectedSupportedCountry === '') return of(null);

      return this.tripUnLocodeAutocompleteService.getTripSearchLocations(
        this.prepareTripSearchLocationRequest(
          this.isMostPopularActive ? this.selectedSupportedCountry : searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.isMostPopularActive ? this.mostPopularItemsPageSize : this.pageSize,
          this.mostPopularItemsPageNo,
          this.shipmentType,
          false, this.from,
          (Number(this.selectedAddressType) as AddressTypeCode),
          this.selectedSupportedCountry))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);

            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getTripUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false,
          this.from))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);

            return response;
          }));
    }
  }

  private prepareUnLocodeRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel
  ): TripsSearchViewModelRequest {
    return {
      type: searchType,
      pageSize,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private checkBeforeSearch(value: string): boolean | TripsSearchViewModelResponse {
    if (this.isSelected) {
      return this.locationsResponse;
    }

    if (this.selectedLocation) {
      this.selectedLocation = null;
    }

    if (this.checkMinimumLength(value)) {
      return null;
    }

    return true
  }

  private checkMinimumLength(value: string): boolean {
    return value?.length < this.minimumLength;
  }

  private checkIfSearchFromPredefinedRequired(): void {
    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (this.location.path().includes('reused') || !!quotesQueryString) {
      this.isSearchFromPredefined = true;
      this.isReuse = this.isSearchFromPredefined;
    }
  }

  private prepareTripSearchLocationRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    pageNo: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel | string,
    addressTypeCode: AddressTypeCode,
    countryCode: string
  ): TripsSearchLocationsRequest {
    return {
      type: searchType,
      pageSize,
      pageNo,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from: (from === '' ? null : from) as TripsSearchFromViewModel,
      addressTypeCode,
      selectedCountryCode: countryCode,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  static getIsLocationAutocompleteSearch(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );
    switch (environmentName) {
      case "qa":
        return environment.qa.isLocationAutocompleteSearch;
      case "sb":
        return environment.sb.isLocationAutocompleteSearch;
      default:
        return environment.isLocationAutocompleteSearch;
    }
  }

  private clearControls(): void {
    this.quotesService.isClearControls.pipe(skip(1)).subscribe((control: any) => {
      if (control && control.type === this.type) {
        if (control.isClear) {
          this.selectedAddressType = "";
          this.clearCountry();
          this.clearLocation();
          this.isDomesticCountry = false;
        }
      }
    })
  }

  private clearCountry(): void {
    this.filteredCountries = [];
    this.selectedSupportedCountry = "";
    this.control?.disable();
    this.doorAddressControl.disable();
    this.isDomesticCountry = false;
  }

  removeLocation(): void {
    this.clearLocation();

    if (this.selectedSupportedCountry) {
      this.isControlDisable = true;
      this.control.disable();
      this.autocompleteTrigger._onChange('*' + this.selectedSupportedCountry);
    }
  }

  private clearLocation(): void {
    this.isSelected = false;
    this.selectedLocation = null;
    this.control?.patchValue("");
    this.doorAddressControl.patchValue("");
    this.isMissingAddress = false;
  }

  private getPlaceAutocomplete(value: string): void {
    this.googleSessionToken = new google.maps.places.AutocompleteSessionToken();
    const service = new google.maps.places.AutocompleteService();
    var request =
    {
      input: value,
      componentRestrictions: { country: this.getCountryCodes() },
      fields: ["address_components"],
      sessionToken: this.googleSessionToken
    };

    service.getPlacePredictions(request, (predictions, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }
      this.isMissingAddress = false;
      this.googleAddresses = [];
      predictions.forEach((prediction) => {
        let googleAddress = new GoogleAddress();
        googleAddress.description = prediction.description;
        googleAddress.place_id = prediction.place_id;
        googleAddress.type = this.selectedAddressType as AddressTypeCode;
        this.googleAddresses.push(googleAddress);
      });
    });
  }

  private fillReuseQuote(): void {
    this.quotesService.sendQuoteData.subscribe((quote: any) => {
      if (this.isReuse && quote) {
        this.quote = quote;
        if (this.type === SearchType.from) {
          this.selectedAddressType = this.getAddressType(quote?.from?.locationType);
        } else if (this.type === SearchType.to) {
          this.selectedAddressType = this.getAddressType(quote?.to?.locationType);
        }

        if (this.selectedAddressType === AddressTypeCode.door.toString() ||
          this.selectedAddressType === AddressTypeCode.warehouse.toString()) {
          this.doorAddressControl.patchValue({ description: this.selectedLocation?.fullAddress });
        }
        this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
        this.control?.enable();
        this.doorAddressControl.enable();
      }
    })
  }

  private getAddressType(locationType: LocationType): string {
    return this.getAddressTypeCode(locationType).toString()
  }

  private getAddressTypeCode(locationType: LocationType): AddressTypeCode {
    const port = [LocationType.seaPort, LocationType.railwayTerminal, LocationType.containerTerminal];
    const airport = [LocationType.airPort];
    const door = [LocationType.townCity];

    if (port.includes(locationType)) {
      return AddressTypeCode.port;
    }
    else if (airport.includes(locationType)) {
      return AddressTypeCode.airport;
    }
    else if (door.includes(locationType)) {
      return AddressTypeCode.door;
    }
    else {
      return AddressTypeCode.port;
    }
  }

  private addressTypeCollectionsBasedOnShipmentType(): void {
    this.addressTypeCollections = Object.keys(this.addressTypeCode).filter((k: string) => !isNaN(Number(k)));

    switch (this.shipmentType) {
      case ShipmentType.FCL:
      case ShipmentType.LCL:
        this.addressTypeCollections = this.addressTypeCollections.filter(x => x !== this.addressTypeCode.airport.toString());
        break;
      case ShipmentType.AIR:
        this.addressTypeCollections = this.addressTypeCollections.filter(x => x !== this.addressTypeCode.port.toString() && x !== this.addressTypeCode.warehouse.toString());
      default:
        break;
    }
  }

  private getFilterSupportedCountries(addressType: AddressTypeCode): SupportedCountry[] {
    this.filteredCountries = this.supportedCountries || [];

    return this.filteredCountries;
  }

  private normalizeAddressTypeCode(): AddressTypeCode {
    return this.selectedAddressType != "" ? Number(this.selectedAddressType) as AddressTypeCode : null;
  }

  private getNewSearchLocationAnalytics(): TripsSearchLocationAnalyticsViewModel {
    let newSearchLocationAnalytics;
    if (this.type == SearchType.from) {
      newSearchLocationAnalytics = {
        shipmentType: this.shipmentType,
        pickupType: this.normalizeAddressTypeCode(),
        originCountry: this.selectedCountry?.countryName,
        originCity: this.selectedLocation?.cityName,
      } as TripsSearchLocationAnalyticsViewModel;
    } else if (this.type == SearchType.to) {
      newSearchLocationAnalytics = {
        shipmentType: this.shipmentType,
        pickupType: this.getAddressTypeCode(this.from?.locationType),
        originCountry: this.from?.countryName,
        originCity: this.from?.cityName,
        deliveryType: this.normalizeAddressTypeCode(),
        destinationCountry: this.selectedCountry?.countryName,
        destinationCity: this.selectedLocation?.cityName,
      } as TripsSearchLocationAnalyticsViewModel;
    }

    return newSearchLocationAnalytics;
  }

  private getIsMostPopularEnabled(): any {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.isMostPopularEnabled;
      case 'sb':
        return environment.sb.isMostPopularEnabled;
      default:
        return environment.isMostPopularEnabled;
    }
  }

  private getMostPopularItems(): void {
    switch (this.shipmentType) {
      case ShipmentType.FCL:
        if (!this.mostPopularStates.fcl) return; break;
      case ShipmentType.LCL:
        if (!this.mostPopularStates.lcl) return; break;
      case ShipmentType.AIR:
        if (!this.mostPopularStates.air) return; break;
    }

    this.isControlDisable = true;
    this.control.disable();
    this.autocompleteTrigger._onChange('*' + this.selectedSupportedCountry);
  }

  private getCountryCodes(): Array<string> {
    const countryCode = this.selectedSupportedCountry.toLocaleLowerCase();   
    if (countryCode == 'cn') {
      return [countryCode, 'hk'];
    }
    return [countryCode];
  }
}
