import { Pipe, PipeTransform } from '@angular/core';
import { DangerousGoodsType } from '../shared.model';

@Pipe({ name: 'dangerousGoodsTypeName' })
export class DangerousGoodsTypeNamePipe implements PipeTransform {
  private dangerousGoodsTypeNames = {};

  constructor() {
    this.dangerousGoodsTypeNames[DangerousGoodsType.BATTERIES] = 'Batteries';
    this.dangerousGoodsTypeNames[DangerousGoodsType.OTHER] = 'Other Dangerous Goods';
  }

  transform(value?: DangerousGoodsType): string {
    return this.dangerousGoodsTypeNames[value] ?? '';
  }
}
