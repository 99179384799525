<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="memberForm" [hidden]="isLoading">
  <div class="member-container" [formGroup]="memberForm">
    <div class="row justify-content-center" *ngIf="mode === modes.edit || type === types.member">
      <div class="col-auto">
        <div class="member-profile-logo">
          <span class="member-profile-name">{{ getInitialName() }}</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="type === types.member">
      <div class="col-auto">
        <div class="member-profile-button">
          <div class="member-profile-image">
            <span class="member-profile-image-icon"></span>
          </div>
          <div class="remove-member-profile-image">
            <span class="remove-member-profile-image-icon"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field mt-3">
      <div class="row">
        <div class="col">
          <span class="field-title">First name</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !memberForm.get('firstName').valid &&
                memberForm.get('firstName')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="First name" formControlName="firstName" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Last name</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !memberForm.get('lastName').valid &&
                memberForm.get('lastName')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Last name" formControlName="lastName" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Nickname</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Nickname" formControlName="nickName" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Role</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !memberForm.get('roles').valid && memberForm.get('roles')?.touched
            }">
            <mat-form-field appearance="fill" [floatLabel]="'never'" class="w-100">
              <mat-select multiple formControlName="roles">
                <mat-option *ngFor="let teamAccessRole of teamAccessRoles" [value]="teamAccessRole.value">
                  {{ teamAccessRole.value | accessRoleTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Email</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !memberForm.get('email').valid &&
                memberForm.get('email')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Email" formControlName="email" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field" *ngIf="mode === modes.edit || type === types.member">
      <div class="row">
        <div class="col">
          <span class="field-title">Status</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="member-field-wrapper">
            <mat-form-field appearance="fill" [floatLabel]="'never'" class="w-100">
              <mat-select *ngIf="type === types.invite" placeholder="Status" formControlName="inviteStatusCode">
                <mat-option *ngFor="let inviteStatusCode of inviteStatusCodes" [value]="inviteStatusCode.value">
                  {{ inviteStatusCode.name | titlecase }}
                </mat-option>
              </mat-select>
              <mat-select *ngIf="type === types.member" placeholder="Status" formControlName="memberStatus">
                <mat-option *ngFor="let memberStatusCode of memberStatusCodes" [value]="memberStatusCode.value">
                  {{ memberStatusCode.name | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="member-field mt-3" *ngIf="mode === modes.edit && type === types.invite">
      <div class="row py-2">
        <div class="col">
          <span class="field-title">Expiration date:
            {{
            memberForm.get("expirationDate").value
            | date : "dd MMM, yyyy"
            }}</span>
        </div>
      </div>
      <div class="row py-2">
        <div class="col">
          <span class="field-title">Invited by: {{ memberForm.get("invitedBy").value }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-navbar>
  <div class="col px-3 align-self-center organization-member-buttons-wrapper">
    <div class="row">
      <div class="col">
        <button mat-stroked-button class="back-button" (click)="onCancel()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button mat-raised-button class="next-button button-spinner" (click)="onSave()" [disabled]="isSubmit">
          <span class="button-inner">
            <span *ngIf="mode === modes.add && type === types.invite">Submit</span>
            <span *ngIf="mode === modes.edit || type === types.member">
              Save<span class="hide-on"> Changes</span>
            </span>
            <mat-spinner diameter="25" *ngIf="isSubmit"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</app-navbar>