import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rejected-credit-line',
  templateUrl: './rejected-credit-line.component.html',
  styleUrls: ['./rejected-credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RejectedCreditLineComponent {
  constructor() {  }
}