<div class="booking-contacts-container" *ngIf="!isViewOrganizationContacts">
  <div class="booking-contacts-field">
    <div class="row">
      <div class="col"><span class="field-title">Shipper</span></div>
    </div>
    <div class="row">
      <div class="col pe-0 booking-contacts-field-wrapper">
        <div class="booking-contacts-field-box" [ngClass]="{
            'invalid-error-box':
              isStepContactsNextButtonPress && !shipperContact
          }">
          <mat-form-field [floatLabel]="'never'">
            <input matInput placeholder="Select Shipper" disabled [value]="shipperContact?.companyName" />
            <button matSuffix mat-icon-button class="booking-remove-contact-button"
              (click)="onRemoveBookingContact(contactTypes.shipper)" *ngIf="shipperContact">
              <span class="remove-contact-icon"></span>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="col-auto ps-1">
        <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
          (click)="viewOrganizationContacts(contactTypes.shipper)">
          <span class="add-contact-icon"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="booking-contacts-field mt-3">
    <div class="row">
      <div class="col"><span class="field-title">Consignee</span></div>
    </div>
    <div class="row">
      <div class="col pe-0 booking-contacts-field-wrapper">
        <div class="booking-contacts-field-box" [ngClass]="{
            'invalid-error-box':
              isStepContactsNextButtonPress && !consigneeContact
          }">
          <mat-form-field [floatLabel]="'never'">
            <input matInput placeholder="Select Consignee" disabled [value]="consigneeContact?.companyName" />
            <button matSuffix mat-icon-button class="booking-remove-contact-button"
              (click)="onRemoveBookingContact(contactTypes.consignee)" *ngIf="consigneeContact">
              <span class="remove-contact-icon"></span>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="col-auto ps-1">
        <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
          (click)="viewOrganizationContacts(contactTypes.consignee)">
          <span class="add-contact-icon"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="booking-contacts-field mt-3">
    <div class="row">
      <div class="col"><span class="field-title">Notify party</span></div>
    </div>
    <div class="row">
      <div class="col pe-0 booking-contacts-field-wrapper">
        <div class="booking-contacts-field-box" [ngClass]="{
            'invalid-error-box':
              isStepContactsNextButtonPress && !notifyPartyContact
          }">
          <mat-form-field [floatLabel]="'never'">
            <input matInput placeholder="Select Notify Party" disabled [value]="notifyPartyContact?.companyName" />
            <button matSuffix mat-icon-button class="booking-remove-contact-button"
              (click)="onRemoveBookingContact(contactTypes.notifyParty)" *ngIf="notifyPartyContact">
              <span class="remove-contact-icon"></span>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="col-auto ps-1">
        <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
          (click)="viewOrganizationContacts(contactTypes.notifyParty)">
          <span class="add-contact-icon"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isViewOrganizationContacts">
  <app-booking-organization-contacts (BackToBooking)="onBack()" (selectedContact)="onSelectedContact($event)"
    [booking]="booking" [contactType]="selectedContactType"></app-booking-organization-contacts>
</div>