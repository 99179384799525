import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Page } from '../shared.model';
import { EncodeUrlCodec } from '../services/encode-url-codec';
import { PackageType, PackageTypeQuery } from './package-types.model';

@Injectable()
export class PackageTypeService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  getPage(query: PackageTypeQuery): Observable<Page<PackageType>> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<Page<PackageType>>(
      `${this.internalUrlsService.getApiBaseUrl()}/packagetypes`, { params });
  }
}
