<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #header>
  <div class="booking-files-documents-header-container" *ngIf="booking" [hidden]="isLoading">
    <div class="row booking-files-documents-header-container=text-wrapper">
      <div class="col from-name-text">
        <div class="row">
          <div class="col d-flex">
            <span class="header-title">From</span>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <span class="header-text">{{ booking.fromDisplayName }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center arrow-image">
        <span class="arrow">
          <img src="../../../assets/images/go.svg" />
        </span>
      </div>
      <div class="col to-name-text">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <span class="header-title">To</span>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <span class="header-text text-end">{{ booking.toDisplayName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="booking-files-documents-container" *ngIf="booking" [hidden]="isLoading">
  <div class="row">
    <div class="col">
      <div class="booking-files-documents-search-container">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <span matPrefix>
            <app-booking-icons [iconName]="'search-icon'"></app-booking-icons>
          </span>
          <input type="text" matInput placeholder="Type document name" (input)="onChangeText()"
            [formControl]="documentNameControl" [matAutocomplete]="autoDocumentName" appAutocompletePosition/>
          <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="documentNameControl.value">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #autoDocumentName="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onSelectDocument()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" matTooltip="{{ option.name }}">
              <span class="booking-files-documents-option-wrapper">
                <span class="booking-files-documents-option">
                  <span class="booking-files-documents-name font-weight-bold">
                    {{ option.name }}
                  </span>
                </span>
              </span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="booking-files-documents-upload-container">
        <app-files-documents [booking]="booking" (addedDocumentEvent)="onAddedDocumentEvent($event)">
        </app-files-documents>
      </div>
    </div>
  </div>
  <div class="row mt-3 h-100">
    <div class="col">
      <div class="booking-files-documents-list-container h-100">
        <app-booking-files-documents-list [bookingId]="booking.id"
          [bookingDocuments]="bookingDocuments"></app-booking-files-documents-list>
      </div>
    </div>
  </div>
</div>

<app-navbar>
  <div class="col px-3 align-self-center booking-files-buttons-wrapper">
    <div class="row">
      <div class="col">
        <button mat-stroked-button class="back-button" type="button" (click)="onBack()">
          <div class="row">
            <div class="col-auto">
              <div class="back-icon"></div>
            </div>
            <div class="col back-to-page">
              <span>Back to {{ getReturnToPageText() }}</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</app-navbar>