import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ExtenderTrip, ExtenderTripLegBase, ExtenderTripRate, Quote } from '../../quotes.model';
import { CarriageStatusType, ShipmentType, LocationType, RateServiceType } from '../../../../shared/shared.model';
import { RateDialogData } from './rate-details.model';
import { RateDetailPanels } from '../../quotes-list/quote-rate-detail-row-variants/new-redesigned/quote-rate-detail-row.model';
import { VolumeUnitNamePipe } from '../../../../shared/pipes/volume-unit-name.pipe';
import { Commodity, Container } from '../../../../shared/bookings/bookings.model';
import { LinearUnitNamePipe } from '../../../../shared/features/linear-units/linear-unit-name.pipe';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { AmplitudeService } from '../../../../shared/amplitude/amplitude.service';
import { amplitudeFlags } from '../../../../shared/amplitude/amplitude.constants';

@Component({
  selector: 'app-rate-details-dialog',
  templateUrl: './rate-details-dialog.component.html',
  styleUrls: ['./rate-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateDetailsDialogComponent implements OnInit {
  isBadgeEnabled: boolean;
  quote: Quote;
  containers: Container[];
  shipmentType: ShipmentType;
  CarriageStatusType = CarriageStatusType;
  ShipmentType = ShipmentType;
  LocationType = LocationType;
  firstLegLocationType: LocationType;
  carriageTripLegs = [];
  lastLegCarriage = null;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  quoteTrip: ExtenderTrip;
  isDeliveryToUS: boolean;
  isExpanded: boolean[] = [false, false, false, false, false];
  rateDetailsPanels = RateDetailPanels;
  rateServiceType = RateServiceType;
  rateDetails = {
    [RateDetailPanels.FREIGHT]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Freight charges include the carrier's base rate in addition to
      other charges and fees, as seen in the quote breakdown. Freight
      charges are calculated based on your search criteria, such as
      cargo details, selected origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.DESTINATION]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Destination charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.ORIGIN]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Origin charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.OTHER]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Additional charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.DANGEROUS]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Dangerous charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
  }

  get priceValidHours(): number {
    return this.shipmentType == ShipmentType.AIR
      ? 48
      : (this.isBasicRateServiceType() ? 30 : 72);
  }

  get accumulatePrice(): number {
    return this.quote?.accumulatePrice;
  }

  get isDoorService(): boolean {
    return this.quote.trip?.fromLocationTypeCode == LocationType.townCity || this.isDoorDelivery
  }

  get isDoorDelivery(): boolean {
    return this.quote.trip?.toLocationTypeCode == LocationType.townCity
  }

  get mappedContainers(): Map<string, Container[]> {
    const mappedContainers = new Map<string, Container[]>();
    this.containers.forEach(container => {
      if (!mappedContainers.has(container.equipmentTypeDescription)) {
        mappedContainers.set(container.equipmentTypeDescription, [])
      }

      mappedContainers.get(container.equipmentTypeDescription).push(container)
    })
    return mappedContainers;
  }

  get mappedCommodities(): Commodity[] {
    let commodities = <Commodity[]>[];
    this.containers.forEach(container => {
      commodities = [...commodities, ...container.commodities]
    })
    return commodities;
  }

  constructor(
    public dialogRef: MatDialogRef<RateDetailsDialogComponent>,
    public datePipe: DatePipe,
    private linearUnitNamePipe: LinearUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: RateDialogData,
    private environmentService: EnvironmentService,
    private amplitudeService: AmplitudeService) { }

  ngOnInit(): void {
    this.containers = this.data.containers;
    this.quote = this.data.quote;
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.data.quote.trip);
    this.firstLegLocationType = this.data.quote.trip.fromLocationTypeCode;
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;
    this.shipmentType = this.data.shipmentType;
    this.quoteTrip = this.data.quote.trip;
    this.isDeliveryToUS = this.quoteTrip.toUnLoCode.startsWith('US');

    this.getRateDetails();
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;
    this.isBasicRateServiceType();
  }

  action(result: boolean): void {
    this.dialogRef.close({
      result: result
    });
  }

  getTotalFreightRate(rates: ExtenderTripRate[]) {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  getRatesByApplicableType(applicableType: string, rates: ExtenderTripRate[]) {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.surcharges.reduce((accumulator, charge) => {
        if (charge.applicableType == applicableType) {
          return accumulator + charge.shipmentRate.amount
        }
        return accumulator
      }, 0)
    }, 0)
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip) {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage, carriage: trip.preCarriageTripLeg
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage, carriage: trip.mainCarriageTripLeg
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage, carriage: trip.postCarriageTripLeg
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element?.shipmentRate?.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    return carriageTripLegs;
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.shipmentType == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  onClickPanel(state: boolean, panel: RateDetailPanels): void {
    this.isExpanded[panel] = state;
  }

  getFreightCharges(): number {
    return this.quote.trip?.mainCarriageTripLeg.legs.reduce(
      (freightCharges, leg) =>
        freightCharges + leg.rates?.reduce(
          (legPrice, rate) =>
          (
            legPrice +
            rate.freightRate?.shipmentRate?.totalAmount +
            rate.surcharges.reduce((surchargePrice, surcharge) => surchargePrice + surcharge?.shipmentRate?.totalAmount, 0)
          ), 0
        ), 0);
  }

  getDimensions(commodity: Commodity): string {
    let dimension = '';

    if (commodity !== null && commodity?.dimension) {
      if (commodity.dimension?.width != null
        && commodity.dimension?.height !== null
        && commodity.dimension?.length !== null) {
        dimension += `${commodity?.dimension?.width} x `;

        dimension += `${commodity?.dimension?.height} x `;

        dimension += `${commodity?.dimension?.length}

      ${this.linearUnitNamePipe.transform(
          commodity?.dimension?.lengthUnitCode
        )} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity?.volume, '1.2-2')}

      ${this.volumeUnitNamePipe.transform(
        commodity?.volumeUnitCode
      )}`;
    }

    return dimension;
  }

  isShowReverseDiscountPrice(charge: number, panel: RateDetailPanels = RateDetailPanels.FREIGHT): boolean {
    if (panel != RateDetailPanels.FREIGHT) return false;

    return this.quote.trip?.reverseDiscount > 0 && charge > 0;
  }

  getReverseDiscountPrice(charge: number): number {
    return charge + this.quote.trip.reverseDiscount;
  }

  getChargeAmountToDisplay(charge: any): string {
    if (this.getChargeAmount(charge) != null)
      return this.decimalPipe.transform(this.getChargeAmount(charge), "1.2-2")

    return "-.--"
  }

  getChargeAmount(charge: any): number {
    if (charge?.totalFreightRate) return charge?.totalFreightRate

    if (charge?.shipmentRate.totalAmount != null) return charge?.shipmentRate.totalAmount || 0

    return null
  }

  isBasicRateServiceType(): boolean {
    if (this.environmentService.environment.isQuoteBadgeEnabled) {
      var variant = this.amplitudeService.getFlag(amplitudeFlags.quoteBadge);
      if (this.amplitudeService.checkQuoteBadgeStyle(variant)) {
        this.isBadgeEnabled = false;
        return false;
      }

      this.isBadgeEnabled = variant.payload[ShipmentType[this.shipmentType]];

      if (!this.isBadgeEnabled) return false;

      return this.rateServiceType.Basic ===
        this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType;
    }
    return false;
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }

  private getRateDetails() {
    let isCargoDetailsAdded = false;
    let cargoDetailsIndex = 0;

    this.carriageTripLegs.forEach(({ carriageType, carriage }: { carriageType: CarriageStatusType, carriage: ExtenderTripLegBase }) => {
      carriage.legs.forEach(leg => {
        let rateDetailPanel = RateDetailPanels.FREIGHT;
        switch (carriageType) {
          case CarriageStatusType.preCarriage:
            rateDetailPanel = RateDetailPanels.ORIGIN
            if (this.quote.trip.isPreCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.postCarriage:
            rateDetailPanel = RateDetailPanels.DESTINATION
            if (this.quote.trip.isPostCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.mainCarriage:
          default:
            rateDetailPanel = RateDetailPanels.FREIGHT
            break;
        }

        leg.rates.forEach(rate => {
          const freightRate = rate.freightRate as any;
          this.rateDetails[rateDetailPanel].totalAmount += freightRate?.shipmentRate.totalAmount || 0;

          if (rateDetailPanel === RateDetailPanels.FREIGHT) {
            if (!isCargoDetailsAdded) {
              freightRate.cargoDetails = this.shipmentType != ShipmentType.FCL ? this.mappedCommodities : this.mappedContainers
              isCargoDetailsAdded = true;
              freightRate.totalFreightRate = freightRate?.shipmentRate.totalAmount || 0;
              this.rateDetails[rateDetailPanel].charges.push(freightRate);
              cargoDetailsIndex = this.rateDetails[rateDetailPanel].charges.length - 1;
            } else {
              this.rateDetails[rateDetailPanel].charges[cargoDetailsIndex].totalFreightRate += freightRate?.shipmentRate.totalAmount || 0;
            }
          } else {
            this.rateDetails[rateDetailPanel].charges.push(freightRate);
          }

          if (rate.surcharges?.length) {
            rate.surcharges.forEach(charge => {
              switch (charge.applicableType?.toUpperCase()) {
                case "FREIGHT":
                  this.rateDetails[RateDetailPanels.FREIGHT].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.FREIGHT].charges.push(charge);
                  break;
                case "ORIGIN":
                  this.rateDetails[RateDetailPanels.ORIGIN].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.ORIGIN].charges.push(charge);
                  break;
                case "DESTINATION":
                  this.rateDetails[RateDetailPanels.DESTINATION].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DESTINATION].charges.push(charge);
                  break;
                case "DANGEROUS":
                  this.rateDetails[RateDetailPanels.DANGEROUS].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DANGEROUS].charges.push(charge);
                  break;
                default:
                  this.rateDetails[RateDetailPanels.OTHER].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.OTHER].charges.push(charge);
                  break;
              }
            })
          }
        })
      })
    })
  }

  private getMissedFreightRate(carriageType: CarriageStatusType): unknown {
    switch (carriageType) {
      case CarriageStatusType.preCarriage:
        return {
          description: "Door pickup",
          isMissingRate: true
        }
      case CarriageStatusType.postCarriage:
        return {
          description: "Door delivery",
          isMissingRate: true
        }
    }
  }
}
