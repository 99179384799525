import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { Variant } from '@amplitude/experiment-js-client';
import { QuoteSortBy, SelectItem, ShipmentType } from '../../../shared/shared.model';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { amplitudeFlagVariants, amplitudeFlags } from '../../../shared/amplitude/amplitude.constants';
import { AmplitudeService } from '../../../shared/amplitude/amplitude.service';
import { Quote, QuotesQuery } from '../quotes.model';
import { UtilityService } from '../../../shared/helper/utility.service';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'cheapest-dropdown-overlay' }
    }
  ]
})
export class QuotesListComponent implements OnInit, OnChanges {
  @Input() quotes: Quote[];
  @Input() showD2dAndAir: boolean;
  @Input() shipmentType: ShipmentType;
  @Input() isListLoading: boolean;
  @Input() isLoading: boolean;
  @Input() quotesQuery: QuotesQuery;
  @Input() isShowLoadingList: boolean;
  @Input() from: string;
  @Input() to: string;
  @Output() backEvent = new EventEmitter();

  sortByOptions: SelectItem[] = [
    { name: 'Recommended', value: QuoteSortBy.recommended },
    { name: 'Cheapest First', value: QuoteSortBy.cheapestFirst },
    { name: 'Fastest First', value: QuoteSortBy.fastestFirst }
  ];

  selectedSortBy: QuoteSortBy = QuoteSortBy.recommended;
  cheapestQuoteIds: string[] = null;
  recommendedQuoteIds: string[] = null;
  earliestQuoteIds: string[] = null;
  nearestQuoteId: string = null;
  shortestTransitTimeQuoteIds: string[] = null;
  specialService: { [id: string]: string[] } = {};
  filteredQuotes: Quote[] = [];

  quoteRowVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowFlagKey];
  quoteRowVariant: Variant;
  quoteRowSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL, ShipmentType.AIR];

  quoteRowDetailsVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowDetailsFlagKey];
  quoteRowDetailsVariant: Variant;
  quoteRowDetailsSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL, ShipmentType.AIR];

  quoteRowPriceVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey];
  quoteRowPriceVariant: Variant;
  quoteRowPriceSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL];

  selectedIndex = -1;
  ShipmentTypes = ShipmentType;
  isBadgeEnabled: boolean = false;
  currentPanelName: string;
  isCreditOrganization: boolean = false;

  get allFirstSearch(): boolean {
    return this.quotes.every(x => x.firstSearch);
  }

  get isQuotesBannerEnabled(): boolean {
    return this.amplitudeService.checkQuotesBannerEnabled() && this.getIsQuotesBannerEnabled() && this.shipmentType === ShipmentType.FCL && !this.isCreditOrganization;
  }

  constructor(
    private amplitudeService: AmplitudeService,
    private utilityService: UtilityService,
    private environmentService: EnvironmentService,
    private settingsService: AppSettingsService
  ) { }

  ngOnInit(): void {
    this.quoteRowVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowFlagKey);
    this.quoteRowDetailsVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowDetailsFlagKey);
    this.quoteRowPriceVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey);
    if (!this.isLoading) {
      this.setSpecialServices();

      if (this.quotesQuery.isAlternative) {
        this.quotes = this.getSelectedQuotes();
      }
    }

    this.isCreditOrganization = this.settingsService.getSettings()?.isCreditOrganization;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading?.currentValue) {
      this.setSpecialServices();

      if (this.quotesQuery.isAlternative) {
        this.quotes = this.getSelectedQuotes();
      }
    }
  }

  onChangeSelection(index: number) {
    this.currentPanelName = null;
    this.selectedIndex = index === this.selectedIndex ? -1 : index;
  }

  getSpecialService(quotes: Quote): string[] {
    const services = [];

    if (this.recommendedQuoteIds?.includes(quotes.id)) {
      services.push('recommended');
    }

    if (this.cheapestQuoteIds?.includes(quotes.id)) {
      services.push('cheapest');
    }

    if (this.shortestTransitTimeQuoteIds?.includes(quotes.id)) {
      services.push('fastest');
    }

    return services.length ? services : null;
  }

  onChangeBadgeEnabled(value: boolean): void {
    this.isBadgeEnabled = value;
  }

  isPriceTagsEnabled(): boolean {
    return this.environmentService.environment.isPriceTagsEnabled;
  }

  setPanelName(value: string): void {
    this.currentPanelName = value;
  }

  private setSpecialServices(): void {
    if (this.quotes.length === 0) return;

    if (!this.isPriceTagsEnabled()) return;

    // Step 1: Identify the cheapest quotes
    const cheapestQuotes = this.quotes.reduce(
      (previous, current) => {
        if (current.accumulatePrice < previous[0].accumulatePrice) {
          return [current];
        } else if (current.accumulatePrice === previous[0].accumulatePrice) {
          previous.push(current);
        }
        return previous;
      },
      [this.quotes[0]]
    );
    this.cheapestQuoteIds = cheapestQuotes.map(q => q.id);

    // Step 2: Identify the earliest departure date quotes
    const quotesWithValidDates = this.quotes.filter(x => this.utilityService.isNotNullOrMinDateValue(x.departureDate));
    const earliestQuotes =
      quotesWithValidDates.length > 0
        ? quotesWithValidDates.reduce(
          (previous, current) => {
            if (new Date(current.departureDate) < new Date(previous[0].departureDate)) {
              return [current];
            } else if (new Date(current.departureDate).getTime() === new Date(previous[0].departureDate).getTime()) {
              previous.push(current);
            }
            return previous;
          },
          [quotesWithValidDates[0]]
        )
        : [];
    this.earliestQuoteIds = earliestQuotes.map(q => q.id);

    // Step 3: Identify the quotes with the shortest transit time
    const shortestTransitTime = Math.min(...this.quotes.map(q => q.estimatedDuration));
    const shortestTransitTimeQuotes = this.quotes.filter(q => q.estimatedDuration === shortestTransitTime);
    this.shortestTransitTimeQuoteIds = shortestTransitTimeQuotes.map(q => q.id);

    // Step 4: Determine the recommended quote using weighted scoring
    const quoteScores = new Map<string, number>();
    const weights = {
      cheapest: 3,
      earliest: 1,
      fastest: 2
    };

    this.quotes.forEach(quote => {
      let score = 0;
      if (this.cheapestQuoteIds.includes(quote.id)) score += weights.cheapest;
      if (this.earliestQuoteIds.includes(quote.id)) score += weights.earliest;
      if (this.shortestTransitTimeQuoteIds.includes(quote.id)) score += weights.fastest;
      quoteScores.set(quote.id, score);
    });

    const maxScore = Math.max(...quoteScores.values());
    const topQuotes = Array.from(quoteScores.entries())
      .filter(([_, score]) => score === maxScore)
      .map(([id, _]) => id);

    // Step 5: Tie-breaking logic to ensure only one recommended quote
    let recommendedQuoteId: string | null = null;
    if (topQuotes.length > 1) {
      recommendedQuoteId = topQuotes.reduce((bestQuoteId, currentQuoteId) => {
        const bestQuote = this.quotes.find(q => q.id === bestQuoteId);
        const currentQuote = this.quotes.find(q => q.id === currentQuoteId);

        if (bestQuote && currentQuote) {
          // Prefer the cheapest, then the earliest, and then the fastest in that order.
          if (currentQuote.accumulatePrice < bestQuote.accumulatePrice) return currentQuoteId;
          if (currentQuote.accumulatePrice === bestQuote.accumulatePrice) {
            if (new Date(currentQuote.departureDate) < new Date(bestQuote.departureDate)) return currentQuoteId;
            if (new Date(currentQuote.departureDate) === new Date(bestQuote.departureDate)) {
              if (currentQuote.estimatedDuration < bestQuote.estimatedDuration) return currentQuoteId;
            }
          }
        }
        return bestQuoteId;
      }, topQuotes[0]);
    } else if (topQuotes.length === 1) {
      recommendedQuoteId = topQuotes[0];
    }

    this.recommendedQuoteIds = recommendedQuoteId ? [recommendedQuoteId] : [];

    // Step 6: Determine the nearest quote
    if (this.quotesQuery.isAlternative) {
      this.nearestQuoteId = this.getNearestQuote(this.quotes).id;
    }

    // Step 7: Assign special service labels
    this.specialService = {};
    this.quotes.forEach(quote => {
      this.specialService[quote.id] = [];
      if (this.cheapestQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('cheapest');
      }
      if (this.earliestQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('earliest');
      }
      if (this.shortestTransitTimeQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('fastest');
      }
      if (this.recommendedQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('recommended');
      }
    });
  }

  private getSelectedQuotes(): Quote[] {
    const selectedQuotesIds = [];
    selectedQuotesIds.push(this.nearestQuoteId);

    const selectedQuotes = this.quotes.filter(quote => selectedQuotesIds.includes(quote.id));
    return selectedQuotes;
  }

  private getNearestQuote(quotes: Quote[]): Quote {
    const date = new Date(this.quotesQuery.fromDate ?? new Date());
    const nearestQuote = quotes.reduce((previous, current) => {
      const previousDate = new Date(previous.departureDate);
      const currentDateDiff = Math.abs(date.getTime() - previousDate.getTime());
      const currentDateDiffCurrent = Math.abs(date.getTime() - new Date(current.departureDate).getTime());
      return currentDateDiffCurrent < currentDateDiff ? current : previous;
    });

    return nearestQuote;
  }

  private getIsQuotesBannerEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isQuotesBannerEnabled;
      case 'sb':
        return environment.sb.isQuotesBannerEnabled;
      default:
        return environment.isQuotesBannerEnabled;
    }
  }
}
