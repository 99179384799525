import { Injectable } from '@angular/core';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { environment } from '../../../environments/environment';
import { AmplitudeService } from '../amplitude/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsAppService {
  environmentsService: EnvironmentsService;
  environmentName: string;

  constructor(
    private amplitudeService: AmplitudeService
  ) {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
  }

  isWhatsAppEnabled(): boolean {
    return this.isWhatsAppEnabledOnEnv() && this.amplitudeService.checkWhatsAppEnabled();
  }

  private isWhatsAppEnabledOnEnv(): boolean {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.isWhatsAppEnabled;
      case 'sb':
        return environment.sb.isWhatsAppEnabled;
      default:
        return environment.isWhatsAppEnabled;
    }
  }
}
