import { WeightRange } from './shared.model';

export const weightRangeMetric: WeightRange[] = [
  { range: '1 - 70 kg', weight: '70', dimensions: '30 x 30 x 30' },
  { range: '71 - 100 kg', weight: '100', dimensions: '80 x 70 x 70' },
  { range: '101 - 250 kg', weight: '250', dimensions: '80 x 120 x 50' },
  { range: '251 - 500 kg', weight: '500', dimensions: '100 x 120 x 100' },
  { range: '501 - 1,000 kg', weight: '1000', dimensions: '100 x 120 x 160' },
];

export const weightRangeImperial: WeightRange[] = [
  { range: '1 - 155 lbs', weight: '155', dimensions: '11.82 x 11.82 x 11.82' },
  { range: '156 - 220 lbs', weight: '220', dimensions: '31.50 x 27.56 x 27.56' },
  { range: '223 - 550 lbs', weight: '550', dimensions: '31.50 x 47.24 x 19.69' },
  { range: '551 - 1,100 lbs', weight: '1100', dimensions: '39.37 x 47.24 x 39.37' },
  { range: '1,101 - 2,200 lbs', weight: '2200', dimensions: '39.37 x 47.24 x 62.99' },
];
