import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";

@Injectable()
export class MonitoringService {
  appInsights: ApplicationInsights;
  environmentsService: EnvironmentsService;
  environmentName: string;

  constructor() {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.getInstrumentationKey(),
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  logPageView(name?: string, url?: string): void { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message}, properties);
  }

  getInstrumentationKey(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.appInsightsInstrumentationKey;
      case 'sb':
        return environment.sb.appInsightsInstrumentationKey;
      default:
        return environment.appInsightsInstrumentationKey;
    }
  }
}