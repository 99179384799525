export class AppSettings {
  organizationId: string;
  countryCode: string;
  receivedAt?: Date;
  organizationName: string;
  isCreditOrganization: boolean;

  static createDefault(): AppSettings {
    return {
      organizationId: null,
      countryCode: null,
      organizationName: null,
      isCreditOrganization: false
    };
  }

  static createDesktopDefault() {
    const settings = this.createDefault();

    return settings;
  }

  static complete(settings: AppSettings): AppSettings {
    if (!settings) {
      settings = this.createDefault();
    }

    const defaultSettings = this.createDefault();
    Object.keys(defaultSettings).forEach(x => {
      settings[x] = settings[x] || defaultSettings[x];
    });

    return settings;
  }
}
