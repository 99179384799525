import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {
    constructor() { }

    whiteSpaceValidator(control: FormControl): any {
        return (control.value || '').trim().length ? null : { 'whitespace': true };
    }

    emailValidator(control: FormControl): any {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(control.value)) {
            return { 'invalidEmail': true };
        }
    }

    decimalValidator(control: FormControl): any {
        const value = parseFloat(control.value);

        if (isNaN(value) || value <= 0) {
            return { min: true };
        }

        return null;
    }
}  