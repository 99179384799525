<div class="tax-id-field-box">
  <mat-form-field class="tax-id-mat-form-field" [appearance]="appearance" [floatLabel]="floatLevel" [ngClass]="class">
    <mat-label>{{titleText}}</mat-label>
    <div class="d-flex h-100 align-items-end">
      <input type="text" matInput [formControl]="taxIdControl" (input)="validateTaxId()" [placeholder]="placeholder">
    </div>
    <div class="button-spinner" matSuffix>
      <span class="button-inner">
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        <span class="tax-id-error-icon" [matTooltip]="tooltip" matTooltipPosition="above"
          *ngIf="!isLoading && !taxIdControl.valid">
          <i class="service-line-icon s4d-icon s4d-info-light"></i>
        </span>
        <span class="tax-id-success-icon" *ngIf="!isLoading && taxIdControl.valid">
          <i class="service-line-icon s4d-icon s4d-check success"></i>
        </span>
      </span>
    </div>
  </mat-form-field>
</div>
<div *ngIf="taxIdControl?.touched && taxIdControl?.hasError('required')" class="mat-error d-flex mt-2">
  <i class="s4d-icon s4d-info-light organization-error-info-icon"></i> <span>Please, enter your organization's Tax
    ID.</span>
</div>
<div *ngIf="taxIdControl?.touched && taxIdControl?.hasError('incorrect')" class="mat-error d-flex mt-2">
  <i class="s4d-icon s4d-info-light organization-error-info-icon"></i> <span>Tax ID invalid.</span>
</div>