<div class="quotes-results-wrapper">
  <div class="quotes-results-header">
    <div class="row m-0 justify-content-end" *ngIf="!noQuotesFound">
      <div class="col-auto pe-0" *ngIf="isQuoteBadgeEnabled">
        <mat-form-field class="w-100" [floatLabel]="'never'">
          <mat-select disableOptionCentering panelClass="quote-panel-sort-option" required
            [(ngModel)]="selectedFilteredBy" (selectionChange)="filterQuotesList(true)">
            <mat-option *ngFor="let filteredBy of filteredByOptions" [value]="filteredBy.value">
              {{ filteredBy.name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-auto">
        <mat-form-field class="w-100" [floatLabel]="'never'">
          <mat-select disableOptionCentering panelClass="quote-panel-shipper-types-option" required
            [value]="shipperType" (selectionChange)="onShipperTypeChanged($event.value)">
            <mat-option *ngFor="let shipperType of shipperTypes" [value]="shipperType.value">
              {{ shipperType.name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-auto pe-0" *ngIf="!isQuoteBadgeEnabled">
        <mat-form-field class="w-100" [floatLabel]="'never'">
          <mat-select disableOptionCentering panelClass="quote-panel-sort-option" required [(ngModel)]="selectedSortBy"
            (selectionChange)="oldSortQuotesList(true)">
            <mat-option *ngFor="let sortBy of sortByOptions" [value]="sortBy.value">
              {{ sortBy.name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="quotes-results-container mt-3 mb-4">
    <div class="row">
      <div class="col-3">
        <div class="quotes-results-body-filter-and-banner">
          <div class="price-history mb-4" *ngIf="isShowPriceTrend()">
            <div class="row price-history-header">
              <div class="col">
                <span class="header-text"> This Route’s price history </span>
              </div>
              <div class="col-auto">
                <span class="header-icon-wrapper">
                  <i class="header-icon s4d-icon s4d-info-light" [matTooltip]="priceHistoryTooltipText"
                    matTooltipPosition="above" matTooltipClass="text-center"></i>
                </span>
              </div>
            </div>
            <div class="row price-history-wrapper">
              <div class="row price-history-percentage-wrapper">
                <div class="col-auto">
                  <span class="price-history-percentage">
                    {{ (priceTrendInfo?.percentageStatus !== percentageStatusCode.notSet && priceTrendInfo?.percentages)
                    ? (priceTrendInfo?.percentageStatus === percentageStatusCode.down ? '-' +
                    (priceTrendInfo?.percentages | number: '1.2-2') + '%' : (priceTrendInfo?.percentages | number:
                    '1.2-2') + '%')
                    : '0.00%' }}
                  </span>
                </div>
                <div class="col px-0"
                  *ngIf="priceTrendInfo?.percentageStatus !== percentageStatusCode.notSet && priceTrendInfo?.percentages > 0">
                  <span class="price-history-status-wrapper">
                    <i class="service-line-icon s4d-icon"
                      [ngClass]="priceTrendInfo?.percentageStatus === percentageStatusCode.up ? 's4d-arrow-down' : 's4d-arrow-up'"></i>
                  </span>
                </div>
              </div>
              <div class="price-history-description-wrapper"
                *ngIf="priceTrendInfo?.percentageStatus !== percentageStatusCode.notSet">
                <P class="price-history-description"
                  *ngIf="priceTrendInfo?.percentageStatus === percentageStatusCode.up">
                  Prices are climbing — <span>secure your rate before it rises further!</span>
                </P>
                <P class="price-history-description"
                  *ngIf="priceTrendInfo?.percentageStatus === percentageStatusCode.down">
                  Prices are dropping — <span>now's the perfect time to lock in a great deal!</span>
                </P>
              </div>
            </div>
            <div class="row price-history-chart">
              <app-price-history-chart [priceTrendInfo]="priceTrendInfo"></app-price-history-chart>
            </div>
          </div>

          <div class="services">
            <div class="row service-header">
              <div class="col">
                <span class="header-text"> Base Services </span>
              </div>
              <div class="col-auto"></div>
            </div>
            <div class="row service-line">
              <div class="col-auto">
                <span class="service-line-icon-wrapper">
                  <i class="service-line-icon s4d-icon s4d-globe"></i>
                </span>
              </div>
              <div class="col px-0">
                <span class="service-line-text">Freight Shipping</span>
              </div>
              <div class="col-auto">
                <span matTooltipPosition="above" matTooltip="International freight shipping included"
                  class="service-line-icon-wrapper">
                  <i class="service-line-icon s4d-icon s4d-check success"></i>
                </span>
              </div>
            </div>
            <div class="row service-line" *ngIf="isPickupAtOrigin">
              <div class="col-auto">
                <span class="service-line-icon-wrapper">
                  <i class="service-line-icon s4d-icon s4d-shipping-box"></i>
                </span>
              </div>
              <div class="col px-0">
                <span class="service-line-text" [matTooltip]="doorPickupTooltip()" matTooltipPosition="above"
                  matTooltipClass="text-center">Door Pickup</span>
              </div>
              <div class="col-auto">
                <button class="service-line-button" (click)="addHideDoorDelivery(true)" matTooltipPosition="above"
                  [matTooltip]="
                    isPickupAtOriginRemoved
                      ? 'Click to add Door Pickup'
                      : 'Click to remove Door Pickup'
                  ">
                  <span *ngIf="isPickupAtOriginRemoved">Add</span>
                  <span *ngIf="!isPickupAtOriginRemoved" class="service-line-icon-wrapper interactive">
                    <i class="service-line-icon s4d-icon s4d-check success"></i>
                    <i class="service-line-icon s4d-icon s4d-close close"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="row service-line" *ngIf="isDeliveryOnDestination">
              <div class="col-auto">
                <span class="service-line-icon-wrapper">
                  <i class="service-line-icon s4d-icon s4d-shipping-box"></i>
                </span>
              </div>
              <div class="col px-0">
                <span class="service-line-text" [matTooltip]="doorDeliveryTooltip()" matTooltipPosition="above"
                  matTooltipClass="text-center">Door Delivery</span>
              </div>
              <div class="col-auto">
                <button class="service-line-button" (click)="addHideDoorDelivery(false)" matTooltipPosition="above"
                  [matTooltip]="
                    isDeliveryOnDestinationRemoved
                      ? 'Click to add Door Delivery'
                      : 'Click to remove Door Delivery'
                  ">
                  <span *ngIf="isDeliveryOnDestinationRemoved">Add</span>
                  <span *ngIf="!isDeliveryOnDestinationRemoved" class="service-line-icon-wrapper interactive">
                    <i class="service-line-icon s4d-icon s4d-check success"></i>
                    <i class="service-line-icon s4d-icon s4d-close close"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="services mt-4" *ngIf="isAdditionalServicesEnabled && isShowAdditionalServicesWidget">
            <div class="row service-header">
              <div class="col">
                <span class="header-text"> Additional Services </span>
              </div>
              <div class="col-auto ps-0">
                <span class="header-icon-wrapper">
                  <i class="header-icon s4d-icon s4d-info-light" [matTooltip]="additionalServicesTooltipText"
                    matTooltipPosition="above" matTooltipClass="text-center"></i>
                </span>
              </div>
            </div>
            <div class="row service-line" *ngFor="let additionalService of additionalServices; let i = index">
              <div class="col-auto">
                <span class="service-line-icon-wrapper">
                  <i class="service-line-icon s4d-icon s4d-import"
                    *ngIf="additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually"></i>
                  <i class="service-line-icon s4d-icon s4d-shield"
                    *ngIf="additionalService.id === additionalServiceType.insurance || additionalService.id === additionalServiceType.insuranceManually"></i>
                  <i class="service-line-icon s4d-icon s4d-search"
                    *ngIf="additionalService.id === additionalServiceType.preShipment"></i>
                </span>
              </div>
              <div class="col px-0">
                <span class="service-line-text"
                  *ngIf="additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually">Customs
                  Clearance</span>
                <span class="service-line-text"
                  *ngIf="additionalService.id === additionalServiceType.insurance || additionalService.id === additionalServiceType.insuranceManually">Insurance</span>
                <span class="service-line-text" [matTooltip]="preShipmentTooltipText" matTooltipPosition="above"
                  matTooltipClass="text-center"
                  *ngIf="additionalService.id === additionalServiceType.preShipment">Pre-Shipment Inspection</span>
              </div>
              <div class="col-auto"></div>
            </div>
          </div>
          <div class="services why-choose-us-banner mt-4">
            <div class="service-header">
              <div class="col">
                <div class="header-title">
                  Peace-of-Mind Shipping
                </div>
              </div>
            </div>
            <div class="row mt-4 gap-4">
              <div class="col-12 d-flex align-items-center service gap-3">
                <div class="service-icon"><img src="../../../../assets/images/quotes/delivery-rate.svg"
                    alt="Delivery rate"></div>
                <div class="service-text">99% delivery rate</div>
              </div>
              <div class="col-12 d-flex align-items-center service gap-3">
                <div class="service-icon"><img src="../../../../assets/images/quotes/customer-service.svg"
                    alt="Custom service"></div>
                <div class="service-text">24/7 live expert assistance</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row m-0 justify-content-end"
          *ngIf="!noQuotesFound && isPriceTagsEnabled() && quotesQuery.shipmentType !== shipmentTypes.AIR && !quotesQuery.isAlternative">
          <app-sort-options [(quotes)]="sortedQuotes" (sortCriteria)="sortQuotesList(true, $event)"
            class="px-0"></app-sort-options>
        </div>
        <div class="quotes-results-body-list">
          <app-no-quotes *ngIf="noQuotesFound; else quotesList" [quotesQuery]="quotesQuery"
            [quoteSearchId]="quoteSearchId"></app-no-quotes>
          <ng-template #quotesList>
            <div *ngIf="quotesQuery.isAlternative" class="schedule-not-available-info-box">
              <app-info-box [icon]="'s4d-info-light'" [title]="titleForAlternative"
                [description]="'Check out our options for surrounding dates that might work for you.'"></app-info-box>
            </div>
            <div *ngIf="isShowScheduleNotAvailableInfoBox" class="schedule-not-available-info-box">
              <app-info-box [icon]="'s4d-info-light'" [title]="'Schedule Not Available.'"
                [description]="'Some quotes are missing schedule information. Lock in your price for 48 hours, and our team will contact you with the details.'"></app-info-box>
            </div>
            <app-quotes-list [quotes]="sortedQuotes" [quotesQuery]="quotesQuery" [isListLoading]="isListLoading"
              [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType" [from]="from" [to]="to"
              [isLoading]="isLoading" (backEvent)="onBack()"></app-quotes-list>
            <div *ngIf="isShowLoadingList" class="row can-transform" [ngClass]="{'is-active': isShowLoadingList}">
              <div class="col d-flex justify-content-center">
                <app-quote-search-flow-loader [isFull]="false" [isShowLeftSide]="false"
                  class="search-flow-loader"></app-quote-search-flow-loader>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
