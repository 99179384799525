<div *ngIf="quote.trip">
  <div class="carriage-container default">
    <div class="row m-0">
      <div class="col">
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 detail-title">
        Base services
      </div>
      <div class="col-6 detail-subtitle">
        International Freight
      </div>
      <div appSetRoleBasedPrice class="col-6 detail-subtitle-value">
        {{ quote.trip.currency | currencyTextIcon }}{{mainCarriageTotal | number:'1.2-2'}}
      </div>
      <ng-container *ngIf="quote.trip.preCarriageTripLeg">
        <div class="col-6 detail-subtitle  text-capitalize">
          {{ quote?.trip?.fromLocationTypeCode | locationTypeShortName }} Pickup
        </div>
        <div class="col-6 detail-subtitle-value">
          <span appSetRoleBasedPrice *ngIf="!quote.trip.isPreCarriageMissingRate; else beingCalculated">
            {{ quote.trip.currency | currencyTextIcon }}{{preCarriageTotal | number:'1.2-2'}}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="quote.trip.postCarriageTripLeg">
        <div class="col-6 detail-subtitle text-capitalize">
          {{ quote.trip.toLocationTypeCode | locationTypeShortName }} Delivery
        </div>
        <div class="col-6 detail-subtitle-value">
          <span appSetRoleBasedPrice *ngIf="!quote.trip.isPostCarriageMissingRate; else beingCalculated">
            {{ quote.trip.currency | currencyTextIcon }}{{postCarriageTotal | number:'1.2-2'}}
          </span>
        </div>
      </ng-container>
      <div button class="col-12 show-details" (click)="onShowDetails()">Show details</div>
    </div>
    <div class="row m-0">
      <div class="col">
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 detail-title">
        Cargo Details
      </div>
      <div class="row p-0 m-0" *ngIf="shipmentType === ShipmentType.FCL">
        <ng-container *ngFor="let equipment of quotesQuery?.equipments">
          <div class="col-6 detail-subtitle">
            {{equipment.equipmentTypeDescription}}
          </div>
          <div class="col-6 detail-subtitle-value">
            x{{equipment.quantity}}
          </div>
        </ng-container>
      </div>
      <div class="row p-0 m-0" *ngIf="shipmentType !== ShipmentType.FCL && commodities">
        <div class="col-6 detail-subtitle">
          Chargeable Weight (Total Weight)
        </div>
        <div class="col-6 detail-subtitle-value">
          {{grandTotalChargeableWeight | number:'1.2-2'}} {{grandTotalWeightUnit | weightUnitName | uppercase}}
          <span class="additional" matTooltip="Total Weight">({{grandTotalWeightAmount | number:'1.2-2'}}
            {{grandTotalWeightUnit |
            weightUnitName | uppercase}})</span>
        </div>
        <div class="col-6 detail-subtitle">
          Total Volume
        </div>
        <div class="col-6 detail-subtitle-value">
          {{grandTotalVolumeAmount | number:'1.2-2'}} {{grandTotalVolumeUnit | volumeUnitName | lowercase}}
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col">
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="row m-0" *ngIf="shipmentType !== ShipmentType.AIR">
      <div class="col-12 detail-title">
        Ship information
      </div>
      <div class="col-6 detail-subtitle">
        Vessel name
      </div>
      <div class="col-6 detail-subtitle-value">
        {{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName || 'n/a' }}
      </div>
      <div class="col-6 detail-subtitle">
        Voyage
      </div>
      <div class="col-6 detail-subtitle-value">
        {{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage || 'n/a' }}
      </div>
      <div class="col-6 detail-subtitle">
        Cut-Off Date
      </div>
      <div class="col-6 detail-subtitle-value">
        {{ getCutOffDateTitle(quote) }}
      </div>
    </div>
    <div class="row m-0" *ngIf="shipmentType !== ShipmentType.AIR">
      <div class="col">
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="row m-0 mt-4">
      <div class="col-6 detail-subtitle submit">
        Total Cost
      </div>
      <div appSetRoleBasedPrice class="col-6 detail-subtitle-value submit">
        {{ quote.trip.currency | currencyTextIcon }} {{accumulatePrice | number:'1.2-2'}}
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </div>
</div>

<ng-template #beingCalculated>
  <span class="being-calculated">Being calculated</span>
</ng-template>