import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { interval, Observable, of } from "rxjs";
import {
  debounce,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { Page } from "../../shared.model";
import { PackageTypeService } from "../package-type.service";
import {
  PackageType,
  PackageTypeCode,
  PackageTypeQuery,
  PackageTypeQueryParameters,
} from "../package-types.model";

@Component({
  selector: "app-package-type-autocomplete",
  templateUrl: "./package-type-autocomplete.component.html",
  styleUrls: ["./package-type-autocomplete.component.scss"],
})
export class PackageTypeAutocompleteComponent implements OnInit {
  @Input() pageSize = 500;
  @Input() minimumLength = 1;
  @Input() packageTypeControl: FormControl = new FormControl();
  autoCompletePackageTypeControl: FormControl = undefined;
  packageTypeResponse: PackageType[] = [];
  selectedPackageType: PackageType = null;
  isPackageTypeSelected = false;
  isLoading = false;
  pageNo = 1;
  @Input() isDisable = false;

  constructor(private packageTypeService: PackageTypeService) { }

  ngOnInit() {
    this.initializePackageTypeAutocomplete();
    this.getPackageTypeControl().subscribe(change => {
      this.initializePackageTypeControls();
      if (change !== null) {
        this.loadPackageTypes();
      }
    });
  }

  getPackageTypeControl(): Observable<FormControl> {
    return of(this.packageTypeControl);
  }

  onSelectPackageType(packageType: PackageType) {
    this.packageTypeControl.patchValue(packageType.id);
  }

  displayFn(packageType: PackageType): string {
    return packageType && packageType.code ? packageType.code : null;
  }

  onAutocompleteChange() {
    const autocompleteCode = this.autoCompletePackageTypeControl
      .value as PackageType;
    if (this.packageTypeControl.value !== autocompleteCode.id) {
      this.packageTypeControl.patchValue(null);
    }

    if (this.packageTypeControl.invalid && this.autoCompletePackageTypeControl.value.length > 2) {
      const packageType = this.packageTypeResponse.find(x => x.description.toUpperCase() === this.autoCompletePackageTypeControl.value.toUpperCase());
      if (packageType !== undefined) {
        this.onSelectPackageType(packageType);
      }
    }

    this.packageTypeControl.invalid
      ? this.autoCompletePackageTypeControl.setErrors({ required: true })
      : "";

    this.packageTypeControl.markAsTouched();
  }

  private initializePackageTypeAutocomplete() {
    this.initializePackageTypeControls();
    this.loadPackageTypes();
  }

  private initializePackageTypeControls() {
    this.packageTypeControl =
      this.packageTypeControl === undefined
        ? new FormControl()
        : this.packageTypeControl;
    this.autoCompletePackageTypeControl = new FormControl();

    if (this.packageTypeControl.value) {
      const packageType = {
        id: this.packageTypeControl.value,
        code: PackageTypeCode[this.packageTypeControl.value],
      } as PackageType;
      this.autoCompletePackageTypeControl.patchValue(packageType);

      if (this.isDisable) {
        this.packageTypeControl.disable({ onlySelf: true });
        this.autoCompletePackageTypeControl.disable({ onlySelf: true });
      }
    }
  }

  private loadPackageTypes() {
    this.autoCompletePackageTypeControl.valueChanges
      .pipe(
        startWith(""),
        debounce(() => interval(400)),
        distinctUntilChanged(),
        switchMap((val) => {
          const isSearch = this.checkPackageTypeBeforeSearch(val);
          return isSearch === true ? this.search(val) : of(isSearch);
        }),
        startWith("")
      )
      .subscribe((x: Page<PackageType>) => {
        this.isLoading = false;
        if (this.isPackageTypeSelected) {
          this.isPackageTypeSelected = false;
        } else {
          this.packageTypeResponse = x.items;
        }
      });
  }

  private search(searchValue: string): Observable<Page<PackageType>> {
    this.isLoading = true;
    const query = new PackageTypeQuery();
    query.pageNo = this.pageNo;
    query.pageSize = this.pageSize;
    query.description = searchValue;
    query.sortBy = PackageTypeQueryParameters.description;
    return this.packageTypeService
      .getPage(query)
      .pipe(map((response) => response));
  }

  private checkMinimumLength(value: string) {
    return !(value.length >= this.minimumLength);
  }

  private checkPackageTypeBeforeSearch(value: string) {
    if (this.isPackageTypeSelected) {
      return this.packageTypeResponse;
    }

    if (this.selectedPackageType) {
      this.selectedPackageType = null;
    }

    if (this.checkMinimumLength(value)) {
      return (this.packageTypeResponse = []);
    }
    return true;
  }
}
