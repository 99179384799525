import { AdditionalRate, CurrencyCode, PricePer, VendorsCode } from '../shared.model';

export class AdditionalServicesQuery {
  bookingId?: string;
  quoteSearchId?: string;
  includeProperties?: boolean;
  additionalServiceType?: AdditionalServiceType;
  isEnabled?: boolean;
  any?: boolean;
  sortBy: AdditionalServicesQueryParameters;
  sortDesc: boolean;
  pageNo: number;
  pageSize: number;
}

export enum AdditionalServiceType {
  customs = 1,
  insurance = 2,
  preShipment = 3,
  customsManually = 4,
  insuranceManually = 5
}

export enum AdditionalServicesQueryParameters {
  name = 1,
  description = 2,
  isEnabled = 3,
  createTimeUtc = 4,
  updateTimeUtc = 5
}

export class AdditionalService {
  id: AdditionalServiceType;
  name: string;
  description: string;
  isEnabled: boolean;
  isManuallyService: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  surcharges: AdditionalRate[];
}

export class SingleBondEntryCalculation {
  shipmentValue: number;
}

export class CreateBookingAdditionalServiceCharges {
  additionalServiceType: AdditionalServiceType;
  charges: CreateBookingAdditionalServiceCharge[];
}

export class CreateBookingAdditionalServiceCharge {
  bookingId: string;
  additionalServiceChargeId: string;
  vendorCode: VendorsCode;
  price: number;
  pricePer: PricePer;
  currencyCode: CurrencyCode;
}

export class BookingAdditionalServiceCharges {
  id: string;
  bookingId: string;
  additionalServiceChargeId: string;
  vendorCode: number;
  price: number;
  createTimeUtc: string;
  updateTimeUtc: string;
  additionalServiceCharge: AdditionalServiceCharge;
  currencyCode: CurrencyCode;
}

export class AdditionalServiceCharge {
  id: string;
  additionalServiceId: AdditionalServiceType;
  name: string;
  description: string;
  chargeCode: string;
  applicableType: string;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class InsuranceRateQuery {
  organizationId: string;
  bookingId: string;
  cargoValue: number;
  vendorCode: VendorsCode;
}

export class InsuranceRate {
  insuranceProduct: InsuranceProduct;
  commodities: InsuranceCommodity[];
  quoteToken: string;
  paymentMethodType?: InsurancePaymentMethodTypeCode;
  expiresIn?: number;
  vendorCode: VendorsCode;
}

export class InsuranceProduct {
  id: string;
  name: string;
  description: string;
  termsAndConditionsLink: string;
  statementOfFact: string;
  limit?: number;
  deductible?: number;
  premium?: number;
  serviceFee?: number;
  tax?: number;
  currency: CurrencyCode;
  commodityExclusions: CommodityExclusion[];
}

export class InsuranceCommodity {
  id: number;
  name: string;
}

export enum InsurancePaymentMethodTypeCode {
  invoice = 1,
  creditCard = 2
}

export class CommodityExclusion {
  title: string;
  description: string;
}

export enum InsurancePanels {
  peaceOfMind = 0,
  costEffective = 1,
  flexibility = 2,
  assurance = 3
}

export enum ExcludedCommoditiesPanels {
  cashFinancialInstruments = 0,
  householdGoods = 1,
  liveAnimalsOrPlants = 2,
  luxuryGoods = 3,
  other = 4
}