<div class="booking-summary-container row" *ngIf="bookingSummary">
  <div class="col-xl-8 col-12 booking-summary-left-container">
    <div class="booking-summary-header">
      <div class="row">
        <div class="col">
          <h2 class="booking-summary-header-title">Order Details</h2>
        </div>
        <div class="col-auto me-4">
          <button mat-flat-button class="booking-summary-header-action"
            (click)="navigateToFilesDocuments(bookingSummary.id)">
            <app-booking-icons [iconName]="'file-icon'"></app-booking-icons>
          </button>
          <button mat-flat-button class="booking-summary-header-action" (click)="print(bookingSummary.id)">
            <img src="../../../../../assets/images/bookings/print.svg" alt="Print">
          </button>
          <button *ngIf="false" mat-flat-button class="booking-summary-header-action"
            (click)="download(bookingSummary.id)">
            <img *ngIf="isFileReady; else waitFile" src="../../../../../assets/images/bookings/download.svg"
              alt="Download">
            <ng-template #waitFile>
              <span class="material-icons load-icon">refresh</span>
            </ng-template>
          </button>
          <button mat-flat-button class="booking-summary-header-action" hidden>
            <img src="../../../../../assets/images/bookings/share.svg" alt="Share">
          </button>
          <button mat-flat-button class="booking-summary-header-action" (click)="reuse(bookingSummary.id)"
            *ngIf="allowedRebook">
            <img src="../../../../../assets/images/bookings/reuse.svg" alt="Rebook autopilot">
          </button>
        </div>
      </div>
      <h4 class="booking-summary-header-sub-title" *ngIf="bookingSummary.customerReferenceId">
        REF: {{ bookingSummary.customerReferenceId }}
      </h4>
    </div>
    <div class="incoterms-container">
      <div class="order-summary-section-title">
        <span>Incoterms</span>
      </div>
      <div *ngIf="bookingSummary.quote?.trip?.incoterms?.length > 0">
        <div class="incoterms-section">
          <div class="incoterms-code">
            <span>{{ getIncotermsCodes() }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-details-container">
      <div class="order-summary-section-title">
        <span>Customer Details</span>
      </div>
      <div class="customer-contact-container">
        <div class="customer-contact">
          <div class="customer-contact-title">
            <span>SHIPPER</span>
          </div>
          <div class="customer-contact-name" appIsShowTooltip
            [matTooltip]="bookingSummary?.shipperContact?.companyName">
            <span>{{ bookingSummary?.shipperContact?.companyName }}</span>
          </div>
        </div>
        <div class="customer-contact">
          <div class="customer-contact-title">
            <span>CONSIGNEE</span>
          </div>
          <div class="customer-contact-name" appIsShowTooltip
            [matTooltip]="bookingSummary?.consigneeContact?.companyName">
            <span>{{ bookingSummary?.consigneeContact?.companyName }}</span>
          </div>
        </div>
        <div class="customer-contact">
          <div class="customer-contact-title">
            <span>NOTIFY PARTY</span>
          </div>
          <div class="customer-contact-name" appIsShowTooltip
            [matTooltip]="bookingSummary?.notifyPartyContact?.companyName">
            <span>{{ bookingSummary?.notifyPartyContact?.companyName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-ocean-freight-container">
      <div class="order-summary-section-title">
        <span>Basic {{ bookingSummary.shipmentTypeCode === shipmentTypes.AIR ? 'Air' : 'Ocean' }} Freight</span>
      </div>
      <div class="basic-ocean-freight-section-container">
        <div class="book-summary-left-section-details">
          <div class="basic-ocean-freight-section">
            <div class="basic-ocean-freight-section-title">
              <span>{{ bookingSummary.shipmentTypeCode === shipmentTypes.AIR ? 'Airline' : 'VESSEL NAME' }} </span>
            </div>
            <div class="basic-ocean-freight-section-name">
              <span>{{ bookingSummary.vesselCode }}</span>
            </div>
          </div>
          <div class="basic-ocean-freight-section">
            <div class="basic-ocean-freight-section-title">
              <span>CARRIER</span>
            </div>
            <div class="basic-ocean-freight-section-name">
              <span>{{ bookingSummary.quote?.trip?.carrierScac }}</span>
            </div>
          </div>
        </div>
        <div class="book-summary-right-section-details">
          <div class="basic-ocean-freight-section">
            <div class="basic-ocean-freight-section-title">
              <span>CUT OFF</span>
            </div>
            <div class="basic-ocean-freight-section-name">
              <span>{{ bookingSummary.cutOffDate ? (bookingSummary.cutOffDate | date: "MMM dd, yyyy") : 'n/a' }}</span>
            </div>
          </div>
          <div class="basic-ocean-freight-section">
            <div class="basic-ocean-freight-section-title">
              <span>ESTIMATED DEPARTURE</span>
            </div>
            <div class="basic-ocean-freight-section-name">
              {{checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.departureDate) ?
                (bookingSummary?.quote?.trip?.departureDate | date: "MMM dd, yyyy") : "Schedule Not Available" }}
            </div>
          </div>
          <div class="basic-ocean-freight-section">
            <div class="basic-ocean-freight-section-title">
              <span>ESTIMATED ARRIVAL</span>
            </div>
            <div class="basic-ocean-freight-section-name">
              {{checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.arrivalDate) ?
                (bookingSummary?.quote?.trip?.arrivalDate | date: "MMM dd, yyyy") : "Schedule Not Available" }}
            </div>
          </div>
          <div class="basic-ocean-freight-section" *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL">
            <div class="basic-ocean-freight-section-title">
              <span>DOCUMENT CUT OFF DATE</span>
            </div>
            <div class="basic-ocean-freight-section-name">
              <span>n/a</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="references-container">
      <div class="order-summary-section-title">
        <span>Reference(s)</span>
      </div>
      <div class="references-section">
        <div class="references-section-title">
          <span>Order</span>
        </div>
        <div class="references-section-name">
          <span>{{ bookingSummary.bolOrderNumber ?? 'n/a' }}</span>
        </div>
      </div>
      <div *ngIf="bookingSummary.anotherReferences">
        <div class="references-section"
          *ngFor="let anotherReference of bookingSummary.anotherReferences; let i = index">
          <div class="references-section-title">
            <span>{{ anotherReference.name }}</span>
          </div>
          <div class="references-section-name">
            <span>{{ anotherReference.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.FCL" class="booking-summary-container-list">
      <div *ngFor="let container of bookingSummary.containers;let i = index" class="booking-summary-container-section">
        <div class="booking-summary-container-section-title">
          <span>Container {{ i + 1 }}</span>
        </div>
        <div class="booking-container-section-equipmentType">
          <span>{{ container.equipmentTypeDescription }}</span>
        </div>
        <div class="booking-summary-container-sub-section">
          <div class="booking-summary-container-section-header">
            <span>Container</span>
          </div>
          <div class="booking-summary-container-row">
            <div class="booking-summary-container-row-left">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Container Contents</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>{{ container.cargoName }}</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Gross Weight</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>
                    {{ container.cargoGrossWeight }} {{ container.cargoGrossWeightUnitCode | weightUnitName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="booking-summary-container-row-right">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Commodity Code</span>
                </div>
                <div class="booking-summary-container-row-section-name"
                  *ngFor="let commodity of container.commodities; last as isLast">
                  <span>{{ commodity.commodityTypeCode }}</span>
                  <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Commodity Description</span>
                </div>
                <div class="booking-summary-container-row-section-name"
                  *ngFor="let commodity of container.commodities; last as isLast">
                  <span>{{ commodity.commodityDescription}}</span>
                  <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-summary-container-sub-section" *ngIf="container?.collectionDate">
            <div class="booking-summary-container-row">
              <div class="booking-summary-container-row-left">
                <div class="booking-summary-container-row-section">
                  <div class="booking-summary-container-row-section-title">
                    <span>Collection Date</span>
                  </div>
                  <div class="booking-summary-container-row-section-name">
                    <span>
                      {{ container.collectionDate ? (container.collectionDate | date: "dd MMMM, yyyy") : 'n/a' }}
                    </span>
                  </div>
                </div>
                <div class="booking-summary-container-row-section">
                  <div class="booking-summary-container-row-section-title">
                    <span>From</span>
                  </div>
                  <div class="booking-summary-container-row-section-name">
                    <span>{{ renderContactData(container.collectContact) }}</span>
                  </div>
                </div>
              </div>
              <div class="booking-summary-container-row-right">
                <div class="booking-summary-container-row-section">
                  <div class="booking-summary-container-row-section-title">
                    <span>Collection Time</span>
                  </div>
                  <div class="booking-summary-container-row-section-name">
                    <span>
                      {{ container.collectionDate ? (container.collectionDate | date: "shortTime") : 'n/a' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-summary-container-sub-section">
          <div class="booking-summary-container-section-header">
            <span>Delivery</span>
          </div>
          <div class="booking-summary-container-row">
            <div class="booking-summary-container-row-left">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Delivery Date</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>n/a</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section" *ngIf="isShowDropContact">
                <div class="booking-summary-container-row-section-title">
                  <span>To</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>{{ renderContactData(container.dropContact) }}</span>
                </div>
              </div>
            </div>
            <div class="booking-summary-container-row-right">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Delivery Time</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>n/a</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL || bookingSummary.shipmentTypeCode === shipmentTypes.AIR"
      class="booking-summary-container-list">
      <div *ngFor="let container of bookingSummary.containers;let i = index" class="booking-summary-container-section">
        <div *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL"
          class="booking-summary-container-section-title">
          <span>LCL Shipment {{ i + 1 }}</span>
        </div>
        <div *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.AIR"
          class="booking-summary-container-section-title">
          <span>AIR Shipment {{ i + 1 }}</span>
        </div>
        <div class="booking-summary-container-sub-section">
          <div class="booking-summary-container-section-header">
            <span>Cargo</span>
          </div>
          <div class="booking-summary-container-row">
            <div class="booking-summary-container-row-left">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Cargo Details</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>{{ container.cargoName }}</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Commodity Code</span>
                </div>
                <div class="booking-summary-container-row-section-name"
                  *ngFor="let commodity of container.commodities; last as isLast">
                  <span>{{ commodity.commodityTypeCode }}</span>
                  <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Commodity Description</span>
                </div>
                <div class="booking-summary-container-row-section-name"
                  *ngFor="let commodity of container.commodities; last as isLast">
                  <span>{{ commodity.commodityDescription }}</span>
                  <span *ngIf="container.commodities.length > 1 && !isLast">:</span>
                </div>
              </div>
            </div>
            <div class="booking-summary-container-row-right">
              <div *ngFor="let commodity of container.commodities;let i = index">
                <div class="booking-summary-container-row-section">
                  <div class="booking-summary-container-row-section-title">
                    <span>Load {{ i+1 }} Details</span>
                  </div>
                  <div class="booking-summary-container-row-section-name">
                    <span>
                      {{ commodity.numberOfPackages }} {{ commodity.packageTypeCode | packageTypeName }}
                    </span>
                    <div *ngIf="isShowCommodityDimension(commodity)">
                      Dimensions (W) {{ commodity.dimension.width }}
                      {{ commodity.dimension.widthUnitCode | dimensionUnitName | lowercase }} x
                      (H) {{ commodity.dimension.height }}
                      {{ commodity.dimension.heightUnitCode | dimensionUnitName | lowercase }} x
                      (L) {{ commodity.dimension.length }}
                      {{ commodity.dimension.lengthUnitCode | dimensionUnitName | lowercase }}
                    </div>
                  </div>
                </div>
                <div class="booking-summary-container-row-section">
                  <div class="booking-summary-container-row-section-title">
                    <span>Grand Totals</span>
                  </div>
                  <div class="booking-summary-container-row-section-name" *ngIf="commodity.volume && commodity.weight">
                    <span>Volume: {{ commodity.volume }}
                      {{ commodity.volumeUnitCode | volumeUnitName | titlecase }},
                      Weight: {{ commodity.weight }}
                      {{ commodity.weightUnitCode | weightUnitName | lowercase }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-summary-container-sub-section">
          <div class="booking-summary-container-section-header">
            <span>Collection</span>
          </div>
          <div class="booking-summary-container-row">
            <div class="booking-summary-container-row-left">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Collection Date</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>
                    {{ container.collectionDate ? (container.collectionDate | date: "dd MMMM, yyyy") : 'n/a' }}
                  </span>
                </div>
              </div>
              <div class="booking-summary-container-row-section" *ngIf="isShowCollectContact">
                <div class="booking-summary-container-row-section-title">
                  <span>From</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>{{ renderContactData(container.collectContact) }}</span>
                </div>
              </div>
            </div>
            <div class="booking-summary-container-row-right">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Collection Time</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>
                    {{ container.collectionDate ? (container.collectionDate | date: "shortTime") : 'n/a' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-summary-container-sub-section">
          <div class="booking-summary-container-section-header">
            <span>Delivery</span>
          </div>
          <div class="booking-summary-container-row">
            <div class="booking-summary-container-row-left">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Delivery Date</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>n/a</span>
                </div>
              </div>
              <div class="booking-summary-container-row-section" *ngIf="isShowDropContact">
                <div class="booking-summary-container-row-section-title">
                  <span>To</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>{{ renderContactData(container.dropContact) }}</span>
                </div>
              </div>
            </div>
            <div class="booking-summary-container-row-right">
              <div class="booking-summary-container-row-section">
                <div class="booking-summary-container-row-section-title">
                  <span>Delivery Time</span>
                </div>
                <div class="booking-summary-container-row-section-name">
                  <span>n/a</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bookingSummary.bookingAdditionalServiceCharges.length > 0 && isAdditionalServicesEnabled" class="booking-summary-container-list">
      <div class="booking-summary-container-section">
        <div class="booking-summary-container-section-title">
          <span>Additional Services</span>
        </div>
        <div *ngFor="let bookingAdditionalService of bookingAdditionalServices;let i = index"
          class="booking-container-section-equipmentType d-flex align-items-center">
          <div class="service-icon d-flex">
            <div class="booking-flow-services-import-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-import"
                *ngIf="bookingAdditionalService.id === additionalServiceType.customs"></i>
              <i class="panel-header-icon s4d-icon s4d-shield"
                *ngIf="bookingAdditionalService.id === additionalServiceType.insurance"></i>
              <i class="panel-header-icon s4d-icon s4d-search"
                *ngIf="bookingAdditionalService.id === additionalServiceType.preShipment"></i>
            </div>
          </div>
          <div class="service-title-description-wrapper ">
            <h3 class="service-title">{{bookingAdditionalService?.name}}</h3>
            <p class="service-description">{{bookingAdditionalService?.description}}</p>
          </div>
          <div class="service-options  d-flex justify-content-center">
            <div class="booking-flow-services-import-icon d-flex align-items-center justify-content-center">
              <i [hidden]="true" class="panel-header-icon s4d-icon s4d-options"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-12 booking-summary-right-container">
    <div class="booking-summary-right-container-wrapper">
      <div class="booking-summary-right-section-header">
        <span>Summary</span>
      </div>
      <div *ngFor="let carriage of getCarriageWiseTripLeg(bookingSummary.quote?.trip)"
        class="booking-summary-right-section-rate-container">
        <div *ngFor="let leg of carriage.legs; let i = index">
          <div *ngIf="leg.rates && leg.rates.length !== 0" class="booking-summary-right-section-leg-container">
            <div class="booking-summary-right-section-container" *ngIf="(leg | checkFreightRates)">
              <div class="booking-summary-right-section-sub-header">Freight Charges</div>
              <div class="booking-summary-right-section-container-row">
                <div class="booking-summary-right-section-container-row-title" appIsShowTooltip
                  [matTooltip]="(leg | freightRate)?.freightRate.description">
                  <span>{{ (leg | freightRate)?.freightRate.description }}</span>
                </div>
                <div class="booking-summary-right-section-container-row-value">
                  <mat-icon class="currency-icon">
                    {{ bookingSummary.quote?.currency | currencyIcon }}
                  </mat-icon>
                  <span>{{ leg.rates | totalFreightRate | number:'1.2-2' }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="(leg | checkSurcharges)">
              <div *ngFor="let applicableType of leg | uniqueApplicableType">
                <div class="booking-summary-right-section-container">
                  <div class="booking-summary-right-section-sub-header">
                    {{ applicableType | titlecase }} Charges
                  </div>
                  <div *ngFor="let additionalType of leg | surchargesByApplicableType:applicableType;let i = index">
                    <div class="booking-summary-right-section-container-row">
                      <div class="booking-summary-right-section-container-row-title" appIsShowTooltip
                        [matTooltip]="additionalType">
                        <span>{{ additionalType }}</span>
                      </div>
                      <div class="booking-summary-right-section-container-row-value">
                        <mat-icon class="currency-icon">
                          {{ bookingSummary.quote?.currency | currencyIcon }}
                        </mat-icon>
                        <span>{{ leg.rates | totalAdditionalTypeRate:applicableType:additionalType | number:'1.2-2'
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="bookingSummary?.bookingAdditionalServiceCharges.length > 0 && isAdditionalServicesEnabled">
        <div class="booking-summary-right-section-container-additional">
          <div class="booking-summary-right-section-sub-header">
            Additional Services
          </div>
          <div *ngFor="let bookingAdditionalServiceCharge of bookingSummary?.bookingAdditionalServiceCharges">
            <div class="booking-summary-right-section-container-row">
              <div class="booking-summary-right-section-container-row-title" appIsShowTooltip
                [matTooltip]="bookingAdditionalServiceCharge?.additionalServiceCharge?.name">
                <span>{{ bookingAdditionalServiceCharge?.additionalServiceCharge?.name }}</span>
              </div>
              <div class="booking-summary-right-section-container-row-value">
                <mat-icon class="currency-icon">
                  {{ bookingSummary.quote.trip.currency | currencyIcon }}
                </mat-icon>
                <span>{{ bookingAdditionalServiceCharge?.price | number : "1.2-2" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="booking-summary-right-section-total-container">
        <div *ngIf="requestsAndPayments?.length > 0">
          <div *ngFor="let payment of requestsAndPayments" class="mt-3 mb-3">
            <div class="booking-summary-right-section-sub-header mb-1">{{ payment.reasonDisplayName }}</div>
            <div class="booking-summary-right-section-total-row mb-2">
              <div class="booking-summary-right-section-total-row-title">
                Total
              </div>
              <div class="booking-summary-right-section-total-row-value">
                <mat-icon class="currency-icon">
                  {{ bookingSummary.quote?.currency | currencyIcon }}
                </mat-icon>
                <span>{{ payment.amount | number:'1.2-2' }}</span>
              </div>
            </div>
            <div *ngIf="payment.fees" class="booking-summary-right-section-total-row">
              <div class="booking-summary-right-section-total-row-title">
                Fees
              </div>
              <div class="booking-summary-right-section-total-row-value">
                <mat-icon class="currency-icon">
                  {{ bookingSummary.quote?.currency | currencyIcon }}
                </mat-icon>
                <span>{{ payment.fees | number:'1.2-2' }}</span>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="booking-summary-right-section-total-row mt-4">
            <div class="booking-summary-right-section-total-row-title">
              Total Amount
            </div>
            <div class="booking-summary-right-section-total-row-value">
              <mat-icon class="currency-icon">
                {{ bookingSummary.quote?.currency | currencyIcon }}
              </mat-icon>
              <span>{{ totalAmount | number:'1.2-2' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
