import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { User, UserService } from '@ship4wd/ngx-authorization';
import { OrganizationsService } from '../organizations/organizations.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public user: User;

  constructor(
    public location: Location,
    private router: Router,
    private userService: UserService,
    private organizationsService: OrganizationsService) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    if (!this.user.isAuthenticated) {
      this.navigateToLogin();
    }
    else{
      this.organizationsService.setUserId(this.user.userId);
    }
  }

  private navigateToLogin() {
   this.router.navigate(['/login']);
  }
}
