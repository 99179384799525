<div class="flow-shipping-location-fields-wrapper d-flex h-100 flex-column">
  <div class="title-wrapper no-close">
    <span class="details-title no-close">Where do you want to ship {{ type === searchType.from ? 'from' :
      'to'}}?</span>
  </div>
  <div class="flow-shipping-location-fields no-close">
    <div class="recent-origin-wrapper no-close" *ngIf="recentQuotes.length">
      <mat-accordion mutli="false">
        <div class="col-12 accordion-main-wrapper">
          <mat-expansion-panel class="recent-origin-details-expansion-panel" (opened)="onClickPanel(true)"
            (closed)="onClickPanel(false)" [expanded]="isExpanded" hideToggle>
            <mat-expansion-panel-header class="panel-header">
              <mat-panel-title class="panel-title">
                <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                  <div class="origin-title-wrapper no-close">
                    <span class="origin-title no-close">Show recent {{ type === searchType.from ? 'origins' :
                      'destinations'
                      }}</span>
                  </div>
                  <div class="panel-arrow-icon d-flex align-items-center no-close">
                    <span class="panel-header-icon-wrapper no-close">
                      <i *ngIf="!isExpanded" class="panel-header-icon s4d-icon s4d-chevron-down is-hover no-close"></i>
                      <i *ngIf="isExpanded" class="panel-header-icon s4d-icon s4d-chevron-up is-hover no-close"></i>
                    </span>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-content">
              <mat-list class="matlist-wrapper no-close">
                <mat-list-item class="matlist-item-wrapper" *ngFor="let quote of recentQuotes"
                  (click)="onRecentQuoteSelect(quote)">
                  <div class="icon-country-name-wrapper">
                    <span matListItemIcon class="matListItemIcon">
                      <i class="contact-country-flag-icon fi fi-{{ quote?.country | lowercase }}"></i>
                    </span>
                    <div matListItemLine class="country-name-wrapper">
                      <span class="country-name dark">{{ quote?.displayName }}, </span>
                      <span class="country-name light">{{ quote?.countryName }}</span>
                      <div class="country-address-wrapper" *ngIf="isShowFullAddress(quote?.locationType)">
                        <span class="country-address light" [matTooltip]="quote?.fullAddress">{{ quote?.fullAddress
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="port-place-wrapper">
                    <span *ngIf="quote?.locationType | locationTypeShortName" class="location-type">
                      {{ quote?.locationType | locationTypeShortName | uppercase }}
                    </span>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </div>
    <div class="addressType-field no-close">
      <mat-form-field appearance="fill" class="w-100" [ngClass]="{
        'is-error': !selectedAddressType && !isSelected && hasError
      }" [floatLabel]="'never'">
        <div class="input-icon-wrapper no-close">
          <i *ngIf="!selectedAddressType" class="input-icon s4d-icon s4d-shipping-box no-close"></i>
          <i *ngIf="selectedAddressType" [ngClass]="selectedAddressType | addressTypeS4dIcon"
            class="input-icon s4d-icon no-close"></i>
        </div>
        <mat-label class="mat-label-text no-close">Select Address Type</mat-label>
        <mat-select class="address-type no-close" disableOptionCentering [(ngModel)]="selectedAddressType"
          panelClass="address-panel-popup no-close no-close-popup-panel"
          (selectionChange)="onSelectAddressType($event.value)" (opened)="addressTypeChanged(true)"
          (closed)="addressTypeChanged(false)">
          <mat-option *ngFor="let addressTypeCollection of addressTypeCollections" [value]="addressTypeCollection"
            [disabled]="addressTypeCollection.disabled" class="no-close">
            <div class="no-close d-flex justify-content-between align-items-center">
              <div class="no-close icon-text-wrapper d-flex align-items-center">
                <div class="input-icon-wrapper no-close">
                  <div class="no-close w-100 d-flex">
                    <i [ngClass]="addressTypeCollection | addressTypeS4dIcon"
                      class="panel-header-icon s4d-icon no-close"></i>
                  </div>
                </div>
              </div>
              <div class="input-text-wrapper no-close">
                <div class="col ps-0 no-close input-text">{{ addressTypeCollection | addressTypeShortName}}</div>
              </div>
              <div class="input-right-icon no-close">
                <div class="icon-tooltip-wrapper no-close">
                  <i class="panel-header-icon s4d-icon s4d-info-light no-close"
                    matTooltip="Your cargo will be collected from your business establishment."
                    *ngIf="addressTypeCollection === addressTypeCode.door.toString()" matTooltipPosition="above"></i>
                </div>
                <div class="icon-tooltip-wrapper no-close">
                  <i class="panel-header-icon s4d-icon s4d-info-light no-close"
                    matTooltip="Your cargo will be collected from the selected warehouse."
                    *ngIf="addressTypeCollection === addressTypeCode.warehouse.toString()"
                    matTooltipPosition="above"></i>
                </div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error class="mt-2" *ngIf="!selectedAddressType && !isSelected && hasError">
        <i class="s4d-icon s4d-info-light"></i>
        This field cannot be empty.
      </mat-error>
    </div>

    <div class="mt-3 country-field no-close" [ngClass]="{'disable-field': !filteredCountries?.length}">
      <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'"
        [ngClass]="{'domestic-country': isDomesticCountry}">
        <div class="input-icon-wrapper no-close">
          <mat-spinner *ngIf="isCountryLoading" class="location-loader location-icon" diameter="22"></mat-spinner>
          <i *ngIf="!isCountryLoading && !selectedSupportedCountry" class="location-icon s4d-icon s4d-map no-close"></i>
          <i *ngIf="!isCountryLoading && selectedSupportedCountry && setFlagIconBasedOnSelectedCountry()"
            class="contact-country-flag-icon fi fi-{{selectedSupportedCountry | lowercase}} no-close"></i>
        </div>
        <mat-label class="mat-label-text no-close">Select Country</mat-label>
        <input type="text" matInput [(ngModel)]="selectedSupportedCountry" [matAutocomplete]="autoCountry"
          (ngModelChange)="onAutocompleteChange($event)" placeholder="Select Country" [disabled]="!selectedAddressType"
          panelClass="address-panel-popup-country no-close no-close-popup-panel" class="no-close"
          appAutocompletePosition>
        <mat-autocomplete #autoCountry="matAutocomplete" class="country-autocomplete no-close"
          [displayWith]="displaySupportedCountryFn" (opened)="countryChanged(true)">
          <mat-option *ngFor="let item of filteredCountries" [value]="item.countryCode"
            (onSelectionChange)="onSelectSupportedCountry($event, item)" class="no-close">
            <div class="countryflag-name-wrapper no-close d-flex align-items-center">
              <div class="country-flag no-close">
                <div class="input-icon-wrapper no-close w-100">
                  <i class="contact-country-flag-icon fi fi-{{item.countryCode | lowercase}} no-close"></i>
                </div>
              </div>
              <div class="country-name no-close">{{ item.countryName }}</div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error *ngIf="isDomesticCountry" class="mt-1">
        <i class="s4d-icon s4d-info-light no-close"></i> Destination cannot be within the same country
      </mat-error>
    </div>

    <div class="mt-3 select-address-field no-close" [ngClass]="{'disable-field': !filteredCountries?.length}" *ngIf="selectedAddressType !== undefined && selectedAddressType.toString() !== addressType.door.toString() &&
      selectedAddressType.toString() !== addressType.warehouse.toString()">
      <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'">
        <div class="col-auto location-icon-main-wrapper no-close">
          <div class="location-icon-wrapper address no-close">
            <mat-spinner *ngIf="isLoading" class="location-loader location-icon" diameter="22"></mat-spinner>
            <i *ngIf="!isLoading && !isSelected" class="location-icon s4d-icon s4d-map no-close"></i>
            <i *ngIf="!isLoading && isSelected" [ngClass]="selectedLocation?.locationType | locationTypeS4DIcon"
              class="location-icon s4d-icon no-close"></i>
          </div>
        </div>

        <mat-label class="mat-label-text no-close">Select City or Port/Airport Code</mat-label>
        <div class="col-12 pe-0 input-element-wrapper no-close" [hidden]="(isSelected && selectedLocation !== null)">
          <input matInput [matAutocomplete]="autoFrom" type="text" #trigger="matAutocompleteTrigger"
            [formControl]="control" appAutocompletePosition (input)="onAutocompleteAddressChange($event.target.value)">
          <mat-autocomplete #autoFrom="matAutocomplete" class="location-autocomplete no-close" [displayWith]="displayFn"
            (optionSelected)="onSelect($event.option.value)" (opened)="panelState(true)" (closed)="panelState(false)"
            panelClass="search-panel-popup no-close-popup-panel">
            <ng-container *ngIf="!isPostcode">
              <mat-option disabled class="no-close" *ngIf="isMostPopularActive">
                Most popular for <i
                  class="contact-country-flag-icon fi fi-{{selectedSupportedCountry | lowercase}} no-close ms-1"></i>
              </mat-option>
              <mat-option *ngIf="suggestedLocality && sortedMap.length" class="no-close" disabled>
                <div class="d-flex justify-content-center p-2 no-close">
                  <span>Did you mean <b class="suggested-locality">{{suggestedLocality}}</b>?</span>
                </div>
              </mat-option>
              <div *ngIf="sortedMap?.length === 0 && locationsResponse && !isLoading &&!isMostPopularMoreLoading"
                class="no-close matchesnotfound-wrapper">
                <mat-option class="d-flex justify-content-center no-close no-result-text-wrapper" disabled>
                  <h3 class="no-result-title">No exact matches were found</h3>
                  <p class="result-desctiption">You can choose the alternative from the most popular ports
                    list.</p>
                </mat-option>
                <div class="ports-title-wrapper">
                  <h5 class="ports-title">Popular Ports</h5>
                </div>
              </div>
              <mat-option *ngFor="let location of sortedMap$ | async" [value]="location" class="no-close"
                [matTooltip]="getLocationTooltipText(location)">
                <div
                  class="d-flex location-autocomplete-option no-close flex align-items-center justify-content-between"
                  (click)="disableDomesticOption($event, location)">
                  <div class="icon-wrapper no-close">
                    <i class="country-icon location-icon s4d-icon no-close"
                      [ngClass]="location?.locationType | locationTypeS4DIcon"></i>
                  </div>
                  <div class="location-wrapper no-close">
                    <div class="location-content no-close">
                      <span class="location-content-strong no-close">{{location?.cuttedDisplayName}},</span>
                      {{location?.countryName}}
                    </div>
                  </div>
                  <div class="location-box-wrapper no-close">
                    <span *ngIf="location.locationType | locationTypeShortName" class="location-type no-close">
                      {{ location.locationType | locationTypeShortName | uppercase }}
                    </span>
                  </div>
                </div>
              </mat-option>
              <div class="d-flex justify-content-center align-items-center pb-1"
                *ngIf="isMostPopularActive && isMostPopularShowLoadMore">
                <button mat-button s4dButton class="no-close small load-more-button" (click)="onLoadMore()"
                  [class.disabled]="isMostPopularMoreLoading">
                  Load more
                </button>
                <mat-spinner *ngIf="isMostPopularMoreLoading" class="ms-2" diameter="16"></mat-spinner>
              </div>

              <span class="code-not-found-text no-close">
                Cant find what are you looking for? <a (click)="openEmbeddedChat()"> Contact Live Assistance.</a>
              </span>
            </ng-container>
          </mat-autocomplete>
        </div>
        <div [hidden]="!(isSelected && selectedLocation)" class="col-12 pe-0 location-details-wrapper no-close"
          (click)="onClicked($event)">
          <span class="location-details no-close" [matTooltip]="selectedLocation?.fullAddress">
            <ng-container>{{ selectedLocation?.cuttedDisplayName }} ({{ selectedLocation?.unLocode }})
            </ng-container>
          </span>
          <mat-icon class="cross-icon no-close" (click)="removeLocation()">clear</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <div *ngIf="selectedAddressType !== undefined && (selectedAddressType.toString() === addressType.door.toString() ||
    selectedAddressType.toString() === addressType.warehouse.toString())" class="select-door-location no-close">
      <mat-form-field appearance="fill" [floatLabel]="'never'" class="w-100 mt-4"
        [ngClass]="{'missing-address': isMissingAddress}">
        <div class="col-auto no-close">
          <div class="location-icon-wrapper no-close">
            <mat-spinner *ngIf="isLoading" class="location-loader location-icon" diameter="22"></mat-spinner>
            <i *ngIf="!isLoading && !isSelected" class="location-icon s4d-icon s4d-map no-close"></i>
            <i *ngIf="!isLoading && isSelected" [ngClass]="selectedLocation?.locationType | locationTypeS4DIcon"
              class="location-icon s4d-icon no-close"></i>
          </div>
        </div>

        <mat-label class="mat-label-text no-close" [hidden]="isFindingAddressLoader">Business Address</mat-label>
        <div class="col-12 selected-input no-close" [hidden]="(isFindingAddressLoader)">
          <input id="txtGoogleAutocomplete" type="text" matInput [matAutocomplete]="addressAuto"
            [formControl]="doorAddressControl" appAutocompletePosition
            (input)="onAutocompleteAddressChange($event.target.value)" />
          <mat-icon class="cross-icon no-close" [hidden]="!isSelected" (click)="removeLocation()">clear</mat-icon>
          <mat-autocomplete class="business-address-panel-popup no-close" #addressAuto="matAutocomplete"
            [displayWith]="displayAddressFn" panelClass="no-close-popup-panel">
            <mat-option *ngFor="let address of googleAddresses" [value]="address" class="no-close">
              <div class="location-autocomplete-option no-close" (click)="setGoogleLocation(address)">
                <div class="location-wrapper no-close">
                  <div class="location-content d-flex no-close">
                    <span class="location-content-strong no-close"
                      [matTooltip]="address.description">{{address.description}}</span>
                  </div>
                </div>
              </div>
            </mat-option>
            <span class="code-not-found-text no-close">
              City/port code not found. Contact <a (click)="openEmbeddedChat()"> Live Assistance.</a> </span>
          </mat-autocomplete>
        </div>
        <div [hidden]="!isFindingAddressLoader" class="px-0 loader-wrapper no-close">
          <mat-spinner class="location-loader location-icon" diameter="22"></mat-spinner>
          <span class="location-details no-close">Loading address details</span>
        </div>
      </mat-form-field>
      <mat-error *ngIf="isMissingAddress" class="mt-1">
        <i class="s4d-icon s4d-info-light no-close"></i> {{ missingAddressText }}
      </mat-error>
    </div>
  </div>
  <div class="footer-wrapper d-flex justify-content-end pt-3 no-close">
    <button mat-button s4dButton class="small confirm-button"
      [disabled]="!(selectedAddressType && selectedSupportedCountry && selectedLocation)" (click)="onConfirm()">
      Confirm
    </button>
  </div>
</div>