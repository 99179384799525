import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentsService, EnvironmentsServiceConfig, InternalUrlsService } from '@ship4wd/ngx-common';
import { UserService } from '@ship4wd/ngx-authorization';
import {
  CheckDangerousGoodsSupportRequest,
  CheckDangerousGoodsSupportResponse,
  IncreaseUnlocodeSearchCountViewModel,
  PostcodeVerify,
  SearchType,
  ShipmentType,
  TripsSearchBaseViewModel,
  TripsSearchLocationsAddressRequest,
  TripsSearchLocationsRequest,
  TripsSearchViewModelRequest,
  TripsSearchViewModelResponse
} from '../shared.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class TripUnLocodeAutocompleteService {
  private get maxItems(): number {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.tripUnLocodeAutocompleteMaxItems;
      case 'sb':
        return environment.sb.tripUnLocodeAutocompleteMaxItems;
      default:
        return environment.tripUnLocodeAutocompleteMaxItems;
    }
  };
  private tSFromFCLStorageKey = 'tripsSearchsFromFCL';
  private tSToFCLStorageKey = 'tripsSearchsToFCL';
  private tSFromLCLStorageKey = 'tripsSearchsFromLCL';
  private tSToLCLStorageKey = 'tripsSearchsToLCL';
  private tSFromAIRStorageKey = 'tripsSearchsFromAIR';
  private tSToAIRStorageKey = 'tripsSearchsToAIR';

  private environmentName: string;

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient,
    private environmentsService: EnvironmentsService,
    private userService: UserService
  ) {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
  }

  getTripUnLocodes(query: TripsSearchViewModelRequest): Observable<TripsSearchViewModelResponse> {
    return this.http.post<TripsSearchViewModelResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/search`, query);
  }

  getCitiesUnLocodes(query: TripsSearchViewModelRequest): Observable<TripsSearchViewModelResponse> {
    return this.http.post<TripsSearchViewModelResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/searchCity`, query);
  }

  getLocationsUnLocodes(query: TripsSearchViewModelRequest): Observable<TripsSearchViewModelResponse> {
    if (!query.from?.value) {
      query.from = null;
    }
    return this.http.post<TripsSearchViewModelResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/searchLocation`, query);
  }

  getTripSearchLocations(query: TripsSearchLocationsRequest): Observable<TripsSearchViewModelResponse> {
    return this.http.post<TripsSearchViewModelResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/tripSearchLocation`, query);
  }

  getTripSearchLocationByAddressAsync(query: TripsSearchLocationsAddressRequest): Observable<TripsSearchViewModelResponse> {
    return this.http.post<TripsSearchViewModelResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/tripSearchLocationByAddress`, query);
  }

  verifyPostcode(countryCode: string, postcode: string): Observable<PostcodeVerify> {
    return this.http.get<PostcodeVerify>(
      `${this.internalUrlsService.getApiBaseUrl()}/postcodes/postcodeVerify/${countryCode}/${postcode}`);
  }

  increaseUnlocodeSearchCount(increaseUnlocodeSearchCountViewModel: IncreaseUnlocodeSearchCountViewModel): Observable<any> {
    return this.http.post<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/increaseUnlocodeSearchCount`, increaseUnlocodeSearchCountViewModel);
  }

  addSelectedTripsSearchToStorage(item: TripsSearchBaseViewModel, searchType: SearchType, shipmentType: ShipmentType): void {
    const storageKey = this.getUserStorageKey(searchType, shipmentType);
    const dataList = this.getDataFromLocalStorage(storageKey);
    const itemExists = dataList.some((x: TripsSearchBaseViewModel) => this.areObjectsEqual(x, item));

    if (!itemExists) {
      dataList.push(item);

      if (dataList.length > this.maxItems) {
        dataList.splice(0, 1);
      }

      this.setDataToLocalStorage(dataList, storageKey);
    }
  }

  getTripsSearchsFromStorage(searchType: SearchType, shipmentType: ShipmentType): TripsSearchBaseViewModel[] {
    const storageKey = this.getUserStorageKey(searchType, shipmentType);
    return this.getDataFromLocalStorage(storageKey);
  }

  checkDangerousGoodsSupport(shipmentType: ShipmentType, from: string, to: string): Observable<CheckDangerousGoodsSupportResponse> {
    const request = new CheckDangerousGoodsSupportRequest();
    request.shipmentType = shipmentType;
    request.from = from;
    request.to = to;

    return this.http.post<CheckDangerousGoodsSupportResponse>(
      `${this.internalUrlsService.getApiBaseUrl()}/trips/checkDangerousGoodsSupport`, request);
  }

  private getUserStorageKey(searchType: SearchType, shipmentType: ShipmentType) {
    return `${this.getStorageKey(searchType, shipmentType)}-${this.userService.getUser().userId}`;
  }

  private getStorageKey(searchType: SearchType, shipmentType: ShipmentType): string {
    switch (shipmentType) {
      case ShipmentType.FCL:
        return searchType == SearchType.from ? this.tSFromFCLStorageKey : this.tSToFCLStorageKey;
      case ShipmentType.LCL:
        return searchType == SearchType.from ? this.tSFromLCLStorageKey : this.tSToLCLStorageKey;
      case ShipmentType.AIR:
        return searchType == SearchType.from ? this.tSFromAIRStorageKey : this.tSToAIRStorageKey;
      default:
        break;
    }
  }

  private areObjectsEqual(item1: TripsSearchBaseViewModel, item2: TripsSearchBaseViewModel): boolean {
    item1 = this.removePropertyFromObject<TripsSearchBaseViewModel>(item1, "searchId");
    item2 = this.removePropertyFromObject<TripsSearchBaseViewModel>(item2, "searchId");
    return JSON.stringify(item1) == JSON.stringify(item2);
  }

  private getDataFromLocalStorage(storageKey: string): TripsSearchBaseViewModel[] {
    const dataString = localStorage.getItem(storageKey);
    return dataString ? JSON.parse(dataString) : [];
  }

  private setDataToLocalStorage(data: TripsSearchBaseViewModel[], storageKey: string): void {
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  private clearLocalStorage(storageKey): void {
    localStorage.removeItem(storageKey);
  }

  private removePropertyFromObject<T>(model: T, propertyName: string): T {
    if (model?.[propertyName]) {
      let copyObj = { ...model };
      delete copyObj[propertyName];
      return copyObj;
    }
    return model;
  }
}
