import { Pipe, PipeTransform } from '@angular/core';
import { LocationType, SearchType } from '../shared.model';

@Pipe({ name: 'locationTypeGroupName' })
export class LocationTypeGroupNamePipe implements PipeTransform {
  private locationTypeGroupNames = {};

  constructor() {
    this.locationTypeGroupNames[LocationType.townCity] = 'Pickup at origin';
    this.locationTypeGroupNames[LocationType.airPort] = 'Airport';
    this.locationTypeGroupNames[LocationType.seaPort] = 'SeaPort';
    this.locationTypeGroupNames[LocationType.railwayTerminal] = 'Inland Port';
    this.locationTypeGroupNames[LocationType.containerTerminal] = 'Port';
    this.locationTypeGroupNames[LocationType.inlandPort] = 'SeaPort';
    this.locationTypeGroupNames[LocationType.recentOrigins] = 'Recent Origins';
    this.locationTypeGroupNames[LocationType.recentDestinations] = 'Recent Destinations';
  }

  transform(value?: LocationType, searchType?: SearchType): string {
    if(searchType === SearchType.to && value === LocationType.townCity) {
        return 'Delivery to door'
    }
    return this.locationTypeGroupNames[value] ?? 'Other';
  }
}