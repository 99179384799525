<div class="row g-0 m-0 gap-1">
  <div class="col d-flex align-items-center justify-content-start">
    <span class="total-title">Freight Charges</span>
  </div>
  <div class="col d-flex align-items-end justify-content-end">
    <span appSetRoleBasedPrice class="estimated-title"
      [ngClass]="{'include-discount-price': isShowReverseDiscountPrice()}">
      {{ quote.currency | currencyTextIcon }}{{ getFreightCharges ? (getFreightCharges | number: "1.0-0") :
      '-.-- ' + (quote.currency | currencyName) }}
    </span>
    <span appSetRoleBasedPrice class="estimated-subtitle discount-price" *ngIf="isShowReverseDiscountPrice()">
      {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice() | number: "1.0-0" }}
    </span>
  </div>
  <div class="col-12 d-flex flex-column align-items-end justify-content-center">
    <div class="d-flex">
      <span class="estimated-subtitle" *ngIf="getLocalCharges" matTooltipPosition="below"
        matTooltipClass="text-center">+ Local Charges {{ quote.currency |
        currencyTextIcon }}{{ getLocalCharges | number: "1.0-0" }}</span>
    </div>
    <div class="d-flex" *ngIf="getDangerousGoodsSurcharges">
      <span class="estimated-subtitle" matTooltipPosition="below" matTooltipClass="text-center">+ Dangerous Goods<br>
        Charges: {{ quote.currency |
        currencyTextIcon }}{{ getDangerousGoodsSurcharges | number: "1.0-0" }}</span>
    </div>
    <div class="d-flex" *ngIf="!getLocalCharges && !getDangerousGoodsSurcharges">
      <span class="estimated-subtitle">Fees Included</span>
    </div>
    <div class="d-flex mt-1" *ngIf="isPartialQuote">
      <span class="estimated-subtitle d-flex" [matTooltip]="getPartialQuoteTooltipText" matTooltipPosition="below"
        matTooltipClass="text-center">+
        Door Service Costs</span>
    </div>
  </div>
</div>