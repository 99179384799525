import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { User, UserService } from '@ship4wd/ngx-authorization';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss']
})
export class EmptyLayoutComponent implements OnInit {
  public user: User;

  constructor(
    public location: Location,
    private router: Router,
    private userService: UserService) { }

  get chatExists(): boolean {
    const chat = document.getElementById('embedded-messaging');

    return chat ? true : false;
  }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    if (!this.user.isAuthenticated) {
      this.navigateToLogin();
    }
  }

  private navigateToLogin(): void {
    this.router.navigate(['/login']);
  }
}
