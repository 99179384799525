import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, expand, reduce, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subscription, of, throwError } from 'rxjs';
import { NIL as NIL_UUID, v4 } from 'uuid';
import { NotificationService } from '@ship4wd/ngx-common';
import { CurrencyCode, LoadUnit, LocationType, Page, ShipmentType, ShipperType } from '../../../../shared/shared.model';
import { ExtenderTripLegBase, Incoterm, IncotermsMappingsQuery, Quote, QuotesQuery } from '../../quotes.model';
import { SupportedCountry } from '../../../../shared/supported-countries/supported-countries.model';
import { PreviousRouteService } from '../../../../shared/helper/previous-route.service';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import { QuotesService } from '../../quotes.service';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { QuotesIncotermService } from '../../quotes-incoterm.service';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { LayoutService } from '../../../../desktop/layout/layout.service';
import { environment } from '../../../../../environments/environment';
import { UtilityService } from '../../../../shared/helper/utility.service';

@Component({
  selector: 'app-quote-search-flow-old',
  templateUrl: './quote-search-flow-old.component.html'
})
export class QuoteSearchFlowOldComponent implements OnInit {
  isShowSearchSection = true;
  shippingTypes: any[] = [
    {
      name: 'FCL',
      description: 'Full-Container Load (FCL)',
      value: ShipmentType.FCL,
      icon: 'fcl'
    },
    {
      name: 'LCL',
      description: 'Less-Than-Container Load (LCL)',
      value: ShipmentType.LCL,
      icon: 'lcl'
    },
    {
      name: 'Air',
      description: 'AIR',
      value: ShipmentType.AIR,
      icon: 'ac'
    }
  ];
  quoteSearchForm: FormGroup;
  quotes: Quote[] = null;
  fromLocationJson: string = null;
  searchId = '';
  result: boolean = false;

  quotesQuery: QuotesQuery = undefined;
  from = '';
  to = '';
  shipmentype: ShipmentType = null;
  reusedBookingId = '';
  reusedQuoteId = '';
  reusedQuote: any = null;
  isListLoading: boolean;
  stopSearchQuote: boolean;
  showD2dAndAir: boolean;
  supportedCountries: SupportedCountry[];
  incoterms: Incoterm[] = null;

  isReuse: boolean = false;
  isStarted: boolean = false;
  isLoading: boolean = true;
  isFilled: boolean = false;
  isFirstSearch: boolean = true;
  noQuotesFound: boolean = false;
  quoteSearchId: string = null;
  searchQuotesSubscription: Subscription;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private previousRouteService: PreviousRouteService,
    private notificationService: NotificationService,
    private settingsService: AppSettingsService,
    private quotesService: QuotesService,
    private bookingsService: BookingsService,
    private quotesIncotermService: QuotesIncotermService,
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public router: Router,
    private utilityService: UtilityService
  ) {
    this.reusedBookingId = this.route.snapshot.queryParams.reusedBookingId;
    this.reusedQuoteId = this.route.snapshot.queryParams.reusedQuoteId;
    this.getWidgetType();
  }

  ngOnInit(): void {
    this.layoutService.setToolbarQuoteResultsPage(false);

    this.createQuoteSearchForm();

    if (this.reusedBookingId !== undefined && this.reusedBookingId !== '') {
      this.getReusedBooking();
    } else if (this.reusedQuoteId !== undefined && this.reusedQuoteId !== '') {
      this.getReusedQuote();
    } else {
      this.isLoading = false;
    }

    this.fillQuoteSearchParamsIfReturningFromBooking();
    if (this.isReturningFromBooking() && this.quotesQuery) {
      this.onSearchQuotes(this.quotesQuery);
    }
  }

  shipmentTypeChange(shipmentType: ShipmentType): void {
    if (shipmentType !== undefined && shipmentType !== null) {
      this.quoteSearchForm.get('shipmentType').setValue(shipmentType);
    }
  }

  onSearchQuotes(query: QuotesQuery): void {
    if (this.searchQuotesSubscription) {
      this.isLoading = false;
      this.isListLoading = false;
      this.isStarted = false;
      this.searchQuotesSubscription.unsubscribe();
      this.stopSearchQuote = true;
      this.quotesService.unsubscribeSearchQuote$.next();
      this.quotesService.unsubscribeSearchQuote$.complete();
    }

    this.isStarted = true;
    this.isLoading = true;
    this.quotesQuery = query;
    this.from = query.from.displayName;
    this.to = query.to.displayName;
    this.quotesQuery.organizationId = this.settingsService.getSettings().organizationId;
    this.reusedQuote = this.reusedQuote;

    this.googleAnalyticsService.searchQuotes(this.quotesQuery, this.isFirstSearch);
    this.isFirstSearch = false;
    this.reloadQuotes();

    this.quotesService.saveQuotesQueryInStorage(query);
  }

  onChangeShipperType(value: ShipperType): void {
    this.stopSearchQuote = true;
    this.quotesService.unsubscribeSearchQuote$.next();
    this.quotesService.unsubscribeSearchQuote$.complete();
    this.quoteSearchForm.get('shipperType').setValue(value);
    this.quotesQuery.shipperType = value;
    this.quotesQuery.searchId = v4();
    this.getIncoterms().subscribe(incoterm => {
      const id = incoterm?.pop()?.id;
      if (id) {
        this.quotesQuery.incoterms = [id];
      }
      this.onSearchQuotes(this.quotesQuery);
    });
  }

  private createQuoteSearchForm(): void {
    this.quoteSearchForm = this.fb.group({
      vendorId: [],
      shipmentType: [this.shipmentype, Validators.required],
      shipperType: [ShipperType.importer, Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      fromcities: ['', Validators.required],
      tocities: ['', Validators.required],
      postcodesTo: ['', Validators.required],
      postcodesFrom: ['', Validators.required],
      currency: [CurrencyCode.USD],
      fromDate: [new Date()],
      containerCommodities: this.fb.array([]),
      equipments: this.fb.array([]),
      incoterms: [null, Validators.required],
      isHazardous: [false],
      reusedFromBookingId: [null]
    });
  }

  private fillQuoteSearchForm(quote: any): any {
    this.quoteSearchForm.get('reusedFromBookingId').setValue(this.reusedQuote.reusedFromBookingId);
    this.quoteSearchForm.get('vendorId').setValue(quote.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quote.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quote.shipperType);
    this.quoteSearchForm.get('from').setValue(quote.from);
    this.quoteSearchForm.get('to').setValue(quote.to);
    this.quoteSearchForm.get('fromcities').setValue(quote.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quote.to.displayName);
    this.quoteSearchForm.get('postcodesTo').setValue(quote.to.postcode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quote.from.postcode || null);
    this.quoteSearchForm.get('currency').setValue(CurrencyCode.USD);
    this.quoteSearchForm.get('fromDate').setValue(quote.fromDate);

    this.quoteSearchForm.get('incoterms').setValue(quote.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quote.isHazardous);

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quote?.commodities.forEach(commodity => {
      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: commodity.dimension?.dimensionUnit,
            height: commodity.dimension?.height,
            length: commodity.dimension?.length,
            width: commodity.dimension?.width,
            dimensionValue: `${commodity.dimension?.length}x${commodity.dimension?.width}x${commodity.dimension?.height}`,
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.packageWeight
              ? commodity.packageWeight * commodity.numberOfPackages
              : commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.packageWeight ?? commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: commodity.packageVolume,
            totalDimensionValueAmount: null,
            totalVolumeAmount: null,
            totalWeightAmount: null,
            isShowMaskedInput: true,
            chargeableWeightAmount: null
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quote?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();
    this.sendQuoteData(quote);
  }

  private getReusedQuote(): void {
    this.quotesService
      .getReuseQuote(this.reusedQuoteId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => {
          this.notificationService.error(error);
        }
      )
      .add(() => (this.isLoading = false));
  }

  private getReusedBooking(): void {
    this.bookingsService
      .getReuseBooking(this.reusedBookingId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => { }
      )
      .add(() => (this.isLoading = false));
  }

  private getIncoterms(): Observable<any> {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      return this.quotesIncotermService.getIncotremsByLocationTypes(query).pipe(
        tap((x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        }),
        catchError(error => {
          this.notificationService.error(error);
          return throwError(error);
        })
      );
    } else {
      this.incoterms = null;
      return of(null);
    }
  }

  private reloadQuotes(): void {
    this.quotesQuery.pageNo = 1;
    this.quotesQuery.pageSize = environment.quotesQueryPageSize;
    this.isListLoading = true;
    this.quotes = [];
    this.stopSearchQuote = false;

    if (
      this.quotesQuery.shipmentType != ShipmentType.AIR &&
      this.quotesQuery.from.locationType === LocationType.townCity &&
      this.quotesQuery.to.locationType === LocationType.townCity
    ) {
      this.showD2dAndAir = false;
    }

    this.isLoading = true;
    this.noQuotesFound = false;

    this.layoutService.setToolbarQuoteResultsPage(false);

    var storedQuoteResult = this.quotesService.getQuotesResultsFromStorage(this.quotesQuery);

    if (!storedQuoteResult) {
      this.searchQuotesSubscription = this.getQuotes()
        .subscribe()
        .add(() => {
          this.isLoading = false;
          this.isListLoading = false;
        });
    } else {
      this.quotes = [...this.quotes, ...storedQuoteResult.quotes];
      this.isLoading = false;
      this.isListLoading = false;
    }
  }

  private getQuotes(): Observable<any[] | Page<Quote>> {
    return this.quotesService.getQuotesByQuery(this.quotesQuery).pipe(
      expand(x => (this.hasMoreQuotesToLoad(x) ? this.loadMoreQuotes(x.pageNo) : this.stopLoadQuotes())),
      tap(
        x => {
          if (this.hasValidItem(x.items)) {
            this.quotes = [...this.quotes, ...x.items];
            this.quotesService.saveQuotesResultsInStorage(this.quotes, this.quotesQuery);
          }
          if (this.isFirstRequest()) {
            if (this.hasValidItem(this.quotes)) {
              this.quotes.forEach(x => (x.firstSearch = true));
              const quote = this.quotes[0];
              this.quoteSearchId = this.getQuoteSearchId();
              if (quote.trip !== null) {
                this.isShowSearchSection = false;
              } else {
                this.noQuotesFound = true;
                this.quoteSearchId = this.getQuoteSearchId();
                this.reusedQuote = null;
              }

              this.isFilled = true;
            } else {
              this.noQuotesFound = true;
              this.quoteSearchId = this.getQuoteSearchId();
              this.reusedQuote = null;
            }

            const quoteResultsPageIncludeResults = !this.noQuotesFound && this.isStarted;
            this.layoutService.setToolbarQuoteResultsPage(quoteResultsPageIncludeResults);
          } else if (this.quotes.some(quote => !quote.firstSearch) || this.quotes.every(quote => quote.firstSearch)) {
            this.quotes = this.filterCreditQuotes(this.quotes);
            this.quotes = this.filterPartialQuotes(this.quotes);
            this.isLoading = false;
          }
        },
        error => (this.isFirstRequest() ? this.notificationService.error(error) : null)
      ),
      reduce((acc, val) => {
        acc = [...acc, ...val?.items];
        return acc;
      }, [])
    );
  }

  private hasMoreQuotesToLoad(response: Page<Quote>): boolean {
    if (this.stopSearchQuote || response.totalCount == 0 || response.items == null) return false;

    if (this.hasValidItem(response.items)) {
      return true;
    }

    return false;
  }

  private loadMoreQuotes(responsePageNo: number): Observable<Page<Quote>> {
    this.quotesQuery.pageNo = responsePageNo + 1;
    return this.quotesService.getQuotesByQuery(this.quotesQuery);
  }

  private stopLoadQuotes(): Observable<never> {
    this.updateGoogleAnalyticsQuoteResults();

    this.isFirstSearch = false;
    this.isListLoading = false;
    return EMPTY;
  }

  private hasValidItem(items: Quote[] | null): boolean {
    return items != null && items.length > 0 && items[0].id !== NIL_UUID;
  }

  private isFirstRequest(): boolean {
    return this.quotesQuery.pageNo == 1;
  }

  private fillQuoteSearchParamsIfReturningFromBooking(): void {
    if (this.isReturningFromBooking()) {
      const quotesQuery = this.quotesService.getQuotesQueryFromStorage();
      if (quotesQuery) {
        this.quotesQuery = quotesQuery;
        this.fillQuoteSearchFormFromQuotesQuery(quotesQuery);
        this.isReuse = true;
      }
    } else {
      this.quotesService.removeQuotesQueryFromStorage();
    }
  }

  private fillQuoteSearchFormFromQuotesQuery(quoteQuery: QuotesQuery): void {
    this.quoteSearchForm.get('vendorId').setValue(quoteQuery.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quoteQuery.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quoteQuery.shipperType);
    this.quoteSearchForm.get('from').setValue(quoteQuery.from);
    this.quoteSearchForm.get('to').setValue(quoteQuery.to);
    this.quoteSearchForm.get('fromcities').setValue(quoteQuery.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quoteQuery.to.displayName);
    this.quoteSearchForm.get('currency').setValue(quoteQuery.currency);
    this.quoteSearchForm.get('fromDate').setValue(quoteQuery.fromDate);
    this.quoteSearchForm.get('incoterms').setValue(quoteQuery.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quoteQuery.isHazardous);
    this.quoteSearchForm.get('postcodesTo').setValue(quoteQuery.to.zipCode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quoteQuery.from.zipCode || null);

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quoteQuery?.containerCommodities.forEach(commodity => {
      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: commodity.dimensionUnit,
            height: commodity.height,
            length: commodity.length,
            width: commodity.width,
            dimensionValue: `${commodity.length}x${commodity.width}x${commodity.height}`,
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: null,
            totalDimensionValueAmount: null,
            totalVolumeAmount: null,
            totalWeightAmount: null,
            isShowMaskedInput: true,
            chargeableWeightAmount: null
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quoteQuery?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();

    setTimeout(() => {
      this.sendQuoteData(quoteQuery);
    }, 100);
  }

  private getQuoteSearchId(): string {
    return this.quotes && this.quotes[0]?.quoteSearchId ? this.quotes[0].quoteSearchId : this.quotesQuery.searchId;
  }

  private filterCreditQuotes(quotes: Quote[]): Quote[] {
    const hasContractRate = (tripLegBase: ExtenderTripLegBase | undefined): boolean => {
      return (
        tripLegBase?.legs?.some(leg =>
          leg.rates?.some(rate => rate.freightRate?.tariff?.toLowerCase().includes('contract'))
        ) ?? false
      );
    };

    const hasContractTariff = quotes.some(
      quote =>
        hasContractRate(quote.trip.preCarriageTripLeg) ||
        hasContractRate(quote.trip.mainCarriageTripLeg) ||
        hasContractRate(quote.trip.postCarriageTripLeg)
    );

    if (hasContractTariff) {
      return quotes.filter(
        quote =>
          hasContractRate(quote.trip.preCarriageTripLeg) ||
          hasContractRate(quote.trip.mainCarriageTripLeg) ||
          hasContractRate(quote.trip.postCarriageTripLeg)
      );
    }

    return quotes;
  }

  private updateGoogleAnalyticsQuoteResults(): void {
    let partialSchedule = 'Not Found';
    let partialDoor = 'Not Found';
    if (this.quotes?.length > 0) {
      partialSchedule = this.isQuotesResultsHasSchedule() ? 'Found' : 'Not Found';
      partialDoor = this.isPartialPriceQuotesResults() ? 'Partial' : 'Found';
    }
    this.googleAnalyticsService.quotesResults(partialSchedule, partialDoor, this.quotes?.length ?? 0);
  }

  private isPartialPriceQuotesResults(): boolean {
    return this.quotes.some(quote => quote.trip.isPreCarriageMissingRate || quote.trip.isPostCarriageMissingRate);
  }

  private isQuotesResultsHasSchedule(): boolean {
    return this.quotes.some(
      quote => this.utilityService.isNotNullOrMinDateValue(quote.departureDate) && this.utilityService.isNotNullOrMinDateValue(quote.arrivalDate)
    );
  }

  private sendQuoteData(quote: any): void {
    this.quotesService.sendQuoteData.next(quote);
  }

  private isReturningFromBooking(): boolean {
    const previousUrl = this.previousRouteService.getPreviousUrl();
    if (previousUrl && previousUrl?.includes('booking')) return true;

    return false;
  }

  private filterPartialQuotes(quotes: Quote[]): Quote[] {
    const hasFullRate = quotes.some(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate);

    return hasFullRate
      ? quotes.filter(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate)
      : quotes;
  }

  private getWidgetType(): void {
    const widget = this.route.snapshot.queryParams.widget;

    if (widget) {
      const widgetType = Number.parseInt(widget, 10) as ShipmentType;
      if (!Number.isNaN(widgetType)) {
        this.shipmentype = widgetType;
      }
    }
  }
}
