import { NgModule, ErrorHandler } from "@angular/core";
import { SharedModule } from './shared/shared.module';
import { AppSettingsService } from "./shared/services/app-settings/app-settings.service";
import { CoreModule } from './core/core.module';
import { MobileModule } from './mobile/mobile.module';
import { MobileRoutingModule } from "./mobile/mobile-routing.module";
import { DesktopModule } from './desktop/desktop.module';
import { DesktopRoutingModule } from "./desktop/desktop-routing.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserInfoService } from './shared/services/user-info/user-info.service';
import { ClarityService } from "./shared/services/clarity.service";
import { GoogleAnalyticsService } from './shared/google-analytics/google-analytics.service';
import { NgxMaskModule } from 'ngx-mask';
import { GooglePlaceService } from "./shared/services/google-place.service";
import { HubspotService } from "./shared/services/hubspot.service";
import { ErrorHandlerService } from './shared/services/errorHandler.service';
import { MonitoringService } from './shared/services/monitoring.service';
import { SalesforceService } from "./shared/services/salesforce.service";
import { WhatsAppService } from "./shared/whatsapp-button/whatsapp.service";
import { SmartlookService } from "./shared/services/smartlook.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
  ],
  imports: [
    SharedModule.forRoot(),
    CoreModule,
    MobileModule,
    MobileRoutingModule,
    DesktopModule,
    DesktopRoutingModule,
    AppRoutingModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
  ],
  providers: [
    AppSettingsService,
    UserInfoService,
    ClarityService,
    SmartlookService,
    GoogleAnalyticsService,
    GooglePlaceService,
    HubspotService,
    SalesforceService,
    MonitoringService,
    WhatsAppService,
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
