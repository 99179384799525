import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'topBorderClass' })
export class TopBorderClassPipe implements PipeTransform {
  constructor() {}

  transform(value?: HTMLElement): string {
    var parentSize = value.parentElement.offsetHeight;
    const top = value.offsetTop;

    const minR = 0;
    const maxR = minR + (parentSize - 168);
    const minL = 168;
    const maxL = minL + (parentSize - 168);

    let classes = '';

    if(top > minL && top < maxL) classes += 'left-purple ';
    if(top > minR && top < maxR) classes += 'right-purple';

    return classes;
  }
}
