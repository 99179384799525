import { ShipmentType } from "../shared.model"

export enum LegalNamePopupFields {
	legalName = 'Company name',
	taxId = 'Tax id'
}

export const LegalNamePopupErrorMessages = {
	[LegalNamePopupFields.legalName]: {
		'required': "Please, enter your organization's legal name.",
		'incorrect': "Legal name invalid."
	},
	[LegalNamePopupFields.taxId]: {
		'required': "Please, enter your organization's Tax ID.",
		'incorrect': "Tax ID invalid."
	}
}

export class ErrorFunnelBooking {
	actionName: string;
	errorValue: string;
	fieldName: string;
	shipmentType: ShipmentType
}