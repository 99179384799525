<div class="no-quotes-main-wrapper" *ngIf="quotesQuery">
  <div class="no-quotes-container">
    <div class="no-quotes-icon-wrapper">
      <span class="no-quotes-icon"></span>
    </div>
    <div class="no-quotes-text-wrapper">
      <span class="no-quotes-text">Your Quotes is on its way</span>
    </div>
    <div class="no-quotes-content">
      <div class="no-quotes-message-wrapper">
        <span class="no-quotes-message">
          Our Customer service is already informed of your search and will
          follow up in an email and send you a quote within 24 hours
        </span>
      </div>
      <div class="no-quotes-summary-wrapper">
        <div class="no-quotes-summary-content">
          <span class="no-quotes-summary-title">Summary</span>
          <div class="no-quotes-summary-details-wrapper">
            <div class="no-quotes-summary-left-text">
              <span class="no-quotes-summary-left-text-title">Type</span>
            </div>
            <div class="no-quotes-summary-right-text">
              <span class="icon-box">
                <app-quote-search-icons [iconName]="quotesQuery.shipmentType === shipmentType.LCL ? 'shipping-type-lcl' :
                quotesQuery.shipmentType === shipmentType.FCL ? 'shipping-type-fcl' : 'shipping-type-ac'">
                </app-quote-search-icons>
              </span>
              <h6 class="no-quotes-summary-right-text-value">
                {{ quotesQuery.shipmentType | shipmentTypeName }}
              </h6>
            </div>
          </div>
          <div class="no-quotes-summary-details-wrapper">
            <div class="no-quotes-summary-left-text">
              <span class="no-quotes-summary-left-text-title">From</span>
            </div>
            <div class="no-quotes-summary-right-text">
              <span class="icon-box">
                <app-quote-search-icons [iconName]="quotesQuery.from?.locationType | locationTypeIcon">
                </app-quote-search-icons>
              </span>
              <h6 class="no-quotes-summary-right-text-value">
                {{ renderFromToData(quotesQuery.from, searchType.from) }}
              </h6>
            </div>
          </div>
          <div class="no-quotes-summary-details-wrapper">
            <div class="no-quotes-summary-left-text">
              <span class="no-quotes-summary-left-text-title">To</span>
            </div>
            <div class="no-quotes-summary-right-text">
              <span class="icon-box">
                <app-quote-search-icons [iconName]="quotesQuery.to?.locationType | locationTypeIcon">
                </app-quote-search-icons>
              </span>
              <h6 class="no-quotes-summary-right-text-value">
                {{ renderFromToData(quotesQuery.to, searchType.to) }}
              </h6>
            </div>
          </div>
          <div class="no-quotes-summary-details-wrapper">
            <div class="no-quotes-summary-left-text">
              <span class="no-quotes-summary-left-text-title">When</span>
            </div>
            <div class="no-quotes-summary-right-text">
              <h6 class="no-quotes-summary-right-text-value from-date-value">
                {{ quotesQuery.fromDate | date: "MMM dd, yyyy" }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>