import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { OverlayModule } from '@angular/cdk/overlay';

import { CookieService } from 'ngx-cookie-service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '@ship4wd/ngx-authorization';
import { SharedMaterialModule } from './shared-material.module';

import { HttpService } from './services/http/http.service';
import { StringCutterService } from './services/text-cutter/stringCutter.service';
import { FormatDateService } from './services/format-date/format-date.service';
import { LanguageService } from './features/language/language.service';

import { ShipmentTypeCargoNamePipe } from './pipes/shipment-type-name.pipe';
import { TripUnLocodeAutocompleteComponent } from './trip-un-locode-autocomplete/trip-un-locode-autocomplete.component';
import { TripUnLocodeAutocompleteService } from './trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { CurrencyIconPipe } from './pipes/currency-icon.pipe';
import { HaulageTypeWordPipe } from './pipes/haulage-type-word.pipe';
import { ApplicableTypePipe } from './pipes/applicable-type.pipe';
import { LocationTypeShortNamePipe } from './pipes/location-type-short-name.pipe';
import { LocationTypeIconPipe } from './pipes/location-type-icon.pipe';
import { HaulageIconTypePipe } from './pipes/haulage-icon-type.pipe';
import { MainCarriageHaulageIconType } from './pipes/mainCarriage-haulage-icon-type.pipe';
import { RateServiceTypeNamePipe } from './pipes/rate-service-type-name.pipe';
import { RateServiceTypeBackgroundPipe } from './bookings/rate-service-type-background.pipe';
import { QuoteSearchIconsComponent } from './quote-search-icons/quote-search-icons.component';
import { BookingsService } from './bookings/bookings.service';
import { BookingContactsService } from './bookings/booking-contacts/booking-contacts.service';
import { DurationPipe } from './features/duration-pipe/duration.pipe';
import { LinearUnitNamePipe } from './features/linear-units/linear-unit-name.pipe';
import { WeightUnitNamePipe } from './pipes/weight-unit-name.pipe';
import { VolumeUnitNamePipe } from './pipes/volume-unit-name.pipe';
import { DimensionUnitNamePipe } from './pipes/dimension-unit-name.pipe';
import { PackageTypeNamePipe } from './pipes/package-type-name.pipe';
import { BookingDocumentsService } from './bookings/booking-documents/booking-documents.service';
import { PackageTypeService } from './package-types/package-type.service';
import { PackageTypeAutocompleteComponent } from './package-types/package-type-autocomplete/package-type-autocomplete.component';
import { CommodityService } from './services/commodity.service';
import { CommodityAutocompleteComponent } from './commodity-autocomplete/commodity-autocomplete.component';
import { IsShowTooltipDirective } from './directives/is-show-tooltip.directive';
import { BookingOrganizationContactsService } from './bookings/booking-organization-contacts/booking-organization-contacts.service';
import { AccountsService } from './accounts/accounts.service';
import { CountryAutocompleteComponent } from './countries/country-autocomplete/country-autocomplete.component';
import { CountryService } from './countries/country.service';
import { KycStatusCodeNamePipe } from './pipes/kyc-status-code-name.pipe';
import { AccessRoleTypeNamePipe } from './pipes/access-role-type-name.pipe';
import { TeamMemberStatusNamePipe } from './pipes/team-member-status-name.pipe';
import { FilesDocumentsComponent } from './files-documents/files-documents.component';
import { Ship4wdNgxManagerUIModule } from '@ship4wd/ngx-manager-ui';
import { FilesDocumentsService } from './files-documents/files-documents.service';
import { FreightRatePipe } from './pipes/quotes/freight-rate.pipe';
import { UniqueApplicableTypePipe } from './pipes/quotes/unique-applicable-type.pipe';
import { TotalFreightRatePipe } from './pipes/quotes/total-freight-rate.pipe';
import { TotalAdditionalTypeRatePipe } from './pipes/quotes/total-additional-type-rate.pipe';
import { AdditionalTypeSurchargePipe } from './pipes/quotes/additional-type-surcharge.pipe';
import { SurchargesByApplicableTypePipe } from './pipes/quotes/surcharges-by-applicable-type.pipe';
import { CheckPricePerPipe } from './pipes/quotes/check-price-per.pipe';
import { CheckSurchargesPipe } from './pipes/quotes/check-surcharges.pipe';
import { CheckFreightRatesPipe } from './pipes/quotes/check-freight-rates.pipe';
import { TopBorderClassPipe } from './pipes/top-border-class.pipe';
import { TripUnLocodeAutocompletePostcodeDialogComponent } from './trip-un-locode-autocomplete/trip-un-locode-autocomplete-postcode-dialog/trip-un-locode-autocomplete-postcode-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { BookingShippingInstructionsService } from './booking-shipping-instructions/booking-shipping-instructions.service';
import { FilesService } from './services/files.service';
import { CheckoutService } from './checkouts/checkout.service';
import { FourtySeasPaymentService } from './payments/fourty-seas-payment.service';
import { PaymentSentComponent } from './payments/payment-sent/payment-sent.component';
import { PaymentsService } from './payments/payments.service';
import { RequestAndPaymentService } from './requests-and-payments/requests-and-payments.service';
import { BookingPaidStatusNamePipe } from './pipes/booking-paid-status-name.pipe';
import { PaymentSuccessfulComponent } from './payments/payment-successful/payment-successful.component';
import { PaymentOfflineComponent } from './payments/payment-offline/payment-offline.component';
import { PaymentBankDetailsComponent } from './payments/payment-bank-details/payment-bank-details.component';
import { CurrencyNamePipe } from './pipes/currency-name.pipe';
import { LegalNameDialogComponent } from './legal-name-dialog/legal-name-dialog.component';
import { SupportedCountriesService } from './supported-countries/supported-countries.service';
import { CityService } from './cities/city.service';
import { CompanySearchService } from './company-search/company-search.service';
import { PaymentPayLaterComponent } from './payments/paymnet-pay-later/payment-pay-later.component';
import { LoaderWithImagesComponent } from './loader-with-images/loader-with-images.component';
import { DashboardService } from './widgets/dashboard.service';
import { ShipmentTypeNamePipe } from './bookings/shipment-type-name.pipe';
import { SwiperDirective } from './directives/s4d-swiper.directive';
import { QuoteSearchTripUnLocodeAutocompleteComponent } from './quote-search-trip-un-locode-autocomplete/quote-search-trip-un-locode-autocomplete.component';
import { LocationTypeS4DIconPipe } from './pipes/location-type-s4d-icon.pipe';
import { LocationTypeGroupNamePipe } from './pipes/location-type-group-name.pipe';
import { HaulageTypeS4DIconPipe } from './pipes/haulage-type-s4d-icon.pipe';
import { CurrencyTextIconPipe } from './pipes/currency-text-icon.pipe';
import { DimensionsInputComponent } from './dimensions-input/dimensions-input.component';
import { PreviousRouteService } from './helper/previous-route.service';
import { PackageTypeInputComponent } from './package-type-input/package-type-input.component';
import { PreventNonNumericDirective } from './directives/prevent-non-numeric.directive';
import { PersonalNotificationDotComponent } from './personal-notifications/personal-notification-dot/personal-notification-dot.component';
import { ErrorMessagePipe } from './pipes/error-message.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ApplicationNotificationService } from './application-notification/application-notification.service';
import { ApplicationNotificationComponent } from './application-notification/application-notification.component';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { CustomNumberFormatPipe } from './pipes/custom-number.pipe';
import { SetRoleBasedPriceDirective } from './directives/set-role-based-price.directive';
import { AddressTypeS4dIconPipe } from './pipes/address-type-s4d-icon.pipe';
import { AddressTypeShortNamePipe } from './pipes/address-type-short-name.pipe';
import { AmplitudeService } from './amplitude/amplitude.service';
import { RoleBasedHideOrShowDirective } from './directives/role-based-hide-or-show.directive';
import { WhatsAppButtonComponent } from './whatsapp-button/whatsapp-button.component';
import { FourtySeasInitDialogService } from './payments/fourty-seas-init-dialog.service';
import { FourtySeasInitDialogComponent } from './payments/fourty-seas-init-dialog/fourty-seas-init-dialog.component';
import { RemoveConfirmDialogComponent } from './remove-confirm-dialog/remove-confirm-dialog.component';
import { S4DPlacesDirective } from './directives/s4d-places.directive';
import { AutocompletePositionDirective } from './directives/autocomplete-position.directive';
import { CommodityInputUngroupedComponent } from './commodity-input-ungrouped/commodity-input-ungrouped.component';
import { TaxIdService } from './tax-id/tax-id-input.service';
import { TaxIdInputComponent } from './tax-id/tax-id-input/tax-id-input.component';
import { LegalNameRollupDialogComponent } from './legal-name-rollup-dialog/legal-name-rollup-dialog.component';
import { CompanySearchInputComponent } from './company-search/company-search.component';
import { TrackInputErrorsDirective } from './directives/track-input-errors';
import { AppcuesService } from './appcues/appcues.service';
import { EnvironmentService } from './services/environment.service';
import { PaymentStatusComponent } from './payments/payment-status/payment-status.component';
import { PriceHistoryChartComponent } from './price-history-chart/price-history-chart.component';
import { PriceHistoryChartService } from './price-history-chart/price-history-chart.service';
import { ActivitiesService } from './activities/activities.service';
import { DangerousGoodsTypeNamePipe } from './pipes/dangerous-goods-name.pipe';
import { InfoBoxComponent } from './info-box/info-box.component';
import { HeapService } from './heap/heap.service';
import { S4DTooltipComponent } from './s4d-tooltip/s4d-tooltip.component';
import { S4DTooltipDirective } from './s4d-tooltip/s4d-tooltip.directive';

@NgModule({
  imports: [
    Ship4wdNgxManagerUIModule.forRoot(),
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CdkStepperModule,
    NgScrollbarModule,
    TranslateModule,
    SharedMaterialModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    ShipmentTypeNamePipe,
    ShipmentTypeCargoNamePipe,
    TripUnLocodeAutocompleteComponent,
    CurrencyIconPipe,
    CurrencyNamePipe,
    HaulageTypeWordPipe,
    ApplicableTypePipe,
    LocationTypeShortNamePipe,
    LocationTypeIconPipe,
    HaulageIconTypePipe,
    MainCarriageHaulageIconType,
    RateServiceTypeNamePipe,
    RateServiceTypeBackgroundPipe,
    DurationPipe,
    QuoteSearchIconsComponent,
    LinearUnitNamePipe,
    WeightUnitNamePipe,
    VolumeUnitNamePipe,
    DimensionUnitNamePipe,
    PackageTypeNamePipe,
    PackageTypeAutocompleteComponent,
    CountryAutocompleteComponent,
    CommodityAutocompleteComponent,
    KycStatusCodeNamePipe,
    IsShowTooltipDirective,
    AccessRoleTypeNamePipe,
    TeamMemberStatusNamePipe,
    FilesDocumentsComponent,
    FreightRatePipe,
    UniqueApplicableTypePipe,
    TotalFreightRatePipe,
    TotalAdditionalTypeRatePipe,
    AdditionalTypeSurchargePipe,
    SurchargesByApplicableTypePipe,
    CheckPricePerPipe,
    CheckSurchargesPipe,
    CheckFreightRatesPipe,
    TopBorderClassPipe,
    TripUnLocodeAutocompletePostcodeDialogComponent,
    ConfirmDialogComponent,
    PaymentSentComponent,
    BookingPaidStatusNamePipe,
    PaymentSuccessfulComponent,
    PaymentOfflineComponent,
    PaymentBankDetailsComponent,
    LegalNameDialogComponent,
    PaymentPayLaterComponent,
    SwiperDirective,
    QuoteSearchTripUnLocodeAutocompleteComponent,
    LocationTypeS4DIconPipe,
    LocationTypeGroupNamePipe,
    LoaderWithImagesComponent,
    HaulageTypeS4DIconPipe,
    CurrencyTextIconPipe,
    DimensionsInputComponent,
    PackageTypeInputComponent,
    PreventNonNumericDirective,
    PersonalNotificationDotComponent,
    ErrorMessagePipe,
    OrdinalPipe,
    RemoveConfirmDialogComponent,
    PhoneInputComponent,
    ApplicationNotificationComponent,
    CustomNumberFormatPipe,
    SetRoleBasedPriceDirective,
    AddressTypeS4dIconPipe,
    AddressTypeShortNamePipe,
    RoleBasedHideOrShowDirective,
    WhatsAppButtonComponent,
    FourtySeasInitDialogComponent,
    S4DPlacesDirective,
    AutocompletePositionDirective,
    CommodityInputUngroupedComponent,
    TaxIdInputComponent,
    LegalNameRollupDialogComponent,
    CompanySearchInputComponent,
    TrackInputErrorsDirective,
    PaymentStatusComponent,
    PriceHistoryChartComponent,
    DangerousGoodsTypeNamePipe,
    InfoBoxComponent,
    S4DTooltipComponent,
    S4DTooltipDirective
  ],
  providers: [
    TripUnLocodeAutocompleteService,
    BookingsService,
    BookingContactsService,
    ShipmentTypeNamePipe,
    RateServiceTypeNamePipe,
    RateServiceTypeBackgroundPipe,
    DurationPipe,
    LinearUnitNamePipe,
    WeightUnitNamePipe,
    VolumeUnitNamePipe,
    DimensionUnitNamePipe,
    BookingDocumentsService,
    PackageTypeService,
    CommodityService,
    BookingOrganizationContactsService,
    AccountsService,
    ActivitiesService,
    CountryService,
    AccessRoleTypeNamePipe,
    TeamMemberStatusNamePipe,
    FilesDocumentsService,
    BookingShippingInstructionsService,
    FilesService,
    CheckoutService,
    FourtySeasPaymentService,
    PaymentsService,
    RequestAndPaymentService,
    BookingPaidStatusNamePipe,
    SupportedCountriesService,
    CityService,
    CompanySearchService,
    DashboardService,
    ErrorMessagePipe,
    ApplicationNotificationService,
    GoogleAnalyticsService,
    CustomNumberFormatPipe,
    AmplitudeService,
    FourtySeasInitDialogService,
    TaxIdService,
    AppcuesService,
    EnvironmentService,
    PriceHistoryChartService,
    HeapService
  ],
  entryComponents: [],
  exports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CdkStepperModule,
    NgScrollbarModule,
    TranslateModule,
    SharedMaterialModule,
    ShipmentTypeNamePipe,
    ShipmentTypeCargoNamePipe,
    TripUnLocodeAutocompleteComponent,
    CurrencyIconPipe,
    CurrencyNamePipe,
    HaulageTypeWordPipe,
    ApplicableTypePipe,
    LocationTypeShortNamePipe,
    LocationTypeIconPipe,
    HaulageIconTypePipe,
    MainCarriageHaulageIconType,
    RateServiceTypeNamePipe,
    RateServiceTypeBackgroundPipe,
    DurationPipe,
    QuoteSearchIconsComponent,
    LinearUnitNamePipe,
    WeightUnitNamePipe,
    VolumeUnitNamePipe,
    DimensionUnitNamePipe,
    PackageTypeNamePipe,
    PackageTypeAutocompleteComponent,
    CommodityAutocompleteComponent,
    CountryAutocompleteComponent,
    KycStatusCodeNamePipe,
    IsShowTooltipDirective,
    AccessRoleTypeNamePipe,
    TeamMemberStatusNamePipe,
    FilesDocumentsComponent,
    FreightRatePipe,
    UniqueApplicableTypePipe,
    TotalFreightRatePipe,
    TotalAdditionalTypeRatePipe,
    AdditionalTypeSurchargePipe,
    SurchargesByApplicableTypePipe,
    CheckPricePerPipe,
    CheckSurchargesPipe,
    CheckFreightRatesPipe,
    TopBorderClassPipe,
    ConfirmDialogComponent,
    PaymentSentComponent,
    BookingPaidStatusNamePipe,
    LegalNameDialogComponent,
    LoaderWithImagesComponent,
    SwiperDirective,
    QuoteSearchTripUnLocodeAutocompleteComponent,
    LocationTypeS4DIconPipe,
    LocationTypeGroupNamePipe,
    HaulageTypeS4DIconPipe,
    CurrencyTextIconPipe,
    DimensionsInputComponent,
    PackageTypeInputComponent,
    PreventNonNumericDirective,
    PersonalNotificationDotComponent,
    ErrorMessagePipe,
    OrdinalPipe,
    RemoveConfirmDialogComponent,
    PhoneInputComponent,
    ApplicationNotificationComponent,
    CustomNumberFormatPipe,
    SetRoleBasedPriceDirective,
    AddressTypeS4dIconPipe,
    AddressTypeShortNamePipe,
    RoleBasedHideOrShowDirective,
    WhatsAppButtonComponent,
    S4DPlacesDirective,
    AutocompletePositionDirective,
    CommodityInputUngroupedComponent,
    TaxIdInputComponent,
    LegalNameRollupDialogComponent,
    CompanySearchInputComponent,
    TrackInputErrorsDirective,
    OverlayModule,
    PriceHistoryChartComponent,
    DangerousGoodsTypeNamePipe,
    InfoBoxComponent,
    S4DTooltipDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SharedMaterialModule.forRoot().providers,
        DatePipe,
        CookieService,
        StringCutterService,
        HttpService,
        FormatDateService,
        LanguageService,
        UserService,
        PreviousRouteService
      ]
    };
  }
}
