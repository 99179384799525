<div class="orders-widget-container" [ngClass]="{ 'is-empty-container': isEmpty }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div class="col widget-title">
        <span *ngIf="!isEmpty">My submitted orders</span>
      </div>
      <div *ngIf="!isEmpty" class="col-auto widget-action">
        <span class="go-icon link-action" (click)="onGoTo()"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
      <div *ngIf="isLoading" class="col is-loader">
        <div *ngFor="let num of range(loaderElementsNumber)" class="content-wrapper loader-element"></div>
      </div>

      <div *ngIf="!isLoading && isEmpty" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="mt-3 is-empty-title text-center">You haven't had orders yet</span>
        <span class="mt-2 is-empty-message">Please search quote or contact support.</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
        [disabled]="isButtonLoading" (click)="onEmptyAction()">
          <span class="button-inner">
            Search quote
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="!isLoading && isError" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>

      <div *ngIf="!isLoading && !isError && !isEmpty" class="col">
        <div class="row content-wrapper pe-3" *ngFor="let booking of bookings">
          <div class="col d-flex flex-column justify-content-center h-100" (click)="onBookingDetails(booking.id)">
            <div class="row h-100">
              <div class="col-auto order-icon-wrapper">
                <span class="carriage-line"></span>
                <span class="carriage-type-icon" [ngClass]="getMainCarriageIcon(booking)">
                </span>
              </div>
              <div class="col order-details">
                <span class="from-order-name">From: {{ booking.fromDisplayName }}</span>
                <span class="to-order-name">To: {{ booking.toDisplayName }}</span>
                <span class="order-dates">{{
                  checkDateNotNullOrEmpty(booking.eta)
                  ? (booking.eta | date : "MMM dd, yyyy")
                  : "Schedule Not Available"
                  }}
                  • {{ getArriveDays(booking) }}</span>
              </div>
              <div class="col order-status-wrapper">
                <span class="order-status-text" [ngClass]="getIconColor(booking)">{{
                  booking.isCanceled === true
                  ? "Cancelled"
                  : booking.statusType?.ship4wdShortFrontendStatus
                  }}</span>
                <span class="order-status-icon" [ngClass]="getIconColor(booking)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
