import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { UserProfile, OrganizationProfile } from './accounts.model';

@Injectable()
export class AccountsService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getUserProfile(organizationId?: string): Observable<UserProfile> {
    let params = new HttpParams()
    if (organizationId) {
      params = params.set("organizationId", organizationId);
    }
    return this.http.get<UserProfile>(
      `${this.internalUrlsService.getApiBaseUrl()}/accounts/userProfile`, { params });
  }

  getOrganizationProfiles(): Observable<OrganizationProfile> {
    return this.http.get<OrganizationProfile>(
      `${this.internalUrlsService.getApiBaseUrl()}/accounts/organizationProfiles`);
  }
}
