import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@ship4wd/ngx-authorization';
import { NotificationService } from '@ship4wd/ngx-common';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';

import { AccessRoleTypeNamePipe } from '../../../shared/pipes/access-role-type-name.pipe';
import { AccessRoleType, TeamAccessRole, TeamMemberStatusCode } from '../../../shared/shared.model';
import {
  OrganizationInvitationViewModel,
  OrganizationMembers,
  TeamMember,
  TeamMembersInvite,
  TeamMemberViewModel
} from '../manage-organization.model';
import { InviteMemberDialogComponent } from './invite-member-dialog/invite-member-dialog.component';
import { InviteMemberService } from './invite-member-dialog/invite-member.service';
import { TeamMemberDialogComponent } from './team-member-dialog/team-member-dialog.component';
import { TeamMemberService } from './team-member-dialog/team-member.service';

@Component({
  selector: 'app-organization-members',
  templateUrl: './organization-members.component.html',
  styleUrls: ['./organization-members.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationMembersComponent implements OnInit {
  @Input() organizationMembers: OrganizationMembers;
  @Output() teamMemberEvent = new EventEmitter();
  teamMemberStatuses = TeamMemberStatusCode;
  userId: string;

  constructor(
    private accessRoleTypeNamePipe: AccessRoleTypeNamePipe,
    public dialog: MatDialog,
    private teamMemberService: TeamMemberService,
    private inviteMemberService: InviteMemberService,
    private notificationService: NotificationService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userId = this.userService.getUser()?.userId;
  }

  getInitialMemberName(member) {
    if (member?.firstName || member?.lastName) {
      return member.firstName.charAt(0).toUpperCase() + member.lastName.charAt(0).toUpperCase();
    }
    return '';
  }

  getAccessRolesOfInviteMember(accessRoles) {
    if (accessRoles) {
      let roles = '';
      const rolesList = accessRoles.split(',') as AccessRoleType[];
      rolesList.forEach((x) => {
        roles += this.accessRoleTypeNamePipe.transform(x) + ', ';
      });
      return roles.trim().slice(0, -1);
    }
    return '';
  }

  getAccessRolesOfTeamMember(accessRoles: TeamAccessRole[]) {
    if (accessRoles) {
      let roles = '';
      accessRoles.forEach((x) => {
        roles += this.accessRoleTypeNamePipe.transform(x.accessRoleType) + ', ';
      });
      return roles.trim().slice(0, -1);
    }
    return '';
  }

  onCancelInviteMember(inviteMember: TeamMembersInvite) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data: {
        title: "Confirmation",
        message: "Are you sure you want to cancel invitation?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm"
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {
        this.cancelInviteMember(inviteMember.id);
      }
    });
  }

  onResendInviteMember(inviteMember: TeamMembersInvite) {
    const inviteMemberViewModel = this.prepareInviteMemberViewModel(inviteMember);
    this.addEditInviteMember(inviteMemberViewModel, 'resend');
  }

  onEditInviteMember(inviteMember: TeamMembersInvite) {
    const dialogRef = this.dialog.open(InviteMemberDialogComponent, {
      panelClass: 'invite-member-dialog-container',
      autoFocus: false,
      data: {
        inviteMember: inviteMember,
        mode: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.isSuccess) {
        this.teamMemberEvent.emit();
      }
    });
  }

  onAddInviteMember() {
    const dialogRef = this.dialog.open(InviteMemberDialogComponent, {
      panelClass: 'invite-member-dialog-container',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.isSuccess) {
        this.teamMemberEvent.emit();
      }
    });
  }

  onEditTeamMember(teamMember: TeamMember) {
    const dialogRef = this.dialog.open(TeamMemberDialogComponent, {
      panelClass: 'team-member-dialog-container',
      autoFocus: false,
      data: {
        teamMember: teamMember
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.isSuccess) {
        this.teamMemberEvent.emit();
      }
    });
  }

  onRemoveTeamMember(teamMember: TeamMember) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data: {
        title: "Confirmation",
        message: "Are you sure you want to remove team member?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm"
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {
        this.removeTeamMember(teamMember.id);
      }
    });
  }

  onSuspendTeamMember(teamMember: TeamMember) {
    const teamMemberViewModel = this.prepareTeamMemberViewModel(teamMember, TeamMemberStatusCode.suspended);
    this.updateTeamMember(teamMember.id, teamMemberViewModel);
  }

  onReactivateTeamMember(teamMember: TeamMember) {
    const teamMemberViewModel = this.prepareTeamMemberViewModel(teamMember, TeamMemberStatusCode.active);
    this.updateTeamMember(teamMember.id, teamMemberViewModel);
  }

  private cancelInviteMember(id: string) {
    this.inviteMemberService
      .cancelInvite(id, this.userId)
      .subscribe(() => {
        this.notificationService.success('Invitation has been successfully cancelled.');
        this.teamMemberEvent.emit();
      },
        (error) => this.notificationService.error(error));
  }

  private addEditInviteMember(inviteMember: OrganizationInvitationViewModel, mode: string) {
    this.inviteMemberService
      .addEditInviteMember(inviteMember)
      .subscribe((x: any) => {
        let createAccountMessage = this.getCreateAccountMessage(x.isTeamMemberExist);
        if (mode === 'resend') {
          this.notificationService.success(`Invitation has been successfully resent. ${createAccountMessage}`);
        }
        this.teamMemberEvent.emit();
      },
        (error) => this.notificationService.error(error));
  }

  private prepareInviteMemberViewModel(inviteMember: TeamMembersInvite) {
    let organizationInvitationViewModel = new OrganizationInvitationViewModel();
    organizationInvitationViewModel.organizationId = inviteMember.organizationId;
    organizationInvitationViewModel.email = inviteMember.email;
    organizationInvitationViewModel.firstName = inviteMember.firstName;
    organizationInvitationViewModel.lastName = inviteMember.lastName;
    organizationInvitationViewModel.nickName = inviteMember.nickName;
    organizationInvitationViewModel.roles = inviteMember.roles;
    return organizationInvitationViewModel;
  }

  private prepareTeamMemberViewModel(teamMember: TeamMember, teamMemberStatus: TeamMemberStatusCode) {
    let teamMemberViewModel = new TeamMemberViewModel();
    teamMemberViewModel = { ...teamMember, ...teamMemberViewModel };
    if (teamMember.teamAccessRoles.length > 0) {
      let roles = '';
      teamMember.teamAccessRoles.forEach((x) => {
        roles += x.accessRoleType + ',';
      });
      teamMemberViewModel.roles = roles.slice(0, -1);
    }
    teamMemberViewModel.memberStatus = teamMemberStatus;
    return teamMemberViewModel;
  }

  private removeTeamMember(id: string) {
    this.teamMemberService
      .deleteTeamMember(id)
      .subscribe(() => {
        this.notificationService.success('Team member has been successfully removed.');
        this.teamMemberEvent.emit();
      },
        (error) => this.notificationService.error(error));
  }

  private updateTeamMember(id: string, teamMember: TeamMemberViewModel) {
    this.teamMemberService
      .updateTeamMember(id, teamMember)
      .subscribe(() => {
        this.notificationService
          .success(`Team member has been successfully
          ${teamMember.memberStatus === TeamMemberStatusCode.suspended ? 'suspended' : 'activated'}.`);
        this.teamMemberEvent.emit();
      },
        (error) => this.notificationService.error(error));
  }

  private getCreateAccountMessage(isTeamMemberExist: boolean): string{
    return !isTeamMemberExist ? 'Please, ask the user to create an account.' : ''
  }
}
