import { Pipe, PipeTransform } from '@angular/core';
import { HaulageType } from '../shared.model';

@Pipe({ name: 'mainCarriageHaulageIconType' })
export class MainCarriageHaulageIconType implements PipeTransform {
  private haulageTypes = {};

  constructor() {
    this.haulageTypes[HaulageType.sea] = 'sea-ship-icon';
    this.haulageTypes[HaulageType.air] = 'air-ship-icon';
    this.haulageTypes[HaulageType.rail] = 'sea-ship-icon';
    this.haulageTypes[HaulageType.land] = 'sea-ship-icon';
    this.haulageTypes[HaulageType.barge] = 'sea-ship-icon';
  }

  transform(value?: HaulageType): string {
    return this.haulageTypes[value] ?? '';
  }
}
