import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';

@Component({
  selector: 'app-requested-credit-line',
  templateUrl: './requested-credit-line.component.html',
  styleUrls: ['./requested-credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestedCreditLineComponent {
  organizationId: string;
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService
  ) {
    this.organizationId = this.settingsService.getSettings().organizationId;
  }
}