import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { EncodeUrlCodec } from '../../services/encode-url-codec';
import { Page } from '../../shared.model';
import {
  OrganizationContact,
  OrganizationContactsQuery,
  OrganizationContactViewModel
} from './booking-organization-contacts.model';

@Injectable()
export class BookingOrganizationContactsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  getOrganizationContactsByQuery(query: OrganizationContactsQuery, organizationId: string):
    Observable<Page<OrganizationContact>> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<Page<OrganizationContact>>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationId}/contacts`,
      { params });
  }

  addOrganizationContact(organizationContact: OrganizationContactViewModel): Observable<OrganizationContact> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<OrganizationContact>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationContact.organizationId}/contacts`,
      organizationContact, httpOptions);
  }

  addOrganizationContacts(organizationContacts: OrganizationContactViewModel[], organizationId: string): Observable<OrganizationContact> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<OrganizationContact>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationId}/contacts/csv`,
      organizationContacts, httpOptions);
  }

  updateOrganizationContact(organizationContact: OrganizationContactViewModel): Observable<OrganizationContact> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<OrganizationContact>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationContact.organizationId}/contacts/${organizationContact.id}`,
      organizationContact, httpOptions);
  }

  deleteOrganizationContact(organizationId: string, organizationContactId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationId}/contacts/${organizationContactId}`);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
