import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemoveConfirmDialogData } from './remove-confirm-dialog-data.model';

@Component({
  selector: 'remove-confirm-dialog',
  templateUrl: './remove-confirm-dialog.component.html',
  styleUrls: ['./remove-confirm-dialog.component.scss']
})
export class RemoveConfirmDialogComponent {
  @Output() accepting = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<RemoveConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemoveConfirmDialogData) { }

  action(result: boolean): void {
    this.dialogRef.close({
      result: result
    });
  }
}
