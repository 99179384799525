<div *ngIf="isLoading; else loaded" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
  <form *ngIf="bookingForm" [formGroup]="bookingForm" class="booking-steps">
    <div [style.display]="lastStep === 0 ? 'block' : 'none'">
      <app-booking-reference (addAnotherReference)="onAddAnotherReference()"
        (removeAnotherReference)="onRemoveAnotherReference($event)" (generatedRefID)="onGenerateRefID()">
      </app-booking-reference>
    </div>
    <div [style.display]="lastStep === 1 ? 'block' : 'none'">
      <app-booking-contacts *ngIf="booking" [booking]="booking" [shipperContact]="shipperContact"
        [consigneeContact]="consigneeContact" [notifyPartyContact]="notifyPartyContact"
        (backEvent)="onBackContacts($event)" (selectedContact)="onSelectedContact($event)"
        (removeBookingContact)="onRemoveBookingContact($event)"
        [isStepContactsNextButtonPress]="isStepContactsNextButtonPress">
      </app-booking-contacts>
    </div>
    <div [style.display]="lastStep === 2 ? 'block' : 'none'">
      <app-booking-containers [shipmentTypeCode]="shipmentTypeCode" [booking]="booking"
        (addCommodity)="onAddCommodity($event)" (removeCommodity)="onDeleteCommodity($event)"
        (backEvent)="onBackContacts($event)" (selectedContact)="onSelectedContact($event)"></app-booking-containers>
    </div>
    <div [style.display]="lastStep === 3 ? 'block' : 'none'">
      <app-booking-documents *ngIf="booking" [booking]="booking"></app-booking-documents>
    </div>
    <div class="booking-summary" [style.display]="lastStep === 4 ? 'block' : 'none'">
      <app-booking-summary [booking]="booking" [bookingSummary]="bookingSummary" [isEditBooking]="isEditBooking"
        (viewBookingEvent)="onViewBooking($event)">
      </app-booking-summary>
      <div class="row">
        <div class="col">
          <div class="booking-summary-condition-checkbox">
            <mat-checkbox color="primary" (change)="onChangeTermsAndConditions($event)"
              [checked]="bookingSummary?.isTermsAndConditionsConfirmed" [disabled]="
                isViewOnly ||
                !isEditBooking ||
                booking.statusType.code !== bookingStatuses.draft
                  ? true
                  : false
              ">
              <div>
                Tick to confirm you have read the
                <a href="HTTPS://WWW.SHIP4WD.COM/TERMS-AND-CONDITIONS/" target="_blank" class="condition-link">
                  terms & conditions
                </a>
                , goods include no personal effects, no refrigerated goods in
                dry containers and all information is accurate.
              </div>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button mat-stroked-button *ngIf="!creditOrganization" class="save-draft-link" type="button" (click)="onSaveDraft()"
          [disabled]="!(lastStep === 4 && !booking.isTermsAndConditionsConfirmed) &&
              !isNextStepLoading
                ? false
                : true
            ">
            <div class="row">
              <span color="primary">Save Draft</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>

  <app-navbar *ngIf="!isViewOrganizationContacts" [progress]="progress">
    <div class="col px-3 align-self-center booking-steps-button-wrapper">
      <div class="back-continue-button-wrapper">
        <div class="back-button-wrapper">
          <button mat-stroked-button class="back-button" type="button" (click)="onBack()" [disabled]="lastStep === 0 || isNextStepLoading">
            <div class="back-icon"></div>
          </button>
        </div>
        <div class="continue-wrapper">
          <button mat-raised-button class="next-button button-spinner" [type]="lastStep === 4 ? 'submit' : 'button'"
            (click)="lastStep === 4 ? onSubmitBooking(creditOrganization) : onNext()" [disabled]="
              !(lastStep === 4 && !booking.isTermsAndConditionsConfirmed) &&
              !isNextStepLoading
                ? false
                : true
            ">
            <span class="button-inner">
              {{ lastStep === 4 ? (creditOrganization ? 'Confirm': (isFinanceEnabled ? 'Confirm & Pay Now' : 'Confirm')) : 'Next' }}
              <mat-spinner diameter="25" *ngIf="isNextStepLoading"></mat-spinner>
            </span>
          </button>
        </div>
      </div>
    </div>
  </app-navbar>
</ng-template>
