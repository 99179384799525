import { Booking, BookingViewModel } from '../bookings/bookings.model';

export class ShipmentInstruction extends Booking {
}

export class ShipmentInstructionViewModel extends BookingViewModel {
  bolOrderNumber: string;
  changedBy: string;
}

export const shippingInstructionCheckProperties = {
  CUSTOMER_REFERENCE: "customerReferenceId",
  PO_NUMBER: "PO Number",
  ORDER_NUMBER: "bolOrderNumber",
  SHIPPER_CONTACT: "shipper_contact",
  CONSIGNEE_CONTACT: "consignee_contact",
  NOTIFY_PARTY_CONTACT: "notify_party_contact",
  CARGO_NAME: "cargoName",
  CARGO_GROSS_WEIGHT: "cargoGrossWeight",
  CARGO_GROSS_WEIGHT_UNIT_CODE: "cargoGrossWeightUnitCode",
  CONTAINER_NUMBER: "containerNumber",
  SEAL_NUMER: "sealNumber",
  COLLECT_CONTACT: "collectContact",
  COLLECTION_REFERENCE: "collectionReference",
  COLLECTION_DATE: "collectionDate",
  DROP_CONTACT: "dropContact",
  ADD_COMMODITY_BUTTON: "AddCommodityButton",
  PACKAGE_TYPE_CODE: "packageTypeCode",
  COMMODITY_TYPE_CODE: "commodityTypeCode",
  VOLUME: "volume",
  VOLUME_UNIT_CODE: "volumeUnitCode",
  PRODUCT_VALUE: "productValue",
  PRODUCT_VALUE_CURRENCY_CODE: "productValueCurrencyCode",
  NUMBER_OF_PACKAGES: "numberOfPackages",
  WEIGHT: "weight",
  WEIGHT_UNIT_CODE: "weightUnitCode",
  MARKS_AND_NUMBERS: "marksAndNumbers",
  ADD_ANOTHER_REF_BUTTON: "AddAnotherRefButton",
};
