<div class="no-quotes-container" *ngIf="quotesQuery">
  <div class="no-quotes-header">
    <div class="row m-0">
      <div class="col-12 px-0 d-flex justify-content-center">
        <div class="no-quotes-icon-wrapper">
          <span class="no-quotes-icon"></span>
        </div>
      </div>
      <div class="col-12 px-0 d-flex justify-content-center mt-4">
        <span class="no-quotes-title">Your Quotes is on its way</span>
      </div>
      <div class="col-12 px-0 d-flex justify-content-center mt-2">
        <span class="no-quotes-message">
          Our Customer Service is already informed of your search and will follow up in an email and send you a quote
          within 24 hours.
        </span>
      </div>
    </div>
  </div>
  <div class="no-quotes-summary mt-3">
    <div class="row m-0">
      <div class="col-12 px-0">
        <span class="no-quotes-summary-title">Summary</span>
      </div>
    </div>
    <div class="row summary-line">
      <div class="col-4 px-0">
        <span class="no-quotes-summary-type">Type</span>
      </div>
      <div class="col-8 pe-0 d-flex align-items-start">
        <div class="no-quotes-summary-icon-wrapper">
          <i class="type-icon s4d-icon" [ngClass]="quotesQuery.shipmentType === shipmentType.LCL ? 's4d-lcl' :
          quotesQuery.shipmentType === shipmentType.FCL ? 's4d-fcl' : 's4d-ac'"></i>
        </div>
        <span class="no-quotes-summary-result">{{ quotesQuery.shipmentType | shipmentTypeName }}</span>
      </div>
    </div>
    <div class="row summary-line">
      <div class="col-4 px-0">
        <span class="no-quotes-summary-type">From</span>
      </div>
      <div class="col-8 pe-0 d-flex align-items-start">
        <div class="no-quotes-summary-icon-wrapper">
          <i class="type-icon s4d-icon" [ngClass]="quotesQuery.from?.locationType | locationTypeS4DIcon"></i>
        </div>
        <span class="no-quotes-summary-result">{{ renderFromToData(quotesQuery.from, searchType.from) }}</span>
      </div>
    </div>
    <div class="row summary-line">
      <div class="col-4 px-0">
        <span class="no-quotes-summary-type">To</span>
      </div>
      <div class="col-8 pe-0 d-flex align-items-start">
        <div class="no-quotes-summary-icon-wrapper">
          <i class="type-icon s4d-icon" [ngClass]="quotesQuery.to?.locationType | locationTypeS4DIcon"></i>
        </div>
        <span class="no-quotes-summary-result">{{ renderFromToData(quotesQuery.to, searchType.to) }}</span>
      </div>
    </div>
    <div class="row summary-line">
      <div class="col-4 px-0">
        <span class="no-quotes-summary-type">When</span>
      </div>
      <div class="col-8 pe-0 d-flex align-items-start">
        <div class="no-quotes-summary-icon-wrapper">
          <i class="type-icon s4d-icon"></i>
        </div>
        <span class="no-quotes-summary-result">{{ quotesQuery.fromDate | date: "MMM dd, yyyy" }}</span>
      </div>
    </div>
  </div>
  <div class="no-quotes-footer mt-4">
    <div class="row m-0">
      <div class="col-12 px-0">
        <button mat-button class="no-quotes-footer-button" (click)="onClickChat()">
          Chat with an Expert
        </button>
      </div>
    </div>
  </div>
</div>