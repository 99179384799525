import { Pipe, PipeTransform } from '@angular/core';
import { HaulageType } from '../../shared/shared.model';

@Pipe({ name: 'haulageIconType' })
export class HaulageIconTypePipe implements PipeTransform {
  private haulageTypes = {};

  constructor() {
    this.haulageTypes[HaulageType.sea] = 'sea-ship-icon';
    this.haulageTypes[HaulageType.air] = 'air-ship-icon';
    this.haulageTypes[HaulageType.rail] = 'rail-ship-icon';
    this.haulageTypes[HaulageType.land] = 'land-ship-icon';
    this.haulageTypes[HaulageType.barge] = 'barge-ship-icon';
  }

  transform(value?: HaulageType): string {
    return this.haulageTypes[value] ?? '';
  }
}
