<div class="row g-0 m-0 gap-1">
  <div class="col d-flex align-items-center justify-content-start">
    <span class="total-title">Total Cost</span>
  </div>
  <div class="col d-flex align-items-center justify-content-end">
    <span appSetRoleBasedPrice class="total-result">{{ quote.currency | currencyTextIcon }}{{ quote.accumulatePrice |
      number : "1.0-0" }}</span>
  </div>
  <div class="col-12 d-flex flex-column align-items-end justify-content-center">
    <div class="d-flex mt-1" *ngIf="isPartialQuote">
      <span class="estimated-subtitle estimated-subtitle-dashed d-flex" [matTooltip]="getPartialQuoteTooltipText"
        matTooltipPosition="below" matTooltipClass="text-center">+
        Door Service Costs</span>
    </div>
  </div>
</div>