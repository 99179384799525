import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs/internal/Observable";
import { BankDetails, CreditLine, Payment, PaymentRequest, UpdatePayment } from "./payments.model";

@Injectable()
export class PaymentsService {

    invokeAddTab = new EventEmitter();

    constructor(
        private internalUrlsService: InternalUrlsService,
        private http: HttpClient) {
    }

    addPaymentRequest(paymentRequest: PaymentRequest): Observable<string | any> {
        return this.http.post(`${this.internalUrlsService.getApiBaseUrl()}/paymentRequests`, paymentRequest);
    }

    addPayment(payment: Payment): Observable<string | any> {
        return this.http.post(`${this.internalUrlsService.getApiBaseUrl()}/payments`, payment);
    }

    updatePayment(payment: UpdatePayment): Observable<string | any> {
        return this.http.put(`${this.internalUrlsService.getApiBaseUrl()}/payments`, payment);
    }

    getBankDetailsbyOrganization(organizationId: string): Observable<BankDetails> {
        return this.http.get<BankDetails>(`${this.internalUrlsService.getApiBaseUrl()}/bankDetails/organization/${organizationId}`);
    }

    getCreditLineByOrganization(organizationId: string): Observable<CreditLine> {
        return this.http.get<CreditLine>(`${this.internalUrlsService.getApiBaseUrl()}/creditLines/${organizationId}`);
    }

    createPaymentAndPaymentRequest(paymentRequest: PaymentRequest, isCreditOrganization: boolean): Observable<string | any> {
        const httpOptions = this.createContentTypeHttpOptions();
        return this.http.post(
            `${this.internalUrlsService.getApiBaseUrl()}/payments/createPaymentAndPaymentRequest?isCreditOrganization=${isCreditOrganization}`,
            paymentRequest,
            httpOptions);
    }

    private createContentTypeHttpOptions(contentType?: string): any {
        return {
            headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
        };
    }
}