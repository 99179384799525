<div class="recent-quotes-widget-wrapper" *ngIf="isEnabled"
  [ngClass]="{ 'is-empty-container': isEmpty, 'is-preview': preview }">
  <div class="d-flex align-items-center widget-header">
    <div *ngIf="editable" class="col-auto me-2 widget-action">
      <mat-icon class="editable-icon">zoom_out_map</mat-icon>
    </div>
    <div class="col widget-title">
      <span *ngIf="!isEmpty">Your recent searches</span>
    </div>
    <div *ngIf="!editable && !isEmpty && !preview" class="col-auto widget-action">
      <span class="go-icon link-action" (click)="onGoTo()"></span>
    </div>
    <div *ngIf="editable" class="col-auto widget-action">
      <span class="remove-icon link-action" (click)="onRemove()"></span>
    </div>
  </div>
  <div class="d-flex flex-column widget-content" [ngClass]="{ 'is-loading': isLoading || preview}">
    <ng-container [ngSwitch]="getCurrentCondition">
      <div *ngSwitchCase="'preview'" class="col is-loader">
        <div *ngFor="let num of range(loaderElementsNumber)" class="content-wrapper loader-element"></div>
      </div>
      <div *ngSwitchCase="'empty'" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="mt-3 is-empty-title">There is no quotes</span>
        <span class="mt-2 is-empty-message">Please search quote or contact support</span>
        <button mat-raised-button *ngIf="!router.url.includes('/quote-search')" color="primary"
          class="mt-3 is-empty-button button-spinner" [disabled]="isButtonLoading" (click)="onEmptyAction()">
          <span class="button-inner">
            Search quote
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>
      <div *ngSwitchCase="'error'" class="col is-error">
        <i class="refresh-icon s4d-icon s4d-refresh" (click)="onReload()"></i>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>
      <ng-container *ngSwitchCase="'view'" [ngTemplateOutlet]="quoteSearchItem"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #quoteSearchItem>
  <div class="row quote-search-item" *ngFor="let quoteSearch of quoteSearches; let i = index"
    (click)="useRecentQuoteSearch(quoteSearch)">
    <div class="col-auto quote-search-item-shipment-type" [matTooltip]="quoteSearch.shipmentType | shipmentTypeName"
      matTooltipPosition="above">
      <i [ngClass]="getMainCarriageIcon(quoteSearch)" class="transport-icon s4d-icon"></i>
      <span>{{ quoteSearch.shipmentType | shipmentTypeName }}</span>
    </div>
    <div class="col-6 quote-search-item-locations">
      <div class="col location-wrapper">
        <ng-container
          *ngTemplateOutlet="quoteSearchItemLocation; context: { location: quoteSearch.from }"></ng-container>
      </div>
      <div class="col-auto d-flex">
        <i class="location-icon s4d-icon s4d-arrow-right"></i>
      </div>
      <div class="col location-wrapper">
        <ng-container *ngTemplateOutlet="quoteSearchItemLocation; context: { location: quoteSearch.to }"></ng-container>
      </div>
    </div>
    <div class="col quote-search-item-cargo-details">
      <i class="cargo-details-icon s4d-icon s4d-boxes"></i>

      <span class="cargo-details" [matTooltip]="getCargoDetails(quoteSearch)" matTooltipPosition="above"
        matTooltipClass="cargo-details-tooltip">{{
        getCargoDetails(quoteSearch, " | ")
        }}</span>

      <i class="ms-auto cargo-details-icon s4d-icon s4d-search"></i>
    </div>
  </div>
</ng-template>

<ng-template #quoteSearchItemLocation let-location="location">
  <i [ngClass]="location.locationType | locationTypeS4DIcon" class="location-icon s4d-icon"></i>

  <span class="location-display-name">{{ location.displayName }} ({{location.unLocode}})</span>

  <span><i class="contact-country-flag-icon fi fi-{{ location.country | lowercase}}"></i></span>
</ng-template>