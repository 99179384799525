import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  ElementRef,
  QueryList,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService, QueryUtils } from "@ship4wd/ngx-common";
import { environment } from "../../../../environments/environment";
import {
  Booking,
  BookingsReadMode,
  BookingsQuery,
  BookingStatusCode,
  BookingsQueryParameters,
} from "../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../shared/bookings/bookings.service";
import { AppSettingsService } from "../../../shared/services/app-settings/app-settings.service";
import { CompletedOrderStatusType, Page } from "../../../shared/shared.model";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit, OnChanges {
  bookings: Booking[] = [];
  openOrders: Booking[] = [];
  submittedOrders: Booking[] = [];
  completedOrders: Booking[] = [];
  totalBookings: number = 0;
  pageNo: number = 1;
  isLoadMore: boolean = false;
  isCheckBookingsCount: boolean = true;
  isDraftStatusShown: boolean = true;
  isCanceledShown: boolean = true;

  @Input()
  isLoading = true;

  @Output()
  isLoadingChanged = new EventEmitter<boolean>();

  @ViewChildren("order", { read: ElementRef }) orders: QueryList<ElementRef>;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  @Input() filters: any;
  @Input() searchText: string;
  @Input() isClearFilters: boolean;

  isSearchTextWithoutFilters = true;

  sortParameters = [
    { key: BookingsQueryParameters.etd, name: 'etd' },
    { key: BookingsQueryParameters.createTimeUtc, name: 'createTimeUtc' },
    { key: BookingsQueryParameters.updateTimeUtc, name: 'updateTimeUtc' }
  ];

  constructor(
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isCheckBookingsCount = this.route.snapshot.queryParams["c"]
    ? this.route.snapshot.queryParams["c"].toLocaleLowerCase() === "true"
    : false || true;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params && params.show == '3') {
        this.isDraftStatusShown = false;
      } else if (params && params.show == '2') {
        this.isCanceledShown = false;
      }
    });
    this.getBookings(this.filters, this.isCheckBookingsCount);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filters?.currentValue !== undefined) {
      if (this.filters && this.filters !== null) {
        this.pageNo = 1;
        this.isLoadMore = false;
        this.getBookings(this.filters);
      }
    }

    if (changes?.isClearFilters?.currentValue === true) {
      this.pageNo = 1;
      this.filters = null;
      this.getBookings();
    }

    if (changes?.searchText?.currentValue !== undefined) {
      if (
        this.searchText !== undefined &&
        this.searchText !== null &&
        this.isSearchTextWithoutFilters === true
      ) {
        if (this.searchText.length > 0) {
          const filterData = this.bookings?.filter(
            (p) =>
              p.fromDisplayName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.toDisplayName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.fromBookingSearch?.value
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.toBookingSearch?.value
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.from
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.to
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.firstName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.lastName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.companyName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.customerReferenceId
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.statusType?.ship4wdFrontendStatus
                .toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.masterBillOfLading
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.bolOrderNumber
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase())
          );

          this.getProcessFilterBooking(filterData);
        } else {
          this.getProcessFilterBooking(this.bookings);
        }
      }
    }
  }

  getBookings(filters?: any, isCheckBookingCount = false): void {
    const query = this.prePareBookingsQueryModel(filters);

    this.isLoading = true;
    this.bookingsService
      .getPage(query)
      .subscribe(
        (x: Page<Booking>) => {
          this.bookings = this.isLoadMore ? this.bookings.concat(x.items) : x.items;

          if (!this.isDraftStatusShown) {
            this.bookings = this.bookings.filter(booking => booking.isCanceled == false || booking.statusType.code == BookingStatusCode.orderSubmitted);
          }

          if (!this.isCanceledShown) {
            this.bookings = this.bookings.filter(booking => booking.isCanceled == false);
          }

          this.totalBookings = x.totalCount;
          if (this.searchText?.length > 0) {
            const filterData = this.bookings?.filter(
              (p) =>
                p.fromDisplayName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.toDisplayName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.fromBookingSearch?.value
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.toBookingSearch?.value
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.from
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.to
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.shipperContact?.firstName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.shipperContact?.lastName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.customerReferenceId
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.statusType?.ship4wdFrontendStatus
                  .toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase())
            );
            this.getProcessFilterBooking(filterData);
          } else {
            this.getProcessFilterBooking(this.bookings);
          }
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.isLoadingChanged.emit(this.isLoading);
        if (isCheckBookingCount) this.checkIfHasBookings();
      });
  }

  cancelBooking(bookingId: string): void {
    if (this.openOrders.findIndex((booking) => booking.id == bookingId) >= 0) {
      this.openOrders = this.openOrders.filter(
        (booking) => booking.id !== bookingId
      );
    } else if (
      this.submittedOrders.findIndex((booking) => booking.id == bookingId) >= 0
    ) {
      this.submittedOrders[
        this.submittedOrders.findIndex((booking) => booking.id == bookingId)
      ].isCanceled = true;
    } else if (
      this.completedOrders.findIndex((booking) => booking.id == bookingId) >= 0
    ) {
      this.completedOrders[
        this.completedOrders.findIndex((booking) => booking.id == bookingId)
      ].isCanceled = true;
    }
  }

  onLoadMore(): void {
    this.pageNo++;
    this.isLoadMore = true;
    this.getBookings(this.filters);
  }

  onSortBooking(sort: MatSort): void {
    this.pageNo = 1;
    this.isLoadMore = false;
    this.sort = sort;
    this.getBookings(this.filters);
  }

  onGetQuote(): void {
    this.router.navigate(['/quote-search']);
  }

  private prePareBookingsQueryModel(filters?: any): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = this.pageNo;
    query.pageSize = environment.pageSize;
    query.sortDesc = true;
    query.readMode = BookingsReadMode.appDashboard;

    if (this.sort?.active != undefined) {
      query.sortBy = QueryUtils.getSortBy(this.sortParameters, this.sort?.active, BookingsQueryParameters.updateTimeUtc);
    }
    query.sortDesc = this.sort?.direction != undefined ? QueryUtils.getSortDesc(this.sort?.direction) : true;

    if (filters) {
      if (filters.fromCity) {
        query.fromDisplayName = filters.fromCity;
      }

      if (filters.toCity) {
        query.toDisplayName = filters.toCity;
      }

      if (filters.fromLastUpdateDate) {
        query.fromLastUpdateDate = this.toIsoDateTimeString(
          filters.fromLastUpdateDate
        );
      }

      if (filters.orderStatuses && filters.orderStatuses.length > 0) {
        query.orderStatuses = filters.orderStatuses;
      }

      if (filters.isCanceled !== null) {
        query.isCanceled = filters.isCanceled;
      }

      if (filters.orIsCanceled !== null) {
        query.orIsCanceled = filters.orIsCanceled;
      }

      if (filters.bolOrderNumber) {
        query.bolOrderNumber = filters.bolOrderNumber;
      }

      if (filters.masterBillOfLading) {
        query.masterBillOfLading = filters.masterBillOfLading;
      }

      if (filters.atd) {
        query.atd = this.toIsoDateTimeString(
          filters.atd
        );
      }

      if (filters.duePaymentDate) {
        query.duePaymentDate = this.toIsoDateTimeString(
          filters.duePaymentDate
        );
      }

      if (filters.bookingPaidStatusCode !== null) {
        query.bookingPaidStatusCode = filters.bookingPaidStatusCode;
      }

      if (filters.paidStatusCodes  && filters.paidStatusCodes.length > 0) {
        query.paidStatusCodes = filters.paidStatusCodes;
      }

      if(filters.any !== null) {
        query.any = filters.any;
      }

      if(filters.hasPayments !== null) {
        query.hasPayments = filters.hasPayments;
      }
    }

    return query;
  }

  private toIsoDateTimeString(date): string {
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  private getProcessFilterBooking(bookingData: Booking[]): void {
    if (bookingData && bookingData.length > 0) {
      bookingData = bookingData.sort(
        (n1, n2) => n1.statusType.code - n2.statusType.code
      );
      bookingData = bookingData.sort((n1, n2) =>
        n1.isCanceled === n2.isCanceled ? 0 : n1.isCanceled ? 1 : -1
      );
    }

    this.completedOrders = bookingData?.filter(
      (x) => {
        return this.getCompletedOrderStatusType().includes(x.statusType.code) || x.isCanceled
      }
    );
    this.openOrders = bookingData?.filter(
      (x) => x.statusType.code == BookingStatusCode.draft && !x.isCanceled
    );
    this.submittedOrders = bookingData?.filter(
      (x) =>
        x.statusType.code != BookingStatusCode.draft &&
        !this.getCompletedOrderStatusType().includes(x.statusType.code) &&
        !x.isCanceled
    );
  }

  private getCompletedOrderStatusType(): BookingStatusCode[] {
    return CompletedOrderStatusType;
  }

  private checkIfHasBookings(): void {
    if (this.totalBookings <= 0) {
      this.navigateToQuoteSearch();
    }
  }

  private navigateToQuoteSearch(): void {
    this.router.navigate(["/quote-search"]);
  }
}
