import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuotesResultsSortBy } from '../../../../../shared/shared.model';
import { Quote } from '../../../../../desktop/quotes/quotes.model';

@Component({
  selector: 'app-flow-sort-options',
  templateUrl: './flow-sort-options.component.html',
  styleUrls: ['./flow-sort-options.component.scss']
})
export class FlowSortOptionsComponent {
  @Input() localCharges: number;
  @Output() sortCriteria = new EventEmitter<QuotesResultsSortBy>();
  selectedOption: QuotesResultsSortBy = QuotesResultsSortBy.recommended;

  QuotesResultsSortBy = QuotesResultsSortBy;

  private _quotes: Quote[];

  @Input()
  get quotes(): Quote[] {
    return this._quotes;
  }
  set quotes(value: Quote[]) {
    this._quotes = value;
    this.quotesChange.emit(this._quotes);
  }

  @Output() quotesChange = new EventEmitter<Quote[]>();

  sortOptions = [
    { key: QuotesResultsSortBy.recommended, label: 'Recommended', getQuote: () => this.recommendedQuote },
    { key: QuotesResultsSortBy.cheapestFirst, label: 'Cheapest', getQuote: () => this.cheapestQuote },
    { key: QuotesResultsSortBy.fastestFirst, label: 'Fastest', getQuote: () => this.fastestQuote },
    { key: QuotesResultsSortBy.earliestFirst, label: 'Earliest', getQuote: () => this.earliestQuote }
  ];

  get recommendedQuote(): Quote | undefined {
    return this.getSortedQuotes(['price', 'duration', 'departureDate'])[0];
  }

  get cheapestQuote(): Quote | undefined {
    return this.getSortedQuotes(['price', 'duration', 'departureDate'])[0];
  }

  get fastestQuote(): Quote | undefined {
    return this.getSortedQuotes(['duration', 'departureDate', 'price'])[0];
  }

  get earliestQuote(): Quote | undefined {
    return this.getSortedQuotes(['departureDate', 'price', 'duration'])[0];
  }

  get recommendedFreightCharges(): number {
    return this.getFreightCharges(this.recommendedQuote);
  }

  get cheapestFreightCharges(): number {
    return this.getFreightCharges(this.cheapestQuote);
  }

  get fastestFreightCharges(): number {
    return this.getFreightCharges(this.fastestQuote);
  }

  get earliestFreightCharges(): number {
    return this.getFreightCharges(this.earliestQuote);
  }

  get recommendedLocalCharges(): number {
    return this.getLocalCharges(this.recommendedQuote);
  }

  get cheapestLocalCharges(): number {
    return this.getLocalCharges(this.cheapestQuote);
  }

  get fastestLocalCharges(): number {
    return this.getLocalCharges(this.fastestQuote);
  }

  get earliestLocalCharges(): number {
    return this.getLocalCharges(this.earliestQuote);
  }

  getSortedQuotes(criteria: string[]): Quote[] {
    return this.quotes.slice().sort((a, b) => this.sortQuotes(a, b, criteria));
  }

  sortQuotes(a: Quote, b: Quote, criteria: string[]): number {
    for (const criterion of criteria) {
      let comparison = 0;
      switch (criterion) {
        case 'price':
          comparison = a.accumulatePrice - b.accumulatePrice;
          break;
        case 'duration':
          comparison = a.estimatedDuration - b.estimatedDuration;
          break;
        case 'departureDate':
          comparison = new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime();
          break;
        default:
          break;
      }
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  }

  getQuoteFreightCharges(key: QuotesResultsSortBy): number {
    return this.getFreightCharges(this.getQuoteByKey(key)) || 0;
  }

  getQuoteDangerousGoodsSurcharges(key: QuotesResultsSortBy): number {
    return this.getDangerousGoodsSurcharges(this.getQuoteByKey(key)) || 0;
  }

  getQuoteLocalCharges(key: QuotesResultsSortBy): number {
    return this.getLocalCharges(this.getQuoteByKey(key)) || 0;
  }

  getFreightCharges(quote: Quote | undefined): number {
    if (!quote?.trip?.mainCarriageTripLeg?.legs) return 0;

    return quote.trip.mainCarriageTripLeg.legs.reduce((totalFreightCharges, leg) => {
      return (
        totalFreightCharges +
        (leg.rates?.reduce((totalLegCharges, rate) => {
          const freightRate = rate.freightRate?.shipmentRate?.totalAmount || 0;
          const surchargeAmount = rate.surcharges.reduce((totalSurcharge, surcharge) => {
            return (
              totalSurcharge +
              (surcharge.applicableType?.toUpperCase() === 'FREIGHT' ? surcharge.shipmentRate.totalAmount : 0)
            );
          }, 0);
          return totalLegCharges + freightRate + surchargeAmount;
        }, 0) || 0)
      );
    }, 0);
  }

  getDangerousGoodsSurcharges(quote: Quote | undefined): number {
    if (!quote?.trip?.mainCarriageTripLeg?.legs) {
      return 0;
    }

    return quote.trip.mainCarriageTripLeg.legs.reduce((totalSurcharge, leg) => {
      if (!leg.rates) {
        return totalSurcharge;
      }

      const legSurcharge = leg.rates.reduce((legTotal, rate) => {
        if (!rate.surcharges) {
          return legTotal;
        }

        const dangerousSurcharge = rate.surcharges.reduce((rateTotal, charge) => {
          return charge.applicableType?.toUpperCase() === "DANGEROUS"
            ? rateTotal + (charge.shipmentRate?.totalAmount || 0)
            : rateTotal;
        }, 0);

        return legTotal + dangerousSurcharge;
      }, 0);

      return totalSurcharge + legSurcharge;
    }, 0);
  }

  getLocalCharges(quote: Quote | undefined): number {
    return (quote?.accumulatePrice || 0) - this.getFreightCharges(quote) - this.getDangerousGoodsSurcharges(quote);
  }

  sort(option: QuotesResultsSortBy) {
    this.selectedOption = option;
    this.sortCriteria.emit(option);
  }

  getQuoteDuration(key: QuotesResultsSortBy): number | undefined {
    return this.getQuoteByKey(key)?.estimatedDuration;
  }

  private getQuoteByKey(key: QuotesResultsSortBy): Quote | undefined {
    switch (key) {
      case QuotesResultsSortBy.recommended:
        return this.recommendedQuote;
      case QuotesResultsSortBy.cheapestFirst:
        return this.cheapestQuote;
      case QuotesResultsSortBy.fastestFirst:
        return this.fastestQuote;
      case QuotesResultsSortBy.earliestFirst:
        return this.earliestQuote;
      default:
        return undefined;
    }
  }
}
