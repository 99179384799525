import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BookingOrganizationContactsService } from './../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { NotificationService } from '@ship4wd/ngx-common';
import {
  OrganizationContact,
  OrganizationContactAction,
  OrganizationContactViewModel
} from './../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { ValidatorsService } from './../../../shared/helper/validators.service';

@Component({
  selector: 'app-manage-contact',
  templateUrl: './manage-contact.component.html',
  styleUrls: ['./manage-contact.component.scss']
})
export class ManageContactComponent implements OnInit {

  @Input() isNewContact: boolean;
  @Input() organizationContact: OrganizationContact;
  @Input() organizationId: string;

  organizationContactForm: FormGroup;
  countryAutocompleteValue: string;
  isLoading = false;
  mainAddress = '';
  isSubmit: boolean = false;

  get organizationContactAction(): OrganizationContactAction {
    return this.isNewContact ? OrganizationContactAction.new : OrganizationContactAction.edit;
  }

  constructor(private fb: FormBuilder,
    private notificationService: NotificationService,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private validatorsService: ValidatorsService) { }

  ngOnInit(): void {
    this.organizationContactForm = this.createOrganizationContactForm();
    this.mainAddress = this.getPropertyValue('address1');
    this.countryAutocompleteValue = this.getPropertyValue('countryCode');
    this.onChanges();
  }

  backToContact(): void {
    location.reload();
  }

  saveContact(): void {
    this.organizationContactForm.markAllAsTouched();
    if (!this.organizationContactForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }
    const organizationContact = this.organizationContactForm.value as OrganizationContactViewModel;

    organizationContact.organizationId = this.organizationId;
    this.saveOrganizationContact(organizationContact);
  }

  onAddressChange(address: any): void {
    this.mainAddress = '';
    this.organizationContactForm.controls['city'].setValue('');
    this.organizationContactForm.controls['countryCode'].setValue('');
    this.organizationContactForm.controls['state'].setValue('');
    this.organizationContactForm.controls['zipCode'].setValue('');

    for (let i in address.address_components) {
      const item = address.address_components[i];

      if (item['types'].indexOf('route') > -1) {
        this.mainAddress += ' ' + item['long_name'];
      }
      else if (item['types'].indexOf('street_number') > -1) {
        this.mainAddress += item['long_name'];
      }
      else if (item['types'].indexOf('locality') > -1) {
        this.organizationContactForm.controls['city'].setValue(item['long_name']);
      } else if (item['types'].indexOf('country') > -1) {
        this.organizationContactForm.controls['countryCode'].setValue(item['short_name']);
        this.countryAutocompleteValue = item['short_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        this.organizationContactForm.controls['state'].setValue(item['long_name']);
      } else if (item['types'].indexOf('postal_code') > -1) {
        this.organizationContactForm.controls['zipCode'].setValue(item['long_name']);
      }
    }

    this.organizationContactForm.controls['address1'].setValue(this.mainAddress);
  }

  private saveOrganizationContact(organizationContact: OrganizationContactViewModel): void {
    this.isSubmit = true;
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.bookingOrganizationContactsService
        .addOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          this.notificationService.success("Contact was added successfuly");
          this.backToContact();
        },
          (error) => this.notificationService.error(error))
        .add(() => this.isSubmit = false);
    }
    else {
      this.bookingOrganizationContactsService
        .updateOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          this.notificationService.success("Contact was updated successfuly");
        },
          (error) => this.notificationService.error(error))
        .add(() => this.isSubmit = false);
    }
  }

  private createOrganizationContactForm(): any {
    return this.fb.group({
      id: [this.organizationContact?.id ?? null],
      companyName: [
        this.getPropertyValue('companyName'), [Validators.required, this.validatorsService.whiteSpaceValidator]
      ],
      firstName: [
        this.getPropertyValue('firstName'), [Validators.required, this.validatorsService.whiteSpaceValidator]
      ],
      lastName: [
        this.getPropertyValue('lastName'), [Validators.required, this.validatorsService.whiteSpaceValidator]
      ],
      email: [
        this.getPropertyValue('email'),
        [Validators.required, Validators.email]
      ],
      address1: [
        this.getPropertyValue('address1')
      ],
      address2: [
        this.getPropertyValue('address2')
      ],
      countryCode: [
        this.getPropertyValue('countryCode'), Validators.required
      ],
      state: [
        this.getPropertyValue('state')
      ],
      city: [
        this.getPropertyValue('city'),
      ],
      zipCode: [
        this.getPropertyValue('zipCode'),
      ],
      phoneNumber: [
        this.getPropertyValue('phoneNumber'),
        [Validators.required, this.phoneValidator()]
      ],
      fax: [
        this.getPropertyValue('fax')
      ],
      taxId: [
        this.getPropertyValue('taxId')
      ],
      customerCode: [
        this.getPropertyValue('customerCode')
      ]
    });
  }

  private getPropertyValue(propertyName: string): string {
    if (!this.isNewContact) {
      return this.organizationContact[propertyName] ?? '';
    }
    else {
      return '';
    }
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const country = this.organizationContactForm?.get('countryCode').value;
      let valid = false;

      switch(country) {
        case 'US':
        case 'CA':
          // Phone number pattern for US and Canada
          valid = /^\d{10}$/.test(control.value);
          break;
        default:
          // Default phone number pattern
          valid = /^[0-9\-\+]{9,20}$/.test(control.value);
          break;
      }

      return valid ? null : {'invalidPhoneNumber': {value: control.value}};
    };
  }

  private onChanges(): void {
    this.organizationContactForm.get('countryCode').valueChanges.subscribe(val => {
      this.organizationContactForm.get('phoneNumber').updateValueAndValidity();
    });
  }
}
