export enum LayoutMode {
  USER,
  LCL,
  FCL,
  AIR,
  ORGANIZATIONS,
  CONTACTS,
  PAYMENTS,
  PAYMENT,
  FINANCE,
  QUOTEFLOW,
  BOOKINGFLOW,
  OTHER
}