<div class="quote-search-date-param" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <div class="quote-search-date-param-wrapper"
    [ngClass]="{'is-active': isOpened, 'is-error': !isSelected && !isOpened && hasError}" (click)="onOpenClick()">
    <div class="row m-0 align-items-center">
      <div class="col-auto date-icon-wrapper px-0">
        <i class="date-icon s4d-icon s4d-calendar"></i>
      </div>
      <div class="col pe-0">
        <div class="date-param" [ngClass]="{'is-selected': isSelected && !isOpened}">
          <div class="row flex-column m-0 g-0">
            <div class="col-auto" [ngClass]="{'d-none': !isSelected || isOpened}">
              <span class="date-param-title">
                {{ control.value | date : "MMM dd, yyyy"}}
              </span>
            </div>
            <div class="col-auto">
              <div class="date-param-subtitle">Departure Date</div>

              <mat-error class="pt-1" *ngIf="!isSelected && !isOpened && hasError">
                <i class="s4d-icon s4d-info-light"></i>
                Required
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpened"
  cdkConnectedOverlayPanelClass="new-flow-cargo-date-panel" [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="getOverlayPosition" (overlayOutsideClick)="onClickedOutsideOverlay($event)">
  <div class="quote-search-date-details-wrapper no-close d-flex flex-column" [ngClass]="{'is-active': isOpened}">
    <div class="d-flex align-items-center mb-3">
      <span class="details-title">{{ getTitle() }}</span>
    </div>
    <div class="d-flex overflow-y-auto">
      <mat-calendar [headerComponent]="customHeader" [selected]="control.value" (selectedChange)="onSelectDate($event)"
        [minDate]="minSelectableDate" [maxDate]="maxSelectableDate" [dateFilter]="itIsNotLimitedDays">
      </mat-calendar>
    </div>
    <div class="footer-wrapper d-flex justify-content-end pt-3" >
      <button mat-button s4dButton class="confirm-button small" (click)="onConfirm()">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
