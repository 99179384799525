import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@sentry/browser';
import { UserService } from '@ship4wd/ngx-authorization';
import { ChatProfile, KycStatusCode, UserProfile } from '../../../shared/accounts/accounts.model';
import { AccountsService } from '../../../shared/accounts/accounts.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { AccessRoleType, OrganizationListType, TeamAccessRole } from '../../../shared/shared.model';
import { NotificationType } from '../organizations.model';
import { OrganizationsService } from '../organizations.service';
import { OrganizationSettingsData } from '../../../shared/organizations/organization.model';
import { SalesforceService } from '../../../shared/services/salesforce.service';
import { WhatsAppService } from '../../../shared/whatsapp-button/whatsapp.service';
import { AppcuesService } from '../../../shared/appcues/appcues.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { SmartlookService } from '../../../shared/services/smartlook.service';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-organization-line',
  templateUrl: './organization-line.component.html',
  styleUrls: ['./organization-line.component.scss']
})
export class OrganizationLineComponent implements OnInit {
  @ViewChild('organizationRejectedTemplate') organizationRejectedTemplate: TemplateRef<ElementRef>;
  @ViewChild('organizationPendingTemplate') organizationPendingTemplate: TemplateRef<ElementRef>;
  @Input() type: OrganizationListType = OrganizationListType.ACCEPTED;
  @Input() data: any = null;
  @Output() refreshPage = new EventEmitter();

  isLoading: boolean = false;
  user: User;
  listTypes = OrganizationListType;
  userProfile: UserProfile;
  isSelectLoading = false;
  selectedOrganizationId: string;

  constructor(
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private salesforceService: SalesforceService,
    public dialog: MatDialog,
    private router: Router,
    private whatsAppService: WhatsAppService,
    private appcuesService: AppcuesService,
    private userInfoService: UserInfoService,
    private smartlookService: SmartlookService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getUser();
  }

  selectOrganization(id: string, name: string, countryCode: string): void {
    this.selectedOrganizationId = id;
    this.isSelectLoading = true;

    this.accountsService.getUserProfile(id).subscribe(
      (userProfile: UserProfile) => {
        if (userProfile.accessRoles.length > 0) {
          const roles: AccessRoleType[] = [];
          userProfile.accessRoles.map((x: TeamAccessRole) => {
            roles.push(x.accessRoleType);
          });
          this.organizationsService.setUserRoles(roles);
        } else {
          this.organizationsService.setUserRoles(null);
        }

        if (userProfile && userProfile.teamMemberSettings) {
          const teamMemberSettings = userProfile.teamMemberSettings;
          teamMemberSettings.disableHelpPopup = false;
        }

        this.organizationsService.setUserSettingsAndInfo(userProfile);

        if (!this.whatsAppService.isWhatsAppEnabled()) this.enableSalesforceChat(userProfile, id, name);

        const organizationSettingsData: OrganizationSettingsData = {
          organizationId: id,
          countryCode: countryCode,
          organizationName: name,
          isCreditOrganization: this.data.isCreditOrganization
        };

        this.organizationsService.setOrganization(organizationSettingsData);
        this.appcuesService.identify();
        this.smartlookService.identify();
        this.organizationsService.returnBack();
      },
      error => {
        this.organizationsService.notify(error, NotificationType.ERROR);
      }
    );

    this.userInfoService.setDepartureDateFlexibilitySettings();

    this.googleAnalyticsService.organizationsClick("select");
  }

  async getConversation(id: string): Promise<void> {
    await this.salesforceService.openChat();
  }

  acceptInvite(id: string): void {
    this.isLoading = true;
    this.organizationsService
      .acceptInvitation(id, this.user.userId)
      .subscribe(
        success => {
          this.organizationsService.notify('Accepted', NotificationType.SUCCESS);
          this.organizationsService.notifyChange();
        },
        error => {
          this.organizationsService.notify(error, NotificationType.ERROR);
        }
      )
      .add(() => (this.isLoading = false));
  }

  rejectInvite(id: string): void {
    this.isLoading = true;
    this.organizationsService
      .rejectInvitation(id, this.user.userId)
      .subscribe(
        success => {
          this.organizationsService.notify('Rejected', NotificationType.ERROR);
          this.organizationsService.notifyChange();
        },
        error => {
          this.organizationsService.notify(error, NotificationType.ERROR);
        }
      )
      .add(() => (this.isLoading = false));
  }

  getOrganizationKycStatus(data: any): void {
    this.setUrlPendingclickParam(data.organizationName);

    this.isLoading = true;
    this.organizationsService
      .getOrganizationKycStatus(data.organizationId)
      .subscribe(
        response => {
          if (response.kycStatus == KycStatusCode[KycStatusCode.Approve]) {
            this.selectOrganization(data.organizationId, data.organizationName, data.countryCode);
          } else {
            this.showMessage(response.kycStatus);
          }
        },
        error => {
          this.organizationsService.notify(error, NotificationType.ERROR);
        }
      )
      .add(() => (this.isLoading = false));
  }

  private showMessage(kycStatus: string): void {
    let initialTemplate: TemplateRef<any> = null;
    switch (kycStatus) {
      case KycStatusCode[KycStatusCode.Pending]:
        initialTemplate = this.organizationPendingTemplate;
        break;
      case KycStatusCode[KycStatusCode.Reject]:
        initialTemplate = this.organizationRejectedTemplate;
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data: {
        initialTemplate,
        showConfirmButton: true,
        confirmButtonText: 'Close'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.removePendingclickParamFromUrl();
      if (result.result === true && kycStatus == KycStatusCode[KycStatusCode.Reject]) {
        this.refreshOrganizationsList();
      }
    });
  }

  private refreshOrganizationsList(): void {
    this.refreshPage.emit();
  }

  public removePendingclickParamFromUrl(): void {
    this.router.navigate([], {
      queryParams: {
        pendingclick: null
      },
      queryParamsHandling: 'merge'
    });
  }

  public setUrlPendingclickParam(organizationName: string): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { pendingclick: organizationName },
      queryParamsHandling: 'merge',
      preserveFragment: true
    });

    this.router.navigateByUrl(urlTree);
  }

  private enableSalesforceChat(userProfile: UserProfile, organizationId: string, organizationName: string): void {
    let chatUser: ChatProfile = {
      fullName: userProfile.fullName,
      email: userProfile.email,
      phoneNumber: userProfile.phoneNumber,
      companyName: organizationName,
      userId: userProfile.id,
      organizationId: organizationId
    };
    localStorage.setItem('chatUserInfo', JSON.stringify(chatUser));
    this.salesforceService.onUserLogout();
    this.salesforceService.addUserChat();
  }
}
