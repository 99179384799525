import { Directive, HostListener, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appPreventNonNumeric]",
})
export class PreventNonNumericDirective {
  constructor(@Optional() private ngControl: NgControl) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "Backspace",
      "Tab",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
    ];

    if (event.ctrlKey) {
      return;
    }

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData
      .getData("text/plain")
      .replace(",", "")
      .match(/\d+(?:\.\d*)?\d+/);

    if (pastedText) {
      this.ngControl.control?.setValue(pastedText[0]);
    }
  }
}
