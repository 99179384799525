import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RollupDialogService } from '../../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-quote-price-tiers-comparison',
  templateUrl: './quote-price-tiers-comparison.component.html',
  styleUrls: ['./quote-price-tiers-comparison.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuotePriceTiersComparisonComponent implements OnInit {
  learnMoreUrl = environment.priceTiersComparisonExternalUrl;

  constructor(public dialogRef: RollupDialogService) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
