import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { FileInfo } from '@ship4wd/ngx-manager-ui';
import { Observable } from 'rxjs';
import { BookingFile } from '../bookings.model';
import { BookingFileUrl } from './booking-documents.model';

@Injectable()
export class BookingDocumentsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  getSignedUrl(bookingId: string, bookingFileId: string): Observable<BookingFileUrl> {
    return this.http.get<BookingFileUrl>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments/`
      + `${bookingId}/${bookingFileId}/url`);
  }

  add(bookingId: string, file: FileInfo): Observable<HttpEvent<BookingFile>> {
    const formData = new FormData();
    formData.append('document', file.file, file.name);

    const params = new HttpParams();
    const options = {
      params,
      reportProgress: true
    };

    const request = new HttpRequest(
      'POST', `${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments/${bookingId}`,
      formData, options);
    return this.http.request(request);
  }

  delete(bookingId: string, bookingFileId: string) {
    return this.http.delete(`${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments`
      + `/${bookingId}/${bookingFileId}`);
  }
}
