import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { User, UserService } from '@ship4wd/ngx-authorization';
import { LayoutMode } from './layout.model';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public user: User;
  mode: LayoutMode;

  modes: any = LayoutMode;

  constructor(
    public location: Location,
    private router: Router,
    private layoutService: LayoutService,
    private userService: UserService) { }

  get chatExists(): boolean {
    const chat = document.getElementById('embedded-messaging');

    return chat ? true : false;
  }

  ngOnInit(): void {
    debugger
    this.user = this.userService.getUser();
    if (!this.user.isAuthenticated) {
      this.navigateToLogin();
    }

    this.layoutService.mode.subscribe(x => {
      this.mode = x;
    });
  }

  private navigateToLogin(): void {
    // this.router.navigate(['/login']);
  }
}
