import { Injectable } from '@angular/core';
import Smartlook from 'smartlook-client'
import { UserService } from '@ship4wd/ngx-authorization';
import { EnvironmentService } from './environment.service';
import { AccountsService } from '../accounts/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class SmartlookService {

  constructor(
    private environmentService: EnvironmentService,
    private userService: UserService,
    private accountsService: AccountsService,
  ) { }

  init(): void {
    const projectKey = this.environmentService.environment.smartloookProjectKey;

    if (!projectKey) {
      console.error('Smartlook project key is missing.');
      return;
    }

    Smartlook.init(projectKey);
  }

  identify(): void {
    const user = this.userService.getUser();
    if (user.isAuthenticated) {
      this.accountsService
      .getUserProfile()
      .subscribe(userProfile => {
        const organizationId = this.getOrganizationId();
        Smartlook.identify(userProfile.email ?? user.userId, {
          userId: user.userId,
          email: userProfile.email,
          userName: userProfile.fullName,
          phoneNumber: userProfile.phoneNumber,
          organizationId: organizationId?.toString()
        })
      },
      error => {
        console.error('Failed to fetch user profile:', error);
      })
    }
    else {
      Smartlook.identify(user.userId, { userId: user.userId });
    }
  }

  private getOrganizationId(): string | null {
    try {
      const appSettings = JSON.parse(localStorage.getItem('appsettings') || '{}');
      return appSettings.organizationId ? appSettings.organizationId.toString() : null;
    } catch (error) {
      console.error('Error parsing organization ID from app settings:', error);
      return null;
    }
  }
}
