<div class="toolbar-header" [ngClass]="{transparent: transparent}" id="toolbar-header"
  (mouseleave)="onMouseLeave($event)">
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-xxl-3 col-auto toolbar-header-left">
        <h1>
          <a (click)="onDashboard()" class="d-block">
            <img *ngIf="!transparent" src="../../../assets/images/ship4wd-logo.png" alt="site-logo" width="142"
              height="40" />
            <img *ngIf="transparent" src="../../../assets/images/ship4wd-logo-white.png" alt="site-logo" width="142"
              height="40" />
          </a>
        </h1>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="authorized && !isRefresh">
        <div class="row header-tabs">
          <div class="col px-0" *ngIf="showDashboard && isDashboardEnabled">
            <button mat-button class="header-tab-button" [class.active]="isDashboardActive"
              (click)="onDashboard()">Overview</button>
          </div>
          <div class="col px-0" *ngIf="showMyShipment">
            <button mat-button class="header-tab-button"
              [class.active]="currentRoute === '/shipments' || isMyShipmentsActive" (click)="onShipment()">My
              Shipments</button>
          </div>
          <div class="col px-0" *ngIf="showFinance && isFinanceEnabled">
            <button mat-button class="header-tab-button" [class.active]="currentRoute === '/finance' || isFinanceActive"
              (click)="onFinance()">My Payments</button>
          </div>
          <div class="col px-0" *ngIf="showCreditLine && isCreditLineEnabled">
            <button mat-button class="header-tab-button" [class.active]="currentRoute === '/creditLine'"
              (click)="onCreditLine()">Credit Line</button>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 col-auto toolbar-header-right justify-content-end">
        <div class="row">
          <div class="col-auto d-flex align-items-center">
            <button *ngIf="authorized" mat-stroked-button class="toolbar-btn-booking" (click)="onNewBooking()">
              <mat-icon>search</mat-icon>
              <span class="toolbar-booking-text d-xl-inline-block d-none">Search Quote</span>
            </button>
          </div>
          <div class="col-auto">
            <ul class="toolbar-header-right-list">
              <li *ngIf="!hideUnsupportedIcons && authorized" class="btn-icon bookmark-icon icon-toolbar-position"></li>
              <li *ngIf="!hideUnsupportedIcons && authorized" class="btn-icon globe-icon icon-toolbar-position"></li>
              <li *ngIf="!hideUnsupportedIcons && !authorized">
                <button mat-button>
                  <div class="row m-0">
                    <div class="col-auto px-0 d-flex align-items-center">
                      <div class="lang-icon-wrapper">
                        <i class="s4d-icon s4d-globe lang-icon-content"></i>
                      </div>
                    </div>
                    <div class="col d-flex align-items-center">English</div>
                  </div>
                </button>
              </li>
              <li *ngIf="!hideUnsupportedIcons && authorized" class="btn-icon bell-icon icon-toolbar-position"></li>
              <li *ngIf="!authorized">
                <div class="toolbar-profile">
                  <span class="toolbar-profile-name">{{ acronymName | uppercase }}</span>
                  <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                    class="toolbar-profile-menu"
                    [ngClass]="{'toolbar-profile-menu-rotate': menuTrigger.menuOpen === true}">
                    <i class="s4d-icon s4d-chevron-down"></i>
                  </button>
                  <mat-menu #menu="matMenu" class="profile-menu">
                    <button mat-menu-item (click)="logout()" class="btn-profile logout">
                      <span>Logout</span>
                    </button>
                  </mat-menu>
                </div>
              </li>
              <li *ngIf="authorized">
                <div class="toolbar-profile">
                  <span class="toolbar-profile-name">{{ acronymName | uppercase }}
                    <app-personal-notification-dot *ngIf="areAnyNotificationsExist"
                      [notificationType]="highestNotificationType"
                      class="toolbar-notification-dot-position"></app-personal-notification-dot>
                  </span>
                  <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                    class="toolbar-profile-menu"
                    [ngClass]="{'toolbar-profile-menu-rotate': menuTrigger.menuOpen === true}">
                    <i class="s4d-icon s4d-chevron-down"></i>
                  </button>
                  <mat-menu #menu="matMenu" class="profile-menu">
                    <button mat-menu-item [matMenuTriggerFor]="switchOrganizations"
                      class="btn-profile no-dropdown-arrow switchOrganizations">
                      <span>Switch Organizations</span>
                    </button>
                    <button mat-menu-item (click)="manageOrganization()" class="btn-profile manageOrganization"
                      *ngIf="isManageOrganizationMenuItemAccess">
                      <span>Manage Organization
                        <app-personal-notification-dot *ngIf="areManageOrganizationNotificationsExist"
                          [notificationType]="highestManageOrganizationNotificationsType"
                          class="toolbar-menu-notification-dot-position"></app-personal-notification-dot>
                      </span>
                    </button>
                    <button mat-menu-item (click)="manageContacts()" class="btn-profile manage-contact"
                      *ngIf="isManageContactsMenuItemAccess">
                      <span>Manage Contacts</span>
                    </button>
                    <button mat-menu-item (click)="logout()" class="btn-profile logout">
                      <span>Logout</span>
                    </button>
                  </mat-menu>

                  <mat-menu #switchOrganizations="matMenu" class="profile-menu">
                    <div *ngFor="let item of acceptedOrganizations;">
                      <button mat-menu-item
                        class="btn-profile"
                        [class.selected]="item.id === selectedOrganizationId"
                        (click)="selectOrganization(item.id)">
                        <i *ngIf="item.id === selectedOrganizationId" class="icon s4d-icon s4d-check"></i>
                        <span>{{ item.name | titlecase }}</span>
                      </button>
                    </div>
                    <button mat-menu-item class="btn-profile chooseOrganization" (click)="onShowAllOrganizations()">
                      <span>Show All</span>
                    </button>
                  </mat-menu>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
