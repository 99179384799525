<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading">
  <div class="booking-organization-contact-container">
    <div class="booking-organization-contact-content-wrapper" [formGroup]="organizationContactForm">
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Company</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('companyName').valid &&
                    organizationContactForm.get('companyName')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Company name" formControlName="companyName" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">First name</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('firstName').valid &&
                    organizationContactForm.get('firstName')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="First name" formControlName="firstName" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Last name</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('lastName').valid &&
                    organizationContactForm.get('lastName')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Last name" formControlName="lastName" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Email</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('email').valid &&
                    organizationContactForm.get('email')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Email" formControlName="email" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Address</span>
            <span class="field-title subtitle">Start typing for Google auto-complete</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('address1').valid &&
                    organizationContactForm.get('address1')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput ngx-google-places-autocomplete (onAddressChange)="onAddressChange($event)"
                  formControlName="address1" type="text" placeholder="Address" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Address 2 (optional)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Address 2" formControlName="address2" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">City</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('city').valid &&
                    organizationContactForm.get('city')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="City" formControlName="city" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">State (optional)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('state').valid &&
                    organizationContactForm.get('state')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="State" formControlName="state" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Country/Region</span>
            <span class="field-title subtitle">Start typing for auto-complete</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper country-auto-complete-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('countryCode').valid &&
                    organizationContactForm.get('countryCode')?.touched
                }">
              <app-country-autocomplete [countryAutocompleteValue]="countryAutocompleteValue"
                [countryControl]="organizationContactForm.get('countryCode')"
                [organizationContactAction]="organizationContactAction">
              </app-country-autocomplete>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Zip/Postcode (optional)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('zipCode').valid &&
                    organizationContactForm.get('zipCode')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Zip / Postcode" formControlName="zipCode" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <app-phone-input class="h-100 w-100" [fromControl]="organizationContactForm.get('phoneNumber')"
          [selectedCountry]="
            organizationContactForm.get('countryCode').value
          "></app-phone-input>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Fax (optional)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('fax').valid &&
                    organizationContactForm.get('fax')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Fax" formControlName="fax" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Tax ID/Vat number</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Tax ID / Vat number" formControlName="taxId" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field">
        <div class="row">
          <div class="col">
            <span class="field-title">Customer code (optional)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="organization-contact-field-wrapper" [ngClass]="{
                  'invalid-error-box':
                    !organizationContactForm.get('customerCode').valid &&
                    organizationContactForm.get('customerCode')?.touched
                }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Customer code" formControlName="customerCode" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col">
        <button mat-stroked-button class="back-button" type="button" (click)="onBackToContact()">
          <div class="row">
            <div class="col-auto">
              <div class="back-icon"></div>
            </div>
            <div class="col back-to-contact">Back to Contacts</div>
          </div>
        </button>
      </div>
    </div>
  </div>

  <app-navbar>
    <div class="col px-3 align-self-center">
      <div class="row">
        <div class="col">
          <button mat-stroked-button class="next-button" type="button" (click)="saveContact()">
            <div class="row">
              <div class="col-auto">
                <div class="back-icon"></div>
              </div>
              <div class="col back-to-contact">Save Contact</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </app-navbar>
</div>