import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-on-quote-search-dialog',
  templateUrl: './on-quote-search-dialog.component.html',
  styleUrls: ['./on-quote-search-dialog.component.scss']
})
export class OnQuoteSearchDialogComponent {

  @Output() accepting = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<OnQuoteSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  navigateToQuoteSearch() {
    this.dialogRef.close({
      isSuccess: true
    });
  }
}
