import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Checkout, AddCheckout } from './checkout.model';
import { PaymentStatusTransition } from '../shared.model';

@Injectable()
export class CheckoutService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  initCheckoutSession(paymentRequestId: string, teamMemberId: string,
    bolOrderNumber: string, transition: PaymentStatusTransition): Observable<Checkout | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    var addCheckout = {
      paymentRequestId: paymentRequestId,
      transition: transition,
      teamMemberId: teamMemberId,
      bolOrderNumber: bolOrderNumber
    } as AddCheckout;

    return this.http.post<Checkout>(
      `${this.internalUrlsService.getApiBaseUrl()}/checkout/initCheckoutSession`, addCheckout, httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}