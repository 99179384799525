<div class="quotes-widget-container" [ngClass]="{ 'is-empty-container': isEmpty }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div class="col widget-title">
        <span *ngIf="!isEmpty">My recent quotes</span>
      </div>
      <div *ngIf="!isEmpty" class="col-auto widget-action">
        <span class="go-icon link-action" (click)="onGoTo()"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
      <div *ngIf="isLoading" class="col is-loader d-flex justify-content-center">
        <div class="content-wrapper loader-element"></div>
      </div>

      <div *ngIf="!isLoading && isEmpty" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="mt-3 is-empty-title">There is no quotes</span>
        <span class="mt-2 is-empty-message">Please search quote or contact support</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
          [disabled]="isButtonLoading" (click)="onEmptyAction()">
          <span class="button-inner">
            Search quote
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="!isLoading && isError" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>

      <div *ngIf="!isLoading && !isError && !isEmpty" class="col">
        <div class="row">
          <swiper-container class="quotes-swiper-container" pagination="true" pagination-clickable="true"
            pagination-dynamic-bullets="true" slides-per-view="auto" space-between="16" centered-slides="true">
            <swiper-slide class="content-wrapper" *ngFor="let booking of bookings">
              <app-order (canceledBookingEvent)="cancelBooking($event)" [booking]="booking" class="col-12" #order
                [id]="booking.id" [isExpandable]="true"></app-order>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
  </div>
</div>