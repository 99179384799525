import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Observable, Subscription, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import {
  LocationType,
  Page,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchBaseViewModel,
  TripsSearchFromViewModel,
  TripsSearchViewModelRequest,
  TripsSearchViewModelResponse
} from '../../../../../shared/shared.model';
import { environment } from '../../../../../../environments/environment';
import { AppSettingsService } from '../../../../../shared/services/app-settings/app-settings.service';
import { TripUnLocodeAutocompleteService } from '../../../../../shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { SupportedCountriesService } from '../../../../../shared/supported-countries/supported-countries.service';
import { SupportedCountriesPairQuery, SupportedCountriesSearchQuery, SupportedCountry } from '../../../../../shared/supported-countries/supported-countries.model';
import { StringCutterService } from '../../../../../shared/services/text-cutter/stringCutter.service';
import { QuotesService } from '../../../quotes.service';

@Component({
  selector: 'app-quote-search-flow-shipping-location',
  templateUrl: './quote-search-flow-shipping-location.component.html',
  styleUrls: ['./quote-search-flow-shipping-location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowShippingLocationComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() control: FormControl;
  @Input() type: SearchType = SearchType.from;
  @Input() pageSize: number = 10;
  @Input() minimumLength: number = 3;
  @Input() shipmentType = ShipmentType.FCL;
  @Input() searchId = '';
  @Input() from = null;
  @Input() to = null;
  @Input() isOpened: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() quoteSearchParameter: QuoteSearchParameters | undefined;

  @Output() setSearchId = new EventEmitter<string>();
  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() setShipperType: EventEmitter<ShipperType> = new EventEmitter();
  @Output() next: EventEmitter<number> = new EventEmitter();

  searchType = SearchType;
  selectedLocation: TripsSearchFromViewModel = null;
  locationsResponse: TripsSearchViewModelResponse = null;
  locations: Map<LocationType, TripsSearchBaseViewModel[]> = new Map<LocationType, TripsSearchBaseViewModel[]>();
  sortedMap: any = [];
  postcodeOptionTitle: string = null;
  suggestedLocality: string = null;

  isLoading: boolean = false;
  isPostcode: boolean = false;
  isLocationAutocompleteSearchEnable: boolean;
  supportedCountries: SupportedCountry[];

  searchParamChangeSubscription!: Subscription;

  isSearchFromPredefined: boolean = false;

  toLocationsLoadedSubscription: Subscription;
  searchLocationsOnShipmentTypeChangeSubscription: Subscription;

  @ViewChild('trigger', { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.elRef.nativeElement.contains(event.target) && !(event.target.classList.contains('mat-option') || event.target.classList.contains('mat-option-text') || event.target.classList.contains('cdk-overlay-backdrop') || event.target.classList.contains('no-close'))) {
      if (this.selectedLocation === null && !this.isSelected) {
        this.control.setValue('');
        this.sortedMap = [];
        this.suggestedLocality = null;
      }
    }
  }

  constructor(
    private location: Location,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private settingsService: AppSettingsService,
    private elRef: ElementRef,
    public dialog: MatDialog,
    private supportedCountriesService: SupportedCountriesService,
    private notificationService: NotificationService,
    private stringCutterService: StringCutterService,
    private quotesService: QuotesService
  ) { }

  ngOnInit(): void {
    this.isLocationAutocompleteSearchEnable = QuoteSearchFlowShippingLocationComponent.getIsLocationAutocompleteSearch();
    this.onValueChanged();
    this.getSupportedCountries();

    if (this.control.value) {
      this.onSelect(this.control.value, false);
    }

    this.searchParamChangeSubscription = this.quotesService.searchParamChange
      .subscribe((searchParam: SearchType) => {
        if (searchParam === this.type) {
          setTimeout(() => document.getElementById(`location${this.type}`).focus(), 100)
        }
      });

    this.checkIfSearchFromPredefinedRequired();
  }

  ngAfterViewInit(): void {
    this.subscribeActiveMenuOption();
  }

  ngOnDestroy(): void {
    this.searchParamChangeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpened?.currentValue === true) setTimeout(() => {
      document.getElementById(`location${this.type}`).focus();
      this.onClicked(null)
    }, 100);
    if (changes.isSelected?.currentValue === true) {
      this.selectedLocation = this.control.value;
      setTimeout(() => document.getElementById(`location${this.type}`).blur(), 100)
    }
    if (changes.shipmentType && !changes.shipmentType.firstChange && !this.isSearchFromPredefined) {
      this.handleAutoSearchLocationsOnShipmentTypeChange();
    }
  }

  displayFn(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.displayName ? tripsSearchBaseViewModel.displayName : null;
  }

  onSelect(value: TripsSearchFromViewModel, emitNext: boolean = true): void {
    if (this.isDomesticRoute(value)) {
      return;
    }

    this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(value, this.type, this.shipmentType);
    this.isSelected = true;
    this.selectedLocation = value;
    this.selectedLocation.searchId = this.locationsResponse?.searchId;
    (document.activeElement as HTMLElement).blur();
    if (emitNext) this.next.emit(this.type === SearchType.from ? QuoteSearchParameters.from : QuoteSearchParameters.to);
    this.isOpened = false;
    this.setSelected.next(this.isSelected);
  }

  disableDomesticOption(event: any, location: TripsSearchBaseViewModel): void {
    if (this.isDomesticRoute(location)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onPostCodeOptionSelected(value: any): void {
    if (this.isDomesticRoute(value.location)) {
      return;
    }

    this.selectedLocation = value.location;
    this.postcodeOptionTitle = value.title;

    if (this.isMustVerifyPostCode(value)
      && this.locationsResponse?.tripsSearchList.length > 0) {
      this.isPostcode = true;
      this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete', 'is-postcode'];
    } else {
      this.onPostCodeOptionConfirmed(value.location);
      this.autocompleteTrigger.closePanel();
    }
  }

  onPostCodeOptionClosed(value: boolean): void {
    this.selectedLocation = null;
    this.isPostcode = false;
    this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete'];
    this.autocompleteTrigger.openPanel();
  }

  onPostCodeOptionConfirmed(value: TripsSearchFromViewModel): void {
    this.isSelected = true;
    this.selectedLocation = value;
    this.isPostcode = false;
    this.autocompleteTrigger.autocomplete.classList = ['location-autocomplete'];
    this.control.setValue(value)
    this.onSelect(value);
  }

  onClicked(event: Event, isMain: boolean = false): void {
    if (isMain) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.isSelected && this.locationsResponse?.tripsSearchList?.length == undefined) {
      let cachelist = this.tripUnLocodeAutocompleteService.getTripsSearchsFromStorage(this.type, this.shipmentType);
      this.locations.clear();
      this.sortedMap = [];
      const key = this.type == SearchType.from ? LocationType.recentOrigins : LocationType.recentDestinations;
      this.sortedMap.push({ key, value: cachelist });
    }

    this.isSelected = false;
    if (this.isPostcode) {
      this.onPostCodeOptionClosed(true);
    }

    this.quotesService.setActiveMenuOption(this.quoteSearchParameter)
  }

  panelState(state: boolean): void {
    document.getElementById("search-flow-overlay").style.visibility = state ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = state ? '1' : '0';
    this.isOpened = state;
    if (document.activeElement == document.getElementById(`location${this.type}`)) return;
    this.isSelected = this.selectedLocation != null && this.isSameLocationFound(this.control.value as TripsSearchBaseViewModel);
  }

  checkLocationType(locationType: LocationType): boolean {
    const ports = [LocationType.seaPort, LocationType.containerTerminal, LocationType.inlandPort];
    const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
    const trains = [LocationType.railwayTerminal];
    const airports = [LocationType.airPort];

    return !ports.includes(locationType) && !others.includes(locationType) && !trains.includes(locationType) && !airports.includes(locationType);
  }

  isDomesticRoute(location: TripsSearchBaseViewModel): boolean {
    if ((this.type == SearchType.from && this.to != null && this.to != '' && location.country == this.to.country)
      || (this.type == SearchType.to && this.from != null && location.country == this.from.country)) {
      return true;
    }
    return false;
  }

  getLocationTooltipText(location: TripsSearchFromViewModel): string {
    return this.isDomesticRoute(location) ? 'Destination cannot be within the same country' : location.displayName;
  }

  private onValueChanged(): void {
    this.control.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          if (this.isSearchFromPredefined && this.control.value?.value) {
            this.isSearchFromPredefined = false;
            return this.search((this.control.value as TripsSearchBaseViewModel).value, null);
          }
          const isSearch = this.checkBeforeSearch(val);
          if (isSearch) {
            this.isLoading = true;
            this.locations.clear();
            this.sortedMap = [];
            this.suggestedLocality = null;
          }

          if (typeof val === "object") {
            val = (this.control.value as TripsSearchBaseViewModel).value;
          }

          return (isSearch === true ? this.search(val || '', null) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        this.processTripsResponse(x);
      });
  }

  private processTripsResponse(x: TripsSearchViewModelResponse): void {
    this.locations.clear();
    this.sortedMap = [];
    this.locationsResponse = x;

    if (this.type === SearchType.to && x?.shipperType) this.setShipperType.next(x?.shipperType);

    if (x?.suggestedLocality != undefined) this.suggestedLocality = x.suggestedLocality

    if (x?.tripsSearchList?.length > 0) {
      x.tripsSearchList.forEach((trip: TripsSearchBaseViewModel) => {
        const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
        const ports = [LocationType.seaPort, LocationType.inlandPort];
        const trains = [LocationType.railwayTerminal];

        let locationType = trip.locationType;

        trip.cuttedDisplayName = this.stringCutterService.cutter(trip.displayName, 20);

        if (others.includes(trip.locationType)) {
          locationType = LocationType.other;
        }
        else if (ports.includes(trip.locationType)) {
          locationType = LocationType.seaPort;
        }
        else if (trains.includes(trip.locationType)) {
          locationType = LocationType.railwayTerminal;
        }

        if (this.locations.has(locationType)) {
          this.locations.get(locationType).push(trip);
        }
        else {
          this.locations.set(locationType, [trip]);
        }
      })

      const sortedKeys = Array.from(this.locations.keys()).sort((a: LocationType, b: LocationType) => {
        return this.getLocationTypeWeight(a) - this.getLocationTypeWeight(b);
      });

      sortedKeys.forEach(key => {
        this.sortedMap.push({ key: key, value: this.locations.get(key)! });
      });
    }
    this.isLoading = false;
  }

  private search(searchValue: string, postcodeSearchValue: string): Observable<TripsSearchViewModelResponse> {
    if (this.isLocationAutocompleteSearchEnable === true) {
      return this.tripUnLocodeAutocompleteService.getLocationsUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false, this.from))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);

            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getTripUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false,
          this.from))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);

            return response;
          }));
    }
  }

  private prepareUnLocodeRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel
  ): TripsSearchViewModelRequest {
    return {
      type: searchType,
      pageSize,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private checkBeforeSearch(value: string): boolean | TripsSearchViewModelResponse {
    if (this.isSelected) {
      return this.locationsResponse;
    }

    if (this.selectedLocation) {
      this.selectedLocation = null;
    }

    if (this.checkMinimumLength(value)) {
      return null;
    }

    return true
  }

  private checkMinimumLength(value: string): boolean {
    return value?.length < this.minimumLength;
  }

  private isMustVerifyPostCode(tripsSearchFromViewModel: TripsSearchFromViewModel | any): boolean {
    return tripsSearchFromViewModel?.location.locationType === LocationType.townCity;
  }

  private getSupportedCountries(): void {
    if (this.shipmentType) {
      const query = this.prepareSupportedCountriesQuery();

      this.isLoading = true;
      this.supportedCountriesService.getSupportedCountries(query)
        .subscribe((x: Page<SupportedCountry>) => {
          this.supportedCountries = x.items;
        },
          (error) => this.notificationService.error(error),
        ).add(() => (this.isLoading = false));
    }
  }

  private prepareSupportedCountriesQuery(): SupportedCountriesSearchQuery {
    let query = new SupportedCountriesSearchQuery();
    query.shipmentType = this.shipmentType;
    query.pageNo = 1;
    query.pageSize = 500;
    return query;
  }

  private getLocationTypeWeight(type: LocationType): number {
    if (type === LocationType.seaPort)
      return 1;

    if (type === LocationType.townCity)
      return this.getTownCityWeight();

    if (type === LocationType.containerTerminal || type === LocationType.airPort)
      return 2;

    if (type === LocationType.railwayTerminal)
      return this.getRailwayTerminalWeight();

    return 3;
  }

  private getTownCityWeight(): number {
    if (this.shipmentType === ShipmentType.FCL)
      return this.type === SearchType.from ? 2 : 3;

    return 1;
  }

  private getRailwayTerminalWeight(): number {
    if (this.shipmentType === ShipmentType.FCL)
      return this.type === SearchType.from ? 3 : 2;

    return 3;
  }

  private checkIfSearchFromPredefinedRequired(): void {
    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (this.location.path().includes('reused') || !!quotesQueryString) {
      this.isSearchFromPredefined = true;
    }
  }

  private handleAutoSearchLocationsOnShipmentTypeChange(): void {
    this.searchLocationsOnShipmentTypeChangeSubscription?.unsubscribe();
    this.isLoading = false;
    this.toLocationsLoadedSubscription?.unsubscribe();
    this.quotesService.notifyToLocationFound.next(null);

    if (this.control.value) {
      this.isLoading = true;
      this.searchLocationsOnShipmentTypeChangeSubscription = this.search(this.control.value.value, null)
        .subscribe((response: TripsSearchViewModelResponse) => {
          this.isLoading = false;
          this.processTripsResponse(response);

          const isSameLocationsFound = response.tripsSearchList.some(tripSearch =>
            tripSearch.locationType == this.control.value.locationType &&
            tripSearch.value == this.control.value.value);
          if (isSameLocationsFound) {
            this.isSelected = true;
            this.setSelected.next(this.isSelected);

            if (this.type === SearchType.to) {
              this.quotesService.notifyToLocationFound.next(true);
            }

            if (this.type === SearchType.from) {
              this.toLocationsLoadedSubscription = this.quotesService.notifyToLocationFound.subscribe((isToLocationFound: boolean) => {
                if (isToLocationFound !== null) {
                  if (!isToLocationFound) {
                    this.next.emit(QuoteSearchParameters.from);
                  }
                  else {
                    this.next.emit(QuoteSearchParameters.to);
                  }
                }
              })
            }
          }
          else {
            this.isSelected = false;
            this.setSelected.next(this.isSelected);

            if (this.type === SearchType.from) {
              this.isOpened = true;
              setTimeout(() => document.getElementById(`location${this.type}`).focus(), 100)
            }

            if (this.type === SearchType.to) {
              this.quotesService.notifyToLocationFound.next(false);
            }
          }
        });
    }
  }

  private isSameLocationFound(selectedLocation: TripsSearchBaseViewModel): boolean {
    return this.locationsResponse?.tripsSearchList.some(tripSearch =>
      tripSearch.locationType == selectedLocation.locationType &&
      tripSearch.value == selectedLocation.value) ||
      this?.sortedMap[0]?.value.some(tripSearch =>
        tripSearch.locationType == selectedLocation.locationType &&
        tripSearch.value == selectedLocation.value);
  }

  static getIsLocationAutocompleteSearch(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );
    switch (environmentName) {
      case "qa":
        return environment.qa.isLocationAutocompleteSearch;
      case "sb":
        return environment.sb.isLocationAutocompleteSearch;
      default:
        return environment.isLocationAutocompleteSearch;
    }
  }

  private subscribeActiveMenuOption(): void {
    this.quotesService.activeMenuOption.subscribe((option: QuoteSearchParameters | null) => {
      if (option !== null) {
        if (this.quoteSearchParameter === QuoteSearchParameters.from && option === QuoteSearchParameters.to) {
          this.autocompleteTrigger.closePanel();
        }
        if (this.quoteSearchParameter === QuoteSearchParameters.to && option === QuoteSearchParameters.from) {
          this.autocompleteTrigger.closePanel();
        }

        if ([QuoteSearchParameters.cargo, QuoteSearchParameters.date, QuoteSearchParameters.shipmentType].includes(option)) {
          this.autocompleteTrigger.closePanel();
        }
      }
    })
  }
}
