import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WidgetType } from "../../../shared/widgets/widgets.model";

@Component({
  selector: "app-dashboard-widget",
  templateUrl: "./dashboard-widget.component.html",
})
export class DashboardWidgetComponent {
  @Input() widget;
  @Input() editable: boolean;
  @Input() preview: boolean = false;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  @Output("isEnabled") isEnabledEmit: EventEmitter<[string, boolean]> = new EventEmitter<[string, boolean]>();

  widgetTypes = WidgetType;

  onRemove(id: string): void {
    this.remove.emit(id);
  }

  onEnabled([id, isEnabled]: [string, boolean]): void {
    if (!isEnabled) this.isEnabledEmit.emit([id, isEnabled])
  }
}
