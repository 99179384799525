<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="quote-search-location-param"
  [ngClass]="{'shifted-popup': type === searchType.from}">
  <div class="quote-search-location-param-wrapper"
    [ngClass]="{'is-active': isOpened, 'is-error': !isSelected && !isOpened && hasError}" (click)="onOpenClick($event)">
    <div class="row m-0 flex-nowrap align-items-center">
      <div class="col-auto px-0 location-icon-wrapper">
        <i *ngIf="!isLoading && !isSelected" class="location-icon s4d-icon s4d-map"></i>
        <i *ngIf="!isLoading && isSelected" class="location-icon s4d-icon"
          [ngClass]="selectedLocation?.locationType | locationTypeS4DIcon"></i>
      </div>
      <div class="col pe-0">
        <div class="location-param" [ngClass]="{'is-selected': isSelected && !isOpened}">
          <div class="row flex-column m-0 g-0">
            <div class="col-auto pe-0" *ngIf="!isSelected || isOpened; else selectedLocationTemplate">
              <div class="location-param-subtitle">Select {{ type === searchType.from ? 'Origin' :
                'Destination'}}</div>
              <mat-error class="pt-1" *ngIf="!isSelected && !isOpened && hasError">
                <i class="s4d-icon s4d-info-light"></i>
                Required
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectedLocationTemplate>
  <div class="col-auto pe-0 d-flex">
    <span class="location-param-title">
      {{ selectedLocation?.cuttedDisplayName }} ({{ selectedLocation?.unLocode }})
    </span>
  </div>
  <div class="col-auto d-flex align-items-center pe-0 location-param-wrapper">
    <span class="location-param-subtitle">
      <ng-container>
        {{selectedLocation?.locationType
        | locationTypeShortName | titlecase}} {{ type === searchType.from ? 'Pickup' : 'Delivery'}}
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpened"
  cdkConnectedOverlayPanelClass="new-flow-shipping-location-panel" [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="getOverlayPosition" (overlayOutsideClick)="onClickedOutsideOverlay($event)">
  <div class="quote-search-location-details-wrapper d-flex flex-column"
    [ngClass]="{'is-active': isOpened, 'recent-quotes-not-exist': recentQuotes.length === 0, 'closed-recent-quotes-list': !isExpanded}"
    [ngSwitch]="type">
    <app-new-flow-shipping-location-fields *ngSwitchCase="searchType.from" [control]="control" [to]="to" [type]="type"
      [shipmentType]="shipmentType" [searchId]="searchId" [isOpened]="isOpened" [isSelected]="isSelected"
      [recentQuotes]="recentQuotes" (setSelectedLocation)="onSetSelectedLocation($event)" [isReuse]="isReuse"
      (setExpandedValue)="onExpanded($event)" [selectedLocationFromParent]="selectedLocation" class="h-100"
      [hasError]="hasError" [supportedCountries]="supportedCountries"></app-new-flow-shipping-location-fields>
    <app-new-flow-shipping-location-fields *ngSwitchCase="searchType.to" [control]="control" [from]="from" [type]="type"
      [shipmentType]="shipmentType" [searchId]="searchId" [isOpened]="isOpened" [isSelected]="isSelected"
      [recentQuotes]="recentQuotes" (setSelectedLocation)="onSetSelectedLocation($event)" [isReuse]="isReuse"
      (setExpandedValue)="onExpanded($event)" [selectedLocationFromParent]="selectedLocation" class="h-100"
      [hasError]="hasError" [supportedCountries]="supportedCountries"></app-new-flow-shipping-location-fields>
  </div>
</ng-template>