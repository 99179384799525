import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RateServiceType, ShipmentType } from '../../../../../../../shared/shared.model';
import { RateDetailPanels } from '../flow-result-details.model';
import { RollupDialogService } from '../../../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { QuotePriceTiersComparisonComponent } from '../../../flow-result/quote-price-tiers-comparison/quote-price-tiers-comparison.component';

@Component({
  selector: 'app-flow-result-details-service',
  templateUrl: './flow-result-details-service.component.html',
  styleUrls: ['./flow-result-details-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowResultDetailsServiceComponent implements OnInit {
  @Input() quoteRateServiceType: RateServiceType;
  @Input() shipmentType: ShipmentType;
  @Input() currentPanelName: string;
  @Output() onIsExpand = new EventEmitter<boolean>();

  rateServiceType = RateServiceType;
  rateDetailsPanels = RateDetailPanels;
  isExpanded: boolean = false;
  ShipmentType = ShipmentType;

  constructor(private rollupDialogService: RollupDialogService) { }

  ngOnInit(): void {
    this.updatePanel();
  }

  isBasicRateServiceType(): boolean {
    return this.rateServiceType.Basic === this.quoteRateServiceType;
  }

  getRateServiceTypeTooltip(): string {
    switch (this.quoteRateServiceType) {
      case RateServiceType.Premium:
      case RateServiceType.Standard:
      case RateServiceType.Other:
        return `These rates are fixed, and valid for a longer period of time. They are best for planned or recurring shipments.`;
      case RateServiceType.Basic:
        return `These are one-time prices with limited availability based on the current market. These are best for urgent or immediate shipping needs.`;
      default:
        return ``;
    }
  }

  onRateServiceType(): void {
    this.rollupDialogService.open(QuotePriceTiersComparisonComponent);
  }

  onClickPanel(state: boolean): void {
    this.isExpanded = state;
    this.onIsExpand.emit(this.isExpanded);
  }

  updatePanel(): void {
    if (this.currentPanelName === this.rateDetailsPanels.STANDARD.toString()) {
      this.isExpanded = true;
    }
  }
}
