import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PersonalNotification, PersonalNotificationCode, PersonalNotificationType } from './personal-notification.model';

@Injectable({
  providedIn: 'root'
})
export class PersonalNotificationsService {
  updateNotifications = new Subject<void>();

  private notifications: PersonalNotification[] = [];

  constructor() { }

  areAnyNotificationsExist(): boolean {
    return this.notifications.length > 0;
  }

  addNotification(notificationCode: PersonalNotificationCode): void {
    switch (notificationCode) {
      case PersonalNotificationCode.missingTaxIdNotification:
        if (!this.isNotificationAlreadyExists(PersonalNotificationCode.missingTaxIdNotification)){
          this.addMissingTaxIdNotification();
        }
        break;
    
      default:
        break;
    }
    this.updateNotifications.next();
  }

  removeNotification(notificationCode: PersonalNotificationCode): void {
    const index = this.notifications.findIndex(n => n.notificationCode === notificationCode);
    if (index !== -1) this.notifications.splice(index, 1);
    this.updateNotifications.next();
  }

  isNotificationAlreadyExists(notificationCode: PersonalNotificationCode): boolean {
    return !!this.notifications.find(n => n.notificationCode === notificationCode);
  }

  getHighestNotificationType(incomingNotifications?: PersonalNotification[]): PersonalNotificationType | null {
    let notifications = incomingNotifications ?? this.notifications;
    if (notifications.length == 0) return null;

    let highestNotificationType: PersonalNotificationType = null;
    for (let notification of notifications) {
      if (notification.notificationType === PersonalNotificationType.alert)
        return PersonalNotificationType.alert;

      if (notification.notificationType === PersonalNotificationType.requestForAction)
        highestNotificationType = PersonalNotificationType.requestForAction;

      if (notification.notificationType === PersonalNotificationType.informational
        && highestNotificationType !== PersonalNotificationType.requestForAction)
        highestNotificationType = PersonalNotificationType.informational;
    }
    return highestNotificationType;
  }

  areManageOrganizationNotificationsExist(): boolean {
    return this.notifications.some(n => 
      n.notificationCode === PersonalNotificationCode.missingTaxIdNotification);
  }

  getHighestManageOrganizationNotificationsType(): PersonalNotificationType | null {
    let existingManageOrganizationNotifications = this.getExistingNotificationsByCodes(
      [ PersonalNotificationCode.missingTaxIdNotification ]);
    return this.getHighestNotificationType(existingManageOrganizationNotifications);
  }

  getExistingNotificationsByCodes(personalNotificationsCodes: PersonalNotificationCode[]): PersonalNotification[] {
    return this.notifications.filter(n => personalNotificationsCodes.includes(n.notificationCode));
  }

  private addMissingTaxIdNotification(): void {
    this.notifications.push(
      new PersonalNotification(
        PersonalNotificationCode.missingTaxIdNotification,
        PersonalNotificationType.alert));
  }
}
