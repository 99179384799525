<div class="rejected-credit-line-wrapper">
  <div class="rejected-credit-line-container">
    <div class="rejected-credit-line-image">
      <img src="../../../../assets/images/credit-line/rejected.svg" alt="" />
    </div>
    <div class="rejected-credit-limit-content">
      <h3 class="rejected-credit-limit-title">
        Your credit application was rejected
      </h3>
      <p class="rejected-credit-limit-content">
        You may contact our support team with any inqueries
      </p>
    </div>
  </div>
</div>