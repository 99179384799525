<a class="quotes-banner" href="https://share.hsforms.com/1l1DYEOkIS_OFvNyT8NkG3gq1ykl" target="_blank">
  <div class="row m-0">
    <div class="col-auto px-0 d-flex align-items-center">
      <div class="quotes-banner-icon-wrapper">
        <div class="quotes-banner-icon"></div>
      </div>
    </div>
    <div class="col px-3">
      <div class="quotes-banner-content-wrapper">
        <span class="quotes-banner-title">Special Savings</span>
        <span class="quotes-banner-text">Receive an exclusive $300 discount on your next FCL shipment when you book by
          Dec 31st, 2024.</span>
      </div>
    </div>
    <div class="col-auto px-0 d-flex align-items-center">
      <div class="quotes-banner-button-wrapper">
        <a mat-button class="quotes-banner-button">
          Claim Offer Now
        </a>
      </div>
    </div>
  </div>
</a>
