<div class="postcode-dialog-container">
  <div class="row">
    <h4 mat-dialog-title class="postcode-dialog-title col">Post Code</h4>
    <button mat-button mat-icon-button matSuffix (click)="onClose()" class="col-auto postcode-dialog-close-btn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="mat-typography postcode-dialog-content">
    <div class="postcode-dialog-text">
      <div class="pt-2 text-left">
        <span class="field-title">Post code</span>
        <span class="field-title subtitle">Enter at least 4 digits post code</span>
      </div>
      <div class="postcode-dialog">
        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
          <input type="text" matInput placeholder="000000" [formControl]="postCode" />
          <div class="button-spinner" matSuffix>
            <span class="button-inner">
              <mat-spinner diameter="25" *ngIf="isFromLoading"></mat-spinner>
            </span>
          </div>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="postcode-dialog-action-button">
    <button mat-raised-button color="primary" class="postcode-dialog-continue-button"
      [disabled]="!isPostcodeValid" (click)="onContinue()">
      CONTINUE
    </button>
  </mat-dialog-actions>

</div>