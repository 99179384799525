import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { Booking, BookingsQuery, BookingsQueryParameters, BookingsReadMode } from '../../shared/bookings/bookings.model';
import { BookingPaidStatusCode, Page, ShipmentType } from '../../shared/shared.model';
import { environment } from '../../../environments/environment';
import { LayoutService } from '../layout/layout.service';
import { BookingsService } from '../../shared/bookings/bookings.service';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { LayoutMode } from '../layout/layout.model';
import { SalesforceService } from '../../shared/services/salesforce.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinanceComponent implements OnInit {
  isLoading = false;
  bookings: Booking[] = [];
  pageNo: number = 1;
  bookingStatusCodes = BookingPaidStatusCode;

  isMobileDashboardEnabled: boolean;

  @ViewChild('header') header: TemplateRef<ElementRef>;

  constructor(
    private router: Router,
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    private layoutService: LayoutService,
    private salesforceService: SalesforceService
  ) {
    this.isMobileDashboardEnabled = this.getIsMobileDasboardEnabled();
  }

  ngOnInit(): void {
    this.getBookings();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.FINANCE);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(false);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarTitle("Finance");
      this.layoutService.setToolbarDescription("Review and process your payments");
      this.layoutService.setWrapperHeader(this.header);
    }, 0);
  }

  getBookings(): void {
    const query = this.prePareBookingsQueryModel();

    query.hasPayments = true;

    this.isLoading = true;
    this.bookingsService
      .getPage(query)
      .subscribe(
        (x: Page<Booking>) => {
          this.bookings = x.items;
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  openPayments(booking: Booking): void {
    this.router.navigate(['/payments', booking.id]);
  }

  private prePareBookingsQueryModel(): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = this.pageNo;
    query.pageSize = environment.pageSize;
    query.sortDesc = true;
    query.sortBy = BookingsQueryParameters.updateTimeUtc;
    query.isCanceled = false;
    query.readMode = BookingsReadMode.appDashboard;

    return query;
  }

  getMainCarriageIcon(shipmentTypeCode: ShipmentType): string {
    switch (shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
      }
      default: {
        return 'ship';
      }
    }
  }

  async onChatClick(): Promise<void> {
    await this.salesforceService.openChat();
  }

  onSearchQuote(): void {
    this.router.navigate(['/quote-search']);
  }

  private getIsMobileDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isMobileDashboardEnabled;
      case "sb":
        return environment.sb.isMobileDashboardEnabled;
      default:
        return environment.isMobileDashboardEnabled;
    }
  }
}
