<div class="container organization-container">
  <div class="row organization-manager-header">
    <div class="col-12">
      <h1 class="organization-manager-title text-center mt-4">
        Organizations
      </h1>
    </div>
  </div>
  <div class="row justify-content-center organization-manager-content">
    <div class="col-8">
      <app-organizations-list *ngIf="accepted.length > 0" [type]="1" [data]="accepted">
      </app-organizations-list>
      <app-organizations-list *ngIf="pending.length > 0" [type]="2" [data]="pending"
        (refreshPage)="getData()"></app-organizations-list>
      <app-organizations-list *ngIf="invited.length > 0" [type]="3" [data]="invited"></app-organizations-list>
    </div>
    <div class="col-12 text-center mb-5">
      <button *ngIf="isRegisterVisible" mat-raised-button class="next-button mt-2 ms-0" (click)="onRegister()">
        {{ registerTitle }}
      </button>
    </div>
  </div>
</div>
