<div class="quote-search-flow-loader" [ngStyle]="{'is-show-left-side': isShowLeftSide}">
  <div *ngIf="isFull" class="row justify-content-end mb-3">
    <div class="col-auto">
      <div class="loader fh-1-5 fw-9"></div>
    </div>
  </div>
  <div class="row" [ngStyle]="{'margin-top': isFull ? '0' : '2rem'}">
    <div class="col-3" [ngStyle]="{'visibility': isFull ? 'visible' : 'hidden'}" *ngIf="isShowLeftSide">
      <div class="section mb-4" *ngFor="let num of range(7)">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="loader w-100 fh-1"></div>
          </div>
          <div class="col-12 mb-3">
            <div class="loader w-100 fh-1-5"></div>
          </div>
          <div class="col-12">
            <div class="loader w-100 fh-1-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="quote-row mb-4" *ngFor="let num of range(4)">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-12 mb-1">
                <div class="loader fw-5 fh-1"></div>
              </div>
              <div class="col-12 mb-1">
                <div class="loader fw-10 fh-2"></div>
              </div>
              <div class="col-12">
                <div class="loader fw-6-25 fh-1"></div>
              </div>
            </div>
          </div>
          <div class="col-auto d-flex justify-content-center align-items-center">
            <div class="loader fw-1-5 fh-1-5"></div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-1 d-flex justify-content-end">
                <div class="loader fw-5 fh-1"></div>
              </div>
              <div class="col-12 mb-1 d-flex justify-content-end">
                <div class="loader fw-10 fh-2"></div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <div class="loader fw-6-25 fh-1"></div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-1 d-flex justify-content-end">
                <div class="loader fw-5 fh-1"></div>
              </div>
              <div class="col-12 mb-1 d-flex justify-content-end">
                <div class="loader fw-10 fh-2"></div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <div class="loader fw-8-75 fh-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col d-flex justify-content-start align-items-end">
            <div class="loader fw-5-75 fh-1-5 me-4"></div>
            <div class="loader fw-3-5 fh-1-5 me-4"></div>
            <div class="loader fw-3-5 fh-1-5"></div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="loader fw-11 fh-3 me-3"></div>
            <div class="loader fw-3 fh-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>