import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogType, NotificationService } from '@ship4wd/ngx-common';
import { DocumentComment } from '../../../shared/bookings/booking-documents/booking-documents.model';
import { Booking, BookingFile } from '../../../shared/bookings/bookings.model';
import { StringCutterService } from '../../../shared/services/text-cutter/stringCutter.service';
import { BookingDocumentsService } from '../../../shared/bookings/booking-documents/booking-documents.service';
import { CommentsDialogComponent } from './comments-dialog/comments-dialog.component';
import { BookingFilesDocumentsPage } from '../../../shared/shared.model';

@Component({
  selector: 'app-booking-files-documents-list',
  templateUrl: './booking-files-documents-list.component.html',
  styleUrls: ['./booking-files-documents-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingFilesDocumentsListComponent implements OnInit, OnChanges {
  @Input() bookingDocuments: BookingFile[] = [];
  @Input() bookingId: string = undefined;
  @Input() pageType: BookingFilesDocumentsPage = undefined;

  public dataSource = new MatTableDataSource<BookingFile>();
  canView = true;
  isSecuredUrl = true;
  bookingFilesDocumentsPage = BookingFilesDocumentsPage;

  public columns: string[] = [
    'fileName',
    'comment',
    'documentType',
    'uploaded',
    'action'
  ];

  constructor(
    private notificationService: NotificationService,
    private bookingDocumentsService: BookingDocumentsService,
    private stringCutterService: StringCutterService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.updateDataSource(this.bookingDocuments);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.bookingDocuments && changes?.bookingDocuments?.firstChange === false) {
      this.updateDataSource(changes.bookingDocuments.currentValue);
    }
  }

  onEditComment(document: BookingFile) {
    this.openCommentsDialog(document);
  }

  getCommentData(comments: DocumentComment[]) {
    if (comments && comments.length > 0) {
      const lastComment = comments.slice(-1);
      return lastComment[0]?.comment;
    }
    return '';
  }

  onViewFile(file: BookingFile) {
    this.getSecureFileUrl(file);
  }

  private updateDataSource(data: BookingFile[]) {
    this.dataSource.data = data;
  }

  private getSecureFileUrl(file: BookingFile) {
    this.bookingDocumentsService
      .getSignedUrl(this.bookingId, file.id)
      .subscribe(x => {
        window.open(x.url, "_blank");
      },
        error => {
          this.notificationService.notifyDialog(error.error, NotificationDialogType.error)
        });
  }

  private openCommentsDialog(document: BookingFile) {
    const dialogRef = this.dialog.open(CommentsDialogComponent, {
      disableClose: true,
      panelClass: 'document-comments-dialog-container',
      autoFocus: false,
      data: {
        document: document
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.document) {
        const selectedDocument = this.bookingDocuments.find(x => x.id === result.document.id);
        if (selectedDocument !== undefined) {
          const index = this.bookingDocuments.indexOf(selectedDocument);
          this.bookingDocuments[index] = result.document;
          this.updateDataSource(this.bookingDocuments);
        }
      }
    });
  }
}
