import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { NotificationService } from '@ship4wd/ngx-common';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { HarmonizedSystemCode, HarmonizedSystemCodeQuery, HarmonizedSystemCodeQueryParameters, Page } from '../../../../../shared/shared.model';
import { BookingFlowService } from '../../booking-flow.service';
import { Booking, Commodity } from '../../../../../shared/bookings/bookings.model';
import { LinearUnitNamePipe } from '../../../../../shared/features/linear-units/linear-unit-name.pipe';
import { VolumeUnitNamePipe } from '../../../../../shared/pipes/volume-unit-name.pipe';
import { PackageType } from '../../../../../shared/package-types/package-types.model';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { CommodityService } from '../../../../../shared/services/commodity.service';

@Component({
  selector: 'app-booking-flow-load-details',
  templateUrl: 'load-details.component.html',
  styleUrls: ["./load-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'copy-container-overlay' }
    }
  ]
})

export class BookingFlowLoadDetailsComponent implements OnInit {
  index: number = 0;
  booking: Booking = null;
  commodityForm: FormGroup = null;
  containers: FormArray = null;
  filteredCommodities: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];
  commodityResponse: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];

  isLoading: boolean = false;
  isToIsrael: boolean = false;

  get isValid(): boolean {
    return this.commodityForm.valid;
  }

  constructor(
    public dialogRef: RollupDialogService,
    private bookingFlowService: BookingFlowService,
    private linearUnitNamePipe: LinearUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
    private commodityService: CommodityService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.booking = data.booking;
    this.index = data.index;
    this.commodityForm = data.current;
    this.containers = data.containers;
    this.bookingFlowService.booking = data.booking;

    if (this.booking.to.substring(0, 2) == "IL") {
      this.isToIsrael = true;
    }

    this.getCommodities();
  }

  ngOnInit(): void { }

  onCheckValid(control: FormControl | AbstractControl): boolean {
    return !control?.valid && control?.touched
  }

  onSelectCommodity(event: MatSelectChange, commodityForm: FormGroup, index: number): void {
    commodityForm.get('commodityObject').setValue(event?.value);
    commodityForm.get('commodityTypeCode').setValue(event?.value?.id);
    commodityForm.get('commodityDescription').setValue(event?.value?.description);

    this.onValueChanged('Commodity Code', index);
  }

  onSelectPackageType(packageType: PackageType | null, commodityForm: FormGroup, index: number): void {
    commodityForm.get('packageTypeCode').setValue(packageType ? packageType.id : null);

    this.onValueChanged('Type of Packaging', index);
  }


  onConfirm(): void {
    this.commodityForm.markAllAsTouched();

    if (this.isValid) {
      const data = {
        commodity: this.commodityForm
      }

      this.dialogRef.close(data);
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  getDimensions(commodityForm: FormGroup): string {
    const id = commodityForm.get('id').value;
    let commodity: Commodity = null;
    let dimension = '';
    this.booking.containers.forEach((x) => {
      const filteredCommodity = x.commodities.find((y) => y.id === id);
      if (filteredCommodity !== undefined) {
        commodity = filteredCommodity;
        return;
      }
    });

    if (commodity !== null && commodity.dimension) {
      if (commodity.dimension?.width != null
        && commodity.dimension?.height !== null
        && commodity.dimension?.length !== null) {
        dimension += `${commodity.dimension?.width} x `;

        dimension += `${commodity.dimension?.height} x `;

        dimension += `${commodity.dimension?.length}

      ${this.linearUnitNamePipe.transform(
          commodity.dimension?.lengthUnitCode
        )} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity.volume, '1.2-2')}

      ${this.volumeUnitNamePipe.transform(
        commodity.volumeUnitCode
      )}`;
    }

    return dimension;
  }

  getFormControlValue(container: FormGroup, key: string): any {
    return container.get(key).value;
  }

  onValueChanged(fieldName: string, index: number): void {
    this.googleAnalyticsService.cargoDetailValueChanged(fieldName, index);
  }

  onFocus(fieldName: string, index: number, formControlName: string): void {
    if (!this.isFieldAlreadyTouched(formControlName))
      this.googleAnalyticsService.cargoDetailsTouched(fieldName, index);
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Cargo Details",
      fieldName,
      errorValue
    })
  }

  private isFieldAlreadyTouched(formControlName: string): boolean {
    return this.containers.controls.some((container: FormGroup) => {
      const commodities = container.controls["commodities"] as FormArray;
      return commodities.controls.some((commodity: FormGroup) => commodity.get(formControlName)?.touched);
    });
  }

  private getCommodities(): void {
    this.isLoading = true;
    let query = new HarmonizedSystemCodeQuery();
    query.pageNo = 1;
    query.pageSize = 500;
    query.any = true;
    query.sortBy = HarmonizedSystemCodeQueryParameters.description;
    this.commodityService
      .getPage(query)
      .subscribe(
        (x: Page<HarmonizedSystemCode>) => {
          this.commodityResponse = x.items;
          this.filteredCommodities = this.commodityResponse;
          const otherCommodities = this.filteredCommodities.filter(item => item.description === 'Other Commodities');
          const restOfItems = this.filteredCommodities.filter(item => item.description !== 'Other Commodities');
          this.filteredCommodities = [...restOfItems, ...otherCommodities];
          return this.filteredCommodities;
        },
        error => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }
}
