import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import {
  CurrencyCode,
  PaymentReasonCode,
  PaymentStatusTransition,
  ShipmentType,
  VolumeUnit,
  WeightUnit,
} from '../../../shared/shared.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import {
  AnotherReference,
  Booking,
  BookingNoteAccessType,
  BookingStatusCode,
  BookingSteps,
  BookingSummary,
  BookingViewModel,
  Commodity,
  Contact,
  ContactType,
  Container,
  Note,
  UpdateBookingStatus,
} from "../../../shared/bookings/bookings.model";
import { BookingContactsService } from '../../../shared/bookings/booking-contacts/booking-contacts.service';
import { environment } from './../../../../environments/environment';
import { UtilityService } from '../../../shared/helper/utility.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { PaymentsService } from '../../../shared/payments/payments.service';
import { Payment, PaymentRequest, UpdatePayment } from '../../../shared/payments/payments.model';
import { FourtySeasPaymentService } from '../../../shared/payments/fourty-seas-payment.service';
import { PaymentStatusCode } from '../../../shared/shared.model';
import { OrganizationsService } from '../../organizations/organizations.service';
import { Organization, TeamMember } from '../../manage-organization/manage-organization.model';
import { NoBookingDialogComponent } from './../no-booking-dialog/no-booking-dialog.component';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { LegalNameDialogComponent } from '../../../shared/legal-name-dialog/legal-name-dialog.component';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-booking-tabs',
  templateUrl: './booking-tabs.component.html',
  styleUrls: ['./booking-tabs.component.scss']
})
export class BookingTabsComponent implements OnInit {
  bookingForm: FormGroup;
  shipmentTypeCode: ShipmentType;
  bookingId: string;
  booking: Booking;
  isDoorPickup: boolean;
  isDoorDelivery: boolean;
  bookingSteps = BookingSteps;
  shipperContact: Contact = null;
  consigneeContact: Contact = null;
  notifyPartyContact: Contact = null;
  collectContact: Contact;
  dropContact: Contact;
  isLoading = false;
  isNextStepLoading = false;
  isEditBooking = false;
  lastSelectedTabIndex = 0;
  tabs = {
    CustomerReference: 0,
    Contacts: 1,
    Containers: 2,
    Documents: 3,
    Summary: 4,
  };
  bookingSummary: BookingSummary;

  @ViewChild("bookingTabGroup", { static: false })
  bookingTabGroup: MatTabGroup;

  @ViewChild("legalNameTemplate")
  legalNameTemplate: TemplateRef<ElementRef>;

  get anotherReferencesFormArray(): FormArray {
    return this.bookingForm.get("anotherReferences") as FormArray;
  }

  get notesFormArray(): FormArray {
    return this.bookingForm.get("notes") as FormArray;
  }

  get containersFormArray(): FormArray {
    return this.bookingForm.get("containers") as FormArray;
  }

  public bookingStatuses = BookingStatusCode;
  isViewOnly = false;
  isViewOrganizationContacts = false;
  isStepContactsNextButtonPress = false;
  isFinanceEnabled: boolean;
  paymentRequestId: string;
  creditOrganization = false;
  teamMember: TeamMember;
  organization: Organization;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    private bookingContactsService: BookingContactsService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private location: Location,
    private userInfoService: UserInfoService,
    private datePipe: DatePipe,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private organizationsService: OrganizationsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.bookingId = this.route.snapshot.params.id;
    this.isEditBooking = this.route.snapshot.params.isEdit;
    this.isFinanceEnabled = this.getIsFinanceEnabled();

    if (this.lastSelectedTabIndex == 0) {
      this.setURLWithStepName();
    }

    this.getBookingById(this.bookingId);
  }

  ngOnInit(): void {
    this.loadOrganization();

    this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
      if (next) {
        this.isNextStepLoading = false
      }
    });

    this.getShipmentTypebyBookingId();
  }

  onGenerateRefID() {
    this.bookingForm.get('customerReferenceId').setValue(this.generateReferenceId());

    var containers = this.bookingForm.get('containers') as FormArray;
    containers.controls.forEach(container => {
      container.get('collectionReference').patchValue(this.bookingForm.get('customerReferenceId').value);
    });
  }

  onAddAnotherReference() {
    this.anotherReferencesFormArray.push(this.createAnotherReferencesForm());
  }

  onRemoveAnotherReference(index) {
    this.anotherReferencesFormArray.removeAt(index);
  }

  onAddCommodity(container: FormGroup) {
    const commodities = container.get("commodities") as FormArray;
    commodities.push(this.createCommodityForm());
  }

  onDeleteCommodity(result) {
    const commodities = result?.container.get("commodities") as FormArray;
    commodities.removeAt(result?.index);
  }

  prevStep(tabGroup: MatTabGroup) {
    tabGroup.selectedIndex = tabGroup.selectedIndex - 1;
    this.lastSelectedTabIndex = tabGroup.selectedIndex - 1;
    this.setURLWithStepName();
  }

  nextStep(tabGroup: MatTabGroup) {
    let isValid = true;
    isValid = this.validateBookingStep(tabGroup.selectedIndex + 1);
    if (isValid) {
      this.goNextStep();
    }
  }

  onTabChange(tabGroup: MatTabGroup) {
    this.lastSelectedTabIndex = tabGroup.selectedIndex;
    this.setURLWithStepName();
  }

  onSubmitBooking(isCreditOrganization: boolean): void {
    if (this.isNextStepLoading == true)
      return;

    if (this.hasOrganizationMissingData()) {
      const dialogRef = this.dialog.open(LegalNameDialogComponent, {
        autoFocus: false,
        data: {
          organization: this.organization
        },
        panelClass: 'legal-name-dialog-container'
      }).afterClosed().subscribe(
        (data) => {
          if (data?.result === true) {
            if (this.isFinanceEnabled)
              this.submitBooking(isCreditOrganization);
            else
              this.submitBookingWithoutFinance();
          }
          else {
            return;
          }
        });
    }
    else {
      if (this.isFinanceEnabled)
        this.submitBooking(isCreditOrganization);
      else
        this.submitBookingWithoutFinance();
    }
  }

  onSaveDraft() {
    this.navigateToDashboard();
  }

  onChangeTermsAndConditions(termsAndCondition) {
    this.updateTermsAndConditions(termsAndCondition.checked);
  }

  onViewBooking(viewOnly: boolean) {
    this.isViewOnly = viewOnly;
  }

  onBack(viewOrganizationContacts: boolean) {
    this.isViewOrganizationContacts = viewOrganizationContacts;
  }

  onSelectedContact(selectedContact: any) {
    this.getBookingContacts(this.bookingId);
  }

  onRemoveBookingContact(contactType: ContactType) {
    this.setBookingContactData(contactType, null);
  }

  getContainerTabTitle(): string {
    if (this.shipmentTypeCode == ShipmentType.AIR) {
      return 'Cargo';
    } else {
      return 'Containers';
    }
  }

  getContainerTabSubtitle(): string {
    if (this.shipmentTypeCode == ShipmentType.AIR) {
      return 'Details';
    } else {
      return 'Information';
    }
  }

  private getShipmentTypebyBookingId(): void {
    this.bookingsService.getById(this.bookingId)
      .subscribe(booking => {
        this.shipmentTypeCode = booking.shipmentTypeCode;
      })
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private getBookingContacts(bookingId: string) {
    this.bookingContactsService
      .getByBookingId(bookingId)
      .subscribe((x: Array<Contact>) => {
        this.setBookingContacts(x);
      },
        error => {
          this.notificationService.error(error)
        });
  }

  private setBookingContacts(contacts: Array<Contact>): void {
    contacts.forEach(x => this.setBookingContactData(x.contactTypeCode, x));
  }

  private setBookingContactData(contactType: ContactType, bookingContact: Contact) {
    if (contactType === ContactType.shipper) {
      this.shipperContact = bookingContact;
      if (this.booking.serviceType > 0 && this.utilityService.isDoorPickupService(this.booking.serviceType)) {
        this.assignShipperContactToCollectContact(this.shipperContact);
      }
    }
    else if (contactType === ContactType.consignee) {
      this.consigneeContact = bookingContact;
      if (this.booking.serviceType > 0 && this.utilityService.isDoorDeliveryService(this.booking.serviceType)) {
        this.assignConsigneeContactToDropContact(this.consigneeContact);
      }
    }
    else if (contactType === ContactType.notifyParty) {
      this.notifyPartyContact = bookingContact;
    }
    else if (contactType === ContactType.drop) {
      this.dropContact = bookingContact;
    }
  }

  private assignShipperContactToCollectContact(shipperContact: Contact) {
    let collect = { ...shipperContact };
    if (shipperContact != null) {
      collect.id = null;
      collect.contactTypeCode = ContactType.collect;
    }
    else {
      collect = null;
    }

    this.containersFormArray?.controls.forEach(x => {
      x.get('collectContact').patchValue(collect);
    });
    this.collectContact = collect;
  }

  private assignConsigneeContactToDropContact(consigneeContact: Contact) {
    let drop = { ...consigneeContact };
    if (consigneeContact != null) {
      drop.id = null;
      drop.contactTypeCode = ContactType.drop;
    }
    else {
      drop = null;
    }

    this.containersFormArray?.controls.forEach(x => {
      x.get('dropContact').patchValue(drop);
    });
    this.dropContact = drop;
  }

  private getCollectContactByShipperContact() {
    if (!this.utilityService.isDoorPickupService(this.booking.serviceType) || this.shipperContact == null)
      return null;

    const collect = { ...this.shipperContact };
    collect.id = null;
    collect.contactTypeCode = ContactType.collect;
    return collect;
  }

  private getDropContactByConsigneeContact() {
    if (!this.utilityService.isDoorDeliveryService(this.booking.serviceType) || this.consigneeContact == null)
      return null;

    const drop = { ...this.consigneeContact };
    drop.id = null;
    drop.contactTypeCode = ContactType.drop;
    return drop;
  }

  private updateTermsAndConditions(isEnabled: boolean) {
    this.bookingsService
      .updateTermsAndConditions(this.booking.id, isEnabled)
      .subscribe((x: Booking) => {
        this.booking.isTermsAndConditionsConfirmed = x.isTermsAndConditionsConfirmed;
      },
        (error) => this.notificationService.error(error)
      );
  }

  private getBookingById(id: string) {
    this.isLoading = true;
    this.bookingsService
      .getById(id)
      .subscribe(
        (booking: Booking) => {
          const updateBookingModel = this.prepareBookingViewModel(booking);
          this.updateBookingForm(updateBookingModel);
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private validateBookingStep(step: number) {
    if (step === this.bookingSteps.stepCustomerRef) {
      return this.bookingStepCustomerRefProvided();
    } else if (step === this.bookingSteps.stepContacts) {
      return this.bookingStepContactsProvided();
    } else if (step === this.bookingSteps.stepCargo) {
      return this.bookingStepCargoProvided();
    } else if (step === this.bookingSteps.stepDocuments) {
      return this.bookingStepDocumentsProvided();
    } else {
      return true;
    }
  }

  private bookingStepCustomerRefProvided(): boolean {
    let valid = true;
    if (!this.requiredIf("customerReferenceId")) {
      valid = false;
    }
    if (!this.requiredIf("anotherReferences")) {
      valid = false;
    }
    if (!this.requiredIf("notes")) {
      valid = false;
    }

    if (valid && this.booking.statusType.code !== BookingStatusCode.draft) {
      this.updateBooking(BookingSteps.stepCustomerRef);
      return false;
    } else {
      return valid;
    }
  }

  private updateBooking(step: BookingSteps) {
    this.isNextStepLoading = true;
    const booking = this.bookingForm.value as Booking;
    let hasSameCommodities = false;

    booking.containers.forEach(x => {
      if (x.collectionDate) {
        x.collectionDate = this.toIsoDateTimeString(x.collectionDate);
      }
      if (x.cargoExpectedReady) {
        x.cargoExpectedReady = this.toIsoDateTimeString(x.cargoExpectedReady);
      }

      if (this.shipmentTypeCode !== ShipmentType.AIR && this.shipmentTypeCode !== ShipmentType.LCL) {
        hasSameCommodities = new Set(x.commodities.map(({ commodityTypeCode, packageTypeCode }) =>
          `commodityTypeCode|${commodityTypeCode}|packageTypeCode|${packageTypeCode}`)).size < x.commodities.length
      }
    });

    if (hasSameCommodities) {
      this.notificationService.error('Commodities code and package type combination should be unique for container.');
      this.isNextStepLoading = false;
      return;
    }

    booking.notes.forEach(note => {
      booking.notes = note.value.trim() == '' ?
        booking.notes.filter(n => n != note) : booking.notes;
    });

    if (this.shipmentTypeCode == ShipmentType.FCL) {
      let condition = true;
      let count = 0;

      booking.containers.forEach(container => {
        this.bookingsService
          .getEquipment(container.equipmentTypeCode)
          .subscribe((response: any) => {
            if (response.equipment) {
              count++;
              const maxWeight = response.equipment.payloadCapacityWeight;
              const cubicCapacity = response.equipment.cubicCapacity;

              let selectedMaxWeight = 0;
              let selectedCubicCapacity = 0;
              container.commodities.forEach(commodity => {
                selectedMaxWeight = selectedMaxWeight + Number(commodity.weight);
                selectedCubicCapacity = selectedCubicCapacity + Number(commodity.volume);
              });
              if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
                condition = false;
              }

              if (count == booking.containers.length && condition) {
                this.bookingsService
                  .updateBooking(this.bookingId, booking)
                  .subscribe(
                    (booking: Booking) => {
                      if (step === BookingSteps.stepCustomerRef || step === BookingSteps.stepCargo) {
                        this.goNextStep();
                      }
                    },
                    (error) => this.notificationService.error(error)
                  )
                  .add(() => (this.isNextStepLoading = false));
              } else if (count == booking.containers.length && !condition) {
                this.openNoBookingDialog();
                this.isNextStepLoading = false;
              }
            }
          });
      });
    } else {
      let maxWeight = environment.lclMaxWeight;
      let cubicCapacity = environment.lclMaxCapacity;
      var condition = true;

      if ((this.shipmentTypeCode == ShipmentType.LCL)) {
        booking.containers.forEach(container => {
          container.commodities.forEach(commodity => {
            maxWeight = commodity.weightUnitCode == WeightUnit.KG ? environment.lclMaxWeight : environment.lclMaxWeight * 2.20462;
            cubicCapacity = commodity.volumeUnitCode == VolumeUnit.CBM ? environment.lclMaxCapacity : environment.lclMaxCapacity * 61023.7441;

            const selectedMaxWeight = commodity.weight;
            const selectedCubicCapacity = commodity.volume;

            if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
              condition = false;
            }
          })
        });
      }

      if (condition) {
        this.bookingsService
          .updateBooking(this.bookingId, booking)
          .subscribe(
            (booking: Booking) => {
              if (step === BookingSteps.stepCustomerRef || step === BookingSteps.stepCargo) {
                this.goNextStep();
              }
            },
            (error) => this.notificationService.error(error)
          )
          .add(() => (this.isNextStepLoading = false));
      } else {
        this.openNoBookingDialog();
        this.isNextStepLoading = false;
      }
    }
  }

  private openNoBookingDialog() {
    const dialogRef = this.dialog.open(NoBookingDialogComponent, {
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {}
    });
  }

  private updateBookingStatus(): Observable<Booking | any> {
    const bookingStatus = {
      shipmentType: this.booking.shipmentTypeCode,
      ship4wdCode: BookingStatusCode.orderSubmitted
    } as UpdateBookingStatus;

    return this.bookingsService
      .updateBookingStatus(this.booking.id, bookingStatus);
  }

  private createPaymentRequest(): Observable<string | any> {
    const paymentRequest = {
      bookingId: this.booking.id,
    } as PaymentRequest;

    return this.paymentsService.addPaymentRequest(paymentRequest);
  }

  private createPayment(paymentRequestId: string, isCreditOrganization: boolean): Observable<string | any> {
    const payment = {
      paymentRequestId: paymentRequestId,
      organizationId: this.booking.organizationId,
      teamMemberId: this.teamMember.id
    } as Payment;

    if (isCreditOrganization) {
      payment.externalStatus = 'Pending vessel departure date';
      payment.paymentStatusCode = PaymentStatusCode.draft
    }

    return this.paymentsService.addPayment(payment);
  }

  public openPopup(title: string, message: string, isSuccess: boolean) {
    const url = document.location.href.replace(window.location.origin, "");

    if (isSuccess) {
      const successUrl = this.getUrl(true, url);
      this.location.go(successUrl);
    } else {
      const failedUrl = this.getUrl(false, url);
      this.location.go(failedUrl);
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {
        title: title,
        message: message,
        showConfirmButton: true,
        confirmButtonText: "Close"
      },
    }).afterClosed().subscribe(() => {
      if (isSuccess) {
        this.navigateToDashboard();
      }
      const combined = this.removeParamFromUrl(url);
      this.location.go(combined);
    });
  }

  public removeParamFromUrl(url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];
    const combined = firstPart + '#' + secondPart;
    return combined;
  }

  public getUrl(isSuccess: boolean, url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];

    if (!isSuccess) {
      return firstPart + '&p=failed#' + secondPart;
    } else {
      return firstPart + '&p=submitted#' + secondPart;
    }
  }

  private navigateToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  private goNextStep() {
    this.bookingTabGroup.selectedIndex = this.bookingTabGroup.selectedIndex + 1;
    this.lastSelectedTabIndex = this.bookingTabGroup.selectedIndex + 1;
    this.setURLWithStepName();
  }

  private prepareBookingViewModel(booking: Booking) {
    const updateBookingViewModel: BookingViewModel = new BookingViewModel();
    updateBookingViewModel.customerReferenceId = booking.customerReferenceId;
    updateBookingViewModel.isTermsAndConditionsConfirmed =
      booking.isTermsAndConditionsConfirmed;
    updateBookingViewModel.containers = booking.containers;
    updateBookingViewModel.anotherReferences = booking.anotherReferences;
    updateBookingViewModel.notes = booking.notes;
    this.shipmentTypeCode = booking.shipmentTypeCode;
    this.isDoorPickup = this.utilityService.isDoorPickupService(booking.serviceType);
    this.isDoorDelivery = this.utilityService.isDoorDeliveryService(booking.serviceType);
    this.booking = booking;
    this.shipperContact = booking.shipperContact;
    this.consigneeContact = booking.consigneeContact;
    this.notifyPartyContact = booking.notifyPartyContact;
    return updateBookingViewModel;
  }

  private toIsoDateTimeString(date) {
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  private updateBookingForm(updateBookingModel: BookingViewModel) {
    this.bookingForm = this.fb.group({
      customerReferenceId: [
        updateBookingModel?.customerReferenceId
          ? updateBookingModel.customerReferenceId
          : "",
        Validators.required,
      ],
      isTermsAndConditionsConfirmed: [
        updateBookingModel?.isTermsAndConditionsConfirmed
          ? updateBookingModel?.isTermsAndConditionsConfirmed
          : true,
      ],
      anotherReferences: this.fb.array([]),
      notes: this.fb.array([]),
      containers: this.fb.array([]),
    });
    this.setAnotherReferences(updateBookingModel.anotherReferences);
    this.setNotes(updateBookingModel.notes);
    this.setContainers(updateBookingModel.containers);
  }

  private setAnotherReferences(anotherReferences: AnotherReference[]) {
    anotherReferences.map((anotherReference) => {
      this.anotherReferencesFormArray.push(
        this.createAnotherReferencesForm(anotherReference)
      );
    });
  }

  private createAnotherReferencesForm(anotherReference?: AnotherReference) {
    return this.fb.group({
      name: [anotherReference ? anotherReference.name : ""],
      value: [anotherReference ? anotherReference.value : ""],
    });
  }

  private setNotes(notes: Note[]) {
    if (notes.length > 0) {
      notes.map((note) => {
        this.notesFormArray.push(this.createNotesForm(note));
      });
    } else {
      this.notesFormArray.push(this.createNotesForm());
    }
  }

  private createNotesForm(note?: Note) {
    return this.fb.group({
      id: [note ? note.id : null],
      bookingId: [note ? note.bookingId : ''],
      value: [note ? note.value : ''],
      createTimeUtc: [note ? note.createTimeUtc : ''],
      updateTimeUtc: [note ? note.updateTimeUtc : ''],
      noteAccessType: [note ? note.noteAccessType : BookingNoteAccessType.customer],
      userId: [note ? note.userId : null],
      userName: [note ? note.userName : '']
    });
  }

  private setContainers(containers: Container[]) {
    containers.map((container) => {
      this.containersFormArray.push(this.createContainerForm(container));
    });
  }

  private createContainerForm(container?: Container) {
    if (container != null && container.dropContact == null) {
      container.dropContact = this.getDropContactByConsigneeContact();
    }

    if (container != null && container.collectContact == null) {
      container.collectContact = this.getCollectContactByShipperContact();
    }

    return this.fb.group({
      id: [container ? container.id : ""],
      equipmentTypeCode: [
        container ? container.equipmentTypeCode : "",
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoName: [container ? container.cargoName : null, Validators.required],
      cargoGrossWeight: [
        container ? container.cargoGrossWeight : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoGrossWeightUnitCode: [
        container && container.cargoGrossWeightUnitCode
          ? container.cargoGrossWeightUnitCode
          : WeightUnit.KG,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoExpectedReady: [container ? container.cargoExpectedReady : null],
      collectionReference: [container ? container.collectionReference : ""],
      collectionDate: [container ? container.collectionDate : null, this.isDoorPickup ? Validators.required : null],
      collectContact: [container ? container.collectContact : null, this.isDoorPickup ? Validators.required : null],
      equipmentTypeDescription: [
        container ? container.equipmentTypeDescription : null,
      ],
      dropContact: [container ? container.dropContact : null, this.isDoorDelivery ? Validators.required : null],
      commodities: this.fb.array(
        this.setCommodities(container.commodities ?? undefined)
      ),
    });
  }

  private setCommodities(commodities: Commodity[]) {
    return commodities.map((commodity) => {
      return this.createCommodityForm(commodity);
    });
  }

  private createCommodityForm(commodity?: Commodity) {
    return this.fb.group({
      id: [commodity ? commodity.id : null],
      packageTypeCode: [
        commodity && commodity.packageTypeCode ? commodity.packageTypeCode : "",
        Validators.required,
      ],
      commodityDescription: [commodity ? commodity.commodityDescription : null],
      commodityTypeCode: [
        commodity ? commodity.commodityTypeCode : "",
        Validators.required,
      ],
      numberOfPackages: [
        commodity ? commodity.numberOfPackages : null,
        [Validators.required, Validators.min(1)],
      ],
      productValue: [
        commodity ? commodity.productValue : null,
        Validators.required,
      ],
      productValueCurrencyCode: [
        commodity && commodity.productValueCurrencyCode
          ? commodity.productValueCurrencyCode
          : CurrencyCode.USD,
        Validators.required,
      ],
      volume: [
        commodity ? commodity.volume : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL ? Validators.required : [],
      ],
      volumeUnitCode: [
        commodity && commodity.volumeUnitCode
          ? commodity.volumeUnitCode
          : VolumeUnit.CBM,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      weight: [
        commodity ? commodity.weight : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      weightUnitCode: [
        commodity && commodity.weightUnitCode
          ? commodity.weightUnitCode
          : WeightUnit.KG,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      marksAndNumbers: [commodity ? commodity.marksAndNumbers : null],
    });
  }

  private requiredIf(controlName: string) {
    const isValid = this.bookingForm.controls[controlName].valid;
    if (this.bookingForm.controls[controlName] instanceof FormControl) {
      if (!isValid) {
        this.bookingForm.controls[controlName].markAsTouched();
      }
    } else if (this.bookingForm.controls[controlName] instanceof FormArray) {
      (this.bookingForm.controls[controlName] as FormArray).controls.forEach(
        (element) => {
          if (element instanceof FormGroup) {
            Object.keys(element.controls).forEach((key) => {
              const childControl = element.get(key);
              if (childControl instanceof FormControl) {
                if (!childControl.valid) {
                  childControl.markAsTouched();
                }
              } else if (childControl instanceof FormArray) {
                (childControl as FormArray).controls.forEach((subElement) => {
                  if (subElement instanceof FormGroup) {
                    Object.keys(subElement.controls).forEach((subKey) => {
                      const subChildControl = subElement.get(subKey);
                      if (!subChildControl.valid) {
                        subChildControl.markAsTouched();
                      }
                    });
                  }
                });
              }
            });
          }
        }
      );
    }
    return isValid;
  }

  private bookingStepDocumentsProvided() {
    this.isNextStepLoading = true;
    of(null)
      .pipe(
        switchMap(() => this.getBookingSummaryById(this.booking.id)),
        tap((x: BookingSummary) => {
          this.bookingSummary = x;
        }),
        switchMap(() => this.getOrganizationById(this.booking.organizationId)),
        tap((x: Organization) => {
          this.organization = x;
          this.creditOrganization = this.organization.creditOrganization;
        }))
      .subscribe(() => {
        this.goNextStep();
      },
        (error) => {
          if (error.error == "Payment not enabled.") {
            this.notificationService.error(error);
            this.navigateToDashboard();
          } else {
            this.googleAnalyticsService.submitBookingFailed();
            this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
          }
        }).add(() => (this.isNextStepLoading = false));
    return false;
  }

  private getBookingSummaryById(id: string): Observable<BookingSummary | any> {
    return this.bookingsService
      .getSummaryById(id);
  }

  private getOrganizationById(organizationId: string): Observable<Organization | any> {
    return this.organizationsService.getOrganization(organizationId);
  }

  private submitBooking(isCreditOrganization: boolean): void {
    this.isNextStepLoading = true;
    of(null)
      .pipe(
        switchMap(() => this.updateBookingStatus()),
        switchMap(() => this.createPaymentRequest()),
        tap((paymentRequestId) => {
          this.paymentRequestId = paymentRequestId;
        }),
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => this.createPayment(this.paymentRequestId, isCreditOrganization)),
        switchMap((paymentId) => {
          var updatePayment = {
            id: paymentId,
            transition: PaymentStatusTransition.checkoutInitialized,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return !isCreditOrganization ?
            this.paymentsService.updatePayment(updatePayment) :
            of(null);
        }),
        switchMap(() => {
          return !isCreditOrganization ?
            this.fourtySeasPaymentService.init(this.paymentRequestId, this.teamMember.id, this.booking,
              PaymentStatusTransition.checkoutInitialized, "dashboard") :
            of(null);
        }))
      .subscribe(() => {
        if (isCreditOrganization) {
          this.navigateToDashboard();
        }
      },
        (error) => {
          if (error.error == "Payment not enabled.") {
            this.notificationService.error(error);
            this.navigateToDashboard();
          } else {
            this.googleAnalyticsService.submitBookingFailed();
            this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
          }
        }).add(() => (this.isNextStepLoading = false));
  }

  private submitBookingWithoutFinance(): void {
    this.isNextStepLoading = true;
    of(null)
      .pipe(
        switchMap(() => this.updateBookingStatus()),
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }))
      .subscribe(() => { this.navigateToDashboard() },
        (error) => {
          this.googleAnalyticsService.submitBookingFailed();
          this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
        }).add(() => (this.isNextStepLoading = false));
  }

  private bookingStepContactsProvided(): boolean {
    this.isStepContactsNextButtonPress = true;
    let valid = true;
    if (this.shipperContact === null) {
      valid = false;
    }
    else if (this.consigneeContact === null) {
      valid = false;
    }
    else if (this.notifyPartyContact === null) {
      valid = false;
    }
    return valid;
  }

  private bookingStepCargoProvided(): boolean {
    let valid = true;
    if (!this.requiredIf("containers")) {
      valid = false;
    }
    if (valid) {
      this.updateBooking(BookingSteps.stepCargo);
      return false;
    } else {
      return valid;
    }
  }

  private setURLWithStepName() {
    const stepName = Object.keys(this.tabs)[this.lastSelectedTabIndex] as string;
    const url = this.router.createUrlTree([], { relativeTo: this.route }).toString() + '#' + stepName?.toLowerCase();
    this.location.go(url);
  }

  private generateReferenceId(): string {
    return this.userInfoService.getSettings().acronymName + this.datePipe.transform(new Date(), 'ddMMyymm');
  }

  private loadOrganization(): void {
    const organizationId = this.settingsService.getSettings().organizationId;
    this.getOrganizationById(organizationId).subscribe((data) => {
      this.organization = data;
    });
  }

  private hasOrganizationMissingData(): boolean {
    return this.organization.legalName == null
      || this.organization.legalName.length <= 0
      || this.isInvalidTaxId(this.organization.taxId);
  }

  private isInvalidTaxId(taxId: string): boolean {
    return !taxId || taxId.toLowerCase() == 'taxid';
  }
}
