<div *ngIf="document">
  <div class="document-comments-container">
    <div class="document-comments-header">
      <h4 class="document-comments-title">Comments</h4>
      <button mat-icon-button class="close-button" (click)="onCancel()" matTooltip="Close"></button>
    </div>
    <div class="document-comments-content-container">
      <div class="document-comment-row" *ngFor="let comment of document.comments">
        <div class="document-comment-content">
          <div class="document-comment-name-content">
            <span class="comment-name">{{ comment.userName | titlecase }}</span>
          </div>
          <div class="document-comment-date-content">
            <span class="comment-date">
              {{ comment.createTimeUtc | date: "dd MMM, yyyy, hh:mm a" }}
            </span>
          </div>
          <div class="document-comment-bottom-content">
            <span class="comment-text">{{ comment.comment }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="add-document-comment-wrapper">
      <div class="comment-field w-100" [ngClass]="{ 'invalid-error-box': !commentControl?.value
        && commentControl?.touched }">
        <mat-form-field floatLabel="never" class="w-100">
          <input matInput placeholder="Type your comments" maxlength="2000" [formControl]="commentControl">
        </mat-form-field>
      </div>
      <button mat-raised-button class="next-button send-button button-spinner" (click)="onSendComment()">
        <span class="button-inner">
          Send
          <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>