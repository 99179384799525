import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  Booking,
  Contact,
  ContactType,
} from '../../../../shared/bookings/bookings.model';

@Component({
  selector: "app-booking-contacts",
  templateUrl: "./booking-contacts.component.html",
  styleUrls: ["./booking-contacts.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BookingContactsComponent {
  contactTypes = ContactType;
  bookingContactId: string;

  @Input() shipperContact: Contact;
  @Input() consigneeContact: Contact;
  @Input() notifyPartyContact: Contact;
  @Input() booking: Booking;
  @Input() isStepContactsNextButtonPress: boolean;

  @Output() selectedBookingContact = new EventEmitter<Contact>();
  @Output() removeBookingContact = new EventEmitter<ContactType>();

  isViewOrganizationContacts = false;
  @Output() backEvent = new EventEmitter<boolean>();
  selectedContactType: ContactType;
  @Output() selectedContact = new EventEmitter();

  constructor(
    public dialog: MatDialog) {
  }

  renderContactData(contact?: Contact) {
    let data = "";
    if (!contact) {
      return data;
    }

    data = this.appendContactData(data, contact.companyName);
    data = this.appendContactData(data, contact.address1);
    data = this.appendContactData(data, contact.address2);
    data = this.appendContactData(data, contact.city);
    data = this.appendContactData(data, contact.zipCode);
    data = this.appendContactData(data, contact.state);
    data = this.appendContactData(data, contact.countryCode);
    return data;
  }

  onRemoveBookingContact(contactType: ContactType) {
    this.removeBookingContact.emit(contactType);
  }

  viewOrganizationContacts(contactType: ContactType) {
    this.isViewOrganizationContacts = true;
    this.selectedContactType = contactType;
    this.backEvent.emit(true);
  }

  onBack() {
    this.isViewOrganizationContacts = false;
    this.backEvent.emit(false);
  }

  onSelectedContact(bookingContact: any) {
    this.onBack();
    this.selectedContact.emit(bookingContact);
  }

  private appendContactData(data: string, value: string) {
    if (data !== "") {
      data += ",";
    }
    data += value;
    return data;
  }
}
