<div class="booking-contacts-container">
  <div class="booking-contacts-container-wrapper">
    <mat-card>
      <div class="row m-0">
        <div class="booking-left-content-details">
          <div class="booking-contacts-left-section">
            <div class="page-header">
              <h2 class="page-header-title">Manage contacts</h2>
              <h4 class="page-header-sub-title">
                Select, edit or create a {{ selectedContactTitle }} in {{ contactCountryCode }}
              </h4>
              <div class="page-header-wrapper">
                <div class="page-header-left">
                  <h6 class="page-header-title">Suitable Contacts</h6>
                </div>
              </div>
            </div>
            <div class="booking-contacts-wrapper">
              <ng-container *ngIf="filteredContacts && filteredContacts.length > 0">
                <div class="booking-contact-wrapper" *ngFor="let contact of filteredContacts">
                  <div class="booking-contact-left-content">
                    <div class="contact-profile-content">
                      <span class="contact-profile-name">
                        {{ getInitialName(contact) }}
                      </span>
                      <span class="contact-country-flag-icon fi fi-{{
                          contact.countryCode | lowercase
                        }}"></span>
                    </div>
                    <div class="contact-detail-wrapper">
                      <div class="contact-detail-information">
                        <div class="contact-name-wrapper" *ngIf="contact?.firstName && contact?.lastName">
                          <span class="contact-name">
                            {{ contact.firstName + " " + contact.lastName }}
                          </span>
                        </div>
                      </div>
                      <div class="contact-company-information" *ngIf="contact?.companyName">
                        <span class="contact-company-name">
                          {{ contact.companyName }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="booking-contact-right-content">
                    <div class="select-button-wrapper">
                      <button mat-raised-button class="select-button button-spinner" (click)="onSelectContact(contact)"
                        [disabled]="isSelectLoading">
                        <span class="button-inner">
                          <span *ngIf="!(isSelectLoading && selectedContactId === contact.id)">
                            Select
                          </span>
                          <mat-spinner diameter="20"
                            *ngIf="isSelectLoading && selectedContactId === contact.id"></mat-spinner>
                        </span>
                      </button>
                    </div>
                    <div class="booking-contact-menu-wrapper">
                      <button mat-icon-button class="booking-contact-menu-button s4d-icon s4d-menu"
                        [matMenuTriggerFor]="menu">
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onViewContact(contact)">
                          <span>View Contact</span>
                        </button>
                        <button mat-menu-item (click)="onUpdateContact(contact)">
                          <span>Update Contact</span>
                        </button>
                        <button mat-menu-item (click)="onDeleteContact(contact)">
                          <span>Remove Contact</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="booking-contacts-bottom">
              <button mat-raised-button class="booking-new-contact-button" (click)="onOpenNewContact()">
                Create Contact
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>