import { GridsterItem } from "angular-gridster2";
import { QuotesResultsSortBy } from "../shared.model";

export enum WidgetType {
  quotes = 1,
  orders = 2,
  credits = 3,
  ads = 4,
  quoteSearch = 5,
  recentSearches = 6
}

export interface WidgetItem extends GridsterItem {
  id?: string;
  type: WidgetType;
  canUseMultiple?: boolean;
  canUseMobile?: boolean;
}

export const WidgetsCollection: Array<WidgetItem> = [
  { cols: 2, rows: 3, x: 0, y: 0, canUseMobile: false, type: WidgetType.quoteSearch },
  { cols: 2, rows: 5, x: 0, y: 0, canUseMobile: true, type: WidgetType.quotes },
  { cols: 2, rows: 5, x: 0, y: 0, canUseMobile: true, type: WidgetType.orders },
  { cols: 2, rows: 3, x: 0, y: 0, canUseMobile: true, type: WidgetType.credits },
  { cols: 4, rows: 5, x: 0, y: 0, canUseMobile: false, type: WidgetType.recentSearches },
  { cols: 4, rows: 3, x: 0, y: 0, canUseMobile: false, type: WidgetType.ads }
];

export class Dashboard {
  userId: string;
  organizationId: string;
  widgets: string;
}

export enum ApplicationFileType {
  dashboardBanner = 1,
  preShipmentExample = 2,
  ishlavTermsAndCondition = 3
}

export class DashboardSettings {
  dashboardBannerUrl: string;
  quotesResultsSortBy: QuotesResultsSortBy

  static createDefault(): DashboardSettings {
    return {
      dashboardBannerUrl: null,
      quotesResultsSortBy: null
    };
  }
}