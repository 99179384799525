import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ROLLUP_DIALOG_DATA } from "../../../../../mobile/rollup-dialog/rollup-dialog.model";
import { RollupDialogService } from "../../../../../mobile/rollup-dialog/rollup-dialog.service";
import { SelectItem, QuotesResultsSortBy, ShipperType, QuotesResultsFilterBy } from "../../../../../shared/shared.model";
import { EnvironmentService } from "../../../../../shared/services/environment.service";

@Component({
  selector: "app-quote-search-flow-filter",
  templateUrl: "./flow-filter.component.html",
  styleUrls: ["./flow-filter.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuoteSearchFlowFilterComponent implements OnInit {
  sortBy: QuotesResultsSortBy = QuotesResultsSortBy.cheapestFirst;
  shipperType: ShipperType = ShipperType.importer;
  selectedFilteredBy: QuotesResultsFilterBy = QuotesResultsFilterBy.all;

  isFreightShipping: boolean = true;
  isDoorPickup: boolean = true;
  isDoorDelivery: boolean = true;

  isPickupAtOrigin: boolean = true;
  isDeliveryOnDestination: boolean = true;

  filteredByOptions: SelectItem[] = [
    {
      name: 'All Quotes',
      value: QuotesResultsFilterBy.all
    },
    {
      name: 'Basic Only',
      value: QuotesResultsFilterBy.basic
    },
    {
      name: 'Standard Only',
      value: QuotesResultsFilterBy.standard
    }
  ];

  sortByOptions: SelectItem[] = [
    {
      name: "Cheapest First",
      value: QuotesResultsSortBy.cheapestFirst,
    },
    {
      name: "Fastest First",
      value: QuotesResultsSortBy.fastestFirst,
    },
    {
      name: "Earliest First",
      value: QuotesResultsSortBy.earliestFirst,
    },
  ];

  shipperTypes: SelectItem[] = [
    {
      name: "I’m Importing",
      value: ShipperType.importer,
    },
    {
      name: "I’m Exporting",
      value: ShipperType.exporter,
    },
  ];

  isQuoteBadgeEnabled = this.environmentService.environment.isQuoteBadgeEnabled;

  constructor(
    public dialogRef: RollupDialogService,
    private environmentService: EnvironmentService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.sortBy = this.data.sortBy;
      this.shipperType = this.data.shipperType;
      this.selectedFilteredBy = this.data.selectedFilteredBy;
      this.isFreightShipping = this.data.isFreightShipping;
      this.isDoorPickup = this.data.isDoorPickup;
      this.isDoorDelivery = this.data.isDoorDelivery;
      this.isPickupAtOrigin = this.data.isPickupAtOrigin;
      this.isDeliveryOnDestination = this.data.isDeliveryOnDestination;
    }
  }

  onConfirm(): void {
    const data = {
      sortBy: this.sortBy,
      filteredBy: this.selectedFilteredBy,
      shipperType: this.shipperType,
      isFreightShipping: this.isFreightShipping,
      isDoorPickup: this.isDoorPickup,
      isDoorDelivery: this.isDoorDelivery,
    };

    this.dialogRef.close(data);
  }
}
