import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationService } from '@ship4wd/ngx-common';
import { environment } from '../../../../environments/environment';
import { TripUnLocodeAutocompleteService } from '../trip-un-locode-autocomplete.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { PostcodeVerify } from '../../shared.model';

@Component({
  selector: 'app-trip-un-locode-autocomplete-postcode-dialog',
  templateUrl: './trip-un-locode-autocomplete-postcode-dialog.component.html',
  styleUrls: ['./trip-un-locode-autocomplete-postcode-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TripUnLocodeAutocompletePostcodeDialogComponent implements OnInit {
  postCode = new FormControl('', [Validators.required]);
  minimumLength = 4;
  countryCode: string;
  isFromLoading = false;
  isPostcodeValid = false;

  constructor(
    public dialogRef: MatDialogRef<TripUnLocodeAutocompletePostcodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService
  ) { }

  ngOnInit(): void {
    this.countryCode = this.data?.countryCode;
    this.postCode.setValue(this.data?.postCode);
    this.postCodeValidation();
  }

  onClose(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data: {
        title: "New Search",
        message: "Are you sure you want to choose another location?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      },
    });

    dialogRef.afterClosed().subscribe((x: any) => {
      if (x.result) {
        this.dialogRef.close({
          postCode: null
        });
      }
    });
  }

  onContinue(): void {
    if (this.postCode.value === null || this.postCode.value?.toString().length < this.minimumLength) {
      this.notificationService.notifyDialog('Please enter at least 4 digits post code.');
      return;
    }
    this.dialogRef.close({
      postCode: this.postCode.value.toString()
    });
  }

  private postCodeValidation(): void {
    this.postCode.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPostcodeValid = false;
          const isMinimumLength = this.checkMinimumLength();
          return (isMinimumLength === true ? this.verifyPostcode() : of(isMinimumLength));
        }),
        startWith('')
      ).subscribe((x: PostcodeVerify) => {
        this.isPostcodeValid = x.isValid;
        if (!x.isValid) {
          this.postCode.setErrors({ 'incorrect': true });
        }
        else {
          this.postCode.setValue(x.postcode);
        }
        this.isFromLoading = false;
      });
  }

  private verifyPostcode(): Observable<PostcodeVerify> {
    this.isFromLoading = true;
    return this.tripUnLocodeAutocompleteService.verifyPostcode(this.countryCode, this.postCode.value)
      .pipe(
        map(response => response));
  }

  private checkMinimumLength(): boolean {
    return !(this.postCode.value === null || this.postCode.value?.toString().length < this.minimumLength);
  }
}