import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { BookingContactViewModel } from '../../../../../shared/bookings/booking-contacts/booking-contacts.model';
import { BookingContactsService } from '../../../../../shared/bookings/booking-contacts/booking-contacts.service';
import { OrganizationContact, OrganizationContactAction, OrganizationContactViewModel } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { BookingOrganizationContactsService } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { Booking, ContactType } from '../../../../../shared/bookings/bookings.model';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { ValidatorsService } from '../../../../../shared/helper/validators.service';
import { ErrorMessages } from '../../../../../shared/shared.model';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { ErrorMessagePipe } from '../../../../../shared/pipes/error-message.pipe';
import { PhoneInputComponent } from '../../../../../shared/phone-input/phone-input.component';

@Component({
  selector: 'app-booking-flow-contacts-create',
  templateUrl: 'flow-contacts-create.component.html',
  styleUrls: ["./flow-contacts-create.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowContactsCreateComponent implements OnInit {
  @Input() booking: Booking = null;
  @Input() contactType: ContactType = null;
  @Input() organizationContact: OrganizationContact = undefined;
  @Input() organizationContactAction: OrganizationContactAction = OrganizationContactAction.new;
  @Input() isBookingContactPage: boolean = true;
  @Input() isDialog: boolean = true;

  @Output() onCanceled = new EventEmitter();
  @Output() onAdded = new EventEmitter();

  organizationContactForm: FormGroup;
  organizationContactActions = OrganizationContactAction;
  countryAutocompleteValue: string;
  countryAutoCompleteAction: OrganizationContactAction = OrganizationContactAction.new;
  contactCountryCode: string = null;
  contactCountryName: string = null;
  selectedContactTitle: string = null;
  placesOptions = {
    types: [],
    componentRestrictions: {}
  };

  isLoading: boolean = false;
  @ViewChild("phoneInputComponent") phoneInputComponent: PhoneInputComponent;

  constructor(
    public dialogRef: RollupDialogService,
    public dialog: MatDialog,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private validatorsService: ValidatorsService,
    private notificationService: NotificationService,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private bookingContactsService: BookingContactsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private errorMessagePipe: ErrorMessagePipe,
    private elements: ElementRef
  ) {
    this.booking = data.booking;
    this.contactType = data.contactType;
    this.organizationContact = data.organizationContact;
    this.organizationContactAction = data.organizationContactAction;
    this.isBookingContactPage = data.isBookingContactPage;
  }

  ngOnInit(): void {
    this.countryAutoCompleteAction = this.organizationContactAction;
    this.contactCountryCode = this.getContactCountryCode();
    this.contactCountryName = this.getContactCountryName();
    this.selectedContactTitle = this.getContactTitle();
    this.organizationContactForm = this.createOrganizationContactForm();
    this.countryAutocompleteValue = this.getPropertyValue('countryCode');
    this.setCountryCode();
    this.onChanges();
  }

  onBackToContact(): void {
    if (this.isDialog) {
      this.dialogRef.close(null);
    }
    else {
      this.onCanceled.emit();
    }
  }

  onSaveAndSelect(): void {
    this.organizationContactForm.markAllAsTouched();
    this.onFocusInvalidControl();

    if (!this.organizationContactForm.valid) {
      this.notificationService.error(
        "Can't save changes because of an input errors, please check out the form"
      );
      this.handleError(null, "Can't save changes because of an input errors, please check out the form")
      return;
    }
    const organizationContact =
      this.organizationContactForm.getRawValue() as OrganizationContactViewModel;

    if (
      !this.compareLocations(
        this.organizationContactForm.getRawValue() as OrganizationContact
      )
    )
      return;

    organizationContact.organizationId = this.booking.organizationId;
    this.saveOrganizationContact(organizationContact);
  }

  onClear(formFieldKey: string, fieldName: string): void {
    var control = this.organizationContactForm.get(formFieldKey);
    control.patchValue("");
    this.handleError(fieldName,
      formFieldKey === 'email' ? 'Business Email invalid' :
        this.errorMessagePipe.transform(this.getValidatorErrorKey(control), fieldName))

    if (formFieldKey === 'address1') {
      this.resetAddressInfo();
    }
  }

  getValidatorErrorKey(control: AbstractControl): ErrorMessages {
    if (control.errors) {
      for (const errorKey in control.errors) {
        return ErrorMessages[errorKey];
      }
    }

    return null;
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Contact Details",
      fieldName,
      errorValue
    })
  }

  private createOrganizationContactForm(): FormGroup {
    return this.fb.group({
      id: [this.organizationContact?.id ?? null],
      firstName: [
        { value: this.getPropertyValue('firstName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      lastName: [
        { value: this.getPropertyValue('lastName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      companyName: [
        { value: this.getPropertyValue('companyName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      email: [
        { value: this.getPropertyValue('email'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, Validators.email, this.validatorsService.emailValidator] : []
      ],
      countryCode: [
        { value: this.getPropertyValue('countryCode'), disabled: this.contactType != ContactType.notifyParty, },
        this.isRequired() ? Validators.required : []
      ],
      phoneNumber: [
        { value: this.getPropertyValue('phoneNumber'), disabled: this.isDisabled() },
        [this.isRequired() ? Validators.required : []]
      ]
    });
  }

  private getPropertyValue(propertyName: string): any {
    if (this.organizationContactAction === OrganizationContactAction.view ||
      this.organizationContactAction === OrganizationContactAction.edit) {
      return this.organizationContact[propertyName] ?? '';
    }
    else {
      return '';
    }
  }

  private isRequired(): boolean {
    return this.organizationContactAction === OrganizationContactAction.view ? false : true;
  }

  private isDisabled(): boolean {
    return this.organizationContactAction === OrganizationContactAction.view ? true : false;
  }

  private resetAddressInfo(): void {
    this.organizationContactForm.controls['city'].setValue('');
    this.organizationContactForm.controls['countryCode'].setValue('');
    this.organizationContactForm.controls['state'].setValue('');
    this.organizationContactForm.controls['zipCode'].setValue('');
    this.organizationContactForm.controls['address2'].setValue('');
  }

  private compareLocations(organizationContact: OrganizationContact): boolean {
    if (this.contactType === ContactType.shipper) {
      let from = this.booking.fromBookingSearch;

      if (from.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${from.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok"
          },
        });

        return false;
      }
    }
    else if (this.contactType === ContactType.consignee) {
      let to = this.booking.toBookingSearch;

      if (to.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${to.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok"
          },
        });

        return false;
      }
    }

    return true;
  }

  private saveOrganizationContact(organizationContact: OrganizationContactViewModel): void {
    this.isLoading = true;
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.bookingOrganizationContactsService
        .addOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          if (this.isBookingContactPage) {
            this.setBookingContact(x);
          }
          else {
            if (this.isDialog) {
              this.dialogRef.close(null);
            }
            else {
              this.onCanceled.emit();
            }
          }
        },
          (error) => this.notificationService.error(error))
        .add(() => {
          this.isLoading = false;
        });
    }
    else {
      this.bookingOrganizationContactsService
        .updateOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          this.setBookingContact(x);
        },
          (error) => this.notificationService.error(error))
        .add(() => this.isLoading = false);
    }
  }

  private setBookingContact(organizationContact: OrganizationContact, isSelectOnly: boolean = false): void {
    const bookingContact = this.prepareBookingContactModel(organizationContact);
    if (this.contactType === ContactType.collect || this.contactType === ContactType.drop) {
      this.notificationService.success('Address has been successfully changed.');

      if (this.isDialog) {
        this.dialogRef.close({
          bookingContact: bookingContact,
          contactType: this.contactType
        });
      }
      else {
        this.onAdded.emit({
          bookingContact: bookingContact,
          contactType: this.contactType
        });
      }
    }
    else {
      this.addBookingContact(bookingContact, isSelectOnly);
    }
  }

  private prepareBookingContactModel(organizationContact: OrganizationContact): BookingContactViewModel {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = this.contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private addBookingContact(bookingContact: BookingContactViewModel, isSelectOnly: boolean): void {
    if (!isSelectOnly) {
      this.isLoading = true;
    }
    this.bookingContactsService
      .addBookingContact(bookingContact)
      .subscribe(
        (bookingContactId: string) => {
          this.notificationService.success(`Contact has been successfully
            ${this.organizationContactAction === OrganizationContactAction.new ? 'added' : 'updated'}.`
          );

          if (this.isDialog) {
            this.dialogRef.close({
              bookingContactId: bookingContactId,
              contactType: this.contactType
            });
          }
          else {
            this.onAdded.emit({
              bookingContactId: bookingContactId,
              contactType: this.contactType
            });
          }
        },
        error => this.notificationService.error(error))
      .add(() => {
        this.isLoading = false;
      });
  }

  private getContactCountryName(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.countryName;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.countryName;
      default:
        return null;
    }
  }

  private getContactCountryCode(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.country;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.country;
      default:
        return null;
    }
  }

  private getContactTitle(): string {
    switch (this.contactType) {
      case ContactType.notifyParty:
        return 'Notify contact'
      case ContactType.consignee:
        return 'contact at Delivery (Importer)'
      case ContactType.shipper:
        return 'contact at Pickup (Exporter/Supplier)'
      default:
        return 'contact';
    }
  }

  private setCountryCode(): void {
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.organizationContactForm.get('countryCode').patchValue(this.contactCountryCode ?? '');
      this.countryAutocompleteValue = this.organizationContactForm.get('countryCode').value;
      this.countryAutoCompleteAction = this.contactType !== ContactType.notifyParty ? OrganizationContactAction.view : this.organizationContactAction;
    }

    if (this.contactCountryCode) {
      this.placesOptions = {
        types: ['address'],
        componentRestrictions: { country: [this.contactCountryCode] }
      }
    }
  }

  private onChanges(): void {
    this.organizationContactForm.get('countryCode').valueChanges.subscribe(val => {
      this.organizationContactForm.get('phoneNumber').updateValueAndValidity();
    });
  }

  private onFocusInvalidControl(): void {
    const key = Object.keys(this.organizationContactForm.controls)
      .find(key => this.organizationContactForm.controls[key].invalid);

    if (key) {
      if (key === "phoneNumber") {
        this.phoneInputComponent.focusPhoneInputElement();
      } else {
        this.elements.nativeElement.querySelector(`[formcontrolname="${key}"]`)?.focus();
      }
    }
  }
}
