import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { User, UserService } from "@ship4wd/ngx-authorization";
import { NotificationService } from "@ship4wd/ngx-common";
import { of, Subscription } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { AppSettingsService } from "../../shared/services/app-settings/app-settings.service";
import { NotificationType } from "../../desktop/organizations/organizations.model";
import { OrganizationsService } from "../../desktop/organizations/organizations.service";
import {
  ChatProfile,
  HomeProfileQuery,
  KycStatusCode,
  OrganizationProfile,
  TeamMemberOrganization,
  UserProfile,
} from "../../shared/accounts/accounts.model";
import { AccountsService } from "../../shared/accounts/accounts.service";
import { Organization, OrganizationSettingsData } from "../../shared/organizations/organization.model";
import {
  AccessRoleType,
  GlobalTradeTypeCode,
  LeadSourceCode,
  LeadStateCode,
  TeamAccessRole,
} from "../../shared/shared.model";
import { LayoutMode } from "../layout/layout.model";
import { LayoutService } from "../layout/layout.service";
import { SalesforceService } from "../../shared/services/salesforce.service";
import { AppcuesService } from "../../shared/appcues/appcues.service";
import { GoogleAnalyticsService } from "../../shared/google-analytics/google-analytics.service";

@Component({
  selector: "app-organizations",
  templateUrl: "./organizations.component.html",
  styleUrls: ["./organizations.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationsComponent implements OnInit, AfterViewInit {
  isLoading = true;
  isSubmit = false;
  isRedirected: boolean;
  returnUrl: string = "/";
  user: User;
  accepted: Array<TeamMemberOrganization> = [];
  pending: Array<any> = [];
  invited: Array<any> = [];
  organizationSubscription: Subscription;
  noOrganizations = false;
  showRegistration: boolean = false;
  organizationFromUrl: string = null;
  autoSelectedId: string = null;
  autoCountryCode: string = null;
  autoSelectedName: string = null;
  autoSelectedIsCreditOrganization: boolean = false;
  userProfile: UserProfile;
  organizationForm: FormGroup;
  registerButtonTitle: string;
  registerButtonTitleVisible: boolean = true;

  constructor(
    private layoutService: LayoutService,
    private accountsService: AccountsService,
    private appSettings: AppSettingsService,
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private salesforceService: SalesforceService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private appcuesService: AppcuesService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "dashboard";
    this.user = this.userService.getUser();
    this.isRedirected = this.route.snapshot.queryParams["r"]
      ? this.route.snapshot.queryParams["r"].toLocaleLowerCase() === "true"
      : false || true;
    this.organizationFromUrl = this.route.snapshot.queryParams["o"];
    this.organizationsService.setReturnUrl(this.returnUrl);
    this.getData();
    this.organizationsService.dataSubject.subscribe((x) => this.getData());

    if (!this.isRedirected) {
      this.appSettings.clearOrganization();
    }

    this.createOrganizationForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.ORGANIZATIONS);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarTitle("Organizations");
      this.layoutService.setToolbarDescription(
        "Select organization & review invitations"
      );
    }, 0);
  }

  showForm() {
    if (this.showRegistration) {
      this.showRegistration = false;
      this.layoutService.setToolbarTitle("Organizations");
      this.layoutService.setToolbarDescription(
        "Select organization & review invitations"
      );
    } else {
      this.showRegistration = true;
      this.layoutService.setToolbarTitle("Add Organization");
      this.layoutService.setToolbarDescription(
        "Find and add your organization"
      );

      this.googleAnalyticsService.organizationsClick("register another company");
    }
    this.organizationForm.reset();
  }

  onSubmit() {
    this.organizationForm.markAllAsTouched();
    if (!this.organizationForm.valid) {
      this.notificationService.error(
        "Can't save changes because of an input errors, please check out the form"
      );
      return;
    }

    this.isSubmit = true;
    const firstName = this.userProfile?.fullName.substring(
      0,
      this.userProfile?.fullName.indexOf(" ")
    );
    const body: Organization = {
      leadSourceId: LeadSourceCode.internal,
      leadStateId: LeadStateCode.submit,
      company: {
        globalTradeTypeId: GlobalTradeTypeCode.both,
        name: this.organizationForm.value.name.trim(),
        legalName: this.organizationForm.value.legalName.trim(),
        country: this.organizationForm.value.country,
        city: this.organizationForm.value.city,
        street: this.organizationForm.value.street,
        state: this.organizationForm.value.state,
        postalCode: this.organizationForm.value.postalCode,
        taxId: this.organizationForm.value.taxId,
        address2: this.organizationForm.value.address2,
        isTermsConfirmation: true,
        isInternalOrganization: false,
        contact: {
          firstname:
            firstName == null || firstName == ""
              ? this.userProfile?.fullName.substring(
                this.userProfile?.fullName.indexOf(" ") + 1
              )
              : firstName,
          lastname: this.userProfile?.fullName.substring(
            this.userProfile?.fullName.indexOf(" ") + 1
          ),
          email: this.userProfile?.email,
          phone: this.userProfile?.phoneNumber,
        },
      },
    };

    this.organizationsService.addOrganization(body).subscribe(
      () => {
        location.reload();
      },
      (error) => {
        this.notificationService.error(error);
        this.isSubmit = false;
      }
    );
  }

  private getData() {
    var query = {} as HomeProfileQuery;

    of(null)
      .pipe(
        switchMap(() => this.accountsService.getUserProfile()),
        tap((userProfile) => {
          this.userProfile = userProfile;
          query.email = userProfile.email;

          if (userProfile.fullName) {
            const firstLetter = userProfile.fullName.substring(0, 1);
            const secondLetter = userProfile.fullName.substring(
              userProfile.fullName.indexOf(" ") + 1,
              userProfile.fullName.indexOf(" ") + 2
            );
            this.organizationsService.setUserFullName(userProfile.fullName);
            this.organizationsService.setUserAcronymName(
              firstLetter + secondLetter
            );
            this.organizationsService.showUserAcronymName(
              firstLetter + secondLetter
            );
          }
        }),
        switchMap(() => this.accountsService.getOrganizationProfiles()),
        tap((organizationProfile: OrganizationProfile) => {
          this.accepted =
            organizationProfile.organizations.filter(
              (x) => x.kycStatusCode === KycStatusCode.Approve
            ) ?? [];
          this.organizationsService.setUserOrganizations(this.accepted);

          this.pending =
            organizationProfile.organizations.filter(
              (x) => x.kycStatusCode === KycStatusCode.Pending
            ) ?? [];
          this.invited =
            organizationProfile.invites.filter(
              (x) => x.inviteStatusCode === 1
            ) ?? [];
          this.registerButtonTitle =
            this.accepted.length > 0
              ? "Register another company"
              : "Register your company";
          this.registerButtonTitleVisible = true;

          if (
            this.accepted.length === 0 &&
            this.invited.length === 0 &&
            this.pending.length === 0
          ) {
            this.noOrganizations = true;
          }

          if (
            this.accepted.length === 1 &&
            this.invited.length === 0 &&
            this.pending.length === 0
          ) {
            this.autoSelectedId = this.accepted[0].organizationId;
            this.autoCountryCode = this.accepted[0].countryCode;
            this.autoSelectedName = this.accepted[0].organizationName;
            this.autoSelectedIsCreditOrganization = this.accepted[0].isCreditOrganization;
          }

          if (this.organizationFromUrl) {
            const isOrganizationFromUrlAccepted =
              this.accepted.findIndex(
                (x) => x.organizationId == this.organizationFromUrl
              ) > -1;

            if (isOrganizationFromUrlAccepted) {
              this.autoSelectedName = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).organizationName;

              this.autoCountryCode = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).countryCode;

              this.autoSelectedIsCreditOrganization = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).isCreditOrganization;
            }
            this.autoSelectedId = isOrganizationFromUrlAccepted
              ? this.organizationFromUrl
              : null;
          }
        }),
        switchMap(() => {
          return this.autoSelectedId
            ? this.accountsService.getUserProfile(this.autoSelectedId)
            : of(null);
        }),
        tap((userProfile: UserProfile) => {
          if (userProfile == null) {
            this.isLoading = false;
          } else {
            if (userProfile.accessRoles.length > 0) {
              const roles: AccessRoleType[] = [];
              userProfile.accessRoles.map((x: TeamAccessRole) => {
                roles.push(x.accessRoleType);
              });
              this.organizationsService.setUserRoles(roles);
            } else {
              this.organizationsService.setUserRoles(null);
            }

            this.organizationsService.setUserSettingsAndInfo(userProfile);

            let chatUser: ChatProfile = {
              fullName: userProfile.fullName,
              email: userProfile.email,
              phoneNumber: userProfile.phoneNumber,
              companyName: this.autoSelectedName,
              userId: userProfile.id,
              organizationId: this.autoSelectedId
            };
            localStorage.setItem("chatUserInfo", JSON.stringify(chatUser));
            this.salesforceService.onUserLogout();
            this.salesforceService.addUserChat();

            const organizationSettingsData: OrganizationSettingsData = {
              organizationId: this.autoSelectedId,
              countryCode: this.autoCountryCode,
              organizationName: this.autoSelectedName,
              isCreditOrganization: this.autoSelectedIsCreditOrganization
            };

            this.organizationsService.setOrganization(organizationSettingsData);
            this.appcuesService.identify()

            if (this.isRedirected) {
              this.organizationsService.returnBack();
            } else {
              this.isLoading = false;
            }
          }
        })
      )
      .subscribe(
        () => { },
        (error) => {
          this.organizationsService.notify(error, NotificationType.ERROR);
          this.isLoading = false;
        }
      );
  }

  private createOrganizationForm() {
    this.organizationForm = this.fb.group({
      name: new FormControl("", [Validators.required]),
      legalName: new FormControl("", [Validators.required]),
      street: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      postalCode: new FormControl(""),
      address2: new FormControl(""),
      taxId: new FormControl(""),
      city: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
    });
  }
}
