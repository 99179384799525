import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserService } from '@ship4wd/ngx-authorization';
import { NotificationService } from '@ship4wd/ngx-common';
import { Booking, BookingStatusCode, BookingSummary } from '../../../shared/bookings/bookings.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { CarriageStatusType, ShipmentType } from '../../../shared/shared.model';
import { UtilityService } from '../../../shared/helper/utility.service';

@Component({
  selector: 'app-print-booking',
  templateUrl: './print-booking.component.html',
  styleUrls: ['./print-booking.component.scss']
})
export class PrintBookingComponent implements OnInit {

  public user: User;
  bookingId: string;
  bookingSummary: BookingSummary;
  isPrintReady: boolean = false;
  isPrintMode: boolean = false;
  shipmentTypes = ShipmentType;
  CarriageTypes = CarriageStatusType;
  preCarriageStatuses = [BookingStatusCode.orderSubmitted] as BookingStatusCode[];
  mainCarriageStatuses = [BookingStatusCode.draft] as BookingStatusCode[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private bookingsService: BookingsService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if(params.mode === 'print') this.isPrintMode = true;
        if(params.token !== undefined){
          window.localStorage.setItem("accessToken", params.token);
        }
        else{
          this.checkUser();
        }
      },
      ()=>{},
      ()=>{
        this.checkUser();
      }
    );


    this.bookingId = this.route.snapshot.params.id;
    this.getBookingSummaryById(this.bookingId);
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getShipperName(booking: Booking) {
    return booking?.shipperContact?.companyName;
  }

  displayStatusIcon(carriageType: CarriageStatusType) {
    if (this.mainCarriageStatuses.includes(this.bookingSummary.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(this.bookingSummary.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return this.bookingSummary.statusType.carriageType === carriageType;
  }

  setDescriptionPosition(status:BookingStatusCode){
    if (this.mainCarriageStatuses.includes(status)) {
      return 'main-position';
    }

    if (this.preCarriageStatuses.includes(status)) {
      return 'pre-position';
    }

    return 'post-position';
  }

  getMainCarriageIcon() {
    switch (this.bookingSummary.shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
        break;
      }
      default: {
        return 'ship';
        break;
      }
    }
  }

  private checkUser(){
    this.user = this.userService.getUser();
    if (!this.user.isAuthenticated) {
      this.navigateToLogin();
    }
  }

  private navigateToLogin() {
   this.router.navigate(['/login']);
  }

  private getBookingSummaryById(id: string) {
    this.bookingsService
      .getSummaryById(id)
      .subscribe(
        (x: BookingSummary) => {
          this.bookingSummary = x;
        },
        (error) => this.notificationService.error(error),
        () => {
          this.isPrintReady = true;

          if(this.isPrintMode) {
            window.setTimeout(function(){
              window.print();
            }, 1000);

          }
        }
      )
  }
}
