import { Injectable } from '@angular/core';
import { File, FileUrl } from '../shared.model';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor() { }

  downloadFile(file: File): void {
    const byteCharacters = atob(file.content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const binaryData = [];
    binaryData.push(new Uint8Array(byteNumbers));

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: 'blob' })
    );
    downloadLink.setAttribute('download', file.name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  downloadFileUrl(fileUrl: FileUrl): void {
    window.open(fileUrl.url, "_blank");
  }
}
