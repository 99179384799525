import { Directive, ElementRef, Input, HostListener, ComponentRef, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { S4DTooltipComponent } from './s4d-tooltip.component';

@Directive({
  selector: '[s4dTooltip]'
})
export class S4DTooltipDirective {
  @Input('s4dTooltip') tooltipContent: string | TemplateRef<any>;
  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' | 'auto' = 'above';
  @Input() tooltipMaxWidth: string = '15rem';
  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<S4DTooltipComponent>;
  private tooltipElement: HTMLElement;
  private hideTimeout: any;

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) {
    this.overlayRef = this.overlay.create();
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    clearTimeout(this.hideTimeout);
    if (!this.overlayRef.hasAttached()) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.el)
        .withPositions(this.getPositions());

      this.overlayRef.updatePositionStrategy(positionStrategy);

      const tooltipPortal = new ComponentPortal(S4DTooltipComponent, null, this.injector);
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);

      if (this.tooltipContent instanceof TemplateRef) {
        this.tooltipRef.instance.template = this.tooltipContent;
        this.tooltipRef.instance.viewContainerRef = this.viewContainerRef;
      } else {
        this.tooltipRef.instance.content = this.tooltipContent;
      }

      const positionClass = this.getPositionClass();
      this.tooltipElement = this.tooltipRef.location.nativeElement.querySelector('#s4d-tooltip');
      this.tooltipElement.classList.add(positionClass);
      this.tooltipElement.style.maxWidth = this.tooltipMaxWidth;

      this.tooltipElement.addEventListener('mouseenter', this.onTooltipMouseEnter.bind(this));
      this.tooltipElement.addEventListener('mouseleave', this.onTooltipMouseLeave.bind(this));

      setTimeout(() => {
        this.tooltipElement.classList.add('show');
      }, 0);
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.hideTimeout = setTimeout(() => {
      if (this.overlayRef.hasAttached()) {
        const tooltipElement = this.tooltipRef.location.nativeElement;
        this.tooltipElement.classList.remove('show');
        setTimeout(() => {
          this.overlayRef.detach();
        }, 300);
      }
    }, 100);
  }

  @HostListener('focus') onFocus(): void {
    this.onMouseEnter();
  }

  @HostListener('blur') onBlur(): void {
    this.onMouseLeave();
  }

  private onTooltipMouseEnter(): void {
    clearTimeout(this.hideTimeout);
  }

  private onTooltipMouseLeave(): void {
    this.onMouseLeave();
  }

  private getPositions(): ConnectedPosition[] {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const spaceAbove = hostPos.top;
    const spaceBelow = window.innerHeight - hostPos.bottom;
    const spaceLeft = hostPos.left;
    const spaceRight = window.innerWidth - hostPos.right;

    if (this.tooltipPosition === 'auto') {
      if (spaceAbove > spaceBelow && spaceAbove > 50) {
        return [{
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }];
      } else if (spaceBelow > 50) {
        return [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }];
      } else if (spaceLeft > spaceRight && spaceLeft > 50) {
        return [{
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center'
        }];
      } else if (spaceRight > 50) {
        return [{
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center'
        }];
      } else {
        return [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }];
      }
    }

    switch (this.tooltipPosition) {
      case 'above':
        return [{
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }];
      case 'below':
        return [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }];
      case 'left':
        return [{
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center'
        }];
      case 'right':
        return [{
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center'
        }];
      default:
        return [{
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }];
    }
  }

  private getPositionClass(): string {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const spaceAbove = hostPos.top;
    const spaceBelow = window.innerHeight - hostPos.bottom;
    const spaceLeft = hostPos.left;
    const spaceRight = window.innerWidth - hostPos.right;

    if (this.tooltipPosition === 'auto') {
      if (spaceAbove > spaceBelow && spaceAbove > 50) {
        return 'above';
      } else if (spaceBelow > 50) {
        return 'below';
      } else if (spaceLeft > spaceRight && spaceLeft > 50) {
        return 'left';
      } else if (spaceRight > 50) {
        return 'right';
      } else {
        return 'above';
      }
    }

    switch (this.tooltipPosition) {
      case 'above':
        return 'above';
      case 'below':
        return 'below';
      case 'left':
        return 'left';
      case 'right':
        return 'right';
      default:
        return 'above';
    }
  }
}
