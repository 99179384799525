import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '@ship4wd/ngx-common';
import { UserService } from '@ship4wd/ngx-authorization';
import { OrganizationsService } from '../../desktop/organizations/organizations.service';
import { KycStatusCode } from '../accounts/accounts.model';
import { AppSettings } from '../features/app-settings/app-settings.model';
import { AppSettingsService } from '../services/app-settings/app-settings.service';
import { UserInfoService } from '../services/user-info/user-info.service';
import { TeamMemberStatusCode } from '../shared.model';
import { PersonalNotificationsService } from '../personal-notifications/personal-notifications.service';
import { PersonalNotificationCode } from '../personal-notifications/personal-notification.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationGuard implements CanActivate {

  constructor(
    private router: Router,
    private appSettings: AppSettingsService,
    private notificationService: NotificationService,
    private organizationsService: OrganizationsService,
    private userInfoService: UserInfoService,
    private appSettingsService: AppSettingsService,
    private userService: UserService,
    private personalNotificationsService: PersonalNotificationsService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      debugger
    let settings: AppSettings = this.appSettings.getSettings();
    const id = settings.organizationId;

    if (!this.userService.getUser().isAuthenticated) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    if (id === undefined || id === null || id === '') {
      this.router.navigate(["/organizations"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    return combineLatest([this.checkOrganization(id, state.url), this.checkUserNotSuspended(id)])
      .pipe(map(([isOrgValid, isUserNotSuspended]) => isOrgValid && isUserNotSuspended));
  }

  checkOrganization(id: string, url: String): Observable<boolean> {
    return this.organizationsService.getOrganization(id).pipe(map((res) => {
      const isTaxIdNotificationExists =
        this.personalNotificationsService.isNotificationAlreadyExists(PersonalNotificationCode.missingTaxIdNotification);
      if (res["taxId"] && isTaxIdNotificationExists) {
        this.personalNotificationsService.removeNotification(PersonalNotificationCode.missingTaxIdNotification);
      }
      else if (!res["taxId"] && !isTaxIdNotificationExists) {
        this.personalNotificationsService.addNotification(PersonalNotificationCode.missingTaxIdNotification);
      }

      if (res["kycStatusCode"] === KycStatusCode.Approve) {
        return true;
      }
      else {
        this.notificationService.error("Your organization not valid.");
        this.appSettingsService.removeSettings();
        this.router.navigate(["/organizations"], {
          queryParams: { returnUrl: url, r: false },
        });
        return false;
      }
    }));
  }

  checkUserNotSuspended(organizationId: string): Observable<boolean> {
    return this.organizationsService.getTeamMember(organizationId).pipe(map((teamMember) => {
      if (teamMember.memberStatus == TeamMemberStatusCode.active) {
        return true;
      } else {
        this.notificationService.error("You are suspended.");
        this.userInfoService.removeSettings();
        this.router.navigate(["/organizations"], {
          queryParams: { r: false }
        });
        return false;
      }
    }));
  }
}
