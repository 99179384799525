import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchType, ShipmentType } from '../../../../shared/shared.model';
import { QuotesQuery, TripsSearchBaseViewModel } from '../../quotes.model';
import { SupportRequestsService } from '../../../../desktop/support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../../../desktop/support-requests/support-requests.model';

@Component({
  selector: 'app-no-quotes',
  templateUrl: './no-quotes.component.html',
  styleUrls: ['./no-quotes.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class NoQuotesComponent implements OnInit {
  @Input() quotesQuery: QuotesQuery;
  @Input() quoteSearchId: string;
  searchType = SearchType;
  notes = new FormControl();
  shipmentType = ShipmentType;

  constructor(
    public dialog: MatDialog,
    private supportRequestsService: SupportRequestsService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.quoteSearchId?.currentValue) {
      this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData(false))
    }
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  renderFromToData(model: TripsSearchBaseViewModel, searchType: SearchType): string {
    let data = '';
    if (!model) {
      return data;
    }

    data = this.appendContactData(data, model.displayName);
    if (searchType === SearchType.to) {
      data = this.appendContactData(data, model.zipCode);
    }
    data = this.appendContactData(data, model.countryName);
    return data;
  }

  private appendContactData(data: string, value: string): string {
    if (value && value !== null) {
      if (data !== "") {
        data += ", ";
      }
      data += value;
    }
    return data;
  }

  private createSupportRequestDialogData(isPopupAutoOpened: boolean = true): SupportRequestDialogData {
    return {
      isPopupAutoOpened: isPopupAutoOpened,
      supportRequestType: SupportRequestTypeCode.general,
      bolOrderNumber: null,
      quoteSearchId: this.quoteSearchId
    } as SupportRequestDialogData
  }
}
