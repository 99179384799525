<div class="row postcode-option" [ngClass]="{ 'p-3': !extended, 'm-0': extended }" (click)="onSelect($event)">
  <div class="col">
    <div *ngIf="extended" class="row justify-content-between align-items-center px-2 pt-2">
      <div class="col">
        <span class="postcode-option-title">{{ title }}</span>
      </div>
      <div class="col-auto px-0">
        <button mat-button class="postcode-option-close-button" (click)="onClose()">
          <i class="postcode-option-close-icon s4d-icon s4d-close"></i>
        </button>
      </div>
    </div>
    <div class="row" [ngClass]="{ 'py-3 px-2': extended }">
      <div class="col-auto">
        <i class="contact-country-flag-icon fi fi-{{
            location.country | lowercase
          }}"></i>
      </div>
      <div class="col px-0 location-wrapper" [matTooltip]="getLocationTooltipText(location)">
        <div class="location-content">
          <span class="location-content-strong">{{ location?.cuttedDisplayName }},</span>
          {{ location?.countryName }}
        </div>
        <div class="location-content" *ngIf="getLocationDoorAdress(location, true)"
          [matTooltip]="getLocationDoorAdress(location, false)">
          <span class="door-location-full-address">{{getLocationDoorAdress(location, true)}}</span>
        </div>
      </div>
      <div class="col-auto">
        <span *ngIf="location?.locationType | locationTypeShortName" class="location-type">
          {{ location.locationType | locationTypeShortName | uppercase }}
        </span>
      </div>
      <div class="col-12" *ngIf="isDisabled">
        <span class="domestic-error">Destination cannot be within the same country</span>
      </div>
    </div>
    <div *ngIf="extended" class="row postcode-option-footer">
      <div class="col px-0">
        <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
            invalid: fullAddress.touched && !postcode.valid
          }">
          <input matInput #postCodeInput ngx-google-places-autocomplete [options]="placesOptions"
            (onAddressChange)="onAddressChange($event)" (keydown)="$event.stopPropagation()" [formControl]="fullAddress"
            type="text" placeholder="Address or Postcode" autofocus />
        </mat-form-field>
      </div>
      <div class="col-auto pe-0 ps-3 d-flex justify-content-center align-items-center">
        <button mat-button class="postcode-option-accept-button" (click)="onConfirm()"
          [disabled]="!postcode.valid && isMustVerifyPostCode(location)">
          <i class="postcode-option-accept-icon s4d-icon s4d-arrow-right"></i>
        </button>
      </div>
      <mat-error class="booking-flow-error mt-1" *ngIf="fullAddress.touched && (!postcode.valid || !fullAddress.valid)">
        <i class="error-icon s4d-icon s4d-info-light"></i> Address has no valid postcode
      </mat-error>
    </div>
  </div>
</div>
