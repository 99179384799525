<div *ngIf="organizationMembers">
  <div class="organization-members-wrapper" *ngIf="!isMemberPageActive">
    <div class="row mb-3">
      <div class="col">
        <button mat-raised-button class="next-button" (click)="onAddInviteMember()">
          <span class="button-inner">
            <div class="row">
              <div class="col invite-text">
                <app-quote-search-icons [iconName]="'add-icon'" class="plus-icon"></app-quote-search-icons>
                <span>Invite Member</span>
              </div>
            </div>
          </span>
        </button>
      </div>
    </div>
    <div class="row invite-members-wrapper" *ngFor="let inviteMember of organizationMembers.inviteMembers">
      <div class="col-auto">
        <div class="organization-member-profile-content">
          <span class="organization-member-profile-name">
            {{ getInitialMemberName(inviteMember) }}
          </span>
          <div class="organization-member-status-type-wrapper">
            <mat-chip-list>
              <mat-chip class="organization-member-status-type">Pending</mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col organization-member-name-wrapper" *ngIf="inviteMember.firstName && inviteMember.lastName">
            <span class="organization-member-name">
              {{ inviteMember.firstName + " " + inviteMember.lastName }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col organization-member-access-role-wrapper">
            <span class="organization-member-access-role">
              {{ getAccessRolesOfInviteMember(inviteMember.roles) }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="organization-members-menu-wrapper">
          <button mat-icon-button class="organization-members-menu-button" [matMenuTriggerFor]="inviteMembersMenu">
            <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
          </button>
          <mat-menu #inviteMembersMenu="matMenu" backdropClass="organization-member-menu">
            <button mat-menu-item (click)="onEditInviteMember(inviteMember)">
              <span class="edit-detailes">Edit</span>
            </button>
            <button mat-menu-item (click)="onResendInviteMember(inviteMember)">
              <span class="resend-detailes">Resend</span>
            </button>
            <button mat-menu-item class="remove-detailes-wrapper" (click)="onCancelInviteMember(inviteMember)">
              <span class="remove-detailes">Cancel</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="row team-members-wrapper" *ngFor="let teamMember of organizationMembers.teamMembers">
      <div class="col-auto">
        <div class="organization-member-profile-content">
          <span class="organization-member-profile-name">
            {{ getInitialMemberName(teamMember) }}
          </span>
          <div class="organization-member-status-type-wrapper" *ngIf="
              teamMember.memberStatus === teamMemberStatuses.pending ||
              teamMember.memberStatus === teamMemberStatuses.suspended
            " [ngClass]="{
              'pending-status-type':
                teamMember.memberStatus === teamMemberStatuses.pending,
              'suspended-status-type':
                teamMember.memberStatus === teamMemberStatuses.suspended
            }">
            <mat-chip-list>
              <mat-chip class="organization-member-status-type">{{
                teamMember.memberStatus | teamMemberStatusName
                }}</mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col organization-member-name-wrapper" *ngIf="teamMember.firstName && teamMember.lastName">
            <span class="organization-member-name">
              {{ teamMember.firstName + " " + teamMember.lastName }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col organization-member-access-role-wrapper">
            <span class="organization-member-access-role">
              {{ getAccessRolesOfTeamMember(teamMember.teamAccessRoles) }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="organization-members-menu-wrapper">
          <button mat-icon-button class="organization-members-menu-button" [matMenuTriggerFor]="teamMembersMenu">
            <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
          </button>
          <mat-menu #teamMembersMenu="matMenu" backdropClass="organization-member-menu">
            <button mat-menu-item (click)="onEditTeamMember(teamMember)">
              <span class="edit-detailes">Edit</span>
            </button>
            <button mat-menu-item *ngIf="teamMember.memberStatus === teamMemberStatuses.suspended"
              (click)="onReactivateTeamMember(teamMember)">
              <span class="reactivate-detailes">Reactivate</span>
            </button>
            <button mat-menu-item *ngIf="teamMember.memberStatus === teamMemberStatuses.active"
              (click)="onSuspendTeamMember(teamMember)">
              <span class="suspend-detailes">Suspend</span>
            </button>
            <button mat-menu-item class="trash-btn" (click)="onRemoveTeamMember(teamMember)">
              <span class="remove-detailes">Remove</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="member-page" *ngIf="isMemberPageActive">
    <app-organization-member [type]="memberPageType" [mode]="memberPageMode" [member]="memberForPage"
      (backEvent)="onMemberBack($event)"></app-organization-member>
  </div>
</div>