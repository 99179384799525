<div class="quote-search-flow" [ngClass]="{'is-overflow-hidden': isLoading && isStarted}">
  <div class="search-flow-header-wrapper">
    <app-quote-search-flow-header [quoteSearchForm]="quoteSearchForm" [shippingTypes]="shippingTypes"
      [isCompact]="isFilled" (shipmentTypeChange)="shipmentTypeChange($event)"
      [quoteSearchId]="quoteSearchId"></app-quote-search-flow-header>
  </div>
  <div class="search-flow-content-wrapper" [ngClass]="{'is-loaded': !isLoading && isStarted}">
    <div id="search-flow-overlay" class="search-flow-overlay"></div>
    <div class="row">
      <div class="col">
        <app-quote-new-search-flow-params class="search-flow-params" [quoteSearchForm]="quoteSearchForm"
          [isReuse]="isReuse" [isLoading]="isListLoading" [reset]="result" [shippingTypes]="shippingTypes"
          [searchId]="searchId" isEnabled="isSearchEnabled" [selectedQuote]="selectedQuote"
          (searchQuotesEvent)='onSearchQuotes($event)' [isFirstSearch]="isFirstSearch"
          [isRecentQuotesSearch]="isRecentQuotesSearch"></app-quote-new-search-flow-params>
      </div>
    </div>
    <div class="search-flow-main-wrapper">
      <div *ngIf="isStarted" class="search-flow-main-container mx-0 g-0">
        <div *ngIf="isLoading && isStarted" class="row mx-0 g-0 can-transform"
          [ngClass]="{'is-active': isLoading && isStarted}">
          <div class="col d-flex justify-content-center">
            <app-loader-with-images class="search-flow-loader-with-image"></app-loader-with-images>
          </div>
        </div>
        <div *ngIf="isLoading && isStarted" class="row mx-0 g-0 can-transform"
          [ngClass]="{'is-active': isLoading && isStarted}">
          <div class="col d-flex justify-content-center">
            <app-quote-search-flow-loader class="search-flow-loader mt-4"></app-quote-search-flow-loader>
          </div>
        </div>
        <div *ngIf="!isLoading && isStarted" class="row mx-0 g-0 h-100 can-transform"
          [ngClass]="{'is-active': !isLoading && isStarted}">
          <app-quotes-results [quotes]="quotes" [isListLoading]="isListLoading"
            [shipmentType]="quoteSearchForm.get('shipmentType').value" [from]="from" [to]="to"
            [quotesQuery]="quotesQuery" [noQuotesFound]="noQuotesFound" [quoteSearchId]="quoteSearchId"
            [isShowLoadingList]="isShowLoadingList" [isLoading]="isLoading"
            (shipperTypeChanged)="onChangeShipperType($event)" [priceTrendInfo]="priceTrendInfo"> </app-quotes-results>
        </div>
      </div>
      <div *ngIf="!isStarted" class="row quote-search-banner-content-wrapper m-0 g-0">
        <div class="col-12 px-0 watch-tutorial">
          <a class="tutorial-link" (click)="onOpenTutorial()">
            <span>Watch our tutorial</span>
            <span class="play-icon material-icons">play_circle_filled</span>
          </a>
        </div>
        <app-recent-quotes-widget class="recent-quotes-widget"></app-recent-quotes-widget>
        <div class="quote-search-banner-line-text-line">
          <span class="quote-search-banner-line"></span>
          <span class="quote-search-banner-text">Why ship with us?</span>
          <span class="quote-search-banner-line"></span>
        </div>

        <div class="row mx-0">
          <div class="col"></div>
          <div class="col-1 quote-search-banner-info">
            <div class="certificate-img mb-2"></div>
            <span class="text-center">Federal Maritime Commission Registration No. 029468</span>
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="iata-cargo-img mb-2"></div>
            <span class="text-center">IATA Cargo Agent</span>
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="furnishings-img mb-2"></div>
            <span class="text-center">Member of the American Home Furnishings Alliance</span>
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="prizes-img mb-2"></div>
            <span class="text-center">Best <span class="text-purple">Freight Forwarder</span> for SMBs U.S. and
              Canada</span>
          </div>
          <div class="col"></div>

        </div>
      </div>
    </div>
  </div>
</div>