<button *ngIf="!isSubmitted(booking) && !booking.isCanceled" mat-menu-item class="dropdown-button"
  [routerLink]="['/booking', booking.id, true]" [disabled]="isLoading">
  <span class="update-order-icon"></span>
  <span class="order-dropdown-text">Update Order</span>
</button>
<button *ngIf="isShowViewDetails(booking)" mat-menu-item class="dropdown-button"
  (click)="navigateToBookingDetails(booking)" [disabled]="isLoading">
  <span class="view-order-icon"></span>
  <span class="order-dropdown-text">View Order</span>
</button>
<button *ngIf="isSubmitted(booking)" mat-menu-item class="dropdown-button" (click)="getConversation()"
  [disabled]="isLoading">
  <span class="contact-support-icon"></span>
  <span class="order-dropdown-text">Contact Support</span>
</button>
<button *ngIf="isSubmitted(booking)" mat-menu-item class="dropdown-button" (click)="onGetSteps()"
  [disabled]="isLoading">
  <i class="check-icon s4d-icon s4d-check-circle"></i>
  <span class="order-dropdown-text">Booking Completion Steps</span>
</button>
<button mat-menu-item class="dropdown-button" (click)="onFilesDocuments(booking.id)" [disabled]="isLoading">
  <span class="files-docs-icon"></span>
  <span class="order-dropdown-text">Files & Docs</span>
</button>
<button *ngIf="isShowShippingInstructions(booking)" mat-menu-item class="dropdown-button"
  [routerLink]="['/shipping-instructions', booking.id]" [disabled]="isLoading">
  <span class="shipping-instructions-icon"></span>
  <span class="order-dropdown-text">Shipping Instructions</span>
</button>
<button *ngIf="!booking.isCanceled && allowedRebook" mat-menu-item class="dropdown-button" (click)="onRebook(booking)"
  [disabled]="isLoading">
  <span class="reuse-icon"></span>
  <span class="order-dropdown-text">Rebook autopilot</span>
</button>
<ng-container *ngIf="!booking.isCanceled && !isHigherOrderReceived(booking)">
  <div class="dropdown-separator"></div>
  <button mat-menu-item class="dropdown-button" (click)="onCancelOrder(booking)" [disabled]="isLoading">
    <span class="cancel-order-icon"></span>
    <span class="cancel-order-text"> Cancel Order</span>
  </button>
</ng-container>