import { Component, HostListener, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportRequestsService } from '../support-requests/support-requests.service';
import { GoogleAnalyticsService } from '../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-live-assistance',
  templateUrl: './live-assistance.component.html',
  styleUrls: ['./live-assistance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveAssistanceComponent implements OnInit {
  elementPosition: { x: number; y: number; };
  left: boolean = false;
  right: boolean = false;
  sourceEvent: any = undefined;

//   document.addEventListener('touchstart', function(event) {
//     // Your code here
//     event.preventDefault(); // This works because passive is set to false
// }, { passive: false });

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.drop(event);
  }

  constructor(
    public dialog: MatDialog,
    private supportRequestsService: SupportRequestsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2
  ) {
    //window.addEventListener('touchmove', (e) => { e.preventDefault(); }, { passive: false });
   }

  ngOnInit(): void {

    setTimeout(() => {
      this.resetChatButtonPositions();
    }, 0);
  }

  get chatStatus(): boolean {
    const chat = document.getElementById('embeddedMessagingFrame');
    const button = document.getElementById('embedded-messaging');
    button.style.visibility = chat ? 'visible' : 'hidden';

    return chat ? true : false;
  }

  get chatOpenedStatus(): boolean {
    const chat = document.getElementById('embeddedMessagingFrame');
    const spinner = document.getElementById('embeddedMessagingLoadingSpinner');

    return chat ? chat.classList.contains('isMaximized') && !spinner && spinner === null : false;
  }

  get chatLoading(): boolean {
    const spinner = document.getElementById('embeddedMessagingLoadingSpinner');
    return spinner ? true : false;
  }

  openSupportDialog(): void {
    this.googleAnalyticsService.chooseLiveBookingAssistance("live assistance");
    if (this.supportRequestsService.dialogRef === null) {
      this.supportRequestsService.openSupportDialog(
        this.supportRequestsService.supportRequestDialogDataBehaviorSubject.value
      );
    } else {
      this.supportRequestsService.close();
    }
  }

  dragStart(event: any): void{
    //event.preventDefault();
  }

  drop(event: any): void {
    const div = document.getElementsByClassName('boundry')[0];
    const wrapper = document.getElementsByClassName('live-assistance-wrapper')[0];
    const wrapperWidth = wrapper.clientWidth;
    const wrapperHeight = wrapper.clientHeight;
    const parentWidth = div.clientWidth
    const centerWidth = div.clientWidth / 2;
    const chatButtonPosition = this.getChatButtonPositions();

    let x = event?.dropPoint?.x;
    const y = event?.dropPoint?.y;
    const defaultLeft = 8;
    let translateX = 0;
    let translateY = 0;
    this.left = false;
    this.right = false;

    if (!event.dropPoint) {
      x = chatButtonPosition?.elementPosition?.x === -8 ?
        (wrapper as HTMLElement).offsetLeft :
        chatButtonPosition?.elementPosition?.x;
      translateX = x;
    }

    const array = (wrapper as any)?.style?.transform?.replace("translate3d(", "")?.replace(/["'()]/g, "")?.split(",")
    if (centerWidth < x) {
      translateY = parseInt(array[1].trim());
      translateX = -defaultLeft;
      this.right = true;
    }
    else {
      translateX = -(parentWidth - wrapperWidth) + defaultLeft;
      translateY = parseInt(array[1].trim());
      this.left = true;
    }

    this.renderer.setStyle(wrapper, 'transform', `translate3d(${translateX}px, ${translateY.toString()},0px)`);
    this.elementPosition = {
      x: translateX,
      y: translateY
    };

    if (translateY < 0) {
      translateY = 0;
      this.elementPosition.y = 0;
    }

    if (window.innerHeight < (translateY + wrapperHeight)) {
      translateY = window.innerHeight - wrapperHeight;
      this.elementPosition.y = translateY;
    }

    if (!event.dropPoint) {
      (this.sourceEvent?.source?._dragRef as any)._activeTransform.x = translateX;
      (this.sourceEvent?.source?._dragRef as any)._activeTransform.y = translateY;
      (this.sourceEvent?.source?._dragRef as any)._applyRootElementTransform(translateX, translateY);
    }
    else {
      this.sourceEvent = event;
    }

    if (event?.source) {
      (event?.source?._dragRef as any)._activeTransform.x = translateX;
      (event?.source?._dragRef as any)._activeTransform.y = translateY;
      (event?.source?._dragRef as any)._applyRootElementTransform(translateX, translateY);
    }

    this.setElementPosition(this.elementPosition);
    const data = {
      elementPosition: this.elementPosition,
      left: this.left,
      right: this.right
    }
    localStorage.setItem('chatButtonPositions', JSON.stringify(data));
  }

  pevent(event : any)  : void {
      event.preventDefault();
  }

  private setChatButtonPosition(elementPosition: any): void {
    const chatButton = document.getElementsByClassName("embeddedMessagingConversationButton")[0] as HTMLElement;
    const liveAssistanceButtonHeight = document.getElementsByClassName("live-assistance-button")[0]?.clientHeight

    if (chatButton) {
      chatButton.style.display = "block";
      if (this.left) {
        chatButton.style.left = '0px';
        chatButton?.style?.removeProperty("right");
      }
      else {
        chatButton.style.right = '0px';
        chatButton?.style?.removeProperty("left");
      }
      chatButton.style.top = (elementPosition.y + liveAssistanceButtonHeight) + 'px';
    }
  }

  private setElementPosition(elementPosition: any): void {
    const liveAssistanceButtonHeight = document.getElementsByClassName("live-assistance-button")[0]?.clientHeight
    const embeddedMessagingFrame = document.getElementById("embeddedMessagingFrame");

    if (embeddedMessagingFrame) {
      if (embeddedMessagingFrame && this.left) {
        embeddedMessagingFrame.style.left = '0px';
        embeddedMessagingFrame?.style?.removeProperty("right");
      }
      else {
        embeddedMessagingFrame.style.right = '0px';
        embeddedMessagingFrame?.style?.removeProperty("left");
      }
      embeddedMessagingFrame.style.top = (elementPosition.y + liveAssistanceButtonHeight) + 'px';
    }

    this.setChatButtonPosition(elementPosition)
  }

  private getChatButtonPositions(): any {
    const chatButtonPositions = localStorage.getItem('chatButtonPositions');
    return chatButtonPositions ? JSON.parse(chatButtonPositions) : this.getDefaultPosition();
  }

  private getDefaultPosition(): any {
    const wrapper = document.getElementsByClassName('live-assistance-wrapper')[0];
    const wrapperWidth = wrapper.clientWidth;
    const wrapperHeight = wrapper.clientHeight;
    const x = -(window.innerWidth - wrapperWidth - 8);
    const y = (window.innerHeight - 221 - wrapperHeight);
    return { elementPosition: { x: x, y: y }, left: true, right: false }
  }

  private resetChatButtonPositions(): void {
    const data = this.getChatButtonPositions();
    this.elementPosition = data?.elementPosition;
    this.left = data?.left;
    this.right = data?.right;

    if (this.elementPosition) {
      const chatButton = document.getElementsByClassName("embeddedMessagingConversationButton")[0] as HTMLElement;
      chatButton.style.display = "none";

      setTimeout(() => {
        this.setElementPosition(this.elementPosition);
      }, 1000);
    }
  }
}
