import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Contact } from '../bookings.model';
import { BookingContactViewModel } from './booking-contacts.model';

@Injectable()
export class BookingContactsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  addBookingContact(bookingContact: BookingContactViewModel) {
    const httpOptions = this.createContentTypeHttpOptions();

    return this.http.post<string>(
      `${this.internalUrlsService.getApiBaseUrl()}/contacts`, bookingContact, httpOptions);
  }

  getById(bookingId: string, bookingContactId: string): Observable<Contact> {
    return this.http.get<Contact>(
      `${this.internalUrlsService.getApiBaseUrl()}/contacts/${bookingId}/${bookingContactId}`);
  }

  getByBookingId(bookingId: string): Observable<Array<Contact>> {
    return this.http.get<Array<Contact>>(
      `${this.internalUrlsService.getApiBaseUrl()}/contacts/${bookingId}`);
  }

  deleteBookingContact(contactId: string): Observable<void> {
    return this.http.delete<void>(`${this.internalUrlsService.getApiBaseUrl()}/contacts/${contactId}`);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
