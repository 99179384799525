<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="apply-credit-line-wrapper d-flex flex-column justify-content-between">
  <div class="d-flex flex-row justify-content-between">
    <div class="apply-credit-line-left-block d-flex flex-column">
      <div class="apply-credit-line-left-block-first-line">Net 90 Payment Terms for Your Shipments from Cargo Arrival Date</div>
      <div class="apply-credit-line-left-block-second-line">Easy Application With No Forms Or Paperwork.</div>
      <div class="apply-credit-line-left-block-third-line">Check your eligibility now and join our SMB customers who have already received $3 million USD worth of credit lines.​</div>
      <button mat-raised-button class="apply-now-button" (click)="initCreditLine()">
        Apply Now
      </button>
    </div>
    <div class="apply-credit-line-right-block d-flex flex-column align-items-end">
      <div class="right-block-list-item">
        <div class="right-block-list-item-image">
          <img src="../../../../assets/images/credit-line/stripe.svg">
        </div>
        <div class="right-block-list-item-text">
          We use Stripe for a secure eligibility checking process.
        </div>
      </div>
      <div class="right-block-list-item">
        <div class="right-block-list-item-image">
          <img src="../../../../assets/images/credit-line/zim.svg">
        </div>
        <div class="right-block-list-item-text">
          We are powered by ZIM (NYSE: ZIM).
        </div>
      </div>
      <div class="right-block-list-item">
        <div class="right-block-list-item-image">
          <img src="../../../../assets/images/credit-line/fmc.svg">
        </div>
        <div class="right-block-list-item-text">
          We are licensed by the Federal Maritime Commission.
        </div>
      </div>
    </div>
  </div>
  <div class="apply-credit-line-footer d-flex flex-column w-100">
    <div class="footer-text-title">To be eligible you need:</div>
    <div class="footer-text-list d-flex flex-row justify-content-between">
      <div class="footer-text-list-item d-flex flex-row">
        <i class="footer-check-icon s4d-icon s4d-check-circle"></i>
        <div class="footer-text">To connect your business bank account - secured by Stripe</div>
      </div>
      <div class="footer-text-list-item d-flex flex-row">
        <i class="footer-check-icon s4d-icon s4d-check-circle"></i>
        <div class="footer-text">A bank account that was opened more than 6 months ago</div>
      </div>
      <div class="footer-text-list-item d-flex flex-row">
        <i class="footer-check-icon s4d-icon s4d-check-circle"></i>
        <div class="footer-text">A monthly revenue above 10,000 USD</div>
      </div>
    </div>
  </div>
</div>