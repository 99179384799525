import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompanySearchService } from './company-search.service';
import { CompanySearchQuery, FoundCompany } from './company-search.model';
import { Organization } from '../../desktop/manage-organization/manage-organization.model';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanySearchInputComponent implements OnInit {
  @Input() legalNameControl: FormControl = undefined;
  @Input() organization: Organization;
  @Input() placeholder: string = "Organization Legal name";
  @Input() titleText: string = "Organization Legal Name";
  @Input() appearance: string = "fill";
  @Input() floatLevel: string = "auto";
  @Input() class: string = "w-100";
  @Input() disabled: boolean = false;
  @Input() tooltip: string = "Please add your organization's actual legal name"

  isLoading = false;
  isSelected = false;
  invalidError = { 'incorrect': true };
  filteredCompanies: Observable<FoundCompany[]>;
  companies: FoundCompany[];
  selectedCompany: FoundCompany;

  constructor(
    private companyService: CompanySearchService) { }

  ngOnInit(): void {
    this.filteredCompanies = this.legalNameControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.fetchCompanies(value as string))
    );
  }


  displayCompanyFn(company: string): string {
    return company !== '' ? company : '';
  }

  getCompanyTooltip(company: FoundCompany): string {
    return company.companyName + ', ' + company.postalCode + ', ' + company.streetAddress;
  }

  fetchCompanies(company: string): Observable<FoundCompany[]> {
    if (company === this.selectedCompany?.companyName) {
      return of(this.companies);
    }

    this.isSelected = false;
    this.selectedCompany = null;

    if (company.length >= 3 && environment.isDnbEnabled) {
      this.isLoading = true;
      const query = {
        country: this.organization?.countryCode,
        city: this.organization?.city,
        state: this.organization?.state,
        companyName: company,
        pageNo: 1,
        pageSize: 25
      } as CompanySearchQuery;

      return this.companyService.getFoundCompanies(query).pipe(
        map(response => {
          this.legalNameControl.updateValueAndValidity()
          this.isLoading = false;

          this.companies = response.items;
          return response.items;
        })
      );
    } else {
      this.isLoading = false;
      return of([]);
    }
  }

  onSelectCompany(company: FoundCompany): void {
    this.selectedCompany = company;
    this.isSelected = true;
  }
}
