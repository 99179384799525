<div class="credits-widget-container" [ngClass]="{ 'is-empty-container': isEmpty }">
  <div class="widget-wrapper">
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
      <div *ngIf="isLoading && !isEmpty" class="col d-flex flex-column flex-grow-1 justify-content-between is-loader">
        <div class="row">
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <div class="content-button loader-element"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && isError" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>

      <div *ngIf="isCreditLineNew" class="col is-empty" (click)="initCreditLine()">
        <span class="is-empty-icon"></span>
        <span class="is-empty-title">Apply for Credit Line</span>
      </div>

      <div *ngIf="isCreditLineRequested" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="is-empty-title">Your Credit Application Is Being Processed</span>
      </div>

      <div *ngIf="isCreditLinePreapproved" class="col is-empty" (click)="claimCreditLine()">
        <span class="is-rocket-icon"></span>
        <span class="is-empty-title">Get <span class="credit-price">$10,000</span> credit line</span>
      </div>

      <div *ngIf="isCreditLineRejected" class="col is-empty">
        <span class="is-rejected-icon"></span>
        <span class="is-empty-title">Your credit application was rejected</span>
      </div>

      <div *ngIf="isCreditLineApproved" class="credit-line-approved">
        <div class="credit-line-approved-title">Credit Line</div>
        <div class="credit-line-approved-info-line">
          <span class="info-line-title col-8">Approved credit</span>
          <span class="info-line-amount col">{{ creditLine.totalLimit | currency : "USD" : true : "1.0-0" }}</span>
        </div>
        <div class="credit-line-approved-info-line">
          <span class="info-line-title col-8">Utilised funds</span>
          <span class="info-line-amount col">-{{
            creditLine.totalLimit - creditLine.availableLimit
            | currency : "USD" : true : "1.0-0"
            }}</span>
        </div>
        <div class="credit-line-approved-info-line">
          <span class="info-line-title col-8">Total available</span>
          <span class="info-line-amount col">{{creditLine.availableLimit | currency : "USD" : true : "1.0-0" }}</span>
        </div>
        <div class="credit-line-approved-button">
          <button *ngIf="creditLine.statusId === CreditLineStatuses.approved" mat-stroked-button color="primary"
            class="content-button" (click)="initCreditLine()">
            Ask to Increase Limit
          </button>
          <button *ngIf="
              creditLine.statusId === CreditLineStatuses.increaseRequested
            " mat-stroked-button color="primary" class="content-button disabled">
            Increase Limit Requested
          </button>
        </div>
      </div>
    </div>
  </div>
</div>