import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from '@ship4wd/ngx-authorization';
import { PrintBookingComponent } from '../desktop/booking/print-booking/print-booking.component';
import { AccessRoleGuard } from '../shared/guards/access-role.guard';
import { OrganizationGuard } from '../shared/guards/organization.guard';
import { AccessRoleType } from '../shared/shared.model';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { BookingFilesDocumentsComponent } from './booking-files-documents/booking-files-documents.component';
import { BookingShippingInstructionsComponent } from './booking-shipping-instructions/booking-shipping-instructions.component';
import { ViewBookingComponent } from './booking/view-booking/view-booking.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { LayoutComponent } from './layout/layout.component';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PaymentSentComponent } from '../shared/payments/payment-sent/payment-sent.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { ConfirmPaymentComponent } from './payments/confirm-payment/confirm-payment.component';
import { FinanceComponent } from './finance/finance.component';
import { PaymentSuccessfulComponent } from '../shared/payments/payment-successful/payment-successful.component';
import { PaymentOfflineComponent } from '../shared/payments/payment-offline/payment-offline.component';
import { PaymentPayLaterComponent } from '../shared/payments/paymnet-pay-later/payment-pay-later.component';
import { ManageContactsComponent } from './manage-contacts/manage-contacts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreditLineComponent } from './credit-line/credit-line.component';
import { QuoteSearchFlowComponent } from './quotes/quote-search-flow/quote-search-flow.component';
import { BookingFlowComponent } from './booking/booking-flow/booking-flow.component';
import { BookingFlowSummaryComponent } from './booking/booking-flow/booking-flow-summary/booking-flow-summary.component';
import { PaymentStatusComponent } from '../shared/payments/payment-status/payment-status.component';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';

export const MOBILE_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'booking/pdf/:id',
    component: PrintBookingComponent
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'creditLine',
        component: CreditLineComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'creditLine/preapproved',
        component: CreditLineComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'shipments',
        component: ShipmentsComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'organizations',
        component: OrganizationsComponent
      },
      {
        path: 'quote-search',
        component: QuoteSearchFlowComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'booking/:id',
        component: BookingFlowComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'booking/summary/:id',
        component: BookingFlowSummaryComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'booking/view/:id',
        component: ViewBookingComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'booking/view/:pageType/:id',
        component: ViewBookingComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'booking/:id/:isEdit',
        component: BookingFlowComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'files-documents/:pageType/:filesPageType/:id',
        component: BookingFilesDocumentsComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'shipping-instructions/:id',
        component: BookingShippingInstructionsComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'manage-organization',
        component: ManageOrganizationComponent,
        canActivate: [OrganizationGuard, AccessRoleGuard],
        data: { allowAccess: [AccessRoleType.owner, AccessRoleType.admin] }
      },
      {
        path: 'manage-contacts',
        component: ManageContactsComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'payments/:id',
        component: PaymentsComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'payment/:bookingId/:id',
        component: PaymentComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'confirmPayment/:bookingId/:id',
        component: ConfirmPaymentComponent,
        canActivate: [OrganizationGuard]
      },
      {
        path: 'finance',
        component: FinanceComponent,
        canActivate: [OrganizationGuard]
      }
    ]
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'paymentStatus',
        component: PaymentStatusComponent,
        canActivate: [OrganizationGuard],
        data: { isMobile: true }
      },
      {
        path: 'paymentSent',
        component: PaymentSentComponent,
        canActivate: [OrganizationGuard],
        data: { isMobile: true }
      },
      {
        path: 'paymentSuccessful',
        component: PaymentSuccessfulComponent,
        canActivate: [OrganizationGuard],
        data: { isMobile: true }
      },
      {
        path: 'payLater',
        component: PaymentPayLaterComponent,
        canActivate: [OrganizationGuard],
        data: { isMobile: true }
      },
      {
        path: 'paymentOffline',
        component: PaymentOfflineComponent,
        canActivate: [OrganizationGuard],
        data: { isMobile: true }
      },
    ]
  },
  {
    path: 'auth/callback',
    component: AuthenticationComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(MOBILE_ROUTES)],
  exports: [RouterModule]
})
export class MobileRoutingModule { }
