import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { Equipments } from '../../../../../shared/equipments-data';
import { SizeService } from '../../../../../shared/helper/size.service';
import { DangerousGoodsType, DimensionUnit, Equipment, LoadUnit, PackageSizeType, QuoteSearchParameters, SelectItem, ShipmentType, UnitMeasurementType, VolumeUnit, WeightRange, WeightUnit } from '../../../../../shared/shared.model';
import { UtilityService } from '../../../../../shared/helper/utility.service';
import { environment } from '../../../../../../environments/environment';
import { QuotesService } from '../../../quotes.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TripUnLocodeAutocompleteService } from 'src/app/shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { weightRangeImperial, weightRangeMetric } from 'src/app/shared/air-weight-range-data';

@Component({
  selector: 'app-quote-search-flow-cargo-details',
  templateUrl: './quote-search-flow-cargo-details.component.html',
  styleUrls: ['./quote-search-flow-cargo-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowCargoDetailsComponent implements OnInit, OnChanges {
  @Input() shipmentType = ShipmentType.FCL;
  @Input() commoditiesFormArray: FormArray;
  @Input() equipmentsFormArray: FormArray;
  @Input() isHazardous: FormControl;
  @Input() isBatteries: FormControl;
  @Input() isShowPackageDimensions: FormControl;
  @Input() isRecentQuotesSearch: boolean;
  @Input() unitMeasurementType: FormControl;
  @Input() isOpened: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() hasError: boolean = false;
  @Input() quoteSearchParameter: QuoteSearchParameters | undefined;
  @Input() from: any = '';
  @Input() to: any = '';
  @Input() form: FormGroup | any = undefined;

  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() clickedOutside: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<QuoteSearchParameters> = new EventEmitter();
  @Output() opened: EventEmitter<boolean> = new EventEmitter();
  @Output() changePieceCalculationToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() hazardousTouched: EventEmitter<boolean> = new EventEmitter();

  shipmetTypes = ShipmentType;
  equipments: Equipment[] = Equipments;
  weightRange: WeightRange[] = weightRangeMetric;
  selectedWeightRange = new FormControl();
  defaultEquipment: string = "45G1"
  defaultWeightRange: any = '1 - 70 kg';
  cargoDetails: string = '';
  totalPackages = 0;
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalChargeableWeight = 0;
  grandTotalChargeableVolume = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  sizeErrorString = null;
  dangerousGoodsWarning: any = null;
  selectedDangerousGoodsType: DangerousGoodsType;
  isCheckingBatteriesSupportInProgress: boolean = false;
  isNoDimensionEnable: boolean = false;

  unitMeasurementTypes: SelectItem[] = [
    { name: UnitMeasurementType[UnitMeasurementType.imperial], value: UnitMeasurementType.imperial },
    { name: UnitMeasurementType[UnitMeasurementType.metric], value: UnitMeasurementType.metric }
  ];

  dimensionUnits: SelectItem[] = [
    { name: DimensionUnit[DimensionUnit.CM], value: DimensionUnit.CM },
    { name: DimensionUnit[DimensionUnit.M], value: DimensionUnit.M },
    { name: DimensionUnit[DimensionUnit.IN], value: DimensionUnit.IN },
    { name: DimensionUnit[DimensionUnit.FT], value: DimensionUnit.FT }
  ];

  weightUnits: SelectItem[] = [
    { name: WeightUnit[WeightUnit.KG], value: WeightUnit.KG },
    { name: WeightUnit[WeightUnit.LB], value: WeightUnit.LB },
  ];

  loadUnits: SelectItem[] = [
    { name: LoadUnit[LoadUnit.volume], value: LoadUnit.volume }
  ];

  showPackageDimensionTypes: any[] = [
    { name: "By Total", value: false },
    { name: "By Piece", value: true }
  ];

  dangerousGoodsTypes: any[] = [
    { name: "Batteries", value: DangerousGoodsType.BATTERIES },
    { name: "Other Dangerous Goods", value: DangerousGoodsType.OTHER }
  ];

  dangerousGoodsWarnings: any[] = [
    { icon: "s4d-hazardous", description: this.sanitizeHtml("Currently, we can only handle Dangerous Goods for FCL shipments") },
    { icon: "s4d-info", description: this.sanitizeHtml("Both the Origin and Destination fields are required. Please select valid options for both") },
    { icon: "s4d-hazardous", description: this.sanitizeHtml("Currently, we don't handle hazardous shipments. <a href='https://ship4wd.com/support-category/dangerous-goods/' target='_blank'>Learn More</a>") },
    { icon: "s4d-hazardous", description: this.sanitizeHtml("We currently do not support shipping batteries to or from this origin/destination. <a href='https://ship4wd.com/support-category/dangerous-goods/' target='_blank'>See the list of available ports</a>") }
  ]

  clickHandler: any = (event) => {
    const isFromToPanel = this.getParentContainerClass(event);
    if (!this.elRef.nativeElement.contains(event.target)
      && !isFromToPanel) {
      this.actionsOnClosing(event.target);

      this.onConfirm(false);
    }
  };

  get isShowPackageTotalVolumeAmount(): boolean {
    return this.shipmentType == ShipmentType.LCL && !this.isShowPackageDimensions.value;
  }

  get getOverlayPosition(): ConnectedPosition[] {
    return [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: window.innerHeight <= 790 ? -176 : 0
      },
    ]
  }

  get isShowGrandTotalChargeableVolume(): boolean {
    return this.grandTotalChargeableWeight > this.grandTotalWeightAmount
  }

  get totalChargeableWeightTooltip(): string {
    if (this.shipmentType != ShipmentType.LCL) return;

    switch (this.unitMeasurementType.value) {
      case UnitMeasurementType.metric:
        return "Total Chargeable Weight is calculated based on the higher value. 1 cbm = 1,000 kg"
      case UnitMeasurementType.imperial:
        return "Total Chargeable Weight is calculated based on the higher value. 1 lb = 48 cui"
    }
  }

  constructor(
    private sizeService: SizeService,
    private elRef: ElementRef,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private quotesService: QuotesService,
    private utilityService: UtilityService,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    debugger
    if (this.commoditiesFormArray.length > 0 || this.equipmentsFormArray.length > 0) {
      this.onSelect();
    }

    this.equipments.forEach(x => {
      if (x.isoCode === this.defaultEquipment) x.disabled = true;
      else x.disabled = false
    });

    this.totalLoadCalculation();

    this.quotesService.saveUnitMeasurementInStorage(this.unitMeasurementType.value);
    this.unitMeasurementType.value == UnitMeasurementType.imperial ?
      this.weightRange = weightRangeImperial :
      this.weightRange = weightRangeMetric;
    this.subscribeActiveMenuOption();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.commoditiesFormArray.controls?.length !== 0 || this.equipmentsFormArray.controls?.length !== 0) {
      this.setParamTitle();
    }

    if (changes.shipmentType && changes.shipmentType?.previousValue !== undefined) setTimeout(() => {
      if (!(changes.shipmentType.currentValue === ShipmentType.LCL && changes.shipmentType.previousValue === ShipmentType.AIR) &&
        !(changes.shipmentType.currentValue === ShipmentType.AIR && changes.shipmentType.previousValue === ShipmentType.LCL)) {
        this.isSelected = false;
        this.setSelected.next(this.isSelected);
      }

      if (changes.shipmentType.currentValue === ShipmentType.FCL) {
        this.commoditiesFormArray.reset();
      }
      else {
        this.equipmentsFormArray.reset();
      }
    }, 100);

    if ((changes.shipmentType?.previousValue !== changes.shipmentType?.currentValue ||
      changes.from?.previousValue !== changes.from?.currentValue ||
      changes.to?.previousValue !== changes.to?.currentValue) && !this.isRecentQuotesSearch) setTimeout(() => {
        this.isHazardous.setValue(false);
        this.isBatteries.setValue(false);
        this.selectedDangerousGoodsType = null;
      }, 100);

    if (changes.isOpened?.currentValue === true) setTimeout(() => {
      this.isOpened = false;
      this.autoConfirm();
      this.onOpenClick();
    }, 100);
    if (changes.isOpened?.currentValue === false) this.autoConfirm(false);

    if (changes.isRecentQuotesSearch.currentValue) {
      this.selectedDangerousGoodsType = this.isBatteries.value ? DangerousGoodsType.BATTERIES : DangerousGoodsType.OTHER;
    }
  }

  onOpenClick(): void {
    debugger
    this.isOpened = !this.isOpened;
    this.isNoDimensionEnable = this.form.isWithoutDimension.value

    if (this.shipmentType == ShipmentType.AIR && this.isNoDimensionEnable)
      this.onAfterChange();

    if (this.shipmentType != ShipmentType.AIR)
      this.isNoDimensionEnable = false;

    this.setDefaults();

    if (this.hasError) {
      this.equipmentsFormArray.markAllAsTouched();
      this.commoditiesFormArray.markAllAsTouched();
    }

    this.setOverlayVisibility();

    this.autoConfirm();
    this.quotesService.setActiveMenuOption(this.quoteSearchParameter);
  }

  onConfirm(emitBackground: boolean = true): void {
    this.autoConfirm(false);

    if (!this.quotesSearchStepContainers()) {
      return;
    }

    if (!this.isTotalLoadCalculationValid()) {
      const maxWeight = this.getMaxWeight();
      const maxCapacity = this.getMaxCapacity();

      const weightUnit = this.unitMeasurementType.value === UnitMeasurementType.metric ? 'KG' : 'LB';
      const capacityUnit = this.unitMeasurementType.value === UnitMeasurementType.metric ? 'CBM' : 'CUI';

      const message = `Search limited by ${maxWeight} ${weightUnit} for weight
        and ${maxCapacity} ${capacityUnit} for volume. Please update your request`;

      this.openPopup(message);
      return;
    }

    if (this.commoditiesFormArray && this.commoditiesFormArray.length > 0) {
      this.commoditiesFormArray.controls.forEach((e: any) => {
        if (e.controls.totalVolumeAmount.value) {
          e.controls.volumeAmount.patchValue(e.controls.totalVolumeAmount.value);
        }
      });
    }

    if (emitBackground) {
      this.isOpened = false;
      this.setOverlayVisibility();
    }

    this.setParamTitle();
    this.setSelected.next(this.isSelected);
    this.next.emit(QuoteSearchParameters.cargo);
  }

  onSelect(): void {
    this.isSelected = true;
    this.setSelected.next(this.isSelected);
  }

  onOpenedChanged(state: boolean, equipmentForm: FormGroup): void {
    if (state) {
      if (equipmentForm.controls.weightAmount) {
        equipmentForm.get('selectedValue').markAsTouched();
        equipmentForm.get('packageWeight').markAsTouched();
        equipmentForm.get('dimensionValue').markAsTouched();
        this.onSelectionChangeweight(this.weightRange.find(x => x.range === this.defaultWeightRange) as WeightRange, equipmentForm)
      }
      else {
        equipmentForm.get('equipmentCode').markAsTouched();
      }
    }
  }

  onSelectionChangeEquipment(equipment: Equipment, equipmentForm): void {
    const oldCode = equipmentForm.get('equipmentCode').value;

    if (oldCode !== equipment.isoCode) {
      const oldEquipment = this.equipments.find(x => x.isoCode == oldCode);

      if (oldEquipment) {
        oldEquipment.disabled = false;
      }
    }

    equipmentForm.get('equipmentCode').setValue(equipment.isoCode);
    equipmentForm.get('equipmentTypeDescription').setValue(equipment.description);

    let quantity = equipmentForm.value.quantity;
    if (quantity == '' || quantity == '0') {
      equipmentForm.get('quantity').setValue('1');
    }

    equipment.disabled = true;
  }

  onSelectionChangeweight(range: WeightRange, commodityForm: FormGroup): void {
    commodityForm.patchValue({ selectedValue: range });
    console.log(commodityForm.get('selectedValue'));
    commodityForm.get('packageWeight').setValue(range.weight);
    if (range.dimensions) {
      commodityForm.get('dimensionValue').setValue(range.dimensions)
      this.onChangeDimensionValue(commodityForm);
    }

    let quantity = commodityForm.value.quantity;
    if (quantity == '' || quantity == '0') {
      commodityForm.get('quantity').setValue('1');
    }
  }

  onAddQuantity(commodityForm: FormGroup): void {
    if (this.shipmentType == ShipmentType.FCL) {
      let quantity = (commodityForm.controls.quantity.value) as number;
      if (!Number.isNaN(quantity) && quantity !== undefined && quantity !== null) {
        commodityForm.get('quantity').setValue(Number(quantity) + 1);
      }
      else {
        commodityForm.get('quantity').setValue(1);
      }
    }
    else {
      const numberOfPackages = commodityForm.controls.numberOfPackages.value as number;
      if (!Number.isNaN(numberOfPackages) && numberOfPackages !== undefined && numberOfPackages !== null) {
        commodityForm.get('numberOfPackages').setValue(numberOfPackages + 1);
      }
      else {
        commodityForm.get('numberOfPackages').setValue(1);
      }
      this.preparePackageTotalVolumeAmountData(commodityForm);
      this.calculateVolume(commodityForm);
    }

  }

  onMinusQuantity(commodityForm: FormGroup): void {
    if (this.shipmentType == ShipmentType.FCL) {
      let quantity = (commodityForm.controls.quantity.value) as number;
      if (!Number.isNaN(quantity) && quantity !== undefined && quantity !== null && quantity > 1) {
        commodityForm.get('quantity').setValue(Number(quantity) - 1);
      }
      else {
        commodityForm.get('quantity').setValue(1);
      }
    }
    else {
      const numberOfPackages = commodityForm.controls.numberOfPackages.value as number;
      if (!Number.isNaN(numberOfPackages) && numberOfPackages !== undefined && numberOfPackages !== null && numberOfPackages > 1) {
        commodityForm.get('numberOfPackages').setValue(numberOfPackages - 1);
      }
      else {
        commodityForm.get('numberOfPackages').setValue(1);
      }

      this.preparePackageTotalVolumeAmountData(commodityForm);
      this.calculateVolume(commodityForm);
    }
  }

  onRemoveEquipment(equipmentForm: FormGroup, event?: any): void {
    event?.stopPropagation();

    this.equipmentsFormArray.removeAt(
      this.equipmentsFormArray.controls.indexOf(equipmentForm)
    );

    const equipment = this.equipments.find(x => x.isoCode == equipmentForm.value.equipmentCode);
    if (equipment) {
      equipment.disabled = false;
    }
  }

  onRemoveLoad(commodityForm: FormGroup): void {
    this.commoditiesFormArray.removeAt(
      this.commoditiesFormArray.controls.indexOf(commodityForm)
    );

    this.totalLoadCalculation();
  }

  onAdd(event?: any): void {
    event?.stopPropagation();

    if (this.shipmentType === ShipmentType.FCL) {
      this.addEquipment();
    }
    else {
      this.addLoad();
    }
  }

  onChangeUnitToggle(): void {
    this.setValuesOnUnitToggle();
    this.quotesService.saveUnitMeasurementInStorage(this.unitMeasurementType.value);
  }

  onChangeDimensionValue(commodityForm: FormGroup): void {
    const dimension = commodityForm.controls.dimensionValue.value as string;

    if (dimension) {
      let dimensionArray = dimension.replace(' ', '').split('x');
      const invalid = dimensionArray.find(x => Number(x) === 0);

      if (dimensionArray && dimensionArray.length > 0 && dimensionArray.length === 3 && invalid) {
        commodityForm.controls.dimensionValue.setErrors({ min: 0 });
        commodityForm.controls.dimensionValue.markAsTouched();

        commodityForm.get('length').setErrors(null);
        commodityForm.get('width').setErrors(null);
        commodityForm.get('height').setErrors(null);
        return;
      }
      if (dimensionArray && dimensionArray.length > 0 && dimensionArray.length === 3 && !invalid
        && Number(dimensionArray[0]) > 0 && Number(dimensionArray[1]) > 0 && Number(dimensionArray[2]) > 0) {
        commodityForm.get('length').setValue(Number(dimensionArray[0]));
        commodityForm.get('width').setValue(Number(dimensionArray[1]));
        commodityForm.get('height').setValue(Number(dimensionArray[2]));

        if (commodityForm.get('length').invalid || commodityForm.get('width').invalid || commodityForm.get('height').invalid) {
          commodityForm.controls.dimensionValue.setErrors(Validators.max);
          commodityForm.controls.dimensionValue.markAsTouched();
          return;
        }
        else {
          commodityForm.controls.dimensionValue.setErrors(null);
        }

        this.calculateVolume(commodityForm);

        let numberOfPackages = commodityForm.value.numberOfPackages;
        if (numberOfPackages == '' || numberOfPackages == '0') {
          commodityForm.get('numberOfPackages').setValue('1');
        }
      }
    }
    else {
      commodityForm.controls.totalDimensionValueAmount.setValue('');
      commodityForm.controls.loadUnit.disable();
    }
  }

  onChangeLength(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeWidth(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeHeight(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeWeight(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeNumberOfPackages(commodityForm: FormGroup): void {
    this.preparePackageTotalVolumeAmountData(commodityForm);
    this.calculateVolume(commodityForm);
  }

  onChangePackageVolume(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeWeightUnit(weightUnit: WeightUnit): void {
    if (weightUnit === WeightUnit.KG) {
      this.unitMeasurementType.setValue(UnitMeasurementType.metric);
    }
    else {
      this.unitMeasurementType.setValue(UnitMeasurementType.imperial);
    }

    this.onChangeUnitToggle();
  }

  onChangePackageNumber(commodityForm: FormGroup): void {
    this.calculateVolume(commodityForm);
  }

  onChangeWeightAmount(commodityForm: FormGroup): void {
    this.preparePackageTotalVolumeAmountData(commodityForm);
    this.calculateVolume(commodityForm);
  }

  onChangeVolumeAmount(commodityForm: FormGroup): void {
    this.preparePackageTotalVolumeAmountData(commodityForm);
    this.calculateVolume(commodityForm);
  }

  onChangeLoadUnit(loadUnit: LoadUnit, commodityForm: FormGroup): void {
    if (loadUnit === LoadUnit.dimensions) {
      commodityForm.controls.isShowMaskedInput.setValue(false);
    } else {
      commodityForm.controls.isShowMaskedInput.setValue(true);
    }
  }

  onTotalDimensionValueAmountClick(e: any, commodityForm: FormGroup): void {
    e.stopPropagation();
    commodityForm.controls.loadUnit.setValue(LoadUnit.dimensions);
    commodityForm.controls.isShowMaskedInput.setValue(false);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.isoCode === o2;
  }

  compareWeightObjects(o1: any, o2: any): boolean {
    return o1.weight === o2.weight;
  }

  getToolTipData(unitMeasurementType: string): string {
    return unitMeasurementType === UnitMeasurementType[UnitMeasurementType.metric] ? "KG, CM, CBM" : "LB, IN, CUI";
  }

  getEquipmentName(equipmentCode: string): string {
    let result: string = "";
    this.equipments.forEach((equipment: Equipment) => {
      if (equipment.isoCode === equipmentCode) result = equipment.description;
    });

    return result;
  }

  checkValid(control: FormControl): boolean {
    return !control?.valid && control?.touched
  }

  composeSizeErrorString(): void {
    if (this.unitMeasurementType.value == UnitMeasurementType.metric)
      this.sizeErrorString = `Maximum values - ` +
        `${environment.lclLengthPackageLimit} x ` +
        `${environment.lclWidthPackageLimit} x ` +
        `${environment.lclHeightPackageLimit}`;
    else
      this.sizeErrorString = `Maximum values - ` +
        `${this.sizeService.convertCentimetersToInches(environment.lclLengthPackageLimit)} x ` +
        `${this.sizeService.convertCentimetersToInches(environment.lclWidthPackageLimit)} x ` +
        `${this.sizeService.convertCentimetersToInches(environment.lclHeightPackageLimit)}`;
  }

  getValidatorErrorKey(control: FormControl): string {
    if (control.errors) {
      for (const errorKey in control.errors) {
        return errorKey;
      }
    }

    return null;
  }

  onShowPackageDimensionsChanged(): void {
    this.changePieceCalculationToggle.emit(this.isShowPackageDimensions.value);
    this.commoditiesFormArray.clear();
    this.addLoad();
  }

  isShowAddContainerOrLeadButton(): boolean {
    return !this.isShowPackageTotalVolumeAmount
      && (this.checkAddEquipmentButtonVisibility() || this.shipmentType !== ShipmentType.FCL)
  }

  isShowChargeableVolumeAmount(commodityForm: FormGroup): boolean {
    return commodityForm.get('chargeableWeightAmount')?.value > commodityForm.get('totalWeightAmount')?.value;
  }

  onClickedOutsideOverlay(event: MouseEvent): void {
    if (!this.utilityService.isEventTargetHasClass(event.target, "no-close")) {
      this.isOpened = false;

      const isFromToPanel = this.getParentContainerClass(event);
      if (!this.elRef.nativeElement.contains(event.target)
        && !isFromToPanel) {
        this.actionsOnClosing(event.target);
        this.setParamTitle();
      }

      this.setOverlayVisibility();
    }
  }

  onHazardousChanged(value: any): void {
    if (value.checked || value === true) {
      if (this.shipmentType === ShipmentType.FCL) {
        if (this.from === undefined || this.to === undefined || this.from === '' || this.to === '') {
          this.dangerousGoodsWarning = this.dangerousGoodsWarnings[1];
        }
        else {
          this.checkDangerousGoods();
        }
      }
      else {
        this.dangerousGoodsWarning = this.dangerousGoodsWarnings[0];
      }
      this.hazardousTouched.emit(true)
    }
    else {
      this.dangerousGoodsWarning = null;
      this.selectedDangerousGoodsType = null;
      this.isHazardous.setValue(false);
      this.isBatteries.setValue(false);
      this.hazardousTouched.emit(false)
    }
  }

  onDangerousGoodsTypeChanged(): void {
    this.checkDangerousGoods();
  }

  onChange(event: MatSlideToggleChange): void {
    this.isNoDimensionEnable = event.checked;
    this.form.isWithoutDimension.setValue(this.isNoDimensionEnable);
    this.onAfterChange()
  }

  onAfterChange() {
    if (this.isNoDimensionEnable) {
      this.commoditiesFormArray.controls.forEach((commoditiesFormControl, index) => {
        debugger
        let commodity;
        let commodityValue = commoditiesFormControl.get('packageWeight').value

        const thresholds = this.weightRange === weightRangeMetric
          ? [70, 100, 250, 500, 1000]
          : [155, 220, 550, 1100, 2200];

        for (const threshold of thresholds) {
          if (commodityValue <= threshold) {
            commodityValue = threshold;
            break;
          }
        }

        commodity = commodityValue ?
          this.weightRange.find(x => x.weight.includes(commodityValue)) :
          this.weightRange.find(x => x.weight.includes(this.defaultWeightRange));
        this.onSelectionChangeweight(commodity as WeightRange, commoditiesFormControl as FormGroup);
      });
    }
  }

  private checkAddEquipmentButtonVisibility(): boolean {
    return !!this.getEquipmentToAdd(this.equipments, this.equipmentsFormArray.controls)
  }

  private setDefaults(): void {
    this.autoConfirm(false);

    this.equipments.forEach(equipment => equipment.disabled = false);

    if (this.shipmentType === ShipmentType.LCL || this.shipmentType === ShipmentType.AIR) {
      if (this.commoditiesFormArray.controls?.length === 0) {
        this.commoditiesFormArray?.push(this.createCommoditiesForm());
      } else if (this.commoditiesFormArray.invalid) {
        this.resetContainerCommodity();
      } else {
        this.commoditiesFormArray.controls.forEach(commodity => {
          this.calculateVolume(commodity as FormGroup);
        });
      }

      this.equipmentsFormArray.clear();
    }

    if (this.shipmentType === ShipmentType.FCL) {
      if (this.equipmentsFormArray.controls?.length === 0) {
        const form = this.createEquipmentsForm();
        this.onSelectionChangeEquipment(this.equipments.find(x => x.isoCode === this.defaultEquipment) as Equipment, form)
        this.equipmentsFormArray?.push(form);
      } else if (this.equipmentsFormArray.invalid) {
        this.equipmentsFormArray.clear();
        const form = this.createEquipmentsForm();
        this.onSelectionChangeEquipment(this.equipments.find(x => x.isoCode === this.defaultEquipment) as Equipment, form)
        this.equipmentsFormArray?.push(form);
      } else if (this.equipmentsFormArray.controls?.length > 0) {
        this.equipmentsFormArray.controls.forEach(equipmentControl => {
          const equipment = this.equipments.find(x => x.isoCode === equipmentControl.get('equipmentCode').value);
          equipment.disabled = true;
        });
      }
      this.commoditiesFormArray.clear();
    }
  }

  private setParamTitle(): void {
    if (this.shipmentType == ShipmentType.FCL) {
      const selected = this.equipmentsFormArray?.controls;

      let numberOfContainers = 0;
      selected.forEach(x => numberOfContainers += Number(x.value.quantity) ?? 0);

      const result = numberOfContainers

      this.isSelected = result > 0;

      this.cargoDetails = result === 1 ? `${result} Container` : `${result} Containers`;
    }
    else {
      const selected = this.commoditiesFormArray?.controls;

      let numberOfPackages = 0;
      selected.forEach(x => numberOfPackages += Number(x.value.numberOfPackages) ?? 0);

      const result = numberOfPackages

      this.isSelected = result > 0;

      this.cargoDetails = result === 1 ? `${result} Package` : `${result} Packages`;
    }
  }

  private addEquipment(): void {
    let newEquipment = this.getEquipmentToAdd(this.equipments, this.equipmentsFormArray.controls);

    if (!!newEquipment) {
      const newEquipmentForm = this.createEquipmentsForm();
      this.equipmentsFormArray.push(newEquipmentForm);
      this.onSelectionChangeEquipment(newEquipment, newEquipmentForm)
    }
  }

  private getEquipmentToAdd(equipments: Equipment[], equipmentControls: AbstractControl[]): Equipment | null {
    return equipments.find(equipment =>
      !equipmentControls.some(control =>
        (control as FormControl).value.equipmentCode === equipment.isoCode
      )
    ) || null;
  }

  private addLoad(): void {
    this.commoditiesFormArray?.push(this.createCommoditiesForm());
  }

  private createEquipmentsForm(): any {
    return this.fb.group({
      equipmentCode: ['', Validators.required],
      equipmentTypeDescription: [''],
      quantity: ['', [Validators.required, Validators.min(1)]]
    });
  }

  private createCommoditiesForm(): any {
    return this.fb.group({
      selectedValue: [],
      dimensionUnit: [
        {
          value: this.unitMeasurementType.value ===
            UnitMeasurementType.imperial ? DimensionUnit.IN : DimensionUnit.CM,
          disabled: true
        },
        Validators.required
      ],
      weightUnit: [
        {
          value: this.unitMeasurementType.value ===
            UnitMeasurementType.imperial ? WeightUnit.LB : WeightUnit.KG,
          disabled: false
        },
        Validators.required
      ],
      weightAmount: [null, [Validators.required]],
      chargeableWeightAmount: [null],
      chargeableVolumeAmount: [null],
      totalWeightAmount: [{ value: 0, disabled: true }, Validators.required],
      totalWeightUnit: [
        this.unitMeasurementType.value ===
          UnitMeasurementType.imperial ? WeightUnit.LB : WeightUnit.KG,
        Validators.required
      ],
      volumeUnit: [
        this.unitMeasurementType.value ===
          UnitMeasurementType.imperial ? VolumeUnit.CUI : VolumeUnit.CBM,
        Validators.required
      ],
      volumeAmount: [null, Validators.required],
      totalVolumeAmount: [{ value: 0, disabled: true }, Validators.required],
      length: [null, this.isShowPackageTotalVolumeAmount ? null : [Validators.required, Validators.min(1), this.createSizeValidator(PackageSizeType.Length)]],
      height: [null, this.isShowPackageTotalVolumeAmount ? null : [Validators.required, Validators.min(1), this.createSizeValidator(PackageSizeType.Height)]],
      width: [null, this.isShowPackageTotalVolumeAmount ? null : [Validators.required, Validators.min(1), this.createSizeValidator(PackageSizeType.Width)]],
      numberOfPackages: [1, [Validators.required, Validators.min(1)]],
      packageVolume: [null, Validators.required],
      packageWeight: [null, [Validators.required, Validators.min(1), this.createSizeValidator(PackageSizeType.Weight)]],
      dimensionValue: ['', this.isShowPackageTotalVolumeAmount ? null : Validators.required],
      totalDimensionValueAmount: [{ value: '', disabled: true }],
      loadUnit: [{ value: LoadUnit.dimensions, disabled: true }],
      isShowMaskedInput: [false],
      isSelectFromRange: [false]
    })
  }

  private setValuesOnUnitToggle(): void {
    let dimensionUnit = DimensionUnit.CM;
    let weightUnit = WeightUnit.KG;
    let volumeUnit = VolumeUnit.CBM;
    this.weightRange = weightRangeMetric;

    if (this.unitMeasurementType.value === UnitMeasurementType.imperial) {
      this.weightRange = weightRangeImperial;
      dimensionUnit = DimensionUnit.IN;
      weightUnit = WeightUnit.LB;
      volumeUnit = VolumeUnit.CUI
    }

    if (this.commoditiesFormArray.controls?.length) {
      this.commoditiesFormArray.controls.forEach((e: FormGroup) => {
        e.controls.packageWeight.setValue(e.value.packageWeight);
        e.controls.selectedValue.setValue(e.value.selectedValue);
        e.controls.height.setValue(e.value.height);
        e.controls.length.setValue(e.value.length);
        e.controls.width.setValue(e.value.width);
        e.controls.packageVolume.setValue(e.value.packageVolume);
        e.controls.dimensionUnit.setValue(dimensionUnit);
        e.controls.weightUnit.setValue(weightUnit);
        e.controls.volumeUnit.setValue(volumeUnit);

        this.calculateVolume(e, true);
      });
    }
    this.onAfterChange();
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;
    let totalChargeableWeight = 0;
    let totalChargeableVolume = 0;

    if (this.commoditiesFormArray.controls?.length) {
      this.commoditiesFormArray.controls.forEach((e: any) => {
        totalVolumeAmount += e.controls.totalVolumeAmount.value ? e.controls.totalVolumeAmount.value : 0;
        totalWeightAmount += e.controls.totalWeightAmount.value ? e.controls.totalWeightAmount.value : 0;
        if (e.controls.packageVolume.value > 0) {
          packagesCount += e.controls.numberOfPackages.value ? e.controls.numberOfPackages.value : 0;
          totalChargeableWeight += e.controls.chargeableWeightAmount.value;
          totalChargeableVolume += e.controls.chargeableVolumeAmount.value;
        }
        this.grandTotalVolumeUnit = e.controls.volumeUnit.value;
        this.grandTotalWeightUnit = e.controls.weightUnit.value;
      });
    }

    this.totalPackages = packagesCount;
    this.grandTotalVolumeAmount = totalVolumeAmount;
    this.grandTotalWeightAmount = totalWeightAmount;
    this.grandTotalChargeableWeight = totalChargeableWeight;
    this.grandTotalChargeableVolume = totalChargeableVolume;
  }

  private calculateTotalChargeableWeight(totalVolume: number): number {
    if (this.unitMeasurementType.value === UnitMeasurementType.metric) {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private calculateTotalChargeableVolume(totalWeight: number): number {
    if (this.unitMeasurementType.value === UnitMeasurementType.metric) {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalWeight / 1000;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalWeight / 1000 * 6;
      }
    } else {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalWeight * 1728 / 36;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalWeight * 166;
      }
    }
  }

  private createSizeValidator(packageSizeType: PackageSizeType): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.shipmentType != ShipmentType.LCL)
        return null;

      const value = control.value;
      if (!value) return null;

      return this.validateSizeLimitation(value, packageSizeType);
    }
  }

  private validateSizeLimitation(value: number, packageSizeType: PackageSizeType): ValidationErrors | null {
    let heightLimit = 2;
    switch (packageSizeType) {
      case PackageSizeType.Height:
        heightLimit = this.unitMeasurementType.value == UnitMeasurementType.metric ?
          environment.lclHeightPackageLimit : this.sizeService.convertCentimetersToInches(environment.lclHeightPackageLimit);
        break;
      case PackageSizeType.Width:
        heightLimit = this.unitMeasurementType.value == UnitMeasurementType.metric ?
          environment.lclWidthPackageLimit : this.sizeService.convertCentimetersToInches(environment.lclWidthPackageLimit);
        break;
      case PackageSizeType.Length:
        heightLimit = this.unitMeasurementType.value == UnitMeasurementType.metric ?
          environment.lclLengthPackageLimit : this.sizeService.convertCentimetersToInches(environment.lclLengthPackageLimit);
        break;
      case PackageSizeType.Weight:
        heightLimit =
          this.unitMeasurementType.value == UnitMeasurementType.metric ?
            environment.lclWeightPackageLimit : this.sizeService.convertKilogramsToPounds(environment.lclWeightPackageLimit);
        break;
      default:
        break;
    }

    if (value > 0 && value <= heightLimit)
      return null;

    if (packageSizeType != PackageSizeType.Weight) {
      this.composeSizeErrorString();
    }

    return { max: true };
  }

  private resetContainerCommodity(): void {
    if (this.commoditiesFormArray.controls?.length === 0) {
      this.commoditiesFormArray?.push(this.createCommoditiesForm());
    }
    else {
      this.commoditiesFormArray.clear();
      this.commoditiesFormArray?.push(this.createCommoditiesForm());
      this.unitMeasurementType.setValue(UnitMeasurementType.metric);
      this.setValuesOnUnitToggle();
    }
    this.equipmentsFormArray.clear();
  }

  private calculateVolume(commodityForm: FormGroup, isDimensionUnitTypeChanged: boolean = false): void {
    const length = commodityForm.controls.length.value as number;
    const width = commodityForm.controls.width.value as number;
    const height = commodityForm.controls.height.value as number;
    const numberOfPackages = commodityForm.controls.numberOfPackages.value as number;
    const dimensionUnit = commodityForm.controls.dimensionUnit.value as DimensionUnit;
    const weight = commodityForm.controls.packageWeight.value as number;

    let volume = this.isShowPackageTotalVolumeAmount ? (commodityForm.controls.packageVolume.value as number) : (length * height * width);
    if (this.isShowPackageTotalVolumeAmount) {
      if (isDimensionUnitTypeChanged) {
        volume = (dimensionUnit !== DimensionUnit.CM) ? (volume * 1000000) : (volume / 1000000);
      }
    }
    else {
      volume = (dimensionUnit === DimensionUnit.CM) ? (volume / 1000000) : volume;
    }

    commodityForm.controls.weightAmount.setValue(weight > 0 ? weight * numberOfPackages : null);
    commodityForm.controls.totalDimensionValueAmount.reset()
    commodityForm.controls.packageVolume.setValue(volume);
    commodityForm.controls.volumeAmount.setValue(volume > 0 ? volume * numberOfPackages : null);
    commodityForm.controls.totalDimensionValueAmount.setValue(volume);
    commodityForm.controls.totalDimensionValueAmount.enable();

    commodityForm.controls.totalVolumeAmount.setValue(volume > 0 ? volume * numberOfPackages : null);
    commodityForm.controls.totalWeightAmount.setValue(weight > 0 ? weight * numberOfPackages : null);

    const chargeableWeightBasedOnVolumeAmount = this.calculateTotalChargeableWeight(commodityForm.controls.totalVolumeAmount.value);

    const chargeableWeightAmount = commodityForm.controls.totalWeightAmount.value > chargeableWeightBasedOnVolumeAmount ?
      commodityForm.controls.totalWeightAmount.value :
      chargeableWeightBasedOnVolumeAmount;

    commodityForm.controls.chargeableWeightAmount
      .setValue(chargeableWeightAmount);

    commodityForm.controls.chargeableVolumeAmount
      .setValue(this.calculateTotalChargeableVolume(chargeableWeightAmount));

    this.totalLoadCalculation();
  }

  private quotesSearchStepContainers(): boolean {
    let valid = true;
    if (this.shipmentType === ShipmentType.LCL || this.shipmentType === ShipmentType.AIR) {
      if (!this.requiredIf(this.commoditiesFormArray)) { valid = false; }
    }
    if (this.shipmentType === ShipmentType.FCL) {
      if (!this.requiredIf(this.equipmentsFormArray)) { valid = false; }
    }

    return valid;
  }

  private requiredIf(control: FormArray): boolean {
    const isValid = control.valid;

    control.controls.forEach((element) => {
      if (element instanceof FormGroup) {
        Object.keys(element.controls).forEach((key) => {
          const childControl = element.get(key);
          if (childControl instanceof FormControl) {
            if (!childControl.valid) {
              childControl.markAsTouched();
            }
          }
          else if (childControl instanceof FormArray) {
            (childControl as FormArray).controls.forEach((subElement) => {
              if (subElement instanceof FormGroup) {
                Object.keys(subElement.controls).forEach((subKey) => {
                  const subChildControl = subElement.get(subKey);
                  if (!subChildControl.valid) {
                    subChildControl.markAsTouched();
                  }
                });
              }
            });
          }
        });
      }
    });

    return isValid;
  }

  private setOverlayVisibility(): void {
    this.opened.emit(this.isOpened)
    document.getElementById("search-flow-overlay").style.visibility = this.isOpened ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = this.isOpened ? '1' : '0';
  }

  private isTotalLoadCalculationValid(): boolean {
    if (this.shipmentType !== ShipmentType.LCL) {
      return true;
    }

    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;

    if (this.commoditiesFormArray.controls?.length) {
      this.commoditiesFormArray.controls.forEach((e: any) => {
        totalVolumeAmount += e.controls.totalVolumeAmount.value ? e.controls.totalVolumeAmount.value : 0;
        totalWeightAmount += e.controls.totalWeightAmount.value ? e.controls.totalWeightAmount.value : 0;
      });
    }

    const maxWeight = this.getMaxWeight();
    const maxCapacity = this.getMaxCapacity();

    if (maxWeight < totalWeightAmount || maxCapacity < totalVolumeAmount) {
      return false;
    }
    return true;
  }

  private getMaxWeight(): number {
    return this.unitMeasurementType.value === UnitMeasurementType.metric ?
      environment.lclMaxWeight : Math.round(environment.lclMaxWeight * 2.20462);
  }

  private getMaxCapacity(): number {
    return this.unitMeasurementType.value === UnitMeasurementType.metric ?
      environment.lclMaxCapacity : Math.round(environment.lclMaxCapacity * 61023.7441);
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok"
      },
    });
  }

  private autoConfirm(state: boolean = true): void {
    if (state) {
      document.getElementsByTagName('app-quote-search-flow')[0].addEventListener('click', this.clickHandler);
    }
    else {
      document.getElementsByTagName('app-quote-search-flow')[0].removeEventListener('click', this.clickHandler);
    }
  }

  private subscribeActiveMenuOption(): void {
    this.quotesService.activeMenuOption.subscribe((option: QuoteSearchParameters | null) => {
      if (option !== null) {
        if (this.quoteSearchParameter === QuoteSearchParameters.cargo && option !== QuoteSearchParameters.cargo) {
          this.isOpened = false;
        }
      }
    })
  }

  private deselectIfCommodityControlsNotValid(): void {
    if (this.shipmentType === ShipmentType.FCL)
      return;

    let isValid = true;
    for (let c of this.commoditiesFormArray.controls) {
      if (!c.valid) isValid = false;
    }

    if (!isValid) {
      this.isSelected = false;
      this.setSelected.next(this.isSelected);
    }
  }

  private findParentByClass(element: any, parentClass: any): boolean {
    let parent = element;
    let isExists = false;
    while ((parent && parent.tagName.toLowerCase() != "html" && parentClass.length > 0)) {
      isExists = this.checkClassNameExists(parent.className, parentClass)
      if (isExists) {
        parent = null;
      }
      else {
        parent = parent.parentNode;
      }
    }

    return isExists;
  }

  private checkClassNameExists(classList: string, parentClass: string[]): boolean {
    let isExists = false;
    parentClass.forEach(className => {
      if (classList?.includes(className)) {
        isExists = true;
      }
    });
    return isExists;
  }

  private getParentContainerClass(event: any): boolean {
    return this.findParentByClass(event.target, ['quote-search-location-details-wrapper', 'cdk-overlay-pane'])
  }

  private preparePackageTotalVolumeAmountData(commodityForm: FormGroup): void {
    if (this.isShowPackageTotalVolumeAmount && this.shipmentType != ShipmentType.FCL) {
      const numberOfPackages = commodityForm.controls.numberOfPackages.value as number;
      const volumeAmount = commodityForm.controls.volumeAmount.value as DimensionUnit;
      const weightAmount = commodityForm.controls.weightAmount.value as number;

      commodityForm.controls.packageVolume.setValue(volumeAmount / numberOfPackages);
      commodityForm.controls.packageWeight.setValue(weightAmount / numberOfPackages);
    }
  }

  private actionsOnClosing(element: any): void {
    if (this.shipmentType !== ShipmentType.FCL) {
      const invalidLoads = this.commoditiesFormArray?.controls.filter(x => !x.valid);

      invalidLoads.forEach((load: FormGroup) => {
        if (!load.valid) {
          this.onRemoveLoad(load)
        }
      });
    }
  }

  private checkDangerousGoods(): void {
    switch (this.selectedDangerousGoodsType) {
      case DangerousGoodsType.BATTERIES: {
        if (this.from === undefined || this.to === undefined || this.from === '' || this.to === '') {
          this.dangerousGoodsWarning = this.dangerousGoodsWarnings[1];
          return;
        }

        this.isCheckingBatteriesSupportInProgress = true;

        this.tripUnLocodeAutocompleteService.checkDangerousGoodsSupport(this.shipmentType, this.from.value, this.to.value).subscribe(
          (data) => {
            if (data && data.isSupportBatteries) {
              this.dangerousGoodsWarning = null;
              this.isBatteries.setValue(true);
            }
            else {
              this.dangerousGoodsWarning = this.dangerousGoodsWarnings[3];
            }
          },
          (error) => {
            this.dangerousGoodsWarning = this.dangerousGoodsWarnings[3];
          },
          () => {
            this.isCheckingBatteriesSupportInProgress = false;
          }
        )

        this.dangerousGoodsWarning = null;
        break;
      }
      case DangerousGoodsType.OTHER: {
        this.dangerousGoodsWarning = this.dangerousGoodsWarnings[2];
        break;
      }
      default: {
        this.dangerousGoodsWarning = null;
        break;
      }
    }
  }

  private sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
