import { Component, OnInit } from '@angular/core';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html'
})
export class WhatsAppButtonComponent implements OnInit {
  whatsAppPhonenumber: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.whatsAppPhonenumber = this.getWhatsAppPhoneNumber();
  }

  getWhatsAppPhoneNumber(): string {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.whatsAppPhoneNumber;
      case 'sb':
        return environment.sb.whatsAppPhoneNumber;
      default:
        return environment.whatsAppPhoneNumber;
    }
  }
}
