import { Pipe, PipeTransform } from '@angular/core';
import { AddressTypeCode } from '../shared.model';

@Pipe({
  name: 'addressTypeS4dIcon'
})
export class AddressTypeS4dIconPipe implements PipeTransform {
  private addressTypeIcons = {};

  constructor() {
    this.addressTypeIcons[AddressTypeCode.port] = 's4d-cargo-crane';
    this.addressTypeIcons[AddressTypeCode.airport] = 's4d-ac';
    this.addressTypeIcons[AddressTypeCode.warehouse] = 's4d-boxes';
    this.addressTypeIcons[AddressTypeCode.door] = 's4d-door';
  }

  transform(value: AddressTypeCode): string {
    return this.addressTypeIcons[value] ?? '';
  }
}