import { Pipe, PipeTransform } from '@angular/core';
import { ExtenderTripRate } from '../../shared.model';

@Pipe({ name: 'totalFreightRate' })
export class TotalFreightRatePipe implements PipeTransform {

  transform(value?: ExtenderTripRate[]): number {
    return value?.reduce((accumulator, current) => {
      return accumulator + current.freightRate.shipmentRate.totalAmount;
    }, 0);
  }
}