import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuotesService } from '../../../quotes.service';
import { QuoteSearchParameters, ShipmentType } from '../../../../../shared/shared.model';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-flow-shipment-type',
  templateUrl: './flow-shipment-type.component.html',
  styleUrls: ['./flow-shipment-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowShipmentTypeComponent implements OnInit, OnChanges {
  @Input() shippingTypes: any[] = [];
  @Input() shipmentTypeControl: FormControl;
  @Input() isOpened: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() quoteSearchParameter: QuoteSearchParameters | undefined;

  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() next: EventEmitter<QuoteSearchParameters> = new EventEmitter();

  get currentShippingType() {
    return this.shippingTypes.find(x => x.value == this.shipmentTypeControl.value)
  };

  constructor(
    private quotesService: QuotesService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscribeActiveMenuOption()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpened?.currentValue === true) setTimeout(() => this.onOpenClick(), 100);
  }

  onOpenClick(): void {
    this.isOpened = !this.isOpened;
    this.setOverlayVisibility();
    this.quotesService.setActiveMenuOption(this.quoteSearchParameter);
    this.googleAnalyticsService.shipmentTypeStart(this.shipmentTypeControl.value);
  }

  onSelect(shipmentType: ShipmentType): void {
    this.shipmentTypeControl.setValue(shipmentType);

    this.isSelected = true;
    this.isOpened = false;

    this.setOverlayVisibility();

    this.setSelected.next(this.isSelected);
    this.next.emit(QuoteSearchParameters.shipmentType);
    this.googleAnalyticsService.shipmentTypeComplete(this.shipmentTypeControl.value)
  }

  private subscribeActiveMenuOption(): void {
    this.quotesService.activeMenuOption.subscribe((option: QuoteSearchParameters | null) => {
      if (option !== null) {
        if (this.quoteSearchParameter === QuoteSearchParameters.shipmentType && option !== QuoteSearchParameters.shipmentType) {
          this.isOpened = false;
        }
      }
    })
  }

  private setOverlayVisibility(): void {
    document.getElementById("search-flow-overlay").style.visibility = this.isOpened ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = this.isOpened ? '1' : '0';
  }
}

