<div class="booking-flow-contacts">
  <div class="row m-0 pt-1">
    <div class="col-12 px-0">
      <span class="panel-subheader">Enter the contact details for shipping pickup and delivery locations.</span>
    </div>
  </div>
  <div class="row m-0 pt-4">
    <div class="booking-flow-contacts-wrapper col px-0">
      <div class="booking-flow-contacts-field row m-0 mb-4">
        <div class="col-12 px-0 pb-3">
          <div class="row m-0">
            <div class="col ps-0">
              <span class="field-title">Contact at Pickup</span>
            </div>
            <div class="col-auto pe-0">
              <div class="field-icon-wrapper">
                <i class="s4d-icon s4d-info-light field-info-icon" matTooltip="Contact at Pickup (Exporter/Supplier)"
                  matTooltipPosition="left"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0">
          <div class="booking-flow-contacts-field-box row m-0">
            <div class="booking-flow-contacts-field-box-content col px-0">
              <mat-form-field floatLabel="never">
                <mat-label *ngIf="!selectedOrganizationShipperContact">Contact at Pickup (Exporter/Supplier)</mat-label>
                <mat-select #shipperSelect disableOptionCentering panelClass="contact-panel-popup" class="contact-type"
                  [value]="selectedOrganizationShipperContact" (selectionChange)="
                  onChangeBookingContact($event, contactTypes.shipper)">
                  <mat-optgroup>Recent Suitable Contacts</mat-optgroup>
                  <mat-optgroup class="contact-panel-add" (click)="onOpenNewContact(contactTypes.shipper)">
                    <span class="s4d-icon s4d-plus"></span>
                    New Contact
                  </mat-optgroup>
                  <mat-optgroup class="contact-panel-info" (click)="onUnselectBookingContact(contactTypes.shipper)">
                    <span class="s4d-icon s4d-person"></span>
                    Select Contact at Pickup (Exporter/Supplier)
                  </mat-optgroup>
                  <mat-select-trigger class="d-flex justify-content-between h-100">
                    <div class="row m-0 location-autocomplete-option">
                      <div class="col-auto flag-icon">
                        <i class="contact-country-flag-icon fi fi-{{
                      selectedOrganizationShipperContact?.countryCode | lowercase }}"></i>
                      </div>
                      <div class="col location-wrapper">
                        <div class="row m-0">
                          <div class="col-12 px-0 contact-pickup-title-wrapper">
                            <span class="location-placeholder">
                              Contact at Pickup (Exporter/Supplier)
                            </span>
                          </div>
                          <div class="col-12 px-0">
                            <div class="location-name-address-wrapper"
                              matTooltip="{{selectedOrganizationShipperContact?.firstName}} {{selectedOrganizationShipperContact?.lastName}}
                                {{selectedOrganizationShipperContact?.address1 ? ', ' + selectedOrganizationShipperContact?.address1 : ''}}">
                              <span class="shipping-firstname-lastname">
                                {{ selectedOrganizationShipperContact?.firstName }}
                                {{ selectedOrganizationShipperContact?.lastName }}</span>
                              <span class="shipping-address">
                                {{ selectedOrganizationShipperContact?.address1 ? ', ' + selectedOrganizationShipperContact?.address1 : '' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row location-autocomplete-option">
                      <mat-spinner diameter="25" *ngIf="isShipperContactLoading"></mat-spinner>
                    </div>
                  </mat-select-trigger>
                  <mat-option *ngFor="let contact of filteredShipperContacts" [value]="contact">
                    <ng-container [ngTemplateOutlet]="others" [ngTemplateOutletContext]="{ contact: contact }">
                    </ng-container>
                  </mat-option>
                  <mat-option hidden>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-auto ps-2 pe-0">
              <button mat-icon-button matTooltip="Select, Edit or Add Contact" class="booking-add-contact-button"
                (click)="filteredShipperContacts.length > 0 ?
                viewOrganizationContacts(contactTypes.shipper) :
                onOpenNewContact(contactTypes.shipper, true)">
                <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="booking-flow-contacts-field row m-0 mb-4">
        <div class="col-12 px-0 pb-3">
          <div class="row m-0">
            <div class="col ps-0">
              <span class="field-title">Contact at Delivery</span>
            </div>
            <div class="col-auto pe-0">
              <div class="field-icon-wrapper">
                <i class="s4d-icon s4d-info-light field-info-icon" matTooltip="Contact at Delivery (Importer)"
                  matTooltipPosition="left"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0">
          <div class="booking-flow-contacts-field-box row m-0">
            <div class="booking-flow-contacts-field-box-content col px-0">
              <mat-form-field floatLabel="never">
                <mat-label *ngIf="!selectedOrganizationConsigneeContact">Contact at Delivery (Importer)</mat-label>
                <mat-select #consigneeSelect disableOptionCentering panelClass="contact-panel-popup" class="contact-type"
                  [value]="selectedOrganizationConsigneeContact" (selectionChange)="
                  onChangeBookingContact($event, contactTypes.consignee)">
                  <mat-optgroup>Recent Suitable Contacts</mat-optgroup>
                  <mat-optgroup class="contact-panel-add" (click)="onOpenNewContact(contactTypes.consignee)">
                    <span class="s4d-icon s4d-plus"></span>
                    New Contact
                  </mat-optgroup>
                  <mat-optgroup class="contact-panel-info" (click)="onUnselectBookingContact(contactTypes.consignee)">
                    <span class="s4d-icon s4d-person"></span>
                    Select Contact at Delivery (Importer)
                  </mat-optgroup>
                  <mat-select-trigger class="d-flex justify-content-between h-100">
                    <div class="row m-0 location-autocomplete-option">
                      <div class="col-auto flag-icon">
                        <i class="contact-country-flag-icon fi fi-{{
                      selectedOrganizationConsigneeContact?.countryCode | lowercase }}"></i>
                      </div>
                      <div class="col location-wrapper">
                        <div class="row m-0">
                          <div class="col-12 px-0 contact-pickup-title-wrapper">
                            <span class="location-placeholder">
                              Contact at Delivery (Importer)
                            </span>
                          </div>
                          <div class="col-12 px-0">
                            <div class="location-name-address-wrapper"
                              matTooltip="{{ selectedOrganizationConsigneeContact?.firstName }} {{ selectedOrganizationConsigneeContact?.lastName }}
                              {{ selectedOrganizationConsigneeContact?.address1 ? ', ' + selectedOrganizationConsigneeContact?.address1 : '' }}">
                              <span class="shipping-firstname-lastname">
                                {{ selectedOrganizationConsigneeContact?.firstName }}
                                {{ selectedOrganizationConsigneeContact?.lastName }}</span>
                              <span class="shipping-address">
                                {{ selectedOrganizationConsigneeContact?.address1 ? ', ' + selectedOrganizationConsigneeContact?.address1 : '' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row location-autocomplete-option">
                      <mat-spinner diameter="25" *ngIf="isConsigneeContactLoading"></mat-spinner>
                    </div>
                  </mat-select-trigger>
                  <mat-option *ngFor="let contact of filteredConsigneeContacts" [value]="contact">
                    <ng-container [ngTemplateOutlet]="others" [ngTemplateOutletContext]="{ contact: contact }">
                    </ng-container>
                  </mat-option>
                  <mat-option hidden>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-auto ps-2 pe-0">
              <button mat-icon-button matTooltip="Select, Edit or Add Contact" class="booking-add-contact-button"
                (click)="filteredConsigneeContacts.length > 0 ?
                viewOrganizationContacts(contactTypes.consignee) :
                onOpenNewContact(contactTypes.consignee, true)">
                <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShowNotifyContact" class="booking-flow-contacts-field row m-0 mb-4">
        <div class="col-12 px-0 pb-3">
          <div class="row m-0">
            <div class="col ps-0">
              <span class="field-title">Contact for Notify</span>
            </div>
            <div class="col-auto pe-0">
              <div class="field-icon-wrapper">
                <i class="s4d-icon s4d-info-light field-info-icon"
                  matTooltip="You can specify additional party to be notified about the shipment"
                  matTooltipPosition="left"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0">
          <div class="booking-flow-contacts-field-box row m-0">
            <div class="booking-flow-contacts-field-box-content col px-0">
              <mat-form-field floatLabel="never">
                <mat-label *ngIf="!selectedOrganizationNotifyPartyContact">Contact for Notify</mat-label>
                <mat-select #notifySelect disableOptionCentering panelClass="contact-panel-popup" class="contact-type"
                  [value]="selectedOrganizationNotifyPartyContact" (selectionChange)="
                      onChangeBookingContact($event, contactTypes.notifyParty)">
                  <mat-optgroup>Recent Suitable Contacts</mat-optgroup>
                  <mat-optgroup class="contact-panel-add" (click)="onOpenNewContact(contactTypes.notifyParty)">
                    <span class="s4d-icon s4d-plus"></span>
                    New Contact
                  </mat-optgroup>
                  <mat-optgroup class="contact-panel-info" (click)="onUnselectBookingContact(contactTypes.notifyParty)">
                    <span class="s4d-icon s4d-person"></span>
                    Select Contact for Notify
                  </mat-optgroup>
                  <mat-select-trigger class="d-flex justify-content-between h-100">
                    <div class="row m-0 location-autocomplete-option">
                      <div class="col-auto flag-icon">
                        <i class="contact-country-flag-icon fi fi-{{
                          selectedOrganizationNotifyPartyContact?.countryCode | lowercase }}"></i>
                      </div>
                      <div class="col location-wrapper">
                        <div class="row m-0">
                          <div class="col-12 px-0 contact-pickup-title-wrapper">
                            <span class="location-placeholder">
                              Contact for Notify
                            </span>
                          </div>
                          <div class="col-12 px-0">
                            <div class="location-name-address-wrapper"
                              matTooltip="{{ selectedOrganizationNotifyPartyContact?.firstName }} {{ selectedOrganizationNotifyPartyContact?.lastName }}
                              {{ selectedOrganizationNotifyPartyContact?.address1 ? ', ' + selectedOrganizationNotifyPartyContact?.address1 : '' }}">
                              <span class="shipping-firstname-lastname">
                                {{ selectedOrganizationNotifyPartyContact?.firstName }}
                                {{ selectedOrganizationNotifyPartyContact?.lastName }}</span>
                              <span class="shipping-address">
                                {{ selectedOrganizationNotifyPartyContact?.address1 ? ', ' + selectedOrganizationNotifyPartyContact?.address1 : ''}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row location-autocomplete-option">
                      <mat-spinner diameter="25" *ngIf="isNotifyPartyContactLoading"></mat-spinner>
                    </div>
                  </mat-select-trigger>
                  <mat-option *ngFor="let contact of orgContacts" [value]="contact">
                    <ng-container [ngTemplateOutlet]="others" [ngTemplateOutletContext]="{ contact: contact }">
                    </ng-container>
                  </mat-option>
                  <mat-option hidden>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-auto ps-2 pe-0">
              <button mat-icon-button matTooltip="Select, Edit or Add Contact" class="booking-add-contact-button"
                (click)="orgContacts.length > 0 ?
                viewOrganizationContacts(contactTypes.notifyParty) :
                onOpenNewContact(contactTypes.notifyParty, true)">
                <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isShowNotifyContact" class="row m-0">
        <div class="col-auto px-0">
          <button class="show-notify-button" (click)="onShowNotifyContact()">
            <div class="plus-icon-wrapper">
              <i class="s4d-icon s4d-plus"></i>
            </div>
            Change Notified Contact
          </button>
        </div>
      </div>
      <ng-template #others let-contact="contact">
        <div class="row m-0 location-autocomplete-option">
          <div class="col-auto flag-icon">
            <i class="contact-country-flag-icon fi fi-{{ contact.countryCode | lowercase }}"></i>
          </div>
          <div class="col-11 location-wrapper">
            <div class="location-name-address-wrapper"
              matTooltip="{{ contact.firstName }} {{ contact.lastName }}{{ contact?.address1 ? ', ' + contact?.address1 : '' }}">
              <span class="shipping-firstname-lastname">
                {{ contact.firstName }} {{ contact.lastName }}</span>
              <span class="shipping-address">{{ contact?.address1 ? ', ' + contact?.address1 : '' }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row justify-content-end" [ngClass]="{'mt-4' : !isShowNotifyContact}">
    <div class="col-auto">
      <button mat-raised-button class="booking-next-button button-spinner" (click)="onNext()"
        [disabled]="isNextLoading || !isValid">
        <span class="button-inner">
          Next
          <mat-spinner diameter="25" *ngIf="isNextLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>
