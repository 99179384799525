import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ShipmentType, UnitMeasurementType } from '../../../../shared/shared.model';
import { environment } from '../../../../../environments/environment';
import { QuotesService } from '../../../../desktop/quotes/quotes.service';

@Injectable({ providedIn: 'root' })
export class QuoteSearchFlowParamsService {
    private unitMeasurement: UnitMeasurementType = UnitMeasurementType.metric;

    constructor(
        private quotesService: QuotesService) { }

    validateCargoDetails(shipmentType: ShipmentType, commoditiesFormArray: FormArray): boolean {
        if (shipmentType !== ShipmentType.LCL) {
            return true;
        }

        this.unitMeasurement = this.getUnitMeasurement();

        let totalVolumeAmount = 0;
        let totalWeightAmount = 0;

        if (commoditiesFormArray.controls?.length) {
            commoditiesFormArray.controls.forEach((e: any) => {
                totalVolumeAmount += e.controls.totalVolumeAmount.value ? e.controls.totalVolumeAmount.value : 0;
                totalWeightAmount += e.controls.totalWeightAmount.value ? e.controls.totalWeightAmount.value : 0;
            });
        }

        const maxWeight = this.getMaxWeight();
        const maxCapacity = this.getMaxCapacity();

        if (maxWeight < totalWeightAmount || maxCapacity < totalVolumeAmount) {
            return false;
        }
        return true;
    }

    private getMaxWeight(): number {
        return this.unitMeasurement === UnitMeasurementType.metric ?
            environment.lclMaxWeight : Math.round(environment.lclMaxWeight * 2.20462);
    }

    private getMaxCapacity(): number {
        return this.unitMeasurement === UnitMeasurementType.metric ?
            environment.lclMaxCapacity : Math.round(environment.lclMaxCapacity * 61023.7441);
    }

    private getUnitMeasurement(): UnitMeasurementType {
        var unitMeasurementValue = this.quotesService.getUnitMeasurementFromStorage();
        if (unitMeasurementValue != undefined && unitMeasurementValue != null) {
            return unitMeasurementValue;
        } else {
            return UnitMeasurementType.metric;
        }
    }
}