import { Injectable, EventEmitter } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http/http.service';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { UserInfo } from '../../features/user-info/user-info.model';
import { AccessRoleType, DepartureDateSettings, SettingsEntry } from '../../shared.model';

@Injectable()
export class UserInfoService {
  private readonly storageKey = 'userInfo';
  private readonly defaultAppSettingsExpirationDays = 7;

  settingsChanged = new EventEmitter<UserInfo>();
  departureDateSettings: DepartureDateSettings = {} as DepartureDateSettings;

  constructor(
    private httpService: HttpService,
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  initSettings(): Observable<any> {
    var oldSettings = this.getSettingsFromStorage();
    if (oldSettings) {
      this.settingsChanged.emit(oldSettings);
      return of(oldSettings);
    }

    return this.setDefaultSettings();
  }

  getSettings(): UserInfo {
    const oldSettings = this.getSettingsFromStorage();
    return oldSettings || UserInfo.createDefault();
  }

  addSettings(settings: UserInfo) {
    if (settings) {
      localStorage.setItem(this.storageKey, JSON.stringify(settings));
    }
  }

  isRolesSatisfied(roles: AccessRoleType[]) {
    if (roles && roles.length > 0) {
      if (this.getSettings().accessRoles !== null) {
        return this.getSettings().accessRoles.some(x => roles.some(y => y === x));
      }
      return false;
    }
    return false;
  }

  removeSettings() {
    const oldSettings = this.getSettingsFromStorage();
    if (oldSettings) {
      localStorage.removeItem(this.storageKey);
    }

    localStorage.removeItem("chatUserInfo");
  }

  checkIsAgent(): boolean {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      return userInfo.accessRoles.includes(AccessRoleType.agent) && !userInfo.accessRoles.includes(AccessRoleType.owner)
    }
  }

  getUserId(): string {
    let settings = this.getSettings();
    return settings?.userId;
  }

  setUserId(userId: string): void {
    let settings = this.getSettings();
    settings.userId = userId;
    this.addSettings(settings);
  }

  setDepartureDateFlexibilitySettings(): void {
    this.getDepartureDateSettings()
      .subscribe((settings: SettingsEntry[]) => {
        if (settings.length > 0) {
          settings.forEach(x => {
            this.setSettingsInLocalStorage(x);
          });

          let userSettings = this.getSettings();
          userSettings.departureDateSettings = this.departureDateSettings;
          this.addSettings(userSettings);
        }
      });
  }

  private refreshSettings(endpoint): Observable<any> {
    return this.httpService
      .get<UserInfo>(endpoint, null, null, { host: window.location.host })
      .pipe(
        catchError(() => of(UserInfo.createDefault())),
        map(x => UserInfo.complete(x as UserInfo)),
        tap(x => {
          this.saveSettingsToStorage(x);
          this.settingsChanged.emit(x);
        })
      );
  }

  private getSettingsFromStorage(): UserInfo {
    const oldSettingsString = localStorage.getItem(this.storageKey);
    if (!oldSettingsString) {
      return null;
    }

    const oldSettings = JSON.parse(oldSettingsString) as UserInfo;
    if (!oldSettings) {
      return null;
    }

    return oldSettings;
  }

  private saveSettingsToStorage(settings: UserInfo) {
    if (!settings) {
      return;
    }

    localStorage.setItem(this.storageKey, JSON.stringify(settings));
  }

  private setDefaultSettings(): Observable<UserInfo> {
    let userInfoSettings = UserInfo.createDefault();
    userInfoSettings = UserInfo.complete(userInfoSettings);
    this.saveSettingsToStorage(userInfoSettings);
    this.settingsChanged.emit(userInfoSettings);
    return of(userInfoSettings);
  }

  private getDepartureDateSettings(): Observable<any> {
    return this.http.get<any>(`${this.internalUrlsService.getApiBaseUrl()}/ApplicationSettings/departureDateSettings`);
  }

  private setSettingsInLocalStorage(setting: SettingsEntry): void {
    const settingsMap: { [key: string]: (value: any) => void } = {
      "FclMinAllowedDepartureDate": (value) => this.departureDateSettings.fclMinAllowedDepartureDate = value,
      "LclMinAllowedDepartureDate": (value) => this.departureDateSettings.lclMinAllowedDepartureDate = value,
      "AirMinAllowedDepartureDate": (value) => this.departureDateSettings.airMinAllowedDepartureDate = value,
      "FclMaxAllowedDepartureDate": (value) => this.departureDateSettings.fclMaxAllowedDepartureDate = value,
      "LclMaxAllowedDepartureDate": (value) => this.departureDateSettings.lclMaxAllowedDepartureDate = value,
      "AirMaxAllowedDepartureDate": (value) => this.departureDateSettings.airMaxAllowedDepartureDate = value,
      "FclBasicPriceTierAllowedDays": (value) => this.departureDateSettings.fclBasicPriceTierAllowedDays = value,
      "LclBasicPriceTierAllowedDays": (value) => this.departureDateSettings.lclBasicPriceTierAllowedDays = value,
      "AirBasicPriceTierAllowedDays": (value) => this.departureDateSettings.airBasicPriceTierAllowedDays = value,
    };

    const update = settingsMap[setting.id];
    if (update) {
      update(setting.value);
    }
  }
}
