import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RequestAndPaymentService } from '../../../shared/requests-and-payments/requests-and-payments.service';
import { RequestAndPayment } from '../../../shared/requests-and-payments/requests-and-payments.model';
import { Booking, BookingSummary } from '../../../shared/bookings/bookings.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { TeamMember } from '../../../shared/organizations/organization.model';
import { UpdatePayment } from '../../../shared/payments/payments.model';
import { BookingFilesDocumentsPage, BookingViewDetailsPage, CurrencyCode, PaymentStatusCode, PaymentStatusTransition } from '../../../shared/shared.model';
import { OrganizationsService } from '../../../shared/organizations/organizations.service';
import { FourtySeasPaymentService } from '../../../shared/payments/fourty-seas-payment.service';
import { PaymentsService } from '../../../shared/payments/payments.service';
import { PaymentBankDetailsComponent } from '../../../shared/payments/payment-bank-details/payment-bank-details.component';
import { LayoutService } from '../../layout/layout.service';
import { LayoutMode } from '../../layout/layout.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  isLoading = false;
  isCredit = false;

  requestAndPayment: RequestAndPayment;
  bookingSummary: BookingSummary;
  teamMember: TeamMember;
  requestId: string;
  bookingId: string;

  currencies = CurrencyCode;

  @ViewChild('moreMenu') moreMenu: TemplateRef<ElementRef>;

  constructor(
    public dialog: MatDialog,
    private layoutService: LayoutService,
    public route: ActivatedRoute,
    public router: Router,
    private bookingsService: BookingsService,
    private organizationsService: OrganizationsService,
    private requestAndPaymentService: RequestAndPaymentService,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService) {
    this.bookingId = this.route.snapshot.params.bookingId;
    this.requestId = route.snapshot.params.id;
    this.isCredit = route.snapshot.params.credit ?? false;
  }

  ngOnInit(): void {
    this.getRequestsAndPaymentsById();
  }

  ngAfterViewInit(): void {
    const url = this.isCredit ? "/creditLine" : "/payments/" + this.bookingId;

    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.PAYMENT);
      this.layoutService.setToolbarShowUser(false);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(true);
      this.layoutService.setToolbarTitle("Payment");
      this.layoutService.setToolbarDescription("");
      this.layoutService.setMoreMenuTemplate(this.moreMenu);
      this.layoutService.setToolbarBackUrl(url);
    }, 0);
  }

  on40seasPayment(requestAndPayment: RequestAndPayment): void {
    this.isLoading = true;
    var transition = requestAndPayment.statusCode === PaymentStatusCode.failed ? PaymentStatusTransition.paymentRetried : PaymentStatusTransition.checkoutInitialized;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.bookingSummary.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: requestAndPayment.paymentId,
            transition: transition,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(() => {
          return this.fourtySeasPaymentService.init(requestAndPayment.id, this.teamMember.id, this.bookingSummary as Booking,
            transition, "dashboard");
        }))
      .subscribe(() => {
        this.isLoading = false;
      }).add(() => (this.isLoading = false));
  }

  onConfirm(paymentId: string): void {
    this.router.navigate(['/confirmPayment', this.bookingId, paymentId]);
  }

  onFilesDocuments(bookingId: string): void {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.finance, BookingFilesDocumentsPage.finance, bookingId]);
  }

  onChangePaymentMethod(requestAndPayment: RequestAndPayment): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.bookingSummary.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: requestAndPayment.paymentId,
            transition: PaymentStatusTransition.paymentMethodChanged,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(async () => this.fourtySeasPaymentService.init(requestAndPayment.id, this.teamMember.id, this.bookingSummary as Booking
          , PaymentStatusTransition.paymentMethodChanged, 'finance').subscribe()
        ))
      .subscribe(() => { })
  }

  onShowDetailsMethod(requestAndPayment: RequestAndPayment): void {
    this.dialog.open(PaymentBankDetailsComponent, {
      autoFocus: false,
      data: {
        refId: requestAndPayment.paymentNumber,
      },
    });
  }

  showPayOrder(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toBePaid || statusCode === PaymentStatusCode.failed;
  }

  showConfirmButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  showChangePaymentMethodButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  showBankDetailsButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  getPaymentStatusColor(statusCode: PaymentStatusCode): any {
    switch (statusCode) {
      case PaymentStatusCode.toBePaid: return null;
      case PaymentStatusCode.inProgress: null;
      case PaymentStatusCode.failed: return 'failed';
      case PaymentStatusCode.paid: return 'paid';
      case PaymentStatusCode.draft: return null;
      case PaymentStatusCode.toPayOffline: return null;
      case PaymentStatusCode.toBeApproved: return null;
      default: return false;

    }
  }

  roundAmount(amount: number): number {
    return Math.round((amount + Number.EPSILON) * 100) / 100;
  }

  private getRequestsAndPaymentsById(): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getSummaryById(this.bookingId)),
        tap((x: BookingSummary) => {
          this.bookingSummary = x;
        }),
        switchMap(() => this.requestAndPaymentService.getById(this.bookingId, this.requestId)))
      .subscribe((requestAndPayment) => {
        this.requestAndPayment = requestAndPayment;
      },
        (error) => { })
      .add(() => (this.isLoading = false));
  }
}
