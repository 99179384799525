<div class="popup-content-wrapper">
  <h2 class="popup-title">Pricing Options</h2>
  <p class="popup-descripion">Compare & Choose The best. <a href="{{ learnMoreUrl }}" target="_blank">Learn More</a></p>
  <div class="table-main-content-wrapper">
    <div class="service-detailes-wrapper">
      <div class="servise-title-wrapper">
        <h3 class="servise-title">
          Service
        </h3>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-support"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Expert Live Assistance 24/7</span>
        </div>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-verified"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Price Guarantee</span>
        </div>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-ship"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Allocation Guarantee</span>
        </div>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-refresh"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Quote Validity</span>
        </div>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-calendar"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Re-Scheduling or Cancellation</span>
        </div>
      </div>
      <div class="service-content">
        <div class="content-icon-wrapper">
          <i class="s4d-icon s4d-clock"></i>
        </div>
        <div class="content-text-wrapper">
          <span class="content-text">Detention</span>
        </div>
      </div>
    </div>
    <div class="plan-detailes-main-wrapper">
      <div class="basic-standard-detailes-wrapper">
        <div class="plan-title-wrapper">
          <h3 class="plan-title">
            Basic
          </h3>
        </div>
        <div class="plan-provided-info-wrapper basic">
          <div class="provided-content" matTooltipPosition="above" matTooltip="Included">
            <div class="icon-sign">
              <i class="s4d-icon s4d-check success"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above" matTooltip="Price is not guaranteed">
            <div class="icon-sign">
              <i class="s4d-icon s4d-close unsuccess"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="Not guaranteed (will be loaded 1st available sailing)">
            <div class="icon-sign">
              <i class="s4d-icon s4d-close unsuccess"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above" matTooltip="Quote is valid for 30 minutes">
            <div class="plan-text">
              30m
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="$200 fee for Re-Scheduling or Cancellation">
            <div class="plan-text">
              $200
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above" matTooltip="3 days for free">
            <div class="plan-text">
              3 days
            </div>
          </div>
        </div>
      </div>
      <div class="basic-standard-detailes-wrapper">
        <div class="plan-title-wrapper standard">
          <h3 class="plan-title ">
            Standard
          </h3>
        </div>
        <div class="plan-provided-info-wrapper">
          <div class="provided-content" matTooltipPosition="above" matTooltip="Included">
            <div class="icon-sign">
              <i class="s4d-icon s4d-check success"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="The price is guaranteed for approximately 45 days">
            <div class="icon-sign">
              <i class="s4d-icon s4d-check success"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="The allocation (date and space) is guaranteed">
            <div class="icon-sign">
              <i class="s4d-icon s4d-check success"></i>
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above" matTooltip="Quote is valid for 72 hours">
            <div class="plan-text">
              72h
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="Free re-scheduling or cancellation Up to 7 days from departure">
            <div class="plan-text free-text">
              Free
            </div>
          </div>
          <div class="provided-content" matTooltipPosition="above"
            matTooltip="Extended detention days of up to 8 days included">
            <div class="plan-text">
              8 days
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-info-wrapper">
    <button class="close-button" (click)="onClose()">
      Close
    </button>
  </div>
</div>