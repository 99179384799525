import { Pipe, PipeTransform } from '@angular/core';
import { HaulageType, LocationType } from '../shared.model';


@Pipe({ name: 'haulageTypeWord' })
export class HaulageTypeWordPipe implements PipeTransform {
  private haulageTypeNames = {};

  constructor() {
    this.haulageTypeNames[HaulageType.sea] = 'Sailing';
    this.haulageTypeNames[HaulageType.air] = 'Fly';
    this.haulageTypeNames[HaulageType.rail] = 'Railled';
    this.haulageTypeNames[HaulageType.land] = 'Picked up';
    this.haulageTypeNames[HaulageType.barge] = 'Sailing';
  }

  transform(value?: LocationType): string {
    return this.haulageTypeNames[value] ?? '';
  }
}
