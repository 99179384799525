import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppSettingsService } from "../services/app-settings/app-settings.service";
import { forkJoin } from "rxjs";
import { OrganizationsService } from "../../desktop/organizations/organizations.service";
import { TeamMember } from "../organizations/organization.model";
import { Organization } from "../../desktop/manage-organization/manage-organization.model";

declare global {
	interface Window {
		Appcues: any;
		AppcuesSettings: any;
	}
}

@Injectable({
	providedIn: "root",
})
export class AppcuesService {

	constructor(
		private router: Router,
		private appSettingsService: AppSettingsService,
		private organizationsService: OrganizationsService
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				window.Appcues.page();
			}
		});
	}

	loadBodyScript(id: string): void {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `//fast.appcues.com/${id}.js`;
		document.body.appendChild(script);
		window.AppcuesSettings = { enableURLDetection: true };
	}

	identify(): void {
		const appSettings = this.appSettingsService.getSettings();

		forkJoin([
			this.organizationsService.getTeamMember(appSettings.organizationId),
			this.organizationsService.getOrganization(appSettings.organizationId)
		]).subscribe(([teamMember, organization]: [TeamMember, Organization]) => {
			window.Appcues.identify(teamMember.userId, {
				firstName: teamMember.firstName,
				lastName: teamMember.lastName,
				companyName: organization.legalName,
				email: teamMember.email,
			})
		})
	}
}