import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-organizations-empty",
  templateUrl: "./organizations-empty.component.html",
  styleUrls: ["./organizations-empty.component.scss"],
})
export class OrganizationsEmptyComponent {
  @Output() openRegister: EventEmitter<boolean> = new EventEmitter();

  onRegister(): void {
    this.openRegister.emit(true);
  }
}
