import {
  CarriageStatusType,
  CodeType,
  Coordinates,
  CurrencyCode,
  DimensionUnit,
  HaulageType,
  IncotermCode,
  LoadUnit,
  LocationType,
  PricePer,
  RateServiceType,
  ShipmentType,
  ShipperType,
  VendorsCode,
  VolumeUnit,
  WeightRange,
  WeightUnit
} from '../../shared/shared.model';

export class QuoteBase {
  organizationId: string;
  companyCode: string;
  userId: string;
  searchId: string;
  from: TripsSearchBaseViewModel;
  to: TripsSearchBaseViewModel;
  vendorId?: VendorsCode;
  fromDate?: string;
  minFromDate?: string;
  shipperType: ShipperType;
  shipmentType: ShipmentType;
  currency?: CurrencyCode;
  sendLegs: boolean;
  equipments: QuotesEquipment[];
  containerCommodities: ContainerCommodity[];
  harmonizedSystemCode: string;
  incoterms: IncotermCode[];
  isHazardous: boolean;
  isBatteries: boolean;
  isAlternative: boolean;
  carriers: string[];
  isWithoutDimension: boolean;
}

export class QuotesQuery extends QuoteBase {
  pageNo: number;
  pageSize: number;
  commodities: string[];
}

export class QuoteSearch extends QuoteBase {
  quotes: Quote[];
  shareable: boolean;
  shareableExpirationDate: string;
  commodities: Commodity[];
}

export class TripsSearchBaseViewModel {
  codeType: CodeType;
  value: string;
  country: string;
  unLocode: string;
  zipCode: string;
  iATA: string;
  area: string;
  displayName: string;
  locationType: LocationType;
  coordinates: Coordinates;
  countryName: string;
  carriageType: CarriageStatusType;
  cityName: string;
  street: string;
  subdivision: string;
}

export class QuotesEquipment {
  equipmentCode: string;
  equipmentTypeDescription: string;
  quantity: number;
  weightUnit?: WeightUnit;
  weightAmount?: number;
}

export class ContainerCommodity {
  weightUnit?: WeightUnit;
  weightAmount?: number;
  volumeUnit?: VolumeUnit;
  volumeAmount?: number;
  length?: number;
  width?: number;
  height?: number;
  dimensionUnit?: DimensionUnit;
  numberOfPackages: number;
  totalWeightAmount?: number;
  totalVolumeAmount?: number;
  packageVolume?: number;
  packageWeight?: number;
  chargeableWeightAmount?: number;
  chargeableVolumeAmount?: number;
  loadUnit?: LoadUnit;
  selectedValue?: WeightRange;
  isWithoutDimension?: boolean;
}

export class Quote {
  id: string;
  firstSearch: boolean;
  routeId: string;
  schedulingTripId: string;
  quoteSearchId: string;
  accumulatePrice?: number;
  accumulateRateId?: string;
  currency?: CurrencyCode;
  carrierScac: string;
  estimatedDuration: number;
  departureDate?: string;
  arrivalDate?: string;
  trip: ExtenderTrip;
}

export class ExtenderTrip {
  schedulingTripId: string;
  quoteSearchId: string;
  routeId: string;
  rateId: string;
  accumulatePrice?: number;
  accumulateRateId?: string;
  currency?: CurrencyCode;
  vendorsCode: VendorsCode;
  carrierScac: string;
  estimatedDuration: number;
  fromUnLoCode: string;
  toUnLoCode: string;
  fromLocationTypeCode?: LocationType;
  toLocationTypeCode?: LocationType;
  departureDate?: string;
  arrivalDate?: string;
  cutOffDate?: string;
  fromEstimatedDuration?: number;
  toEstimatedDuration?: number;
  incoterms: IncotermCode[];
  isPreCarriageMissingRate?: boolean;
  isPostCarriageMissingRate?: boolean;
  reverseDiscount: number;
  preCarriageTripLeg: ExtenderTripLegBase;
  mainCarriageTripLeg: ExtenderTripLegBase;
  postCarriageTripLeg: ExtenderTripLegBase;
}

export class ExtenderTripLegBase {
  fromLocationName: string;
  toLocationName: string;
  legs: ExtenderTripLeg[];
}

export class ExtenderTripLeg {
  fromCode: string;
  fromCodeType: CodeType;
  fromLocationType?: LocationType;
  toCode: string;
  toCodeType: CodeType;
  toLocationType?: LocationType;
  fromLocationName: string;
  fromCountryName: string;
  departureDate?: string;
  toLocationName: string;
  toCountryName: string;
  arrivalDate?: string;
  estimatedDuration?: number;
  carrierCode: string;
  vesselName: string;
  voyage: string;
  vendorId: number;
  haulageType?: HaulageType;
  legNumber: number;
  carriageStatusTypeCode: CarriageStatusType;
  rates: ExtenderTripRate[];
}

export class ExtenderTripRate {
  rateServiceType: RateServiceType;
  shipmentEquipment: ShipmentEquipment;
  freightRate: FreightRate;
  surcharges: AdditionalRate[];
  validFrom?: string;
  validTo?: string;
}

export class ShipmentEquipment {
  code: string;
  name: string;
  description: string;
  quantity: number;
}

export class FreightRate {
  tariff: string;
  supplier: string;
  remarks: string;
  description: string;
  shipmentRate: ShipmentRate;
}

export class ShipmentRate {
  amount: number;
  totalAmount: number;
  currency: CurrencyCode;
  calculationBase: string;
  isRateMultiplied: boolean;
  pricePer: PricePer;
}

export class AdditionalRate {
  tariff: string;
  supplier: string;
  remarks: string;
  shipmentRate: ShipmentRate;
  additionalType: string;
  additionalTypeCode: string;
  applicableType: string;
}

export class MissingQuotes {
  organizationId: string;
  shipmentTypeName: string;
  from: string;
  to: string;
  shippingDate: string;
  notes: string;
  quoteSearchId: string;
  shipmentType: ShipmentType;
}

export class IncotermsMappingsQuery {
  shipmentType?: ShipmentType;
  shipperType?: ShipperType;
  fromLocationType?: LocationType;
  toLocationType?: LocationType;
}

export class Incoterm {
  id: IncotermCode;
  miniDescription: string;
  fullDescription: string;
}

export class StoredQuotesResults {
  quotes: Quote[];
  expiresAt: number;
}

export class Commodity {
  numberOfPackages: number;
  packageVolume: number;
  packageWeight: number;
  volumeAmount: number;
  volumeUnit: VolumeUnit;
  weightAmount: WeightUnit;
  weightUnit: number;
  dimensions: Dimensions;
}

export class Dimensions {
  dimensionUnit: DimensionUnit;
  height: number
  length: number
  width: number
}
