import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Location } from '@angular/common';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from "@ship4wd/ngx-common";
import { Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { ROLLUP_DIALOG_DATA } from "../../../../../mobile/rollup-dialog/rollup-dialog.model";
import { RollupDialogService } from "../../../../../mobile/rollup-dialog/rollup-dialog.service";
import { AppSettingsService } from "../../../../../shared/services/app-settings/app-settings.service";
import { StringCutterService } from "../../../../../shared/services/text-cutter/stringCutter.service";
import { LocationType, Page, SearchType, ShipmentType, ShipperType, TripsSearchBaseViewModel, TripsSearchFromViewModel, TripsSearchViewModelRequest, TripsSearchViewModelResponse } from "../../../../../shared/shared.model";
import { SupportedCountriesSearchQuery, SupportedCountry } from "../../../../../shared/supported-countries/supported-countries.model";
import { SupportedCountriesService } from "../../../../../shared/supported-countries/supported-countries.service";
import { TripUnLocodeAutocompleteService } from "../../../../../shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: 'app-quote-search-flow-location',
  templateUrl: './flow-location.component.html',
  styleUrls: ['./flow-location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowLocationComponent implements OnInit {
  searchTerm: FormControl = new FormControl();
  control: FormControl = new FormControl();
  locations: Map<LocationType, TripsSearchBaseViewModel[]> = new Map<LocationType, TripsSearchBaseViewModel[]>();
  sortedMap: any = [];
  type: SearchType = SearchType.from;
  pageSize: number = 10;
  minimumLength: number = 3;
  shipmentType = ShipmentType.FCL;
  searchId = '';
  selectedLocation: TripsSearchFromViewModel = null;
  locationsResponse: TripsSearchViewModelResponse = null;
  supportedCountries: SupportedCountry[];
  postcode: FormControl;
  fullAddress: FormControl;
  placesOptions = null;
  from = null;
  to = null;
  shipperType: ShipperType = undefined;

  selectedLocationForPostcode: TripsSearchFromViewModel = null;
  selectedGroupForPostode: number = 0;

  isLoading: boolean = false;
  isSearchFromPredefined: boolean = false;
  isLocationAutocompleteSearchEnable: boolean;
  isPostCode: boolean = false;

  @ViewChild("postCodeInput") postCodeInput: ElementRef;

  constructor(public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private location: Location,
    private settingsService: AppSettingsService,
    private stringCutterService: StringCutterService,
    private supportedCountriesService: SupportedCountriesService,
    private notificationService: NotificationService,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService) {
    this.type = data.type;
    this.searchId = data.searchId;
    this.shipmentType = data.shipmentType;
    if (this.type === SearchType.to) {
      this.from = data.from;
    }
    else if (this.type === SearchType.from) {
      this.to = data.to;
    }
  }

  ngOnInit(): void {
    this.isLocationAutocompleteSearchEnable = QuoteSearchFlowLocationComponent.getIsLocationAutocompleteSearch();
    this.onSearchChange();
    this.getSupportedCountries();
    this.checkIfSearchFromPredefinedRequired();

    if (this.data.control.value?.value !== undefined) {
      this.control = this.data.control;
      this.searchTerm.setValue(this.data.control.value?.value)
    }

    document.getElementById("searchTerm").focus();
  }

  getLocationDoorAdress(location: TripsSearchFromViewModel, shortName: boolean): string | null {
    if (location.locationType == LocationType.townCity && location.fullAddress != null) {
      return this.getLocationFullAddress(location, shortName);
    }

    return null;
  }

  isMustVerifyPostCode(
    tripsSearchFromViewModel: TripsSearchFromViewModel | any
  ): boolean {
    if (tripsSearchFromViewModel.locationType === LocationType.townCity) {
      if (
        this.shipmentType === ShipmentType.LCL ||
        this.shipmentType === ShipmentType.AIR
      ) {
        return true;
      } else {
        return this.isCountryTruckEnable(tripsSearchFromViewModel?.country);
      }
    }

    return false;
  }

  onSearchChange(): void {
    this.searchTerm.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          if (this.isSearchFromPredefined && this.control.value?.value) {
            this.isSearchFromPredefined = false;
            return this.search((this.control.value as TripsSearchBaseViewModel).value, null);
          }
          const isSearch = this.checkBeforeSearch(val);
          if (isSearch) {
            this.isLoading = true;
            this.locations.clear();
            this.sortedMap = [];
          }

          if (typeof val === "object") {
            val = (this.control.value as TripsSearchBaseViewModel).value;
          }

          return (isSearch === true ? this.search(val || '', null) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        this.processTripsResponse(x);
      });
  }

  onClear(state: boolean = false): void {
    if (state) {
      this.control.setValue("");
      this.searchTerm.setValue("");
    }
  }

  onCheckLocationType(locationType: LocationType): boolean {
    const ports = [LocationType.seaPort, LocationType.containerTerminal, LocationType.inlandPort];
    const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
    const trains = [LocationType.railwayTerminal];
    const airports = [LocationType.airPort];

    return !ports.includes(locationType) && !others.includes(locationType) && !trains.includes(locationType) && !airports.includes(locationType);
  }

  onSelect(location: TripsSearchFromViewModel, locationGroup: number, isExtended: boolean = false): void {
    if (this.isDomesticRoute(location)) {
      return;
    }

    if (this.checkLocationType(location.locationType) && isExtended && (locationGroup != LocationType.recentOrigins && locationGroup != LocationType.recentDestinations)) {
      this.selectedLocationForPostcode = location;

      this.selectedGroupForPostode = locationGroup;
      this.placesOptions = {
        componentRestrictions: { country: [location.country] },
      };

      this.isPostCode = true;

      this.fullAddress = new FormControl("", Validators.required);
      this.postcode = new FormControl("", Validators.required);
      this.selectedLocation = location;
      setTimeout(() => {
        this.postCodeInput?.nativeElement?.focus();
      }, 500);
    }
    else {
      this.setSelected(location);
    }

  }

  onAddressChange(address: any): void {
    const fullAddressValue = address.address_components
      .filter(
        (component) =>
          !component.types.includes("postal_code") &&
          !component.types.includes("plus_code") &&
          !component.types.includes("country")
      )
      .map((component) => component.short_name || component.long_name)
      .join(", ");

    this.fullAddress.patchValue(fullAddressValue);
    for (let i in address.address_components) {
      const item = address.address_components[i];
      if (item["types"].indexOf("postal_code") > -1) {
        this.postcode.patchValue(item["long_name"]);

        return;
      } else {
        this.postcode.patchValue(this.selectedLocation.zipCode);
      }
    }
  }

  onConfirmPostCode(): void {
    if (this.fullAddress?.valid) {
      this.selectedLocationForPostcode.zipCode = this.postcode.value;
      this.selectedLocationForPostcode.fullAddress = this.fullAddress.value;

      this.setSelected(this.selectedLocationForPostcode);
      this.onConfirm();
    }
  }

  onConfirm(): void {
    const data = {
      control: this.control,
      searchId: this.searchId,
      shipperType: this.shipperType
    }

    this.dialogRef.close(data);
  }

  private setSelected(location: TripsSearchFromViewModel): void {
    this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(location, this.type, this.shipmentType);

    this.selectedLocation = location;
    this.selectedLocation.searchId = this.locationsResponse?.searchId;
    this.control.setValue(this.selectedLocation);

    this.onConfirm();
  }

  isDomesticRoute(location: TripsSearchBaseViewModel): boolean {
    if ((this.type == SearchType.from && this.to != null && this.to != '' && location.country == this.to.country)
      || (this.type == SearchType.to && this.from != null && location.country == this.from.country)) {
      return true;
    }
    return false;
  }

  private search(searchValue: string, postcodeSearchValue: string): Observable<TripsSearchViewModelResponse> {
    if (this.isLocationAutocompleteSearchEnable === true) {
      return this.tripUnLocodeAutocompleteService.getLocationsUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false,
          this.from))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.searchId = checkSearchId;

            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getTripUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false,
          this.from))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.searchId = checkSearchId;

            return response;
          }));
    }
  }

  private prepareUnLocodeRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel
  ): TripsSearchViewModelRequest {
    return {
      type: searchType,
      pageSize,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from: from,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private checkBeforeSearch(value: string): boolean | TripsSearchViewModelResponse {
    if (this.selectedLocation) {
      this.selectedLocation = null;
    }

    if (this.checkMinimumLength(value)) {
      return null;
    }

    return true
  }

  private checkMinimumLength(value: string): boolean {
    return value?.length < this.minimumLength;
  }

  private processTripsResponse(x: TripsSearchViewModelResponse): void {
    this.locations.clear();
    this.sortedMap = [];
    this.locationsResponse = x;

    if (this.type === SearchType.to && x?.shipperType) this.shipperType = x?.shipperType;

    if (x?.tripsSearchList?.length > 0) {
      x.tripsSearchList.forEach((trip: TripsSearchBaseViewModel) => {
        const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
        const ports = [LocationType.seaPort, LocationType.inlandPort];
        const trains = [LocationType.railwayTerminal];

        let locationType = trip.locationType;

        trip.cuttedDisplayName = this.stringCutterService.cutter(trip.displayName, 20);

        if (others.includes(trip.locationType)) {
          locationType = LocationType.other;
        }
        else if (ports.includes(trip.locationType)) {
          locationType = LocationType.seaPort;
        }
        else if (trains.includes(trip.locationType)) {
          locationType = LocationType.railwayTerminal;
        }

        if (this.locations.has(locationType)) {
          this.locations.get(locationType).push(trip);
        }
        else {
          this.locations.set(locationType, [trip]);
        }
      })

      const sortedKeys = Array.from(this.locations.keys()).sort((a: LocationType, b: LocationType) => {
        return this.getLocationTypeWeight(a) - this.getLocationTypeWeight(b);
      });

      sortedKeys.forEach(key => {
        this.sortedMap.push({ key: key, value: this.locations.get(key)! });
      });
    }
    else {
      if (this.locationsResponse?.tripsSearchList?.length == undefined) {
        let cachelist = this.tripUnLocodeAutocompleteService.getTripsSearchsFromStorage(this.type, this.shipmentType);
        this.locations.clear();
        this.sortedMap = [];
        const key = this.type == SearchType.from ? LocationType.recentOrigins : LocationType.recentDestinations;
        this.sortedMap.push({ key, value: cachelist });
      }
    }

    this.isLoading = false;
  }

  private getLocationTypeWeight(type: LocationType): number {
    if (type === LocationType.seaPort)
      return 1;

    if (type === LocationType.townCity)
      return this.getTownCityWeight();

    if (type === LocationType.containerTerminal || type === LocationType.airPort)
      return 2;

    if (type === LocationType.railwayTerminal)
      return this.getRailwayTerminalWeight();

    return 3;
  }

  private getTownCityWeight(): number {
    if (this.shipmentType === ShipmentType.FCL)
      return this.type === SearchType.from ? 2 : 3;

    return 1;
  }

  private getRailwayTerminalWeight(): number {
    if (this.shipmentType === ShipmentType.FCL)
      return this.type === SearchType.from ? 3 : 2;

    return 3;
  }

  static getIsLocationAutocompleteSearch(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );
    switch (environmentName) {
      case "qa":
        return environment.qa.isLocationAutocompleteSearch;
      case "sb":
        return environment.sb.isLocationAutocompleteSearch;
      default:
        return environment.isLocationAutocompleteSearch;
    }
  }

  private getSupportedCountries(): void {
    const query = this.prepareSupportedCountriesQuery();

    this.isLoading = true;
    this.supportedCountriesService.getSupportedCountries(query)
      .subscribe((x: Page<SupportedCountry>) => {
        this.supportedCountries = x.items;
      },
        (error) => this.notificationService.error(error),
      ).add(() => (this.isLoading = false));
  }

  private prepareSupportedCountriesQuery(): SupportedCountriesSearchQuery {
    let query = new SupportedCountriesSearchQuery();
    query.shipmentType = this.shipmentType;
    query.pageNo = 1;
    query.pageSize = 500;
    return query;
  }

  private checkIfSearchFromPredefinedRequired(): void {
    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (this.location.path().includes('reused') || !!quotesQueryString) {
      this.isSearchFromPredefined = true;
    }
  }

  private checkLocationType(locationType: LocationType): boolean {
    const ports = [LocationType.seaPort, LocationType.containerTerminal, LocationType.inlandPort];
    const others = [LocationType.industrialZone, LocationType.busStation, LocationType.island, LocationType.heliport, LocationType.dryPort, LocationType.administrativeDivision];
    const trains = [LocationType.railwayTerminal];
    const airports = [LocationType.airPort];

    return !ports.includes(locationType) && !others.includes(locationType) && !trains.includes(locationType) && !airports.includes(locationType);
  }

  private isCountryTruckEnable(countryCode: string): boolean {
    const found = this.supportedCountries.find(x => x.countryCode == countryCode && x.isTruckEnabled);
    return found != undefined;
  }

  private getLocationFullAddress(location: TripsSearchFromViewModel, shortName: boolean): string | null {
    const doorAddress = `${location.zipCode}, ${location.fullAddress}`;
    return shortName ? this.stringCutterService.cutter(doorAddress, 35) : doorAddress;
  }
}
