import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quotes-banner',
  templateUrl: './quotes-banner.component.html',
  styleUrls: ['./quotes-banner.component.scss']
})
export class QuotesBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
