import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { Booking, BookingStatusCode } from '../../../../shared/bookings/bookings.model';
import { BookingPaidStatusCode, BookingViewDetailsPage, ShipmentType } from '../../../../shared/shared.model';
import { TransactionPaymentDialogComponent } from '../../order/transaction-payment-dialog/transaction-payment-dialog.component';
import { environment } from '../../../../../environments/environment';
import { UtilityService } from '../../../../shared/helper/utility.service';

@Component({
  selector: 'app-order-list-grid-view',
  templateUrl: './order-list-grid-view.component.html',
  styleUrls: ['./order-list-grid-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderListGridViewComponent {
  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  @Input()
  dataSource: MatTableDataSource<Booking>;
  @Input()
  sortParameters: Array<any>;
  @Input()
  isLoading: boolean = false;
  @Input() isNoBookings: boolean;
  @Input() isSortLoading: boolean = false;

  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  @Output() sortBookingEvent: EventEmitter<MatSort> = new EventEmitter();
  @Output() exportBookingsEvent: EventEmitter<string> = new EventEmitter();
  public columns = [
    'icon',
    'bolOrderNumber',
    'masterBillOfLading',
    'shipmentType',
    'from',
    'to',
    'statusType',
    'etd',
    'createTimeUtc',
    'updateTimeUtc',
    'action',
  ];

  isFinanceEnabled: boolean;

  get isEmptyList(): boolean {
    return this.dataSource.data.length < 1
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private utilityService: UtilityService) {
    this.isFinanceEnabled = this.getIsFinanceEnabled();
  }

  getMainCarriageIcon(booking: Booking): string {
    switch (booking.shipmentTypeCode) {
      case ShipmentType.AIR:
        return 'local_airport';

      default:
        return 'directions_boat';
    }
  }

  onCancelBooking(bookingId: string): void {
    this.canceledBookingEvent.emit(bookingId);
  }

  onSort(): void {
    this.sortBookingEvent.emit(this.sort);
  }

  getSubmitButtonIcon(booking: Booking): string {
    if (!this.isSubmitted(booking)) {
      return 'playlist_add_check'
    }
    return 'view-order-icon'
  }

  navigateTo(booking: Booking): void {
    if (!this.isSubmitted(booking)) {
      this.router.navigate(['/booking', booking.id, true]);
    }
    else if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid) {
      this.openTransactionPaymentDialog(booking);
    }
    else {
      this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, booking.id]);
    }
  }

  isSubmitted(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft;
  }

  showPayOrder(booking: Booking): boolean {
    if (!this.isFinanceEnabled) return false;

    return this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid;
  }

  navigateToBookingDetails(booking: Booking): void {
    this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, booking.id]);
  }

  onQuoteSearch(): void {
    this.router.navigate(['/quote-search'])
  }

  checkDateNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private openTransactionPaymentDialog(booking: Booking) {
    const dialogRef = this.dialog.open(TransactionPaymentDialogComponent, {
      panelClass: 'transaction-payment',
      autoFocus: false,
      data: {
        booking: booking
      }
    });

    dialogRef.afterClosed().subscribe(() => { });
  }
}
