<ng-template #header>
  <div class="header-panel" [ngClass]="isFiltersActive ? 'd-flex h-100 no-background' : ''" *ngIf="isSearchActive">
    <div class="header-panel-wrapper">
      <div class="search-container">
        <div class="row">
          <div class="col pe-0">
            <div class="panel-search-box">
              <mat-form-field [floatLabel]="'never'">
                <span matPrefix class="panel-search-icon">
                  <app-booking-icons [iconName]="'search-icon'"></app-booking-icons>
                </span>
                <input matInput placeholder="Search..." [(ngModel)]="searchText" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-auto px-1">
            <button mat-icon-button class="panel-button" (click)="onFiltersClick()">
              <app-booking-icons [iconName]="'filter-icon'"></app-booking-icons>
            </button>
          </div>
          <div class="col-auto px-1">
            <button mat-icon-button class="panel-button close-icon" (click)="onSearchClick()">
            </button>
          </div>
        </div>
      </div>
      <div class="filters-container" *ngIf="isFiltersActive">
        <div class="row">
          <div class="col">
            <span class="filter-content-title">Order status</span>
            <div class="filter-order-status-wrapper" *ngFor="let orderStatus of orderStatuses; let i = index">
              <span class="filter-order-status-text">
                <mat-checkbox class="filter-order-status-checkbox" [value]="orderStatus.value"
                  [(ngModel)]="orderStatus.isChecked" [checked]="orderStatus.allComplete" color="primary"
                  (change)="setAll($event.checked,orderStatus)" [indeterminate]="someComplete(orderStatus)">
                  {{ orderStatus.statusName }}
                </mat-checkbox>
              </span>
              <span class="filter-sub-order-status-text"
                *ngIf="orderStatus.isChild && orderStatus.childStatuses.length > 0">
                <ul>
                  <li *ngFor="let childStatus of orderStatus.childStatuses;let j = index">
                    <mat-checkbox class="filter-sub-order-status-checkbox" [value]="childStatus.value"
                      [checked]="childStatus.isChecked" [(ngModel)]="childStatus.isChecked"
                      (ngModelChange)="updateAllComplete(orderStatus)">
                      {{ childStatus.statusName }}
                    </mat-checkbox>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="filter-order-fields-wrapper" [formGroup]="filterForm">
              <div class="filter-order-field">
                <div class="row">
                  <div class="col">
                    <span class="filter-content-title">From city</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field [floatLabel]="'never'" class="w-100">
                      <input matInput type="text" placeholder='Type city name' formControlName="fromCity" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="filter-order-field">
                <div class="row">
                  <div class="col">
                    <span class="filter-content-title">To city</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field [floatLabel]="'never'" class="w-100">
                      <input matInput type="text" placeholder='Type city name' formControlName="toCity" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="filter-order-field">
                <div class="row">
                  <div class="col">
                    <span class="filter-content-title">From last update date</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field class="w-100">
                      <input matInput [ngxMatDatetimePicker]="picker" formControlName="fromLastUpdateDate" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker #picker>
                      </ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="filter-order-field">
                <div class="row">
                  <div class="col">
                    <span class="filter-content-title">BOL/Order №</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field [floatLabel]="'never'" class="w-100">
                      <input matInput type="text" placeholder='Type BOL/Order№' formControlName="bolOrderNumber" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="filter-order-field">
                <div class="row">
                  <div class="col">
                    <span class="filter-content-title">Master bill of lading</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field [floatLabel]="'never'" class="w-100">
                      <input matInput type="text" placeholder='Type master bill of lading'
                        formControlName="masterBillOfLading" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 pb-5 filters-buttons-wrapper">
          <div class="col">
            <button mat-stroked-button class="back-button" (click)="clearFilter()"
              [disabled]="!isFilterApplied">Clear</button>
          </div>
          <div class="col">
            <button mat-raised-button class="next-button" (click)="applyFilter()">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="mobile-shipments">
  <app-orders [isLoading]="isLoading" (isLoadingChanged)="onLoadingChange($event)" [searchText]="searchText"
    [filters]="filters" [isClearFilters]="isClearFilters"
    [ngClass]="(isFilterApplied || searchText) && !isSearchActive ? 'filtered' : ''"></app-orders>
  <div class="clear-filter" *ngIf="(isFilterApplied || searchText) && !isSearchActive">
    <button mat-raised-button class="clear-button" (click)="onClearFilter()">
      <span class="button-inner">
        Clear filter
      </span>
    </button>
  </div>
</div>

<app-dashboard-nav *ngIf="isMobileDashboardEnabled"></app-dashboard-nav>
<app-navbar *ngIf="!isMobileDashboardEnabled">
  <div class="col px-3 h-100 align-self-center bottom-navigation-wrapper">
    <div class="row">
      <div class="col px-2">
        <a [routerLink]="['/shipments']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon shipment"></span>
            <span class="nav-link-text">Shipments</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a class="nav-link" [ngClass]="isSearchActive ? 'is-active' : ''" (click)="onSearchClick()">
          <div class="d-flex flex-column">
            <span class="nav-link-icon search"></span>
            <span class="nav-link-text">Search</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a [routerLink]="['/quote-search']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon search-quote"></span>
            <span class="nav-link-text">Get Quote</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a [routerLink]="['/finance']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon finance"></span>
            <span class="nav-link-text">Finance</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a class="nav-link" (click)="onChatClick()">
          <div class="d-flex flex-column">
            <span class="nav-link-icon chat"></span>
            <span class="nav-link-text">Chat</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</app-navbar>