import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { Booking, ContactType } from '../../../../../shared/bookings/bookings.model';
import { OrganizationContact, OrganizationContactAction, OrganizationContactViewModel } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { BookingOrganizationContactsService } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { BookingContactsService } from '../../../../../shared/bookings/booking-contacts/booking-contacts.service';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { BookingContactViewModel } from '../../../../../shared/bookings/booking-contacts/booking-contacts.model';
import { LayoutService } from '../../../../../mobile/layout/layout.service';
import { ValidatorsService } from '../../../../../shared/helper/validators.service';

@Component({
  selector: 'app-create-booking-organization-contact',
  templateUrl: './create-booking-organization-contact.component.html',
  styleUrls: ['./create-booking-organization-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateBookingOrganizationContactComponent implements OnInit, AfterViewInit {
  @Output() backToContact = new EventEmitter();
  @Output() selectedContact = new EventEmitter();
  @Input() booking: Booking;
  @Input() contactType: ContactType;
  @Input() organizationContact: OrganizationContact;
  @Input() organizationContactAction: OrganizationContactAction;

  organizationContactForm: FormGroup;
  countryAutocompleteValue: string;
  isLoading = false;
  mainAddress = '';
  organizationContactActions = OrganizationContactAction;

  constructor(
    private fb: FormBuilder,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private bookingContactsService: BookingContactsService,
    private layoutService: LayoutService,
    private validatorsService: ValidatorsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.organizationContactForm = this.createOrganizationContactForm();
    this.mainAddress = this.getPropertyValue('address1');
    this.countryAutocompleteValue = this.getPropertyValue('countryCode');
    this.onChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let title = this.organizationContactAction === OrganizationContactAction.view ? 'View contact' :
        this.organizationContactAction === OrganizationContactAction.edit ? 'Update contact' :
          'New contact';
      this.layoutService.setToolbarTitle(title);
      this.layoutService.setToolbarDescription('');
    }, 0);
  }

  onAddressChange(address: any) {
    this.mainAddress = '';
    this.organizationContactForm.controls['city'].setValue('');
    this.organizationContactForm.controls['countryCode'].setValue('');
    this.organizationContactForm.controls['state'].setValue('');
    this.organizationContactForm.controls['zipCode'].setValue('');

    for (let i in address.address_components) {
      const item = address.address_components[i];

      if (item['types'].indexOf('route') > -1) {
        this.mainAddress += ' ' + item['long_name'];
      }
      else if (item['types'].indexOf('street_number') > -1) {
        this.mainAddress += item['long_name'];
      }
      else if (item['types'].indexOf('locality') > -1) {
        this.organizationContactForm.controls['city'].setValue(item['long_name']);
      } else if (item['types'].indexOf('country') > -1) {
        this.organizationContactForm.controls['countryCode'].setValue(item['short_name']);
        this.countryAutocompleteValue = item['short_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        this.organizationContactForm.controls['state'].setValue(item['long_name']);
      } else if (item['types'].indexOf('postal_code') > -1) {
        this.organizationContactForm.controls['zipCode'].setValue(item['long_name']);
      }
    }

    this.organizationContactForm.controls['address1'].setValue(this.mainAddress);
  }

  onBackToContact() {
    this.backToContact.emit();
  }

  onSelectOnly() {
    this.organizationContactForm.markAllAsTouched();
    if (!this.organizationContactForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }
    const organizationContact = this.organizationContactForm.value as OrganizationContact;

    if (!this.compareLocations(organizationContact)) return;

    organizationContact.organizationId = this.booking.organizationId;
    this.setBookingContact(organizationContact, true);
  }

  onSaveAndSelect() {
    this.organizationContactForm.markAllAsTouched();
    if (!this.organizationContactForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }
    const organizationContact = this.organizationContactForm.value as OrganizationContactViewModel;

    if (!this.compareLocations(this.organizationContactForm.value as OrganizationContact)) return;

    organizationContact.organizationId = this.booking.organizationId;
    this.saveOrganizationContact(organizationContact);
  }

  private isDisabled() {
    return this.organizationContactAction === OrganizationContactAction.view ? true : false;
  }

  private isRequired() {
    return this.organizationContactAction === OrganizationContactAction.view ? false : true;
  }

  private createOrganizationContactForm() {
    return this.fb.group({
      id: [this.organizationContact?.id ?? null],
      companyName: [
        { value: this.getPropertyValue('companyName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      firstName: [
        { value: this.getPropertyValue('firstName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      lastName: [
        { value: this.getPropertyValue('lastName'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, this.validatorsService.whiteSpaceValidator] : []
      ],
      email: [
        { value: this.getPropertyValue('email'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, Validators.email] : []
      ],
      address1: [
        { value: this.getPropertyValue('address1'), disabled: this.isDisabled() }
      ],
      address2: [
        { value: this.getPropertyValue('address2'), disabled: this.isDisabled() }
      ],
      countryCode: [
        { value: this.getPropertyValue('countryCode'), disabled: this.isDisabled() },
        this.isRequired() ? Validators.required : []
      ],
      state: [
        { value: this.getPropertyValue('state'), disabled: this.isDisabled() }
      ],
      city: [
        { value: this.getPropertyValue('city'), disabled: this.isDisabled() }
      ],
      zipCode: [
        { value: this.getPropertyValue('zipCode'), disabled: this.isDisabled() },
      ],
      phoneNumber: [
        { value: this.getPropertyValue('phoneNumber'), disabled: this.isDisabled() },
        [this.isRequired() ? Validators.required : [], this.phoneValidator()]
      ],
      fax: [
        { value: this.getPropertyValue('fax'), disabled: this.isDisabled() }
      ],
      taxId: [
        { value: this.getPropertyValue('taxId'), disabled: this.isDisabled() }
      ],
      customerCode: [
        { value: this.getPropertyValue('customerCode'), disabled: this.isDisabled() }
      ]
    });
  }

  private getPropertyValue(propertyName: string) {
    if (this.organizationContactAction === OrganizationContactAction.view ||
      this.organizationContactAction === OrganizationContactAction.edit) {
      return this.organizationContact[propertyName] ?? '';
    }
    else {
      return '';
    }
  }

  private compareLocations(organizationContact: OrganizationContact) {

    if (this.contactType === ContactType.shipper) {
      let from = this.booking.fromBookingSearch;

      if (from.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${from.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok"
          },
        });

        return false;
      }
    }
    else if (this.contactType === ContactType.consignee) {
      let to = this.booking.toBookingSearch;

      if (to.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${to.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok"
          },
        });

        return false;
      }
    }

    return true;
  }

  private setBookingContact(organizationContact: OrganizationContact, isSelectOnly: boolean = false) {
    const bookingContact = this.prepareBookingContactModel(organizationContact);
    if (this.contactType === ContactType.collect || this.contactType === ContactType.drop) {
      this.notificationService.success('Address has been successfully changed.');

      this.selectedContact.emit({
        bookingContact: bookingContact,
        contactType: this.contactType
      });
    }
    else {
      this.addBookingContact(bookingContact, isSelectOnly);
    }
  }

  private prepareBookingContactModel(organizationContact: OrganizationContact) {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = this.contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.address1 = this.mainAddress;
    bookingContactViewModel.address2 = organizationContact.address2;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.state = organizationContact.state;
    bookingContactViewModel.city = organizationContact.city;
    bookingContactViewModel.zipCode = organizationContact.zipCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private addBookingContact(bookingContact: BookingContactViewModel, isSelectOnly: boolean) {
    if (!isSelectOnly) {
      this.isLoading = true;
    }
    this.bookingContactsService
      .addBookingContact(bookingContact)
      .subscribe(
        (bookingContactId: string) => {
          this.notificationService.success(`Contact has been successfully
            ${this.organizationContactAction === OrganizationContactAction.new ? 'added' : 'updated'}.`
          );
          this.selectedContact.emit({
            bookingContactId: bookingContactId,
            contactType: this.contactType
          });
        },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  private saveOrganizationContact(organizationContact: OrganizationContactViewModel) {
    this.isLoading = true;
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.bookingOrganizationContactsService
        .addOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          this.setBookingContact(x);
        },
          (error) => this.notificationService.error(error))
        .add(() => this.isLoading = false);
    }
    else {
      this.bookingOrganizationContactsService
        .updateOrganizationContact(organizationContact)
        .subscribe((x: OrganizationContact) => {
          this.setBookingContact(x);
        },
          (error) => this.notificationService.error(error))
        .add(() => this.isLoading = false);
    }
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const country = this.organizationContactForm?.get('countryCode').value;
      let valid = false;

      switch(country) {
        case 'US':
        case 'CA':
          // Phone number pattern for US and Canada
          valid = /^\d{10}$/.test(control.value);
          break;
        default:
          // Default phone number pattern
          valid = /^[0-9\-\+]{9,20}$/.test(control.value);
          break;
      }

      return valid ? null : {'invalidPhoneNumber': {value: control.value}};
    };
  }

  private onChanges(): void {
    this.organizationContactForm.get('countryCode').valueChanges.subscribe(val => {
      this.organizationContactForm.get('phoneNumber').updateValueAndValidity();
    });
  }
}
