import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@ship4wd/ngx-authorization';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingFilesDocumentsService } from '../../../../desktop/booking-files-documents/booking-files-documents.service';
import { DocumentComment } from '../../../../shared/bookings/booking-documents/booking-documents.model';
import { BookingFile } from '../../../../shared/bookings/bookings.model';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { UserTypeCode } from '../../../../shared/shared.model';

@Component({
  selector: 'app-comments-dialog',
  templateUrl: './comments-dialog.component.html',
  styleUrls: ['./comments-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentsDialogComponent implements OnInit {
  document: BookingFile;
  commentControl = new FormControl();
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CommentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private bookingFilesDocumentsService: BookingFilesDocumentsService,
    private userService: UserService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.document = this.data?.document;
  }

  onSendComment() {
    if (this.commentControl.value === '' ||
      this.commentControl.value === null ||
      this.commentControl.value === undefined) {
      this.commentControl.markAsTouched();
      this.notificationService.error('Please fill comment.');
      return;
    }

    const documentComment = this.prepareDocumentCommentModel(this.commentControl.value);
    this.addNewComment(documentComment);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private addNewComment(documentComment: DocumentComment) {
    this.isLoading = true;
    this.bookingFilesDocumentsService
      .addDocumentComment(this.document.bookingId, this.document.id, documentComment)
      .subscribe((bookingDocument: BookingFile) => {
        this.notificationService.success('Comment has been added successfully.');
        this.dialogRef.close({
          document: bookingDocument
        });
      },
        error => {
          this.notificationService.error(error);
        }
      )
      .add(() => (this.isLoading = false));
  }

  private prepareDocumentCommentModel(comment: string) {
    const documentComment = new DocumentComment();
    documentComment.createTimeUtc = this.toIsoDateTimeString();
    documentComment.userName = this.userInfoService.getSettings().fullName;
    documentComment.userType = UserTypeCode.user;
    documentComment.userId = this.userService.getUser()?.userId;
    documentComment.comment = comment;
    return documentComment;
  }

  private toIsoDateTimeString() {
    let date = new Date();
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }
}
