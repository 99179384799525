import { Equipment } from './shared.model';

export const Equipments: Equipment[] = [
    // { isoCode: '22BU', description: '20 Dry Bulk Container' },
    { isoCode: '22G1', description: '20 Dry Container' },
    // { isoCode: '22P1', description: '20 Flat Rack' },
    // { isoCode: '22P3', description: '20 Platform' },
    // { isoCode: '22R1', description: '20 Reefer Container' },
    // { isoCode: '22TN', description: '20 Tank Container' },
    // { isoCode: '22U1', description: '20 Open Top Container' },
    { isoCode: '42G1', description: '40 Dry Container' },
    // { isoCode: '42P1', description: '40 Flat Rack' },
    // { isoCode: '42P3', description: '40 Platform' },
    // { isoCode: '42R1', description: '40 Reefer Container' },
    // { isoCode: '42TN', description: '40 Tank Container' },
    // { isoCode: '42U1', description: '40 Open Top Container' },
    { isoCode: '45G1', description: '40 High Cube Dry Container' },
    // { isoCode: '45R1', description: '40 High Cube Reefer Container' },
    { isoCode: 'L5G0', description: '45 High Cube Dry Container' },
    // { isoCode: 'M5G1', description: '48 High Cube Dry Container' }
];
