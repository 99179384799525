import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routesTree: Routes = [
];

@NgModule({
  imports: [RouterModule.forRoot(routesTree)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }
