import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingFlowService } from '../../booking-flow.service';
import {
  AdditionalServiceType,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges,
  InsuranceRate,
  InsuranceRateQuery
} from '../../../../../shared/additional-services/additional-services.model';
import { AdditionalRate, CurrencyCode, InsurancePanels, VendorsCode } from '../../../../../shared/shared.model';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { ROLLUP_DIALOG_DATA } from '../../../../rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../rollup-dialog/rollup-dialog.service';
import { AppSettingsService } from '../../../../../shared/services/app-settings/app-settings.service';
import { OrganizationsService } from '../../../../../desktop/organizations/organizations.service';
import { Organization } from '../../../../../desktop/manage-organization/manage-organization.model';

@Component({
  selector: 'app-additional-service-insurance-manually',
  templateUrl: './additional-service-insurance-manually.component.html',
  styleUrls: ['./additional-service-insurance-manually.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServiceInsuranceManuallyComponent implements OnInit {
  additionalRates: AdditionalRate[];
  bookingId: string;
  booking: Booking;
  isServiceAlreadyAdded: boolean;
  insuranceRate: InsuranceRate;
  organization: Organization;
  organizationId: string;
  isExpanded: boolean[] = [false, false, false, false];
  insurancePanels = InsurancePanels;
  isShowCalculateInsurance: boolean = false;
  cargoInvoiceValueControl: FormControl = new FormControl('', Validators.required);
  calculateInsuranceBtnLoading: boolean = false;
  insurancePrice: number;
  isTermsAndConditionChecked: boolean = false;
  isLoading: boolean = false;
  currencyCodes = CurrencyCode;
  cargoInputString: string = '';
  errorMessages: string[] = [];

  constructor(
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService,
    private settingsService: AppSettingsService,
    private organizationsService: OrganizationsService,
    public rollupDialogService: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.bookingId = data.bookingId;
    this.booking = data.booking;
    this.additionalRates = data.additionalRates;
    this.isServiceAlreadyAdded = data.isServiceAlreadyAdded;
  }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.organizationsService.getOrganization(this.organizationId)
      .subscribe((x: Organization) => {
        this.organization = x
      })
  }

  onClickPanel(state: boolean, panel: InsurancePanels): void {
    this.isExpanded[panel] = state;
  }

  onChangeTermsAndConditions(termsAndCondition: any): void {
    this.isTermsAndConditionChecked = termsAndCondition.checked
  }

  onCalculateInsurance(): void {
    if (this.cargoInvoiceValueControl.value && this.cargoInvoiceValueControl.value != '') {
      this.calculateInsuranceBtnLoading = true;
      this.errorMessages = [];
      var insuranceRateQuery = this.prepareInsuranceRateQuery();
      this.bookingFlowService.getInsuranceRateByQuery(insuranceRateQuery)
        .subscribe(
          (response: any) => {
            if (response.data as InsuranceRate && response.errors.length === 0) {
              this.insuranceRate = response.data;
              this.insurancePrice = this.onCalculateInsurancePrice()
              this.isShowCalculateInsurance = true;
            } else {
              response.errors.forEach(x => this.errorMessages.push(x.message));
            }
          },
          (error: any) => {
            this.notificationService.error(error);
          }
        ).add(() => {
          this.calculateInsuranceBtnLoading = false;
        });
    }
  }

  checkValid(control: FormControl): boolean {
    return !control?.valid && control?.touched
  }

  onAddInsurance(): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges();
    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: AdditionalServiceType.insuranceManually,
      charges: charges
    } as CreateBookingAdditionalServiceCharges;
    this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
  }

  onRemove(): void {
    this.rollupDialogService.close({
      isRemove: true
    });
  }

  onLinkClick(): void {
    window.open(this.insuranceRate?.insuranceProduct?.termsAndConditionsLink, "_blank");
  }

  onInsuranceCargoValueInput(): void {
    this.cargoInputString = this.removeAllCommas(this.cargoInputString);
    this.cargoInvoiceValueControl.setValue(this.cargoInputString);
    this.cargoInputString = this.formatNumberWithCommas(this.cargoInputString);
  }

  private onCalculateInsurancePrice(): number {
    return this.insuranceRate?.insuranceProduct?.premium +
      this.insuranceRate?.insuranceProduct?.tax +
      this.insuranceRate?.insuranceProduct?.serviceFee
  }

  private prepareInsuranceRateQuery(): InsuranceRateQuery {
    return {
      bookingId: this.booking.id,
      organizationId: this.organizationId,
      cargoValue: this.cargoInvoiceValueControl.value
    } as InsuranceRateQuery
  }

  private prepareCreateBookingAdditionalServiceCharges()
    : CreateBookingAdditionalServiceCharge[] {
    let charges = new Array<CreateBookingAdditionalServiceCharge>();

    this.additionalRates.forEach(x => {
      const charge = this.prepareBookingAdditionalServiceCharge(x);
      charges.push(charge);
    })

    return charges;
  }

  private prepareBookingAdditionalServiceCharge(additionalRate: AdditionalRate)
    : CreateBookingAdditionalServiceCharge {
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: VendorsCode.default,
      price:  0,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: 1
    } as CreateBookingAdditionalServiceCharge;
  }

  private saveBookingCustomAdditionalServiceCharges(model: CreateBookingAdditionalServiceCharges): void {
    this.isLoading = true;
    this.bookingFlowService.addAdditionalServices(this.bookingId, model)
      .subscribe(
        () => {
          this.rollupDialogService.close({
            isBookingAdditionalServiceUpdated: true,
            additonalServiceType: AdditionalServiceType.insuranceManually
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      ).add(() => {
        this.isLoading = false;
      });
  }

  private removeAllCommas(inputString: string): string {
    const stringWithoutCommas = inputString.replace(/,/g, '');
    return stringWithoutCommas;
  }

  private formatNumberWithCommas(value: string): string {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}
