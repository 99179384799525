import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs";
import { CompanySearchQuery, FoundCompany } from "./company-search.model";
import { EncodeUrlCodec } from "../services/encode-url-codec";
import { Page } from "../shared.model";

@Injectable()
export class CompanySearchService {
  constructor(
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService
  ) { }

  getFoundCompanies(query: CompanySearchQuery): Observable<Page<FoundCompany>> {
    let params = new HttpParams({
      fromObject: query as any,
      encoder: new EncodeUrlCodec(),
    });

    return this.http.get<Page<FoundCompany>>(
      `${this.internalUrlsService.getApiBaseUrl()}/companySearch`,
      { params }
    );
  }
}
