<div class="booking-organization-contacts-container" *ngIf="!isOpenNewContact">
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div [hidden]="isLoading">
    <div class="page-header-with-back-button">
      <div class="back-title">
        <a href="javascript:void(0)" class="back-icon" (click)="onBackToBooking()">{{ backTitle }}</a>
      </div>
      <div class="page-header">
        <div class="page-header-wrapper">
          <div class="page-header-left">
            <h2 class="page-header-title">Select Contact</h2>
          </div>
          <div class="page-header-right">
            <div class="booking-organization-contact-search-box">
              <mat-form-field [floatLabel]="'never'">
                <span matPrefix class=""></span>
                <span matPrefix class="booking-organization-contact-search-icon">
                  <app-booking-icons [iconName]="'search-icon'"></app-booking-icons>
                </span>
                <input matInput placeholder="Search..." (input)="onContactSearch($event)" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <h4 class="page-header-sub-title">Select {{ selectedContactTitle }} contact or create new</h4>
      </div>
    </div>
    <div class="booking-organization-contacts-wrapper">
      <ng-container *ngIf="filteredContacts && filteredContacts.length > 0">
        <div class="booking-organization-contact-wrapper" *ngFor="let contact of filteredContacts">
          <div class="booking-organization-contact-left-content">
            <div class="organization-contact-profile-content">
              <span class="organization-contact-profile-name">{{ getInitialName(contact) }}</span>
              <span class="contact-country-flag-icon fi fi-{{contact.countryCode | lowercase}}"></span>
            </div>
            <div class="organization-contact-detail-wrapper">
              <div class="organization-contact-detail-information">
                <div class="organization-contact-name-wrapper" *ngIf="contact?.firstName && contact?.lastName">
                  <span class="organization-contact-name">
                    {{ contact.firstName + ' ' + contact.lastName }}
                  </span>
                </div>
                <div class="organization-contact-rating-wrapper">
                  <span class="organization-contact-rating-icon"></span>
                  <span class="organization-contact-rating-text">5.0</span>
                </div>
                <div class="organization-contact-type-wrapper">
                  <mat-chip-list>
                    <mat-chip class="organization-contact-type" color="primary"
                      *ngIf="!hideUnsupportedIcons">Shipper</mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div class="organization-contact-company-information" *ngIf="contact?.companyName">
                <span class="organization-contact-company-name">{{ contact.companyName }}</span>
              </div>
            </div>
          </div>
          <div class="booking-organization-contact-right-content">
            <div class="select-button-wrapper">
              <button mat-raised-button class="select-button button-spinner" (click)="onSelectContact(contact)"
                [disabled]="isSelectLoading">
                <span class="button-inner">
                  <span *ngIf="!(isSelectLoading && selectedContactId === contact.id)">Select</span>
                  <mat-spinner diameter="20" *ngIf="isSelectLoading && selectedContactId === contact.id"></mat-spinner>
                </span>
              </button>
            </div>
            <div class="booking-contact-menu-wrapper">
              <button mat-icon-button class="booking-contact-menu-button" [matMenuTriggerFor]="menu">
                <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onViewContact(contact)">
                  <span>View</span>
                </button>
                <button mat-menu-item (click)="onUpdateContact(contact)">
                  <span>Update</span>
                </button>
                <button mat-menu-item (click)="onDeleteContact(contact)">
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="booking-organization-contacts-bottom">
      <button mat-raised-button class="back-button" (click)="onCancel()">Cancel</button>
      <button mat-raised-button class="next-button" (click)="onOpenNewContact()">New Contact</button>
    </div>
  </div>
</div>
<div *ngIf="isOpenNewContact">
  <app-create-booking-organization-contact (backToContact)="onBackToContact()" [booking]="booking"
    (selectedContact)="onSelectedContact($event)" [contactType]="contactType"
    [organizationContact]="organizationContact" [organizationContactAction]="organizationContactAction">
  </app-create-booking-organization-contact>
</div>