import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { RollupDialogService } from '../../../../../rollup-dialog/rollup-dialog.service';
import { CommodityExclusion } from '../../../../../../shared/additional-services/additional-services.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../../mobile/rollup-dialog/rollup-dialog.model';

@Component({
  selector: 'app-excluded-commodities',
  templateUrl: './excluded-commodities.component.html',
  styleUrls: ['./excluded-commodities.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExcludedCommoditiesComponent implements OnInit {
  isExpanded: boolean[] = [];
  commodityExclusions: CommodityExclusion[];

  constructor(
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.commodityExclusions = data.commodityExclusions;
  }

  ngOnInit(): void {
    this.commodityExclusions.forEach((x, index) => {
      index === 0 ? this.isExpanded.push(true)
        : this.isExpanded.push(false);
    })
  }

  onClickPanel(state: boolean, panel: number): void {
    this.isExpanded[panel] = state;
  }

  onBack(): void {
    this.dialogRef.close();
  }
}
