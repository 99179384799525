import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { BookingFlowService } from '../../../../desktop/booking/booking-flow/booking-flow.service';
import { RateDetailsDialogComponent } from '../../../../mobile/quotes/quote-search-flow/rate-details/rate-details-dialog.component';
import { RollupDialogService } from '../../../../mobile/rollup-dialog/rollup-dialog.service';
import { BookingAdditionalServiceCharges, AdditionalServiceType } from '../../../../shared/additional-services/additional-services.model';
import { Booking, BookingStatusCode, BookingSummary, Commodity, Container, SummaryQuote } from '../../../../shared/bookings/bookings.model';
import { LocationTypeS4DIconPipe } from '../../../../shared/pipes/location-type-s4d-icon.pipe';
import { CarriageStatusType, ExtenderTrip, ExtenderTripRate, LocationType, ShipmentType, UnitMeasurementType, VolumeUnit, WeightUnit } from '../../../../shared/shared.model';
import { environment } from '../../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { BookingsService } from '../../../../shared/bookings/bookings.service';

@Component({
  selector: 'app-booking-flow-details',
  templateUrl: 'booking-flow-details.component.html',
  styleUrls: ["./booking-flow-details.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowDetailsComponent implements OnInit, OnChanges {
  @Input() bookingSummary: BookingSummary;
  @Input() isCreditOrganization: boolean;
  @Input() isSummary: boolean = false;
  @Input() isValid: boolean = false;
  @Input() isSubmitLoading: boolean = false;
  @Input() hideButtons: boolean = false;
  @Input() isManualExpand: boolean = false;
  @Input() isView: boolean = false;

  @Output() onSubmit = new EventEmitter<boolean>();
  @Output() onSubmitDraft = new EventEmitter<boolean>();

  @ViewChild('bottomSheet', { static: false }) bottomSheet: ElementRef;

  isExpanded: boolean = false;
  isAdditionalServicesEnabled: boolean;

  bookingId: string;
  carriageTripLegs = [];
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  quote: SummaryQuote;
  uniqueLocations = [];
  groupedContainers: Array<{ code: string; description: string; count: number }>;
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalChargeableWeightAmount = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  unitMeasurement = UnitMeasurementType[UnitMeasurementType.metric];
  ShipmentTypes = ShipmentType;
  isAgent: boolean = false;
  isLoading: boolean = false;
  startY: number;
  currentY: number;
  initialTranslateY: number = 72;
  defaultTranslateY: number = 72;
  clientHeight: number = 0;
  resizeObserver: ResizeObserver;
  isClicked: boolean = false;

  bookingStatusCodes = BookingStatusCode;

  get isTermsConfirmed(): boolean {
    return this.bookingSummary.isTermsAndConditionsConfirmed
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookingFlowService: BookingFlowService,
    private rollupDialogService: RollupDialogService,
    private locationTypeIconPipe: LocationTypeS4DIconPipe,
    public datePipe: DatePipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userInfoService: UserInfoService,
    private utilityService: UtilityService,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private renderer: Renderer2
  ) {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === this.bottomSheet.nativeElement && this.isClicked === false) {
          this.clientHeight = entry.target.clientHeight;
          this.setInitialSizes();
          this.renderer.setStyle(this.bottomSheet.nativeElement, 'transition', 'transform 0.3s ease');

          if (this.isExpanded) {
            this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(0%)`);
            this.initialTranslateY = 0;
          }
          else {
            this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(${this.defaultTranslateY}%)`);
            this.initialTranslateY = this.defaultTranslateY;
          }
        }
      }

      this.isClicked = false;
    });
  }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.bookingSummary.quote.trip);

    this.bookingFlowService.validState.subscribe(x => {
      this.isValid = x;
    });

    this.quote = this.bookingSummary.quote;

    this.extendUniqueLocations();
    this.isAdditionalServicesEnabled = this.getIsAdditionalServicesEnabled();
    this.isAgent = this.userInfoService.checkIsAgent();

    this.setInitialSizes();
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.bottomSheet.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.bottomSheet.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.bookingSummary) {
      this.getCargoDetails();
    }

    if (this.isManualExpand && changes?.bookingSummary) {
      this.isExpanded = true;
    }
  }

  onPanStart(event: any): void {
    this.startY = event.center.y;
    this.renderer.setStyle(this.bottomSheet.nativeElement, 'transition', 'none');
  }

  onPanEnd(event: any): void {
    this.setSizes();
    this.isExpanded = this.initialTranslateY === this.defaultTranslateY ? false : true;

    this.startY = this.currentY = null;
  }

  onPanMove(event: any): void {
    this.currentY = event.center.y;
    const deltaY = this.currentY - this.startY;
    const newTranslateY = Math.max(0, this.initialTranslateY + (deltaY / this.clientHeight) * 100);

    this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(${newTranslateY}%)`);

    if (newTranslateY < this.initialTranslateY - 3) {
      if (!this.isExpanded) {
        this.isExpanded = true;
      }
    } else if (newTranslateY > this.defaultTranslateY - 10) {
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    }
  }

  onClick(): void {
    this.isClicked = true
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(0%)`);
      this.initialTranslateY = 0;
    }
    else {
      this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(${this.defaultTranslateY}%)`);
      this.initialTranslateY = this.defaultTranslateY;
    }
  }

  onShowDetails(): void {
    const data = {
      quote: this.quote,
      containers: this.bookingSummary?.containers,
      shipmentType: this.bookingSummary?.shipmentTypeCode
    };

    const dialogRef = this.rollupDialogService.open(
      RateDetailsDialogComponent,
      data
    );
  }

  onReviewAndPay(): void {
    this.googleAnalyticsService.reviewAndPaySelected();

    this.router.navigate(["booking/summary/", this.bookingId]);
  }

  onPay(): void {
    this.googleAnalyticsService.payNowSelected();
    this.onSubmit.emit(true);
  }

  onSaveDraft(): void {
    this.googleAnalyticsService.saveAsDraftSelected();
    this.onSubmitDraft.emit(true);
  }

  getShipmentIcon(): string {
    switch (this.bookingSummary?.shipmentTypeCode) {
      case ShipmentType.FCL:
        return "s4d-fcl";
      case ShipmentType.LCL:
        return "s4d-lcl";
      case ShipmentType.AIR:
        return "s4d-ac";
      default:
        break;
    }
  }

  getLocationTypeIcon(type: LocationType, isFirst: boolean): string {
    return this.locationTypeIconPipe.transform(
      type !== null ? type : isFirst ? this.quote.trip.fromLocationTypeCode : this.quote.trip.toLocationTypeCode
    );
  }

  getAdditionalServiceTooltipText(model: BookingAdditionalServiceCharges): string | null {
    if (model.additionalServiceCharge.chargeCode === 'ISE') {
      return 'The Importer Security Filing (ISF) also referred to as 10+2, is a customs import requirement of the United States Customs and Border Protection (CBP) ; which requires containerized cargo information, for security purposes, to be transmitted to the agency at least 24 hours before goods are loaded onto an ocean vessel headed to the U.S';
    }

    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.preShipment:
        return '1 visit is sufficient for most pre-shipping inspections.';
      default:
        return null;
    }
  }

  getAdditionalServiceDescription(model: BookingAdditionalServiceCharges): string {
    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.preShipment:
        return 'per visit';
      default:
        return null;
    }
  }

  getCutOffDateTitle(quote: SummaryQuote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.bookingSummary?.shipmentTypeCode == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  getAdditionalServiceManuallyDescription(model: BookingAdditionalServiceCharges): string {
    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.customsManually:
      case AdditionalServiceType.insuranceManually:
        return `Our customer service team will contact
        you after booking to finalize this pricing
         and provide further details.`;
      default:
        return null;
    }
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  updateTermsAndConditions(isEnabled: boolean): void {
    this.isLoading = true;
    this.bookingsService
      .updateTermsAndConditions(this.bookingSummary.id, isEnabled)
      .subscribe((x: Booking) => {
        this.bookingSummary.isTermsAndConditionsConfirmed = x.isTermsAndConditionsConfirmed;
      },
        (error) => this.notificationService.error(error)
      ).add(() => this.isLoading = false);
  }

  isShowReverseDiscountPrice(charge: number): boolean {
    return this.quote.trip?.reverseDiscount > 0 && charge > 0;
  }

  getReverseDiscountPrice(charge: number): number {
    return charge + this.quote.trip.reverseDiscount;
  }


  private getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage, carriage: trip.preCarriageTripLeg
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage, carriage: trip.mainCarriageTripLeg
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage, carriage: trip.postCarriageTripLeg
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element?.shipmentRate?.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    return carriageTripLegs;
  }

  private getTotalFreightRate(rates: ExtenderTripRate[]): number {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  private extendUniqueLocations(): void {
    const seenLocations = new Map();

    this.carriageTripLegs.forEach(item => {
      item.carriage.legs.forEach(leg => {
        const fromKey = `${leg.fromLocationName}-${leg.fromLocationType}`;
        const toKey = `${leg.toLocationName}-${leg.toLocationType}`;

        const fromValue = {
          name: leg.fromLocationName,
          type: leg.fromLocationType,
          date: leg.departureDate
        };

        const toValue = {
          name: leg.toLocationName,
          type: leg.toLocationType,
          date: leg.arrivalDate
        };

        // Check and update for 'From' location
        if (!seenLocations.has(fromKey)) {
          this.uniqueLocations.push(fromValue);
          seenLocations.set(fromKey, fromValue);
        } else if (!seenLocations.get(fromKey).date && leg.departureDate) {
          // Update if the existing entry doesn't have a date but the new entry does
          const index = this.uniqueLocations.findIndex(l => l.name === leg.fromLocationName && l.type === leg.fromLocationType);
          this.uniqueLocations[index] = fromValue;
          seenLocations.set(fromKey, fromValue);
        }

        // Check and update for 'To' location
        if (!seenLocations.has(toKey)) {
          this.uniqueLocations.push(toValue);
          seenLocations.set(toKey, toValue);
        } else if (!seenLocations.get(toKey).date && leg.arrivalDate) {
          // Update if the existing entry doesn't have a date but the new entry does
          const index = this.uniqueLocations.findIndex(l => l.name === leg.toLocationName && l.type === leg.toLocationType);
          this.uniqueLocations[index] = toValue;
          seenLocations.set(toKey, toValue);
        }
      });
    });
  }

  private getIsAdditionalServicesEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isAdditionalServicesEnabled;
      case 'sb':
        return environment.sb.isAdditionalServicesEnabled;
      default:
        return environment.isAdditionalServicesEnabled;
    }
  }

  private getCargoDetails(): void {
    if (this.bookingSummary.shipmentTypeCode !== ShipmentType.FCL) {
      this.totalLoadCalculation();
    }
    else {
      this.groupedContainers = this.getEquipmentTypeSummary();
    }
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;
    this.grandTotalWeightAmount = 0;
    this.grandTotalVolumeAmount = 0;
    this.grandTotalChargeableWeightAmount = 0;

    this.bookingSummary.containers.forEach((container: Container) => {
      container.commodities.forEach((commodity: Commodity) => {
        packagesCount = commodity.numberOfPackages ? commodity.numberOfPackages : 0;
        totalVolumeAmount = commodity.volume ? commodity.volume : 0;
        totalWeightAmount = commodity.weight ? commodity.weight : 0;

        this.grandTotalVolumeUnit = commodity.volumeUnitCode;
        this.grandTotalWeightUnit = commodity.weightUnitCode;
        this.unitMeasurement = commodity.weightUnitCode === WeightUnit.LB ? UnitMeasurementType[UnitMeasurementType.imperial] : UnitMeasurementType[UnitMeasurementType.metric];

        this.grandTotalVolumeAmount += totalVolumeAmount;
        this.grandTotalWeightAmount += totalWeightAmount * commodity.numberOfPackages;

        this.grandTotalChargeableWeightAmount = this.calculateTotalChargeableWeight(this.grandTotalVolumeAmount);
        this.grandTotalChargeableWeightAmount = this.grandTotalWeightAmount > this.grandTotalChargeableWeightAmount ? this.grandTotalWeightAmount : this.grandTotalChargeableWeightAmount;
      });
    });
  }

  private getEquipmentTypeSummary(): Array<{ code: string; description: string; count: number }> {
    const summaryObj = this.bookingSummary.containers.reduce((accumulator, container) => {
      if (!accumulator[container.equipmentTypeCode]) {
        accumulator[container.equipmentTypeCode] = {
          code: container.equipmentTypeCode,
          description: container.equipmentTypeDescription,
          count: 0
        };
      }

      accumulator[container.equipmentTypeCode].count += 1;
      return accumulator;
    }, {} as Record<string, { code: string; description: string; count: number }>);

    return Object.values(summaryObj);
  }

  private calculateTotalChargeableWeight(totalVolume: number): number {
    if (this.unitMeasurement === UnitMeasurementType[UnitMeasurementType.metric]) {
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }

  private setInitialSizes(): void {
    if (this.isSummary) {
      this.initialTranslateY = this.remToPercentage(17.25);
      this.defaultTranslateY = this.remToPercentage(17.25);
    }
    else if (this.isView) {
      if (this.quote?.trip?.reverseDiscount > 0) {
        this.initialTranslateY = this.remToPercentage(7);
        this.defaultTranslateY = this.remToPercentage(7);
      } else {
        this.initialTranslateY = this.remToPercentage(6);
        this.defaultTranslateY = this.remToPercentage(6);
      }
    }
    else {
      this.initialTranslateY = this.remToPercentage(13.5);
      this.defaultTranslateY = this.remToPercentage(13.5);
    }
  }

  private setSizes(): void {
    this.renderer.setStyle(this.bottomSheet.nativeElement, 'transition', 'transform 0.3s ease');
    const threshold = this.clientHeight / 10;

    if (this.currentY < this.startY - threshold) {
      this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(0%)`);
      this.initialTranslateY = 0;
    } else if (this.currentY > this.startY + threshold) {
      this.renderer.setStyle(this.bottomSheet.nativeElement, 'transform', `translateY(${this.defaultTranslateY}%)`);
      this.initialTranslateY = this.defaultTranslateY;
    } else {
      this.renderer.setStyle(this.bottomSheet.nativeElement, `transform`, `translateY(${this.initialTranslateY}%)`);
    }
  }

  private remToPercentage(value: number): number {
    const innerHeight = window.innerHeight;
    const remInPixels = value * parseFloat(getComputedStyle(document.documentElement).fontSize);
    return ((innerHeight - remInPixels) / innerHeight) * 100;
  }
}
