<mat-dialog-content tabindex="-1" class="additional-service-details-dialog-container-content" *ngIf="additionalRates">
  <div class="d-flex flex-column justify-content-between h-100 model-content-detailes-wrapper"
    *ngIf="additionalRates[0].supplier.toLowerCase() === vendorsCodes[vendorsCodes.clearit].toLowerCase()">
    <div class="additional-service-details-dialog-container">
      <div class="additional-service-container">
        <div class="row m-0 pb-4">
          <div class="col-12 px-0  title">
            <h2 class="additional-service-title">
              Customs Clearance Service
              {{ getCurrencyByChargeCode(customsChargeCodes.CCS) | currencyTextIcon }}{{
              getPriceByChargeCode(customsChargeCodes.CCS) | number:'1.2-2' }}
            </h2>
          </div>
          <div class="col-12 px-0 subtitle">
            The service handles customs declaration & importer security filing, which is required for commercial
            imports
            into the USA.
          </div>
        </div>
        <div class="mb-3">
          <div class="additional-service-feature-title mb-3">
            Included Features
          </div>
          <div class="d-flex align-items-start additional-service-feature-wrapper">
            <div class="additional-service-feature-action">
              <i class="panel-header-icon s4d-icon s4d-check success"></i>
            </div>
            <div class="additional-service-feature-fields mb-3">
              <div class="feature-title">
                Formal Custom Declaration to United States Customs & Border Patrol.
              </div>
              <div class="feature-sub-title-wrapper">
                <h5 class="subtitle-text">Includes up to 5 commodities & 1 invoice header.</h5>
                <h5 class="subtitle-text">$3.50 / additional commodity & $15.00 per invoice thereafter.</h5>
              </div>
            </div>
            <div class="feature-fees">
              Included
            </div>
          </div>
        </div>
        <div>
          <div class="additional-service-feature-title pb-3">
            Additional Features
          </div>
          <div class="align-items-start additional-service-feature-wrapper mb-3" *ngFor="let item of additonalFeatures">
            <div class="d-flex us-import-bond-wrapper">
              <div class="additional-service-feature-fields">
                <div class="feature-title-recommended-wrapper">
                  <h3 class="feature-title">{{ item.title }}</h3>
                  <div class="recommended-wrapper" *ngIf="item.groupName === clearItChargeCodeGroup.ISF"
                    [matTooltip]="recommendedTooltipText" matTooltipPosition="above" matTooltipClass="text-center">
                    <span class="recommended-text">recommended</span>
                  </div>
                </div>
                <div class="feature-sub-title">
                  {{ item.description }}
                </div>
              </div>
            </div>

            <div class="sub-features" *ngIf="item.options && item.options.length > 0">
              <div class="align-items-start additional-service-feature-wrapper" *ngFor="let subItem of item.options">
                <div class="d-flex w-100">
                  <div class="additional-service-feature-action">
                    <mat-radio-button [name]="item.groupName" color="primary" [checked]="subItem.checked"
                      [disabled]="subItem.disabled"
                      (change)="subAdditionalFeatureChanged($event, subItem, item)"></mat-radio-button>
                  </div>
                  <div class="additional-service-feature-fields">
                    <div class="feature-title">
                      {{ subItem.title }}
                    </div>
                    <div class="feature-sub-title">
                      {{ subItem.description }}
                    </div>
                  </div>
                  <div class="d-flex feature-fees">
                    {{ subItem.currency | currencyTextIcon }}{{ subItem.amount | number:'1.2-2' }}
                  </div>
                </div>
                <div *ngIf="subItem.chargeCode === customsChargeCodes.SGB && subItem.checked === true"
                  class="shipment-value-wrapper">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput type="number" (input)="onChangeShipmentValue($event)"
                      placeholder="Enter shipment value" min="1" appPreventNonNumeric>
                  </mat-form-field>
                  <mat-spinner class="single-entry-bound-shipment-loader" diameter="25"
                    *ngIf="singleEntryBoundShipmentLoader"></mat-spinner>
                  <span class="dollar-sign" *ngIf="!singleEntryBoundShipmentLoader">$</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-info">
          Service is handled by our trusted custom broker partner ClearIT.​<br>
          The actual duties will be determined by US authorities at cargo clearance and will be paid directly by you
          to the custom broker/US authorities.
        </div>
      </div>
    </div>
    <div class="row dialog-footer">
      <button mat-button class="dialog-button-close col-4" (click)="onClose()">Close</button>
      <button mat-raised-button [disabled]="isConfirmDisable"
        class="dialog-button col-4 confirm-button-wrapper button-spinner" (click)="onSubmit()">
        <span [ngClass]="{
            'button-inner': isLoading
          }">Confirm</span>
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </button>
    </div>
  </div>

</mat-dialog-content>