<div class="dashboard-header" id="dashboard-header">
  <div class="container">
    <div class="row dashboard-header-row">
      <div class="col dashboard-header-left">
        <h1 class="mt-2" *ngIf="!isOrdersGridViewMode || isCreditLine">
          {{ title }}
        </h1>
      </div>
      <div class="col-3 dashboard-header-right" *ngIf="isShow()">
        <div class="dashboard-search-box">
          <mat-form-field [floatLabel]="'never'"
            matTooltip="Search among currently displayed orders to display those that your search matches To and From locations, Shipper name, Shipper company, Order Ref. Id and Order status">
            <span matPrefix class="dashboard-search-icon">
              <app-booking-icons [iconName]="'search-icon'"></app-booking-icons>
            </span>
            <input matInput placeholder="Search..." (input)="onSearchText($event)" />
          </mat-form-field>
        </div>
        <div class="dashboard-filter-box">
          <div *ngIf="isFilterApplied" class="filter-badge-icon">
            <span class="filtertooltp">Filter is selected</span>
          </div>
          <button mat-icon-button class="dashboard-filter-button" [matMenuTriggerFor]="beforeMenu">
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <div class="dashboard-filter-container" (click)="onMenuClick($event)">
                <div class="dashboard-filter-header">
                  <span class="dashboard-filter-header-text">Filter</span>
                </div>
                <div class="dashboard-filter-content-container">
                  <div class="dashboard-filter-content">
                    <div *ngIf="!isFinance" class="dashboard-filter-content-header">
                      <span class="dashboard-filter-content-header-text">Order status</span>
                    </div>
                    <div *ngIf="!isFinance" class="dashboard-filter-content-wrapper">
                      <div class="dashboard-filter-order-status-wrapper"
                        *ngFor="let orderStatus of orderStatuses; let i = index">
                        <span class="dashboard-filter-order-status-text">
                          <mat-checkbox class="dashboard-filter-order-status-checkbox" [value]="orderStatus.value"
                            [(ngModel)]="orderStatus.isChecked" [checked]="orderStatus.allComplete" color="primary"
                            (change)="setAll($event.checked,orderStatus)" [indeterminate]="someComplete(orderStatus)">
                            {{ orderStatus.statusName }}
                          </mat-checkbox>
                        </span>
                        <span class="dashboard-filter-sub-order-status-text"
                          *ngIf="orderStatus.isChild && orderStatus.childStatuses.length > 0">
                          <ul>
                            <li *ngFor="let childStatus of orderStatus.childStatuses;let j = index">
                              <mat-checkbox class="dashboard-filter-sub-order-status-checkbox"
                                [value]="childStatus.value" [checked]="childStatus.isChecked"
                                [(ngModel)]="childStatus.isChecked" (ngModelChange)="updateAllComplete(orderStatus)">
                                {{ childStatus.statusName }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                    <div [formGroup]="filterForm">
                      <div class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Origin</mat-label>
                            <div class="row h-100">
                              <div class="col d-flex align-items-end">
                                <input matInput type="text" formControlName="fromCity" />
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Destination</mat-label>
                            <div class="row h-100">
                              <div class="col d-flex align-items-end">
                                <input matInput type="text" formControlName="toCity" />
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                      <div *ngIf="!isFinance" class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Last Updated Date</mat-label>
                            <div class="row">
                              <div class="col d-flex align-items-end">
                                <input matInput formControlName="fromLastUpdateDate" [matDatepicker]="picker"
                                  placeholder="mm/dd/yyyy" />
                              </div>
                              <div class="col-auto px-0">
                                <mat-datepicker-toggle [for]="picker">
                                  <i matDatepickerToggleIcon class="custom-calendar-icon s4d-icon s4d-calendar"></i>
                                </mat-datepicker-toggle>
                              </div>
                            </div>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <mat-label>BOL/Order Number</mat-label>
                            <div class="row h-100">
                              <div class="col d-flex align-items-end">
                                <input matInput type="text" formControlName="bolOrderNumber" />
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Master Bill Of Lading</mat-label>
                            <div class="row h-100">
                              <div class="col d-flex align-items-end">
                                <input matInput type="text" formControlName="masterBillOfLading" />
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                      <div *ngIf="isFinance" class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field"><mat-form-field class="w-100"
                            appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Actual Time Of Departure </mat-label>
                            <div class="row">
                              <div class="col d-flex align-items-end">
                                <input matInput formControlName="atd" [matDatepicker]="atdPicker"
                                  placeholder="mm/dd/yyyy" />
                              </div>
                              <div class="col-auto px-0">
                                <mat-datepicker-toggle [for]="atdPicker">
                                  <i matDatepickerToggleIcon class="custom-calendar-icon s4d-icon s4d-calendar"></i>
                                </mat-datepicker-toggle>
                              </div>
                            </div>
                            <mat-datepicker #atdPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div *ngIf="isFinance" class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field"><mat-form-field class="w-100"
                            appearance="fill" [floatLabel]="'auto'">
                            <mat-label>Due Payment Date</mat-label>
                            <div class="row">
                              <div class="col d-flex align-items-end">
                                <input matInput formControlName="duePaymentDate" [matDatepicker]="duePaymentDatePicker"
                                  placeholder="mm/dd/yyyy" />
                              </div>
                              <div class="col-auto px-0">
                                <mat-datepicker-toggle [for]="duePaymentDatePicker">
                                  <i matDatepickerToggleIcon class="custom-calendar-icon s4d-icon s4d-calendar"></i>
                                </mat-datepicker-toggle>
                              </div>
                            </div>
                            <mat-datepicker #duePaymentDatePicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div *ngIf="isFinance" class="dashboard-filter-content-field-wrapper">
                        <div class="dashboard-filter-content-field search-field">
                          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                            <div class="row h-100">
                              <div class="col d-flex align-items-center"> <mat-select placeholder="Paid status"
                                  formControlName="bookingPaidStatusCode">
                                  <mat-option *ngFor="let paidStatusCode of bookingPaidStatusCodes"
                                    [value]="paidStatusCode.value">
                                    {{ paidStatusCode.name | titlecase }}
                                  </mat-option>
                                </mat-select>
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dashboard-filter-footer">
                  <div class="dashboard-filter-footer-wrapper">
                    <button mat-raised-button class="back-button" (click)="clearFilter()"
                      [disabled]="!isFilterApplied">Clear</button>
                    <button mat-raised-button class="next-button" (click)="applyFilter()">Apply</button>
                  </div>
                </div>
              </div>
            </mat-menu>
            <app-booking-icons [iconName]="'filter-icon'"></app-booking-icons>
          </button>
        </div>
        <div class="dashboard-filter-box" *ngIf="!isFinance && !isCreditLine">
          <button mat-icon-button class="dashboard-filter-button change-view" matTooltip="{{ordersViewModeTooltip}}"
            (click)="changeOrdersViewMode()">
            <mat-icon class="order-view-mode-icon">{{ordersViewModeIcon}}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>