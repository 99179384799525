<div class="excluded-commodities-wrapper">
    <div class="excluded-commodities-content">
        <div class="excluded-commodities-title">
            List of excluded Commodities
        </div>
        <mat-accordion>
            <div class="w-100 accordion-main-wrapper"
                *ngFor="let commodityExclusion of commodityExclusions; let i = index">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                    [expanded]="isExpanded[i]"
                    (opened)="onClickPanel(true, i)"
                    (closed)="onClickPanel(false, i)" hideToggle>
                    <mat-expansion-panel-header class="panel-header">
                        <mat-panel-title class="panel-title">
                            <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                                <div class="accordion-title d-flex align-items-center p-0">{{ commodityExclusion.title }}</div>
                                <div>
                                    <span class="panel-header-icon-wrapper">
                                        <i *ngIf="!this.isExpanded[i]"
                                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                                        <i *ngIf="isExpanded[i]"
                                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                                    </span>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="panel-content">
                        <span>
                            {{ commodityExclusion.description }}
                        </span>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>

    <div class="excluded-commodities-footer">
        <button mat-raised-button class="excluded-commodities-footer-back-button" (click)="onBack()">
            <span class="button-inner-text">Back</span>
        </button>
    </div>
</div>