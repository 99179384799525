import { TeamMemberSettings } from '../../../desktop/manage-organization/manage-organization.model';
import { AccessRoleType, DepartureDateSettings } from '../../shared.model';

export class UserInfo {
  fullName: string;
  acronymName: string;
  accessRoles: AccessRoleType[];
  acceptedOrganizations: UserInfoOrganization[];
  userId: string;
  userSettings: TeamMemberSettings;
  teamMemberId: string; 
  phoneNumber: string;
  departureDateSettings: DepartureDateSettings;

  static createDefault(): UserInfo {
    return {
      accessRoles: null,
      fullName: '',
      acronymName: '',
      acceptedOrganizations: null,
      userId: '',
      userSettings: null,
      teamMemberId: '',
      phoneNumber: '',
      departureDateSettings: null
    };
  }

  static createDesktopDefault() {
    const settings = this.createDefault();
    return settings;
  }

  static complete(settings: UserInfo): UserInfo {
    if (!settings) {
      settings = this.createDefault();
    }

    const defaultSettings = this.createDefault();
    Object.keys(defaultSettings).forEach(x => {
      settings[x] = settings[x] || defaultSettings[x];
    });

    return settings;
  }
}

export class UserInfoOrganization {
  id: string;
  name: string;
}
