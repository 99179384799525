import { AfterViewInit, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from "@ship4wd/ngx-common";
import { CreditLine, CreditLineStatusCode } from "../../shared/payments/payments.model";
import { PaymentsService } from "../../shared/payments/payments.service";
import { AppSettingsService } from "../../shared/services/app-settings/app-settings.service";
import { environment } from "../../../environments/environment";
import { LayoutService } from "../layout/layout.service";
import { LayoutMode } from "../layout/layout.model";

@Component({
  selector: "app-credit-line",
  templateUrl: "./credit-line.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CreditLineComponent implements OnInit, AfterViewInit {
  isLoading = true;
  organizationId: string = null;
  creditLine: CreditLine = null;

  isMobileDashboardEnabled: boolean;
  isCreditLineNew: boolean = false;
  isCreditLineRejected: boolean = false;
  isCreditLineApproved: boolean = false;
  isCreditLinePreapproved: boolean = false;
  isCreditLineRequested: boolean = false;
  isCreditLine = true;

  get isOrganizationLoaded(): boolean {
    return this.organizationId != null;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public settingsService: AppSettingsService,
    private paymentsService: PaymentsService,
    private layoutService: LayoutService,
    private notificationService: NotificationService
  ) {
    this.isMobileDashboardEnabled = this.getIsMobileDasboardEnabled();
  }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.getCreditLine();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.USER);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
    }, 0);
  }

  private getCreditLine(): void {
    if (this.organizationId) {
      this.isLoading = true;
      this.paymentsService
        .getCreditLineByOrganization(this.organizationId)
        .subscribe(
          (data: CreditLine) => {
            this.creditLine = data;
            this.setCreditLineStatus();

            if (this.mainCreditLineUrl() && this.isCreditLinePreapproved) {
              this.router.navigate(['/creditLine/preapproved']);
            }
            else {
              this.isLoading = false;
            }
          },
          (error) => {
            this.notificationService.error(error);
            this.isLoading = false
          }
        );
    }
  }

  private setCreditLineStatus(): void {
    switch (this.creditLine.statusId) {
      case CreditLineStatusCode.new:
        this.isCreditLineNew = true;
        break;
      case CreditLineStatusCode.rejected:
        this.isCreditLineRejected = true;
        break;
      case CreditLineStatusCode.approved:
        this.isCreditLineApproved = true;
        break;
      case CreditLineStatusCode.preApproved:
        this.isCreditLinePreapproved = true;
        break;
      case CreditLineStatusCode.requested:
        this.isCreditLineRequested = true;
        break;
      case CreditLineStatusCode.increaseRequested:
        this.isCreditLineApproved = true;
        break;
      default:
        break;
    }
  }

  private mainCreditLineUrl(): boolean {
    const segments = this.route.snapshot.url.map(segment => segment.path);

    if (segments.length === 1 && segments[0] === 'creditLine') {
      return true;
    } else {
      return false;
    }
  }

  private getIsMobileDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isMobileDashboardEnabled;
      case "sb":
        return environment.sb.isMobileDashboardEnabled;
      default:
        return environment.isMobileDashboardEnabled;
    }
  }
}
