import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";
import { environment } from '../../../environments/environment.prod';
import { Quote, QuotesQuery } from "../../desktop/quotes/quotes.model";
import { AddressTypeCode, CurrencyCode, FourtySeasPaymentResponse, LocationType, ShipmentType, ShipperType, TripsSearchLocationAnalyticsViewModel } from "../shared.model";
import { Booking, BookingSummary, Container } from "../bookings/bookings.model";
import { AppSettings } from "../features/app-settings/app-settings.model";
import { UserProfile } from "../accounts/accounts.model";
import { UtilityService } from "../helper/utility.service";
import { ErrorFunnelBooking } from "./google-analytics.constants";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  environmentsService: EnvironmentsService;
  environmentName: string;
  notFoundValue: string = 'not found'

  constructor(
    private datePipe: DatePipe,
    private utilityService: UtilityService) {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
  }

  loadHeadScript(): void {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.getGoogleAnalyticsId()}');
    `;
    document.head.appendChild(script);
  }

  loadBodyScript(): void {
    var noscript = document.createElement('noscript')
    var iframe = document.createElement('iframe')
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this.getGoogleAnalyticsId()}`)
    iframe.setAttribute("height", "0")
    iframe.setAttribute("width", "0")
    iframe.setAttribute("style", "display:none;visibility:hidden")
    noscript.appendChild(iframe)
    document.body.appendChild(noscript)
  }

  // User Properties
  userProperties(appSettings: AppSettings, user: UserProfile): void {
    const dataLayer: any = {
      event: "user-properties",
      user_id: user?.id, // The unique identifier for the user
      company_id: appSettings.organizationId, // The unique identifier for the organization.
      company_name: appSettings.organizationName, // The name of the company.
      company_country: appSettings.countryCode, // The country where the company is located.
      date_registration: this.convertDateTimeToGoogleDate(user.createTimeUtc), // new parameter the date of the registration user did.
      user_type: appSettings.isCreditOrganization ? "with contract" : "no contract", // new parameter the type of the user
      domain_mail: user.email  // new parameter for the domain mail user login
    };

    this.pushDataLayer(dataLayer);
  }

  // Quotes
  searchQuotes(query: QuotesQuery, isFirstSearch: boolean): void {
    const dataLayer: any = {
      event: "quote-properties",
      origin_City: query.from.cityName, // city where the shipment originates from.
      destination_City: query.to.cityName, // city where the shipment is intended to be delivered.
      origin_Country: query.from.countryName, // country where the shipment originates from.
      destination_Country: query.to.countryName, // country where the shipment is intended to be delivered.
      departure_date: this.convertDateTimeToGoogleDate(query.fromDate), // Time of departure date
      quotesearchId: query.searchId, // uniqe quoteSearchId
      shipper_type: this.getShipperTypeName(query.shipperType), // Describe if I'm importing / exporting
      pickup_type: this.getLocationTypeName(query.from.locationType), // The mode of shipment is intended to be delivered
      delivery_type: this.getLocationTypeName(query.to.locationType), // The mode of shipment origin
      type_of_shipment: this.getShipmentTypeName(query.shipmentType), // The mode of shipment
      first_search: isFirstSearch
    };

    this.pushDataLayer(dataLayer);
  }

  quotesResults(partialSchedule: string, partialDoor: string, quotesCount: number): void {
    const dataLayer: any = {
      event: "price-quotation",
      partial_schedule: partialSchedule, // The type of price quotation available in case of schedule (Found, Partial, Not Found).
      partial_door: partialDoor, // The type of price quotation available in case of shipping type (Found, Partial, Not Found).
      value: quotesCount, // count of quotes are offered
      element_name: "quotes results", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  shipperTypeChanged(shipperType: ShipperType): void {
    const dataLayer: any = {
      event: "shipper-Type-change",
      field_name: this.getShipperTypeName(shipperType), // the text in field
      element_name: "quotes results" // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  quoteBooked(quote: Quote, quotesQuery: QuotesQuery): void {
    const quoteTimeToArrivalDays = this.getDaysGap(quote.departureDate, quote.arrivalDate);
    const intervalTimeDays = this.getDaysGap(quotesQuery.fromDate, quote.departureDate);

    const dataLayer: any = {
      event: "select-quote-button",
      button_name: "select", // the text in field
      departure_date: this.convertDateTimeToGoogleDate(quote.departureDate), // "23.11.2" Time of departure date
      interval_time: intervalTimeDays != null ? `${intervalTimeDays} days` : this.notFoundValue,// Calculates the day difference
      arrival_date: this.convertDateTimeToGoogleDate(quote.arrivalDate), // "30.11.2" Time of Pick up date
      type_of_shipment: this.getShipmentTypeName(quotesQuery.shipmentType), //"fcl" The mode of shipment
      quote_time_to_arrival: quoteTimeToArrivalDays != null ? `${quoteTimeToArrivalDays}d` : this.notFoundValue, // The mode of shipment
      quote_price: quote.accumulatePrice.toString(), // The mode of shipment
      element_name: "quotes results" // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  // Bookings
  bookingProperties(booking: BookingSummary): void {
    const quoteTimeToArrivalDays = this.getDaysGap(booking.etd, booking.eta);

    const dataLayer: any = {
      event: "Booking-Properties",
      type_of_Shipment: this.getShipmentTypeName(booking.shipmentTypeCode), //"lcl" The mode of shipment
      origin_City: booking.fromBookingSearch.cityName, // city where the shipment originates from.
      destination_City: booking.toBookingSearch.cityName, // city where the shipment is intended to be delivered.
      origin_Country: booking.fromBookingSearch.countryName, // country where the shipment originates from.
      destination_Country: booking.toBookingSearch.countryName, // country where the shipment is intended to be delivered.
      departure_date: this.convertDateTimeToGoogleDate(booking.etd), // Time of departure date
      arrival_date: this.convertDateTimeToGoogleDate(booking.eta), // Time of Pick up date
      collection_date: this.convertDateTimeToGoogleDate(booking.containers[0]?.collectionDate), // The date on which the cargo is collected from the customer.
      quote_time_to_arrival: quoteTimeToArrivalDays != null ? `${quoteTimeToArrivalDays} days` : this.notFoundValue, // time from departure to arrival
      quote_price: booking.totalPrice.toString(), // quote price
      delivery_type: this.getLocationTypeName(booking.toBookingSearch.locationType), // The mode of shipment origin
      pickup_type: this.getLocationTypeName(booking.fromBookingSearch.locationType), // The mode of shipment is intended to be delivered
      shipper_type: this.getShipperTypeName(booking.shipperTypeCode), // Describe if I'm importing / exporting
      quotesearchId: booking.quoteSearchId, // uniqe quoteSearchId
      bookingId: booking?.id
    };

    this.pushDataLayer(dataLayer);
  }

  bookingSelectItem(booking: BookingSummary): void {
    this.pushDataLayer({ ecommerce: null });  // Clear the previous ecommerce object.

    const dataLayer: any = {
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_id: booking.id, // The service code (shipment type) or additional services code.
            item_name: this.getShipmentTypeName(booking.shipmentTypeCode), // The shipment type or additional services type.
            affiliation: " null", // Future parameter
            coupon: "null", // Future parameter
            discount: 0, // The discount amount within the total price.
            index: 0, // The position number of the selected offer in the list.
            item_brand: this.getLocationTypeName(booking.fromBookingSearch.locationType), // "PICKUP_ TYPE" The mode of shipment is intended to be delivered
            item_category: booking.fromBookingSearch.cityName, // The origin city
            item_category2: booking.toBookingSearch.cityName, // The destination city
            item_category3: booking.fromBookingSearch.countryName, // The origin country
            item_category4: booking.toBookingSearch.countryName, // The destination country
            item_category5: "null", // Future parameter
            item_variant: this.getLocationTypeName(booking.toBookingSearch.locationType), // "DELIVERY_TYPE" The mode of shipment origin
            price: booking.totalPrice, // The order price.
            quantity: 1 // The quantity or amount.
          }
        ]
      }
    };

    this.pushDataLayer(dataLayer);
  }

  bookingBeginCheckout(booking: BookingSummary): void {
    this.pushDataLayer({ ecommerce: null });  // Clear the previous ecommerce object.

    const dataLayer: any = {
      event: "begin_checkout",
      ecommerce: {
        currency: this.getCurrencyCodeName(booking.quote.currency),
        value: booking.totalPrice,
        items: [
          {
            item_id: booking.id, // The service code (shipment type) or additional services code.
            item_name: this.getShipmentTypeName(booking.shipmentTypeCode), // The shipment type or additional services type.
            affiliation: "null", // Future parameter
            coupon: "null", // Future parameter
            discount: 0, // The discount amount within the total price.
            index: 0, // The position number of the selected offer in the list.
            item_brand: this.getLocationTypeName(booking.fromBookingSearch.locationType), // "PICKUP_ TYPE" The mode of shipment is intended to be delivered
            item_category: booking.fromBookingSearch.cityName, // The origin city
            item_category2: booking.toBookingSearch.cityName, // The destination city
            item_category3: booking.fromBookingSearch.countryName, // The origin country
            item_category4: booking.toBookingSearch.countryName, // The destination country
            item_category5: "null", // Future parameter
            item_variant: this.getLocationTypeName(booking.toBookingSearch.locationType), // // "DELIVERY_TYPE" The mode of shipment origin
            price: booking.totalPrice, // The order price.
            quantity: 1 // The quantity or amount.
          }
        ]
      }
    };

    this.pushDataLayer(dataLayer);
  }

  startCheckOutStepCargoDetail(): void {
    const dataLayer: any = {
      event: "start-check-out-step",
      action_name: "cargo detail", // the text in field
      element_name: "start check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  cargoDetailValueChanged(fieldName: string, index: number): void {
    const dataLayer: any = {
      event: "click-field-check-out-step",
      action_name: "cargo detail", // the text in field
      element_name: "check out step", // name of the element interact with
      value: index, // the index of the "load"
      field_name: fieldName // the text in field
    };

    this.pushDataLayer(dataLayer);
  }

  cargoDetailsTouched(fieldName: string, index: number): void {
    const dataLayer: any = {
      event: "touch-field-check-out-step",
      action_name: "cargo detail", // the text in field
      element_name: "check out step", // name of the element interact with
      value: index, // the index of the "load"
      field_name: fieldName // the text in field
    };

    this.pushDataLayer(dataLayer);
  }

  selectedTotalWeightAndVolumeError(): void {
    const dataLayer: any = {
      event: "error-complete-check-out-step",
      action_name: "cargo detail", // the text in field
      element_name: "complete check out step", // name of the element interact with
      error_name: "Selected total weight and volume can not be larger than selected equipment sizes.", // massage of the problem show to uesr.
    };

    this.pushDataLayer(dataLayer);
  }

  completeCheckOutStepCargoDetail(collectionDate: string): void {
    const dataLayer: any = {
      event: "complete-check-out-step",
      action_name: "cargo detail", // the text in field
      collection_date: this.convertDateTimeToGoogleDate(collectionDate), // name of the element interact with
      element_name: "complete check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  startCheckOutStepContactDetail(): void {
    const dataLayer: any = {
      event: "start-check-out-step",
      action_name: "Contact Details", // the text in field
      element_name: "start check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  completeCheckOutStepContactDetail(): void {
    const dataLayer: any = {
      event: "complete-check-out-step",
      action_name: "Contact Details", // the text in field
      element_name: "complete check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  reviewAndPaySelected(): void {
    const dataLayer: any = {
      event: "check-out-click",
      button_name: "review and pay", // the text in field
      element_name: "check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  payNowSelected(): void {
    const dataLayer: any = {
      event: "check-out-click",
      button_name: "Pay now", // the text in field
      element_name: "check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  saveAsDraftSelected(): void {
    const dataLayer: any = {
      event: "check-out-click",
      button_name: "Save as draft", // the text in field
      element_name: "check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  submitBookingFailed(): void {
    const dataLayer: any = {
      event: "check-out-error-click",
      button_name: "Pay now", // the text in field
      error_name: " An error occurred while submitting your booking, try again", // massage of the problem show to user.
      element_name: "popup error check out step", // name of the element interact with
    };

    this.pushDataLayer(dataLayer);
  }

  // Payment
  addPaymentInfo(data: FourtySeasPaymentResponse, booking: Booking): void {
    this.pushDataLayer({ ecommerce: null });  // Clear the previous ecommerce object.

    const dataLayer: any = {
      event: "add_payment_info",
      ecommerce: {
        currency: data.currency,
        value: data.result.total,
        payment_type: data.result.paymentOption.paymentMethod,
        items: [
          {
            item_id: booking.id, // The service code (shipment type) or additional services code.
            item_name: this.getShipmentTypeName(booking.shipmentTypeCode), // The shipment type or additional services type.
            affiliation: " null", // Future parameter
            coupon: " null", // Future parameter
            discount: 0, // The discount amount within the total price.
            index: 0, // The position number of the selected offer in the list.
            item_brand: this.getLocationTypeName(booking.fromBookingSearch.locationType), // "PICKUP_ TYPE" The mode of shipment is intended to be delivered
            item_category: booking.fromBookingSearch.cityName, // The origin city
            item_category2: booking.toBookingSearch.cityName, // The destination city
            item_category3: booking.fromBookingSearch.countryName, // The origin country
            item_category4: booking.toBookingSearch.countryName, // The destination country
            item_category5: "null", // Future parameter
            item_variant: this.getLocationTypeName(booking.toBookingSearch.locationType), // // "DELIVERY_TYPE" The mode of shipment origin
            price: data.result.total, // The order price.
            quantity: 1 // The quantity or amount.
          }
        ]
      }
    };

    this.pushDataLayer(dataLayer);
  }

  beginCheckout(data: FourtySeasPaymentResponse, booking: Booking): void {
    this.pushDataLayer({ ecommerce: null });  // Clear the previous ecommerce object.

    const dataLayer: any = {
      event: "purchase",
      ecommerce: {
        transaction_id: data.id,
        currency: data.currency,
        value: data.result.total,
        items: [
          {
            item_id: booking.id, // The service code (shipment type) or additional services code.
            item_name: this.getShipmentTypeName(booking.shipmentTypeCode), // The shipment type or additional services type.
            affiliation: " null", // Future parameter
            coupon: " null", // Future parameter
            discount: 0, // The discount amount within the total price.
            index: 0, // The position number of the selected offer in the list.
            item_brand: this.getLocationTypeName(booking.fromBookingSearch.locationType), // "PICKUP_ TYPE" The mode of shipment is intended to be delivered
            item_category: booking.fromBookingSearch.cityName, // The origin city
            item_category2: booking.toBookingSearch.cityName, // The destination city
            item_category3: booking.fromBookingSearch.countryName, // The origin country
            item_category4: booking.toBookingSearch.countryName, // The destination country
            item_category5: "null", // Future parameter
            item_variant: this.getLocationTypeName(booking.toBookingSearch.locationType), // // "DELIVERY_TYPE" The mode of shipment origin
            price: data.result.total, // The order price.
            quantity: 1 // The quantity or amount.
          }
        ]
      }
    };

    this.pushDataLayer(dataLayer);
  }

  shipmentTypeStart(shipmentType: ShipmentType): void {
    const dataLayer: any = {
      event: "search_engagement",
      step_no: 1, // The no of step to search
      step_name: "shipment type", // the step name
      field_name: "shipment type", // The field name user interacts
      type_of_shipment: this.getShipmentTypeName(shipmentType), // The mode of shipment choose – FCL/LCL/AIR
      action_name: `start step`,
      element_name: "main search quotes" // name of the element interact with
    }

    this.pushDataLayer(dataLayer);
  }

  shipmentTypeComplete(shipmentType: ShipmentType): void {
    const dataLayer: any = {
      event: "search_engagement",
      step_no: 1, // The no of step to search
      step_name: "shipment type", // the step name
      field_name: "shipment type", // The field name user interacts
      type_of_shipment: this.getShipmentTypeName(shipmentType), // The mode of shipment choose – FCL/LCL/AIR
      action_name: `complete step`,
      element_name: "main search quotes" // name of the element interact with
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationLocationTouched(shipmentType: ShipmentType, stepNo: number, stepName: string): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(shipmentType)}`,
      step_no: stepNo, // The no of step to search
      step_name: stepName, // the step name in text
      field_name: "",// The field name user interact
      action_name: "start step",
      type_of_shipment: this.getShipmentTypeName(shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes",// name of the element interact with
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationRecentOriginsStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "use your recent origins", // The field name user interacts
      action_name: `start choose from list`, // according to action user take
      type_of_shipment: this.getShipmentTypeName(address?.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes",// name of the element interact with
      origin_City: address?.originCity, // value when chosen.
      origin_Country: address?.originCountry,// value when  chosen
      pickup_type: this.getAddressTypeName(address?.pickupType) // value when  chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationRecentOriginsComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "use your recent origins", // The field name user interacts
      action_name: `complete choose from list`, // according to action user take
      type_of_shipment: this.getShipmentTypeName(address?.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes",// name of the element interact with
      origin_City: address?.originCity, // value when chosen.
      origin_Country: address?.originCountry,// value when  chosen
      pickup_type: this.getAddressTypeName(address?.pickupType) // value when  chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationRecentDestinationsStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "use your recent destinations", // The field name user interacts
      action_name: `start choose from list`, // according to action user take
      type_of_shipment: this.getShipmentTypeName(address?.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes",// name of the element interact with
      destination_City: address?.destinationCity, // value when chosen.
      destination_Country: address?.destinationCountry,// value when  chosen
      delivery_type: this.getAddressTypeName(address?.deliveryType), // value when  chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationRecentDestinationsComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "use your recent destinations", // The field name user interacts
      action_name: `complete choose from list`, // according to action user take
      type_of_shipment: this.getShipmentTypeName(address?.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes",// name of the element interact with
      destination_City: address?.destinationCity, // value when chosen.
      destination_Country: address?.destinationCountry,// value when  chosen
      delivery_type: this.getAddressTypeName(address?.deliveryType), // value when  chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressTypeStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select address type", // The field name user interacts
      action_name: `start choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType) // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressTypeComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address?.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select address type", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType) // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressTypeStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select address type", // The field name user interacts
      action_name: `start choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressTypeComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select address type", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginCountryStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: `start choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginCountryComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationCountryStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: `start choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationCountryComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressCodeStartTyping(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select city or port/airport city", // The field name user interacts
      action_name: `start typing`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressCodeSelect(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select city or port/airport city", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressCodeStartTyping(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: `start typing`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressCodeSelect(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: `complete choose from list`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: `complete step`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: `complete step`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressCountryError(address: TripsSearchLocationAnalyticsViewModel, error: string): void {
    const dataLayer: any = {
      event: `error_search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: address.actionName, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen,
      value: error // The value text of error
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressCountryError(address: TripsSearchLocationAnalyticsViewModel, error: string): void {
    const dataLayer: any = {
      event: `error_search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select country", // The field name user interacts
      action_name: address.actionName, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCity,// value chosen
      destination_City: address.destinationCity,
      value: error // The value text of error
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationOriginAddressError(address: TripsSearchLocationAnalyticsViewModel, error: string): void {
    const dataLayer: any = {
      event: `error_search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 2, // The no of step to search
      step_name: "where do you want to ship from?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: address.actionName, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen,
      value: error // The value text of error
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchLocationDestinationAddressError(address: TripsSearchLocationAnalyticsViewModel, error: string): void {
    const dataLayer: any = {
      event: `error_search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 3, // The no of step to search
      step_name: "where do you want to ship to?", // the step name in text
      field_name: "select city or  port/airport city", // The field name user interacts
      action_name: address.actionName, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      value: error // The value text of error
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "Cargo details", // the step name in text
      field_name: "", // The field name user interacts
      action_name: `start step`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsChurn(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "Cargo details", // the step name in text
      field_name: "confirm", // The field name user interacts
      action_name: `start churn`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsUseToggle(address: TripsSearchLocationAnalyticsViewModel, isPieceCalculation: boolean): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "cargo details", // the step name in text
      field_name: "toggle piece calculation",  // this is update for the element
      action_name: isPieceCalculation ? "toggle switch to by piece" : "toggle switch to by total", // this is update for the element
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "Cargo details", // the step name in text
      field_name: "confirm", // The field name user interacts
      action_name: `complete step`, // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsIsHazardousChecked(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "Cargo details", // the step name in text
      field_name: "checkbox dangerous goods", // The field name user interacts
      action_name: "check", // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchCargoDetailsIsHazardousUnchecked(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 4, // The no of step to search
      step_name: "Cargo details", // the step name in text
      field_name: "checkbox dangerous goods", // The field name user interacts
      action_name: "uncheck", // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchDepartureDateStart(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 5, // The no of step to search
      step_name: "When Cargo Is Ready For Collection?", // the step name in text
      field_name: "", // The field name user interacts
      action_name: "start step", // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
      departure_date: this.convertDateTimeToGoogleDate(address.departureDate),
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchDepartureDateComplete(address: TripsSearchLocationAnalyticsViewModel): void {
    const dataLayer: any = {
      event: `search_engagement_${this.getShipmentTypeName(address.shipmentType)}`,
      step_no: 5, // The no of step to search
      step_name: "When Cargo Is Ready For Collection?", // the step name in text
      field_name: "", // The field name user interacts
      action_name: "complete step", // according to action
      type_of_shipment: this.getShipmentTypeName(address.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
      element_name: "main search quotes", // name of the element interact with
      pickup_type: this.getAddressTypeName(address.pickupType), // value chosen
      origin_Country: address.originCountry,// value chosen
      origin_City: address.originCity, // value chosen
      delivery_type: this.getAddressTypeName(address.deliveryType), // value chosen
      destination_Country: address.destinationCountry,// value chosen
      destination_City: address.destinationCity,
      departure_date: this.convertDateTimeToGoogleDate(address.departureDate),
    }

    this.pushDataLayer(dataLayer);
  }

  newSearchViewQuotes(isFirstSearch: boolean, isRecentQuotesSearch: boolean = false): void {
    const dataLayer: any = {
      event: `search_engagement`,
      step_no: 6, // The no of step to search
      step_name: isRecentQuotesSearch ? "click from recent search" : "click to perform search", // the step name in text
      first_search: isFirstSearch
    }

    this.pushDataLayer(dataLayer);
  }

  createAccountStartTypingCompanyAddress(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'add organization', // the step name in text
      field_name: 'full address', // The field name user interact
      action_name: 'start step',
      element_name: 'social group'
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountChooseAddressType(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'add organization', // the step name in text
      field_name: 'full address', // The field name user interact
      action_name: 'complete choose from list',
      element_name: 'social group'
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountStartTypingCompanyName(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'add organization', // the step name in text
      field_name: 'company name', // The field name user interact
      action_name: 'start typing',
      element_name: 'social group'
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountChooseCompanyName(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'add organization', // the step name in text
      field_name: 'company name', // The field name user interact
      action_name: 'complete choose from list',
      element_name: 'social group'
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountAddOrganizationComplete(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'business registration complete-add organization', // the step name in text
      action_name: 'complete step',
      button_name: 'next', // value chosen.
      element_name: 'social group',
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountAddOrganizationCompleteError(): void {
    const dataLayer: any = {
      event: 'error_funnel_registration',
      step_no: 4, // The no of step to search
      step_name: 'business registration complete-add organization', // the step name in text
      action_name: 'complete step',
      button_name: 'next', // value chosen.
      element_name: 'social group',
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountOrganizationKycApproved(): void {
    const dataLayer: any = {
      event: 'funnel_registration',
      step_no: 5, // The no of step to search
      step_name: 'kyc approve', // the step name in text
    };

    this.pushDataLayer(dataLayer);
  }

  createAccountError(params: any): void {
    const dataLayer: any = {
      event: 'error_funnel_registration',
      step_no: params?.stepNo, // The no of step to search
      step_name: params?.stepName, // the step name in text
      field_name: params?.fieldName, // value chosen.
      element_name: params?.elementName,
    };

    this.pushDataLayer(dataLayer);
  }

  chooseShipmentTypeFromDashboardStart(shipmentType: ShipmentType): void {
    const dataLayer: any = {
      event: "search_engagement",
      step_no: 1, // The no of step to search
      step_name: "shipment type", // the step name
      field_name: "shipment type", // The field name user interacts
      type_of_Shipment: this.getShipmentTypeName(shipmentType), // The mode of shipment choose – FCL/LCL/AIR
      action_name: "start step",
      element_name: "dashboard" // name of the element interact with
    }

    this.pushDataLayer(dataLayer);
  }

  chooseShipmentTypeFromDashboardComplete(shipmentType: ShipmentType): void {
    const dataLayer: any = {
      event: "search_engagement",
      step_no: 1, // The no of step to search
      step_name: "shipment type", // the step name
      field_name: "shipment type", // The field name user interacts
      type_of_Shipment: this.getShipmentTypeName(shipmentType), // The mode of shipment choose – FCL/LCL/AIR
      action_name: "complete step",
      element_name: "dashboard" // name of the element interact with
    }

    this.pushDataLayer(dataLayer);
  }

  chooseLiveBookingAssistance(buttonText: string): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 1, // the no of step to search
      step_name: "click to assistance", // the step name
      button_name: buttonText // the name of button
    }

    this.pushDataLayer(dataLayer);
  }

  exposedLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 1.1, // the no of step to search
      step_name: "exposure popup", // the step name
    }

    this.pushDataLayer(dataLayer);
  }

  startTypingPhoneLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2, // the no of step to search
      step_name: "call with an expert", // the step name
      action_name: "start typing", //the name of action
      field_name: "phoneNumber", // the field name user interacts
    }

    this.pushDataLayer(dataLayer);
  }

  callFromLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2.1, // the no of step to search
      step_name: "call with an expert", // the step name
      action_name: "send phone", //the name of action
      button_name: "call arrow" // the name of button
    }

    this.pushDataLayer(dataLayer);
  }

  dispalyMessageBookingAssistanceWillCall(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 3, // the no of step to search
      step_name: "confirm booking assistant will call", // the step name
      action_name: "success call" //the name of action
    }

    this.pushDataLayer(dataLayer);
  }

  chatFromLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2, // the no of step to search
      step_name: "chat with an expert", // the step name
      action_name: "click on chat", //the name of action
    }

    this.pushDataLayer(dataLayer);
  }

  noNeedHelpFromLiveBookingAssistancePopup(actionName: string): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 0, // the no of step to search
      step_name: "call with an expert", // the step name
      action_name: actionName, //the name of action
      button_name: "X" // the name of button
    }

    this.pushDataLayer(dataLayer);
  }

  errorFromLiveBookingAssistancePopup(message: string): void {
    const dataLayer: any = {
      event: "error_popup_help_booking",
      step_no: 2.1, // the no of step to search
      step_name: "call with an expert", // the step name
      action_name: "send phone", //the name of action
      error_value: message // text of error message
    }

    this.pushDataLayer(dataLayer);
  }

  exposureLegalNamePopup(): void {
    const dataLayer: any = {
      event: "popup_booking_company_add_details",
      action_name: "exposure popup",
      element_name: "Add missing data"// value of title of the message in popup
    }

    this.pushDataLayer(dataLayer);
  }

  startTypingFieldLegalNamePopup(fieldName: string): void {
    const dataLayer: any = {
      event: "popup_booking_company_add_details",
      action_name: "start typing",
      field_name: fieldName // name of field
    }

    this.pushDataLayer(dataLayer);
  }

  submitLegalNamePopup(): void {
    const dataLayer: any = {
      event: "popup_booking_company_add_details",
      action_name: "click to submit",
      field_name: "submit" // the text show to user on button
    }

    this.pushDataLayer(dataLayer);
  }

  successfulAddedLegalNamePopup(): void {
    const dataLayer: any = {
      event: "popup_booking_company_add_details",
      action_name: "success company add details",
    }

    this.pushDataLayer(dataLayer);
  }

  errorLegalNamePopup(message: string, fieldName: string): void {
    const dataLayer: any = {
      event: "error_popup_booking_company_add_details",
      field_name: fieldName, // name of filed
      error_value: message.toLowerCase() // the error value text show to user
    }

    this.pushDataLayer(dataLayer);
  }

  errorFunnelBooking(error: ErrorFunnelBooking) {
    const dataLayer: any = {
      event: "error_funnel_booking",
      action_name: error.actionName,
      element_name: "check out step",
      error_value: error.errorValue, // The value text of error
      field_name: error.fieldName, // The field name error occurred
      type_of_Shipment: this.getShipmentTypeName(error.shipmentType), // The mode of shipment choosen – FCL/LCL/AIR
    }

    this.pushDataLayer(dataLayer);
  }

  whatsAppFromLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2, // the no of step to search
      step_name: "WhatsApp", // the step name
      action_name: "click on WhatsApp", //the name of action
    }

    this.pushDataLayer(dataLayer);
  }

  whatsAppScreenFromLiveBookingAssistancePopup(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2.1, // the no of step to search
      step_name: "screen WhatsApp", // the step name
      action_name: "show screen WhatsApp", //the name of action
    }

    this.pushDataLayer(dataLayer);
  }

  backToLiveBookingAssistancePopupFromWhatsApp(): void {
    const dataLayer: any = {
      event: "popup_help_booking",
      step_no: 2.1, // the no of step to search
      step_name: "screen WhatsApp", // the step name
      action_name: "close screen WhatsApp", //the name of action
      button_name: "X" // the name of button
    }

    this.pushDataLayer(dataLayer);
  }

  recentSearchShown(): void {
    const dataLayer: any = {
      event: "show recent search"
    }

    this.pushDataLayer(dataLayer);
  }

  recentSearchClick(): void {
    const dataLayer: any = {
      event: "click recent search"
    }

    this.pushDataLayer(dataLayer);
  }

  organizationsClick(buttonText: string): void {
    const dataLayer: any = {
      event: "organizations",
      button_name: buttonText // value chosen
    }

    this.pushDataLayer(dataLayer);
  }

  private pushDataLayer(data: any): void {
    const lowercasedData = this.convertStringsToLowerCase(data);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(lowercasedData);
  }

  private convertStringsToLowerCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(item => this.convertStringsToLowerCase(item));
    }

    if (obj && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        acc[key] = typeof value === 'string' ? value.toLowerCase() : this.convertStringsToLowerCase(value);
        return acc;
      }, {} as Record<string, any>);
    }

    return obj;
  }

  private convertDateTimeToGoogleDate(dateTime: string): string | null {
    if (!this.utilityService.isNotNullOrMinDateValue(dateTime)) {
      return this.notFoundValue;
    }

    const datetime = new Date(dateTime);
    return isNaN(datetime.getTime()) ? this.notFoundValue : this.datePipe.transform(datetime, 'dd.MM.yy');
  }

  private getDaysGap(fromDate: string, toDate: string): number {
    if (!this.utilityService.isNotNullOrMinDateValue(fromDate) || !this.utilityService.isNotNullOrMinDateValue(toDate)) {
      return null;
    }

    const fromDatetime = new Date(fromDate);
    const toDatetime = new Date(toDate);
    if (isNaN(fromDatetime.getTime()) || isNaN(toDatetime.getTime())) {
      return null;
    }

    const timeDifference = toDatetime.getTime() - fromDatetime.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }

  private getShipperTypeName(shipperType: ShipperType): string {
    switch (shipperType) {
      case ShipperType.exporter:
        return "I'm Exporting";
      case ShipperType.importer:
        return "I'm Importing";
      default:
        return null;
    }
  }

  private getLocationTypeName(locationType: LocationType): string {
    switch (locationType) {
      case LocationType.townCity:
        return 'Door';
      case LocationType.airPort:
        return 'AirPort';
      case LocationType.seaPort:
        return 'SeaPort';
      case LocationType.railwayTerminal:
        return 'Inland Port';
      case LocationType.containerTerminal:
        return 'Warehouse(cfs)';
      default:
        return locationType?.toString();
    }
  }

  private getCurrencyCodeName(currencyCode: CurrencyCode): string {
    switch (currencyCode) {
      case CurrencyCode.USD:
        return "usd";
      case CurrencyCode.EUR:
        return "eur";
      default:
        return null;
    }
  }

  private getShipmentTypeName(shipmentType: ShipmentType): string {
    switch (shipmentType) {
      case ShipmentType.LCL:
        return "lcl";
      case ShipmentType.FCL:
        return "fcl";
      case ShipmentType.AIR:
        return "air";
      default:
        return shipmentType;
    }
  }

  private getBookingCommoditiesAmount(shipmentTypeCode: ShipmentType, containers: Container[]): number {
    if (containers.length === 0) {
      return 0;
    }

    if (shipmentTypeCode === ShipmentType.FCL) {
      return containers.length;
    } else {
      return containers[0].commodities.reduce((total, commodity) => {
        return total + (commodity.numberOfPackages || 0);
      }, 0);
    }
  }

  private getAddressTypeName(addressTypeCode: AddressTypeCode): string {
    switch (addressTypeCode) {
      case AddressTypeCode.airport:
        return 'airport';
      case AddressTypeCode.door:
        return "door";
      case AddressTypeCode.port:
        return "seaport";
      case AddressTypeCode.warehouse:
        return "warehouse";
      default:
        return null;
    }
  }

  private getGoogleAnalyticsId(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.googleAnalyticsID;
      case 'sb':
        return environment.sb.googleAnalyticsID;
      default:
        return environment.googleAnalyticsID;
    }
  }
}
