<div class="row m-0 p-0 dimensions-input-container" role="group" [formGroup]="dimensions"
  [attr.aria-describedby]="userAriaDescribedBy" [attr.aria-labelledby]="parentFormField?.getLabelId()"
  (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <div class="col p-0">
    <input matInput formControlName="l" class="text-center" type="text" pattern="\d*" inputmode="numeric" maxlength="3"
      size="3" placeholder="L" (input)="_handleInput(dimensions.controls.l, w)" #l appPreventNonNumeric />
  </div>
  <div class="col-auto px-1"><span>x</span></div>
  <div class="col p-0">
    <input matInput formControlName="w" class="text-center" type="text" pattern="\d*" inputmode="numeric" maxlength="3"
      size="3" placeholder="W" (input)="_handleInput(dimensions.controls.w, h)"
      (keyup.backspace)="autoFocusPrev(dimensions.controls.w, l)" #w appPreventNonNumeric />
  </div>
  <div class="col-auto px-1"><span>x</span></div>
  <div class="col p-0">
    <input matInput formControlName="h" class="text-center" type="text" pattern="\d*" inputmode="numeric" maxlength="3"
      size="3" placeholder="H" (input)="_handleInput(dimensions.controls.h)"
      (keyup.backspace)="autoFocusPrev(dimensions.controls.h, w)" #h appPreventNonNumeric />
  </div>
</div>
