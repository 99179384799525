<div class="claim-credit-line-container">
  <div class="claim-credit-line-wrapper">
    <div class="credit-line-approved-title">Credit Line</div>
    <div class="credit-line-approved-info-line">
      <span class="info-line-title col-8">Approved credit</span>
      <span class="info-line-amount col">{{
        creditLine.totalLimit | currency : "USD" : true : "1.0-0"
        }}</span>
    </div>
    <div class="credit-line-approved-info-line">
      <span class="info-line-title col-8">Utilised funds</span>
      <span class="info-line-amount col">-{{
        creditLine.totalLimit - creditLine.availableLimit
        | currency : "USD" : true : "1.0-0"
        }}</span>
    </div>
    <div class="credit-line-approved-info-line">
      <span class="info-line-title col-8">Total available</span>
      <span class="info-line-amount col">{{
        creditLine.availableLimit | currency : "USD" : true : "1.0-0"
        }}</span>
    </div>
    <div class="credit-line-approved-button">
      <button *ngIf="creditLine.statusId === CreditLineStatuses.approved" mat-stroked-button color="primary"
        class="content-button button-spinner" [disabled]="isLoading" (click)="initCreditLine()">
        <span class="button-inner">
          Ask to Increase Limit
          <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        </span>
      </button>
      <button *ngIf="creditLine.statusId === CreditLineStatuses.increaseRequested" mat-stroked-button color="primary"
        class="content-button button-spinner disabled">
        <span class="button-inner">
          Increase Limit Requested
          <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>

  <div class="claim-credit-line-booking">
    <div class="row">
      <div class="col">
        <span class="claim-credit-line-booking-title">Payments</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="!payments; else loaded" class="loading">
          <mat-spinner></mat-spinner>
        </div>
        <ng-template #loaded>
          <app-payment-line *ngFor="let payment of payments" [payment]="payment"></app-payment-line>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="payments && !hasPayment" class="splash-screen">
          <div class="splash-screen-content">
              <span class="splash-screen-title">No Transactions Yet</span>
              <span class="splash-screen-text mt-3">Your transactions using credit funds will appear here.</span>
              <button mat-raised-button class="splash-screen-button" (click)="onQuoteSearch()">
                  Create New Order
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>