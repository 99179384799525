export class SupportRequest {
    teamMemberId: string;
    organizationId: string;
    organizationName: string;
    fullName: string;
    phoneNumber: string;
    bolOrderNumber: string;
    supportRequestType: SupportRequestTypeCode;
}

export class SupportRequestDialogData {
    isPopupAutoOpened: boolean;
    supportRequestType: SupportRequestTypeCode;
    bolOrderNumber: string;
    quoteSearchId: string;
}

export enum SupportRequestTypeCode {
    general = 1,
    booking = 2
}

export class SupportRequestMouseLeave {
    event: MouseEvent;
    isQuotesPage: boolean;
    quoteResultsPageIncludeResults: boolean;
}