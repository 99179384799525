export class OrganizationContact {
  id: string;
  organizationId: string;
  originId?: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  countryCode: string;
  state: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  taxId: string;
  customerCode: string;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  organizationCode: string;
  organizationName: string;
}

export class OrganizationContactsQuery {
  organizationId?: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  countryCode: string;
  state: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  taxId: string;
  customerCode: string;
  createTimeUtc?: string;
  updateTimeUtc?: string;
  sortBy: OrganizationContactsQueryParameters;
  sortDesc: boolean;
  pageNo: number;
  pageSize: number;
  any: boolean;
}

export enum OrganizationContactsQueryParameters {
  companyName = 1,
  firstName = 2,
  lastName = 3,
  email = 4,
  address1 = 5,
  address2 = 6,
  country = 7,
  state = 8,
  city = 9,
  postCode = 10,
  telephone = 11,
  fax = 12,
  taxId = 13,
  customerCode = 14,
  createTimeUtc = 15,
  updateTimeUtc = 16,
  lastUsedDateTimeUtc = 17
}

export class OrganizationContactViewModel {
  id?: string;
  organizationId: string;
  originId?: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  countryCode: string;
  state: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  taxId: string;
  customerCode: string;
}

export enum OrganizationContactAction {
  new = 1,
  view = 2,
  edit = 3
}
