import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import * as uuid from "uuid";
import { MatDialog } from "@angular/material/dialog";
import {
  GridsterConfig,
  Draggable,
  Resizable,
  PushDirections,
  GridsterItem,
  CompactType,
  DisplayGrid,
  GridType,
  GridsterItemComponentInterface,
  GridsterComponentInterface,
} from "angular-gridster2";
import { MatSidenav } from "@angular/material/sidenav";
import { UserService } from "@ship4wd/ngx-authorization";
import { HttpService } from "../../shared/services/http/http.service";
import { LayoutService } from "../layout/layout.service";
import {
  EnvironmentsService,
  EnvironmentsServiceConfig,
  NotificationService,
} from "@ship4wd/ngx-common";
import { environment } from "../../../environments/environment";
import {
  Dashboard,
  WidgetItem,
  WidgetType,
  WidgetsCollection,
} from "../../shared/widgets/widgets.model";
import { WidgetReviewComponent } from "./dashboard-widget/widget-review/widget-review.component";
import { DashboardService } from "../../shared/widgets/dashboard.service";
import { AppSettingsService } from "../../shared/services/app-settings/app-settings.service";

interface Safe extends GridsterConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @HostBinding("style.--gridHeight") gridHeightStyle;
  @ViewChild("drawer") drawer: MatSidenav;
  @ViewChild("gridster") gridster: GridsterComponentInterface;

  isLoading: boolean = true;
  isEdit: boolean = false;
  isDashboardEnabled: boolean;
  isWidgetsEditingEnabled: boolean;

  options: Safe;
  gridRows: number;
  organizationId: string;
  userId: string;

  allWidgets: Array<WidgetItem>;
  availableWidgets: Array<WidgetItem>;
  dashboardWidgets: Array<WidgetItem>;

  constructor(
    public http: HttpService,
    public dialog: MatDialog,
    public router: Router,
    private settingsService: AppSettingsService,
    private userService: UserService,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private dashboardService: DashboardService
  ) {
    this.isDashboardEnabled = this.getIsDasboardEnabled();

    if (!this.isDashboardEnabled) this.router.navigate(["/shipments"]);

    this.isWidgetsEditingEnabled = this.getIsWidgetsEditingEnabled();
  }

  ngAfterViewInit(): void {
    let rows = document.querySelectorAll("div.gridster-row");

    for (let row = 0; row < rows.length; row++) {
      const element = rows[row];
      for (let index = 0; index < 4; index++) {
        let div = document.createElement("div");
        div.classList.add("gridster-cell");
        element.appendChild(div);
      }
    }
  }

  ngOnInit(): void {
    this.gridHeightStyle = "100%";
    this.layoutService.setDefault();

    this.organizationId = this.settingsService.getSettings().organizationId;
    this.userId = this.userService.getUser()?.userId;

    this.setGridOptions();
    this.setGridWidgets();
  }

  onGridInit(grid: any): void {
    this.getDashboardDataFromStorage();
  }

  onGridItemInit(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    this.setGridRowsCount();
    this.setGridHeight();
  }

  onGridItemChange(item: GridsterItem): void {
    this.setGridRowsCount();
    this.setGridHeight();
  }

  onGridItemRemoved(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    this.setGridRowsCount();
    this.setGridHeight();
  }

  onOptionsChanged(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  onAdd(item: WidgetItem): void {
    const isPossible = this.options.api.getNextPossiblePosition(item);

    if (isPossible) {
      const widget = this.options.api.getFirstPossiblePosition(item);
      item.x = widget.x;
      item.y = widget.y;
      item.id = uuid.v4();

      this.dashboardWidgets.push(item);
      this.setAvailableWidgets();
      this.checkAvailableWidgets();
    } else {
      this.notificationService.notify(
        "You don't have empty space for this widget."
      );
    }
  }

  onRemove(id: string): void {
    this.dashboardWidgets = [
      ...this.dashboardWidgets.filter((x) => x.id !== id),
    ];
    this.setAvailableWidgets();
    this.checkAvailableWidgets();
  }

  onEnabled([id, isEnabled]: [string, boolean]): void {
    if (isEnabled) return;

    const widgetToRemove = this.dashboardWidgets.find((x) => x.id === id)

    this.dashboardWidgets = [
      ...this.dashboardWidgets.filter((x) => x.id !== id),
    ];

    this.allWidgets = [
      ...this.allWidgets.filter((x) => x.type !== widgetToRemove?.type),
    ];

    this.setAvailableWidgets();
    this.checkAvailableWidgets();
  }

  onEdit(): void {
    this.options.displayGrid =
      this.options.displayGrid === DisplayGrid.Always
        ? DisplayGrid.None
        : DisplayGrid.Always;
    this.options.draggable.enabled = !this.options.draggable.enabled;
    this.options.enableEmptyCellDrop = !this.options.enableEmptyCellDrop;
    this.onOptionsChanged();

    this.isEdit = !this.isEdit;

    if (this.availableWidgets.length > 0) {
      if (this.drawer.opened) {
        this.drawer.close();
      } else {
        this.drawer.open();
      }
    }
  }

  onEmptyCellClick(event: DragEvent, item: GridsterItem): void {
    const widget = JSON.parse(
      event.dataTransfer.getData("widget")
    ) as WidgetItem;
    widget.x = item.x;
    widget.y = item.y;
    widget.id = uuid.v4();

    this.dashboardWidgets.push(widget);
    this.setAvailableWidgets();
    this.setDefaultForEmpty();
    this.checkAvailableWidgets();
  }

  onDrag(ev: any): void {
    const widgetType = Number(ev.target.id) as WidgetType;

    const widget = this.availableWidgets.find(
      (item) => item.type === widgetType
    );

    if (widget) {
      this.options.defaultItemCols = widget.cols;
      this.options.defaultItemRows = widget.rows;
      this.onOptionsChanged();
    }

    if (ev.dataTransfer) {
      DataTransferItem;
      ev.dataTransfer.setData("widget", JSON.stringify(widget));
      ev.dataTransfer.dropEffect = "copy";
    }
  }

  onPreview(item: WidgetItem) {
    this.dialog.open(WidgetReviewComponent, {
      data: { item },
    });
  }

  onSave(): void {
    this.setDashboardDataToDB();

    this.options.displayGrid = DisplayGrid.None;
    this.options.draggable.enabled = false;
    this.options.enableEmptyCellDrop = false;
    this.onOptionsChanged();

    this.isEdit = false;

    if (this.availableWidgets.length > 0) {
      if (this.drawer.opened) {
        this.drawer.close();
      } else {
        this.drawer.open();
      }
    }

    this.notificationService.success("Saved.");
  }

  onRestore(): void {
    this.getDashboardDataFromStorage();

    this.options.displayGrid = DisplayGrid.None;
    this.options.draggable.enabled = false;
    this.options.enableEmptyCellDrop = false;
    this.onOptionsChanged();

    this.isEdit = false;

    if (this.availableWidgets.length > 0) {
      if (this.drawer.opened) {
        this.drawer.close();
      } else {
        this.drawer.open();
      }
    }

    this.notificationService.success("Restored.");
  }

  private checkAvailableWidgets(): void {
    if (this.availableWidgets.length === 0) {
      this.drawer.close();
    } else {
      this.drawer.open();
    }
  }

  private getIsWidgetsEditingEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isWidgetsEditingEnabled;
      case "sb":
        return environment.sb.isWidgetsEditingEnabled;
      default:
        return environment.isWidgetsEditingEnabled;
    }
  }

  private getIsDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isDashboardEnabled;
      case "sb":
        return environment.sb.isDashboardEnabled;
      default:
        return environment.isDashboardEnabled;
    }
  }

  private getDashboardDataFromStorage(): void {
    this.getDashboardDataFromDB();
  }

  private getDashboardDataFromDB(): void {
    this.dashboardService
      .getByUserAndOrganization(this.userId, this.organizationId)
      .subscribe((x) => {
        if (x && x.widgets) {
          this.dashboardWidgets = JSON.parse(x.widgets);
        } else {
          this.setDefaultWidgets();
        }
      })
      .add(() => {
        this.isLoading = false;
        this.setGridRowsCount();
        this.setGridHeight();
        this.setAvailableWidgets();
      });
  }

  private setGridRowsCount(): void {
    const lastWidget = this.dashboardWidgets.reduce(
      (prev, current) => {
        return prev.y < current.y ? current : prev
      }
    );

    this.gridRows = lastWidget.y + lastWidget.rows;
  }

  private setDashboardDataToDB(): void {
    const dashboard = {
      userId: this.userId,
      organizationId: this.organizationId,
      widgets: JSON.stringify(this.dashboardWidgets),
    } as Dashboard;

    this.dashboardService.setDashboard(dashboard).subscribe((x) => { });
  }

  private setDefaultWidgets(): void {
    this.dashboardWidgets = [
      { cols: 2, rows: 3, x: 0, y: 0, id: uuid.v4(), type: WidgetType.quoteSearch },
      { cols: 2, rows: 5, x: 0, y: 3, id: uuid.v4(), type: WidgetType.quotes },
      { cols: 2, rows: 3, x: 2, y: 0, id: uuid.v4(), type: WidgetType.credits },
      { cols: 2, rows: 5, x: 2, y: 3, id: uuid.v4(), type: WidgetType.orders },
      { cols: 4, rows: 5, x: 2, y: 8, id: uuid.v4(), type: WidgetType.recentSearches },
      {
        cols: 4,
        rows: 3,
        x: 0,
        y: 13,
        id: uuid.v4(),
        dragEnabled: false,
        resizeEnabled: false,
        type: WidgetType.ads,
      },
    ];
  }

  private setGridHeight(): void {
    const gridHeight =
      (this.gridRows * (this.options.fixedRowHeight + this.options.margin)) +
      (this.options.outerMarginBottom + this.options.outerMarginTop - this.options.margin);

    this.gridHeightStyle = gridHeight > 0 ? gridHeight + "px" : "100%";
  }

  private setGridWidgets(): void {
    this.allWidgets = [...WidgetsCollection];
  }

  private setAvailableWidgets(): void {
    this.availableWidgets = [
      ...this.allWidgets.filter(
        (item) =>
          !this.dashboardWidgets.some(
            (item2) => item2.type === item.type && item.canUseMultiple !== true
          )
      ),
    ];
  }

  private setDefaultForEmpty(): void {
    this.options.defaultItemCols = 1;
    this.options.defaultItemRows = 1;
    this.onOptionsChanged();
  }

  private setGridOptions(): void {
    this.options = {
      gridType: GridType.Fit,
      compactType: CompactType.CompactUp,
      margin: 24,
      outerMargin: true,
      outerMarginBottom: 24,
      outerMarginTop: 24,
      outerMarginLeft: 0,
      outerMarginRight: 0,
      useTransformPositioning: true,
      mobileBreakpoint: 0,
      useBodyForBreakpoint: true,
      minCols: 4,
      maxCols: 4,
      minRows: 10,
      maxRows: 16,
      maxItemCols: 4,
      minItemCols: 1,
      maxItemRows: 10,
      minItemRows: 1,
      maxItemArea: 40,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedRowHeight: 55,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: true,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
      disableScrollVertical: true,
      disableScrollHorizontal: true,
      initCallback: (grid) => this.onGridInit(grid.rows),
      itemChangeCallback: (item) => this.onGridItemChange(item),
      itemRemovedCallback: (item, itemComponent) =>
        this.onGridItemRemoved(item, itemComponent),
      itemInitCallback: (item, itemComponent) =>
        this.onGridItemInit(item, itemComponent),
      emptyCellDropCallback: this.onEmptyCellClick.bind(this),
    };
  }
}
