<div class="booking-organization-contact-container">
  <div class="page-header-with-back-button">
    <div class="back-title">
      <a href="javascript:void(0)" class="back-icon" (click)="onBackToContact()">Back to Contacts</a>
    </div>
    <div class="page-header">
      <h2 class="page-header-title">
        {{ organizationContactAction === organizationContactActions.view ? 'Contact' :
        organizationContactAction === organizationContactActions.edit ? 'Update Contact' : 'New Contact' }}
      </h2>
      <h4 class="page-header-sub-title" *ngIf="organizationContactAction !== organizationContactActions.view">
        {{ organizationContactAction === organizationContactActions.new ?
        'Fill the form below to add only or create and save a new contact.' :
        'Change the form below to update contact.'
        }}
      </h4>
    </div>
  </div>
  <div class="booking-organization-contact-content-wrapper" [formGroup]="organizationContactForm">
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Company</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('companyName').valid
        && organizationContactForm.get('companyName')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Company name' formControlName="companyName" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-fields-wrapper">
      <div class="booking-organization-contact-field-left">
        <div class="booking-organization-contact-field">
          <div class="organization-contact-field-title-wrapper">
            <span class="field-title">First name</span>
          </div>
          <div class="organization-contact-field-wrapper" [ngClass]="{
            'invalid-error-box': !organizationContactForm.get('firstName').valid
            && organizationContactForm.get('firstName')?.touched
          }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder='First name' formControlName="firstName" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="booking-organization-contact-field-right">
        <div class="booking-organization-contact-field">
          <div class="organization-contact-field-title-wrapper">
            <span class="field-title">Last name</span>
          </div>
          <div class="organization-contact-field-wrapper" [ngClass]="{
            'invalid-error-box': !organizationContactForm.get('lastName').valid
            && organizationContactForm.get('lastName')?.touched
          }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder='Last name' formControlName="lastName" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Email</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('email').valid
        && organizationContactForm.get('email')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Email' formControlName="email" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Address</span>
        <span class="field-title subtitle">Start typing for Google auto-complete</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('address1').valid
        && organizationContactForm.get('address1')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput ngx-google-places-autocomplete (onAddressChange)="onAddressChange($event)"
            formControlName="address1" type="text" placeholder='Address'>
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('address2').valid
        && organizationContactForm.get('address2')?.touched
      }">
        <span class="field-title">Address 2 (optional)</span>
      </div>
      <div class="organization-contact-field-wrapper">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Address 2' formControlName="address2" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">City</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('city').valid
        && organizationContactForm.get('city')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='City' formControlName="city" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">State (optional)</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('state').valid
        && organizationContactForm.get('state')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='State' formControlName="state" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Country/Region</span>
        <span class="field-title subtitle">Start typing for auto-complete</span>
      </div>
      <div class="organization-contact-field-wrapper country-auto-complete-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('countryCode').valid
        && organizationContactForm.get('countryCode')?.touched
      }">
        <app-country-autocomplete [countryAutocompleteValue]="countryAutocompleteValue"
          [countryControl]="organizationContactForm.get('countryCode')"
          [organizationContactAction]="organizationContactAction">
        </app-country-autocomplete>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Zip/Postcode (optional)</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('zipCode').valid
        && organizationContactForm.get('zipCode')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Zip / Postcode' formControlName="zipCode" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Telephone</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('phoneNumber').valid
        && organizationContactForm.get('phoneNumber')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Telephone' formControlName="phoneNumber" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Fax (optional)</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('fax').valid
        && organizationContactForm.get('fax')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Fax' formControlName="fax" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Tax ID/Vat number</span>
      </div>
      <div class="organization-contact-field-wrapper">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Tax ID / Vat number' formControlName="taxId" />
        </mat-form-field>
      </div>
    </div>
    <div class="booking-organization-contact-field">
      <div class="organization-contact-field-title-wrapper">
        <span class="field-title">Customer code (optional)</span>
      </div>
      <div class="organization-contact-field-wrapper" [ngClass]="{
        'invalid-error-box': !organizationContactForm.get('customerCode').valid
        && organizationContactForm.get('customerCode')?.touched
      }">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput type="text" placeholder='Customer code' formControlName="customerCode" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="booking-organization-contact-bottom"
    *ngIf="organizationContactAction !== organizationContactActions.view">
    <button mat-raised-button class="back-button" (click)="onSelectOnly()" matTooltip="Do not save to organization">
      Select Only
    </button>
    <button mat-raised-button class="next-button button-spinner" (click)="onSaveAndSelect()" matTooltip="
        {{ organizationContactAction === organizationContactActions.new ?
          'Save to organization, and select' : 'Update to organization, and select' }}">
      <span class="button-inner">
        {{ organizationContactAction === organizationContactActions.new ? 'Save & Select' : 'Update & Select' }}
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </button>
  </div>
</div>