import { CurrencyCode, PaymentMethodTypeCode, PaymentStatusCode, PaymentTermCode } from "../shared.model";

export class RequestAndPayment {
    id?: string;
    paymentId?: string;
    bookingId: string;
    paymentNumber: number;
    dueDate: string;
    amount: number;
    reasonCode: PaymentReasonCode;
    reasonDisplayName: string;
    statusCode: PaymentStatusCode;
    statusDisplayName: string;
    fees: number;
    externalStatus: string;
    transactionId: string;
    createTimeUtc: string;
    updateTimeUtc: string;
}

export enum PaymentReasonCode {
    initialPayment = 1,
    additionalPayment = 2
}

export class RequestAndPaymentQuery {
    bookingId: string;
    paymentReasonCode?: PaymentReasonCode;
    currencyCode?: CurrencyCode;
    dateTime: string;
    organizationName: string;
    organizationId: string;
    paymentId: string;
    status?: PaymentStatusCode;
    statusTypes?: Array<PaymentStatusCode>;
    paymentTerm?: PaymentTermCode;
    amount?: number;
    paymentMethodTypeCode?: PaymentMethodTypeCode
    reason?: PaymentReasonCode;
    createTimeUtcRangeFrom: string;
    createTimeUtcRangeTo: string;
    updateTimeUtcRangeFrom: string;
    updateTimeUtcRangeTo: string;
    any: boolean
    sortBy: PaymentRequestAndPaymentsQueryParameters;
    sortDesc: boolean;
    pageNo: number;
    pageSize: number;
}

export enum PaymentRequestAndPaymentsQueryParameters {
    createTimeUtc = 1,
    updateTimeUtc = 2,
    paymentReasonCode = 3,
    currencyCode = 4,
    duePaymentDate = 5,
    organizationName = 6,
    paymentNumber = 7,
    status = 8,
    amount = 9,
    paymentMethodType = 10,
    reason = 11,
    progress = 12
}