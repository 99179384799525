<mat-form-field [appearance]="appearance" class="w-100 company-search-form-field" [floatLabel]="floatLevel" S4DPlaces>
  <mat-label>{{titleText}}</mat-label>
  <div class="d-flex h-100 align-items-end">
    <input matInput type="text" [placeholder]="placeholder" [formControl]="legalNameControl"
      [matAutocomplete]="autoCompany" appAutocompletePosition />
  </div>
  <div class="button-spinner autocomplete-spinner" matSuffix>
    <span class="button-inner">
      <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      <span class="company-search-error-icon" [matTooltip]="tooltip" matTooltipPosition="above"
        *ngIf="!isLoading && !isSelected || !legalNameControl.valid">
        <i class="service-line-icon s4d-icon s4d-info-light"></i>
      </span>
      <span class="company-search-success-icon" *ngIf="!isLoading && isSelected && legalNameControl.valid">
        <i class="service-line-icon s4d-icon s4d-check success"></i>
      </span>
    </span>
  </div>
  <mat-autocomplete class="legal-name-autocomplete" #autoCompany="matAutocomplete" [displayWith]="displayCompanyFn">
    <mat-option class="city" *ngFor="let item of filteredCompanies | async" [value]="item.companyName"
      [matTooltip]="getCompanyTooltip(item)" [matTooltipPosition]="'above'" (onSelectionChange)="onSelectCompany(item)">
      <span class="option-main-color">{{ item.companyName }}</span>, <span class="option-secondary-color">{{
        item.postalCode }}</span>,
      <span class="option-secondary-color">{{ item.streetAddress }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div *ngIf="legalNameControl?.touched && legalNameControl?.hasError('required')" class="mat-error d-flex mt-2">
  <i class="s4d-icon s4d-info-light organization-error-info-icon"></i> <span>Please, enter your organization's legal
    name.</span>
</div>
<div *ngIf="legalNameControl?.touched && legalNameControl?.hasError('incorrect')" class="mat-error d-flex mt-2">
  <i class="s4d-icon s4d-info-light organization-error-info-icon"></i> <span>Legal name invalid.</span>
</div>