import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { SearchValidQuery, VerifySearchValid } from './routes.model';

@Injectable()
export class RoutesService {
    constructor(
        private internalUrlsService: InternalUrlsService,
        private http: HttpClient
    ) { }

    verifySearchValid(query: SearchValidQuery): Observable<VerifySearchValid> {
        return this.http.post<VerifySearchValid>(
            `${this.internalUrlsService.getApiBaseUrl()}/routes/verifySearchValid`, query);
    }
}
