import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SupportRequestDialogComponent } from '../support-requests/support-request-dialog/support-request-dialog.component';
import { SupportRequestsService } from '../support-requests/support-requests.service';
import { GoogleAnalyticsService } from '../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-live-assistance',
  templateUrl: './live-assistance.component.html',
  styleUrls: ['./live-assistance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveAssistanceComponent {

  constructor(
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private supportRequestsService: SupportRequestsService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  get chatStatus(): boolean {
    const chat = document.getElementById('embeddedMessagingFrame');
    const button = document.getElementById('embedded-messaging');

    if (button) {
      button.style.visibility = chat ? 'visible' : 'hidden';
    }

    return chat ? true : false;
  }

  get chatOpenedStatus(): boolean {
    const chat = document.getElementById('embeddedMessagingFrame');
    const spinner = document.getElementById('embeddedMessagingLoadingSpinner');

    return chat ? chat.classList.contains('isMaximized') && !spinner && spinner === null : false;
  }

  get chatLoading(): boolean {
    const spinner = document.getElementById('embeddedMessagingLoadingSpinner');

    return spinner ? true : false;
  }

  get isBookingsPage(): boolean {
    return this.router.url.includes('booking');
  }

  openSupportDialog(): void {
    this.googleAnalyticsService.chooseLiveBookingAssistance("live assistance");
    const data = this.supportRequestsService.supportRequestDialogDataBehaviorSubject.value;
    if (data)
      data.isFromBookingsPage = this.isBookingsPage;

    const dialogRef = this.dialog.open(SupportRequestDialogComponent, {
      autoFocus: false,
      scrollStrategy: this.sso.noop(),
      data: data,
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });
  }
}
