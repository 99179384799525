import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { OrganizationInvitationViewModel } from '../../manage-organization.model';

@Injectable({
  providedIn: 'root'
})
export class InviteMemberService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  addEditInviteMember(inviteMember: OrganizationInvitationViewModel) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<string>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizationInvitations`,
      inviteMember, httpOptions);
  }

  cancelInvite(id: string, userId: string) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put(
      `${this.internalUrlsService.getApiBaseUrl()}/organizationInvitations/${id}/cancel`,
      JSON.stringify(userId), httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
