<mat-dialog-content class="tutorial-dialog-content">
  <div class="close-icon-wrapper d-flex">
    <span class="close-icon" (click)="onClose()"></span>
  </div>
  <h4 class="tutorial-dialog-title">
    Watch Our Short Tutorial To See How To Search For Shipping Quotes
  </h4>
  <div class="tutorial-video-container">
    <iframe width="458" height="286" src="https://www.youtube.com/embed/aZcFISt6ibw" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
  <div class="tutorial-points-container-wrapper">
    <div class="tutorial-points d-flex w-100">
      <ul class="tutorial-points">
        <li><span class="material-icons">check</span> Quote, Compare, and Book in Minutes</li>
        <li><span class="material-icons">check</span> Get Real Support from Real People 24/7</li>
        <li><span class="material-icons">check</span> Pay with Credit Card and ACH Debit</li>
        <li><span class="material-icons">check</span> Pay up to 90 days after cargo delivery date</li>
      </ul>
    </div>
  </div>
  <div class="row text-center justify-content-md-center align-self-stretch tutorial-dialog-footer">
    <button class="tutorial-dialog-button search-quote-button" (click)="onQuoteSearch()">
      Search for a Quote
    </button>
  </div>
</mat-dialog-content>
