import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language.model';

@Injectable()
export class LanguageService {
  private static readonly DEFAULT_LANGUAGE = 'en';

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>) {
    this.changeLanguage(this.getLanguage());
  }

  getLanguage() {
    return LanguageService.DEFAULT_LANGUAGE;
  }

  getAllLanguages() {
    return Language.all();
  }

  changeLanguage(language: string) {
    language = language || LanguageService.DEFAULT_LANGUAGE;

    this.translateService.use(language);
    this.dateAdapter.setLocale(language);

  }

  hasTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }
}
