import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@ship4wd/ngx-common';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import {
  AdditionalRate,
  BookingFlowPanelState,
  BookingFlowPanels,
  CurrencyCode,
  CustomsChargeCodes,
  InsuranceChargeCodes,
  Page,
  PreShipmentChargeCodes,
  VendorsCode
} from '../../../../shared/shared.model';
import { Booking, BookingSummary } from '../../../../shared/bookings/bookings.model';
import { BookingFlowService } from '../booking-flow.service';
import { CurrencyTextIconPipe } from '../../../../shared/pipes/currency-text-icon.pipe';
import {
  AdditionalService,
  AdditionalServiceType,
  AdditionalServicesQuery,
  AdditionalServicesQueryParameters,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges
} from '../../../../shared/additional-services/additional-services.model';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { AdditionalServiceDialogComponent } from './additional-service-dialog/additional-service-dialog.component';
import { AdditionalServiceCustomsComponent } from './additional-service-customs/additional-service-customs.component';
import { AdditionalServicePreShipmentComponent } from './additional-service-pre-shipment/additional-service-pre-shipment.component';
import { AdditionalServiceInsuranceComponent } from './additional-service-insurance/additional-service-insurance.component';
import { AdditionalServiceInsuranceManuallyComponent } from './additional-service-insurance-manually/additional-service-insurance-manually.component';
import { Organization } from '../../../manage-organization/manage-organization.model';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { PostalCodeUpdateDialogComponent } from './postal-code-update-dialog/postal-code-update-dialog.component';

@Component({
  selector: 'app-booking-flow-services',
  templateUrl: './booking-flow-services.component.html',
  styleUrls: ["./booking-flow-services.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BookingFlowServicesComponent implements OnInit {
  @Output() next: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() isCostEstimator: EventEmitter<any> = new EventEmitter();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() isBookingAdditionalServiceUpdated: EventEmitter<any> = new EventEmitter();
  @Output() setState: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() hasAdditionalServices: EventEmitter<boolean> = new EventEmitter();
  @Input() booking: Booking;
  @Input() selectedAdditionalService: AdditionalServiceType = undefined;
  @Input() isCreditOrganization: boolean;
  @Input() bookingSummary: BookingSummary;
  @Input() organization: Organization;
  btnLoading: boolean = false;
  bookingForm: FormGroup;
  additionalServices: AdditionalService[];
  additionalServiceType = AdditionalServiceType;
  totalSurchargeAmoutWithoutFCD: number = 0;
  vendorsCode = VendorsCode;
  customsChargeCodes = CustomsChargeCodes;
  removeAddServiceBtnLoading: boolean = false;
  bookingId: string;
  isValid: boolean = false;
  additionalServiceTypes = AdditionalServiceType;
  insuranceErrors: string[];
  isAgent: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private controlContainer: ControlContainer,
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityService: UtilityService,
    private userInfoService: UserInfoService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.bookingForm = this.controlContainer.control as FormGroup;
    this.getAdditionalServices();

    this.bookingFlowService.validState.subscribe(x => {
      this.isValid = x;
      this.getInsuranceErrors()
    });

    if (this.booking?.bookingAdditionalServiceCharges?.length > 0) {
      this.onSetState(true);
    }

    this.isAgent = this.userInfoService.checkIsAgent();
  }

  onAddServices(additionalService: AdditionalService): void {
    switch (additionalService.id) {
      case AdditionalServiceType.customs:
        this.addCustomsServices(additionalService);
        break;
      case AdditionalServiceType.customsManually:
        this.addCustomsManuallyServices(additionalService);
        break;
      case AdditionalServiceType.preShipment:
        this.addPreShippingServices(additionalService);
        break;
      case AdditionalServiceType.insurance:
        if (this.isValidBookingInsurance()) {
          this.addInsuranceServices(additionalService);
        } else {
          this.notificationService.error("There is missing required information for insurance. Please fill Contact Details & Cargo Details section")
        }
        break;
      case AdditionalServiceType.insuranceManually:
        if (this.isValidBookingInsurance()) {
          this.addInsuranceManuallyServices(additionalService);
        } else {
          this.notificationService.error("There is missing required information for insurance. Please fill Contact Details & Cargo Details section")
        }
        break;
      default:
        break;
    }
  }

  onCostEstimator(additionalService: AdditionalService): void {
    this.isCostEstimator.emit({
      isCostEstimator: true,
      vendorCode: VendorsCode[additionalService?.surcharges[0].supplier.toString().toLocaleLowerCase()]
    });
  }

  getAdditionalServices(): void {
    const query = {
      bookingId: this.bookingId,
      includeProperties: true,
      sortBy: AdditionalServicesQueryParameters.name,
      pageNo: 1,
      pageSize: 10,
      isEnabled: true
    } as AdditionalServicesQuery;

    this.isLoading.emit(true);
    this.bookingFlowService.getAdditionalServices(query)
      .subscribe(
        (x: Page<AdditionalService>) => {
          this.additionalServices = x.items;
          this.hasAdditionalServices.emit(x.items.length > 0);
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      ).add(() => (this.isLoading.emit(false)));
  }

  getSurchargeAmout(surchargeAmount: number): number {
    if (surchargeAmount > 0) {
      this.totalSurchargeAmoutWithoutFCD += surchargeAmount;
    }
    return this.totalSurchargeAmoutWithoutFCD;
  }

  getAdditionalServiceAmount(additionalService: AdditionalService, isTotal: boolean = false): string | number {
    if (additionalService.id === AdditionalServiceType.customs
      && additionalService?.surcharges?.length > 0) {
      const isClearItFilter = additionalService.surcharges.filter(x => x.supplier.toLocaleLowerCase() === this.vendorsCode[this.vendorsCode.clearit].toLocaleLowerCase());
      if (isClearItFilter.length > 0) {
        let customCCSAmount = 0;
        let customCCSAmountCurrency;
        let customCCSSurcharge = additionalService.surcharges.find(x => x.additionalTypeCode.toLocaleLowerCase() === this.customsChargeCodes[this.customsChargeCodes.CCS].toLocaleLowerCase())
        if (customCCSSurcharge !== undefined) {
          customCCSAmount = customCCSSurcharge.shipmentRate.amount;
          customCCSAmountCurrency = customCCSSurcharge.shipmentRate.currency;
        }

        if (isTotal) {
          let totalAditionalServiceFeaturesAmount = 0;
          let aditionalServiceFeaturesAmountCurrency;

          if (this.booking.bookingAdditionalServiceCharges.length > 0) {
            const bookingAdditionalServiceCharges = this.booking.bookingAdditionalServiceCharges.filter(x =>
              x.vendorCode === VendorsCode.clearit
              && x.additionalServiceCharge.chargeCode.toLocaleLowerCase() !== this.customsChargeCodes[this.customsChargeCodes.CCS].toLocaleLowerCase()
            );
            if (bookingAdditionalServiceCharges.length > 0) {
              bookingAdditionalServiceCharges.forEach(bookingAdditionalServiceCharge => {
                const additionalServiceChargeId = bookingAdditionalServiceCharge.additionalServiceChargeId;
                const additionalServiceSurcharge = additionalService.surcharges.find(x => x.additionalType === additionalServiceChargeId);
                if (additionalServiceSurcharge !== undefined && additionalServiceSurcharge?.shipmentRate) {
                  totalAditionalServiceFeaturesAmount += additionalServiceSurcharge.shipmentRate.amount;
                  aditionalServiceFeaturesAmountCurrency = additionalServiceSurcharge.shipmentRate.currency
                }
              });
            }
          }

          return (totalAditionalServiceFeaturesAmount !== 0 ? " + " +
            this.getCurrencyIcon(aditionalServiceFeaturesAmountCurrency) +
            totalAditionalServiceFeaturesAmount : "");
        }

        return (this.getCurrencyIcon(customCCSAmountCurrency) + customCCSAmount);
      }
    }
    if (additionalService.id === AdditionalServiceType.preShipment) {
      return this.getPreShippingAmount(additionalService);
    }

    if (additionalService.id === AdditionalServiceType.insurance) {
      return this.getInsuranceAmount(additionalService);
    }

    return 0;
  }

  onRemoveServices(additionalService: AdditionalService): void {
    this.selectedAdditionalService = additionalService.id;
    this.removeAddServiceBtnLoading = true;
    this.bookingFlowService.deleteAdditionalServices(this.booking.id, additionalService.id)
      .subscribe(
        (response: any) => {
          this.isBookingAdditionalServiceUpdated.emit(
            {
              isBookingAdditionalServiceUpdated: true,
              additonalServiceType: additionalService.id
            }
          );
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      ).add(() => {
        this.removeAddServiceBtnLoading = false;
      });
  }

  isShowServiceButton(additionalService: AdditionalService): boolean {
    return this.bookingSummary.bookingAdditionalServiceCharges.some(x =>
      x.additionalServiceCharge.additionalServiceId === additionalService.id);
  }

  getSelectedAdditionalService(additionalService: any): boolean {
    return (this.selectedAdditionalService && additionalService.id === this.additionalServiceType.customs && this.selectedAdditionalService === this.additionalServiceType.customs);
  }

  onReviewAndPay(): void {
    this.googleAnalyticsService.reviewAndPaySelected();

    this.router.navigate(["booking/summary/", this.bookingId]);
  }

  getAdditionalServiceDescription(additionalService: AdditionalService): string {
    switch (additionalService.id) {
      case AdditionalServiceType.customs:
        return '+ Duties & Import Fees';
      case AdditionalServiceType.preShipment:
        return 'per visit';
      default:
        return '';
    }
  }

  getAdditionalServiceTooltipText(additionalService: AdditionalService, isTotal: boolean = false): string | null {
    switch (additionalService.id) {
      case AdditionalServiceType.preShipment:
        return 'One visit is sufficient for most per-shipping inspections. '
          + 'After you finish your booking, a shipping expert will call you to schedule.';
      case AdditionalServiceType.customs:
        return isTotal ? 'Additional Features summary' : 'Formal Custom Declaration';
      default:
        return null;
    }
  }

  isShowChangeButton(additionalService: AdditionalService): boolean {
    switch (additionalService.id) {
      case AdditionalServiceType.customs:
        return true;
      default:
        return false;
    }
  }

  isShowServiceDetailsButton(additionalService: AdditionalService): boolean {
    switch (additionalService.id) {
      case AdditionalServiceType.preShipment:
        return true;
      default:
        return false;
    }
  }

  onShowServiceDetails(additionalService: AdditionalService): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(AdditionalServiceDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0'
      });

      switch (additionalService.id) {
        case AdditionalServiceType.preShipment:
          dialogRef.componentInstance.component = AdditionalServicePreShipmentComponent;
          break;
        default:
          break;
      }

      dialogRef.afterClosed().subscribe(data => {
        this.dialogService.setDialogOpen(false);
      });
    }
  }

  onSetState(isValidState: boolean = undefined): void {
    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.services;
    state.success = isValidState !== undefined ? isValidState : this.isValid;
    state.valid = isValidState;
    state.loading = false;

    this.setState.emit(state);
  }

  getInsuranceErrors(): void {
    this.insuranceErrors = [];
    const MAX_INSURANCE_DATE = 30; // days
    if (this.isDepartureDateInvalid(MAX_INSURANCE_DATE)) {
      this.insuranceErrors.push("This service is unavailable because the departure date should be within " + MAX_INSURANCE_DATE + " days from now.");
    }

    this.validateContacts();

    if (!this.isValidBookingInsurance() || this.isOrganizationPostalCodeMissing()) {
      this.insuranceErrors.push("There is required information missing for the insurance.");

      if (!this.isValidBookingInsurance()) {
        this.insuranceErrors.push("Please fill Contact Details & Cargo Details section.");
      }
      if (this.isOrganizationPostalCodeMissing()) {
        this.insuranceErrors.push("The ZIP code of your organization’s address is not provided.");
      }
    }
  }

  onUpdateZipCode(): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(PostalCodeUpdateDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'update-postal-code',
        data: this.organization
      });

      dialogRef.afterClosed().subscribe(data => {
        data ? this.bookingFlowService.validState.subscribe(x => this.isValid = x)
          : this.organization.postalCode = '';

        this.getInsuranceErrors();
        this.dialogService.setDialogOpen(false);
      });
    }
  }

  private isDepartureDateInvalid(maxDays: number): boolean {
    const maxInsuranceDate = new Date();
    maxInsuranceDate.setDate(maxInsuranceDate.getDate() + maxDays);
    return new Date(this.bookingSummary.quote?.trip?.departureDate) > maxInsuranceDate;
  }

  private validateContacts(): void {
    if (this.utilityService.isDoorDeliveryService(this.bookingSummary.serviceType))
      this.validateContact(this.bookingSummary.shipperContact, "Pickup");

    if (this.utilityService.isDoorPickupService(this.bookingSummary.serviceType))
      this.validateContact(this.bookingSummary.consigneeContact, "Delivery");
  }

  private validateContact(contact, contactType: string): void {
    if (!contact) {
      this.insuranceErrors.push(`${contactType} contact is not valid`);
    }
  }

  private getCurrencyIcon(currencyCode: CurrencyCode) {
    const currencyTextIconPipe = new CurrencyTextIconPipe();
    return currencyTextIconPipe.transform(currencyCode);
  }

  private getPreShippingAmount(additionalService: AdditionalService): string {
    const charge = additionalService.surcharges?.find(x => x.additionalTypeCode == PreShipmentChargeCodes[PreShipmentChargeCodes.INP]);
    return this.getCurrencyIcon(charge.shipmentRate.currency) + (charge?.shipmentRate?.totalAmount ?? 0);
  }

  private getInsuranceAmount(additionalService: AdditionalService): string {
    const insuranceAdditionalServiceCharge = this.booking.bookingAdditionalServiceCharges.find(x =>
      x.vendorCode === VendorsCode.loadSure
      && x.additionalServiceCharge.chargeCode.toLocaleLowerCase() === InsuranceChargeCodes[InsuranceChargeCodes.BXF].toLocaleLowerCase()
    );
    return this.getCurrencyIcon(insuranceAdditionalServiceCharge?.currencyCode) + (insuranceAdditionalServiceCharge?.price?.toFixed(2) ?? "");
  }

  private addCustomsServices(additionalService: AdditionalService): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(AdditionalServiceDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0'
      });

      dialogRef.componentInstance.component = AdditionalServiceCustomsComponent;
      dialogRef.componentInstance.bookingId = this.bookingId;
      dialogRef.componentInstance.booking = this.booking;
      dialogRef.componentInstance.additionalRates = additionalService.surcharges;

      dialogRef.afterClosed().subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result && result.isBookingAdditionalServiceUpdated) {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: result.isBookingAdditionalServiceUpdated,
            additonalServiceType: result.additonalServiceType
          });
        }
      });
    }
  }

  private addCustomsManuallyServices(additionalService: AdditionalService): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges(additionalService);

    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: AdditionalServiceType.customsManually,
      charges: charges
    } as CreateBookingAdditionalServiceCharges;

    this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
  }

  private addPreShippingServices(additionalService: AdditionalService): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges(additionalService);

    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: additionalService.id,
      charges: charges
    } as CreateBookingAdditionalServiceCharges;
    this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
  }

  private saveBookingCustomAdditionalServiceCharges(model: CreateBookingAdditionalServiceCharges): void {
    this.btnLoading = true;
    this.bookingFlowService.addAdditionalServices(this.bookingId, model)
      .subscribe(
        () => {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: true,
            additonalServiceType: model.additionalServiceType
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      ).add(() => {
        this.btnLoading = false;
      });
  }

  private prepareCreateBookingAdditionalServiceCharges(additionalService: AdditionalService)
    : CreateBookingAdditionalServiceCharge[] {
    let charges = new Array<CreateBookingAdditionalServiceCharge>();

    additionalService.surcharges.forEach(x => {
      const charge = this.prepareCreateBookingAdditionalServiceCharge(x);
      charges.push(charge);
    })

    return charges;
  }

  private prepareCreateBookingAdditionalServiceCharge(additionalRate: AdditionalRate)
    : CreateBookingAdditionalServiceCharge {
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: this.getVendorCode(additionalRate.supplier),
      price: additionalRate.shipmentRate.totalAmount,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: additionalRate.shipmentRate.currency
    } as CreateBookingAdditionalServiceCharge;
  }

  private getVendorCode(supplier: string): VendorsCode {
    const normalizedSupplier = supplier.toLowerCase();
    for (const key in VendorsCode) {
      if (key.toLowerCase() === normalizedSupplier) {
        return VendorsCode[key as keyof typeof VendorsCode];
      }
    }
    return null;
  }

  private addInsuranceServices(additionalService: AdditionalService): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(AdditionalServiceDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0'
      });

      dialogRef.componentInstance.component = AdditionalServiceInsuranceComponent;
      dialogRef.componentInstance.bookingId = this.bookingId;
      dialogRef.componentInstance.booking = this.booking;
      dialogRef.componentInstance.additionalRates = additionalService.surcharges;

      dialogRef.afterClosed().subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result && result.isBookingAdditionalServiceUpdated) {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: result.isBookingAdditionalServiceUpdated,
            additonalServiceType: result.additonalServiceType
          });
        }
      });
    }
  }

  private addInsuranceManuallyServices(additionalService: AdditionalService): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(AdditionalServiceDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0'
      });

      dialogRef.componentInstance.component = AdditionalServiceInsuranceManuallyComponent;
      dialogRef.componentInstance.bookingId = this.bookingId;
      dialogRef.componentInstance.booking = this.booking;
      dialogRef.componentInstance.additionalRates = additionalService.surcharges;

      dialogRef.afterClosed().subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result && result.isBookingAdditionalServiceUpdated) {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: result.isBookingAdditionalServiceUpdated,
            additonalServiceType: result.additonalServiceType
          });
        }
      });
    }
  }

  private isValidBookingInsurance(): boolean {
    return this.isValid;
  }

  private isOrganizationPostalCodeMissing(): boolean {
    return this.organization.postalCode == null || this.organization.postalCode == '';
  }
}
