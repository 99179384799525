import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { ShipmentType } from "../../../../shared/shared.model";
import { GoogleAnalyticsService } from "../../../../shared/google-analytics/google-analytics.service";

@Component({
  selector: "app-quote-search-widget",
  templateUrl: "./quote-search-widget.component.html",
  styleUrls: ["./quote-search-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuoteSearchWidgetComponent implements OnInit {
  @Input() widget;
  @Input() editable: boolean;
  @Input() preview: boolean = false;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean = true;
  isError: boolean = false;

  errorMessage: string;

  loaderElementsNumber: number = 3;

  shippingTypes: any[] = [
    {
      name: "Full Container \nLoad",
      value: ShipmentType.FCL,
      icon: "shipping-type-fcl",
    },
    {
      name: "Less than \nContainer Load",
      value: ShipmentType.LCL,
      icon: "shipping-type-lcl",
    },
    { name: "Air Cargo", value: ShipmentType.AIR, icon: "shipping-type-ac" },
  ];

  constructor(
    public router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    if (!this.preview) {
      this.getData();
    }
  }

  onGoTo(type: ShipmentType): void {
    this.googleAnalyticsService.chooseShipmentTypeFromDashboardStart(type)
    this.googleAnalyticsService.chooseShipmentTypeFromDashboardComplete(type)
    this.router.navigate(["/quote-search"], {
      queryParams: { widget: type },
    });
  }

  onRemove(): void {
    this.remove.next(this.widget.id);
  }

  onReload(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading = false;
  }

  range(value): number[] {
    return Array.from({ length: value }, (v, k) => k + 1);
  }
}
