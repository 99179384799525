import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@ship4wd/ngx-authorization";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, OnDestroy {
  public isLoading = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const returnUrl = params["returnUrl"];
      this.authService.redirectToLogin(undefined, returnUrl);
    });
  }

  ngOnDestroy() {
    this.isLoading = false;
  }
}
