import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { OrganizationContactAction } from "../../bookings/booking-organization-contacts/booking-organization-contacts.model";
import { Country } from "../country.model";
import { CountryService } from "../country.service";

@Component({
  selector: "app-country-autocomplete",
  templateUrl: "./country-autocomplete.component.html",
  styleUrls: ["./country-autocomplete.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CountryAutocompleteComponent implements OnChanges, OnInit {
  @Input() countryControl: FormControl = undefined;
  @Input() countryAutocompleteValue: string;
  @Input() organizationContactAction: OrganizationContactAction;
  @Input() byName: boolean = false;
  @Input() title: string = "Start type your country";
  @Input() floatLabel: string = "never";
  @Input() controlName: string = 'Country'
  @Output() isTouched = new EventEmitter<any>();
  @Output() triggerError = new EventEmitter<string>();

  autoCompleteCountryControl: FormControl;
  countries: Country[];
  filteredCountries: Observable<Country[]>;
  selectedCountry: Country = null;
  isCountrySelected = false;
  isLoading = false;
  floatLabelValue: string = "never";
  placeholderValue: string = "Start type your country";

  constructor(private countryService: CountryService) {
    this.initializeCountryControls();
    this.countries = this.countryService.getCountries();
    this.filteredCountries = this.autoCompleteCountryControl.valueChanges.pipe(
      startWith(""),
      map((country) =>
        country ? this.filterStates(country) : this.countries.slice()
      )
    );
  }

  ngOnInit(): void {
    if (this.countryControl.disabled) this.autoCompleteCountryControl.disable();
  }

  ngOnChanges(): void {
    if (this.countryAutocompleteValue) {
      const country = this.countries.find(
        (c) => (this.byName ? c.name : c.code) === this.countryAutocompleteValue
      );
      this.autoCompleteCountryControl.patchValue(country);
      if (this.organizationContactAction) {
        if (this.organizationContactAction === OrganizationContactAction.view) {
          this.autoCompleteCountryControl.disable();
        } else {
          this.autoCompleteCountryControl.enable();
        }
      }
    }
  }

  onBlur(): void {
    this.isTouched.emit({
      isTouched: true,
      countryControl: this.countryControl
    });
  }

  onSelectCountry(country: Country): void {
    this.countryControl.patchValue(this.byName ? country.name : country.code);
  }

  displayFn(country: Country): string {
    return country && country.name ? country.name : null;
  }

  onAutocompleteChange(): void {
    const autocompleteCode = this.autoCompleteCountryControl.value as Country;
    if (
      this.countryControl.value !==
      (this.byName ? autocompleteCode.name : autocompleteCode.code)
    ) {
      this.countryControl.patchValue(null);
    }

    this.countryControl.invalid
      ? this.autoCompleteCountryControl.setErrors({ required: true })
      : "";

    this.countryControl.markAsTouched();
  }

  handleError(event: string): void {
    this.triggerError.emit(event);
  }

  private filterStates(value: string): Country[] {
    if (typeof value === "string") {
      return this.countries.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  private initializeCountryControls(): void {
    this.countryControl =
      this.countryControl === undefined
        ? new FormControl()
        : this.countryControl;
    this.autoCompleteCountryControl = new FormControl();

    if (this.countryControl.value) {
      const country = (
        this.byName
          ? {
            name: this.countryControl.value,
          }
          : {
            code: this.countryControl.value,
          }
      ) as Country;

      this.autoCompleteCountryControl.patchValue(country);
    }
  }
}
