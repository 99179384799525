<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="booking-flow-summary" [hidden]="isLoading" *ngIf="bookingSummary">
  <div class="booking-flow-summary-content-wrapper">
    <div class="booking-flow-summary-content gap-4">
      <div id="customer-details" class="summary-card">
        <div class="summary-card-header mb-4">
          <div class="row m-0">
            <div class="col px-0 d-flex align-items-center">
              <span class="card-header-title">Summary</span>
            </div>
            <div class="col-auto ps-2 pe-0 d-flex align-items-center">
              <div class="card-header-icon-wrapper" (click)="onPrint(bookingSummary.id)">
                <i class="card-header-icon s4d-icon s4d-print"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="summary-card-content gap-4">
          <div class="row-m-0">
            <div class="col-12 px-0 d-flex flex-column gap-4">
              <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'"
                *ngIf="bookingSummary?.customerReferenceId">
                <mat-label class="form-label">Customer reference</mat-label>
                <div class="row h-100">
                  <div class="col d-flex align-items-end disabled">
                    <input matInput [value]="bookingSummary?.customerReferenceId" placeholder="Customer reference"
                      disabled>
                  </div>
                </div>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'"
                *ngIf="bookingSummary?.masterBillOfLading">
                <mat-label class="form-label">Master Bill of Lading</mat-label>
                <div class="row h-100">
                  <div class="col d-flex align-items-end disabled">
                    <input matInput [value]="bookingSummary?.masterBillOfLading" placeholder="Master Bill of Lading"
                      disabled>
                  </div>
                </div>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'"
                *ngIf="bookingSummary?.bolOrderNumber">
                <mat-label class="form-label">Order ID</mat-label>
                <div class="row h-100">
                  <div class="col d-flex align-items-end disabled">
                    <input matInput [value]="bookingSummary?.bolOrderNumber" placeholder="Order ID" disabled>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="summary-card-header">
            <div class="row m-0 gap-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-header-title">Customer Details</span>
              </div>
            </div>
          </div>
          <div class="row m-0 px-0 gap-3">
            <div class="row m-0 px-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Contact at Pickup (Exporter/Supplier)</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{ bookingSummary?.shipperContact?.firstName}}
                  {{ bookingSummary?.shipperContact?.lastName}}
                  {{ bookingSummary?.shipperContact?.address1 ? ', ' + bookingSummary?.shipperContact?.address1 : '' }}
                </span>
              </div>
            </div>
            <div class="row m-0 px-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Contact at Delivery (Importer)</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{ bookingSummary?.consigneeContact?.firstName}}
                  {{ bookingSummary?.consigneeContact?.lastName}}
                  {{ bookingSummary?.consigneeContact?.address1 ? ', ' + bookingSummary?.consigneeContact?.address1 : ''
                  }}
                </span>
              </div>
            </div>
            <div class="row m-0 px-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Notify Party</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{ bookingSummary?.notifyPartyContact?.firstName}}
                  {{ bookingSummary?.notifyPartyContact?.lastName}}
                  {{ bookingSummary?.notifyPartyContact?.address1 ? ', ' + bookingSummary?.notifyPartyContact?.address1
                  : '' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="freight-details" class="summary-card" *ngIf="bookingSummary?.shipmentTypeCode !== shipmentTypes.AIR">
        <div class="summary-card-header mb-4">
          <div class="row m-0">
            <div class="col-12 px-0 d-flex">
              <span class="card-header-title">Basic Ocean Freight</span>
            </div>
          </div>
        </div>
        <div class="summary-card-content gap-3">
          <div class="row m-0 gap-1" *ngIf="bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.vesselName">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Vessel Name</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{ bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1" *ngIf="bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.voyage">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Voyage</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{ bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.voyage }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1" *ngIf="bookingSummary?.quote?.trip?.carrierScac">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Carrier</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{ bookingSummary?.quote?.trip?.carrierScac }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Estimated Departure</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.departureDate) ?
                (bookingSummary?.quote?.trip?.departureDate | date: "MMM dd, yyyy") : "Schedule Not Available"
                }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Estimated Arrival</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.arrivalDate) ?
                (bookingSummary?.quote?.trip?.arrivalDate | date: "MMM dd, yyyy") : "Schedule Not Available" }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1" *ngIf="checkDateNotNullOrEmpty(bookingSummary?.atd)">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Actual Time of Departure</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{ (bookingSummary?.atd | date: "MMM dd, yyyy") }}
              </span>
            </div>
          </div>
          <div class="row m-0 gap-1" *ngIf="checkDateNotNullOrEmpty(bookingSummary?.ata)">
            <div class="col-12 px-0 d-flex">
              <span class="card-content-title">Actual Time of Arrival</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-content-description">
                {{ (bookingSummary?.ata | date: "MMM dd, yyyy") }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="bookingSummary?.shipmentTypeCode === shipmentTypes.FCL">
        <div id="cargo-details-{{i}}" class="summary-card"
          *ngFor="let container of bookingSummary?.containers; let i = index">
          <div class="summary-card-header mb-4">
            <div class="row m-0 gap-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-header-title">Container {{ i + 1 }}</span>
              </div>
            </div>
          </div>
          <div class="summary-card-content gap-3">
            <div class="row m-0 gap-1" *ngIf="container?.equipmentTypeDescription">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Container Type</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.equipmentTypeDescription }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.containerNumber">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Container Number</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.containerNumber }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.sealNumber">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Seal Number</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.sealNumber }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.cargoGrossWeight">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Gross Weight</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{ container?.cargoGrossWeight }}
                  {{ container?.cargoGrossWeightUnitCode| weightUnitName }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.commodities.length">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Commodity Category</span>
              </div>
              <div class="col-12 px-0 d-flex" *ngFor="let commodity of container.commodities">
                <span class="card-content-description">{{ commodity?.commodityDescription }}</span>
              </div>
            </div>
            <div class="row m-0 mt-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-subtitle">Collection</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container.collectionDate">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Collection Date</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{
                  container.collectionDate
                  ? (container?.collectionDate
                  | date : "dd MMMM, yyyy")
                  : "n/a"
                  }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container.collectionDate">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Collection Time</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{
                  container?.collectionDate
                  ? (container?.collectionDate | date : "shortTime")
                  : "n/a"
                  }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="bookingSummary.cutOffDate">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">CUT OFF Date</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{ bookingSummary.cutOffDate | date : "dd MMMM, yyyy" }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="bookingSummary.fromBookingSearch.displayName">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">From</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ getFormattedBookingAddress('from') }}</span>
              </div>
            </div>
            <div class="row m-0 mt-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-subtitle">Delivery</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.cargoExpectedReady">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Delivery Date</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.cargoExpectedReady | date : "dd MMMM, yyyy"
                  }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.cargoExpectedReady">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Delivery Time</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.cargoExpectedReady | date :
                  "shortTime" }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="bookingSummary.toBookingSearch.displayName">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">To</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ getFormattedBookingAddress('to') }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="bookingSummary?.shipmentTypeCode !== shipmentTypes.FCL">
        <div id="cargo-details-{{i}}" class="summary-card"
          *ngFor="let container of bookingSummary?.containers;let i = index">
          <div class="summary-card-header mb-4">
            <div class="row m-0 gap-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-header-title">Cargo</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-header-description">
                  {{bookingSummary?.shipmentTypeCode === shipmentTypes.LCL ? 'LCL Shipment' : 'AIR Shipment'}}
                </span>
              </div>
            </div>
          </div>
          <div class="summary-card-content gap-3">
            <div class="row m-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Commodity Category</span>
              </div>
              <div class="col-12 px-0 d-flex" *ngFor="let commodity of container.commodities">
                <span class="card-content-description">{{ commodity?.commodityDescription }}</span>
              </div>
            </div>
            <ng-container *ngFor="let commodity of container.commodities;let i = index">
              <div class="row m-0 mt-2">
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-subtitle">Load {{ i + 1 }} Details</span>
                </div>
              </div>
              <div class="row m-0 gap-1">
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-title">Packages</span>
                </div>
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-description">
                    {{ commodity?.numberOfPackages }} {{ commodity?.packageTypeCode |
                    packageTypeName }}
                  </span>
                </div>
              </div>
              <div class="row m-0 gap-1" *ngIf="isShowCommodityDimension(commodity)">
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-title">Dimensions</span>
                </div>
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-description">
                    (W) {{ commodity?.dimension.width }}
                    {{
                    commodity?.dimension.widthUnitCode
                    | dimensionUnitName
                    | lowercase
                    }}
                    x (H) {{ commodity?.dimension.height }}
                    {{
                    commodity?.dimension.heightUnitCode
                    | dimensionUnitName
                    | lowercase
                    }}
                    x (L) {{ commodity?.dimension.length }}
                    {{
                    commodity?.dimension.lengthUnitCode
                    | dimensionUnitName
                    | lowercase
                    }}
                  </span>
                </div>
              </div>
              <div class="row m-0 gap-1" *ngIf="commodity.volume && commodity.weight">
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-title">Grand Totals</span>
                </div>
                <div class="col-12 px-0 d-flex">
                  <span class="card-content-description">
                    Volume: {{ commodity?.volume }} {{ commodity.volumeUnitCode | volumeUnitName |
                    titlecase }},
                    Weight: {{ commodity?.weight }} {{ commodity?.weightUnitCode | weightUnitName |
                    lowercase }}
                  </span>
                </div>
              </div>
            </ng-container>
            <div class="row m-0 mt-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-subtitle">Collection</span>
              </div>
            </div>
            <div class="row m-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Collection Date</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{
                  container.collectionDate
                  ? (container?.collectionDate
                  | date : "dd MMMM, yyyy")
                  : "n/a"
                  }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Collection Time</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">
                  {{
                  container.collectionDate
                  ? (container?.collectionDate | date : "shortTime")
                  : "n/a"
                  }}
                </span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="bookingSummary.fromBookingSearch.displayName">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">From</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ getFormattedBookingAddress('from') }}</span>
              </div>
            </div>
            <div class="row m-0 mt-2">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-subtitle">Delivery</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.cargoExpectedReady">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Delivery Date</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.cargoExpectedReady | date : "dd MMMM, yyyy"
                  }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="container?.cargoExpectedReady">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">Delivery Time</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ container?.cargoExpectedReady | date :
                  "shortTime" }}</span>
              </div>
            </div>
            <div class="row m-0 gap-1" *ngIf="bookingSummary.toBookingSearch.displayName">
              <div class="col-12 px-0 d-flex">
                <span class="card-content-title">To</span>
              </div>
              <div class="col-12 px-0 d-flex">
                <span class="card-content-description">{{ getFormattedBookingAddress('to') }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div id="additionalServices" class="summary-card" *ngIf="bookingSummary && bookingAdditionalServices.length">
        <div class="summary-card-header mb-4">
          <div class="row m-0">
            <div class="col-12 px-0 d-flex">
              <span class="card-header-title">Additional Services</span>
            </div>
          </div>
        </div>
        <div class="summary-card-content gap-3">
          <div class="booking-flow-services">
            <div class="booking-flow-services-container"
              *ngFor="let additionalService of bookingAdditionalServices; let i = index"
              [ngClass]="{ 'customs-additional-service': additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually }">
              <div class="col booking-flow-services-container-wrapper">
                <div class="d-flex align-items-center booking-flow-services-section">
                  <div class="icon-flow-services-content-wrapper">
                    <div class="booking-flow-services-import-icon d-flex align-items-center justify-content-center">
                      <i class="panel-header-icon s4d-icon s4d-import"
                        *ngIf="additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually"></i>
                      <i class="panel-header-icon s4d-icon s4d-shield"
                        *ngIf="additionalService.id === additionalServiceType.insurance || additionalService.id === additionalServiceType.insuranceManually"></i>
                      <i class="panel-header-icon s4d-icon s4d-search"
                        *ngIf="additionalService.id === additionalServiceType.preShipment"></i>
                    </div>
                    <div class="booking-flow-services-fields">
                      <div class="booking-flow-services-title">
                        <span>{{ additionalService?.name }}</span>
                      </div>
                      <div class="booking-flow-services-description">
                        <span>{{ additionalService?.description }}</span>
                      </div>
                    </div>
                    <div class="btn booking-flow-services-actions" [matMenuTriggerFor]="menu" *ngIf="additionalService.id === additionalServiceType.insurance &&
                    certificateSummary?.certificateLink && certificateSummary?.termsAndConditionsLink">
                      <mat-icon class="material-symbols-outlined"> more_horiz </mat-icon>
                    </div>

                    <mat-menu #menu backdropClass="booking-flow-services-dropdown">
                      <btn *ngIf="certificateSummary?.certificateLink" mat-menu-item class="dropdown-button"
                        (click)="navigateToLink(certificateSummary?.certificateLink)">
                        <span class="booking-flow-services-dropdown-text">Insurance Certificate</span>
                      </btn>
                      <btn *ngIf="certificateSummary?.termsAndConditionsLink" mat-menu-item class="dropdown-button"
                        (click)="navigateToLink(certificateSummary?.termsAndConditionsLink)">
                        <span class="booking-flow-services-dropdown-text">Terms of Service</span>
                      </btn>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="details" class="summary-card">
        <div class="summary-card-header mb-4">
          <div class="row m-0 gap-2">
            <div class="col-12 px-0 d-flex">
              <span class="card-header-title">Details</span>
            </div>
            <div class="col-12 px-0 d-flex">
              <span class="card-header-description">Upload relevant documents for your booking</span>
            </div>
          </div>
        </div>
        <div class="summary-card-content gap-4">
          <div class="row m-0 gap-2">
            <div class="col-12 px-0 d-flex">
              <lib-drag-drop class="card-uploader" [mode]="fileUploaderMode.all" [count]="7" [canDownload]="false"
                [files]="bookingSummary?.documents" [maxFileSize]="5242880" [supportedFormats]="supportedFileFormats"
                [layoutType]="fileUploaderLayoutType.client" [isSecuredUrl]="true"
                [title]="'You can drop here any files related to the booking. Click here to select them.'"
                (filesChange)="onListingFilesChange($event)" (getSecureFileUrl)="onGetSecureFileUrl($event)">
              </lib-drag-drop>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-flow-summary-footer-wrapper">
    <div class="booking-flow-summary-footer summary-footer-wrapper">
      <app-booking-flow-details *ngIf="bookingSummary" [bookingSummary]="bookingSummary"
        [isCreditOrganization]="isCreditOrganization" [hideButtons]="hideButtons" [isSubmitLoading]="isSubmitLoading"
        [isView]="true"></app-booking-flow-details>
    </div>
  </div>
</div>
