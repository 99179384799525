import { Injectable } from '@angular/core';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { SignJWT, importPKCS8 } from 'jose';
import { environment } from 'src/environments/environment';
import { UserInfoService } from './user-info/user-info.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesforceUserInfo {
  fullName?: string;
  email?: string;
  companyName?: string;
  phoneNumber?: string;
  organizationId?: string;
  userId?: string;
}

interface Window {
  embeddedservice_bootstrap: any;
}

const embedded_svc = (window as any).embedded_svc;

@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
  environmentsService: EnvironmentsService;
  environmentName: string;

  windowRef = window as unknown as Window;
  sfTimer: any;
  sfCounter: number = 0;

  constructor(public userInfoService: UserInfoService) {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
    this.initEventListeners();
  }

  addUserChat(): void {
    if (!this.getIsNewSfChatEnabled()) {
      const salesforceUrl = this.getSalesforceUrl();
      const salesforceId = this.getSalesforceId();
      const salesforceSetupFlow = this.getSalesforceSetupFlow();
      const salesforceChatName = this.getSalesforceChatName();
      const salesforceEmbeddedUrl = this.getSalesforceEmbeddedUrl();
      const salesforceBaseLiveAgentContentURL = this.getSalesforceBaseLiveAgentContentURL();
      const salesforceBaseLiveAgentURL = this.getSalesforceBaseLiveAgentURL();
      const salesforceEswLiveAgentDevName = this.getSalesforceEswLiveAgentDevName();

      if (!window.location.pathname.includes('booking/pdf')) {
        const initESW = (gslbBaseURL: string | null) => {
          embedded_svc.settings.displayHelpButton = true;
          embedded_svc.settings.language = '';
          embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          embedded_svc.settings.entryFeature = 'LiveAgent';

          const userInfo: SalesforceUserInfo = JSON.parse(localStorage.getItem('chatUserInfo') || '{}');

          embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName: userInfo.fullName?.split(' ')[0],
            LastName: userInfo.fullName?.split(' ')[1],
            Email: userInfo.email,
            Company: userInfo.companyName,
            Phone: userInfo.phoneNumber
          };

          embedded_svc.init(salesforceUrl, salesforceSetupFlow, gslbBaseURL, salesforceId, salesforceChatName, {
            baseLiveAgentContentURL: salesforceBaseLiveAgentContentURL,
            deploymentId: '5724K0000004PM5',
            buttonId: '5734K0000004Puj',
            baseLiveAgentURL: salesforceBaseLiveAgentURL,
            eswLiveAgentDevName: salesforceEswLiveAgentDevName,
            isOfflineSupportEnabled: true
          });
        };

        if (!embedded_svc) {
          const s = document.createElement('script');
          s.setAttribute('src', salesforceEmbeddedUrl);
          s.onload = () => {
            initESW(null);
          };
          document.body.appendChild(s);
        } else {
          initESW('https://service.force.com');
        }
      }
    } else {
      if (!this.windowRef.embeddedservice_bootstrap) {
        const embededUrl = this.getNewSalesforceChatEmbededUrl();

        const s = document.createElement('script');
        s.setAttribute(
          'src',
          embededUrl
        );
        s.onload = () => {
          this.addNewUserChat();
        };
        document.body.appendChild(s);
      }
    }
  }

  onUserLogout(): void {
    if (this.windowRef?.embeddedservice_bootstrap?.userVerificationAPI) {
      this.windowRef.embeddedservice_bootstrap.userVerificationAPI.clearSession().catch(error => {
        console.error('Error during logout:', error);
      });
    }
  }

  async performActionWithTimeout(): Promise<string> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const result = 'Action completed after timeout';
        resolve(result);
      }, 2000); // 2000 milliseconds = 2 seconds
    });
  }

  async openChat(): Promise<boolean> {
    const helpChatButton: HTMLElement = document.getElementById("embeddedMessagingConversationButton") as HTMLElement;

    return new Promise((resolve) => {
      if (helpChatButton.style.display === 'none') {
        this.onUserLogout();

        setTimeout(() => {
          this.addUserChat();
          helpChatButton.click();

          resolve(true);
        }, 1000);
      }
      else {
        helpChatButton.click();

        resolve(true)
      }
    });
  }

  private addNewUserChat(): void {
    try {
      const salesforceNewChatId = this.getNewSalesforceChatId();
      const salesforceNewChatName = this.getNewSalesforceChatName();
      const salesforceNewChatUrl = this.getNewSalesforceChatUrl();
      const salesforceNewChatScriptUrl = this.getNewSalesforceChatScriptUrl();

      this.windowRef.embeddedservice_bootstrap.settings.language = 'en_US';
      this.windowRef.embeddedservice_bootstrap.init(salesforceNewChatId, salesforceNewChatName, salesforceNewChatUrl, {
        scrt2URL: salesforceNewChatScriptUrl
      });
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  }

  private initEventListeners(): void {
    window.addEventListener('onEmbeddedMessagingReady', () => this.onEmbeddedMessagingReady());
  }

  private onEmbeddedMessagingReady(): void {
    const chatUserInfo: SalesforceUserInfo = JSON.parse(localStorage.getItem('chatUserInfo') || '{}');
    const userId = this.userInfoService.getUserId();
    const fullNameParts = chatUserInfo.fullName?.split(' ') || [];

    this.windowRef.embeddedservice_bootstrap.settings.embeddedServiceConfig.customLabels = [
      { labelName: '_firstName', labelValue: fullNameParts[0] },
      { labelName: '_lastName', labelValue: fullNameParts.slice(1).join(' ') },
      { labelName: '_email', labelValue: chatUserInfo.email },
      { labelName: 'Company', labelValue: chatUserInfo.companyName },
      { labelName: 'Phone', labelValue: chatUserInfo.phoneNumber ?? '-' },
      { labelName: 'CompanyId', labelValue: chatUserInfo.organizationId },
      { labelName: 'UserId', labelValue: userId }
    ];

    this.windowRef.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
      CurrentUserUrl: window?.location?.href || '',
      CompanyId: chatUserInfo?.organizationId || '',
      UserId: userId || '',
      FirstName: fullNameParts[0],
      LastName: fullNameParts.slice(1).join(' '),
      Email: chatUserInfo.email,
      Company: chatUserInfo.companyName,
      Phone: chatUserInfo.phoneNumber ?? '-'
    });

    this.generateSalesforceNewChatJwtToken(userId)
      .then(salesforceNewChatJwt => {
        this.windowRef.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
          identityTokenType: 'JWT',
          identityToken: salesforceNewChatJwt
        });
      })
      .then(() => {
        this.sfTimer = setInterval(() => {
          this.autoCloseChat();
        }, 100)
      })
      .catch(error => {
        console.error('Failed to set Salesforce chat identity token:', error);
      });
  }

  private async generateSalesforceNewChatJwtToken(userId: string): Promise<string> {
    const privateKey = await importPKCS8(this.getNewSalesforceChatPrivateKey(), 'RS256');
    const headerId = this.getIsNewSfChatHeaderId();

    const token = await new SignJWT({ sub: userId, iss: 'Ship4wd' })
      .setProtectedHeader({ alg: 'RS256', typ: 'JWT', kid: headerId })
      .setIssuedAt()
      .setExpirationTime('1d')
      .sign(privateKey);

    return token;
  }

  getIsNewSfChatEnabled(): boolean {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.isNewSfChatEnabled;
      case 'sb':
        return environment.sb.salesforce.isNewSfChatEnabled;
      default:
        return environment.salesforce.isNewSfChatEnabled;
    }
  }

  getSalesforceUrl(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.url;
      case 'sb':
        return environment.sb.salesforce.url;
      default:
        return environment.salesforce.url;
    }
  }

  getSalesforceId(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.id;
      case 'sb':
        return environment.sb.salesforce.id;
      default:
        return environment.salesforce.id;
    }
  }

  getSalesforceSetupFlow(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.setupFlow;
      case 'sb':
        return environment.sb.salesforce.setupFlow;
      default:
        return environment.salesforce.setupFlow;
    }
  }

  getSalesforceChatName(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.chatName;
      case 'sb':
        return environment.sb.salesforce.chatName;
      default:
        return environment.salesforce.chatName;
    }
  }

  getSalesforceEmbeddedUrl(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.embeddedUrl;
      case 'sb':
        return environment.sb.salesforce.embeddedUrl;
      default:
        return environment.salesforce.embeddedUrl;
    }
  }

  getSalesforceBaseLiveAgentContentURL(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.baseLiveAgentContentURL;
      case 'sb':
        return environment.sb.salesforce.baseLiveAgentContentURL;
      default:
        return environment.salesforce.baseLiveAgentContentURL;
    }
  }

  getSalesforceBaseLiveAgentURL(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.baseLiveAgentURL;
      case 'sb':
        return environment.sb.salesforce.baseLiveAgentURL;
      default:
        return environment.salesforce.baseLiveAgentURL;
    }
  }

  getSalesforceEswLiveAgentDevName(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.eswLiveAgentDevName;
      case 'sb':
        return environment.sb.salesforce.eswLiveAgentDevName;
      default:
        return environment.salesforce.eswLiveAgentDevName;
    }
  }

  getNewSalesforceChatId(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.id;
      case 'sb':
        return environment.sb.salesforce.newChat.id;
      default:
        return environment.salesforce.newChat.id;
    }
  }

  getNewSalesforceChatName(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.name;
      case 'sb':
        return environment.sb.salesforce.newChat.name;
      default:
        return environment.salesforce.newChat.name;
    }
  }

  getNewSalesforceChatUrl(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.url;
      case 'sb':
        return environment.sb.salesforce.newChat.url;
      default:
        return environment.salesforce.newChat.url;
    }
  }

  getNewSalesforceChatScriptUrl(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.scriptUrl;
      case 'sb':
        return environment.sb.salesforce.newChat.scriptUrl;
      default:
        return environment.salesforce.newChat.scriptUrl;
    }
  }

  getNewSalesforceChatEmbededUrl(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.embededUrl;
      case 'sb':
        return environment.sb.salesforce.newChat.embededUrl;
      default:
        return environment.salesforce.newChat.embededUrl;
    }
  }

  getIsNewSfChatHeaderId(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.headerId;
      case 'sb':
        return environment.sb.salesforce.newChat.headerId;
      default:
        return environment.salesforce.newChat.headerId;
    }
  }

  getNewSalesforceChatPrivateKey(): string {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.salesforce.newChat.privateKey;
      case 'sb':
        return environment.sb.salesforce.newChat.privateKey;
      default:
        return environment.salesforce.newChat.privateKey;
    }
  }

  private autoCloseChat(): void {
    this.sfCounter++;

    if (this.sfCounter >= 3000) {
      clearInterval(this.sfTimer);
      this.sfCounter = 0;
    }

    const chat = document.getElementById('embeddedMessagingFrame');

    if (chat) {
      if (chat.classList.contains('isMaximized')) {
        const button = document.getElementById('embeddedMessagingConversationButton');
        button.click();
        clearInterval(this.sfTimer);
        this.sfCounter = 0;
      }
    } else {
      clearInterval(this.sfTimer);
      this.sfCounter = 0;
    }
  }
}
