<div class="container-fluid toolbar-mobile"
  [ngClass]="{'hide-image' : hideImage, 'quote-flow': (mode === modes.QUOTEFLOW), 'booking-flow': (mode === modes.BOOKINGFLOW)}">
  <div>
    <div class="background-sprite sprite-1"></div>
    <div class="background-sprite sprite-2"></div>
    <div class="background-sprite sprite-3"></div>
  </div>
  <div class="row">
    <div *ngIf="showHome" class="col-auto align-self-center">
      <button mat-icon-button (click)="goHome()">
        <span class="home-icon"></span>
      </button>
    </div>
    <div *ngIf="showBack" class="col-auto">
      <button mat-icon-button (click)="goBack()">
        <span class="arrow-back-icon"></span>
      </button>
    </div>
    <div class="col align-self-center">
      <div class="row toolbar-mobile-content" [ngClass]="showHome || showBack ? 'px-0' : ''">
        <div *ngIf="title" class="col-12 px-0">
          <span class="toolbar-mobile-title">{{title}}</span>
        </div>
        <div *ngIf="description" class="px-0"
          [ngClass]="showHome && (mode === modes.FCL || mode === modes.LCL) ? 'col-9 toolbar-mobile-subtitle-wrapper' : (mode !== modes.FCL && mode !== modes.LCL && mode !== modes.AIR ? 'col-12' : 'col-9')">
          <div class="toolbar-mobile-subtitle">{{description}}</div>
        </div>
      </div>
    </div>
    <div class="col-auto align-self-center">
      <div *ngIf="mode === modes.USER || showUser === true" class="toolbar-mobile-profile">
        <span class="toolbar-mobile-profile-name" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">{{
          acronymName | uppercase }}</span>
        <mat-menu #menu="matMenu" class="profile-menu">
          <button *ngIf="showSwitchOrganizationMenu" mat-menu-item (click)="onShowAllOrganizations()"
            class="btn-profile no-dropdown-arrow switchOrganizations">
            <span>Switch Organizations</span>
          </button>
          <button mat-menu-item (click)="manageOrganization()" class="btn-profile manageOrganization"
            *ngIf="isManageOrganizationMenuItemAccess">
            <span>Manage Organization</span>
          </button>
          <button mat-menu-item (click)="manageContacts()" class="btn-profile manage-contact"
            *ngIf="isManageContactsMenuItemAccess">
            <span>Manage Contacts</span>
          </button>
          <button mat-menu-item (click)="logout()" class="btn-profile logout"
            [ngClass]="showSwitchOrganizationMenu || isManageOrganizationMenuItemAccess ? 'bt-1' : ''">
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
      <div *ngIf="mode === modes.PAYMENT && showUser === false" class="toolbar-mobile-profile">
        <span class="toolbar-mobile-more-button" [matMenuTriggerFor]="moreMenu" #menuTrigger="matMenuTrigger">
          <mat-icon>more_vert</mat-icon>
        </span>
        <mat-menu #moreMenu="matMenu" class="profile-menu">
          <ng-container *ngIf="moreMenuTemplate" [ngTemplateOutlet]="moreMenuTemplate"></ng-container>
        </mat-menu>
      </div>
      <div *ngIf="(mode === modes.FCL || mode === modes.LCL) && showUser === false" class="toolbar-mobile-ship"></div>
      <div *ngIf="mode === modes.AIR && showUser === false" class="toolbar-mobile-plane"></div>
    </div>
  </div>
</div>