<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="eligible-credit-line-wrapper">
  <div class="eligible-credit-line-container">
    <div class="eligible-credit-line-image">
      <span class="rocket-icon"></span>
    </div>
    <div class="eligible-credit-limit-content">
      <h3 class="eligible-credit-limit-title">
        You are Pre-approved to the get
        <span class="eligible-credit-limit-title-text">$10,000</span> credit
        line
      </h3>
      <p class="eligible-credit-limit-content">
        Your business has been pre-approved for a $10,000 credit line.​
        <br />
        <span class="strong-text">Order now and pay up to 90 days after cargo arrival​.</span>
      </p>
      <button type="button" class="eligible-credit-limit-claim" (click)="claimCreditLine()">
        Claim Now
      </button>
      <div class="eligible-credit-limit-eligible-content">
        <span class="eligible-credit-limit-eligible-term">To be Eligible You need:</span>
        <div class="eligible-credit-limit-eligible-term-content">
          <span class="eligible-credit-limit-eligible-term-content-text">
            <i class="s4d-icon s4d-check-circle"></i> To connect your business
            bank account
          </span>
          <span class="eligible-credit-limit-eligible-term-content-text">
            <i class="s4d-icon s4d-check-circle"></i> A bank account that was
            opened more than 6 months ago
          </span>
          <span class="eligible-credit-limit-eligible-term-content-text">
            <i class="s4d-icon s4d-check-circle"></i> A monthly revenue above
            10,000 USD
          </span>
        </div>
      </div>
    </div>
  </div>
</div>