import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "@ship4wd/ngx-common";
import { AppSettingsService } from "../../services/app-settings/app-settings.service";
import { BankDetails } from "../payments.model";
import { PaymentsService } from "../payments.service";

@Component({
  selector: "app-payment-bank-details",
  templateUrl: "./payment-bank-details.component.html",
  styleUrls: ["./payment-bank-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentBankDetailsComponent implements OnInit {
  organizationId: string;
  bankDetails: BankDetails = null;
  isLoading = true;
  isMobile = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentBankDetailsComponent>,
    private settingsService: AppSettingsService,
    private paymentsService: PaymentsService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.isMobile = this.data.isMobile;

    if (this.organizationId) {
      this.paymentsService
        .getBankDetailsbyOrganization(this.organizationId)
        .subscribe(
          (data: BankDetails) => {
            this.bankDetails = data;
          },
          (error) => this.notificationService.error(error)
        )
        .add(() => (this.isLoading = false));
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
