<form [formGroup]="selectLanguageform">
  <mat-form-field class="input-default">
    <mat-select formControlName="language" name="language" [placeholder]="'common.selectLanguage' | translate"
      [value]="language" [(ngModel)]="language">
      <mat-option *ngFor="let language of allLanguages" [value]="language?.languageCode">
        {{language?.languageName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button type="button" class="button-default" (click)="submit()">Ok</button>
</form>
