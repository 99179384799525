<div class="col px-0 d-flex justify-content-end align-items-center">
  <div class="shipment-type-label">
    <span class="label-text"> {{ booking?.shipmentTypeCode | shipmentTypeCargoName }} {{ booking?.shipmentTypeCode !==
      shipmentType.AIR ? 'Ocean' : '' }}</span>
  </div>
</div>
<div class="order">
  <div class="trip-details">
    <div class="row m-0 trip">
      <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center line-dashed-wrapper">
        <div class="trip-icon-wrapper">
          <i class="trip-icon s4d-icon" [ngClass]="booking?.fromBookingSearch?.locationType | locationTypeS4DIcon"></i>
        </div>
      </div>
      <div class="col  pe-0 d-flex flex-column mb-3 countryname-wrapper">
        <span class="countryname">
          {{ booking?.fromDisplayName }}
          <i class="fi"
            [ngClass]="'fi-' + (booking?.fromBookingSearch?.country ? booking?.fromBookingSearch?.country.toLowerCase() : '')"></i>
        </span>
        <span class="trip-date">
          {{ checkDateNotNullOrEmpty(booking.etd) ? (booking.etd | date) : "Schedule Not Available" }}
        </span>
      </div>

      <div *ngIf="booking.isCanceled" class="canceled"></div>
      <div class="row even-content-wrapper">
        <div class="order-status-inner col-auto px-0">
          <p class="pre-carriage-icon m-0 s4d-icon s4d-truck" [hidden]="!displayStatusIcon(carriageTypes.preCarriage)">
          </p>
          <p class="main-carriage-icon m-0 s4d-icon" [hidden]="!displayStatusIcon(carriageTypes.mainCarriage)"
            [ngClass]="booking.shipmentTypeCode === shipmentType.AIR ? 's4d-ac' : 's4d-ship'"></p>
          <p class="post-carriage-icon m-0 s4d-icon s4d-truck"
            [hidden]="!displayStatusIcon(carriageTypes.postCarriage)"></p>
          <p class="trip-status-icon m-0"></p>
        </div>

        <div class="m-0 trip col even-content">
          <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
          </div>
          <div class="col  d-flex flex-column mb-4">
            <span class="dayshours">{{ !isTripDurationDefaultValue(booking.tripDuration) ?
              (booking.tripDuration | duration:false) : "~30 Days" }}</span>
          </div>
        </div>
      </div>
      <div class="p-0 row m-0 trip mb-3">
        <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
          <div class="trip-icon-wrapper">
            <i class="trip-icon s4d-icon" [ngClass]="booking?.toBookingSearch?.locationType | locationTypeS4DIcon"></i>
          </div>
        </div>
        <div class="col pe-0 d-flex flex-column countryname-wrapper">
          <span class="countryname">
            {{ booking?.toDisplayName }}
            <i class="fi"
              [ngClass]="'fi-' + (booking?.toBookingSearch?.country ? booking?.toBookingSearch?.country.toLowerCase() : '')"></i>
          </span>
          <span class="trip-date">
            {{ checkDateNotNullOrEmpty(booking.eta) ? (booking.eta | date) : "Schedule Not Available" }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="quote-info-wrapper">
    <div class="row quoteinfo-innner-content justify-content-between"
      *ngIf="booking?.statusType?.ship4wdShortFrontendStatus">
      <span class="quote-info-name col-auto">Status</span>
      <span class="quote-info-value submitted col-auto">{{ booking?.statusType?.ship4wdShortFrontendStatus }}</span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between"
      *ngIf="checkDateNotNullOrEmpty(booking?.cutOffDate)">
      <span class="quote-info-name col-auto">Cut-Off Date</span>
      <span class="quote-info-value col-auto">{{ booking?.cutOffDate | date }}</span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between" *ngIf="booking?.createTimeUtc">
      <span class="quote-info-name col-auto">Order Created</span>
      <span class="quote-info-value col-auto">{{ booking?.createTimeUtc | date:'MMM d, y' }}</span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between"
      *ngIf="booking?.bookingAdditionalInformation?.vesselName">
      <span class="quote-info-name col-auto">Vessel Name</span>
      <span class="quote-info-value col-auto">{{ booking?.bookingAdditionalInformation?.vesselName }}</span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between"
      *ngIf="booking?.bookingAdditionalInformation?.voyage">
      <span class="quote-info-name col-auto">Voyage</span>
      <span class="quote-info-value col-auto">{{ booking?.bookingAdditionalInformation?.voyage }}</span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between">
      <span class="quote-info-name col-auto">Price Guarantee</span>
      <span class="quote-info-value col-auto">
        <i class="s4d-icon s4d-verified-filled"></i>
        <span class="quote-info-value price">Fixed Price</span>
      </span>
    </div>
    <div class="row quoteinfo-innner-content justify-content-between" *ngIf="booking?.bolOrderNumber">
      <span class="quote-info-name col-auto">BOL Number</span>
      <span class="quote-info-value col-auto">{{ booking?.bolOrderNumber }}</span>
    </div>
  </div>

  <div class="order-bottom">
    <div class="row order-buttons-wrapper">
      <div class="col">
        <div *ngIf="isShowPayButton(booking)" class="btn submit-button" [ngClass]="booking.isCanceled ? 'canceled' : ''"
          (click)="onSubmitButton(booking)">
          {{ getSubmitButtonText(booking) }}</div>
      </div>
      <div class="col-auto ps-0">
        <div class="btn order-actions">
          <mat-icon class="material-symbols-outlined" [matMenuTriggerFor]="menu"> more_vert </mat-icon>
        </div>
        <mat-menu #menu backdropClass="order-dropdown">
          <button *ngIf="!isSubmitted(booking) && !booking.isCanceled" mat-menu-item class="dropdown-button"
            [routerLink]="['/booking', booking.id, true]" [disabled]="isLoading">
            <span class="update-order-icon"></span>
            <span class="order-dropdown-text">Update Order</span>
          </button>
          <button *ngIf="!isSubmitted(booking)" mat-menu-item class="dropdown-button"
            [routerLink]="['/booking/view', 1, booking.id]" [disabled]="isLoading">
            <span class="view-order-icon"></span>
            <span class="order-dropdown-text">View Order</span>
          </button>
          <button *ngIf="isSubmitted(booking)" mat-menu-item class="dropdown-button" (click)="getConversation()"
            [disabled]="isLoading">
            <span class="contact-support-icon"></span>
            <span class="order-dropdown-text">Contact Support</span>
          </button>
          <button *ngIf="isSubmitted(booking)" mat-menu-item class="dropdown-button" (click)="onGetSteps()"
            [disabled]="isLoading">
            <i class="check-icon s4d-icon s4d-check-circle"></i>
            <span class="order-dropdown-text">Booking Completion Steps</span>
          </button>
          <button mat-menu-item class="dropdown-button" (click)="onFilesDocuments(booking.id)" [disabled]="isLoading">
            <span class="files-docs-icon"></span>
            <span class="order-dropdown-text">Files & Docs</span>
          </button>
          <button *ngIf="isShowShippingInstructions(booking)" mat-menu-item class="dropdown-button"
            [routerLink]="['/shipping-instructions', booking.id]" [disabled]="isLoading">
            <span class="shipping-instructions-icon"></span>
            <span class="order-dropdown-text">Shipping Instructions</span>
          </button>
          <button *ngIf="!booking.isCanceled && allowedRebook" mat-menu-item class="dropdown-button"
            (click)="onRebook(booking)" [disabled]="isLoading">
            <span class="reuse-icon"></span>
            <span class="order-dropdown-text">Rebook autopilot</span>
          </button>
          <div *ngIf="!booking.isCanceled" class="dropdown-separator"></div>
          <button *ngIf="!booking.isCanceled && !isHigherOrderReceived(booking)" mat-menu-item class="dropdown-button"
            (click)="onCancelOrder(booking)" [disabled]="isLoading">
            <span class="cancel-order-icon"></span>
            <span class="cancel-order-text"> Cancel Order</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
