import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import {
  AnotherReference,
  Note,
  ReferenceSelectItem
} from '../../../../shared/bookings/bookings.model';

@Component({
  selector: 'app-booking-reference',
  templateUrl: './booking-reference.component.html',
  styleUrls: ['./booking-reference.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingReferenceComponent implements OnInit {
  bookingForm: FormGroup;
  referenceTypes: ReferenceSelectItem[] = [{ name: 'PO Number', value: 1 }];

  @Output() addAnotherReference = new EventEmitter<AnotherReference>();
  @Output() removeAnotherReference = new EventEmitter();
  @Output() addNote = new EventEmitter<Note>();
  @Output() generatedRefID = new EventEmitter<string>();

  @ViewChild('tooltip') tooltip: MatTooltip;

  get isHideAnotherRef(): boolean {
    return !this.bookingForm.get('customerReferenceId')?.value;
  }

  constructor(private controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.bookingForm = this.controlContainer.control as FormGroup;
  }

  ngAfterViewInit() {
    this.tooltip.show();
    this.changeDetectorRef.detectChanges();
    setTimeout(() => this.tooltip.hide(5000));
  }

  onGenerateRefID() {
    this.generatedRefID.emit();
  }

  onRemoveCustomerReference() {
    this.bookingForm.get("customerReferenceId").patchValue(null);
  }

  onAddAnotherRef() {
    this.addAnotherReference.emit();
  }

  onRemoveAnotherRef(index) {
    this.removeAnotherReference.emit(index);
  }
}
