<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="isOrganizationLoaded" [hidden]="isLoading">
  <app-dashboard-header [isOrdersGridViewMode]="isOrdersGridViewMode" [isFinance]="true"
    (filters)="onFiltersEvent($event)" (searchText)="onSearchText($event)"
    (clearFilters)="onClearFilters($event)"></app-dashboard-header>
  <app-order-list [isLoading]="isLoading" (isLoadingChanged)="onLoadingChange($event)"
    [isOrdersGridViewMode]="isOrdersGridViewMode" [searchText]="searchText" [filters]="filters" [isFinance]="true"
    [isClearFilters]="isClearFilters"></app-order-list>
</div>