<div class="container-fluid payment-offline-container" [ngClass]="{'p-0': isMobile === true}">
  <div [ngClass]="{'mobile-wrapper background-air-cloud': isMobile === true}"></div>
  <div [ngClass]="{
    'background-fcl-wrapper': type === shipmentType.FCL,
    'background-lcl-wrapper': type === shipmentType.LCL,
    'background-air-wrapper': type === shipmentType.AIR,
    'mobile-wrapper': isMobile === true
    }"></div>
  <div class="row justify-content-center align-items-center px-3 done-container">
    <div class="col col-lg-6 col-xl-4 done-width" [ngClass]="{'mobile-wrapper': isMobile === true}">
      <div class="row">
        <div class="col-12 d-flex justify-content-center pt-4">
          <div class="icon-background">
            <img class="main-icon" src="../../../assets/images/payments/bank.svg" alt="Offline Payment" />
          </div>
        </div>
        <div class="col-12 text-center pt-3">
          <h1 class="done-title">Offline Payment</h1>
        </div>
        <div class="col-12 text-center pt-2">
          <h4 class="done-subtitle">
            Your payment request has been acknowledged, please make an offline
            wire bank transfer using the provided details. You can monitor the
            progress of your payment by accessing your finance dashboard with
            the payment <span class="identification" (click)="onFinanceDashboard()">ID {{id}}</span>,
            After bank transfer please upload proof of transfer via the dashboard.
          </h4>
          <div class="row">
            <div class="col" [ngClass]="{'order-2': isMobile === true}">
              <button mat-flat-button class="show-button" [ngClass]="{'w-100': isMobile === true}" (click)="onDetails()">
                Show Bank Details
              </button>
            </div>
            <div class="col" [ngClass]="{'mb-3 order-1': isMobile === true}">
              <button mat-flat-button class="done-button" [ngClass]="{'w-100': isMobile === true}" (click)="onDashboard()">
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>