import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CountryService } from "../countries/country.service";
import { Country } from "../countries/country.model";

@Component({
  selector: "app-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrls: ["./phone-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PhoneInputComponent implements OnInit, AfterViewInit {
  @Input() fromControl: FormControl = undefined;
  @Input()
  get selectedCountry(): string {
    return this._selectedCountry;
  }
  @Output() errorMessage = new EventEmitter<string>();

  set selectedCountry(value: string) {
    this._selectedCountry = value;

    if (this._selectedCountry)
      this.selectedCountryForm = this.countries.find(
        (country) => country.code == value
      );
  }

  @Input() enableAutoCountrySelect = true;
  @Input() searchCountryFlag = false;
  @Input() label: string = 'Phone number (international format)'

  @Output() firstFocus: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;

  _selectedCountry: string = "US";
  phoneForm: FormGroup = undefined;
  countries: Country[] = [];
  selectedCountryForm: Country = null;

  constructor(
    private el: ElementRef,
    private countryService: CountryService) { }

  ngOnInit(): void {
    this.countries = this.countryService.getCountries();
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl(
        {
          value: this.checkPhoneNumberPrefix(this.fromControl.value),
          disabled: this.fromControl.disabled,
        },
        [Validators.required]
      ),
    });
    this.onChanges();
  }

  ngAfterViewInit() {
    const inputList: HTMLInputElement[] = Array.from(this.el.nativeElement.getElementsByTagName('input'));

    const focusHandler = () => {
      this.firstFocus.emit();
      inputList.forEach(input => input.removeEventListener('focus', focusHandler));
    };

    inputList.forEach((input: HTMLInputElement) => {
      input.addEventListener('focus', focusHandler);
    });
  }

  onChanges(): void {
    this.phoneForm.get("phoneNumber").valueChanges.subscribe(() => {
      const control = this.phoneForm.get("phoneNumber");

      if (control.value?.countryCode)
        this.selectedCountryForm = this.countries.find(
          (country) => country.code == control.value?.countryCode
        );

      control.touched || (control.invalid && control.value !== "" && control.value !== null)
        ? this.fromControl.markAsTouched()
        : this.fromControl.markAsUntouched();

      this.fromControl.patchValue(control.value?.e164Number);

      this.fromControl.setErrors(
        control.errors
          ? {
            invalidPhoneNumber: !control.errors?.validatePhoneNumber?.valid,
          }
          : null
      );
    });
  }

  handleError(event: string): void {
    this.errorMessage.emit(`Please provide a valid phone number ${this.selectedCountryForm?.name ? "for " + this.selectedCountryForm?.name : ''}`);
  }

  focusPhoneInputElement(): void {
    const phoneInputElement = this.el.nativeElement.querySelector('input');
    if (phoneInputElement)
      phoneInputElement.focus();
  }

  private checkPhoneNumberPrefix(phoneNumber: string): string {
    if (phoneNumber !== null && phoneNumber !== "") {
      return phoneNumber.charAt(0) === "+" ? phoneNumber : '+' + phoneNumber;
    }
  }
}
