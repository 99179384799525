<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="booking-flow-summary" [hidden]="isLoading">
  <div class="booking-flow-summary-header">
    <div class="booking-flow-summary-header-background">
      <div class="background-sprite sprite-1"></div>
      <div class="background-sprite sprite-2"></div>
      <div class="background-sprite sprite-3"></div>
    </div>
    <div class="container booking-flow-summary-header-actions">
      <div class="row py-4">
        <div class="col-auto ps-0">
          <button class="header-button" (click)="onBackBooking()">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-arrow-left"></i>
            </div>
            Back to Booking
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative h-100 d-flex">
    <div class="booking-flow-summary-container">
      <div class="booking-flow-summary-wrapper">
        <div class="row align-items-start">
          <div class="col-8">
            <div class="row">
              <div class="col-12">
                <div class="booking-flow-summary-content mb-4">
                  <div class="booking-flow-summary-content-title mb-4">
                    <h2 class="booking-summary-header-title">Summary</h2>
                    <button mat-flat-button class="booking-summary-header-action">
                      <img src="../../../../../assets/images/bookings/print.svg" (click)="print(bookingSummary?.id)"
                        alt="Print" />
                    </button>
                  </div>
                  <mat-form-field class="w-100 mb-4" appearance="fill" [floatLabel]="'auto'"
                    *ngIf="customerReferenceControl.value">
                    <mat-label class="form-label">Customer reference</mat-label>
                    <div class="row h-100">
                      <div class="col d-flex align-items-end">
                        <input matInput [formControl]="customerReferenceControl" placeholder="Customer reference" />
                      </div>
                    </div>
                  </mat-form-field>
                  <mat-form-field class="w-100 mb-4" appearance="fill" [floatLabel]="'auto'"
                    *ngIf="bookingSummary?.masterBillOfLading">
                    <mat-label class="form-label">Master Bill of Lading</mat-label>
                    <div class="row h-100">
                      <div class="col d-flex align-items-end disabled">
                        <input matInput [value]="bookingSummary?.masterBillOfLading" placeholder="Master Bill of Lading"
                          disabled />
                      </div>
                    </div>
                  </mat-form-field>
                  <mat-form-field class="w-100 mb-4" appearance="fill" [floatLabel]="'auto'"
                    *ngIf="bookingSummary?.bolOrderNumber">
                    <mat-label class="form-label">Order ID</mat-label>
                    <div class="row h-100">
                      <div class="col d-flex align-items-end disabled">
                        <input matInput [value]="bookingSummary?.bolOrderNumber" placeholder="Order ID" disabled />
                      </div>
                    </div>
                  </mat-form-field>
                  <div class="customer-details-container">
                    <div class="order-summary-section-title">
                      <h3>Customer Details</h3>
                    </div>
                    <div class="customer-contact-container">
                      <div class="row mx-0">
                        <div class="customer-contact col-12 px-0">
                          <div class="customer-contact-title">
                            <mat-label class="form-label">Contact at Pickup</mat-label>
                            <div class="customer-contact-name">
                              <h4>
                                {{ bookingSummary?.shipperContact?.firstName }}
                                {{ bookingSummary?.shipperContact?.lastName }}
                                {{ bookingSummary?.shipperContact?.address1 ? ', ' +
                                bookingSummary?.shipperContact?.address1 : '' }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="customer-contact col-12 px-0">
                          <div class="customer-contact-title">
                            <mat-label class="form-label">Contact at Delivery</mat-label>
                            <div class="customer-contact-name">
                              <h4>
                                {{ bookingSummary?.consigneeContact?.firstName }}
                                {{ bookingSummary?.consigneeContact?.lastName }}
                                {{ bookingSummary?.consigneeContact?.address1 ? ', ' +
                                bookingSummary?.consigneeContact?.address1 : '' }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="customer-contact col-12 px-0">
                          <div class="customer-contact-title">
                            <mat-label class="form-label">Contact for Notify</mat-label>
                            <div class="customer-contact-name">
                              <h4>
                                {{ bookingSummary?.notifyPartyContact?.firstName }}
                                {{ bookingSummary?.notifyPartyContact?.lastName }}
                                {{ bookingSummary?.notifyPartyContact?.address1 ? ', ' +
                                bookingSummary?.notifyPartyContact?.address1 : '' }}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="bookingSummary?.shipmentTypeCode !== shipmentTypes.AIR">
                <div class="booking-flow-summary-content mb-4">
                  <div class="basic-ocean-freight-container">
                    <h3>Basic Ocean Freight</h3>
                    <div class="basic-ocean-freight-section-container row">
                      <div class="basic-ocean-freight-section-container-content row" *ngIf="
                          bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.vesselName ||
                          bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.voyage ||
                          bookingSummary?.quote?.trip?.carrierScac
                        ">
                        <div class="basic-ocean-freight-section col-4 p-0"
                          *ngIf="bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.vesselName">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">
                              {{ bookingSummary?.shipmentTypeCode === shipmentTypes.AIR ? 'Airline' : 'VESSEL NAME' }}
                            </mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>{{ bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.vesselName }}</h4>
                          </div>
                        </div>
                        <div class="basic-ocean-freight-section col-4 p-0"
                          *ngIf="bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.voyage">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label"> VOYAGE </mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{ bookingSummary?.quote?.trip.mainCarriageTripLeg?.legs[0]?.voyage }}
                            </h4>
                          </div>
                        </div>
                        <div class="basic-ocean-freight-section col-4 p-0">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">
                              Cut-Off Date
                            </mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{ getCutOffDateTitle(bookingSummary?.quote) }}
                            </h4>
                          </div>
                        </div>
                        <div class="basic-ocean-freight-section col-4 p-0"
                          *ngIf="bookingSummary?.quote?.trip?.carrierScac">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label"> CARRIER </mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>{{ bookingSummary?.quote?.trip?.carrierScac }}</h4>
                          </div>
                        </div>
                      </div>
                      <div class="basic-ocean-freight-section-container-content row">
                        <div class="basic-ocean-freight-section col-4 p-0">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">ESTIMATED DEPARTURE</mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{
                              checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.departureDate)
                              ? (bookingSummary?.quote?.trip?.departureDate | date : 'MMM dd, yyyy')
                              : 'Schedule Not Available'
                              }}
                            </h4>
                          </div>
                        </div>
                        <div class="basic-ocean-freight-section col-4 p-0">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">ESTIMATED ARRIVAL</mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{
                              checkDateNotNullOrEmpty(bookingSummary?.quote?.trip?.arrivalDate)
                              ? (bookingSummary?.quote?.trip?.arrivalDate | date : 'MMM dd, yyyy')
                              : 'Schedule Not Available'
                              }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="basic-ocean-freight-section-container-content row"
                        *ngIf="bookingSummary?.atd || bookingSummary?.ata">
                        <div class="basic-ocean-freight-section col-4 p-0" *ngIf="bookingSummary?.atd">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">Actual Time of Departure</mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{ bookingSummary?.atd }}
                            </h4>
                          </div>
                        </div>
                        <div class="basic-ocean-freight-section col-4 p-0" *ngIf="bookingSummary?.ata">
                          <div class="basic-ocean-freight-section-title">
                            <mat-label class="form-label">Actual Time of Arrival</mat-label>
                          </div>
                          <div class="basic-ocean-freight-section-title">
                            <h4>
                              {{ bookingSummary?.ata }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="booking-flow-summary-content">
                  <div *ngIf="bookingSummary?.shipmentTypeCode === shipmentTypes.FCL"
                    class="booking-summary-container-list">
                    <div class="booking-summary-container-section round_box"
                      *ngFor="let container of bookingSummary?.containers; let i = index">
                      <div class="booking-summary-container-section-title title_box d-block mb-2">
                        <h2>Container {{ i + 1 }}</h2>
                      </div>
                      <div class="booking-summary-container-row">
                        <div class="row">
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container?.equipmentTypeDescription">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">Container Type</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.equipmentTypeDescription }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container?.containerNumber">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">Container Number</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.containerNumber }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-4 mb-4" *ngIf="container?.sealNumber">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">Seal Number</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.sealNumber }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-3" *ngIf="container?.cargoGrossWeight">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">GROSS WEIGHT</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>
                                {{ container?.cargoGrossWeight }}
                                {{ container?.cargoGrossWeightUnitCode | weightUnitName }}
                              </h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-5 mb-4"
                            *ngIf="container?.commodities.length">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COMMODITY CATEGORY</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name"
                              *ngFor="let commodity of container.commodities">
                              <h4>{{ commodity?.commodityDescription || 'n/a' }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div class="booking-summary-container-sub-section my-4">
                        <div class="booking-summary-container row">
                          <h3 class="haeding">Collection</h3>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container.collectionDate">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COLLECTION DATE</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.collectionDate | date : 'dd MMMM, yyyy' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container.collectionDate">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COLLECTION TIME</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.collectionDate | date : 'shortTime' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="bookingSummary.cutOffDate">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">CUT OFF Date</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ bookingSummary.cutOffDate | date : 'dd MMMM, yyyy' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-12"
                            *ngIf="bookingSummary.fromBookingSearch.displayName">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">FROM</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ getFormattedBookingAddress('from') }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div class="booking-summary-container-sub-section mt-4">
                        <div class="booking-summary-container row">
                          <h3 class="haeding">Delivery</h3>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container?.cargoExpectedReady">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">DELIVERY DATE</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.cargoExpectedReady | date : 'dd MMMM, yyyy' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-4 mb-4"
                            *ngIf="container?.cargoExpectedReady">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">DELIVERY TIME</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.cargoExpectedReady | date : 'shortTime' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-12"
                            *ngIf="bookingSummary.toBookingSearch.displayName">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">TO</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ getFormattedBookingAddress('to') }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                      bookingSummary?.shipmentTypeCode === shipmentTypes.LCL ||
                      bookingSummary?.shipmentTypeCode === shipmentTypes.AIR
                    " class="booking-summary-container-list">
                    <div *ngFor="let container of bookingSummary?.containers; let i = index"
                      class="booking-summary-container-section round_box mb-4">
                      <div *ngIf="bookingSummary?.shipmentTypeCode === shipmentTypes.LCL"
                        class="booking-summary-container-section-title title_box d-block mb-2">
                        <h2>
                          <span>LCL Shipment</span>
                        </h2>
                      </div>
                      <div *ngIf="bookingSummary?.shipmentTypeCode === shipmentTypes.AIR"
                        class="booking-summary-container-section-title title_box d-block mb-2">
                        <h2>
                          <span>AIR Shipment</span>
                        </h2>
                      </div>
                      <div class="booking-container-section-equipmentType">
                        <span>Cargo</span>
                      </div>
                      <div class="booking-summary-container-row">
                        <div class="row">
                          <div class="booking-summary-container-row-section col-6 pt-2">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">Commodity Category Code</mat-label>
                            </div>
                            <div *ngFor="let commodity of container.commodities; last as isLast"
                              class="booking-summary-container-row-section-name">
                              <h4>
                                {{ commodity?.commodityTypeCode }}
                                <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                              </h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-6 pt-2">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COMMODITY DESCRIPTION</mat-label>
                            </div>
                            <div *ngFor="let commodity of container.commodities; last as isLast"
                              class="booking-summary-container-row-section-name">
                              <h4>
                                {{ commodity?.commodityDescription }}
                                <span *ngIf="container.commodities.length > 1 && !isLast">:</span>
                              </h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-12 pt-2"
                            *ngFor="let commodity of container.commodities; let i = index">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">Load {{ i + 1 }} Details</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <span>
                                {{ commodity?.numberOfPackages }}
                                {{ commodity?.packageTypeCode | packageTypeName }}
                              </span>
                              <div *ngIf="isShowCommodityDimension(commodity)">
                                Dimensions (W) {{ commodity?.dimension.width }}
                                {{ commodity?.dimension.widthUnitCode | dimensionUnitName | lowercase }}
                                x (H) {{ commodity?.dimension.height }}
                                {{ commodity?.dimension.heightUnitCode | dimensionUnitName | lowercase }}
                                x (L) {{ commodity?.dimension.length }}
                                {{ commodity?.dimension.lengthUnitCode | dimensionUnitName | lowercase }}
                              </div>
                            </div>
                            <div class="booking-summary-container-row-section">
                              <div class="booking-summary-container-row-section-title">
                                <span>Grand Totals</span>
                              </div>
                              <div class="booking-summary-container-row-section-name"
                                *ngIf="commodity.volume && commodity.weight">
                                <span>Volume: {{ commodity?.volume }}
                                  {{ commodity.volumeUnitCode | volumeUnitName | titlecase }}, Weight:
                                  {{ commodity?.weight }}
                                  {{ commodity?.weightUnitCode | weightUnitName | lowercase }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-summary-container-sub-section mt-3">
                        <div class="booking-summary-container row">
                          <h3 class="haeding">Collection</h3>
                          <div class="booking-summary-container-row-section col-6">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COLLECTION DATE</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>
                                {{
                                container.collectionDate
                                ? (container?.collectionDate | date : 'dd MMMM, yyyy')
                                : 'n/a'
                                }}
                              </h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-6">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">COLLECTION TIME</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>
                                {{
                                container.collectionDate ? (container?.collectionDate | date : 'shortTime') : 'n/a'
                                }}
                              </h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-6"
                            *ngIf="bookingSummary.fromBookingSearch.displayName">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">FROM</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ getFormattedBookingAddress('from') }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-summary-container-sub-section mt-3">
                        <div class="booking-summary-container row">
                          <h3 class="haeding">Delivery</h3>
                          <div class="booking-summary-container-row-section col-6"
                            *ngIf="container?.cargoExpectedReady">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">DELIVERY DATE</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.cargoExpectedReady | date : 'dd MMMM, yyyy' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-6"
                            *ngIf="container?.cargoExpectedReady">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">DELIVERY TIME</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ container?.cargoExpectedReady | date : 'shortTime' }}</h4>
                            </div>
                          </div>
                          <div class="booking-summary-container-row-section col-6"
                            *ngIf="bookingSummary.toBookingSearch.displayName">
                            <div class="booking-summary-container-row-section-title">
                              <mat-label class="form-label">TO</mat-label>
                            </div>
                            <div class="booking-summary-container-row-section-name">
                              <h4>{{ getFormattedBookingAddress('to') }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="booking-flow-summary-content">
                  <div class="row doucment-uploaded-content">
                    <app-booking-flow-summary-document [booking]="booking"></app-booking-flow-summary-document>
                  </div>
                  <!-- notes -->
                  <div class="notes my-4">
                    <p class="form-label silver_chalice">NOTE (OPTIONAL)</p>
                    <form action="#">
                      <div class="booking-reference-field-box" [formGroup]="bookingCustomerNote"
                        *ngIf="bookingCustomerNote">
                        <textarea name="notes" class="form-control" formControlName="value"
                          placeholder="Leave comment..." rows="5"></textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-summary-right-content-wrapper col-4">
            <app-booking-flow-summary-sidebar *ngIf="bookingSummary" [bookingSummary]="bookingSummary"
              (onSubmit)="onSubmitBooking()" (onSubmitDraft)="onSaveDraft()"
              [isCreditOrganization]="isCreditOrganization"
              [isSubmitLoading]="isSubmitLoading"></app-booking-flow-summary-sidebar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
