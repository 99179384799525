import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PaymentBankDetailsComponent } from '../payment-bank-details/payment-bank-details.component';
import { ShipmentType } from '../../shared.model';

@Component({
  selector: 'app-payment-offline',
  templateUrl: './payment-offline.component.html',
  styleUrls: ['./payment-offline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentOfflineComponent implements OnInit {
  id: string;
  type = ShipmentType.FCL;
  shipmentType = ShipmentType;
  isMobile = false;
  
  constructor(public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.isMobile = data.isMobile ?? false;
    });
    
    this.route.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.type = params.type == 1 ? ShipmentType.LCL : 
                    params.type == 2 ? ShipmentType.FCL :
                    params.type == 3 ? ShipmentType.AIR :
                    ShipmentType.FCL;
      }
    );
  }

  onDashboard() {
    window.location.href = '/dashboard';
  }

  onDetails() {
    this.dialog.open(PaymentBankDetailsComponent, {
      panelClass: 'bank-details-mobile-wrapper',
      autoFocus: false,
      data: {
        refId: this.id,
        isMobile: this.isMobile
      },
    });
  }

  onFinanceDashboard(): void {
    window.location.href = '/finance';
  }
}
