<div class="quote-search-widget-container" [ngClass]="{ 'is-preview': preview }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div *ngIf="editable" class="col-auto me-2 widget-action">
        <mat-icon class="editable-icon">zoom_out_map</mat-icon>
      </div>
      <div class="col widget-title">
        <span>Book your shipment</span>
      </div>
      <div *ngIf="editable" class="col-auto widget-action">
        <span class="remove-icon link-action" (click)="onRemove()"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading || preview }">
      <div *ngIf="isLoading || preview" class="col is-loader">
        <div class="row h-100 gap-3 m-0">
          <div *ngFor="let num of range(loaderElementsNumber)" class="col content-wrapper loader-element"></div>
        </div>
      </div>
      <div *ngIf="!isLoading && isError && !preview" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>
      <div *ngIf="!isLoading && !isError && !preview" class="col p-0">
        <div class="row h-100 gap-3 m-0">
          <div *ngFor="let item of shippingTypes" class="col content-wrapper" (click)="onGoTo(item.value)">
            <div class="row m-0">
              <div class="col-auto shipping-type-icon-wrapper">
                <span class="shipping-type-icon" [ngClass]="item.icon"></span>
              </div>
            </div>
            <div class="row h-100 align-content-end">
              <div class="col-12">
                <span class="shipping-type-title">Book {{ item.value | shipmentTypeName }}</span>
              </div>
              <div class="col">
                <span class="shipping-type-text">{{ item.name }}</span>
              </div>
              <div class="col-auto d-flex align-items-end">
                <span class="shipping-type-go"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>