<div class="booking-flow-services" [formGroup]="bookingForm" *ngIf="bookingForm && additionalServices">
  <div class="mb-3 booking-flow-services-container" *ngFor="let additionalService of additionalServices; let i = index"
    [ngClass]="{ 'customs-additional-service': additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually }">
    <div class="booking-flow-services-container-wrapper">
      <div class="d-flex booking-flow-services-section" (click)="onAddServices(additionalService)">
        <div class="icon-flow-services-content-wrapper">
          <div class="booking-flow-services-import-icon d-flex align-items-center justify-content-center">
            <i class="panel-header-icon s4d-icon s4d-import"
              *ngIf="additionalService.id === additionalServiceType.customs || additionalService.id === additionalServiceType.customsManually"></i>
            <i class="panel-header-icon s4d-icon s4d-shield"
              *ngIf="[additionalServiceType.insuranceManually, additionalServiceType.insurance].includes(additionalService.id)"></i>
            <i class="panel-header-icon s4d-icon s4d-search"
              *ngIf="additionalService.id === additionalServiceType.preShipment"></i>
          </div>
          <div class="booking-flow-services-fields">
            <div class="booking-flow-services-title">
              <span>{{ additionalService?.name }}</span>
            </div>
            <div class="booking-flow-services-description">
              <span>{{ additionalService?.description }}</span>
            </div>
            <div class="booking-additional-service-amount">
              <div class="booking-flow-services-fees">
                <div
                  *ngIf="(selectedAdditionalService && additionalService.id === additionalServiceType.customs && selectedAdditionalService === additionalServiceType.customs); else priceTemplate">
                  <mat-spinner diameter="25"></mat-spinner>
                </div>
                <ng-template #priceTemplate>
                  <ng-container *ngIf="additionalService.id === additionalServiceTypes.customs">
                    <div class="booking-flow-services-title">
                      <span>{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                        getAdditionalServiceAmount(additionalService) : '$' +
                        getAdditionalServiceAmount(additionalService)
                        }}</span>
                      <span>{{ getAdditionalServiceAmount(additionalService, true) }}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="additionalService.id !== additionalServiceTypes.customs">
                    <div class="booking-flow-services-title">
                      <span>{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                        getAdditionalServiceAmount(additionalService) +
                        (additionalService.id === additionalServiceTypes.preShipment ? " per visit" : "") : ''
                        }}</span>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="action-icon-wrapper"
          *ngIf="additionalService.id === additionalServiceType.insurance ? !insuranceErrors.length : true">
          <i *ngIf="booking?.bookingAdditionalServiceCharges?.length === 0 || !isShowServiceButton(additionalService)"
            class="s4d-icon s4d-chevron-right action-icon"></i>
          <ng-container
            *ngIf="(booking?.bookingAdditionalServiceCharges?.length !== 0 && isShowServiceButton(additionalService))">
            <i *ngIf="!(removeAddServiceBtnLoading && selectedAdditionalService === additionalService.id)"
              class="s4d-icon s4d-check action-icon success"></i>
            <span>
              <mat-spinner diameter="25"
                *ngIf="removeAddServiceBtnLoading && selectedAdditionalService === additionalService.id"></mat-spinner>
            </span>
          </ng-container>
        </div>
      </div>
      <div class="d-flex align-items-center booking-flow-calculate-duties-section"
        *ngIf="additionalService.id === additionalServiceType.customs" (click)="onCostEstimator(additionalService)">
        <div class="booking-flow-services-calculator-icon d-flex align-items-center justify-content-center">
        </div>
        <div class="booking-flow-services-calculator-fields">
          <div class="booking-flow-services-calculator-title">
            <span>Import Cost Table</span>
          </div>
          <div class="booking-flow-services-calculator-description">
            <span>See the costs for your import, including purchase tax, duties & fees.</span>
          </div>
        </div>
        <div class="booking-flow-services-calculator-arrow-icon">
          <i class="panel-header-icon s4d-icon s4d-arrow-right"></i>
        </div>
      </div>
      <a href="https://www.gov.il/apps/taxes/importTable/" target="_blank"
        class="d-flex align-items-center mt-3 booking-flow-calculate-duties-section booking-flow-calculate-duties-link"
        *ngIf="additionalService.id === additionalServiceType.customsManually">
        <div class="booking-flow-services-calculator-icon d-flex align-items-center justify-content-center">
        </div>
        <div class="booking-flow-services-calculator-fields">
          <div class="booking-flow-services-calculator-title">
            <span>Import Cost Table</span>
          </div>
          <div class="booking-flow-services-calculator-description">
            <span>See the costs for your import, including purchase tax, duties & fees.</span>
          </div>
        </div>
        <div class="booking-flow-services-calculator-arrow-icon">
          <i class="panel-header-icon s4d-icon s4d-arrow-right"></i>
        </div>
      </a>
      <div class="booking-flow-insurance-errors row m-0 mt-3 gap-2"
        *ngIf="additionalService.id === additionalServiceType.insurance && insuranceErrors.length">
        <div class="booking-flow-insurance-error-icon col-auto p-0">
          <i class="panel-header-icon s4d-icon s4d-info"></i>
        </div>
        <div class="booking-flow-services-description col p-0">
          <span class="insaurance-text" *ngFor="let error of insuranceErrors">{{ error }}</span>
          <span *ngIf="isOrganizationPostalCodeMissing()" class="zip-code-update" (click)="onUpdateZipCode()">Update Information</span>
        </div>
      </div>
    </div>
  </div>

  <button mat-raised-button class="booking-next-button button-spinner" (click)="onReviewAndPay()"
    [disabled]="btnLoading || !isValid">
    <span class="button-inner">
      Review {{ !isCreditOrganization && !isAgent ? "& Pay" : "" }}
      <mat-spinner diameter="25" *ngIf="btnLoading"></mat-spinner>
    </span>
  </button>
</div>