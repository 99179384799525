<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #header>
  <div class="manage-organization-header-container">
    <div class="row manage-organization-header-container-wrapper">
      <div class="col px-0 border-end">
        <button mat-button class="page-selector" [ngClass]="isDetails ? 'active' : ''" (click)="onTabChange(0)">
          Details
        </button>
      </div>
      <div class="col px-0">
        <button mat-button class="page-selector" [ngClass]="!isDetails ? 'active' : ''" (click)="onTabChange(1)">
          Members
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="manage-organization-container" *ngIf="organization" [hidden]="isLoading">
  <div class="row">
    <div class="col">
      <div *ngIf="isDetails" class="manage-organization-details">
        <app-organization-detail [organizationForm]="organizationForm"
          [organizationRoleTypeCodes]="organizationRoleTypeCodes" (roles)="onChangeRoles($event)"
          [countryAutocompleteValue]="countryAutocompleteValue"></app-organization-detail>
      </div>
      <div *ngIf="!isDetails" class="manage-organization-members">
        <app-organization-members [organizationMembers]="organizationMembers" (teamMemberEvent)="onTeamMemberEvent()"
          (memberPageEvent)="onMemberPageEvent($event)"></app-organization-members>
      </div>
    </div>
  </div>
</div>
<app-navbar *ngIf="isNavigationShowing">
  <div class="col px-3 align-self-center manage-organization-buttons-wrapper">
    <div class="row">
      <div class="col">
        <button mat-stroked-button class="back-button" (click)="onBack()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button mat-raised-button class="next-button button-spinner" (click)="onSave()">
          <span class="button-inner">
            <span>
              Save<span class="hide-on"> Changes</span>
            </span>
            <mat-spinner diameter="25" *ngIf="isSubmit"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</app-navbar>