<div class="quote-search-flow-loader">
  <ng-container *ngIf="isLocations; else quotesTemplate">
    <div *ngFor="let num of range(3)">
      <div class="loader fw-6-25 fh-1 py-1"></div>
      <div *ngFor="let num of range(2)" class="row py-3" (click)="onSelect(location, locationGroup.key, true)">
        <div class="col-auto">
          <div class="loader fw-1-5 fh-1"></div>
        </div>
        <div class="col px-0">
          <div class="loader fw-8-75 fh-1"></div>
        </div>
        <div class="col-auto">
          <div class="loader fw-3-5 fh-1"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #quotesTemplate>
  <div class="loader-card">
    <div class="row m-0">
      <div class="col-auto p-0">
        <div class="loader left-label"></div>
      </div>
      <div class="col-auto p-0">
        <div class="loader right-label"></div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 px-0">
        <div class="p-3">
          <div class="row m-0">
            <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
              <div class="loader fw-1 fh-1"></div>
              <div class="line"></div>
            </div>
            <div class="col ps-3 pe-0 d-flex flex-column mb-4">
              <div class="loader fw-9 fh-1"></div>
              <div class="loader fw-7-5 fh-1 mt-1"></div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
              <div class="loader fw-1 fh-1"></div>
              <div class="line"></div>
            </div>
            <div class="col ps-3 pe-0 d-flex flex-column mb-4">
              <div class="loader fw-3-5 fh-1"></div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
              <div class="loader fw-1 fh-1"></div>
            </div>
            <div class="col ps-3 pe-0 d-flex flex-column">
              <div class="loader fw-9 fh-1"></div>
              <div class="loader fw-7-5 fh-1 mt-1"></div>
            </div>
          </div>
        </div>
        <mat-divider class="mx-3 my-2 divider"></mat-divider>
        <div class="p-3">
          <div class="row m-0">
            <div class="col px-0 d-flex align-items-center justify-content-start">
              <div class="loader fw-6-25 fh-1-5"></div>
            </div>
            <div class="col px-0 d-flex align-items-center justify-content-end">
              <div class="loader fw-6-25 fh-1-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 p-3 pt-2">
      <div class="col-12 px-0">
        <div class="loader w-100 fh-3"></div>
      </div>
      <div class="col-12 px-0 mt-3 d-flex justify-content-center">
        <div class="loader fw-9 fh-1-5"></div>
      </div>
    </div>
  </div>
</ng-template>