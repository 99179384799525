import { ContactType } from "../bookings.model";

export class BookingContactViewModel {
  bookingId: string;
  organizationId: string;
  originId?:string;
  contactTypeCode: ContactType;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  countryCode: string;
  state: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  taxId: string;
  customerCode: string;
}
