import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as uuid from 'uuid';
import { NotificationService } from "@ship4wd/ngx-common";
import { Booking, BookingStatusCode, UpdateBookingIsCanceled } from '../../../shared/bookings/bookings.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { BookingFilesDocumentsPage, BookingPaidStatusCode, BookingViewDetailsPage, CompletedOrderStatusType } from '../../../shared/shared.model';
import { SalesforceService } from '../../../shared/services/salesforce.service';

@Component({
  selector: 'app-order-menu',
  templateUrl: './order-menu.component.html'
})
export class OrderMenuComponent implements OnInit {
  allowedRebook: boolean = false;
  isLoading = false;

  @Input()
  booking: Booking;

  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();

  prohibitedSubmittedBookingStatuses = [BookingStatusCode.draft]

  get getCompletedOrderStatusType() {
    return CompletedOrderStatusType;
  }

  constructor(
    public router: Router,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
    private salesforceService: SalesforceService
  ) { }

  ngOnInit(): void {
    this.allowedRebook = (this.booking.statusType.code != BookingStatusCode.draft ||
      this.getCompletedOrderStatusType.includes(this.booking.statusType.code)) && !this.booking.isCanceled;
  }

  isSubmitted(booking: Booking): boolean {
    return !this.prohibitedSubmittedBookingStatuses.includes(booking.statusType.code);
  }

  isShowShippingInstructions(booking: Booking): boolean {
    return this.utilityService.isBookingSubmitted(booking);
  }

  onFilesDocuments(bookingId: string) {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.shipments, BookingFilesDocumentsPage.shipments, bookingId]);
  }

  isHigherOrderReceived(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft &&
      booking.statusType.code !== BookingStatusCode.orderSubmitted &&
      booking.statusType.code !== BookingStatusCode.orderReceived
  }

  async getConversation(): Promise<void> {
    await this.salesforceService.openChat();
  }

  onGetSteps(): void {
    window.open('https://ship4wd.com/support-category/post-booking-cargo-fulfillment/', "_blank")
  }

  onRebook(booking: Booking) {
    if (this.allowedRebook) {
      this.router.navigate(['/quote-search'], { queryParams: { reusedBookingId: booking.id } });
    }
  }

  onCancelOrder(booking: Booking) {
    this.cancelBooking(booking);
  }

  isShowViewDetails(booking: Booking): boolean {
    return booking.isCanceled || this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && booking.hasPayments;
  }

  navigateToBookingDetails(booking: Booking): void {
    this.router.navigate(['/booking/view', BookingViewDetailsPage.shipments, booking.id]);
  }

  private cancelBooking(booking: Booking) {
    const updateBookingIsCanceledModel = this.prepareUpdateBookingIsCanceled(booking);

    this.isLoading = true;
    this.loadingEvent.emit(this.isLoading);
    this.bookingsService
      .updateBookingIsCanceled(booking.id, updateBookingIsCanceledModel)
      .subscribe((x: Booking) => {
        this.notificationService.success('Order has been cancelled successfully');
        this.canceledBookingEvent.emit(booking.id);
      },
        error => this.notificationService.error(error))
      .add(() => {
        this.isLoading = false;
        this.loadingEvent.emit(this.isLoading);
      });
  }

  private prepareUpdateBookingIsCanceled(booking: Booking) {
    const updateBookingIsCanceled = new UpdateBookingIsCanceled();
    updateBookingIsCanceled.isCanceled = true;
    updateBookingIsCanceled.userId = uuid.v4();
    updateBookingIsCanceled.organizationId = booking.organizationId;
    updateBookingIsCanceled.isAgent = !booking.isAgentLocked;
    return updateBookingIsCanceled;
  }
}
