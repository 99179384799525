<div class="booking-flow-contacts-create">
  <div class="contacts-create-header">
    <div class="row m-0">
      <div class="col-12 px-0 d-flex">
        <span class="title">
          {{
          organizationContactAction === organizationContactActions.view
          ? "View Exporter Contact"
          : organizationContactAction === organizationContactActions.edit
          ? "Update Exporter Contact"
          : "Create a New Exporter Contact"
          }}
        </span>
      </div>
      <div class="col-12 px-0 d-flex">
        <span class="description">
          {{ contactType ?
          'Fill the form below to create ' + selectedContactTitle + ' from ' + contactCountryName :
          'Fill the form below to add only or create and save a new contact.'
          }}
        </span>
      </div>
    </div>
  </div>

  <div class="contacts-create-content" [formGroup]="organizationContactForm">
    <div class="row m-0">
      <div class="col-12 px-0 mb-4 form-detailes-wrapper">
        <mat-form-field class="w-100" [floatLabel]="'auto'" appearance="fill">
          <mat-label>First Name (Required)</mat-label>
          <input id="firstName" formControlName="firstName" type="text" matInput trackInputErrors
            (triggerError)="handleError('First name', $event)" controlName="First name" />
          <div matSuffix
            *ngIf="organizationContactForm.get('firstName').value.length > 0 && organizationContactAction !== organizationContactActions.view">
            <div class="input-icon-wrapper" (click)="onClear('firstName', 'First name')">
              <i class="input-icon s4d-icon s4d-close"></i>
            </div>
          </div>
          <mat-error class="contact-field-error"
            *ngIf="organizationContactForm.get('firstName')?.touched && organizationContactForm.get('firstName')?.hasError('required')">
            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'First Name'}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-0 mb-4 form-detailes-wrapper">
        <mat-form-field class="w-100" [floatLabel]="'auto'" appearance="fill">
          <mat-label>Last Name (Required)</mat-label>
          <input id="lastName" formControlName="lastName" type="text" matInput trackInputErrors
            (triggerError)="handleError('Last name', $event)" controlName="Last name" />
          <div matSuffix
            *ngIf="organizationContactForm.get('lastName').value.length > 0 && organizationContactAction !== organizationContactActions.view">
            <div class="input-icon-wrapper" (click)="onClear('lastName', 'Last name')">
              <i class="input-icon s4d-icon s4d-close"></i>
            </div>
          </div>
          <mat-error class="contact-field-error"
            *ngIf="organizationContactForm.get('lastName')?.touched && organizationContactForm.get('lastName')?.hasError('required')">
            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Last Name'}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-0 mb-4 form-detailes-wrapper">
        <mat-form-field class="w-100" [floatLabel]="'auto'" appearance="fill">
          <mat-label>Company (Required)</mat-label>
          <input id="companyName" formControlName="companyName" type="text" matInput trackInputErrors
            (triggerError)="handleError('Company', $event)" controlName="Company" />
          <div matSuffix
            *ngIf="organizationContactForm.get('companyName').value.length > 0 && organizationContactAction !== organizationContactActions.view">
            <div class="input-icon-wrapper" (click)="onClear('companyName', 'Company')">
              <i class="input-icon s4d-icon s4d-close"></i>
            </div>
          </div>
          <mat-error class="contact-field-error"
            *ngIf="organizationContactForm.get('companyName')?.touched && organizationContactForm.get('companyName')?.hasError('required')">
            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Company'}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-0 mb-4 form-detailes-wrapper country-detailes"
        *ngIf="organizationContactForm.get('countryCode')">
        <app-country-autocomplete class="contact-field--country" floatLabel="auto" title="Country"
          [countryAutocompleteValue]="
              organizationContactForm.get('countryCode').value
            " [countryControl]="organizationContactForm.get('countryCode')"
          (triggerError)="handleError('Country', $event)">
        </app-country-autocomplete>
        <mat-error class="contact-field-error" *ngIf="
              organizationContactForm.get('countryCode')?.touched &&
              organizationContactForm.get('countryCode')?.hasError('required')
            ">
          <i class="s4d-icon s4d-info-light"></i>
          {{ "required" | errorMessage : "Country" }}
        </mat-error>
      </div>
      <div class="col-12 px-0 mb-4 form-detailes-wrapper">
        <mat-form-field class="w-100" [floatLabel]="'auto'" appearance="fill">
          <mat-label>Business Email (Required)</mat-label>
          <input id="email" formControlName="email" type="text" matInput trackInputErrors
            (triggerError)="handleError('Business Email', 'Business Email invalid')" controlName="Business Email" />
          <div matSuffix
            *ngIf="organizationContactForm.get('email').value.length > 0 && organizationContactAction !== organizationContactActions.view">
            <div class="input-icon-wrapper" (click)="onClear('email', 'Business email')">
              <i class="input-icon s4d-icon s4d-close"></i>
            </div>
          </div>
          <mat-error class="contact-field-error"
            *ngIf="organizationContactForm.get('email')?.touched && organizationContactForm.get('email')?.hasError('invalidEmail')">
            <i class="s4d-icon s4d-info-light"></i> Business Email invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-0 mb-4 form-detailes-wrapper contact-detailes">
        <app-phone-input #phoneInputComponent class="h-100 w-100"
          [fromControl]="organizationContactForm.get('phoneNumber')"
          [selectedCountry]="organizationContactForm.get('countryCode').value"
          (errorMessage)="handleError('Phone number', $event)"></app-phone-input>
      </div>
    </div>
  </div>

  <div class="contacts-create-footer">
    <div class="row m-0 button-wrapper">
      <div class="px-0 1-100" *ngIf="organizationContactAction !== organizationContactActions.view">
        <button mat-raised-button class="create-button button-spinner" (click)="onSaveAndSelect()" matTooltip="{{
              organizationContactAction === organizationContactActions.new
                ? 'Create'
                : 'Update'
            }}">
          <span class="button-inner">
            {{
            organizationContactAction === organizationContactActions.new
            ? "Create"
            : "Update"
            }}
            <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
          </span>
        </button>
      </div>
      <div class="px-0 w-100">
        <button mat-raised-button class="cancel-button" (click)="onBackToContact()">
          {{organizationContactAction !== organizationContactActions.view ? 'Cancel' : 'Close'}}
        </button>
      </div>
    </div>
  </div>
</div>