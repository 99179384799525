<div class="booking-contacts-container" *ngIf="!isViewOrganizationContacts">
  <div class="booking-contacts-header">
    <h2 class="booking-contacts-header-title">Contacts</h2>
    <h4 class="booking-contacts-header-sub-title">Provide your contacts</h4>
  </div>
  <div class="booking-contacts-wrapper">
    <div class="booking-contacts-field">
      <span class="field-title">Shipper</span>
      <div class="booking-contacts-field-box" [ngClass]="{
        'invalid-error-box': isStepContactsNextButtonPress && !shipperContact
      }">
        <mat-form-field [floatLabel]="'never'">
          <input matInput placeholder="Select Shipper" disabled [value]="shipperContact?.companyName" />
          <button matSuffix mat-icon-button class="booking-remove-contact-button"
            (click)="onRemoveBookingContact(contactTypes.shipper)" *ngIf="shipperContact">
            <span class="remove-contact-icon"></span>
          </button>
        </mat-form-field>
        <div>
          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
            (click)="viewOrganizationContacts(contactTypes.shipper)">
            <span class="add-contact-icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="booking-contacts-field">
      <span class="field-title">Consignee</span>
      <div class="booking-contacts-field-box" [ngClass]="{
        'invalid-error-box': isStepContactsNextButtonPress && !consigneeContact
      }">
        <mat-form-field [floatLabel]="'never'">
          <input matInput placeholder="Select Consignee" disabled [value]="consigneeContact?.companyName" />
          <button matSuffix mat-icon-button class="booking-remove-contact-button"
            (click)="onRemoveBookingContact(contactTypes.consignee)" *ngIf="consigneeContact">
            <span class="remove-contact-icon"></span>
          </button>
        </mat-form-field>
        <div>
          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
            (click)="viewOrganizationContacts(contactTypes.consignee)">
            <span class="add-contact-icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="booking-contacts-field">
      <span class="field-title">Notify party</span>
      <div class="booking-contacts-field-box" [ngClass]="{
        'invalid-error-box': isStepContactsNextButtonPress && !notifyPartyContact
      }">
        <mat-form-field [floatLabel]="'never'">
          <input matInput placeholder="Select Notify Party" disabled [value]="notifyPartyContact?.companyName" />
          <button matSuffix mat-icon-button class="booking-remove-contact-button"
            (click)="onRemoveBookingContact(contactTypes.notifyParty)" *ngIf="notifyPartyContact">
            <span class="remove-contact-icon"></span>
          </button>
        </mat-form-field>
        <div>
          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
            (click)="viewOrganizationContacts(contactTypes.notifyParty)">
            <span class="add-contact-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isViewOrganizationContacts">
  <app-booking-organization-contacts (BackToBooking)="onBack()"
    (selectedContact)="onSelectedContact($event)"
    [booking]="booking" [contactType]="selectedContactType"></app-booking-organization-contacts>
</div>