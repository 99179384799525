import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-info-box',
  styleUrls: ['./info-box.component.scss'],
  templateUrl: './info-box.component.html'
})
export class InfoBoxComponent {
  @Input() title: string | SafeHtml | null = null;
  @Input() description: string | SafeHtml | null = null;
  @Input() icon: string | null = null;

  constructor() { }
}
