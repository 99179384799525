import { BookingStatusCode } from './bookings/bookings.model';

export class Page<T> {
  items: T[];
  pageNo: number;
  pageSize: number;
  totalCount: number;
  isAlternative?: boolean;
}

export enum ShipmentType {
  LCL = 1,
  FCL = 2,
  AIR = 3
}

export class SelectItem {
  name: string;
  shortName?: string;
  value: number;
}

export enum DimensionUnit {
  CM = 1,
  M = 2,
  IN = 3,
  FT = 4
}

export enum WeightUnit {
  KG = 1,
  LB = 2
}

export enum LinearUnit {
  CM = 1,
  M = 2,
  IN = 3,
  FT = 4
}

export enum CodeType {
  unLoCode = 1,
  zipCode = 2,
  iata = 3,
  google = 4
}

export enum VendorsCode {
  default = -1,
  linescape = 1,
  zim = 2,
  vanguardLogistics = 3,
  kontainers = 4,
  portrixGpm = 5,
  wisetech = 6,
  oneView = 7,
  balance = 8,
  aeb = 9,
  clearit = 10,
  byc = 11,
  myFlyingBox = 12,
  xeneta = 13,
  vTrust = 14,
  loadSure = 17,
  exFreight = 18,
  herco = 19,
  shipco = 20,
  ecuLine = 21,
  davio = 22,
  luckyLogistics = 23,
  cloudLogistics = 24,
  ishlav = 25,
  freightMango = 32
}

export enum ShipperType {
  exporter = 1,
  importer = 2
}

export enum BookingPaidStatusCode {
  paid = 1,
  partialyPaid = 2,
  unPaid = 3
}

export enum CurrencyCode {
  USD = 1,
  EUR = 2
}

export enum VolumeUnit {
  CBM = 1,
  CFT = 2,
  CUI = 3
}

export enum IncotermCode {
  EXW = 1,
  FCA_W = 2,
  FCA = 3,
  FAS = 4,
  FOB = 5,
  CFR = 6,
  CIF = 7,
  CPT = 8,
  CIP = 9,
  DPU = 10,
  DAP = 11,
  DDP = 12,
  DAT = 13
}

export enum LocationType {
  townCity = 0,
  airPort = 1,
  seaPort = 2,
  railwayTerminal = 3,
  containerTerminal = 4,
  inlandPort = 5,
  industrialZone = 6,
  busStation = 7,
  island = 8,
  heliport = 9,
  dryPort = 10,
  administrativeDivision = 11,
  other = 99,
  recentOrigins = 100,
  recentDestinations = 101
}

export enum CarriageStatusType {
  preCarriage = 1,
  mainCarriage = 2,
  postCarriage = 3
}

export enum HaulageType {
  sea = 1,
  air = 2,
  rail = 3,
  land = 4,
  barge = 5
}

export enum RateServiceType {
  Standard = 1,
  Premium = 2,
  Express = 3,
  Other = 4,
  Basic = 5
}

export enum SearchType {
  from = 1,
  to = 2
}

export class TripsSearchBaseViewModel {
  codeType: CodeType;
  value: string;
  country: string;
  unLocode: string;
  zipCode: string;
  zipCodeShortest: number = null;
  iata: string;
  area: string;
  displayName: string;
  locationType: LocationType;
  coordinates: Coordinates;
  countryName: string;
  cityName: string;
  street: string;
  subdivision: string;
  carriageType: CarriageStatusType;
  city: string;
  functions: string;
  locationTypesClassifierCodes: string;
  cuttedDisplayName: string;
}

export class Coordinates {
  latitude: number;
  longitude: number;
  utmCoordinates: string;
}

export class TripsSearchFromViewModel extends TripsSearchBaseViewModel {
  searchId: string;
  fullAddress: string;
}

export class TripsSearchViewModelResponse extends TripsSearchBaseViewModel {
  searchId: string;
  shipperType: ShipperType;
  tripsSearchList: TripsSearchBaseViewModel[];
  suggestedLocality: string;
}

export interface TripsSearchLocationOrigin {
  originCity: string;
  originCountry: string;
  pickupType: AddressTypeCode;
}

export interface TripsSearchLocationDestination {
  destinationCity: string;
  destinationCountry: string;
  deliveryType: AddressTypeCode;
}

export interface TripsSearchLocationAnalyticsViewModel extends TripsSearchLocationOrigin, TripsSearchLocationDestination {
  shipmentType: ShipmentType;
  stepNo: number;
  stepName: string;
  actionName: string;
  departureDate: string;
}

export class Profile {
  userId?: string;
  organizationId?: string;
  userName?: string;
  organizationName?: string;
  isAgent?: boolean;
  orgKycStatus?: boolean;
  countryCode: string;
  createTimeUtc?: string;
}

export class TripsSearchViewModelRequest {
  type: SearchType;
  searchValue: string;
  postcodeSearchValue: string;
  pageSize: number;
  shipmentType: ShipmentType;
  isPostcode: boolean;
  from: TripsSearchFromViewModel;
  profile: Profile;
}

export class IncreaseUnlocodeSearchCountViewModel {
  unLocode: string;
  shipmentType: ShipmentType;
  searchType: SearchType;
}

export class Equipment {
  isoCode: string;
  description: string;
  disabled?: boolean;
}

export enum QuoteSortBy {
  recommended = 1,
  cheapestFirst = 2,
  fastestFirst = 3
}

export class ExtenderTrip {
  schedulingTripId: string;
  quoteSearchId: string;
  routeId: string;
  rateId: string;
  accumulatePrice?: number;
  accumulateRateId?: string;
  currency?: CurrencyCode;
  vendorsCode: VendorsCode;
  carrierScac: string;
  estimatedDuration: number;
  fromUnLoCode: string;
  toUnLoCode: string;
  fromLocationTypeCode?: LocationType;
  toLocationTypeCode?: LocationType;
  departureDate?: string;
  arrivalDate?: string;
  cutOffDate?: string;
  fromEstimatedDuration?: number;
  toEstimatedDuration?: number;
  incoterms: IncotermCode[];
  isPreCarriageMissingRate?: boolean;
  isPostCarriageMissingRate?: boolean;
  reverseDiscount: number;
  preCarriageTripLeg: ExtenderTripLegBase;
  mainCarriageTripLeg: ExtenderTripLegBase;
  postCarriageTripLeg: ExtenderTripLegBase;
}

export class ExtenderTripLegBase {
  fromLocationName: string;
  toLocationName: string;
  legs: ExtenderTripLeg[];
}

export class ExtenderTripLeg {
  fromCode: string;
  fromCodeType: CodeType;
  fromLocationType?: LocationType;
  toCode: string;
  toCodeType: CodeType;
  toLocationType?: LocationType;
  fromLocationName: string;
  fromCountryName: string;
  departureDate?: string;
  toLocationName: string;
  toCountryName: string;
  arrivalDate?: string;
  estimatedDuration?: number;
  carrierCode: string;
  vesselName: string;
  voyage: string;
  vendorId: number;
  haulageType?: HaulageType;
  legNumber: number;
  carriageStatusTypeCode: CarriageStatusType;
  rates: ExtenderTripRate[];
}

export class ExtenderTripRate {
  rateServiceType: RateServiceType;
  shipmentEquipment: ShipmentEquipment;
  freightRate: FreightRate;
  surcharges: AdditionalRate[];
  validFrom?: string;
  validTo?: string;
}

export class ShipmentEquipment {
  code: string;
  name: string;
  description: string;
  quantity: number;
}

export class FreightRate {
  tariff: string;
  supplier: string;
  remarks: string;
  description: string;
  shipmentRate: ShipmentRate;
}

export class ShipmentRate {
  amount: number;
  totalAmount: number;
  currency: CurrencyCode;
  calculationBase: string;
  isRateMultiplied: boolean;
  pricePer: PricePer;
}

export class FourtySeasPaymentResponse {
  externalId: string;
  buyer: string;
  amount: number;
  status: string;
  currency: string;
  createdBy: FourtySeasPaymentCreatedBy;
  metadata: FourtySeasPaymentMetadata;
  lineItems: any[];
  paymentOptions: any[];
  createdAt: string;
  updatedAt: string;
  seller: string;
  timeline: FourtySeasPaymentTimeline;
  result: FourtySeasPaymentResult;
  id: string;
  tenantId: string;
}

export class FourtySeasPaymentCreatedBy {
  type: string;
  id: string;
}

export class FourtySeasPaymentMetadata {
  "BOL/Order№": string;
}

export class FourtySeasPaymentTimeline {
  pending: FourtySeasPaymentTimelineUpdated;
  confirmed: FourtySeasPaymentTimelineUpdated;
}

export class FourtySeasPaymentTimelineUpdated {
  updatedAt: string;
}

export class FourtySeasPaymentResult {
  totalFee: number;
  total: number;
  paymentOption: FourtySeasPaymentOption;
  paymentOptionFee: FourtySeasPaymentOptionFee;
  paymentMethodId: string;
}

export class FourtySeasPaymentOption {
  paymentMethod: PaymentMethodTypeCode;
  paymentTerm: FourtySeasPaymentTerm;
}

export class FourtySeasPaymentOptionFee {
  fixed: number;
  percentage: number;
}

export class FourtySeasPaymentTerm {
  type: PaymentTermCode;
  payload: PaymentTermPayload;
}

export class PaymentTermPayload {
  days: number;
  countFrom: string;
}

export enum PricePer {
  lumpsum = 0,
  shipment = 1,
  bl = 2,
  document = 3,
  container = 4,
  seal = 5,
  transaction = 6,
  truck = 7,
  flat = 8,
  cbm_kg = 9,
  cbm = 10,
  kg = 11,
  cw = 12,
  wm = 13,
  cfs = 14
}

export class Rebate { }

export class AdditionalRate {
  tariff: string;
  supplier: string;
  remarks: string;
  shipmentRate: ShipmentRate;
  additionalType: string;
  additionalTypeCode: string;
  applicableType: string;
}

export class HarmonizedSystemCode {
  id: string;
  description: string;
  tariffNumFormatted: string;
  unitOfMeasure1: string;
  col1RateAdVlrm: number;
  basicDutyRateString: string;
  pgaCodeList: string;
  ustR301: string;
  ustR301Note: string;
}

export const SupportedFileFormats = [
  'tjf',
  'pjp',
  'xbm',
  'jxl',
  'svgz',
  'jpg',
  'jpeg',
  'ico',
  'tiff',
  'gif',
  'svg',
  'jfif',
  'png',
  'bmp',
  'pjpeg',
  'avif',
  'pdf'
];

export class HarmonizedSystemCodeQuery {
  description: string;
  isoCode: string;
  any: boolean;
  grouped: boolean;
  sortBy: HarmonizedSystemCodeQueryParameters;
  sortDesc: boolean;
  pageNo: number;
  pageSize: number;
}

export enum HarmonizedSystemCodeQueryParameters {
  isoCode = 1,
  description = 2
}

export enum BookingStatusCodeEnum {
  DRFT = 1,
  ORSB = 2,
  ORC = 3,
  BKD = 4,
  CRD = 5,
  DOC = 6,
  PKUP = 7,
  ONHD = 8,
  ACO = 9,
  CDO = 10,
  ONB = 11,
  DEP = 12,
  HBI = 13,
  ANS = 14,
  AAS = 15,
  ACD = 16,
  CDD = 17,
  TTR = 18,
  CTRA = 19,
  DEO = 20,
  OFD = 21,
  DLV = 22,
  POD = 23,
  CTRR = 24,
  INV = 25,
  TFD = 26,
  ACL = 27,
  CAU = 28,
  ARW = 29,
  AWB = 30,
  MAN = 31,
  UPA = 32,
  RCF = 33,
  LPA = 34,
  CCD = 35,
  RFC = 36
}

export enum OrganizationListType {
  ACCEPTED = 1,
  PENDING = 2,
  INVITED = 3
}

export enum LeadSourceCode {
  website = 1,
  websiteRegistration = 2,
  kontainers = 3,
  internal = 4,
  ship4WDFront = 5,
  unknown = 6
}

export enum LeadStateCode {
  step1 = 1,
  step2 = 2,
  step3 = 3,
  submit = 4
}

export enum TradeNeedsTypeCode {
  tbd = 0,
  ocean = 1,
  air = 2,
  customs = 3,
  packaging = 4,
  warehousing = 5,
  brokerage = 6,
  trucking = 7,
  all = 8
}

export enum GlobalTradeTypeCode {
  exporter = 1,
  importer = 2,
  both = 3,
  unknown = 4
}

export enum InviteStatusCode {
  pending = 1,
  accepted = 2,
  canceled = 3,
  expired = 4,
  rejected = 5
}

export enum TeamMemberStatusCode {
  suspended = 1,
  active = 2,
  pending = 3
}

export enum TeamMemberPageType {
  invite = 1,
  member = 2
}

export enum TeamMemberPageMode {
  add = 1,
  edit = 2,
  show = 3
}

export enum AccessRoleType {
  bookingRead = 1,
  bookingCreate = 2,
  bookingUpdate = 3,
  bookingCancel = 4,
  teamMemberInvite = 5,
  teamMemberRemove = 6,
  teamMemberUpdate = 7,
  admin = 8,
  owner = 9,
  agent = 10
}

export enum KycStatusCode {
  pending = 1,
  approve = 2,
  reject = 3
}

export class TeamAccessRole {
  id: string;
  memberId: string;
  organizationId: string;
  organizationName: string;
  accessRoleType: AccessRoleType;
}

export const CompletedOrderStatusType = [
  BookingStatusCode.delivered,
  BookingStatusCode.invoiced,
  BookingStatusCode.receivedFromCarrier
];

export enum DocumentTypeCode {
  OTH = 0,
  FCR = 1,
  HBL = 2,
  MBL = 3,
  INV = 4,
  BKF = 5,
  PRE = 6,
  MFT = 7,
  PRQ = 8,
  CINV = 9,
  PACK = 10,
  COO = 11,
  ASN = 12,
  VINV = 13,
  FCRU = 14,
  HBLU = 15,
  MBLU = 16,
  INVU = 17,
  INVD = 18,
  ARR = 19,
  DON = 20,
  POD = 21,
  PHO = 22,
  ARRU = 23,
  DONU = 24,
  PAYP = 25,
  MSDS = 26
}

export enum BookingFilesDocumentsPage {
  dashboard = 1,
  orderDetails = 2,
  finance = 3,
  creditLine = 4,
  shipments = 5
}

export enum BookingViewDetailsPage {
  dashboard = 1,
  finance = 2,
  creditLine = 3,
  shipments = 4
}

export enum UserTypeCode {
  agent = 1,
  user = 2
}

export class File {
  content: string;
  name: string;
}

export class FileUrl {
  url: string
}

export enum PaymentReasonCode {
  initialPayment = 1,
  additionalPayment = 2
}

export enum PaymentStatusCode {
  toBePaid = 1,
  inProgress = 2,
  failed = 3,
  paid = 4,
  draft = 5,
  toPayOffline = 6,
  toBeApproved = 7,
  refunded = 8,
  scheduled = 9
}

export enum PaymentStatusTransition {
  draftCompleted = 1,
  paymentInProgress = 2,
  offileSelected = 3,
  checkoutInitialized = 4,
  paymentMethodChanged = 5,
  offlineDocumentsUploaded = 6,
  offlineApproved = 7,
  paymentSucceded = 8,
  paymentFailed = 9,
  paymentRetried = 10
}

export enum CreditLineStatusTransition {
  approve = 1,
  preApproved = 2,
  reject = 3,
  request = 4,
  claim = 5,
  reApply = 6,
  increaseRequest = 7
}

export class PostcodeVerify {
  postcode: string;
  isValid: boolean;
}

export enum PackageSizeType {
  Height = 1,
  Width = 2,
  Length = 3,
  Weight = 4
}

export enum UnitMeasurementType {
  imperial = 1,
  metric = 2
}

export enum PaymentTermCode {
  immediate = 1,
  net = 2
}

export enum PaymentMethodTypeCode {
  card = 1,
  ach = 3,
  acss = 4,
  bacs = 5,
  phone = 6,
  wire = 7,
  check = 8
}

export enum LoadUnit {
  volume = 1,
  dimensions = 2
}

export enum QuotesResultsSortBy {
  cheapestFirst = 1,
  fastestFirst = 2,
  earliestFirst = 3,
  recommended = 4
}

export enum QuotesResultsFilterBy {
  all = 1,
  basic = 2,
  standard = 3
}

export enum QuoteSearchParameters {
  shipmentType = 0,
  from = 1,
  to = 2,
  cargo = 3,
  date = 4
}

export enum BookingFlowPanels {
  cargoDetails = 0,
  contacts = 1,
  services = 2
}

export class BookingFlowPanelState {
  panel: BookingFlowPanels = BookingFlowPanels.cargoDetails;
  success: boolean = false;
  error: boolean = false;
  loading: boolean = false;
  valid: boolean = false;
}

export enum CustomsChargeCodes {
  OBO = 1,
  ABF = 2,
  CCS = 3,
  SGB = 4,
  ISE = 5
}

export enum InsuranceChargeCodes {
  BXF = 1
}

export enum PreShipmentChargeCodes {
  INP = 1
}

export enum PreShipmentDetailPanels {
  recommendation = 0,
  inspections = 1,
  values = 2,
  supportedCounties = 3
}

export enum ErrorMessages {
  required = 1,
  minLength = 2,
  maxLength = 3,
  min = 4,
  max = 5,
  pattern = 6
}

export enum InsurancePanels {
  peaceOfMind = 0,
  costEffective = 1,
  flexibility = 2,
  assurance = 3
}

export enum AddressTypeCode {
  port = 0,
  airport = 1,
  door = 2,
  warehouse = 3
}

export enum PaymentResultStepType {
  SUCCESS = 1,
  WAITING = 2,
  BOTH = 3
}

export class SupportedCountriesSearchResponse {
  countryCode: string;
  countryName: string;
  description: string;
}

export class TripsSearchLocationsRequest {
  type: SearchType;
  searchValue: string;
  pageSize: number;
  pageNo: number;
  shipmentType: ShipmentType;
  addressTypeCode: AddressTypeCode;
  selectedCountryCode: string;
  from: TripsSearchFromViewModel | null;
  isPostcode: boolean;
  postcodeSearchValue: string;
  profile: Profile;
}

export class GoogleAddress {
  description: string;
  place_id: string;
  type: AddressTypeCode;
}

export class TripsSearchLocationsAddressRequest {
  country: string;
  state: string;
  city: string;
  sublocality: string;
  postcode: string;
  fullAddress: string;
  addressTypeCode: AddressTypeCode;
  shipmentType: ShipmentType;
  type: SearchType;
  from: TripsSearchFromViewModel;
  profile: Profile;
}

export class TaxIdQuery {
  taxId: string;
  countryCode: string;
}

export class CheckDangerousGoodsSupportRequest {
  shipmentType: ShipmentType;
  from: string;
  to: string;
}
export class CheckDangerousGoodsSupportResponse {
  isSupportBatteries: boolean;
}

export enum DangerousGoodsType {
  OTHER = 0,
  BATTERIES = 1
}

export class SettingsEntry {
  id: string;
  value: string;
}

export class DepartureDateSettings {
  fclMinAllowedDepartureDate: string;
  lclMinAllowedDepartureDate: string;
  airMinAllowedDepartureDate: string;
  fclMaxAllowedDepartureDate: string;
  lclMaxAllowedDepartureDate: string;
  airMaxAllowedDepartureDate: string;
  fclBasicPriceTierAllowedDays: string;
  lclBasicPriceTierAllowedDays: string;
  airBasicPriceTierAllowedDays: string;
}


export class WeightRange {
  range: string;
  weight: string;
  dimensions: string;
}