<div class="dialog-container">
  <div class="close-button">
    <button mat-button class="button-inner" (click)=" onClose('I don’t need help')">
      <i class="s4d-icon s4d-close"></i>
    </button>
  </div>
  <div class="back-arrow" (click)="onBack()">
    <i class="panel-header-icon s4d-icon s4d-arrow-left"></i>
  </div>
  <div class="row dialog-message-wrapper">
    <div class="dialog-title-description-wrapper">
      <div class="support-requests-icon">
        <i class="s4d-icon s4d-phone"></i>
      </div>
      <h4 class="dialog-title">
        Need Help With Your Booking?
      </h4>
    </div>
    <div class="support-requests-container-wrapper">
      <div class="support-requests-option whatsapp cursor-pointer" (click)="onClickWhatsApp()">
        <div class="d-flex support-requests-section">
          <div class="icon-support-requests-content-wrapper">
            <div class="support-requests-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-whatsapp"></i>
            </div>
          </div>
          <div class="support-requests-fields">
            <div class="support-requests-title">
              <span>WhatsApp</span>
            </div>
            <div class="support-requests-description">
              <span>Talk to us via WhatsApp</span>
            </div>
          </div>
        </div>
      </div>
      <div class="support-requests-option" (click)="onClickChat()">
        <div class="d-flex support-requests-section">
          <div class="icon-support-requests-content-wrapper">
            <div class="support-requests-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-comments-light"></i>
            </div>
          </div>
          <div class="support-requests-fields">
            <div class="support-requests-title">
              <span>Chat with a live Expert</span>
            </div>
            <div class="support-requests-description">
              <span>Chat with Ship4wd Expert</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ortext-wrapper mt-4" *ngIf="showCallWithExpert()">
        <span class="ortext">OR</span>
      </div>
      <div class="support-requests-option-callinput" *ngIf="showCallWithExpert()">
        <div class="d-flex support-requests-section">
          <div class="support-requests-fields w-100">
            <div class="row w-100 mt-4 px-0 mx-0 support-requests-phone">
              <div class="booking-contact-field p-0">
                <div class="contact-field-wrapper">
                  <app-phone-input class="h-100 w-100" [fromControl]="supportRequestForm.get('phoneNumber')"
                    [selectedCountry]="appSettings.countryCode" [label]="'Phone number (international format)'"
                    (errorMessage)="onErrorMessage($event)" (firstFocus)="onPhoneInputFirstFocus()"></app-phone-input>
                </div>
              </div>
            </div>
            <div class="row w-100 mt-3 px-0 mx-0">
              <button mat-button class="button-spinner button-inner callmeback-button"
                (click)="onClickAddSupportRequest()" [disabled]="isButtonLoading || !supportRequestForm.valid">
                <i class="s4d-icon s4d-person"></i>
                Call Me Back
                <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>