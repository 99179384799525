import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { QuoteSearch } from 'src/app/desktop/quotes/quotes.model';
import { EncodeUrlCodec } from '../services/encode-url-codec';
import { RecentQuoteSearchesByUserIdQuery } from './activities.model';

@Injectable()
export class ActivitiesService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getRecentQuoteSearchesByUserId(query: RecentQuoteSearchesByUserIdQuery): Observable<QuoteSearch[]> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<QuoteSearch[]>(
      `${this.internalUrlsService.getApiBaseUrl()}/activities/getRecentQuoteSearchesByUserId`, { params });
  }

  getUniqueRecentQuoteSearchesByUserId(query: RecentQuoteSearchesByUserIdQuery): Observable<QuoteSearch[]> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<QuoteSearch[]>(
      `${this.internalUrlsService.getApiBaseUrl()}/activities/getUniqueRecentQuoteSearchesByUserId`, { params });
  }
}
