import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { environment } from '../../../../environments/environment';
import { DashboardService } from '../../../shared/widgets/dashboard.service';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';
import {
  LocationType,
  Page,
  QuotesResultsFilterBy,
  QuotesResultsSortBy,
  RateServiceType,
  SearchType,
  SelectItem,
  ShipmentType,
  ShipperType
} from '../../../shared/shared.model';
import {
  AdditionalService,
  AdditionalServiceType
} from '../../../shared/additional-services/additional-services.model';
import { ExtenderTrip, ExtenderTripLegBase, Quote, QuotesQuery } from '../quotes.model';
import { QuotesService } from '../quotes.service';
import { BookingFlowService } from '../../booking/booking-flow/booking-flow.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { PercentageStatusCode, PriceTrend } from '../../../shared/price-history-chart/price-history-chart.model';
import { EnvironmentService } from '../../../shared/services/environment.service';

@Component({
  selector: 'app-quotes-results',
  templateUrl: './quotes-results.component.html',
  styleUrls: ['./quotes-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuotesResultsComponent implements OnInit, OnChanges {
  @Input() quotes: Quote[];
  @Input() showD2dAndAir: boolean;
  @Input() shipmentType: ShipmentType;
  @Input() isListLoading: boolean;
  @Input() isLoading: boolean;
  @Input() isShowLoadingList: boolean;
  @Input() from: string;
  @Input() to: string;
  @Input() quotesQuery: QuotesQuery;
  @Input() noQuotesFound: boolean;
  @Input() quoteSearchId: string;
  @Input() priceTrendInfo: PriceTrend;
  @Output() backEvent = new EventEmitter();
  @Output() shipperTypeChanged = new EventEmitter();

  sortedQuotes: Quote[];
  isDeliveryOnDestinationRemoved = false;
  isPickupAtOriginRemoved = false;
  isAdditionalServicesEnabled = false;
  isQuoteBadgeEnabled = this.environmentService.environment.isQuoteBadgeEnabled;
  shipmentTypes = ShipmentType;
  selectedSortBy = this.environmentService.environment.isQuoteBadgeEnabled
    ? QuotesResultsSortBy.recommended
    : QuotesResultsSortBy.cheapestFirst;
  selectedFilteredBy = QuotesResultsFilterBy.all;
  distantFuture = new Date(8640000000000000);
  shipperType: ShipperType;
  shippingTypes: any[] = [
    { name: 'FCL', value: ShipmentType.FCL, icon: 'shipping-type-fcl' },
    { name: 'LCL', value: ShipmentType.LCL, icon: 'shipping-type-lcl' },
    { name: 'Air', value: ShipmentType.AIR, icon: 'shipping-type-ac' }
  ];

  isShowPriceTrendChart: boolean = true;
  percentageStatusCode = PercentageStatusCode;
  rateServiceType = RateServiceType;

  isShowScheduleNotAvailableInfoBox: boolean = false;

  additionalServices: AdditionalService[];
  additionalServiceType = AdditionalServiceType;
  isShowAdditionalServicesWidget = false;
  additionalServicesTooltipText: string = `After selecting a quote, you will
   find the option to add these
   services into your shipment under
   the "Additional Services" tab.`;
  preShipmentTooltipText: string = `This service is relevant in case you need an official
  report relevant for the quality of the shipment`;
  priceHistoryTooltipText: string = `Weekly averages are calculated from
   the prices displayed on our platform.`;

  filteredByOptions: SelectItem[] = [
    {
      name: 'All Quotes',
      value: QuotesResultsFilterBy.all
    },
    {
      name: 'Basic Only',
      value: QuotesResultsFilterBy.basic
    },
    {
      name: 'Standard Only',
      value: QuotesResultsFilterBy.standard
    }
  ];

  sortByOptions: SelectItem[] = [
    {
      name: 'Cheapest First',
      value: QuotesResultsSortBy.cheapestFirst
    },
    {
      name: 'Fastest First',
      value: QuotesResultsSortBy.fastestFirst
    },
    {
      name: 'Earliest First',
      value: QuotesResultsSortBy.earliestFirst
    }
  ];

  shipperTypes: SelectItem[] = [
    {
      name: 'I’m Importing',
      value: ShipperType.importer
    },
    {
      name: 'I’m Exporting',
      value: ShipperType.exporter
    }
  ];

  get shipmentTypeIcon(): string {
    return this.shippingTypes.find(x => x.value == this.shipmentType)?.icon;
  }

  get isDeliveryOnDestination(): boolean {
    return this.quotesQuery.to.locationType == LocationType.townCity;
  }

  get isPickupAtOrigin(): boolean {
    return this.quotesQuery.from.locationType == LocationType.townCity;
  }

  get titleForAlternative(): SafeHtml {
    const fromDate = this.quotesQuery.fromDate;
    const date = new Date(fromDate);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return `We couldn't find a quote for <span class='font-500'>${formattedDate}<span>`;
  }

  get savedSortBy(): QuotesResultsSortBy {
    return this.dashboardService.getSettings()?.quotesResultsSortBy;
  }

  constructor(
    private dashboardService: DashboardService,
    private quotesService: QuotesService,
    private bookingFlowService: BookingFlowService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityService: UtilityService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.shipperType = this.quotesQuery.shipperType;
    this.selectedSortBy = this.environmentService.environment.isQuoteBadgeEnabled
      ? QuotesResultsSortBy.recommended
      : QuotesResultsSortBy.cheapestFirst;
    this.getListOfQuotes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quotes) {
      this.getListOfQuotes();
      this.recalculateIsShowScheduleNotAvailableInfoBox();
    }
    if (changes.quoteSearchId?.firstChange) {
      this.isAdditionalServicesEnabled = this.getIsAdditionalServicesEnabled();
    }
    if (changes.quoteSearchId?.currentValue && this.isAdditionalServicesEnabled) {
      this.loadAdditionalServices(changes.quoteSearchId?.currentValue);
    }
  }

  getCargoDetails(): string {
    const numberOfContainers = this.quotesQuery.containerCommodities
      .map(item => item.numberOfPackages)
      .reduce((prev, next) => prev + next);
    return `${numberOfContainers} ${this.shipmentType == ShipmentType.FCL ? 'Containers' : 'Packages'}`;
  }

  onBack(): void {
    this.backEvent.emit();
  }

  onShipperTypeChanged(value: ShipperType): void {
    this.googleAnalyticsService.shipperTypeChanged(value);

    this.shipperTypeChanged.emit(value);
  }

  getSortedQuotes(criteria: string[]): Quote[] {
    return this.quotes.slice().sort((a, b) => this.sortQuotes(a, b, criteria));
  }

  sortQuotes(a: Quote, b: Quote, criteria: string[]): number {
    for (const criterion of criteria) {
      let comparison = 0;
      switch (criterion) {
        case 'price':
          comparison = a.accumulatePrice - b.accumulatePrice;
          break;
        case 'duration':
          comparison = a.estimatedDuration - b.estimatedDuration;
          break;
        case 'departureDate':
          comparison = new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime();
          break;
        default:
          break;
      }
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  }

  sortQuotesList(isUserSelected: boolean, sortedBy: QuotesResultsSortBy = null): void {
    sortedBy = sortedBy ?? this.selectedSortBy;

    switch (sortedBy) {
      case QuotesResultsSortBy.recommended:
        this.sortedQuotes.sort((a, b) => this.sortQuotes(a, b, ['price', 'duration', 'departureDate']));
        break;

      case QuotesResultsSortBy.cheapestFirst:
        this.sortedQuotes.sort((a, b) => this.sortQuotes(a, b, ['price', 'duration', 'departureDate']));
        break;

      case QuotesResultsSortBy.fastestFirst:
        this.sortedQuotes.sort((a, b) => this.sortQuotes(a, b, ['duration', 'departureDate', 'price']));
        break;

      case QuotesResultsSortBy.earliestFirst:
        this.sortedQuotes.sort((a, b) => this.sortQuotes(a, b, ['departureDate', 'price', 'duration']));
        break;

      default:
        break;
    }

    if (isUserSelected) {
      this.dashboardService.setQuotesResultsSortBy(sortedBy);
    }
  }

  oldSortQuotesList(isUserSelected: boolean): void {
    switch (this.selectedSortBy) {
      case QuotesResultsSortBy.cheapestFirst:
        this.sortedQuotes.sort(
          (a, b) =>
            (a.accumulatePrice != null && a.accumulatePrice != 0
              ? a.accumulatePrice
              : Infinity) -
            (b.accumulatePrice != null && b.accumulatePrice != 0
              ? b.accumulatePrice
              : Infinity)
        );
        break;
      case QuotesResultsSortBy.fastestFirst:
        this.sortedQuotes.sort(
          (a, b) =>
            (a.estimatedDuration != null && a.estimatedDuration != 0
              ? a.estimatedDuration
              : Infinity) -
            (b.estimatedDuration != null && b.estimatedDuration != 0
              ? b.estimatedDuration
              : Infinity)
        );
        break;
      case QuotesResultsSortBy.earliestFirst:
        this.clearDefaultDepartureDate();
        this.sortedQuotes.sort((a, b) => {
          let dateA = a.departureDate
            ? new Date(a.departureDate)
            : this.distantFuture;
          let dateB = b.departureDate
            ? new Date(b.departureDate)
            : this.distantFuture;
          return dateA.getTime() - dateB.getTime();
        });
        break;
      default:
        break;
    }

    if (isUserSelected) {
      this.dashboardService.setQuotesResultsSortBy(this.selectedSortBy);
    }
  }

  private clearDefaultDepartureDate(): void {
    for (const quote of this.sortedQuotes) {
      if (!this.utilityService.isNotNullOrMinDateValue(quote.departureDate)) {
        quote.departureDate = null;
      }
    }
  }

  filterQuotesList(): void {
    switch (this.selectedFilteredBy) {
      case QuotesResultsFilterBy.all:
        // If "All Quotes" is selected, no filter is applied
        this.sortedQuotes = this.quotes;
        this.sortQuotesList(false, this.savedSortBy);
        break;

      case QuotesResultsFilterBy.basic:
        // Filter for "Basic Only" rate service type
        this.sortedQuotes = this.quotes.filter(
          quote => quote.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType === RateServiceType.Basic
        );
        this.sortQuotesList(false, this.savedSortBy);
        break;

      case QuotesResultsFilterBy.standard:
        // Filter for "Standard Only" rate service type
        this.sortedQuotes = this.quotes.filter(
          quote => quote.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType !== RateServiceType.Basic
        );
        this.sortQuotesList(false, this.savedSortBy);
        break;

      default:
        this.sortedQuotes = this.quotes;
        this.sortQuotesList(false, this.savedSortBy);
        break;
    }
  }

  addHideDoorDelivery(isPickupChanged: boolean): void {
    if (this.shipmentType == ShipmentType.FCL) {
      if (isPickupChanged) {
        this.isPickupAtOriginRemoved = !this.isPickupAtOriginRemoved;
      } else {
        this.isDeliveryOnDestinationRemoved = !this.isDeliveryOnDestinationRemoved;
      }

      this.sortedQuotes = this.getQuotesCopy();
      for (const quote of this.sortedQuotes) {
        if (this.isPickupAtOriginRemoved) {
          quote.trip.preCarriageTripLeg = null;
        }
        if (this.isDeliveryOnDestinationRemoved) {
          quote.trip.postCarriageTripLeg = null;
        }

        quote.accumulatePrice = this.calculateAccumulatePrice(quote.trip);
      }

      this.sortQuotesList(false);
    } else {
      if (isPickupChanged) {
        this.quotesService.searchParamChange.next(SearchType.from);
      } else {
        this.quotesService.searchParamChange.next(SearchType.to);
      }
    }
  }

  doorDeliveryTooltip(): string {
    return `The cost of transporting the shipment from the
    ${this.quotesQuery.shipmentType == ShipmentType.AIR ? 'Airport' : 'Port'
      } at destination to the recipient location.`;
  }

  doorPickupTooltip(): string {
    return `The cost of transporting the shipment from the
    supplier door to the ${this.quotesQuery.shipmentType == ShipmentType.AIR ? 'Airport' : 'Port'}`;
  }

  isShowPriceTrend(): boolean {
    return (
      this.environmentService.environment.isPriceTrendsEnabled &&
      this.quotesQuery.shipmentType === ShipmentType.FCL &&
      this.quotesQuery.from.locationType === LocationType.seaPort &&
      this.quotesQuery.to.locationType === LocationType.seaPort &&
      this.priceTrendInfo?.averagePriceTrendWeeks > 0 &&
      this.checkWeeklyAveragePriceCount(this.priceTrendInfo)
    );
  }

  isPriceTagsEnabled(): boolean {
    return this.environmentService.environment.isPriceTagsEnabled;
  }

  private getListOfQuotes() {
    this.sortedQuotes = this.getQuotesCopy();
    for (const quote of this.sortedQuotes) {
      if (this.isPickupAtOriginRemoved) {
        quote.trip.preCarriageTripLeg = null;
      }
      if (this.isDeliveryOnDestinationRemoved) {
        quote.trip.postCarriageTripLeg = null;
      }

      quote.accumulatePrice = this.calculateAccumulatePrice(quote.trip);
    }
    const sortBy = this.dashboardService.getSettings()?.quotesResultsSortBy;
    if (sortBy !== undefined && sortBy !== null) {
      this.selectedSortBy = sortBy;
    }

    this.sortQuotesList(false);
  }

  private calculateAccumulatePrice(extenderTrip: ExtenderTrip): number {
    return (
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.preCarriageTripLeg) +
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.mainCarriageTripLeg) +
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.postCarriageTripLeg)
    );
  }

  private calculateExtenderTripLegAccumulatePrice(extenderTripLeg: ExtenderTripLegBase): number | null {
    return extenderTripLeg?.legs?.length > 0
      ? extenderTripLeg.legs.reduce((accumulator: number | null, leg) => {
        const ratesSum =
          leg.rates?.reduce((sum: number, rate) => {
            const shipmentRateTotalAmount = rate.freightRate?.shipmentRate?.totalAmount ?? 0;
            const surchargeSum = rate.surcharges?.reduce(
              (s: number, surcharge) => s + (surcharge?.shipmentRate?.totalAmount ?? 0),
              0
            );
            return sum + shipmentRateTotalAmount + (surchargeSum ?? 0);
          }, 0) ?? 0;

        return accumulator != null ? accumulator + ratesSum : null;
      }, 0)
      : null;
  }

  private loadAdditionalServices(quoteSearchId: string): void {
    this.isShowAdditionalServicesWidget = false;
    this.bookingFlowService
      .getAdditionalServices({
        quoteSearchId: quoteSearchId,
        isEnabled: true,
        sortBy: 1,
        sortDesc: true,
        pageNo: 1,
        pageSize: 20
      })
      .subscribe((x: Page<AdditionalService>) => {
        this.additionalServices = x.items;
        if (this.additionalServices?.length > 0) {
          this.additionalServices.sort((a, b) => a.id - b.id);
          this.isShowAdditionalServicesWidget = true;
        }
      });
  }

  private getQuotesCopy(): Quote[] {
    return JSON.parse(JSON.stringify(this.quotes));
  }

  private getIsAdditionalServicesEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isAdditionalServicesEnabled;
      case 'sb':
        return environment.sb.isAdditionalServicesEnabled;
      default:
        return environment.isAdditionalServicesEnabled;
    }
  }

  private checkWeeklyAveragePriceCount(priceTrend: PriceTrend): boolean {
    if (priceTrend) {
      return priceTrend.priceTrendWeeks.filter(x => x.weeklyAveragePrice > 0).length > 1;
    }
  }

  private recalculateIsShowScheduleNotAvailableInfoBox(): void {
    this.isShowScheduleNotAvailableInfoBox = this.quotes.some(q => !q.departureDate || !q.arrivalDate);
  }
}
