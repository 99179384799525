import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Inject } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingFlowService } from '../../booking-flow.service';
import {
  AdditionalServiceType,
  BookingAdditionalServiceCharges,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges,
  SingleBondEntryCalculation
} from '../../../../../shared/additional-services/additional-services.model';
import { AdditionalRate, CurrencyCode, CustomsChargeCodes, VendorsCode } from '../../../../../shared/shared.model';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { ClearItChargeCodeGroup } from '../../../../../shared/customs/customs.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';

@Component({
  selector: 'app-additional-service-customs',
  templateUrl: './additional-service-customs.component.html',
  styleUrls: ['./additional-service-customs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServiceCustomsComponent implements OnInit {
  additionalRates: AdditionalRate[];
  bookingId: string;
  booking: Booking;
  @Output() closeDialog = new EventEmitter<any>();

  vendorsCodes = VendorsCode;
  customsChargeCodes = CustomsChargeCodes;
  isLoading: boolean = false;
  isConfirmDisable: boolean = false;
  singleEntryBoundSearchTermSubject = new Subject<string>();
  singleEntryBoundShipmentLoader: boolean = false;
  clearItChargeCodeGroup = ClearItChargeCodeGroup;
  recommendedTooltipText: string = `Shipment can't leave the origin without
                            filling this as regulated by the government.`;
  selectedAdditionalServicesFeaturesPriceSum: number = 0;
  isServiceAlreadyAdded = false;

  additonalFeatures: any[] = [
    {
      title: "ISF Filing",
      description: 'Importer Security filing is required by U.S. Customs & Border Patrol 48 hours to vessel departure. You must have ISF Filing.',
      groupName: ClearItChargeCodeGroup.ISF,
      options: [
        {
          title: "ISF (importer Security Filling) Service",
          description: "",
          chargeCode: this.customsChargeCodes.ISE,
          amount: "",
          checked: true,
          disabled: false,
          currency: CurrencyCode.USD
        },
        {
          title: "I have my own ISF Filing",
          description: "",
          amount: "0.00",
          checked: false,
          disabled: false,
          currency: CurrencyCode.USD
        }
      ]
    },
    {
      title: "US Import Bond",
      description: 'Required for all importers in the United States (select 1 option below)',
      groupName: ClearItChargeCodeGroup.USBond,
      options: [
        {
          title: "I have my own continuous bond",
          description: "",
          amount: "0.00",
          checked: true,
          disabled: false,
          currency: CurrencyCode.USD
        },
        {
          title: "Annual (continuous Bond)",
          description: "Covers all bonding requirements for 1 year, including ISF.",
          chargeCode: this.customsChargeCodes.ABF,
          amount: "",
          checked: false,
          disabled: false,
          currency: CurrencyCode.USD
        },
        {
          title: "Single entry bond",
          description: "Single-use bond (recommended for low-value, infrequent importers) +$90.00 ISF Bond applicable on all ocean shipments.",
          chargeCode: this.customsChargeCodes.SGB,
          amount: "",
          checked: false,
          disabled: false,
          currency: CurrencyCode.USD
        }
      ]
    }
  ]

  constructor(
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService,
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.booking = data.booking;
    this.bookingId = data.bookingId;
    this.additionalRates = data.additionalRates;
    this.isServiceAlreadyAdded = data.isServiceAlreadyAdded;
  }

  ngOnInit(): void {
    this.updateAdditonalServiceRates();
    this.subscribeSingleEntryBoundSearchTermSubject();
    this.setAdditionalServicesFeaturesBasedOnBooking(this.booking.bookingAdditionalServiceCharges);
  }

  onSubmit(): void {
    const selectedAdditionalServiceFeatures = this.getSelectedAdditionalServiceFeatures();

    if (selectedAdditionalServiceFeatures.length > 0) {
      const createBookingAdditionalServiceChargesModel = {
        additionalServiceType: AdditionalServiceType.customs,
        charges: selectedAdditionalServiceFeatures
      } as CreateBookingAdditionalServiceCharges;

      this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
    }
  }

  onRemove(): void {
    this.dialogRef.close({
      isRemove: true
    });
  }

  additionalFeatureChange(event: MatCheckboxChange, item: any): void {
    const value = event.source.checked;
    item.checked = value;
  }

  subAdditionalFeatureChanged(event: MatRadioChange, subItem: any, item: any): void {
    let options = item.options;
    const value = event.source.checked;
    if (options && options.length > 0) {
      options.forEach(element => {
        element.checked = false;
      });
    }

    subItem.checked = value;

    if (item.groupName === ClearItChargeCodeGroup.USBond && subItem.chargeCode !== this.customsChargeCodes.SGB) {
      this.resetSingleEntryBoundAmount(false);
    }
    this.isConfirmDisable = item.groupName === ClearItChargeCodeGroup.USBond && subItem.chargeCode === this.customsChargeCodes.SGB ? true : false;

    this.recalculateSelectedAdditionalServicesFeaturesPriceSum();
  }

  recalculateSelectedAdditionalServicesFeaturesPriceSum(): void {
    const selectedFeatures = this.getSelectedAdditionalServiceFeatures();

    this.selectedAdditionalServicesFeaturesPriceSum =
      selectedFeatures.reduce((sum, feature) => sum + feature.price, 0) - this.getPriceByChargeCode(this.customsChargeCodes.CCS);
  }

  onChangeShipmentValue(event: any): void {
    this.singleEntryBoundSearchTermSubject.next(event);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private subscribeSingleEntryBoundSearchTermSubject(): void {
    this.singleEntryBoundSearchTermSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((value: any) => {
        if (value.target.value !== "" && value.target.value > 0) {
          this.onChangeSingleEntryBoundShipmentValue(value.target.value);
        }
        else {
          this.resetSingleEntryBoundAmount(true);
        }
      });
  }

  private onChangeSingleEntryBoundShipmentValue(shipmentValue: number): void {
    let query = new SingleBondEntryCalculation();
    query.shipmentValue = shipmentValue;

    this.singleEntryBoundShipmentLoader = true;

    this.bookingFlowService.getSingleBondEntryCalculation(query)
      .subscribe(
        (response: number) => this.setSingleEntryBoundPrice(response),
        (error: any) => this.notificationService.error(error),
      )
      .add(() => this.singleEntryBoundShipmentLoader = false);
  }

  private updateAdditonalServiceRates(): void {
    this.additonalFeatures.forEach(section => {
      if (section.chargeCode) {
        section.amount = this.getPriceByChargeCode(section.chargeCode);
        section.currency = this.getCurrencyByChargeCode(section.chargeCode);
      }

      if (section.options && section.options.length > 0) {
        section.options.forEach(subSection => {
          if (subSection.chargeCode) {
            subSection.amount = this.getPriceByChargeCode(subSection.chargeCode);
            subSection.currency = this.getCurrencyByChargeCode(subSection.chargeCode);
          }
        });
      }
    });
  }

  private getPriceByChargeCode(chargeCode: CustomsChargeCodes): number {
    const additionalRate = this.getAdditionalRateByChargeCode(chargeCode);

    return additionalRate?.shipmentRate.amount;
  }

  private getAdditionalServiceFeatureForConfirm(additonalFeature: any): CreateBookingAdditionalServiceCharge {
    const additionalRate = this.getAdditionalRateByChargeCode(additonalFeature.chargeCode);
    const vendorCode = this.getVendorCode(this.additionalRates[0].supplier);
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: vendorCode,
      price: additonalFeature.chargeCode === CustomsChargeCodes.SGB ? additonalFeature.amount : additionalRate.shipmentRate.amount,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: additionalRate.shipmentRate.currency
    } as CreateBookingAdditionalServiceCharge
  }

  private getAdditionalRateByChargeCode(chargeCode: CustomsChargeCodes): AdditionalRate | null {
    const additionalRate = this.additionalRates.find(x =>
      x.additionalTypeCode.toLocaleLowerCase() ===
      this.customsChargeCodes[chargeCode].toString().toLocaleLowerCase()
    )
    return additionalRate ?? null;
  }

  private getVendorCode(supplier: string): VendorsCode {
    const normalizedSupplier = supplier.toLowerCase();
    for (const key in VendorsCode) {
      if (key.toLowerCase() === normalizedSupplier) {
        return VendorsCode[key as keyof typeof VendorsCode];
      }
    }
    return null;
  }

  private getFormalCustomDeclerationAdditionalRate(): any {
    const additionalRate = this.getAdditionalRateByChargeCode(this.customsChargeCodes.CCS);
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: this.getVendorCode(this.additionalRates[0].supplier),
      price: additionalRate?.shipmentRate ? additionalRate.shipmentRate.amount : 0.00,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: additionalRate.shipmentRate.currency
    }
  }

  private saveBookingCustomAdditionalServiceCharges(model: CreateBookingAdditionalServiceCharges): void {
    this.isLoading = true;
    this.bookingFlowService.addAdditionalServices(this.bookingId, model)
      .subscribe(() => {
        this.dialogRef.close({
          isBookingAdditionalServiceUpdated: true,
          additonalServiceType: AdditionalServiceType.customs
        });
      },
        (error: any) => this.notificationService.error(error),
      ).add(() => {
        this.isLoading = false;
      });
  }

  private setAdditionalServicesFeaturesBasedOnBooking(model: BookingAdditionalServiceCharges[]): void {
    if (model && model.length > 0) {
      const clearitBookingAdditionalServiceCharges = model.filter(x =>
        x.vendorCode === VendorsCode.clearit);

      this.additonalFeatures.forEach(additonalFeature => {
        if (additonalFeature?.options?.length > 0) {
          additonalFeature.options.forEach(subAdditonalFeature => {
            const selectedSubASCharge = this.getSelectedAdditionalServiceCharge(clearitBookingAdditionalServiceCharges, subAdditonalFeature.chargeCode);
            if (selectedSubASCharge != undefined) {
              additonalFeature.options.forEach(subGroupOption => {
                subGroupOption.checked = false;
              });
              subAdditonalFeature.checked = true;

              if (subAdditonalFeature?.chargeCode === this.customsChargeCodes.SGB) {
                subAdditonalFeature.amount = selectedSubASCharge.price;
              }
            }
          })
        }
      })

      let isISEAdditionalService = clearitBookingAdditionalServiceCharges?.some(x => x.additionalServiceCharge.chargeCode == this.customsChargeCodes[CustomsChargeCodes.ISE]);
      if (!isISEAdditionalService) {
        this.additonalFeatures.forEach(additonalFeature => {
          if (additonalFeature.groupName === ClearItChargeCodeGroup.ISF) {
            additonalFeature.options.forEach(subGroupOption => {
              subGroupOption.checked = false;
            });
            let ownISFFilling = additonalFeature?.options.find(x => x.chargeCode !== this.customsChargeCodes.ISE)

            if (ownISFFilling !== undefined) {
              ownISFFilling.checked = true;
            }
          }
        })
      }
    }

    this.recalculateSelectedAdditionalServicesFeaturesPriceSum();
  }

  private resetSingleEntryBoundAmount(isConfirmDisable: boolean = false): void {
    this.isConfirmDisable = isConfirmDisable;
    this.additonalFeatures.forEach(additonalFeature => {
      if (additonalFeature?.options?.length > 0) {
        additonalFeature.options.forEach(subAdditonalFeature => {
          if (subAdditonalFeature?.chargeCode && subAdditonalFeature?.chargeCode === this.customsChargeCodes.SGB) {
            const additionalRate = this.getAdditionalRateByChargeCode(this.customsChargeCodes.SGB);
            subAdditonalFeature.amount = additionalRate && additionalRate?.shipmentRate ? additionalRate.shipmentRate.amount : 0;
          }
        })
      }
    })

    this.recalculateSelectedAdditionalServicesFeaturesPriceSum();
  }

  private getCurrencyByChargeCode(chargeCode: CustomsChargeCodes): number {
    const additionalRate = this.getAdditionalRateByChargeCode(chargeCode);
    return additionalRate?.shipmentRate.currency;
  }

  private getSelectedAdditionalServiceCharge(charges: BookingAdditionalServiceCharges[], chargeCode: string)
    : BookingAdditionalServiceCharges | undefined {
    return charges.find(x => x.additionalServiceCharge.chargeCode == this.customsChargeCodes[chargeCode]);
  }

  private setSingleEntryBoundPrice(price: number): void {
    this.additonalFeatures.forEach(additonalFeature => {
      if (additonalFeature?.options && additonalFeature?.options.length > 0) {
        additonalFeature?.options.forEach(option => {
          option.chargeCode === this.customsChargeCodes.SGB ? option.amount = price : 0;
        })
      }
    });
    this.isConfirmDisable = false;

    this.recalculateSelectedAdditionalServicesFeaturesPriceSum();
  }

  private getSelectedAdditionalServiceFeatures(): CreateBookingAdditionalServiceCharge[] {
    const selectedAdditionalServiceFeatures: CreateBookingAdditionalServiceCharge[] = [];

    const formalCustomDeclerationAdditionalRate = this.getFormalCustomDeclerationAdditionalRate();
    selectedAdditionalServiceFeatures.push(formalCustomDeclerationAdditionalRate);

    this.additonalFeatures.forEach((additionalFeature) => {
      if (additionalFeature.options?.length > 0) {
        additionalFeature.options.forEach((subAdditionalFeature) => {
          if (subAdditionalFeature.chargeCode && subAdditionalFeature.checked) {
            const additionalFeatureData = this.getAdditionalServiceFeatureForConfirm(subAdditionalFeature);
            selectedAdditionalServiceFeatures.push(additionalFeatureData);
          }
        });
      }
    });

    return selectedAdditionalServiceFeatures;
  }
}
