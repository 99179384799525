import { UserTypeCode } from '../../shared.model';

export class BookingFileUrl {
  url: string;
}

export class DocumentComment {
  userId: string;
  userName: string;
  comment: string;
  createTimeUtc: string;
  userType: UserTypeCode;
}
