import { Pipe, PipeTransform } from '@angular/core';
import { TeamMemberStatusCode } from '../shared.model';

@Pipe({ name: 'teamMemberStatusName' })
export class TeamMemberStatusNamePipe implements PipeTransform {
  private teamMemberStatusTypeNames = {};

  constructor() {
    this.teamMemberStatusTypeNames[TeamMemberStatusCode.suspended] = 'Suspended';
    this.teamMemberStatusTypeNames[TeamMemberStatusCode.active] = 'Active';
    this.teamMemberStatusTypeNames[TeamMemberStatusCode.pending] = 'Pending';
  }

  transform(value?: TeamMemberStatusCode): string {
    return this.teamMemberStatusTypeNames[value] ?? '';
  }
}
