import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "@ship4wd/ngx-common";
import { AppSettingsService } from "../../../shared/services/app-settings/app-settings.service";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { FourtySeasPaymentService } from "../../../shared/payments/fourty-seas-payment.service";
import { CreditLine, CreditLineStatusCode } from "../../../shared/payments/payments.model";
import { PaymentRequestAndPaymentsQueryParameters, RequestAndPayment, RequestAndPaymentQuery } from "../../../shared/requests-and-payments/requests-and-payments.model";
import { RequestAndPaymentService } from "../../../shared/requests-and-payments/requests-and-payments.service";
import { Page, PaymentStatusCode, PaymentTermCode } from "../../../shared/shared.model";

@Component({
  selector: "app-claim-credit-line",
  templateUrl: "./claim-credit-line.component.html",
  styleUrls: ["./claim-credit-line.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClaimCreditLineComponent implements OnInit {
  @Input() creditLine: CreditLine = null;
  CreditLineStatuses = CreditLineStatusCode;

  isLoading = false;
  organizationId: string;

  payments: RequestAndPayment[] = null;
  hasPayment: boolean = false;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private requestAndPaymentService: RequestAndPaymentService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.getRequestsAndPayments();
    this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
      if (next) {
        this.isLoading = false;
        this.getRequestsAndPayments();
      }
    });
  }

  initCreditLine(): void {
    this.isLoading = true;
    this.fourtySeasPaymentService
      .initCreditLine(this.organizationId)
      .subscribe(
        () => { },
        () => {
          this.openPopup(
            "Error. An error occurred while initialising your credit line, try again."
          );
        }
      )
      .add(() => (this.isLoading = false));
  }

  onQuoteSearch(): void {
    this.router.navigate(['/quote-search'])
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok",
      },
    });
  }

  private getRequestsAndPayments(): void {
    const query = {
      statusTypes: [PaymentStatusCode.inProgress, PaymentStatusCode.scheduled],
      paymentTerm: PaymentTermCode.net,
      pageNo: 1,
      pageSize: 500,
      sortBy: PaymentRequestAndPaymentsQueryParameters.updateTimeUtc,
    } as RequestAndPaymentQuery;

    this.requestAndPaymentService
      .getByOrganizationQuery(query, this.organizationId)
      .subscribe(
        (x: Page<RequestAndPayment>) => {
          this.payments = x.items;
          this.hasPayment = this.payments.length > 0;
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }
}
