export enum CustomsShippingMethod {
  Truck = 1,
  Ocean = 2,
  Air = 3
}

export enum CustomsBondType {
  SingleEntryBond = 1,
  AnnualBondRequested = 2,
  UserAlreadyHasTheirOwnBond = 3
}

export class CustomsRateQuery {
  organizationId: string;
  bookingId: string;
  shippingMethod?: CustomsShippingMethod;
  shippingFrom: string;
  bondType?: CustomsBondType;
  freightCharge?: number;
  commodities: CustomsCommodity[];
}

export class CustomsCommodity {
  code: string;
  description: string;
  price: number;
  totalUnits: number;
  dutyUnits: number;
  countryManufacture: string;
}

export class CustomsRate {
  estimateIdentifier: string;
  customFee: CustomsFee[];
  clearitFee: CustomsFee[];
  landedCost: CustomsFee[];
  totalAmount: string;
}

export class CustomsFee {
  description: string;
  amount: string;
}

export enum ClearItChargeCodeGroup {
  ISF = 1,
  USBond = 2
}