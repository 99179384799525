import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-tutorial-dialog',
  templateUrl: './welcome-tutorial-dialog.component.html',
  styleUrls: ['./welcome-tutorial-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeTutorialDialogComponent {
  constructor(public dialogRef: MatDialogRef<WelcomeTutorialDialogComponent>, private router: Router) {}

  onQuoteSearch(): void {
    this.dialogRef.close(true);
    this.router.navigate(['/quote-search']);
  }

  onClose(): void {
    this.dialogRef.close(true);
    this.router.navigate(['/quote-search']);
  }
}
