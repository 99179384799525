import { SearchType, ShipmentType } from "../shared.model";

export class SupportedCountry {
  id: string;
  countryCode: string;
  countryName: string;
  shipmentType: ShipmentType;
  isEnabled: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  isDeleted: boolean;
  isTruckEnabled: boolean;
  description: string;
}

export class SupportedCountriesSearchQuery {
  countryCode: string;
  countryName: string;
  shipmentType: ShipmentType;
  isEnabled?: boolean;
  isTruckEnabled?: boolean;
  any: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  pageNo: number;
  pageSize: number;
  sortBy?: SupportedCountriesQueryParameters;
  sortDesc: boolean;
}

export enum SupportedCountriesQueryParameters {
  countryCode = 1,
  countryName = 2,
  shipmentType = 3,
  isEnabled = 4,
  createTimeUtc = 5,
  updateTimeUtc = 6,
  isTruckEnabled = 7
}

export class SupportedCountriesSearch {
  shipmentType: ShipmentType;
  searchType: SearchType;
}

export class SupportedCountriesPairQuery {
  fromCountryCode: string;
  toCountryCode: string;
}
