import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { EncodeUrlCodec } from '../../shared/services/encode-url-codec';
import {
  Organization,
  OrganizationMembers,
  OrganizationViewModel
} from './manage-organization.model';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getOrganization(id: string): Observable<Organization> {
    return this.http.get<Organization>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${id}`
    );
  }

  getTeamAndInviteMembersById(organizationId: string, userId: string): Observable<OrganizationMembers> {
    const params = new HttpParams({ fromObject: userId as any, encoder: new EncodeUrlCodec() });
    return this.http.get<OrganizationMembers>(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationId}/members`, { params });
  }

  updateOrganization(organizationId: string, organization: OrganizationViewModel) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put(
      `${this.internalUrlsService.getApiBaseUrl()}/organizations/${organizationId}`,
      organization, httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
