import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { interval, Observable, of } from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { CommodityService } from '../services/commodity.service';
import {
  HarmonizedSystemCode,
  HarmonizedSystemCodeQuery,
  HarmonizedSystemCodeQueryParameters,
  Page,
} from '../shared.model';

@Component({
  selector: 'app-commodity-autocomplete',
  templateUrl: './commodity-autocomplete.component.html',
  styleUrls: ['./commodity-autocomplete.component.scss']
})
export class CommodityAutocompleteComponent implements OnInit {
  @Input() pageSize = 500;
  @Input() minimumLength = 3;
  commodityControl: FormControl = undefined;
  commodityResponse: HarmonizedSystemCode[] = [];
  isCommoditySelected = false;
  isLoading = false;
  pageNo = 1;
  @Output() selectedCommodity = new EventEmitter<HarmonizedSystemCode>();

  constructor(private commodityService: CommodityService) { }

  ngOnInit() {
    this.initializeCommodityAutocomplete();
    this.commodityService.resetCommodityAutoComplete.subscribe((isReset) => {
      if (isReset) {
        this.resetCommodityAutocomplete();
      }
    });
  }

  onSelectCommodity(commodity: HarmonizedSystemCode) {
    this.selectedCommodity.emit(commodity);
  }

  private initializeCommodityControl() {
    this.commodityControl =
      this.commodityControl === undefined
        ? new FormControl()
        : this.commodityControl;
  }

  private initializeCommodityAutocomplete() {
    this.initializeCommodityControl();
    this.loadCommodities();
  }

  private loadCommodities() {
    this.commodityControl.valueChanges
      .pipe(
        startWith(''),
        debounce(() => interval(400)),
        distinctUntilChanged(),
        switchMap((val) => {
          const isSearch = this.checkCommodityBeforeSearch(val);
          return isSearch === true ? this.search(val) : of(isSearch);
        }),
        startWith('')
      )
      .subscribe((x: Page<HarmonizedSystemCode>) => {
        this.isLoading = false;
        if (this.isCommoditySelected) {
          this.isCommoditySelected = false;
        } else {
          this.commodityResponse = x.items;
        }
      });
  }

  private search(searchValue: string): Observable<Page<HarmonizedSystemCode>> {
    this.isLoading = true;
    let query = new HarmonizedSystemCodeQuery();
    query.pageNo = this.pageNo;
    query.pageSize = this.pageSize;
    query.description = searchValue;
    query.isoCode = searchValue;
    query.any = true;
    query.sortBy = HarmonizedSystemCodeQueryParameters.isoCode;
    
    return this.commodityService.getPage(query)
      .pipe(map((response) => response));
  }

  private checkMinimumLength(value: string) {
    return !(value.length >= this.minimumLength);
  }

  private checkCommodityBeforeSearch(value: string) {
    if (this.isCommoditySelected) {
      return this.commodityResponse;
    }

    if (this.checkMinimumLength(value)) {
      return { items: [] };
    }
    return true;
  }

  private resetCommodityAutocomplete() {
    this.isCommoditySelected = false;
    this.commodityResponse = [];
    this.commodityControl.patchValue('');
  }
}
