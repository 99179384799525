<div class="postal-code-popup-wrapper">
  <div class="close-icon-wrapper" (click)="onClose()">
    <i class="panel-header-icon s4d-icon s4d-close"></i>
  </div>
  <div class="postal-code-popup-content">
    <div class="zipcode-icon-wrapper">
      <i class="s4d-icon s4d-zipcode"></i>
    </div>
    <div class="zipcode-tiltle-description-wrapper">
      <h2 class="zipcode-title">Add ZIP Code</h2>
      <p class="zipcode-description">Please provide your organization's ZIP code to continue.</p>
    </div>
    <div class="zipcode-input-wrapper">
      <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
        <div class="row h-100">
          <div class="col d-flex align-items-end">
            <input #postalCodeInput matInput type="text" placeholder="ZIP Code" [formControl]="postalCode" />
          </div>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="submit-button-wrapper">
    <button (click)="onSubmit()"
      [ngClass]="{'btn-disable': (postalCode.touched && postalCode.hasError('required')) || isLoading}"
      [disabled]="(postalCode.touched && postalCode.hasError('required')) || isLoading">Submit
      <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
    </button>
  </div>
</div>