import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable, Subject } from 'rxjs';
import { TeamMember } from './organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private returnUrl: string = "/";
  dataSubject: Subject<null> = new Subject<null>();
  acronymName = new EventEmitter();

  constructor(
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService
  ) { }

  getTeamMember(organizationId): Observable<TeamMember> {
    const params = new HttpParams().set("organizationId", organizationId);
    return this.http.get<TeamMember>(`${this.internalUrlsService.getApiBaseUrl()}/teammembers`,
      { params }
    );
  }
}
