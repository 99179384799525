<div class="boundry">
  <div class="mobile-layout" [ngClass]="{'quote-flow-bg': (mode === modes.QUOTEFLOW || mode === modes.BOOKINGFLOW)}">
    <div class="mobile-toolbar">
      <app-toolbar></app-toolbar>
    </div>
    <div class="mobile-content"
      [ngClass]="{'quote-flow': (mode === modes.QUOTEFLOW), 'booking-flow': (mode === modes.BOOKINGFLOW)}">
      <app-content></app-content>
    </div>
  </div>
  <!-- <app-live-assistance *ngIf="chatExists"></app-live-assistance> -->
  <app-live-assistance ></app-live-assistance>
</div>
