import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User, UserService } from '@ship4wd/ngx-authorization';
import { OrganizationsService } from './organizations.service';
import { Subscription, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TeamMemberOrganization, UserProfile, HomeProfileQuery, OrganizationProfile, ChatProfile, KycStatusCode } from '../../shared/accounts/accounts.model';
import { AccountsService } from '../../shared/accounts/accounts.service';
import { OrganizationSettingsData } from '../../shared/organizations/organization.model';
import { SalesforceService } from '../../shared/services/salesforce.service';
import { AccessRoleType, TeamAccessRole } from '../../shared/shared.model';
import { WhatsAppService } from '../../shared/whatsapp-button/whatsapp.service';
import { NotificationType } from './organizations.model';
import { GoogleAnalyticsService } from '../../shared/google-analytics/google-analytics.service';
import { QuotesService } from '../quotes/quotes.service';
import { AppcuesService } from '../../shared/appcues/appcues.service';
import { UserInfoService } from '../../shared/services/user-info/user-info.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationsComponent implements OnInit {
  user: User;
  userProfile: UserProfile;

  autoSelectedId: string = null;
  autoCountryCode: string = null;
  autoSelectedName: string = null;
  autoSelectedIsCreditOrganization: boolean = false;
  registerButtonTitle: string = '';
  returnUrl: string = "/";
  organizationFromUrl: string = null;

  isLoading: boolean = true;
  isRedirected: boolean = false;
  isRegister: boolean = false;
  isNoOrganizations = false;
  isRegisterButtonTitleVisible: boolean = true;

  accepted: Array<TeamMemberOrganization> = [];
  pending: Array<any> = [];
  invited: Array<any> = [];

  organizationSubscription: Subscription;

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private accountsService: AccountsService,
    private salesforceService: SalesforceService,
    private whatsAppService: WhatsAppService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private quotesService: QuotesService,
    private appcuesService: AppcuesService,
    private userInfoService: UserInfoService) { }

  get chatExists(): boolean {
    const chat = document.getElementById('embedded-messaging');

    return chat ? true : false;
  }

  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "dashboard";
    this.user = this.userService.getUser();
    this.isRedirected = this.route.snapshot.queryParams["r"]
      ? this.route.snapshot.queryParams["r"].toLocaleLowerCase() === "true"
      : false || true;
    this.organizationFromUrl = this.route.snapshot.queryParams["o"];
    this.organizationsService.setReturnUrl(this.returnUrl);

    this.getData();
    this.organizationsService.dataSubject.subscribe((x) => this.getData());
  }

  getData(): void {
    var query = {} as HomeProfileQuery;

    of(null)
      .pipe(
        switchMap(() => this.accountsService.getUserProfile()),
        tap((userProfile) => {
          this.userProfile = userProfile;
          query.email = userProfile.email;

          if (userProfile.fullName) {
            const firstLetter = userProfile.fullName.substring(0, 1);
            const secondLetter = userProfile.fullName.substring(
              userProfile.fullName.indexOf(" ") + 1,
              userProfile.fullName.indexOf(" ") + 2
            );
            this.organizationsService.setUserFullName(userProfile.fullName);
            this.organizationsService.setUserAcronymName(
              firstLetter + secondLetter
            );
            this.organizationsService.showUserAcronymName(
              firstLetter + secondLetter
            );
          }
        }),
        switchMap(() => this.accountsService.getOrganizationProfiles()),
        tap((organizationProfile: OrganizationProfile) => {
          this.accepted =
            organizationProfile.organizations.filter(
              (x) => x.kycStatusCode === KycStatusCode.Approve
            ) ?? [];
          this.organizationsService.setUserOrganizations(this.accepted);

          this.pending =
            organizationProfile.organizations.filter(
              (x) => x.kycStatusCode === KycStatusCode.Pending
            ) ?? [];
          this.invited =
            organizationProfile.invites.filter(
              (x) => x.inviteStatusCode === 1
            ) ?? [];

          if (this.getIsFromSSO() && this.accepted.length > 0) {
            this.googleAnalyticsService.createAccountOrganizationKycApproved()
          }
          this.isRegisterButtonTitleVisible = true;
          this.registerButtonTitle =
            this.accepted.length > 0
              ? "Register another company"
              : "Register your company";
          if (
            this.accepted.length === 0 &&
            this.invited.length === 0 &&
            this.pending.length === 0
          ) {
            this.isNoOrganizations = true;
          }

          if (
            this.accepted.length === 1 &&
            this.invited.length === 0 &&
            this.pending.length === 0
          ) {
            this.autoSelectedId = this.accepted[0].organizationId;
            this.autoCountryCode = this.accepted[0].countryCode;
            this.autoSelectedName = this.accepted[0].organizationName;
            this.autoSelectedIsCreditOrganization = this.accepted[0].isCreditOrganization;
          }
          if (this.organizationFromUrl) {
            const isOrganizationFromUrlAccepted =
              this.accepted.findIndex(
                (x) => x.organizationId == this.organizationFromUrl
              ) > -1;

            if (isOrganizationFromUrlAccepted) {
              this.autoSelectedName = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).organizationName;

              this.autoCountryCode = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).countryCode;

              this.autoSelectedIsCreditOrganization = this.accepted.find(
                (x) => x.organizationId == this.organizationFromUrl
              ).isCreditOrganization;
            }
            this.autoSelectedId = isOrganizationFromUrlAccepted
              ? this.organizationFromUrl
              : null;

            this.userInfoService.setDepartureDateFlexibilitySettings();
          }
        }),
        switchMap(() => {
          return this.autoSelectedId
            ? this.accountsService.getUserProfile(this.autoSelectedId)
            : of(null);
        }),
        tap((userProfile: UserProfile) => {
          if (userProfile == null) {
            this.isLoading = false;
            this.quotesService.removeQuotesResultsFromStorage();
          } else {
            if (userProfile.accessRoles.length > 0) {
              const roles: AccessRoleType[] = [];
              userProfile.accessRoles.map((x: TeamAccessRole) => {
                roles.push(x.accessRoleType);
              });
              this.organizationsService.setUserRoles(roles);
            } else {
              this.organizationsService.setUserRoles(null);
            }

            if (userProfile && userProfile.teamMemberSettings) {
              const teamMemberSettings = userProfile.teamMemberSettings;
              teamMemberSettings.disableHelpPopup = false;
            }

            this.organizationsService.setUserSettingsAndInfo(userProfile);

            if (!this.whatsAppService.isWhatsAppEnabled())
              this.enableSalesforceChat(userProfile, this.autoSelectedId, this.autoSelectedName);

            const organizationSettingsData: OrganizationSettingsData = {
              organizationId: this.autoSelectedId,
              countryCode: this.autoCountryCode,
              organizationName: this.autoSelectedName,
              isCreditOrganization: this.autoSelectedIsCreditOrganization
            };

            this.organizationsService.setOrganization(organizationSettingsData);
            this.quotesService.removeQuotesResultsFromStorage();
            this.appcuesService.identify();

            if (this.isRedirected) {
              this.organizationsService.returnBack();
            } else {
              this.isLoading = false;
            }
          }
        })
      )
      .subscribe(
        () => { },
        (error) => {
          this.organizationsService.notify(error, NotificationType.ERROR);
          this.isLoading = false;
        }
      );
  }

  createAccount(): void {
    if (this.isRegister) {
      this.isRegister = false;
    } else {
      this.isRegister = true;
    }

    this.googleAnalyticsService.organizationsClick("register another company");
  }

  getIsFromSSO(): boolean {
    return !this.route.snapshot.queryParams["r"];
  }

  private enableSalesforceChat(userProfile: UserProfile, organizationId: string, organizationName: string): void {
    let chatUser: ChatProfile = {
      fullName: userProfile.fullName,
      email: userProfile.email,
      phoneNumber: userProfile.phoneNumber,
      companyName: organizationName,
      userId: userProfile.id,
      organizationId: organizationId
    };
    localStorage.setItem("chatUserInfo", JSON.stringify(chatUser));
    this.salesforceService.onUserLogout();
    this.salesforceService.addUserChat();
  }
}
