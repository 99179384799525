import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-fourty-seas-init-dialog',
  templateUrl: './fourty-seas-init-dialog.component.html',
  styleUrls: ['./fourty-seas-init-dialog.component.scss'],
  encapsulation:  ViewEncapsulation.None
})
export class FourtySeasInitDialogComponent {
}
