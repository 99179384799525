import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as uuid from 'uuid';
import { DateUtils, NotificationService } from '@ship4wd/ngx-common';
import { Incoterm, IncotermsMappingsQuery, QuotesQuery } from '../../../../../desktop/quotes/quotes.model';
import {
  AddressTypeCode,
  GoogleAddress,
  IncotermCode,
  LocationType,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchLocationAnalyticsViewModel
} from '../../../../../shared/shared.model';
import { QuoteSearchFlowDateComponent } from '../flow-date/flow-date.component';
import { QuoteSearchFlowCargoComponent } from '../flow-cargo/flow-cargo.component';
import { QuoteSearchFlowParamsService } from '../quote-search-flow-params.service';
import { StringCutterService } from '../../../../../shared/services/text-cutter/stringCutter.service';
import { QuotesIncotermService } from '../../../../../desktop/quotes/quotes-incoterm.service';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { QuoteSearchFlowNewLocationComponent } from '../flow-new-location/flow-new-location.component';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { skip, tap } from 'rxjs/operators';

@Component({
  selector: 'app-quote-search-new-flow-params',
  templateUrl: './new-flow-params.component.html',
  styleUrls: ['./new-flow-params.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchNewFlowParamsComponent implements OnInit, OnChanges {
  @Input() shippingTypes: any[] = [];
  @Input() quoteSearchForm: FormGroup = undefined;
  @Input() searchId: string = '';
  @Input() reset: any = null;
  @Input() isReuse: boolean;
  @Input() isLoading: boolean = false;
  @Input() isBack: boolean = false;
  @Input() isFirstSearch: boolean = true;
  @Input() isRecentQuotesSearch: boolean;


  @Output() searchQuotesEvent = new EventEmitter<QuotesQuery>();

  quoteSearchParameters = QuoteSearchParameters;

  searchType = SearchType;
  incoterms: Incoterm[] = null;

  paramsTitle: any[] = [{}, {}, {}, {}, {}];

  isSelected: boolean[] = [true, false, false, false, false];
  isError: boolean[] = [false, false, false, false, false];

  locationTypeShortNames: string[] = ['Door', 'AirPort', 'SeaPort', 'Inland port', 'Warehouse(cfs)'];

  currentShippingType: any;
  selectedGoogleAddress: GoogleAddress;
  oldShipmentType: ShipmentType;
  previousShipmentType: ShipmentType;

  get form(): any {
    return this.quoteSearchForm.controls;
  }

  get shipmentType(): any {
    return this.quoteSearchForm.get('shipmentType')?.value;
  }

  @ViewChild('dialogContainer', { read: ViewContainerRef })
  container: ViewContainerRef;

  constructor(
    private rollupDialogService: RollupDialogService,
    private quotesIncotermService: QuotesIncotermService,
    private stringCutterService: StringCutterService,
    private quoteSearchFlowParamsService: QuoteSearchFlowParamsService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    (this.form.shipmentType as FormControl).valueChanges.subscribe((x: ShipmentType) => {
      this.resetHazardousAndBatteries();
      this.oldShipmentType = this.quoteSearchForm.value['shipmentType'];
      this.previousShipmentType = this.oldShipmentType;
      this.isSelected = [
        true,
        false,
        false,
        this.isSelected[QuoteSearchParameters.cargo],
        this.isSelected[QuoteSearchParameters.date]
      ];
      this.setCargoTitle(this.isSelected[QuoteSearchParameters.cargo]);
      this.setDateTitle(this.isSelected[QuoteSearchParameters.date]);
      this.updateShipmentType();
    });

    this.updateShipmentType();

    (this.form.isHazardous as FormControl).valueChanges.pipe(
      skip(1),
      tap((x: boolean) => {
        if (x) {
          this.googleAnalyticsService.newSearchCargoDetailsIsHazardousChecked(this.getNewSearchLocationAnalytics())
        } else {
          this.googleAnalyticsService.newSearchCargoDetailsIsHazardousUnchecked(this.getNewSearchLocationAnalytics())
        }
      })
    ).subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReuse?.currentValue) {
      this.setLocationTitle(SearchType.from);
      this.setLocationTitle(SearchType.to);
      this.setCargoTitle(true);
      this.setDateTitle(true);

      this.onViewQuotes();
      this.previousShipmentType = null;
      this.oldShipmentType = null;
      this.isReuse = false;
    }

    if (changes.reset?.currentValue != undefined) {
      this.isSelected = [true, false, false, false, false];
    }

    if (changes.isBack && !changes.reset) {
      this.setLocationTitle(SearchType.from);
      this.setLocationTitle(SearchType.to);
      this.setCargoTitle(true);
      this.setDateTitle(true);
    }
  }

  onClickParam(type: QuoteSearchParameters): void {
    switch (type) {
      case QuoteSearchParameters.from:
        {
          const data = {
            control: this.form.from,
            type: SearchType.from,
            searchId: this.searchId,
            shipmentType: this.form.shipmentType.value,
            from: this.form.from.value,
            to: this.form.to.value,
            selectedGoogleAddress: this.selectedGoogleAddress,
            oldShipmentType: this.previousShipmentType
          };

          const dialogRef = this.rollupDialogService.open(QuoteSearchFlowNewLocationComponent, data);
          const previousFromValue = this.form.from.value.value;

          dialogRef.subscribe(data => {
            if (data !== undefined && data != null) {
              if (previousFromValue !== data.control.value.value) {
                this.resetHazardousAndBatteries();
              }

              this.isError[QuoteSearchParameters.from] = false;
              this.form.from = data.control;
              this.searchId = data.searchId;
              this.selectedGoogleAddress = data.selectedGoogleAddress;
              this.getIncoterms();
              this.setLocationTitle(SearchType.from);
              this.previousShipmentType = null;
            } else {
              if (!this.isSelected[QuoteSearchParameters.from]) {
                this.quoteSearchForm.get('from')?.setValue('');
                this.quoteSearchForm.get('from')?.markAsUntouched();
                this.previousShipmentType = null;
                this.googleAnalyticsService.newSearchLocationOriginAddressComplete(this.getNewSearchLocationAnalytics())
              }
            }
          });
          this.newSearchLocationTouched(QuoteSearchParameters.from)
        }
        break;
      case QuoteSearchParameters.to:
        {
          const data = {
            control: this.form.to,
            type: SearchType.to,
            searchId: this.searchId,
            shipmentType: this.form.shipmentType.value,
            from: this.form.from.value,
            to: this.form.to.value,
            selectedGoogleAddress: this.selectedGoogleAddress,
            oldShipmentType: this.previousShipmentType ?? this.oldShipmentType
          };

          const dialogRef = this.rollupDialogService.open(QuoteSearchFlowNewLocationComponent, data);
          const previousToValue = this.form.to.value.value;

          dialogRef.subscribe(data => {
            if (data !== undefined && data != null) {
              if (previousToValue !== data.control.value.value) {
                this.resetHazardousAndBatteries();
              }

              this.isError[QuoteSearchParameters.to] = false;
              this.form.to = data.control;
              this.searchId = data.searchId;
              this.form.shipperType.setValue(data.shipperType === undefined ? ShipperType.importer : data.shipperType);
              this.selectedGoogleAddress = data.selectedGoogleAddress;
              this.getIncoterms();
              this.setLocationTitle(SearchType.to);
              this.oldShipmentType = null;
            } else {
              if (!this.isSelected[QuoteSearchParameters.to]) {
                this.quoteSearchForm.get('to')?.setValue('');
                this.quoteSearchForm.get('to')?.markAsUntouched();
                this.oldShipmentType = null;
                this.googleAnalyticsService.newSearchLocationDestinationAddressComplete(this.getNewSearchLocationAnalytics())
              }
            }
          });
          this.newSearchLocationTouched(QuoteSearchParameters.to)
        }
        break;
      case QuoteSearchParameters.cargo:
        {
          const data = {
            isHazardous: this.form.isHazardous,
            isBatteries: this.form.isBatteries,
            from: this.form.from.value.value,
            to: this.form.to.value.value,
            commoditiesFormArray: this.form.containerCommodities,
            equipmentsFormArray: this.form.equipments,
            shipmentType: this.form.shipmentType.value,
            isShowPackageDimensions: this.form.isShowPackageDimensions,
            unitMeasurementType: this.form.unitMeasurementType,
            locationAnalitics: this.getNewSearchLocationAnalytics()
          };

          const dialogRef = this.rollupDialogService.open(QuoteSearchFlowCargoComponent, data);

          this.googleAnalyticsService.newSearchCargoDetailsStart(this.getNewSearchLocationAnalytics())
          dialogRef.subscribe(data => {
            if (data !== undefined && data != null) {
              this.isError[QuoteSearchParameters.cargo] = false;
              this.form.isHazardous = data.isHazardous;
              this.form.isBatteries = data.isBatteries;
              this.form.containerCommodities = data.commoditiesFormArray;
              this.form.equipments = data.equipmentsFormArray;
            }
            else {
              if (this.form.shipmentType !== ShipmentType.FCL) {
                const invalidLoads = this.form.containerCommodities?.controls.filter(x => !x.valid);

                invalidLoads.forEach((load: FormGroup) => {
                  if (!load.valid) {
                    this.form.containerCommodities.removeAt(
                      this.form.containerCommodities?.controls.indexOf(load)
                    );
                  }
                });
              }
            }

            this.setCargoTitle(true);

            if (this.quotesSearchStepContainers()) {
              this.googleAnalyticsService.newSearchCargoDetailsComplete(this.getNewSearchLocationAnalytics())
            } else {
              this.googleAnalyticsService.newSearchCargoDetailsChurn(this.getNewSearchLocationAnalytics())
            }
          });
        }
        break;
      case QuoteSearchParameters.date:
        {
          const data = {
            control: this.form.fromDate,
            shipmentType: this.form.shipmentType.value
          };

          const dialogRef = this.rollupDialogService.open(QuoteSearchFlowDateComponent, data);

          this.googleAnalyticsService.newSearchDepartureDateStart(this.getNewSearchLocationAnalytics())
          dialogRef.subscribe(data => {
            if (data !== undefined && data != null) {
              this.isError[QuoteSearchParameters.date] = false;
              this.form.fromDate = data.control;

              this.setDateTitle(true);
            }
            this.googleAnalyticsService.newSearchDepartureDateComplete(this.getNewSearchLocationAnalytics())
          });
        }
        break;
      default: {
      }
    }
  }

  onViewQuotes(): void {
    this.setErrorStatuses();
    if ((!this.validateParams() && !this.quoteSearchForm.valid) || !this.checkAllSelected()) {
      this.notificationService.error(`Can't search quotes because of an input errors, please check out the form`);
      return;
    }

    const quote = this.quoteSearchForm.getRawValue() as QuotesQuery;
    quote.fromDate = typeof (quote.fromDate) === 'object' ?
      DateUtils.toIsoDateString(quote.fromDate) :
      this.datepipe.transform(quote.fromDate, 'yyyy-MM-dd');

    if (this.quoteSearchForm.get('incoterms').value) {
      let incotermsCodes: IncotermCode[] = [];
      incotermsCodes.push(this.quoteSearchForm.get('incoterms').value);
      quote.incoterms = incotermsCodes;
    }

    if (this.quoteSearchForm.get('from').value) {
      quote.from.zipCode = quote.from.zipCode ? quote.from.zipCode : this.quoteSearchForm.get('from').value.postcode;
    }

    if (this.quoteSearchForm.get('to').value) {
      quote.to.zipCode = quote.to.zipCode ? quote.to.zipCode : this.quoteSearchForm.get('to').value.postcode;
    }
    quote.searchId = uuid.v4();

    this.searchQuotesEvent.emit(quote);
    this.googleAnalyticsService.newSearchViewQuotes(this.isFirstSearch, this.isRecentQuotesSearch);
  }

  getCutted(name: string): string {
    return this.stringCutterService.cutter(name, Math.round(this.getWidth() * 0.06));
  }

  updateShipmentType(): void {
    this.currentShippingType = this.shippingTypes.find(x => x.value == this.quoteSearchForm.get('shipmentType').value);
  }

  onOpenClick(): void {
    this.googleAnalyticsService.shipmentTypeStart(this.form.shipmentType.value);
  }

  onCloseSelect(): void {
    this.googleAnalyticsService.shipmentTypeComplete(this.form.shipmentType.value);
  }

  departureDateOpened(isOpened: boolean): void {
    if (isOpened) {
      return;
    }

    this.googleAnalyticsService.newSearchDepartureDateComplete(this.getNewSearchLocationAnalytics())
  }

  private getWidth(): number {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  private checkLocationType(locationType: LocationType): boolean {
    const ports = [LocationType.seaPort, LocationType.containerTerminal, LocationType.inlandPort];
    const others = [
      LocationType.industrialZone,
      LocationType.busStation,
      LocationType.island,
      LocationType.heliport,
      LocationType.dryPort,
      LocationType.administrativeDivision
    ];
    const trains = [LocationType.railwayTerminal];
    const airports = [LocationType.airPort];

    return (
      !ports.includes(locationType) &&
      !others.includes(locationType) &&
      !trains.includes(locationType) &&
      !airports.includes(locationType)
    );
  }

  private formatDate(d: Date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const day = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  private setCargoTitle(state: boolean = false): void {
    if (this.shipmentType == ShipmentType.FCL) {
      const selected = this.form.equipments?.controls;

      let numberOfContainers = 0;
      selected.forEach(x => (numberOfContainers += Number(x.value.quantity) ?? 0));

      const result = numberOfContainers;

      this.isSelected[QuoteSearchParameters.cargo] = result > 0 && state;

      if (result > 0 && state) {
        this.paramsTitle[QuoteSearchParameters.cargo] = {
          result: result,
          title: result === 1 ? 'Container' : 'Containers'
        };
      }
    } else {
      const selected = this.form.containerCommodities?.controls;

      let numberOfPackages = 0;
      selected.forEach(x => (numberOfPackages += Number(x.value.numberOfPackages) ?? 0));

      const result = numberOfPackages;

      this.isSelected[QuoteSearchParameters.cargo] = result > 0;

      if (result > 0 && state) {
        this.paramsTitle[QuoteSearchParameters.cargo] = {
          result: result,
          title: result === 1 ? 'Load' : 'Loads'
        };
      }
    }
  }

  private setDateTitle(state: boolean = false): void {
    this.isSelected[QuoteSearchParameters.date] = state;

    if (state) {
      this.paramsTitle[QuoteSearchParameters.date] = {
        result: this.formatDate(new Date(this.form.fromDate.value)),
        title: 'Cargo Ready'
      };
    }
  }

  private setLocationTitle(type: SearchType): void {
    if (type === SearchType.from) {
      const result = this.form.from.value !== undefined && this.form.from.value !== null && this.form.from.value !== '';
      this.isSelected[QuoteSearchParameters.from] = result;

      if (result) {
        const isDoor = this.checkLocationType(this.form.from.value.locationType);
        const typeName =
          this.form.from.value.locationType > 4 ? '' : this.locationTypeShortNames[this.form.from.value.locationType];
        const title = `Pickup from ${typeName} ${this.form.from.value.zipCode && isDoor ? `(${this.form.from.value.zipCode})` : ''
          }`;

        this.paramsTitle[QuoteSearchParameters.from] = {
          result: this.form.from.value.displayName,
          title: title,
          locationType: this.form.from.value.locationType
        };
      }
    } else {
      const result = this.form.to.value !== undefined && this.form.to.value !== null && this.form.to.value !== '';
      this.isSelected[QuoteSearchParameters.to] = result;

      if (result) {
        const isDoor = this.checkLocationType(this.form.to.value.locationType);
        const typeName =
          this.form.to.value.locationType > 4 ? '' : this.locationTypeShortNames[this.form.to.value.locationType];
        const title = `Delivery to ${typeName} ${this.form.to.value.zipCode && isDoor ? `(${this.form.to.value.zipCode})` : ''
          }`;

        this.paramsTitle[QuoteSearchParameters.to] = {
          result: this.form.to.value.displayName,
          title: title,
          locationType: this.form.to.value.locationType
        };
      }
    }
  }

  private getIncoterms(): void {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      this.quotesIncotermService.getIncotremsByLocationTypes(query).subscribe(
        (x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        },
        error => this.notificationService.error(error)
      );
    } else {
      this.incoterms = null;
    }
  }

  private validateParams(): boolean {
    this.quoteSearchForm.markAllAsTouched();

    const commoditiesIsValid = this.quoteSearchFlowParamsService.validateCargoDetails(
      this.form.shipmentType.value,
      this.form.containerCommodities
    );
    this.isError[QuoteSearchParameters.cargo] = !(commoditiesIsValid && this.isSelected[QuoteSearchParameters.cargo]);

    return (
      this.form.from.valid &&
      this.form.to.valid &&
      this.form.fromDate.valid &&
      this.quotesSearchStepContainers() &&
      commoditiesIsValid &&
      this.quoteSearchForm.get('incoterms').value != null
    );
  }

  private checkAllSelected(): boolean {
    return this.isSelected.every(x => x === true);
  }

  private quotesSearchStepContainers(): boolean {
    let valid = true;
    if (this.form.shipmentType?.value === ShipmentType.LCL || this.form.shipmentType?.value === ShipmentType.AIR) {
      if (!this.requiredIf('containerCommodities')) {
        valid = false;
      }
    }
    if (this.form.shipmentType?.value === ShipmentType.FCL) {
      if (!this.requiredIf('equipments')) {
        valid = false;
      }
    }
    return valid;
  }

  private requiredIf(controlName: string): boolean {
    const isValid = this.quoteSearchForm.controls[controlName].valid;
    if (this.quoteSearchForm.controls[controlName] instanceof FormControl) {
      if (!isValid) {
        this.quoteSearchForm.controls[controlName].markAsTouched();
      }
    } else if (this.quoteSearchForm.controls[controlName] instanceof FormArray) {
      (this.quoteSearchForm.controls[controlName] as FormArray).controls.forEach(element => {
        if (element instanceof FormGroup) {
          Object.keys(element.controls).forEach(key => {
            const childControl = element.get(key);
            if (childControl instanceof FormControl) {
              if (!childControl.valid) {
                childControl.markAsTouched();
              }
            } else if (childControl instanceof FormArray) {
              (childControl as FormArray).controls.forEach(subElement => {
                if (subElement instanceof FormGroup) {
                  Object.keys(subElement.controls).forEach(subKey => {
                    const subChildControl = subElement.get(subKey);
                    if (!subChildControl.valid) {
                      subChildControl.markAsTouched();
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    return isValid;
  }

  private setErrorStatuses(): void {
    this.isError[QuoteSearchParameters.shipmentType] = !this.isSelected[QuoteSearchParameters.shipmentType];
    this.isError[QuoteSearchParameters.from] = !this.isSelected[QuoteSearchParameters.from];
    this.isError[QuoteSearchParameters.to] = !this.isSelected[QuoteSearchParameters.to];
    this.isError[QuoteSearchParameters.cargo] = !this.isSelected[QuoteSearchParameters.cargo];
    this.isError[QuoteSearchParameters.date] = !this.isSelected[QuoteSearchParameters.date];
  }

  private newSearchLocationTouched(type: QuoteSearchParameters): void {
    this.googleAnalyticsService.newSearchLocationLocationTouched(
      this.form.shipmentTYpe.value,
      type + 1,
      `where do you want to ship ${QuoteSearchParameters[type].toString()}?`
    )
  }

  private getAddressTypeCode(locationType: LocationType): AddressTypeCode {
    const port = [LocationType.seaPort, LocationType.railwayTerminal, LocationType.containerTerminal];
    const airport = [LocationType.airPort];
    const door = [LocationType.townCity];

    if (port.includes(locationType)) {
      return AddressTypeCode.port;
    }
    else if (airport.includes(locationType)) {
      return AddressTypeCode.airport;
    }
    else if (door.includes(locationType)) {
      return AddressTypeCode.door;
    }
    else {
      return AddressTypeCode.port;
    }
  }

  private getNewSearchLocationAnalytics(): TripsSearchLocationAnalyticsViewModel {
    const locationOrigin = this.quoteSearchForm.get("from").value;
    const locationDestination = this.quoteSearchForm.get("to").value;
    const departureDate = this.quoteSearchForm.get("fromDate").value as Date;

    const newSearchLocationAnalytics = {
      shipmentType: this.shipmentType,
      pickupType: this.getAddressTypeCode(locationOrigin.locationType),
      originCountry: locationOrigin.countryName,
      originCity: locationOrigin.cityName,
      deliveryType: this.getAddressTypeCode(locationDestination.locationType),
      destinationCountry: locationDestination.countryName,
      destinationCity: locationDestination.cityName,
      departureDate: DateUtils.toIsoDateString(departureDate),
    } as TripsSearchLocationAnalyticsViewModel;
    return newSearchLocationAnalytics;
  }

  private resetHazardousAndBatteries(): void {
    this.form.isHazardous.setValue(false);
    this.form.isBatteries.setValue(false);
  }
}
