import { Injectable, ErrorHandler } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private monitoringService: MonitoringService) {
        super();
    }

    handleError(error: Error): void {
        this.monitoringService.logException(error); // Manually log exception
    }
}