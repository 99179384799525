import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService, ValidationService } from "@ship4wd/ngx-common";
import {
  OrganizationInvitationViewModel,
  TeamMemberViewModel,
} from "../../../../desktop/manage-organization/manage-organization.model";
import { InviteMemberService } from "../../../../desktop/manage-organization/organization-members/invite-member-dialog/invite-member.service";
import { TeamMemberService } from "../../../../desktop/manage-organization/organization-members/team-member-dialog/team-member.service";
import { LayoutService } from "../../../../mobile/layout/layout.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import {
  AccessRoleType,
  InviteStatusCode,
  SelectItem,
  TeamMemberPageMode,
  TeamMemberPageType,
  TeamMemberStatusCode,
} from "../../../../shared/shared.model";

@Component({
  selector: "app-organization-member",
  templateUrl: "./organization-member.component.html",
  styleUrls: ["./organization-member.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationMemberComponent implements OnInit, AfterViewInit {
  @Input() type: TeamMemberPageType;
  @Input() mode: TeamMemberPageMode;
  @Input() member: any;
  @Output() backEvent = new EventEmitter<boolean>();

  memberForm: FormGroup;
  isLoading = false;
  isSubmit = false;

  types = TeamMemberPageType;
  modes = TeamMemberPageMode;

  memberStatusCodes: SelectItem[] = [
    {
      name: TeamMemberStatusCode[TeamMemberStatusCode.suspended],
      value: TeamMemberStatusCode.suspended,
    },
    {
      name: TeamMemberStatusCode[TeamMemberStatusCode.active],
      value: TeamMemberStatusCode.active,
    },
  ];

  inviteStatusCodes: SelectItem[] = [
    {
      name: InviteStatusCode[InviteStatusCode.pending],
      value: InviteStatusCode.pending,
    },
    {
      name: InviteStatusCode[InviteStatusCode.accepted],
      value: InviteStatusCode.accepted,
    },
    {
      name: InviteStatusCode[InviteStatusCode.canceled],
      value: InviteStatusCode.canceled,
    },
    {
      name: InviteStatusCode[InviteStatusCode.expired],
      value: InviteStatusCode.expired,
    },
    {
      name: InviteStatusCode[InviteStatusCode.rejected],
      value: InviteStatusCode.rejected,
    },
  ];

  teamAccessRoles: SelectItem[] = [
    {
      name: AccessRoleType[AccessRoleType.owner],
      value: AccessRoleType.owner,
    },
    {
      name: AccessRoleType[AccessRoleType.agent],
      value: AccessRoleType.agent
    }
  ];

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    private inviteMemberService: InviteMemberService,
    private teamMemberService: TeamMemberService,
    private settingsService: AppSettingsService
  ) { }

  ngOnInit(): void {
    if (this.type === TeamMemberPageType.invite) {
      this.createInviteMemberForm();
    } else {
      this.createTeamMemberForm();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let title = "";

      if (this.type === TeamMemberPageType.invite) {
        if (this.mode === TeamMemberPageMode.add) {
          title = "Invite Member";
        } else if (this.mode === TeamMemberPageMode.edit) {
          title = "Edit Member";
        }
      } else {
        title = "Edit Member";
      }

      this.layoutService.setToolbarTitle(title);
    }, 0);
  }

  getInitialName(): string {
    if (this.member && this.member.firstName) {
      return this.member.firstName.charAt(0).toUpperCase();
    }
    return "";
  }

  onCancel(): void {
    this.backEvent.emit(false);
  }

  onSave(): void {
    this.isSubmit = true;
    this.validationService.validate(this.memberForm);
    if (!this.memberForm.valid) {
      this.notificationService.error(
        "Can't save changes because of an input errors, please check out the form"
      );
      this.isSubmit = false;
      return;
    }

    if (this.type === TeamMemberPageType.invite) {
      const inviteMember = this.prepareInviteMemberViewModel();
      this.updateInviteMember(inviteMember);
    } else {
      const teamMember = this.prepareTeamMemberViewModel();
      this.updateTeamMember(teamMember);
    }
  }

  private prepareInviteMemberViewModel(): OrganizationInvitationViewModel {
    const inviteMember = this.memberForm.getRawValue();
    let inviteMemberViewModel = new OrganizationInvitationViewModel();
    inviteMemberViewModel.organizationId =
      this.settingsService.getSettings()?.organizationId;
    inviteMemberViewModel.email = inviteMember.email;
    inviteMemberViewModel.firstName = inviteMember.firstName;
    inviteMemberViewModel.lastName = inviteMember.lastName;
    inviteMemberViewModel.nickName = inviteMember.nickName;
    if (inviteMember.roles) {
      let roles = "";
      inviteMember.roles.forEach((x) => {
        roles += x + ",";
      });
      inviteMemberViewModel.roles = roles.slice(0, -1);
    }
    return inviteMemberViewModel;
  }

  private prepareTeamMemberViewModel(): TeamMemberViewModel {
    const teamMember = this.memberForm.getRawValue();
    let teamMemberViewModel = new TeamMemberViewModel();
    teamMemberViewModel.firstName = teamMember.firstName;
    teamMemberViewModel.lastName = teamMember.lastName;
    teamMemberViewModel.nickName = teamMember.nickName;
    teamMemberViewModel.email = teamMember.email;
    teamMemberViewModel.memberStatus = teamMember.memberStatus;
    teamMemberViewModel.organizationId =
      this.settingsService.getSettings()?.organizationId;
    if (teamMember.roles) {
      let roles = "";
      teamMember.roles.forEach((x) => {
        roles += x + ",";
      });
      teamMemberViewModel.roles = roles.slice(0, -1);
    }
    return teamMemberViewModel;
  }

  private createInviteMemberForm(): void {
    this.memberForm = this.fb.group({
      firstName: [
        {
          value: this.getInvitePropertyValue("firstName"),
          disabled: this.isDisabled(),
        },
        this.isRequired() ? Validators.required : [],
      ],
      lastName: [
        {
          value: this.getInvitePropertyValue("lastName"),
          disabled: this.isDisabled(),
        },
        this.isRequired() ? Validators.required : [],
      ],
      nickName: [this.getInvitePropertyValue("nickName")],
      roles: [this.getInvitePropertyValue("roles"), Validators.required],
      email: [
        {
          value: this.getInvitePropertyValue("email"),
          disabled: this.isDisabled(),
        },
        this.isRequired() ? [Validators.required, Validators.email] : [],
      ],
      inviteStatusCode: [
        {
          value: this.getInvitePropertyValue("inviteStatusCode"),
          disabled: this.isDisabled(),
        },
      ],
      invitationCreationDate: [this.getInvitePropertyValue("nickName")],
      expirationDate: [this.getInvitePropertyValue("expirationDate")],
      invitedBy: [this.getInvitePropertyValue("createdByName")],
    });
  }

  private createTeamMemberForm(): void {
    this.memberForm = this.fb.group({
      firstName: [
        { value: this.getMemberPropertyValue("firstName"), disabled: true },
        Validators.required,
      ],
      lastName: [
        { value: this.getMemberPropertyValue("lastName"), disabled: true },
        Validators.required,
      ],
      nickName: [this.getMemberPropertyValue("nickName")],
      roles: [
        this.getMemberPropertyValue("teamAccessRoles"),
        Validators.required,
      ],
      email: [
        { value: this.getMemberPropertyValue("email"), disabled: true },
        [Validators.required, Validators.email],
      ],
      memberStatus: [
        {
          value: this.getMemberPropertyValue("memberStatus"),
          disabled:
            this.getMemberPropertyValue("memberStatus") ==
              TeamMemberStatusCode.pending
              ? true
              : false,
        },
        Validators.required,
      ],
    });
  }

  private updateInviteMember(
    inviteMember: OrganizationInvitationViewModel
  ): void {
    this.isLoading = true;

    this.inviteMemberService
      .addEditInviteMember(inviteMember)
      .subscribe(
        () => {
          if (this.mode === TeamMemberPageMode.add) {
            this.notificationService.success(
              "Invitation has been successfully sent."
            );
            this.isSubmit = false;
          } else {
            this.notificationService.success(
              "Invite member has been successfully changed."
            );
            this.isSubmit = false;
          }
          this.backEvent.emit(true);
        },
        (error) => {
          this.notificationService.error(error);
          this.isSubmit = false;
        }
      )
      .add(() => {
        this.isLoading = false;
        this.isSubmit = false;
      });
  }

  private updateTeamMember(teamMember: TeamMemberViewModel): void {
    this.isLoading = true;

    this.teamMemberService
      .updateTeamMember(this.member?.id, teamMember)
      .subscribe(
        () => {
          this.notificationService.success(
            "Team member has been successfully changed."
          );
          this.isSubmit = false;
          this.backEvent.emit(true);
        },
        (error) => {
          this.notificationService.error(error);
          this.isSubmit = false;
        }
      )
      .add(() => {
        this.isLoading = false;
        this.isSubmit = false;
      });
  }

  private getInvitePropertyValue(propertyName: string): any {
    if (this.member) {
      if (propertyName === "roles") {
        if (this.member[propertyName]) {
          const roles: AccessRoleType[] = [];
          this.member[propertyName]
            .split(",")
            .map(Number)
            .forEach((x: AccessRoleType) => {
              roles.push(x);
            });
          return roles;
        }
      } else {
        return this.member[propertyName] ?? "";
      }
    } else {
      return "";
    }
  }

  private getMemberPropertyValue(propertyName: string): any {
    if (propertyName === "teamAccessRoles") {
      if (this.member[propertyName]) {
        const roles: AccessRoleType[] = [];
        this.member[propertyName].forEach((x) => {
          roles.push(x.accessRoleType);
        });
        return roles;
      }
    } else {
      return this.member[propertyName] ?? "";
    }
  }

  private isDisabled(): boolean {
    return this.mode === TeamMemberPageMode.add ? false : true;
  }

  private isRequired(): boolean {
    return this.mode === TeamMemberPageMode.add ? true : false;
  }
}
