import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { startWith } from 'rxjs/operators';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { OrganizationsService } from '../../desktop/organizations/organizations.service';
import { UserInfoService } from '../../shared/services/user-info/user-info.service';
import { AccessRoleType } from '../../shared/shared.model';
import { UserInfoOrganization } from '../../shared/features/user-info/user-info.model';
import { PersonalNotificationType } from '../../shared/personal-notifications/personal-notification.model';
import { PersonalNotificationsService } from '../../shared/personal-notifications/personal-notifications.service';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { GoogleAnalyticsService } from '../../shared/google-analytics/google-analytics.service';
import { OnQuoteSearchDialogComponent } from './on-quote-search-dialog/on-quote-search-dialog.component';
import { OnDashboardDialogComponent } from './on-dashboard-dialog/on-dashboard-dialog.component';
import { environment } from '../../../environments/environment';
import { LayoutService } from '../layout/layout.service';
import { QuotesService } from '../quotes/quotes.service';
import { SupportRequestsService } from '../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestMouseLeave } from '../support-requests/support-requests.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  @Input() authorized: boolean = true;
  @Input() transparent: boolean = false;

  isManageOrganizationMenuItemAccess?: boolean = null;
  isManageContactsMenuItemAccess?: boolean = null;
  acronymName: string = '';
  selectedOrganizationId: string = '';
  acceptedOrganizations: UserInfoOrganization[];
  hideUnsupportedIcons: boolean;
  isFinanceEnabled: boolean;
  showDashboard: boolean = false;
  showMyShipment: boolean = false;
  showFinance: boolean = false;
  isRefresh: boolean = false;
  currentRoute: string;
  showCreditLine: boolean = false;
  isCreditLineEnabled: boolean;
  isDashboardEnabled: boolean;
  areAnyNotificationsExist: boolean;
  highestNotificationType: PersonalNotificationType;
  areManageOrganizationNotificationsExist: boolean;
  highestManageOrganizationNotificationsType: PersonalNotificationType;
  quoteResultsPageIncludeResults: boolean;
  dialogRef: any = null;

  get isDashboardPage() {
    return this.router.url.includes('dashboard');
  }

  get isBookingsPage() {
    return this.router.url.includes('booking');
  }

  get isQuotesPage() {
    return this.router.url.includes('quote');
  }

  get isDashboardActive(): boolean {
    return this.currentRoute !== '/shipments'
      && this.currentRoute !== '/finance'
      && this.currentRoute !== '/creditLine'
      && !this.currentRoute.includes('/files-documents')
      && !this.currentRoute.includes('/booking')
      && !this.currentRoute.includes('/shipments')
      && !this.currentRoute.includes('/quote-search')
      && !this.currentRoute.includes('/shipping-instructions')
      && !this.currentRoute.includes('/paymentStatus');
  }

  get isMyShipmentsActive(): boolean {
    return this.currentRoute.includes('/files-documents/1')
      || this.currentRoute.includes('/files-documents/5')
      || this.currentRoute.includes('/booking/view')
      || this.currentRoute.includes('/shipments')
      || this.currentRoute.includes('/shipping-instructions');
  }

  get isFinanceActive(): boolean {
    return this.currentRoute.includes('/files-documents/3');
  }

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private appSettingsService: AppSettingsService,
    private layoutService: LayoutService,
    private quotesService: QuotesService,
    public dialog: MatDialog,
    organizationsService: OrganizationsService,
    private notificationsService: PersonalNotificationsService,
    private supportRequestsService: SupportRequestsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    organizationsService.acronymName.subscribe(name => {
      this.acronymName = name;
    });
    this.hideUnsupportedIcons = environment.hideUnsupportedIcons;
    this.isFinanceEnabled = this.getIsFinanceEnabled();
    this.isCreditLineEnabled = this.getIsCreditLineEnabled();
    this.isDashboardEnabled = this.getIsDasboardEnabled();
  }

  ngOnInit() {
    this.layoutService.setDefault();
    this.currentRoute = this.router.url;
    this.hasAccessOfManageOrganizationMenuItem();
    this.hasAccessManageContactsMenuItem();
    this.acronymName = this.userInfoService.getSettings().acronymName;
    this.acceptedOrganizations = this.userInfoService.getSettings().acceptedOrganizations;
    this.selectedOrganizationId = this.appSettingsService.getSettings().organizationId;

    this.layoutService.toolbarShowDashboard.subscribe(x => {
      this.showDashboard = x;
      this.isRefresh = true;
      setTimeout(() => {
        this.isRefresh = false;
      }, 100);
    });

    this.layoutService.toolbarShowMyShipment.subscribe(x => {
      this.showMyShipment = x;
      this.isRefresh = true;
      setTimeout(() => {
        this.isRefresh = false;
      }, 100);
    });

    this.layoutService.toolbarShowFinance.subscribe(x => {
      this.showFinance = x;
      this.isRefresh = true;
      setTimeout(() => {
        this.isRefresh = false;
      }, 100);
    });

    this.layoutService.toolbarShowCreditLine.subscribe(x => {
      this.showCreditLine = x;
      this.isRefresh = true;
      setTimeout(() => {
        this.isRefresh = false;
      }, 100);
    });

    this.layoutService.toolbarQuoteResultsPage.subscribe(x => {
      this.quoteResultsPageIncludeResults = x;
    });

    this.notificationsService.updateNotifications.pipe(startWith('')).subscribe(() => {
      this.areAnyNotificationsExist = this.notificationsService.areAnyNotificationsExist()

      this.highestNotificationType = this.notificationsService.getHighestNotificationType()

      this.areManageOrganizationNotificationsExist = this.notificationsService.areManageOrganizationNotificationsExist();

      this.highestManageOrganizationNotificationsType = this.notificationsService.getHighestManageOrganizationNotificationsType();
    })

    this.closeAllDialogs();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll({ event }: { event; }) {
    let toolbar = document.getElementById("toolbar-header");
    if (window.pageYOffset > toolbar.offsetTop) {
      toolbar.classList.add("sticky")
    } else {
      toolbar.classList.remove("sticky");
    }
  }

  onNewBooking() {
    if (this.isBookingsPage || this.quoteResultsPageIncludeResults) {
      const dialogRef = this.dialog.open(OnQuoteSearchDialogComponent, {
        panelClass: 'on-popup-container',
        autoFocus: false,
        data: {}
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.isSuccess) {
          this.navigateToQuoteSearch();
        }
      });
    } else {
      this.navigateToQuoteSearch();
    }
  }

  onDashboard() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/dashboard');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  onShipment() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/shipments');
    } else {
      this.router.navigate(['/shipments']);
    }
  }

  onFinance() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/finance');
    } else {
      this.router.navigate(['/finance']);
    }
  }

  onCreditLine() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/creditLine');
    } else {
      this.router.navigate(['/creditLine']);
    }
  }

  navigateToQuoteSearch() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.quotesService.stopSearchQuote$.next(true);
    this.quotesService.isFromSearchButton$.next(true);
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/quote-search']);
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  manageOrganization() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/manage-organization');
    } else {
      this.router.navigate(['/manage-organization']);
    }
  }

  manageContacts() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/manage-contacts');
    } else {
      this.router.navigate(['/manage-contacts']);
    }
  }

  onShowAllOrganizations() {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/organizations', { r: false });
    } else {
      this.router.navigate(['/organizations'], { queryParams: { r: false } });
    }
  }

  selectOrganization(id: string) {
    if (this.quoteResultsPageIncludeResults) {
      this.openDialogAndHandleResult('/organizations', { o: id });
    } else {
      this.router.navigate(['/organizations'], { queryParams: { o: id } });
    }

    this.googleAnalyticsService.organizationsClick("select");
  }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }

  toShipment() {
    this.router.navigate(['/shipments']);
  }

  toFinance() {
    this.router.navigate(['/finance']);
  }

  toCreditLine() {
    this.router.navigate(['/creditLine']);
  }

  onMouseLeave(event: MouseEvent): void {
    const { supportRequestMouseLeaveRequest, supportRequestDialogData } = this.createRequestForOpenSupportDialogOnMouseLeave(event);
    this.supportRequestsService.openSupportDialogOnMouseLeave(supportRequestMouseLeaveRequest, supportRequestDialogData);
  }

  private openDialogAndHandleResult(navigatePath: string, queryParams: any = {}): void {
    const dialogRef = this.dialog.open(OnDashboardDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.isSuccess) {
        this.router.navigate([navigatePath], { queryParams });
      }
    });
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private getIsCreditLineEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isCreditLineEnabled;
      case 'sb':
        return environment.sb.isCreditLineEnabled;
      default:
        return environment.isCreditLineEnabled;
    }
  }

  private getIsDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isDashboardEnabled;
      case "sb":
        return environment.sb.isDashboardEnabled;
      default:
        return environment.isDashboardEnabled;
    }
  }

  private hasAccessOfManageOrganizationMenuItem() {
    this.isManageOrganizationMenuItemAccess = this.userInfoService
      .isRolesSatisfied([AccessRoleType.admin, AccessRoleType.owner]);
  }

  private hasAccessManageContactsMenuItem(): void {
    this.isManageContactsMenuItemAccess = this.userInfoService
      .isRolesSatisfied([AccessRoleType.admin, AccessRoleType.owner]);
  }

  private createRequestForOpenSupportDialogOnMouseLeave(event: MouseEvent): {
    supportRequestMouseLeaveRequest: SupportRequestMouseLeave,
    supportRequestDialogData: SupportRequestDialogData
  } {
    const supportRequestMouseLeaveRequest = {
      event: event,
      isBookingsPage: this.isBookingsPage
    } as SupportRequestMouseLeave;

    const supportRequestDialogData = this.supportRequestsService.supportRequestDialogDataBehaviorSubject.value;

    return { supportRequestMouseLeaveRequest, supportRequestDialogData };
  }

  private closeAllDialogs(): void {
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }
}
