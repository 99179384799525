import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[S4DPlaces]'
})
export class S4DPlacesDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    return;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement
        .querySelectorAll('[ngx-google-places-autocomplete]')[0]
        .addEventListener('click', this.setStyles());
    }, 500);
  }

  private setStyles(): void {
    const pacContainer = document.getElementsByClassName('pac-container')[0] as HTMLBaseElement;
    if (typeof pacContainer == 'object') {
      this.el.nativeElement.classList.add('position-relative');
      this.el.nativeElement.appendChild(pacContainer);

      pacContainer.classList.add('mat-field');
      pacContainer.style.setProperty('--s4d-pac-height', this.el.nativeElement.offsetHeight + 'px');
    }
  }
}
