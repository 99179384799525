<div class="quote-search-flow">
  <div class="quote-search-flow-header-wrapper">
    <div class="mt-4" *ngIf="isOldSearchStyle">
      <app-quote-search-flow-params [quoteSearchForm]="quoteSearchForm" [isReuse]="isReuse" [isLoading]="isListLoading"
        [reset]="result" [searchId]="searchId" [isBack]="isBack" isEnabled="isSearchEnabled"
        (searchQuotesEvent)='onSearchQuotes($event)' [shippingTypes]="shippingTypes"></app-quote-search-flow-params>
    </div>
    <div class="mt-4" *ngIf="!isOldSearchStyle">
      <app-quote-search-new-flow-params [quoteSearchForm]="quoteSearchForm" [isReuse]="isReuse"
        [isLoading]="isListLoading" [reset]="result" [searchId]="searchId" [isBack]="isBack" isEnabled="isSearchEnabled"
        (searchQuotesEvent)='onSearchQuotes($event)' [shippingTypes]="shippingTypes"
        [isFirstSearch]="isFirstSearch" [isRecentQuotesSearch]="isRecentQuotesSearch"></app-quote-search-new-flow-params>
    </div>
  </div>
  <div #searchFlowContent class="quote-search-flow-content-wrapper">
    <div *ngIf="!isStarted" class="row quote-search-content-banner mx-0 px-3">
      <app-recent-quotes-widget class="mt-4 px-0"></app-recent-quotes-widget>
      <div class="col-12 watch-tutorial">
        <a class="tutorial-link" (click)="onOpenTutorial()">
          <span>Watch our tutorial</span>
          <span class="play-icon material-icons">play_circle_filled</span>
        </a>
      </div>
      <div class="quote-search-banner-line-text-line">
        <span class="quote-search-banner-line"></span>
        <span class="quote-search-banner-text">why us?</span>
        <span class="quote-search-banner-line"></span>
      </div>
      <div class="row m-0">
        <div class="col-12 px-0">
          <swiper-container class="banner-swiper-container" pagination="true" pagination-clickable="true"
            slides-per-view="1" centered-slides="true">
            <swiper-slide class="content-wrapper">
              <div class="quote-search-banner-info">
                <div class="certificate-img mb-2"></div>
                <span class="quote-search-banner-info-text">Federal Maritime Commission Registration No. 029468</span>
              </div>
            </swiper-slide>
            <swiper-slide class="content-wrapper">
              <div class="quote-search-banner-info">
                <div class="iata-cargo-img mb-2"></div>
                <span class="quote-search-banner-info-text">IATA Cargo Agent</span>
              </div>
            </swiper-slide>
            <swiper-slide class="content-wrapper">
              <div class="quote-search-banner-info">
                <div class="furnishings-img mb-2"></div>
                <span class="quote-search-banner-info-text">Member of the American Home Furnishings Alliance</span>
              </div>
            </swiper-slide>
            <swiper-slide class="content-wrapper">
              <div class="quote-search-banner-info">
                <div class="prizes-img mb-2"></div>
                <span class="quote-search-banner-info-text">Best <span class="text-purple">Freight Forwarder</span> for
                  SMBs U.S. and
                  Canada</span>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
    <div *ngIf="isStarted" class="quote-search-content px-3 py-4">
      <div *ngIf="isLoading && isStarted" class="row m-0 can-transform"
        [ngClass]="{'is-active': isLoading && isStarted}">
        <div class="col px-0 d-flex justify-content-center">
          <app-loader-with-images [isImage]="false" class="search-flow-loader-with-image"></app-loader-with-images>
        </div>
      </div>
      <div *ngIf="isLoading && isStarted" class="row m-0 mt-4 can-transform"
        [ngClass]="{'is-active': isLoading && isStarted}">
        <div class="col px-0 d-flex flex-column justify-content-center">
          <app-quote-search-flow-loader class="search-flow-loader"></app-quote-search-flow-loader>
        </div>
      </div>
      <div *ngIf="!isLoading && isStarted" class="row m-0 h-100 can-transform"
        [ngClass]="{'is-active': !isLoading && isStarted}">
        <div class="col px-0 d-flex flex-column justify-content-center">
          <app-no-quotes *ngIf="noQuotesFound; else quotesList" [quotesQuery]="quotesQuery"
            [quoteSearchId]="quoteSearchId"></app-no-quotes>
          <ng-template #quotesList>
            <app-quote-search-flow-results [quotes]="quotes" [isListLoading]="isListLoading" [isLoading]="isLoading"
              [shipmentType]="quoteSearchForm.get('shipmentType').value" [from]="from" [to]="to"
              [quotesQuery]="quotesQuery" [noQuotesFound]="noQuotesFound" [quoteSearchId]="quoteSearchId"
              (shipperTypeChanged)="onChangeShipperType($event)"> </app-quote-search-flow-results>
          </ng-template>
        </div>
      </div>
      <div *ngIf="!(isLoading && isStarted) && isListLoading" class="row m-0 mt-4 can-transform"
        [ngClass]="{'is-active': !(isLoading && isStarted) && isListLoading}">
        <div class="col px-0 d-flex flex-column justify-content-center">
          <app-quote-search-flow-loader></app-quote-search-flow-loader>
        </div>
      </div>
    </div>
  </div>
</div>