import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { OrganizationContact, OrganizationContactAction } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { ContactType } from '../../../../../shared/bookings/bookings.model';

@Component({
  selector: 'app-booking-flow-contacts-select',
  templateUrl: 'flow-contacts-select.component.html',
  styleUrls: ["./flow-contacts-select.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowContactsSelectComponent {
  searchTerm: string = "";
  contactType: ContactType;
  contacts: OrganizationContact[];
  filtered: OrganizationContact[];
  title: string;
  organizationContactActions = OrganizationContactAction;

  isLoading: boolean = false;
  isAddActive: boolean = false;

  constructor(
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.contactType = data.contactType;
    this.contacts = data.contacts;
    this.filtered = data.contacts;

    this.setTitle();
  }

  onSearchTermChanged(): void {
    this.filtered = [...this.contacts.filter((x: OrganizationContact) =>
      x.city.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      x.companyName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      x.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      x.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      x.address1.toLowerCase().includes(this.searchTerm.toLowerCase()))];
  }

  onClear(): void {
    this.searchTerm = "";
    this.onSearchTermChanged();
  }

  onAddContact(): void {
    this.isAddActive = true;
  }

  onSelectContact(contact: OrganizationContact, bookingContactId: string = ""): void {
    const data = {
      contactType: this.contactType,
      contact: contact,
      bookingContactId: bookingContactId
    }

    this.dialogRef.close(data);
  }

  onAddCanceled(): void {
    this.isAddActive = false;
  }

  onAdded(result: any): void {
    if (result) {
      this.isAddActive = false;
      this.onSelectContact(result.bookingContact, result.bookingContactId);
    }
  }

  private setTitle(): void {
    switch (this.contactType) {
      case ContactType.shipper: {
        this.title = "Contact at Pickup (Exporter/Supplier)"
        break;
      }
      case ContactType.consignee: {
        this.title = "Contact at Delivery (Importer)"
        break;
      }
      case ContactType.notifyParty: {
        this.title = "Contact For Notify"
        break;
      }
      case ContactType.collect: {
        this.title = "Contact for Door Pickup Address"
        break;
      }
      case ContactType.drop: {
        this.title = "Contact for Door Delivery Address"
        break;
      }
      default: {
        this.title = "Contact"
        break;
      }
    }
  }
}