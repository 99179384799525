import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { LocationTypeS4DIconPipe } from '../shared/pipes/location-type-s4d-icon.pipe';

import { AppLoaderComponent } from './app-loader/app-loader.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardHeaderComponent } from './dashboard/dashboard-header/dashboard-header.component';
import { LayoutComponent } from './layout/layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BookingComponent } from './booking/booking.component';
import { BookingTabsComponent } from './booking/booking-tabs/booking-tabs.component';
import { BookingIconsComponent } from './booking/booking-icons/booking-icons.component';
import { BookingReferenceComponent } from './booking/booking-tabs/booking-reference/booking-reference.component';
import { BookingContactsComponent } from './booking/booking-tabs/booking-contacts/booking-contacts.component';
import { BookingContainersComponent } from './booking/booking-tabs/booking-containers/booking-containers.component';
import { BookingSummaryComponent } from './booking/booking-tabs/booking-summary/booking-summary.component';
import { QuotesListComponent } from './quotes/quotes-list/quotes-list.component';
import { QuotesService } from './quotes/quotes.service';
import { QuotesRowComponent } from './quotes/quotes-list/quote-row-variants/default/quotes-row.component';
import { QuoteRateDetailRowComponent } from './quotes/quotes-list/quote-rate-detail-row-variants/default/quote-rate-detail-row.component';
import { BookingDocumentsComponent } from './booking/booking-tabs/booking-documents/booking-documents.component';
import { Ship4wdNgxManagerUIModule } from '@ship4wd/ngx-manager-ui';
import { ViewBookingComponent } from './booking/view-booking/view-booking.component';
import { ViewBookingDocumentComponent } from './booking/view-booking/view-booking-document/view-booking-document.component';
import { BookingCommodityDialogComponent } from './booking/booking-tabs/booking-commodity-dialog/booking-commodity-dialog.component';
import { BookingOrganizationContactsComponent } from './booking/booking-tabs/booking-contacts/booking-organization-contacts/booking-organization-contacts.component';
import { CreateBookingOrganizationContactComponent } from './booking/booking-tabs/booking-contacts/create-booking-organization-contact/create-booking-organization-contact.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationsManagerComponent } from './organizations/organizations-manager/organizations-manager.component';
import { OrganizationsListComponent } from './organizations/organizations-list/organizations-list.component';
import { OrganizationLineComponent } from './organizations/organization-line/organization-line.component';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { OrganizationDetailComponent } from './manage-organization/organization-detail/organization-detail.component';
import { OrganizationMembersComponent } from './manage-organization/organization-members/organization-members.component';
import { TeamMemberDialogComponent } from './manage-organization/organization-members/team-member-dialog/team-member-dialog.component';
import { InviteMemberDialogComponent } from './manage-organization/organization-members/invite-member-dialog/invite-member-dialog.component';
import { TeamMemberService } from './manage-organization/organization-members/team-member-dialog/team-member.service';
import { ManageOrganizationService } from './manage-organization/manage-organization.service';
import { InviteMemberService } from './manage-organization/organization-members/invite-member-dialog/invite-member.service';
import { NoBookingDialogComponent } from './booking/no-booking-dialog/no-booking-dialog.component';
import { OnQuoteSearchDialogComponent } from './toolbar/on-quote-search-dialog/on-quote-search-dialog.component';
import { OnDashboardDialogComponent } from './toolbar/on-dashboard-dialog/on-dashboard-dialog.component';
import { BookingFilesDocumentsComponent } from './booking-files-documents/booking-files-documents.component';
import { BookingFilesDocumentsListComponent } from './booking-files-documents/booking-files-documents-list/booking-files-documents-list.component';
import { BookingFilesDocumentsService } from './booking-files-documents/booking-files-documents.service';
import { CommentsDialogComponent } from './booking-files-documents/booking-files-documents-list/comments-dialog/comments-dialog.component';
import { BookingShippingInstructionsComponent } from './booking-shipping-instructions/booking-shipping-instructions.component';
import { BookingInstructionsDetailComponent } from './booking-shipping-instructions/booking-instructions-detail/booking-instructions-detail.component';
import { NotAllowShippingInstructionsDialogComponent } from './booking-shipping-instructions/not-allow-shipping-instructions-dialog/not-allow-shipping-instructions-dialog.component';
import { PrintBookingComponent } from './booking/print-booking/print-booking.component';
import { LayoutService } from '../desktop/layout/layout.service';
import { FinanceComponent } from './finance/finance.component';
import { RoutesService } from './routes/routes.service';
import { OrganizationCreateComponent } from './organizations/organization-create/organization-create.component';
import { CreditLineComponent } from './credit-line/credit-line.component';
import { ApplyCreditLineComponent } from './credit-line/apply-credit-line/apply-credit-line.component';
import { RequestedCreditLineComponent } from './credit-line/requested-credit-line/requested-credit-line.component';
import { EligibleCreditLineComponent } from './credit-line/eligible-credit-line/eligible-credit-line.component';
import { ClaimCreditLineComponent } from './credit-line/claim-credit-line/claim-credit-line.component';
import { CreditLinesService } from '../shared/creditLines/creditLines.service';
import { GridsterModule } from 'angular-gridster2';
import { ShipmentsComponent } from './shipments/shipments.component';
import { OrderListComponent } from './shipments/order-list/order-list.component';
import { OrderComponent } from './shipments/order/order.component';
import { OrderListCardViewComponent } from './shipments/order-list/order-list-card-view/order-list-card-view.component';
import { OrderListGridViewFinanceComponent } from './shipments/order-list/order-list-grid-view-finance/order-list-grid-view-finance.component';
import { RequestsAndPaymentsListComponent } from './shipments/order-list/order-list-grid-view-finance/requests-and-payments-list/requests-and-payments-list.component';
import { OrderListGridViewComponent } from './shipments/order-list/order-list-grid-view/order-list-grid-view.component';
import { OrderMenuComponent } from './shipments/order-menu/order-menu.component';
import { PaymentConfirmationDialogComponent } from './shipments/order/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { TransactionPaymentDialogComponent } from './shipments/order/transaction-payment-dialog/transaction-payment-dialog.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget/dashboard-widget.component';
import { QuotesWidgetComponent } from './dashboard/dashboard-widget/quotes-widget/quotes-widget.component';
import { OrdersWidgetComponent } from './dashboard/dashboard-widget/orders-widget/orders-widget.component';
import { CreditsWidgetComponent } from './dashboard/dashboard-widget/credits-widget/credits-widget.component';
import { AdsWidgetComponent } from './dashboard/dashboard-widget/ads-widget/ads-widget.component';
import { ManageContactsComponent } from './manage-contacts/manage-contacts.component';
import { ManageContactComponent } from './manage-contacts/manage-contact/manage-contact.component';
import { RejectedCreditLineComponent } from './credit-line/rejected-credit-line/rejected-credit-line.component';
import { WidgetReviewComponent } from './dashboard/dashboard-widget/widget-review/widget-review.component';
import { QuoteSearchWidgetComponent } from './dashboard/dashboard-widget/quote-search-widget/quote-search-widget.component';
import { QuotesResultsComponent } from './quotes/quotes-results/quotes-results.component';
import { QuoteSearchFlowComponent } from './quotes/quote-search-flow/quote-search-flow.component';
import { QuoteSearchFlowHeaderComponent } from './quotes/quote-search-flow/flow-header/quote-search-flow-header.component';
import { QuoteSearchFlowParamsComponent } from './quotes/quote-search-flow/flow-params/quote-search-flow-params.component';
import { QuoteSearchFlowShippingLocationComponent } from './quotes/quote-search-flow/flow-params/flow-shipping-location/quote-search-flow-shipping-location.component';
import { QuoteSearchFlowCargoDetailsComponent } from './quotes/quote-search-flow/flow-params/flow-cargo-details/quote-search-flow-cargo-details.component';
import { QuoteSearchFlowCargoDateComponent } from './quotes/quote-search-flow/flow-params/flow-cargo-date/quote-search-flow-cargo-date.component';
import { DatepickerCustomHeaderComponent } from './quotes/quote-search-flow/flow-params/flow-cargo-date/datepicker-custom-header/datepicker-custom-header.component';
import { QuoteSearchFlowShippingLocationOptionComponent } from './quotes/quote-search-flow/flow-params/flow-shipping-location/flow-shipping-location-option/quote-search-flow-shipping-location-option.component';
import { QuoteSearchFlowLoaderComponent } from './quotes/quote-search-flow/flow-loader/quote-search-flow-loader.component';
import { RateDetailsDialogComponent } from './quotes/quote-search-flow/rate-details/rate-details-dialog.component';
import { BookingFlowComponent } from './booking/booking-flow/booking-flow.component';
import { BookingFlowSidebarComponent } from './booking/booking-flow/booking-flow-sidebar/booking-flow-sidebar.component';
import { BookingFlowServicesComponent } from './booking/booking-flow/booking-flow-services/booking-flow-services.component';
import { BookingFlowCargoDetailsComponent } from './booking/booking-flow/booking-flow-cargo-details/booking-flow-cargo-details.component';
import { BookingFlowContactsComponent } from './booking/booking-flow/booking-flow-contacts/booking-flow-contacts.component';
import { BookingFlowSummaryComponent } from './booking/booking-flow-summary/booking-flow-summary.component';
import { BookingFlowSummarySidebarComponent } from './booking/booking-flow-summary/booking-flow-summary-sidebar/booking-flow-summary-sidebar.component';
import { BookingFlowContactComponent } from './booking/booking-flow/booking-flow-contacts/booking-flow-contact/booking-flow-contact.component';
import { BookingFlowCreateContactComponent } from './booking/booking-flow/booking-flow-contacts/booking-flow-create-contact/booking-flow-create-contact.component';
import { BookingFlowSummaryDocumentComponent } from './booking/booking-flow-summary/booking-flow-summary-document/booking-flow-summary-document.component';
import { BookingFlowService } from './booking/booking-flow/booking-flow.service';
import { ServiceCostEstimatorComponent } from './booking/booking-flow/booking-flow-services/service-cost-estimator/service-cost-estimator.component';
import { AdditionalServiceDialogComponent } from './booking/booking-flow/booking-flow-services/additional-service-dialog/additional-service-dialog.component';
import { AdditionalServiceCustomsComponent } from './booking/booking-flow/booking-flow-services/additional-service-customs/additional-service-customs.component';
import { AdditionalServicePreShipmentComponent } from './booking/booking-flow/booking-flow-services/additional-service-pre-shipment/additional-service-pre-shipment.component';
import { NoQuotesComponent } from './quotes/quotes-results/no-quotes/no-quotes.component';
import { AdditionalServiceInsuranceComponent } from './booking/booking-flow/booking-flow-services/additional-service-insurance/additional-service-insurance.component';
import { LiveAssistanceComponent } from './live-assistance/live-assistance.component';
import { ChangeCargoConfirmDialogComponent } from './booking/booking-flow/booking-flow-cargo-details/change-cargo-confirm-dialog/change-cargo-confirm-dialog.component';
import { AdditionalServiceInsuranceManuallyComponent } from './booking/booking-flow/booking-flow-services/additional-service-insurance-manually/additional-service-insurance-manually.component';
import { QuoteNewSearchFlowParamsComponent } from './quotes/quote-search-flow/flow-params/quote-new-search-flow-params/quote-new-search-flow-params.component';
import { NewFlowShippingLocationComponent } from './quotes/quote-search-flow/flow-params/new-flow-shipping-location/new-flow-shipping-location.component';
import { NewFlowShippingLocationFieldsComponent } from './quotes/quote-search-flow/flow-params/new-flow-shipping-location/new-flow-shipping-location-fields/new-flow-shipping-location-fields.component';
import { QuoteSearchFlowOldComponent } from './quotes/quote-search-flow/quote-search-flow-old/quote-search-flow-old.component';
import { QuoteSearchFlowNewComponent } from './quotes/quote-search-flow/quote-search-flow-new/quote-search-flow-new.component';
import { QuotesIncotermService } from './quotes/quotes-incoterm.service';
import { QuoteRateDetailRowRedesignedComponent } from './quotes/quotes-list/quote-rate-detail-row-variants/new-redesigned/quote-rate-detail-row.component';
import { FlowShipmentTypeComponent } from './quotes/quote-search-flow/flow-params/flow-shipment-type/flow-shipment-type.component';
import { QuoteRowPriceComponent } from './quotes/quotes-list/quote-row-price-variants/default/quote-row-price.component';
import { QuoteRowPriceRedesignedComponent } from './quotes/quotes-list/quote-row-price-variants/new-redesigned/quote-row-price.component';
import { RateDetailsDialogDefaultComponent } from './quotes/quotes-list/quote-rate-detail-row-variants/default/rate-details-dialog/rate-details-dialog.component';
import { SupportRequestDialogComponent } from './support-requests/support-request-dialog/support-request-dialog.component';
import { OrganizationsEmptyComponent } from './organizations/organizations-empty/organizations-empty.component';
import { WelcomeTutorialDialogComponent } from './welcome-tutorial/welcome-tutorial-dialog/welcome-tutorial-dialog.component';
import { WelcomeTutorialService } from './welcome-tutorial/welcome-tutorial.service';
import { QuoteSearchFlowLayoutComponent } from './quotes/quote-search-flow/quote-search-flow-layout/quote-search-flow-layout.component';
import { QuotePriceTiersComparisonComponent } from './quotes/quotes-list/quote-row-variants/quote-price-tiers-comparison/quote-price-tiers-comparison.component';
import { QuoteRateDetailRowServiceComponent } from './quotes/quotes-list/quote-rate-detail-row-variants/new-redesigned/quote-rate-detail-row-service/quote-rate-detail-row-service.component';
import { SortOptionsComponent } from './quotes/quotes-results/sort-options/sort-options.component';
import { PostalCodeUpdateDialogComponent } from './booking/booking-flow/booking-flow-services/postal-code-update-dialog/postal-code-update-dialog.component';
import { RecentQuotesWidgetComponent } from './dashboard/dashboard-widget/recent-quotes-widget/recent-quotes-widget.component';
import { QuotesBannerComponent } from './quotes/quotes-list/quotes-banner/quotes-banner.component';

@NgModule({
  imports: [
    SharedModule,
    GooglePlaceModule,
    Ship4wdNgxManagerUIModule.forRoot(),
    GridsterModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AppLoaderComponent,
    LoginComponent,
    DashboardComponent,
    DashboardHeaderComponent,
    LayoutComponent,
    ToolbarComponent,
    ShipmentsComponent,
    OrderListComponent,
    OrderComponent,
    BookingComponent,
    BookingTabsComponent,
    BookingIconsComponent,
    BookingReferenceComponent,
    BookingContactsComponent,
    BookingContainersComponent,
    QuotesListComponent,
    QuotesRowComponent,
    QuoteRateDetailRowComponent,
    BookingContainersComponent,
    BookingSummaryComponent,
    BookingDocumentsComponent,
    ViewBookingComponent,
    ViewBookingDocumentComponent,
    BookingCommodityDialogComponent,
    BookingOrganizationContactsComponent,
    CreateBookingOrganizationContactComponent,
    OrganizationsComponent,
    OrganizationsManagerComponent,
    OrganizationsListComponent,
    OrganizationLineComponent,
    OrganizationCreateComponent,
    ManageOrganizationComponent,
    OrganizationDetailComponent,
    OrganizationMembersComponent,
    OrganizationsEmptyComponent,
    TeamMemberDialogComponent,
    InviteMemberDialogComponent,
    NoBookingDialogComponent,
    OnQuoteSearchDialogComponent,
    OnDashboardDialogComponent,
    BookingFilesDocumentsComponent,
    BookingFilesDocumentsListComponent,
    CommentsDialogComponent,
    BookingShippingInstructionsComponent,
    BookingInstructionsDetailComponent,
    NotAllowShippingInstructionsDialogComponent,
    PrintBookingComponent,
    OrderMenuComponent,
    OrderListCardViewComponent,
    OrderListGridViewComponent,
    OrderListGridViewFinanceComponent,
    FinanceComponent,
    RequestsAndPaymentsListComponent,
    TransactionPaymentDialogComponent,
    PaymentConfirmationDialogComponent,
    CreditLineComponent,
    ApplyCreditLineComponent,
    RequestedCreditLineComponent,
    EligibleCreditLineComponent,
    ClaimCreditLineComponent,
    RejectedCreditLineComponent,
    DashboardWidgetComponent,
    QuotesWidgetComponent,
    OrdersWidgetComponent,
    CreditsWidgetComponent,
    AdsWidgetComponent,
    ManageContactsComponent,
    ManageContactComponent,
    WidgetReviewComponent,
    QuoteSearchWidgetComponent,
    QuoteSearchFlowComponent,
    QuoteSearchFlowHeaderComponent,
    QuoteSearchFlowParamsComponent,
    QuoteSearchFlowShippingLocationComponent,
    QuoteSearchFlowCargoDetailsComponent,
    QuoteSearchFlowCargoDateComponent,
    DatepickerCustomHeaderComponent,
    QuotesResultsComponent,
    QuoteSearchFlowShippingLocationOptionComponent,
    QuoteSearchFlowLoaderComponent,
    RateDetailsDialogComponent,
    BookingFlowComponent,
    BookingFlowSidebarComponent,
    BookingFlowServicesComponent,
    BookingFlowCargoDetailsComponent,
    BookingFlowContactsComponent,
    BookingFlowSummaryComponent,
    BookingFlowSummarySidebarComponent,
    BookingFlowContactComponent,
    BookingFlowCreateContactComponent,
    BookingFlowSummaryDocumentComponent,
    ServiceCostEstimatorComponent,
    AdditionalServiceDialogComponent,
    AdditionalServiceCustomsComponent,
    AdditionalServicePreShipmentComponent,
    NoQuotesComponent,
    AdditionalServiceInsuranceComponent,
    AdditionalServiceInsuranceManuallyComponent,
    LiveAssistanceComponent,
    ChangeCargoConfirmDialogComponent,
    QuoteNewSearchFlowParamsComponent,
    NewFlowShippingLocationComponent,
    NewFlowShippingLocationFieldsComponent,
    QuoteSearchFlowOldComponent,
    QuoteSearchFlowNewComponent,
    QuoteRateDetailRowRedesignedComponent,
    FlowShipmentTypeComponent,
    QuoteRowPriceComponent,
    QuoteRowPriceRedesignedComponent,
    RateDetailsDialogDefaultComponent,
    SupportRequestDialogComponent,
    WelcomeTutorialDialogComponent,
    QuoteSearchFlowLayoutComponent,
    QuotePriceTiersComparisonComponent,
    QuoteRateDetailRowServiceComponent,
    SortOptionsComponent,
    PostalCodeUpdateDialogComponent,
    RecentQuotesWidgetComponent,
    QuotesBannerComponent
  ],
  providers: [
    QuotesService,
    ManageOrganizationService,
    TeamMemberService,
    InviteMemberService,
    QuotesIncotermService,
    BookingFilesDocumentsService,
    LayoutService,
    RoutesService,
    CreditLinesService,
    LocationTypeS4DIconPipe,
    BookingFlowService,
    DecimalPipe,
    WelcomeTutorialService
  ],
  entryComponents: [LoginComponent, DashboardComponent, LayoutComponent],
  exports: []
})
export class DesktopModule { }
