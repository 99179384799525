import { Pipe, PipeTransform } from '@angular/core';
import { VolumeUnit } from '../shared.model';

@Pipe({ name: 'volumeUnitName' })
export class VolumeUnitNamePipe implements PipeTransform {
  private volumeUnitNames = {};

  constructor() {
    this.volumeUnitNames[VolumeUnit.CBM] = 'CBM';
    this.volumeUnitNames[VolumeUnit.CFT] = 'CFT';
    this.volumeUnitNames[VolumeUnit.CUI] = 'CUI';
  }

  transform(value?: VolumeUnit): string {
    return this.volumeUnitNames[value] ?? '';
  }
}
