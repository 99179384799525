import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { EncodeUrlCodec } from './encode-url-codec';
import { HarmonizedSystemCode, HarmonizedSystemCodeQuery, Page } from '../shared.model';

@Injectable()
export class CommodityService {
  resetCommodityAutoComplete =new BehaviorSubject<boolean>(false);
  
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) {}

  getById(id: string): Observable<HarmonizedSystemCode> {
    return this.http.get<HarmonizedSystemCode>(
        `${this.internalUrlsService.getApiBaseUrl()}/harmonizedsystemcodes/${id}`);
 }

  getPage(query: HarmonizedSystemCodeQuery): Observable<Page<HarmonizedSystemCode>> {  
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<Page<HarmonizedSystemCode>>(
      `${this.internalUrlsService.getApiBaseUrl()}/harmonizedsystemcodes/`, { params });
  }
}
