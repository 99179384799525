import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, UrlSerializer } from "@angular/router";
import { Subscription } from 'rxjs';
import { DeviceDefService } from "./shared/services/device-defining/deviceDef.service";
import { AppSettingsService } from "./shared/services/app-settings/app-settings.service";
import { DESKTOP_ROUTES } from "./desktop/desktop-routing.module";
import { AmplitudeService } from "./shared/amplitude/amplitude.service";

// adding Google analytics
import { filter } from 'rxjs/operators';
import { UserInfoService } from './shared/services/user-info/user-info.service';
import { Meta } from "@angular/platform-browser";
import { ClarityService } from "./shared/services/clarity.service";
import { GoogleAnalyticsService } from './shared/google-analytics/google-analytics.service';
import { GooglePlaceService } from './shared/services/google-place.service';
import { HubspotService } from "./shared/services/hubspot.service";
import { SalesforceService } from "./shared/services/salesforce.service";
import { UserService } from "@ship4wd/ngx-authorization";
import { WhatsAppService } from "./shared/whatsapp-button/whatsapp.service";
import { AccountsService } from "./shared/accounts/accounts.service";
import { AppcuesService } from "./shared/appcues/appcues.service";
import { EnvironmentService } from "./shared/services/environment.service";
import { SmartlookService } from "./shared/services/smartlook.service";
import { HeapService } from "./shared/heap/heap.service";
declare var gtag

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title: string;
  settingsChangedSubscription: Subscription;
  isMobile: boolean;
  isLoading: boolean;
  displayWhatsAppButton: boolean = false;
  private mutationObserver: MutationObserver;

  constructor(
    private router: Router,
    public device: DeviceDefService,
    private meta: Meta,
    private appSettingsService: AppSettingsService,
    private userInfoService: UserInfoService,
    private urlSerializer: UrlSerializer,
    private clarityService: ClarityService,
    private smartlookService: SmartlookService,
    private salesforceService: SalesforceService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private googlePlaceService: GooglePlaceService,
    private hubspotService: HubspotService,
    private userService: UserService,
    private amplitudeService: AmplitudeService,
    private whatsAppService: WhatsAppService,
    private accountsService: AccountsService,
    private appcuesService: AppcuesService,
    private environmentService: EnvironmentService,
    private heapService: HeapService
  ) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      if (this.userService.getUser()?.isAuthenticated) {
        const appSettings = this.appSettingsService.getSettings();
        this.accountsService.getUserProfile().subscribe(result => {
          this.googleAnalyticsService.userProperties(appSettings, result);
          this.userInfoService.setUserId(result?.id);
        })
      }
    });
    navEndEvent$.subscribe((e: NavigationEnd) => {
      const urlWithoutAccessToken = this.removeAccessToken(e.urlAfterRedirects);
      gtag('config', this.environmentService.environment.googleAnalyticsID, {
        'page_path': urlWithoutAccessToken
      });
    });
    this.title = "Ship4wd";
  }

  ngOnInit(): void {
    debugger
    this.isMobile = this.device.isMobile();
    if (!this.device.isMobile()) {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({
        name: 'viewport', content: window.innerWidth < 992 ? 'width=992, initial-scale=' + Number((window.innerWidth / 992).toFixed(1)) :
          'width=device-width, height=device-height, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi'
      })
      this.router.resetConfig(DESKTOP_ROUTES);
    }

    this.isLoading = true;
    this.appSettingsService
      .initSettings()
      .subscribe(
        () => this.isLoading = false,
        () => this.isLoading = false
      );

    this.userInfoService
      .initSettings()
      .subscribe(
        () => this.isLoading = false,
        () => this.isLoading = false
      );

    if (this.environmentService.environment.isLoadClarityScript) {
      this.clarityService.loadScript();
    }

    this.googlePlaceService.loadScript(this.environmentService.environment.googlePlaceApiKey);

    if (this.environmentService.environment['isLoadGoogleAnalyticsScript']) {
      this.googleAnalyticsService.loadHeadScript();
      this.googleAnalyticsService.loadBodyScript();
    }

    if (this.environmentService.environment.isLoadHubspotScript) {
      this.hubspotService.loadBodyScript(this.environmentService.environment.hubspotScriptId);
    }

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          const button = document.querySelector('.startButton');
          if (button) {
            button.dispatchEvent(new Event('click'));
          }
        }
      });
    });

    this.mutationObserver.observe(document.body, { childList: true, subtree: true });

    this.amplitudeService.init(this.environmentService.environment.amplitude, this.userService.getUser()?.userId).then(
      () => {
        if (this.whatsAppService.isWhatsAppEnabled())
          this.displayWhatsAppButton = true;
        else
          this.salesforceService.addUserChat();
      }
    );

    if (this.environmentService.environment.appcues.isEnabled) {
      this.appcuesService.loadBodyScript(this.environmentService.environment.appcues.appId)

      if (this.userService.getUser().isAuthenticated) {
        this.appcuesService.identify()
      }
    }

    if (this.environmentService.environment.isSmartlookEnabled) {
      this.smartlookService.init();
      this.smartlookService.identify();
    }

    if (this.userService.getUser().isAuthenticated)
      this.heapService.init()
  }

  ngOnDestroy() {
    this.settingsChangedSubscription.unsubscribe();
    this.mutationObserver.disconnect();
  }

  public removeAccessToken(url: string) {
    const urlTree = this.urlSerializer.parse(url);
    if (urlTree.queryParams['access_token']) {
      urlTree.queryParams['access_token'] = null;
    }
    if (urlTree.fragment && urlTree.fragment.includes('access_token')) {
      urlTree.fragment = null;
    }
    return this.urlSerializer.serialize(urlTree);
  }
}
