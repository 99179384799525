<div class="quote-search-shipment-type-param">
  <div class="quote-search-shipment-type-param-wrapper" [ngClass]="{
    'is-active': isOpened,
    'is-shining': !shipmentTypeControl.value && !isOpened
    }" (click)="onOpenClick()">
    <ng-container *ngIf="currentShippingType">
      <div class="row flex-nowrap h-100 m-0">
        <div class="col-auto d-flex align-items-center p-0">
          <div class="flow-param-field-icon-wrapper">
            <i class="flow-param-field-icon s4d-icon" [ngClass]="'s4d-' + currentShippingType.icon"></i>
          </div>
        </div>
        <div class="col d-flex align-items-center pe-0">
          <div class="row w-100 m-0">
            <div class="col-12 px-0">
              <span class="flow-param-field-result">{{currentShippingType.name}}</span>
            </div>
            <div class="col-12 px-0">
              <span class="flow-param-field-description">Shipment Type</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!currentShippingType">
      <div class="row flex-nowrap h-100 m-0">
        <div class="col-auto d-flex align-items-center p-0">
          <div class="flow-param-field-icon-wrapper">
            <i class="flow-param-field-icon s4d-icon s4d-boxes-light"></i>
          </div>
        </div>
        <div class="col d-flex align-items-center pe-0">
          <div class="col-12 d-flex align-items-center p-0">
            <span class="flow-param-field-title grey">Shipment Type</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="isOpened" class="quote-search-shipment-type-wrapper" [ngClass]="{'is-active': isOpened}">
    <div class="row m-0">
      <div class="col-12 flow-param-field-option" *ngFor="let item of shippingTypes" [aria-label]="item.description"  (click)="onSelect(item.value)">
        <div class="row m-0 align-items-center">
          <div class="col-auto flow-param-field-icon-wrapper px-0">
            <i class="flow-param-field-icon s4d-icon" [ngClass]="'s4d-' + item.icon"></i>
          </div>
          <span class="col flow-param-field-title pe-0">{{ item.description }}</span>
        </div>
      </div>
    </div>
  </div>
</div>