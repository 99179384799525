<div *ngIf="isLoading" class="loading small-loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div class="payment-confirmation-container d-flex flex-column justify-content-between ng-star-inserted"
  *ngIf="!isLoading">
  <div class="payment-confirmation-top">
    <h1 class="payment-confirmation-title">Payment Confirmation</h1>
    <h2 class="payment-confirmation-subtitle">To complete your offline transaction, upload a payment verification
      document.</h2>
      <button mat-flat-button class="show-button" (click)="onShowBankDetails()">
        Show Bank Details
      </button>
    <mat-dialog-content class="mat-dialog-content mat-typography payment-confirmation-content">
      <div class="payment-confirmation-content-wrapper">
        <lib-drag-drop [mode]="FileUploaderMode.all" [count]="1" [files]="getBookingDocuments()"
          (filesChange)="onListingFilesChange($event)" [maxFileSize]="5242880" [layoutType]="LayoutType.client"
          [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)"
          [title]="'You can also click on this area to select the file'">
        </lib-drag-drop>
      </div>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions align="center" class="payment-confirmation-action-button">
    <button mat-raised-button mat-dialog-close class="payment-confirmation-cancel-button">Cancel</button>
    <button mat-stroked-button class="view-order-details-button" (click)="confirmPayment(paymentId)"
    [disabled]="!isFileUploaded">
      Confirm
    </button>
  </mat-dialog-actions>
</div>