<div class="files-documents-list-container" *ngIf="bookingId && bookingDocuments">
  <div class="files-documents-list-wrapper">
    <div class="files-documents-list-item w-100 p-3 pb-0" *ngFor="let item of bookingDocuments">
      <div class="row">
        <div class="col-auto d-flex align-items-center pe-0">
          <span class="file-icon"></span>
        </div>
        <div class="col d-flex align-items-center file-title-name-wrapper">
          <span class="file-title" matTooltip="{{ item.name }}">
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-auto d-flex align-items-center pe-0">
          <mat-icon class="uploaded-icon">cloud_upload</mat-icon>
        </div>
        <div class="col d-flex align-items-center">
          <span class="file-uploaded" matTooltip="Uploaded">
            {{ item.createTimeUtc | date: "dd MMM, yyyy" }}
          </span>
        </div>
      </div>
      <div class="row border-top mt-3 margin-full-width">
        <div class="col-4 d-flex align-items-center justify-content-center border-end px-2 file-description-wrapper">
          <span class="file-description" matTooltip="Uploaded">
            {{ item.description }}
          </span>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <button mat-menu-item class="edit-comment-button h-100" (click)="onEditComment(item)"></button>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center border-start">
          <button mat-icon-button class="booking-contact-menu-button h-100" [matMenuTriggerFor]="menu">
            <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
          </button>
          <mat-menu #menu="matMenu" class="files-documents-menu">
            <button mat-menu-item *ngIf="canView && isSecuredUrl" (click)="onViewFile(item)">
              <span class="view-file-icon"></span>
              <span class="file-text">View/Download</span>
            </button>
            <button mat-menu-item [hidden]="true">
              <span class="sign-file-icon"></span>
              <span class="file-text">Sign File</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>