import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode } from '../shared.model';

@Pipe({ name: 'currencyTextIcon' })
export class CurrencyTextIconPipe implements PipeTransform {
  private currencyIcons = {};

  constructor() {
    this.currencyIcons[CurrencyCode.USD] = '$';
    this.currencyIcons[CurrencyCode.EUR] = '€';
  }

  transform(value?: CurrencyCode): string {
    return this.currencyIcons[value] ?? '';
  }
}
