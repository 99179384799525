<div id="print-ready" class="print-container" *ngIf="isPrintReady">
    <div class="print-header">
        <div class="print-header-background"></div>
        <div class="row">
            <div class="col print-header-logo">
                <img class="print-header-logo-image" src="../../../../assets/images/ship4wd-logo.png" alt="Ship4wd">
            </div>
            <div class="col-auto print-header-details">
                <p class="text-bold-12 print-header-details-title text-center">Order Details</p>
                <p class="text-normal-8 mb-0 text-center">{{ bookingSummary.bolOrderNumber }}</p>
            </div>
        </div>
    </div>
    <div class="print-content pb-0">
        <div class="d-flex">
            <div class="print-content-sidebar">
                <div class="order">
                    <div class="order-top">
                      <div>
                        <p class="direction-label">FROM</p>
                        <p class="direction-title">{{ bookingSummary.fromDisplayName }}</p>
                        <p class="direction-date">{{ checkDateNotNullOrEmpty(bookingSummary.etd) ? (bookingSummary.etd | date) : "Schedule Not Available" }}</p>
                      </div>
                      <div class="order-trip-status">
                        <div *ngIf="bookingSummary.isCanceled" class="canceled"></div>
                        <div class="trip-status-icon">
                            <p class="pre-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.preCarriage)"></p>
                            <p class="main-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.mainCarriage)"
                              [ngClass]="bookingSummary.shipmentTypeCode ? getMainCarriageIcon() : ''"></p>
                            <p class="post-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.postCarriage)"></p>
                        </div>
                        <div>
                          <div class="trip-status-details" [ngClass]="setDescriptionPosition(bookingSummary.statusType.code)">
                            <p class="trip-status">
                              {{ bookingSummary.statusType.ship4wdFrontendStatus }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="direction-label">TO</p>
                        <p class="direction-title">{{ bookingSummary.toDisplayName }}</p>
                        <p class="direction-date">{{ checkDateNotNullOrEmpty(bookingSummary.eta) ? (bookingSummary.eta | date) : "Schedule Not Available" }}</p>
                      </div>

                    </div>
                    <div class="order-bottom">
                      <p class="direction-date">Shipper: {{ getShipperName(bookingSummary) ? getShipperName(bookingSummary) : "Schedule Not Available" }}</p>
                      <p class="direction-date">Type: {{ bookingSummary.shipmentTypeCode | shipmentTypeName }}</p>
                      <p class="direction-date">Ref: {{ bookingSummary.customerReferenceId ?? "Schedule Not Available" }}</p>
                    </div>
                  </div>
            </div>
            <div class="print-content-customer-details">
                <div class="details-header mt-0 row">
                    <div class="col details-title">
                      <span>Customer Details</span>
                    </div>
                    <div class="col-auto details-date">
                        <span>{{ checkDateNotNullOrEmpty(bookingSummary.etd) ? (bookingSummary.etd | date: 'MMM dd, yyyy hh:mm aa' ) : "Schedule Not Available" }}</span>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="data-title col-12">
                        <span>SHIPPER</span>
                    </div>
                    <div class="data-info col-12">
                        <span>{{ bookingSummary?.shipperContact?.companyName }}</span>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="data-title col-12">
                        <span>CONSIGNEE</span>
                    </div>
                    <div class="data-info col-12">
                        <span>{{ bookingSummary?.consigneeContact?.companyName }}</span>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="data-title col-12">
                        <span>NOTIFY PARTY</span>
                    </div>
                    <div class="data-info col-12">
                        <span>{{ bookingSummary?.notifyPartyContact?.companyName }}</span>
                    </div>
                </div>

                <div class="details-header row">
                    <div class="col details-title">
                      <span>Basic Ocean Freight</span>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="col-6 px-0">
                        <div class="data-title col-12">
                            <span>VESSEL NAME</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.vesselCode }}</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="data-title col-12">
                            <span>CUT OFF</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.shipmentTypeCode === shipmentTypes.LCL ? 'n/a' : 'n/a' }}</span>
                        </div>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="col-6 px-0">
                        <div class="data-title col-12">
                            <span>CARRIER</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.quote?.trip?.carrierScac }}</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="data-title col-12">
                            <span>ESTIMATED DEPARTURE</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.quote?.trip?.departureDate | date: "MMM dd, yyyy"}}</span>
                        </div>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="col-6 px-0">
                        <div class="data-title col-12">
                            <span>ESTIMATED ARRIVAL</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.quote?.trip?.arrivalDate | date: "MMM dd, yyyy" }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="print-content-details">
            <div class="print-content-background"></div>
            <div class="print-content-details-header">
                <div class="details-header mt-0 row">
                    <div class="col details-title">
                      <span>Reference(s)</span>
                    </div>
                </div>
                <div class="details-data row">
                    <div class="col-6 px-0">
                        <div class="data-title col-12">
                            <span>REF</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.customerReferenceId }}</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="data-title col-12">
                            <span>ORDER</span>
                        </div>
                        <div class="data-info col-12">
                            <span>{{ bookingSummary.bolOrderNumber }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div #border1 class="print-content-details-data">
                <div class="print-content-details-border-top" [ngClass]="border1 | topBorderClass">
                    <div class="section-in-border row justify-content-center">
                        <div class="col-auto section-in-border-data">
                            <div class="row">
                                <div class="col-auto section-in-border-icon">
                                    <div class="info-icon"></div>
                                </div>
                                <div class="col section-in-border-text">
                                    <span>Particulars furnished by shipper</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="print-content-details-content">
                    <table class="print-details-table">
                        <tbody>
                            <tr>
                                <td class="width-174">
                                    <p class="table-title-dark">MKS & Nos. / Cont. NOS.</p>
                                </td>
                                <td class="width-174">
                                    <p class="table-title-dark">Description of goods</p>
                                </td>
                                <td class="width-107">
                                    <p class="table-title-dark">Weight</p>
                                </td>
                                <td class="width-107">
                                    <p class="table-title-dark">Measurement</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 22G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 1</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 45G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 2</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">2000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>

                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 22G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 1</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 45G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 2</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">2000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 22G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 1</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 45G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 2</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">2000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 22G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 1</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 45G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 2</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">2000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 22G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 1</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>
                            <tr class="height-141">
                                <td>
                                    <p class="table-title-light">Marks</p>
                                    <p class="table-data-black">Marks1, marks2, marks3</p>
                                    <p class="table-title-light mt-12">Container Number</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">Seal</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">1 x 45G0</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">Cargo 2</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">2000</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                                <td>
                                    <p class="table-title-light">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light mt-12">&nbsp;</p>
                                    <p class="table-data-black">&nbsp;</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p class="table-title-dark">MKS & Nos. / Cont. NOS.</p>
                                </td>
                                <td>
                                    <p class="table-title-dark">Description of goods</p>
                                </td>
                                <td>
                                    <p class="table-title-dark">Weight</p>
                                </td>
                                <td>
                                    <p class="table-title-dark">Measurement</p>
                                </td>
                            </tr>
                            <tr class="height-55">
                                <td colspan="2" class="align-bottom text-right">
                                    <p class="table-title-light">2 Containers. Total Tare</p>
                                </td>
                                <td class="align-bottom text-right">
                                    <p class="table-data-black">3000</p>
                                    <p class="table-title-light">Total</p>
                                </td>
                                <td class="align-bottom text-right">
                                    <p class="table-data-black">&nbsp;</p>
                                    <p class="table-title-light">Total</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div #border2 class="print-content-details-section">
                <div class="print-content-details-border-top" [ngClass]="border2 | topBorderClass">
                    <div class="section-in-border row justify-content-center">
                        <div class="col-auto section-in-border-data">
                            <div class="row">
                                <div class="col-auto section-in-border-icon">
                                    <div class="info-icon"></div>
                                </div>
                                <div class="col section-in-border-text">
                                    <span>Particulars furnished by shipper</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="print-content-details-section-content">
                        <table class="print-details-table">
                            <tbody>
                                <tr class="height-55 table-border-bottom-0">
                                    <td class="width-129">
                                        <p class="table-title-light">Charges Name</p>
                                        <p class="table-data-black">&nbsp;</p>
                                    </td>
                                    <td class="width-125">
                                        <p class="table-title-light">Description</p>
                                        <p class="table-data-black">&nbsp;</p>
                                    </td>
                                    <td class="width-88">
                                        <p class="table-title-light">Rate</p>
                                        <p class="table-data-black">&nbsp;</p>
                                    </td>
                                    <td class="width-88">
                                        <p class="table-title-light">Unit</p>
                                        <p class="table-data-black">&nbsp;</p>
                                    </td>
                                    <td class="width-129">
                                        <p class="table-title-light">Invoice Party</p>
                                        <p class="table-data-black">&nbsp;</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="print-details-table">
                            <tbody>
                                <tr class="height-55">
                                    <td class="width-188">
                                        <p class="table-title-dark">Total Prepaid</p>
                                        <p class="table-data-black"></p>
                                    </td>
                                    <td>
                                        <p class="table-title-dark">Total Collect</p>
                                        <p class="table-data-black"></p>
                                    </td>
                                    <td class="width-188">
                                        <p class="table-title-dark">Carrier’s Receipt</p>
                                        <p class="table-data-black">1 Container</p>
                                    </td>
                                </tr>
                                <tr class="height-55 table-border-bottom-0">
                                    <td>
                                        <p class="table-title-dark">Place and date of issue</p>
                                        <p class="table-data-black">on</p>
                                    </td>
                                    <td>
                                        <p class="table-title-dark">Shipped on Board Date (Local Time)</p>
                                        <p class="table-data-black"></p>
                                    </td>
                                    <td>
                                        <p class="table-title-dark">Declared Value Charges</p>
                                        <p class="table-data-black"></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="print-content-text">
            <p class="text-normal-10">Shipped, as far as ascertained by reasonable means of checking, in apparent good order and condition unless otherwise stated herein the total number or quantity of Containers or other packages or units indicated in the box opposite entitled “Carrier’s Receipt”</p>
        </div>

        <div class="print-content-sign">
            <div class="print-content-sign-text">
                <p class="text-normal-10">Signed for the Carrier</p>
            </div>
            <div class="print-content-sign-area">
                <p class="text-normal-10">As Agent(s)</p>
            </div>
        </div>

        <div class="print-content-remark">
            <p class="text-bold-12 mb-0">Remarks</p>
        </div>
    </div>
    <div class="print-footer">
        <div class="print-footer-background"></div>
    </div>
</div>
