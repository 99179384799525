<div *ngIf="organizationForm">
  <div class="organization-container" [formGroup]="organizationForm">
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="organization-logo">
          <img class="organization-logo-image" src="../../../../assets/images/s4d.jpg" alt="site-logo" width="80"
            height="80" />
        </div>
      </div>
    </div>
    <div class="manage-organization-field mt-3">
      <div class="row">
        <div class="col">
          <span class="field-title">Name</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
          <mat-form-field [floatLabel]="'never'" class="w-100">
            <input matInput type="text" placeholder="Name" formControlName="name" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field mt-3">
      <div class="row">
        <div class="col">
          <span class="field-title">Legal Name</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
          <mat-form-field [floatLabel]="'never'" class="w-100">
            <input matInput type="text" placeholder="Legal Name" formControlName="legalName" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Fax</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !organizationForm.get('fax').valid &&
                organizationForm.get('fax')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Fax" formControlName="fax" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Role</span>
        </div>
      </div>
      <div class="row">
        <div class="col check-paddings" *ngFor="let item of organizationRoleTypeCodes">
          <div class="organization-role-check-button" [ngClass]="{
              'organization-role-check-icon': item.isChecked === true
            }">
            <mat-checkbox class="organization-role-checkbox" [value]="item.value" [checked]="item.isChecked"
              (change)="onChangeRole($event.checked, item.value)">
              <span class="organization-role-check"></span>
              {{ item.name | titlecase }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Website</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Website" formControlName="website" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Industry</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Industry" formControlName="industry" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Trade Needs</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'">
              <mat-select placeholder="Trade Needs" formControlName="tradeNeeds">
                <mat-option *ngFor="let tradeNeedsTypeCode of tradeNeedsTypeCodes" [value]="tradeNeedsTypeCode.value">
                  {{ tradeNeedsTypeCode.name | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <app-tax-id-input class="w-100" [taxIdControl]="organizationForm.get('taxId')"
          [countryCode]="organizationForm.get('countryCode').value" floatLevel="never"
          [disabled]="isTaxIdDisabled"></app-tax-id-input>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Street</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !organizationForm.get('street').valid &&
                organizationForm.get('street')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Type your Street address or P.O. Box" formControlName="street"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Address 2</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Apt, suite, unit, building, floor, etc."
                formControlName="address2" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">City</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !organizationForm.get('city').valid &&
                organizationForm.get('city')?.touched
            }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="City" formControlName="city" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Zip/Postcode</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Zip/Postcode" formControlName="postalCode" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">Country</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper" [ngClass]="{
              'invalid-error-box':
                !organizationForm.get('countryCode').valid &&
                organizationForm.get('countryCode')?.touched
            }">
            <app-country-autocomplete [countryControl]="organizationForm.get('countryCode')"
              [countryAutocompleteValue]="countryAutocompleteValue">
            </app-country-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-organization-field">
      <div class="row">
        <div class="col">
          <span class="field-title">State</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="manage-organization-field-wrapper" [ngClass]="{ 'invalid-error-box': !organizationForm.get('state').valid
          && organizationForm.get('state')?.touched }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="State" formControlName="state"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>