import { LocationType, ShipmentType } from '../shared.model';

export class PriceTrendModelQuery {
  fromUnLocode: string;
  toUnLocode: string;
  fromLocationType: LocationType;
  toLocationType: LocationType;
  shipmentType: ShipmentType;
}

export class PriceTrend {
  priceTrendId: string;
  fromUnLocode: string;
  toUnLocode: string;
  fromLocationType: LocationType;
  toLocationType: LocationType;
  shipmentType: ShipmentType;
  percentages: number;
  percentageStatus: PercentageStatusCode;
  averagePriceTrendWeeks: number;
  priceTrendWeeks: PriceTrendWeek[];
}

export enum PercentageStatusCode {
  notSet = 0,
  up = 1,
  down = 2
}

export class PriceTrendWeek {
  weeklyAveragePrice: number;
  weekStartDate: string;
  weekEndDate: string;
}