import { environment } from './../../../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DecimalPipe } from '@angular/common';
import {
  CurrencyCode,
  HarmonizedSystemCode,
  SelectItem,
  ShipmentType,
  VolumeUnit,
  WeightUnit
} from '../../../../shared/shared.model';
import {
  Booking,
  Commodity,
  Contact,
  ContactType
} from '../../../../shared/bookings/bookings.model';
import { LinearUnitNamePipe } from '../../../../shared/features/linear-units/linear-unit-name.pipe';
import { BookingCommodityDialogComponent }
  from '../booking-commodity-dialog/booking-commodity-dialog.component';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { VolumeUnitNamePipe } from '../../../../shared/pipes/volume-unit-name.pipe';

@Component({
  selector: 'app-booking-containers',
  templateUrl: './booking-containers.component.html',
  styleUrls: ['./booking-containers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingContainersComponent implements OnInit {
  weightUnits: SelectItem[] = [
    { name: WeightUnit[WeightUnit.KG], value: WeightUnit.KG },
    { name: WeightUnit[WeightUnit.LB], value: WeightUnit.LB }
  ];

  currencyCodes: SelectItem[] = [
    { name: 'USD, $', shortName: 'USD', value: CurrencyCode.USD },
    { name: 'EUR, €', shortName: 'EUR', value: CurrencyCode.EUR }
  ];

  volumeUnits: SelectItem[] = [
    { name: VolumeUnit[VolumeUnit.CBM], value: VolumeUnit.CBM },
    { name: VolumeUnit[VolumeUnit.CFT], value: VolumeUnit.CFT },
    { name: VolumeUnit[VolumeUnit.CUI], value: VolumeUnit.CUI }
  ];

  bookingForm: FormGroup;
  shipmentTypes = ShipmentType;
  contactTypes = ContactType;
  isLoading = false;
  minSelectableDate = new Date();
  maxSelectableDate = new Date();
  isWeightHasError = false;
  isShowCollectContact = false;
  isShowDropContact = false;

  isViewOrganizationContacts = false;
  @Output() backEvent = new EventEmitter<boolean>();
  selectedContactType: ContactType;
  @Output() selectedContact = new EventEmitter();

  @Input() shipmentTypeCode: ShipmentType;
  @Output() addCommodity = new EventEmitter<FormGroup>();
  @Output() removeCommodity = new EventEmitter<any>();
  @Output() changeCollectDropContact = new EventEmitter<any>();
  @Input() booking: Booking;
  containerId: string | null = null;

  constructor(
    private controlContainer: ControlContainer,
    public dialog: MatDialog,
    private linearUnitNamePipe: LinearUnitNamePipe,
    private utilityService: UtilityService,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe) {
  }

  ngOnInit() {
    this.initializeContainer();
  }

  getFormControlValue(container: FormGroup, key: string) {
    return container.get(key).value;
  }

  setFormControlValue(form: FormGroup, key: string, value: any) {
    form.get(key).patchValue(value);
  }

  setContactControlValue(container: FormGroup, key: string) {
    return this.renderContactData(container.get(key).value);
  }

  onAddCommodity(container: FormGroup) {
    this.addCommodity.emit(container);
  }

  onDeleteCommodity(container: FormGroup, index: number) {
    this.removeCommodity.emit({ container, index });
  }

  getLoadsText(): string {
    if (this.shipmentTypeCode == ShipmentType.AIR) {
      return 'AIR';
    } else if (this.shipmentTypeCode == ShipmentType.LCL) {
      return 'LCL';
    }

    return '';
  }

  copyDetails(index: number, targetContainer: FormGroup) {
    const targetCommodities = targetContainer.get('commodities') as FormArray;
    if (targetCommodities && targetCommodities.controls.length > 0) {
      targetCommodities.clear();
    }

    const containers = this.bookingForm.get('containers') as FormArray;
    const sourceContainer = containers.controls[index - 1] as FormGroup;

    const cargoProperties = ['cargoName', 'cargoGrossWeight', 'cargoGrossWeightUnitCode', 'cargoExpectedReady', 'collectionDate', 'collectionReference'];

    cargoProperties.forEach((key) => {
      targetContainer.get(key).patchValue(sourceContainer.get(key).value);
    });

    if (sourceContainer.get('commodities').value.length > 0) {
      const sourceCommodities = sourceContainer.get('commodities') as FormArray;
      sourceCommodities.controls.forEach((commodity, index) => {
        this.onAddCommodity(targetContainer);
        const commodities = targetContainer.get('commodities') as FormArray;
        Object.keys(commodity.value).forEach((key) => {
          commodities.controls[index].get(key).patchValue(key === 'id' ? null : commodity.value[key]);
        });
      });
    }
  }

  checkCommoditiesWeight(container: any) {
    const containerWeight = container.value.cargoGrossWeight ?? 0;
    const containerWeightUnit = container.get('cargoGrossWeightUnitCode').value;
    const commodities = container.get('commodities').value;

    let total: number = 0;

    commodities.forEach(commodity => {
      const commodityWeightUnit = commodity.weightUnitCode;
      const commodityWeight = commodity.weight ?? 0;
      const kgToLb = 2.20462;
      const lbToKg = 0.453592;

      total += (containerWeightUnit === commodityWeightUnit) ?
        commodityWeight :
        (containerWeightUnit === 1 ? commodityWeight * lbToKg : commodityWeight * kgToLb);
    });

    const result = total > containerWeight;

    if (result) {
      container.get('cargoGrossWeight').setErrors({ 'incorrect': true });
      container.get('cargoGrossWeight').markAsTouched();

      container.get('commodities').controls.forEach(commodity => {
        commodity.get('weight').setErrors({ 'incorrect': true });
        commodity.get('weight').markAsTouched();
      });
    }
    else {
      container.get('cargoGrossWeight').setErrors(null);
      container.get('cargoGrossWeight').markAsTouched();

      container.get('commodities').controls.forEach(commodity => {
        if (commodity.get('weight').value >= 0) {
          commodity.get('weight').setErrors(null);
          commodity.get('weight').markAsTouched();
        }
      });
    }
  }

  onOpenComodityDialog(
    formName: FormGroup,
    commodityTypeCodeControlName: string,
    commodityDescriptionControlName: string) {
    const dialogRef = this.dialog.open(BookingCommodityDialogComponent, {
      data: {
        commodityForm: formName,
        commodityTypeCodeControlName: commodityTypeCodeControlName
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.value) {
        const commodity = result.value as HarmonizedSystemCode;
        this.setFormControlValue(result.form, commodityTypeCodeControlName, commodity.id);
        this.setFormControlValue(result.form, commodityDescriptionControlName, commodity.description);
      }
    });
  }

  viewOrganizationContacts(contactType: ContactType, containerForm: FormGroup) {
    this.isViewOrganizationContacts = true;
    this.selectedContactType = contactType;
    this.containerId = containerForm.get("id").value;
    this.backEvent.emit(true);
  }

  renderContactData(contact?: Contact) {
    let data = '';
    if (!contact) {
      return data;
    }

    data = this.appendContactData(data, contact.companyName);
    data = this.appendContactData(data, contact.address1);
    data = this.appendContactData(data, contact.address2);
    data = this.appendContactData(data, contact.city);
    data = this.appendContactData(data, contact.zipCode);
    data = this.appendContactData(data, contact.state);
    data = this.appendContactData(data, contact.countryCode);
    return data;
  }

  onBack() {
    this.isViewOrganizationContacts = false;
    this.backEvent.emit(false);
  }

  onSelectedContact(result: any) {
    this.onBack();
    this.containerId = null;
    const contactType = result.contactType;
    const containerId = result.containerId;

    const bookingContact = result.bookingContact;
    this.changeCollectDropContact.emit({
      bookingContact,
      contactType,
      containerId,
    });
  }

  getDimensions(commodityForm: FormGroup) {
    const id = commodityForm.get('id').value;
    let commodity: Commodity = null;
    let dimension = '';

    this.booking.containers.forEach((x) => {
      const filteredCommodity = x.commodities.find((y) => y.id === id);
      if (filteredCommodity !== undefined) {
        commodity = filteredCommodity;
        return;
      }
    });

    if (commodity !== null && commodity.dimension) {
      if (commodity.dimension?.width != null
        && commodity.dimension?.height != null
        && commodity.dimension?.length != null) {
        dimension += `(W) ${commodity.dimension?.width}
          ${this.linearUnitNamePipe.transform(
          commodity.dimension?.widthUnitCode
        )} x `;
        dimension += `(H) ${commodity.dimension?.height}
          ${this.linearUnitNamePipe.transform(
          commodity.dimension?.heightUnitCode
        )} x `;
        dimension += `(L) ${commodity.dimension?.length}
          ${this.linearUnitNamePipe.transform(
          commodity.dimension?.lengthUnitCode
        )}`;
      }
      else {
        dimension += `${this.decimalPipe.transform(commodity.volume, '1.2-2')}
          ${this.volumeUnitNamePipe.transform(commodity.volumeUnitCode)}`
      }
    }
    return dimension;
  }

  getContainersTitle(container: FormGroup) {
    if (this.shipmentTypeCode === ShipmentType.LCL) {
      return 'When would you like the vessel to sail?';
    } else if (this.shipmentTypeCode === ShipmentType.FCL) {
      return this.getFormControlValue(container, 'equipmentTypeDescription');
    } else if (this.shipmentTypeCode === ShipmentType.AIR) {
      return 'When would you like the air cargo to ship?';
    }

    return '';
  }

  private appendContactData(data: string, value: string) {
    if (data !== '') {
      data += ',';
    }
    data += value;
    return data;
  }

  onRemoveBookingContact(contactType: ContactType, containerForm: any) {
    this.containerId = null;
    const id = containerForm.get("id").value;
    this.changeCollectDropContact.emit({
      bookingContact: null,
      contactType: contactType,
      containerId: id
    });
  }

  getMinimumCollectionTime(containerForm: FormGroup) {
    let cargoExpectedReady = containerForm.get('cargoExpectedReady')?.value;
    return cargoExpectedReady ?? this.minSelectableDate;
  }

  private getEnviromentMaxAllowedShippingDate(): number {
    switch (this.shipmentTypeCode) {
      case ShipmentType.AIR:
        return environment.air.maxAllowedShippingDate;
      case ShipmentType.FCL:
        return environment.fcl.maxAllowedShippingDate;
      case ShipmentType.LCL:
        return environment.lcl.maxAllowedShippingDate;
      default:
        return 0;
    }
  }

  private initializeContainer() {
    this.containerId = null;
    this.bookingForm = (this.controlContainer.control as FormGroup);
    if (this.booking.serviceType > 0) {
      this.isShowCollectContact = false;
      this.isShowDropContact = false;
      const doorPickup = this.utilityService.isDoorPickupService(this.booking.serviceType);
      const doordelivery = this.utilityService.isDoorDeliveryService(this.booking.serviceType);
      if (doorPickup)
        this.isShowCollectContact = true;
      if (doordelivery)
        this.isShowDropContact = true;
    }

    if (this.utilityService.isNotNullOrMinDateValue(this.booking.etd)) {
      this.maxSelectableDate = new Date(this.booking.etd)
      this.maxSelectableDate.setDate(this.maxSelectableDate.getDate() - this.getEnviromentMaxAllowedShippingDate());
    } else {
      this.minSelectableDate = new Date();
      this.maxSelectableDate = null;
    }
  }
}
