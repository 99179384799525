import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';
import { NotificationService } from '@ship4wd/ngx-common';
import { environment } from '../../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { Booking, BookingFile, BookingSummary, Commodity } from '../../../../shared/bookings/bookings.model';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { LinearUnitNamePipe } from '../../../../shared/features/linear-units/linear-unit-name.pipe';
import { PackageType } from '../../../../shared/package-types/package-types.model';
import { VolumeUnitNamePipe } from '../../../../shared/pipes/volume-unit-name.pipe';
import {
  BookingFlowPanelState,
  BookingFlowPanels,
  DocumentTypeCode,
  ErrorMessages,
  HarmonizedSystemCode,
  HarmonizedSystemCodeQuery,
  HarmonizedSystemCodeQueryParameters,
  Page,
  SelectItem,
  ShipmentType,
  VolumeUnit,
  WeightUnit
} from '../../../../shared/shared.model';
import { BookingFlowService } from '../booking-flow.service';
import { PackageTypeInputComponent } from '../../../../shared/package-type-input/package-type-input.component';
import { ChangeCargoConfirmDialogComponent } from './change-cargo-confirm-dialog/change-cargo-confirm-dialog.component';
import { CommodityService } from '../../../../shared/services/commodity.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { FileInfo, FileUploaderMode, LayoutType } from '@ship4wd/ngx-manager-ui';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { BookingDocumentsService } from 'src/app/shared/bookings/booking-documents/booking-documents.service';
import { FilesDocumentsService } from 'src/app/shared/files-documents/files-documents.service';

@Component({
  selector: 'app-booking-flow-cargo-details',
  templateUrl: './booking-flow-cargo-details.component.html',
  styleUrls: ['./booking-flow-cargo-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'copy-container-overlay' }
    }
  ]
})
export class BookingFlowCargoDetailsComponent implements OnInit, AfterViewInit {
  @ViewChildren(PackageTypeInputComponent) packageTypeInputComponents: QueryList<PackageTypeInputComponent>;

  @Input() containers: FormArray;
  @Input() booking: Booking;
  @Input() shipmentTypeCode: ShipmentType;
  @Input() bookingSummary: BookingSummary;
  @Output() addCommodity = new EventEmitter<FormGroup>();
  @Output() removeCommodity = new EventEmitter<any>();
  @Output() next: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() setState: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() copyContainerDetails = new EventEmitter<any>();
  @Output() isBookingAdditionalServiceUpdated: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  isNextLoading: boolean = false;
  isToIsrael: boolean = false;

  shipmentTypes = ShipmentType;
  minSelectableDate = new Date();
  maxSelectableDate = new Date();
  collectDateControl: FormControl = new FormControl('', Validators.required);
  filteredCommodities: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];
  commodityResponse: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];
  dialogRef: MatDialogRef<ChangeCargoConfirmDialogComponent>;
  FileUploaderMode = FileUploaderMode;
  LayoutType = LayoutType;
  documents: FileInfo[] = [];
  isFileUploaded = false;

  weightUnits: SelectItem[] = [
    { name: WeightUnit[WeightUnit.KG], value: WeightUnit.KG },
    { name: WeightUnit[WeightUnit.LB], value: WeightUnit.LB }
  ];

  volumeUnits: SelectItem[] = [
    { name: VolumeUnit[VolumeUnit.CBM], value: VolumeUnit.CBM },
    { name: VolumeUnit[VolumeUnit.CFT], value: VolumeUnit.CFT },
    { name: VolumeUnit[VolumeUnit.CUI], value: VolumeUnit.CUI }
  ];

  get isValid(): boolean {
    let commoditiesValid = true;

    this.containers.controls.forEach((container: FormGroup) => {
      const commodities = container.controls['commodities'] as FormArray;
      commodities.controls.forEach((commodity: FormGroup) => {
        if (!commodity.valid) commoditiesValid = false;
      });
    });

    return (
      commoditiesValid &&
      this.collectDateControl.valid
    );
  }

  containersWeightAndVolumeValidityMap = new Map<FormControl, boolean>();
  containersCubicAndWeight = new Map<string, any>();

  isLclWeightAndVolumeValid = true;

  constructor(
    private bookingFlowService: BookingFlowService,
    private bookingsService: BookingsService,
    private commodityService: CommodityService,
    private linearUnitNamePipe: LinearUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private utilityService: UtilityService,
    private bookingDocumentsService: BookingDocumentsService,
    private filesDocumentsService: FilesDocumentsService
  ) {
    this.getCommodities();
  }

  ngOnInit(): void {
    if (this.containers) {
      this.collectDateControl.setValue(this.containers?.controls[0]?.value?.collectionDate);
    }

    this.bookingFlowService.panelState.subscribe(x => {
      if (x.panel === BookingFlowPanels.cargoDetails) this.isNextLoading = x.loading;
    });

    if (this.utilityService.isNotNullOrMinDateValue(this.booking.etd)) {
      this.maxSelectableDate = new Date(this.booking.etd);
      this.maxSelectableDate.setDate(this.maxSelectableDate.getDate() - this.getEnviromentMaxAllowedShippingDate());
    } else {
      this.maxSelectableDate = null;
    }

    if (this.maxSelectableDate != null && new Date(this.collectDateControl.value) > this.maxSelectableDate) {
      this.collectDateControl.setValue(this.maxSelectableDate);
    }

    this.containers.controls.forEach((containerControl: FormControl) => {
      this.containersWeightAndVolumeValidityMap.set(containerControl, true);

      if (containerControl.value.equipmentTypeCode != null) {
        this.bookingsService.getEquipment(containerControl.value.equipmentTypeCode).subscribe(response => {
          this.containersCubicAndWeight.set(response.equipment.isoCode, {
            maxCubic: response.equipment.cubicCapacity,
            maxWeight: response.equipment.maxWeight,
            maxWeightUnit: response.equipment.maxWeightUnit
          });
        });
      }
    });

    if (this.booking.to.substring(0, 2) == 'IL') {
      this.isToIsrael = true;
    }

    if (this.shipmentTypeCode == ShipmentType.AIR) {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      this.minSelectableDate = currentDate;
    }

    this.documents = this.booking.documents.filter(x => x.documentTypeCode === DocumentTypeCode.MSDS).map((x) => {
      return {
        id: x.id,
        name: x.name,
        url: x.url,
        progress: null,
        file: null,
      } as FileInfo;
    }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onSetState();
    }, 500);
  }

  onDateChanged(value: Moment): void {
    this.onSetState(true);
    const date = value?.utc().format();
    this.containers.controls.forEach(container => {
      container.get('collectionDate').setValue(date);
    });
    this.collectDateControl.setValue(date);
  }

  onDatePickerPopUpStateChanged(state: boolean): void {
    const overlay = document.getElementsByClassName('cdk-overlay-container')[0];

    if (state) {
      overlay.setAttribute('style', 'z-index:999999 !important;');
    }
    else {
      overlay.attributes.removeNamedItem('style');
    }
  }

  onUnitSelect(event: any): void {
    event.stopPropagation();
    this.onSetState(true);
  }

  onSelectCommodity(event: MatSelectChange, commodityForm: FormGroup, index: number): void {
    commodityForm.get('commodityObject').setValue(event?.value);
    commodityForm.get('commodityTypeCode').setValue(event?.value?.id);
    commodityForm.get('commodityDescription').setValue(event?.value?.description);

    this.onValueChanged('Commodity Code', index, true);
  }

  onSelectTextCommodity(index: number): void {
    this.onValueChanged('Commodity Code', index, true);
  }

  onSelectPackageType(packageType: PackageType | null, commodityForm: FormGroup, index: number): void {
    commodityForm.get('packageTypeCode').setValue(packageType ? packageType.id : null);

    this.containers.controls.forEach(containerControl => {
      const commodities = (containerControl as FormGroup).get('commodities') as FormArray;

      if (commodities) {
        commodities.controls.forEach(commodityControl => {
          const packageTypeCodeControl = (commodityControl as FormGroup).get('packageTypeCode');

          if (packageTypeCodeControl && !packageTypeCodeControl.value) {
            packageTypeCodeControl.setValue(packageType ? packageType.id : null);
          }
        });
      }
    });

    this.onValueChanged('Type of Packaging', index, true);
  }

  onAddCommodity(container: FormGroup) {
    this.onSetState(true);
    this.addCommodity.emit(container);
  }

  onDeleteCommodity(container: FormGroup, index: number) {
    this.onSetState(true);
    this.removeCommodity.emit({ container, index });
  }

  onSetState(changed: boolean = false): void {
    this.runWeightAndVolumeValidity(changed);

    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.cargoDetails;
    state.success = changed ? false : this.isValid;
    state.valid = this.isValid;
    state.loading = false;

    this.setState.emit(state);
  }

  onNext(): void {
    if (this.isCargoChanged()) {
      this.openCargoConfirmDialog();
    } else {
      this.updateCargoDetails();
    }
  }

  getContainersTitle(container: FormGroup): any {
    if (this.shipmentTypeCode === ShipmentType.FCL) {
      return this.getFormControlValue(container, 'equipmentTypeDescription');
    }

    return '';
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  getFormControlValue(container: FormGroup, key: string): any {
    return container.get(key).value;
  }

  getDimensions(commodityForm: FormGroup): string {
    const id = commodityForm.get('id').value;
    let commodity: Commodity = null;
    let dimension = '';
    this.booking.containers.forEach(x => {
      const filteredCommodity = x.commodities.find(y => y.id === id);
      if (filteredCommodity !== undefined) {
        commodity = filteredCommodity;
        return;
      }
    });

    if (commodity !== null && commodity.dimension) {
      if (commodity.dimension?.width != null
        && commodity.dimension?.height !== null
        && commodity.dimension?.length !== null) {
        dimension += `${commodity.dimension?.width} x `;

        dimension += `${commodity.dimension?.height} x `;

        dimension += `${commodity.dimension?.length}
      ${this.linearUnitNamePipe.transform(commodity.dimension?.lengthUnitCode)} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity.volume, '1.2-4')}

      ${this.volumeUnitNamePipe.transform(commodity.volumeUnitCode)}`;
    }

    return dimension;
  }

  getMinimumCollectionTime(): any {
    let cargoExpectedReady = this.containers[0]?.containerForm.get('cargoExpectedReady')?.value;
    return cargoExpectedReady ?? this.minSelectableDate;
  }

  markAllInputsAsTouched(): void {
    this.collectDateControl.markAsTouched();
    this.markAllChildInputsAsTouched();
  }

  onCopyContainerDetails(sIndex: number, dIndex: number, targetContainer: FormGroup): void {
    this.copyContainerDetails.emit({ sIndex: sIndex, dIndex: dIndex, targetContainer: targetContainer });
  }

  checkValid(control: FormControl): boolean {
    return !control?.valid && control?.touched;
  }

  getValidatorErrorKey(control: FormControl): ErrorMessages {
    if (control.errors) {
      for (const errorKey in control.errors) {
        return ErrorMessages[errorKey];
      }
    }

    return null;
  }

  onValueChanged(fieldName: string, index: number, setState: boolean): void {
    if (setState) {
      this.onSetState(setState);
    }

    this.googleAnalyticsService.cargoDetailValueChanged(fieldName, index);
  }

  onFocus(fieldName: string, index: number, formControlName: string): void {
    if (!this.isFieldAlreadyTouched(formControlName)) this.googleAnalyticsService.cargoDetailsTouched(fieldName, index);
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.shipmentTypeCode,
      actionName: "Cargo details",
      fieldName,
      errorValue
    })
  }

  getSecureFileUrl(fileId: string) {
    this.bookingDocumentsService
      .getSignedUrl(this.booking.id, fileId)
      .subscribe(
        (x) => {
          window.open(x.url, "_blank");
        },
        (error) => {
          this.notificationService.error(error);
        }
      );
  }

  onDocumentsChange(files: FileInfo[]) {
    const filesToAdd = files.filter((x) => !x.id);
    const filesToRemove = this.documents.filter(
      (x) => !files.some((y) => y === x)
    );
    this.documents = files;
    this.deleteFiles(filesToRemove);
    this.addFiles(filesToAdd);
  }

  private isFieldAlreadyTouched(formControlName: string): boolean {
    return this.containers.controls.some((container: FormGroup) => {
      const commodities = container.controls['commodities'] as FormArray;
      return commodities.controls.some((commodity: FormGroup) => commodity.get(formControlName)?.touched);
    });
  }

  private getEnviromentMaxAllowedShippingDate(): number {
    switch (this.shipmentTypeCode) {
      case ShipmentType.AIR:
        return environment.air.maxAllowedShippingDate;
      case ShipmentType.FCL:
        return environment.fcl.maxAllowedShippingDate;
      case ShipmentType.LCL:
        return environment.lcl.maxAllowedShippingDate;
      default:
        return 0;
    }
  }

  private checkWeightAndVolumeValidity(): Observable<boolean> {
    if (this.shipmentTypeCode == ShipmentType.FCL) {
      let condition = true;
      let count = 0;

      let observables: Observable<any>[] = [];
      this.containers.controls.forEach((container: FormControl) => {
        observables.push(
          this.bookingsService.getEquipment(container.value.equipmentTypeCode).pipe(
            map(response => {
              return { equipment: response.equipment, containerControl: container };
            })
          )
        );
      });

      return forkJoin(
        observables.map(obs =>
          obs.pipe(
            map((equipmentContainer: any) => {
              if (equipmentContainer.equipment) {
                count++;
                const maxWeight = equipmentContainer.equipment.payloadCapacityWeight;
                const cubicCapacity = equipmentContainer.equipment.cubicCapacity;

                let selectedMaxWeight = 0;
                let selectedCubicCapacity = 0;
                equipmentContainer.containerControl.value.commodities.forEach(commodity => {
                  selectedMaxWeight = selectedMaxWeight + Number(commodity.weight);
                  selectedCubicCapacity = selectedCubicCapacity + Number(commodity.volume);
                });
                if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
                  this.googleAnalyticsService.selectedTotalWeightAndVolumeError();
                  this.sendFunnelBookingError("cargo details", "Selected total weight and volume can not be larger than selected equipment sizes.")
                  condition = false;
                  this.containersWeightAndVolumeValidityMap.set(equipmentContainer.containerControl, false);
                } else {
                  this.containersWeightAndVolumeValidityMap.set(equipmentContainer.containerControl, true);
                }

                equipmentContainer.containerControl.controls.cargoGrossWeight.setValue(selectedMaxWeight);

                if (count == this.containers.controls.length && condition) {
                  return true;
                } else if (count == this.containers.controls.length && !condition) {
                  const state = new BookingFlowPanelState();
                  state.panel = BookingFlowPanels.cargoDetails;
                  state.success = false;
                  state.valid = false;
                  state.loading = false;
                  state.error = true;
                  this.setState.emit(state);
                  return false;
                }
              }
            })
          )
        )
      ).pipe(
        map(processedValues => {
          if (processedValues.some(value => value === false)) {
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      let maxWeight = environment.lclMaxWeight;
      let cubicCapacity = environment.lclMaxCapacity;
      var condition = true;

      if (this.shipmentTypeCode == ShipmentType.LCL) {
        this.containers.controls.forEach((container: FormControl) => {
          container.value.commodities.forEach(commodity => {
            maxWeight =
              commodity.weightUnitCode == WeightUnit.KG ? environment.lclMaxWeight : environment.lclMaxWeight * 2.20462;
            cubicCapacity =
              commodity.volumeUnitCode == VolumeUnit.CBM
                ? environment.lclMaxCapacity
                : environment.lclMaxCapacity * 61023.7441;

            const selectedMaxWeight = commodity.weight;
            const selectedCubicCapacity = commodity.volume;

            if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
              this.googleAnalyticsService.selectedTotalWeightAndVolumeError();
              this.sendFunnelBookingError("cargo details", "Selected total weight and volume can not be larger than selected equipment sizes.")
              condition = false;
              this.isLclWeightAndVolumeValid = false;
            }
          });
        });
      }

      if (condition) {
        return of(true);
      } else {
        const state = new BookingFlowPanelState();
        state.panel = BookingFlowPanels.cargoDetails;
        state.success = false;
        state.valid = false;
        state.loading = false;
        state.error = true;
        this.setState.emit(state);
        return of(false);
      }
    }
  }

  private markAllChildInputsAsTouched(): void {
    this.packageTypeInputComponents.forEach(childComponent => {
      childComponent.markAllInputsAsTouched();
    });
  }

  private runWeightAndVolumeValidity(changed: boolean): void {
    if (changed && Array.from(this.containersWeightAndVolumeValidityMap.values()).some(value => value == false)) {
      this.checkWeightAndVolumeValidity().subscribe();
    }
  }

  private openCargoConfirmDialog(): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      this.dialogRef = this.dialog.open(ChangeCargoConfirmDialogComponent, {
        autoFocus: false,
        scrollStrategy: this.sso.noop(),
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0',
        disableClose: true
      });

      this.dialogRef.afterClosed().subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result && result.isAllowSaveBookingCargoDetails) {
          this.updateCargoDetails();
          this.deleteAllBookingAdditionalServices();
        }
      });
    }
  }

  private isCargoChanged(): boolean {
    let isChanged = false;
    if (this.bookingSummary.bookingAdditionalServiceCharges.length > 0) {
      var initialValue = this.containers.getRawValue();
      if (initialValue[0].collectionDate !== this.booking.containers[0].collectionDate) {
        isChanged = true;
        return isChanged;
      }

      if (initialValue[0].commodities.length !== this.bookingSummary.containers[0].commodities.length) {
        isChanged = true;
        return isChanged;
      }

      for (let index in initialValue[0].commodities) {
        let isCommodityChanged = true;
        var formCommodity = initialValue[0].commodities[index];
        var bookingCommodity = this.bookingSummary.containers[0].commodities[index];
        if (
          bookingCommodity.commodityTypeCode === formCommodity.commodityTypeCode &&
          bookingCommodity.volume === formCommodity.volume &&
          bookingCommodity.weight === formCommodity.weight
        ) {
          isCommodityChanged = false;
        }

        if (isCommodityChanged) {
          isChanged = true;
          break;
        }
      }
    }

    return isChanged;
  }

  private deleteAllBookingAdditionalServices(): void {
    this.bookingFlowService
      .deleteAllAdditionalServices(this.booking.id)
      .subscribe(
        (response: any) => {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: true
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      )
      .add(() => {
        this.dialogRef.close();
      });
  }

  private updateCargoDetails(): void {
    this.checkWeightAndVolumeValidity().subscribe(res => {
      if (res === true) {
        const state = new BookingFlowPanelState();
        state.panel = BookingFlowPanels.cargoDetails;
        state.error = false;
        state.success = false;
        state.loading = true;
        state.valid = this.isValid;

        this.next.emit(state);
      }
    });
  }

  private getCommodities(): void {
    this.isLoading = true;
    let query = new HarmonizedSystemCodeQuery();
    query.pageNo = 1;
    query.pageSize = 500;
    query.any = true;
    query.sortBy = HarmonizedSystemCodeQueryParameters.description;

    this.commodityService
      .getPage(query)
      .subscribe(
        (x: Page<HarmonizedSystemCode>) => {
          this.commodityResponse = x.items;

          this.filteredCommodities = this.commodityResponse;
          const otherCommodities = this.filteredCommodities.filter(item => item.description === 'Other Commodities');
          const restOfItems = this.filteredCommodities.filter(item => item.description !== 'Other Commodities');

          this.filteredCommodities = [...restOfItems, ...otherCommodities];
          return this.filteredCommodities;
        },
        error => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private sendFunnelBookingError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.shipmentTypeCode,
      actionName: "Cargo details",
      fieldName,
      errorValue
    })
  }

  private deleteFiles(files: FileInfo[]) {
    this.isFileUploaded = false;
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.booking.id, fileToRemove.id)
        .subscribe((data) => {
          const index = this.booking.documents.findIndex(
            (x) => x.id === fileToRemove.id
          );
          if (index > -1) {
            this.booking.documents.splice(index, 1);
          }
        });
    }
  }

  private addFiles(files: FileInfo[]) {
    for (const fileToAdd of files) {
      this.filesDocumentsService
        .add(this.booking.id, fileToAdd, null, DocumentTypeCode.MSDS)
        .subscribe(
          (x) => {
            this.finalizeFileLoading(fileToAdd, x);
            this.isFileUploaded = true;
          },
          (error) => {
            this.notificationService.error(error);
          }
        );
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any) {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round((100 * response.loaded) / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.booking.documents.push(addedFile);
    }
  }
}
