import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppSettings } from '../../features/app-settings/app-settings.model';
import { HttpService } from '../http/http.service';

@Injectable()
export class AppSettingsService {
  private readonly storageKey = 'appsettings';
  private readonly defaultAppSettingsExpirationDays = 7;

  settingsChanged = new EventEmitter<AppSettings>();

  constructor(private httpService: HttpService) {
  }

  initSettings(): Observable<any> {
    var oldSettings = this.getSettingsFromStorage();
    if (oldSettings) {
      this.settingsChanged.emit(oldSettings);
      return of(oldSettings);
    }

    return this.setDefaultSettings();
  }

  getSettings(): AppSettings {
    const oldSettings = this.getSettingsFromStorage();
    return oldSettings || AppSettings.createDefault();
  }

  addSettings(settings: AppSettings) {
    if (settings) {
      localStorage.setItem(this.storageKey, JSON.stringify(settings));
    }
  }

  removeSettings() {
    const oldSettings = this.getSettingsFromStorage();
    if (oldSettings) {
      localStorage.removeItem(this.storageKey);
    }
  }

  clearOrganization(){
    const settings = this.getSettingsFromStorage();
    settings.organizationId = null;
    
    if (settings) {
      localStorage.setItem(this.storageKey, JSON.stringify(settings));
    }
  }

  private refreshSettings(endpoint): Observable<any> {
    return this.httpService
      .get<AppSettings>(endpoint, null, null, { host: window.location.host })
      .pipe(
        catchError(() => of(AppSettings.createDefault())),
        map(x => AppSettings.complete(x as AppSettings)),
        tap(x => {
          this.saveSettingsToStorage(x);
          this.settingsChanged.emit(x);
        })
      );
  }

  private getSettingsFromStorage(): AppSettings {
    const oldSettingsString = localStorage.getItem(this.storageKey);
    if (!oldSettingsString) {
      return null;
    }

    const oldSettings = JSON.parse(oldSettingsString) as AppSettings;
    if (!oldSettings) {
      return null;
    }

    return oldSettings;
  }

  private saveSettingsToStorage(settings: AppSettings) {
    if (!settings) {
      return;
    }

    settings.receivedAt = new Date();
    localStorage.setItem(this.storageKey, JSON.stringify(settings));
  }

  private isExpired(settings: AppSettings): boolean {
    const appSettingsExpirationDays = environment.appSettingsExpirationDays || this.defaultAppSettingsExpirationDays;
    const expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() - appSettingsExpirationDays * 24 * 60 * 60 * 1000);
    return settings.receivedAt < expirationTime;
  }

  private setDefaultSettings(): Observable<AppSettings> {
    let appSettings = AppSettings.createDefault();
    appSettings = AppSettings.complete(appSettings);
    this.saveSettingsToStorage(appSettings);
    this.settingsChanged.emit(appSettings);
    return of(appSettings);
  }
}
