import { Inject, Component } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationData } from './application-notification.model';

@Component({
  selector: 'app-application-notification',
  templateUrl: './application-notification.component.html'
})
export class ApplicationNotificationComponent {
  
  constructor(
    public snackBarRef: MatSnackBarRef<ApplicationNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: NotificationData) {
  }
}
