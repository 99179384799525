import { Variant } from '@amplitude/experiment-js-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { QuoteSearch } from '../../../../desktop/quotes/quotes.model';
import { QuotesService } from '../../../../desktop/quotes/quotes.service';
import { RecentQuoteSearchesByUserIdQuery } from '../../../../shared/activities/activities.model';
import { ActivitiesService } from '../../../../shared/activities/activities.service';
import { amplitudeFlags, amplitudeFlagVariants } from '../../../../shared/amplitude/amplitude.constants';
import { AmplitudeService } from '../../../../shared/amplitude/amplitude.service';
import { VolumeUnitNamePipe } from '../../../../shared/pipes/volume-unit-name.pipe';
import { WeightUnitNamePipe } from '../../../../shared/pipes/weight-unit-name.pipe';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { ShipmentType, UnitMeasurementType, VolumeUnit, WeightUnit } from '../../../../shared/shared.model';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-recent-quotes-widget',
  templateUrl: './recent-quotes-widget.component.html',
  styleUrls: ['./recent-quotes-widget.component.scss']
})
export class RecentQuotesWidgetComponent implements OnInit {
  @Input() widget;
  @Input() editable: boolean;
  @Input() preview: boolean = false;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  @Output("isEnabled") isEnabledEmit: EventEmitter<[string, boolean]> = new EventEmitter<[string, boolean]>();

  isLoading: boolean = true;
  isError: boolean = false;
  isEmpty: boolean = false;
  isButtonLoading: boolean = false;

  quoteSearches: QuoteSearch[] = [];
  errorMessage: string;
  loaderElementsNumber: number = 5;
  quoteSearchesElementsNumber: number = 5;

  amplitudeVariant: Variant;

  get getCurrentCondition(): string {
    if (this.isLoading || this.preview) return 'preview'

    if (this.isEmpty) return 'empty'

    if (this.isError) return 'error'

    return 'view'
  }

  get isEnabled(): boolean {
    if (this.amplitudeVariant?.key !== amplitudeFlagVariants[amplitudeFlags.recentQuotesWidget].enabled) return false;

    if (!this.amplitudeVariant?.payload) return true;

    return this.amplitudeVariant?.payload.some(x => this.router.url.includes(x))
  }

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private activitiesService: ActivitiesService,
    private userSettings: UserInfoService,
    private settingsService: AppSettingsService,
    private weightUnitNamePipe: WeightUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private quotesService: QuotesService,
    private amplitudeService: AmplitudeService,
    private numberPipe: DecimalPipe,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.googleAnalyticsService.recentSearchShown();
    this.amplitudeVariant = this.amplitudeService.getFlag(amplitudeFlags.recentQuotesWidget);
    this.isEnabledEmit.emit([this.widget?.id, this.isEnabled])
    if (!this.preview && this.isEnabled) {
      this.getData();
    }
  }

  getData(): void {
    const query = this.prepareQueryModel();

    this.isLoading = true;

    this.activitiesService.getUniqueRecentQuoteSearchesByUserId(query)
      .subscribe(
        (x: QuoteSearch[]) => {
          this.quoteSearches = x;
          if (this.quoteSearches.length === 0) {
            this.isEmpty = true;
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error;
        }
      )
      .add(() => this.isLoading = false);
  }

  range(value): number[] {
    return Array.from({ length: value }, (v, k) => k + 1);
  }

  onEmptyAction(): void {
    this.isButtonLoading = true;
    this.router.navigate(["/quote-search"]);
  }

  onReload(): void {
    this.getData();
  }

  getMainCarriageIcon(quoteSearch: QuoteSearch): string {
    switch (quoteSearch.shipmentType) {
      case ShipmentType.AIR:
        return "s4d-ac";

      case ShipmentType.FCL:
        return "s4d-fcl";

      case ShipmentType.LCL:
        return "s4d-lcl";

      default:
        return "s4d-boxes";
    }
  }

  getCargoDetails(quoteSearch: QuoteSearch, separator: string = ", "): string {
    let cargoDetailsArray: string[] = []
    const numberOfPackages = quoteSearch.commodities?.reduce((accumulator, x) => accumulator + x.numberOfPackages, 0);
    const weightAmount = quoteSearch.commodities?.reduce((accumulator, x) => accumulator + x.weightAmount, 0);
    const volumeAmount = quoteSearch.commodities?.reduce((accumulator, x) => accumulator + x.volumeAmount, 0);
    const chargeableWeightBasedOnVolumeAmount = this.calculateTotalChargeableWeight(volumeAmount, quoteSearch.commodities[0]?.volumeUnit, quoteSearch.shipmentType);

    const chargeableWeightAmount = weightAmount > chargeableWeightBasedOnVolumeAmount ?
      weightAmount :
      chargeableWeightBasedOnVolumeAmount;

    const chargeableVolumeAmount = this.calculateTotalChargeableVolume(chargeableWeightAmount, quoteSearch.commodities[0]?.weightUnit, quoteSearch.shipmentType);

    switch (quoteSearch.shipmentType) {
      case ShipmentType.FCL:
        cargoDetailsArray = quoteSearch.equipments?.map(({ quantity, equipmentTypeDescription }) => `${quantity} x ${equipmentTypeDescription}`);
        break;
      case ShipmentType.LCL:
        cargoDetailsArray = [
          `${numberOfPackages} ${numberOfPackages > 1 ? 'Units' : 'Unit'}`,
          `Total: ${this.numberPipe.transform(
            chargeableWeightAmount,
            "1.2-2"
          )
          } ${this.weightUnitNamePipe.transform(quoteSearch.commodities[0].weightUnit).toLocaleLowerCase()} (${this.numberPipe.transform(
            chargeableVolumeAmount,
            "1.2-2"
          )
          } ${this.volumeUnitNamePipe.transform(quoteSearch.commodities[0].volumeUnit).toLocaleLowerCase()})`
        ];
        break;
      case ShipmentType.AIR:
        cargoDetailsArray = [
          `${numberOfPackages} ${numberOfPackages > 1 ? 'Units' : 'Unit'}`,
          `Total: ${this.numberPipe.transform(
            chargeableWeightAmount,
            "1.2-2"
          )
          } ${this.weightUnitNamePipe.transform(quoteSearch.commodities[0].weightUnit).toLocaleLowerCase()}`
        ];
        break;
    }

    return cargoDetailsArray.join(separator)
  }

  useRecentQuoteSearch(quoteSearch: QuoteSearch): void {
    this.googleAnalyticsService.recentSearchClick();
    this.quotesService.searchQuotesByQuoteSearch(quoteSearch);
    if (!this.router.url.includes("/quote-search"))
      this.router.navigate(["/quote-search"])
  }

  onRemove(): void {
    this.remove.next(this.widget.id);
  }

  private prepareQueryModel(): RecentQuoteSearchesByUserIdQuery {
    const query = new RecentQuoteSearchesByUserIdQuery();

    query.pageSize = this.quoteSearchesElementsNumber;
    query.pageNo = 1;
    query.userId = this.userSettings.getSettings().userId;
    query.organizationId = this.settingsService.getSettings().organizationId;

    return query;
  }

  private calculateTotalChargeableWeight(totalVolume: number, unitMeasurementType: VolumeUnit, shipmentType: ShipmentType): number {
    if (unitMeasurementType === VolumeUnit.CBM) {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private calculateTotalChargeableVolume(totalWeight: number, unitMeasurementType: WeightUnit, shipmentType: ShipmentType): number {
    if (unitMeasurementType === WeightUnit.KG) {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight / 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight / 1000 * 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight * 1728 / 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight * 166;
      }
    }
  }
}
