import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CreditLineStatusTransition, FourtySeasPaymentResponse, PaymentStatusTransition } from '../shared.model';
import { CheckoutService } from '../checkouts/checkout.service';
import { CreditLinesService } from '../creditLines/creditLines.service';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { Booking } from '../bookings/bookings.model';
import { FourtySeasInitDialogService } from '../../shared/payments/fourty-seas-init-dialog.service';

declare const _40Seas: any;

@Injectable()
export class FourtySeasPaymentService {

  public checkoutEnd = new EventEmitter<boolean>();
  paymentLoaderBehaviorSubject = new BehaviorSubject<boolean | undefined>(undefined);
  creditLineLoaderBehaviorSubject = new BehaviorSubject<boolean | undefined>(undefined);
  paymentCancelBehaviorSubject = new BehaviorSubject<boolean | undefined>(undefined);
  theme = {
    '.Picker': { borderColor: '#6e3df7' },
    '.Picker--selected': { color: '#6e3df7', borderColor: '#6e3df7', boxShadow: 'none' },
    '.Button': {
      color: '#fff', backgroundColor: '#6E3DF7', border: '0px',
      borderradius: '12px'
    },
    '.Button:icon': {
      color: 'white'
    },
    '.Button:hovered': { backgroundColor: '#8a8ae3' },
    '.Button:disabled': { color: 'white', backgroundColor: '#c1c1e6' },
    '.MainText': { color: '#787E8E', fontsize: '16px', lineheight: '24px', margin: '0' },
  };

  constructor(private router: Router,
    private checkoutService: CheckoutService,
    private creditLinesService: CreditLinesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private fourtySeasInitDialogService: FourtySeasInitDialogService) { }

  init(paymentRequestId: string,
    teamMemberId: string,
    booking: Booking,
    transition: PaymentStatusTransition,
    returnUrl: string = null): Observable<any> {
    const theme = this.theme;
    this.hideOrShowPaymentLoader(true);
    const options = { skipSuccessUponConfirmation: true };
    this.isFourtySeasModelReady();

    return from(this.checkoutService.initCheckoutSession(paymentRequestId, teamMemberId, booking.bolOrderNumber, transition)).pipe(
      switchMap((checkout: { token: any; }) => {
        this.checkoutEnd.next(true);
        return new Observable(observer => {
          _40Seas.open(checkout.token, {
            onConfirm: (data) => {
              let redirectingUrl = "paymentStatus?status=";
              const paymentMethod = data.result.paymentOption.paymentMethod;

              switch (paymentMethod) {
                case 'wire':
                  redirectingUrl += "false";
                  break;
                case 'card':
                case 'ach':
                  redirectingUrl += "true";
                  break;
                default:
                  break;
              }

              if (data.result.paymentOption.paymentTerm.type == 'net' && !redirectingUrl.endsWith("true")) {
                redirectingUrl += "true";
              }

              observer.next(
                window.location.href = '/' + redirectingUrl + '&id=' + data.externalId
              );
              observer.next(_40Seas.close());
              observer.next(this.googleAnalyticsService.addPaymentInfo(data as FourtySeasPaymentResponse, booking));
              observer.next(this.googleAnalyticsService.beginCheckout(data as FourtySeasPaymentResponse, booking));
              observer.complete();
            },
            onCancel: () => {
              if (returnUrl != null) {
                observer.next(
                  this.router.navigate([returnUrl]));
                observer.complete();
              }
              this.hideOrShowPaymentLoader(false);
              this.isPaymentCanceled(true);
            }
          }, options, theme);
        });
      })
    );
  }

  initCreditLine(organizationId: string, returnUrl: string = null, transition: CreditLineStatusTransition = null) {
    const theme = this.theme;

    this.hideOrShowPaymentLoader(true);
    this.hideOrShowCreditLineLoader(true);
    const options = { skipSuccessUponConfirmation: true };

    return from(this.creditLinesService.initCreditLineSession(organizationId)).pipe(
      switchMap((checkout: { token: any; }) => {
        this.checkoutEnd.next(true);
        return new Observable(observer => {
          _40Seas.open(checkout.token, {
            onConfirm: () => {
              this.fourtySeasInitDialogService.closeFourtySeasInitDialog();
              observer.next(
                this.setRequestedCreditLineStatus(organizationId, transition != null ? transition : CreditLineStatusTransition.request)
              );
              observer.next(_40Seas.close());
              observer.complete();
            },
            onCancel: () => {
              this.fourtySeasInitDialogService.closeFourtySeasInitDialog();
              if (returnUrl != null) {
                observer.next(
                  this.router.navigate([returnUrl]));
                observer.complete();
              }
              this.hideOrShowPaymentLoader(false);
              this.hideOrShowCreditLineLoader(false);
            }
          }, options, theme);
        });
      }),
      catchError((error) => {
        this.hideOrShowPaymentLoader(false);
        this.hideOrShowCreditLineLoader(false);
        return throwError(error);
      })
    );
  }

  hideOrShowPaymentLoader(value: boolean): void {
    this.paymentLoaderBehaviorSubject.next(value);
  }

  hideOrShowCreditLineLoader(value: boolean): void {
    this.creditLineLoaderBehaviorSubject.next(value);
  }

  isPaymentCanceled(value: boolean): void {
    this.paymentCancelBehaviorSubject.next(value);
  }

  private setRequestedCreditLineStatus(organizationId: string, transition: CreditLineStatusTransition) {
    this.creditLinesService.updateCreditLine(organizationId, transition).subscribe(() => { })
      .add(() => { window.location.href = '/creditLine'; });
  }

  private isFourtySeasModelReady(): void {
    const handleMessage = (event: MessageEvent) => {
      const data = event?.data ? JSON.parse(event.data) : null;
      if (data && data.type === "ready") {
        window.removeEventListener("message", handleMessage);
        this.fourtySeasInitDialogService.closeFourtySeasInitDialog();
      }
    };
    window.addEventListener("message", handleMessage, { once: true });
  }
}
