import { Component, ViewEncapsulation, OnInit, Inject, HostListener } from "@angular/core";
import { DatePipe, DecimalPipe } from "@angular/common";
import { ContainerCommodity, ExtenderTrip, ExtenderTripRate, Quote, QuotesQuery } from "../../../../../../../desktop/quotes/quotes.model";
import { ROLLUP_DIALOG_DATA } from "../../../../../../../mobile/rollup-dialog/rollup-dialog.model";
import { RollupDialogService } from "../../../../../../../mobile/rollup-dialog/rollup-dialog.service";
import { ShipmentType, CarriageStatusType, LocationType, ExtenderTripLegBase } from "../../../../../../../shared/shared.model";
import { RateDetailPanels } from "../../../../flow-results/flow-result-details/new-redesigned/flow-result-details.model";
import { VolumeUnitNamePipe } from "../../../../../../../shared/pipes/volume-unit-name.pipe";
import { FlowResultDetailsDialogComponent } from "../../../../flow-results/flow-result-details/new-redesigned/flow-result-details-dialog/flow-result-details-dialog.component";
import { RateDialogData } from "../../../../../../../desktop/quotes/quotes-list/quote-rate-detail-row-variants/default/rate-details-dialog/rate-details.model";
import { DimensionUnitNamePipe } from "../../../../../../../shared/pipes/dimension-unit-name.pipe";
import { UtilityService } from "../../../../../../../shared/helper/utility.service";

@Component({
  selector: 'app-rate-details-dialog',
  templateUrl: './rate-details-dialog.component.html',
  styleUrls: ['./rate-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateDetailsDialogDefaultComponent implements OnInit {
  quote: Quote;
  quotesQuery: QuotesQuery;
  shipmentType: ShipmentType;
  CarriageStatusType = CarriageStatusType;
  ShipmentType = ShipmentType;
  LocationType = LocationType;
  firstLegLocationType: LocationType;
  carriageTripLegs = [];
  lastLegCarriage = null;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  quoteTrip: ExtenderTrip;
  isDeliveryToUS: boolean;
  isExpanded: boolean[] = [false, false, false, false, false];
  rateDetailsPanels = RateDetailPanels;
  rateDetails = {
    [RateDetailPanels.FREIGHT]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Freight charges include the carrier's base rate in addition to
      other charges and fees, as seen in the quote breakdown. Freight
      charges are calculated based on your search criteria, such as
      cargo details, selected origin and destination, among others.`
    },
    [RateDetailPanels.DESTINATION]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Destination charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others.`
    },
    [RateDetailPanels.ORIGIN]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Origin charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others.`
    },
    [RateDetailPanels.OTHER]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Additional charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others.`
    },
    [RateDetailPanels.DANGEROUS]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Dangerous charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others.`
    },
  }

  isShowFullNames = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isShowFullNames = window.innerWidth >= 336;
  }

  get priceValidHours(): number {
    return this.shipmentType == ShipmentType.AIR ? 48 : 72;
  }

  get accumulatePrice(): number {
    return this.quote?.accumulatePrice;
  }

  get isDoorService(): boolean {
    return this.quote.trip?.fromLocationTypeCode == LocationType.townCity || this.quote.trip?.toLocationTypeCode == LocationType.townCity
  }

  constructor(public dialogRef: RollupDialogService,
    public datePipe: DatePipe,
    private dimensionUnitNamePipe: DimensionUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
    private rollupDialogService: RollupDialogService,
    private utilityService: UtilityService,
    @Inject(ROLLUP_DIALOG_DATA) public data: RateDialogData) { }

  ngOnInit(): void {
    this.quotesQuery = this.data.quotesQuery;
    this.quote = this.data.quote;
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.data.quote.trip);
    this.firstLegLocationType = this.data.quote.trip.fromLocationTypeCode;
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;
    this.shipmentType = this.data.shipmentType;
    this.quoteTrip = this.data.quote.trip;
    this.isDeliveryToUS = this.quoteTrip.toUnLoCode.startsWith('US');

    this.getRateDetails();
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  openInfoDialog(): void {
    const data = {
      rateDetails: this.rateDetails
    }

    const dialogRef = this.rollupDialogService.open(FlowResultDetailsDialogComponent, data, ['always-dark-background', 'rate-details-dialog-overlay'])
  }

  getTotalFreightRate(rates: ExtenderTripRate[]): number {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  getRatesByApplicableType(applicableType: string, rates: ExtenderTripRate[]): number {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.surcharges.reduce((accumulator, charge) => {
        if (charge.applicableType == applicableType) {
          return accumulator + charge.shipmentRate.amount
        }
        return accumulator
      }, 0)
    }, 0)
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage,
        carriage: trip.preCarriageTripLeg,
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage,
        carriage: trip.mainCarriageTripLeg,
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage,
        carriage: trip.postCarriageTripLeg,
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element.shipmentRate.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element.shipmentRate.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element?.shipmentRate?.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    return carriageTripLegs;
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.shipmentType == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  onClickPanel(state: boolean, panel: RateDetailPanels): void {
    this.isExpanded[panel] = state;
  }

  getFreightCharges(): number {
    return this.quote.trip?.mainCarriageTripLeg.legs.reduce(
      (freightCharges, leg) =>
        freightCharges + leg.rates?.reduce(
          (legPrice, rate) =>
          (
            legPrice +
            rate.freightRate?.shipmentRate?.totalAmount +
            rate.surcharges.reduce((surchargePrice, surcharge) => surchargePrice + surcharge?.shipmentRate?.totalAmount, 0)
          ), 0
        ), 0);
  }

  getDimensions(commodity: ContainerCommodity): string {
    let dimension = '';

    if (commodity !== null) {
      if (commodity.width != null
        && commodity.height !== null
        && commodity.length !== null) {
        dimension += `${commodity?.width} x `;

        dimension += `${commodity?.height} x `;

        dimension += `${commodity?.length}

      ${this.dimensionUnitNamePipe.transform(
          commodity?.dimensionUnit
        )} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity.totalVolumeAmount, '1.2-2')}

      ${this.volumeUnitNamePipe.transform(
        commodity.volumeUnit
      )}`;
    }

    return dimension;
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }

  private getRateDetails(): void {
    let isCargoDetailsAdded = false;

    this.carriageTripLegs.forEach(({ carriageType, carriage }: { carriageType: CarriageStatusType, carriage: ExtenderTripLegBase }) => {
      carriage.legs.forEach(leg => {
        let rateDetailPanel = RateDetailPanels.FREIGHT;
        switch (carriageType) {
          case CarriageStatusType.preCarriage:
            rateDetailPanel = RateDetailPanels.ORIGIN
            if (this.quote.trip.isPreCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.postCarriage:
            rateDetailPanel = RateDetailPanels.DESTINATION
            if (this.quote.trip.isPostCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.mainCarriage:
          default:
            rateDetailPanel = RateDetailPanels.FREIGHT
            break;
        }

        leg.rates.forEach(rate => {
          const freightRate = rate.freightRate as any;
          this.rateDetails[rateDetailPanel].totalAmount += freightRate?.shipmentRate.totalAmount || 0;

          if (rateDetailPanel === RateDetailPanels.FREIGHT && !isCargoDetailsAdded) {
            freightRate.cargoDetails = this.quotesQuery.shipmentType == ShipmentType.FCL ? this.quotesQuery?.equipments : this.quotesQuery?.containerCommodities;
            isCargoDetailsAdded = true;
          }
          this.rateDetails[rateDetailPanel].charges.push(freightRate);

          if (rate.surcharges?.length) {
            rate.surcharges.forEach(charge => {
              switch (charge.applicableType?.toUpperCase()) {
                case "FREIGHT":
                  this.rateDetails[RateDetailPanels.FREIGHT].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.FREIGHT].charges.push(charge);
                  break;
                case "ORIGIN":
                  this.rateDetails[RateDetailPanels.ORIGIN].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.ORIGIN].charges.push(charge);
                  break;
                case "DESTINATION":
                  this.rateDetails[RateDetailPanels.DESTINATION].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DESTINATION].charges.push(charge);
                  break;
                case "DANGEROUS":
                  this.rateDetails[RateDetailPanels.DANGEROUS].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DANGEROUS].charges.push(charge);
                  break;
                default:
                  this.rateDetails[RateDetailPanels.OTHER].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.OTHER].charges.push(charge);
                  break;
              }
            })
          }
        })
      })
    })
  }

  private getMissedFreightRate(carriageType: CarriageStatusType): any {
    switch (carriageType) {
      case CarriageStatusType.preCarriage:
        return {
          description: "Door pickup",
          isMissingRate: true
        }
      case CarriageStatusType.postCarriage:
        return {
          description: "Door delivery",
          isMissingRate: true
        }
    }
  }
}
