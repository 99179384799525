export class PackageType {
    id: PackageTypeCode;
    code: string;
    description: string;
}

export class PackageTypeQuery {
    code: string;
    description: string;
    any: boolean;
    sortBy: PackageTypeQueryParameters;
    sortDesc: boolean;
    pageNo: number;
    pageSize: number;
  }
  
  export enum PackageTypeQueryParameters {
    code = 1,
    description = 2
  }

export enum PackageTypeCode {
    BAG = 1,
    BAGS = 2,
    BALE = 3,
    BALES = 4,
    BARREL = 5,
    BARRELS = 6,
    BUNDLE = 7,
    BUNDLES = 8,
    BING = 9,
    BINGS = 10,
    BIN = 11,
    BINS = 12,
    BLKBAG = 13,
    BLKBAGS = 14,
    BULK = 15,
    BULKS = 16,
    BOBBIN = 17,
    BOBBINS = 18,
    BOTTLE = 19,
    BOTTLES = 20,
    BOX = 21,
    BOXES = 22,
    BASKET = 23,
    BASKETS = 24,
    BUCKET = 25,
    BUCKETS = 26,
    CAN = 27,
    CANS = 28,
    CASE = 29,
    CASES = 30,
    CBC = 31,
    CARBOY = 32,
    CARBOYS = 33,
    CANCASE = 34,
    CHEST = 35,
    CHESTS = 36,
    WOODCNT = 37,
    CNTR = 38,
    CNTRS = 39,
    COIL = 40,
    COILS = 41,
    CONE = 42,
    CONES = 43,
    CRADLE = 44,
    CRADLES = 45,
    CRATE = 46,
    CRATES = 47,
    CASK = 48,
    CASKS = 49,
    CARTON = 50,
    CARTONS = 51,
    CUBE = 52,
    CUBES = 53,
    CYL = 54,
    CYLS = 55,
    DRYBULK = 56,
    DRUM = 57,
    DRUMS = 58,
    DUFFLE = 59,
    DUFFLES = 60,
    FIRKIN = 61,
    FIRKINS = 62,
    LIQLINR = 63,
    FRAME = 64,
    FRAMES = 65,
    FLASK = 66,
    FLASKS = 67,
    GOH = 68,
    HEDBEEF = 69,
    HOGSHED = 70,
    HAMPER = 71,
    HAMPERS = 72,
    JAR = 73,
    JARS = 74,
    JUG = 75,
    JUGS = 76,
    KEG = 77,
    KEGS = 78,
    LIQBULK = 79,
    LOG = 80,
    LOGS = 81,
    LOOSE = 82,
    LUG = 83,
    LUGS = 84,
    LIFTVAN = 85,
    MIXED = 86,
    NOIL = 87,
    NOILS = 88,
    OVRWRAP = 89,
    PAIL = 90,
    PAILS = 91,
    PIECE = 92,
    PIECES = 93,
    PIM = 94,
    PIMS = 95,
    PKG = 96,
    PKGS = 97,
    PLTFORM = 98,
    PALLET = 99,
    PALLETS = 100,
    VEHICLE = 101,
    PIPRACK = 102,
    QTRBEEF = 103,
    RACK = 104,
    RACKS = 105,
    REEL = 106,
    REELS = 107,
    ROLL = 108,
    ROLLS = 109,
    RVR = 110,
    SACK = 111,
    SACKS = 112,
    DRYLINR = 113,
    SUITCAS = 114,
    SHEET = 115,
    SHEETS = 116,
    SIDBEEF = 117,
    SKID = 118,
    SKIDS = 119,
    SLPSHT = 120,
    SLPSHTS = 121,
    SLEEVE = 122,
    SLEEVES = 123,
    SPINCYL = 124,
    SPOOL = 125,
    SPOOLS = 126,
    TUBE = 127,
    TUBES = 128,
    TOTEBIN = 129,
    TANK = 130,
    TANKS = 131,
    TIERCE = 132,
    TIERCES = 133,
    TRIWBOX = 134,
    TRUNK = 135,
    TRUNKS = 136,
    TRAY = 137,
    TRAYS = 138,
    TOTECAN = 139,
    TUB = 140,
    TUBS = 141,
    UNPACKD = 142,
    UNIT = 143,
    UNITS = 144,
    VANPACK = 145,
    WHEELED = 146,
    WLC = 147,
    WRAPPED = 148
}