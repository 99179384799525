import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TeamMemberOrganization } from "../../../shared/accounts/accounts.model";

@Component({
  selector: "app-organizations-manager",
  templateUrl: "./organizations-manager.component.html",
  styleUrls: ["./organizations-manager.component.scss"],
})
export class OrganizationsManagerComponent {
  @Input() accepted: Array<TeamMemberOrganization> = [];
  @Input() pending: Array<any> = [];
  @Input() invited: Array<any> = [];
  @Input() isRegisterVisible: boolean;
  @Input() registerTitle: string = '';

  @Output() openRegister: EventEmitter<boolean> = new EventEmitter();

  onRegister(): void {
    this.openRegister.emit(true);
  }
}
