<div class="organizations-container" [ngClass]="{register: isRegister || isNoOrganizations, manage: !isRegister}">
  <app-toolbar [authorized]="false" [transparent]="isRegister || isNoOrganizations"></app-toolbar>

  <ng-container *ngIf="isLoading">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="(isNoOrganizations || isRegister) && !isLoading">
    <app-organization-create (cancel)="createAccount()" [userProfile]="userProfile" [isFromSso]="getIsFromSSO()"
      [isNoOrganizations]="isNoOrganizations"></app-organization-create>
  </ng-container>

  <ng-container *ngIf="!isNoOrganizations && !isRegister && !isLoading">
    <app-organizations-manager [accepted]="accepted" [pending]="pending" [invited]="invited"
      [isRegisterVisible]="isRegisterButtonTitleVisible" [registerTitle]="registerButtonTitle"
      (openRegister)="createAccount()"></app-organizations-manager>
  </ng-container>
</div>

<app-live-assistance *ngIf="!isLoading && chatExists"></app-live-assistance>
