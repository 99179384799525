import {
  GlobalTradeTypeCode,
  InviteStatusCode,
  KycStatusCode,
  TeamMemberStatusCode,
  TradeNeedsTypeCode,
  TeamAccessRole
} from '../../shared/shared.model';

export class Organization {
  id: string;
  companyCode: string;
  organizationCode: string;
  name: string;
  legalName: string;
  globalTradeTypeId: GlobalTradeTypeCode;
  tradeNeeds: TradeNeedsTypeCode;
  industry: string;
  taxId: string;
  address: string;
  address2: string;
  street: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  postalCode: string;
  fax: string;
  website: string;
  logoUrl: string;
  phone: string;
  isTermsConfirmation: boolean;
  isDeleted: boolean;
  creditOrganization: boolean;
  kycStatusCode: KycStatusCode;
  kYCStatusHistories: KYCStatusHistory[];
  dunsNumber: string;
  organizationDunsDetail: OrganizationDunsDetail;
  isInternalOrganization: boolean;
}

export class OrganizationDunsDetail {
  dnbInformation: string;
}

export class KYCStatusHistory {
  organizationId: string;
  kycStatusCode: KycStatusCode;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class OrganizationViewModel {
  companyCode: string;
  name: string;
  legalName: string;
  kycStatusCode: KycStatusCode;
  globalTradeTypeId: GlobalTradeTypeCode;
  tradeNeeds: TradeNeedsTypeCode;
  industry: string;
  taxId: string;
  address: string;
  address2: string;
  street: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
  fax: string;
  website: string;
  logoUrl: string;
  phone: string;
  isTermsConfirmation: boolean;
  isDeleted: boolean;
  creditOrganization: boolean;
  isInternalOrganization: boolean;
}

export class OrganizationMembers {
  teamMembers: TeamMember[];
  inviteMembers: TeamMembersInvite[];
}

export class TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  userId?: string;
  memberStatus: TeamMemberStatusCode;
  organizationId: string;
  teamAccessRoles: TeamAccessRole[];
  teamMemberSettings: TeamMemberSettings;
}

export class TeamMembersInvite {
  id: string;
  organizationId: string;
  organizationName: string;
  organizationLegalName: string;
  email: string;
  firstName: string;
  lastName: string;
  nickName: string;
  inviteStatusCode: InviteStatusCode;
  expirationDate?: string;
  roles: string;
  createdByUid?: string;
  createdByName: string;
  modifiedByUid?: string;
  modifiedByName: string;
  isDeleted: boolean;
}

export class TeamMemberViewModel {
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  userId?: string;
  memberStatus: TeamMemberStatusCode;
  organizationId: string;
  roles: string;
}

export class TeamMemberSettings {
  disableHelpPopup: boolean;
  isFirstLogin: boolean;
  updateTimeUtc: string;
}

export class OrganizationInvitationViewModel {
  organizationId: string;
  email: string;
  firstName: string;
  lastName: string;
  nickName: string;
  roles: string;
}
