import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '@ship4wd/ngx-common';
import { Organization, OrganizationViewModel } from '../../../../../desktop/manage-organization/manage-organization.model';
import { ManageOrganizationService } from '../../../../../desktop/manage-organization/manage-organization.service';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { AppSettingsService } from '../../../../../shared/services/app-settings/app-settings.service';

@Component({
  selector: 'app-postal-code-update-dialog',
  templateUrl: './postal-code-update-dialog.component.html',
  styleUrls: ['./postal-code-update-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostalCodeUpdateDialogComponent implements OnInit, AfterViewInit {
  isLoading = false;
  postalCode: FormControl = new FormControl('', Validators.required);
  organizationId: string;
  organization: Organization;
  @ViewChild('postalCodeInput') postalCodeInput!: ElementRef;

  constructor(private manageOrganizationService: ManageOrganizationService,
    private settingsService: AppSettingsService,
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private notificationService: NotificationService) {
    this.organizationId = this.settingsService.getSettings().organizationId;
  }

  ngOnInit(): void {
    this.organization = this.data;
  }

  ngAfterViewInit(): void {
    this.postalCodeInput.nativeElement.focus();
  }

  onClose(isUpdated?: boolean | undefined): void {
    this.dialogRef.close(isUpdated);
  }

  onSubmit(): void {
    this.organization.postalCode = this.postalCode.value;
    this.updateOrganization(this.organization);
  }

  private updateOrganization(organization: OrganizationViewModel) {
    this.isLoading = true;
    this.manageOrganizationService.updateOrganization(this.organizationId, organization)
      .subscribe(() => this.onClose(true),
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false)
  }
}
