import { Injectable } from '@angular/core';
import { shippingInstructionCheckProperties } from '../booking-shipping-instructions/booking-shipping-instructions.model';
import { Booking, BookingServiceType, BookingStatusCode } from '../bookings/bookings.model';
import { CompletedOrderStatusType, ShipmentType } from '../shared.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }

  toIsoDateTimeString(date) {
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  isNotNullOrMinDateValue(dateTime: string | null): boolean {
    return dateTime != null && !dateTime.includes("0001-01-01");
  }

  isDisabled(propertyName: string, currentBookingStatus: BookingStatusCode, shipmentType: ShipmentType | null = null) {
    let isDisabled = false;
    switch (propertyName) {
      case shippingInstructionCheckProperties.CUSTOMER_REFERENCE:
      case shippingInstructionCheckProperties.PO_NUMBER:
      case shippingInstructionCheckProperties.CARGO_NAME:
      case shippingInstructionCheckProperties.CARGO_GROSS_WEIGHT:
      case shippingInstructionCheckProperties.CARGO_GROSS_WEIGHT_UNIT_CODE:
      case shippingInstructionCheckProperties.CONTAINER_NUMBER:
      case shippingInstructionCheckProperties.SEAL_NUMER:
      case shippingInstructionCheckProperties.COLLECTION_REFERENCE:
      case shippingInstructionCheckProperties.COLLECTION_DATE:
      case shippingInstructionCheckProperties.ADD_COMMODITY_BUTTON:
      case shippingInstructionCheckProperties.PACKAGE_TYPE_CODE:
      case shippingInstructionCheckProperties.COMMODITY_TYPE_CODE:
      case shippingInstructionCheckProperties.VOLUME:
      case shippingInstructionCheckProperties.VOLUME_UNIT_CODE:
      case shippingInstructionCheckProperties.PRODUCT_VALUE:
      case shippingInstructionCheckProperties.PRODUCT_VALUE_CURRENCY_CODE:
      case shippingInstructionCheckProperties.NUMBER_OF_PACKAGES:
      case shippingInstructionCheckProperties.MARKS_AND_NUMBERS:
      case shippingInstructionCheckProperties.ADD_ANOTHER_REF_BUTTON:
        isDisabled = this.checkBeforeACOStatus(currentBookingStatus);
        break;
      case shippingInstructionCheckProperties.ORDER_NUMBER:
      case shippingInstructionCheckProperties.SHIPPER_CONTACT:
      case shippingInstructionCheckProperties.CONSIGNEE_CONTACT:
      case shippingInstructionCheckProperties.NOTIFY_PARTY_CONTACT:
      case shippingInstructionCheckProperties.COLLECT_CONTACT:
      case shippingInstructionCheckProperties.DROP_CONTACT:
        isDisabled = this.checkBeforeOrderSubmitStatus(currentBookingStatus);
        break;
      case shippingInstructionCheckProperties.WEIGHT:
      case shippingInstructionCheckProperties.WEIGHT_UNIT_CODE:
        if (shipmentType === ShipmentType.FCL) {
          isDisabled = this.checkBeforeACOStatus(currentBookingStatus);
        }
        else {
          isDisabled = this.checkBeforeOrderSubmitStatus(currentBookingStatus);
        }
        break;
      default:
        break;
    }
    return isDisabled;
  }

  checkBeforeACOStatus(currentBookingStatus: BookingStatusCode) {
    if (currentBookingStatus < BookingStatusCode.arrivedAtCustomsOrigin) {
      return false;
    }
    return true;
  }

  isBookingSubmitted(booking: Booking): boolean {
    return booking.statusType.code != BookingStatusCode.draft &&
      !this.getcompletedOrderStatusType().includes(booking.statusType.code) &&
      !booking.isCanceled;
  }

  isDoorPickupService(bookingServiceType: BookingServiceType): boolean {
    const serviceType = BookingServiceType[bookingServiceType].split('2');
    if (serviceType.length == 2) {
      if (serviceType[0].toLowerCase() == 'door')
        return true;
    }
    return false;
  }

  isDoorDeliveryService(bookingServiceType: BookingServiceType): boolean {
    const serviceType = BookingServiceType[bookingServiceType].split('2');
    if (serviceType.length == 2) {
      if (serviceType[1].toLowerCase() == 'door')
        return true;
    }
    return false;
  }

  isEventTargetHasClass(element: EventTarget | null, classToSearch: string): boolean {
    while (element instanceof HTMLElement) {
      if (element.classList.contains(classToSearch)) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  }

  private checkBeforeOrderSubmitStatus(currentBookingStatus: BookingStatusCode) {
    if (currentBookingStatus <= BookingStatusCode.orderReceived) {
      return false;
    }
    return true;
  }

  private getcompletedOrderStatusType() {
    return CompletedOrderStatusType;
  }
}
