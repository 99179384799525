import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { RateDetailPanels } from '../flow-result-details.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RateServiceType } from '../../../../../../../shared/shared.model';
import { RollupDialogService } from 'src/app/mobile/rollup-dialog/rollup-dialog.service';
import { QuotePriceTiersComparisonComponent } from '../../../flow-result/quote-price-tiers-comparison/quote-price-tiers-comparison.component';

@Component({
  selector: 'app-flow-result-details-dialog',
  templateUrl: './flow-result-details-dialog.component.html',
  styleUrls: ['./flow-result-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowResultDetailsDialogComponent implements OnInit {
  rateDetailsPanels = RateDetailPanels;
  rateServiceType: RateServiceType;
  sortedRateDetails;

  constructor(@Inject(ROLLUP_DIALOG_DATA) public data: any, private rollupDialogService: RollupDialogService) {
    this.rateServiceType = data.rateServiceType;

    if (this.rateServiceType === RateServiceType.Other || this.rateServiceType === RateServiceType.Premium)
      this.rateServiceType = RateServiceType.Standard;

    this.sortedRateDetails = {};
  }

  ngOnInit() {
    this.sortedRateDetails = this.sortRateDetails(this.data.rateDetails);
  }

  sortRateDetails(rateDetails) {
    const standardOrBasic = {};
    const others = {};

    for (const key in rateDetails) {
      if (rateDetails[key].type === RateServiceType.Standard || rateDetails[key].type === RateServiceType.Basic) {
        standardOrBasic[key] = rateDetails[key];
      } else {
        others[key] = rateDetails[key];
      }
    }

    return { ...others, ...standardOrBasic };
  }

  onRateServiceType(): void {
    this.rollupDialogService.open(QuotePriceTiersComparisonComponent);
  }
}
