<div id="s4d-tooltip" class="s4d-tooltip">
  <div class="s4d-tooltip-container">
    <ng-container *ngIf="template; else stringContent">
      <div class="s4d-tooltip-inner">
        <ng-container *ngTemplateOutlet="template; context: { $implicit: viewContainerRef }"></ng-container>
      </div>
    </ng-container>
    <ng-template #stringContent>
      <div class="s4d-tooltip-inner" [innerHTML]="content"></div>
    </ng-template>
  </div>
</div>