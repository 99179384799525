<div class="quote-search-location-param">
  <mat-form-field appearance="standard" floatLabel="never" [ngClass]="{'location-has-data' : isSelected}"
    (click)="onClicked($event,true)">
    <div class="row flex-nowrap m-0 align-items-center">
      <div class="col-auto px-0">
        <div class="location-icon-wrapper">
          <mat-spinner *ngIf="isLoading" class="location-loader location-icon" diameter="22"></mat-spinner>
          <i *ngIf="!isLoading && !isSelected" class="location-icon s4d-icon s4d-map"></i>
          <i *ngIf="!isLoading && isSelected" class="location-icon s4d-icon"
            [ngClass]="selectedLocation?.locationType | locationTypeS4DIcon"></i>
        </div>
      </div>
      <div class="col pe-0">
        <div class="row flex-column m-0 g-0">
          <div class="col-auto pe-0">
            <input matInput id="location{{type}}" [matAutocomplete]="autoFrom" type="text"
              #trigger="matAutocompleteTrigger" (click)="onClicked($event)"
              [placeholder]="type === searchType.from ? 'Origin City' : 'Destination City'" [formControl]="control" appAutocompletePosition>
          </div>
          <div *ngIf="isSelected" class="col-auto pe-0 d-flex align-items-center location-details-wrapper">
            <div class="location-details" [matTooltip]="selectedLocation?.fullAddress">
              <ng-container *ngIf="type === searchType.from && checkLocationType(selectedLocation?.locationType)">
                <span *ngIf="selectedLocation?.zipCode">({{selectedLocation.zipCode}})</span>
                {{selectedLocation?.locationType
                | locationTypeShortName | titlecase}} Pickup
              </ng-container>
              <ng-container
                *ngIf="type === searchType.from && !checkLocationType(selectedLocation?.locationType)">{{selectedLocation?.locationType
                | locationTypeShortName |
                titlecase}} Pickup</ng-container>
              <ng-container
                *ngIf="type === searchType.to && checkLocationType(selectedLocation?.locationType)">({{selectedLocation?.zipCode}})
                {{selectedLocation?.locationType |
                  locationTypeShortName | titlecase}} Delivery</ng-container>
              <ng-container
                *ngIf="type === searchType.to && !checkLocationType(selectedLocation?.locationType)">{{selectedLocation?.locationType |
                  locationTypeShortName | titlecase}} Delivery
                </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="onSelect($event.option.value)" class="location-autocomplete" (opened)="panelState(true)"
      (closed)="panelState(false)">
      <ng-container *ngIf="!isPostcode">
        <mat-option *ngIf="suggestedLocality && sortedMap.length" disabled>
          <div class="d-flex justify-content-center p-2">
            <span>Did you mean <b class="suggested-locality">{{suggestedLocality}}</b>?</span>
          </div>
        </mat-option>
        <mat-optgroup *ngFor="let locationGroup of sortedMap" [label]="locationGroup.key | locationTypeGroupName: type">
          <ng-container *ngIf="checkLocationType(locationGroup.key); else others">
            <mat-option *ngFor="let location of locationGroup.value" [value]="location">
              <app-quote-search-flow-shipping-location-option [location]="location"
                [isDisabled]="isDomesticRoute(location)" [title]="locationGroup.key | locationTypeGroupName: type"
                (isSelected)="onPostCodeOptionSelected($event)"></app-quote-search-flow-shipping-location-option>
            </mat-option>
          </ng-container>
          <ng-template #others>
            <mat-option *ngFor="let location of locationGroup.value" [value]="location"
              [matTooltip]="getLocationTooltipText(location)">
              <div class="row location-autocomplete-option" (click)="disableDomesticOption($event, location)">
                <div class="col-auto">
                  <i class="contact-country-flag-icon fi fi-{{location.country | lowercase}}"></i>
                </div>
                <div class="col px-0 location-wrapper">
                  <div class="location-content">
                    <span class="location-content-strong">{{location.cuttedDisplayName}},</span>
                    {{location.countryName}}
                  </div>
                </div>
                <div class="col-auto">
                  <span *ngIf="location.locationType | locationTypeShortName" class="location-type">
                    {{ location.locationType | locationTypeShortName | uppercase }}
                  </span>
                </div>
                <div class="col-12" *ngIf="this.isDomesticRoute(location)">
                  <span class="domestic-error">Destination cannot be within the same country</span>
                </div>
              </div>
            </mat-option>
          </ng-template>
        </mat-optgroup>
        <mat-option *ngIf="sortedMap?.length === 0 && locationsResponse" disabled>
          <div class="d-flex justify-content-center">No results found</div>
        </mat-option>
      </ng-container>
      <mat-option *ngIf="isPostcode" [value]="selectedLocation" disabled>
        <app-quote-search-flow-shipping-location-option [location]="selectedLocation" [shipmentType]="shipmentType"
          [supportedCountries]="supportedCountries" [title]="postcodeOptionTitle" [extended]="true"
          (isClosed)="onPostCodeOptionClosed($event)"
          (isConfirmed)="onPostCodeOptionConfirmed($event)"></app-quote-search-flow-shipping-location-option>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
