<div class="dialog-container">
  <div class="row dialog-message-wrapper">
    <span>
      <img src="../../../../assets/images/delete-user.svg" />
    </span>

    <span class="dialog-title">Remove Contact?</span>
    <span class="dialog-message mt-3">
      Are you sure you want to remove this contact?<br>
      You can not undo this action.</span>
  </div>

  <div class="row text-center justify-content-between dialog-footer">
    <button mat-raised-button class="dialog-button cancel-button col-4" (click)="action(false)">
      {{ data.cancelButtonText }}
    </button>
    <button mat-raised-button class="dialog-button confirm-button col-4" (click)="action(true)">
      {{ data.confirmButtonText }}
    </button>
  </div>
</div>