import { Component, OnInit, HostListener, Input } from "@angular/core";
import { LayoutMode } from "../layout.model";
import { LayoutService } from "../layout.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  @Input() show: boolean = true;
  @Input() progress: number = 0;

  constructor(private layoutService: LayoutService) {
  }

  ngOnInit() {
  }
}
