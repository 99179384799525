<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="booking-flow" [hidden]="isLoading">
  <div class="booking-flow-header">
    <div class="booking-flow-header-background">
      <div class="background-sprite sprite-1"></div>
      <div class="background-sprite sprite-2"></div>
      <div class="background-sprite sprite-3"></div>
    </div>
    <div class="container booking-flow-header-actions">
      <div class="row d-flex justify-content-between py-4">
        <div class="col-auto ps-0"
          *ngIf="!isViewOrganizationContacts && !isOpenNewContact && !isCostEstimator && !isEdit">
          <button class="header-button" (click)="onBackSearchResults()">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-arrow-left"></i>
            </div>
            Back to Search Results
          </button>
        </div>
        <div class="col-auto ps-0" *ngIf="isViewOrganizationContacts || isBookingContactPage || isCostEstimator">
          <button class="header-button" (click)="onViewOrganizationContacts({isView: false})">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-arrow-left"></i>
            </div>
            Back to bookings
          </button>
        </div>
        <div class="col-auto ps-0" *ngIf="!isViewOrganizationContacts && !isBookingContactPage && isOpenNewContact">
          <button class="header-button" (click)="onBackOrganizationContacts()">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-arrow-left"></i>
            </div>
            Back to contacts
          </button>
        </div>
        <div class="col-auto ps-0"
          *ngIf="!isViewOrganizationContacts && !isOpenNewContact && !isCostEstimator && isEdit">
          <button class="header-button" (click)="onBackShipmentPage()">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-arrow-left"></i>
            </div>
            Back to Shipment
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-flow-container" [formGroup]="bookingForm" *ngIf="bookingForm">
    <div class="booking-flow-wrapper">
      <div class="row mb-5">
        <div class="col-8">
          <div class="row" [hidden]="isViewOrganizationContacts || isCostEstimator">
            <mat-accordion>
              <div class="col-12">
                <mat-expansion-panel class="booking-expansion-panel px-0"
                  [expanded]="isExpanded[bookingFlowPanels.cargoDetails]"
                  (opened)="onClickPanel(true, bookingFlowPanels.cargoDetails)"
                  (closed)="onClickPanel(false, bookingFlowPanels.cargoDetails)" hideToggle>
                  <mat-expansion-panel-header class="panel-header px-4">
                    <mat-panel-title class="panel-title">
                      <div class="row w-100 m-0">
                        <div class="col px-0">Cargo Details</div>
                        <div class="col-auto d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper" [ngClass]="{
                              interactive:
                                isSuccess[bookingFlowPanels.cargoDetails] ||
                                isError[bookingFlowPanels.cargoDetails] ||
                                isProgress[bookingFlowPanels.cargoDetails]
                            }">
                            <i *ngIf="isShowSuccessIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-check success"></i>
                            <i *ngIf="isShowErrorIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-info-light close"></i>
                            <i *ngIf="isShowProgressIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                            <i *ngIf=" isShowCevronDownHoverIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                              matTooltip="Mandatory fields are missing"></i>
                            <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.cargoDetails)"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content">
                    <app-booking-flow-cargo-details *ngIf="booking" [containers]="bookingForm.get('containers')"
                      [booking]="booking" [shipmentTypeCode]="shipmentTypeCode" (addCommodity)="onAddCommodity($event)"
                      (removeCommodity)="onDeleteCommodity($event)"
                      (copyContainerDetails)="onCopyContainerDetails($event)" (setState)="onSetState($event)"
                      (next)="onNext($event)" [bookingSummary]="bookingSummary"
                      (isBookingAdditionalServiceUpdated)="onBookingAdditionalServiceUpdated($event)"></app-booking-flow-cargo-details>
                  </div>
                </mat-expansion-panel>
              </div>
              <div class="col-12 mt-4 mb-4">
                <mat-expansion-panel class="booking-expansion-panel" [expanded]="isExpanded[bookingFlowPanels.contacts] &&
                isSuccess[bookingFlowPanels.cargoDetails]" [disabled]="!isSuccess[bookingFlowPanels.cargoDetails]"
                  (opened)="onClickPanel(true, bookingFlowPanels.contacts)"
                  (closed)="onClickPanel(false, bookingFlowPanels.contacts)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="row w-100 m-0">
                        <div class="col px-0">Contact Details</div>
                        <div class="col-auto d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper" [ngClass]="{
                            interactive:
                              (isSuccess[bookingFlowPanels.contacts] ||
                              isError[bookingFlowPanels.contacts] ||
                              isProgress[bookingFlowPanels.contacts]) &&
                              isSuccess[bookingFlowPanels.cargoDetails]
                          }">
                            <i *ngIf="isShowSuccessIcon(bookingFlowPanels.contacts) &&
                              isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-check success"></i>
                            <i *ngIf="isShowErrorIcon(bookingFlowPanels.contacts) &&
                            isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-info-light close"></i>
                            <i *ngIf="isShowProgressIcon(bookingFlowPanels.contacts) &&
                            isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                            <i *ngIf="isShowCevronDownHoverIcon(bookingFlowPanels.contacts) &&
                            isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.contacts) &&
                            isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                              matTooltip="Mandatory fields are missing"></i>
                            <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.contacts) &&
                            isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                            <i *ngIf="!isSuccess[bookingFlowPanels.cargoDetails]"
                              class="panel-header-icon s4d-icon s4d-lock"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content mt-0">
                    <app-booking-flow-contacts *ngIf="booking" [booking]="booking" (next)="onNext($event)"
                      (isViewOrganizationContacts)="onViewOrganizationContacts($event)" (setState)="onSetState($event)"
                      [shipperContact]="shipperContact" [consigneeContact]="consigneeContact"
                      [notifyPartyContact]="notifyPartyContact" (selectedContact)="onSelectedContact($event)"
                      (isOpenNewContact)="onOpenNewContact($event)" (unselectedContact)="onUnselectedContact($event)"
                      (isLoading)="onLoading($event)"></app-booking-flow-contacts>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-4" *ngIf="isAdditionalServicesEnabled" [style.display]="displayAdditionalServices">
                <mat-expansion-panel class="booking-expansion-panel" [expanded]="isExpanded[bookingFlowPanels.services] &&
                isSuccess[bookingFlowPanels.cargoDetails] &&
                isSuccess[bookingFlowPanels.contacts]"
                  [disabled]="!isSuccess[bookingFlowPanels.cargoDetails] || !isSuccess[bookingFlowPanels.contacts]"
                  (opened)="onClickPanel(true, bookingFlowPanels.services)"
                  (closed)="onClickPanel(false, bookingFlowPanels.services)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="row w-100 m-0">
                        <div class="col px-0">Additional Services</div>
                        <div class="col-auto d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper" [ngClass]="{
                            interactive:
                              (isSuccess[bookingFlowPanels.services] ||
                              isError[bookingFlowPanels.services] ||
                              isProgress[bookingFlowPanels.services])  &&
                              isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]
                          }">
                            <i *ngIf="isShowSuccessIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-check success"></i>
                            <i *ngIf="isShowErrorIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-info-light close"></i>
                            <i *ngIf="isShowProgressIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                            <i *ngIf=" isShowCevronDownHoverIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                              matTooltip="Mandatory fields are missing"></i>
                            <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.services) &&
                            isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                            <i *ngIf="!isSuccess[bookingFlowPanels.cargoDetails] || !isSuccess[bookingFlowPanels.contacts]"
                              class="panel-header-icon s4d-icon s4d-lock"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content">
                    <app-booking-flow-services (next)="onNext($event)" (isCostEstimator)="onCostEstimator($event)"
                      (hasAdditionalServices)="updateHasAdditionalServices($event)" [booking]="booking"
                      (isLoading)="onLoading($event)" [selectedAdditionalService]="selectedAdditionalService"
                      [isCreditOrganization]="isCreditOrganization"
                      (isBookingAdditionalServiceUpdated)="onBookingAdditionalServiceUpdated($event)"
                      (setState)="onSetState($event)" [bookingSummary]="bookingSummary"
                      [organization]="organization"></app-booking-flow-services>
                  </div>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>

          <div *ngIf="isViewOrganizationContacts">
            <app-booking-flow-contact (BackToBooking)="onBack()" (selectedContact)="onSelectedContact($event)"
              [booking]="booking" [organizationContacts]="organizationContacts" [contactType]="selectedContactType"
              (organizationContactandAction)="onOrganizationContactandAction($event)"
              (isOpenNewContact)="onOpenNewContact($event)" (isLoading)="onLoading($event)"></app-booking-flow-contact>
          </div>

          <div *ngIf="isCostEstimator">
            <app-service-cost-estimator [booking]="booking" [selectedCountry]="selectedCountry"
              [containers]="bookingForm.get('containers')" [shipmentTypeCode]="shipmentTypeCode"
              (setState)="onSetState($event)" [existingServiceCostEstimatorForm]="serviceCostEstimatorForm"
              [existingCalculaterResponse]="calculaterResponse" (isClose)="onViewOrganizationContacts({isView: false})"
              (isLoading)="onLoading($event)" [vendorCode]="vendorCode"></app-service-cost-estimator>
          </div>
        </div>

        <div class="col-4 siderbar-wrapper">
          <app-booking-flow-sidebar *ngIf="bookingSummary && this.organization" [bookingSummary]="bookingSummary"
            [isCreditOrganization]="isCreditOrganization">
          </app-booking-flow-sidebar>
        </div>
      </div>
    </div>
  </div>
</div>