import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "@ship4wd/ngx-common";
import {
  AdditionalService,
  AdditionalServiceType,
  AdditionalServicesQuery,
  AdditionalServicesQueryParameters
} from '../../../shared/additional-services/additional-services.model';
import { Booking, BookingStatusCode, BookingSummary, CertificateSummary, Commodity, SummaryQuote } from "../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../shared/bookings/bookings.service";
import {
  BookingPaidStatusCode,
  BookingViewDetailsPage,
  ExtenderTrip,
  Page,
  ShipmentType
} from "../../../shared/shared.model";
import { RequestAndPayment } from "../../../shared/requests-and-payments/requests-and-payments.model";
import { BookingFlowService } from '../booking-flow/booking-flow.service';
import { TransactionPaymentDialogComponent } from "../../shipments/order/transaction-payment-dialog/transaction-payment-dialog.component";
import { TeamMember } from "../../manage-organization/manage-organization.model";
import { UtilityService } from "../../../shared/helper/utility.service";
import { SupportRequestsService } from "../../support-requests/support-requests.service";
import { SupportRequestDialogData, SupportRequestTypeCode } from "../../support-requests/support-requests.model";

@Component({
  selector: "app-view-booking",
  templateUrl: "./view-booking.component.html",
  styleUrls: ["./view-booking.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ViewBookingComponent implements OnInit {
  bookingId: string;
  bookingSummary: BookingSummary | any;
  booking: Booking | any;
  teamMember: TeamMember;
  viewDetailsPageType: BookingViewDetailsPage;
  shipmentTypes = ShipmentType;
  additionalServices: AdditionalService[];
  bookingAdditionalServices: AdditionalService[] = [];
  additionalServiceType = AdditionalServiceType;
  isLoading = false;
  bookingCustomerNote: FormControl = new FormControl();
  customerReferenceControl: FormControl = new FormControl();
  isFinanceEnabled: boolean;
  paymentRequestId: string;
  generatedReferenceId: string;
  isCreditOrganization = false;
  hideButtons = true;
  certificateSummary: CertificateSummary;
  requestAndPayments: RequestAndPayment[];

  constructor(
    private route: ActivatedRoute,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private bookingFlowService: BookingFlowService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private supportRequestsService: SupportRequestsService
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.viewDetailsPageType = Number(this.route.snapshot.params.pageType) as BookingViewDetailsPage;
    this.getBookingSummaryById(this.bookingId);
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onBack(): void {
    this.navigateToPage();
  }

  getAdditionalServices(): void {
    if (this.bookingSummary.bookingAdditionalServiceCharges.length > 0) {
      const query = {
        bookingId: this.bookingId,
        includeProperties: true,
        sortBy: AdditionalServicesQueryParameters.name,
        pageNo: 1,
        pageSize: 10,
      } as AdditionalServicesQuery;

      this.bookingFlowService.getAdditionalServices(query)
        .subscribe(
          (x: Page<AdditionalService>) => {
            this.additionalServices = x.items;
          },
          (error: any) => {
            this.notificationService.error(error);
          }
        ).add(() => {
          const uniqueAdditionalService = [...new Set(this.bookingSummary?.bookingAdditionalServiceCharges?.map(x => x.additionalServiceCharge.additionalServiceId))]
          uniqueAdditionalService?.forEach(x => {
            this.bookingAdditionalServices.push(...this.additionalServices?.filter(y => y.id === x));
          })
        });
    }
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.preCarriageTripLeg);
    }
    if (trip?.mainCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.mainCarriageTripLeg);
    }
    if (trip?.postCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.postCarriageTripLeg);
    }
    return carriageTripLegs;
  }

  print(id: string): void {
    window.open(`/booking/pdf/${id}?mode=print`, '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getReturnToPageText(): string {
    switch (this.viewDetailsPageType) {
      case BookingViewDetailsPage.finance:
        return 'Finance';
      case BookingViewDetailsPage.dashboard:
        return 'Dashboard';
      case BookingViewDetailsPage.creditLine:
        return 'Credit Line';
      case BookingViewDetailsPage.shipments:
        return 'Shipment';
      default:
        return null
    }
  }

  navigateToLink(link: string): void {
    window.open(link, "_blank");
  }

  openTransactionPaymentDialog(): void {
    const dialogRef = this.dialog.open(TransactionPaymentDialogComponent, {
      panelClass: "transaction-payment",
      autoFocus: false,
      data: {
        booking: this.booking,
      },
    });

    dialogRef.afterClosed().subscribe(() => { });
  }

  getFormattedBookingAddress(bookingSearchType: 'from' | 'to'): string {
    const bookingSearch = bookingSearchType === 'from' ? this.bookingSummary.fromBookingSearch : this.bookingSummary.toBookingSearch;
    let addressParts: string[] = [];

    if (bookingSearch?.street) {
      addressParts.push(bookingSearch.street);
    }

    if (bookingSearch?.displayName) {
      addressParts.push(bookingSearch.displayName);
    }

    if (bookingSearch?.countryName) {
      addressParts.push(bookingSearch.countryName);
    }

    if (bookingSearch?.postcode) {
      addressParts.push(bookingSearch.postcode);
    }

    return addressParts.join(', ');
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }

  getCutOffDateTitle(quote: SummaryQuote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.bookingSummary?.shipmentTypeCode == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.datePipe.transform(cutOffDate, dateFormat) || 'n/a' : defaultTitle;
  }

  private navigateToPage(): void {
    switch (this.viewDetailsPageType) {
      case BookingViewDetailsPage.finance:
        window.location.href = '/finance';
        break;
      case BookingViewDetailsPage.dashboard:
        window.location.href = '/dashboard';
        break;
      case BookingViewDetailsPage.creditLine:
        window.location.href = '/creditLine';
        break;
      case BookingViewDetailsPage.shipments:
        window.location.href = '/shipments';
        break;
      default:
        break;
    }
  }

  private getBookingSummaryById(id: string): void {
    this.isLoading = true;
    this.bookingsService
      .getSummaryById(id)
      .subscribe(
        (bookingSummary: BookingSummary) => {
          this.bookingSummary = bookingSummary;
          this.booking = this.bookingSummary as Booking;
          this.customerReferenceControl.setValue(this.bookingSummary?.customerReferenceId);
          this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData());
          this.hideButtons = this.isHideButtons(this.bookingSummary);
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.getAdditionalServices();
        this.getBookingInsuranceCertificate();
      });
  }

  private isHideButtons(bookingSummary: BookingSummary): boolean {
    if (bookingSummary.statusType.code !== BookingStatusCode.draft &&
      bookingSummary.bookingPaidStatusCode === BookingPaidStatusCode.paid) {
      return true;
    }
    return false;
  }

  private getBookingInsuranceCertificate(): void {
    const hasInsuranceService = this.bookingSummary.bookingAdditionalServiceCharges
      .some(x => x.additionalServiceCharge.additionalServiceId
        == AdditionalServiceType.insurance);

    if (hasInsuranceService && this.bookingSummary.bookingPaidStatusCode == BookingPaidStatusCode.paid) {
      this.getInsuranceCertificateByBookingId(this.bookingId);
    }
  }

  private getInsuranceCertificateByBookingId(id: string): void {
    this.bookingsService
      .getInsuranceCertificateById(id)
      .subscribe(
        (response: CertificateSummary) => {
          if (response) {
            this.certificateSummary = response;
          }
        },
      )
  }

  private createSupportRequestDialogData(): SupportRequestDialogData {
    return {
      isPopupAutoOpened: false,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: this.booking.bolOrderNumber,
      quoteSearchId: this.booking.quoteSearchId
    } as SupportRequestDialogData
  }
}
