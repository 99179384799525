<div [formGroup]="bookingForm" *ngIf="!isViewOrganizationContacts">
  <div formArrayName="containers">
    <div *ngFor="
        let containerForm of bookingForm.get('containers')?.controls;
        let i = index
      ">
      <div [formGroup]="containerForm">
        <div class="booking-containers-container">
          <div class="booking-containers-header" *ngIf="shipmentTypeCode === shipmentTypes.FCL">
            <div class="booking-containers-header-wrapper">
              <div class="row">
                <div class="col">
                  <div class="row mb-2">
                    <div class="col">
                      <h2 class="booking-containers-header-title">
                        {{ "Container " + (i + 1) }}
                      </h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <h4 class="booking-containers-header-sub-title">
                        {{ getContainersTitle(containerForm) }}
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-center" *ngIf="
                    bookingForm.get('containers')?.controls.length !== 1 &&
                    i !== 0">
                  <button mat-button class="booking-container-copy-button" (click)="onCopyDetails(i, containerForm)"
                    matTooltip="Paste container details from previous container">
                    <span matPrefix class="booking-container-copy-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div [formGroup]="containerForm" *ngIf="shipmentTypeCode === shipmentTypes.FCL">
            <div class="booking-containers-wrapper">
              <div class="row">
                <div class="col">
                  <div class="booking-commodity-title">Cargo details</div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Cargo</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('cargoName').valid &&
                          containerForm.get('cargoName')?.touched
                      }">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput type="text" formControlName="cargoName"
                          placeholder='e.g. "Sheets" or "Car Parts"' />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Weight</span>
                  </div>
                </div>
                  <div class="booking-containers-unit-box" [ngClass]="{
                      'invalid-error-box':
                        !containerForm.get('cargoGrossWeight').valid &&
                        containerForm.get('cargoGrossWeight')?.touched
                    }">
                    <div class="booking-containers-unit-box-input-value-wrapper">
                      <div class="booking-containers-unit-box-input">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="number" placeholder="0" formControlName="cargoGrossWeight" min="0"
                            (change)="checkCommoditiesWeight(containerForm)" />
                        </mat-form-field>
                      </div>
                      <div class="booking-containers-unit-box-value">
                        <mat-form-field class="last">
                          <mat-select formControlName="cargoGrossWeightUnitCode" (selectionChange)="
                              checkCommoditiesWeight(containerForm)">
                            <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                              {{ weightUnit.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="booking-containers-wrapper">
              <div class="row">
                <div class="col">
                  <div class="booking-commodity-title pt-3">
                    Expected cargo ready
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Ready date and time</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('cargoExpectedReady').valid &&
                          containerForm.get('cargoExpectedReady')?.touched
                      }">
                      <mat-form-field>
                        <input matInput [ngxMatDatetimePicker]="pickerCargoExpectedReady" readonly
                          formControlName="cargoExpectedReady" [min]="minSelectableDate" [max]="maxSelectableDate" />
                        <mat-datepicker-toggle matSuffix [for]="pickerCargoExpectedReady"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerCargoExpectedReady>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="booking-containers-wrapper">
              <div class="row">
                <div class="col">
                  <div class="booking-commodity-title pt-3">Collection</div>
                </div>
              </div>
              <div class="booking-containers-field" *ngIf="isShowCollectContact">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Collect</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput placeholder="Add contact" disabled [value]="collectContact?.companyName" />
                        <button matSuffix mat-icon-button class="booking-contact-button" (click)="
                            collectContact
                              ? onRemoveBookingContact(contactTypes.collect)
                              : viewOrganizationContacts(contactTypes.collect)">
                          <span [ngClass]="collectContact ? 'remove-contact-icon' : 'add-contact-icon' "></span>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Loading Reference</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('collectionReference').valid &&
                          containerForm.get('collectionReference')?.touched
                      }">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput type="text" formControlName="collectionReference" placeholder="Reference" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Collection Date & Time</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('collectionDate').valid &&
                          containerForm.get('collectionDate')?.touched
                      }">
                      <mat-form-field class="full-width" [floatLabel]="'never'">
                        <input matInput [ngxMatDatetimePicker]="pickerCollectionDate" formControlName="collectionDate"
                          [min]="getMinimumCollectionTime(containerForm)" [max]="maxSelectableDate"
                          placeholder="Date & Time" />
                        <mat-datepicker-toggle matSuffix [for]="pickerCollectionDate"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerCollectionDate>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field" *ngIf="isShowDropContact">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Drop</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput placeholder="Add contact" disabled [value]="dropContact?.address1" />
                        <button matSuffix mat-icon-button class="booking-contact-button" (click)="
                            dropContact
                              ? onRemoveBookingContact(contactTypes.drop)
                              : viewOrganizationContacts(contactTypes.drop)">
                          <span [ngClass]="dropContact ? 'remove-contact-icon' : 'add-contact-icon' "></span>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div formArrayName="commodities">
              <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls; let i = index">
                <div class="booking-commodity-wrapper">
                  <div class="row">
                    <div class="col">
                      <div class="booking-commodity-title">
                        Commodity {{ i + 1 }}
                      </div>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <button mat-icon-button matSuffix color="warn"
                        (click)="onDeleteCommodity(containerForm, i)"
                        *ngIf="containerForm.get('commodities')?.controls.length !== 1">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div [formGroup]="commodityForm">
                    <div class="booking-containers-wrapper">
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Type of Packaging</span>
                            <span class="field-title subtitle">
                              Start typing (min 1 letter) for auto-completion
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-field-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('packageTypeCode').valid &&
                                  commodityForm.get('packageTypeCode')?.touched
                              }">
                              <app-package-type-autocomplete [pageSize]="10"
                                [packageTypeControl]="commodityForm.get('packageTypeCode')" class="w-100">
                              </app-package-type-autocomplete>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Product Value</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('productValue').valid &&
                                  commodityForm.get('productValue')?.touched
                              }">
                              <div class="booking-containers-unit-box-input-value-wrapper">
                                <div class="booking-containers-unit-box-input">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="0" formControlName="productValue" />
                                  </mat-form-field>
                                </div>
                                <div class="booking-containers-unit-box-value">
                                  <mat-form-field class="last">
                                    <mat-select formControlName="productValueCurrencyCode">
                                      <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                                        {{ currency.shortName }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Commodity Code</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-field-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('commodityTypeCode')
                                    .valid &&
                                  commodityForm.get('commodityTypeCode')
                                    ?.touched
                              }">
                              <div class="row">
                                <div class="col">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" formControlName="commodityTypeCode"
                                      placeholder='e.g. SCTG 37210' readonly (click)="
                                        onOpenComodityDialog(
                                          commodityForm,
                                          'commodityTypeCode',
                                          'commodityDescription'
                                        )" />
                                  </mat-form-field>
                                </div>
                                <div class="col-auto d-flex align-items-center">
                                  <button matSuffix mat-icon-button (click)="
                                      onOpenComodityDialog(
                                        commodityForm,
                                        'commodityTypeCode',
                                        'commodityDescription'
                                      )">
                                    <mat-icon>search</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Amount of Packages</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('numberOfPackages')
                                    .valid &&
                                  commodityForm.get('numberOfPackages')?.touched
                              }">
                              <mat-form-field [floatLabel]="'never'">
                                <input matInput type="number" placeholder="0" formControlName="numberOfPackages" />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Volume</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('volume').valid &&
                                  commodityForm.get('volume')?.touched
                              }">
                              <div class="booking-containers-unit-box-input-value-wrapper">
                                <div class="booking-containers-unit-box-input">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="0" formControlName="volume" min="0" />
                                  </mat-form-field>
                                </div>
                                <div class="booking-containers-unit-box-value">
                                  <mat-form-field class="last">
                                    <mat-select formControlName="volumeUnitCode">
                                      <mat-option *ngFor="let volumeUnit of volumeUnits" [value]="volumeUnit.value">
                                        {{ volumeUnit.name }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Weight</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('weight').valid &&
                                  commodityForm.get('weight')?.touched
                              }">
                              <div class="booking-containers-unit-box-input-value-wrapper">
                                <div class="booking-containers-unit-box-input">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="0" formControlName="weight" min="0"
                                      (change)="checkCommoditiesWeight(containerForm)" />
                                  </mat-form-field>
                                </div>
                                <div class="booking-containers-unit-box-value">
                                  <mat-form-field class="last">
                                    <mat-select formControlName="weightUnitCode"
                                      (selectionChange)="checkCommoditiesWeight(containerForm)">
                                      <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                                        {{ weightUnit.name }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="booking-container-add">
              <div class="row">
                <div class="col">
                  <button mat-button class="booking-container-add-button" (click)="onAddCommodity(containerForm)">
                    <span matPrefix class="booking-container-add-icon">
                      <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
                    </span>
                    <span class="booking-container-add-title">Add Commodity</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div [formGroup]="containerForm" *ngIf="shipmentTypeCode === shipmentTypes.LCL || shipmentTypeCode === shipmentTypes.AIR">
            <div class="booking-containers-wrapper">
              <div class="row">
                <div class="col">
                  <div class="booking-commodity-title pt-0">Cargo details</div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Cargo</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('cargoName').valid &&
                          containerForm.get('cargoName')?.touched
                      }">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput type="text" formControlName="cargoName"
                          placeholder='e.g. "Sheets" or "Car Parts"' />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="booking-containers-wrapper">
              <div class="row">
                <div class="col">
                  <div class="booking-commodity-title pt-3">Collection</div>
                </div>
              </div>
              <div class="booking-containers-field" *ngIf="isShowCollectContact">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Collect</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput placeholder="Add contact" disabled [value]="collectContact?.companyName" />
                        <button matSuffix mat-icon-button class="booking-contact-button" (click)="
                            collectContact
                              ? onRemoveBookingContact(contactTypes.collect)
                              : viewOrganizationContacts(contactTypes.collect)
                          ">
                          <span [ngClass]="
                              collectContact
                                ? 'remove-contact-icon'
                                : 'add-contact-icon'
                            "></span>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Loading Reference</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('collectionReference').valid &&
                          containerForm.get('collectionReference')?.touched
                      }">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput type="text" formControlName="collectionReference" placeholder='Reference' />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Collection Date & Time</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box" [ngClass]="{
                        'invalid-error-box':
                          !containerForm.get('collectionDate').valid &&
                          containerForm.get('collectionDate')?.touched
                      }">
                      <mat-form-field class="full-width" [floatLabel]="'never'">
                        <input matInput [ngxMatDatetimePicker]="pickerCollectionDate" formControlName="collectionDate"
                          placeholder="Date & Time" />
                        <mat-datepicker-toggle matSuffix [for]="pickerCollectionDate"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerCollectionDate>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="booking-containers-field" *ngIf="isShowDropContact">
                <div class="row">
                  <div class="col">
                    <span class="field-title">Drop</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="booking-containers-field-box">
                      <mat-form-field [floatLabel]="'never'">
                        <input matInput placeholder="Add contact" disabled [value]="dropContact?.address1" />
                        <button matSuffix mat-icon-button class="booking-contact-button" (click)="
                            dropContact
                              ? onRemoveBookingContact(contactTypes.drop)
                              : viewOrganizationContacts(contactTypes.drop)
                          ">
                          <span [ngClass]="
                              dropContact
                                ? 'remove-contact-icon'
                                : 'add-contact-icon'
                            "></span>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div formArrayName="commodities">
              <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls; let i = index">
                <div class="booking-commodity-wrapper">
                  <div class="row">
                    <div class="col">
                      <div class="booking-commodity-title">
                        <span>Load {{ i + 1 }}</span>
                        <span class="commodities-dimensions" *ngIf="commodityForm">
                          {{ getDimensions(commodityForm) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div [formGroup]="commodityForm">
                    <div class="booking-containers-wrapper">
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Marks & Numbers</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-field-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('marksAndNumbers').valid &&
                                  commodityForm.get('marksAndNumbers')?.touched
                              }">
                              <mat-form-field [floatLabel]="'never'">
                                <input matInput type="text" formControlName="marksAndNumbers"
                                  placeholder='Marks & Numbers' />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Commodity Code</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-field-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('commodityTypeCode')
                                    .valid &&
                                  commodityForm.get('commodityTypeCode')
                                    ?.touched
                              }">
                              <div class="row">
                                <div class="col">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" formControlName="commodityTypeCode"
                                      placeholder='e.g. SCTG 37210' readonly (click)="
                                        onOpenComodityDialog(
                                          commodityForm,
                                          'commodityTypeCode',
                                          'commodityDescription'
                                        )
                                      " />
                                  </mat-form-field>
                                </div>
                                <div class="col-auto">
                                  <button matSuffix mat-icon-button (click)="
                                      onOpenComodityDialog(
                                        commodityForm,
                                        'commodityTypeCode',
                                        'commodityDescription'
                                      )
                                    ">
                                    <mat-icon>search</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">How many packages</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('numberOfPackages')
                                    .valid &&
                                  commodityForm.get('numberOfPackages')?.touched
                              }">
                              <mat-form-field [floatLabel]="'never'">
                                <input matInput type="number" placeholder="0" formControlName="numberOfPackages" />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Type of Packaging</span>
                            <span class="field-title subtitle">Start typing (min 1 letter) for
                              auto-completion</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-field-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('packageTypeCode').valid &&
                                  commodityForm.get('packageTypeCode')?.touched
                              }">
                              <app-package-type-autocomplete [pageSize]="10" [packageTypeControl]="
                                  commodityForm.get('packageTypeCode')
                                " class="w-100">
                              </app-package-type-autocomplete>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="booking-containers-field">
                        <div class="row">
                          <div class="col">
                            <span class="field-title">Product Value</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="booking-containers-unit-box" [ngClass]="{
                                'invalid-error-box':
                                  !commodityForm.get('productValue').valid &&
                                  commodityForm.get('productValue')?.touched
                              }">
                              <div class="product-value-wraaper">
                                <div class="product-value-field">
                                  <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="number" placeholder="0" formControlName="productValue" />
                                  </mat-form-field>
                                </div>
                                <div class="currency-text">
                                  <mat-form-field class="last">
                                    <mat-select formControlName="productValueCurrencyCode">
                                      <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                                        {{ currency.shortName }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isViewOrganizationContacts">
  <app-booking-organization-contacts (BackToBooking)="onBack()" (selectedContact)="onSelectedContact($event)"
    [booking]="booking" [contactType]="selectedContactType"></app-booking-organization-contacts>
</div>
