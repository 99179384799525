import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject, interval, of } from "rxjs";
import { Page } from "../shared.model";
import { startWith, debounce, distinctUntilChanged, switchMap, map } from "rxjs/operators";
import { PackageType, PackageTypeCode, PackageTypeQuery, PackageTypeQueryParameters } from "../package-types/package-types.model";
import { PackageTypeService } from "../package-types/package-type.service";
import { DeviceDefService } from "../services/device-defining/deviceDef.service";

@Component({
  selector: "package-type-input",
  templateUrl: "./package-type-input.component.html",
  styleUrls: ['./package-type-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatFormFieldControl, useExisting: PackageTypeInputComponent },
  ],
  host: {
    '[class.package-type-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class PackageTypeInputComponent
  implements
  OnInit,
  ControlValueAccessor,
  MatFormFieldControl<PackageTypeCode>,
  OnDestroy,
  DoCheck {
  @Input()
  get value(): PackageTypeCode | null {
    return this.selectedPackageType?.id;
  }
  set value(data: PackageTypeCode | null) {
    if (data) {
      this.selectedPackageType = {
        id: data,
        code: PackageTypeCode[data],
      } as PackageType;

      this.packageTypeControl.setValue(this.selectedPackageType);
    }

    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.packageTypeControl.disable() : this.packageTypeControl.enable();
    this.stateChanges.next();
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input("aria-describedby") userAriaDescribedBy: string;
  @Input() pageSize = 500;
  @Input() minimumLength = 1;
  @Input() controlName: string = "Type of Packaging"

  @HostBinding() id = `commodity-input-${PackageTypeInputComponent.nextId++}`;

  static nextId = 0;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  stateChanges: Subject<void> = new Subject<void>();

  packageTypeControl: FormControl = new FormControl(null);

  focused: boolean = false;
  touched: boolean = false;
  errorState: boolean = false;
  autofilled?: boolean;
  controlType: string = "commodity-input";

  get empty(): boolean {
    return !this.packageTypeControl.value;
  }

  localStorageRecentPackageTypesKey = "recentPackageTypes"
  localStorageRecentPackageTypesMaxAmount = 3
  recentPackageTypes: PackageType[] = [];

  @Output() onSelectionChanged = new EventEmitter<PackageType | null>();
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() triggerError = new EventEmitter<string>();

  packageTypeResponse: PackageType[] = [];
  selectedPackageType: PackageType = null;
  isPackageTypeSelected = false;
  isLoading = false;
  pageNo = 1;
  isMobile: boolean;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.onFocusOut(event);
    }
  }

  constructor(
    fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private packageTypeService: PackageTypeService,
    public device: DeviceDefService,
    private elementRef: ElementRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() public parentFormField: MatFormField,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    this.isMobile = this.device.isMobile();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnInit(): void {
    this.loadPackageTypes();
    this.getPackageTypeControl().subscribe(change => {
      if (change !== null) {
        this.loadPackageTypes();
      }
    });
    this.getLocalRecentPackageTypes();
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }

    this.searchPackageTypes();
    this.onFocus.emit()
  }

  onFocusOut(event: FocusEvent): void {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onChange(event: any): void { }

  onTouched(): void { }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != "input") {
      this._elementRef.nativeElement.querySelector("input").focus();
    }
  }

  onSelect(packageType: PackageType): void {
    this.addRecentPackageType(packageType)
    this.onSelectionChanged.emit(packageType);
  }

  onKeyUp(event: KeyboardEvent): void {
    this.onSelectionChanged.emit(null);
  }

  writeValue(data: PackageTypeCode | null): void {
    this.value = data;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".package-type-input-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  displayFn(packageType: PackageType | null): string | null {
    return packageType && packageType.code ? packageType.code : null;
  }

  getPackageTypeControl(): Observable<FormControl> {
    return of(this.packageTypeControl);
  }

  markAllInputsAsTouched(): void {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.focused = false;
    }
    else {
      trigger.openPanel();
      this.focused = true;
      this.searchPackageTypes();
    }
  }

  handleError(event: string): void {
    this.triggerError.emit(event);
  }

  private updateErrorState(): void {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.packageTypeControl.invalid) &&
      (this.touched || this.ngControl.touched);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  private loadPackageTypes(): void {
    this.packageTypeControl.valueChanges
      .pipe(
        startWith(""),
        debounce(() => interval(400)),
        distinctUntilChanged(),
        switchMap((val) => {
          var packageTypeQueryParameters;
          if (this.packageTypeControl?.value?.description) {
            packageTypeQueryParameters = PackageTypeQueryParameters.description;
          } else if (this.packageTypeControl?.value?.code) {
            packageTypeQueryParameters = PackageTypeQueryParameters.code;
          } else {
            packageTypeQueryParameters = "";
          }

          const isSearch = this.checkPackageTypeBeforeSearch(val);
          return isSearch === true ? this.search(val, packageTypeQueryParameters) : of(isSearch);
        }),
        startWith("")
      )
      .subscribe((x: Page<PackageType>) => {
        this.isLoading = false;
        if (this.isPackageTypeSelected) {
          this.isPackageTypeSelected = false;
        } else {
          this.packageTypeResponse = x.items;
        }
      });
  }

  private search(searchValue: string, packageTypeQueryParameters: PackageTypeQueryParameters = PackageTypeQueryParameters.description): Observable<Page<PackageType>> {
    this.isLoading = true;
    this.onLoading.emit(true);
    const query = new PackageTypeQuery();
    query.pageNo = this.pageNo;
    query.pageSize = this.pageSize;
    query.sortBy = PackageTypeQueryParameters.description;

    if (searchValue)
      switch (packageTypeQueryParameters) {
        case PackageTypeQueryParameters.description:
          query.description = searchValue;
          break;

        case PackageTypeQueryParameters.code:
          query.code = searchValue;
          break;

        default:
          query.description = searchValue;
          break;
      }

    return this.packageTypeService
      .getPage(query)
      .pipe(map((response) => response));
  }

  private checkMinimumLength(value: string): boolean {
    return value?.length == 0 ? false : !(value.length >= this.minimumLength);
  }

  private checkPackageTypeBeforeSearch(value: string): any {
    if (this.isPackageTypeSelected) {
      return this.packageTypeResponse;
    }

    if (this.selectedPackageType) {
      this.selectedPackageType = null;
    }

    if (this.checkMinimumLength(value)) {
      return (this.packageTypeResponse = []);
    }
    return true;
  }

  private addRecentPackageType(packageType: PackageType): void {
    if (this.recentPackageTypes.findIndex((recentPackage) => recentPackage.description == packageType.description) == -1) {
      this.recentPackageTypes.push(packageType)
    }

    if (this.recentPackageTypes.length > this.localStorageRecentPackageTypesMaxAmount) {
      this.recentPackageTypes = this.recentPackageTypes.splice(-this.localStorageRecentPackageTypesMaxAmount)
    }

    this.updateLocalRecentPackageTypes()
  }

  private updateLocalRecentPackageTypes(): void {
    localStorage.setItem(this.localStorageRecentPackageTypesKey, JSON.stringify(this.recentPackageTypes))
    this.getLocalRecentPackageTypes()
  }

  private getLocalRecentPackageTypes(): void {
    this.recentPackageTypes = JSON.parse(localStorage.getItem(this.localStorageRecentPackageTypesKey)) || []
  }

  private searchPackageTypes(): void {
    this.search(this.packageTypeControl?.value?.code, PackageTypeQueryParameters.code)
      .subscribe((x: Page<PackageType>) => {
        this.isLoading = false;
        this.packageTypeResponse = x.items;
      });
  }
}
