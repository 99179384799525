<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="booking-files-documents-container" *ngIf="booking" [hidden]="isLoading">
  <div class="booking-files-documents-header-container">
    <div class="container booking-files-documents-header">
      <div class="booking-files-documents-left-content">
        <div class="back-booking-files-documents-title">
          <a href="javascript:void(0)" class="back-icon" (click)="onBack()">
            Back to {{ getReturnToPageText() }}
          </a>
          <h5 class="left-content-title">Files and Documents</h5>
        </div>
      </div>
      <div class="booking-files-documents-right-content">
        <div class="booking-files-documents-content">
          <div class="right-content-wrapper">
            <span class="right-content-title">From</span>
            <span class="right-content-text">{{ booking.fromDisplayName }}</span>
          </div>
          <div class="right-content-wrapper">
            <span class="arrow">
              <img src="../../../assets/images/go.svg" />
            </span>
          </div>
          <div class="right-content-wrapper">
            <span class="right-content-title">To</span>
            <span class="right-content-text">{{ booking.toDisplayName }}</span>
          </div>
          <div class="right-content-wrapper">
            <div class="order-number-title">
              <span class="right-content-title">Order Number</span>
            </div>
            <span class="right-content-text">{{ booking.bolOrderNumber ?? 'n/a' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container booking-files-documents-wrapper">
    <div class="row col-auto booking-files-documents-containers-field justify-content-end">
      <div class="booking-files-documents-containers-field-box middle">
        <div class="w-100">
          <mat-form-field [floatLabel]="'never'" class="w-100">
            <span matPrefix>
              <app-booking-icons [iconName]="'search-icon'"></app-booking-icons>
            </span>
            <input type="text" matInput placeholder="Type document name" (input)="onChangeText()"
              [formControl]="documentNameControl" [matAutocomplete]="autoDocumentName" appAutocompletePosition>
            <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="documentNameControl.value">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #autoDocumentName="matAutocomplete" [displayWith]="displayFn"
              (optionSelected)="onSelectDocument()">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option" matTooltip="{{option.name}}">
                <span class="booking-files-documents-option-wrapper">
                  <span class="booking-files-documents-option">
                    <span class="booking-files-documents-name font-weight-bold">
                      {{option.name}}
                    </span>
                  </span>
                </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="booking-files-documents-upload-container">
      <app-files-documents [booking]="booking" (addedDocumentEvent)="onAddedDocumentEvent($event)">
      </app-files-documents>
    </div>
    <div class="booking-files-documents-list-container">
      <app-booking-files-documents-list [bookingId]="booking.id" [bookingDocuments]="bookingDocuments"
        [pageType]="filesDocumentsPageType"></app-booking-files-documents-list>
    </div>
  </div>
</div>