<div class="container-fluid payment-successful-container" [ngClass]="{'p-0': isMobile === true}">
  <div [ngClass]="{'mobile-wrapper background-air-cloud': isMobile === true}"></div>
  <div [ngClass]="{
    'background-fcl-wrapper': type === shipmentType.FCL,
    'background-lcl-wrapper': type === shipmentType.LCL,
    'background-air-wrapper': type === shipmentType.AIR,
    'mobile-wrapper': isMobile === true
    }"></div>
  <div class="row justify-content-center align-items-center px-3 done-container">
    <div class="col col-lg-6 col-xl-4 done-width" [ngClass]="{'mobile-wrapper': isMobile === true}">
      <div class="row">
        <div class="col-12 d-flex justify-content-center pt-4">
          <div class="icon-background">
            <img class="main-icon" src="../../../assets/images/payments/credit-card.svg"
              alt="Your payment is successful" />
          </div>
        </div>
        <div class="col-12 text-center pt-3">
          <h1 class="done-title">Your payment is successful</h1>
        </div>
        <div class="col-12 text-center pt-2">
          <h4 class="done-subtitle">
            You can track the current status on your finance dashboard with
            payment <span class="identification" (click)="onFinanceDashboard()">ID {{id}}</span>.
          </h4>
          <button mat-raised-button class="done-button" [ngClass]="{'w-100': isMobile === true}" (click)="onDashboard()">
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
</div>