<mat-dialog-content *ngIf="teamMemberForm">
  <div class="team-member-container" [formGroup]="teamMemberForm">
    <div class="team-member-profile-container">
      <div class="team-member-profile-logo">
        <span class="team-member-profile-name">{{ getInitialName() }}</span>
      </div>
      <div class="team-member-profile-button">
        <div class="team-member-profile-image">
          <span class="team-member-profile-image-icon"></span>
        </div>
        <div class="remove-team-member-profile-image">
          <span class="remove-team-member-profile-image-icon"></span>
        </div>
      </div>
    </div>
    <div class="team-member-content-container">
      <div class="team-member-content-wrapper">
        <div class="team-member-field">
          <span class="field-title">First name</span>
          <div class="team-member-field-box">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="First name" formControlName="firstName" />
            </mat-form-field>
          </div>
        </div>
        <div class="team-member-field">
          <span class="field-title">Last name</span>
          <div class="team-member-field-box">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Last name" formControlName="lastName" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="team-member-content-wrapper">
        <div class="team-member-field">
          <span class="field-title">Nickname</span>
          <div class="team-member-field-box">
            <div class="team-member-field-nickname">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput type="text" placeholder="Type nickname" formControlName="nickName" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="team-member-field">
          <span class="field-title">Role</span>
          <div class="team-member-field-box"
            [ngClass]="{ 'invalid-error-box': !teamMemberForm.get('roles')?.valid
            && teamMemberForm.get('roles')?.touched }">
            <div class="team-member-field-role">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <mat-select multiple formControlName="roles">
                  <mat-option *ngFor="let teamAccessRole of teamAccessRoles"
                    [value]="teamAccessRole.value">
                    {{ teamAccessRole.value | accessRoleTypeName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="team-member-content-wrapper">
        <div class="team-member-field w-100">
          <span class="field-title">Email</span>
          <div class="team-member-field-box">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput type="text" placeholder="Type email" formControlName="email" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="team-member-content-wrapper">
        <div class="team-member-field w-100">
          <span class="field-title">Status</span>
          <div class="team-member-field-box">
            <div class="team-member-field-status">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <mat-select placeholder="Status" formControlName="memberStatus">
                  <mat-option *ngFor="let memberStatusCode of memberStatusCodes"
                    [value]="memberStatusCode.value">
                    {{ memberStatusCode.name | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-member-footer">
      <button mat-raised-button class="back-button" (click)="onCancel()">Cancel</button>
      <button [disabled]="isLoading" mat-raised-button class="next-button button-spinner"
        (click)="onSave()">
        <span class="button-inner">
          Save Changes
          <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</mat-dialog-content>