import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ContentService {
  scrollToOptions = new BehaviorSubject<ScrollToOptions | null>(null);

  constructor() { }

  scrollTo(options: ScrollToOptions): void {
    this.scrollToOptions.next(options)
  }
}