<div *ngIf="isLoading; else loaded" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
  <div class="payments-page-wrapper">
    <div class="row">
      <div class="col ps-3">
        <div class="title bold">From</div>
        <div class="location bold from-location">
          {{ bookingSummary.fromDisplayName }}
        </div>
        <div class="title bold">To</div>
        <div class="location bold to-location">
          {{ bookingSummary.toDisplayName }}
        </div>
        <div class="status bold">
          <lib-traffic-light class="paid-status-color" [status]="getIconColor(bookingPaidStatusCode)"></lib-traffic-light>
          <span>{{ bookingPaidStatusCode | bookingPaidStatusName }}</span>
        </div>

        <div class="square">
          <div class="title bold">Details</div>
          <div class="row inner-row">
            <div class="col-5 inner-element">Bol/Order#</div>
            <div class="col-7 value">{{ bookingSummary.bolOrderNumber }}</div>
          </div>
          <div class="row inner-row">
            <div class="col-5 inner-element">Master Bol</div>
            <div class="col-7 value">
              {{ bookingSummary.masterBillOfLading }}
            </div>
          </div>
          <div class="row inner-row">
            <div class="col-5 inner-element">ATD</div>
            <div class="col-7 value">{{ bookingSummary.atd | date }}</div>
          </div>
          <div class="row inner-row">
            <div class="col-5 inner-element">Shipment</div>
            <div class="col-7 value main-carriage-icon" [ngClass]="
                bookingSummary.shipmentTypeCode ? getMainCarriageIcon() : ''
              "></div>
          </div>
          <div class="row inner-row">
            <div class="col-5 inner-element">Updated</div>
            <div class="col-7 value">
              {{ bookingSummary.updateTimeUtc | date }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <div class="title bold">Payments</div>
        <div class="row payment-content-wrapper mt-3" *ngFor="let requestAndPayment of requestAndPayments">
          <div class="col">
            <div class="row">
              <div appSetRoleBasedPrice class="col ps-0 title amount">
                {{ requestAndPayment.amount | currency }}
              </div>
            </div>
            <div class="row">
              <div class="col ps-0 text-container-size">
                {{ requestAndPayment.reasonDisplayName }}
              </div>
              <div class="col-auto text-right icons px-0">
                <span class="status-icon" [ngClass]="getPaymentIconStatusColor(requestAndPayment.statusCode)"></span>
                <span class="arrow-back-icon rotate" (click)="toPayment(requestAndPayment.id)"></span>
              </div>
            </div>
            <div class="row">
              <div class="col-auto ps-0 text-mar">
                {{ requestAndPayment.dueDate | date }}
              </div>
              <div class="col text-right text-to-be-paid"
                [ngClass]="getPaymentStatusColor(requestAndPayment.statusCode)">
                {{ requestAndPayment.statusDisplayName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>