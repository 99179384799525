import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@ship4wd/ngx-authorization';
import { NotificationService, ValidationService } from '@ship4wd/ngx-common';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { GlobalTradeTypeCode } from '../../shared/shared.model';
import {
  Organization,
  OrganizationMembers,
  OrganizationViewModel
} from './manage-organization.model';
import { ManageOrganizationService } from './manage-organization.service';
import { PersonalNotificationsService } from '../../shared/personal-notifications/personal-notifications.service';
import { PersonalNotificationCode } from '../../shared/personal-notifications/personal-notification.model';

@Component({
  selector: 'app-manage-organization',
  templateUrl: './manage-organization.component.html',
  styleUrls: ['./manage-organization.component.scss'],
})
export class ManageOrganizationComponent implements OnInit {
  isLoading = false;
  organization: Organization;
  organizationMembers: OrganizationMembers;
  organizationForm: FormGroup;
  organizationId: string;
  countryAutocompleteValue: string;
  userId: string;

  organizationRoleTypeCodes: any[] = [
    {
      name: GlobalTradeTypeCode[GlobalTradeTypeCode.importer],
      value: GlobalTradeTypeCode.importer,
      isChecked: false
    },
    {
      name: GlobalTradeTypeCode[GlobalTradeTypeCode.exporter],
      value: GlobalTradeTypeCode.exporter,
      isChecked: false
    }
  ];

  constructor(
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    private validationService: ValidationService,
    private manageOrganizationService: ManageOrganizationService,
    private userService: UserService,
    private location: Location,
    private route: ActivatedRoute,
    private personalNotificationsService: PersonalNotificationsService
  ) {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.getOrganizationInformationWithMembers();

    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { org: this.organizationId } }).toString();
    this.location.go(url);
  }

  ngOnInit() {
    this.userId = this.userService.getUser()?.userId;
  }

  onCancel() {
    this.router.navigate(['/dashboard']);
  }

  onSaveChanges() {
    this.validationService.validate(this.organizationForm);

    if (!this.organizationForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }

    const organizationData = this.organizationForm.value as OrganizationViewModel;

    const roles = [];
    this.organizationRoleTypeCodes.forEach(e => {
      if (e.isChecked) {
        roles.push(e.value)
      }
    });

    const selectedRole = roles.length === this.organizationRoleTypeCodes.length ?
      GlobalTradeTypeCode.both : (roles.length > 0 ? roles[0] : GlobalTradeTypeCode.unknown);
    organizationData.globalTradeTypeId = selectedRole;

    const organization = { ...this.organization, ...organizationData };
    this.updateOrganization(organization);
  }

  onChangeRoles(roleData: any) {
    if (roleData) {
      this.organizationRoleTypeCodes
      .find(x => x.value === roleData.organizationRoleTypeCode).isChecked = roleData.checked;
    }
  }

  onTeamMemberEvent() {
    this.getOrganizationInformationWithMembers();
  }

  private getOrganizationInformationWithMembers() {
    this.isLoading = true;
    of(null)
      .pipe(
        switchMap(x => combineLatest([
          this.manageOrganizationService.getOrganization(this.organizationId),
          this.manageOrganizationService.getTeamAndInviteMembersById(this.organizationId, this.userId)
        ]))
      )
      .subscribe(([organization, organizationMembers]) => {
        this.updateOrganizationData(organization);
        this.organizationMembers = organizationMembers;
        this.createOrganizationForm();
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false)
  }

  private updateOrganization(organization: OrganizationViewModel) {
    this.isLoading = true;
    of(null)
      .pipe(
        switchMap(() => this.manageOrganizationService.updateOrganization(this.organizationId, organization)),
        switchMap(() => this.manageOrganizationService.getOrganization(this.organizationId))
      )
      .subscribe((organization) => {
        this.updateOrganizationData(organization);
        this.notificationService.success('Organization has been successfully changed.');

        if (!!organization.taxId) {
          if (this.personalNotificationsService.isNotificationAlreadyExists(PersonalNotificationCode.missingTaxIdNotification))
            this.personalNotificationsService.removeNotification(PersonalNotificationCode.missingTaxIdNotification);
        }
        else {
          this.personalNotificationsService.addNotification(PersonalNotificationCode.missingTaxIdNotification);
        }
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false)
  }

  private updateOrganizationData(organization: Organization) {
    this.organization = organization;
    this.countryAutocompleteValue = this.getPropertyValue('countryCode');
    if (organization.globalTradeTypeId) {
      if (organization.globalTradeTypeId === GlobalTradeTypeCode.both) {
        this.organizationRoleTypeCodes.map(x => x.isChecked = true);
      }
      else {
        if (organization.globalTradeTypeId !== GlobalTradeTypeCode.unknown) {
          this.organizationRoleTypeCodes.find(x => x.value === organization.globalTradeTypeId).isChecked = true;
        }
      }
    }
  }

  private createOrganizationForm() {
    this.organizationForm = this.fb.group({
      name: [{value: this.getPropertyValue('name'), disabled: true}],
      legalName: [this.getPropertyValue('legalName')],
      fax: [this.getPropertyValue('fax')],
      globalTradeTypeId: [this.getPropertyValue('globalTradeTypeId'), Validators.required],
      website: [this.getPropertyValue('website')],
      industry: [this.getPropertyValue('industry')],
      tradeNeeds: [this.getPropertyValue('tradeNeeds')],
      taxId: [this.getPropertyValue('taxId'), [Validators.required, this.atLeastOneNumberValidator()]],
      street: [this.getPropertyValue('street'), Validators.required],
      address2: [this.getPropertyValue('address2')],
      city: [this.getPropertyValue('city'), Validators.required],
      postalCode: [this.getPropertyValue('postalCode'), Validators.required],
      countryCode: [this.getPropertyValue('countryCode'), Validators.required],
      state: [this.getPropertyValue('state'), Validators.required]
    });
  }

  private getPropertyValue(propertyName: string) {
    return this.organization[propertyName] ?? '';
  }

  private atLeastOneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!/\d/.test(control.value as string))
        return { atLeastOneNumberError: true };
      return null;
    }
  }
}
