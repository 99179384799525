<div
  class="ads-widget-image-container"
  *ngIf="!isLoading && dashboardBanners; else shipNowBannerTemplate"
>
  <div class="row">
    <swiper-container
      class="ads-swiper-container"
      pagination="true"
      pagination-clickable="true"
      space-between="16"
      centered-slides="true"
      autoplay-delay="7000"
      autoplay-disable-on-interaction="false"
    >
      <swiper-slide
        class="content-wrapper"
        *ngFor="let banner of dashboardBanners"
      >
        <div class="banner-container mb-3">
          <ng-container *ngTemplateOutlet="banner"></ng-container>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>

<ng-template #shipNowBannerTemplate>
  <div class="ads-widget-container" [routerLink]="['/creditLine']">
    <div *ngIf="!isLoading && !isError" class="widget-background">
      <div class="ads-background ads-background--ship-now">
        <div class="ads-image"></div>
        <button
          mat-raised-button
          color="primary"
          class="ads-button ads-button--green"
        >
          Apply Now
        </button>
      </div>
    </div>
    <div class="widget-wrapper">
      <div class="row widget-header">
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <mat-icon class="editable-icon link-action">zoom_out_map</mat-icon>
          </div>
        </div>
        <div class="col widget-title"></div>
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <span class="remove-icon" (click)="onRemove()"></span>
          </div>
        </div>
      </div>
      <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
        <div *ngIf="isLoading" class="col is-loader">
          <div class="loader-wrapper loader-element"></div>
        </div>
        <div *ngIf="!isLoading && isError" class="col is-error">
          <span class="refresh-icon" (click)="onReload()"></span>
          <span class="mt-3">{{ errorMessage }}</span>
        </div>
        <div *ngIf="!isLoading && !isError" class="col d-flex flex-grow-1">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-xxl-6 col-xl-7 col-9">
                <span class="ads-title">Ship Now. Choose when to Pay.</span>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <span class="ads-subtitle ads-subtitle--ship-now"
                  >Pay Up To 90 Days From Cargo Delivery</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #customsClearanceBannerTemplate>
  <a
    href="https://ship4wd.com/shipping/customs-clearance/"
    target="_blank"
    class="ads-widget-container"
  >
    <div *ngIf="!isLoading && !isError" class="widget-background">
      <div class="ads-background ads-background--customs-clearance">
        <div class="ads-image"></div>
        <button mat-raised-button color="primary" class="ads-button">
          Learn More
        </button>
      </div>
    </div>
    <div class="widget-wrapper">
      <div class="row widget-header">
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <mat-icon class="editable-icon link-action">zoom_out_map</mat-icon>
          </div>
        </div>
        <div class="col widget-title"></div>
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <span class="remove-icon" (click)="onRemove()"></span>
          </div>
        </div>
      </div>
      <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
        <div *ngIf="isLoading" class="col is-loader">
          <div class="loader-wrapper loader-element"></div>
        </div>
        <div *ngIf="!isLoading && isError" class="col is-error">
          <span class="refresh-icon" (click)="onReload()"></span>
          <span class="mt-3">{{ errorMessage }}</span>
        </div>
        <div *ngIf="!isLoading && !isError" class="col d-flex flex-grow-1">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-xxl-6 col-xl-7 col-9">
                <span class="ads-title">Customs Clearance, simplified</span>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <span class="ads-subtitle mt-2"
                  >Get your shipping costs and customs info ahead of time. Enjoy
                  a paperless customs process, hassle-free.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #expertHelpBannerTemplate>
  <div class="ads-widget-container" (click)="openEmbeddedChat()">
    <div *ngIf="!isLoading && !isError" class="widget-background">
      <div class="ads-background ads-background--expert-help">
        <div class="ads-image"></div>
        <div class="ads-chat">Live Assistance</div>
      </div>
    </div>
    <div class="widget-wrapper">
      <div class="row widget-header">
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <mat-icon class="editable-icon link-action">zoom_out_map</mat-icon>
          </div>
        </div>
        <div class="col widget-title"></div>
        <div *ngIf="editable && false" class="col-auto widget-action">
          <div class="link-action">
            <span class="remove-icon" (click)="onRemove()"></span>
          </div>
        </div>
      </div>
      <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading }">
        <div *ngIf="isLoading" class="col is-loader">
          <div class="loader-wrapper loader-element"></div>
        </div>
        <div *ngIf="!isLoading && isError" class="col is-error">
          <span class="refresh-icon" (click)="onReload()"></span>
          <span class="mt-3">{{ errorMessage }}</span>
        </div>
        <div *ngIf="!isLoading && !isError" class="col d-flex flex-grow-1">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-xxl-6 col-xl-7 col-9">
                <span class="ads-title">24/7 Live Expert Assistance</span>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <span class="ads-subtitle mt-2"
                  >From booking to cargo delivery, our support team is always
                  with you. No bots here, we work when you work to get your the
                  answers you want when you need them.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
