<div class="phone-input-container w-100" [formGroup]="phoneForm">
  <mat-form-field class="w-100 phone-input-form-field" appearance="fill" [floatLabel]="'always'"
    [class.mat-form-field-invalid]="
      fromControl.touched && fromControl.hasError('invalidPhoneNumber')
    ">
    <mat-label class="form-label form-label--phone">{{label}}</mat-label>
    <div class="row h-100">
      <div class="col d-flex align-items-end p-0">
        <ngx-intl-tel-input #phoneNumberInput class="intl-tel-input-container" [cssClass]="'mat-input-element'"
          formControlName="phoneNumber" [selectedCountryISO]="selectedCountry"
          [enableAutoCountrySelect]="enableAutoCountrySelect" [searchCountryFlag]="searchCountryFlag" trackInputErrors
          (triggerError)="handleError($event)"></ngx-intl-tel-input>
        <input matInput hidden type="text" />
      </div>
    </div>
  </mat-form-field>
  <mat-error class="contact-field-error" *ngIf="fromControl.touched && fromControl.hasError('invalidPhoneNumber')">
    <i class="s4d-icon s4d-info-light"></i> Please provide a valid phone number
    {{ selectedCountryForm?.name ? "for " + selectedCountryForm?.name : '' }}
  </mat-error>
</div>
