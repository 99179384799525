<div *ngIf="isLoading" class="loading small-loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<div class="requests-and-payments-list" *ngIf="!isLoading">
  <div *ngIf="isNoCreditLines" class="splash-screen">
    <div class="splash-screen-content">
      <span class="splash-screen-title">No Open Credit Line transactions yet</span>
      <span class="splash-screen-text mt-3">Your transactions using credit funds will appear here.</span>
      <button mat-raised-button class="splash-screen-button" (click)="onQuoteSearch()">
        Search New Quote
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" [disabled]="!isCreditLineMode" class="requests-and-payments-table w-100"
    matSort (matSortChange)="onSort($event)">
    <ng-container matColumnDef="paymentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Payment Number
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ item.paymentNumber }}">
        {{ item.paymentNumber ?? "-" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="duePaymentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Pay Due
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ item.dueDate }}">
        {{ item?.dueDate | date : "MMM dd, yy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Total Amount
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ getTotalAmount(item) | number : '1.2-2' }}">
        <span appSetRoleBasedPrice>
          <mat-icon class="currency-icon">{{ currency | currencyIcon }}</mat-icon>
          <span>{{ getTotalAmount(item) | number : "1.2-2" }}</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Reason
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ item?.reasonDisplayName }}">
        {{ item?.reasonDisplayName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updateTimeUtc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        UPDATED
      </th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        <div class="date-time">
          {{ item?.updateTimeUtc | date : "MMM dd, yy" }}
          <span class="time">{{ item?.updateTimeUtc | date : "HH:mm a" }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Status
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ item.statusDisplayName }}">
        <lib-traffic-light class="paid-status-color" [status]="getIconColor(item.statusCode)"></lib-traffic-light>
        <span>{{ item.statusDisplayName }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isCreditLineMode">
        Progress
      </th>
      <td mat-cell *matCellDef="let item" matTooltip="{{ item.externalStatus }}">
        <lib-traffic-light class="paid-status-color" *ngIf="item.externalStatus"
          [status]="getIconColor(item.statusCode)"></lib-traffic-light>
        {{ item.externalStatus ?? "-" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <div class="button-spinner" matSuffix *ngIf="isSortLoading">
          <span class="button-inner">
            <mat-spinner diameter="25"></mat-spinner>
          </span>
        </div>
      </th>
      <td mat-cell *matCellDef="let item">
        <button appRoleBasedHideOrShow *ngIf="showPayOrder(item.statusCode)" mat-icon-button class="pay-button button-spinner" (click)="
            item.statusCode !== statusCodes.failed
              ? onPay(item.id, item.paymentId)
              : onRetryPay(item.id, item.paymentId)
          ">
          <span class="button-inner">
            Pay Order
            <mat-spinner diameter="20" *ngIf="onPayIsLoading && payItemId === item.id"></mat-spinner>
          </span>
        </button>
        <button *ngIf="showConfirmButton(item.statusCode)" mat-icon-button class="pay-button"
          (click)="onConfirm(item.paymentId, item.paymentNumber)">
          Upload
        </button>
        <div class="col-auto btn order-actions" [matMenuTriggerFor]="menu">
          <mat-icon class="material-symbols-outlined content-text"> more_vert </mat-icon>
        </div>
        <mat-menu #menu backdropClass="order-dropdown">
          <btn *ngIf="showChangePaymentMethodButton(item.statusCode)" mat-menu-item class="dropdown-button"
            (click)="onChangePaymentMethod(item)">
            <span class="left-right-icon"></span>
            <span class="order-dropdown-text">Change Payment Method</span>
          </btn>
          <btn *ngIf="isCreditLineMode" mat-menu-item class="dropdown-button"
            (click)="navigateToBookingDetails(item.bookingId)">
            <span class="view-order-icon"></span>
            <span class="order-dropdown-text">Show Booking Details</span>
          </btn>
          <btn *ngIf="showBankDetailsButton(item.statusCode)" mat-menu-item class="dropdown-button"
            (click)="onShowDetailsMethod(item)">
            <span class="bank-icon"></span>
            <span class="order-dropdown-text">Show Bank Details</span>
          </btn>
          <btn mat-menu-item class="dropdown-button" (click)="onFilesDocuments(item.bookingId)">
            <span class="files-docs-icon"></span>
            <span class="order-dropdown-text">Files & Documents</span>
          </btn>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let item; columns: columns" class="main-row"></tr>
  </table>
</div>
