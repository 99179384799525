import { Injectable } from '@angular/core';
import { EnvironmentService } from '../services/environment.service';
import { UserInfoService } from '../services/user-info/user-info.service';
import { AppSettingsService } from '../services/app-settings/app-settings.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { TeamMember } from '../organizations/organization.model';
import { AmplitudeService } from '../amplitude/amplitude.service';
import { amplitudeFlags, amplitudeFlagVariants } from '../amplitude/amplitude.constants';

declare global {
  interface Window {
    heap: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HeapService {
  private _heap: any;

  constructor(
    private environmentService: EnvironmentService,
    private userInfo: UserInfoService,
    private appSettings: AppSettingsService,
    private organizationsService: OrganizationsService,
    private amplitudeService: AmplitudeService,
  ) { }

  async init(): Promise<void> {
    const variant = this.amplitudeService.getFlag(amplitudeFlags.heapService);

    const { organizationId } = this.appSettings.getSettings();
    let user = organizationId ? await this.organizationsService.getTeamMember(organizationId).toPromise() : null;

    if (variant.key !== amplitudeFlagVariants[amplitudeFlags.heapService].enabled || user?.organization?.isInternalOrganization) return;
    if (!this._heap) {
      this.loadScript();
      this._heap = window.heap;
    }

    if (user)
      this.identifyUser(user);
  }

  async identifyUser(userInfo?: TeamMember): Promise<void> {
    if (!this._heap) return;

    const { organizationId: orgId } = this.appSettings.getSettings();
    const { userId, fullName, phoneNumber } = this.userInfo.getSettings();
    userInfo ??= await this.organizationsService.getTeamMember(orgId).toPromise();

    if (!userInfo || userInfo?.organization?.isInternalOrganization) {
      this._heap.stopTracking();
      return;
    }

    const { organizationId, email, organization } = userInfo;
    this._heap.identify(`${fullName} - ${organization.name}`)

    const identityData = {
      userId: userId,
      email: email || userId,
      userName: fullName,
      phoneNumber: phoneNumber,
      organizationId: organizationId.toString()
    };

    this._heap.addUserProperties(identityData)
  }

  loadScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
          window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
          heap.load("${this.environmentService.environment.heap.appId}");
      `;
    document.head.appendChild(script);
  }
}
