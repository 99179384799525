import { Injectable } from "@angular/core";
import { City } from "./city.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs";

@Injectable()
export class CityService {
  constructor(
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService
  ) { }

  getCities(name: string): Observable<City[]> {
    let params = new HttpParams();
    params = params.set("name", name);

    return this.http.get<City[]>(
      `${this.internalUrlsService.getApiBaseUrl()}/cities`,
      { params }
    );
  }
}
