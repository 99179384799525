import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, expand, reduce, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subscription, of, throwError } from 'rxjs';
import { NIL as NIL_UUID, v4 } from 'uuid';
import { NotificationService } from '@ship4wd/ngx-common';
import { ContainerCommodity, ExtenderTripLegBase, Incoterm, IncotermsMappingsQuery, Quote, QuoteSearch, QuotesQuery } from '../../quotes.model';
import {
  CurrencyCode,
  DepartureDateSettings,
  LoadUnit,
  LocationType,
  Page,
  ShipmentType,
  ShipperType,
  UnitMeasurementType,
  WeightUnit
} from '../../../../shared/shared.model';
import { SupportedCountriesPairQuery, SupportedCountry } from '../../../../shared/supported-countries/supported-countries.model';
import { SupportedCountriesService } from '../../../../shared/supported-countries/supported-countries.service';
import { PreviousRouteService } from '../../../../shared/helper/previous-route.service';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import { QuotesService } from '../../quotes.service';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { QuotesIncotermService } from '../../quotes-incoterm.service';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { LayoutService } from '../../../../desktop/layout/layout.service';
import { environment } from '../../../../../environments/environment';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { WelcomeTutorialService } from '../../../../desktop/welcome-tutorial/welcome-tutorial.service';
import { PriceTrend, PriceTrendModelQuery } from '../../../../shared/price-history-chart/price-history-chart.model';
import { PriceHistoryChartService } from '../../../../shared/price-history-chart/price-history-chart.service';
import { ActivitiesService } from '../../../../shared/activities/activities.service';
import { RecentQuoteSearchesByUserIdQuery } from '../../../../shared/activities/activities.model';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { ArrayUtils } from '../../../../shared/services/utils/arrayUtils';

@Component({
  selector: 'app-quote-search-flow-new',
  templateUrl: './quote-search-flow-new.component.html'
})
export class QuoteSearchFlowNewComponent implements OnInit {
  isShowSearchSection = true;
  shippingTypes: any[] = [
    {
      name: 'FCL',
      description: 'Full-Container Load (FCL)',
      value: ShipmentType.FCL,
      icon: 'fcl'
    },
    {
      name: 'LCL',
      description: 'Less-Than-Container Load (LCL)',
      value: ShipmentType.LCL,
      icon: 'lcl'
    },
    {
      name: 'Air',
      description: 'AIR',
      value: ShipmentType.AIR,
      icon: 'ac'
    }
  ];
  quoteSearchForm: FormGroup;
  quotes: Quote[] = null;
  fromLocationJson: string = null;
  searchId = '';
  result: boolean = false;

  quotesQuery: QuotesQuery = undefined;
  from = '';
  to = '';
  shipmentype: ShipmentType = null;
  reusedBookingId = '';
  reusedQuoteId = '';
  reusedQuote: any = null;
  isListLoading: boolean;
  stopSearchQuote: boolean;
  showD2dAndAir: boolean;
  supportedCountries: SupportedCountry[];
  incoterms: Incoterm[] = null;

  isReuse: boolean = false;
  isStarted: boolean = false;
  isLoading: boolean = true;
  isFilled: boolean = false;
  isFirstSearch: boolean = true;
  isRecentQuotesSearch: boolean = false;
  noQuotesFound: boolean = false;
  quoteSearchId: string = null;
  searchQuotesSubscription: Subscription;
  supportedCountriesSubscription: Subscription;
  selectedQuote: any;
  priceTrendInfo: PriceTrend;
  recentQuotesSubscription: Subscription;
  departureDateSettings: DepartureDateSettings;

  get isShowLoadingList(): boolean {
    return !(this.isLoading && this.isStarted) && this.isListLoading;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private previousRouteService: PreviousRouteService,
    private notificationService: NotificationService,
    private settingsService: AppSettingsService,
    private userSettings: UserInfoService,
    private quotesService: QuotesService,
    private bookingsService: BookingsService,
    private supportedCountriesService: SupportedCountriesService,
    private quotesIncotermService: QuotesIncotermService,
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public router: Router,
    private utilityService: UtilityService,
    private welcomeTutorialService: WelcomeTutorialService,
    private priceHistoryChartService: PriceHistoryChartService,
    private activitiesService: ActivitiesService
  ) {
    if (this.router.url.includes("found-quote") || this.router.url.includes("not-found")) {
      this.router.navigate(['/quote-search']);
    }
    this.reusedBookingId = this.route.snapshot.queryParams.reusedBookingId;
    this.reusedQuoteId = this.route.snapshot.queryParams.reusedQuoteId;
    this.getWidgetType();
    this.getIsFirstSearch();
  }

  ngOnInit(): void {
    this.layoutService.setToolbarQuoteResultsPage(false);
    this.createQuoteSearchForm();
    this.observeRecentQuotes();

    if (this.reusedBookingId !== undefined && this.reusedBookingId !== '') {
      this.getReusedBooking();
    } else if (this.reusedQuoteId !== undefined && this.reusedQuoteId !== '') {
      this.getReusedQuote();
    } else {
      this.isLoading = false;
    }

    this.fillQuoteSearchParamsIfReturningFromBooking();

    this.quotesService.stopSearchQuote$.subscribe((isStop) => {
      if (isStop) {
        this.stopSearchQuote = true;
        this.quotesService.unsubscribeSearchQuote$.next();
        this.quotesService.unsubscribeSearchQuote$.complete();
      }
    });

    this.getDepartureDateSettings();
  }

  ngOnDestroy(): void {
    this.recentQuotesSubscription?.unsubscribe();
  }

  shipmentTypeChange(shipmentType: ShipmentType): void {
    if (shipmentType !== undefined && shipmentType !== null) {
      this.quoteSearchForm.get('shipmentType').setValue(shipmentType);
    }
  }

  onSearchQuotes(query: QuotesQuery): void {
    debugger
    if (this.supportedCountriesSubscription) {
      this.isLoading = false;
      this.isListLoading = false;
      this.isStarted = false;
      this.supportedCountriesSubscription.unsubscribe();
      this.stopSearchQuote = true;
      this.quotesService.unsubscribeSearchQuote$.next();
      this.quotesService.unsubscribeSearchQuote$.complete();
    }

    if (this.searchQuotesSubscription) {
      this.isLoading = false;
      this.isListLoading = false;
      this.isStarted = false;
      this.searchQuotesSubscription.unsubscribe();
      this.stopSearchQuote = true;
      this.quotesService.unsubscribeSearchQuote$.next();
      this.quotesService.unsubscribeSearchQuote$.complete();
    }

    this.isStarted = true;
    this.isLoading = true;
    this.quotesQuery = query;
    this.from = query.from.displayName;
    this.to = query.to.displayName;
    this.quotesQuery.organizationId = this.settingsService.getSettings().organizationId;
    this.reusedQuote = this.reusedQuote;

    const minDate = this.setFromDate();
    minDate.setUTCHours(0, 0, 0, 0);
    this.quotesQuery.minFromDate = minDate.toISOString();

    this.googleAnalyticsService.searchQuotes(this.quotesQuery, this.isFirstSearch);
    this.updateIsFirstSearch(false);
    this.reloadQuotes();
    this.getPriceTrendInfo();

    this.isRecentQuotesSearch = false;

    this.quotesService.saveQuotesQueryInStorage(query);
  }

  onChangeShipperType(value: ShipperType): void {
    this.stopSearchQuote = true;
    this.quotesService.unsubscribeSearchQuote$.next();
    this.quotesService.unsubscribeSearchQuote$.complete();
    this.quoteSearchForm.get('shipperType').setValue(value);
    this.quotesQuery.shipperType = value;
    this.quotesQuery.searchId = v4();
    this.getIncoterms().subscribe(incoterm => {
      const id = incoterm?.pop()?.id;
      if (id) {
        this.quotesQuery.incoterms = [id];
      }
      this.onSearchQuotes(this.quotesQuery);
    });
  }

  onOpenTutorial() {
    this.welcomeTutorialService.openWelcomeTutorialDialog();
  }

  private createQuoteSearchForm(): void {
    this.quoteSearchForm = this.fb.group({
      vendorId: [],
      shipmentType: [this.shipmentype, Validators.required],
      shipperType: [ShipperType.importer, Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      fromcities: ['', Validators.required],
      tocities: ['', Validators.required],
      postcodesTo: ['', Validators.required],
      postcodesFrom: ['', Validators.required],
      currency: [CurrencyCode.USD],
      fromDate: [new Date()],
      containerCommodities: this.fb.array([]),
      equipments: this.fb.array([]),
      incoterms: [null, Validators.required],
      isHazardous: [false],
      isBatteries: [false],
      reusedFromBookingId: [null],
      isShowPackageDimensions: [false],
      unitMeasurementType: [this.getDefaultUnitMeasurementType()],
      isWithoutDimension: [false],
    });
  }

  private fillQuoteSearchForm(quote: any): any {
    debugger
    this.quoteSearchForm.get('reusedFromBookingId').setValue(this.reusedQuote?.reusedFromBookingId);
    this.quoteSearchForm.get('vendorId').setValue(quote.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quote.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quote.shipperType);
    this.quoteSearchForm.get('from').setValue(quote.from);
    this.quoteSearchForm.get('to').setValue(quote.to);
    this.quoteSearchForm.get('fromcities').setValue(quote.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quote.to.displayName);
    this.quoteSearchForm.get('postcodesTo').setValue(quote.to.postcode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quote.from.postcode || null);
    this.quoteSearchForm.get('currency').setValue(CurrencyCode.USD);
    this.quoteSearchForm.get('fromDate').setValue(this.setFromDate());

    this.quoteSearchForm.get('incoterms').setValue(quote.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quote.isHazardous);
    this.quoteSearchForm.get('isBatteries').setValue(quote.isBatteries);
    this.quoteSearchForm.get('isShowPackageDimensions').setValue(quote?.commodities?.some(x => x.dimension?.height > 0
      || x.dimension?.width > 0 || x.dimension?.length > 0));
    this.quoteSearchForm.get('unitMeasurementType').setValue(this.getUnitMeasurementType(quote?.commodities[0]?.weightUnit));
    // this.quoteSearchForm.get('isWithoutDimension').setValue(quote?.isWithoutDimension)
    this.quoteSearchForm.get('isWithoutDimension').setValue(true)

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quote?.commodities.forEach(commodity => {
      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: { value: commodity.dimension?.dimensionUnit, disabled: true },
            height: commodity.dimension?.height,
            length: commodity.dimension?.length,
            width: commodity.dimension?.width,
            dimensionValue: this.getDimensionValue(commodity.dimension?.length, commodity.dimension?.width, commodity.dimension?.height),
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.packageWeight
              ? commodity.packageWeight * commodity.numberOfPackages
              : commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.packageWeight ?? commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: commodity.packageVolume,
            totalDimensionValueAmount: commodity.packageVolume,
            totalVolumeAmount: commodity.volumeAmount,
            totalWeightAmount: commodity.weightAmount,
            isShowMaskedInput: this.quoteSearchForm.get('isShowPackageDimensions').value,
            chargeableWeightAmount: this.calculateChargeableWeightAmount(commodity),
            chargeableVolumeAmount: this.calculateChargeableVolumeAmount(commodity),
            selectedValue: commodity.selectedValue
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quote?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();
    this.selectedQuote = quote;
    this.sendQuoteData(quote);
  }

  private getReusedQuote(): void {
    debugger
    this.quotesService
      .getReuseQuote(this.reusedQuoteId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => {
          this.notificationService.error(error);
        }
      )
      .add(() => (this.isLoading = false));
  }

  private getReusedBooking(): void {
    debugger
    this.bookingsService
      .getReuseBooking(this.reusedBookingId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => { }
      )
      .add(() => (this.isLoading = false));
  }

  private getIncoterms(): Observable<any> {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      return this.quotesIncotermService.getIncotremsByLocationTypes(query).pipe(
        tap((x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        }),
        catchError(error => {
          this.notificationService.error(error);
          return throwError(error);
        })
      );
    } else {
      this.incoterms = null;
      return of(null);
    }
  }

  private reloadQuotes(): void {
    this.quotesQuery.pageNo = 1;
    this.quotesQuery.pageSize = environment.quotesQueryPageSize;
    this.isListLoading = true;
    this.quotes = [];
    this.stopSearchQuote = false;

    if (
      this.quotesQuery.shipmentType != ShipmentType.AIR &&
      this.quotesQuery.from.locationType === LocationType.townCity &&
      this.quotesQuery.to.locationType === LocationType.townCity
    ) {
      this.showD2dAndAir = false;
    }

    this.isLoading = true;
    this.noQuotesFound = false;

    this.layoutService.setToolbarQuoteResultsPage(false);

    this.supportedCountriesSubscription = this.isCountryPairSupported()
      .subscribe(isSupported => {
        if (!isSupported) {
          this.notificationService.notify
            (`Not allow search between ${this.quotesQuery?.from?.countryName} and ${this.quotesQuery?.to?.countryName}`,
              5000
            );
          this.isLoading = false;
          this.isListLoading = false;
          return;
        }

        var storedQuoteResult = this.quotesService.getQuotesResultsFromStorage(this.quotesQuery);

        if (!storedQuoteResult) {
          this.searchQuotesSubscription = this.getQuotes()
            .subscribe()
            .add(() => {
              this.isLoading = false;
              this.isListLoading = false;
            });
        } else {
          this.quotes = [...this.quotes, ...storedQuoteResult.quotes];
          this.isLoading = false;
          this.isListLoading = false;
        }
      })
  }

  private getQuotes(): Observable<any[] | Page<Quote>> {
    return this.quotesService.getQuotesByQuery(this.quotesQuery).pipe(
      expand(x => this.hasMoreQuotesToLoad(x) ? this.loadMoreQuotes(x.pageNo) : this.stopLoadQuotes()),
      tap(
        x => {
          if (this.hasValidItem(x.items)) {
            this.quotes = [...this.quotes, ...x.items];
          }
          if (x.isAlternative) {
            this.quotesQuery.isAlternative = true;
            this.quotesQuery.searchId = this.getQuoteSearchId();
          }
          if (this.isFirstRequest()) {
            if (this.hasValidItem(this.quotes)) {
              this.quotes.forEach(x => (x.firstSearch = true));
              const quote = this.quotes[0];
              this.quoteSearchId = this.getQuoteSearchId();
              if (quote.trip !== null) {
                this.isShowSearchSection = false;
              } else {
                this.noQuotesFound = true;
                this.quoteSearchId = this.getQuoteSearchId();
                this.reusedQuote = null;
              }

              this.isFilled = true;
            } else {
              this.noQuotesFound = true;
              this.quoteSearchId = this.getQuoteSearchId();
              this.reusedQuote = null;
              this.isFilled = true;
            }

            const quoteResultsPageIncludeResults = !this.noQuotesFound && this.isStarted;
            this.layoutService.setToolbarQuoteResultsPage(quoteResultsPageIncludeResults);
          } else if (this.quotes.some(quote => !quote.firstSearch) || this.quotes.every(quote => quote.firstSearch)) {
            this.quotes = this.filterCreditQuotes(this.quotes);
            this.quotes = this.filterPartialQuotes(this.quotes);

            if (this.quotesQuery.shipmentType != ShipmentType.AIR)
              this.quotes = this.filterVariousTimeOptionQuotesWithinScheduledQuotes(this.quotes)

            this.quotes = this.filterQuotesWithNotNegativePrice(this.quotes)

            this.quotes = ArrayUtils.distinctBy(this.quotes, x => {
              return {
                accumulatePrice: x.accumulatePrice,
                arrivalDate: new Date(x.arrivalDate).toLocaleDateString(),
                departureDate: new Date(x.departureDate).toLocaleDateString(),
                vesselName: x.trip?.mainCarriageTripLeg?.legs[0]?.vesselName,
                cutOffDate: x.trip?.cutOffDate
              }
            }, [
              x => x.trip?.mainCarriageTripLeg?.legs[0]?.vesselName.startsWith("ZIM")
            ]);
            this.isLoading = false;
          }
          this.quotesService.saveQuotesResultsInStorage(this.quotes, this.quotesQuery);

          if (this.quotes.length > 0) {
            window.history.pushState(null, null, "/quote-search/found-quote");
          }
          else {
            window.history.pushState(null, null, "/quote-search/not-found");
          }
        }
      ),
      reduce((acc, val) => [...acc, ...(val?.items || [])], []),
      catchError(error => {
        this.isFirstRequest()
          ? this.notificationService.error(
            'Unfortunately we faced an unexpected issue providing you the requested service. Please try again later'
          )
          : null
        return of([]);
      }))
  }

  private isCountryPairSupported(): Observable<boolean> {
    const query: SupportedCountriesPairQuery = {
      fromCountryCode: this.quotesQuery.from.country,
      toCountryCode: this.quotesQuery.to.country
    };

    return this.supportedCountriesService.isCountryPairSupported(query)
  }

  private hasMoreQuotesToLoad(response: Page<Quote>): boolean {
    if (this.stopSearchQuote || response.totalCount == 0 || response.items == null) return false;

    return this.hasValidItem(response.items);
  }

  private loadMoreQuotes(responsePageNo: number): Observable<Page<Quote>> {
    this.quotesQuery.pageNo = responsePageNo + 1;
    return this.quotesService.getQuotesByQuery(this.quotesQuery);
  }

  private stopLoadQuotes(): Observable<never> {
    this.updateGoogleAnalyticsQuoteResults();

    this.isListLoading = false;
    this.updateIsFirstSearch(false);
    return EMPTY;
  }

  private hasValidItem(items: Quote[] | null): boolean {
    return items != null && items.length > 0 && items[0].id !== NIL_UUID;
  }

  private isFirstRequest(): boolean {
    return this.quotesQuery.pageNo == 1;
  }

  private fillQuoteSearchParamsIfReturningFromBooking(): void {
    if (this.isReturningFromBooking()) {
      const quotesQuery = this.quotesService.getQuotesQueryFromStorage();
      if (quotesQuery) {
        this.quotesQuery = quotesQuery;
        this.fillQuoteSearchFormFromQuotesQuery(quotesQuery);
        this.isReuse = true;
      }
    } else {
      this.quotesService.removeQuotesQueryFromStorage();
    }
  }

  private fillQuoteSearchFormFromQuotesQuery(quoteQuery: QuotesQuery): void {
    this.quoteSearchForm.get('vendorId').setValue(quoteQuery.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quoteQuery.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quoteQuery.shipperType);
    this.quoteSearchForm.get('from').setValue(quoteQuery.from);
    this.quoteSearchForm.get('to').setValue(quoteQuery.to);
    this.quoteSearchForm.get('fromcities').setValue(quoteQuery.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quoteQuery.to.displayName);
    this.quoteSearchForm.get('currency').setValue(quoteQuery.currency);
    this.quoteSearchForm.get('fromDate').setValue(quoteQuery.fromDate);
    this.quoteSearchForm.get('incoterms').setValue(quoteQuery.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quoteQuery.isHazardous);
    this.quoteSearchForm.get('isBatteries').setValue(quoteQuery.isBatteries);
    this.quoteSearchForm.get('postcodesTo').setValue(quoteQuery.to.zipCode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quoteQuery.from.zipCode || null);
    this.quoteSearchForm.get('isShowPackageDimensions').setValue(quoteQuery?.containerCommodities?.some(x => x.height > 0
      || x.width > 0 || x.length > 0));
    this.quoteSearchForm.get('unitMeasurementType').setValue(this.getUnitMeasurementType(quoteQuery?.containerCommodities[0]?.weightUnit));
    this.quoteSearchForm.get('isWithoutDimension').setValue(quoteQuery?.isWithoutDimension);

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quoteQuery?.containerCommodities.forEach(commodity => {

      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: { value: commodity.dimensionUnit, disabled: true },
            height: commodity.height,
            length: commodity.length,
            width: commodity.width,
            dimensionValue: this.getDimensionValue(commodity.length, commodity.width, commodity.height),
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: commodity.packageVolume,
            totalDimensionValueAmount: commodity.packageVolume,
            totalVolumeAmount: commodity.volumeAmount,
            totalWeightAmount: commodity.weightAmount,
            isShowMaskedInput: this.quoteSearchForm.get('isShowPackageDimensions').value,
            chargeableWeightAmount: this.calculateChargeableWeightAmount(commodity),
            chargeableVolumeAmount: this.calculateChargeableVolumeAmount(commodity),
            selectedValue: commodity.selectedValue,
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quoteQuery?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();

    setTimeout(() => {
      this.sendQuoteData(quoteQuery);
    }, 100);
  }

  private getQuoteSearchId(): string {
    return this.quotes && this.quotes[0]?.quoteSearchId ? this.quotes[0].quoteSearchId : this.quotesQuery.searchId;
  }

  private filterCreditQuotes(quotes: Quote[]): Quote[] {
    const hasContractRate = (tripLegBase: ExtenderTripLegBase | undefined): boolean => {
      return (
        tripLegBase?.legs?.some(leg =>
          leg.rates?.some(rate => rate.freightRate?.tariff?.toLowerCase().includes('contract'))
        ) ?? false
      );
    };

    const hasContractTariff = quotes.some(
      quote =>
        hasContractRate(quote.trip.preCarriageTripLeg) ||
        hasContractRate(quote.trip.mainCarriageTripLeg) ||
        hasContractRate(quote.trip.postCarriageTripLeg)
    );

    if (hasContractTariff) {
      return quotes.filter(
        quote =>
          hasContractRate(quote.trip.preCarriageTripLeg) ||
          hasContractRate(quote.trip.mainCarriageTripLeg) ||
          hasContractRate(quote.trip.postCarriageTripLeg)
      );
    }

    return quotes;
  }

  private updateGoogleAnalyticsQuoteResults(): void {
    let partialSchedule = 'Not Found';
    let partialDoor = 'Not Found';
    if (this.quotes?.length > 0) {
      partialSchedule = this.isQuotesResultsHasSchedule() ? 'Found' : 'Not Found';
      partialDoor = this.isPartialPriceQuotesResults() ? 'Partial' : 'Found';
    }
    this.googleAnalyticsService.quotesResults(partialSchedule, partialDoor, this.quotes?.length ?? 0);
  }

  private isPartialPriceQuotesResults(): boolean {
    return this.quotes.some(quote => quote.trip.isPreCarriageMissingRate || quote.trip.isPostCarriageMissingRate);
  }

  private isQuotesResultsHasSchedule(): boolean {
    return this.quotes.some(
      quote => this.utilityService.isNotNullOrMinDateValue(quote.departureDate) && this.utilityService.isNotNullOrMinDateValue(quote.arrivalDate)
    );
  }

  private sendQuoteData(quote: any): void {
    this.quotesService.sendQuoteData.next(quote);
  }

  private isReturningFromBooking(): boolean {
    let isFromSearchButton = false;
    const previousUrl = this.previousRouteService.getPreviousUrl();
    this.quotesService.isFromSearchButton$.subscribe((isFromSearchButton$: boolean) => {
      if (isFromSearchButton$)
        isFromSearchButton = isFromSearchButton$;
    });

    if (previousUrl && previousUrl?.includes('booking') && !isFromSearchButton) {
      this.quotesService.isFromSearchButton$.next(false);
      return true;
    }
    return false;
  }

  private filterPartialQuotes(quotes: Quote[]): Quote[] {
    const hasFullRate = quotes.some(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate);

    return hasFullRate
      ? quotes.filter(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate)
      : quotes;
  }

  private getWidgetType(): void {
    const widget = this.route.snapshot.queryParams.widget;

    if (widget) {
      const widgetType = Number.parseInt(widget, 10) as ShipmentType;
      if (!Number.isNaN(widgetType)) {
        this.shipmentype = widgetType;
      }
    }
  }

  private getDimensionValue(length: number, width: number, height: number): string {
    if (length > 0 && width > 0 && height > 0) {
      return `${length}x${width}x${height}`;
    }
    return '';
  }

  private calculateChargeableWeightAmount(commodity: ContainerCommodity): number {
    const unitMeasurementType = this.getUnitMeasurementType(commodity.weightUnit);
    let chargeableWeightAmount = this.calculateTotalChargeableWeight(this.quoteSearchForm.get('shipmentType').value,
      unitMeasurementType,
      commodity.volumeAmount);

    if (commodity.weightAmount > chargeableWeightAmount) {
      chargeableWeightAmount = commodity.weightAmount;
    }

    return chargeableWeightAmount;
  }

  private calculateChargeableVolumeAmount(commodity: ContainerCommodity): number {
    const unitMeasurementType = this.getUnitMeasurementType(commodity.weightUnit);
    let chargeableVolumeAmount = this.calculateTotalChargeableVolume(this.quoteSearchForm.get('shipmentType').value,
      unitMeasurementType,
      commodity.weightAmount);

    if (commodity.volumeAmount > chargeableVolumeAmount) {
      chargeableVolumeAmount = commodity.volumeAmount;
    }

    return chargeableVolumeAmount;
  }

  private calculateTotalChargeableVolume(shipmentType: ShipmentType, unitMeasurementType: UnitMeasurementType, totalWeight: number): number {
    if (unitMeasurementType === UnitMeasurementType.metric) {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight / 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight / 1000 * 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight * 1728 / 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight * 166;
      }
    }
  }

  private calculateTotalChargeableWeight(shipmentType: ShipmentType, unitMeasurementType: UnitMeasurementType, totalVolume: number): number {
    if (unitMeasurementType === UnitMeasurementType.metric) {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private getUnitMeasurementType(weightUnit: WeightUnit): UnitMeasurementType {
    return weightUnit == WeightUnit.LB ? UnitMeasurementType.imperial : UnitMeasurementType.metric
  }

  private getDefaultUnitMeasurementType(): UnitMeasurementType {
    var unitMeasurementValue = this.quotesService.getUnitMeasurementFromStorage();
    if (unitMeasurementValue != undefined && unitMeasurementValue != null) {
      return unitMeasurementValue;
    }

    return UnitMeasurementType.metric;
  }

  private filterVariousTimeOptionQuotesWithinScheduledQuotes(quotes: Quote[]): Quote[] {
    const hasAtLeastOneScheduling = quotes.some(x => this.utilityService.isNotNullOrMinDateValue(x.arrivalDate) || this.utilityService.isNotNullOrMinDateValue(x.departureDate));

    return hasAtLeastOneScheduling
      ? quotes.filter(x => this.utilityService.isNotNullOrMinDateValue(x.arrivalDate) || this.utilityService.isNotNullOrMinDateValue(x.departureDate))
      : quotes;
  }

  private filterQuotesWithNotNegativePrice(quotes: Quote[]): Quote[] {
    return quotes.filter(x => x.trip.mainCarriageTripLeg.legs.some(leg => leg.rates.some(rate => rate.freightRate.shipmentRate.totalAmount >= 0)))
  }

  private setFromDate(): Date {
    var minSelectableDate = new Date();
    minSelectableDate.setDate(new Date().getDate() + (this.getEnviromentMinAllowedShippingDate() + 1));
    if (minSelectableDate.getDay() == 0) {
      minSelectableDate.setDate(minSelectableDate.getDate() + 1);
    }
    else if (this.quoteSearchForm.get('shipmentType').value == ShipmentType.LCL && minSelectableDate.getDay() == 6) {
      minSelectableDate.setDate(minSelectableDate.getDate() + 2);
    }

    return minSelectableDate;
  }

  private getEnviromentMinAllowedShippingDate(): number {
    switch (this.quoteSearchForm.get('shipmentType').value) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMinAllowedDepartureDate ?? environment.air.minAllowedShippingDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMinAllowedDepartureDate ?? environment.fcl.minAllowedShippingDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMinAllowedDepartureDate ?? environment.lcl.minAllowedShippingDate);
      default:
        return 0;
    }
  }

  private getPriceTrendInfo(): void {
    var priceTrendModelQuery = {
      fromUnLocode: this.quotesQuery.from.unLocode,
      toUnLocode: this.quotesQuery.to.unLocode,
      fromLocationType: this.quotesQuery.from.locationType,
      toLocationType: this.quotesQuery.to.locationType,
      shipmentType: this.quotesQuery.shipmentType,
      equipments: this.quotesQuery.equipments
    } as PriceTrendModelQuery;

    this.priceHistoryChartService.getPriceTrendsByQuery(priceTrendModelQuery)
      .subscribe(
        (priceTrend: PriceTrend) => {
          this.priceTrendInfo = priceTrend;
          this.priceTrendInfo.percentages = Math.abs(this.priceTrendInfo.percentages);
        },
        error => this.notificationService.error(error)
      );
  }

  private observeRecentQuotes(): void {
    this.recentQuotesSubscription = this.quotesService.useRecentQuoteSearch.subscribe(x => {
      if (!x) return;

      this.fillRecentQuoteSearch(x);
    })
  }

  private fillRecentQuoteSearch(quoteSearch: QuoteSearch): void {
    debugger
    this.isReuse = true;
    this.isRecentQuotesSearch = true;
    this.fillQuoteSearchForm(quoteSearch);
    this.quotesService.searchQuotesByQuoteSearch(null);
  }

  private getIsFirstSearch(): void {
    const isFirstSearchFromStorage = this.quotesService.getIsFirstSearchFromStorage(this.userSettings.getSettings().userId, this.settingsService.getSettings().organizationId);

    if (isFirstSearchFromStorage != null)
      this.isFirstSearch = isFirstSearchFromStorage;
    else {
      const query = this.prepareQueryModel();
      this.activitiesService.getRecentQuoteSearchesByUserId(query)
        .subscribe(x => {
          this.updateIsFirstSearch(!x.length)
        })
    }
  }

  private updateIsFirstSearch(value: boolean): void {
    this.isFirstSearch = value;
    this.quotesService.updateIsFirstSearchInStorage(value, this.userSettings.getSettings().userId, this.settingsService.getSettings().organizationId);
  }

  private prepareQueryModel(): RecentQuoteSearchesByUserIdQuery {
    const query = new RecentQuoteSearchesByUserIdQuery();

    query.pageSize = 1;
    query.pageNo = 1;
    query.userId = this.userSettings.getSettings().userId;
    query.organizationId = this.settingsService.getSettings().organizationId;

    return query;
  }

  private getDepartureDateSettings(): void {
    let settings = this.userSettings.getSettings();
    this.departureDateSettings = settings.departureDateSettings;
  }

  private convertToNumber(value: string | number | null | undefined): number | null {
    const num = value ? Number(value) : null;
    return isNaN(num) ? null : num;
  }
}
