<div class="service-cost-estimator-container" *ngIf="vendorCode === vendorsCodes.clearit">
  <ng-container [ngTemplateOutlet]='clearItTemplate'></ng-container>
  <div clearItTemplate></div>
</div>

<ng-template #clearItTemplate>
  <mat-card>
    <div class="row">
      <div class="service-cost-estimator-left-section">
        <div class="page-header">
          <h2 class="page-header-title">
            Import Cost Calculator
          </h2>
          <h4 class="page-header-sub-title">
            We help you predict your USA import duties and fees. Actual amount will be determined when cargo is cleared
            with government custom agency.
          </h4>
          <div *ngIf="serviceCostEstimatorForm">
            <div [formGroup]="serviceCostEstimatorForm">
              <div *ngFor="let commodityForm of serviceCostEstimatorForm.get('commodities')?.controls;let i = index"
                [formGroup]="commodityForm">
                <div>
                  <div class="service-cost-estimator-content pb-3">
                    <div class="d-flex justify-content-between service-cost-estimator-content-wrapper">
                      <h3 class="service-cost-estimator-subtitle">
                        Commodity {{ i + 1 }}
                      </h3>
                      <button mat-icon-button matSuffix color="warn" (click)="onDeleteCommodity(commodityForm, i)"
                        *ngIf="serviceCostEstimatorForm.get('commodities')?.controls.length !== 1">
                        <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
                      </button>
                    </div>
                    <div class="col ps-0 pb-3">
                      <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                        <mat-label>Commodity Code, HTS or description</mat-label>
                        <div class="row h-100">
                          <div class="col d-flex align-items-end">
                            <commodity-input-ungrouped formControlName="commodityObject" placeholder="Select commodity"
                              class="w-100" [pageSize]="200" [minimumLength]="3"
                              [isTouch]="commodityForm.get('commodityObject')?.touched"
                              (onSelectionChanged)="onSelectHarmonizedSystemCode($event, commodityForm)"
                              (triggerError)="handleError('Commodity code', $event)"></commodity-input-ungrouped>
                          </div>
                        </div>
                      </mat-form-field>
                      <div>
                        <mat-error class="mt-2" *ngIf="!commodityForm.get('commodityObject').valid && commodityForm.get('commodityObject')?.touched &&
                            commodityForm.get('commodityObject')?.hasError('required')">
                          <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
                        </mat-error>
                      </div>
                    </div>
                    <div class="d-flex commodity-wrapper">
                      <div class="col-2 px-0 tooltip-main-wrapper">
                        <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                          <mat-label>Quantity</mat-label>
                          <div class="row h-100">
                            <div class="col d-flex align-items-end">
                              <input matInput type="number" min="1" formControlName="totalUnits" placeholder="0"
                                (change)="onSetState(true)" appPreventNonNumeric trackInputErrors
                                (triggerError)="handleError('Quantity', $event)" controlName="Quantity"
                                (keypress)="restrictNumeric($event)" (paste)="handlePaste($event, i, 'totalUnits')" />
                            </div>
                          </div>
                          <mat-error
                            *ngIf="commodityForm.get('totalUnits')?.touched && commodityForm.get('totalUnits')?.hasError('required')"
                            class="mb-2">
                            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
                          </mat-error>
                        </mat-form-field>
                        <div class="tooltip-content-wrapper quatity">
                          <span class="tooltip-text">Select how many units you have.</span>
                        </div>
                      </div>
                      <div class="col-3 px-0 tooltip-main-wrapper">
                        <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                          <mat-label>Unit of Measure</mat-label>
                          <div class="row h-100">
                            <div class="col d-flex align-items-end">
                              <input matInput class="disabled-input" type="text" formControlName="unitOfMeasure"
                                placeholder="0" (change)="onSetState(true)" trackInputErrors
                                (triggerError)="handleError('Unit of Measure', $event)" controlName="Unit of Measure" />
                            </div>
                          </div>
                          <mat-error
                            *ngIf="commodityForm.get('unitOfMeasure')?.touched && commodityForm.get('unitOfMeasure')?.hasError('required')">
                            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
                          </mat-error>
                        </mat-form-field>
                        <div class="tooltip-content-wrapper unit-measure">
                          <span class="tooltip-text">This is the unit of measure for the selected
                            commodity. It cannot be changed.</span>
                        </div>
                      </div>
                      <div class="col-3 px-0 unit-cost-wrapper tooltip-main-wrapper">
                        <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                          <mat-label class="unit-cost-title">Unit Cost</mat-label>
                          <div class="row h-100">
                            <div class="col d-flex align-items-end align-items-center">
                              <input matInput type="number" formControlName="price" placeholder="0"
                                (change)="onSetState(true)" class="unit-cost-input" appPreventNonNumeric
                                trackInputErrors (triggerError)="handleError('Unit Cost', $event)"
                                controlName="Unit Cost" (keypress)="restrictNumeric($event)"
                                (paste)="handlePaste($event, i, 'price')" />
                              <span class="dollar-sign">$</span>
                            </div>
                          </div>
                          <mat-error
                            *ngIf="commodityForm.get('price')?.touched && commodityForm.get('price')?.hasError('required')">
                            <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
                          </mat-error>
                        </mat-form-field>
                        <div class="tooltip-content-wrapper unit-cost">
                          <span class="tooltip-text">Type the price for each unit.</span>
                        </div>
                      </div>
                      <div
                        class="col px-0 tooltip-main-wrapper select-input manufactured-in-wrapper service-country-auto-complete-wrapper">
                        <div class="row">
                          <div class="col d-flex align-items-end">
                            <app-country-autocomplete [title]="'Manufactured In'" [floatLabel]="'auto'"
                              (isTouched)="onIsTouched($event)"
                              [countryAutocompleteValue]="commodityForm.get('countryManufacture').value"
                              [countryControl]="commodityForm.get('countryManufacture')"
                              [organizationContactAction]="organizationContactAction"
                              (triggerError)="handleError('Manufactured In', $event)" controlName="Manufactured In">
                            </app-country-autocomplete>
                          </div>
                        </div>
                        <mat-error
                          *ngIf="commodityForm.get('countryManufacture')?.touched && commodityForm.get('countryManufacture')?.hasError('required')"
                          class="mt-2">
                          <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
                        </mat-error>
                        <div class="tooltip-content-wrapper select-input-service-country">
                          <span class="tooltip-text">Select where the commodity has been manufactured.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="commodity-add pt-4">
                <button mat-button class="commodity-add-button" (click)="onAddCommodity()">
                  <span matPrefix class="commodity-add-icon">
                    <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
                  </span>
                  <span class="commodity-add-title">Add Commodity</span>
                </button>
              </div>
            </div>
          </div>
          <div class="service-cost-estimator-bottom">
            <button mat-raised-button class="calculater-button button-spinner" (click)="onCalculate()"
              [disabled]="isCalculatorBtnLoading || !serviceCostEstimatorForm.valid">
              <span class="button-inner">
                Calculate
                <mat-spinner diameter="25" *ngIf="isCalculatorBtnLoading"></mat-spinner>
              </span>
            </button>
          </div>
          <div class="fees-estimation-wrapper" *ngIf="isShowCalculationResults">
            <div class="total-summary-title">
              Duties Fee Estimation
            </div>
            <div *ngIf="calculatedSummaries" class="bill-detailes-wrapper">
              <div class="d-flex justify-content-between calculater-result" *ngFor="let summary of calculatedSummaries">
                <div class="description" [matTooltip]="getDescriptionTooltip(summary?.description)">
                  {{ getDescription(summary?.description) }}</div>
                <mat-icon class="currency-section">
                  {{ summary?.amount | currencyIcon }}
                </mat-icon>
                <div class="description-value">{{ summary?.amount }}</div>
              </div>
              <div class="d-flex justify-content-between total-amount">
                <span class="total-amount-title">Total Amount</span>
                <span class="description-value"> {{ totalAmount }} </span>
              </div>
            </div>
            <div *ngIf="!isShowCalculateSummaries && isShowCalculationResults">
              <div class="col-auto">
                <button *ngIf="!isTotalAmountZero()" class="hide-show-response" (click)="onShowCalculateSummaries()">
                  Show Details
                </button>
              </div>
            </div>
            <div *ngIf="isShowCalculateSummaries">
              <div class="col-auto">
                <button class="hide-show-response" (click)="onHideCalculateSummaries()">
                  Hide Details
                </button>
              </div>
            </div>
            <div class="d-flex" *ngIf="!isShowCalculateSummaries">
              <div>
                <i class="equipment-remove-icon s4d-icon s4d-info-light no-close"></i>
              </div>
              <span>Government agencies will determine actual customs and duties.</span>
            </div>
            <div class="service-cost-estimator-footer">
              <button mat-raised-button class="email-button d-none">
                <span>
                  Send to Email
                </span>
              </button>
              <button mat-raised-button class="close-button" (click)="onClose()">
                <span>
                  Close
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-template>