import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Variant } from '@amplitude/experiment-js-client';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { ExtenderTrip, ExtenderTripLegBase, Quote, QuotesQuery } from '../../../../desktop/quotes/quotes.model';
import { RollupDialogService } from '../../../../mobile/rollup-dialog/rollup-dialog.service';
import {
  ShipmentType,
  QuotesResultsSortBy,
  ShipperType,
  LocationType,
  SearchType,
  QuotesResultsFilterBy,
  RateServiceType
} from '../../../../shared/shared.model';
import { QuoteSearchFlowFilterComponent } from './flow-filter/flow-filter.component';
import { DashboardService } from '../../../../shared/widgets/dashboard.service';
import { QuotesService } from '../../../../desktop/quotes/quotes.service';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { amplitudeFlagVariants, amplitudeFlags } from '../../../../shared/amplitude/amplitude.constants';
import { AmplitudeService } from '../../../../shared/amplitude/amplitude.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-search-flow-results',
  templateUrl: './flow-results.component.html',
  styleUrls: ['./flow-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowResultsComponent implements OnInit {
  @Input() quotes: Quote[];
  @Input() showD2dAndAir: boolean;
  @Input() shipmentType: ShipmentType;
  @Input() isListLoading: boolean;
  @Input() isLoading: boolean;
  @Input() quotesQuery: QuotesQuery;
  @Output() backEvent = new EventEmitter();
  @Output() shipperTypeChanged = new EventEmitter();

  filteredQuotes: Quote[] = [];
  selectedSortBy: QuotesResultsSortBy = this.environmentService.environment.isQuoteBadgeEnabled
    ? QuotesResultsSortBy.recommended
    : QuotesResultsSortBy.cheapestFirst;
  selectedFilteredBy: QuotesResultsFilterBy = QuotesResultsFilterBy.all;
  cheapestQuoteIds: string[] = null;
  recommendedQuoteIds: string[] = null;
  earliestQuoteIds: string[] = null;
  nearestQuoteId: string = null;
  shortestTransitTimeQuoteIds: string[] = null;
  specialService: { [id: string]: string[] } = {};
  selectedIndex = -1;
  distantFuture = new Date(8640000000000000);
  shipperType: ShipperType;
  shipmentTypes = ShipmentType;
  rateServiceType = RateServiceType;
  isDeliveryOnDestinationRemoved = false;
  isPickupAtOriginRemoved = false;
  isShowScheduleNotAvailableInfoBox: boolean = false;
  currentPanelName: string;
  isCreditOrganization: boolean = false;

  get allFirstSearch(): boolean {
    return this.quotes.every(x => x.firstSearch);
  }

  get isDeliveryOnDestination(): boolean {
    return this.quotesQuery.to.locationType == LocationType.townCity;
  }

  get isPickupAtOrigin(): boolean {
    return this.quotesQuery.from.locationType == LocationType.townCity;
  }

  get titleForAlternative(): SafeHtml {
    const fromDate = this.quotesQuery.fromDate;
    const date = new Date(fromDate);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return `We couldn't find a quote for <span class='font-500'>${formattedDate}<span>`;
  }

  get isQuotesBannerEnabled(): boolean {
    return this.amplitudeService.checkQuotesBannerEnabled() && this.getIsQuotesBannerEnabled() && this.shipmentType === ShipmentType.FCL && !this.isCreditOrganization;
  }

  quoteRowVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowFlagKey];
  quoteRowVariant: Variant;
  quoteRowSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL, ShipmentType.AIR];

  quoteRowDetailsVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowDetailsFlagKey];
  quoteRowDetailsVariant: Variant;
  quoteRowDetailsSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL, ShipmentType.AIR];

  quoteRowPriceVariants = amplitudeFlagVariants[amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey];
  quoteRowPriceVariant: Variant;
  quoteRowPriceSupportedShipmentTypes = [ShipmentType.FCL, ShipmentType.LCL];

  ShipmentTypes = ShipmentType;

  constructor(
    private rollupDialogService: RollupDialogService,
    private dashboardService: DashboardService,
    private quotesService: QuotesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private amplitudeService: AmplitudeService,
    private environmentService: EnvironmentService,
    private utilityService: UtilityService,
    private settingsService: AppSettingsService
  ) { }

  ngOnInit(): void {
    this.quoteRowVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowFlagKey);
    this.quoteRowDetailsVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowDetailsFlagKey);
    this.quoteRowPriceVariant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey);

    this.shipperType = this.quotesQuery.shipperType;
    this.selectedSortBy = this.environmentService.environment.isQuoteBadgeEnabled
      ? QuotesResultsSortBy.recommended
      : QuotesResultsSortBy.cheapestFirst;
    this.getListOfQuotes();

    if (!this.isLoading) {
      this.setSpecialServices();

      if (this.quotesQuery.isAlternative) {
        this.quotes = this.getSelectedQuotes();
      }
    }

    this.isCreditOrganization = this.settingsService.getSettings()?.isCreditOrganization;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading?.currentValue) {
      this.setSpecialServices();

      if (this.quotesQuery.isAlternative) {
        this.quotes = this.getSelectedQuotes();
      }
    }

    if (changes.quotes) {
      this.getListOfQuotes();
      this.recalculateIsShowScheduleNotAvailableInfoBox();
    }
  }

  onChangeSelection(index: number): void {
    this.currentPanelName = null;
    this.selectedIndex = index === this.selectedIndex ? -1 : index;
  }

  onShowFilter(): void {
    const data = {
      sortBy: this.selectedSortBy,
      shipperType: this.shipperType,
      selectedFilteredBy: this.selectedFilteredBy,
      isDoorPickup: !this.isPickupAtOriginRemoved,
      isDoorDelivery: !this.isDeliveryOnDestinationRemoved,
      isPickupAtOrigin: this.isPickupAtOrigin,
      isDeliveryOnDestination: this.isDeliveryOnDestination
    };

    const dialogRef = this.rollupDialogService.open(QuoteSearchFlowFilterComponent, data);

    dialogRef.subscribe(data => {
      if (data !== undefined && data != null) {
        if (data.shipperType != this.shipperType) {
          this.shipperType = data.shipperType;
          this.onShipperTypeChanged(data.shipperType);
        }

        if (data.isDoorPickup != !this.isPickupAtOriginRemoved) {
          this.isPickupAtOriginRemoved = data.isDoorPickup;
          this.addHideDoorDelivery(true);
        }

        if (data.isDoorDelivery != !this.isDeliveryOnDestinationRemoved) {
          this.isDeliveryOnDestinationRemoved = data.isDoorDelivery;
          this.addHideDoorDelivery(false);
        }

        if (data.filteredBy != this.selectedFilteredBy) {
          this.selectedFilteredBy = data.filteredBy;
          this.filterQuotesList(true);
        }

        if (data.sortBy != this.selectedSortBy) {
          this.selectedSortBy = data.sortBy;
          this.environmentService.environment.isQuoteBadgeEnabled
            ? this.sortQuotesList(true)
            : this.oldSortQuotesList(false);
        }
      }
    });
  }

  getSpecialService(quotes: Quote): string[] {
    const services = [];

    if (this.recommendedQuoteIds?.includes(quotes.id)) {
      services.push('recommended');
    } else {
      if (this.cheapestQuoteIds?.includes(quotes.id)) {
        services.push('cheapest');
      }
      if (this.shortestTransitTimeQuoteIds?.includes(quotes.id)) {
        services.push('fastest');
      }
    }

    return services.length ? services : null;
  }

  private setSpecialServices(): void {
    if (this.quotes.length === 0) return;

    if (!this.isPriceTagsEnabled()) return;

    // Step 1: Identify the cheapest quotes
    const cheapestQuotes = this.quotes.reduce(
      (previous, current) => {
        if (current.accumulatePrice < previous[0].accumulatePrice) {
          return [current];
        } else if (current.accumulatePrice === previous[0].accumulatePrice) {
          previous.push(current);
        }
        return previous;
      },
      [this.quotes[0]]
    );
    this.cheapestQuoteIds = cheapestQuotes.map(q => q.id);

    // Step 2: Identify the earliest departure date quotes
    const quotesWithValidDates = this.quotes.filter(x => this.utilityService.isNotNullOrMinDateValue(x.departureDate));
    const earliestQuotes =
      quotesWithValidDates.length > 0
        ? quotesWithValidDates.reduce(
          (previous, current) => {
            if (new Date(current.departureDate) < new Date(previous[0].departureDate)) {
              return [current];
            } else if (new Date(current.departureDate).getTime() === new Date(previous[0].departureDate).getTime()) {
              previous.push(current);
            }
            return previous;
          },
          [quotesWithValidDates[0]]
        )
        : [];
    this.earliestQuoteIds = earliestQuotes.map(q => q.id);

    // Step 3: Identify the quotes with the shortest transit time
    const shortestTransitTime = Math.min(...this.quotes.map(q => q.estimatedDuration));
    const shortestTransitTimeQuotes = this.quotes.filter(q => q.estimatedDuration === shortestTransitTime);
    this.shortestTransitTimeQuoteIds = shortestTransitTimeQuotes.map(q => q.id);

    // Step 4: Determine the recommended quote using weighted scoring
    const quoteScores = new Map<string, number>();
    const weights = {
      cheapest: 3,
      earliest: 1,
      fastest: 2
    };

    this.quotes.forEach(quote => {
      let score = 0;
      if (this.cheapestQuoteIds.includes(quote.id)) score += weights.cheapest;
      if (this.earliestQuoteIds.includes(quote.id)) score += weights.earliest;
      if (this.shortestTransitTimeQuoteIds.includes(quote.id)) score += weights.fastest;
      quoteScores.set(quote.id, score);
    });

    const maxScore = Math.max(...quoteScores.values());
    const topQuotes = Array.from(quoteScores.entries())
      .filter(([_, score]) => score === maxScore)
      .map(([id, _]) => id);

    // Step 5: Tie-breaking logic to ensure only one recommended quote
    let recommendedQuoteId: string | null = null;
    if (topQuotes.length > 1) {
      recommendedQuoteId = topQuotes.reduce((bestQuoteId, currentQuoteId) => {
        const bestQuote = this.quotes.find(q => q.id === bestQuoteId);
        const currentQuote = this.quotes.find(q => q.id === currentQuoteId);

        if (bestQuote && currentQuote) {
          // Prefer the cheapest, then the earliest, and then the fastest in that order.
          if (currentQuote.accumulatePrice < bestQuote.accumulatePrice) return currentQuoteId;
          if (currentQuote.accumulatePrice === bestQuote.accumulatePrice) {
            if (new Date(currentQuote.departureDate) < new Date(bestQuote.departureDate)) return currentQuoteId;
            if (new Date(currentQuote.departureDate) === new Date(bestQuote.departureDate)) {
              if (currentQuote.estimatedDuration < bestQuote.estimatedDuration) return currentQuoteId;
            }
          }
        }
        return bestQuoteId;
      }, topQuotes[0]);
    } else if (topQuotes.length === 1) {
      recommendedQuoteId = topQuotes[0];
    }

    this.recommendedQuoteIds = recommendedQuoteId ? [recommendedQuoteId] : [];

    // Step 6: Determine the nearest quote
    if (this.quotesQuery.isAlternative) {
      this.nearestQuoteId = this.getNearestQuote(this.quotes).id;
    }

    // Step 7: Assign special service labels
    this.specialService = {};
    this.quotes.forEach(quote => {
      this.specialService[quote.id] = [];
      if (this.cheapestQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('cheapest');
      }
      if (this.earliestQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('earliest');
      }
      if (this.shortestTransitTimeQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('fastest');
      }
      if (this.recommendedQuoteIds.includes(quote.id)) {
        this.specialService[quote.id].push('recommended');
      }
    });
  }

  private getListOfQuotes(): void {
    this.filteredQuotes = this.getQuotesCopy();
    this.filterQuotesList(true);

    for (const quote of this.filteredQuotes) {
      if (this.isPickupAtOriginRemoved) {
        quote.trip.preCarriageTripLeg = null;
      }
      if (this.isDeliveryOnDestinationRemoved) {
        quote.trip.postCarriageTripLeg = null;
      }

      quote.accumulatePrice = this.calculateAccumulatePrice(quote.trip);
    }
    const sortBy = this.dashboardService.getSettings()?.quotesResultsSortBy;
    if (sortBy !== undefined && sortBy !== null) {
      this.selectedSortBy = sortBy;
    }

    this.environmentService.environment.isQuoteBadgeEnabled
      ? this.sortQuotesList(false)
      : this.oldSortQuotesList(false);
  }

  getSortedQuotes(criteria: string[]): Quote[] {
    return this.quotes.slice().sort((a, b) => this.sortQuotes(a, b, criteria));
  }

  sortQuotes(a: Quote, b: Quote, criteria: string[]): number {
    for (const criterion of criteria) {
      let comparison = 0;
      switch (criterion) {
        case 'price':
          comparison = a.accumulatePrice - b.accumulatePrice;
          break;
        case 'duration':
          comparison = a.estimatedDuration - b.estimatedDuration;
          break;
        case 'departureDate':
          comparison = new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime();
          break;
        default:
          break;
      }
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  }

  sortQuotesList(isUserSelected: boolean, sortedBy: QuotesResultsSortBy = null): void {
    sortedBy = sortedBy ?? this.selectedSortBy;

    switch (sortedBy) {
      case QuotesResultsSortBy.recommended:
        this.filteredQuotes.sort((a, b) => this.sortQuotes(a, b, ['price', 'duration', 'departureDate']));
        break;

      case QuotesResultsSortBy.cheapestFirst:
        this.filteredQuotes.sort((a, b) => this.sortQuotes(a, b, ['price', 'duration', 'departureDate']));
        break;

      case QuotesResultsSortBy.fastestFirst:
        this.filteredQuotes.sort((a, b) => this.sortQuotes(a, b, ['duration', 'departureDate', 'price']));
        break;

      case QuotesResultsSortBy.earliestFirst:
        this.filteredQuotes.sort((a, b) => this.sortQuotes(a, b, ['departureDate', 'price', 'duration']));
        break;

      default:
        break;
    }

    if (isUserSelected) {
      this.dashboardService.setQuotesResultsSortBy(sortedBy);
    }
  }

  oldSortQuotesList(isUserSelected: boolean): void {
    switch (this.selectedSortBy) {
      case QuotesResultsSortBy.cheapestFirst:
        this.filteredQuotes.sort(
          (a, b) =>
            (a.accumulatePrice != null && a.accumulatePrice != 0
              ? a.accumulatePrice
              : Infinity) -
            (b.accumulatePrice != null && b.accumulatePrice != 0
              ? b.accumulatePrice
              : Infinity)
        );
        break;
      case QuotesResultsSortBy.fastestFirst:
        this.filteredQuotes.sort(
          (a, b) =>
            (a.estimatedDuration != null && a.estimatedDuration != 0
              ? a.estimatedDuration
              : Infinity) -
            (b.estimatedDuration != null && b.estimatedDuration != 0
              ? b.estimatedDuration
              : Infinity)
        );
        break;
      case QuotesResultsSortBy.earliestFirst:
        this.clearDefaultDepartureDate();
        this.filteredQuotes.sort((a, b) => {
          let dateA = a.departureDate
            ? new Date(a.departureDate)
            : this.distantFuture;
          let dateB = b.departureDate
            ? new Date(b.departureDate)
            : this.distantFuture;
          return dateA.getTime() - dateB.getTime();
        });
        break;
      default:
        break;
    }

    if (isUserSelected) {
      this.dashboardService.setQuotesResultsSortBy(this.selectedSortBy);
    }
  }

  filterQuotesList(isUserSelected: boolean): void {
    switch (this.selectedFilteredBy) {
      case QuotesResultsFilterBy.all:
        // If "All Quotes" is selected, no filter is applied
        this.filteredQuotes = this.quotes;
        this.sortQuotesList(true);
        break;

      case QuotesResultsFilterBy.basic:
        // Filter for "Basic Only" rate service type
        this.filteredQuotes = this.quotes.filter(
          quote => quote.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType === RateServiceType.Basic
        );
        this.sortQuotesList(true);
        break;

      case QuotesResultsFilterBy.standard:
        // Filter for "Standard Only" rate service type
        this.filteredQuotes = this.quotes.filter(
          quote => quote.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType !== RateServiceType.Basic
        );
        this.sortQuotesList(true);
        break;

      default:
        this.filteredQuotes = this.quotes;
        this.sortQuotesList(true);
        break;
    }

    if (isUserSelected) {
      this.dashboardService.setQuotesResultsSortBy(this.selectedSortBy);
    }
  }

  isPriceTagsEnabled(): boolean {
    return this.environmentService.environment.isPriceTagsEnabled;
  }

  setPanelName(value: string): void {
    this.currentPanelName = value;
  }

  private clearDefaultDepartureDate(): void {
    for (const quote of this.filteredQuotes) {
      if (!this.utilityService.isNotNullOrMinDateValue(quote.departureDate)) {
        quote.departureDate = null;
      }
    }
  }

  private onShipperTypeChanged(value: ShipperType): void {
    this.googleAnalyticsService.shipperTypeChanged(value);

    this.shipperTypeChanged.emit(value);
  }

  private addHideDoorDelivery(isPickupChanged: boolean): void {
    if (this.shipmentType == ShipmentType.FCL) {
      if (isPickupChanged) {
        this.isPickupAtOriginRemoved = !this.isPickupAtOriginRemoved;
      } else {
        this.isDeliveryOnDestinationRemoved = !this.isDeliveryOnDestinationRemoved;
      }

      this.filteredQuotes = this.getQuotesCopy();
      for (const quote of this.filteredQuotes) {
        if (this.isPickupAtOriginRemoved) {
          quote.trip.preCarriageTripLeg = null;
        }
        if (this.isDeliveryOnDestinationRemoved) {
          quote.trip.postCarriageTripLeg = null;
        }

        quote.accumulatePrice = this.calculateAccumulatePrice(quote.trip);
      }

      this.sortQuotesList(false);
    } else {
      if (isPickupChanged) {
        this.quotesService.searchParamChange.next(SearchType.from);
      } else {
        this.quotesService.searchParamChange.next(SearchType.to);
      }
    }
  }

  private calculateAccumulatePrice(extenderTrip: ExtenderTrip): number {
    return (
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.preCarriageTripLeg) +
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.mainCarriageTripLeg) +
      this.calculateExtenderTripLegAccumulatePrice(extenderTrip.postCarriageTripLeg)
    );
  }

  private calculateExtenderTripLegAccumulatePrice(extenderTripLeg: ExtenderTripLegBase): number | null {
    return extenderTripLeg?.legs?.length > 0
      ? extenderTripLeg.legs.reduce((accumulator: number | null, leg) => {
        const ratesSum =
          leg.rates?.reduce((sum: number, rate) => {
            const shipmentRateTotalAmount = rate.freightRate?.shipmentRate?.totalAmount ?? 0;
            const surchargeSum = rate.surcharges?.reduce(
              (s: number, surcharge) => s + (surcharge?.shipmentRate?.totalAmount ?? 0),
              0
            );
            return sum + shipmentRateTotalAmount + (surchargeSum ?? 0);
          }, 0) ?? 0;

        return accumulator != null ? accumulator + ratesSum : null;
      }, 0)
      : null;
  }

  private getQuotesCopy(): Quote[] {
    return JSON.parse(JSON.stringify(this.quotes));
  }

  private recalculateIsShowScheduleNotAvailableInfoBox(): void {
    this.isShowScheduleNotAvailableInfoBox = this.quotes.some(q => !q.departureDate || !q.arrivalDate);
  }

  private getSelectedQuotes(): Quote[] {
    const selectedQuotesIds = [];
    selectedQuotesIds.push(this.nearestQuoteId);

    const selectedQuotes = this.quotes.filter(quote => selectedQuotesIds.includes(quote.id));
    return selectedQuotes;
  }

  private getNearestQuote(quotes: Quote[]): Quote {
    const date = new Date(this.quotesQuery.fromDate ?? new Date());
    const nearestQuote = quotes.reduce((previous, current) => {
      const previousDate = new Date(previous.departureDate);
      const currentDateDiff = Math.abs(date.getTime() - previousDate.getTime());
      const currentDateDiffCurrent = Math.abs(date.getTime() - new Date(current.departureDate).getTime());
      return currentDateDiffCurrent < currentDateDiff ? current : previous;
    });

    return nearestQuote;
  }

  private getIsQuotesBannerEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isQuotesBannerEnabled;
      case 'sb':
        return environment.sb.isQuotesBannerEnabled;
      default:
        return environment.isQuotesBannerEnabled;
    }
  }
}
