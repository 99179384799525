import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingContactsService } from '../../shared/bookings/booking-contacts/booking-contacts.service';
import { AnotherReference, Commodity, Contact, ContactType, Container } from '../../shared/bookings/bookings.model';
import { BookingsService } from '../../shared/bookings/bookings.service';
import { UtilityService } from '../../shared/helper/utility.service';
import { UserInfoService } from '../../shared/services/user-info/user-info.service';
import { CurrencyCode, ShipmentType, ShipperType, VolumeUnit, WeightUnit } from '../../shared/shared.model';
import { environment } from '../../../environments/environment';
import { NoBookingDialogComponent } from '../booking/no-booking-dialog/no-booking-dialog.component';
import { NotAllowShippingInstructionsDialogComponent } from './not-allow-shipping-instructions-dialog/not-allow-shipping-instructions-dialog.component';
import { BookingShippingInstructionsService } from '../../shared/booking-shipping-instructions/booking-shipping-instructions.service';
import { ShipmentInstruction, ShipmentInstructionViewModel, shippingInstructionCheckProperties } from '../../shared/booking-shipping-instructions/booking-shipping-instructions.model';

@Component({
  selector: 'app-booking-shipping-instructions',
  templateUrl: './booking-shipping-instructions.component.html',
  styleUrls: ['./booking-shipping-instructions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingShippingInstructionsComponent implements OnInit {
  bookingId: string;
  isLoading = false;
  shipmentInstruction: ShipmentInstruction;
  shipmentTypeCode: ShipmentType;
  shipperContact: Contact = null;
  consigneeContact: Contact = null;
  notifyPartyContact: Contact = null;
  isShipperContactDisable = false;
  isConsigneeContactDisable = false;
  isNotifyPartyContactDisable = false;
  shipmentInstructionBookingForm: FormGroup;
  isViewOrganizationContacts = false;
  selectedContactType: ContactType;
  containerId: string | null = null;
  isAddAnotherRefButtonDisable = false;
  isAddCommodityButtonDisable = false;
  isSubmitLoading = false;
  isBookingSubmitted = true;
  isDoorPickup = false;
  isDoorDelivery = false;
  isShippingInstructionsSubmitted = false;
  isTermsAndConditionsConfirmed = false;

  get anotherReferencesFormArray(): FormArray {
    return this.shipmentInstructionBookingForm.get("anotherReferences") as FormArray;
  }

  get containersFormArray(): FormArray {
    return this.shipmentInstructionBookingForm.get("containers") as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private bookingShippingInstructionsService: BookingShippingInstructionsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private bookingContactsService: BookingContactsService,
    private utilityService: UtilityService,
    private bookingsService: BookingsService,
    public dialog: MatDialog,
    private userInfoService: UserInfoService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.getBookingById();
  }

  getShipperType(shipperType: ShipperType) {
    return ShipperType[shipperType];
  }

  onDeleteCommodity(result) {
    const commodities = result?.container.get("commodities") as FormArray;
    commodities.removeAt(result?.index);
  }

  onAddCommodity(container: FormGroup) {
    const commodities = container.get("commodities") as FormArray;
    commodities.push(this.createCommodityForm());
  }

  onAddAnotherReference() {
    this.anotherReferencesFormArray.push(this.createAnotherReferencesForm());
  }

  onRemoveAnotherReference(index: number) {
    this.anotherReferencesFormArray.removeAt(index);
  }

  onGenerateRefID() {
    this.shipmentInstructionBookingForm.get('customerReferenceId').setValue(this.generateReferenceId());
  }

  onViewOrganizationContact(data: any) {
    if (data !== null && data !== undefined) {
      this.isViewOrganizationContacts = data.isViewOrganizationContacts;
      this.selectedContactType = data.selectedContactType;
      this.containerId = data.containerId;
    }
  }

  onBack() {
    this.isViewOrganizationContacts = false;
  }

  onSelectedContact(selectedContact: any) {
    if (selectedContact.contactType === ContactType.collect || selectedContact.contactType === ContactType.drop) {
      this.onSelectedContactForCollectOrDrop(selectedContact);
    }
    else {
      this.getBookingContact(this.bookingId, selectedContact.bookingContactId, selectedContact.contactType);
    }
  }

  onSelectedContactForCollectOrDrop(result: any) {
    const containerId = result.containerId;
    const bookingContact = result.bookingContact;
    const container = this.containersFormArray.controls.filter(
      (x) => x.get("id").value === containerId
    );
    if (result.contactType === ContactType.collect) {
      container[0].get("collectContact").patchValue(bookingContact);
    } else if (result.contactType === ContactType.drop) {
      container[0].get("dropContact").patchValue(bookingContact);
    }
    this.onBack();
  }

  onRemoveBookingContact(data: any) {
    if (data !== null && data !== undefined) {
      if (data.selectedContactType === ContactType.shipper) {
        this.shipperContact = null;
      }
      else if (data.selectedContactType === ContactType.consignee) {
        this.consigneeContact = null;
      }
      else if (data.selectedContactType === ContactType.notifyParty) {
        this.notifyPartyContact = null;
      }
      else if (data.selectedContactType === ContactType.collect
        || data.selectedContactType === ContactType.drop) {
        const container = this.containersFormArray.controls.filter(
          (x) => x.get("id").value === data.containerId
        );
        if (data.selectedContactType === ContactType.collect) {
          container[0].get("collectContact").patchValue(null);
          container[0].get("collectContact").markAsTouched();
        } else if (data.selectedContactType === ContactType.drop) {
          container[0].get("dropContact").patchValue(null);
          container[0].get("dropContact").markAsTouched();
        }
      }
    }
  }

  onCancelChanges() {
    this.navigateToDashboard();
  }

  onSubmit() {
    this.updateShipmentInstructionBooking();
  }

  onClose(): void {
    this.navigateToDashboard();
  }

  onConfirmTermsChanged(e: boolean): void {
    this.isTermsAndConditionsConfirmed = e;
  }

  private updateShipmentInstructionBooking() {
    this.isSubmitLoading = true;
    this.markAllAsTouched();
    if (!(this.shipmentInstructionBookingForm.valid && this.isValidBookingContacts())) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      this.isSubmitLoading = false;
      return;
    }

    if (this.isTermsAndConditionsConfirmed === false) {
      this.notificationService.error('Please check terms and condition.');
      this.isSubmitLoading = false;
      return;
    }

    const shipmentInstructionBooking =
      this.shipmentInstructionBookingForm.getRawValue() as ShipmentInstructionViewModel;
    shipmentInstructionBooking.changedBy = this.userInfoService.getSettings().fullName;

    let hasSameCommodities = false;
    shipmentInstructionBooking.containers.forEach(x => {
      if (x.collectionDate) {
        x.collectionDate = this.utilityService.toIsoDateTimeString(x.collectionDate);
      }

      if (this.shipmentTypeCode !== ShipmentType.AIR && this.shipmentTypeCode !== ShipmentType.LCL) {
        hasSameCommodities = new Set(x.commodities.map(({ commodityTypeCode, packageTypeCode }) =>
          `commodityTypeCode|${commodityTypeCode}|packageTypeCode|${packageTypeCode}`)).size < x.commodities.length;
      }
    });

    if (hasSameCommodities) {
      this.notificationService
        .error('Commodities code and package type combination should be unique for container.');
      this.isLoading = false;
      return;
    }

    if (this.shipmentTypeCode == ShipmentType.FCL) {
      let condition = true;
      let count = 0;

      shipmentInstructionBooking.containers.forEach(container => {
        this.bookingsService
          .getEquipment(container.equipmentTypeCode)
          .subscribe((response: any) => {
            if (response.equipment) {
              count++;
              const maxWeight = response.equipment.payloadCapacityWeight;
              const cubicCapacity = response.equipment.cubicCapacity;

              let selectedMaxWeight = 0;
              let selectedCubicCapacity = 0;
              container.commodities.forEach(commodity => {
                selectedMaxWeight = selectedMaxWeight + Number(commodity.weight);
                selectedCubicCapacity = selectedCubicCapacity + Number(commodity.volume);
              });

              if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
                condition = false;
              }

              if (count == shipmentInstructionBooking.containers.length && condition) {
                this.bookingShippingInstructionsService
                  .updateShippingInstructionBooking(this.bookingId, shipmentInstructionBooking)
                  .subscribe(
                    (shipmentInstructionBookingResponse: ShipmentInstruction) => {
                      this.setShipmentInstructionBookingForm(shipmentInstructionBookingResponse);
                      this.notificationService.success("Shipping instructions is submitted successfully.");
                      this.setShippingInstructionsSubmittedMode();
                    },
                    (error) => this.notificationService.error(error)
                  )
                  .add(() => (this.isSubmitLoading = false));
              } else if (count == shipmentInstructionBooking.containers.length && !condition) {
                this.openNoBookingDialog();
                this.isSubmitLoading = false;
              }
            }
          });
      });
    } else {
      const maxWeight = environment.lclMaxWeight;
      const cubicCapacity = environment.lclMaxCapacity;
      var condition = true;

      shipmentInstructionBooking.containers.forEach(container => {
        container.commodities.forEach(commodity => {
          const selectedMaxWeight = commodity.weight;
          const selectedCubicCapacity = commodity.volume;
          if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
            condition = false;
          }
        })
      });

      if (condition) {
        this.bookingShippingInstructionsService
          .updateShippingInstructionBooking(this.bookingId, shipmentInstructionBooking)
          .subscribe(
            (shipmentInstructionBookingResponse: ShipmentInstruction) => {
              this.setShipmentInstructionBookingForm(shipmentInstructionBookingResponse);
              this.notificationService.success("Shipping instructions is submitted successfully.");
              this.setShippingInstructionsSubmittedMode();
            },
            (error) => this.notificationService.error(error)
          )
          .add(() => (this.isSubmitLoading = false));
      } else {
        this.openNoBookingDialog();
        this.isSubmitLoading = false;
      }
    }
  }

  private isValidBookingContacts(): boolean {
    let valid = true;
    if (this.shipperContact === null) {
      valid = false;
    }
    else if (this.consigneeContact === null) {
      valid = false;
    }
    else if (this.notifyPartyContact === null) {
      valid = false;
    }
    return valid;
  }

  private openNoBookingDialog() {
    const dialogRef = this.dialog.open(NoBookingDialogComponent, {
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {}
    });
  }

  private getBookingContact(bookingId: string, bookingContactId: string, contactType: ContactType) {
    this.bookingContactsService
      .getById(bookingId, bookingContactId)
      .subscribe((x: Contact) => {
        this.setBookingContactData(contactType, x);
        this.onBack();
      },
        error => {
          this.notificationService.error(error)
        });
  }

  private setBookingContactData(contactType: ContactType, bookingContact: Contact) {
    if (contactType === ContactType.shipper) {
      this.shipperContact = bookingContact;
      this.assignContactToCollectAndDrop(this.shipperContact);
    }
    else if (contactType === ContactType.consignee) {
      this.consigneeContact = bookingContact;
    }
    else if (contactType === ContactType.notifyParty) {
      this.notifyPartyContact = bookingContact;
    }
  }

  private assignContactToCollectAndDrop(shipperContact: Contact) {
    const shipper = { ...shipperContact };
    shipper.id = null;
    this.containersFormArray?.controls.forEach(x => {
      x.get('collectContact').patchValue(shipper);
    });
  }

  private getBookingById() {
    this.isLoading = true;
    this.bookingShippingInstructionsService
      .getShippingInstructionBooking(this.bookingId)
      .subscribe(
        (x: ShipmentInstruction) => {
          this.isBookingSubmitted = this.utilityService.isBookingSubmitted(x);
          if (!this.isBookingSubmitted) {
            this.openNotAllowShippingInstructionDialog();
          }
          else {
            this.setShipmentInstructionBookingForm(x);
          }
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private setShipmentInstructionBookingForm(shipmentInstruction: ShipmentInstruction) {
    const updateShipmentInstructionBookingModel = this.prepareShipmentInstructionBookingViewModel(shipmentInstruction);
    this.updateShipmentInstructionBookingForm(updateShipmentInstructionBookingModel);
  }

  private prepareShipmentInstructionBookingViewModel(shipmentInstructionBooking: ShipmentInstruction) {
    const updateShipmentInstructionBookingViewModel: ShipmentInstructionViewModel = new ShipmentInstructionViewModel();
    updateShipmentInstructionBookingViewModel.customerReferenceId = shipmentInstructionBooking.customerReferenceId;
    updateShipmentInstructionBookingViewModel.bolOrderNumber = shipmentInstructionBooking.bolOrderNumber;
    updateShipmentInstructionBookingViewModel.containers = shipmentInstructionBooking.containers;
    updateShipmentInstructionBookingViewModel.anotherReferences = shipmentInstructionBooking.anotherReferences;
    updateShipmentInstructionBookingViewModel.notes = shipmentInstructionBooking.notes;
    this.shipmentTypeCode = shipmentInstructionBooking.shipmentTypeCode;
    this.shipmentInstruction = shipmentInstructionBooking;
    this.shipperContact = shipmentInstructionBooking.shipperContact;
    this.consigneeContact = shipmentInstructionBooking.consigneeContact;
    this.notifyPartyContact = shipmentInstructionBooking.notifyPartyContact;
    this.isDoorPickup = this.utilityService.isDoorPickupService(shipmentInstructionBooking.serviceType);
    this.isDoorDelivery = this.utilityService.isDoorDeliveryService(shipmentInstructionBooking.serviceType);
    this.isShipperContactDisable = this.isDisabled(shippingInstructionCheckProperties.SHIPPER_CONTACT);
    this.isConsigneeContactDisable = this.isDisabled(shippingInstructionCheckProperties.CONSIGNEE_CONTACT);
    this.isNotifyPartyContactDisable = this.isDisabled(shippingInstructionCheckProperties.NOTIFY_PARTY_CONTACT);
    this.isAddCommodityButtonDisable = this.isDisabled(shippingInstructionCheckProperties.ADD_COMMODITY_BUTTON);
    this.isAddAnotherRefButtonDisable = this.isDisabled(shippingInstructionCheckProperties.ADD_ANOTHER_REF_BUTTON);
    return updateShipmentInstructionBookingViewModel;
  }

  private updateShipmentInstructionBookingForm(updateShipmentInstructionBookingModel: ShipmentInstructionViewModel) {
    this.shipmentInstructionBookingForm = this.fb.group({
      customerReferenceId: [
        {
          value: updateShipmentInstructionBookingModel?.customerReferenceId
            ? updateShipmentInstructionBookingModel.customerReferenceId
            : '',
          disabled: this.isDisabled(shippingInstructionCheckProperties.CUSTOMER_REFERENCE)
        },
        Validators.required
      ],
      bolOrderNumber: [
        {
          value: updateShipmentInstructionBookingModel?.bolOrderNumber
            ? updateShipmentInstructionBookingModel.bolOrderNumber
            : '',
          disabled: true
        },
        Validators.required
      ],
      anotherReferences: this.fb.array([]),
      containers: this.fb.array([]),
    });
    this.setAnotherReferences(updateShipmentInstructionBookingModel.anotherReferences);
    this.setContainers(updateShipmentInstructionBookingModel.containers);
  }

  private setAnotherReferences(anotherReferences: AnotherReference[]) {
    anotherReferences.map((anotherReference) => {
      this.anotherReferencesFormArray.push(
        this.createAnotherReferencesForm(anotherReference)
      );
    });
  }

  private createAnotherReferencesForm(anotherReference?: AnotherReference) {
    return this.fb.group({
      id: [anotherReference ? anotherReference.id : null],
      name: [anotherReference ? anotherReference.name : ''],
      value: [
        {
          value: anotherReference ? anotherReference.value : '',
          disabled: true
        }
      ]
    });
  }

  private setContainers(containers: Container[]) {
    containers.map((container) => {
      this.containersFormArray.push(this.createContainerForm(container));
    });
  }

  private createContainerForm(container?: Container) {
    if (container != null && container.dropContact == null) {
      container.dropContact = this.getDropContactByConsigneeContact();
    }

    if (container != null && container.collectContact == null) {
      container.collectContact = this.getCollectContactByShipperContact();
    }

    return this.fb.group({
      id: [container ? container.id : ''],
      equipmentTypeCode: [
        container ? container.equipmentTypeCode : '',
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      cargoGrossWeight: [
        {
          value: container ? container.cargoGrossWeight : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.CARGO_GROSS_WEIGHT)
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      cargoGrossWeightUnitCode: [
        {
          value: container && container.cargoGrossWeightUnitCode ? container.cargoGrossWeightUnitCode : WeightUnit.KG,
          disabled: this.isDisabled(shippingInstructionCheckProperties.CARGO_GROSS_WEIGHT_UNIT_CODE)
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      cargoExpectedReady: [container ? container.cargoExpectedReady : null],
      collectionReference: [
        {
          value: container ? container.collectionReference : '',
          disabled: this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ?
            this.isDisabled(shippingInstructionCheckProperties.COLLECTION_REFERENCE) : false
        }
      ],
      collectionDate: [
        {
          value: container ? container.collectionDate : null,
          disabled: this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ?
            this.isDisabled(shippingInstructionCheckProperties.COLLECTION_DATE) : false
        },
        this.isDoorPickup ? Validators.required : null
      ],
      collectContact: [
        {
          value: container ? container.collectContact : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.COLLECT_CONTACT)
        },
        this.isDoorPickup ? Validators.required : null
      ],
      equipmentTypeDescription: [
        container ? container.equipmentTypeDescription : null,
      ],
      dropContact: [
        {
          value: container ? container.dropContact : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.DROP_CONTACT)
        },
        this.isDoorDelivery ? Validators.required : null
      ],
      commodities: this.fb.array(
        this.setCommodities(container.commodities ?? undefined)
      ),
      containerNumber: [
        {
          value: container ? container.containerNumber : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.CONTAINER_NUMBER)
        }],
      sealNumber: [
        {
          value: container ? container.sealNumber : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.SEAL_NUMER)
        }]
    });
  }

  private setCommodities(commodities: Commodity[]) {
    return commodities.map((commodity) => {
      return this.createCommodityForm(commodity);
    });
  }

  private createCommodityForm(commodity?: Commodity) {
    return this.fb.group({
      id: [commodity ? commodity.id : null],
      packageTypeCode: [
        {
          value: commodity && commodity.packageTypeCode ? commodity.packageTypeCode : '',
          disabled: this.isDisabled(shippingInstructionCheckProperties.PACKAGE_TYPE_CODE)
        },
        Validators.required,
      ],
      commodityDescription: [commodity ? commodity.commodityDescription : null],
      commodityTypeCode: [
        {
          value: commodity ? commodity.commodityTypeCode : 'SCTG 37210',
          disabled: this.isDisabled(shippingInstructionCheckProperties.COMMODITY_TYPE_CODE)
        },
        Validators.required,
      ],
      numberOfPackages: [
        {
          value: commodity ? commodity.numberOfPackages : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.NUMBER_OF_PACKAGES)
        },
        [Validators.required, Validators.min(1)],
      ],
      productValue: [
        {
          value: commodity ? commodity.productValue : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.PRODUCT_VALUE)
        },
        Validators.required,
      ],
      productValueCurrencyCode: [
        {
          value: commodity && commodity.productValueCurrencyCode ? commodity.productValueCurrencyCode : CurrencyCode.USD,
          disabled: this.isDisabled(shippingInstructionCheckProperties.PRODUCT_VALUE_CURRENCY_CODE)
        },
        Validators.required,
      ],
      volume: [
        {
          value: commodity ? commodity.volume : null,
          disabled: this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ?
            this.isDisabled(shippingInstructionCheckProperties.VOLUME) : false
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? [] : [],
      ],
      volumeUnitCode: [
        {
          value: commodity && commodity.volumeUnitCode ? commodity.volumeUnitCode : VolumeUnit.CBM,
          disabled: this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ?
            this.isDisabled(shippingInstructionCheckProperties.VOLUME_UNIT_CODE) : false
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      weight: [
        {
          value: commodity ? commodity.weight : null,
          disabled: this.isDisabled(shippingInstructionCheckProperties.WEIGHT, this.shipmentInstruction.shipmentTypeCode)
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      weightUnitCode: [
        {
          value: commodity && commodity.weightUnitCode ? commodity.weightUnitCode : WeightUnit.KG,
          disabled: this.isDisabled(shippingInstructionCheckProperties.WEIGHT_UNIT_CODE, this.shipmentInstruction.shipmentTypeCode)
        },
        this.shipmentInstruction.shipmentTypeCode === ShipmentType.FCL ? Validators.required : []
      ],
      marksAndNumbers: [
        {
          value: commodity ? commodity.marksAndNumbers : null,
          disabled: this.shipmentInstruction.shipmentTypeCode === ShipmentType.LCL ?
            this.isDisabled(shippingInstructionCheckProperties.MARKS_AND_NUMBERS) : false
        }
      ],
    });
  }

  private isDisabled(propertyName: string, shipmentType: ShipmentType | null = null) {
    return this.utilityService.isDisabled(propertyName, this.shipmentInstruction.statusType.code, shipmentType);
  }

  private markAllAsTouched() {
    Object.keys(this.shipmentInstructionBookingForm.controls).forEach((key) => {
      const isDisabled = this.shipmentInstructionBookingForm.controls[key].disabled;

      if (this.shipmentInstructionBookingForm.controls[key] instanceof FormControl) {
        if (!isDisabled) {
          this.shipmentInstructionBookingForm.controls[key].markAsTouched();
        }
      } else if (this.shipmentInstructionBookingForm.controls[key] instanceof FormArray) {
        (this.shipmentInstructionBookingForm.controls[key] as FormArray).controls.forEach(
          (element) => {
            if (element instanceof FormGroup) {
              Object.keys(element.controls).forEach((key) => {
                const childControl = element.get(key);
                if (childControl instanceof FormControl) {
                  if (!childControl.disabled) {
                    childControl.markAsTouched();
                  }
                } else if (childControl instanceof FormArray) {
                  (childControl as FormArray).controls.forEach((subElement) => {
                    if (subElement instanceof FormGroup) {
                      Object.keys(subElement.controls).forEach((subKey) => {
                        const subChildControl = subElement.get(subKey);
                        if (!subChildControl.disabled) {
                          subChildControl.markAsTouched();
                        }
                      });
                    }
                  });
                }
              });
            }
          }
        );
      }
    });
  }

  private openNotAllowShippingInstructionDialog() {
    const dialogRef = this.dialog.open(NotAllowShippingInstructionsDialogComponent, {
      disableClose: true,
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.navigateToDashboard();
    });
  }

  private generateReferenceId(): string {
    return this.userInfoService.getSettings().acronymName + this.datePipe.transform(new Date(), 'ddMMyymm');
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  private setShippingInstructionsSubmittedMode(): void {
    this.isShippingInstructionsSubmitted = true;
    this.isShipperContactDisable = true;
    this.isConsigneeContactDisable = true;
    this.isNotifyPartyContactDisable = true;
    this.isAddAnotherRefButtonDisable = true;
    this.isAddCommodityButtonDisable = true;
    this.shipmentInstructionBookingForm.disable();
  }

  private getDropContactByConsigneeContact() {
    if (!this.utilityService.isDoorDeliveryService(this.shipmentInstruction.serviceType) || this.consigneeContact == null)
      return null;

    const drop = { ...this.consigneeContact };
    drop.id = null;
    drop.contactTypeCode = ContactType.drop;
    return drop;
  }

  private getCollectContactByShipperContact() {
    if (!this.utilityService.isDoorPickupService(this.shipmentInstruction.serviceType) || this.shipperContact == null)
      return null;

    const collect = { ...this.shipperContact };
    collect.id = null;
    collect.contactTypeCode = ContactType.collect;
    return collect;
  }
}
