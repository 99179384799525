<div class="booking-flow-container-details" [formGroup]="containerForm">
  <div class="container-details-header mb-4">
    <div class="row m-0">
      <div class="col-auto d-flex align-items-center ps-0">
        <div class="container-order-wrapper">
          <span class="container-order">{{index}}</span>
        </div>
      </div>
      <div class="col d-flex align-items-center px-0">
        <span class="container-title">{{ getContainerTitle(containerForm) }}</span>
      </div>
      <div class="col-auto d-flex align-items-center px-0" *ngIf="containers?.controls?.length > 1">
        <div class="copy-icon-wrapper">
          <i class="s4d-icon s4d-paste copy-icon" (click)=select.open()></i>
        </div>
        <mat-select disableOptionCentering #select class="copy-dropdown" panelClass="select-panel">
          <ng-container *ngFor="let container of containers?.controls; let j = index">
            <mat-option *ngIf="j !== index - 1" [value]="container.value"
              (click)="onCopyContainerDetails(j, index - 1, containerForm)">
              Copy from container {{ j + 1 }}
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
      <div *ngIf="isToIsrael" class="hts-guide-link-wrapper col-12 d-flex justify-content-end ms-auto px-0 mt-4">
        <a href="https://shaarolami-query.customs.mof.gov.il/CustomspilotWeb/he/CustomsBook/Import/CustomsTaarifEntry"
          target="_blank">
          HTS Commodity Guide
        </a>
      </div>
    </div>
  </div>
  <div formArrayName="commodities" class="container-details-content">
    <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls;
      let i = index" [formGroup]="commodityForm" class="row m-0 container-details pb-4 mb-4" [ngClass]="
      (commodityForm.get('commodityObject')?.touched && containerForm.get('commodities')?.controls?.length > 0) ||
      (commodityForm.get('volume')?.touched && onCheckValid(commodityForm.get('volume'))) ||
      (commodityForm.get('weight')?.touched && onCheckValid(commodityForm.get('weight'))) ? 'error-box' : '' ">
      <div class="col-12 px-0">
        <div class="row m-0">
          <div class="col d-flex align-items-center px-0">
            <span class="commodity-title">Commodity {{ i + 1 }}</span>
          </div>
          <div class="col-auto d-flex align-items-center ps-3 pe-0">
            <button class="actions-icon-wrapper" [ngClass]="{ 'd-flex': trigger.menuOpen }"
              [matMenuTriggerFor]="commodityMenu" #trigger="matMenuTrigger">
              <i class="s4d-icon s4d-menu actions-icon"></i>
            </button>
            <mat-menu class="commodity-menu" #commodityMenu="matMenu">
              <button mat-menu-item (click)="onAddCommodity(containerForm, i)">
                <span>Add Commodity</span>
              </button>
              <button *ngIf="containerForm.get('commodities')?.controls?.length > 1" mat-menu-item
                (click)="onDeleteCommodity(containerForm, i)">
                <span>Remove Commodity</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-12 px-0 mt-3">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>What are you shipping?</mat-label>
              <div class="row h-100 commodity-input-container">
                <div class="col d-flex align-items-end">
                  <mat-select #commodityselect class="commodity-select" panelClass="commodity-input-container-panel"
                    [ngClass]="{'mat-select-opened':commodityselect.panelOpen}" [compareWith]="compareObjects"
                    [formControl]="commodityForm.get('commodityObject')"
                    (focus)="onFocus('Commodity Code', i + 1, 'commodityObject')"
                    (selectionChange)="onSelectCommodity($event, commodityForm, i)" disableOptionCentering
                    trackInputErrors (triggerError)="handleError('Commodity details', $event)"
                    controlName="Commodity details">
                    <mat-option class="commodity" *ngFor="let commodity of filteredCommodities" [value]="commodity"
                      title="{{commodity.description}}">
                      {{ commodity.description }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('commodityObject')?.touched &&
                  commodityForm.get('commodityObject')?.hasError('required')
                " class="booking-flow-error">
                <i class="error-icon s4d-icon s4d-info-light"></i>
                Commodity details are required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 px-0 mt-4">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Volume</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <input matInput type="number" formControlName="volume" appPreventNonNumeric placeholder="0"
                    [max]="containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxCubic"
                    (change)="onValueChanged('Volume', i + 1)" (focus)="onFocus('Volume', i + 1, 'volume')"
                    (keyup)="getVolumeWeightError()" trackInputErrors (triggerError)="handleError('Volume', $event)" />
                </div>
                <div class="col-auto d-flex align-items-center pe-2">
                  <mat-select class="unit-select" panelClass="select-panel" formControlName="volumeUnitCode"
                    (click)="onUnitSelect($event)">
                    <mat-option *ngFor="let volumeUnit of volumeUnits" [value]="volumeUnit.value">
                      {{ volumeUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('volume')?.touched &&
                  onCheckValid(commodityForm.get('volume'))
                " class="booking-flow-error">
                <i class="error-icon s4d-icon s4d-info-light"></i>
                {{getValidatorErrorKey(commodityForm.get('volume')) | errorMessage: 'Volume'}}
              </mat-error>
              <mat-hint align="start" class="hint-info">
                <i class="error-icon s4d-icon s4d-info-light"></i>Up to
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxCubic}} cbm
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 px-0 mt-4">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Weight</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <input matInput type="number" appPreventNonNumeric formControlName="weight" placeholder="0"
                    [max]="containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxWeight"
                    (focus)="onFocus('Weight', i + 1, 'weight')" (change)="onValueChanged('Weight', i + 1)"
                    (keyup)="getVolumeWeightError()" trackInputErrors (triggerError)="handleError('Weight', $event)" />
                </div>
                <div class="col-auto d-flex align-items-center pe-2">
                  <mat-select class="unit-select" formControlName="weightUnitCode" panelClass="select-panel"
                    (click)="onUnitSelect($event)">
                    <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                      {{ weightUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('weight')?.touched &&
                  onCheckValid(commodityForm.get('weight'))
                " class="booking-flow-error">
                <i class="error-icon s4d-icon s4d-info-light"></i>
                {{getValidatorErrorKey(commodityForm.get('weight')) | errorMessage: 'Weight'}}
              </mat-error>
              <mat-hint align="start" class="hint-info">
                <i class="error-icon s4d-icon s4d-info-light"></i>Up to
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxWeight}}
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxWeightUnit | weightUnitName}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="volumeWeightError">
      <mat-error class="mt-4 mb-3">
        <i class="s4d-icon s4d-info-light"></i> Selected total weight and volume can
        not be larger than selected equipment sizes.
      </mat-error>
    </div>
    <div class="row m-0 justify-content-center">
      <div class="col-auto d-flex align-items-center">
        <button mat-button class="add-commodity-button" (click)="onAddCommodity(containerForm)">
          <span matPrefix class="add-commodity-button-icon-wrapper">
            <i class="s4d-icon s4d-plus add-commodity-button-icon"></i>
          </span>
          <span class="add-commodity-button-title">Add Commodity</span>
        </button>
      </div>
    </div>
  </div>
  <div class="container-details-footer">
    <div class="row m-0">
      <div class="col px-0">
        <button mat-raised-button class="confirm-button button-spinner" [disabled]="!isValid || volumeWeightError"
          (click)="onConfirm()">
          <span class="button-inner">
            Confirm
            <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>