import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { WelcomeTutorialDialogComponent } from './welcome-tutorial-dialog/welcome-tutorial-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WelcomeTutorialService {
  dialogRef: any = null;
  timeoutId: any;

  constructor(public dialog: MatDialog, private readonly sso: ScrollStrategyOptions) {}

  openWelcomeTutorialDialog(): void {
    const dialogRef = this.dialog.open(WelcomeTutorialDialogComponent, {
      autoFocus: false,
      scrollStrategy: this.sso.noop(),
      backdropClass: 'backdropBackground',
      panelClass: 'tutorial-dialog-container'
    });
  }
}
