<div class="booking-flow-contacts">
  <div class="row m-0 pt-4">
    <div class="col-12 px-0 mb-4">
      <div class="booking-flow-contact">
        <div class="row m-0">
          <div class="col px-0 d-flex align-items-center">
            <span class="title">Contact at pickup</span>
          </div>
          <div class="col-auto pe-0" (click)="onManageContacts(contactTypes.shipper)">
            <div class="manage-contacts-icon-wrapper">
              <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <div class="col px-0">
            <div class="contact-field row m-0" [ngClass]="{'active':selectedOrganizationShipperContact}"
              (click)="onOpenSelect(contactTypes.shipper)">
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!selectedOrganizationShipperContact" class="s4d-icon s4d-person field-icon"></i>
                  <i *ngIf="selectedOrganizationShipperContact"
                    class="fi fi-{{ selectedOrganizationShipperContact?.countryCode | lowercase }} field-icon"></i>
                </div>
              </div>
              <div class="col field-inner-wrapper">
                <div class="row m-0">
                  <div class="col-12 px-0 d-flex">
                    <span class="field-placeholder">Contact at
                      Pickup (Exporter/Supplier)</span>
                  </div>
                  <div class="col-12 px-0 d-flex" *ngIf="selectedOrganizationShipperContact">
                    <span class="field-text">
                      <span class="field-text-strong">
                        {{ selectedOrganizationShipperContact?.firstName }}
                        {{ selectedOrganizationShipperContact?.lastName }}
                      </span> {{ selectedOrganizationShipperContact?.address1 ? ', ' + selectedOrganizationShipperContact?.address1 : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!isShipperContactLoading" class="s4d-icon s4d-chevron-down field-icon"></i>
                  <mat-spinner *ngIf="isShipperContactLoading" class="field-icon field-loader"
                    diameter="22"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 mb-4">
      <div class="booking-flow-contact">
        <div class="row m-0">
          <div class="col px-0 d-flex align-items-center">
            <span class="title">Contact at delivery</span>
          </div>
          <div class="col-auto pe-0" (click)="onManageContacts(contactTypes.consignee)">
            <div class="manage-contacts-icon-wrapper">
              <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <div class="col px-0">
            <div class="contact-field row m-0" [ngClass]="{'active':selectedOrganizationConsigneeContact}"
              (click)="onOpenSelect(contactTypes.consignee)">
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!selectedOrganizationConsigneeContact" class="s4d-icon s4d-person field-icon"></i>
                  <i *ngIf="selectedOrganizationConsigneeContact"
                    class="fi fi-{{ selectedOrganizationConsigneeContact?.countryCode | lowercase }} field-icon"></i>
                </div>
              </div>
              <div class="col field-inner-wrapper">
                <div class="row m-0">
                  <div class="col-12 px-0 d-flex">
                    <span class="field-placeholder">Contact at
                      Delivery (Importer)</span>
                  </div>
                  <div class="col-12 px-0 d-flex" *ngIf="selectedOrganizationConsigneeContact">
                    <span class="field-text">
                      <span class="field-text-strong">
                        {{ selectedOrganizationConsigneeContact?.firstName }}
                        {{ selectedOrganizationConsigneeContact?.lastName }}
                      </span> {{ selectedOrganizationConsigneeContact?.address1 ? ', ' + selectedOrganizationConsigneeContact?.address1 : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!isConsigneeContactLoading" class="s4d-icon s4d-chevron-down field-icon"></i>
                  <mat-spinner *ngIf="isConsigneeContactLoading" class="field-icon field-loader"
                    diameter="22"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 mb-4" *ngIf="isShowNotifyContact">
      <div class="booking-flow-contact">
        <div class="row m-0">
          <div class="col px-0 d-flex align-items-center">
            <span class="title">Contact for Notify</span>
          </div>
          <div class="col-auto pe-0" (click)="onManageContacts(contactTypes.notifyParty)">
            <div class="manage-contacts-icon-wrapper">
              <i class="s4d-icon s4d-person-two manage-contacts-icon"></i>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <div class="col px-0">
            <div class="contact-field row m-0" [ngClass]="{'active':selectedOrganizationNotifyPartyContact}"
              (click)="onOpenSelect(contactTypes.notifyParty)">
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!selectedOrganizationNotifyPartyContact" class="s4d-icon s4d-person field-icon"></i>
                  <i *ngIf="selectedOrganizationNotifyPartyContact"
                    class="fi fi-{{ selectedOrganizationNotifyPartyContact?.countryCode | lowercase }} field-icon"></i>
                </div>
              </div>
              <div class="col field-inner-wrapper">
                <div class="row m-0">
                  <div class="col-12 px-0 d-flex">
                    <span class="field-placeholder">Contact for Notify</span>
                  </div>
                  <div class="col-12 px-0 d-flex" *ngIf="selectedOrganizationNotifyPartyContact">
                    <span class="field-text">
                      <span class="field-text-strong">
                        {{ selectedOrganizationNotifyPartyContact?.firstName }}
                        {{ selectedOrganizationNotifyPartyContact?.lastName }}
                      </span> {{ selectedOrganizationNotifyPartyContact?.address1 ? ', ' + selectedOrganizationNotifyPartyContact?.address1 : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto px-0">
                <div class="field-icon-wrapper">
                  <i *ngIf="!isNotifyPartyContactLoading" class="s4d-icon s4d-chevron-down field-icon"></i>
                  <mat-spinner *ngIf="isNotifyPartyContactLoading" class="field-icon field-loader"
                    diameter="22"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto px-0 mb-4" *ngIf="!isShowNotifyContact">
      <button class="show-notify-button" (click)="onShowNotifyContact()">
        <div class="show-icon-wrapper">
          <i class="s4d-icon s4d-plus show-icon"></i>
        </div>
        Change Notified Contact
      </button>
    </div>
  </div>
  <div class="row m-0">
    <div class="col px-0">
      <button mat-raised-button class="confirm-button button-spinner" (click)="onNext()"
        [disabled]="isNextLoading || !isValid">
        <span class="button-inner">
          Next
          <mat-spinner diameter="25" *ngIf="isNextLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>
