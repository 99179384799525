import { Component, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { OrganizationsService } from "../../../desktop/organizations/organizations.service";
import { UserInfoService } from "../../../shared/services/user-info/user-info.service";
import { AccessRoleType } from "../../../shared/shared.model";
import { LayoutMode } from "../layout.model";
import { LayoutService } from "../layout.service";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnChanges {
  mode: LayoutMode;
  title: string;
  description: string;
  acronymName: string = '';
  backUrl: string = "/";
  showUser: boolean = false;
  showHome: boolean = true;
  showBack: boolean = false;
  showSwitchOrganizationMenu: boolean = true;
  hideImage: boolean = false;
  moreMenuTemplate: TemplateRef<any>;
  isManageOrganizationMenuItemAccess?: boolean = null;
  isManageContactsMenuItemAccess?: boolean = null;

  modes: any = LayoutMode;

  constructor(private router: Router,
    public location: Location,
    private layoutService: LayoutService,
    private userInfoService: UserInfoService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog) {
      this.organizationsService.acronymName.subscribe(name => {
        this.acronymName = name;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.layoutService.mode.subscribe(x => {
      this.mode = x;
      this.setUserData();
    });

    this.layoutService.toolbarShowUser.subscribe(x => {
      this.showUser = x;
      this.setUserData();
    });
    this.layoutService.toolbarShowHome.subscribe( x => this.showHome = x);
    this.layoutService.toolbarShowBack.subscribe( x => this.showBack = x);
    this.layoutService.toolbarTitle.subscribe( x => this.title = x);
    this.layoutService.toolbarDescription.subscribe(x => this.description = x);
    this.layoutService.toolbarHideImage.subscribe( x => this.hideImage = x);
    this.layoutService.toolbarBackUrl.subscribe( x => this.backUrl = x);
    this.layoutService.moreMenuTemplate.subscribe( x => this.moreMenuTemplate = x);

    const path = window.location.pathname;
    if (path == "/organizations") {
      this.showSwitchOrganizationMenu = false;
    }
  }

  manageContacts(): void {
    this.router.navigate(['/manage-contacts']);
  }

  goHome() {
    this.router.navigate(['/'], { queryParams: { c: false } });
  }

  goBack() {
    if(this.backUrl === '..') this.location.back();
    this.router.navigate([this.backUrl], { queryParams: { c: false } });
  }

  logout() {
    this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: `Are you sure to logout?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      },
    }).afterClosed().subscribe(
      data => {
        if (data.result) {
          this.router.navigate(['/logout']);
        }
      }
    );
  }

  manageOrganization(): void {
    this.router.navigate(['/manage-organization']);
  }

  onShowAllOrganizations() {
    this.router.navigate(['/organizations'], { queryParams: { r: false } });
  }

  private setUserData(){
    if(this.mode === LayoutMode.USER || this.showUser === true){
      this.hasAccessOfManageOrganizationMenuItem();
      this.hasAccessManageContactsMenuItem();
      this.acronymName = this.userInfoService.getSettings().acronymName;

      if(!this.title){
        this.title = `Hey, ${this.userInfoService.getSettings()?.fullName?.split(' ')[0]}!`;
      }

      if(!this.description){
        this.description = "Welcome Back!";
      }
    }
  }

  private hasAccessOfManageOrganizationMenuItem() {
    this.isManageOrganizationMenuItemAccess = this.userInfoService
      .isRolesSatisfied([AccessRoleType.admin, AccessRoleType.owner]) && this.mode !== LayoutMode.ORGANIZATIONS;
  }

  private hasAccessManageContactsMenuItem(): void {
    this.isManageContactsMenuItemAccess = this.userInfoService
      .isRolesSatisfied([AccessRoleType.admin, AccessRoleType.owner]);
  }
}
