import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { RequestAndPayment } from "../../../../shared/requests-and-payments/requests-and-payments.model";
import { PaymentStatusCode } from "../../../../shared/shared.model";

@Component({
  selector: "app-payment-line",
  templateUrl: "./payment-line.component.html",
  styleUrls: ["./payment-line.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentLineComponent implements OnInit {
  @Input() payment: RequestAndPayment = null;

  get totalAmount(): number {
    return (
      this.payment.amount + (this.payment.fees != null ? this.payment.fees : 0)
    );
  }

  constructor(public router: Router) { }

  ngOnInit(): void { }

  getIconColor(): string {
    switch (this.payment.statusCode) {
      case PaymentStatusCode.toBePaid:
        return "warning";
      case PaymentStatusCode.inProgress:
        "warning";
      case PaymentStatusCode.failed:
        return "danger";
      case PaymentStatusCode.paid:
        return "success";
      case PaymentStatusCode.draft:
        return "warning";
      case PaymentStatusCode.toPayOffline:
        return "warning";
      case PaymentStatusCode.toBeApproved:
        return "warning";
      case PaymentStatusCode.scheduled:
        return "warning";
      default:
        return "danger";
    }
  }

  toPayment(): void {
    this.router.navigate([
      "/payment",
      this.payment.bookingId,
      this.payment.id,
      { credit: true },
    ]);
  }
}
