import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";
import { Booking, BookingStatusCode } from "../../../shared/bookings/bookings.model";
import { BookingPaidStatusCode, BookingViewDetailsPage, CarriageStatusType, ShipmentType } from "../../../shared/shared.model";
import { StringCutterService } from "../../../shared/services/text-cutter/stringCutter.service";
import { TransactionPaymentDialogComponent } from './transaction-payment-dialog/transaction-payment-dialog.component';
import { environment } from "../../../../environments/environment";
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { UtilityService } from "../../../shared/helper/utility.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent implements OnInit {
  isLoading: boolean = false;
  isFinanceEnabled: boolean;
  BookingStatus = BookingStatusCode;
  CarriageTypes = CarriageStatusType;
  BookingPaidStatusCode = BookingPaidStatusCode;
  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  fromDisplayName: string;
  toDisplayName: string;

  @Input()
  booking: Booking;
  preCarriageStatuses = [
    BookingStatusCode.orderSubmitted
  ] as BookingStatusCode[];
  mainCarriageStatuses = [
    BookingStatusCode.draft
  ] as BookingStatusCode[];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private stringCutterService: StringCutterService,
    private userInfoService: UserInfoService,
    private utilityService: UtilityService
  ) {
    this.isFinanceEnabled = this.getIsFinanceEnabled();
  }

  ngOnInit(): void {
    this.fromDisplayName = this.stringCutterService.cutter(this.booking.fromDisplayName, 26);
    this.toDisplayName = this.stringCutterService.cutter(this.booking.toDisplayName, 26);
  }

  displayStatusIcon(carriageType: CarriageStatusType): boolean {
    if (this.mainCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return this.booking.statusType.carriageType === carriageType;
  }

  getMainCarriageIcon(): string {
    switch (this.booking.shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
      }
      default: {
        return 'ship';
      }
    }
  }

  getShipperName(booking: Booking): string {
    return booking?.shipperContact?.companyName;
  }

  getSubmitButtonText(booking: Booking): string {
    if (booking.isCanceled) {
      return 'Canceled'
    }

    if (!this.isSubmitted(booking)) {
      return 'Resume Order'
    }

    if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled && booking.hasPayments) {
      return 'Pay Order'
    }

    return 'Details'
  }

  isShowPayButton(booking: Booking): boolean {
    return !(this.isSubmitted(booking) &&
      booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid &&
      this.isFinanceEnabled &&
      booking.hasPayments &&
      this.userInfoService.checkIsAgent());
  }

  isSubmitted(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft;
  }

  onCancelBooking(bookingId: string): void {
    this.canceledBookingEvent.emit(bookingId);
  }

  onLoadingChanged(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  canBeRanked(booking: Booking): boolean {
    return booking.isCanceled || booking.statusType.code == BookingStatusCode.delivered;
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  onSubmitButton(booking: Booking): void {
    if (!this.isSubmitted(booking)) {
      this.router.navigate(['/booking', booking.id, true]);
    }
    else if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled && booking.hasPayments) {
      this.openTransactionPaymentDialog(booking);
    }
    else {
      this.router.navigate(['/booking/view', BookingViewDetailsPage.shipments, booking.id]);
    }
  }

  isTripDurationDefaultValue(tripDuration: string): boolean {
    return tripDuration == '00:00:00' || tripDuration == null;
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private openTransactionPaymentDialog(booking: Booking): void {
    const dialogRef = this.dialog.open(TransactionPaymentDialogComponent, {
      panelClass: 'transaction-payment',
      autoFocus: false,
      data: {
        booking: booking
      }
    });

    dialogRef.afterClosed().subscribe(() => { });
  }
}
