import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DecimalPipe } from '@angular/common';
import { ShipmentInstruction } from '../../../shared/booking-shipping-instructions/booking-shipping-instructions.model';
import {
  AnotherReference,
  BookingStatusCode,
  Commodity,
  Contact,
  ContactType,
  ReferenceSelectItem
} from '../../../shared/bookings/bookings.model';
import { LinearUnitNamePipe } from '../../../shared/features/linear-units/linear-unit-name.pipe';
import { UtilityService } from '../../../shared/helper/utility.service';
import {
  CarriageStatusType,
  CurrencyCode,
  HarmonizedSystemCode,
  SelectItem,
  ShipmentType,
  VolumeUnit,
  WeightUnit
} from '../../../shared/shared.model'
import { BookingCommodityDialogComponent } from '../../booking/booking-tabs/booking-commodity-dialog/booking-commodity-dialog.component';
import { VolumeUnitNamePipe } from '../../../shared/pipes/volume-unit-name.pipe';

@Component({
  selector: 'app-booking-instructions-detail',
  templateUrl: './booking-instructions-detail.component.html',
  styleUrls: ['./booking-instructions-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingInstructionsDetailComponent implements OnInit {
  @Input() shipmentInstructionBookingForm: FormGroup = undefined;
  @Input() shipmentInstruction: ShipmentInstruction;
  @Input() shipperContact: Contact;
  @Input() consigneeContact: Contact;
  @Input() notifyPartyContact: Contact;
  @Input() isShipperContactDisable = false;
  @Input() isConsigneeContactDisable = false;
  @Input() isNotifyPartyContactDisable = false;
  @Input() isAddCommodityButtonDisable = false;
  @Input() isAddAnotherRefButtonDisable = false;
  @Output() addCommodity = new EventEmitter<FormGroup>();
  @Output() removeCommodity = new EventEmitter<any>();
  @Output() addAnotherReference = new EventEmitter<AnotherReference>();
  @Output() removeAnotherReference = new EventEmitter();
  @Output() generatedRefID = new EventEmitter<string>();
  @Output() viewOrganizationContactEvent = new EventEmitter<any>();
  @Output() removeBookingContactEvent = new EventEmitter<any>();

  isShowCollectContact = false;
  isShowDropContact = false;

  shipmentTypes = ShipmentType;
  contactTypes = ContactType;
  isHideAnotherRef: boolean;
  collectContact: Contact;
  dropContact: Contact;
  CarriageTypes = CarriageStatusType;
  preCarriageStatuses = [
    BookingStatusCode.orderSubmitted
  ] as BookingStatusCode[];
  mainCarriageStatuses = [
    BookingStatusCode.draft
  ] as BookingStatusCode[];

  referenceTypes: ReferenceSelectItem[] = [{ name: 'PO Number', value: 1 }];

  weightUnits: SelectItem[] = [
    { name: WeightUnit[WeightUnit.KG], value: WeightUnit.KG },
    { name: WeightUnit[WeightUnit.LB], value: WeightUnit.LB }
  ];

  currencyCodes: SelectItem[] = [
    { name: 'USD, $', shortName: 'USD', value: CurrencyCode.USD },
    { name: 'EUR, €', shortName: 'EUR', value: CurrencyCode.EUR }
  ];

  volumeUnits: SelectItem[] = [
    { name: VolumeUnit[VolumeUnit.CBM], value: VolumeUnit.CBM },
    { name: VolumeUnit[VolumeUnit.CFT], value: VolumeUnit.CFT },
    { name: VolumeUnit[VolumeUnit.CUI], value: VolumeUnit.CUI }
  ];

  constructor(
    public dialog: MatDialog,
    private linearUnitNamePipe: LinearUnitNamePipe,
    private utilityService: UtilityService,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.collectContact = this.shipmentInstruction.containers[0].collectContact;
    this.dropContact = this.shipmentInstruction.containers[0].dropContact;
    if (this.shipmentInstruction.serviceType > 0) {
      this.isShowCollectContact = false;
      this.isShowDropContact = false;
      const doorPickup = this.utilityService.isDoorPickupService(this.shipmentInstruction.serviceType);
      const doordelivery = this.utilityService.isDoorDeliveryService(this.shipmentInstruction.serviceType);
      if (doorPickup)
        this.isShowCollectContact = true;
      if (doordelivery)
        this.isShowDropContact = true;
    }
  }

  onRemoveBookingContact(contactType: ContactType, containerId: string | null = null) {
    this.removeBookingContactEvent.emit({
      selectedContactType: contactType,
      containerId: containerId
    });
  }

  onDeleteCommodity(container: FormGroup, index: number) {
    this.removeCommodity.emit({ container, index });
  }

  onOpenComodityDialog(
    formName: FormGroup,
    commodityTypeCodeControlName: string,
    commodityDescriptionControlName: string) {
    const dialogRef = this.dialog.open(BookingCommodityDialogComponent, {
      data: {
        commodityForm: formName,
        commodityTypeCodeControlName: commodityTypeCodeControlName
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.value) {
        const commodity = result.value as HarmonizedSystemCode;
        this.setFormControlValue(result.form, commodityTypeCodeControlName, commodity.id);
        this.setFormControlValue(result.form, commodityDescriptionControlName, commodity.description);
      }
    });
  }

  setFormControlValue(form: FormGroup, key: string, value: any) {
    form.get(key).patchValue(value);
  }

  onAddCommodity(container: FormGroup) {
    this.addCommodity.emit(container);
  }

  onAddAnotherRef() {
    this.addAnotherReference.emit();
  }

  onGenerateRefID() {
    this.isHideAnotherRef = false;
    this.generatedRefID.emit();
  }

  onRemoveCustomerReference() {
    this.isHideAnotherRef = true;
    this.shipmentInstructionBookingForm.get("customerReferenceId").patchValue(null);
  }

  onRemoveAnotherRef(index: number) {
    this.removeAnotherReference.emit(index);
  }

  onChangeRefID(changedValue: any) {
    if (changedValue.target.value) {
      this.isHideAnotherRef = false;
    }
    else {
      this.isHideAnotherRef = true;
    }
  }

  renderContactData(contact: Contact) {
    let data = '';
    if (contact) {
      data = this.appendContactData(data, contact.companyName);
      data = this.appendContactData(data, contact.address1);
      data = this.appendContactData(data, contact.address2);
      data = this.appendContactData(data, contact.city);
      data = this.appendContactData(data, contact.zipCode);
      data = this.appendContactData(data, contact.state);
      data = this.appendContactData(data, contact.countryCode);
    }
    return data;
  }

  viewOrganizationContacts(contactType: ContactType, containerId: string | null = null) {
    this.viewOrganizationContactEvent.emit({
      isViewOrganizationContacts: true,
      selectedContactType: contactType,
      containerId: containerId
    });
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  displayStatusIcon(carriageType: CarriageStatusType) {
    if (this.mainCarriageStatuses.includes(this.shipmentInstruction.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(this.shipmentInstruction.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return this.shipmentInstruction.statusType.carriageType === carriageType;
  }

  getMainCarriageIcon() {
    switch (this.shipmentInstruction.shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
        break;
      }
      default: {
        return 'ship';
        break;
      }
    }
  }

  getDimensions(commodityForm: FormGroup) {
    const id = commodityForm.get('id').value;
    let commodity: Commodity = null;
    let dimension = '';

    this.shipmentInstruction.containers.forEach((x) => {
      const filteredCommodity = x.commodities.find((y) => y.id === id);
      if (filteredCommodity !== undefined) {
        commodity = filteredCommodity;
        return;
      }
    });

    if (commodity !== null && commodity.dimension) {
      if (commodity.dimension?.width != null
        && commodity.dimension?.height != null
        && commodity.dimension?.length != null) {
        dimension += `(W) ${commodity.dimension?.width}
      ${this.linearUnitNamePipe.transform(
          commodity.dimension?.widthUnitCode
        )} x `;
        dimension += `(H) ${commodity.dimension?.height}
      ${this.linearUnitNamePipe.transform(
          commodity.dimension?.heightUnitCode
        )} x `;
        dimension += `(L) ${commodity.dimension?.length}
      ${this.linearUnitNamePipe.transform(
          commodity.dimension?.lengthUnitCode
        )}`;
      }
      else {
        dimension += `${this.decimalPipe.transform(commodity.volume, '1.2-2')}
      ${this.volumeUnitNamePipe.transform(commodity.volumeUnitCode)}`
      }
    }
    return dimension;
  }

  setDescriptionPosition(status: BookingStatusCode) {
    if (this.mainCarriageStatuses.includes(status)) {
      return 'main-position';
    }

    if (this.preCarriageStatuses.includes(status)) {
      return 'pre-position';
    }

    return 'post-position';
  }

  private appendContactData(data: string, value: string) {
    if (value) {
      if (data !== '') {
        data += ', ';
      }
      data += value;
    }
    return data;
  }
}
