<div *ngIf="quote" class="flow-result">
  <div class="rate-service-type-tag-wrapper" *ngIf="isPriceTagsEnabled() && quotesQuery.shipmentType !== ShipmentTypes.AIR">
    <span class="rate-service-type-text" [ngClass]="isBasicRateServiceType() ? 'basic-rate-type' : ''"
      (click)="onRateServiceType()">{{ isBasicRateServiceType() ? "Basic" :
      "Standard" }}</span>
    <div *ngIf="specialServices?.length > 0" class="col-auto p-0">
      <div class="d-flex">
        <div *ngFor="let service of specialServices" class="special-label" [ngClass]="service">
          {{service | titlecase}}
        </div>
      </div>
    </div>
  </div>
  <div class="row g-0 m-0 flow-result-content">
    <div class="col-12" (click)="onExpandDetailRow()">
      <div class="trip-details">
        <div class="row m-0 trip">
          <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
            <div class="trip-icon-wrapper">
              <i class="trip-icon s4d-icon" [ngClass]="quote?.trip?.fromLocationTypeCode | locationTypeS4DIcon"></i>
            </div>
            <div class="trip-line"></div>
          </div>
          <div class="col ps-3 pe-0 d-flex flex-column mb-4">
            <span *ngIf="quote.departureDate && quote.arrivalDate" class="trip-date">
              {{ quote.departureDate | date : "MMM dd, yy" }}
            </span>
            <span *ngIf="!quote.departureDate || !quote.arrivalDate" class="trip-date">Schedule Not Available</span>
            <span class="trip-description mt-1">
              {{ isShowFullNames ? "Estimated Departure" : "Estimated departure" }}</span>
          </div>
        </div>
        <div class="row m-0 trip">
          <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
            <div class="trip-icon-wrapper">
              <i class="trip-icon s4d-icon" [ngClass]="getShipmentTypeIcon() | haulageTypeS4DIcon"></i>
            </div>
            <div class="trip-line"></div>
          </div>
          <div class="col ps-3 pe-0 d-flex flex-column mb-4">
            <span *ngIf="!isShowEstimatedDuration(); else estimatedDurationTemplate" class="trip-date">{{
              getEstimationText }}</span>
          </div>
        </div>
        <div class="row m-0 trip">
          <div class="col-auto px-0 d-flex flex-column justify-content-start align-items-center">
            <div class="trip-icon-wrapper">
              <i class="trip-icon s4d-icon" [ngClass]="quote?.trip?.toLocationTypeCode | locationTypeS4DIcon"></i>
            </div>
          </div>
          <div class="col ps-3 pe-0 d-flex flex-column">
            <span *ngIf="quote.arrivalDate" class="trip-date">
              {{ quote.arrivalDate | date : "MMM dd, yy" }}
            </span>
            <span *ngIf="!quote.arrivalDate" class="trip-date">Schedule Not Available</span>
            <span class="trip-description mt-1">
              {{ isShowFullNames ? "Estimated Arrival" : "Estimated arrival" }}
            </span>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="quote.arrivalDate && quote.departureDate" class="mx-3 my-2 trip-divider"></mat-divider>
      <div class="row g-0 m-0 p-3 g-12 flow-result-details" *ngIf="quote.arrivalDate && quote.departureDate">
        <ng-container *ngIf="shipmentType !== ShipmentTypes.AIR">
          <div class="row g-0 m-0" *ngIf="quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName">
            <div class="col-6 detail-subtitle">Vessel Name</div>
            <div class="col-6 text-end detail-subtitle-value">
              {{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName || 'n/a' }}
            </div>
          </div>
          <div class="row g-0 m-0" *ngIf="quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage">
            <div class="col-6 detail-subtitle">Voyage</div>
            <div class="col-6 text-end detail-subtitle-value">
              {{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage || 'n/a' }}
            </div>
          </div>
          <div class="row g-0 m-0">
            <div class="col-6 detail-subtitle">Cut-Off-Date</div>
            <div class="col-6 text-end detail-subtitle-value">
              {{ getCutOffDateTitle(quote) }}
            </div>
          </div>
        </ng-container>
        <div class="row g-0 m-0">
          <div class="col-6 detail-subtitle">{{ isBasicRateServiceType() ? "Limited Availability" : "Price Guarantee" }}
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <span class="detail-subtitle-value d-flex">
              <i class="s4d-icon" [ngClass]="isBasicRateServiceType() ? 's4d-stop-watch' : 's4d-verified-filled'"></i>
            </span>
          </div>
        </div>
      </div>
      <mat-divider class="mx-3 my-2 trip-divider"></mat-divider>
      <div class="trip-total">
        <ng-container *ngIf="quoteRowPriceTemplate" [ngTemplateOutlet]="quoteRowPriceTemplate"
          [ngTemplateOutletContext]="{quote, isShowFullNames}">
        </ng-container>
        <ng-content *ngIf="!quoteRowPriceTemplate" select="quotePrice"></ng-content>
      </div>
    </div>
    <div class="col-12" *ngIf="index === selectedIndex">
      <mat-divider class="mx-3 my-2 trip-divider"></mat-divider>
      <ng-container *ngIf="quoteDetailsTemplate" [ngTemplateOutlet]="quoteDetailsTemplate"
        [ngTemplateOutletContext]="{quote}">
      </ng-container>
      <ng-content *ngIf="!quoteDetailsTemplate" select="quoteDetails"></ng-content>
    </div>
  </div>
  <div class="row g-0 m-0 flow-result-footer" (click)="onExpandDetailRow()">
    <div *ngIf="!quote.departureDate || !quote.arrivalDate" class="col-12 mb-3">
      <app-info-box [icon]="'s4d-info-light'" [title]="'Lock in Price'"
        [description]="'Lock in your price and our team will help coordinate your exact details.'">
      </app-info-box>
    </div>
    <div class="col-12">
      <button mat-button class="trip-select-button button-spinner" (click)="onBookQuote($event, quote)"
        [disabled]="isLoading">
        <span class="button-inner">
          <span *ngIf="!isLoading">{{ isBasicRateServiceType() ? "Select" : "Lock in Price!" }}</span>
          <mat-spinner diameter="14" *ngIf="isLoading"></mat-spinner>
        </span>
      </button>
    </div>
    <div class="col-12 mt-3">
      <button mat-button class="trip-details-button">
        {{index === selectedIndex ? 'Hide Price Details': 'Show Price Details'}}
      </button>
    </div>
  </div>
</div>

<ng-template #estimatedDurationTemplate>
  <span class="estimated-type-title">{{ quote.trip.fromEstimatedDuration === quote.trip.toEstimatedDuration ? 1 :
    quote.trip.fromEstimatedDuration }} - {{ quote.trip.toEstimatedDuration }} Business Days</span>
</ng-template>
