<div class="flow-results">
  <div class="row m-0 mb-4 flow-results-header">
    <div class="col px-0 d-flex align-items-center">
      <span class="header-title">Search results</span>
    </div>
    <div class="col-auto ps-2 pe-0 d-flex align-items-center" (click)="onShowFilter()">
      <div class="header-icon-wrapper">
        <i class="header-icon s4d-icon s4d-slider-filters"></i>
      </div>
    </div>
  </div>
  <app-flow-sort-options class="row m-0 p-0 pb-4 justify-content-end"
    *ngIf="isPriceTagsEnabled() && quotesQuery.shipmentType !== shipmentTypes.AIR && !quotesQuery.isAlternative"
    [(quotes)]="filteredQuotes" (sortCriteria)="sortQuotesList(true, $event)"></app-flow-sort-options>
  <div *ngIf="quotesQuery.isAlternative" class="schedule-not-available-info-box">
    <app-info-box [icon]="'s4d-info-light'" [title]="titleForAlternative"
      [description]="'Check out our options for surrounding dates that might work for you.'"></app-info-box>
  </div>
  <div *ngIf="isShowScheduleNotAvailableInfoBox" class="schedule-not-available-info-box">
    <app-info-box [icon]="'s4d-info-light'" [title]="'Schedule Not Available.'"
      [description]="'Some quotes are missing schedule information. Lock in your price for 48 hours, and our team will contact you with the details.'"></app-info-box>
  </div>
  <div class="row m-0 flow-results-content">
    <div class="col-12 px-0">
      <ng-container *ngFor="let quote of filteredQuotes; let i = index">
        <div *ngIf="(quote.firstSearch && allFirstSearch && !isListLoading) || !quote.firstSearch"
          class="flow-result-content">
          <ng-container [ngTemplateOutlet]="quoteRowTemplate" [ngTemplateOutletContext]="{quote, i}"></ng-container>
        </div>
        <app-quotes-banner class="d-block mb-4" *ngIf="isQuotesBannerEnabled && i === 0"></app-quotes-banner>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #quoteRowTemplate let-quote="quote" let-i="i">
  <app-quote-search-flow-result [quote]="quote" [quotesQuery]="quotesQuery" [showD2dAndAir]="showD2dAndAir"
    [shipmentType]="shipmentType" [index]="i" [selectedIndex]="selectedIndex"
    (onChangeSelection)="onChangeSelection($event)" [specialServices]="getSpecialService(quote)"
    [quoteDetailsTemplate]="quoteRowRateDetailsTemplate"
    [quoteRowPriceTemplate]="quoteRowPriceTemplate"></app-quote-search-flow-result>
</ng-template>

<ng-template #quoteRowRateDetailsTemplate let-quote="quote">
  <ng-container *ngIf="quoteRowDetailsSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngSwitch]="quoteRowDetailsVariant.value">
    <ng-container *ngSwitchCase="quoteRowDetailsVariants.newRowDetailsStyle">
      <app-quote-search-flow-result-details-redesigned *ngIf="quote.trip" [quote]="quote" [quotesQuery]="quotesQuery"
        [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType" [currentPanelName]="currentPanelName"
        (setPanelName)="setPanelName($event)"></app-quote-search-flow-result-details-redesigned>
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="quoteRowRateDetailsTemplateDefault"
      [ngTemplateOutletContext]="{quote}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!quoteRowDetailsSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngTemplateOutlet]="quoteRowRateDetailsTemplateDefault" [ngTemplateOutletContext]="{quote}"></ng-container>
</ng-template>

<ng-template #quoteRowRateDetailsTemplateDefault let-quote="quote">
  <app-quote-search-flow-result-details *ngIf="quote.trip" [quote]="quote" [quotesQuery]="quotesQuery"
    [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType"></app-quote-search-flow-result-details>
</ng-template>

<ng-template #quoteRowPriceTemplate let-quote="quote" let-isShowFullNames="isShowFullNames">
  <ng-container *ngIf="quoteRowPriceSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngSwitch]="quoteRowPriceVariant?.value">
    <app-quote-search-flow-price-redesigned *ngSwitchCase="quoteRowPriceVariants.newRowPriceStyle"
      [quote]="quote"></app-quote-search-flow-price-redesigned>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="quoteRowPriceTemplateDefault"
      [ngTemplateOutletContext]="{quote, isShowFullNames}">
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!quoteRowPriceSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngTemplateOutlet]="quoteRowPriceTemplateDefault" [ngTemplateOutletContext]="{quote, isShowFullNames}">
  </ng-container>
</ng-template>

<ng-template #quoteRowPriceTemplateDefault let-quote="quote" let-isShowFullNames="isShowFullNames">
  <app-quote-search-flow-price [quote]="quote" [isShowFullNames]="isShowFullNames"></app-quote-search-flow-price>
</ng-template>
