import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Language } from "../../../shared/features/language/language.model";
import { LanguageService } from "../../../shared/features/language/language.service";

@Component({
  selector: "app-select-language",
  templateUrl: "select-language.html",
  styleUrls: ["./select-language.scss"]
})
export class SelectLanguageComponent {
  language: string;
  allLanguages: Language[];
  selectLanguageform: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SelectLanguageComponent>,
    private languageService: LanguageService
  ) {
    this.selectLanguageform = this.fb.group({
      language: []
    });
    this.allLanguages = languageService.getAllLanguages();
    this.language = this.languageService.getLanguage();
  }

  submit() {
    this.languageService.changeLanguage(this.language);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
