import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-loader-with-images",
  templateUrl: "./loader-with-images.component.html",
  styleUrls: ["./loader-with-images.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderWithImagesComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() stepTitles: string[];
  @Input() stepDuration: number;
  @Input() isImage: boolean = true;

  currentStep: number = 0;
  progress: number = 0;

  ngOnInit(): void {
    this.imageUrl = this.imageUrl ?? "../assets/images/loader/ship.svg";
    this.stepTitles = this.stepTitles ?? ['Checking Route Availability', 'Sending quote Request to Multiple Carriers', 'Waiting for carriers to share the rates'];
    this.stepDuration = this.stepDuration ?? 4.8;
    this.changeStepEvery(this.stepDuration);
  }

  changeStepEvery(seconds: number): void {
    setTimeout(() => {
      this.progress = 100 / this.stepTitles.length;
    }, 0);

    setInterval(() => {
      if (this.currentStep + 1 < this.stepTitles.length) {
        this.progress = (100 / this.stepTitles.length) * (this.currentStep + 2);
        var currentElement = document.getElementById(
          "step-" + this.currentStep
        );
        currentElement?.classList.add("d-none");
        var nextElement = document.getElementById(
          "step-" + (this.currentStep + 1)
        );
        nextElement?.classList.remove("d-none");
        this.currentStep += 1;
      }
    }, seconds * 1000);
  }

  isCurrentStep(index: number): boolean {
    return index === this.currentStep;
  }
}
