<div class="dialog-container">
  <div class="row">
    <div class="col-12 text-center">
      <mat-dialog-content class="dialog-content">
        <div class="dialog-message-wrapper">
          <mat-spinner diameter="75"></mat-spinner>
        </div>
        <div class="dialog-message-wrapper">
          <span class="dialog-message">
            <ng-container>
              <div class="dialog-content-title">
                <span>
                  Give Us A Second
                </span>
              </div>
              <div>
                <span>
                  We are looking for available payment providers for you.
                </span>
              </div>
            </ng-container>
          </span>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  <div class="row text-center mt-2 justify-content-md-center dialog-footer">
    <div>
      <span class="dialog-footer-title">Powered by</span>
      <img src="./../../../../assets/images/fourty-seas.svg" alt="">
    </div>
  </div>