<div class="dialog-content" *ngIf="data?.organization">
  <form [formGroup]="organizationForm" class="organization-create-form mb-auto mt-0 text-center"
    (ngSubmit)="onSubmit()">
    <span class="checklist-icon"></span>
    <div class="organization-missing-data-text-wrapper text-center my-4">
      <h3 class="mb-2">Just One More Step</h3>
      <span>
        To complete your booking, please provide your company's legal name and tax ID.
      </span>
    </div>
    <div class="col-12 mt-2 text-start">
      <app-company-search class="w-100" [legalNameControl]="organizationForm.get('legalName')"
        [organization]="data.organization" [placeholder]="'Organization Legal name'" [titleText]="'Organization Legal Name'"
        (input)="onInputCheckIsStartedTyping(organizationForm.get('legalName'), LegalNamePopupFields.legalName)"
        (focusin)="focusInCheckError(organizationForm.get('legalName'), LegalNamePopupFields.legalName)"
        (focusout)="focusOutCheckError(organizationForm.get('legalName'), LegalNamePopupFields.legalName)"></app-company-search>
    </div>
    <div class="col-12 mt-4 text-start">
      <app-tax-id-input class="w-100" [taxIdControl]="organizationForm.get('taxId')"
        [countryCode]="data.organization.countryCode" floatLevel="never" [placeholder]="'Tax identifier'"
        [titleText]="'Tax Identifier'"
        (input)="onInputCheckIsStartedTyping(organizationForm.get('taxId'), LegalNamePopupFields.taxId)"
        (focusin)="focusInCheckError(organizationForm.get('taxId'), LegalNamePopupFields.taxId)"
        (focusout)="focusOutCheckError(organizationForm.get('taxId'), LegalNamePopupFields.taxId)"></app-tax-id-input>
    </div>
  </form>
  <div class="row text-center justify-content-md-center align-self-stretch dialog-footer">
    <button [disabled]="isLoading || !organizationForm.valid" mat-raised-button
      class="dialog-button button-spinner confirm-button col" (click)="onSubmit()">
      <span class="button-inner">
        Submit
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </button>
  </div>
</div>
