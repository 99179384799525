import { Pipe, PipeTransform } from '@angular/core';
import { LocationType } from '../shared.model';

@Pipe({ name: 'locationTypeShortName' })
export class LocationTypeShortNamePipe implements PipeTransform {
  private locationTypeShortNames = {};

  constructor() {
    this.locationTypeShortNames[LocationType.townCity] = 'Door';
    this.locationTypeShortNames[LocationType.airPort] = 'AirPort';
    this.locationTypeShortNames[LocationType.seaPort] = 'SeaPort';
    this.locationTypeShortNames[LocationType.railwayTerminal] = 'Inland port';
    this.locationTypeShortNames[LocationType.containerTerminal] = 'Warehouse(cfs)';
  }

  transform(value?: LocationType): string {
    return this.locationTypeShortNames[value] ?? '';
  }
}
