import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { register } from 'swiper/element/bundle';
import { DragDropModule } from '@angular/cdk/drag-drop';
register();

import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { SelectLanguageComponent } from './language/select-language/select-language';
import { LanguagePickerComponent } from './language/language-picker/language-picker.component';
import { LanguagePopUpService } from './language/language-pop-up.service';
import { ShipmentsComponent } from './shipments/shipments.component';
import { LayoutComponent } from './layout/layout.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { ContentComponent } from './layout/content/content.component';
import { OrdersComponent } from './shipments/orders/orders.component';
import { OrderComponent } from './shipments/orders/order/order.component';
import { LayoutService } from './layout/layout.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationsListComponent } from './organizations/organizations-list/organizations-list.component';
import { OrganizationLineComponent } from './organizations/organization-line/organization-line.component';
import { QuotesService } from '../desktop/quotes/quotes.service';
import { Ship4wdNgxManagerUIModule } from '@ship4wd/ngx-manager-ui';
import { BookingStepsComponent } from './booking/booking-steps/booking-steps.component';
import { BookingComponent } from './booking/booking.component';
import { BookingReferenceComponent } from './booking/booking-steps/booking-reference/booking-reference.component';
import { BookingContactsComponent } from './booking/booking-steps/booking-contacts/booking-contacts.component';
import { BookingContainersComponent } from './booking/booking-steps/booking-containers/booking-containers.component';
import { BookingDocumentsComponent } from './booking/booking-steps/booking-documents/booking-documents.component';
import { BookingSummaryComponent } from './booking/booking-steps/booking-summary/booking-summary.component';
import { BookingIconsComponent } from './booking/booking-icons/booking-icons.component';
import { BookingOrganizationContactsComponent } from './booking/booking-steps/booking-contacts/booking-organization-contacts/booking-organization-contacts.component';
import { CreateBookingOrganizationContactComponent } from './booking/booking-steps/booking-contacts/create-booking-organization-contact/create-booking-organization-contact.component';
import { BookingCommodityDialogComponent } from './booking/booking-steps/booking-commodity-dialog/booking-commodity-dialog.component';
import { ViewBookingComponent } from './booking/view-booking/view-booking.component';
import { BookingShippingInstructionsComponent } from './booking-shipping-instructions/booking-shipping-instructions.component';
import { BookingInstructionsDetailComponent } from './booking-shipping-instructions/booking-instructions-detail/booking-instructions-detail.component';
import { BookingFilesDocumentsComponent } from './booking-files-documents/booking-files-documents.component';
import { BookingFilesDocumentsListComponent } from './booking-files-documents/booking-files-documents-list/booking-files-documents-list.component';
import { CommentsDialogComponent } from './booking-files-documents/booking-files-documents-list/comments-dialog/comments-dialog.component';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { OrganizationDetailComponent } from './manage-organization/organization-detail/organization-detail.component';
import { OrganizationMembersComponent } from './manage-organization/organization-members/organization-members.component';
import { OrganizationMemberComponent } from './manage-organization/organization-members/organization-member/organization-member.component';
import { RoutesService } from '../desktop/routes/routes.service';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { ConfirmPaymentComponent } from './payments/confirm-payment/confirm-payment.component';
import { FinanceComponent } from './finance/finance.component';
import { OrganizationCreateComponent } from './organizations/organization-create/organization-create.component';
import { ManageContactsComponent } from './manage-contacts/manage-contacts.component';
import { ManageContactComponent } from './manage-contacts/manage-contact/manage-contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardNavComponent } from './layout/dashboard-nav/dashboard-nav.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget/dashboard-widget.component';
import { QuotesWidgetComponent } from './dashboard/dashboard-widget/quotes-widget/quotes-widget.component';
import { OrdersWidgetComponent } from './dashboard/dashboard-widget/orders-widget/orders-widget.component';
import { CreditsWidgetComponent } from './dashboard/dashboard-widget/credits-widget/credits-widget.component';
import { CreditLineComponent } from './credit-line/credit-line.component';
import { ApplyCreditLineComponent } from './credit-line/apply-credit-line/apply-credit-line.component';
import { ClaimCreditLineComponent } from './credit-line/claim-credit-line/claim-credit-line.component';
import { EligibleCreditLineComponent } from './credit-line/eligible-credit-line/eligible-credit-line.component';
import { RejectedCreditLineComponent } from './credit-line/rejected-credit-line/rejected-credit-line.component';
import { RequestedCreditLineComponent } from './credit-line/requested-credit-line/requested-credit-line.component';
import { PaymentLineComponent } from './credit-line/claim-credit-line/payment-line/payment-line.component';
import { QuoteSearchFlowComponent } from './quotes/quote-search-flow/quote-search-flow.component';
import { QuoteSearchFlowHeaderComponent } from './quotes/quote-search-flow/flow-header/quote-search-flow-header.component';
import { QuoteSearchFlowParamsComponent } from './quotes/quote-search-flow/flow-params/quote-search-flow-params.component';
import { QuoteSearchFlowLocationComponent } from './quotes/quote-search-flow/flow-params/flow-location/flow-location.component';
import { RollupDialogService } from './rollup-dialog/rollup-dialog.service';
import { RollupDialogComponent } from './rollup-dialog/rollup-dialog.component';
import { QuoteSearchFlowDateComponent } from './quotes/quote-search-flow/flow-params/flow-date/flow-date.component';
import { QuoteSearchFlowCargoComponent } from './quotes/quote-search-flow/flow-params/flow-cargo/flow-cargo.component';
import { QuoteSearchFlowResultsComponent } from './quotes/quote-search-flow/flow-results/flow-results.component';
import { QuoteSearchFlowResultComponent } from './quotes/quote-search-flow/flow-results/flow-result/default/flow-result.component';
import { QuoteSearchFlowLoaderComponent } from './quotes/quote-search-flow/flow-loader/flow-loader.component';
import { QuoteSearchFlowResultDetailsComponent } from './quotes/quote-search-flow/flow-results/flow-result-details/default/flow-result-details.component';
import { RateDetailsDialogComponent } from './quotes/quote-search-flow/rate-details/rate-details-dialog.component';
import { QuoteSearchFlowFilterComponent } from './quotes/quote-search-flow/flow-results/flow-filter/flow-filter.component';
import { NoQuotesComponent } from './quotes/quote-search-flow/no-quotes/no-quotes.component';
import { QuoteSearchFlowParamsService } from './quotes/quote-search-flow/flow-params/quote-search-flow-params.service';
import { BookingFlowComponent } from './booking/booking-flow/booking-flow.component';
import { BookingFlowCargoDetailsComponent } from './booking/booking-flow/booking-flow-cargo-details/booking-flow-cargo-details.component';
import { BookingFlowContactsComponent } from './booking/booking-flow/booking-flow-contacts/booking-flow-contacts.component';
import { BookingFlowServicesComponent } from './booking/booking-flow/booking-flow-services/booking-flow-services.component';
import { BookingFlowDetailsComponent } from './booking/booking-flow/booking-flow-details/booking-flow-details.component';
import { BookingFlowSummaryComponent } from './booking/booking-flow/booking-flow-summary/booking-flow-summary.component';
import { S4DHammerConfig } from '../shared/helper/s4d-hammer.config';
import { BookingFlowContainerDetailsComponent } from './booking/booking-flow/booking-flow-cargo-details/container-details/container-details.component';
import { BookingFlowService } from './booking/booking-flow/booking-flow.service';
import { BookingFlowContactsSelectComponent } from './booking/booking-flow/booking-flow-contacts/flow-contacts-select/flow-contacts-select.component';
import { BookingFlowContactsCreateComponent } from './booking/booking-flow/booking-flow-contacts/flow-contacts-create/flow-contacts-create.component';
import { BookingFlowContactsManageComponent } from './booking/booking-flow/booking-flow-contacts/flow-contacts-manage/flow-contacts-manage.component';
import { BookingFlowLoadDetailsComponent } from './booking/booking-flow/booking-flow-cargo-details/load-details/load-details.component';
import { AdditionalServicePreShipmentComponent } from './booking/booking-flow/booking-flow-services/additional-service-pre-shipment/additional-service-pre-shipment.component';
import { AdditionalServiceCustomsComponent } from './booking/booking-flow/booking-flow-services/additional-service-customs/additional-service-customs.component';
import { ServiceCostEstimatorComponent } from './booking/booking-flow/booking-flow-services/service-cost-estimator/service-cost-estimator.component';
import { AdditionalServiceInsuranceComponent } from './booking/booking-flow/booking-flow-services/additional-service-insurance/additional-service-insurance.component';
import { AdsWidgetComponent } from './dashboard/dashboard-widget/ads-widget/ads-widget.component';
import { ExcludedCommoditiesComponent } from './booking/booking-flow/booking-flow-services/additional-service-insurance/excluded-commodities/excluded-commodities.component';
import { AdditionalServiceInsuranceManuallyComponent } from './booking/booking-flow/booking-flow-services/additional-service-insurance-manually/additional-service-insurance-manually.component';
import { AdditionalServiceCustomsManuallyComponent } from './booking/booking-flow/booking-flow-services/additional-service-customs-manually/additional-service-customs-manually.component';
import { QuoteSearchFlowResultDetailsRedesignedComponent } from './quotes/quote-search-flow/flow-results/flow-result-details/new-redesigned/flow-result-details.component';
import { FlowPriceComponent } from './quotes/quote-search-flow/flow-results/flow-price/default/flow-price.component'
import { FlowPriceRedesignedComponent } from './quotes/quote-search-flow/flow-results/flow-price/new-redesigned/flow-price.component';
import { QuoteSearchFlowNewLocationComponent } from './quotes/quote-search-flow/flow-params/flow-new-location/flow-new-location.component';
import { QuoteSearchNewFlowParamsComponent } from './quotes/quote-search-flow/flow-params/new-flow-params/new-flow-params.component'
import { FlowResultDetailsDialogComponent } from './quotes/quote-search-flow/flow-results/flow-result-details/new-redesigned/flow-result-details-dialog/flow-result-details-dialog.component';
import { RateDetailsDialogDefaultComponent } from './quotes/quote-search-flow/flow-results/flow-result-details/default/rate-details-dialog/rate-details-dialog.component';
import { SupportRequestDialogComponent } from './support-requests/support-request-dialog/support-request-dialog.component';
import { ContentService } from './layout/content/content.service';
import { SubOrderViewDashboardComponent } from './shipments/orders/order/sub-order-view-dashboard/sub-order-view-dashboard.component';
import { LiveAssistanceComponent } from './live-assistance/live-assistance.component';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { WelcomeTutorialDialogComponent } from './welcome-tutorial/welcome-tutorial-dialog/welcome-tutorial-dialog.component';
import { WelcomeTutorialService } from './welcome-tutorial/welcome-tutorial.service';
import { FlowResultDetailsServiceComponent } from './quotes/quote-search-flow/flow-results/flow-result-details/new-redesigned/flow-result-details-service/flow-result-details-service.component';
import { QuotePriceTiersComparisonComponent } from './quotes/quote-search-flow/flow-results/flow-result/quote-price-tiers-comparison/quote-price-tiers-comparison.component';
import { FlowSortOptionsComponent } from './quotes/quote-search-flow/flow-results/flow-sort-options/flow-sort-options.component';
import { PostalCodeUpdateDialogComponent } from './booking/booking-flow/booking-flow-services/postal-code-update-dialog/postal-code-update-dialog.component';
import { RecentQuotesWidgetComponent } from './dashboard/dashboard-widget/recent-quotes-widget/recent-quotes-widget.component';
import { QuotesBannerComponent } from './quotes/quote-search-flow/flow-results/quotes-banner/quotes-banner.component';

@NgModule({
  imports: [SharedModule, GooglePlaceModule, Ship4wdNgxManagerUIModule.forRoot(), HammerModule, DragDropModule],
  declarations: [
    LayoutComponent,
    EmptyLayoutComponent,
    OrganizationsComponent,
    OrganizationsListComponent,
    OrganizationLineComponent,
    OrganizationCreateComponent,
    ToolbarComponent,
    ContentComponent,
    NavbarComponent,
    ShipmentsComponent,
    OrdersComponent,
    OrderComponent,
    LogoutComponent,
    LoginComponent,
    LanguagePickerComponent,
    SelectLanguageComponent,
    BookingComponent,
    BookingStepsComponent,
    BookingReferenceComponent,
    BookingContactsComponent,
    BookingContainersComponent,
    BookingDocumentsComponent,
    BookingSummaryComponent,
    BookingIconsComponent,
    BookingOrganizationContactsComponent,
    CreateBookingOrganizationContactComponent,
    BookingCommodityDialogComponent,
    ViewBookingComponent,
    BookingShippingInstructionsComponent,
    BookingInstructionsDetailComponent,
    BookingFilesDocumentsComponent,
    BookingFilesDocumentsListComponent,
    CommentsDialogComponent,
    ManageOrganizationComponent,
    OrganizationDetailComponent,
    OrganizationMembersComponent,
    OrganizationMemberComponent,
    PaymentsComponent,
    PaymentComponent,
    ConfirmPaymentComponent,
    FinanceComponent,
    ManageContactsComponent,
    ManageContactComponent,
    DashboardComponent,
    DashboardNavComponent,
    DashboardWidgetComponent,
    QuotesWidgetComponent,
    OrdersWidgetComponent,
    CreditsWidgetComponent,
    CreditLineComponent,
    ApplyCreditLineComponent,
    ClaimCreditLineComponent,
    EligibleCreditLineComponent,
    RejectedCreditLineComponent,
    RequestedCreditLineComponent,
    PaymentLineComponent,
    RollupDialogComponent,
    QuoteSearchFlowComponent,
    QuoteSearchFlowHeaderComponent,
    QuoteSearchFlowParamsComponent,
    QuoteSearchFlowLocationComponent,
    QuoteSearchFlowCargoComponent,
    QuoteSearchFlowDateComponent,
    QuoteSearchFlowResultsComponent,
    QuoteSearchFlowResultComponent,
    QuoteSearchFlowResultDetailsComponent,
    QuoteSearchFlowFilterComponent,
    QuoteSearchFlowLoaderComponent,
    NoQuotesComponent,
    RateDetailsDialogComponent,
    BookingFlowComponent,
    BookingFlowCargoDetailsComponent,
    BookingFlowContactsComponent,
    BookingFlowServicesComponent,
    BookingFlowDetailsComponent,
    BookingFlowSummaryComponent,
    BookingFlowContainerDetailsComponent,
    BookingFlowLoadDetailsComponent,
    BookingFlowContactsSelectComponent,
    BookingFlowContactsCreateComponent,
    BookingFlowContactsManageComponent,
    AdditionalServicePreShipmentComponent,
    AdditionalServiceCustomsComponent,
    ServiceCostEstimatorComponent,

    AdditionalServiceInsuranceComponent,
    ExcludedCommoditiesComponent,
    AdsWidgetComponent,
    AdditionalServiceInsuranceManuallyComponent,
    AdditionalServiceCustomsManuallyComponent,
    QuoteSearchFlowResultDetailsRedesignedComponent,
    FlowPriceComponent,
    FlowPriceRedesignedComponent,
    QuoteSearchFlowNewLocationComponent,
    QuoteSearchNewFlowParamsComponent,
    FlowResultDetailsDialogComponent,
    RateDetailsDialogDefaultComponent,
    SupportRequestDialogComponent,
    SubOrderViewDashboardComponent,
    LiveAssistanceComponent,
    WelcomeTutorialDialogComponent,
    FlowResultDetailsServiceComponent,
    QuotePriceTiersComparisonComponent,
    FlowSortOptionsComponent,
    PostalCodeUpdateDialogComponent,
    RecentQuotesWidgetComponent,
    QuotesBannerComponent
  ],
  providers: [
    LanguagePopUpService,
    LayoutService,
    BookingFlowService,
    QuotesService,
    RollupDialogService,
    QuoteSearchFlowParamsService,
    RoutesService,
    ContentService,
    WelcomeTutorialService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: S4DHammerConfig }
  ],
  entryComponents: [SelectLanguageComponent, LanguagePickerComponent, LayoutComponent],
  exports: []
})
export class MobileModule {
  debugger
 }
