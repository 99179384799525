<div class="row m-0 w-100">
  <div class="col-12 px-0">
    <span class="estimated-type-title estimated-type-title--black">Total Price</span>
  </div>
  <div class="col-12 px-0">
    <span appSetRoleBasedPrice class="estimated-title">
      {{ quote.currency | currencyTextIcon }}{{ quote.accumulatePrice | number: "1.0-0" }}
    </span>
  </div>
  <div class="col-12 px-0">
    <div class="d-flex mt-1" *ngIf="isPartialQuote">
      <span class="estimated-subtitle estimated-subtitle-dashed d-flex" [matTooltip]="getPartialQuoteTooltipText"
        matTooltipPosition="below" matTooltipClass="text-center">+
        Door Service Costs</span>
    </div>
  </div>
</div>