import { Experiment, ExperimentClient, Variant } from "@amplitude/experiment-js-client"
import { Injectable } from "@angular/core";
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeFlags, amplitudeFlagSettings, amplitudeFlagVariants } from './amplitude.constants';
import { AmplitudeKeys } from "./amplitude.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable()
export class AmplitudeService {
  private experiment: ExperimentClient

  constructor(
    private environmentService: EnvironmentService
  ) { }

  async init(amplitudeKeys: AmplitudeKeys, user_id: string): Promise<void> {
    amplitude.init(amplitudeKeys.apiKey, user_id)
    this.experiment = Experiment.initializeWithAmplitudeAnalytics(amplitudeKeys.deployKey);
    await this.experiment.start();
  }

  setUser(user_id: string): void {
    amplitude.setUserId(user_id)
  }

  getFlag(flagKey: string): Variant {
    if (this.isFlagEnabled(amplitudeFlagSettings[flagKey])) {
      return this.experiment.variant(flagKey)
    }

    return {} as Variant;
  }

  checkQuoteSearchStyle(variant: Variant): boolean {
    const defaultVariant = amplitudeFlagVariants[amplitudeFlags.quoteSearchFlagKey].oldSearchStyle;

    if (amplitudeFlags.quoteSearchFlagKey) {
      if (variant && variant.value) {
        return variant.value === defaultVariant ?? false;
      } else {
        variant.value = defaultVariant;
        return true;
      }
    }
  }

  checkWhatsAppEnabled(): boolean {
    const variant = this.getFlag(amplitudeFlags.isWhatsAppEnabledFlagKey);
    const whatsAppEnabledVariant = amplitudeFlagVariants[amplitudeFlags.isWhatsAppEnabledFlagKey].whatsAppEnabled;

    if (amplitudeFlags.isWhatsAppEnabledFlagKey) {
      if (variant && variant.value) {
        return variant.value === whatsAppEnabledVariant ?? false;
      } else {
        return false;
      }
    }
  }

  checkQuoteBadgeStyle(variant: Variant): boolean {
    const defaultVariant = amplitudeFlagVariants[amplitudeFlags.quoteBadge].withoutRowPriceStyle;

    if (amplitudeFlags.quoteBadge) {
      if (variant && variant.value) {
        return variant.value === defaultVariant ?? false;
      } else {
        variant.value = defaultVariant;
        return true;
      }
    }
  }

  checkQuotesBannerEnabled(): boolean {
    const variant = this.getFlag(amplitudeFlags.isQuotesBannerFlagKey);
    const result = amplitudeFlagVariants[amplitudeFlags.isQuotesBannerFlagKey].enabled;

    if (amplitudeFlags.isQuotesBannerFlagKey) {
      if (variant && variant.value) {
        return variant.value === result ?? false;
      } else {
        return false;
      }
    }
  }

  private isFlagEnabled(flagSettingsKey: string): boolean {
    return this.getNestedProperty(this.environmentService.environment, flagSettingsKey) as boolean;
  }

  private getNestedProperty(obj: object, path: string): unknown {
    const pathParts = path.split('.');
    let currentObj = obj;

    for (let i = 0; i < pathParts.length; i++) {
      if (!currentObj || !currentObj.hasOwnProperty(pathParts[i])) {
        return undefined;
      }
      currentObj = currentObj[pathParts[i]];
    }

    return currentObj;
  }

}
