<div [formGroup]="bookingForm" *ngIf="!isViewOrganizationContacts">
  <div formArrayName="containers">
    <div *ngFor="let containerForm of bookingForm.get('containers')?.controls;let i = index">
      <div [formGroup]="containerForm">
        <div class="booking-containers-container" [ngClass]="shipmentTypeCode === shipmentTypes.LCL ? 'lcl' : ''">
          <div class="booking-containers-header">
            <div class="booking-containers-header-wrapper">
              <div class="booking-containers-header-title-section">
                <h2 class="booking-containers-header-title"> {{ shipmentTypeCode === shipmentTypes.LCL
                  || shipmentTypeCode === shipmentTypes.AIR
                  ? "Cargo"
                  : shipmentTypeCode === shipmentTypes.FCL
                  ? "Container " + (i + 1)
                  : ""
                  }}
                </h2>
              </div>
              <div class="booking-containers-header-copy-section">
                <button *ngIf="bookingForm.get('containers')?.controls.length !== 1 && i !== 0" mat-button
                  class="booking-container-copy-button" (click)="copyDetails(i, containerForm)"
                  matTooltip="Paste container details from previous container">
                  <span matPrefix class="booking-container-copy-icon"></span>
                </button>
              </div>
            </div>
            <h4 class="booking-containers-header-sub-title">
              {{ getContainersTitle(containerForm) }}
            </h4>
          </div>

          <div [formGroup]="containerForm" *ngIf="shipmentTypeCode === shipmentTypes.FCL">
            <div class="booking-containers-subtitle">
              Cargo details
            </div>

            <div class="booking-containers-wrapper">
              <div class="booking-containers-field left-one">
                <span class="field-title">Cargo</span>
                <div class="booking-containers-field-box middle" [ngClass]="{
                    'invalid-error-box': !containerForm.get('cargoName').valid
                    && containerForm.get('cargoName')?.touched
                  }">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput type="text" formControlName="cargoName"
                      placeholder='e.g. "Sheets" or "Car Parts"' />
                  </mat-form-field>
                </div>
              </div>
              <div class="booking-cargo-wrapper">
                <div class="booking-cargo-weight">
                  <span class="field-title">Weight</span>
                  <div class="booking-cargo-weight-box" [ngClass]="{
                      'invalid-error-box': (!containerForm.get('cargoGrossWeight').valid
                      && containerForm.get('cargoGrossWeight')?.touched)
                    }">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="number" placeholder="0" formControlName="cargoGrossWeight" min="0"
                        (change)="checkCommoditiesWeight(containerForm)" appPreventNonNumeric />
                    </mat-form-field>
                    <mat-form-field class="last">
                      <mat-select formControlName="cargoGrossWeightUnitCode"
                        (selectionChange)="checkCommoditiesWeight(containerForm)">
                        <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                          {{ weightUnit.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="booking-containers-subtitle p-0">
              Expected cargo ready
            </div>
            <div class="booking-containers-wrapper booking-cargo-date-time">
              <div class="booking-containers-field">
                <span class="field-title">Ready date and time</span>
                <div [ngClass]="{
                  'invalid-error-box': !containerForm.get('cargoExpectedReady').valid
                  && containerForm.get('cargoExpectedReady')?.touched
                  }">
                  <mat-form-field class="full-width">
                    <input matInput [ngxMatDatetimePicker]="pickerCargoExpectedReady" readonly
                      formControlName="cargoExpectedReady" [min]="minSelectableDate" [max]="maxSelectableDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerCargoExpectedReady"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerCargoExpectedReady>
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div *ngIf="isShowCollectContact">
              <div class="booking-containers-subtitle p-0"
                [ngClass]="{'booking-containers-subtitle-padding': !isShowCollectContact}">
                Collection
              </div>
              <div class="booking-containers-wrapper" *ngIf="isShowCollectContact">
                <div class="booking-containers-field not-main">
                  <span class="field-title">Collect</span>
                  <div class="booking-containers-field-box" [ngClass]="{
                    'invalid-error-box': containerForm.get('collectContact').touched
                    && containerForm.get('collectContact').invalid }">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput placeholder="Add contact" disabled
                        [value]="containerForm.get('collectContact')?.value?.address1" />
                      <button matSuffix mat-icon-button class="booking-remove-contact-button"
                        (click)="onRemoveBookingContact(contactTypes.collect,containerForm)"
                        *ngIf="containerForm.get('collectContact')?.value">
                        <span class="remove-contact-icon"></span>
                      </button>
                    </mat-form-field>
                    <img src="../../../../../assets/images/bookings/add-contact.svg" matSuffix
                      (click)="viewOrganizationContacts(contactTypes.collect,containerForm)"
                      *ngIf="!containerForm.get('collectContact')?.value" class="add-contact-icon" />
                  </div>
                </div>
              </div>

              <div class="booking-containers-wrapper p-0">
                <div class="booking-containers-field">
                  <span class="field-title">Loading Reference</span>
                  <div class="booking-containers-field-box" [ngClass]="{
                  'invalid-error-box': !containerForm.get('collectionReference').valid
                  && containerForm.get('collectionReference')?.touched
                }">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="text" formControlName="collectionReference" placeholder='Reference' />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="booking-containers-wrapper p-0 booking-cargo-date-time">
                <div class="booking-containers-field not-main">
                  <span class="field-title">Collection Date</span>
                  <div class="booking-containers-field-box p-0" [ngClass]="{
                    'invalid-error-box': !containerForm.get('collectionDate').valid
                      && containerForm.get('collectionDate')?.touched
                  }">
                    <mat-form-field class="full-width" [floatLabel]="'never'">
                      <input matInput [ngxMatDatetimePicker]="pickerCollectionDate" formControlName="collectionDate"
                        [min]="getMinimumCollectionTime(containerForm)" [max]="maxSelectableDate"
                        placeholder="Date & Time">
                      <mat-datepicker-toggle matSuffix [for]="pickerCollectionDate"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker #pickerCollectionDate>
                      </ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isShowDropContact">
              <div class="booking-containers-wrapper p-0" *ngIf="isShowDropContact">
                <div class="booking-containers-field">
                  <span class="field-title">Drop</span>
                  <div class="booking-containers-field-box" [ngClass]="{
                    'invalid-error-box': containerForm.get('dropContact').touched
                    && containerForm.get('dropContact').invalid }">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput placeholder="Add contact" disabled
                        [value]="containerForm.get('dropContact')?.value?.address1" />
                      <button matSuffix mat-icon-button class="booking-remove-contact-button"
                        (click)="onRemoveBookingContact(contactTypes.drop,containerForm)"
                        *ngIf="containerForm.get('dropContact')?.value">
                        <span class="remove-contact-icon"></span>
                      </button>
                    </mat-form-field>
                    <img src="../../../../../assets/images/bookings/add-contact.svg"
                      *ngIf="!containerForm.get('dropContact')?.value" matSuffix class="add-contact-icon"
                      (click)="viewOrganizationContacts(contactTypes.drop,containerForm)" />
                  </div>
                </div>
              </div>
            </div>

            <div formArrayName="commodities" class="booking-commodity">
              <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls;let i = index">
                <div class="booking-commodity-wrapper">
                  <div class="booking-commodity-subtitle">
                    Commodity {{ i + 1 }}
                  </div>
                  <div class="text-right">
                    <button mat-icon-button matSuffix color="warn" (click)="onDeleteCommodity(containerForm, i)"
                      *ngIf="containerForm.get('commodities')?.controls.length !== 1">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
                <div [formGroup]="commodityForm">
                  <div class="booking-containers-wrapper">
                    <div class="booking-containers-field not-main left-one">
                      <span class="field-title">Type of Packaging</span>
                      <span class="field-title subtitle">Start typing (min 1 letters) for auto-completion</span>
                      <div class="booking-containers-field-box middle" [ngClass]="{
                        'invalid-error-box': !commodityForm.get('packageTypeCode').valid
                        && commodityForm.get('packageTypeCode')?.touched
                        }">
                        <app-package-type-autocomplete [pageSize]="10"
                          [packageTypeControl]="commodityForm.get('packageTypeCode')" class="w-100">
                        </app-package-type-autocomplete>
                      </div>
                    </div>
                    <div class="booking-cargo-wrapper">
                      <div class="booking-cargo-weight">
                        <span class="field-title small">Product Value</span>
                        <div class="booking-cargo-weight-box" [ngClass]="{
                            'invalid-error-box': !commodityForm.get('productValue').valid
                            && commodityForm.get('productValue')?.touched
                          }">
                          <mat-form-field [floatLabel]="'never'">
                            <input matInput type="number" placeholder="0" formControlName="productValue" appPreventNonNumeric />
                          </mat-form-field>
                          <mat-form-field class="last">
                            <mat-select formControlName="productValueCurrencyCode">
                              <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                                {{ currency.shortName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="booking-containers-wrapper pt-0">
                    <div class="booking-containers-field not-main left-one">
                      <span class="field-title">Commodity Code</span>
                      <div class="booking-containers-field-box middle" [ngClass]="{
                        'invalid-error-box': !commodityForm.get('commodityTypeCode').valid
                        && commodityForm.get('commodityTypeCode')?.touched
                        }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="text" formControlName="commodityTypeCode" placeholder='e.g. SCTG 37210'
                            readonly (click)="onOpenComodityDialog(
                              commodityForm, 'commodityTypeCode', 'commodityDescription'
                            )" />
                        </mat-form-field>
                        <button matSuffix mat-icon-button (click)="onOpenComodityDialog(
                            commodityForm, 'commodityTypeCode', 'commodityDescription')">
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div class="booking-cargo-wrapper">
                      <div class="booking-cargo-weight">
                        <span class="field-title">Amount of Packages</span>
                        <div class="booking-cargo-weight-box" [ngClass]="{
                            'invalid-error-box': !commodityForm.get('numberOfPackages').valid
                            && commodityForm.get('numberOfPackages')?.touched
                          }">
                          <mat-form-field [floatLabel]="'never'">
                            <input matInput type="number" placeholder="0" formControlName="numberOfPackages" appPreventNonNumeric />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="booking-cargo-wrapper">
                    <div class="booking-cargo-weight full">
                      <span class="field-title">Volume</span>
                      <div class="booking-cargo-weight-box middle" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('volume').valid
                          && commodityForm.get('volume')?.touched
                        }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="number" placeholder="0" formControlName="volume" min="0" appPreventNonNumeric />
                        </mat-form-field>
                        <mat-form-field class="last">
                          <mat-select formControlName="volumeUnitCode">
                            <mat-option *ngFor="let volumeUnit of volumeUnits" [value]="volumeUnit.value">
                              {{ volumeUnit.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="booking-cargo-weight">
                      <span class="field-title">Weight</span>
                      <div class="booking-cargo-weight-box" [ngClass]="{
                          'invalid-error-box': (!commodityForm.get('weight').valid
                          && commodityForm.get('weight')?.touched)
                        }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="number" placeholder="0" formControlName="weight" min="0"
                            (change)="checkCommoditiesWeight(containerForm)" appPreventNonNumeric />
                        </mat-form-field>
                        <mat-form-field class="last">
                          <mat-select formControlName="weightUnitCode"
                            (selectionChange)="checkCommoditiesWeight(containerForm)">
                            <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                              {{ weightUnit.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="booking-container-add">
              <button mat-button class="booking-container-add-button" (click)="onAddCommodity(containerForm)">
                <span matPrefix class="booking-container-add-icon">
                  <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
                </span>
                <span class="booking-container-add-title">Add Commodity</span>
              </button>
            </div>
          </div>
          <div [formGroup]="containerForm" *ngIf="shipmentTypeCode === shipmentTypes.LCL
            || shipmentTypeCode === shipmentTypes.AIR">
            <div class="booking-containers-subtitle">
              {{ getLoadsText() }} Loads
            </div>

            <div class="booking-containers-wrapper">
              <div class="booking-containers-field">
                <span class="field-title">Cargo Details</span>
                <div class="booking-containers-field-box" [ngClass]="{
                    'invalid-error-box': !containerForm.get('cargoName').valid
                    && containerForm.get('cargoName')?.touched
                  }">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput type="text" formControlName="cargoName"
                      placeholder='e.g. "Sheets" or "Car Parts"' />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="booking-containers-subtitle p-0"
              [ngClass]="{'booking-containers-subtitle-padding': !isShowCollectContact}">
              Collection
            </div>
            <div class="booking-containers-wrapper" *ngIf="isShowCollectContact">
              <div class="booking-containers-field not-main">
                <span class="field-title">Collect</span>
                <div class="booking-containers-field-box" [ngClass]="{
                  'invalid-error-box': containerForm.get('collectContact').touched
                  && containerForm.get('collectContact').invalid }">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput placeholder="Add contact" disabled
                      [value]="containerForm.get('collectContact')?.value?.address1" />
                    <button matSuffix mat-icon-button class="booking-remove-contact-button"
                      (click)="onRemoveBookingContact(contactTypes.collect,containerForm)"
                      *ngIf="containerForm.get('collectContact')?.value">
                      <span class="remove-contact-icon"></span>
                    </button>
                  </mat-form-field>
                  <img src="../../../../../assets/images/bookings/add-contact.svg" matSuffix
                    (click)="viewOrganizationContacts(contactTypes.collect,containerForm)"
                    *ngIf="!containerForm.get('collectContact')?.value" class="add-contact-icon" />
                </div>
              </div>
            </div>

            <div class="booking-containers-wrapper p-0">
              <div class="booking-containers-field">
                <span class="field-title">Loading Reference</span>
                <div class="booking-containers-field-box" [ngClass]="{
                    'invalid-error-box': !containerForm.get('collectionReference').valid
                    && containerForm.get('collectionReference')?.touched
                  }">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput type="text" formControlName="collectionReference" placeholder='Reference' />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="booking-containers-wrapper p-0 booking-cargo-date-time">
              <div class="booking-containers-field not-main">
                <span class="field-title">Collection Date</span>
                <div class="booking-containers-field-box p-0" [ngClass]="{
                  'invalid-error-box': !containerForm.get('collectionDate').valid
                  && containerForm.get('collectionDate')?.touched
                }">
                  <mat-form-field class="full-width" [floatLabel]="'never'">
                    <input matInput [ngxMatDatetimePicker]="pickerCollectionDate"
                      [min]="getMinimumCollectionTime(containerForm)" [max]="maxSelectableDate"
                      formControlName="collectionDate" placeholder="Date & Time">
                    <mat-datepicker-toggle matSuffix [for]="pickerCollectionDate"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerCollectionDate>
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="booking-containers-wrapper p-0" *ngIf="isShowDropContact">
              <div class="booking-containers-field">
                <span class="field-title">Drop</span>
                <div class="booking-containers-field-box" [ngClass]="{
                  'invalid-error-box': containerForm.get('dropContact').touched
                  && containerForm.get('dropContact').invalid }">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput placeholder="Add contact" disabled
                      [value]="containerForm.get('dropContact')?.value?.address1" />
                    <button matSuffix mat-icon-button class="booking-remove-contact-button"
                      (click)="onRemoveBookingContact(contactTypes.drop,containerForm)"
                      *ngIf="containerForm.get('dropContact')?.value">
                      <span class="remove-contact-icon"></span>
                    </button>
                  </mat-form-field>
                  <img src="../../../../../assets/images/bookings/add-contact.svg"
                    *ngIf="!containerForm.get('dropContact')?.value" matSuffix class="add-contact-icon"
                    (click)="viewOrganizationContacts(contactTypes.drop,containerForm)" />
                </div>
              </div>
            </div>

            <div formArrayName="commodities" class="booking-commodity lcl-commodity-wrapper">
              <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls;let i = index">
                <div class="booking-containers-subtitle p-0">
                  <span>Load {{ i + 1 }}</span>
                  <span class="commodities-dimensions" *ngIf="commodityForm">
                    {{ getDimensions(commodityForm) }}
                  </span>
                </div>
                <div [formGroup]="commodityForm">
                  <div class="booking-containers-wrapper">
                    <div class="booking-containers-field not-main">
                      <span class="field-title">Marks & Numbers</span>
                      <div class="booking-containers-field-box" [ngClass]="{
                    'invalid-error-box': !commodityForm.get('marksAndNumbers').valid
                    && commodityForm.get('marksAndNumbers')?.touched }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="text" formControlName="marksAndNumbers" placeholder='Marks & Numbers' />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="booking-containers-wrapper pt-0">
                    <div class="booking-containers-field left-one not-main">
                      <span class="field-title">Commodity Code</span>
                      <div class="booking-containers-field-box half" [ngClass]="{
                        'invalid-error-box': !commodityForm.get('commodityTypeCode').valid
                        && commodityForm.get('commodityTypeCode')?.touched
                        }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="text" formControlName="commodityTypeCode" placeholder='e.g. SCTG 37210'
                            readonly
                            (click)="onOpenComodityDialog(commodityForm, 'commodityTypeCode', 'commodityDescription')" />
                        </mat-form-field>
                        <button matSuffix mat-icon-button
                          (click)="onOpenComodityDialog(commodityForm, 'commodityTypeCode', 'commodityDescription')">
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div class="booking-cargo-wrapper">
                      <div class="booking-cargo-weight">
                        <span class="field-title">How many packages</span>
                        <div class="booking-cargo-weight-box half" [ngClass]="{
                            'invalid-error-box': !commodityForm.get('numberOfPackages').valid
                            && commodityForm.get('numberOfPackages')?.touched
                          }">
                          <mat-form-field [floatLabel]="'never'">
                            <input matInput placeholder="0" formControlName="numberOfPackages" readonly />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="booking-cargo-wrapper">
                    <div class="booking-cargo-weight first">
                      <span class="field-title">Type of Packaging</span>
                      <span class="field-title subtitle">Start typing (min 1 letter) for auto-completion</span>
                      <div class="booking-cargo-weight-box half" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('packageTypeCode').valid
                          && commodityForm.get('packageTypeCode')?.touched
                        }">
                        <app-package-type-autocomplete [pageSize]="10"
                          [packageTypeControl]="commodityForm.get('packageTypeCode')" class="w-100">
                        </app-package-type-autocomplete>
                      </div>
                    </div>
                    <div class="booking-cargo-weight">
                      <span class="field-title small">Product Value</span>
                      <div class="booking-cargo-weight-box half" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('productValue').valid
                          && commodityForm.get('productValue')?.touched
                        }">
                        <mat-form-field [floatLabel]="'never'">
                          <input matInput type="number" placeholder="0" formControlName="productValue" appPreventNonNumeric />
                        </mat-form-field>
                        <mat-form-field class="last">
                          <mat-select formControlName="productValueCurrencyCode">
                            <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                              {{ currency.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isViewOrganizationContacts">
  <app-booking-organization-contacts (BackToBooking)="onBack()" (selectedContact)="onSelectedContact($event)"
    [booking]="booking" [contactType]="selectedContactType"
    [containerId]="containerId"></app-booking-organization-contacts>
</div>