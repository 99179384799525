import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutMode } from './layout.model';

@Injectable()
export class LayoutService {
    private modeSubject: BehaviorSubject<LayoutMode> = new BehaviorSubject<LayoutMode>(LayoutMode.OTHER);
    public mode = this.modeSubject.asObservable();

    private toolbarShowUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarShowUser = this.toolbarShowUserSubject.asObservable();

    private toolbarShowHomeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public toolbarShowHome = this.toolbarShowHomeSubject.asObservable();

    private toolbarShowBackSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public toolbarShowBack = this.toolbarShowBackSubject.asObservable();

    private toolbarTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public toolbarTitle = this.toolbarTitleSubject.asObservable();

    private toolbarDescriptionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public toolbarDescription = this.toolbarDescriptionSubject.asObservable();

    private toolbarHideImageSubject: BehaviorSubject<boolean>  = new BehaviorSubject<boolean>(false);
    public toolbarHideImage = this.toolbarHideImageSubject.asObservable();

    private toolbarBackUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('/');
    public toolbarBackUrl = this.toolbarBackUrlSubject.asObservable();

    private wrapperHeaderSubject: BehaviorSubject<TemplateRef<ElementRef>> = new BehaviorSubject<TemplateRef<ElementRef>>(null);
    public wrapperHeader = this.wrapperHeaderSubject.asObservable();

    private moreMenuTemplateSubject: BehaviorSubject<TemplateRef<ElementRef>> = new BehaviorSubject<TemplateRef<ElementRef>>(null);
    public moreMenuTemplate = this.moreMenuTemplateSubject.asObservable();

    constructor() {
    }

    setMode(mode: LayoutMode = LayoutMode.OTHER) {
        this.modeSubject.next(mode);
    }

    setToolbarShowUser(show: boolean = false) {
        this.toolbarShowUserSubject.next(show);
    }

    setToolbarShowHome(show: boolean = true) {
        this.toolbarShowHomeSubject.next(show);
    }

    setToolbarShowBack(show: boolean = false) {
        this.toolbarShowBackSubject.next(show);
    }

    setToolbarTitle(title: string = undefined) {
        this.toolbarTitleSubject.next(title);
    }

    setToolbarDescription(description: string = undefined) {
        this.toolbarDescriptionSubject.next(description);
    }

    setHideImage(hide: boolean = false) {
        this.toolbarHideImageSubject.next(hide);
    }

    setToolbarBackUrl(backUrl: string = '/') {
        this.toolbarBackUrlSubject.next(backUrl);
    }

    setWrapperHeader(element: TemplateRef<ElementRef> = null) {
        this.wrapperHeaderSubject.next(element);
    }

    setMoreMenuTemplate(element: TemplateRef<ElementRef> = null) {
        this.moreMenuTemplateSubject.next(element);
    }

    setDefault() {
        this.setToolbarShowUser();
        this.setToolbarShowHome();
        this.setToolbarShowBack();
        this.setToolbarTitle();
        this.setToolbarDescription();
        this.setToolbarBackUrl();
        this.setWrapperHeader();
        this.setMoreMenuTemplate();
        this.setMode();
    }
}