<div class="booking-reference-container" [formGroup]="bookingForm">
  <div class="booking-reference-header">
    <h2 class="booking-reference-header-title">Customer Reference</h2>
    <h4 class="booking-reference-header-sub-title">
      Provide your customer reference
    </h4>
  </div>
  <div class="booking-reference-wrapper">
    <div class="booking-reference-field-wrapper">
      <div class="booking-reference-field">
        <span class="field-title">Customer Reference</span>
        <div class="booking-reference-field-box" [ngClass]="{
            'invalid-error-box':
              !bookingForm.get('customerReferenceId').valid &&
              bookingForm.get('customerReferenceId')?.touched
          }">
          <mat-form-field [floatLabel]="'never'">
            <input matInput placeholder="Add customer reference" formControlName="customerReferenceId"/>
            <button matSuffix mat-icon-button class="remove-customer-reference-button"
              (click)="onRemoveCustomerReference($event)" *ngIf="bookingForm.get('customerReferenceId')?.value">
              <span class="remove-customer-reference-icon"></span>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="booking-reference-field-refresh">
        <button matSuffix mat-icon-button class="booking-reference-field-refresh-button"
          matTooltip="Generate reference id" #tooltip="matTooltip" matTooltipPosition="above" (click)="onGenerateRefID()">
          <span class="booking-reference-field-refresh-icon"></span>
        </button>
      </div>
    </div>
    <div class="booking-another-reference-wrapper" [hidden]="isHideAnotherRef">
      <div class="booking-reference-add">
        <button mat-button class="booking-reference-add-button" (click)="onAddAnotherRef()">
          <span matPrefix class="booking-reference-add-icon">
            <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
          </span>
          <span class="booking-reference-add-title">Add Reference</span>
        </button>
      </div>
      <div formArrayName="anotherReferences" class="booking-another-reference">
        <div *ngFor="
            let anotherReferenceForm of bookingForm.get('anotherReferences')
              ?.controls;
            let i = index
          " [formGroup]="anotherReferenceForm" class="another-reference-wrapper">
          <div class="booking-another-reference-name-field">
            <span class="field-title">Ref. name</span>
            <div class="booking-another-reference-name-box">
              <mat-form-field class="w-100" [floatLabel]="'never'">
                <mat-select required formControlName="name" placeholder="Reference type">
                  <mat-option *ngFor="let referenceType of referenceTypes" [value]="referenceType.name">
                    {{ referenceType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="booking-another-reference-value-field">
            <span class="field-title">Ref. value</span>
            <div class="booking-another-reference-value-box">
              <mat-form-field [floatLabel]="'never'">
                <input required matInput placeholder="Type ref. value" formControlName="value" />
              </mat-form-field>
            </div>
          </div>
          <div class="booking-another-reference-delete-icon">
            <button mat-icon-button matSuffix color="warn" (click)="onRemoveAnotherRef(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="booking-reference-field-wrapper" [ngClass]="{'booking-note-box': isHideAnotherRef === true}">
      <div class="booking-reference-field">
        <span class="field-title">Note (Optional)</span>
        <div class="booking-reference-field-box">
          <div *ngFor="
              let noteForm of bookingForm.get('notes')?.controls;
              let i = index
            ">
            <div [formGroup]="noteForm" class="d-flex">
              <mat-form-field [floatLabel]="'never'">
                <input matInput placeholder="Type your note" formControlName="value" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>