import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { LayoutService } from '../../layout/layout.service';
import { LayoutMode } from '../../layout/layout.model';
import { BookingFlowPanelState, BookingFlowPanels, CurrencyCode, HarmonizedSystemCode, ShipmentType, ShipperType, VolumeUnit, WeightUnit } from '../../../shared/shared.model';
import { AnotherReference, Booking, BookingNoteAccessType, BookingSummary, BookingViewModel, Commodity, Contact, ContactType, Container, Note } from '../../../shared/bookings/bookings.model';
import { ValidatorsService } from '../../../shared/helper/validators.service';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { BookingContactsService } from '../../../shared/bookings/booking-contacts/booking-contacts.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { AdditionalServiceType } from '../../../shared/additional-services/additional-services.model';
import { OrganizationsService } from '../../../desktop/organizations/organizations.service';
import { environment } from '../../../../environments/environment';
import { Organization } from '../../../desktop/manage-organization/manage-organization.model';
import { QuotesQuery } from '../../../desktop/quotes/quotes.model';
import { BookingFlowCargoDetailsComponent } from './booking-flow-cargo-details/booking-flow-cargo-details.component';
import { BookingFlowService } from './booking-flow.service';
import { RollupDialogService } from '../../rollup-dialog/rollup-dialog.service';
import { ServiceCostEstimatorComponent } from './booking-flow-services/service-cost-estimator/service-cost-estimator.component';
import { CustomsRate } from '../../../shared/customs/customs.model';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';
import { SupportRequestsService } from '../../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../support-requests/support-requests.model';
import { DialogService } from '../../../shared/services/dialog.service';

@Component({
  selector: 'app-booking-flow',
  templateUrl: './booking-flow.component.html',
  styleUrls: ["./booking-flow.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowComponent implements OnInit, AfterViewInit {
  @ViewChild(BookingFlowCargoDetailsComponent) childComponent: BookingFlowCargoDetailsComponent;

  isExpanded: boolean[] = [true, false, false];
  isSuccess: boolean[] = [false, false, false];
  isError: boolean[] = [false, false, false];
  isProgress: boolean[] = [true, true, false];
  isValid: boolean[] = [false, false, true];
  isDoorPickup: boolean;
  isDoorDelivery: boolean;
  isAdditionalServicesEnabled: boolean = false;
  isContactChanged: boolean = true;
  isCreditOrganization = false;
  isEdit: boolean = false;
  isLoading: boolean = false;
  isManageContacts: boolean = false;

  shipperContact: Contact = null;
  consigneeContact: Contact = null;
  notifyPartyContact: Contact = null;
  pickupContact: Contact = null;
  deliveryContact: Contact = null;

  bookingId: string;
  booking: Booking;
  bookingForm: FormGroup;
  bookingFlowPanels = BookingFlowPanels;
  shipmentTypeCode: ShipmentType;
  bookingSummary: BookingSummary;
  selectedAdditionalService: AdditionalServiceType | undefined;
  organization: Organization;
  selectedCountry: string = "";
  contactType: ContactType;

  serviceCostEstimatorForm: FormGroup | undefined = undefined;
  calculaterResponse: CustomsRate;
  isManualExpand: boolean = false;
  isSubmitLoading: boolean = false;

  get displayAdditionalServices(): string {
    return this.isAdditionalServicesEnabled ? null : 'none';
  }

  get isBookingValid(): boolean {
    return this.isValid.every(x => x === true);
  }

  get containersFormArray(): FormArray {
    return this.bookingForm.get("containers") as FormArray;
  }

  get anotherReferencesFormArray(): FormArray {
    return this.bookingForm.get("anotherReferences") as FormArray;
  }

  get notesFormArray(): FormArray {
    return this.bookingForm.get("notes") as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private validatorsService: ValidatorsService,
    private notificationService: NotificationService,
    private bookingFlowService: BookingFlowService,
    private bookingsService: BookingsService,
    private bookingContactsService: BookingContactsService,
    private utilityService: UtilityService,
    private organizationsService: OrganizationsService,
    private rollupDialogService: RollupDialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private supportRequestsService: SupportRequestsService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.isEdit = this.route.snapshot.params.isEdit ? JSON.parse(this.route.snapshot.params.isEdit) : false;
    if (this.bookingId) {
      this.getBookingById(this.bookingId);
    }
    this.isAdditionalServicesEnabled = this.getIsAdditionalServicesEnabled();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.BOOKINGFLOW);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarTitle("Booking Quote");
      this.layoutService.setToolbarDescription("Complete your order");
      this.layoutService.setToolbarBackUrl(this.isEdit ? ".." : "/quote-search");
    }, 0);
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onClickPanel(state: boolean, panel: BookingFlowPanels): void {
    this.isExpanded[panel] = state;

    this.setPanelStatus(state, panel);
  }

  onAddCommodity(container: FormGroup): void {
    const commodities = container.get("commodities") as FormArray;
    commodities.push(this.createCommodityForm());
  }

  onDeleteCommodity(result): void {
    const commodities = result?.container.get("commodities") as FormArray;
    commodities.removeAt(result?.index);
  }

  onCopyContainerDetails(data: any): void {
    if (this.shipmentTypeCode == ShipmentType.FCL) {
      let sourceCommodities = this.containersFormArray.controls[data?.sIndex]?.get('commodities') as FormArray;
      let targetCommodities = this.containersFormArray.controls[data?.dIndex]?.get('commodities') as FormArray;
      targetCommodities.clear();
      sourceCommodities.value.forEach(commodity => {
        commodity.id = null;
        targetCommodities.push(this.createCommodityForm(commodity))
      });
      targetCommodities.updateValueAndValidity();
    }
  }

  onSelectedContact(selectedContact: any): void {
    if (selectedContact.contactType == ContactType.collect
      || selectedContact.contactType == ContactType.drop) {
      this.setSelectedContact(selectedContact.contactType, selectedContact.bookingContact);
    }
    else {
      this.getBookingContacts(this.booking.id);
    }

    this.isManageContacts = false;
  }

  onUnselectedContact(contactType: ContactType): void {
    this.setSelectedContact(contactType, null);

    if (contactType != ContactType.collect && contactType != ContactType.drop) {
      this.getBookingContacts(this.booking.id);
    }
  }

  onUpdateHasAdditionalServices(hasAdditionalServices: boolean): void {
    this.isAdditionalServicesEnabled = hasAdditionalServices;
  }

  onBookingAdditionalServiceUpdated(data: any): void {
    this.getBookingByIdAfterAdditionalService(this.bookingId, data)
  }

  onManageContacts(data: any): void {
    this.isManageContacts = true;
    this.contactType = data?.contactType;
  }

  onLoading(value: boolean): void {
    this.isLoading = value;
  }

  onBack(): void {
    this.isManageContacts = false;
  }

  onNext(state: BookingFlowPanelState): void {
    this.setState(state);
    this.updateBooking(state);
    this.toNext(state);

    this.updateGoogleAnalyticsStep(state);
  }

  onSetState(state: BookingFlowPanelState): void {
    this.setState(state);
  }

  isShowSuccessIcon(panel: BookingFlowPanels): boolean {
    return this.isSuccess[panel];
  }

  isShowErrorIcon(panel: BookingFlowPanels): boolean {
    return this.isError[panel] && !this.isProgress[panel] && !this.isSuccess[panel];
  }

  isShowProgressIcon(panel: BookingFlowPanels): boolean {
    return this.isProgress[panel];
  }

  isShowCevronDownHoverIcon(panel: BookingFlowPanels): boolean {
    return !this.isExpanded[panel] && (!this.isError[panel] || this.isSuccess[panel]);
  }

  isShowErrorHoverIcon(panel: BookingFlowPanels): boolean {
    return this.isError[panel] && !this.isExpanded[panel] && !this.isSuccess[panel];
  }

  isShowCevronUpHoverIcon(panel: BookingFlowPanels): boolean {
    return this.isExpanded[panel];
  }

  onCostEstimator(value: any): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(ServiceCostEstimatorComponent, {
        bookingForm: this.bookingForm,
        booking: this.booking,
        containers: this.bookingForm.get('containers'),
        shipmentTypeCode: this.shipmentTypeCode,
        existingServiceCostEstimatorForm: this.serviceCostEstimatorForm,
        existingCalculaterResponse: this.calculaterResponse,
        vendorCode: value.vendorCode as number,
        selectedCountry: this.selectedCountry
      });

      dialogRef.subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        this.serviceCostEstimatorForm = result?.serviceCostEstimatorForm;
        this.calculaterResponse = result?.calculaterResponse;
      });
    }
  }

  onSaveDraft(): void {
    this.isSubmitLoading = true;
    this.bookingsService.updateBooking(this.bookingId, this.booking)
      .subscribe(() => {
        this.navigateToDashboard();
      },
        (error) => this.notificationService.error(error))
      .add(() => (this.isSubmitLoading = false));
  }

  openSupportDialog(): void {
    this.googleAnalyticsService.chooseLiveBookingAssistance("book with an expert");
    this.supportRequestsService.openSupportDialogIfNeeded(0, this.createSupportRequestDialogData(false));
  }

  private setPanelStatus(state: boolean, panel: BookingFlowPanels): void {
    if (!state) {
      let invalid = false;
      switch (panel) {
        case this.bookingFlowPanels.cargoDetails:
          invalid = this.bookingForm.get('containers').invalid;
          if (invalid) {
            this.markFormArraysAsTouched(this.bookingForm.get('containers') as FormArray);
            this.childComponent.markAllInputsAsTouched();
          }
          break;
        case this.bookingFlowPanels.contacts:
          invalid = this.isContactsPannelInvalid();
          break;
        case this.bookingFlowPanels.services:
          break;
        default:
          break;
      }

      this.isError[panel] = invalid;

      if (invalid) {
        this.isSuccess[panel] = false;
      }
    }
  }

  private markFormArraysAsTouched(formArray: FormGroup | FormArray): void {
    if (formArray) {
      this.markFormGroupAsTouched(formArray);
    }
  }

  private markFormGroupAsTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  private isContactsPannelInvalid(): boolean {
    return this.shipperContact == null
      || this.consigneeContact == null || this.notifyPartyContact == null;
  }

  private isAddressesPannelInvalid(): boolean {
    return (this.isDoorPickup && this.pickupContact == null)
      || (this.isDoorDelivery && this.deliveryContact == null);
  }

  private createCommodityForm(commodity?: Commodity): FormGroup {
    return this.fb.group({
      id: [commodity ? commodity.id : null],
      packageTypeCode: [
        commodity && commodity.packageTypeCode ? commodity.packageTypeCode : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL ? [] : Validators.required,
      ],
      commodityObject: [commodity?.commodityTypeCode ? { id: commodity.commodityTypeCode, description: commodity.commodityDescription } as HarmonizedSystemCode : null, Validators.required],
      commodityDescription: [commodity ? commodity.commodityDescription : null],
      commodityTypeCode: [
        commodity ? commodity.commodityTypeCode : null,
        Validators.required,
      ],
      numberOfPackages: [
        commodity ? commodity.numberOfPackages : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL ? [] : [Validators.required, Validators.min(1)],
      ],
      productValue: [
        commodity ? commodity.productValue : null
      ],
      productValueCurrencyCode: [
        commodity && commodity.productValueCurrencyCode
          ? commodity.productValueCurrencyCode
          : CurrencyCode.USD,
        Validators.required,
      ],
      volume: [
        commodity ? commodity.volume : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? [Validators.required, this.validatorsService.decimalValidator]
          : [],
      ],
      volumeUnitCode: [
        commodity && commodity.volumeUnitCode
          ? commodity.volumeUnitCode
          : VolumeUnit.CBM,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      weight: [
        commodity ? commodity.weight : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? [Validators.required, this.validatorsService.decimalValidator]
          : [],
      ],
      weightUnitCode: [
        commodity && commodity.weightUnitCode
          ? commodity.weightUnitCode
          : WeightUnit.KG,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      marksAndNumbers: [commodity ? commodity.marksAndNumbers : null],
    });
  }

  private setState(state): void {
    this.isProgress[state.panel] = state.loading;
    this.isValid[state.panel] = state.valid;

    if (state.error) this.isSuccess[state.panel] = false;
    if (state.success) this.isError[state.panel] = false;

    this.isSuccess[state.panel] = state.success;
    this.isError[state.panel] = state.error;

    this.bookingFlowService.setPanelState(state);

    let nonValids = this.isValid.filter(x => x === false);
    const isSuccessTemp = [...this.isSuccess].slice(0, -1);
    let nonSuccesses = isSuccessTemp.filter(x => x === false);

    this.bookingFlowService.setValidState(nonValids.length === 0 && nonSuccesses.length === 0)
  }

  private toNext(state: any): void {
    this.isExpanded[state.panel] = false;

    switch (state.panel as BookingFlowPanels) {
      case this.bookingFlowPanels.cargoDetails:
        this.isExpanded[state.panel + 1] = true;
        break;
      case this.bookingFlowPanels.contacts:
        if (this.isAdditionalServicesEnabled) {
          this.isExpanded[state.panel + 1] = true;
        } else {
          this.isManualExpand = true;
        }
        break;
      default:
        break;
    }
  }

  private updateBooking(state: BookingFlowPanelState): void {
    const booking = this.bookingForm.value as Booking;
    let hasSameCommodities = false;

    booking.containers.forEach(x => {
      if (x.collectionDate) {
        x.collectionDate = this.toIsoDateTimeString(x.collectionDate);
      }
      if (x.cargoExpectedReady) {
        x.cargoExpectedReady = this.toIsoDateTimeString(x.cargoExpectedReady);
      }

      if (this.deliveryContact) {
        x.dropContact = this.deliveryContact
      }

      if (this.pickupContact) {
        x.collectContact = this.pickupContact
      }

      if (this.shipmentTypeCode !== ShipmentType.AIR && this.shipmentTypeCode !== ShipmentType.LCL) {
        hasSameCommodities = new Set(x.commodities.map(({ commodityTypeCode, packageTypeCode }) =>
          `commodityTypeCode|${commodityTypeCode}|packageTypeCode|${packageTypeCode}`)).size < x.commodities.length
      }
    });

    if (hasSameCommodities) {
      this.notificationService.error('Commodities code and package type combination should be unique for container.');

      state.error = true;
      state.loading = false;
      this.setState(state);

      return null;
    }

    booking.notes.forEach(note => {
      booking.notes = note.value.trim() == '' ?
        booking.notes.filter(n => n != note) : booking.notes;
    });

    if (this.shipmentTypeCode == ShipmentType.FCL) {
      booking.containers.forEach(container => {
        let selectedMaxWeight = 0;
        container.commodities.forEach(commodity => {
          selectedMaxWeight = selectedMaxWeight + Number(commodity.weight);
        });

        container.cargoGrossWeight = selectedMaxWeight;
      });
    }

    if (state.valid) {
      this.sendUpdateRequest(booking, state)
    } else {
      state.error = true
      state.loading = false
      this.setState(state)
    }
  }

  private toIsoDateTimeString(date): string {
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  private sendUpdateRequest(booking: Booking, state: BookingFlowPanelState): void {
    of(null)
      .pipe(
        switchMap(() => this.bookingsService.updateBooking(this.bookingId, booking)),
        switchMap(() => this.bookingsService.getSummaryById(this.bookingId)),
        tap((x: BookingSummary) => { this.bookingSummary = x; }),
      )
      .subscribe((response) => {
        state.success = true;
        state.loading = false;
      },
        (error) => {
          state.error = true;
          state.loading = false;
        }).add(() => {
          this.setState(state);
        });
  }

  private setSelectedContact(contactType: ContactType, selectedContact: Contact): void {
    switch (contactType) {
      case ContactType.shipper:
        this.shipperContact = selectedContact;
        break;
      case ContactType.consignee:
        this.consigneeContact = selectedContact;
        break;
      case ContactType.notifyParty:
        this.notifyPartyContact = selectedContact;
        break;
      case ContactType.collect:
        this.pickupContact = selectedContact;
        break;
      case ContactType.drop:
        this.deliveryContact = selectedContact;
        break;
      default:
        break;
    }
  }

  private getBookingContacts(bookingId: string): void {
    this.bookingContactsService
      .getByBookingId(bookingId)
      .subscribe(
        (x: Array<Contact>) => {
          this.setBookingContacts(x);
        },
        (error) => {
          this.notificationService.error(error);
        }
      );
  }

  private setBookingContacts(contacts: Array<Contact>): void {
    contacts.forEach((x) => this.setBookingContactData(x.contactTypeCode, x));
  }

  private setBookingContactData(
    contactType: ContactType,
    bookingContact: Contact
  ): void {
    if (contactType === ContactType.shipper) {
      this.shipperContact = bookingContact;
      if (
        this.booking.serviceType > 0 &&
        this.utilityService.isDoorPickupService(this.booking.serviceType)
      ) {
        this.assignShipperContactToCollectContact(this.shipperContact);
      }
    } else if (contactType === ContactType.consignee) {
      this.consigneeContact = bookingContact;
      if (
        this.booking.serviceType > 0 &&
        this.utilityService.isDoorDeliveryService(this.booking.serviceType)
      ) {
        this.assignConsigneeContactToDropContact(this.consigneeContact);
      }
    } else if (contactType === ContactType.notifyParty) {
      this.notifyPartyContact = bookingContact;
    } else if (contactType === ContactType.drop) {
      this.deliveryContact = bookingContact;
    }
  }

  private assignShipperContactToCollectContact(shipperContact: Contact): void {
    let collect = { ...shipperContact };
    if (shipperContact != null) {
      collect.id = null;
      collect.contactTypeCode = ContactType.collect;
    } else {
      collect = null;
    }

    this.containersFormArray?.controls.forEach((x) => {
      x.get("collectContact").patchValue(collect);
    });
    this.pickupContact = collect;
    this.isContactChanged = true;
  }

  private assignConsigneeContactToDropContact(consigneeContact: Contact): void {
    let drop = { ...consigneeContact };
    if (consigneeContact != null) {
      drop.id = null;
      drop.contactTypeCode = ContactType.drop;
    } else {
      drop = null;
    }

    this.containersFormArray?.controls.forEach((x) => {
      x.get("dropContact").patchValue(drop);
    });
    this.deliveryContact = drop;
    this.isContactChanged = true;
  }

  private getBookingByIdAfterAdditionalService(bookingId: string, data: any): void {
    this.isSuccess[this.bookingFlowPanels.services] = false;
    this.isProgress[this.bookingFlowPanels.services] = true;
    this.selectedAdditionalService = data?.additonalServiceType ?? undefined;
    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getSummaryById(bookingId)),
        tap((x: BookingSummary) => { this.bookingSummary = x; }),
        switchMap(() => this.bookingsService.getById(bookingId)))
      .subscribe((booking: Booking) => {
        this.booking = { ...this.booking };
        this.booking.bookingAdditionalServiceCharges = [...booking.bookingAdditionalServiceCharges];
        if (this.booking.bookingAdditionalServiceCharges?.length > 0) {
          this.isSuccess[this.bookingFlowPanels.services] = true;
        }
      },
        (error) => this.notificationService.error(error))
      .add(() => {
        this.isLoading = false;
        this.isProgress[this.bookingFlowPanels.services] = false;
        this.selectedAdditionalService = undefined
      });
  }

  private getBookingById(id: string): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getSummaryById(id)),
        tap((x: BookingSummary) => { this.bookingSummary = x; }),
        switchMap(() => this.getOrganizationById(this.bookingSummary.organizationId)),
        tap((x: Organization) => {
          this.isCreditOrganization = x.creditOrganization;
          this.organization = x;
        }),
        switchMap(() => this.bookingsService.getById(id)))
      .subscribe((booking: Booking) => {
        const updateBookingModel = this.prepareBookingViewModel(booking);
        this.updateBookingForm(updateBookingModel);
        this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData(false));

        this.googleAnalyticsService.bookingProperties(this.bookingSummary);
        this.googleAnalyticsService.bookingSelectItem(this.bookingSummary);
        this.googleAnalyticsService.bookingBeginCheckout(this.bookingSummary);
        this.googleAnalyticsService.startCheckOutStepCargoDetail();
      },
        (error) => this.notificationService.error(error))
      .add(() => {
        this.isLoading = false;
        this.getDefaultCountryForCalculater();
      });
  }

  private getOrganizationById(organizationId: string): Observable<Organization | any> {
    return this.organizationsService.getOrganization(organizationId);
  }

  private getIsAdditionalServicesEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isAdditionalServicesEnabled;
      case 'sb':
        return environment.sb.isAdditionalServicesEnabled;
      default:
        return environment.isAdditionalServicesEnabled;
    }
  }

  private prepareBookingViewModel(booking: Booking): BookingViewModel {
    const updateBookingViewModel: BookingViewModel = new BookingViewModel();
    updateBookingViewModel.customerReferenceId = booking.customerReferenceId;
    updateBookingViewModel.isTermsAndConditionsConfirmed =
      booking.isTermsAndConditionsConfirmed;
    updateBookingViewModel.containers = booking.containers;
    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (quotesQueryString) {
      const quotesQuery = JSON.parse(quotesQueryString) as QuotesQuery;
      if (quotesQuery.searchId == this.bookingSummary.quoteSearchId)
        updateBookingViewModel.containers.forEach(container => {
          if (container.collectionDate == null) {
            container.collectionDate = quotesQuery.fromDate;
          }
        });
    }
    updateBookingViewModel.anotherReferences = booking.anotherReferences;
    updateBookingViewModel.notes = booking.notes;
    this.shipmentTypeCode = booking.shipmentTypeCode;
    this.isDoorPickup = this.utilityService.isDoorPickupService(
      booking.serviceType
    );
    this.isDoorDelivery = this.utilityService.isDoorDeliveryService(
      booking.serviceType
    );
    this.booking = booking;
    this.shipperContact = booking.shipperContact;
    this.consigneeContact = booking.consigneeContact;
    this.notifyPartyContact = booking.notifyPartyContact;
    this.pickupContact = booking.containers[0].collectContact;
    this.deliveryContact = booking.containers[0].dropContact;

    return updateBookingViewModel;
  }

  private getDefaultCountryForCalculater(): void {
    this.selectedCountry = this.booking?.fromBookingSearch?.country;
  }

  private updateBookingForm(updateBookingModel: BookingViewModel): void {
    this.bookingForm = this.fb.group({
      customerReferenceId: [
        updateBookingModel?.customerReferenceId
          ? updateBookingModel.customerReferenceId
          : "",
        Validators.required,
      ],
      isTermsAndConditionsConfirmed: [
        updateBookingModel?.isTermsAndConditionsConfirmed
          ? updateBookingModel?.isTermsAndConditionsConfirmed
          : true,
      ],
      anotherReferences: this.fb.array([]),
      notes: this.fb.array([]),
      containers: this.fb.array([]),
    });
    this.setAnotherReferences(updateBookingModel.anotherReferences);
    this.setNotes(updateBookingModel.notes);
    this.setContainers(updateBookingModel.containers);
  }

  private setAnotherReferences(anotherReferences: AnotherReference[]): void {
    anotherReferences.map((anotherReference) => {
      this.anotherReferencesFormArray.push(
        this.createAnotherReferencesForm(anotherReference)
      );
    });
  }

  private setNotes(notes: Note[]): void {
    if (notes.length > 0) {
      notes.map((note) => {
        this.notesFormArray.push(this.createNotesForm(note));
      });
    } else {
      this.notesFormArray.push(this.createNotesForm());
    }
  }

  private setContainers(containers: Container[]): void {
    containers.map((container) => {
      this.containersFormArray.push(this.createContainerForm(container));
    });
  }

  private createAnotherReferencesForm(
    anotherReference?: AnotherReference
  ): FormGroup {
    return this.fb.group({
      name: [anotherReference ? anotherReference.name : ""],
      value: [anotherReference ? anotherReference.value : ""],
    });
  }

  private createNotesForm(note?: Note): FormGroup {
    return this.fb.group({
      id: [note ? note.id : null],
      bookingId: [note ? note.bookingId : ""],
      value: [note ? note.value : ""],
      createTimeUtc: [note ? note.createTimeUtc : ""],
      updateTimeUtc: [note ? note.updateTimeUtc : ""],
      noteAccessType: [
        note ? note.noteAccessType : BookingNoteAccessType.customer,
      ],
      userId: [note ? note.userId : null],
      userName: [note ? note.userName : ""],
    });
  }

  private createContainerForm(container?: Container): FormGroup {
    if (container != null && container.dropContact == null) {
      container.dropContact = this.getDropContactByConsigneeContact();
      this.deliveryContact = container.dropContact;
    }

    if (container != null && container.collectContact == null) {
      container.collectContact = this.getCollectContactByShipperContact();
      this.pickupContact = container.collectContact;
    }

    return this.fb.group({
      id: [container ? container.id : ""],
      equipmentTypeCode: [
        container ? container.equipmentTypeCode : "",
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoGrossWeight: [
        container ? container.cargoGrossWeight : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoGrossWeightUnitCode: [
        container && container.cargoGrossWeightUnitCode
          ? container.cargoGrossWeightUnitCode
          : WeightUnit.KG,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      cargoExpectedReady: [container ? container.cargoExpectedReady : null],
      collectionReference: [container ? container.collectionReference : ""],
      collectionDate: [
        container ? container.collectionDate : null,
        this.isDoorPickup ? Validators.required : null,
      ],
      collectContact: [
        container ? container.collectContact : null,
        this.isDoorPickup ? Validators.required : null,
      ],
      equipmentTypeDescription: [
        container ? container.equipmentTypeDescription : null,
      ],
      dropContact: [
        container ? container.dropContact : null,
        this.isDoorDelivery ? Validators.required : null,
      ],
      commodities: this.fb.array(
        this.setCommodities(container.commodities ?? undefined)
      ),
    });
  }

  private getCollectContactByShipperContact(): any {
    if (!this.utilityService.isDoorPickupService(this.booking.serviceType) || this.shipperContact == null)
      return null;

    const collect = { ...this.shipperContact };
    collect.id = null;
    collect.contactTypeCode = ContactType.collect;
    return collect;
  }

  private getDropContactByConsigneeContact(): any {
    if (!this.utilityService.isDoorDeliveryService(this.booking.serviceType) || this.consigneeContact == null)
      return null;

    const drop = { ...this.consigneeContact };
    drop.id = null;
    drop.contactTypeCode = ContactType.drop;
    return drop;
  }

  private setCommodities(commodities: Commodity[]): FormGroup[] {
    return commodities.map((commodity) => {
      return this.createCommodityForm(commodity);
    });
  }

  private updateGoogleAnalyticsStep(state: BookingFlowPanelState): void {
    const booking = this.bookingForm.value as Booking;

    if (state.panel == BookingFlowPanels.cargoDetails && state.valid) {
      this.googleAnalyticsService.completeCheckOutStepCargoDetail(booking.containers[0].collectionDate);
      this.googleAnalyticsService.startCheckOutStepContactDetail();
      this.googleAnalyticsService.bookingProperties(this.bookingSummary);
    }
    else if (state.panel == BookingFlowPanels.contacts && state.valid) {
      this.googleAnalyticsService.completeCheckOutStepContactDetail();
    }
  }

  private createSupportRequestDialogData(isPopupAutoOpened: boolean): SupportRequestDialogData {
    return {
      isPopupAutoOpened: isPopupAutoOpened,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: this.booking.bolOrderNumber,
      quoteSearchId: this.booking.quoteSearchId
    } as SupportRequestDialogData
  }

  private navigateToDashboard(): void {
    this.router.navigate(["/dashboard"]);
  }
}
