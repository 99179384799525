import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ship4wd/ngx-authorization';
import { NotificationService } from '@ship4wd/ngx-common';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { SalesforceService } from '../../../shared/services/salesforce.service';
import { QuotesService } from '../../../desktop/quotes/quotes.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private appSettingsService: AppSettingsService,
    private salesforceService: SalesforceService,
    private userInfoService: UserInfoService,
    private quotesService: QuotesService
  ) { }

  ngOnInit() {
    this.authService.removeToken();
    this.appSettingsService.removeSettings();
    this.userInfoService.removeSettings();
    this.salesforceService.onUserLogout();
    this.quotesService.removeQuotesResultsFromStorage();
    this.notificationService.success('You have been successfully logged out');
    this.router.navigate(['/login']);
  }
}
