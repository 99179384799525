import { Component, ElementRef, Type, ViewChild, ViewContainerRef, ViewEncapsulation, ComponentFactoryResolver, ComponentRef, Injector, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { RollupDialogService } from './rollup-dialog.service';
import { ROLLUP_DIALOG_DATA } from './rollup-dialog.model';

@Component({
  selector: 's4d-rollup-dialog',
  templateUrl: './rollup-dialog.component.html',
  styleUrls: ['./rollup-dialog.component.scss'],
  animations: [
    trigger('slideFromBottom', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
      state('enter', style({ transform: 'translateY(0%)', opacity: 1 })),
      state('leave', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition('void => enter', animate('200ms ease-in-out')),
      transition('enter => void', animate('600ms ease-in-out')),
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  host: { '[@slideFromBottom]': 'animationState' }
})
export class RollupDialogComponent implements OnInit {
  @ViewChild('target', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;
  @Input() dialogState: Observable<"open" | "close">;
  @Input() set class(value: string | string[]) {
    if (value instanceof Array) {
      this._class = value.join(" ")
      return;
    }

    this._class = value;
  } 

  animationState: 'enter' | 'leave' = 'enter';
  _class: string;

  isMultipleDialogsOpened: boolean = true;

  constructor(
    private el: ElementRef,
    private rollupDialogService: RollupDialogService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.dialogState.subscribe(state => {
      if (state === 'open') {
        this.animationState = 'enter';
      } else if (state === 'close') {
        this.animationState = 'leave';
      }
    });

    this.isMultipleDialogsOpened = this.rollupDialogService.getIsMultipleDialogsOpened();
  }

  onOverlayClick(event: Event): void {
    if (event.target === this.el.nativeElement.firstChild) {
      this.rollupDialogService.close();
    }
  }

  onSwipe(): void {
    this.rollupDialogService.close();
  }

  setChildComponent(component: Type<any>, data?: any): void {
    this.viewContainerRef.clear();

    const injector = Injector.create({
      providers: [{ provide: ROLLUP_DIALOG_DATA, useValue: data }]
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<any> = this.viewContainerRef.createComponent(componentFactory, null, injector);

    if ('data' in componentRef.instance) {
      componentRef.instance.data = data;
    }
  }
}
