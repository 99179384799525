import { Pipe, PipeTransform } from '@angular/core';
import { AccessRoleType } from '../shared.model';

@Pipe({ name: 'accessRoleTypeName' })
export class AccessRoleTypeNamePipe implements PipeTransform {
  private accessRoleTypeNames = {};

  constructor() {
    this.accessRoleTypeNames[AccessRoleType.bookingRead] = 'Booking Read';
    this.accessRoleTypeNames[AccessRoleType.bookingCreate] = 'Booking Create';
    this.accessRoleTypeNames[AccessRoleType.bookingUpdate] = 'Booking Update';
    this.accessRoleTypeNames[AccessRoleType.bookingCancel] = 'Booking Cancel';
    this.accessRoleTypeNames[AccessRoleType.teamMemberInvite] = 'Team Member Invite';
    this.accessRoleTypeNames[AccessRoleType.teamMemberRemove] = 'Team Member Remove';
    this.accessRoleTypeNames[AccessRoleType.teamMemberUpdate] = 'Team Member Update';
    this.accessRoleTypeNames[AccessRoleType.admin] = 'Admin';
    this.accessRoleTypeNames[AccessRoleType.owner] = 'Owner';
    this.accessRoleTypeNames[AccessRoleType.agent] = 'Agent';
  }

  transform(value?: AccessRoleType): string {
    return this.accessRoleTypeNames[value] ?? '';
  }
}
