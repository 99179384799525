import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-search-icons',
  templateUrl: './quote-search-icons.component.html',
  styleUrls: ['./quote-search-icons.component.scss']
})
export class QuoteSearchIconsComponent implements OnInit {

  @Input() iconName: string;
  @Input() isMobile: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
