import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { FourtySeasPaymentService } from "../../../shared/payments/fourty-seas-payment.service";
import { CreditLine, CreditLineStatusCode } from "../../../shared/payments/payments.model";
import { PaymentsService } from "../../../shared/payments/payments.service";
import { AppSettingsService } from "../../../shared/services/app-settings/app-settings.service";
import { CreditLineStatusTransition } from "../../../shared/shared.model";

@Component({
  selector: "app-apply-credit-line",
  templateUrl: "./apply-credit-line.component.html",
  styleUrls: ["./apply-credit-line.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ApplyCreditLineComponent implements OnInit {
  organizationId: string;
  creditLine: CreditLine;
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private settingsService: AppSettingsService
  ) {
    this.organizationId = this.settingsService.getSettings().organizationId;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading = true;

    this.paymentsService
      .getCreditLineByOrganization(this.organizationId)
      .subscribe((data: CreditLine) => {
        this.creditLine = data;
      })
      .add(() => (this.isLoading = false));
  }

  initCreditLine(): void {
    this.isLoading = true;

    var transition = null;
    if (
      this.creditLine?.statusId &&
      this.creditLine.statusId == CreditLineStatusCode.approved
    ) {
      transition = CreditLineStatusTransition.increaseRequest;
    }

    this.fourtySeasPaymentService
      .initCreditLine(this.organizationId, null, transition)
      .subscribe(
        () => { },
        () => {
          this.openPopup(
            "Error. An error occurred while initialising your credit line, try again."
          );
        }
      )
      .add(() => (this.isLoading = false));
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok",
      },
    });
  }
}
