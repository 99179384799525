import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@ship4wd/ngx-authorization';
import { NotificationService, ValidationService } from '@ship4wd/ngx-common';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import {
  AccessRoleType,
  SelectItem,
  TeamMemberStatusCode,
} from '../../../../shared/shared.model';
import { TeamMember, TeamMemberViewModel } from '../../manage-organization.model';
import { TeamMemberService } from './team-member.service';

@Component({
  selector: 'app-team-member-dialog',
  templateUrl: './team-member-dialog.component.html',
  styleUrls: ['./team-member-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamMemberDialogComponent implements OnInit {
  teamMember: TeamMember;
  teamMemberForm: FormGroup;
  isLoading = false;

  memberStatusCodes: SelectItem[] = [
    {
      name: TeamMemberStatusCode[TeamMemberStatusCode.suspended],
      value: TeamMemberStatusCode.suspended
    },
    {
      name: TeamMemberStatusCode[TeamMemberStatusCode.active],
      value: TeamMemberStatusCode.active
    }
  ];

  teamAccessRoles: SelectItem[] = [
    {
      name: AccessRoleType[AccessRoleType.owner],
      value: AccessRoleType.owner
    },
    {
      name: AccessRoleType[AccessRoleType.agent],
      value: AccessRoleType.agent
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<TeamMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    private teamMemberService: TeamMemberService,
    private settingsService: AppSettingsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.teamMember = this.data?.teamMember;
    this.createTeamMemberForm();
  }

  getInitialName() {
    if (this.teamMember && this.teamMember.firstName) {
      return this.teamMember.firstName.charAt(0).toUpperCase();
    }
    return '';
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.validationService.validate(this.teamMemberForm);
    if (!this.teamMemberForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }

    const teamMember = this.prepareTeamMemberViewModel();
    this.updateTeamMember(teamMember);
  }

  private prepareTeamMemberViewModel() {
    const teamMember = this.teamMemberForm.getRawValue();
    let teamMemberViewModel = new TeamMemberViewModel();
    teamMemberViewModel.firstName = teamMember.firstName;
    teamMemberViewModel.lastName = teamMember.lastName;
    teamMemberViewModel.nickName = teamMember.nickName;
    teamMemberViewModel.email = teamMember.email;
    teamMemberViewModel.memberStatus = teamMember.memberStatus;
    teamMemberViewModel.organizationId = this.settingsService.getSettings()?.organizationId;
    if (teamMember.roles) {
      let roles = '';
      teamMember.roles.forEach((x) => {
        roles += x + ',';
      });
      teamMemberViewModel.roles = roles.slice(0, -1);
    }
    return teamMemberViewModel;
  }

  private updateTeamMember(teamMember: TeamMemberViewModel) {
    this.isLoading = true;
    this.teamMemberService
      .updateTeamMember(this.teamMember?.id, teamMember)
      .subscribe(() => {
        this.notificationService.success('Team member has been successfully changed.');
        this.dialogRef.close({
          isSuccess: true
        });
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false)
  }

  private createTeamMemberForm() {
    this.teamMemberForm = this.fb.group({
      firstName: [
        { value: this.getPropertyValue('firstName'), disabled: true },
        Validators.required,
      ],
      lastName: [
        { value: this.getPropertyValue('lastName'), disabled: true },
        Validators.required,
      ],
      nickName: [this.getPropertyValue('nickName')],
      roles: [this.getPropertyValue('teamAccessRoles'), Validators.required],
      email: [
        { value: this.getPropertyValue('email'), disabled: true },
        [Validators.required, Validators.email],
      ],
      memberStatus: [
        {
          value: this.getPropertyValue('memberStatus'),
          disabled: this.getPropertyValue('memberStatus') == TeamMemberStatusCode.pending ? true : false
        },
        Validators.required,
      ],
    });
  }

  private getPropertyValue(propertyName: string) {
    if (propertyName === 'teamAccessRoles') {
      if (this.teamMember[propertyName]) {
        const roles: AccessRoleType[] = [];
        this.teamMember[propertyName].forEach((x) => {
          roles.push(x.accessRoleType);
        });
        return roles;
      }
    }
    else {
      return this.teamMember[propertyName] ?? '';
    }
  }
}
