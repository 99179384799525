<div class="container-fluid quote-search-flow-parameters px-3">
  <div class="quote-search-flow-parameters-container" [formGroup]="quoteSearchForm">
    <div class="row mx-0">
      <div class="col-12 px-0">
        <mat-form-field class="details-unit flow-param-field align-items-center justify-content-center d-flex">
          <div class="position-absolute w-100" *ngIf="!currentShippingType">
            <ng-template [ngTemplateOutlet]="shipmentTypePlaceholder"></ng-template>
          </div>
          <mat-select panelClass="flow-param-field-panel" [formControl]="form.shipmentType" (opened)="onOpenClick()"
            (closed)="onCloseSelect()">
            <mat-select-trigger *ngIf="currentShippingType">
              <div class="row h-100 m-0 gap-12">
                <div class="col-auto d-flex align-items-center p-0">
                  <div class="flow-param-field-icon-wrapper">
                    <i class="flow-param-field-icon s4d-icon" [ngClass]="'s4d-' + currentShippingType.icon"></i>
                  </div>
                </div>
                <div class="col d-flex align-items-center p-0">
                  <div class="row w-100 m-0">
                    <div class="col-12 px-0">
                      <span class="flow-param-field-result">{{currentShippingType.name}}</span>
                    </div>
                    <div class="col-12 px-0">
                      <span class="flow-param-field-description">Shipment Type</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-select-trigger>
            <mat-option class="flow-param-field-option" *ngFor="let item of shippingTypes" [value]="item.value"
              [aria-label]="item.description">
              <div class="d-flex align-items-center gap-3">
                <div class="flow-param-field-icon-wrapper">
                  <i class="flow-param-field-icon s4d-icon" [ngClass]="'s4d-' + item.icon"></i>
                </div>
                <span class="flow-param-field-title">{{ item.description }}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-3 px-0">
        <div class="flow-param-field" (click)="onClickParam(quoteSearchParameters.from)"
          [ngClass]="{'invalid':isError[quoteSearchParameters.from], disabled: !form.shipmentType.value}">
          <div *ngIf="!isSelected[quoteSearchParameters.from]" class="row h-100 m-0 gap-12">
            <div class="col-auto d-flex align-items-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon s4d-map"></i>
              </div>
            </div>
            <div class="col d-flex align-items-center p-0">
              <span class="flow-param-field-title">Select Origin</span>
            </div>
          </div>
          <div *ngIf="isSelected[quoteSearchParameters.from]" class="row h-100 m-0 gap-12">
            <div class="col-auto d-flex align-items-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon"
                  [ngClass]="paramsTitle[quoteSearchParameters.from]?.locationType | locationTypeS4DIcon"></i>
              </div>
            </div>
            <div class="col d-flex align-items-center p-0">
              <div class="row w-100 m-0">
                <div class="col-12 px-0">
                  <span
                    class="flow-param-field-result">{{getCutted(paramsTitle[quoteSearchParameters.from]?.result)}}</span>
                </div>
                <div class="col-12 px-0">
                  <span class="flow-param-field-description">{{paramsTitle[quoteSearchParameters.from]?.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 px-0">
        <div class="flow-param-field" (click)="onClickParam(quoteSearchParameters.to)"
          [ngClass]="{'invalid':isError[quoteSearchParameters.to], disabled: !form.shipmentType.value}">
          <div *ngIf="!isSelected[quoteSearchParameters.to]" class="row h-100 m-0 gap-12">
            <div class="col-auto d-flex align-items-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon s4d-map"></i>
              </div>
            </div>
            <div class="col d-flex align-items-center p-0">
              <span class="flow-param-field-title">Select Destination</span>
            </div>
          </div>
          <div *ngIf="isSelected[quoteSearchParameters.to]" class="row h-100 m-0 gap-12">
            <div class="col-auto d-flex align-items-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon"
                  [ngClass]="paramsTitle[quoteSearchParameters.to]?.locationType | locationTypeS4DIcon"></i>
              </div>
            </div>
            <div class="col d-flex align-items-center p-0">
              <div class="row w-100 m-0">
                <div class="col-12 px-0">
                  <span
                    class="flow-param-field-result">{{getCutted(paramsTitle[quoteSearchParameters.to]?.result)}}</span>
                </div>
                <div class="col-12 px-0">
                  <span class="flow-param-field-description">{{paramsTitle[quoteSearchParameters.to]?.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mt-3 ps-0">
        <div class="flow-param-field vertical" (click)="onClickParam(quoteSearchParameters.cargo)"
          [ngClass]="{'invalid':isError[quoteSearchParameters.cargo], disabled: !form.shipmentType.value}">
          <div *ngIf="!isSelected[quoteSearchParameters.cargo] || !paramsTitle[quoteSearchParameters.cargo]?.title"
            class="row h-100 m-0">
            <div class="col-12 d-flex align-items-center justify-content-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon s4d-boxes-light"></i>
              </div>
            </div>
            <div class="col-12 d-flex align-items-start justify-content-center p-0">
              <span class="flow-param-field-title">Cargo Details</span>
            </div>
          </div>
          <div *ngIf="isSelected[quoteSearchParameters.cargo] && paramsTitle[quoteSearchParameters.cargo]?.title"
            class="row h-100 m-0">
            <div class="col-12 d-flex align-items-center justify-content-center p-0">
              <span class="flow-param-field-result">{{paramsTitle[quoteSearchParameters.cargo]?.result}}</span>
            </div>
            <div class="col-12 d-flex align-items-start justify-content-center p-0">
              <span class="flow-param-field-description">{{paramsTitle[quoteSearchParameters.cargo]?.title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mt-3 pe-0">
        <div class="flow-param-field vertical" (click)="onClickParam(quoteSearchParameters.date)"
          [ngClass]="{'invalid':isError[quoteSearchParameters.date], disabled: !form.shipmentType.value}">
          <div *ngIf="!isSelected[quoteSearchParameters.date]" class="row h-100 m-0">
            <div class="col-12 d-flex align-items-center justify-content-center p-0">
              <div class="flow-param-field-icon-wrapper">
                <i class="flow-param-field-icon s4d-icon s4d-calendar s-16"></i>
              </div>
            </div>
            <div class="col-12 d-flex align-items-start justify-content-center p-0">
              <span class="flow-param-field-title">Departure Date</span>
            </div>
          </div>
          <div *ngIf="isSelected[quoteSearchParameters.date]" class="row h-100 m-0">
            <div class="col-12 d-flex align-items-center justify-content-center p-0">
              <span class="flow-param-field-result">{{paramsTitle[quoteSearchParameters.date]?.result}}</span>
            </div>
            <div class="col-12 d-flex align-items-start justify-content-center p-0">
              <span class="flow-param-field-description">{{paramsTitle[quoteSearchParameters.date]?.title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 px-0">
        <button mat-button class="flow-param-confirm" [ngClass]="{disabled: !form.shipmentType.value}"
          (click)="onViewQuotes()">
          Search Quotes
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #shipmentTypePlaceholder>
  <div class="row h-100 m-0 gap-12">
    <div class="col-auto d-flex align-items-center p-0">
      <div class="flow-param-field-icon-wrapper">
        <i class="flow-param-field-icon s4d-icon s4d-fcl"></i>
      </div>
    </div>
    <div class="col d-flex align-items-center p-0">
      <div class="col-10 d-flex align-items-center p-0">
        <span class="flow-param-field-title">Shipment Type</span>
      </div>
    </div>
  </div>
</ng-template>