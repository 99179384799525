import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { NotificationService } from '@ship4wd/ngx-common';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CompanySearchQuery, FoundCompany } from '../../../shared/company-search/company-search.model';
import { CompanySearchService } from '../../../shared/company-search/company-search.service';
import { OrganizationsService } from '../organizations.service';
import { Organization, SignUpCreateOrganizationFields } from '../../../shared/organizations/organization.model';
import { GlobalTradeTypeCode, LeadSourceCode, LeadStateCode } from '../../../shared/shared.model';
import { UserProfile } from '../../../shared/accounts/accounts.model';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'org-overlay-panel' }
    }
  ]
})
export class OrganizationCreateComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @Input() isFromSso: boolean;
  @Input() isNoOrganizations: boolean = true;
  @Output() cancel = new EventEmitter();

  isSubmit: boolean = false;
  isCompaniesLoading: boolean = false;
  isCompanySelected: boolean = false;

  organizationForm: FormGroup;
  mainAddress: string = '';
  filteredCompanies: Observable<FoundCompany[]>;
  companies: FoundCompany[];
  selectedCompany: FoundCompany;
  countryCodesWithStates: string[] = ['US', 'CA'];
  SignUpCreateOrganizationFields = SignUpCreateOrganizationFields;
  errorStatuses: Record<SignUpCreateOrganizationFields, boolean> = {
    [SignUpCreateOrganizationFields.fullAddress]: false,
    [SignUpCreateOrganizationFields.companyName]: false,
  };
  isStartedTyping: Record<SignUpCreateOrganizationFields, boolean> = {
    [SignUpCreateOrganizationFields.fullAddress]: false,
    [SignUpCreateOrganizationFields.companyName]: false,
  };

  constructor(
    private fb: FormBuilder,
    private companyService: CompanySearchService,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  get form(): any {
    return this.organizationForm.controls;
  }

  ngOnInit(): void {
    this.createOrganizationForm();

    this.filteredCompanies = this.form.company.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.fetchCompanies(value as string))
    );
  }

  displayCompanyFn(company: string): string {
    return company !== '' ? company : '';
  }

  getCompanyTooltip(company: FoundCompany): string {
    return company.companyName + ', ' + company.postalCode + ', ' + company.streetAddress;
  }

  fetchCompanies(company: string): Observable<FoundCompany[]> {
    if (company === this.selectedCompany?.companyName) {
      return of(this.companies);
    }

    this.isCompanySelected = false;
    this.selectedCompany = null;

    if (company.length >= 3 && environment.isDnbEnabled) {
      this.isCompaniesLoading = true;
      const query = {
        country: this.form.countryCode.value,
        city: this.form.city.value,
        state: this.form.state.value,
        companyName: company,
        pageNo: 1,
        pageSize: 25
      } as CompanySearchQuery;

      return this.companyService.getFoundCompanies(query).pipe(
        map(response => {
          this.isCompaniesLoading = false;

          this.companies = response.items;
          return response.items;
        })
      );
    } else {
      this.isCompaniesLoading = false;
      return of([]);
    }
  }

  onSelectCompany(company: FoundCompany): void {
    this.selectedCompany = company;
    this.isCompanySelected = true;
    if (this.isFromSso) {
      this.googleAnalyticsService.createAccountChooseCompanyName();
      this.isStartedTyping[SignUpCreateOrganizationFields.companyName] = false;
    }
  }

  onCancel(): void {
    this.organizationForm.reset();
    this.cancel.emit();
  }

  onSubmit(): void {
    this.organizationForm.markAllAsTouched();
    if (!this.organizationForm.valid) {
      if (this.isFromSso) {
        this.googleAnalyticsService.createAccountAddOrganizationCompleteError();
      }
      this.notificationService.error("Can't save changes because of an input errors, please check out the form");
      return;
    }

    this.isSubmit = true;
    const firstName = this.userProfile?.fullName.substring(0, this.userProfile?.fullName.indexOf(' '));
    const body: Organization = {
      leadSourceId: LeadSourceCode.internal,
      leadStateId: LeadStateCode.submit,
      company: {
        globalTradeTypeId: GlobalTradeTypeCode.both,
        name: this.selectedCompany ? this.selectedCompany.companyName : this.form.company.value.trim(),
        legalName: this.selectedCompany ? this.selectedCompany.companyName : '',
        country: this.form.country.value,
        countryCode: this.form.countryCode.value,
        city: this.form.city.value,
        street: this.selectedCompany ? this.selectedCompany.streetAddress : this.form.street.value,
        address2: '',
        state: this.form.state.value,
        postalCode: this.selectedCompany ? this.selectedCompany.postalCode : this.form.zipCode.value,
        taxId: '',
        dunsNumber: this.selectedCompany ? this.selectedCompany.duns : '',
        isTermsConfirmation: true,
        contact: {
          firstname:
            firstName == null || firstName == ''
              ? this.userProfile?.fullName.substring(this.userProfile?.fullName.indexOf(' ') + 1)
              : firstName,
          lastname: this.userProfile?.fullName.substring(this.userProfile?.fullName.indexOf(' ') + 1),
          email: this.userProfile?.email,
          phone: this.userProfile?.phoneNumber
        },
        additionalInformation: this.selectedCompany ? this.selectedCompany.additionalInformation : '',
        isInternalOrganization: false
      }
    };

    this.organizationsService.addOrganization(body).subscribe(
      () => {
        if (this.isFromSso) {
          this.googleAnalyticsService.createAccountAddOrganizationComplete();
        }
        location.reload();
      },
      error => {
        if (this.isFromSso) {
          this.googleAnalyticsService.createAccountAddOrganizationCompleteError();
        }
        this.notificationService.error(error);
        this.isSubmit = false;
      }
    );
  }

  onAddressChange(address: any): void {
    this.resetAddress();
    this.form.address.setValue('');
    this.form.address.setErrors(null);

    this.selectedCompany = null;

    for (let i in address.address_components) {
      const item = address.address_components[i];
      if (item['types'].indexOf('route') > -1) {
        this.mainAddress += ' ' + item['long_name'];
      } else if (item['types'].indexOf('street_number') > -1) {
        this.mainAddress += item['long_name'];
      } else if (item['types'].indexOf('locality') > -1) {
        this.form.city.setValue(item['long_name']);
      } else if (item['types'].indexOf('sublocality') > -1 && this.form.city.value === '') {
        this.form.city.setValue(item['long_name']);
      } else if (item['types'].indexOf('administrative_area_level_2') > -1 && this.form.city.value === '') {
        this.form.city.setValue(item['long_name']);
      } else if (item['types'].indexOf('country') > -1) {
        this.form.country.setValue(item['long_name']);
        this.form.countryCode.setValue(item['short_name']);
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        this.form.state.setValue(item['long_name']);
        this.form.city.value === '' ? this.form.city.setValue(item['long_name']) : null;
      } else if (item['types'].indexOf('postal_code') > -1) {
        this.form.zipCode.setValue(item.long_name);
      }
    }

    this.form.address.setValue(this.mainAddress);
    this.form.street.setValue(this.mainAddress);

    if (this.mainAddress === '') {
      this.form.address.setErrors({ street: true });
      this.resetAddress();
      this.googleAnalyticsService.createAccountError({
        stepNo: 4,
        stepName: 'add organization',
        fieldName: SignUpCreateOrganizationFields.fullAddress,
        elementName: 'social group',
      });
      this.isStartedTyping[SignUpCreateOrganizationFields.fullAddress] = false;
      return;
    }

    if (
      this.countryCodesWithStates.includes(this.form.countryCode.value) &&
      (this.form.state.value === null || this.form.state.value === '')
    ) {
      this.form.address.setErrors({ state: true });
      this.resetAddress();
      this.googleAnalyticsService.createAccountError({
        stepNo: 4,
        stepName: 'add organization',
        fieldName: SignUpCreateOrganizationFields.fullAddress,
        elementName: 'social group',
      });
      this.isStartedTyping[SignUpCreateOrganizationFields.fullAddress] = false;
      return;
    }

    this.form.company.enable();
    if (this.isFromSso) {
      this.googleAnalyticsService.createAccountChooseAddressType();
      this.isStartedTyping[SignUpCreateOrganizationFields.fullAddress] = false;
    }
  }

  getErrorMessage(control: string): string {
    const formControl = this.organizationForm.get(control);

    if (control === 'address') {
      if (formControl?.hasError('required')) {
        return 'You must enter an address';
      } else if (formControl?.hasError('street')) {
        return 'Select an address with a street address.';
      } else if (formControl?.hasError('state')) {
        return 'Select an address with a state for this country.';
      }
    }

    return '';
  }

  focusInCheckError(control: AbstractControl | null, field: SignUpCreateOrganizationFields) {
    this.errorStatuses[field] = Boolean(control?.invalid && control.touched);
  }

  focusOutCheckError(control: AbstractControl | null, field: SignUpCreateOrganizationFields): void {
    if (control?.invalid && !this.errorStatuses[field] && this.isFromSso) {
      this.googleAnalyticsService.createAccountError({
        stepNo: 4,
        stepName: 'add organization',
        fieldName: field,
        elementName: 'social group',
      });
    }
  }

  onInputCheckIsStartedTyping(control: AbstractControl | null, field: SignUpCreateOrganizationFields): void {
    if (!this.isStartedTyping[field] && this.isFromSso) {
      switch (field) {
        case SignUpCreateOrganizationFields.companyName:
          this.googleAnalyticsService.createAccountStartTypingCompanyName();
          break;
        case SignUpCreateOrganizationFields.fullAddress:
          this.googleAnalyticsService.createAccountStartTypingCompanyAddress();
          break;
      }
    }

    this.isStartedTyping[field] = control?.value.length > 0;
  }

  private resetAddress(): void {
    this.form.city.setValue('');
    this.form.country.setValue('');
    this.form.countryCode.setValue('');
    this.form.state.setValue('');
    this.form.zipCode.setValue('');
    this.form.company.setValue('');
    this.form.street.setValue('');

    this.mainAddress = '';
  }

  private createOrganizationForm(): void {
    this.organizationForm = this.fb.group({
      address: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      countryCode: [null, Validators.required],
      state: new FormControl(null),
      city: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(''),
      company: new FormControl({ value: '', disabled: true }, [Validators.required]),
      street: new FormControl('')
    });
  }
}
