import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { PriceTrend } from './price-history-chart.model';

@Component({
  selector: 'app-price-history-chart',
  templateUrl: './price-history-chart.component.html',
  styleUrls: ['./price-history-chart.component.scss']
})
export class PriceHistoryChartComponent implements OnInit {
  @Input() priceTrendInfo: PriceTrend;
  @Output() isShowPriceTrendChart = new EventEmitter<boolean>(false);

  chart: Chart;
  average: number;

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.priceTrendInfo) {
      this.generateChart();
    }
  }

  generateChart(): void {
    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    const xValues = this.priceTrendInfo.priceTrendWeeks.map(x => this.datePipe.transform(new Date(x.weekStartDate), 'MM/dd') || '');
    const datas = this.priceTrendInfo.priceTrendWeeks.map(x => Number(x.weeklyAveragePrice.toFixed(2)));
    const average = Number(this.priceTrendInfo?.averagePriceTrendWeeks.toFixed(2));

    const filteredData = datas.filter((value) => value > 0);
    const filteredLabels = xValues.filter((_, index) => datas[index] > 0);

    this.chart = new Chart('priceChart', {
      type: 'bar',
      data: {
        labels: filteredLabels,
        datasets: [{
          data: filteredData,
          minBarLength: 5,
          backgroundColor: (context) => context.dataIndex === context.dataset.data.length - 1 ? "#6e3df7" : '#d3c4fb',
          borderRadius: 3,
          hoverBackgroundColor: "#6e3df7",
          barPercentage: 0.8,
          barThickness: 10
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          annotation: {
            common: { drawTime: 'beforeDatasetsDraw' },
            annotations: {
              avgLine: {
                type: 'line',
                label: {
                  font: { weight: 1 },
                  display: false,
                  backgroundColor: '#e5e6ea',
                  color: 'black',
                  drawTime: 'afterDatasetsDraw',
                  content: () => [`$${average}`, 'avg.'],
                  callout: { display: true, borderColor: 'rgba(102, 102, 102, 0.5)', borderDash: [6, 6], borderWidth: 2, margin: 0 },
                  yAdjust: -8,
                  xAdjust: 0,
                },
                yMin: average,
                yMax: average,
                borderColor: 'gray',
                borderWidth: 1,
                enter({ element }, event) { element.label.options.display = true; element.options.borderColor = '#6e3df7'; return true; },
                leave({ element }, event) { element.label.options.display = false; element.options.borderColor = 'gray'; return true; },
                borderDash: [2, 2]
              },
            }
          },
          tooltip: {
            displayColors: false,
            mode: 'point',
            backgroundColor: "#e5e6ea",
            titleColor: 'black',
            bodyColor: 'black',
            callbacks: {
              title: () => '',
              afterLabel: (tooltipItem) => tooltipItem.label,
              label: () => '',
              beforeLabel: (tooltipItem) => (parseFloat(tooltipItem.formattedValue.replace(/,/g, '')) > 0) ? `$${tooltipItem.formattedValue}` : "N/A",
            },
            position: "average",
            xAlign: "center",
            yAlign: "bottom",
            bodyAlign: "center"
          },
        },
        responsive: true,
        scales: {
          x: {
            ticks: { maxRotation: 0, minRotation: 0, autoSkip: false, font: { size: 10 }, callback: (value, index, values) => index === 0 ? xValues[0] : index === values.length - 1 ? xValues[xValues.length - 1] : '' },
            grid: { display: false },
            border: { display: false }
          },
          y: { display: false, grid: { display: false } }
        },
      },
    });
  }
}
