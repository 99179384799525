<div *ngIf="isLoading; else loaded" class="loading">
  <mat-spinner></mat-spinner>
</div>
<ng-template #loaded>
  <div class="organizations h-100">
    <div *ngIf="noOrganizations && !showRegistration" class="organization-warning">
      <div class="row">
        <div class="col-auto align-self-center">
          <mat-icon class="organization-warning-icon">warning</mat-icon>
        </div>
        <div class="col organization-warning-border">
          <span class="organization-warning-description" id="new-user-registered">
            You are not a member of any organization, ask your organization
            administrator to invite you, or alternatively click on the button
            bellow to register new company.
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!noOrganizations && !showRegistration" class="organizations-list">
      <div class="row">
        <div class="col">
          <app-organizations-list *ngIf="accepted.length > 0" [type]="1" [data]="accepted"></app-organizations-list>
          <app-organizations-list *ngIf="pending.length > 0" [type]="2" [data]="pending"></app-organizations-list>
          <app-organizations-list *ngIf="invited.length > 0" [type]="3" [data]="invited"
            (refreshPage)="getData()"></app-organizations-list>
        </div>
      </div>
    </div>
    <div *ngIf="showRegistration" class="organizations-registration">
      <div class="row">
        <div class="col">
          <app-organization-create (cancel)="showForm()" [userProfile]="userProfile"></app-organization-create>
        </div>
      </div>
    </div>
    <div *ngIf="!showRegistration" [ngClass]="noOrganizations ? 'pt-5' : ''">
      <button mat-raised-button class="full-button" *ngIf="registerButtonTitleVisible" (click)="showForm()">
        {{ registerButtonTitle }}
      </button>
    </div>
  </div>
</ng-template>