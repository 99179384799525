import { Component, ViewEncapsulation, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, expand, finalize, reduce, switchMap, tap } from 'rxjs/operators';
import { NIL as NIL_UUID, v4 } from 'uuid';
import { Variant } from '@amplitude/experiment-js-client';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { LayoutService } from '../../layout/layout.service';
import { LayoutMode } from '../../layout/layout.model';
import {
  CurrencyCode,
  DepartureDateSettings,
  LoadUnit,
  LocationType,
  Page,
  ShipmentType,
  ShipperType,
  UnitMeasurementType,
  WeightUnit
} from '../../../shared/shared.model';
import {
  Incoterm,
  ExtenderTripLegBase,
  IncotermsMappingsQuery,
  Quote,
  QuotesQuery,
  ContainerCommodity,
  QuoteSearch
} from '../../../desktop/quotes/quotes.model';
import { QuotesService } from '../../../desktop/quotes/quotes.service';
import { PreviousRouteService } from '../../../shared/helper/previous-route.service';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { QuotesIncotermService } from '../../../desktop/quotes/quotes-incoterm.service';
import { TeamMemberService } from '../../../desktop/manage-organization/organization-members/team-member-dialog/team-member.service';
import { OrganizationsService } from '../../../desktop/organizations/organizations.service';
import { SupportedCountriesPairQuery } from '../../../shared/supported-countries/supported-countries.model';
import { SupportedCountriesService } from '../../../shared/supported-countries/supported-countries.service';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';
import { AmplitudeService } from '../../../shared/amplitude/amplitude.service';
import { amplitudeFlags } from '../../../shared/amplitude/amplitude.constants';
import { ContentService } from '../../layout/content/content.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { WelcomeTutorialService } from '../../welcome-tutorial/welcome-tutorial.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { SupportRequestsService } from '../../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../support-requests/support-requests.model';
import { RecentQuoteSearchesByUserIdQuery } from '../../../shared/activities/activities.model';
import { ActivitiesService } from '../../../shared/activities/activities.service';
import { ArrayUtils } from '../../../shared/services/utils/arrayUtils';

@Component({
  selector: 'app-quote-search-flow',
  templateUrl: './quote-search-flow.component.html',
  styleUrls: ['./quote-search-flow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowComponent implements OnInit, AfterViewInit {
  shippingTypes: any[] = [
    {
      name: 'FCL',
      description: 'Full Container Load',
      value: ShipmentType.FCL,
      icon: 'fcl'
    },
    {
      name: 'LCL',
      description: 'Less than Container Load',
      value: ShipmentType.LCL,
      icon: 'lcl'
    },
    {
      name: 'Air',
      description: 'Air Cargo',
      value: ShipmentType.AIR,
      icon: 'ac'
    }
  ];

  quoteSearchForm: FormGroup;
  shipmentype: ShipmentType = null;
  quotesQuery: QuotesQuery = undefined;
  incoterms: Incoterm[] = null;
  quotes: Quote[] = null;
  searchQuotesSubscription: Subscription;
  supportedCountriesSubscription: Subscription;
  searchId = '';
  reusedBookingId = '';
  reusedQuoteId = '';
  quoteSearchId: string = null;
  from = '';
  to = '';
  reusedQuote: any = null;
  result: any;
  stopSearchQuote: boolean;
  showD2dAndAir: boolean;
  noQuotesFound: boolean = false;
  isLoading: boolean = true;
  isStarted: boolean = false;
  isReuse: boolean = false;
  isListLoading: boolean;
  isBack: boolean = false;
  isOldSearchStyle: boolean = true;
  isFirstSearch: boolean = true;
  isRecentQuotesSearch: boolean = false;
  variant: Variant;
  recentQuotesSubscription: Subscription;

  get departureDateSettings(): DepartureDateSettings {
    let settings = this.userInfoService.getSettings();
    return settings.departureDateSettings;
  }

  yOffset: number = -168;
  @ViewChild('searchFlowContent') searchFlowContent: ElementRef<Element>;

  constructor(
    private layoutService: LayoutService,
    private settingsService: AppSettingsService,
    private quotesService: QuotesService,
    private bookingsService: BookingsService,
    private previousRouteService: PreviousRouteService,
    private notificationService: NotificationService,
    private quotesIncotermService: QuotesIncotermService,
    private supportedCountriesService: SupportedCountriesService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private amplitudeService: AmplitudeService,
    private contentService: ContentService,
    private utilityService: UtilityService,
    private userInfoService: UserInfoService,
    private teamMemberService: TeamMemberService,
    private organizationsService: OrganizationsService,
    private welcomeTutorialService: WelcomeTutorialService,
    private supportRequestsService: SupportRequestsService,
    private activitiesService: ActivitiesService
  ) {
    this.reusedBookingId = this.route.snapshot.queryParams.reusedBookingId;
    this.reusedQuoteId = this.route.snapshot.queryParams.reusedQuoteId;
  }

  ngOnInit(): void {
    this.isOldSearchStyle = this.checkQuoteSearchFlow();
    this.initiateWelcomeTutorial();
    this.updateTeamMemberSettings();
    this.createQuoteSearchForm();
    this.observeRecentQuotes();
    this.getIsFirstSearch();

    if (this.reusedBookingId !== undefined && this.reusedBookingId !== '') {
      this.getReusedBooking();
    } else if (this.reusedQuoteId !== undefined && this.reusedQuoteId !== '') {
      this.getReusedQuote();
    } else {
      this.isLoading = false;
    }

    this.quotesService.stopSearchQuote$.subscribe((isStop) => {
      if (isStop) {
        this.stopSearchQuote = true;
        this.quotesService.unsubscribeSearchQuote$.next();
        this.quotesService.unsubscribeSearchQuote$.complete();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.QUOTEFLOW);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarTitle('Search Quotes');
      this.layoutService.setToolbarDescription('Find and book your shipping quotes');
      this.fillQuoteSearchParamsIfReturningFromBooking();
      if (this.isReturningFromBooking() && this.quotesQuery) {
        this.onSearchQuotes(this.quotesQuery);
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
    this.recentQuotesSubscription?.unsubscribe();
  }

  onSearchQuotes(query: QuotesQuery): void {
    if (this.supportedCountriesSubscription) {
      this.isLoading = false;
      this.isListLoading = false;
      this.isStarted = false;
      this.supportedCountriesSubscription.unsubscribe();
      this.stopSearchQuote = true;
      this.quotesService.unsubscribeSearchQuote$.next();
      this.quotesService.unsubscribeSearchQuote$.complete();
    }

    if (this.searchQuotesSubscription) {
      this.isLoading = false;
      this.isListLoading = false;
      this.isStarted = false;
      this.searchQuotesSubscription.unsubscribe();
      this.stopSearchQuote = true;
      this.quotesService.unsubscribeSearchQuote$.next();
      this.quotesService.unsubscribeSearchQuote$.complete();
    }

    this.isStarted = true;
    this.isLoading = true;
    this.quotesQuery = query;
    this.from = query.from.displayName;
    this.to = query.to.displayName;
    this.quotesQuery.organizationId = this.settingsService.getSettings().organizationId;
    this.reusedQuote = this.reusedQuote;

    const minDate = this.setFromDate();
    minDate.setUTCHours(0, 0, 0, 0);
    this.quotesQuery.minFromDate = minDate.toISOString();

    this.googleAnalyticsService.searchQuotes(this.quotesQuery, this.isFirstSearch);
    this.updateIsFirstSearch(false);
    this.reloadQuotes();

    this.isRecentQuotesSearch = false;
    this.quotesService.saveQuotesQueryInStorage(query);

    setTimeout(() => {
      const y = this.searchFlowContent.nativeElement.getBoundingClientRect().top + this.yOffset;
      this.contentService.scrollTo({ behavior: "smooth", top: y })
    }, 100)
  }

  onShipmentTypeChange(shipmentType: ShipmentType): void {
    if (shipmentType !== undefined && shipmentType !== null) {
      this.quoteSearchForm.get('shipmentType').setValue(shipmentType);
    }
  }

  onChangeShipperType(value: ShipperType): void {
    this.stopSearchQuote = true;
    this.quotesService.unsubscribeSearchQuote$.next();
    this.quotesService.unsubscribeSearchQuote$.complete();
    this.quoteSearchForm.get('shipperType').setValue(value);
    this.quotesQuery.shipperType = value;
    this.quotesQuery.searchId = v4();
    this.getIncoterms().subscribe(incoterm => {
      const id = incoterm?.pop()?.id;
      if (id) {
        this.quotesQuery.incoterms = [id];
      }
      this.onSearchQuotes(this.quotesQuery);
    });
  }

  onOpenTutorial() {
    this.welcomeTutorialService.openWelcomeTutorialDialog();
  }

  private initiateWelcomeTutorial(): void {
    const userInfo = this.userInfoService.getSettings();
    const teamMemberSettings = userInfo.userSettings;

    if (teamMemberSettings.isFirstLogin) {
      this.welcomeTutorialService.openWelcomeTutorialDialog();
    }
  }

  private updateTeamMemberSettings(): void {
    const userInfo = this.userInfoService.getSettings();
    const teamMemberSettings = userInfo.userSettings;

    if (teamMemberSettings.isFirstLogin) {
      teamMemberSettings.isFirstLogin = false;
      this.teamMemberService.updateTeamMemberSettings(userInfo.teamMemberId, teamMemberSettings).subscribe(() => {
        this.organizationsService.setUserSettings(teamMemberSettings);
      });
    }
  }

  private createQuoteSearchForm(): void {
    this.quoteSearchForm = this.fb.group({
      vendorId: [],
      shipmentType: [this.shipmentype, Validators.required],
      shipperType: [ShipperType.importer, Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      fromcities: ['', Validators.required],
      tocities: ['', Validators.required],
      postcodesTo: ['', Validators.required],
      postcodesFrom: ['', Validators.required],
      currency: [CurrencyCode.USD],
      fromDate: [new Date()],
      containerCommodities: this.fb.array([]),
      equipments: this.fb.array([]),
      incoterms: [null, Validators.required],
      isHazardous: [false],
      isBatteries: [false],
      reusedFromBookingId: [null],
      isShowPackageDimensions: [false],
      unitMeasurementType: [this.getDefaultUnitMeasurementType()]
    });
  }

  private getReusedBooking(): void {
    this.bookingsService
      .getReuseBooking(this.reusedBookingId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => { }
      )
      .add(() => (this.isLoading = false));
  }

  private getReusedQuote(): void {
    this.quotesService
      .getReuseQuote(this.reusedQuoteId)
      .subscribe(
        data => {
          this.reusedQuote = data;
          this.fillQuoteSearchForm(this.reusedQuote);
          this.isReuse = true;
        },
        error => {
          this.notificationService.error(error);
        }
      )
      .add(() => (this.isLoading = false));
  }

  private fillQuoteSearchParamsIfReturningFromBooking(): void {
    if (this.isReturningFromBooking()) {
      const quotesQuery = this.quotesService.getQuotesQueryFromStorage();
      if (quotesQuery) {
        this.quotesQuery = quotesQuery;
        this.fillQuoteSearchFormFromQuotesQuery(quotesQuery);
      }

      this.isBack = true;
    } else {
      this.quotesService.removeQuotesQueryFromStorage();
      this.isBack = false;
    }
  }

  private fillQuoteSearchFormFromQuotesQuery(quoteQuery: QuotesQuery): void {
    this.quoteSearchForm.get('vendorId').setValue(quoteQuery.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quoteQuery.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quoteQuery.shipperType);
    this.quoteSearchForm.get('from').setValue(quoteQuery.from);
    this.quoteSearchForm.get('to').setValue(quoteQuery.to);
    this.quoteSearchForm.get('fromcities').setValue(quoteQuery.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quoteQuery.to.displayName);
    this.quoteSearchForm.get('currency').setValue(quoteQuery.currency);
    this.quoteSearchForm.get('fromDate').setValue(quoteQuery.fromDate);
    this.quoteSearchForm.get('incoterms').setValue(quoteQuery.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quoteQuery.isHazardous);
    this.quoteSearchForm.get('isBatteries').setValue(quoteQuery.isBatteries);
    this.quoteSearchForm.get('postcodesTo').setValue(quoteQuery.to.zipCode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quoteQuery.from.zipCode || null);
    this.quoteSearchForm.get('isShowPackageDimensions').setValue(quoteQuery?.containerCommodities?.some(x => x.height > 0
      || x.width > 0 || x.length > 0));
    this.quoteSearchForm.get('unitMeasurementType').setValue(this.getUnitMeasurementType(quoteQuery?.containerCommodities[0]?.weightUnit));

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quoteQuery?.containerCommodities.forEach(commodity => {
      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: { value: commodity.dimensionUnit, disabled: true },
            height: commodity.height,
            length: commodity.length,
            width: commodity.width,
            dimensionValue: this.getDimensionValue(commodity.length, commodity.width, commodity.height),
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: commodity.packageVolume,
            totalDimensionValueAmount: commodity.packageVolume,
            totalVolumeAmount: commodity.volumeAmount,
            totalWeightAmount: commodity.weightAmount,
            isShowMaskedInput: this.quoteSearchForm.get('isShowPackageDimensions').value,
            chargeableWeightAmount: this.calculateChargeableWeightAmount(commodity),
            chargeableVolumeAmount: this.calculateChargeableVolumeAmount(commodity)
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quoteQuery?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();
  }

  private fillQuoteSearchForm(quote: any): any {
    this.quoteSearchForm.get('reusedFromBookingId').setValue(this.reusedQuote?.reusedFromBookingId);
    this.quoteSearchForm.get('vendorId').setValue(quote.vendorId);
    this.quoteSearchForm.get('shipmentType').setValue(quote.shipmentType);
    this.quoteSearchForm.get('shipperType').setValue(quote.shipperType);
    this.quoteSearchForm.get('from').setValue(quote.from);
    this.quoteSearchForm.get('to').setValue(quote.to);
    this.quoteSearchForm.get('fromcities').setValue(quote.from.displayName);
    this.quoteSearchForm.get('tocities').setValue(quote.to.displayName);
    this.quoteSearchForm.get('postcodesTo').setValue(quote.to.postcode || null);
    this.quoteSearchForm.get('postcodesFrom').setValue(quote.from.postcode || null);
    this.quoteSearchForm.get('currency').setValue(CurrencyCode.USD);
    this.quoteSearchForm.get('fromDate').setValue(this.setFromDate());

    this.quoteSearchForm.get('incoterms').setValue(quote.incoterms[0]);
    this.quoteSearchForm.get('isHazardous').setValue(quote.isHazardous, { emitEvent: false });
    this.quoteSearchForm.get('isBatteries').setValue(quote.isBatteries);
    this.quoteSearchForm.get('isShowPackageDimensions').setValue(quote?.commodities?.some(x => x.dimension?.height > 0
      || x.dimension?.width > 0 || x.dimension?.length > 0));
    this.quoteSearchForm.get('unitMeasurementType').setValue(this.getUnitMeasurementType(quote?.commodities[0]?.weightUnit));

    const containerCommodities = this.quoteSearchForm.get('containerCommodities') as FormArray;
    containerCommodities.clear();
    quote?.commodities.forEach(commodity => {
      containerCommodities.push(
        this.fb.group(
          {
            dimensionUnit: { value: commodity.dimension?.dimensionUnit, disabled: true },
            height: commodity.dimension?.height,
            length: commodity.dimension?.length,
            width: commodity.dimension?.width,
            dimensionValue: this.getDimensionValue(commodity.dimension?.length, commodity.dimension?.width, commodity.dimension?.height),
            loadUnit: LoadUnit.volume,
            numberOfPackages: commodity.numberOfPackages,
            volumeAmount: commodity.volumeAmount,
            volumeUnit: commodity.volumeUnit,
            weightAmount: commodity.packageWeight
              ? commodity.packageWeight * commodity.numberOfPackages
              : commodity.weightAmount,
            weightUnit: commodity.weightUnit,
            totalWeightUnit: commodity.weightUnit,
            packageWeight: commodity.packageWeight ?? commodity.weightAmount / commodity.numberOfPackages,
            packageVolume: commodity.packageVolume,
            totalDimensionValueAmount: commodity.packageVolume,
            totalVolumeAmount: commodity.volumeAmount,
            totalWeightAmount: commodity.weightAmount,
            isShowMaskedInput: this.quoteSearchForm.get('isShowPackageDimensions').value,
            chargeableWeightAmount: this.calculateChargeableWeightAmount(commodity),
            chargeableVolumeAmount: this.calculateChargeableVolumeAmount(commodity)
          } || []
        )
      );
    });

    let equipmentsArray = this.quoteSearchForm.get('equipments') as FormArray;
    equipmentsArray.clear();

    quote?.equipments?.forEach((equipment, index) => {
      const equipmentGroup = this.fb.group({
        equipmentCode: equipment.equipmentCode,
        equipmentTypeDescription: equipment.equipmentTypeDescription,
        quantity: equipment.quantity
      });
      equipmentsArray.insert(index, equipmentGroup);
    });
    this.quoteSearchForm.markAsUntouched();
    this.sendQuoteData(quote);
  }

  private sendQuoteData(quote: any): void {
    this.quotesService.sendQuoteData.next(quote);
  }

  private getIncoterms(): Observable<any> {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      return this.quotesIncotermService.getIncotremsByLocationTypes(query).pipe(
        tap((x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        }),
        catchError(error => {
          this.notificationService.error(error);
          return throwError(error);
        })
      );
    } else {
      this.incoterms = null;
      return of(null);
    }
  }

  private reloadQuotes(): void {
    this.quotesQuery.pageNo = 1;
    this.quotesQuery.pageSize = environment.quotesQueryPageSize;
    this.isListLoading = true;
    this.quotes = [];
    this.stopSearchQuote = false;

    if (
      this.quotesQuery.shipmentType != ShipmentType.AIR &&
      this.quotesQuery.from.locationType === LocationType.townCity &&
      this.quotesQuery.to.locationType === LocationType.townCity
    ) {
      this.showD2dAndAir = false;
    }

    this.isLoading = true;
    this.noQuotesFound = false;

    this.supportedCountriesSubscription = this.isCountryPairSupported()
      .subscribe(isSupported => {
        if (!isSupported) {
          this.notificationService.notify
            (`Not allow search between ${this.quotesQuery?.from?.countryName} and ${this.quotesQuery?.to?.countryName}`,
              5000
            );
          this.isLoading = false;
          this.isListLoading = false;
          return;
        }

        var storedQuoteResult = this.quotesService.getQuotesResultsFromStorage(this.quotesQuery);

        if (!storedQuoteResult) {
          this.searchQuotesSubscription = this.getQuotes()
            .subscribe()
            .add(() => {
              this.isLoading = false;
              this.isListLoading = false;
            });
        } else {
          this.quotes = [...this.quotes, ...storedQuoteResult.quotes];
          this.isLoading = false;
          this.isListLoading = false;
        }
      })
  }

  private getQuotes(): Observable<any[] | Page<Quote>> {
    return this.quotesService.getQuotesByQuery(this.quotesQuery).pipe(
      expand(x => (this.hasMoreQuotesToLoad(x) ? this.loadMoreQuotes(x.pageNo) : this.stopLoadQuotes())),
      tap(
        x => {
          if (this.hasValidItem(x.items)) {
            this.quotes = [...this.quotes, ...x.items];
          }
          if (x.isAlternative) {
            this.quotesQuery.isAlternative = true;
            this.quotesQuery.searchId = this.getQuoteSearchId();
          }
          if (this.isFirstRequest()) {
            if (this.hasValidItem(this.quotes)) {
              this.quotes.forEach(x => (x.firstSearch = true));
              const quote = this.quotes[0];
              this.quoteSearchId = this.getQuoteSearchId();
              if (quote.trip === null) {
                this.noQuotesFound = true;
                this.quoteSearchId = this.getQuoteSearchId();
                this.reusedQuote = null;
              }
            } else {
              this.noQuotesFound = true;
              this.quoteSearchId = this.getQuoteSearchId();
              this.reusedQuote = null;
            }

            this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData());
          } else if (this.quotes.some(quote => !quote.firstSearch) || this.quotes.every(quote => quote.firstSearch)) {
            this.quotes = this.filterCreditQuotes(this.quotes);
            this.quotes = this.filterPartialQuotes(this.quotes);

            if (this.quotesQuery.shipmentType != ShipmentType.AIR)
              this.quotes = this.filterVariousTimeOptionQuotesWithinScheduledQuotes(this.quotes);

            this.quotes = this.filterQuotesWithNotNegativePrice(this.quotes)

            this.quotes = ArrayUtils.distinctBy(this.quotes, x => {
              return {
                accumulatePrice: x.accumulatePrice,
                arrivalDate: new Date(x.arrivalDate).toLocaleDateString(),
                departureDate: new Date(x.departureDate).toLocaleDateString(),
                vesselName: x.trip?.mainCarriageTripLeg?.legs[0]?.vesselName,
                cutOffDate: x.trip?.cutOffDate
              }
            }, [
              x => x.trip?.mainCarriageTripLeg?.legs[0]?.vesselName.startsWith("ZIM")
            ]);
            this.isLoading = false;
          }
          this.quotesService.saveQuotesResultsInStorage(this.quotes, this.quotesQuery);
        },
        (error) => (this.isFirstRequest() ? this.notificationService.error(
          "Unfortunately we faced an unexpected issue providing you the requested service. Please try again later"
        ) : null)
      ),
      reduce((acc, val) => {
        acc = [...acc, ...val?.items];
        return acc;
      }, [])
    );
  }

  private isCountryPairSupported(): Observable<boolean> {
    const query: SupportedCountriesPairQuery = {
      fromCountryCode: this.quotesQuery.from.country,
      toCountryCode: this.quotesQuery.to.country
    };

    return this.supportedCountriesService.isCountryPairSupported(query)
  }

  private filterCreditQuotes(quotes: Quote[]): Quote[] {
    const hasContractRate = (tripLegBase: ExtenderTripLegBase | undefined): boolean => {
      return (
        tripLegBase?.legs?.some(leg =>
          leg.rates?.some(rate => rate.freightRate?.tariff?.toLowerCase().includes('contract'))
        ) ?? false
      );
    };

    const hasContractTariff = quotes.some(
      quote =>
        hasContractRate(quote.trip.preCarriageTripLeg) ||
        hasContractRate(quote.trip.mainCarriageTripLeg) ||
        hasContractRate(quote.trip.postCarriageTripLeg)
    );

    if (hasContractTariff) {
      return quotes.filter(
        quote =>
          hasContractRate(quote.trip.preCarriageTripLeg) ||
          hasContractRate(quote.trip.mainCarriageTripLeg) ||
          hasContractRate(quote.trip.postCarriageTripLeg)
      );
    }

    return quotes;
  }

  private hasMoreQuotesToLoad(response: Page<Quote>): boolean {
    if (this.stopSearchQuote || response.totalCount == 0 || response.items == null) return false;

    return this.hasValidItem(response.items);
  }

  private loadMoreQuotes(responsePageNo: number): Observable<Page<Quote>> {
    this.quotesQuery.pageNo = responsePageNo + 1;
    return this.quotesService.getQuotesByQuery(this.quotesQuery);
  }

  private stopLoadQuotes(): Observable<never> {
    this.updateGoogleAnalyticsQuoteResults();

    this.updateIsFirstSearch(false);
    this.isListLoading = false;
    return EMPTY;
  }

  private hasValidItem(items: Quote[] | null): boolean {
    return items != null && items.length > 0 && items[0].id !== NIL_UUID;
  }

  private isFirstRequest(): boolean {
    return this.quotesQuery.pageNo == 1;
  }

  private getQuoteSearchId(): string {
    return this.quotes && this.quotes[0]?.quoteSearchId ? this.quotes[0].quoteSearchId : this.quotesQuery.searchId;
  }

  private updateGoogleAnalyticsQuoteResults(): void {
    let partialSchedule = 'Not Found';
    let partialDoor = 'Not Found';
    if (this.quotes?.length > 0) {
      partialSchedule = this.isQuotesResultsHasSchedule() ? 'Found' : 'Not Found';
      partialDoor = this.isPartialPriceQuotesResults() ? 'Partial' : 'Found';
    }
    this.googleAnalyticsService.quotesResults(partialSchedule, partialDoor, this.quotes?.length ?? 0);
  }

  private isPartialPriceQuotesResults(): boolean {
    return this.quotes.some(quote => quote.trip.isPreCarriageMissingRate || quote.trip.isPostCarriageMissingRate);
  }

  private isQuotesResultsHasSchedule(): boolean {
    return this.quotes.some(
      quote => this.utilityService.isNotNullOrMinDateValue(quote.departureDate) && this.utilityService.isNotNullOrMinDateValue(quote.arrivalDate)
    );
  }

  private isReturningFromBooking(): boolean {
    const previousUrl = this.previousRouteService.getPreviousUrl();
    if (previousUrl && previousUrl?.includes('booking')) return true;

    return false;
  }

  private filterPartialQuotes(quotes: Quote[]): Quote[] {
    const hasFullRate = quotes.some(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate);

    return hasFullRate
      ? quotes.filter(x => !x.trip.isPreCarriageMissingRate && !x.trip.isPostCarriageMissingRate)
      : quotes;
  }

  private checkQuoteSearchFlow(): boolean {
    if (this.getNewQuoteSearchFlowEnabled()) {
      this.variant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchFlagKey);
      this.isOldSearchStyle = this.amplitudeService.checkQuoteSearchStyle(this.variant);
    }

    return this.isOldSearchStyle;
  }

  private getNewQuoteSearchFlowEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.newQuoteSearchFlowEnabled;
      case 'sb':
        return environment.sb.newQuoteSearchFlowEnabled;
      default:
        return environment.newQuoteSearchFlowEnabled;
    }
  }

  private getDimensionValue(length: number, width: number, height: number): string {
    if (length > 0 && width > 0 && height > 0) {
      return `${length}x${width}x${height}`;
    }
    return '';
  }

  private calculateChargeableWeightAmount(commodity: ContainerCommodity): number {
    const unitMeasurementType = this.getUnitMeasurementType(commodity.weightUnit);
    let chargeableWeightAmount = this.calculateTotalChargeableWeight(this.quoteSearchForm.get('shipmentType').value,
      unitMeasurementType,
      commodity.volumeAmount);

    if (commodity.weightAmount > chargeableWeightAmount) {
      chargeableWeightAmount = commodity.weightAmount;
    }

    return chargeableWeightAmount;
  }

  private calculateChargeableVolumeAmount(commodity: ContainerCommodity): number {
    const unitMeasurementType = this.getUnitMeasurementType(commodity.weightUnit);
    let chargeableVolumeAmount = this.calculateTotalChargeableVolume(this.quoteSearchForm.get('shipmentType').value,
      unitMeasurementType,
      commodity.weightAmount);

    if (commodity.volumeAmount > chargeableVolumeAmount) {
      chargeableVolumeAmount = commodity.volumeAmount;
    }

    return chargeableVolumeAmount;
  }

  private calculateTotalChargeableVolume(shipmentType: ShipmentType, unitMeasurementType: UnitMeasurementType, totalWeight: number): number {
    if (unitMeasurementType === UnitMeasurementType.metric) {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight / 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight / 1000 * 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalWeight * 1728 / 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalWeight * 166;
      }
    }
  }

  private calculateTotalChargeableWeight(shipmentType: ShipmentType, unitMeasurement: UnitMeasurementType, totalVolume: number): number {
    if (unitMeasurement === UnitMeasurementType.metric) {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private getUnitMeasurementType(weightUnit: WeightUnit): UnitMeasurementType {
    return weightUnit == WeightUnit.LB ? UnitMeasurementType.imperial : UnitMeasurementType.metric
  }

  private getDefaultUnitMeasurementType(): UnitMeasurementType {
    var unitMeasurementValue = this.quotesService.getUnitMeasurementFromStorage();
    if (unitMeasurementValue != undefined && unitMeasurementValue != null) {
      return unitMeasurementValue;
    }

    return UnitMeasurementType.metric;
  }

  private filterVariousTimeOptionQuotesWithinScheduledQuotes(quotes: Quote[]): Quote[] {
    const hasAtLeastOneScheduling = quotes.some(x => this.utilityService.isNotNullOrMinDateValue(x.arrivalDate) || this.utilityService.isNotNullOrMinDateValue(x.departureDate));

    return hasAtLeastOneScheduling
      ? quotes.filter(x => this.utilityService.isNotNullOrMinDateValue(x.arrivalDate) || this.utilityService.isNotNullOrMinDateValue(x.departureDate))
      : quotes;
  }

  private filterQuotesWithNotNegativePrice(quotes: Quote[]): Quote[] {
    return quotes.filter(x => x.trip.mainCarriageTripLeg.legs.some(leg => leg.rates.some(rate => rate.freightRate.shipmentRate.totalAmount >= 0)))
  }

  private setFromDate(): Date {
    var minSelectableDate = new Date();
    minSelectableDate.setDate(new Date().getDate() + (this.getEnviromentMinAllowedShippingDate() + 1));
    if (minSelectableDate.getDay() == 0) {
      minSelectableDate.setDate(minSelectableDate.getDate() + 1);
    }
    else if (this.quoteSearchForm.get('shipmentType').value == ShipmentType.LCL && minSelectableDate.getDay() == 6) {
      minSelectableDate.setDate(minSelectableDate.getDate() + 2);
    }

    return minSelectableDate;
  }

  private getEnviromentMinAllowedShippingDate(): number {
    switch (this.quoteSearchForm.get('shipmentType').value) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMinAllowedDepartureDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMinAllowedDepartureDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMinAllowedDepartureDate);
      default:
        return 0;
    }
  }

  private createSupportRequestDialogData(): SupportRequestDialogData {
    return {
      isPopupAutoOpened: false,
      supportRequestType: SupportRequestTypeCode.general,
      bolOrderNumber: null,
      quoteSearchId: this.quoteSearchId
    } as SupportRequestDialogData
  }

  private observeRecentQuotes(): void {
    this.recentQuotesSubscription = this.quotesService.useRecentQuoteSearch.subscribe(x => {
      if (!x) return;

      this.fillRecentQuoteSearch(x);
    })
  }

  private fillRecentQuoteSearch(quoteSearch: QuoteSearch): void {
    this.reusedQuote = quoteSearch;
    this.isRecentQuotesSearch = true;
    this.fillQuoteSearchForm(quoteSearch);
    this.quotesService.searchQuotesByQuoteSearch(null);
    this.isReuse = true;
  }

  private getIsFirstSearch(): void {
    const isFirstSearchFromStorage = this.quotesService.getIsFirstSearchFromStorage(this.userInfoService.getSettings().userId, this.settingsService.getSettings().organizationId);

    if (isFirstSearchFromStorage != null)
      this.isFirstSearch = isFirstSearchFromStorage;
    else {
      const query = this.prepareQueryModel();
      this.activitiesService.getRecentQuoteSearchesByUserId(query)
        .subscribe(x => {
          this.updateIsFirstSearch(!x.length)
        })
    }
  }

  private updateIsFirstSearch(value: boolean): void {
    this.isFirstSearch = value;
    this.quotesService.updateIsFirstSearchInStorage(value, this.userInfoService.getSettings().userId, this.settingsService.getSettings().organizationId);
  }

  private prepareQueryModel(): RecentQuoteSearchesByUserIdQuery {
    const query = new RecentQuoteSearchesByUserIdQuery();

    query.pageSize = 1;
    query.pageNo = 1;
    query.userId = this.userInfoService.getSettings().userId;
    query.organizationId = this.settingsService.getSettings().organizationId;

    return query
  }

  private convertToNumber(value: string | null | undefined): number | null {
    const num = value ? Number(value) : null;
    return isNaN(num) ? null : num;
  }
}
