<mat-dialog-content tabindex="-1" class="additional-service-details-dialog-container-content">
  <div class="d-flex flex-column justify-content-between h-100 model-content-detailes-wrapper">
    <div class="additional-service-details-dialog-container">
      <div id="additional-service-details-dialog-overlay" class="additional-service-container"
        *ngIf="!isShowExcludedCommodities">
        <div class="additional-service-container-wrapper mb-4">
          <div class="row m-0 pb-4">
            <div class="col-12 px-0 title">
              <h2 class="additional-service-title">
                Insurance
              </h2>
            </div>
            <div class="col-12 px-0 subtitle">
              All goods will be picked up from your doorstep.
            </div>
          </div>
          <div class="cargo-invoice-value-wrapper mb-2">
            <mat-form-field appearance="fill" [floatLabel]="'auto'">
              <mat-label>Cargo Invoice Value</mat-label>
              <input [formControl]="cargoInvoiceValueControl" matInput type="number" min="1" appPreventNonNumeric
                trackInputErrors
                (triggerError)="handleError('Cargo Invoice Value', 'Please enter valid cargo invoice value')"
                controlName="Cargo Invoice Value">
            </mat-form-field>
            <span class="dollar-sign">$</span>
          </div>
          <mat-error *ngIf="
              cargoInvoiceValueControl?.touched &&
                checkValid(cargoInvoiceValueControl)
              " class="booking-flow-error">
            <i class="error-icon s4d-icon s4d-info-light"></i>
            Please enter valid cargo invoice value
          </mat-error>
          <mat-error *ngIf="errorMessages.length > 0" class="booking-flow-error">
            <div *ngFor="let errorMessage of errorMessages">
              <i class="error-icon s4d-icon s4d-info-light"></i>
              {{ errorMessage }}
            </div>
          </mat-error>
          <div class="calculate-insurance">
            <span (click)="onCalculateInsurance()" *ngIf="!calculateInsuranceBtnLoading">Calculate Insurance</span>
            <mat-spinner diameter="25" *ngIf="calculateInsuranceBtnLoading"></mat-spinner>
          </div>
        </div>
        <div class="additional-service-wrapper" *ngIf="!isShowCalculateInsurance">
          <div class="divider-container mb-4" *ngIf="insuranceSupplier === VendorsCode.loadSure">
            <div class="ship4wd-loadsure-text-wrapper">
              <span class="loadsure-text">Ship4wd & Loadsure Partnership</span>
            </div>
          </div>
          <div class="col-12 px-0 subtitle mb-4">
            Approximate 60% of shipments in transit are under or uninsured, this can represent a massive liability to
            your cash flow. We provide all-risk insurance that provide you with:
          </div>
          <div>
            <mat-accordion>
              <div class="col-12 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.peaceOfMind]"
                  (opened)="onClickPanel(true, insurancePanels.peaceOfMind)"
                  (closed)="onClickPanel(false, insurancePanels.peaceOfMind)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-verified"></i>
                        </div>
                        <div class="accordion-title  d-flex align-items-center p-0">Peace of Mind</div>
                        <div class="panel-arrow-icond-flex align-items-center">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.peaceOfMind]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.peaceOfMind]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content">
                    <span>
                      Our Door-to-door coverage includes damage or loss (including
                      general average, mechanical breakdowns, inside theft, fraudulent pick-up, loading
                      and unloading damage, and Acts of God) up to $2M USD limit
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-3 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.costEffective]"
                  (opened)="onClickPanel(true, insurancePanels.costEffective)"
                  (closed)="onClickPanel(false, insurancePanels.costEffective)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-coins"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Cost-Effective</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.costEffective]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.costEffective]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      We help you cut coverage costs by as much as 80% with our Al driven dynamic pricing that match
                      your cargo's specifications, quote given in under a minute. claims settled with full invoice
                      value of your cargo - even import duties
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-3 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.flexibility]"
                  (opened)="onClickPanel(true, insurancePanels.flexibility)"
                  (closed)="onClickPanel(false, insurancePanels.flexibility)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-directions"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Flexibility</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.flexibility]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.flexibility]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      Per shipment Pay as you go insurance
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-4 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.assurance]"
                  (opened)="onClickPanel(true, insurancePanels.assurance)"
                  (closed)="onClickPanel(false, insurancePanels.assurance)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-quality"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Assurance</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.assurance]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.assurance]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      Accelerate claims processing, digitally, from weeks or months to days- even minutes
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>
              <div class="loadsure-logo-icon-wrapper" *ngIf="insuranceSupplier === VendorsCode.loadSure">
                <div class="loadsure-logo-icon d-flex align-items-center justify-content-center">
                </div>
              </div>
            </mat-accordion>
          </div>
        </div>

        <div class="calculate-insurance-wrapper" *ngIf="isShowCalculateInsurance">
          <div class="calculate-content-insaurance-amount-wrapper">
            <div class="calculate-insurance-content mb-3">
              <div class="panel-icon mb-3">
                <i class="panel-header-icon s4d-icon s4d-shield"></i>
              </div>
              <div class="col-12 px-0">
                <h2 class="calculate-insurance-title">
                  {{ insuranceRate?.insuranceProduct?.name }}
                </h2>
              </div>
              <div class="col-12 px-0 calculate-insurance-sub-title-wrapper">
                <p class="calculate-insurance-sub-title">
                  {{ insuranceRate?.insuranceProduct?.description }}
                </p>
              </div>
            </div>

            <div class="calculate-insurance-price-wrapper d-flex justify-content-between">
              <div class="calculate-insurance-price-content" matTooltipPosition="above"
                matTooltip="Maximum amount the insurance policy will pay for a covered loss">
                <span class="calculate-insurance-title">Limit:</span>
              </div>
              <div class="calculate-insurance-price-wrapper-content">
                <span class="calculate-insurance-price">
                  {{ insuranceRate?.insuranceProduct?.currency | currencyTextIcon }}{{
                  insuranceRate?.insuranceProduct?.limit | customNumberFormat }}
                </span>
              </div>
            </div>

            <div class="calculate-insurance-price-wrapper d-flex justify-content-between">
              <div class="calculate-insurance-price-content" matTooltipPosition="above"
                matTooltip="Amount the policyholder must pay out of pocket before the insurance coverage begins">
                <span class="calculate-insurance-title">Deductible:</span>
              </div>
              <div class="calculate-insurance-price-wrapper-content">
                <span class="calculate-insurance-price">
                  {{ insuranceRate?.insuranceProduct?.currency | currencyTextIcon }}{{
                  insuranceRate?.insuranceProduct?.deductible | customNumberFormat }}
                </span>
              </div>
            </div>

            <div class="total-calculate-insurance-price-wrapper d-flex justify-content-between">
              <div class="calculate-insurance-price-content">
                <span class="calculate-insurance-title">Insurance Price:</span>
              </div>
              <div class="calculate-insurance-price-wrapper-content">
                <div class="tooltip-content">
                  <span class="tooltip-title"> Price Breakdown:</span>
                  <ul>
                    <li>Premium: {{ insuranceRate?.insuranceProduct?.currency | currencyTextIcon }}{{
                      insuranceRate?.insuranceProduct?.premium }}</li>
                    <li>Taxes & Fees: {{ insuranceRate?.insuranceProduct?.currency | currencyTextIcon }}{{
                      insuranceRate?.insuranceProduct?.tax +
                      insuranceRate?.insuranceProduct?.serviceFee }}</li>
                  </ul>
                </div>
                <span class="calculate-insurance-price">
                  {{ insuranceRate?.insuranceProduct?.currency | currencyTextIcon }}{{ insurancePrice | number :
                  "1.2-2" }}
                  <span>{{ currencyCodes[insuranceRate?.insuranceProduct?.currency] }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-4 mb-4 text-grey checkbox-content">
            <mat-checkbox (change)="onChangeTermsAndConditions($event)">
              <span class="confirmation-text">
                I agree to the <span class="link-text" (click)="onLinkClick()">Terms of Service</span> and confirm
                that I don't ship
                <br>
                any <span class="link-text" (click)="onExcludedCommoditiesLinkClick()">Excluded Commodities</span>.
              </span>
            </mat-checkbox>
          </div>

          <div class="loadsure-recommended-content-wrapper" *ngIf="insuranceSupplier === VendorsCode.loadSure">
            <p class="loadsure-recommended-content">
              Loadsure is recommended, offered and sold by Zachary Taylor, License 19757048.
              Underwritten by various syndicates at Lloyds. Coverage is provided on a surplus lines
              basis by various syndicates at Lloyd's. Surplus lines insurers do not generally
              participate in state guaranty funds and insureds are therefore not protected by such funds.
            </p>
          </div>
        </div>
      </div>

      <div class="excluded-commodities-wrapper" *ngIf="isShowExcludedCommodities">
        <div class="additional-service-container-wrapper mb-4">
          <div class="row m-0">
            <div class="col-12 px-0 title-wrapper">
              <h2 class="additional-service-title">
                List of excluded Commodities
              </h2>
            </div>
          </div>
        </div>

        <div class="acoordion-data-wrapper">
          <mat-accordion>
            <div class="col-12 accordion-main-wrapper"
              *ngFor="let commodityExclusion of insuranceRate?.insuranceProduct?.commodityExclusions; let i = index">
              <mat-expansion-panel class="additional-service-details-expansion-panel"
                [expanded]="isExpandedExcludedCommodities[i]" (opened)="onClickExcludedCommoditiesPanel(true, i)"
                (closed)="onClickExcludedCommoditiesPanel(false, i)" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                      <div class="accordion-title  d-flex align-items-center p-0">{{ commodityExclusion?.title }}
                      </div>
                      <div class="panel-arrow-icond-flex align-items-center">
                        <span class="panel-header-icon-wrapper">
                          <i *ngIf="!this.isExpandedExcludedCommodities[i]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpandedExcludedCommodities[i]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content">
                  <p>
                    {{ commodityExclusion?.description }}
                  </p>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </div>
    </div>

    <div class="row dialog-footer">
      <button mat-button class="dialog-button-close col-4" *ngIf="!isShowExcludedCommodities"
        (click)="onClose()">Close</button>
      <button mat-button class="dialog-button-close col-4" *ngIf="isShowExcludedCommodities"
        (click)="onExcludedCommoditiesLinkClick()">Back</button>
      <button mat-raised-button class="dialog-button col-4 confirm-button-wrapper button-spinner"
        *ngIf="!isShowExcludedCommodities"
        [disabled]="insuranceRate === undefined || !insuranceRate || !isTermsAndConditionChecked"
        (click)="onAddInsurance()">
        <span [ngClass]="{
          'button-inner': isLoading
        }">Add Insurance</span>
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </button>
    </div>
  </div>
</mat-dialog-content>