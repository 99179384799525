import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { RollupDialogService } from '../../../../rollup-dialog/rollup-dialog.service';
import { CommodityExclusion } from '../../../../../shared/additional-services/additional-services.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';

@Component({
  selector: 'app-excluded-commodities',
  templateUrl: './change-cargo-confirm.component.html',
  styleUrls: ['./change-cargo-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeCargoConfirmComponent {
  isExpanded: boolean[] = [];
  commodityExclusions: CommodityExclusion[];

  constructor(
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.commodityExclusions = data.commodityExclusions;
  }

  onSave(): void {
    this.dialogRef.close({ isAllowSaveBookingCargoDetails: true });
  }

  onCancel(): void {
    this.dialogRef.close({ isAllowSaveBookingCargoDetails: false });
  }
}
