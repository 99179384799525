<div class="customs-container d-flex flex-column justify-content-between"
  *ngIf="additionalRates && additionalRates[0]?.supplier.toLowerCase() === vendorsCodes[vendorsCodes.clearit].toLowerCase()">

  <div class="customs-page-title">Customs Clearance Service</div>

  <div class="customs-subtitle">
    The service handles customs declaration & importer security filing, which is required for commercial
    imports
    into the USA.
  </div>

  <div class="customs-feature-title">
    Included Features
  </div>
  <div class="d-flex flex-row align-items-start custom-declaration-wrapper">
    <div class="icon-wrapper">
      <i class="s4d-icon s4d-check success"></i>
    </div>
    <div class="d-flex flex-column">
      <div class="customs-title">
        Formal Custom Declaration to United States Customs & Border Patrol.
      </div>
      <div class="custom-declaration-subtitle-text-wrapper">
        <div class="customs-subtitle">
          Includes up to 5 commodities & 1 invoice header. $3.50 / additional commodity & $15.00 per invoice thereafter.
        </div>
      </div>
    </div>
    <div class="customs-fees-text">
      Included
    </div>
  </div>

  <div class="customs-feature-title">
    Additional Features
  </div>

  <div class="align-items-start additional-service-feature-wrapper" *ngFor="let item of additonalFeatures">
    <div class="d-flex us-import-bond-wrapper">
      <div class="additional-service-feature-fields">
        <div class="feature-title-recommended-wrapper">
          <div class="feature-title">{{ item.title }}</div>
          <div class="recommended-wrapper" *ngIf="item.groupName === clearItChargeCodeGroup.ISF"
            [matTooltip]="recommendedTooltipText" matTooltipPosition="above" matTooltipClass="text-center">
            <span class="recommended-text">recommended</span>
          </div>
        </div>
        <div class="feature-sub-title">
          {{ item.description }}
        </div>
      </div>
    </div>

    <div *ngIf="item.options && item.options.length > 0">
      <div class="align-items-start additional-service-feature-wrapper sub-features"
        *ngFor="let subItem of item.options">
        <div class="d-flex w-100">
          <div class="additional-service-feature-action">
            <mat-radio-button [name]="item.groupName" color="primary" [checked]="subItem.checked"
              [disabled]="subItem.disabled"
              (change)="subAdditionalFeatureChanged($event, subItem, item)"></mat-radio-button>
          </div>
          <div class="additional-service-feature-fields">
            <div class="feature-title">
              {{ subItem.title }}
            </div>
            <div class="feature-sub-title" [ngClass]="{'subfeature-sub-title': subItem.description}">
              {{ subItem.description }}
            </div>
          </div>
          <div class="d-flex feature-fees">
            {{ subItem.currency | currencyTextIcon }}{{ subItem.amount | number:'1.2-2' }}
          </div>
        </div>
        <div *ngIf="subItem.chargeCode === customsChargeCodes.SGB && subItem.checked === true"
          class="shipment-value-wrapper">
          <mat-form-field [floatLabel]="'never'">
            <input matInput type="number" (input)="onChangeShipmentValue($event)" placeholder="Enter shipment value" min="1"
              appPreventNonNumeric>
          </mat-form-field>
          <mat-spinner class="single-entry-bound-shipment-loader" diameter="25"
            *ngIf="singleEntryBoundShipmentLoader"></mat-spinner>
          <span class="dollar-sign" *ngIf="!singleEntryBoundShipmentLoader">$</span>
        </div>
      </div>
    </div>
  </div>

  <div class="service-info">
    Service is handled by our trusted custom broker partner ClearIT.
    The actual duties will be determined by US authorities at cargo clearance and will be paid directly by you
    to the custom broker/US authorities.
  </div>

  <div class="customs-footer">
    <div class="customs-total-cost-wrapper d-flex flex-row justify-content-between align-items-center">
      <div class="customs-total-cost-title">
        Total Cost
      </div>
      <div class="customs-total-cost-price">
        {{ getCurrencyByChargeCode(customsChargeCodes.CCS) | currencyTextIcon }}
        {{ getPriceByChargeCode(customsChargeCodes.CCS) | number:'1.2-2' }}
        {{ " + " }}
        {{ selectedAdditionalServicesFeaturesPriceSum | number:'1.2-2' }}
      </div>
    </div>
    <button *ngIf="!isServiceAlreadyAdded" mat-raised-button class="confirm-button button-spinner"
      [disabled]="isConfirmDisable" (click)="onSubmit()">
      <span [ngClass]="isLoading ? 'button-inner' : ''">Add Customs Clearance
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </button>
    <button *ngIf="isServiceAlreadyAdded" mat-raised-button class="confirm-button button-spinner"
      [disabled]="isConfirmDisable" (click)="onSubmit()">
      <span [ngClass]="isLoading ? 'button-inner' : ''">Update Customs Clearance
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </button>
    <button *ngIf="isServiceAlreadyAdded" mat-stroked-button
      class="confirm-button stroked-button-color-red button-spinner" [disabled]="isConfirmDisable" (click)="onRemove()">
      <span>Remove Customs Clearance</span>
    </button>
  </div>
</div>