export class Language {
  languageCode: string;
  languageName: string;

  public static all(): Language[] {
    return [
      { languageCode: 'en', languageName: 'English' },
      { languageCode: 'es', languageName: 'Español' }
    ];
  }
}
