import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import {
  Booking,
  BookingStatusCode,
  BookingsQuery,
  BookingsQueryParameters,
  BookingsReadMode,
  BookingsShowParameters,
} from "../../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../../shared/bookings/bookings.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import {
  BookingStatusCodeEnum,
  BookingViewDetailsPage,
  Page,
  ShipmentType,
} from "../../../../shared/shared.model";
import { UtilityService } from "../../../../shared/helper/utility.service";

@Component({
  selector: "app-orders-widget",
  templateUrl: "./orders-widget.component.html",
  styleUrls: ["./orders-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersWidgetComponent implements OnInit {
  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isError: boolean = false;
  isEmpty: boolean = false;

  bookings: Booking[] = [];
  errorMessage: string;

  loaderElementsNumber: number = 3;

  constructor(
    private settingsService: AppSettingsService,
    private bookingsService: BookingsService,
    public router: Router,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  onGoTo(): void {
    this.router.navigate(["/shipments"], {
      queryParams: { show: BookingsShowParameters.woDraft },
    });
  }

  onReload(): void {
    this.getData();
  }

  onEmptyAction(): void {
    this.isButtonLoading = true;
    this.router.navigate(["/quote-search"]);
  }

  onBookingDetails(bookingId: string): void {
    this.router.navigate([
      "/booking/view",
      BookingViewDetailsPage.finance,
      bookingId,
    ]);
  }

  getData(): void {
    const query = this.prepareQueryModel();

    this.isLoading = true;
    this.bookingsService
      .getPage(query)
      .subscribe(
        (x: Page<Booking>) => {
          this.bookings = x.items;
          this.bookings = this.bookings.filter(booking => !booking.isCanceled || booking.statusType.code == BookingStatusCode.orderSubmitted);
          this.sortBookings();

          if (x.items.length === 0) {
            this.isEmpty = true;
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error;
        }
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  getArriveDays(booking: Booking): string {
    const getDateFromBooking = (bookingDate) => {
      return this.checkDateNotNullOrEmpty(bookingDate)
        ? new Date(bookingDate)
        : null;
    };

    const calculateDays = (startDate, endDate) => {
      return Math.round(
        (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24
      );
    };

    var etaDate = getDateFromBooking(booking.eta);
    var etdDate = getDateFromBooking(booking.etd);

    if (etaDate) {
      var startDate = etdDate ? etdDate : new Date();
      var days = calculateDays(startDate, etaDate);
      return `arrive in ${days} days`;
    }

    return "Schedule Not Available";
  }

  getIconColor(booking: Booking): string {
    if (booking.isCanceled === true) {
      return "is-canceled";
    } else if (
      booking.statusType.code === BookingStatusCode.bookedWithCarrier ||
      booking.statusType.code === BookingStatusCode.creditCheck ||
      booking.statusType.code === BookingStatusCode.documentsReceived
    ) {
      return "booked-with-carrier";
    } else if (
      booking.statusType.code === BookingStatusCode.delivered ||
      booking.statusType.code === BookingStatusCode.invoiced
    ) {
      return "delivered";
    } else {
      return "other";
    }
  }

  getMainCarriageIcon(booking: Booking): string {
    switch (booking.shipmentTypeCode) {
      case ShipmentType.AIR:
        return "ac";

      case ShipmentType.FCL:
        return "fcl";

      case ShipmentType.LCL:
        return "lcl";

      default:
        return "lcl";
    }
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  range(value): number[] {
    return Array.from({ length: value }, (v, k) => k + 1);
  }

  private prepareQueryModel(): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = 1;
    query.pageSize = 5;
    query.sortDesc = true;
    query.sortBy = BookingsQueryParameters.etd;
    query.readMode = BookingsReadMode.appDashboard;
    query.orderStatuses = [
      BookingStatusCodeEnum.ORSB,
      BookingStatusCodeEnum.ORC,
      BookingStatusCodeEnum.BKD,
      BookingStatusCodeEnum.CRD,
      BookingStatusCodeEnum.DOC,
      BookingStatusCodeEnum.PKUP,
      BookingStatusCodeEnum.ONHD,
      BookingStatusCodeEnum.ACO,
      BookingStatusCodeEnum.CDO,
      BookingStatusCodeEnum.ONB,
      BookingStatusCodeEnum.DEP,
      BookingStatusCodeEnum.HBI,
      BookingStatusCodeEnum.ANS,
      BookingStatusCodeEnum.AAS,
      BookingStatusCodeEnum.ACD,
      BookingStatusCodeEnum.CDD,
      BookingStatusCodeEnum.TTR,
      BookingStatusCodeEnum.CTRA,
      BookingStatusCodeEnum.DEO,
      BookingStatusCodeEnum.OFD,
      BookingStatusCodeEnum.DLV,
      BookingStatusCodeEnum.POD,
      BookingStatusCodeEnum.CTRR,
      BookingStatusCodeEnum.INV,
      BookingStatusCodeEnum.TFD,
      BookingStatusCodeEnum.ACL,
      BookingStatusCodeEnum.CAU,
      BookingStatusCodeEnum.ARW,
      BookingStatusCodeEnum.AWB,
      BookingStatusCodeEnum.MAN,
      BookingStatusCodeEnum.UPA,
      BookingStatusCodeEnum.RCF,
      BookingStatusCodeEnum.LPA,
      BookingStatusCodeEnum.CCD,
      BookingStatusCodeEnum.RFC,
    ];
    query.isCanceled = false;
    query.orIsCanceled = true;

    return query;
  }

  private sortBookings(): void {
    this.bookings.sort((a, b) => {
      const dateAEtd = new Date(a.etd).getTime();
      const dateBEtd = new Date(b.etd).getTime();
      const dateAEta = new Date(a.eta).getTime();
      const dateBEta = new Date(b.eta).getTime();

      return dateAEtd - dateBEtd || dateAEta - dateBEta;
    });
  }
}
