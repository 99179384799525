import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { NotificationService } from "@ship4wd/ngx-common";
import {
  FileInfo,
  FileUploaderMode,
  LayoutType,
} from "@ship4wd/ngx-manager-ui";
import {
  DocumentTypeCode,
  PaymentStatusTransition,
} from "../../../../shared/shared.model";
import { BookingDocumentsService } from "../../../../../app/shared/bookings/booking-documents/booking-documents.service";
import { FilesDocumentsService } from "../../../../shared/files-documents/files-documents.service";
import {
  Booking,
  BookingFile,
} from "../../../../shared/bookings/bookings.model";
import { UpdatePayment } from "../../../../shared/payments/payments.model";
import { PaymentsService } from "../../../../shared/payments/payments.service";
import { PaymentBankDetailsComponent } from "../../../../shared/payments/payment-bank-details/payment-bank-details.component";

@Component({
  selector: "app-payment-confirmation-dialog",
  templateUrl: "./payment-confirmation-dialog.component.html",
  styleUrls: ["./payment-confirmation-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentConfirmationDialogComponent implements OnInit {
  booking: Booking;
  paymentId: string;
  paymentNumber: string;
  documentType: DocumentTypeCode;
  FileUploaderMode = FileUploaderMode;
  LayoutType = LayoutType;
  documentNameControl: FormControl = new FormControl();
  bookingDocuments: BookingFile[] = [];
  filteredOptions: Observable<BookingFile[]>;
  listingFiles: FileInfo[] = [];
  isLoading = false;
  isFileUploaded = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PaymentConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private bookingDocumentsService: BookingDocumentsService,
    private filesDocumentsService: FilesDocumentsService,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService
  ) { }

  ngOnInit(): void {
    this.booking = this.data?.booking;
    this.paymentId = this.data?.paymentId;
    this.paymentNumber = this.data?.paymentNumber;
  }

  confirmPayment(paymentId: string): void {
    this.dialogRef.close();

    var updatePayment = {
      id: paymentId,
      transition: PaymentStatusTransition.offlineDocumentsUploaded,
    } as UpdatePayment;

    this.paymentsService.updatePayment(updatePayment).subscribe();
  }

  getBookingDocuments(): FileInfo[] {
    return this.listingFiles ?? [];
  }

  onListingFilesChange(files: FileInfo[]) {
    const filesToAdd = files.filter((x) => !x.id);
    const filesToRemove = this.listingFiles.filter(
      (x) => !files.some((y) => y === x)
    );
    this.listingFiles = files;
    this.deleteFiles(filesToRemove);
    this.addFiles(filesToAdd);
  }

  onShowBankDetails() {
    this.dialog.open(PaymentBankDetailsComponent, {
      autoFocus: false,
      data: {
        refId: this.paymentNumber,
      },
    });
  }

  getSecureFileUrl(fileId: string) {
    this.bookingDocumentsService
      .getSignedUrl(this.booking.id, fileId)
      .subscribe(
        (x) => {
          window.open(x.url, "_blank");
        },
        (error) => {
          this.notificationService.error(error);
        }
      );
  }

  private addFiles(files: FileInfo[]) {
    for (const fileToAdd of files) {
      this.filesDocumentsService
        .add(this.booking.id, fileToAdd, null, DocumentTypeCode.PAYP)
        .subscribe(
          (x) => {
            this.finalizeFileLoading(fileToAdd, x);
            this.isFileUploaded = true;
          },
          (error) => {
            this.notificationService.error(error);
          }
        );
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any) {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round((100 * response.loaded) / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.booking.documents.push(addedFile);
    }
  }

  private deleteFiles(files: FileInfo[]) {
    this.isFileUploaded = false;
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.booking.id, fileToRemove.id)
        .subscribe((data) => {
          const index = this.booking.documents.findIndex(
            (x) => x.id === fileToRemove.id
          );
          if (index > -1) {
            this.booking.documents.splice(index, 1);
          }
        });
    }
  }
}
