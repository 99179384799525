import { TemplateRef } from "@angular/core";

export class DialogData {
    title: string;
    message: string;
    showCancelButton: boolean = false;
    showConfirmButton: boolean = true;
    cancelButtonText: string = "Cancel";
    confirmButtonText: string = "Confirm";
    initialTemplate: TemplateRef<any>;
}