import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingFlowService } from '../../booking-flow.service';
import {
  AdditionalServiceType,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges,
  InsuranceRate,
  InsuranceRateQuery
} from '../../../../../shared/additional-services/additional-services.model';
import { AdditionalRate, CurrencyCode, FileUrl, InsurancePanels, VendorsCode } from '../../../../../shared/shared.model';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { ExcludedCommoditiesComponent } from './excluded-commodities/excluded-commodities.component';
import { AppSettingsService } from '../../../../../shared/services/app-settings/app-settings.service';
import { OrganizationsService } from '../../../../../desktop/organizations/organizations.service';
import { Organization } from '../../../../../desktop/manage-organization/manage-organization.model';
import { DashboardService } from '../../../../../shared/widgets/dashboard.service';
import { ApplicationFileType } from '../../../../../shared/widgets/widgets.model';
import { FilesService } from '../../../../../shared/services/files.service';
import { DialogService } from '../../../../../shared/services/dialog.service';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-additional-service-insurance',
  templateUrl: './additional-service-insurance.component.html',
  styleUrls: ['./additional-service-insurance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServiceInsuranceComponent implements OnInit {
  additionalRates: AdditionalRate[];
  bookingId: string;
  booking: Booking;
  isServiceAlreadyAdded: boolean;
  insuranceRate: InsuranceRate;
  organization: Organization;
  organizationId: string;
  isExpanded: boolean[] = [false, false, false, false];
  insurancePanels = InsurancePanels;
  isShowCalculateInsurance: boolean = false;
  cargoInvoiceValueControl: FormControl = new FormControl('', Validators.required);
  calculateInsuranceBtnLoading: boolean = false;
  insurancePrice: number;
  isTermsAndConditionChecked: boolean = false;
  isLoading: boolean = false;
  currencyCodes = CurrencyCode;
  cargoInputString: string = '';
  errorMessages: string[] = [];
  fileUrl: FileUrl = null;

  loadsureErrorsMessages = [
    {
      error: 'request.body.shipment.shipper.email',
      message: "Shipper's or Consignee's email is invalid"
    },
    {
      error: 'request.body.assured.address.state',
      message: "Organization's state invalid"
    },
    {
      error: 'request.body.assured.address.postal',
      message: "Organization's postal code invalid"
    },
    {
      error: 'request.body.assured.address.address1',
      message: "Organization's address invalid"
    },
    {
      error: 'No matching product found',
      message: 'Requested cargo invoice value is too big'
    },
    {
      error: "request.body.shipment.stops[0].address",
      message: "Shipper's address invalid"
    },
    {
      error: "request.body.shipment.stops[1].address",
      message: "Delivery's address invalid"
    },
    {
      error: "request.body.shipment.shipper not set",
      message: "Shipper's contact not valid"
    },
    {
      error: "request.body.shipment.recipient",
      message: "Recipient's contact not valid"
    },
  ]

  defaultErrorMessage = "Something went wrong. Check all the information in booking and organization"
  VendorsCode = VendorsCode;

  get insuranceSupplier(): VendorsCode {
    if (this.additionalRates[0]?.supplier) {
      return VendorsCode[
        Object.keys(VendorsCode).find(
          vendor => vendor.toLowerCase() == this.additionalRates[0]?.supplier.toLowerCase()
        )
      ];
    }

    return VendorsCode.default;
  }

  constructor(
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService,
    private settingsService: AppSettingsService,
    private organizationsService: OrganizationsService,
    public rollupDialogService: RollupDialogService,
    private dashboardService: DashboardService,
    private filesService: FilesService,
    private dialogService: DialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.bookingId = data.bookingId;
    this.booking = data.booking;
    this.additionalRates = data.additionalRates;
    this.isServiceAlreadyAdded = data.isServiceAlreadyAdded;
  }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.organizationsService.getOrganization(this.organizationId)
      .subscribe((x: Organization) => {
        this.organization = x
      })
  }

  onClickPanel(state: boolean, panel: InsurancePanels): void {
    this.isExpanded[panel] = state;
  }

  onExcludedCommoditiesLinkClick(): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(ExcludedCommoditiesComponent, {
        commodityExclusions: this.insuranceRate.insuranceProduct.commodityExclusions
      });

      dialogRef.subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
      });
    }
  }

  onChangeTermsAndConditions(termsAndCondition: any): void {
    this.isTermsAndConditionChecked = termsAndCondition.checked
  }

  onCalculateInsurance(): void {
    if (this.cargoInvoiceValueControl.value && this.cargoInvoiceValueControl.value != '') {
      this.calculateInsuranceBtnLoading = true;
      this.errorMessages = [];
      var insuranceRateQuery = this.prepareInsuranceRateQuery();
      this.bookingFlowService.getInsuranceRateByQuery(insuranceRateQuery)
        .subscribe(
          (response: any) => {
            if (response.data as InsuranceRate && response.errors.length === 0) {
              this.insuranceRate = response.data;
              this.insurancePrice = this.onCalculateInsurancePrice()
              this.isShowCalculateInsurance = true;
            } else {
              response.errors.forEach(x => {
                const errorMessage = this.loadsureErrorsMessages.find(({ error }) => x.message.includes(error));

                if (!errorMessage) {
                  if (!this.errorMessages.includes(this.defaultErrorMessage)) {
                    this.errorMessages.push(this.defaultErrorMessage)
                  }
                  return;
                }

                if (!this.errorMessages.includes(errorMessage.message)) {
                  this.errorMessages.push(errorMessage.message)
                }
              });

              if (this.errorMessages.length) {
                this.handleError('Cargo Invoice Value', this.errorMessages[0])
              }
            }
          },
          (error: any) => {
            this.notificationService.error(error);
          }
        ).add(() => {
          this.calculateInsuranceBtnLoading = false;
        });
    }
  }

  checkValid(control: FormControl): boolean {
    return !control?.valid && control?.touched
  }

  onAddInsurance(): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges();
    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: AdditionalServiceType.insurance,
      charges: charges
    } as CreateBookingAdditionalServiceCharges;
    this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
  }

  onRemove(): void {
    this.rollupDialogService.close({
      isRemove: true
    });
  }

  onLinkClick(): void {
    switch (this.insuranceSupplier) {
      case VendorsCode.ishlav:
        this.getIshlavTermsFile();
        break;
      default:
        window.open(this.insuranceRate?.insuranceProduct?.termsAndConditionsLink, "_blank");
        break;
    }
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Additional Services",
      fieldName,
      errorValue
    })
  }

  private onCalculateInsurancePrice(): number {
    return this.insuranceRate?.insuranceProduct?.premium +
      this.insuranceRate?.insuranceProduct?.tax +
      this.insuranceRate?.insuranceProduct?.serviceFee
  }

  private prepareInsuranceRateQuery(): InsuranceRateQuery {
    return {
      bookingId: this.booking.id,
      organizationId: this.organizationId,
      cargoValue: this.cargoInvoiceValueControl.value,
      vendorCode: this.insuranceSupplier
    } as InsuranceRateQuery
  }

  private prepareCreateBookingAdditionalServiceCharges()
    : CreateBookingAdditionalServiceCharge[] {
    let charges = new Array<CreateBookingAdditionalServiceCharge>();

    this.additionalRates.forEach(x => {
      const charge = this.prepareBookingAdditionalServiceCharge(x);
      charges.push(charge);
    })

    return charges;
  }

  private prepareBookingAdditionalServiceCharge(additionalRate: AdditionalRate)
    : CreateBookingAdditionalServiceCharge {
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: this.insuranceSupplier,
      price: this.insurancePrice,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: this.insuranceRate?.insuranceProduct?.currency
    } as CreateBookingAdditionalServiceCharge;
  }

  private saveBookingCustomAdditionalServiceCharges(model: CreateBookingAdditionalServiceCharges): void {
    this.isLoading = true;
    this.bookingFlowService.addAdditionalServices(this.bookingId, model)
      .subscribe(
        () => {
          this.rollupDialogService.close({
            isBookingAdditionalServiceUpdated: true,
            additonalServiceType: AdditionalServiceType.insurance
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      ).add(() => {
        this.isLoading = false;
      });
  }

  private getIshlavTermsFile(): void {
    if (this.fileUrl) {
      this.filesService.downloadFileUrl(this.fileUrl);
      return;
    }

    this.isLoading = true;

    this.dashboardService
      .getApplicationFile(ApplicationFileType.ishlavTermsAndCondition)
      .subscribe(
        url => {
          this.fileUrl = new FileUrl();
          this.fileUrl.url = url;
          this.filesService.downloadFileUrl(this.fileUrl);
        },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }
}
