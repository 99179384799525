import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { DecimalPipe } from "@angular/common";
import {
  ContainerCommodity,
  Quote,
  QuotesQuery,
  ExtenderTrip,
  ExtenderTripRate,
} from "../../../../../../desktop/quotes/quotes.model";
import { RollupDialogService } from "../../../../../rollup-dialog/rollup-dialog.service";
import {
  ShipmentType,
  CarriageStatusType,
  WeightUnit,
  VolumeUnit,
  ExtenderTripLegBase,
  RateServiceType,
} from "../../../../../../shared/shared.model";
import { DimensionUnitNamePipe } from "../../../../../../shared/pipes/dimension-unit-name.pipe";
import { VolumeUnitNamePipe } from "../../../../../../shared/pipes/volume-unit-name.pipe";
import { RateDetailsDialogComponent } from "../../../rate-details/rate-details-dialog.component";
import { RateDetailPanels } from "./flow-result-details.model";
import { FlowResultDetailsDialogComponent } from "./flow-result-details-dialog/flow-result-details-dialog.component";

@Component({
  selector: "app-quote-search-flow-result-details-redesigned",
  templateUrl: "./flow-result-details.component.html",
  styleUrls: ["./flow-result-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuoteSearchFlowResultDetailsRedesignedComponent implements OnInit {
  @Input() quote: Quote;
  @Input() shipmentType: ShipmentType;
  @Input() showD2dAndAir: boolean;
  @Input() quotesQuery: QuotesQuery;
  @Input() currentPanelName: string;
  @Output() setPanelName = new EventEmitter<string>();
  CarriageStatusType = CarriageStatusType;
  ShipmentType = ShipmentType;
  commodities: ContainerCommodity;
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalChargeableWeight = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  showDetails = false;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  carriageTripLegs = [];
  lastLegCarriage = null;
  isExpanded: boolean[] = [false, false, false, false, false];
  rateDetailsPanels = RateDetailPanels;
  rateDetails = {
    [RateDetailPanels.STANDARD]: {
      type: RateServiceType.Standard,
      totalAmount: 0,
      charges: [],
      tooltip: `These rates are fixed, and valid for a longer period of time.
      They are best for planned or recurring shipments.`
    },
    [RateDetailPanels.BASIC]: {
      type: RateServiceType.Basic,
      totalAmount: 0,
      charges: [],
      tooltip: `These are one-time prices with limited availability based on
      the current market. These are best for urgent or immediate shipping needs.`
    },
    [RateDetailPanels.FREIGHT]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Freight charges include the carrier's base rate in addition to
      other charges and fees, as seen in the quote breakdown. Freight
      charges are calculated based on your search criteria, such as
      cargo details, selected origin and destination, among others.`
    },
    [RateDetailPanels.DESTINATION]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Destination charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others.`
    },
    [RateDetailPanels.ORIGIN]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Origin charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others.`
    },
    [RateDetailPanels.OTHER]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Additional charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others.`
    },
    [RateDetailPanels.DANGEROUS]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Dangerous charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others.`
    },
  }

  isShowFullNames = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isShowFullNames = window.innerWidth >= 336;
  }

  get accumulatePrice(): number {
    return this.quote?.accumulatePrice;
  }

  get quoteRateServiceType(): RateServiceType {
    return this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType
  }

  get priceValidHours(): number {
    return this.quotesQuery.shipmentType == ShipmentType.AIR ? 48 : 72;
  }

  constructor(
    private rollupDialogService: RollupDialogService,
    private readonly sso: ScrollStrategyOptions,
    private dimensionUnitNamePipe: DimensionUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit(): void {
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.quote.trip);
    this.getRateDetails();
    this.lastLegCarriage =
      this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;

    if (this.shipmentType !== ShipmentType.FCL) {
      this.totalLoadCalculation();
      this.commodities =
        this.quotesQuery.containerCommodities[
        this.quotesQuery.containerCommodities.length - 1
        ];
    }
    this.updatePanel();
  }

  getTotalFreightRate(rates: ExtenderTripRate[]): number {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage,
        carriage: trip.preCarriageTripLeg,
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage,
        carriage: trip.mainCarriageTripLeg,
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage,
        carriage: trip.postCarriageTripLeg,
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element.shipmentRate.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element.shipmentRate.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce(
      (accumulator, current) => {
        if (current.rates) {
          current.rates.forEach((x) =>
            x.surcharges.forEach((element) => {
              accumulator += element?.shipmentRate?.totalAmount;
            })
          );

          return accumulator + this.getTotalFreightRate(current.rates);
        } else return 0;
      },
      0
    );

    return carriageTripLegs;
  }

  onClickPanel(state: boolean, panel: RateDetailPanels): void {
    if (state) {
      this.setPanelName.emit(panel.toString());
    }
    this.isExpanded[panel] = state;
  }

  getDimensions(commodity: ContainerCommodity): string {
    let dimension = '';

    if (commodity !== null) {
      if (commodity.width != null
        && commodity.height !== null
        && commodity.length !== null) {
        dimension += `${commodity?.width} x `;

        dimension += `${commodity?.height} x `;

        dimension += `${commodity?.length}

      ${this.dimensionUnitNamePipe.transform(
          commodity?.dimensionUnit
        )} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity.totalVolumeAmount, '1.2-2')}

      ${this.volumeUnitNamePipe.transform(
        commodity.volumeUnit
      )}`;
    }

    return dimension;
  }

  openInfoDialog(): void {
    const data = {
      rateDetails: this.rateDetails,
      rateServiceType: this.quoteRateServiceType
    }

    const dialogRef = this.rollupDialogService.open(FlowResultDetailsDialogComponent, data, ['always-dark-background', 'flow-rate-details-dialog-overlay'])
  }

  isShowReverseDiscountPrice(charge: number, panel: RateDetailPanels = RateDetailPanels.FREIGHT): boolean {
    if (panel != RateDetailPanels.FREIGHT) return false;

    return this.quote.trip?.reverseDiscount > 0 && charge > 0;
  }

  getReverseDiscountPrice(charge: number): number {
    return charge + this.quote.trip.reverseDiscount;
  }

  getChargeAmountToDisplay(charge: any): string {
    if (this.getChargeAmount(charge) != null)
      return this.decimalPipe.transform(this.getChargeAmount(charge), "1.2-2")

    return "-.--"
  }

  getChargeAmount(charge: any): number {
    if (charge?.totalFreightRate) return charge?.totalFreightRate

    if (charge?.shipmentRate.totalAmount != null) return charge?.shipmentRate.totalAmount || 0

    return null
  }

  updatePanel(): void {
    const panelName = this.currentPanelName;
    this.isExpanded[panelName] = true;
  }

  onChangeExpand(value: boolean): void {
    this.onClickPanel(value, this.rateDetailsPanels.STANDARD)
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;

    this.quotesQuery.containerCommodities.forEach((e: any) => {
      packagesCount += e.numberOfPackages ? e.numberOfPackages : 0;
      totalVolumeAmount += e.totalVolumeAmount
        ? e.totalVolumeAmount
        : e.volumeAmount * packagesCount
          ? e.volumeAmount * packagesCount
          : 0;
      totalWeightAmount += e.totalWeightAmount
        ? e.totalWeightAmount
        : e.volumeAmount * packagesCount
          ? e.weightAmount * packagesCount
          : 0;
      this.grandTotalVolumeUnit = e.volumeUnit;
      this.grandTotalWeightUnit = e.weightUnit;
      this.grandTotalVolumeAmount += e.volumeAmount;
      this.grandTotalWeightAmount += e.weightAmount;

      var chargableWeight = this.calculateTotalChargeableWeight(
        e.volumeAmount,
        e.volumeUnit
      );
      var commodityChargeableWeight =
        e.weightAmount > chargableWeight ? e.weightAmount : chargableWeight;
      this.grandTotalChargeableWeight += commodityChargeableWeight;
    });
  }

  private calculateTotalChargeableWeight(
    totalVolume: number,
    volumeUnit: VolumeUnit
  ): number {
    if (volumeUnit === VolumeUnit.CBM) {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return (totalVolume * 1000) / 6;
      }
    } else {
      if (this.shipmentType === ShipmentType.LCL) {
        return (totalVolume / 1728) * 36;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private getRateDetails() {
    let isCargoDetailsAdded = false;
    let cargoDetailsIndex = 0;

    this.carriageTripLegs.forEach(({ carriageType, carriage }: { carriageType: CarriageStatusType, carriage: ExtenderTripLegBase }) => {
      carriage.legs.forEach(leg => {
        let rateDetailPanel = RateDetailPanels.FREIGHT;
        switch (carriageType) {
          case CarriageStatusType.preCarriage:
            rateDetailPanel = RateDetailPanels.ORIGIN
            if (this.quote.trip.isPreCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.postCarriage:
            rateDetailPanel = RateDetailPanels.DESTINATION
            if (this.quote.trip.isPostCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.mainCarriage:
          default:
            rateDetailPanel = RateDetailPanels.FREIGHT
            break;
        }

        leg.rates.forEach(rate => {
          const freightRate = rate.freightRate as any;
          this.rateDetails[rateDetailPanel].totalAmount += freightRate?.shipmentRate.totalAmount || 0;

          if (rateDetailPanel === RateDetailPanels.FREIGHT) {
            if (!isCargoDetailsAdded) {
              freightRate.cargoDetails = this.quotesQuery.shipmentType == ShipmentType.FCL ? this.quotesQuery?.equipments : this.quotesQuery?.containerCommodities;
              isCargoDetailsAdded = true;
              freightRate.totalFreightRate = freightRate?.shipmentRate.totalAmount || 0;
              this.rateDetails[rateDetailPanel].charges.push(freightRate);
              cargoDetailsIndex = this.rateDetails[rateDetailPanel].charges.length - 1;
            } else {
              this.rateDetails[rateDetailPanel].charges[cargoDetailsIndex].totalFreightRate += freightRate?.shipmentRate.totalAmount || 0;
            }
          } else {
            this.rateDetails[rateDetailPanel].charges.push(freightRate);
          }

          if (rate.surcharges?.length) {
            rate.surcharges.forEach(charge => {
              switch (charge.applicableType?.toUpperCase()) {
                case "FREIGHT":
                  this.rateDetails[RateDetailPanels.FREIGHT].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.FREIGHT].charges.push(charge);
                  break;
                case "ORIGIN":
                  this.rateDetails[RateDetailPanels.ORIGIN].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.ORIGIN].charges.push(charge);
                  break;
                case "DESTINATION":
                  this.rateDetails[RateDetailPanels.DESTINATION].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DESTINATION].charges.push(charge);
                  break;
                case "DANGEROUS":
                  this.rateDetails[RateDetailPanels.DANGEROUS].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DANGEROUS].charges.push(charge);
                  break;
                default:
                  this.rateDetails[RateDetailPanels.OTHER].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.OTHER].charges.push(charge);
                  break;
              }
            })
          }
        })
      })
    })
  }

  private getMissedFreightRate(carriageType: CarriageStatusType): unknown {
    switch (carriageType) {
      case CarriageStatusType.preCarriage:
        return {
          description: "Door pickup",
          isMissingRate: true
        }
      case CarriageStatusType.postCarriage:
        return {
          description: "Door delivery",
          isMissingRate: true
        }
    }
  }
}
