<div class="row">
    <div class="col">
        <p class="organizations-list-title">{{title}}</p>
    </div>
</div>
<div class="row mb-3">
    <div class="col">
        <div *ngFor="let item of data">
            <app-organization-line (refreshPage)="refreshOrganizationsList()" [data]="item"
                [type]="type"></app-organization-line>
        </div>
    </div>
</div>