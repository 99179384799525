import { Pipe, PipeTransform } from '@angular/core';
import { RateServiceType } from '../shared.model';

@Pipe({ name: 'rateServiceTypeBackground' })
export class RateServiceTypeBackgroundPipe implements PipeTransform {
  private shipmentTypeNames = {};

  constructor() {
    this.shipmentTypeNames[RateServiceType.Standard] = '';
    this.shipmentTypeNames[RateServiceType.Premium] = 'premium';
    this.shipmentTypeNames[RateServiceType.Express] = 'express';
    this.shipmentTypeNames[RateServiceType.Other] = 'other';
    this.shipmentTypeNames[RateServiceType.Basic] = 'basic';
  }

  transform(value?: RateServiceType): string {
    return this.shipmentTypeNames[value] ?? '';
  }
}
