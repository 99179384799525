import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SizeService {
    constructor() { }

    convertCentimetersToInches(cmLimit: number): number {
        return Math.round(cmLimit / 2.54);
    }

    convertKilogramsToPounds(kilograms: number): number {
        return Math.round(kilograms * 2.205);
    }
}