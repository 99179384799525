<div class="container-fluid">
  <div class="row justify-content-center h-100 bank-details-container">
    <div class="col bank-details-width">
      <div class="row">
        <div class="col-12 d-flex justify-content-center pt-4">
          <img class="back-icon" src="../../../assets/images/payments/bank.svg" alt="Bank Details" />
        </div>
        <div class="col-12 text-center pt-3">
          <h1 class="bank-details-title">Bank Details</h1>
        </div>
        <div class="col-12 text-center pt-2">
          <h4 class="bank-details-subtitle">
            Here is all info you need to make an offline payment.
          </h4>
        </div>
      </div>
      <div *ngIf="!isLoading; else loading" class="row bank-details-content" [ngClass]="{'mobile-wrapper': isMobile === true}">
        <div class="row content-row">
          <div class="col content-title">Country</div>
          <div class="col content-value">
            {{ (bankDetails?.countryCode ?? '') !== "" ? bankDetails.countryCode : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Account name</div>
          <div class="col content-value">
            {{ (bankDetails?.accountName ?? '') !== "" ? bankDetails.accountName : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Company code</div>
          <div class="col content-value">
            {{ (bankDetails?.companyCode ?? '') !== "" ? bankDetails.companyCode : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Bank name</div>
          <div class="col content-value">
            {{ (bankDetails?.bankName ?? '') !== "" ? bankDetails.bankName : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Bank Address</div>
          <div class="col content-value">
            {{ (bankDetails?.bankAddress ?? '') !== "" ? bankDetails.bankAddress : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Bank Account Number</div>
          <div class="col content-value">
            {{
              (bankDetails?.bankAccountNumber ?? '') !== ""
            ? bankDetails.bankAccountNumber
            : "-"
            }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Routing Number</div>
          <div class="col content-value">
            {{
              (bankDetails?.routingNumber ?? '') !== "" ? bankDetails.routingNumber : "-"
            }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">SWIFT code</div>
          <div class="col content-value">
            {{ (bankDetails?.swiftCode ?? '') !== "" ? bankDetails.swiftCode : "-" }}
          </div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Payment Reference</div>
          <div class="col content-value">ID {{ data.refId }}</div>
        </div>
        <div class="row content-row">
          <div class="col content-title">Currency</div>
          <div class="col content-value">
            {{ bankDetails?.currencyCode ?? 1 | currencyName }}
          </div>
        </div>
      </div>
      <div class="bank-details-footer" [ngClass]="{'mobile-wrapper': isMobile === true}">
        <button mat-flat-button class="close-button" (click)="onClose()">
          Close
        </button>
      </div>

      <ng-template #loading>
        <div class="loading small-loading">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>