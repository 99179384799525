import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TeamMember } from '../../../../desktop/manage-organization/manage-organization.model';
import { PaymentStatusCode } from '../../../../shared/shared.model';
import { Organization } from '../../../../../app/desktop/manage-organization/manage-organization.model';
import { OrganizationsService } from '../../../../../app/desktop/organizations/organizations.service';
import { Booking, BookingSummary } from '../../../../shared/bookings/bookings.model';
import { FourtySeasPaymentService } from '../../../../shared/payments/fourty-seas-payment.service';
import { UpdatePayment } from '../../../../shared/payments/payments.model';
import { PaymentsService } from '../../../../shared/payments/payments.service';
import { RequestAndPayment } from '../../../../shared/requests-and-payments/requests-and-payments.model';
import { RequestAndPaymentService } from '../../../../shared/requests-and-payments/requests-and-payments.service';
import { BookingViewDetailsPage, PaymentStatusTransition } from '../../../../shared/shared.model';
import { PaymentConfirmationDialogComponent } from '../payment-confirmation-dialog/payment-confirmation-dialog.component';
import { FourtySeasInitDialogService } from '../../../../shared/payments/fourty-seas-init-dialog.service';

@Component({
  selector: 'app-transaction-payment-dialog',
  templateUrl: './transaction-payment-dialog.component.html',
  styleUrls: ['./transaction-payment-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionPaymentDialogComponent implements OnInit {
  booking: Booking;
  requestAndPayments: RequestAndPayment[];
  isLoading = false;
  bookingSummary: BookingSummary;
  creditOrganization = false;
  teamMember: TeamMember;

  constructor(
    public dialogRef: MatDialogRef<TransactionPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public router: Router,
    private requestAndPaymentService: RequestAndPaymentService,
    private organizationsService: OrganizationsService,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private fourtySeasInitDialogService : FourtySeasInitDialogService) {
  }

  ngOnInit(): void {
    this.booking = this.data?.booking;
    this.getRequestsAndPaymentsByBookingId();
  }

  viewOrderDetails(): void {
    this.dialogRef.close();
    this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, this.booking.id]);
  }

  on40seasPayment(requestAndPayment: RequestAndPayment): void {
    this.fourtySeasInitDialogService.openFourtySeasInitDialog();
    this.isLoading = true;
    of(null)
      .pipe(
        switchMap(() => this.getOrganization()),
        tap((x: Organization) => {
          this.creditOrganization = x.creditOrganization;
        }),
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          this.isLoading = true;
          var transition = requestAndPayment.statusCode === PaymentStatusCode.failed ? PaymentStatusTransition.paymentRetried : PaymentStatusTransition.checkoutInitialized;
          return this.fourtySeasPaymentService.init(requestAndPayment.id, this.teamMember.id, this.booking,
            transition, "dashboard");
        }))
      .subscribe(() => {
        this.isLoading = false;
        this.dialogRef.close();
      }).add(() => (this.isLoading = false));
  }

  onConfirm(paymentId: string): void {
    this.dialogRef.close();
    this.openPaymentConfirmDialog(paymentId);
  }

  onChangePaymentMethod(requestAndPayment: RequestAndPayment): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: requestAndPayment.paymentId,
            transition: PaymentStatusTransition.paymentMethodChanged,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(async () => this.fourtySeasPaymentService.init(requestAndPayment.id, this.teamMember.id, this.booking,
           PaymentStatusTransition.paymentMethodChanged, 'finance').subscribe()
        ))
      .subscribe(() => { })
  }

  showPayOrder(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toBePaid || statusCode === PaymentStatusCode.failed;
  }

  showConfirmButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  showChangePaymentMethodButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  private getRequestsAndPaymentsByBookingId(): void {
    this.isLoading = true;
    this.requestAndPaymentService
      .getAllByBookingId(this.booking.id)
      .subscribe((x: RequestAndPayment[]) => {
        this.requestAndPayments = x.filter(x => x.statusCode !== PaymentStatusCode.paid);
      }).add(() => {
        this.isLoading = false;
      });
  }

  private getOrganization(): Observable<Organization | any> {
    return this.organizationsService.getOrganization(this.booking.organizationId);
  }

  private openPaymentConfirmDialog(paymentId: string) {
    const dialogRef = this.dialog.open(PaymentConfirmationDialogComponent, {
      panelClass: 'payment-confirmation',
      autoFocus: false,
      data: {
        booking: this.booking,
        paymentId: paymentId
      }
    });

    dialogRef.afterClosed().subscribe(() => { });
  }
}
