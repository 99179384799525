import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Booking, Commodity } from '../../../shared/bookings/bookings.model';
import { ValidatorsService } from '../../../shared/helper/validators.service';
import { BookingFlowPanelState, CurrencyCode, HarmonizedSystemCode, Page, ShipmentType, VolumeUnit, WeightUnit } from '../../../shared/shared.model';
import { AdditionalService, AdditionalServiceType, AdditionalServicesQuery, CreateBookingAdditionalServiceCharges, InsuranceRate, InsuranceRateQuery, SingleBondEntryCalculation } from '../../../shared/additional-services/additional-services.model';
import { EncodeUrlCodec } from '../../../shared/services/encode-url-codec';
import { CustomsRate, CustomsRateQuery } from '../../../shared/customs/customs.model';

@Injectable({ providedIn: 'root' })
export class BookingFlowService {
  booking: Booking = null;

  private panelStateSubject: BehaviorSubject<BookingFlowPanelState> = new BehaviorSubject<BookingFlowPanelState>(new BookingFlowPanelState());
  public panelState = this.panelStateSubject.asObservable();

  private validStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validState = this.validStateSubject.asObservable();

  constructor(
    private fb: FormBuilder,
    private validatorsService: ValidatorsService,
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService
  ) { }

  addCommodity(commodity?: Commodity): FormGroup {
    return this.createCommodityForm(commodity);
  }

  setCommodities(commodities: Commodity[]): FormGroup[] {
    return commodities.map((commodity) => {
      return this.createCommodityForm(commodity);
    });
  }

  setPanelState(state: BookingFlowPanelState): void {
    this.panelStateSubject.next(state);
  }

  setValidState(state: boolean): void {
    this.validStateSubject.next(state);
  }

  getAdditionalServices(query: AdditionalServicesQuery): Observable<Page<AdditionalService>> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<Page<AdditionalService>>(
      `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices`, { params });
  }

  addAdditionalServices(bookingId: string, model: CreateBookingAdditionalServiceCharges): Observable<void | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<void>(
      `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/${bookingId}`, model, httpOptions);
  }

  deleteAdditionalServices(bookingId: string, additionalServiceType: AdditionalServiceType): Observable<void> {
    return this.http.delete<void>(`${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices`
      + `/${bookingId}/${additionalServiceType}`);
  }

  getSingleBondEntryCalculation(model: SingleBondEntryCalculation): Observable<number | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<number>(
      `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/customs/rates/singleBondEntryCalculation`, model, httpOptions);
  }

  getCustomRateByQuery(customsRateQuery: CustomsRateQuery): Observable<CustomsRate> {
    return this.http.post<CustomsRate>(
      `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/customs/rates/getByQuery`, customsRateQuery);
  }

  getInsuranceRateByQuery(insuranceRateQuery: InsuranceRateQuery): Observable<InsuranceRate>{
    return this.http.post<InsuranceRate>(
        `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/insurance/rates/getByQuery`, insuranceRateQuery);
  }

  private createCommodityForm(commodity?: Commodity): FormGroup {
    return this.fb.group({
      id: [commodity ? commodity.id : null],
      packageTypeCode: [
        commodity && commodity.packageTypeCode ? commodity.packageTypeCode : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL ? [] : Validators.required,
      ],
      commodityObject: [commodity?.commodityTypeCode ? { id: commodity.commodityTypeCode, description: commodity.commodityDescription } as HarmonizedSystemCode : null, Validators.required],
      commodityDescription: [commodity ? commodity.commodityDescription : null],
      commodityTypeCode: [
        commodity ? commodity.commodityTypeCode : null,
        Validators.required,
      ],
      numberOfPackages: [
        commodity ? commodity.numberOfPackages : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL ? [] : [Validators.required, Validators.min(1)],
      ],
      productValue: [
        commodity ? commodity.productValue : null
      ],
      productValueCurrencyCode: [
        commodity && commodity.productValueCurrencyCode
          ? commodity.productValueCurrencyCode
          : CurrencyCode.USD,
        Validators.required,
      ],
      volume: [
        commodity ? commodity.volume : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? [Validators.required, this.validatorsService.decimalValidator]
          : [],
      ],
      volumeUnitCode: [
        commodity && commodity.volumeUnitCode
          ? commodity.volumeUnitCode
          : VolumeUnit.CBM,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      weight: [
        commodity ? commodity.weight : null,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? [Validators.required, this.validatorsService.decimalValidator]
          : [],
      ],
      weightUnitCode: [
        commodity && commodity.weightUnitCode
          ? commodity.weightUnitCode
          : WeightUnit.KG,
        this.booking.shipmentTypeCode === ShipmentType.FCL
          ? Validators.required
          : [],
      ],
      marksAndNumbers: [commodity ? commodity.marksAndNumbers : null],
    });
  }

  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}