import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserService } from '@ship4wd/ngx-authorization';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  public isLoading = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const user = this.userService.getUser();
    if (user.isAuthenticated && !this.isJwtExpired()) {
      this.navigateToDashboard();
    } else {
      this.route.queryParams.subscribe(params => {
        const returnUrl = params['returnUrl'];
        this.authService.redirectToLogin(undefined, returnUrl);
      });
    }
  }

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  private isJwtExpired(): boolean {
    const token = window.localStorage.getItem('accessToken');

    if (!token)
      return true;

    const payload = this.decodeJwt(token);

    if (!payload) return true;

    const currentTime = Math.floor(Date.now() / 1000);

    return payload.exp < currentTime;
  }

  private decodeJwt(token: string): any {
    if (!token || token.split('.').length !== 3) {
      console.error('Invalid JWT token format.');
      return null;
    }

    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding JWT', error);
      return null;
    }
  }
}
