<div class="customs-container d-flex flex-column" *ngIf="additionalService">

  <div class="customs-page-title">{{ additionalService?.name }}</div>

  <div class="customs-subtitle">
    {{ additionalService?.description }}
  </div>

  <div class="customs-footer mt-auto">
    <div class="customs-total-cost-wrapper d-flex flex-row justify-content-between align-items-center">
      <div class="customs-total-cost-title">
        Total Cost
      </div>
      <div class="customs-total-cost-price">
        <div class="p-0 detail-subtitle-value detail-subtitle-value-manual">
          <span matTooltipClass="additional-service-manually-tooltip" matTooltipPosition="above"
            [matTooltip]="getAdditionalServiceManuallyDescription(additionalService)">
            Post-Booking
          </span>
        </div>
      </div>
    </div>
    <button *ngIf="!isServiceAlreadyAdded" mat-raised-button class="confirm-button button-spinner"
      [disabled]="isConfirmDisable" (click)="onSubmit()">
      <span [ngClass]="isLoading ? 'button-inner' : ''">Add Customs Clearance
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </button>
    <button *ngIf="isServiceAlreadyAdded" mat-stroked-button
      class="confirm-button stroked-button-color-red button-spinner" [disabled]="isConfirmDisable" (click)="onRemove()">
      <span>Remove Customs Clearance</span>
    </button>
  </div>
</div>