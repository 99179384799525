import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../../shared/features/language/language.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {

  @Input()
  backgroudColor: string;
  allLanguages = null;

  get currentLanguage() {
    const language = this.languageService.getLanguage();
    return this.allLanguages.find(x => x.languageCode === language) || this.allLanguages[0];
  }

  constructor(private languageService: LanguageService) {
    this.allLanguages = languageService.getAllLanguages();
  }

  onLanguageSelect(language: string) {
    this.languageService.changeLanguage(language);
  }
}
