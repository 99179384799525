import { Organization, TeamMemberSettings } from "../../desktop/manage-organization/manage-organization.model";
import { InviteStatusCode, TeamAccessRole } from "../shared.model";

export class UserProfileQuery {
  organizationId: string;
}

export class UserProfile {
  id?: string;
  fullName?: string;
  email?: string;
  picture?: string;
  emailConfirmed: boolean;
  phoneNumber?: string;
  phoneNumberConfirmed: boolean;
  statusCode: UserStatusCode;
  isSsoOnly: boolean;
  accessFailedCount: number;
  lockoutEnabled: boolean;
  lockoutEnd?: string;
  createTimeUtc: string;
  accessRoles: TeamAccessRole[];
  teamMemberSettings: TeamMemberSettings;
  organization: Organization;
}

export class ChatProfile {
  fullName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  organizationId: string;
  userId: string;
}

export class HomeProfileQuery {
  email?: string;
}

export class OrganizationProfile {
  fullName?: string;
  picture?: string;
  organizations: TeamMemberOrganization[];
  invites: Invite[];
}

export class TeamMemberOrganization {
  organizationId: string;
  organizationName?: string;
  inviteEmail?: string;
  name?: string;
  nickName?: string;
  kycStatusCode: KycStatusCode;
  countryCode: string;
  userId?: string;
  isCreditOrganization?: boolean;
  isInternalOrganization?: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class Invite {
  organizationId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  inviteStatusCode: InviteStatusCode;
  expirationDate?: string;
  roles?: string;
  createdByUid?: string;
  createdByName?: string;
  modifiedByUid?: string;
  modifiedByName?: string;
  isDeleted: boolean;
}

export enum UserStatusCode {
  Active = 1,
  Suspended = 2,
  Blocked = 3,
  PaymentServiceSuspended = 4,
}

export enum KycStatusCode {
  Pending = 1,
  Approve = 2,
  Reject = 3,
}
