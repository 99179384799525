import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-icons',
  templateUrl: './booking-icons.component.html',
  styleUrls: ['./booking-icons.component.scss']
})
export class BookingIconsComponent implements OnInit {

  @Input() iconName: string;
  constructor() {
  }

  ngOnInit(): void {
  }
}
