<div class="quote-search-flow-parameters">
  <div class="container quote-search-flow-wrapper">
    <div [formGroup]="quoteSearchForm" *ngIf="quoteSearchForm"
      class="row g-0 quote-search-flow-header-wrapper-content-data">
      <div class="col-auto ps-0 h-100">
        <app-flow-shipment-type [shipmentTypeControl]="form.shipmentType"
          [isOpened]="isOpened[quoteSearchParameters.shipmentType]"
          [quoteSearchParameter]="quoteSearchParameters.shipmentType"
          [isSelected]="isSelected[quoteSearchParameters.shipmentType]" [shippingTypes]="shippingTypes"
          (setSelected)="onSetSelected($event, quoteSearchParameters.shipmentType)"
          (opened)="onSetOpened($event, quoteSearchParameters.shipmentType)"
          (next)="onNext($event)"></app-flow-shipment-type>
      </div>
      <div class="col-auto">
        <span class="vertical-divider"></span>
      </div>
      <div class="col h-100 quote-search-flow-item" [ngClass]="{
        'disabled': !form.shipmentType.value
      }">
        <app-new-flow-shipping-location [control]="form.from" [to]="form.to.value" [type]="searchType.from"
          [shipmentType]="form.shipmentType.value" [searchId]="searchId"
          [quoteSearchParameter]="quoteSearchParameters.from" [isOpened]="isOpened[quoteSearchParameters.from]"
          [isSelected]="isSelected[quoteSearchParameters.from]" [hasError]="hasError[quoteSearchParameters.from]"
          (setSelected)="onSetSelected($event, quoteSearchParameters.from)" (setSearchId)="onSetSearchId($event)"
          (opened)="onSetOpened($event, quoteSearchParameters.from)" (next)="onNext($event)"
          [isReuse]="isReuse"></app-new-flow-shipping-location>
      </div>
      <div class="col-auto">
        <i class="s4d-icon s4d-arrow-right"></i>
      </div>
      <div class="col h-100 quote-search-flow-item" [ngClass]="{
        'disabled': !form.shipmentType.value
      }">
        <app-new-flow-shipping-location [control]="form.to" [from]="form.from.value" [type]="searchType.to"
          [shipmentType]="form.shipmentType.value" [searchId]="searchId"
          [quoteSearchParameter]="quoteSearchParameters.to" [isOpened]="isOpened[quoteSearchParameters.to]"
          [hasError]="hasError[quoteSearchParameters.to]" [isSelected]="isSelected[quoteSearchParameters.to]"
          [to]="form.to.value" (setSelected)="onSetSelected($event, quoteSearchParameters.to)"
          (opened)="onSetOpened($event, quoteSearchParameters.to)" (setShipperType)="onSetShipperType($event)"
          (setSearchId)="onSetSearchId($event)" (next)="onNext($event)"
          [isReuse]="isReuse"></app-new-flow-shipping-location>
      </div>
      <div class="col-auto">
        <span class="vertical-divider"></span>
      </div>
      <div class="col-auto h-100 quote-search-flow-item" [ngClass]="{
        'disabled': !form.shipmentType.value
      }">
        <app-quote-search-flow-cargo-details [shipmentType]="shipmentype"
          [commoditiesFormArray]="form.containerCommodities" [equipmentsFormArray]="form.equipments"
          [isHazardous]="form.isHazardous" [isBatteries]="form.isBatteries"
          [quoteSearchParameter]="quoteSearchParameters.cargo" [isShowPackageDimensions]="form.isShowPackageDimensions"
          [unitMeasurementType]="form.unitMeasurementType" [isOpened]="isOpened[quoteSearchParameters.cargo]"
          [isSelected]="isSelected[quoteSearchParameters.cargo]" [hasError]="hasError[quoteSearchParameters.cargo]"
          [from]="form.from.value" [to]="form.to.value"
          (setSelected)="onSetSelected($event, quoteSearchParameters.cargo)" (next)="onNext($event)"
          (opened)="onSetOpened($event, quoteSearchParameters.cargo)"
          (changePieceCalculationToggle)="onChangePieceCalculationToggle($event)"
          (clickedOutside)="cargoDetailsClickedOutside()" (hazardousTouched)="cargoDetailsHazardousTouched($event)"
          [isRecentQuotesSearch]="isRecentQuotesSearch" [form]="form"></app-quote-search-flow-cargo-details>
      </div>
      <div class="col-auto">
        <span class="vertical-divider"></span>
      </div>
      <div class="col-auto h-100 quote-search-flow-item" [ngClass]="{
        'disabled': !form.shipmentType.value
      }">
        <app-quote-search-flow-cargo-date [control]="form.fromDate" [shipmentType]="shipmentype"
          [quoteSearchParameter]="quoteSearchParameters.date" [isOpened]="isOpened[quoteSearchParameters.date]"
          [isSelected]="isSelected[quoteSearchParameters.date]" [hasError]="hasError[quoteSearchParameters.date]"
          (setSelected)="onSetSelected($event, quoteSearchParameters.date)" (next)="onNext($event)"
          (opened)="onSetOpened($event, quoteSearchParameters.date)"
          (opened)="departureDateOpened($event)"></app-quote-search-flow-cargo-date>
      </div>
      <div class="col-auto pe-0 search-button-wrapper quote-search-flow-item" [ngClass]="{
        'disabled': !form.shipmentType.value
      }" (click)="onViewQuotes()">
        <div class="search-button">
          <i class="search-icon s4d-icon s4d-search"></i>
        </div>
      </div>
    </div>
  </div>
</div>