<div *ngIf="shipmentInstructionBookingForm">
  <div class="row booking-shipping-instructions-content" [formGroup]="shipmentInstructionBookingForm">
    <div class="col-12">
      <div class="booking-shipping-instructions-content-small">
        <div class="booking-shipping-instructions-content-right-wrapper">
          <div class="row mt-4">
            <div class="col-4 from-displayname">
              <p class="direction-label">from</p>
              <p class="direction-title">{{ shipmentInstruction.fromDisplayName }}</p>
            </div>
            <div class="col-4 order-trip-status">
              <div class="trip-status-icon my-auto">
                <p class="pre-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.preCarriage)"></p>
                <p class="main-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.mainCarriage)"
                  [ngClass]="shipmentInstruction.shipmentTypeCode ? getMainCarriageIcon() : ''"></p>
                <p class="post-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.postCarriage)"></p>
                <div class="trip-status-details"
                  [ngClass]="setDescriptionPosition(shipmentInstruction.statusType.code)">
                  <p class="transit-time">
                    {{ shipmentInstruction.tripDuration ? (shipmentInstruction.tripDuration | duration) : "Schedule Not Available" }}</p>
                  <p class="trip-status">
                    {{ shipmentInstruction.statusType.ship4wdFrontendStatus }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 to-delivery-loacation text-end">
              <p class="direction-label">to</p>
              <p class="direction-title">{{ shipmentInstruction.toDisplayName }}</p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="row justify-content-between">
            <div class="col-auto booking-shipping-instructions-field-box">
              <span class="booking-shipping-instructions-field-title">{{ shipmentInstruction.shipmentTypeCode ===
                shipmentTypes.AIR ? 'Airplane' : 'Vessel' }} </span>
              <div class="booking-shipping-instructions-right-field">
                <span>{{ shipmentInstruction.vesselCode }}</span>
              </div>
            </div>
            <div class="col-auto booking-shipping-instructions-field-box">
              <span class="booking-shipping-instructions-field-title">Estimated departure</span>
              <div class="booking-shipping-instructions-right-field">
                <span>
                  {{ checkDateNotNullOrEmpty(shipmentInstruction.etd) ?
                  (shipmentInstruction.etd | date: "dd MMMM yyyy") : "Schedule Not Available" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col booking-shipping-instructions-content-left">
      <div class="booking-shipping-instructions-content-wrapper">
        <div class="booking-shipping-instructions-reference-wrapper">
          <div class="booking-shipping-instructions-field-box">
            <span class="booking-shipping-instructions-field-title">Customer Reference</span>
            <div class="booking-shipping-instructions-field" [ngClass]="{
              'invalid-error-box':
                !shipmentInstructionBookingForm.get('customerReferenceId').valid &&
                shipmentInstructionBookingForm.get('customerReferenceId')?.touched
              }">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput placeholder="Add customer reference" formControlName="customerReferenceId"
                  (change)="onChangeRefID($event)" />
                <button matSuffix mat-icon-button class="remove-customer-reference-button"
                  (click)="onRemoveCustomerReference($event)" *ngIf="shipmentInstructionBookingForm.get('customerReferenceId')?.value
                    && !shipmentInstructionBookingForm.get('customerReferenceId')?.disabled">
                  <span class="remove-customer-reference-icon"></span>
                </button>
              </mat-form-field>
              <div class="booking-reference-field-refresh"
                *ngIf="!shipmentInstructionBookingForm.get('customerReferenceId')?.disabled">
                <button matSuffix mat-icon-button class="generate-customer-reference-button"
                  matTooltip="Generate reference id" (click)="onGenerateRefID()">
                  <span class="generate-customer-reference-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-shipping-instructions-another-reference-wrapper" *ngIf="!isAddAnotherRefButtonDisable">
          <div class="booking-shipping-instructions-reference-add">
            <button mat-button class="booking-shipping-instructions-reference-add-button" (click)="onAddAnotherRef()">
              <span matPrefix class="booking-shipping-instructions-reference-add-icon">
                <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
              </span>
              <span class="booking-shipping-instructions-reference-add-title">Add Reference</span>
            </button>
          </div>
        </div>
      </div>
      <div class="booking-shipping-instructions-content-wrapper">
        <div class="booking-shipping-instructions-field-box"
          [ngClass]="shipmentInstructionBookingForm.get('anotherReferences')?.controls.length > 0 ? 'field-box-spacing' : ''">
          <div formArrayName="anotherReferences" class="booking-shipping-instructions-another-reference">
            <div class="another-reference-wrapper-content"
              *ngFor="let anotherReferenceForm of shipmentInstructionBookingForm.get('anotherReferences')?.controls;let i = index"
              [formGroup]="anotherReferenceForm">
              <div class="existing-another-ref" *ngIf="anotherReferenceForm.get('id')?.value">
                <span class="booking-shipping-instructions-field-title">
                  {{ anotherReferenceForm.get('name')?.value }}
                </span>
                <div class="booking-shipping-instructions-field">
                  <mat-form-field [floatLabel]="'never'" class="w-100">
                    <input matInput placeholder="Type {{ anotherReferenceForm.get('name')?.value ?
                      anotherReferenceForm.get('name')?.value : 'ref. value' }}" formControlName="value" />
                    <button matSuffix mat-icon-button class="remove-po-number-button" (click)="onRemoveAnotherRef(i)"
                      *ngIf="!anotherReferenceForm.get('value')?.disabled">
                      <span class="remove-po-number-icon"></span>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="new-another-ref" *ngIf="!anotherReferenceForm.get('id')?.value">
                <div class="row refrence-text-input-delete-reference-wrapper">
                  <span class="field-title">Ref. name</span>
                  <div class="col refrence-text-input-wrapper">
                    <div class="row">
                      <div class="col">
                        <div class="booking-another-reference-name-field">
                          <div class="booking-another-reference-name-box">
                            <mat-form-field class="w-100" [floatLabel]="'never'">
                              <mat-select required formControlName="name" placeholder="Reference type">
                                <mat-option *ngFor="let referenceType of referenceTypes" [value]="referenceType.name">
                                  {{ referenceType.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col booking-another-reference-value-field-wrapper">
                        <div class="booking-another-reference-value-field">
                          <span class="field-title">Ref. value</span>
                          <div class="booking-another-reference-value-box">
                            <mat-form-field [floatLabel]="'never'">
                              <input required matInput placeholder="Type ref. value" formControlName="value" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto d-flex align-items-center delete-ref-icon">
                    <div class="booking-another-reference-delete-icon">
                      <button matSuffix mat-icon-button class="remove-po-number-button" (click)="onRemoveAnotherRef(i)">
                        <span class="remove-po-number-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-shipping-instructions-field-box">
          <span class="booking-shipping-instructions-field-title">Order</span>
          <div class="booking-shipping-instructions-field" [ngClass]="{
            'invalid-error-box':
              !shipmentInstructionBookingForm.get('bolOrderNumber').valid &&
              shipmentInstructionBookingForm.get('bolOrderNumber')?.touched
          }">
            <mat-form-field [floatLabel]="'never'" class="w-100">
              <input matInput placeholder="Type order number" formControlName="bolOrderNumber" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="booking-shipping-instructions-content-wrapper">
        <div class="field-box-spacing">
          <span class="booking-shipping-instructions-header-title">Customer Details</span>
        </div>
        <div class="booking-shipping-instructions-field-box add-instruction-wrapper field-box-spacing">
          <span class="booking-shipping-instructions-field-title">Shipper</span>
          <div class="booking-shipping-instructions-field customer-detailes">
            <div class="w-100 shipping-detailes" [ngClass]="{'invalid-error-box': !shipperContact}">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput placeholder="Type shipper address" readonly [value]="renderContactData(shipperContact)"
                  [disabled]="isShipperContactDisable" />
                <button matSuffix mat-icon-button class="booking-remove-contact-button"
                  (click)="onRemoveBookingContact(contactTypes.shipper)"
                  *ngIf="shipperContact && !isShipperContactDisable">
                  <span class="remove-contact-icon"></span>
                </button>
              </mat-form-field>
            </div>
            <div class="add-booking-container-btn" *ngIf="!isShipperContactDisable">
              <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                (click)="viewOrganizationContacts(contactTypes.shipper)">
                <span class="add-contact-icon"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="booking-shipping-instructions-field-box add-instruction-wrapper field-box-spacing">
          <span class="booking-shipping-instructions-field-title">Consignee</span>
          <div class="booking-shipping-instructions-field customer-detailes">
            <div class="w-100 shipping-detailes" [ngClass]="{'invalid-error-box': !consigneeContact}">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput placeholder="Type consignee address" readonly
                  [value]="renderContactData(consigneeContact)" [disabled]="isConsigneeContactDisable" />
                <button matSuffix mat-icon-button class="booking-remove-contact-button"
                  (click)="onRemoveBookingContact(contactTypes.consignee)"
                  *ngIf="consigneeContact && !isConsigneeContactDisable">
                  <span class="remove-contact-icon"></span>
                </button>
              </mat-form-field>
            </div>
            <div class="add-booking-container-btn" *ngIf="!isConsigneeContactDisable">
              <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                (click)="viewOrganizationContacts(contactTypes.consignee)">
                <span class="add-contact-icon"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="booking-shipping-instructions-field-box add-instruction-wrapper">
          <span class="booking-shipping-instructions-field-title">Notify Party</span>
          <div class="booking-shipping-instructions-field customer-detailes">
            <div class="w-100 shipping-detailes" [ngClass]="{'invalid-error-box': !notifyPartyContact}">
              <mat-form-field [floatLabel]="'never'" class="w-100">
                <input matInput placeholder="Type notify party address" readonly
                  [value]="renderContactData(notifyPartyContact)" [disabled]="isNotifyPartyContactDisable" />
                <button matSuffix mat-icon-button class="booking-remove-contact-button"
                  (click)="onRemoveBookingContact(contactTypes.notifyParty)"
                  *ngIf="notifyPartyContact && !isNotifyPartyContactDisable">
                  <span class="remove-contact-icon"></span>
                </button>
              </mat-form-field>
            </div>
            <div class="add-booking-container-btn" *ngIf="!isNotifyPartyContactDisable">
              <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                (click)="viewOrganizationContacts(contactTypes.notifyParty)">
                <span class="add-contact-icon"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="containers">
        <div *ngFor="let containerForm of shipmentInstructionBookingForm.get('containers')?.controls;let i = index">
          <div class="booking-shipping-instructions-containers-container"
            [ngClass]="shipmentInstruction?.shipmentTypeCode === shipmentTypes.LCL ? 'lcl' : ''">
            <div [formGroup]="containerForm" *ngIf="shipmentInstruction?.shipmentTypeCode === shipmentTypes.FCL">
              <div class="booking-shipping-instructions-content-wrapper">
                <div class="booking-shipping-instructions-title">
                  <span>Container {{ i + 1 }}</span>
                </div>
                <div class="booking-shipping-instructions-subtitle">
                  <span>{{ containerForm.get('equipmentTypeDescription')?.value }}</span>
                </div>
                <div class="field-box-spacing">
                  <span class="booking-shipping-instructions-header-title">Cargo Details</span>
                </div>
                <div class="booking-shipping-instructions-container-content">
                  <div class="booking-container-field-box-right weight">
                    <span class="booking-shipping-instructions-field-title">Weight</span>
                    <div class="booking-shipping-instructions-field weight-selector" [ngClass]="{
                      'invalid-error-box':
                        !containerForm.get('cargoGrossWeight').valid &&
                        containerForm.get('cargoGrossWeight')?.touched
                    }">
                      <div class="weight-content-wrapper">
                        <mat-form-field [floatLabel]="'never'" class="w-100">
                          <input matInput placeholder="0" formControlName="cargoGrossWeight" />
                        </mat-form-field>
                      </div>
                      <div class="weight-select-wrapper">
                        <mat-form-field class="last">
                          <mat-select formControlName="cargoGrossWeightUnitCode">
                            <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                              {{ weightUnit.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="booking-shipping-instructions-container-content mt-4">
                  <div class="booking-container-field-box-left field-box-spacing">
                    <span class="booking-shipping-instructions-field-title">Container Number</span>
                    <div class="booking-shipping-instructions-field" [ngClass]="{
                      'invalid-error-box':
                        !containerForm.get('containerNumber').valid &&
                        containerForm.get('containerNumber')?.touched
                    }">
                      <mat-form-field [floatLabel]="'never'" class="w-100">
                        <input matInput placeholder="XXXX00000000" formControlName="containerNumber" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="booking-container-field-box-right field-box-spacing">
                    <span class="booking-shipping-instructions-field-title">Seal Number</span>
                    <div class="booking-shipping-instructions-field" [ngClass]="{
                      'invalid-error-box':
                        !containerForm.get('sealNumber').valid &&
                        containerForm.get('sealNumber')?.touched
                    }">
                      <mat-form-field [floatLabel]="'never'" class="w-100">
                        <input matInput placeholder="000,000" formControlName="sealNumber" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div *ngIf="isShowCollectContact">
                  <div class="collection-wrapper">
                    <div class="field-box-spacing">
                      <span class="booking-shipping-instructions-header-title">Collection</span>
                    </div>
                    <div class="booking-shipping-instructions-field-box field-box-spacing">
                      <span class="booking-shipping-instructions-field-title">From</span>
                      <div class="booking-shipping-instructions-field from-to-wrapper">
                        <div class="collect-contact-from-wrapper">
                          <mat-form-field [floatLabel]="'never'" class="w-100">
                            <input matInput placeholder="Type collect address" readonly
                              [value]="renderContactData(containerForm.get('collectContact').value)"
                              [disabled]="containerForm.get('collectContact').disabled" />
                            <button matSuffix mat-icon-button class="booking-remove-contact-button"
                              (click)="onRemoveBookingContact(contactTypes.collect, containerForm.get('id').value)"
                              *ngIf="containerForm.get('collectContact').value && !containerForm.get('collectContact').disabled">
                              <span class="remove-contact-icon"></span>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="add-booking-container-btn" *ngIf="!containerForm.get('collectContact').disabled">
                          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                            (click)="viewOrganizationContacts(contactTypes.collect, containerForm.get('id').value)">
                            <span class="add-contact-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="booking-shipping-instructions-field-box field-box-spacing">
                      <span class="booking-shipping-instructions-field-title">Loading Reference</span>
                      <div class="booking-shipping-instructions-field">
                        <mat-form-field [floatLabel]="'never'" class="w-100">
                          <input matInput placeholder="Type loading reference" formControlName="collectionReference" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="booking-shipping-instructions-field-box field-box-spacing booking-cargo-date-time">
                      <span class="booking-shipping-instructions-field-title">Collection date and time</span>
                      <div class="booking-shipping-instructions-field" [ngClass]="{
                      'invalid-error-box': !containerForm.get('collectionDate').valid
                      && containerForm.get('collectionDate')?.touched
                    }">
                        <mat-form-field class="full-width" [floatLabel]="'never'">
                          <input matInput [ngxMatDatetimePicker]="picker" formControlName="collectionDate"
                            placeholder="Date & Time">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <ngx-mat-datetime-picker #picker>
                          </ngx-mat-datetime-picker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isShowDropContact">
                  <div class="delivery-wrapper">
                    <div class="field-box-spacing">
                      <span class="booking-shipping-instructions-header-title">Delivery</span>
                    </div>
                    <div class="booking-shipping-instructions-field-box">
                      <span class="booking-shipping-instructions-field-title">Delivery Address</span>
                      <div class="booking-shipping-instructions-field from-to-wrapper">
                        <div class="collect-contact-to-wrapper">
                          <mat-form-field [floatLabel]="'never'" class="w-100">
                            <input matInput placeholder="Type drop address" readonly
                              [value]="renderContactData(containerForm.get('dropContact').value)"
                              [disabled]="containerForm.get('dropContact').disabled" />
                            <button matSuffix mat-icon-button class="booking-remove-contact-button"
                              (click)="onRemoveBookingContact(contactTypes.drop, containerForm.get('id').value)"
                              *ngIf="containerForm.get('dropContact').value && !containerForm.get('dropContact').disabled">
                              <span class="remove-contact-icon"></span>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="add-booking-container-btn" *ngIf="!containerForm.get('dropContact').disabled">
                          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                            (click)="viewOrganizationContacts(contactTypes.drop, containerForm.get('id').value)">
                            <span class="add-contact-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div formArrayName="commodities" class="booking-shipping-instructions-content-wrapper commodities">
                <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls;let i = index">
                  <div class="comodity-title field-box-spacing">
                    <span class="booking-shipping-instructions-header-title">Commodity {{ i + 1 }}</span>
                    <div class="text-right">
                      <button mat-icon-button matSuffix color="warn" (click)="onDeleteCommodity(containerForm, i)"
                        *ngIf="containerForm.get('commodities')?.controls.length !== 1">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div [formGroup]="commodityForm">
                    <div class="booking-shipping-instructions-field-box field-box-spacing">
                      <span class="booking-shipping-instructions-field-title">Marks and Numbers</span>
                      <div class="booking-shipping-instructions-field">
                        <mat-form-field [floatLabel]="'never'" class="w-100">
                          <input matInput placeholder="e.g. X0_000000" formControlName="marksAndNumbers" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="booking-shipping-instructions-container-content packing-wrapper">
                      <div class="booking-container-field-box-left field-box-spacing">
                        <span class="booking-shipping-instructions-field-title">Type of Packing</span>
                        <span class="field-title subtitle">Start typing (min 1 letters) for auto-completion</span>
                        <div class="booking-shipping-instructions-field">
                          <app-package-type-autocomplete [pageSize]="10"
                            [packageTypeControl]="commodityForm.get('packageTypeCode')" class="w-100"
                            [isDisable]="commodityForm.get('packageTypeCode')?.disabled">
                          </app-package-type-autocomplete>
                        </div>
                      </div>
                      <div class="booking-container-field-box-right field-box-spacing">
                        <span class="booking-shipping-instructions-field-title">Product Value</span>
                        <div class="booking-shipping-instructions-field country-selector" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('productValue').valid
                           && commodityForm.get('productValue')?.touched }">
                          <div class="product-value-number-wrapper">
                            <mat-form-field [floatLabel]="'never'" class="w-100">
                              <input matInput placeholder="0" formControlName="productValue" />
                            </mat-form-field>
                          </div>
                          <div class="select-currency-wrapper">
                            <mat-form-field class="last">
                              <mat-select formControlName="productValueCurrencyCode">
                                <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                                  {{ currency.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="booking-shipping-instructions-container-content">
                      <div class="booking-container-field-box-left field-box-spacing">
                        <span class="booking-shipping-instructions-field-title">Commodity Code</span>
                        <div class="booking-shipping-instructions-field searching" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('commodityTypeCode').valid
                           && commodityForm.get('commodityTypeCode')?.touched }">
                          <mat-form-field [floatLabel]="'never'" class="w-100">
                            <input matInput type="text" formControlName="commodityTypeCode"
                              placeholder='e.g. SCTG 37210' readonly (click)="onOpenComodityDialog(
                                commodityForm, 'commodityTypeCode', 'commodityDescription')" />
                          </mat-form-field>
                          <button class="t-10" matSuffix mat-icon-button
                            *ngIf="!commodityForm.get('commodityTypeCode').disabled"
                            (click)="onOpenComodityDialog(commodityForm, 'commodityTypeCode', 'commodityDescription')">
                            <mat-icon>search</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="booking-container-field-box-right field-box-spacing">
                        <span class="booking-shipping-instructions-field-title">How Many Packages</span>
                        <div class="booking-shipping-instructions-field" [ngClass]="{
                          'invalid-error-box': !commodityForm.get('numberOfPackages').valid
                          && commodityForm.get('numberOfPackages')?.touched
                        }">
                          <mat-form-field [floatLabel]="'never'" class="w-100">
                            <input matInput placeholder="0" formControlName="numberOfPackages" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="booking-shipping-instructions-container-content">
                      <div class="booking-container-field-box-left volume">
                        <span class="booking-shipping-instructions-field-title">Volume</span>
                        <div class="booking-shipping-instructions-field volume-selector" [ngClass]="{
                         'invalid-error-box': !commodityForm.get('volume').valid
                          && commodityForm.get('volume')?.touched }">
                          <div class="volume-content-wrapper">
                            <mat-form-field [floatLabel]="'never'" class="w-100">
                              <input matInput placeholder="0" formControlName="volume" />
                            </mat-form-field>
                          </div>
                          <div class="volume-content-selector">
                            <mat-form-field class="last">
                              <mat-select formControlName="volumeUnitCode">
                                <mat-option *ngFor="let volumeUnit of volumeUnits" [value]="volumeUnit.value">
                                  {{ volumeUnit.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="booking-container-field-box-right weight">
                        <span class="booking-shipping-instructions-field-title">Weight</span>
                        <div class="booking-shipping-instructions-field weight-selector" [ngClass]="{
                            'invalid-error-box': !commodityForm.get('weight').valid
                            && commodityForm.get('weight')?.touched
                          }">
                          <div class="weight-content-wrapper">
                            <mat-form-field [floatLabel]="'never'" class="w-100">
                              <input matInput placeholder="0" formControlName="weight" />
                            </mat-form-field>
                          </div>
                          <div class="weight-select-wrapper">
                            <mat-form-field class="last">
                              <mat-select formControlName="weightUnitCode">
                                <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                                  {{ weightUnit.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="booking-shipping-instructions-another-commodity-wrapper"
                  *ngIf="!isAddCommodityButtonDisable">
                  <div class="booking-shipping-instructions-reference-add">
                    <button mat-button class="booking-shipping-instructions-reference-add-button"
                      (click)="onAddCommodity(containerForm)">
                      <span matPrefix class="booking-shipping-instructions-reference-add-icon">
                        <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
                      </span>
                      <span class="booking-shipping-instructions-reference-add-title">Add Commodity</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div [formGroup]="containerForm" *ngIf="shipmentInstruction?.shipmentTypeCode === shipmentTypes.LCL
              || shipmentInstruction?.shipmentTypeCode === shipmentTypes.AIR">
              <div class="booking-shipping-instructions-content-wrapper">
                <div class="booking-shipping-instructions-title">
                  <span>Load {{ i + 1 }}</span>
                </div>
                <div formArrayName="commodities">
                  <div *ngFor="let commodityForm of containerForm.get('commodities')?.controls;let i = index">
                    <div [formGroup]="commodityForm">
                      <div class="booking-shipping-instructions-commodity-wrapper field-box-spacing">
                        <div class="field-box-spacing">
                          <span class="booking-shipping-instructions-header-title">Commodity {{ i + 1 }}</span>
                          <div class="booking-shipping-instructions-subtitle">
                            <span>{{ getDimensions(commodityForm) }}</span>
                          </div>
                        </div>
                        <div class="booking-shipping-instructions-field-box field-box-spacing">
                          <span class="booking-shipping-instructions-field-title">Marks and Numbers</span>
                          <div class="booking-shipping-instructions-field">
                            <mat-form-field [floatLabel]="'never'" class="w-100">
                              <input matInput placeholder="e.g. X0_000000" formControlName="marksAndNumbers" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="booking-shipping-instructions-container-content">
                          <div class="booking-container-field-box-left field-box-spacing">
                            <span class="booking-shipping-instructions-field-title">Commodity Code</span>
                            <div class="booking-shipping-instructions-field searching" [ngClass]="{
                              'invalid-error-box': !commodityForm.get('commodityTypeCode').valid
                               && commodityForm.get('commodityTypeCode')?.touched }">
                              <mat-form-field [floatLabel]="'never'" class="w-100">
                                <input matInput type="text" formControlName="commodityTypeCode"
                                  placeholder='e.g. SCTG 37210' readonly
                                  (click)="onOpenComodityDialog(commodityForm, 'commodityTypeCode', 'commodityDescription')" />
                              </mat-form-field>
                              <button class="t-10" matSuffix mat-icon-button
                                *ngIf="!commodityForm.get('commodityTypeCode').disabled"
                                (click)="onOpenComodityDialog(commodityForm, 'commodityTypeCode', 'commodityDescription')">
                                <mat-icon>search</mat-icon>
                              </button>
                            </div>
                          </div>
                          <div class="booking-container-field-box-right field-box-spacing">
                            <span class="booking-shipping-instructions-field-title">How Many Packages</span>
                            <div class="booking-shipping-instructions-field" [ngClass]="{
                              'invalid-error-box': !commodityForm.get('numberOfPackages').valid
                               && commodityForm.get('numberOfPackages')?.touched }">
                              <mat-form-field [floatLabel]="'never'" class="w-100">
                                <input matInput placeholder="0" formControlName="numberOfPackages" />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="booking-shipping-instructions-container-content packing-wrapper">
                          <div class="booking-container-field-box-left field-box-spacing">
                            <span class="booking-shipping-instructions-field-title">Type of Packing</span>
                            <span class="field-title subtitle">Start typing (min 1 letter) for auto-completion</span>
                            <div class="booking-shipping-instructions-field">
                              <app-package-type-autocomplete [pageSize]="10"
                                [packageTypeControl]="commodityForm.get('packageTypeCode')" class="w-100"
                                [isDisable]="commodityForm.get('packageTypeCode')?.disabled">
                              </app-package-type-autocomplete>
                            </div>
                          </div>
                          <div class="booking-container-field-box-right field-box-spacing">
                            <span class="booking-shipping-instructions-field-title">Product Value</span>
                            <div class="booking-shipping-instructions-field country-selector" [ngClass]="{
                             'invalid-error-box': !commodityForm.get('productValue').valid
                              && commodityForm.get('productValue')?.touched }">
                              <div class="product-value-number-wrapper">
                                <mat-form-field [floatLabel]="'never'" class="w-100">
                                  <input matInput placeholder="0" formControlName="productValue" />
                                </mat-form-field>
                              </div>
                              <div class="select-currency-wrapper">
                                <mat-form-field class="last">
                                  <mat-select formControlName="productValueCurrencyCode">
                                    <mat-option *ngFor="let currency of currencyCodes" [value]="currency.value">
                                      {{ currency.name }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="booking-shipping-instructions-load-content">
                          <div class="booking-container-field-box-right">
                            <span class="booking-shipping-instructions-field-title">Weight</span>
                            <div class="booking-shipping-instructions-field weight-selector" [ngClass]="{
                              'invalid-error-box': !commodityForm.get('weight').valid
                               && commodityForm.get('weight')?.touched }">
                              <div class="weight-content-wrapper">
                                <mat-form-field [floatLabel]="'never'" class="w-100">
                                  <input matInput placeholder="0" formControlName="weight" />
                                </mat-form-field>
                              </div>
                              <div class="weight-select-wrapper">
                                <mat-form-field class="last">
                                  <mat-select formControlName="weightUnitCode">
                                    <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                                      {{ weightUnit.name }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="collection-wrapper">
                  <div class="field-box-spacing">
                    <span class="booking-shipping-instructions-header-title">Collection</span>
                  </div>
                  <div class="booking-shipping-instructions-field-box field-box-spacing" *ngIf="isShowCollectContact">
                    <span class="booking-shipping-instructions-field-title">From</span>
                    <div class="booking-shipping-instructions-field from-to-wrapper">
                      <div class="collect-contact-from-wrapper">
                        <mat-form-field [floatLabel]="'never'" class="w-100">
                          <input matInput placeholder="Type collect address" readonly
                            [value]="renderContactData(containerForm.get('collectContact').value)"
                            [disabled]="containerForm.get('collectContact').disabled" />
                          <button matSuffix mat-icon-button class="booking-remove-contact-button"
                            (click)="onRemoveBookingContact(contactTypes.collect, containerForm.get('id').value)"
                            *ngIf="containerForm.get('collectContact').value && !containerForm.get('collectContact').disabled">
                            <span class="remove-contact-icon"></span>
                          </button>
                        </mat-form-field>
                      </div>
                      <div class="add-booking-container-btn" *ngIf="!containerForm.get('collectContact').disabled">
                        <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                          (click)="viewOrganizationContacts(contactTypes.collect, containerForm.get('id').value)">
                          <span class="add-contact-icon"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="booking-shipping-instructions-field-box field-box-spacing">
                    <span class="booking-shipping-instructions-field-title">Loading Reference</span>
                    <div class="booking-shipping-instructions-field">
                      <mat-form-field [floatLabel]="'never'" class="w-100">
                        <input matInput placeholder="Type loading reference" formControlName="collectionReference" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="booking-shipping-instructions-field-box field-box-spacing booking-cargo-date-time">
                    <span class="booking-shipping-instructions-field-title">Collection date and time</span>
                    <div class="booking-shipping-instructions-field" [ngClass]="{
                      'invalid-error-box': !containerForm.get('collectionDate').valid
                      && containerForm.get('collectionDate')?.touched
                    }">
                      <mat-form-field class="full-width" [floatLabel]="'never'">
                        <input matInput [ngxMatDatetimePicker]="picker" formControlName="collectionDate"
                          placeholder="Date & Time">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="isShowDropContact">
                  <div class="delivery-wrapper">
                    <div class="field-box-spacing">
                      <span class="booking-shipping-instructions-header-title">Delivery</span>
                    </div>
                    <div class="booking-shipping-instructions-field-box">
                      <span class="booking-shipping-instructions-field-title">Delivery Address</span>
                      <div class="booking-shipping-instructions-field from-to-wrapper">
                        <div class="collect-contact-to-wrapper">
                          <mat-form-field [floatLabel]="'never'" class="w-100">
                            <input matInput placeholder="Type drop address" readonly
                              [value]="renderContactData(containerForm.get('dropContact').value)"
                              [disabled]="containerForm.get('dropContact').disabled" />
                            <button matSuffix mat-icon-button class="booking-remove-contact-button"
                              (click)="onRemoveBookingContact(contactTypes.drop, containerForm.get('id').value)"
                              *ngIf="containerForm.get('dropContact').value && !containerForm.get('dropContact').disabled">
                              <span class="remove-contact-icon"></span>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="add-booking-container-btn" *ngIf="!containerForm.get('dropContact').disabled">
                          <button mat-icon-button matTooltip="Select contact" class="booking-add-contact-button"
                            (click)="viewOrganizationContacts(contactTypes.drop, containerForm.get('id').value)">
                            <span class="add-contact-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
