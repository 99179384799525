import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClarityService {

  constructor() { }

  loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            clarity("set", "userId", JSON.parse(localStorage.getItem("userInfo")).userId.toString());
            clarity("set", "organizationId", JSON.parse(localStorage.getItem("appsettings")).organizationId.toString());
        })(window, document, "clarity", "script", "h2587ekv00");
    `;
    document.head.appendChild(script);
  }
}
