import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DocumentComment } from '../../../shared/bookings/booking-documents/booking-documents.model';
import { BookingDocumentsService } from '../../../shared/bookings/booking-documents/booking-documents.service';
import { BookingFile } from '../../../shared/bookings/bookings.model';
import { CommentsDialogComponent } from './comments-dialog/comments-dialog.component';

@Component({
  selector: 'app-booking-files-documents-list',
  templateUrl: './booking-files-documents-list.component.html',
  styleUrls: ['./booking-files-documents-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingFilesDocumentsListComponent {
  @Input() bookingDocuments: BookingFile[] = [];
  @Input() bookingId: string = undefined;
  canView = true;
  isSecuredUrl = true;

  constructor(
    private bookingDocumentsService: BookingDocumentsService,
    private dialog: MatDialog
  ) { }

  onEditComment(document: BookingFile) {
    this.openCommentsDialog(document);
  }

  getCommentData(comments: DocumentComment[]) {
    if (comments && comments.length > 0) {
      const lastComment = comments.slice(-1);
      return lastComment[0]?.comment;
    }
    return '';
  }

  onViewFile(file: BookingFile) {
    this.getSecureFileUrl(file);
  }

  private getSecureFileUrl(file: BookingFile) {
    this.bookingDocumentsService
      .getSignedUrl(this.bookingId, file.id)
      .subscribe(x => {
        window.open(x.url, "_blank");
      },
        error => {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              message: `${error.error}`,
              showConfirmButton: true,
              confirmButtonText: "Ok"
            },
          });
        });
  }

  private openCommentsDialog(document: BookingFile) {
    const dialogRef = this.dialog.open(CommentsDialogComponent, {
      disableClose: true,
      panelClass: 'document-comments-dialog-container',
      autoFocus: false,
      data: {
        document: document
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.document) {
        const selectedDocument = this.bookingDocuments.find(x => x.id === result.document.id);
        if (selectedDocument !== undefined) {
          const index = this.bookingDocuments.indexOf(selectedDocument);
          this.bookingDocuments[index] = result.document;
        }
      }
    });
  }

}
