import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NotificationService } from "@ship4wd/ngx-common";
import { SalesforceService } from "../../../../shared/services/salesforce.service";
import { DashboardService } from "../../../../shared/widgets/dashboard.service";
import { ApplicationFileType } from "../../../../shared/widgets/widgets.model";

@Component({
  selector: "app-ads-widget",
  templateUrl: "./ads-widget.component.html",
  styleUrls: ["./ads-widget.component.scss"],
})
export class AdsWidgetComponent implements OnInit {
  @Input() widget;
  @Input() editable: boolean;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("shipNowBannerTemplate", { read: TemplateRef })
  shipNowBannerTemplate: TemplateRef<ElementRef>;
  @ViewChild("customsClearanceBannerTemplate", { read: TemplateRef })
  customsClearanceBannerTemplate: TemplateRef<ElementRef>;
  @ViewChild("expertHelpBannerTemplate", { read: TemplateRef })
  expertHelpBannerTemplate: TemplateRef<ElementRef>;
  @ViewChild("creditBannerTemplate", { read: TemplateRef })
  creditBannerTemplate: TemplateRef<ElementRef>;

  isLoading: boolean = false;
  isError: boolean = false;
  errorMessage: string;
  dashboardBannerUrl: string = null;
  dashboardBanners: TemplateRef<ElementRef>[] = [];

  constructor(
    private notificationService: NotificationService,
    private dashboardService: DashboardService,
    private salesforceService: SalesforceService
  ) { }

  ngOnInit(): void {
    this.dashboardBannerUrl =
      this.dashboardService.getSettings()?.dashboardBannerUrl;
    if (this.dashboardBannerUrl == null) {
      this.getDashboardBanner();
    }
  }

  onRemove(): void {
    this.remove.next(this.widget.id);
  }

  getDashboardBanner(): void {
    this.isLoading = true;
    this.dashboardService
      .getApplicationFile(ApplicationFileType.dashboardBanner)
      .subscribe(
        (imageUrl: string) => {
          this.dashboardBannerUrl = imageUrl;
          this.dashboardService.setDashboardBanner(this.dashboardBannerUrl);
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.dashboardBanners = [
          this.creditBannerTemplate,
          this.expertHelpBannerTemplate,
          this.customsClearanceBannerTemplate,
          this.shipNowBannerTemplate,
        ];
      });
  }

  async openEmbeddedChat(): Promise<void> {
    await this.salesforceService.openChat();
  }
}
