import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutService {
    private toolbarShowDashboardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarShowDashboard = this.toolbarShowDashboardSubject.asObservable();
    private toolbarShowMyShipmentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarShowMyShipment = this.toolbarShowMyShipmentSubject.asObservable();
    private toolbarShowFinanceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarShowFinance = this.toolbarShowFinanceSubject.asObservable();
    private toolbarShowCreditLineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarShowCreditLine = this.toolbarShowCreditLineSubject.asObservable();
    private toolbarQuoteResultsPageSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toolbarQuoteResultsPage = this.toolbarQuoteResultsPageSubject.asObservable();

    constructor() {
    }

    setToolbarShowDashboard(show: boolean = true) {
        this.toolbarShowDashboardSubject.next(show);
    }

    setToolbarShowMyShipment(show: boolean = true) {
        this.toolbarShowMyShipmentSubject.next(show);
    }

    setToolbarShowFinance(show: boolean = true) {
        this.toolbarShowFinanceSubject.next(show);
    }

    setToolbarShowCreditLine(show: boolean = true) {
        this.toolbarShowCreditLineSubject.next(show);
    }

    setToolbarQuoteResultsPage(show: boolean = false) {
        this.toolbarQuoteResultsPageSubject.next(show);
    }

    setDefault() {
        this.setToolbarShowDashboard();
        this.setToolbarShowMyShipment();
        this.setToolbarShowFinance();
        this.setToolbarShowCreditLine();
        this.setToolbarQuoteResultsPage();
    }
}