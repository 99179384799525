import { Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "@ship4wd/ngx-authorization";
import { NotificationService, ValidationService } from "@ship4wd/ngx-common";
import { combineLatest, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
  Organization,
  OrganizationMembers,
  OrganizationViewModel,
} from "../../desktop/manage-organization/manage-organization.model";
import { ManageOrganizationService } from "../../desktop/manage-organization/manage-organization.service";
import { AppSettingsService } from "../../shared/services/app-settings/app-settings.service";
import { GlobalTradeTypeCode } from "../../shared/shared.model";
import { LayoutMode } from "../layout/layout.model";
import { LayoutService } from "../layout/layout.service";

@Component({
  selector: "app-manage-organization",
  templateUrl: "./manage-organization.component.html",
  styleUrls: ["./manage-organization.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageOrganizationComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoading = false;
  isDetails = true;
  isSubmit = false;
  isNavigationShowing = true;
  organization: Organization;
  organizationMembers: OrganizationMembers;
  organizationForm: FormGroup;
  organizationId: string;
  countryAutocompleteValue: string;
  userId: string;

  organizationRoleTypeCodes: any[] = [
    {
      name: GlobalTradeTypeCode[GlobalTradeTypeCode.importer],
      value: GlobalTradeTypeCode.importer,
      isChecked: false,
    },
    {
      name: GlobalTradeTypeCode[GlobalTradeTypeCode.exporter],
      value: GlobalTradeTypeCode.exporter,
      isChecked: false,
    },
  ];

  @ViewChild('header') header: TemplateRef<ElementRef>;

  constructor(
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private settingsService: AppSettingsService,
    private userService: UserService,
    private manageOrganizationService: ManageOrganizationService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: FormBuilder
  ) {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.getOrganizationInformationWithMembers();

    const url = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: { org: this.organizationId },
      })
      .toString();
    this.location.go(url);
  }

  ngOnInit(): void {
    this.userId = this.userService.getUser()?.userId;
  }

  ngOnDestroy(): void {
    this.layoutService.setWrapperHeader();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.ORGANIZATIONS);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(true);
      this.layoutService.setToolbarTitle("Manage Organization");
      this.layoutService.setToolbarDescription("");
      this.layoutService.setWrapperHeader(this.header);
    }, 0);
  }

  onTabChange(tab: number): void {
    if (tab === 0) {
      this.isDetails = true;
    } else {
      this.isDetails = false;
    }

    if (!this.isNavigationShowing) {
      this.isNavigationShowing = true;
      this.layoutService.setToolbarTitle("Manage Organization");
    }
  }

  onTeamMemberEvent(): void {
    this.getOrganizationInformationWithMembers();
  }

  onMemberPageEvent(show: boolean): void {
    this.isNavigationShowing = show;

    if (!show) {
      this.layoutService.setToolbarTitle("Manage Organization");
    }
  }

  onChangeRoles(roleData: any): void {
    if (roleData) {
      this.organizationRoleTypeCodes.find(
        (x) => x.value === roleData.organizationRoleTypeCode
      ).isChecked = roleData.checked;
    }
  }

  onBack(): void {
    this.router.navigate(["/dashboard"]);
  }

  onSave(): void {
    this.validationService.validate(this.organizationForm);

    if (!this.organizationForm.valid) {
      this.notificationService.error(
        "Can't save changes because of an input errors, please check out the form"
      );
      return;
    }

    const organizationData = this.organizationForm
      .value as OrganizationViewModel;

    const roles = [];
    this.organizationRoleTypeCodes.forEach((e) => {
      if (e.isChecked) {
        roles.push(e.value);
      }
    });

    const selectedRole =
      roles.length === this.organizationRoleTypeCodes.length
        ? GlobalTradeTypeCode.both
        : roles.length > 0
          ? roles[0]
          : GlobalTradeTypeCode.unknown;
    organizationData.globalTradeTypeId = selectedRole;

    const organization = { ...this.organization, ...organizationData };
    this.updateOrganization(organization);
  }

  private getOrganizationInformationWithMembers(): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap((x) =>
          combineLatest([
            this.manageOrganizationService.getOrganization(this.organizationId),
            this.manageOrganizationService.getTeamAndInviteMembersById(
              this.organizationId,
              this.userId
            ),
          ])
        )
      )
      .subscribe(
        ([organization, organizationMembers]) => {
          this.updateOrganizationData(organization);
          this.organizationMembers = organizationMembers;
          this.createOrganizationForm();
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private updateOrganization(organization: OrganizationViewModel): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() =>
          this.manageOrganizationService.updateOrganization(
            this.organizationId,
            organization
          )
        ),
        switchMap(() =>
          this.manageOrganizationService.getOrganization(this.organizationId)
        )
      )
      .subscribe(
        (organization) => {
          this.updateOrganizationData(organization);
          this.notificationService.success(
            "Organization has been successfully changed."
          );
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private updateOrganizationData(organization: Organization): void {
    this.organization = organization;
    this.countryAutocompleteValue = this.getPropertyValue("countryCode");

    if (organization.globalTradeTypeId) {
      if (organization.globalTradeTypeId === GlobalTradeTypeCode.both) {
        this.organizationRoleTypeCodes.map((x) => (x.isChecked = true));
      } else {
        if (organization.globalTradeTypeId !== GlobalTradeTypeCode.unknown) {
          this.organizationRoleTypeCodes.find(
            (x) => x.value === organization.globalTradeTypeId
          ).isChecked = true;
        }
      }
    }
  }

  private createOrganizationForm(): void {
    this.organizationForm = this.fb.group({
      name: [{value: this.getPropertyValue('name'), disabled: true}],
      legalName: [this.getPropertyValue('legalName')],
      fax: [this.getPropertyValue("fax")],
      globalTradeTypeId: [
        this.getPropertyValue("globalTradeTypeId"),
        Validators.required,
      ],
      website: [this.getPropertyValue("website")],
      industry: [this.getPropertyValue("industry")],
      tradeNeeds: [this.getPropertyValue("tradeNeeds")],
      taxId: [this.getPropertyValue("taxId"), Validators.required],
      street: [this.getPropertyValue("street"), Validators.required],
      address2: [this.getPropertyValue("address2")],
      city: [this.getPropertyValue("city"), Validators.required],
      postalCode: [this.getPropertyValue("postalCode"), Validators.required],
      countryCode: [this.getPropertyValue("countryCode"), Validators.required],
      state: [this.getPropertyValue("state"), Validators.required],
    });
  }

  private getPropertyValue(propertyName: string): any {
    return this.organization[propertyName] ?? "";
  }
}
