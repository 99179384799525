import { Pipe, PipeTransform } from '@angular/core';
import { AdditionalRate } from '../../../desktop/quotes/quotes.model';
import { ExtenderTripRate } from '../../shared.model';

@Pipe({ name: 'additionalTypeSurcharge' })
export class AdditionalTypeSurchargePipe implements PipeTransform {

  transform(value?: ExtenderTripRate, applicableType?: string, additionalType?: string): AdditionalRate {
    return value.surcharges.filter((x) => {
        return x.applicableType === applicableType && x.additionalType === additionalType;
      })[0];
  }
}