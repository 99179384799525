import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  ViewEncapsulation,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService, QueryUtils } from "@ship4wd/ngx-common";
import { FilesService } from "../../../shared/services/files.service";
import { environment } from "../../../../environments/environment";
import {
  Booking,
  BookingsReadMode,
  BookingsQuery,
  BookingStatusCode,
  BookingsQueryParameters,
} from '../../../shared/bookings/bookings.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { BookingPaidStatusCode, CompletedOrderStatusType, Page } from '../../../shared/shared.model';
import { LayoutService } from "../../layout/layout.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit, OnChanges {
  bookings: Booking[] = [];
  openOrders: Booking[] = [];
  submittedOrders: Booking[] = [];
  completedOrders: Booking[] = [];
  totalBookings: number = 0;
  pageNo: number = 1;
  isLoadMore: boolean = false;
  dataSource: MatTableDataSource<Booking> = new MatTableDataSource<Booking>();
  isNoBookings: boolean = false;
  isDraftStatusShown: boolean = true;
  isCanceledShown: boolean = true;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  @Input()
  isLoading = true;
  @Input()
  isFinance: boolean = false;
  @Input() isCreditLine: boolean = false;
  @Input()
  isOrdersGridViewMode: boolean;

  @Output()
  isLoadingChanged = new EventEmitter<boolean>();

  @Input()
  headerTitle: string;

  @Output()
  headerTitleChanged = new EventEmitter<string>();

  orders: QueryList<ElementRef>;

  @Input() filters: any = null;
  @Input() searchText: string;
  @Input() isClearFilters: boolean;

  isSearchTextWithoutFilters = true;
  isDashboardEnabled: boolean;
  isSortLoading: boolean = false;

  @HostListener("window:scroll", ["$event"])
  onScroll(event): void {
    const activeSection = this.orders
      ?.toArray()
      .findIndex((section) => this.isElementInViewport(section.nativeElement));
    var orderId = this.orders?.toArray()[activeSection]?.nativeElement?.id;

    this.headerTitle = this.getOrdersTitle(orderId);
    this.headerTitleChanged.emit(this.headerTitle);
  }

  sortParameters = [
    { key: BookingsQueryParameters.etd, name: 'etd' },
    { key: BookingsQueryParameters.createTimeUtc, name: 'createTimeUtc' },
    { key: BookingsQueryParameters.updateTimeUtc, name: 'updateTimeUtc' },
    { key: BookingsQueryParameters.atd, name: 'atd' },
    { key: BookingsQueryParameters.duePaymentDate, name: 'duePaymentDate' },
    { key: BookingsQueryParameters.bookingPaidStatusCode, name: 'bookingPaidStatusCode' },
    { key: BookingsQueryParameters.bolOrderNumber, name: 'bolOrderNumber' },
    { key: BookingsQueryParameters.masterBillOfLading, name: 'masterBillOfLading' },
    { key: BookingsQueryParameters.shipmentType, name: 'shipmentType' },
    { key: BookingsQueryParameters.from, name: 'from' },
    { key: BookingsQueryParameters.to, name: 'to' },
    { key: BookingsQueryParameters.statusType, name: 'statusType' },
  ];

  constructor(
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    private layoutService: LayoutService,
    private filesService: FilesService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.isDashboardEnabled = this.getIsDasboardEnabled();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params && params.show == '3') {
        this.isDraftStatusShown = false;
      } else if (params && params.show == '2') {
        this.isCanceledShown = false;
      }
    });

    this.getBookings(this.filters, true);
    this.layoutService.setDefault();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  cancelBooking(): void {
    this.getBookings(this.filters);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filters?.currentValue !== undefined) {
      if (this.filters && this.filters !== null) {
        this.pageNo = 1;
        this.isLoadMore = false;
        this.getBookings(this.filters);
      }
    }

    if (changes?.isClearFilters?.currentValue === true) {
      this.pageNo = 1;
      this.filters = null;
      this.getBookings();
    }

    if (changes?.searchText?.currentValue !== undefined) {
      if (
        this.searchText !== undefined &&
        this.searchText !== null &&
        this.isSearchTextWithoutFilters === true
      ) {
        if (this.searchText.length > 0) {
          const filterData = this.bookings?.filter(
            (p) =>
              p.fromDisplayName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.toDisplayName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.fromBookingSearch?.value
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.toBookingSearch?.value
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.from
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.to
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.firstName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.lastName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.shipperContact?.companyName
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.customerReferenceId
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.statusType?.ship4wdFrontendStatus
                .toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.masterBillOfLading
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase()) ||
              p.bolOrderNumber
                ?.toLowerCase()
                .startsWith(this.searchText.toLocaleLowerCase())
          );

          this.dataSource.data = filterData;
          this.getProcessFilterBooking(filterData);
        } else {
          this.dataSource.data = this.bookings;
          this.getProcessFilterBooking(this.bookings);
        }
      }
    }
  }

  getBookings(filters?: any, isCheckBookingCount = false): void {
    const query = this.prePareBookingsQueryModel(filters);

    if (this.isFinance) {
      query.hasPayments = true;
    }

    this.isLoading = true;
    this.isLoadingChanged.emit(this.isLoading);
    this.bookingsService
      .getPage(query)
      .subscribe(
        (x: Page<Booking>) => {
          this.bookings = this.isLoadMore ? this.bookings.concat(x.items) : x.items;

          if (!this.isDraftStatusShown) {
            this.bookings = this.bookings.filter(booking => booking.isCanceled == false || booking.statusType.code == BookingStatusCode.orderSubmitted);
          }

          if (!this.isCanceledShown) {
            this.bookings = this.bookings.filter(booking => booking.isCanceled == false);
          }

          this.totalBookings = x.totalCount;
          this.dataSource.data = this.bookings;
          if (this.searchText?.length > 0) {
            const filterData = this.bookings?.filter(
              (p) =>
                p.fromDisplayName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.toDisplayName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.fromBookingSearch?.value
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.toBookingSearch?.value
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.from
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.to
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.shipperContact?.firstName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.shipperContact?.lastName
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.customerReferenceId
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.statusType?.ship4wdFrontendStatus
                  .toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.masterBillOfLading
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase()) ||
                p.bolOrderNumber
                  ?.toLowerCase()
                  .startsWith(this.searchText.toLocaleLowerCase())
            );
            this.getProcessFilterBooking(filterData);
          } else {
            this.getProcessFilterBooking(this.bookings);
          }
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.isSortLoading = false;
        this.isLoadingChanged.emit(this.isLoading);
        if (isCheckBookingCount) this.checkIfHasBookings();
      });
  }

  exportBookings(): void {
    const query = this.prePareBookingsQueryModel(this.filters);
    if (this.isFinance) {
      query.hasPayments = true;
    }

    this.isLoading = true;
    this.bookingsService
      .exportBookingsCsv(query)
      .subscribe(
        fileUrl => this.filesService.downloadFileUrl(fileUrl),
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  onLoadMore(): void {
    this.pageNo++;
    this.isLoadMore = true;
    this.getBookings(this.filters);
  }

  onSortBooking(sort: MatSort): void {
    this.isSortLoading = true;
    this.pageNo = 1;
    this.isLoadMore = false;
    this.sort = sort;
    this.getBookings(this.filters);
  }

  onOrdersLoaded(data: QueryList<ElementRef>): void {
    this.orders = data;
  }

  private prePareBookingsQueryModel(filters?: any): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = this.pageNo;
    query.pageSize = environment.pageSize;
    query.sortDesc = true;
    query.readMode = BookingsReadMode.appDashboard;

    if (this.isFinance) {
      query.sortBy = BookingsQueryParameters.atd;
      query.isCanceled = false;
    }

    if (this.sort?.active != undefined) {
      query.sortBy = QueryUtils.getSortBy(this.sortParameters, this.sort?.active, BookingsQueryParameters.updateTimeUtc);
    }
    query.sortDesc = this.sort?.direction != undefined ? QueryUtils.getSortDesc(this.sort?.direction) : true;

    if (filters) {
      if (filters.fromCity) {
        query.fromDisplayName = filters.fromCity;
      }

      if (filters.toCity) {
        query.toDisplayName = filters.toCity;
      }

      if (filters.fromLastUpdateDate) {
        query.fromLastUpdateDate = this.toIsoDateTimeString(
          filters.fromLastUpdateDate
        );
      }

      if (filters.orderStatuses && filters.orderStatuses.length > 0) {
        query.orderStatuses = filters.orderStatuses;
      }

      if (filters.isCanceled !== null) {
        query.isCanceled = filters.isCanceled;
      }

      if (filters.orIsCanceled !== null) {
        query.orIsCanceled = filters.orIsCanceled;
      }

      if (filters.bolOrderNumber) {
        query.bolOrderNumber = filters.bolOrderNumber;
      }

      if (filters.masterBillOfLading) {
        query.masterBillOfLading = filters.masterBillOfLading;
      }

      if (filters.atd) {
        query.atd = this.toIsoDateTimeString(
          filters.atd
        );
      }

      if (filters.duePaymentDate) {
        query.duePaymentDate = this.toIsoDateTimeString(
          filters.duePaymentDate
        );
      }

      if (filters.bookingPaidStatusCode !== null) {
        query.bookingPaidStatusCode = filters.bookingPaidStatusCode;
      }

      if (filters.paidStatusCodes && filters.paidStatusCodes.length > 0) {
        query.paidStatusCodes = filters.paidStatusCodes;
      }

      if (filters?.any) {
        query.any = filters.any;
      }

      if (filters?.hasPayments) {
        query.hasPayments = filters.hasPayments;
      }
    }

    return query;
  }

  private toIsoDateTimeString(date): string {
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  private getOrdersTitle(orderId: string): string {
    if (orderId) {
      const booking = this.bookings.find((x) => x.id == orderId);

      if (this.getcompletedOrderStatusType().includes(booking.statusType.code) || booking.isCanceled) {
        return "Completed Orders";
      }

      if (booking.statusType.code == BookingStatusCode.draft) {
        return "Open Orders";
      }

      return "Submitted Orders";
    }
  }

  private isElementInViewport(el): any {
    var rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 80 &&
      rect.right >= 0 &&
      rect.top <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private getProcessFilterBooking(bookingData: Booking[]): void {
    if (bookingData && bookingData.length > 0) {
      if (!this.isOrdersGridViewMode) {
        bookingData = bookingData.sort(
          (n1, n2) => n1.statusType.code - n2.statusType.code
        );

        bookingData = bookingData.sort((n1, n2) =>
          n1.isCanceled === n2.isCanceled ? 0 : n1.isCanceled ? 1 : -1
        );
      }

      this.headerTitle = this.getOrdersTitle(bookingData[0].id);
      this.headerTitleChanged.emit(this.headerTitle);
    }

    this.completedOrders = bookingData?.filter(
      (x) => {
        return this.getcompletedOrderStatusType().includes(x.statusType.code) || x.isCanceled
      }
    );
    this.openOrders = bookingData?.filter(
      (x) => x.statusType.code == BookingStatusCode.draft && !x.isCanceled
    );
    this.submittedOrders = bookingData?.filter(
      (x) =>
        x.statusType.code != BookingStatusCode.draft &&
        !this.getcompletedOrderStatusType().includes(x.statusType.code) &&
        !x.isCanceled
    );
  }

  private getcompletedOrderStatusType(): BookingStatusCode[] {
    return CompletedOrderStatusType;
  }

  private checkIfHasBookings(): void {
    if (this.totalBookings <= 0) {
      if (this.isFinance) {
        this.dataSource.data.length = 0;
        this.isCreditLine = true;
      }
      else {
        this.isNoBookings = true;
        this.setEmptyBookings();
      }
    }
    else {
      this.layoutService.setToolbarShowMyShipment(true);
      this.layoutService.setToolbarShowFinance(true);
      this.layoutService.setToolbarShowCreditLine(true);
    }
  }

  private getIsDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isDashboardEnabled;
      case "sb":
        return environment.sb.isDashboardEnabled;
      default:
        return environment.isDashboardEnabled;
    }
  }

  private navigateToQuoteSearch(): void {
    this.router.navigate(['/quote-search']);
  }

  private navigateToShipment(): void {
    this.router.navigate(['/shipments']);
  }

  private setEmptyBookings(): void {
    let bookings: Booking[] = [];
    for (let index = 0; index < 5; index++) {
      let booking = {
        id: index.toString(),
        shipmentTypeCode: 3,
        masterBillOfLading: '12345',
        bolOrderNumber: 'zMW1ZptX5P',
        fromDisplayName: 'Ningbo',
        toDisplayName: 'Pearson International',
        isCanceled: false,
        createTimeUtc: '1900-01-01',
        updateTimeUtc: '1900-01-01',
        etd: '1900-01-01',
        bookingPaidStatusCode: BookingPaidStatusCode.paid,
        statusType: {
          code: 1
        }
      } as unknown as Booking;

      bookings.push(booking);
    }

    this.dataSource.data = bookings;
  }
}
