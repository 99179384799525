import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { Observable, Subject, interval, of } from "rxjs";
import { startWith, debounce, distinctUntilChanged, switchMap, map } from "rxjs/operators";
import { CommodityService } from "../services/commodity.service";
import { StringCutterService } from "../services/text-cutter/stringCutter.service";
import { HarmonizedSystemCode, HarmonizedSystemCodeQuery, HarmonizedSystemCodeQueryParameters, Page } from "../shared.model";
import { DeviceDefService } from "../services/device-defining/deviceDef.service";

@Component({
  selector: "commodity-input-ungrouped",
  templateUrl: "./commodity-input-ungrouped.component.html",
  styleUrls: ['./commodity-input-ungrouped.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatFormFieldControl, useExisting: CommodityInputUngroupedComponent },
  ],
  host: {
    '[class.commodity-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class CommodityInputUngroupedComponent
  implements
  OnInit,
  ControlValueAccessor,
  MatFormFieldControl<HarmonizedSystemCode>,
  OnDestroy,
  DoCheck,
  OnChanges {
  @Input()
  get value(): HarmonizedSystemCode | null {
    return this.selectedCommodity;
  }
  set value(data: HarmonizedSystemCode | null) {
    if (data && !this.isCommoditySelected) {
      this.selectedCommodity = data;
      this.commodityControl.setValue(data);
      this.isCommoditySelected = true;
    }

    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.commodityControl.disable() : this.commodityControl.enable();
    this.stateChanges.next();
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input("aria-describedby") userAriaDescribedBy: string;
  @Input() pageSize = 500;
  @Input() minimumLength = 3;
  @Input() isTouch: boolean = false;
  @Input() controlName: string = "Commodity code"

  @HostBinding() id = `commodity-input-${CommodityInputUngroupedComponent.nextId++}`;

  static nextId = 0;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  stateChanges: Subject<void> = new Subject<void>();

  commodityControl: FormControl = new FormControl(null, Validators.required);

  isMobile: boolean;
  focused: boolean = false;
  touched: boolean = false;
  errorState: boolean = false;
  autofilled?: boolean;
  controlType: string = "commodity-input";

  get empty(): boolean {
    return !this.commodityControl.value;
  }

  @Output() onSelectionChanged = new EventEmitter<HarmonizedSystemCode>();
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<unknown>();
  @Output() triggerError = new EventEmitter<string>();

  commodityResponse: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];
  selectedCommodity: HarmonizedSystemCode = null;
  valueTooltip: string = null;
  isCommoditySelected: boolean = false;
  isLoading = false;
  pageNo = 1;

  constructor(
    fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private commodityService: CommodityService,
    private stringCutterService: StringCutterService,
    public device: DeviceDefService,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() public parentFormField: MatFormField,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    this.isMobile = this.device.isMobile();

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnInit(): void {
    this.loadCommodities();
    this.commodityService.resetCommodityAutoComplete.subscribe((isReset) => {
      if (isReset) {
        this.resetCommodityAutocomplete();
      }
    });

    this.valueTooltip = this.value?.description;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isTouch?.currentValue) {
      if (changes?.isTouch?.currentValue === true) {
        this.touched = true;
        this.focused = false;
        this.onTouched();
        this.stateChanges.next();
      }
    }
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.touched = false;
      this.focused = true;
      this.stateChanges.next();
    }

    this.onFocus.emit()
  }

  onFocusOut(event: FocusEvent): void {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.commodityControl.setValue(this.selectedCommodity)
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onChange(event: any): void { }

  onTouched(): void { }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != "input") {
      this._elementRef.nativeElement.querySelector("input").focus();
    }
  }

  onSelectCommodity(commodity: HarmonizedSystemCode): void {
    this.valueTooltip = commodity?.description;
    this.onSelectionChanged.emit(commodity);
  }

  writeValue(data: HarmonizedSystemCode | null): void {
    this.value = data;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".commodity-input-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  displayFn(commodity: HarmonizedSystemCode | null): string | null {
    return commodity ? `${commodity.id} ${commodity.description}` : null;
  }

  markAllInputsAsTouched(): void {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  getHarmonizedSystemCodeDescription(description: string): string {
    return this.stringCutterService.cutter(description, 25);
  }

  clearHtsCodeInput(): void {
    this.resetCommodityAutocomplete();
    this.selectedCommodity = null;
    this.onSelectionChanged.emit(null);
  }

  handleError(event: string): void {
    this.triggerError.emit(event);
  }

  private updateErrorState(): void {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.commodityControl.invalid) &&
      (this.touched || this.ngControl.touched);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  private loadCommodities(): void {
    this.commodityControl.valueChanges
      .pipe(
        startWith(''),
        debounce(() => interval(400)),
        distinctUntilChanged(),
        switchMap((val) => {
          const isSearch = this.checkCommodityBeforeSearch(val);
          return isSearch === true ? this.search(val) : of(isSearch);
        }),
        startWith('')
      )
      .subscribe((x: Page<HarmonizedSystemCode>) => {
        this.isLoading = false;
        this.onLoading.emit(false);

        if (this.isCommoditySelected) {
          this.isCommoditySelected = false;
        } else {
          this.commodityResponse = x.items;
        }
      });
  }

  private search(searchValue: string): Observable<Page<HarmonizedSystemCode>> {
    this.isLoading = true;
    this.onLoading.emit(true);
    let query = new HarmonizedSystemCodeQuery();
    query.pageNo = this.pageNo;
    query.pageSize = this.pageSize;
    query.description = searchValue;
    query.isoCode = searchValue;
    query.any = true;
    query.grouped = false;
    query.sortBy = HarmonizedSystemCodeQueryParameters.isoCode;

    return this.commodityService.getPage(query)
      .pipe(map((response) => response));
  }

  private checkMinimumLength(value: string) {
    return !(value?.length >= this.minimumLength);
  }

  private checkCommodityBeforeSearch(value: string): any {
    if (this.isCommoditySelected) {
      return { items: this.commodityResponse };
    }

    if (this.checkMinimumLength(value)) {
      return { items: [] };
    }
    return true;
  }

  private resetCommodityAutocomplete(): void {
    this.isCommoditySelected = false;
    this.commodityResponse = [];
    this.commodityControl.setValue(null);
  }
}
