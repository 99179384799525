import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-on-dashboard-dialog',
  templateUrl: './on-dashboard-dialog.component.html',
  styleUrls: ['./on-dashboard-dialog.component.scss']
})
export class OnDashboardDialogComponent {

  @Output() accepting = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<OnDashboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  navigateToDashboard() {
    this.dialogRef.close({
      isSuccess: true
    });
  }
}
