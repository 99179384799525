<div class="booking-flow-contacts-manage">
  <div class="flow-contacts-manage-header">
    <div class="row m-0">
      <div class="col-12 px-0">
        <span class="title">Manage contacts</span>
      </div>
      <div class="col-12 px-0">
        <span class="description">
          Select, edit or create a {{ selectedContactTitle }} in {{ contactCountryCode }}.
        </span>
      </div>
    </div>
  </div>
  <div class="flow-contacts-manage-content">
    <div class="row m-0">
      <div class="col px-0">
        <span class="content-title">Suitable Contacts</span>
      </div>
    </div>
    <ng-container *ngIf="filteredContacts && filteredContacts.length > 0">
      <div class="row contact-line" *ngFor="let contact of filteredContacts">
        <div class="col-auto px-0 d-flex align-items-center">
          <div class="line-icon-wrapper">
            <i class="line-icon fi fi-{{ contact.countryCode | lowercase }}"></i>
          </div>
        </div>
        <div class="col px-0 d-flex align-items-center" (click)="onSelect(contact)">
          <span class="line-text"><span class="line-text-strong">{{ contact.firstName }} {{ contact.lastName}}</span>
            {{ contact.address1 ? ', ' + contact.address1 : '' }}</span>
        </div>
        <div class="col-auto pe-0 ps-2 d-flex align-items-center">
          <button class="actions-icon-wrapper" [ngClass]="{ 'd-flex': trigger.menuOpen }"
            [matMenuTriggerFor]="contactMenu" #trigger="matMenuTrigger">
            <i class="s4d-icon s4d-menu actions-icon"></i>
          </button>
          <mat-menu class="contact-menu" #contactMenu="matMenu">
            <button mat-menu-item (click)="onView(contact)">
              <span>View</span>
            </button>
            <button mat-menu-item (click)="onUpdate(contact)">
              <span>Update</span>
            </button>
            <button mat-menu-item (click)="onDeleteContact(contact)">
              <span>Remove</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flow-contacts-manage-footer">
    <div class="row m-0 gap-3">
      <div class="col px-0">
        <button mat-raised-button class="cancel-button" (click)="onBack()">
          Cancel
        </button>
      </div>
      <div class="col px-0">
        <button mat-raised-button class="create-button" (click)="onAdd()">
          <span class="button-inner">
            Create Contact
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
