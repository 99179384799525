import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import {
  SupportedCountriesPairQuery,
  SupportedCountriesSearch,
  SupportedCountriesSearchQuery,
  SupportedCountry
} from "./supported-countries.model";
import { Page } from "../shared.model";
import { EncodeUrlCodec } from "../services/encode-url-codec";

@Injectable({
    providedIn: 'root'
})
export class SupportedCountriesService {

    constructor(
        private internalUrlsService: InternalUrlsService,
        private http: HttpClient) {
    }

    getSupportedCountries(query: SupportedCountriesSearchQuery): Observable<Page<SupportedCountry>> {
        const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
        return this.http.get<Page<SupportedCountry>>(`${this.internalUrlsService.getApiBaseUrl()}/supportedcountries`, { params });
    }

    getSupportedCountriesByCountryUsability(query: SupportedCountriesSearch): Observable<SupportedCountry[]> {
        const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
        return this.http.get<SupportedCountry[]>(`${this.internalUrlsService.getApiBaseUrl()}/supportedcountries/getAllCountriesByCountryUsability`, { params });
    }

    isCountryPairSupported(query: SupportedCountriesPairQuery): Observable<boolean> {
        const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
        return this.http.get<boolean>(`${this.internalUrlsService.getApiBaseUrl()}/supportedcountries/isCountryPairSupported`, { params });
    }
}
