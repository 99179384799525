import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "@ship4wd/ngx-authorization";
import { NotificationService } from "@ship4wd/ngx-common";
import {
  OrganizationInvitationViewModel,
  OrganizationMembers,
  TeamMember,
  TeamMembersInvite,
  TeamMemberViewModel,
} from "../../../desktop/manage-organization/manage-organization.model";
import { InviteMemberService } from "../../../desktop/manage-organization/organization-members/invite-member-dialog/invite-member.service";
import { TeamMemberService } from "../../../desktop/manage-organization/organization-members/team-member-dialog/team-member.service";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { AccessRoleTypeNamePipe } from "../../../shared/pipes/access-role-type-name.pipe";
import {
  AccessRoleType,
  TeamAccessRole,
  TeamMemberPageMode,
  TeamMemberPageType,
  TeamMemberStatusCode,
} from "../../../shared/shared.model";

@Component({
  selector: "app-organization-members",
  templateUrl: "./organization-members.component.html",
  styleUrls: ["./organization-members.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationMembersComponent implements OnInit {
  @Input() organizationMembers: OrganizationMembers;
  @Output() teamMemberEvent = new EventEmitter();
  @Output() memberPageEvent = new EventEmitter<boolean>();

  teamMemberStatuses = TeamMemberStatusCode;
  userId: string;
  isMemberPageActive = false;
  memberPageType: TeamMemberPageType;
  memberPageMode: TeamMemberPageMode;
  memberForPage: any;

  constructor(
    private accessRoleTypeNamePipe: AccessRoleTypeNamePipe,
    private teamMemberService: TeamMemberService,
    private inviteMemberService: InviteMemberService,
    private notificationService: NotificationService,
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userId = this.userService.getUser()?.userId;
  }

  getInitialMemberName(member): string {
    if (member?.firstName || member?.lastName) {
      return (
        member.firstName.charAt(0).toUpperCase() +
        member.lastName.charAt(0).toUpperCase()
      );
    }
    return "";
  }

  getAccessRolesOfInviteMember(accessRoles): string {
    if (accessRoles) {
      let roles = "";
      const rolesList = accessRoles.split(",") as AccessRoleType[];
      rolesList.forEach((x) => {
        roles += this.accessRoleTypeNamePipe.transform(x) + ", ";
      });
      return roles.trim().slice(0, -1);
    }
    return "";
  }

  getAccessRolesOfTeamMember(accessRoles: TeamAccessRole[]): string {
    if (accessRoles) {
      let roles = "";
      accessRoles.forEach((x) => {
        roles += this.accessRoleTypeNamePipe.transform(x.accessRoleType) + ", ";
      });
      return roles.trim().slice(0, -1);
    }
    return "";
  }

  onMemberBack(reload: boolean): void {
    this.memberPageEvent.next(true);
    this.isMemberPageActive = false;

    if (reload) {
      this.teamMemberEvent.emit();
    }
  }

  onAddInviteMember(): void {
    this.memberForPage = null;
    this.memberPageType = TeamMemberPageType.invite;
    this.memberPageMode = TeamMemberPageMode.add;
    this.memberPageEvent.next(false);
    this.isMemberPageActive = true;
  }

  onCancelInviteMember(inviteMember: TeamMembersInvite): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "on-popup-container",
      autoFocus: false,
      data: {
        title: "Confirmation",
        message: "Are you sure you want to cancel invitation?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {
        this.cancelInviteMember(inviteMember.id);
      }
    });
  }

  onResendInviteMember(inviteMember: TeamMembersInvite): void {
    const inviteMemberViewModel =
      this.prepareInviteMemberViewModel(inviteMember);
    this.addEditInviteMember(inviteMemberViewModel, "resend");
  }

  onEditInviteMember(inviteMember: TeamMembersInvite): void {
    this.memberForPage = inviteMember;
    this.memberPageType = TeamMemberPageType.invite;
    this.memberPageMode = TeamMemberPageMode.edit;
    this.memberPageEvent.next(false);
    this.isMemberPageActive = true;
  }

  onEditTeamMember(teamMember: TeamMember): void {
    this.memberForPage = teamMember;
    this.memberPageType = TeamMemberPageType.member;
    this.memberPageMode = TeamMemberPageMode.edit;
    this.memberPageEvent.next(false);
    this.isMemberPageActive = true;
  }

  onRemoveTeamMember(teamMember: TeamMember): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "on-popup-container",
      autoFocus: false,
      data: {
        title: "Confirmation",
        message: "Are you sure you want to remove team member?",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {
        this.removeTeamMember(teamMember.id);
      }
    });
  }

  onSuspendTeamMember(teamMember: TeamMember): void {
    const teamMemberViewModel = this.prepareTeamMemberViewModel(
      teamMember,
      TeamMemberStatusCode.suspended
    );
    this.updateTeamMember(teamMember.id, teamMemberViewModel);
  }

  onReactivateTeamMember(teamMember: TeamMember): void {
    const teamMemberViewModel = this.prepareTeamMemberViewModel(
      teamMember,
      TeamMemberStatusCode.active
    );
    this.updateTeamMember(teamMember.id, teamMemberViewModel);
  }

  private cancelInviteMember(id: string): void {
    this.inviteMemberService.cancelInvite(id, this.userId).subscribe(
      () => {
        this.notificationService.success(
          "Invitation has been successfully cancelled."
        );
        this.teamMemberEvent.emit();
      },
      (error) => this.notificationService.error(error)
    );
  }

  private removeTeamMember(id: string): void {
    this.teamMemberService.deleteTeamMember(id).subscribe(
      () => {
        this.notificationService.success(
          "Team member has been successfully removed."
        );
        this.teamMemberEvent.emit();
      },
      (error) => this.notificationService.error(error)
    );
  }

  private addEditInviteMember(
    inviteMember: OrganizationInvitationViewModel,
    mode: string
  ): void {
    this.inviteMemberService.addEditInviteMember(inviteMember).subscribe(
      () => {
        if (mode === "resend") {
          this.notificationService.success(
            "Invitation has been successfully resent."
          );
        }
        this.teamMemberEvent.emit();
      },
      (error) => this.notificationService.error(error)
    );
  }

  private prepareInviteMemberViewModel(
    inviteMember: TeamMembersInvite
  ): OrganizationInvitationViewModel {
    let organizationInvitationViewModel = new OrganizationInvitationViewModel();
    organizationInvitationViewModel.organizationId =
      inviteMember.organizationId;
    organizationInvitationViewModel.email = inviteMember.email;
    organizationInvitationViewModel.firstName = inviteMember.firstName;
    organizationInvitationViewModel.lastName = inviteMember.lastName;
    organizationInvitationViewModel.nickName = inviteMember.nickName;
    organizationInvitationViewModel.roles = inviteMember.roles;
    return organizationInvitationViewModel;
  }

  private prepareTeamMemberViewModel(
    teamMember: TeamMember,
    teamMemberStatus: TeamMemberStatusCode
  ): TeamMemberViewModel {
    let teamMemberViewModel = new TeamMemberViewModel();
    teamMemberViewModel = { ...teamMember, ...teamMemberViewModel };
    if (teamMember.teamAccessRoles.length > 0) {
      let roles = "";
      teamMember.teamAccessRoles.forEach((x) => {
        roles += x.accessRoleType + ",";
      });
      teamMemberViewModel.roles = roles.slice(0, -1);
    }
    teamMemberViewModel.memberStatus = teamMemberStatus;
    return teamMemberViewModel;
  }

  private updateTeamMember(id: string, teamMember: TeamMemberViewModel): void {
    this.teamMemberService.updateTeamMember(id, teamMember).subscribe(
      () => {
        this.notificationService.success(`Team member has been successfully 
          ${teamMember.memberStatus === TeamMemberStatusCode.suspended
            ? "suspended"
            : "activated"
          }.`);
        this.teamMemberEvent.emit();
      },
      (error) => this.notificationService.error(error)
    );
  }
}
