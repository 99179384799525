import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BookingSummary } from '../../../app/shared/bookings/bookings.model';
import { BookingsService } from '../../../app/shared/bookings/bookings.service';
import { RequestAndPayment } from '../../../app/shared/requests-and-payments/requests-and-payments.model';
import { RequestAndPaymentService } from '../../../app/shared/requests-and-payments/requests-and-payments.service';
import { BookingPaidStatusCode, PaymentStatusCode, ShipmentType } from '../../../app/shared/shared.model';
import { LayoutService } from '../layout/layout.service';
import { LayoutMode } from '../layout/layout.model';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentsComponent implements OnInit {
  bookingId: string;
  requestAndPayments: RequestAndPayment[];
  isLoading = false;
  bookingSummary: BookingSummary;
  creditOrganization = false;
  bookingPaidStatusCode: BookingPaidStatusCode;

  constructor(
    private layoutService: LayoutService,
    public router: Router,
    public route: ActivatedRoute,
    private requestAndPaymentService: RequestAndPaymentService,
    private bookingsService: BookingsService) {
    this.bookingId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getRequestsAndPaymentsByBookingId();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.PAYMENTS);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(true);
      this.layoutService.setToolbarTitle("Payments");
      this.layoutService.setToolbarDescription("Select the Transaction to Pay");
      this.layoutService.setToolbarBackUrl("/finance");
    }, 0);
  }


  getIconColor(statusCode: BookingPaidStatusCode): any {
    return statusCode === BookingPaidStatusCode.paid ? true : statusCode === BookingPaidStatusCode.unPaid ? false : null;
  }

  getPaymentStatusColor(statusCode: PaymentStatusCode): any {
    switch (statusCode) {
      case PaymentStatusCode.toBePaid: return null;
      case PaymentStatusCode.inProgress: null;
      case PaymentStatusCode.failed: return 'failed';
      case PaymentStatusCode.paid: return 'paid';
      case PaymentStatusCode.draft: return null;
      case PaymentStatusCode.toPayOffline: return null;
      case PaymentStatusCode.toBeApproved: return null;
      default: return false;

    }
  }

  getPaymentIconStatusColor(statusCode: PaymentStatusCode): any {
    switch (statusCode) {
      case PaymentStatusCode.toBePaid: return 'partialyPaid';
      case PaymentStatusCode.inProgress: 'partialyPaid';
      case PaymentStatusCode.failed: return 'failed';
      case PaymentStatusCode.paid: return 'paid';
      case PaymentStatusCode.draft: return 'partialyPaid';
      case PaymentStatusCode.toPayOffline: return 'partialyPaid';
      case PaymentStatusCode.toBeApproved: return 'partialyPaid';
      default: return 'partialyPaid';

    }
  }

  getMainCarriageIcon(): string {
    switch (this.bookingSummary.shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
      }
      default: {
        return 'ship';
      }
    }
  }

  toPayment(id: string) {
    this.router.navigate(['/payment', this.bookingId, id]);
  }

  private getRequestsAndPaymentsByBookingId(): void {
    this.isLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getSummaryById(this.bookingId)),
        tap((x: BookingSummary) => {
          this.bookingSummary = x;
          this.bookingPaidStatusCode = this.bookingSummary.bookingPaidStatusCode;
        }),
        switchMap(() => this.requestAndPaymentService
          .getAllByBookingId(this.bookingId)))
      .subscribe((x) => {
        this.requestAndPayments = x;
      },
        (error) => { })
      .add(() => (this.isLoading = false));
  }
}
