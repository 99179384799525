import { Directive, AfterViewInit, Input, ElementRef } from "@angular/core";
import { SwiperOptions } from "swiper";

@Directive({
    selector: '[S4DSwiper]'
  })
  export class SwiperDirective implements AfterViewInit {
  
    private readonly swiperElement: HTMLElement;
  
    @Input('config')
    config?: SwiperOptions;
  
    constructor(private el: ElementRef<HTMLElement>) {
      this.swiperElement = el.nativeElement;
    }
  
    ngAfterViewInit(): void {
      Object.assign(this.el.nativeElement, this.config);
      
      // @ts-ignore
      this.el.nativeElement.initialize();
    }
  }