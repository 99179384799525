<ng-container>
  <div class="col-12 responsive-services-wrapper">
    <mat-expansion-panel class="additional-service-details-expansion-panel"
      [ngClass]="{'rate-services' : shipmentType !== ShipmentType.AIR, 'service-expanded': isExpanded}"
      [expanded]="isExpanded" (opened)="onClickPanel(true)" (closed)="onClickPanel(false)" hideToggle>
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title class="panel-title">
          <div class="panel-title-content-wrapper d-flex gap-2 justify-content-between align-items-center">
            <div class="d-flex">
              <div class="accordion-title d-flex text-capitalize align-items-center p-0">
                {{ isBasicRateServiceType() ? "Basic" : "Standard" }} Services
              </div>
            </div>
            <div class="tooltip-icon-arrow-wrapper d-flex">
              <div class="custom-tooltip-icon-wrapper" [s4dTooltip]="tooltipTemplate" tooltipPosition="above"
                tooltipMaxWidth="15rem">
                <span class="panel-icon panel-header-icon-wrapper">
                  <i class="panel-header-icon s4d-icon s4d-info-light is-hover" matTooltipPosition="above"
                    matTooltipClass="additional-service-details-info-tooltip"></i>
                </span>
              </div>
              <div>
                <span class="panel-icon panel-header-icon-wrapper">
                  <i *ngIf="!isExpanded" class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                  <i *ngIf="isExpanded" class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                </span>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expand-content-detailes-wrapper">
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-support"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Support</h5>
              <h4 class="content-title">Expert Live Assistance 24/7</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-verified"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Price Guarantee</h5>
              <h4 class="content-title">
                {{ isBasicRateServiceType() ?
                "Price is not guaranteed" :
                "Guaranteed price - up to around 45 days"
                }}</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-ship"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Allocation</h5>
              <h4 class="content-title">
                {{ isBasicRateServiceType() ?
                "Not guaranteed (will be loaded 1st available sailing)" :
                "Allocation guaranteed (date + space)"
                }}</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-refresh"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Quote Validity</h5>
              <h4 class="content-title">
                {{ isBasicRateServiceType() ?
                "Quote is valid for 30 minutes" :
                "Quote is valid for 72 hours"
                }}</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-calendar"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Re-Scheduling or Cancellation</h5>
              <h4 class="content-title">
                {{ isBasicRateServiceType() ?
                "$200 fee" :
                "Free re-scheduling or cancellation Up to 7 days from departure"
                }}</h4>
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-auto icon-title-wrapper">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-clock"></i>
            </div>
            <div class="content-tile-wrapper">
              <h5 class="service-text-title">Detention</h5>
              <h4 class="content-title">
                {{ isBasicRateServiceType() ? "3 days for free" :
                "Extended detention days of up to 8 days included"
                }}</h4>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</ng-container>

<ng-template #tooltipTemplate>
  <span> {{ getRateServiceTypeTooltip() }}
    <span class="link" (click)="onRateServiceType()">Learn More & Compare</span>
  </span>
</ng-template>