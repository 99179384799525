<ng-container *ngFor="let booking of openOrders">
    <app-order (canceledBookingEvent)="onCancelBooking($event)" [booking]="booking" class="col-12" #order [id]="booking.id"></app-order>
  </ng-container>
  <h1 *ngIf="submittedOrders?.length && headerTitle !== 'Submitted Orders'" class="order-list-title">
    Submitted Orders
  </h1>
  <ng-container *ngFor="let booking of submittedOrders">
    <app-order (canceledBookingEvent)="onCancelBooking($event)" [booking]="booking" class="col-12" #order [id]="booking.id"></app-order>
  </ng-container>
  <h1 *ngIf="completedOrders?.length && headerTitle !== 'Completed Orders'" class="order-list-title">
    Complete Orders
  </h1>
  <ng-container *ngFor="let booking of completedOrders">
    <app-order (canceledBookingEvent)="onCancelBooking($event)" [booking]="booking" class="col-12" #order [id]="booking.id"></app-order>
  </ng-container>