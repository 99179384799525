<div class="flow-result-details-info d-flex flex-column gap-3 px-3 pt-4">
  <ng-container *ngFor="let item of sortedRateDetails | keyvalue">
    <div class="flow-result-details-rate-info d-flex flex-column gap-2"
      *ngIf="item.value?.charges?.length || item.value?.type === rateServiceType">
      <h2>{{ (rateDetailsPanels[item.key] | titlecase) }} {{ item.value?.charges?.length ? 'Charges' : 'Services' }}
      </h2>
      <p>
        {{ item.value.tooltip }}
        <span *ngIf="!item.value?.charges?.length" class="more-compare" (click)='onRateServiceType()'>
          Learn More & Compare
        </span>
      </p>
    </div>
  </ng-container>
</div>
