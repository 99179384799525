<div *ngIf="organizationMembers">
  <div class="organization-members-wrapper">
    <div class="organization-members-header">
      <h3 class="organization-members-header-text">Team Members</h3>
      <div class="add-invite-member-container">
        <button mat-button class="add-invite-member-button" (click)="onAddInviteMember()">
          <span matPrefix class="add-invite-member-icon">
            <app-quote-search-icons [iconName]="'add-icon'" class="plus-icon"></app-quote-search-icons>
            <span class="invite-member">Invite Member</span>
          </span>
        </button>
      </div>
    </div>
    <div class="organization-members-content">
      <div class="invite-members-container">
        <div class="invite-members-wrapper"
          *ngFor="let inviteMember of organizationMembers.inviteMembers">
          <div class="organization-members-left-content">
            <div class="organization-member-profile-content">
              <span class="organization-member-profile-name">
                {{ getInitialMemberName(inviteMember) }}
              </span>
            </div>
            <div class="organization-member-detail-wrapper">
              <div class="organization-member-detail-information">
                <div class="organization-member-name-wrapper"
                  *ngIf="inviteMember.firstName && inviteMember.lastName">
                  <span class="organization-member-name">
                    {{ inviteMember.firstName + ' ' + inviteMember.lastName }}
                  </span>
                </div>
                <div class="organization-member-status-type-wrapper">
                  <mat-chip-list>
                    <mat-chip class="organization-member-status-type">Pending</mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div class="organization-member-access-role-wrapper">
                <span class="organization-member-access-role">
                  {{ getAccessRolesOfInviteMember(inviteMember.roles) }}
                </span>
              </div>
            </div>
          </div>
          <div class="organization-members-right-content">
            <div class="organization-members-menu-wrapper">
              <button mat-icon-button class="organization-members-menu-button"
                [matMenuTriggerFor]="inviteMembersMenu">
                <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
              </button>
              <mat-menu #inviteMembersMenu="matMenu" backdropClass="organization-member-menu">
                <button mat-menu-item (click)="onEditInviteMember(inviteMember)">
                  <span class="edit-detailes">Edit</span>
                </button>
                <button mat-menu-item (click)="onResendInviteMember(inviteMember)">
                  <span class="resend-detailes">Resend</span>
                </button>
                <button mat-menu-item class="remove-detailes-wrapper"
                  (click)="onCancelInviteMember(inviteMember)">
                  <span class="remove-detailes">Cancel</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <div class="team-members-container">
        <div class="team-members-wrapper"
          *ngFor="let teamMember of organizationMembers.teamMembers">
          <div class="organization-members-left-content">
            <div class="organization-member-profile-content">
              <span class="organization-member-profile-name">
                {{ getInitialMemberName(teamMember) }}
              </span>
            </div>
            <div class="organization-member-detail-wrapper">
              <div class="organization-member-detail-information">
                <div class="organization-member-name-wrapper"
                  *ngIf="teamMember.firstName && teamMember.lastName">
                  <span class="organization-member-name">
                    {{ teamMember.firstName + ' ' + teamMember.lastName }}
                  </span>
                </div>
                <div class="organization-member-status-type-wrapper"
                  *ngIf="teamMember.memberStatus === teamMemberStatuses.pending
                  || teamMember.memberStatus === teamMemberStatuses.suspended"
                  [ngClass]="{'pending-status-type' : teamMember.memberStatus === teamMemberStatuses.pending,
                  'suspended-status-type' : teamMember.memberStatus === teamMemberStatuses.suspended}">
                  <mat-chip-list>
                    <mat-chip class="organization-member-status-type">
                      {{ teamMember.memberStatus | teamMemberStatusName }}
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div class="organization-member-access-role-wrapper">
                <span class="organization-member-access-role">
                  {{ getAccessRolesOfTeamMember(teamMember.teamAccessRoles) }}
                </span>
              </div>
            </div>
          </div>
          <div class="organization-members-right-content">
            <div class="organization-members-menu-wrapper">
              <button mat-icon-button class="organization-members-menu-button"
                [matMenuTriggerFor]="teamMembersMenu">
                <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
              </button>
              <mat-menu #teamMembersMenu="matMenu" backdropClass="organization-member-menu">
                <button mat-menu-item (click)="onEditTeamMember(teamMember)">
                  <span class="edit-detailes">Edit</span>
                </button>
                <button mat-menu-item *ngIf="teamMember.memberStatus === teamMemberStatuses.suspended"
                  (click)="onReactivateTeamMember(teamMember)">
                  <span class="reactivate-detailes">Reactivate</span>
                </button>
                <button mat-menu-item *ngIf="teamMember.memberStatus === teamMemberStatuses.active"
                  (click)="onSuspendTeamMember(teamMember)">
                  <span class="suspend-detailes">Suspend</span>
                </button>
                <button mat-menu-item class="trash-btn" (click)="onRemoveTeamMember(teamMember)">
                  <span class="remove-detailes">Remove</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>