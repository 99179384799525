import { Pipe, PipeTransform } from "@angular/core";
import { AddressTypeCode } from "../shared.model";

@Pipe({ name: 'addressTypeShortName' })
export class AddressTypeShortNamePipe implements PipeTransform {
  private addressTypeShortNames = {};

  constructor() {
    this.addressTypeShortNames[AddressTypeCode.port] = 'Port';
    this.addressTypeShortNames[AddressTypeCode.airport] = 'Airport';
    this.addressTypeShortNames[AddressTypeCode.door] = 'Door';
    this.addressTypeShortNames[AddressTypeCode.warehouse] = 'Warehouse';
  }

  transform(value?: AddressTypeCode): string {
    return this.addressTypeShortNames[value] ?? '';
  }
}