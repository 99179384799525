<div class="container-fluid quote-search-flow-header px-3">
  <div class="quote-search-flow-header-container">
    <div class="row m-0">
      <div class="col px-0 d-flex" [formGroup]="quoteSearchForm">
        <mat-button-toggle-group class="shipping-type-toggle row mx-0 w-100 justify-content-center" name="shippingType"
          aria-label="Shipping type" (change)="onChangeShippingType($event.value)" formControlName="shipmentType">
          <mat-button-toggle class="shipping-type-button col" *ngFor="let item of shippingTypes" [value]="item.value"
            [aria-label]="item.description">
            <i class="shipping-type-icon s4d-icon" [ngClass]="'s4d-' + item.icon"></i>
            <span class="shipping-type-name">{{ item.name }}</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</div>