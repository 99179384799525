<div class="booking-documents-container">
  <div class="booking-documents-header">
    <h2 class="booking-documents-header-title">Documents</h2>
    <h4 class="booking-documents-header-sub-title">
      Upload relevant documents for your booking.
    </h4>
  </div>
  <div class="booking-documents-wrapper">
    <lib-drag-drop [mode]="FileUploaderMode.all" [count]="7" [canDownload]="false" [files]="booking.documents"
      (filesChange)="onListingFilesChange($event)" [maxFileSize]="5242880" [supportedFormats]="SupportedFileFormats"
      [layoutType]="LayoutType.client" [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)">
    </lib-drag-drop>
  </div>
</div>