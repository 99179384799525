<div class="row m-0 p-0 package-type-input-container" role="group" [attr.aria-describedby]="userAriaDescribedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <div class="col px-0 packagebox-input-wrapper">
    <input type="text" matInput [formControl]="packageTypeControl" [matAutocomplete]="autoPackageType"
      [placeholder]="placeholder" (keyup)="onKeyUp($event)" appAutocompletePosition #trigger="matAutocompleteTrigger"
      trackInputErrors (triggerError)="handleError($event)" [controlName]="controlName">
    <mat-autocomplete #autoPackageType="matAutocomplete" class="package-type-autocomplete" [displayWith]="displayFn">
      <mat-optgroup *ngIf="recentPackageTypes?.length && empty" label="Recent Types of Packaging">
        <mat-option class="package-type" *ngFor="let packageType of recentPackageTypes" [value]="packageType"
          (onSelectionChange)="onSelect(packageType)">
          <div class="row g-0 m-0">
            <div class="col">
              <span class="package-type-name" [matTooltip]="!isMobile ? packageType.description : ''">
                {{ packageType.description }}
              </span>
            </div>
          </div>
        </mat-option>
      </mat-optgroup>
      <mat-optgroup label="All Types of Packaging">
        <mat-option class="package-type" *ngFor="let packageType of packageTypeResponse" [value]="packageType"
          (onSelectionChange)="onSelect(packageType)">
          <div class="row g-0 m-0">
            <div class="col">
              <span class="package-type-name" [matTooltip]="!isMobile ? packageType.description : ''">
                {{ packageType.description }}
              </span>
            </div>
          </div>
        </mat-option>
      </mat-optgroup>
      <mat-option *ngIf="!isLoading && packageTypeControl?.value?.length >= minimumLength
          && packageTypeResponse?.length === 0" disabled>
        <div class="d-flex justify-content-center">
          No results found
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="col-auto ps-2 pe-0 package-arrow-wrapper">
    <div class="suffix-wrapper d-flex gap-1" matSuffix>
      <i *ngIf="focused && !isLoading" class="detail-subtitle-icon s4d-icon s4d-chevron-up is-hover"
        (click)="openOrClosePanel($event, trigger)"></i>
      <i *ngIf="!focused && !isLoading" class="detail-subtitle-icon s4d-icon s4d-chevron-down is-hover"
        (click)="openOrClosePanel($event, trigger)"></i>
      <div *ngIf="isLoading" class="button-spinner">
        <span class="button-inner">
          <mat-spinner diameter="25"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
</div>