import { Pipe, PipeTransform } from '@angular/core';
import { LocationType } from '../shared.model';

@Pipe({ name: 'locationTypeS4DIcon' })
export class LocationTypeS4DIconPipe implements PipeTransform {
  private locationTypeIcons = {};

  constructor() {
    this.locationTypeIcons[LocationType.townCity] = 's4d-door';
    this.locationTypeIcons[LocationType.airPort] = 's4d-ac';
    this.locationTypeIcons[LocationType.seaPort] = 's4d-ship';
    this.locationTypeIcons[LocationType.railwayTerminal] = 's4d-train';
    this.locationTypeIcons[LocationType.containerTerminal] = 's4d-cargo-crane';
    this.locationTypeIcons[LocationType.inlandPort] = 's4d-map';
    this.locationTypeIcons[LocationType.industrialZone] = 's4d-building';
    this.locationTypeIcons[LocationType.busStation] = 's4d-map';
    this.locationTypeIcons[LocationType.island] = 's4d-map';
    this.locationTypeIcons[LocationType.heliport] = 's4d-map';
    this.locationTypeIcons[LocationType.dryPort] = 's4d-truck';
    this.locationTypeIcons[LocationType.administrativeDivision] = 's4d-map';
    this.locationTypeIcons[LocationType.other] = 's4d-map';
  }

  transform(value?: LocationType): string {
    return this.locationTypeIcons[value] ?? '';
  }
}