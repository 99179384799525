import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { FileInfo } from '@ship4wd/ngx-manager-ui';
import { Observable } from 'rxjs';
import { BookingFileUrl, DocumentComment } from '../bookings/booking-documents/booking-documents.model';
import { BookingFile } from '../bookings/bookings.model';
import { DocumentTypeCode } from '../shared.model';

@Injectable()
export class FilesDocumentsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  getSignedUrl(bookingId: string, bookingFileId: string): Observable<BookingFileUrl> {
    return this.http.get<BookingFileUrl>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments/`
      + `${bookingId}/${bookingFileId}/url`);
  }

  add(bookingId: string, file: FileInfo, comments: DocumentComment[],
    documentTypeCode: DocumentTypeCode): Observable<HttpEvent<BookingFile>> {
    const formData = new FormData();
    formData.append('document', file.file, file.name);
    comments?.forEach((documentComment: DocumentComment, index: number) => {
      formData.append('comments[' + index + '][comment]', documentComment.comment.toString());
      formData.append('comments[' + index + '][userId]', documentComment.userId.toString());
      formData.append('comments[' + index + '][userType]', documentComment.userType.toString());
      formData.append('comments[' + index + '][userName]', documentComment.userName.toString());
      formData.append('comments[' + index + '][createTimeUtc]', documentComment.createTimeUtc.toString());
    });

    const params = new HttpParams();
    const options = {
      params,
      reportProgress: true
    };

    const request = new HttpRequest(
      'POST',
      `${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments/${bookingId}?documentTypeCode=${documentTypeCode}`,
      formData, options);
    return this.http.request(request);
  }
}
