import { Directive, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ErrorMessages } from '../shared.model';
import { ErrorMessagePipe } from '../pipes/error-message.pipe';

@Directive({
  selector: '[trackInputErrors]'
})
export class TrackInputErrorsDirective {
  @Input() controlName: string;
  errorStatus: boolean;

  get getValidatorErrorKey(): ErrorMessages {
    if (this.ngControl.control.errors) {
      for (const errorKey in this.ngControl.control.errors) {
        return ErrorMessages[errorKey];
      }
    }

    return null;
  }

  @Output() triggerError = new EventEmitter<string>();

  constructor(
    @Optional() private ngControl: NgControl,
    private errorMessagePipe: ErrorMessagePipe
  ) { }

  @HostListener("focusin", ["$event"])
  focusIn() {
    this.errorStatus = Boolean(this.ngControl.control?.invalid && this.ngControl.control.touched);
  }

  @HostListener("focusout", ["$event"])
  focusOut(): void {
    if (this.ngControl.control?.invalid && !this.errorStatus) {
      this.triggerError.emit(
        this.errorMessagePipe.transform(
          this.getValidatorErrorKey,
          this.controlName ?? this.ngControl.name.toString()
        )
      )
    }
  }


}
