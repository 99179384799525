import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-cargo-confirm-dialog',
  templateUrl: './change-cargo-confirm-dialog.component.html',
  styleUrls: ['./change-cargo-confirm-dialog.component.scss']
})
export class ChangeCargoConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeCargoConfirmDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close({ isAllowSaveBookingCargoDetails: false });
  }

  onSave(): void {
    this.dialogRef.close({ isAllowSaveBookingCargoDetails: true });
  }
}
