import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { Subject } from "rxjs";

@Component({
  selector: "dimensions-input",
  templateUrl: "./dimensions-input.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatFormFieldControl, useExisting: DimensionsInputComponent },
  ],
})
export class DimensionsInputComponent
  implements
  ControlValueAccessor,
  MatFormFieldControl<string>,
  OnDestroy,
  DoCheck {
  @Input()
  get value(): string | null {
    let n = this.dimensions.value;
    if (
      Number(n.l.length) > 0 &&
      Number(n.w.length) > 0 &&
      Number(n.h.length) > 0
    ) {
      return `${n.l}x${n.w}x${n.h}`;
    }
    return null;
  }
  set value(data: string | null) {
    let parsed: string[] = data.split("x");

    if (parsed.length === 3) {
      this.dimensions.setValue({
        l: parsed[0],
        w: parsed[1],
        h: parsed[2],
      });
      this.stateChanges.next();
    }
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.dimensions.disable() : this.dimensions.enable();
    this.stateChanges.next();
  }

  @Input("aria-describedby") userAriaDescribedBy: string;

  @HostBinding() id = `dimensions-input-${DimensionsInputComponent.nextId++}`;

  static nextId = 0;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  stateChanges: Subject<void> = new Subject<void>();

  dimensions: FormGroup;

  focused: boolean = false;
  touched: boolean = false;
  errorState: boolean = false;
  shouldLabelFloat: boolean = false;
  autofilled?: boolean;
  controlType: string = "dimensions-input";

  get empty(): boolean {
    const {
      value: { l, w, h },
    } = this.dimensions;

    return !l && !w && !h;
  }

  constructor(
    fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() public parentFormField: MatFormField,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.dimensions = fb.group({
      l: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(3)],
      ],
      w: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(3)],
      ],
      h: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(3)],
      ],
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onChange(event: any): void { }

  onTouched(): void { }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != "input") {
      this._elementRef.nativeElement.querySelector("input").focus();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, "program");
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, "program");
    }
  }

  writeValue(data: string | null): void {
    this.value = data;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".dimensions-input-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (control.value.length === 3 && !nextElement?.value?.length) this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  private updateErrorState(): void {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.dimensions.invalid) &&
      (this.touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
