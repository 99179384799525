<div class="booking-flow-cargo-details">
  <div class="row m-0 px-4">
    <div class="col-12 px-0 custom-date-field">
      <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
        <mat-label>Collection Date</mat-label>
        <div class="row">
          <div class="col d-flex align-items-end">
            <input matInput [formControl]="collectDateControl" [matDatepicker]="picker"
              [min]="getMinimumCollectionTime()" [max]="maxSelectableDate" placeholder="mm/dd/yyyy"
              (dateChange)="onDateChanged($event.value)" trackInputErrors readonly (focus)="picker.open()"
              (click)="picker.open()" (triggerError)="handleError('Collection Date', $event)"
              controlName="Collection Date & Time" />
          </div>
          <div class="col-auto px-0">
            <mat-datepicker-toggle matSuffix [for]="picker">
              <i matDatepickerToggleIcon class="custom-calendar-icon s4d-icon s4d-calendar"></i>
            </mat-datepicker-toggle>
          </div>
        </div>
        <mat-datepicker #picker (opened)="onDatePickerPopUpStateChanged(true)"
          (closed)="onDatePickerPopUpStateChanged(false)"></mat-datepicker>
        <mat-error class="booking-flow-error" *ngIf="
            collectDateControl.touched &&
            collectDateControl.hasError('required')
          ">
          <i class="error-icon s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Collection Date & Time'}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="shipmentTypeCode === shipmentTypes.FCL" class="booking-flow-cargo-details-fcl">
    <ng-container *ngFor="let containerForm of containers?.controls; let i = index" [formGroup]="containerForm">
      <div class="container-header px-4">
        <div class="row d-flex m-0 align-items-center">
          <div class="col-auto container-header-index-title-wrapper px-0">
            <span class="container-header-index-title"> {{ i + 1 }} </span>
          </div>
          <div class="col-auto container-header-title-wrapper px-0">
            <span class="container-header-title">{{
              getContainersTitle(containerForm)
              }}</span>
          </div>
          <div *ngIf="isToIsrael" class="hts-guide-link-wrapper col-auto d-flex ms-auto px-0" [ngClass]="{
            'me-2': containers?.controls?.length > 1
          }">
            <a href="https://shaarolami-query.customs.mof.gov.il/CustomspilotWeb/he/CustomsBook/Import/CustomsTaarifEntry"
              target="_blank">
              HTS Commodity Guide
            </a>
          </div>
          <div class="copy-button-wrapper d-flex align-items-center justify-content-center ml-auto"
            *ngIf="containers?.controls?.length > 1">
            <div class="icon-wrapper">
              <i class="s4d-icon s4d-copy" (click)=select.open()></i>
            </div>
            <mat-select disableOptionCentering #select class="copy-dropdown">
              <ng-container *ngFor="let container of containers?.controls; let j = index">
                <mat-option *ngIf="j !== i" [value]="container.value"
                  (click)="onCopyContainerDetails(j, i, containerForm)">
                  Copy from container {{ j + 1 }}
                </mat-option>
              </ng-container>
            </mat-select>
          </div>
        </div>
      </div>
      <div formArrayName="commodities" class="container-content">
        <div *ngFor="
            let commodityForm of containerForm.get('commodities')?.controls;
            let i = index
          " [formGroup]="commodityForm" class="row m-0 pe-4 container-content-row" [ngClass]="
            (commodityForm.get('commodityObject')?.touched &&
            containerForm.get('commodities')?.controls?.length > 0) ||
            (commodityForm.get('volume')?.touched &&
            checkValid(commodityForm.get('volume'))) ||
            (commodityForm.get('weight')?.touched &&
            checkValid(commodityForm.get('weight')))
              ? 'error-box'
              : ''
          ">
          <div class="col-auto commodity-wrapper">
            <button class="commodity-button add-commodity-icon-wrapper" [ngClass]="{ 'd-flex': trigger.menuOpen }"
              [matMenuTriggerFor]="commodityMenu" #trigger="matMenuTrigger">
              <i class="add-commodity-icon s4d-icon s4d-menu-double"></i>
            </button>
            <mat-menu class="commodity-menu" #commodityMenu="matMenu">
              <button mat-menu-item (click)="onAddCommodity(containerForm, i)">
                <span>Add Commodity</span>
              </button>
              <button *ngIf="containerForm.get('commodities')?.controls?.length > 1" mat-menu-item
                (click)="onDeleteCommodity(containerForm, i)">
                <span>Remove Commodity</span>
              </button>
            </mat-menu>
          </div>
          <div class="col ps-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>What are you shipping?</mat-label>
              <div class="row h-100 commodity-input-container">
                <div class="col d-flex align-items-end">
                  <mat-select #commodityselect class="commodity-select" panelClass="commodity-input-container-panel"
                    [ngClass]="{'mat-select-opened':commodityselect.panelOpen}" [compareWith]="compareObjects"
                    [formControl]="commodityForm.get('commodityObject')"
                    (focus)="onFocus('Commodity Code', i + 1, 'commodityObject')"
                    (selectionChange)="onSelectCommodity($event, commodityForm, i)" disableOptionCentering
                    trackInputErrors (triggerError)="handleError('Commodity details', $event)"
                    controlName="Commodity details">
                    <mat-option class="commodity" *ngFor="let commodity of filteredCommodities" [value]="commodity"
                      matTooltip="{{commodity.description}}">
                      {{ commodity.description }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-3 px-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Volume</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <input matInput type="number" formControlName="volume" appPreventNonNumeric placeholder="0"
                    [max]="containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxCubic"
                    (focus)="onFocus('Volume', i + 1, 'volume')" (change)="onValueChanged('Volume', i + 1, true)"
                    trackInputErrors (triggerError)="handleError('Volume', $event)" />
                </div>
                <div class="col-auto d-flex align-items-center pe-2">
                  <mat-select class="unit-select" formControlName="volumeUnitCode" (click)="onUnitSelect($event)">
                    <mat-option *ngFor="let volumeUnit of volumeUnits" [value]="volumeUnit.value">
                      {{ volumeUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('volume')?.touched &&
                  checkValid(commodityForm.get('volume'))
                " class="booking-flow-error">
                <i class="error-icon s4d-icon s4d-info-light"></i>
                {{getValidatorErrorKey(commodityForm.get('volume')) | errorMessage: 'Volume'}}
              </mat-error>
              <mat-hint align="start" class="hint-info">
                <i class="error-icon s4d-icon s4d-info-light"></i>Up to
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxCubic}} cbm
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-3 pe-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Weight</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <input matInput type="number" appPreventNonNumeric formControlName="weight" placeholder="0"
                    (focus)="onFocus('Weight', i + 1, 'weight')" (change)="onValueChanged('Weight',i+1, true)"
                    trackInputErrors (triggerError)="handleError('Weight', $event)" />
                </div>
                <div class="col-auto d-flex align-items-center pe-2">
                  <mat-select class="unit-select" formControlName="weightUnitCode" (click)="onUnitSelect($event)">
                    <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                      {{ weightUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('weight')?.touched &&
                  checkValid(commodityForm.get('weight'))
                " class="booking-flow-error">
                <i class="error-icon s4d-icon s4d-info-light"></i>
                {{getValidatorErrorKey(commodityForm.get('weight')) | errorMessage: 'Weight'}}
              </mat-error>
              <mat-hint align="start" class="hint-info"
                (click)="test(containersCubicAndWeight.get(containerForm.value.equipmentTypeCode).maxCubic)">
                <i class="error-icon s4d-icon s4d-info-light"></i>Up to
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxWeight}}
                {{containersCubicAndWeight.get(containerForm.value.equipmentTypeCode)?.maxWeightUnit | weightUnitName}}
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div [ngClass]="
          !this.containersWeightAndVolumeValidityMap.get(containerForm)
            ? 'ms-4'
            : 'ms-0'
        ">
        <div *ngIf="
            this.containersWeightAndVolumeValidityMap.get(containerForm);
            else volumeError
          "></div>
      </div>
      <div class="px-4 booking-container-add">
        <button mat-button class="booking-container-add-button col-auto" (click)="onAddCommodity(containerForm)">
          <span matPrefix class="booking-container-add-icon">
            <app-booking-icons [iconName]="'add-icon'"></app-booking-icons>
          </span>
          <span class="booking-container-add-title">Add Commodity</span>
        </button>
      </div>
    </ng-container>

    <div class="container-files px-4 mt-4" *ngIf="booking?.bookingAdditionalInformation?.isBatteries">
      <div class="row">
        <div class="col-12">
          <span class="container-files-title">Dangerous Goods Shipping</span>
        </div>
        <div class="col-12 mt-2">
          <span class="container-files-subtitle">Please upload the MSDS document to continue. <a
              href="https://ship4wd.com/support-category/dangerous-goods/" target="_blank"
              class="container-files-link">Learn
              More</a></span>
        </div>
        <div class="col-12">
          <lib-drag-drop [mode]="FileUploaderMode.all" [count]="7" [files]="documents" [canDownload]="false"
            (filesChange)="onDocumentsChange($event)" [maxFileSize]="5242880" [layoutType]="LayoutType.client"
            [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)" class="container-files-content"
            [title]="'You can also click on this area to select the file'">
          </lib-drag-drop>
        </div>
        <div class="col-12">
          <div class="s4d-alert-warning mt-3">
            <span class="alert-icon"><i class="s4d-icon s4d-info"></i></span>
            <span class="alert-text">All battery shipments are subject to carrier approval. <a
                href="https://ship4wd.com/support-category/dangerous-goods/" target="_blank">Learn
                More</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="shipmentTypeCode !== shipmentTypes.FCL" class="booking-flow-cargo-details-lcl-air px-4">
    <ng-container *ngFor="let containerForm of containers?.controls; let i = index" [formGroup]="containerForm">
      <div formArrayName="commodities">
        <div *ngFor="
            let commodityForm of containerForm.get('commodities')?.controls;
            let i = index
          " [formGroup]="commodityForm" class="row m-0">
          <div class="col-12 px-0">
            <div class="container-header">
              <div class="row m-0">
                <div class="col px-0">
                  <span class="container-header-title">Load {{ i + 1 }}</span>
                </div>
                <div class="col ps-2 pe-0 d-flex align-items-center justify-content-end">
                  <div *ngIf="isToIsrael" class="hts-guide-link-wrapper col-auto d-flex ms-auto px-0" [ngClass]="{
                    'me-2': getDimensions(commodityForm)
                  }">
                    <a href="https://shaarolami-query.customs.mof.gov.il/CustomspilotWeb/he/CustomsBook/Import/CustomsTaarifEntry"
                      target="_blank">
                      HTS Commodity Guide
                    </a>
                  </div>
                  <span class="container-header-subtitle container-header-subtitle-dimensions">{{
                    getDimensions(commodityForm)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 ps-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>What are you shipping?</mat-label>
              <div class="row h-100 commodity-input-container">
                <div class="col d-flex align-items-end">
                  <mat-select #commodityselect class="commodity-select"
                    [ngClass]="{'mat-select-opened':commodityselect.panelOpen}"
                    panelClass="commodity-input-container-panel" [compareWith]="compareObjects"
                    [formControl]="commodityForm.get('commodityObject')"
                    (focus)="onFocus('Commodity Code', i + 1, 'commodityObject')"
                    (selectionChange)="onSelectCommodity($event, commodityForm, i)" disableOptionCentering
                    trackInputErrors (triggerError)="handleError('Commodity details', $event)"
                    controlName="Commodity details">
                    <mat-option class="commodity" *ngFor="let commodity of filteredCommodities" [value]="commodity"
                      matTooltip="{{commodity.description}}">
                      {{ commodity.description }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('commodityObject')?.touched &&
                  commodityForm.get('commodityObject')?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i> Commodity details are required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-2 px-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Quantity</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <input matInput type="number" class="input-readonly" formControlName="numberOfPackages"
                    (focus)="onFocus('Quantity', i + 1, 'numberOfPackages')" placeholder="0"
                    (change)="onValueChanged('Quantity',i+1, true)" readonly appPreventNonNumeric trackInputErrors
                    (triggerError)="handleError('Quantity', $event)" />
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('numberOfPackages')?.touched &&
                  commodityForm.get('numberOfPackages')?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4 pe-0">
            <mat-form-field class="w-100" appearance="fill" [floatLabel]="'always'">
              <mat-label>Type of Packaging</mat-label>
              <div class="row h-100">
                <div class="col d-flex align-items-end">
                  <package-type-input formControlName="packageTypeCode" placeholder="e.g. Box" class="w-100"
                    (onFocus)="onFocus('Type of Packaging', i + 1, 'packageTypeCode')" (onSelectionChanged)="
                      onSelectPackageType($event, commodityForm, i+1)
                    "
                    (triggerError)="handleError('Type of Packaging', getValidatorErrorKey(commodityForm.get('packageTypeCode')))"></package-type-input>
                </div>
              </div>
              <mat-error *ngIf="
                  commodityForm.get('packageTypeCode')?.touched &&
                  commodityForm.get('packageTypeCode')?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Type of Packaging'}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- <div class="row m-0 mt-3">
                <div class="col-auto px-0">
                    <button class="commodity-button link" (click)="onAddCommodity(containerForm)">
                        <div class="icon-wrapper">
                            <i class="s4d-icon s4d-plus"></i>
                        </div>
                        Add load
                    </button>
                </div>
            </div> -->
    </ng-container>
    <div *ngIf="isLclWeightAndVolumeValid; else volumeError"></div>
  </div>

  <div class="row justify-content-end px-4 mt-4">
    <div class="col-auto">
      <button mat-raised-button class="booking-next-button button-spinner" (click)="onNext()"
        [disabled]="isNextLoading || !isValid || (booking?.bookingAdditionalInformation?.isBatteries && documents.length === 0)">
        <span class="button-inner">
          Next
          <mat-spinner diameter="25" *ngIf="isNextLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>

<ng-template #volumeError>
  <mat-error class="mt-4 mb-3">
    <i class="s4d-icon s4d-info-light"></i> Selected total weight and volume can
    not be larger than selected equipment sizes.
  </mat-error>
</ng-template>