<mat-dialog-content tabindex="-1" class="additional-service-details-dialog-container-content">
  <div class="d-flex flex-column justify-content-between h-100 model-content-detailes-wrapper">
    <div class="additional-service-details-dialog-container">
      <div class="additional-service-container">
        <div class="row m-0 pb-4">
          <div class="col-12 px-0  title">
            <h2 class="additional-service-title">
              Pre-Shipment Inspection Details
            </h2>
          </div>
          <div class="col-12 px-0 subtitle">
            One visit is sufficient for most per-shipping inspections. All-inclusive final Random Inspection to confirm
            the whole shipment’s quality level.
          </div>
        </div>
        <div class="mb-3">
          <mat-accordion>
            <div class="col-12">
              <mat-expansion-panel class="additional-service-details-expansion-panel"
                [expanded]="isExpanded[preShipmentDetailPanels.recommendation]"
                (opened)="onClickPanel(true, preShipmentDetailPanels.recommendation)"
                (closed)="onClickPanel(false, preShipmentDetailPanels.recommendation)" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="row w-100 m-0">
                      <div class="col px-0 d-flex align-items-center">Why do we recommend it?</div>
                      <div class="col-auto d-flex align-items-center pe-0">
                        <span class="panel-header-icon-wrapper">
                          <i *ngIf="!this.isExpanded[preShipmentDetailPanels.recommendation]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpanded[preShipmentDetailPanels.recommendation]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content pt-0">
                  <span>
                    If you are worried about or have experienced problems such as poor quality,
                    incorrect shipments, or wrong packaging for goods imported from Asia,
                    product inspection services is your solution. Powered by V-Trust,
                    the product inspection service can help you to have an accurate understanding about the quality
                    of the goods without going to the factory by yourself.
                    It normally requires the production to be completed and at least 80% of goods packed into cartons.
                    <br><br>
                    The inspected samples are selected at random, according to international standards and procedures.
                    V-Trust uses <span class="link-text" (click)="onLinkClick()">ANSI/ASQ Z1.4-2008 (AQL)</span>
                    as its sampling standard and acceptable level of quality.
                  </span>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12 mt-4 mb-4">
              <mat-expansion-panel class="additional-service-details-expansion-panel"
                [expanded]="isExpanded[preShipmentDetailPanels.inspections]"
                (opened)="onClickPanel(true, preShipmentDetailPanels.inspections)"
                (closed)="onClickPanel(false, preShipmentDetailPanels.inspections)" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="row w-100 m-0">
                      <div class="col px-0 d-flex align-items-center">What is the standard inspection criteria?</div>
                      <div class="col-auto d-flex align-items-center pe-0">
                        <span class="panel-header-icon-wrapper">
                          <i *ngIf="!this.isExpanded[preShipmentDetailPanels.inspections]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpanded[preShipmentDetailPanels.inspections]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content pt-0">
                  <span class="header">
                    The inspection is random and specific to what you are shipping, and includes a general on-site
                    checking of the shipment.
                    Below is an example of some of the criteria of what is typically included. <br>
                    After you complete your booking, our shipping experts will call to schedule your inspection date and
                    coordinate your inspection criteria.
                  </span>
                  <ul class="mt-1">
                    <li *ngFor="let inspection of inspections">{{inspection}}</li>
                  </ul>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12 mt-4 mb-4">
              <mat-expansion-panel class="additional-service-details-expansion-panel"
                [expanded]="isExpanded[preShipmentDetailPanels.values]"
                (opened)="onClickPanel(true, preShipmentDetailPanels.values)"
                (closed)="onClickPanel(false, preShipmentDetailPanels.values)" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="row w-100 m-0">
                      <div class="col px-0 d-flex align-items-center">Values Included</div>
                      <div class="col-auto d-flex align-items-center pe-0">
                        <span class="panel-header-icon-wrapper">
                          <i *ngIf="!this.isExpanded[preShipmentDetailPanels.values]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpanded[preShipmentDetailPanels.values]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content pt-0">
                  <ul>
                    <li *ngFor="let valueInclude of valuesInclude">{{valueInclude}}</li>
                  </ul>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12 mt-4">
              <mat-expansion-panel class="additional-service-details-expansion-panel"
                [expanded]="isExpanded[preShipmentDetailPanels.supportedCounties]"
                (opened)="onClickPanel(true, preShipmentDetailPanels.supportedCounties)"
                (closed)="onClickPanel(false, preShipmentDetailPanels.supportedCounties)" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="row w-100 m-0">
                      <div class="col px-0 d-flex align-items-center">Supported countries for the inspection</div>
                      <div class="col-auto d-flex align-items-center pe-0">
                        <span class="panel-header-icon-wrapper">
                          <i *ngIf="!this.isExpanded[preShipmentDetailPanels.supportedCounties]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpanded[preShipmentDetailPanels.supportedCounties]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content pt-0">
                  <div class="supported-countries-list" *ngFor="let supportedCountry of supportedCountries">
                    <span class="supported-country-flag-icon fi fi-{{supportedCountry.code | lowercase}}"></span>
                    <span class="supported-country-name">{{supportedCountry.name}}</span>
                  </div>
                  <div class="supported-countries-info">
                    <i class="info-icon s4d-icon s4d-info-light "></i>
                    <span class="info-text">
                      Click <a href="https://www.v-trust.com/en/our-network" target="_blank"
                        class="link-text">www.v-trust.com/en/our-network</a> to see the exact supported areas for each
                      country.
                    </span>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="row dialog-footer">
      <button mat-button class="dialog-button-close col-4" (click)="onClose()">Close</button>
      <button mat-raised-button [disabled]="isLoading" class="dialog-button col-4 confirm-button-wrapper button-spinner"
        (click)="onShowExample()">
        <span class="button-inner">Show Example</span>
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </button>
    </div>
  </div>
</mat-dialog-content>