import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as uuid from 'uuid';
import { DateUtils, NotificationService } from '@ship4wd/ngx-common';
import { Incoterm, IncotermsMappingsQuery, QuotesQuery } from '../../../quotes.model';
import {
  AddressTypeCode,
  IncotermCode,
  LocationType,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchLocationAnalyticsViewModel
} from '../../../../../shared/shared.model';
import { QuotesIncotermService } from '../../../quotes-incoterm.service';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-quote-new-search-flow-params',
  templateUrl: './quote-new-search-flow-params.component.html',
  styleUrls: ['./quote-new-search-flow-params.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteNewSearchFlowParamsComponent implements OnInit, OnChanges {
  @Input() quoteSearchForm: FormGroup = undefined;
  @Input() searchId: string = '';
  @Input() reset: any = null;
  @Input() isReuse: boolean;
  @Input() isLoading: boolean = false;
  @Input() shippingTypes: any[] = [];
  @Input() selectedQuote: any;
  @Input() isFirstSearch: boolean;
  @Input() isRecentQuotesSearch: boolean;

  @Output() searchQuotesEvent = new EventEmitter<QuotesQuery>();

  isOpened: boolean[] = [false, false, false, false, false];
  isSelected: boolean[] = [true, false, false, false, false];
  hasError: boolean[] = [false, false, false, false, false];

  quoteSearchParameters = QuoteSearchParameters;

  searchType = SearchType;
  incoterms: Incoterm[] = null;

  get form(): any {
    return this.quoteSearchForm.controls;
  }

  get shipmentype(): any {
    return this.quoteSearchForm.get('shipmentType')?.value;
  }
  constructor(
    private quotesIncotermService: QuotesIncotermService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    (this.form.shipmentType as FormControl).valueChanges.subscribe(() => {
      this.isOpened = [false, false, false, false, false];
      this.hasError = [false, false, false, false, false]
      this.isSelected = [
        true,
        false,
        false,
        this.isSelected[QuoteSearchParameters.cargo],
        this.isSelected[QuoteSearchParameters.date]
      ];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReuse?.currentValue) {
      this.isSelected = [true, true, true, true, true];
      this.hasError = [false, false, false, false, false]
      this.onViewQuotes();
      this.isReuse = false;
    }

    if (changes.reset?.currentValue) {
      this.isSelected = [true, false, false, false, false];
      this.hasError = [false, false, false, false, false]
      this.isOpened = [false, false, false, false, false];
    }
  }

  onNext(step: QuoteSearchParameters): void {
    switch (step) {
      case QuoteSearchParameters.shipmentType:
        {
          if (!this.isSelected[QuoteSearchParameters.from]) {
            this.isOpened[QuoteSearchParameters.from] = true;
          }
        }
        break;
      case QuoteSearchParameters.from:
        {
          if (!this.isSelected[QuoteSearchParameters.to]) {
            setTimeout(() => {
              this.isOpened[QuoteSearchParameters.to] = true;
            }, 0);
          }

          this.getIncoterms();
        }
        break;
      case QuoteSearchParameters.to:
        {
          if (!this.isSelected[QuoteSearchParameters.cargo]) {
            this.isOpened[QuoteSearchParameters.cargo] = true;
          }

          this.googleAnalyticsService.newSearchCargoDetailsStart(this.getNewSearchLocationAnalytics())
          this.getIncoterms();
        }
        break;
      case QuoteSearchParameters.cargo:
        {
          if (!this.isSelected[QuoteSearchParameters.date]) {
            this.isOpened[QuoteSearchParameters.date] = true;
          }

          if (this.isValidQuotesSearchStepContainers()) {
            this.googleAnalyticsService.newSearchCargoDetailsComplete(this.getNewSearchLocationAnalytics())
          } else {
            this.googleAnalyticsService.newSearchCargoDetailsChurn(this.getNewSearchLocationAnalytics())
          }

          this.googleAnalyticsService.newSearchDepartureDateStart(this.getNewSearchLocationAnalytics())
        }
        break;
      case QuoteSearchParameters.date:
        {
          this.googleAnalyticsService.newSearchDepartureDateComplete(this.getNewSearchLocationAnalytics())
        }
        break;
      default: {
      }
    }
  }

  onSetSearchId(searchId: string): void {
    if (searchId !== undefined && searchId !== '' && searchId !== null) {
      this.searchId = searchId;
    }
  }

  onViewQuotes(): void {
    if ((!this.validateParams() && !this.quoteSearchForm.valid) || !this.checkAllSelected()) {
      this.isOpened[this.hasError.findIndex(x => x)] = true;
      return;
    }

    const quote = this.quoteSearchForm.getRawValue() as QuotesQuery;
    quote.fromDate = typeof (quote.fromDate) === 'object' ?
      DateUtils.toIsoDateString(quote.fromDate) :
      this.datepipe.transform(quote.fromDate, 'yyyy-MM-dd');

    if (this.quoteSearchForm.get('incoterms').value) {
      let incotermsCodes: IncotermCode[] = [];
      incotermsCodes.push(this.quoteSearchForm.get('incoterms').value);
      quote.incoterms = incotermsCodes;
    }

    if (this.quoteSearchForm.get('from').value) {
      quote.from.zipCode = quote.from.zipCode ? quote.from.zipCode : this.quoteSearchForm.get('from').value.postcode;
    }

    if (this.quoteSearchForm.get('to').value) {
      quote.to.zipCode = quote.to.zipCode ? quote.to.zipCode : this.quoteSearchForm.get('to').value.postcode;
    }
    quote.searchId = uuid.v4();

    this.searchQuotesEvent.emit(quote);
    this.googleAnalyticsService.newSearchViewQuotes(this.isFirstSearch, this.isRecentQuotesSearch);
  }

  onSetSelected(state: boolean, param: QuoteSearchParameters): void {
    this.isSelected[param] = state;
  }

  onSetOpened(state: boolean, param: QuoteSearchParameters): void {
    this.isOpened[param] = state;
  }

  onSetShipperType(type: ShipperType): void {
    this.quoteSearchForm.get('shipperType').setValue(type === undefined ? null : type);
  }

  onChangePieceCalculationToggle(isPieceCalculation: boolean): void {
    this.googleAnalyticsService.newSearchCargoDetailsUseToggle(
      this.getNewSearchLocationAnalytics(),
      isPieceCalculation);
  }

  cargoDetailsClickedOutside(): void {
    if (this.isValidQuotesSearchStepContainers()) {
      this.googleAnalyticsService.newSearchCargoDetailsComplete(this.getNewSearchLocationAnalytics())
    } else {
      this.googleAnalyticsService.newSearchCargoDetailsChurn(this.getNewSearchLocationAnalytics())
    }
  }

  cargoDetailsHazardousTouched(x: boolean): void {
    if (x) {
      this.googleAnalyticsService.newSearchCargoDetailsIsHazardousChecked(this.getNewSearchLocationAnalytics())
    } else {
      this.googleAnalyticsService.newSearchCargoDetailsIsHazardousUnchecked(this.getNewSearchLocationAnalytics())
    }
  }

  departureDateOpened(isOpened: boolean): void {
    if (isOpened) {
      return;
    }

    this.googleAnalyticsService.newSearchDepartureDateComplete(this.getNewSearchLocationAnalytics())
  }

  private validateParams(): boolean {
    this.quoteSearchForm.markAllAsTouched();
    this.setFromAndToValue();

    this.hasError[QuoteSearchParameters.from] = this.form.from.invalid;
    this.hasError[QuoteSearchParameters.to] = this.form.to.invalid;
    this.hasError[QuoteSearchParameters.cargo] = !this.isValidQuotesSearchStepContainers();
    this.hasError[QuoteSearchParameters.date] = this.form.fromDate.invalid || !this.isSelected[QuoteSearchParameters.date];

    return (
      this.hasError.every(x => !x) &&
      this.quoteSearchForm.get('incoterms').value != null
    );
  }

  private checkAllSelected(): boolean {
    return this.isSelected.every(x => x === true);
  }

  private isValidQuotesSearchStepContainers(): boolean {
    let valid = true;
    if (this.form.shipmentType?.value === ShipmentType.LCL || this.form.shipmentType?.value === ShipmentType.AIR) {
      if (!this.requiredIf('containerCommodities')) {
        valid = false;
      }
    }
    if (this.form.shipmentType?.value === ShipmentType.FCL) {
      if (!this.requiredIf('equipments')) {
        valid = false;
      }
    }

    this.onSetSelected(valid, QuoteSearchParameters.cargo);
    return valid;
  }

  private requiredIf(controlName: string): boolean {
    let isValid = this.quoteSearchForm.controls[controlName].valid;
    if (this.quoteSearchForm.controls[controlName] instanceof FormControl) {
      if (!isValid) {
        this.quoteSearchForm.controls[controlName].markAsTouched();
      }
    } else if (this.quoteSearchForm.controls[controlName] instanceof FormArray) {
      isValid = isValid && this.quoteSearchForm.controls[controlName].value.length > 0;
      (this.quoteSearchForm.controls[controlName] as FormArray).controls.forEach(element => {
        if (element instanceof FormGroup) {
          Object.keys(element.controls).forEach(key => {
            const childControl = element.get(key);
            if (childControl instanceof FormControl) {
              if (!childControl.valid) {
                childControl.markAsTouched();
              }
            } else if (childControl instanceof FormArray) {
              (childControl as FormArray).controls.forEach(subElement => {
                if (subElement instanceof FormGroup) {
                  Object.keys(subElement.controls).forEach(subKey => {
                    const subChildControl = subElement.get(subKey);
                    if (!subChildControl.valid) {
                      subChildControl.markAsTouched();
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    return isValid;
  }

  private getIncoterms(): void {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      this.quotesIncotermService.getIncotremsByLocationTypes(query).subscribe(
        (x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        },
        error => this.notificationService.error(error)
      );
    } else {
      this.incoterms = null;
    }
  }

  private getAddressTypeCode(locationType: LocationType): AddressTypeCode {
    const port = [LocationType.seaPort, LocationType.railwayTerminal, LocationType.containerTerminal];
    const airport = [LocationType.airPort];
    const door = [LocationType.townCity];

    if (port.includes(locationType)) {
      return AddressTypeCode.port;
    }
    else if (airport.includes(locationType)) {
      return AddressTypeCode.airport;
    }
    else if (door.includes(locationType)) {
      return AddressTypeCode.door;
    }
    else {
      return AddressTypeCode.port;
    }
  }

  private getNewSearchLocationAnalytics(): TripsSearchLocationAnalyticsViewModel {
    const locationOrigin = this.quoteSearchForm.get("from").value;
    const locationDestination = this.quoteSearchForm.get("to").value;
    const departureDate = this.quoteSearchForm.get("fromDate").value as Date;

    const newSearchLocationAnalytics = {
      shipmentType: this.shipmentype,
      pickupType: this.getAddressTypeCode(locationOrigin.locationType),
      originCountry: locationOrigin.countryName,
      originCity: locationOrigin.cityName,
      deliveryType: this.getAddressTypeCode(locationDestination.locationType),
      destinationCountry: locationDestination.countryName,
      destinationCity: locationDestination.cityName,
      departureDate: DateUtils.toIsoDateString(departureDate),
    } as TripsSearchLocationAnalyticsViewModel;
    return newSearchLocationAnalytics;
  }

  private setFromAndToValue(): void {
    if (this.form?.from?.value === "" || this.form?.from?.value === null) {
      this.form?.from.setValue(this.selectedQuote?.from);
      this.form?.from.enable();
    }

    if (this.form?.to?.value === "" || this.form?.to?.value === null) {
      this.form?.to.setValue(this.selectedQuote?.to);
      this.form?.to.enable();
    }
  }
}
