<div class="files-documents-container">
  <div class="files-documents-drag-drop" *ngIf="documentsFormArray?.controls.length === 0">
    <lib-drag-drop [mode]="FileUploaderMode.all" [count]="7" [files]="getBookingDocuments()"
      (filesChange)="onListingFilesChange($event)" [maxFileSize]="5242880" [layoutType]="LayoutType.client"
      [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)"
      [title]="'You can also click on this area to select the file'">
    </lib-drag-drop>
  </div>
  <div class="files-documents-upload-list" *ngIf="documentsFormArray?.controls.length > 0">
    <div [formGroup]="filesDocumentsForm" *ngIf="filesDocumentsForm">
      <div formArrayName="documents">
        <div class="files-documents-upload-row" *ngFor="let documentForm of documentsFormArray?.controls;let i = index">
          <div class="file-uploaded-wrapper" [formGroup]="documentForm">
            <div class="files-documents-upload-row-container">
              <div class="files-documents-upload-file-name">
                <span class="files-documents-upload-file-icon"></span>
                <span matTooltip="{{ documentForm.get('fileData').value?.name }}" class="files-documents-upload-file-name-text">
                  {{ documentForm.get('fileData').value?.name }}
                </span>
              </div>
              <div class="files-documents-upload-comment">
                <mat-form-field [floatLabel]="'never'" class="w-100">
                  <input matInput type="text" placeholder="Type to add a comment..." formControlName="comment" />
                </mat-form-field>
              </div>
              <div class="files-documents-upload-file-type" [ngClass]="{ 'invalid-error-box': !documentForm.get('documentType').valid
                && documentForm.get('documentType')?.touched }">
                <mat-form-field [floatLabel]="'never'" class="w-100">
                  <mat-select placeholder="Select File Type" formControlName="documentType">
                    <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.value">
                      {{ documentType.name | titlecase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="files-documents-buttons">
                <div class="files-documents-upload-action">
                  <button mat-raised-button class="next-button" (click)="onUpload(documentForm, i)"
                    [disabled]="disabledUploadButton">
                    Upload
                  </button>
                </div>
                <div class="files-documents-upload-remove">
                  <button mat-icon-button class="files-documents-upload-remove-button"
                    (click)="onCancel(documentForm, i)"></button>
                </div>
              </div>
            </div>
            <div class="files-documents-upload-row-progress">
              <lib-progress *ngIf="documentForm.get('fileData').value?.isStart &&
                !isUploaded(documentForm.get('fileData').value)"
                [progress]="documentForm.get('fileData').value?.progress" [showValue]="false">
              </lib-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>