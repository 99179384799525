import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  EnvironmentsService,
  EnvironmentsServiceConfig,
  InternalUrlsService,
  NotificationService
} from '@ship4wd/ngx-common';
import {
  SupportRequest,
  SupportRequestDialogData,
  SupportRequestMouseLeave
} from './support-requests.model';
import { environment } from '../../../environments/environment';
import { SupportRequestDialogComponent } from './support-request-dialog/support-request-dialog.component';
import { UserInfoService } from '../../shared/services/user-info/user-info.service';
import { RollupDialogService } from '../rollup-dialog/rollup-dialog.service';
import { TeamMemberSettings } from '../../desktop/manage-organization/manage-organization.model';
import { TeamMemberService } from '../../desktop/manage-organization/organization-members/team-member-dialog/team-member.service';
import { OrganizationsService } from '../../desktop/organizations/organizations.service';
import { GoogleAnalyticsService } from '../../shared/google-analytics/google-analytics.service';
import { DialogService } from '../../shared/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestsService {
  supportRequestDialogDataBehaviorSubject = new BehaviorSubject<SupportRequestDialogData>(null);
  dialogRef: any = null;
  timeoutId: any;

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient,
    private userInfoService: UserInfoService,
    public dialog: MatDialog,
    private rollupDialogService: RollupDialogService,
    private teamMemberService: TeamMemberService,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.clearTimeout();
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      });
  }

  addSupportRequest(model: SupportRequest): Observable<void | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<void | any>(
      `${this.internalUrlsService.getApiBaseUrl()}/supportRequests`,
      model,
      httpOptions
    );
  }

  openSupportDialogIfNeeded(delay: number = 0, supportRequestDialogData: SupportRequestDialogData): void {
    if (this.getIsShowSupportPopupAutoEnabled() && !supportRequestDialogData.isPopupAutoOpened) {
      this.openSupportDialog(supportRequestDialogData);
    }

    if (supportRequestDialogData.isPopupAutoOpened && this.getIsShowSupportPopupAutoEnabled() &&
      !this.userInfoService.getSettings().userSettings.disableHelpPopup) {
      this.timeoutId = setTimeout(() => {
        this.openSupportDialog(supportRequestDialogData);
      }, delay);
    }
  }

  close(): void {
    if (this.dialogRef) {
      this.rollupDialogService.close();
      this.dialogRef = null;
    }
  }

  openSupportDialogOnMouseLeave(
    supportRequestMouseLeave: SupportRequestMouseLeave,
    supportRequestDialogData: SupportRequestDialogData
  ): void {
    const element = supportRequestMouseLeave.event.currentTarget as HTMLElement;
    const mouseY = supportRequestMouseLeave.event.clientY;
    const elementY = element.getBoundingClientRect().top;

    if (
      this.getIsShowSupportPopupAutoEnabled() &&
      supportRequestMouseLeave.isQuotesPage &&
      supportRequestMouseLeave.quoteResultsPageIncludeResults &&
      !this.userInfoService.getSettings().userSettings.disableHelpPopup &&
      mouseY < elementY
    ) {
      this.openSupportDialog(supportRequestDialogData);
    }
  }

  openSupportDialog(supportRequestDialogData: SupportRequestDialogData): void {
    if (this.dialogRef != null) return;

    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      this.dialogRef = this.rollupDialogService.open(SupportRequestDialogComponent, supportRequestDialogData, "support-request-wrapper");
      this.dialogRef.subscribe(data => {
        this.dialogService.setDialogOpen(false);
        if (data === undefined) {
          this.googleAnalyticsService.noNeedHelpFromLiveBookingAssistancePopup('outside popup');
          this.updateTeamMemberSettings({ disableHelpPopup: true } as TeamMemberSettings);
        }

        this.dialogRef = null
      });
    }
  }

  updateSupportRequestDialogData(value: SupportRequestDialogData): void {
    this.supportRequestDialogDataBehaviorSubject.next(value)
  }

  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }

  private getIsShowSupportPopupAutoEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isShowSupportPopupAutoEnabled;
      case 'sb':
        return environment.sb.isShowSupportPopupAutoEnabled;
      default:
        return environment.isShowSupportPopupAutoEnabled;
    }
  }

  private updateTeamMemberSettings(teamMemberSettings: TeamMemberSettings): void {
    this.teamMemberService
      .updateTeamMemberSettings(this.userInfoService.getSettings()?.teamMemberId, teamMemberSettings)
      .subscribe(() => {
        this.organizationsService.setUserSettings(teamMemberSettings);
      },
        (error) => this.notificationService.error(error))
  }

  private clearTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
