<mat-dialog-content tabindex="-1" class="booking-contact-container">
  <div class="booking-contacts-left-section">
    <div class="page-header">
      <div class="p-4">
        <h2 class="page-header-title">
          {{
          organizationContactAction === organizationContactActions.view
          ? "Contact"
          : organizationContactAction === organizationContactActions.edit
          ? "Update Contact"
          : "Create a New Exporter Contact"
          }}
        </h2>
        <h4 *ngIf="!contactType" class="page-header-sub-title">
          Fill the form below to add only or create and save a new contact.
        </h4>
        <h4 *ngIf="contactType" class="page-header-sub-title">
          Fill the form below to create {{ selectedContactTitle }} from
          {{ contactCountryName }}
        </h4>
      </div>
      <div class="booking-contact-content-wrapper px-4" [formGroup]="organizationContactForm">
        <div class="booking-contact-fields-wrapper row">
          <div class="booking-contact-field-left col-sm-6">
            <div class="booking-contact-field">
              <div class="contact-field-wrapper">
                <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                  <mat-label class="form-label">First name (Required)</mat-label>
                  <div class="row h-100">
                    <div class="col d-flex align-items-end">
                      <input matInput type="text" formControlName="firstName" trackInputErrors
                        (triggerError)="handleError('First name', $event)" controlName="First name" />
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <mat-error class="contact-field-error" *ngIf="
                  organizationContactForm.get('firstName')?.touched &&
                  organizationContactForm.get('firstName')?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i>
                {{ "required" | errorMessage : "First Name" }}
              </mat-error>
            </div>
          </div>
          <div class="booking-contact-field-right col-sm-6">
            <div class="booking-contact-field">
              <div class="contact-field-wrapper">
                <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                  <mat-label class="form-label">Last name (Required)</mat-label>
                  <div class="row h-100">
                    <div class="col d-flex align-items-end">
                      <input matInput type="text" formControlName="lastName" trackInputErrors
                        (triggerError)="handleError('Last name', $event)" controlName="Last name" />
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <mat-error class="contact-field-error" *ngIf="
                  organizationContactForm.get('lastName')?.touched &&
                  organizationContactForm.get('lastName')?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i>
                {{ "required" | errorMessage : "Last Name" }}
              </mat-error>
            </div>
          </div>
        </div>

        <div class="booking-contact-fields-wrapper row">
          <div class="booking-contact-field">
            <div class="contact-field-wrapper">
              <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                <mat-label class="form-label">Company (Required)</mat-label>
                <div class="row h-100">
                  <div class="col d-flex align-items-end">
                    <input matInput type="text" formControlName="companyName" trackInputErrors
                      (triggerError)="handleError('Company', $event)" controlName="Company" />
                  </div>
                </div>
              </mat-form-field>
              <mat-error class="contact-field-error" *ngIf="
                  organizationContactForm.get('companyName')?.touched &&
                  organizationContactForm
                    .get('companyName')
                    ?.hasError('required')
                ">
                <i class="s4d-icon s4d-info-light"></i>
                {{ "required" | errorMessage : "Company" }}
              </mat-error>
            </div>
          </div>

          <div class="booking-contact-field" *ngIf="organizationContactForm.get('countryCode')">
            <app-country-autocomplete class="contact-field--country" floatLabel="auto" title="Country"
              [countryAutocompleteValue]="
                organizationContactForm.get('countryCode').value
              " [countryControl]="organizationContactForm.get('countryCode')"
              (triggerError)="handleError('Country', $event)">
            </app-country-autocomplete>
            <mat-error class="contact-field-error" *ngIf="
                organizationContactForm.get('countryCode')?.touched &&
                organizationContactForm.get('countryCode')?.hasError('required')
              ">
              <i class="s4d-icon s4d-info-light"></i>
              {{ "required" | errorMessage : "Country" }}
            </mat-error>
          </div>


          <div class="booking-contact-field">
            <div class="contact-field-wrapper">
              <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
                <mat-label class="form-label">Business Email (Required)</mat-label>
                <div class="row h-100">
                  <div class="col d-flex align-items-end">
                    <input matInput type="email" formControlName="email" trackInputErrors
                      (triggerError)="handleError('Business Email', 'Business Email invalid')"
                      controlName="Business Email" />
                  </div>
                </div>
              </mat-form-field>
              <mat-error class="contact-field-error" *ngIf="
                  organizationContactForm.get('email')?.touched &&
                  organizationContactForm.get('email')?.hasError('invalidEmail')
                ">
                <i class="s4d-icon s4d-info-light"></i> Business Email invalid
              </mat-error>
            </div>
          </div>
          <div class="booking-contact-field">
            <div class="contact-field-wrapper">
              <app-phone-input #phoneInputComponent class="h-100 w-100" [fromControl]="organizationContactForm.get('phoneNumber')"
                [selectedCountry]="organizationContactForm.get('countryCode').value"
                (errorMessage)="handleError('Phone number', $event)" controlName="Phone number"></app-phone-input>
            </div>
          </div>
        </div>
      </div>

      <div class="booking-contact-bottom p-4">
        <div class="row gap-4 m-0 w-100">
          <div class="col px-0">
            <button mat-raised-button class="cancel-button" (click)="onBackToContact()" [matTooltip]="
                organizationContactAction !== organizationContactActions.view
                  ? 'Cancel'
                  : 'Close'
              ">
              {{
              organizationContactAction !== organizationContactActions.view
              ? "Cancel"
              : "Close"
              }}
            </button>
          </div>
          <div class="col px-0" *ngIf="
              organizationContactAction !== organizationContactActions.view
            ">
            <button mat-raised-button class="create-button button-spinner" (click)="onSaveAndSelect()" matTooltip="{{
                organizationContactAction === organizationContactActions.new
                  ? 'Create'
                  : 'Update & Select'
              }}">
              <span class="button-inner">
                {{
                organizationContactAction === organizationContactActions.new
                ? "Create"
                : "Update & Select"
                }}
                <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>