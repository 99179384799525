import { BookingAdditionalServiceCharges } from '../additional-services/additional-services.model';
import { PackageType } from "../package-types/package-types.model";
import {
  BookingPaidStatusCode,
  BookingStatusCodeEnum,
  CarriageStatusType,
  CodeType,
  CurrencyCode,
  DocumentTypeCode,
  ExtenderTrip,
  LinearUnit,
  LocationType,
  Profile,
  RateServiceType,
  ShipmentType,
  ShipperType,
  TradeNeedsTypeCode,
  VendorsCode,
  VolumeUnit,
  WeightUnit,
} from "../shared.model";
import { DocumentComment } from './booking-documents/booking-documents.model';

export class Booking {
  id: string;
  organizationId: string;
  quoteId: string;
  quoteSearchId: string;
  rateId: string;
  companyCode: string;
  shipperTypeCode: ShipperType;
  shipmentTypeCode: ShipmentType;
  isUserLocked: boolean;
  isAgentLocked: boolean;
  from: string;
  to: string;
  fromDisplayName: string;
  toDisplayName: string;
  vesselCode: string;
  etd: string;
  cutOffDate: string;
  atd: string;
  duePaymentDate: string;
  tripDuration: string;
  eta: string;
  customerReferenceId: string;
  isTermsAndConditionsConfirmed: boolean;
  bookingPaidStatusCode: BookingPaidStatusCode;
  statusTypeId: string;
  serviceType: BookingServiceType;
  rateServiceType: RateServiceType;
  masterBillOfLading: string;
  bolOrderNumber: string;
  isDeleted: boolean;
  isCanceled: boolean;
  hasPayments: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  notifyPartyContact: Contact;
  consigneeContact: Contact;
  shipperContact: Contact;
  statusType: BookingStatus;
  fromBookingSearch: BookingSearch;
  toBookingSearch: BookingSearch;
  containers: Container[];
  anotherReferences: AnotherReference[];
  notes: Note[];
  documents: BookingFile[] = [];
  bookingAdditionalServiceCharges: BookingAdditionalServiceCharges[];
  organizationName: string;
  bookingAdditionalInformation: BookingAdditionalInformation;
  expirationTime: string;
}

export class BookingsQuery {
  from: string;
  to: string;
  vesselCode: string;
  etd?: string;
  atd?: string;
  duePaymentDate?: string;
  shipperTypeCode?: ShipperType;
  shipmentTypeCode?: ShipmentType;
  customerReferenceId: string;
  isTermsAndConditionsConfirmed?: boolean;
  hasFinancialDocuments?: boolean;
  hasCreditLineDocuments?: boolean;
  bookingPaidStatusCode?: BookingPaidStatusCode;
  statusTypeId?: string;
  serviceType?: BookingServiceType;
  organizationId: string;
  isCanceled?: boolean;
  hasPayments?: boolean;
  any: boolean;
  sortBy?: BookingsQueryParameters;
  sortDesc: boolean;
  pageNo: number;
  pageSize: number;
  orderStatuses: BookingStatusCodeEnum[];
  paidStatusCodes: BookingPaidStatusCode[];
  fromDisplayName: string;
  toDisplayName: string;
  fromLastUpdateDate: string;
  orIsCanceled?: boolean;
  bolOrderNumber: string;
  masterBillOfLading: string;
  readMode?: BookingsReadMode
}

export enum BookingsReadMode {
  readAll = 1,
  appDashboard = 2,
  backOfficeDashboard = 3,
  backOfficeBooking = 4,
  applicationBooking = 5
}

export class BookingStatus {
  id: string;
  code: BookingStatusCode;
  carriageType: CarriageStatusType;
  ship4wdFrontendStatus: string;
  ship4wdShortFrontendStatus: string;
  description: string;
}

export class UpdateBookingStatus {
  shipmentType?: ShipmentType;
  ship4wdCode: BookingStatusCode;
  statusTypeId: string;
}

export class UpdateBookingIsCanceled {
  isCanceled: boolean;
  userId: string;
  organizationId: string;
  isAgent: boolean;
}

export enum BookingsShowParameters {
  all = 1,
  draft = 2,
  woDraft = 3
}

export enum BookingsQueryParameters {
  from = 1,
  to = 2,
  vesselCode = 3,
  etd = 4,
  shipperType = 5,
  shipmentType = 6,
  customerReferenceId = 7,
  isTermsAndConditionsConfirmed = 8,
  statusType = 9,
  serviceType = 10,
  createTimeUtc = 11,
  updateTimeUtc = 12,
  isCanceled = 13,
  bolOrderNumber = 14,
  masterBillOfLading = 15,
  bookingPaidStatusCode = 16,
  atd = 17,
  duePaymentDate = 18,
  rateServiceType = 19
}

export enum BookingStatusCode {
  draft = 1,
  orderSubmitted = 2,
  orderReceived = 3,
  bookedWithCarrier = 4,
  creditCheck = 5,
  documentsReceived = 6,
  pickedUp = 7,
  onHand = 8,
  arrivedAtCustomsOrigin = 9,
  customsReleasedOrigin = 10,
  onBoard = 11,
  departed = 12,
  hblIssuedToCustomer = 13,
  arrivalNotiveNotificationSent = 14,
  arrivedAtPort = 15,
  arrivedAtCustomsDestination = 16,
  customsReleased = 17,
  transferToRail = 18,
  cntrAvailable = 19,
  deliveryOrder = 20,
  outForDelivery = 21,
  delivered = 22,
  proofOfDelivery = 23,
  returnedContainer = 24,
  invoiced = 25,
  tenderedToCFS = 26,
  arrivedCFSDestination = 27,
  cargoUnloaded = 28,
  shipmentStoredAtWarehouse = 29,
  airwaybilIssued = 30,
  manifested = 31,
  upliftPreAlert = 32,
  arrived = 33,
  landingPreAlert = 34,
  customsReleasedDestination = 35,
  receivedFromCarrier = 36
}

export enum BookingServiceType {
  port2Port = 1,
  door2Port = 2,
  door2Door = 3,
  port2Door = 4,
  port2Cfs = 5,
  port2Ramp = 6,
  door2Cfs = 7,
  door2Ramp = 8,
  cfs2Port = 9,
  cfs2Door = 10,
  cfs2Cfs = 11,
  cfs2Ramp = 12,
  ramp2Port = 13,
  ramp2Door = 14,
  ramp2Cfs = 15,
  ramp2Ramp = 16,
  allServices = 17
}

export enum ContactType {
  notifyParty = 1,
  consignee = 2,
  shipper = 3,
  collect = 4,
  drop = 5,
}

export class Contact {
  id?: string;
  bookingId: string;
  organizationId: string;
  originId?: string;
  contactTypeCode: ContactType;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  taxId: string;
  customerCode: string;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class BookingFile {
  id: string;
  bookingId: string;
  name: string;
  url: string;
  documentTypeCode: DocumentTypeCode;
  description: string;
  comments: DocumentComment[];
  createTimeUtc: string;
}

export class Container {
  id: string;
  bookingId: string;
  equipmentTypeCode: string;
  equipmentTypeDescription: string;
  cargoName: string;
  cargoGrossWeight?: number;
  cargoGrossWeightUnitCode?: WeightUnit;
  cargoExpectedReady?: string;
  collectionReference: string;
  collectionDate?: string;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  collectContact: Contact;
  dropContact: Contact;
  commodities: Commodity[];
  containerNumber: string;
  sealNumber: string;
}

export class Commodity {
  id?: string;
  containerId: string;
  dimensionId?: string;
  packageTypeCode: PackageType;
  commodityTypeCode: string;
  commodityDescription: string;
  numberOfPackages?: number;
  productValue?: number;
  productValueCurrencyCode: CurrencyCode;
  volume?: number;
  volumeUnitCode?: VolumeUnit;
  weight?: number;
  weightUnitCode?: WeightUnit;
  marksAndNumbers: string;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
  dimension: Dimension;
}

export class Dimension {
  id: string;
  commodityId: string;
  length?: number;
  lengthUnitCode?: LinearUnit;
  width?: number;
  widthUnitCode?: LinearUnit;
  height?: number;
  heightUnitCode?: LinearUnit;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class AnotherReference {
  id: string;
  bookingId: string;
  name: string;
  value: string;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class Note {
  id: string;
  bookingId: string;
  value: string;
  createTimeUtc: string;
  updateTimeUtc: string;
  noteAccessType: BookingNoteAccessType;
  userId: string;
  userName: string;
}

export enum BookingNoteAccessType {
  customer = 1,
  internal = 2
}

export class BookingViewModel {
  customerReferenceId: string;
  isTermsAndConditionsConfirmed: boolean;
  containers: Container[];
  anotherReferences: AnotherReference[];
  notes: Note[];
}

export enum BookingSteps {
  stepCustomerRef = 1,
  stepContacts = 2,
  stepCargo = 3,
  stepDocuments = 4,
  stepSummary = 5,
}

export class CreateBooking {
  quoteId: string;
  quoteSearchId: string;
  rateId: string;
  preCarriageAllowed: boolean;
  postCarriageAllowed: boolean;
  profile: Profile;
}

export class BookingPdfRequest {
  bookingId: string;
  token: string;
}

export class BookingSummary extends Booking {
  quote: SummaryQuote;
  totalPrice: number;
}

export class SummaryQuote {
  id: string;
  schedulingTripId: string;
  quoteSearchId: string;
  routeId: string;
  accumulatePrice?: number;
  accumulateRateId?: string;
  currency?: CurrencyCode;
  trip: ExtenderTrip;
  isPreCarriageMissingRate?: boolean;
  isPostCarriageMissingRate?: boolean;
}

export class SummaryRate {
  id: string;
  vendorsCode: VendorsCode;
  currencyCode: CurrencyCode;
  grandTotal: number;
  totalFreightRates: number;
  totalRebates: number;
  totalSurcharges: number;
  totalRelatedCosts: number;
  total: number;
  rateServiceTypeCode: RateServiceType;
  shipmentEquipment: SummaryShipmentEquipment;
  rateLegs: SummaryRateLeg[];
}

export class SummaryRateLeg {
  freightRate: SummaryFreightRate;
  surcharges: SummaryAdditionalRate[];
  relatedCosts: SummaryAdditionalRate[];
}

export class SummaryShipmentEquipment {
  code: string;
  name: string;
  quantity: number;
}

export class SummaryFreightRate {
  tariff: string;
  supplier: string;
  remarks: string;
  description: string;
  shipmentRate: SummaryShipmentRate;
}

export class SummaryShipmentRate {
  amount: number;
  currency: CurrencyCode;
  calculationBase: string;
}

export class SummaryAdditionalRate {
  tariff: string;
  supplier: string;
  remarks: string;
  shipmentRate: SummaryShipmentRate;
  additionalType: string;
  applicableType: string;
}

export class BookingStatusViewModel {
  statusCode: BookingStatus;
}

export class ReferenceSelectItem {
  name: string;
  value: number;
}

export class BookingSearch {
  id: string;
  codeType: CodeType;
  value: string;
  country: string;
  city: string;
  unLocode: string;
  postcode: string;
  iATA: string;
  area: string;
  displayName: string;
  functions: string;
  locationType: LocationType;
  latitudes: string;
  countryName: string;
  cityName: string;
  street: string;
  carriageType: CarriageStatusType;
}

export class BookingSummaryPageRightSideBar {
  orderNo: string;
  freightRates: FreightRate;
  otherSections?: OtherSection[];
}

export class FreightRate {
  name: string = 'Freight Charges';
  properties?: SummaryProperty[];
}

export class OtherSection {
  name: string;
  type: string;
  properties?: SummaryProperty[];
}

export class SummaryProperty {
  name: string;
  value: number;
}

export class CertificateSummary {
  certificateNumber: string;
  productId: string;
  productName: string;
  certificateLink: string;
  termsAndConditionsLink: string;
  fileClaimLink: string;
  limit?: number;
  deductible?: number;
  premium?: number;
  serviceFee?: number;
  tax?: number;
  currency: CurrencyCode;
}

export class BookingAdditionalInformation {
  id: string;
  incoterms: string;
  currency: CurrencyCode | null;
  carrierScac: string;
  vesselName: string;
  voyage: string;
  contractNo: string;
  isHazardous: boolean;
  isBatteries: boolean;
  divisionCode: string;
  billingTypeCode: string;
  externalQuoteId: string;
  externalBookingNo: string;
  isDeleted: boolean;
  createTimeUtc: string;
  updateTimeUtc: string;
}
