import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentsService, EnvironmentsServiceConfig, InternalUrlsService } from '@ship4wd/ngx-common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 } from 'uuid';
import {
  Page,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  UnitMeasurementType,
  WeightUnit
} from '../../shared/shared.model';
import { MissingQuotes, Quote, QuoteSearch, QuotesQuery, StoredQuotesResults } from './quotes.model';
import { environment } from '../../../environments/environment';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class QuotesService {
  unsubscribeSearchQuote$: Subject<void> = new Subject<void>();
  stopSearchQuote$ = new BehaviorSubject<boolean>(false);
  isFromSearchButton$ = new BehaviorSubject<boolean>(false);
  searchParamChange = new Subject<SearchType>();
  notifyToLocationFound = new BehaviorSubject<boolean | null>(null);
  activeMenuOption = new BehaviorSubject<QuoteSearchParameters | null>(null);
  private recentQuoteSearchSubject = new BehaviorSubject<QuoteSearch | null>(null);
  useRecentQuoteSearch = this.recentQuoteSearchSubject.asObservable();
  quotesResultsStorageKey: string = 'quotesResults';
  quotesQueryStorageKey: string = 'quotesQuery';
  isFirstSearchStorageKey: string = 'isFirstSearch';
  unitMeasurementStorageKey: string = 'unitMeasurementType';

  isClearControls = new BehaviorSubject<any | null>(null);
  sendQuoteData = new BehaviorSubject<any | null>(null);

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe
  ) { }

  getQuotesByQuery(query: QuotesQuery): Observable<Page<Quote>> {
    this.unsubscribeSearchQuote$ = new Subject<void>();
    return this.http.post<Page<Quote>>(
      `${this.internalUrlsService.getApiBaseUrl()}/quotes`, query)
      .pipe(takeUntil(this.unsubscribeSearchQuote$));
  }

  getMissingQuote(missingQuotes: MissingQuotes): Observable<any> {
    return this.http.post<HttpStatusCode>(
      `${this.internalUrlsService.getApiBaseUrl()}/quotes/missingQuotes`, missingQuotes);
  }

  getReuseQuote(id: string, ignoreSharable: boolean = false): Observable<any> {
    let params = new HttpParams()
    if (ignoreSharable) {
      params = params.set("ignoreSharable", ignoreSharable);
    }
    return this.http.get<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/quotes/reuse/${id}`, { params });
  }

  setActiveMenuOption(option: QuoteSearchParameters): void {
    this.activeMenuOption.next(option);
  }

  getQuotesQueryFromStorage(): QuotesQuery {
    const oldQuotesQueryString = this.getFromStorage(this.quotesQueryStorageKey);
    if (!oldQuotesQueryString) {
      return null;
    }

    const oldQuotesQuery = JSON.parse(oldQuotesQueryString) as QuotesQuery;
    if (!oldQuotesQuery) {
      return null;
    }

    oldQuotesQuery.searchId = v4();

    return oldQuotesQuery;
  }

  getIsFirstSearchFromStorage(userId: string, organizationId: string): boolean {
    const isFirstSearchString = this.getFromStorage(`${this.isFirstSearchStorageKey}-${userId}-${organizationId}`);

    if (!isFirstSearchString) return null;

    return JSON.parse(isFirstSearchString)
  }

  updateIsFirstSearchInStorage(isFirstSearch: boolean, userId: string, organizationId: string): void {
    const storageKey = `${this.isFirstSearchStorageKey}-${userId}-${organizationId}`;
    this.removeFromStorage(storageKey);
    this.addInStorage(storageKey, isFirstSearch);
  }

  saveQuotesQueryInStorage(query: QuotesQuery): void {
    this.addInStorage(this.quotesQueryStorageKey, query);
  }

  removeQuotesQueryFromStorage(): void {
    this.removeFromStorage(this.quotesQueryStorageKey);
  }

  getQuotesResultsFromStorage(quotesQuery: QuotesQuery): StoredQuotesResults {
    const quoteStorageKey = this.prepareKeyForQuoteStorage(quotesQuery);
    const oldQuotesResultsString = this.getFromStorage(quoteStorageKey);
    if (!oldQuotesResultsString) {
      return null;
    }

    const oldQuotesResults = JSON.parse(oldQuotesResultsString) as StoredQuotesResults;
    if (!oldQuotesResults) {
      return null;
    }

    const currentTime = new Date();
    const expirationTime = new Date(oldQuotesResults.expiresAt);

    if (currentTime > expirationTime) {
      this.removeFromStorage(quoteStorageKey);
      return null;
    }

    return oldQuotesResults;
  }

  saveQuotesResultsInStorage(quotes: Array<Quote>, quotesQuery: QuotesQuery): void {
    var quoteStorageKey = this.prepareKeyForQuoteStorage(quotesQuery);
    const expirationTime = new Date();
    expirationTime.setTime(
      expirationTime.getTime() + this.getDefaultQuotesResultsExpirationTime()
    );
    const quotesResults = {
      quotes,
      expiresAt: expirationTime.getTime(),
    } as StoredQuotesResults;

    this.addInStorage(quoteStorageKey, quotesResults);
  }

  removeQuotesResultsFromStorage(): void {
    Object.keys(localStorage)
      .filter(key => key.startsWith("quoteResult-"))
      .forEach(key => localStorage.removeItem(key));
  }

  getUnitMeasurementFromStorage(): UnitMeasurementType {
    const unitMeasurementTypeString = this.getFromStorage(this.unitMeasurementStorageKey);
    if (!unitMeasurementTypeString) {
      return null;
    }

    return JSON.parse(unitMeasurementTypeString) as UnitMeasurementType;
  }

  saveUnitMeasurementInStorage(UnitMeasurement: UnitMeasurementType): void {
    this.addInStorage(this.unitMeasurementStorageKey, UnitMeasurement);
  }

  removeUnitMeasurementFromStorage(): void {
    this.removeFromStorage(this.unitMeasurementStorageKey);
  }

  prepareKeyForQuoteStorage(quotesQuery: QuotesQuery): string {
    let equipmentKey = "";
    let totalNumberOfPackages = 0;
    let totalChargeableWeightAmount = 0;
    let totalVolumeAmount = 0;

    if (quotesQuery.shipmentType === ShipmentType.FCL) {
      quotesQuery.equipments = quotesQuery.equipments.sort((a, b) => (a.equipmentCode > b.equipmentCode) ? 1 : (b.equipmentCode > a.equipmentCode) ? -1 : 0)
      quotesQuery.equipments.map((x) => {
        equipmentKey += x.equipmentCode + x.quantity
      });
      equipmentKey += Number(quotesQuery.isHazardous);
    } else {
      quotesQuery.containerCommodities.map((x) => {
        totalNumberOfPackages += x.numberOfPackages;
        totalChargeableWeightAmount += parseFloat(this.decimalPipe.transform(this.calculateWeight(x), '1.2-2').replace(',', ''));
        totalVolumeAmount += parseFloat(this.decimalPipe.transform(x.totalVolumeAmount, '1.2-2'));
      });
      equipmentKey += `${totalVolumeAmount}v${totalNumberOfPackages}p${totalChargeableWeightAmount}w${Number(quotesQuery.isHazardous)}`;
    }

    return `quoteResult-${quotesQuery.shipmentType}${quotesQuery.from.unLocode}${quotesQuery.from.locationType}${quotesQuery.to.unLocode}${quotesQuery.to.locationType}${equipmentKey}${quotesQuery.fromDate.replace(/-/g, '')}`;
  }

  searchQuotesByQuoteSearch(quoteSearch: QuoteSearch) {
    this.recentQuoteSearchSubject.next(quoteSearch);
  }

  private getFromStorage(key: string): string {
    return localStorage.getItem(key);
  }

  private addInStorage(key: string, data: any): void {
    if (![null, undefined].includes(data)) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  private removeFromStorage(key: string): void {
    const oldData = this.getFromStorage(key);
    if (oldData) {
      localStorage.removeItem(key);
    }
  }

  private getDefaultQuotesResultsExpirationTime(): number {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.quotesResultsExpirationTime;
      case 'sb':
        return environment.sb.quotesResultsExpirationTime;
      default:
        return environment.quotesResultsExpirationTime;
    }
  }

  private calculateWeight(commodity: any): string {
    if (!commodity.height && !commodity.length && !commodity.width) {
      if (commodity.weightUnit === WeightUnit.KG) {
        return (commodity.volumeAmount * 1000).toString();
      } else {
        return (commodity.volumeAmount / 1728 * 36).toString();
      }
    } else {
      return commodity.weightAmount.toString();
    }
  }
}
