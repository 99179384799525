import { Pipe, PipeTransform } from '@angular/core';
import { ExtenderTripRate } from '../../shared.model';

@Pipe({ name: 'totalAdditionalTypeRate' })
export class TotalAdditionalTypeRatePipe implements PipeTransform {

  transform(value?: ExtenderTripRate[], applicableType?: string, additionalType?: string): number {
    return value.reduce((accumulator, current) => {
        current.surcharges.filter((f) => {
        return f.applicableType === applicableType && f.additionalType === additionalType;
      }).forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        });
  
        return accumulator;
      }, 0);
  }
}