<div *ngIf="isLoading; else loaded" class="loading">
    <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
    <div class="booking-documents-container">
        <div class="booking-documents-wrapper">
            <lib-drag-drop [mode]="FileUploaderMode.all" [count]="7" [canDownload]="false"
                (filesChange)="onListingFilesChange($event)" [maxFileSize]="5242880" [layoutType]="LayoutType.client"
                [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)">
            </lib-drag-drop>
        </div>
    </div>

    <app-navbar>
        <div class="col px-3 align-self-center booking-steps-button-wrapper">
            <div class="back-continue-button-wrapper">
                <div class="continue-wrapper">
                    <button mat-raised-button class="next-button button-spinner" (click)="confirmPayment()">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </app-navbar>
</ng-template>