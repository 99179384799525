import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'app-quote-search-flow-loader',
  templateUrl: './quote-search-flow-loader.component.html',
  styleUrls: ['./quote-search-flow-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowLoaderComponent {
  @Input() isFull: boolean = true;
  @Input() isShowLeftSide: boolean = true;
  
  range(value): number[] {
    return Array.from({ length: value }, (v, k) => k + 1);
  }
}
