import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { ShipmentInstruction, ShipmentInstructionViewModel } from './booking-shipping-instructions.model';

@Injectable({
  providedIn: 'root'
})
export class BookingShippingInstructionsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getShippingInstructionBooking(bookingId: string): Observable<ShipmentInstruction> {
    return this.http.get<ShipmentInstruction>(
      `${this.internalUrlsService.getApiBaseUrl()}/shipmentInstructions/${bookingId}`
    );
  }

  updateShippingInstructionBooking(bookingId: string, ShipmentInstruction: ShipmentInstructionViewModel) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put(
      `${this.internalUrlsService.getApiBaseUrl()}/shipmentInstructions/${bookingId}`,
      ShipmentInstruction, httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
