import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ContainerCommodity, ExtenderTrip, ExtenderTripRate, Quote, QuotesQuery } from '../../../quotes.model';
import { CarriageStatusType, ShipmentType, VolumeUnit, WeightUnit } from '../../../../../shared/shared.model';
import { RateDetailsDialogDefaultComponent } from './rate-details-dialog/rate-details-dialog.component';
import { UtilityService } from '../../../../../shared/helper/utility.service';

@Component({
  selector: 'app-quote-rate-detail-row',
  templateUrl: './quote-rate-detail-row.component.html',
  styleUrls: ['./quote-rate-detail-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteRateDetailRowComponent implements OnInit {
  @Input() quote: Quote;
  @Input() shipmentType: ShipmentType;
  @Input() showD2dAndAir: boolean;
  @Input() quotesQuery: QuotesQuery;
  CarriageStatusType = CarriageStatusType;
  ShipmentType = ShipmentType;
  commodities: ContainerCommodity;
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalChargeableWeight = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  showDetails = false;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  carriageTripLegs = [];
  lastLegCarriage = null;

  get accumulatePrice(): number {
    return this.quote?.accumulatePrice;
  }

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private readonly sso: ScrollStrategyOptions,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.quote.trip);
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;

    if (this.shipmentType !== ShipmentType.FCL) {
      this.totalLoadCalculation();
      this.commodities = this.quotesQuery.containerCommodities[this.quotesQuery.containerCommodities.length - 1];
    }
  }

  getTotalFreightRate(rates: ExtenderTripRate[]) {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip) {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage, carriage: trip.preCarriageTripLeg
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage, carriage: trip.mainCarriageTripLeg
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage, carriage: trip.postCarriageTripLeg
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element?.shipmentRate?.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    return carriageTripLegs;
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }
  
    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.shipmentType == ShipmentType.FCL) {
      
      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }
  
    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  onShowDetails(): void {
    this.dialog.open(RateDetailsDialogDefaultComponent, {
      autoFocus: true,
      scrollStrategy: this.sso.noop(),
      data: {
        quote: this.quote,
        quotesQuery: this.quotesQuery,
        shipmentType: this.shipmentType
      },
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;

    this.quotesQuery.containerCommodities.forEach((e: any) => {
      packagesCount += e.numberOfPackages ? e.numberOfPackages : 0;
      totalVolumeAmount += e.totalVolumeAmount ? e.totalVolumeAmount : e.volumeAmount * packagesCount ? e.volumeAmount * packagesCount : 0;
      totalWeightAmount += e.totalWeightAmount ? e.totalWeightAmount : e.volumeAmount * packagesCount ? e.weightAmount * packagesCount : 0;
      this.grandTotalVolumeUnit = e.volumeUnit;
      this.grandTotalWeightUnit = e.weightUnit;
      this.grandTotalVolumeAmount += e.volumeAmount;
      this.grandTotalWeightAmount += e.weightAmount;

      var chargableWeight = this.calculateTotalChargeableWeight(e.volumeAmount, e.volumeUnit);
      var commodityChargeableWeight = e.weightAmount > chargableWeight ? e.weightAmount : chargableWeight;
      this.grandTotalChargeableWeight += commodityChargeableWeight;
    });
  }

  private calculateTotalChargeableWeight(totalVolume: number, volumeUnit: VolumeUnit): number {
    if (volumeUnit === VolumeUnit.CBM) {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }
}