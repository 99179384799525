<div class="quote-loader">
  <div class="row justify-content-center w-100">
    <div class="col-12 d-flex justify-content-center">
      <div class="quote-loader-image">
        <img *ngIf="isImage" [src]="imageUrl" alt="Loading image" />
        <mat-spinner *ngIf="!isImage" class="loading-spinner" diameter="48"></mat-spinner>
      </div>
    </div>
    <div class="col-12">
      <div class="row quote-loader-wrapper" style="--d: {{ stepDuration + 's' }}">
        <div id="step-{{ i }}" class="col" [ngClass]="{ 'd-none': i !== 0 }"
          *ngFor="let title of stepTitles; let i = index">
          <h2 class="quote-loader-title" [ngClass]="{ 'stop-animation': i + 1 === stepTitles.length }">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <div class="quote-loader-progress-bar">
        <mat-progress-bar style="--d: {{ stepDuration + 's' }}" *ngIf="stepTitles" class="progress-bar"
          mode="determinate" [value]="progress"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>