import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '@ship4wd/ngx-common';
import { Booking, Contact, ContactType } from '../../../../shared/bookings/bookings.model';
import { BookingFlowPanelState, BookingFlowPanels, Page } from '../../../../shared/shared.model';
import { RollupDialogService } from '../../../../mobile/rollup-dialog/rollup-dialog.service';
import { BookingFlowContactsSelectComponent } from './flow-contacts-select/flow-contacts-select.component';
import { BookingContactsService } from '../../../../shared/bookings/booking-contacts/booking-contacts.service';
import { BookingOrganizationContactsService } from '../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { OrganizationContact, OrganizationContactsQuery, OrganizationContactsQueryParameters } from '../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { BookingContactViewModel } from '../../../../shared/bookings/booking-contacts/booking-contacts.model';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'app-booking-flow-contacts',
  templateUrl: 'booking-flow-contacts.component.html',
  styleUrls: ["./booking-flow-contacts.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowContactsComponent implements OnInit, OnChanges {
  @Input() booking: Booking;
  @Input() shipperContact: Contact;
  @Input() consigneeContact: Contact;
  @Input() notifyPartyContact: Contact;
  @Output() selectedContact = new EventEmitter();
  @Output() unselectedContact = new EventEmitter<ContactType>();
  @Output() next: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() setState: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() manageContacts = new EventEmitter();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();

  orgContacts: OrganizationContact[];
  filteredShipperContacts: OrganizationContact[];
  filteredConsigneeContacts: OrganizationContact[];
  selectedOrganizationShipperContact: OrganizationContact | undefined;
  selectedOrganizationConsigneeContact: OrganizationContact | undefined;
  selectedOrganizationNotifyPartyContact: OrganizationContact | undefined;
  contactTypes = ContactType;

  isShowNotifyContact: boolean = false;
  isShipperContactLoading: boolean = false;
  isConsigneeContactLoading: boolean = false;
  isNotifyPartyContactLoading: boolean = false;
  isNextLoading: boolean = false;

  get isValid(): boolean {
    return (this.selectedOrganizationShipperContact !== null && this.selectedOrganizationShipperContact !== undefined) &&
      (this.selectedOrganizationConsigneeContact !== null && this.selectedOrganizationConsigneeContact !== undefined) &&
      (this.selectedOrganizationNotifyPartyContact !== null && this.selectedOrganizationNotifyPartyContact !== undefined);
  }

  constructor(
    private rollupDialogService: RollupDialogService,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private bookingContactsService: BookingContactsService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getOrganizationContacts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getOrganizationContacts();

    if (changes?.shipperContact?.currentValue) {
      this.setSelectedContactByContactType(
        ContactType.shipper,
        changes?.shipperContact?.currentValue
      );
    }

    if (changes?.consigneeContact?.currentValue) {
      this.setSelectedContactByContactType(
        ContactType.consignee,
        changes?.consigneeContact?.currentValue
      );
    }

    if (changes?.notifyPartyContact?.currentValue) {
      this.setSelectedContactByContactType(
        ContactType.notifyParty,
        changes?.notifyPartyContact?.currentValue
      );
    }
  }

  onOpenSelect(contactType: ContactType): void {
    const data = {
      contactType: contactType,
      contacts: null,
      booking: this.booking
    };

    switch (contactType) {
      case ContactType.shipper: {
        data.contacts = this.filteredShipperContacts;
        break;
      }
      case ContactType.consignee: {
        data.contacts = this.filteredConsigneeContacts;
        break;
      }
      case ContactType.notifyParty: {
        data.contacts = this.orgContacts;
        break;
      }
      default: break;
    }

    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(BookingFlowContactsSelectComponent, data);

      dialogRef.subscribe(data => {
        this.dialogService.setDialogOpen(false);
        if (data !== undefined && data != null) {
          if (data.bookingContactId.length === 0) {
            if (data.contact === null) {
              this.unselectContact(data.contactType);
            }
            else {
              this.selectContact(data.contact, data.contactType);
            }
          }
          else {
            this.selectedContact.emit({
              bookingContactId: data.bookingContactId,
              contactType: contactType,
            });
          }
        }
      });
    }
  }

  onShowNotifyContact(): void {
    this.isShowNotifyContact = true;
  }

  onManageContacts(contactType: ContactType): void {
    this.manageContacts.emit({ contactType: contactType });
  }

  onSetState(): void {
    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.contacts;
    state.success = this.isValid;
    state.valid = this.isValid;
    state.loading = false;

    this.setState.emit(state);
  }

  onNext(): void {
    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.contacts;
    state.success = !this.isValid;
    state.error = !this.isValid;
    state.valid = this.isValid;
    state.loading = true;

    this.next.emit(state);
  }

  private getOrganizationContacts(): void {
    const query = {
      pageNo: 1,
      pageSize: 50,
      sortBy: OrganizationContactsQueryParameters.lastUsedDateTimeUtc,
      sortDesc: true
    } as OrganizationContactsQuery;

    this.bookingOrganizationContactsService
      .getOrganizationContactsByQuery(
        query,
        this.booking.organizationId
      )
      .subscribe(
        (x: Page<OrganizationContact>) => {
          this.orgContacts = x.items;

          if (this.booking?.fromBookingSearch?.country) {
            this.filteredShipperContacts = this.getContactsByCountryCode(
              this.booking.fromBookingSearch.country
            );
          }

          if (this.booking?.toBookingSearch?.country) {
            this.filteredConsigneeContacts = this.getContactsByCountryCode(
              this.booking.toBookingSearch.country
            );
          }

          if (this.shipperContact) {
            this.setSelectedContactByContactType(
              ContactType.shipper,
              this.shipperContact
            );
          }

          if (this.consigneeContact) {
            this.setSelectedContactByContactType(
              ContactType.consignee,
              this.consigneeContact
            );
          }

          if (this.notifyPartyContact) {
            this.setSelectedContactByContactType(
              ContactType.notifyParty,
              this.notifyPartyContact
            );
          }
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => { this.onSetState(); this.isLoading.emit(false); });
  }

  private getContactsByCountryCode(countryCode: string): OrganizationContact[] {
    return this.orgContacts.filter((x) => x.countryCode === countryCode);
  }

  private setSelectedContactByContactType(
    contactType: ContactType,
    contact: Contact): void {
    if (
      contactType === ContactType.shipper &&
      this.filteredShipperContacts?.length > 0
    ) {
      this.selectedOrganizationShipperContact =
        this.filteredShipperContacts.find((x) => x.id === contact.originId);
      this.isShipperContactLoading = false;
    } else if (
      contactType === ContactType.consignee &&
      this.filteredConsigneeContacts?.length > 0
    ) {
      this.selectedOrganizationConsigneeContact =
        this.filteredConsigneeContacts.find((x) => x.id === contact.originId);
      this.isConsigneeContactLoading = false;
    } else if (
      contactType === ContactType.notifyParty &&
      this.orgContacts?.length > 0
    ) {
      this.selectedOrganizationNotifyPartyContact = this.orgContacts.find(
        (x) => x.id === contact.originId
      );
      this.isNotifyPartyContactLoading = false;
    }
  }

  private selectContact(contact: OrganizationContact, contactType: ContactType): void {
    const bookingContact = this.prepareBookingContactModel(
      contact,
      contactType
    );
    this.addBookingContact(bookingContact, contactType);
  }

  private unselectContact(contactType: ContactType): void {
    const contactId = this.getBookingContactId(contactType);

    if (contactId) {
      this.deleteBookingContact(contactId, contactType);
    }
  }

  private prepareBookingContactModel(
    organizationContact: OrganizationContact,
    contactType: ContactType): BookingContactViewModel {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.address1 = organizationContact.address1;
    bookingContactViewModel.address2 = organizationContact.address2;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.state = organizationContact.state;
    bookingContactViewModel.city = organizationContact.city;
    bookingContactViewModel.zipCode = organizationContact.zipCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private addBookingContact(
    bookingContact: BookingContactViewModel,
    contactType: ContactType
  ): void {
    this.setLoaderValueByContactType(contactType);
    this.bookingContactsService.addBookingContact(bookingContact).subscribe(
      (bookingContactId: string) => {
        this.selectedContact.emit({
          bookingContactId: bookingContactId,
          contactType: contactType,
        });
      },
      (error) => {
        this.notificationService.error(error);
        this.isShipperContactLoading = false;
        this.isConsigneeContactLoading = false;
        this.isNotifyPartyContactLoading = false;
      }
    );
  }

  private setLoaderValueByContactType(contactType: ContactType): void {
    switch (contactType) {
      case ContactType.shipper:
        this.isShipperContactLoading = true;
        break;
      case ContactType.consignee:
        this.isConsigneeContactLoading = true;
        break;
      case ContactType.notifyParty:
        this.isNotifyPartyContactLoading = true;
        break;
      default:
        return null;
    }
  }

  private getBookingContactId(contactType: ContactType): string | null {
    switch (contactType) {
      case ContactType.shipper:
        return this.shipperContact?.id;
      case ContactType.consignee:
        return this.consigneeContact?.id;
      case ContactType.notifyParty:
        return this.notifyPartyContact?.id;
      default:
        return null;
    }
  }

  private deleteBookingContact(
    contactId: string,
    contactType: ContactType
  ): void {
    this.setLoaderValueByContactType(contactType);
    this.bookingContactsService.deleteBookingContact(contactId).subscribe(
      () => {
        this.unselectedContact.emit(contactType);
        this.removeSelectedBookingContact(contactType);
      },
      (error) => {
        this.notificationService.error(error);
        this.isShipperContactLoading = false;
        this.isConsigneeContactLoading = false;
        this.isNotifyPartyContactLoading = false;
      }
    );
  }

  private removeSelectedBookingContact(contactType: ContactType): void {
    switch (contactType) {
      case ContactType.shipper: {
        this.selectedOrganizationShipperContact = null;
        this.isShipperContactLoading = false;
        break;
      }
      case ContactType.consignee: {
        this.selectedOrganizationConsigneeContact = null;
        this.isConsigneeContactLoading = false;
        break;
      }
      case ContactType.notifyParty: {
        this.selectedOrganizationNotifyPartyContact = null;
        this.isNotifyPartyContactLoading = false;
        break;
      }
    }
  }
}