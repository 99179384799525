import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as uuid from 'uuid';
import { NotificationService } from '@ship4wd/ngx-common';
import { catchError, expand, tap } from "rxjs/operators";
import { EMPTY, Observable, of } from "rxjs";
import { TransactionPaymentDialogComponent } from "../../../../desktop/shipments/order/transaction-payment-dialog/transaction-payment-dialog.component";
import {
  Booking,
  BookingStatusCode,
  BookingsQuery,
  BookingsQueryParameters,
  BookingsReadMode,
  BookingsShowParameters,
  UpdateBookingIsCanceled,
} from "../../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../../shared/bookings/bookings.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import {
  BookingPaidStatusCode,
  BookingViewDetailsPage,
  CarriageStatusType,
  Page,
  ShipmentType,
} from "../../../../shared/shared.model";
import { environment } from "src/environments/environment";
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { UtilityService } from "../../../../shared/helper/utility.service";

@Component({
  selector: "app-quotes-widget",
  templateUrl: "./quotes-widget.component.html",
  styleUrls: ["./quotes-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuotesWidgetComponent implements OnInit {
  @Input() widget;
  @Input() editable: boolean;
  @Input() preview: boolean = false;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isError: boolean = false;
  isEmpty: boolean = false;
  isSnackBarOpened = false;
  bookings: Booking[] = [];
  errorMessage: string;
  loaderElementsNumber: number = 5;
  maxElementsNumber: number = 4;
  CarriageTypes = CarriageStatusType;
  bookingStatusCodes = BookingStatusCode;
  preCarriageStatuses = [
    BookingStatusCode.orderSubmitted,
  ] as BookingStatusCode[];
  mainCarriageStatuses = [BookingStatusCode.draft] as BookingStatusCode[];

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private bookingsService: BookingsService,
    public router: Router,
    private userInfoService: UserInfoService,
    private notificationService: NotificationService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    if (!this.preview) {
      this.getData();
    }
  }

  onGoTo(): void {
    this.router.navigate(["/shipments"], {
      queryParams: { show: BookingsShowParameters.draft },
    });
  }

  onRemove(): void {
    this.remove.next(this.widget.id);
  }

  onReload(): void {
    this.getData();
  }

  onEmptyAction(): void {
    this.isButtonLoading = true;
    this.router.navigate(["/quote-search"]);
  }

  onResume(booking: Booking): void {
    if (booking.statusType.code === BookingStatusCode.draft) {
      this.router.navigate(["/booking", booking.id, true]);
    } else {
      this.openTransactionPaymentDialog(booking);
    }
  }

  onShowSnack(): void {
    this.isSnackBarOpened = true;
  }

  onCloseSnack(): void {
    this.isSnackBarOpened = false;
  }

  getData(): void {
    const query = this.prepareQueryModel();

    this.isLoading = true;

    this.bookingsService.getPage(query)
      .pipe(
        expand(x => this.hasMoreBookingsToLoad(x) ? this.loadMoreBookings(x.pageNo) : this.stopLoadBookings()),
        tap((bookings: Page<Booking>) => {
          const paymentsItems = bookings.items.filter(booking => booking.isCanceled == false);

          this.bookings.push(...paymentsItems);

          if (this.bookings?.length === 0) {
            this.isEmpty = true;
          }

          if (bookings.pageNo == 1) this.isLoading = false
        }),
        catchError((error) => {
          this.isError = true;
          this.errorMessage = error;

          return of([])
        })
      )
      .subscribe()
      .add(() => this.isLoading = false);
  }

  displayStatusIcon(
    booking: Booking,
    carriageType: CarriageStatusType
  ): boolean {
    if (this.mainCarriageStatuses.includes(booking.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(booking.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return booking.statusType.carriageType === carriageType;
  }

  getMainCarriageIcon(booking: Booking): string {
    switch (booking.shipmentTypeCode) {
      case ShipmentType.AIR:
        return "local_airport";

      case ShipmentType.FCL:
        return "shipping-type-fcl";

      case ShipmentType.LCL:
        return "shipping-type-lcl";

      default:
        return "directions_boat";
    }
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getIconColor(booking: Booking): string {
    if (booking.isCanceled === true) {
      return "is-canceled";
    } else if (
      booking.statusType.code === BookingStatusCode.bookedWithCarrier
    ) {
      return "booked-with-carrier";
    } else if (booking.statusType.code === BookingStatusCode.delivered) {
      return "delivered";
    } else {
      return "order-status";
    }
  }

  getExpirationTime(booking: Booking): string {
    const currentTime = new Date();
    const createTime = new Date(booking.createTimeUtc);
    const expirationTime = new Date(createTime);

    expirationTime.setDate(createTime.getDate() + this.getEnviromentBookingExpirationDays(booking.shipmentTypeCode));

    const differenceInSeconds = (expirationTime.getTime() - currentTime.getTime()) / 1000;

    if (differenceInSeconds <= 0) {
      return "Expired";
    }

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;

    if (differenceInSeconds < secondsInMinute) {
      return `${Math.round(differenceInSeconds)}s left`;
    } else if (differenceInSeconds < secondsInHour) {
      return `${Math.round(differenceInSeconds / secondsInMinute)}m left`;
    } else if (differenceInSeconds < secondsInDay) {
      return `${Math.round(differenceInSeconds / secondsInHour)}h left`;
    } else {
      return `${Math.round(differenceInSeconds / secondsInDay)}d left`;
    }
  }

  range(value): number[] {
    return Array.from({ length: value }, (v, k) => k + 1);
  }

  getBookingButtonText(booking: Booking) {
    if (booking.statusType.code === BookingStatusCode.draft) {
      return "Resume Order";
    }
    else {
      return "Pay Order";
    }
  }

  isShowBookingButton(booking: Booking): boolean {
    return booking.statusType.code === BookingStatusCode.draft ? true : this.checkIsAgent();
  }

  checkIsAgent(): boolean {
    return !this.userInfoService.checkIsAgent();
  }

  onOrderDetails(id: string): void {
    this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, id]);
  }

  onCancelOrder(booking: Booking): void {
    this.cancelBooking(booking);
  }

  private cancelBooking(booking: Booking): void {
    const updateBookingIsCanceledModel = this.prepareUpdateBookingIsCanceled(booking);

    this.isLoading = true;
    this.bookingsService
      .updateBookingIsCanceled(booking.id, updateBookingIsCanceledModel)
      .subscribe((x: Booking) => {
        this.bookings = [];
        this.notificationService.success('Order has been cancelled successfully');
        this.getData();
      },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  private prepareUpdateBookingIsCanceled(booking: Booking): UpdateBookingIsCanceled {
    const updateBookingIsCanceled = new UpdateBookingIsCanceled();
    updateBookingIsCanceled.isCanceled = true;
    updateBookingIsCanceled.userId = uuid.v4();
    updateBookingIsCanceled.organizationId = booking.organizationId;
    updateBookingIsCanceled.isAgent = !booking.isAgentLocked;
    return updateBookingIsCanceled;
  }

  private getEnviromentBookingExpirationDays(shipmentType: ShipmentType): number {
    switch (shipmentType) {
      case ShipmentType.AIR:
        return environment.air.bookingExpirationDays;
      case ShipmentType.FCL:
        return environment.fcl.bookingExpirationDays;
      case ShipmentType.LCL:
        return environment.lcl.bookingExpirationDays;
      default:
        return 0;
    }
  }

  private prepareQueryModel(): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = 1;
    query.pageSize = 10;
    query.sortBy = BookingsQueryParameters.createTimeUtc;
    query.sortDesc = true;
    query.readMode = BookingsReadMode.appDashboard;
    query.any = false;
    query.paidStatusCodes = [BookingPaidStatusCode.unPaid, BookingPaidStatusCode.partialyPaid];
    query.isCanceled = false;

    return query;
  }

  private openTransactionPaymentDialog(booking: Booking): void {
    const dialogRef = this.dialog.open(TransactionPaymentDialogComponent, {
      panelClass: "transaction-payment",
      autoFocus: false,
      data: {
        booking: booking,
      },
    });

    dialogRef.afterClosed().subscribe(() => { });
  }

  private hasMoreBookingsToLoad(response: Page<Booking>): boolean {
    if (!response || response.totalCount === 0 || !response.items) {
      return false;
    }

    return this.hasValidItem(response.items);
  }

  private hasValidItem(items: Booking[] | null): boolean {
    return items != null && items.length > 0 && items[0].id !== uuid.NIL;
  }

  private loadMoreBookings(responsePageNo: number): Observable<Page<Booking>> {
    const query = this.prepareQueryModel()
    query.pageNo = responsePageNo + 1;
    return this.bookingsService.getPage(query);
  }

  private stopLoadBookings(): Observable<never> {
    this.isLoading = false;
    return EMPTY;
  }
}
