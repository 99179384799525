import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShipmentType } from '../../../../shared/shared.model';

@Component({
  selector: 'app-quote-search-flow-header',
  templateUrl: './quote-search-flow-header.component.html',
  styleUrls: ['./quote-search-flow-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowHeaderComponent {
  @Input() shippingTypes: any[] = [];
  @Input() quoteSearchForm: FormGroup = undefined;

  @Output() shipmentTypeChange = new EventEmitter<any>();

  constructor() { }

  onChangeShippingType(type: ShipmentType): void {
    this.shipmentTypeChange.emit(type);
  }
}
