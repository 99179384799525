import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Variant } from '@amplitude/experiment-js-client';
import { ShipmentType } from '../../../../shared/shared.model';
import { AmplitudeService } from '../../../../shared/amplitude/amplitude.service';
import { amplitudeFlags } from '../../../../shared/amplitude/amplitude.constants';
import { SupportRequestsService } from '../../../../desktop/support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../../../desktop/support-requests/support-requests.model';

@Component({
  selector: 'app-quote-search-flow-header',
  templateUrl: './quote-search-flow-header.component.html',
  styleUrls: ['./quote-search-flow-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowHeaderComponent implements OnInit, OnChanges {
  @Input() shippingTypes: any[] = [];
  @Input() quoteSearchForm: FormGroup = undefined;
  @Input() isCompact: boolean = false;
  @Input() quoteSearchId: string;

  @Output() shipmentTypeChange = new EventEmitter<any>();

  variant: Variant;
  isOldSearchStyle: boolean = true;

  constructor(private amplitudeService: AmplitudeService,
    private supportRequestsService: SupportRequestsService) { }

  ngOnInit(): void {
    this.variant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchFlagKey);
    this.isOldSearchStyle = this.amplitudeService.checkQuoteSearchStyle(this.variant);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.quoteSearchId?.currentValue) {
      this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData(false))
    }
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onChangeShippingType(type: ShipmentType): void {
    this.shipmentTypeChange.emit(type);
  }

  private createSupportRequestDialogData(isPopupAutoOpened: boolean): SupportRequestDialogData {
    return {
      isPopupAutoOpened: isPopupAutoOpened,
      supportRequestType: SupportRequestTypeCode.general,
      bolOrderNumber: null,
      quoteSearchId: this.quoteSearchId
    } as SupportRequestDialogData
  }
}
