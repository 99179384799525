<div class="row payment-line">
  <div class="col">
    <div class="row">
      <div class="ps-0 col-12">
        <span class="payment-amount">{{
          totalAmount | currency : "USD" : true : "1.0-0"
          }}</span>
      </div>
      <div class="ps-0 col-12">
        <span class="payment-reason">{{ payment?.reasonDisplayName }}</span>
      </div>
      <div class="ps-0 col-12">
        <span class="payment-status" [ngClass]="getIconColor()">{{
          payment?.statusDisplayName
          }}</span>
      </div>
    </div>
  </div>
  <div class="col-auto payment-actions">
    <span class="arrow-back-icon rotate" (click)="toPayment()"></span>
  </div>
</div>
