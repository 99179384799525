<div class="booking-flow-sidebar" *ngIf="bookingSummary">
  <div class="booking-flow-sidebar-header">
    <div class="row m-0 p-0 justify-content-end">
      <div class="col-auto p-0 fcl-label">
        <div class="quotes-right-label">
          <i class="shipping-type-icon s4d-icon" [ngClass]="getShipmentIcon()"></i>
          <span class="label-text"> {{ bookingSummary?.shipmentTypeCode | shipmentTypeCargoName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div #scrollableDiv class="booking-flow-sidebar-content">
    <div class="cities-container">
      <div class="cities-wrapper"
        *ngFor="let location of uniqueLocations; let i = index; let isFirst = first; let isLast = last">
        <div class="row cities" [ngClass]="location.date || isFirst || isLast ? '' : 'empty-date'">
          <div class="col-auto px-0 d-flex align-items-start">
            <div class="haulage-icon-wrapper">
              <i class="haulage-icon s4d-icon" [ngClass]="getLocationTypeIcon(location.type, isFirst)"></i>
              <div class="lines" [ngClass]="{'d-none': isLast}"></div>
            </div>
          </div>
          <div class="col ps-3 pe-0 d-flex align-items-center">
            <div class="col-12 px-0">
              <span class="city-name">
                {{ location.name }}
              </span>
              <span class="city-date">
                <ng-container *ngIf="isFirst || isLast || location.date">
                  {{checkDateNotNullOrEmpty(location.date) ?
                  (location.date | date: "MMM dd") : "Various Time Options" }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="summary-divider"></div>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" (afterCollapse)="checkScrollBar()"
      (afterExpand)="checkScrollBar()">
      <mat-expansion-panel-header class="py-3">
        <mat-panel-title class="booking-section-header">
          Base services
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mx-0 mb-3">
        <div class="col-8 p-0 detail-subtitle">International Freight</div>
        <div class="col-4 p-0 d-flex flex-column align-items-end">
          <span class="detail-subtitle-value" appSetRoleBasedPrice
            [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(mainCarriageTotal)}">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon
            }}{{ mainCarriageTotal | number : "1.2-2" }}
          </span>
          <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
            *ngIf="isShowReverseDiscountPrice(mainCarriageTotal)">
            {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(mainCarriageTotal) | number: "1.2-2" }}
          </span>
        </div>
      </div>
      <div class="row mx-0 mb-3" *ngIf="bookingSummary.quote.trip.preCarriageTripLeg">
        <div class="col p-0 detail-subtitle text-capitalize">{{ bookingSummary.quote?.trip?.fromLocationTypeCode |
          locationTypeShortName }} Pickup</div>
        <div appSetRoleBasedPrice class="col-auto p-0 detail-subtitle-value">
          <span *ngIf="!bookingSummary.quote.trip.isPreCarriageMissingRate; else beingCalculated">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon
            }}{{ preCarriageTotal | number : "1.2-2" }}</span>
        </div>
      </div>
      <div class="row mx-0 mb-3" *ngIf="bookingSummary.quote.trip.postCarriageTripLeg">
        <div class="col p-0 detail-subtitle text-capitalize">{{ bookingSummary.quote?.trip?.toLocationTypeCode |
          locationTypeShortName }} Delivery</div>
        <div appSetRoleBasedPrice class="col-auto p-0 detail-subtitle-value">
          <span *ngIf="!bookingSummary.quote.trip.isPostCarriageMissingRate; else beingCalculated">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon
            }}{{ postCarriageTotal | number : "1.2-2" }}
          </span>
        </div>
      </div>
      <div button class="col-12 px-0 show-details" (click)="onShowDetails()">Show details</div>
    </mat-expansion-panel>
    <div class="summary-divider"></div>
    <div class="additional-service-content-wrapper">
      <mat-expansion-panel class="mat-elevation-z0 additional-service-content" [expanded]="true"
        *ngIf="bookingSummary?.bookingAdditionalServiceCharges.length > 0 && isAdditionalServicesEnabled"
        (afterCollapse)="checkScrollBar()" (afterExpand)="checkScrollBar()">
        <mat-expansion-panel-header class="py-3">
          <mat-panel-title class="booking-section-header">
            Additional Services
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row m-0 title-value-wrapper"
          *ngFor="let bookingAdditionalServiceCharge of bookingSummary?.bookingAdditionalServiceCharges">
          <div class="row m-0 p-0">
            <div class="col-8 p-0 detail-subtitle">{{ bookingAdditionalServiceCharge?.additionalServiceCharge?.name }}
            </div>
            <div class="col-4 p-0 detail-subtitle-value"
              *ngIf="!getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
              {{ bookingSummary.quote.trip.currency | currencyTextIcon }}
              {{ bookingAdditionalServiceCharge?.price | number : "1.2-2" }}
            </div>
            <div class="col-4 p-0 detail-subtitle-value detail-subtitle-value-manual"
              *ngIf="getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
              <span matTooltipPosition="above" matTooltipClass="additional-service-manually-tooltip"
                [matTooltip]="getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
                Post-Booking
              </span>
            </div>
          </div>
          <div class="row m-0 p-0" *ngIf="getAdditionalServiceDescription(bookingAdditionalServiceCharge)">
            <div class="col-8 p-0 detail-subtitle"></div>
            <div class="col-4 p-0 detail-subtitle-value">
              <span class="additional" matTooltipClass="additional-service-price-tooltip"
                [matTooltip]="getAdditionalServiceTooltipText(bookingAdditionalServiceCharge)">{{
                getAdditionalServiceDescription(bookingAdditionalServiceCharge) }}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="summary-divider"></div>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" (afterCollapse)="checkScrollBar()"
      (afterExpand)="checkScrollBar()">
      <mat-expansion-panel-header class="py-3">
        <mat-panel-title class="booking-section-header">
          Cargo details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="bookingSummary.shipmentTypeCode === ShipmentTypes.FCL">
        <div class="row  subtitle-value-wrapper mx-0"
          [ngClass]="{ 'py-3': i !== 1 && i !== groupedContainers.length - 1 }"
          *ngFor="let equipment of groupedContainers; let i = index">
          <div class="col-8 p-0 detail-subtitle">
            {{ equipment.description }}
          </div>
          <div class="col-4 p-0 detail-subtitle-value">
            x{{ equipment.count }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="bookingSummary.shipmentTypeCode !== ShipmentTypes.FCL">
        <ng-container
          *ngIf="bookingSummary.shipmentTypeCode === ShipmentTypes.AIR || bookingSummary.shipmentTypeCode === ShipmentTypes.LCL">
          <div class="row m-0">
            <div class="col-6 p-0 detail-subtitle">
              {{ "Chargeable Weight" }}
            </div>
            <span class="col p-0 detail-subtitle-value">
              {{ grandTotalChargeableWeightAmount | number : "1.2-2" }}
              {{ grandTotalWeightUnit | weightUnitName | uppercase }}
            </span>
          </div>
        </ng-container>
        <div class="row m-0 pt-3">
          <div class="col-6 p-0 detail-subtitle">
            {{ "Total Weight" }}
          </div>
          <div class="col-6 p-0 detail-subtitle-value">
            {{ grandTotalWeightAmount | number : "1.2-2" }}
            {{ grandTotalWeightUnit | weightUnitName | uppercase }}
          </div>
        </div>
        <div class="row m-0 pt-3">
          <div class="col-8 p-0 detail-subtitle">
            {{
            bookingSummary.shipmentTypeCode === ShipmentTypes.AIR
            ? "Volume"
            : "Total Volume"
            }}
          </div>
          <div class="col-4 p-0 detail-subtitle-value">
            {{ grandTotalVolumeAmount | number : "1.2-2" }}
            {{ grandTotalVolumeUnit | volumeUnitName | uppercase }}
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
    <div class="summary-divider"></div>
  </div>
  <div class="total-button-wrapper" [ngClass]="hasScroll ? 'has-scroll' : ''">
    <div class="total-cost-wrapper">
      <div class="row m-0">
        <div class="col p-0 detail-subtitle submit">Total Cost</div>
        <div class="col p-0 align-items-end d-flex flex-column">
          <span appSetRoleBasedPrice class="detail-subtitle-value submit"
            [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(bookingSummary.totalPrice)}">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon }}{{ bookingSummary.totalPrice | number : "1.2-2"
            }}
            {{ bookingSummary.quote.trip.currency | currencyName}}
          </span>
          <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
            *ngIf="isShowReverseDiscountPrice(bookingSummary.totalPrice)">
            {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(bookingSummary.totalPrice) | number:
            "1.2-2" }} {{ quote.currency | currencyName}}
          </span>
        </div>
      </div>
    </div>
    <div class="row m-0 justify-content-center booking-button-wrapper">
      <div class="col-auto w-100 px-0">
        <button mat-raised-button class="booking-pay-button button-spinner" (click)="onReviewAndPay()"
          [disabled]="isLoading || !isValid">
          <span class="button-inner">
            Review {{ !isCreditOrganization && !isAgent ? "& Pay" : "" }}
            <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
    <div class="options-wrapper">
      <span class="option-text">OR</span>
    </div>
    <div class="col-auto ps-0 booking-assistance-button-wrapper" *ngIf="isLiveAssistancePopupEnabled">
      <button class="booking-assistance-button" (click)="openSupportDialog(false)">
        Book with an Expert
      </button>
    </div>
  </div>
</div>

<ng-template #beingCalculated>
  <span class="being-calculated">Being calculated</span>
</ng-template>