import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import {
  Booking,
  BookingPdfRequest,
  BookingStatusCode,
  BookingSummary,
  Commodity,
  Contact
} from '../../../../shared/bookings/bookings.model';
import {
  ExtenderTrip,
  IncotermCode,
  ShipmentType,
  BookingFilesDocumentsPage,
  CompletedOrderStatusType,
  BookingViewDetailsPage
} from '../../../../shared/shared.model';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { Router } from '@angular/router';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { RequestAndPayment } from '../../../../shared/requests-and-payments/requests-and-payments.model';
import { RequestAndPaymentService } from '../../../../shared/requests-and-payments/requests-and-payments.service';
import { AdditionalService, AdditionalServiceType } from '../../../../shared/additional-services/additional-services.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {
  @Input() booking: Booking;
  @Input() bookingSummary: BookingSummary;
  shipmentTypes = ShipmentType;
  @Input() isViewOnly = false;
  @Input() isEditBooking = false;
  @Input() bookingAdditionalServices: AdditionalService[] = [];
  @Input() pageType: BookingViewDetailsPage = BookingViewDetailsPage.dashboard;
  @Output() viewBookingEvent = new EventEmitter<boolean>();

  requestsAndPayments: RequestAndPayment[];
  totalAmount: number;

  isFileReady: boolean = true;
  isShowCollectContact = false;
  isShowDropContact = false;
  allowedRebook: boolean = false;
  additionalServiceType = AdditionalServiceType;
  isAdditionalServicesEnabled: boolean;

  constructor(private router: Router,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private requestAndPaymentService: RequestAndPaymentService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.viewBookingEvent.emit(this.isViewOnly);
    if (this.booking?.serviceType > 0 || this.bookingSummary?.serviceType > 0) {
      this.isShowCollectContact = false;
      this.isShowDropContact = false;
      const doorPickup = this.utilityService.isDoorPickupService(this.booking?.serviceType ?? this.bookingSummary.serviceType);
      const doordelivery = this.utilityService.isDoorDeliveryService(this.booking?.serviceType ?? this.bookingSummary.serviceType);
      if (doorPickup)
        this.isShowCollectContact = true;
      if (doordelivery)
        this.isShowDropContact = true;
    }

    if (this.bookingSummary?.hasPayments) {
      this.getRequestsAndPaymentsByBookingId(this.bookingSummary.id);
    }

    this.allowedRebook = (this.bookingSummary?.statusType.code != BookingStatusCode.draft ||
      this.getcompletedOrderStatusType().includes(this.bookingSummary?.statusType.code)) && !this.bookingSummary?.isCanceled;

    this.isAdditionalServicesEnabled = this.getIsAdditionalServicesEnabled();
  }

  renderContactData(contact: Contact) {
    let data = '';
    if (contact) {
      data = this.appendContactData(data, contact.companyName);
      data = this.appendContactData(data, contact.address1);
      data = this.appendContactData(data, contact.address2);
      data = this.appendContactData(data, contact.city);
      data = this.appendContactData(data, contact.zipCode);
      data = this.appendContactData(data, contact.state);
      data = this.appendContactData(data, contact.countryCode);
    }
    return data;
  }

  onClose() {
    this.navigateToBookingsDashboard();
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip) {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.preCarriageTripLeg);
    }
    if (trip?.mainCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.mainCarriageTripLeg);
    }
    if (trip?.postCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.postCarriageTripLeg);
    }
    return carriageTripLegs;
  }

  getIncotermsCodes() {
    if (this.bookingSummary?.quote?.trip?.incoterms?.length > 0) {
      let incotermCodes = '';
      this.bookingSummary.quote.trip.incoterms.forEach((x) => {
        incotermCodes += IncotermCode[x] + ', ';
      });
      return incotermCodes.trim().slice(0, -1);
    }
    return 'n/a';
  }

  print(id: string) {
    window.open(`/booking/pdf/${id}?mode=print`, '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
  }

  reuse(id: string): void {
    if (this.allowedRebook) {
      this.router.navigate(['/quote-search'], { queryParams: { reusedBookingId: id } });
    }
  }

  download(id: string) {
    this.isFileReady = false;

    const bookingPdfRequest = new BookingPdfRequest();
    bookingPdfRequest.bookingId = id;
    bookingPdfRequest.token = window.localStorage.getItem("accessToken");

    this.bookingsService.getPdfUrl(bookingPdfRequest).subscribe(
      (url: URL) => {
        if (url) {
          window.open(url.toString());
        }
      },
      (error) => {
        this.notificationService.error(error);
        this.isFileReady = true;
      },
      () => {
        this.isFileReady = true;
      }
    );
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  navigateToFilesDocuments(bookingId: string) {
    this.router.navigate(['/files-documents',this.pageType, BookingFilesDocumentsPage.orderDetails, bookingId]);
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }

  private getRequestsAndPaymentsByBookingId(bookingId: string): void {
    this.requestAndPaymentService.getAllByBookingId(bookingId).subscribe(
      (x: RequestAndPayment[]) => {
        this.requestsAndPayments = x;
        this.totalAmount = this.requestsAndPayments.reduce((sum, rp) => sum + rp.amount + rp.fees, 0);
      },
      (error) => { }
    );
  }

  private getcompletedOrderStatusType() {
    return CompletedOrderStatusType;
  }

  private navigateToBookingsDashboard() {
    this.router.navigate(['/bookings/dashboard']);
  }

  private appendContactData(data: string, value: string) {
    if (value) {
      if (data !== '') {
        data += ', ';
      }
      data += value;
    }
    return data;
  }

  private getIsAdditionalServicesEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isAdditionalServicesEnabled;
      case 'sb':
        return environment.sb.isAdditionalServicesEnabled;
      default:
        return environment.isAdditionalServicesEnabled;
    }
  }
}
