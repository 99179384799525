import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { EncodeUrlCodec } from '../../shared/services/encode-url-codec';
import { Incoterm, IncotermsMappingsQuery } from './quotes.model';

@Injectable()
export class QuotesIncotermService {
  constructor(private internalUrlsService: InternalUrlsService, private http: HttpClient) {}

  getIncotremsByLocationTypes(query: IncotermsMappingsQuery): Observable<Incoterm[]> {
    const params = new HttpParams({
      fromObject: query as any,
      encoder: new EncodeUrlCodec()
    });
    return this.http.get<Incoterm[]>(`${this.internalUrlsService.getApiBaseUrl()}/incoterms/incotermsByLocationTypes`, {
      params
    });
  }
}
