import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Inject } from '@angular/core';
import { NotificationService } from '@ship4wd/ngx-common';
import { BookingFlowService } from '../../booking-flow.service';
import {
  AdditionalService,
  AdditionalServiceType,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges,
} from '../../../../../shared/additional-services/additional-services.model';
import { AdditionalRate, CustomsChargeCodes, VendorsCode } from '../../../../../shared/shared.model';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { ClearItChargeCodeGroup } from '../../../../../shared/customs/customs.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';

@Component({
  selector: 'app-additional-service-customs-manually',
  templateUrl: './additional-service-customs-manually.component.html',
  styleUrls: ['./additional-service-customs-manually.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServiceCustomsManuallyComponent implements OnInit {
  additionalRates: AdditionalRate[];
  additionalService: AdditionalService;
  bookingId: string;
  booking: Booking;
  @Output() closeDialog = new EventEmitter<any>();

  vendorsCodes = VendorsCode;
  customsChargeCodes = CustomsChargeCodes;
  isLoading: boolean = false;
  isConfirmDisable: boolean = false;
  singleEntryBoundShipmentLoader: boolean = false;
  clearItChargeCodeGroup = ClearItChargeCodeGroup;
  recommendedTooltipText: string = `Shipment can't leave the origin without
                            filling this as regulated by the government.`;
  selectedAdditionalServicesFeaturesPriceSum: number = 0;
  isServiceAlreadyAdded = false;

  constructor(
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService,
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.booking = data.booking;
    this.bookingId = data.bookingId;
    this.additionalRates = data.additionalRates;
    this.isServiceAlreadyAdded = data.isServiceAlreadyAdded;
    this.additionalService = data.additionalService
  }

  ngOnInit(): void { }

  onSubmit(): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges(this.additionalService);

    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: AdditionalServiceType.customsManually,
      charges: charges
    } as CreateBookingAdditionalServiceCharges;

    this.saveBookingCustomAdditionalServiceCharges(createBookingAdditionalServiceChargesModel);
  }

  onRemove(): void {
    this.dialogRef.close({
      isRemove: true
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getAdditionalServiceManuallyDescription(additionalService: AdditionalService): string {
    switch (additionalService.id) {
      case AdditionalServiceType.customsManually:
      case AdditionalServiceType.insuranceManually:
        return `Our customer service team will contact
        you after booking to finalize this pricing
         and provide further details.`;
      default:
        return null;
    }
  }

  private saveBookingCustomAdditionalServiceCharges(model: CreateBookingAdditionalServiceCharges): void {
    this.isLoading = true;
    this.bookingFlowService.addAdditionalServices(this.bookingId, model)
      .subscribe(() => {
        this.dialogRef.close({
          isBookingAdditionalServiceUpdated: true,
          additonalServiceType: AdditionalServiceType.customsManually
        });
      },
        (error: any) => this.notificationService.error(error),
      ).add(() => {
        this.isLoading = false;
      });
  }

  private prepareCreateBookingAdditionalServiceCharges(additionalService: AdditionalService)
    : CreateBookingAdditionalServiceCharge[] {
    let charges = new Array<CreateBookingAdditionalServiceCharge>();

    additionalService.surcharges.forEach(x => {
      const charge = this.prepareCreateBookingAdditionalServiceCharge(x);
      charges.push(charge);
    })

    return charges;
  }

  private prepareCreateBookingAdditionalServiceCharge(additionalRate: AdditionalRate)
    : CreateBookingAdditionalServiceCharge {
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate ? additionalRate.additionalType : null,
      vendorCode: VendorsCode.default,
      price: additionalRate.shipmentRate.totalAmount,
      pricePer: additionalRate.shipmentRate.pricePer,
      currencyCode: additionalRate.shipmentRate.currency
    } as CreateBookingAdditionalServiceCharge;
  }
}
