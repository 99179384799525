import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { LayoutService } from '../../../../layout/layout.service';
import { BookingOrganizationContactsService } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { environment } from '../../../../../../environments/environment';
import {
  OrganizationContact, OrganizationContactAction, OrganizationContactsQuery,
  OrganizationContactsQueryParameters
} from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { Booking, ContactType } from '../../../../../shared/bookings/bookings.model';
import { BookingContactsService } from '../../../../../shared/bookings/booking-contacts/booking-contacts.service';
import { Page } from '../../../../../shared/shared.model';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { BookingContactViewModel } from '../../../../../shared/bookings/booking-contacts/booking-contacts.model';
import { RemoveConfirmDialogComponent } from '../../../../../shared/remove-confirm-dialog/remove-confirm-dialog.component';

@Component({
  selector: 'app-booking-organization-contacts',
  templateUrl: './booking-organization-contacts.component.html',
  styleUrls: ['./booking-organization-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingOrganizationContactsComponent implements OnInit, AfterViewInit {
  @Input() booking: Booking;
  @Input() contactType: ContactType;
  @Input() backTitle: string = "Back to Booking";
  @Input() containerId: string | null = null;
  @Output() BackToBooking = new EventEmitter();
  @Output() selectedContact = new EventEmitter();

  organizationContacts: OrganizationContact[];
  isLoading = false;
  isOpenNewContact = false;
  selectedContactTitle: string;
  organizationContact: OrganizationContact;
  organizationContactAction: OrganizationContactAction = OrganizationContactAction.new;
  isSelectLoading = false;
  hideUnsupportedIcons: boolean;
  contactCountryCode: string = null;

  constructor(
    private layoutService: LayoutService,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private bookingContactsService: BookingContactsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.selectedContactTitle = this.getContactTitle();
    this.contactCountryCode = this.getContactCountryCode();
    this.getOrganizationContacts(this.contactCountryCode);
    this.hideUnsupportedIcons = environment.hideUnsupportedIcons;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeader();
    }, 0);
  }

  getInitialName(contact: OrganizationContact): string {
    if (contact?.firstName && contact?.lastName) {
      return contact.firstName.charAt(0).toUpperCase() + contact.lastName.charAt(0).toUpperCase();
    }
    return '';
  }

  onBackToBooking(): void {
    this.BackToBooking.emit();
  }

  onOpenNewContact(): void {
    this.openNewContactPage(true);
  }

  onSelectedContact(selectedContact: any): void {
    this.openNewContactPage(false);
    this.selectedContact.emit(selectedContact);
  }

  onSelectContact(organizationContact: OrganizationContact) {
    if (organizationContact) {
      if (this.contactType === ContactType.shipper) {
        let from = this.booking.fromBookingSearch;

        if (from.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${from.countryName}`,
              showConfirmButton: true,
              confirmButtonText: "Ok"
            },
          });

          return;
        }
      }
      else if (this.contactType === ContactType.consignee) {
        let to = this.booking.toBookingSearch;

        if (to.country.toUpperCase().trim() !== organizationContact.countryCode.toUpperCase().trim()) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              message: `The contact country for "${ContactType[this.contactType]}" should be the same as stated in the booking ${to.countryName}`,
              showConfirmButton: true,
              confirmButtonText: "Ok"
            },
          });

          return;
        }
      }

      organizationContact.organizationId = this.booking.organizationId;
      this.setBookingContact(organizationContact);
    }
  }

  onViewContact(organizationContact: OrganizationContact): void {
    this.prePareContactPage(organizationContact, OrganizationContactAction.view);
  }

  onUpdateContact(organizationContact: OrganizationContact): void {
    this.prePareContactPage(organizationContact, OrganizationContactAction.edit);
  }

  onDeleteContact(contact: OrganizationContact): void {
    const dialogRef = this.dialog.open(RemoveConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data:{
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Remove'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {

        this.deleteOrganizationContact(contact);
      }
    });
  }

  onBackToContact(): void {
    this.openNewContactPage(false);
    this.organizationContactAction = OrganizationContactAction.new;
    this.setHeader();
  }

  private setHeader(): void {
    this.layoutService.setToolbarTitle("Select Contact");
    this.layoutService.setToolbarDescription(`Select ${this.selectedContactTitle} contact or create new`);
  }

  private getOrganizationContacts(countryCode: string): void {
    const query = {
      pageNo: 1,
      pageSize: 50,
      sortBy: OrganizationContactsQueryParameters.lastUsedDateTimeUtc,
      sortDesc: true
    } as OrganizationContactsQuery;

    if (countryCode != null) {
      query.countryCode = countryCode;
    }

    this.isLoading = true;
    this.bookingOrganizationContactsService
      .getOrganizationContactsByQuery(query, this.booking.organizationId)
      .subscribe((x: Page<OrganizationContact>) => {
        this.organizationContacts = x.items;
      },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private openNewContactPage(isOpen: boolean): void {
    this.isOpenNewContact = isOpen;
  }

  private prePareContactPage(organizationContact: OrganizationContact,
    organizationContactAction: OrganizationContactAction): void {
    this.openNewContactPage(true);
    this.organizationContact = organizationContact;
    this.organizationContactAction = organizationContactAction;
  }

  private setBookingContact(organizationContact: OrganizationContact): void {
    const bookingContact = this.prepareBookingContactModel(organizationContact);
    if (this.contactType === ContactType.collect || this.contactType === ContactType.drop) {
      this.selectedContact.emit({
        bookingContact: bookingContact,
        contactType: this.contactType,
        containerId: this.containerId
      });
    }
    else {
      this.addBookingContact(bookingContact);
    }
  }

  private prepareBookingContactModel(organizationContact: OrganizationContact): BookingContactViewModel {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = this.contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.address1 = organizationContact.address1;
    bookingContactViewModel.address2 = organizationContact.address2;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.state = organizationContact.state;
    bookingContactViewModel.city = organizationContact.city;
    bookingContactViewModel.zipCode = organizationContact.zipCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private addBookingContact(bookingContact: BookingContactViewModel): void {
    this.isSelectLoading = true;
    this.bookingContactsService
      .addBookingContact(bookingContact)
      .subscribe(
        (bookingContactId: string) => {
          this.selectedContact.emit({
            bookingContactId: bookingContactId,
            contactType: this.contactType
          });
        },
        error => this.notificationService.error(error))
      .add(() => this.isSelectLoading = false);
  }

  private getContactTitle(): string {
    switch (this.contactType) {
      case ContactType.notifyParty:
        return 'Notify Party'
      case ContactType.consignee:
        return 'Consignee'
      case ContactType.shipper:
        return 'Shipper'
      default:
        return null;
    }
  }

  private getContactCountryCode(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.country;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.country;
      default:
        return null;
    }
  }

  private deleteOrganizationContact(organizationContact: OrganizationContact): void {
    this.isSelectLoading = true;
    this.bookingOrganizationContactsService
      .deleteOrganizationContact(organizationContact.organizationId, organizationContact.id)
      .subscribe(
        () => {
          this.getOrganizationContacts(this.contactCountryCode);
        },
        error => this.notificationService.error(error))
      .add(() => this.isSelectLoading = false);
  }
}
