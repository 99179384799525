import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountsService } from '../../shared/accounts/accounts.service';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { HttpService } from '../../shared/services/http/http.service';
import { FourtySeasPaymentService } from '../../shared/payments/fourty-seas-payment.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html'
})
export class FinanceComponent implements OnInit {
  isLoading = true;
  data: any;
  organizationId: string = null;
  filters: any;
  searchText: string;
  isClearFilters: boolean;
  isOrdersGridViewMode: boolean = true;

  get isOrganizationLoaded() {
    return this.organizationId != null;
  }

  constructor(
    public http: HttpService,
    public dialog: MatDialog,
    public router: Router,
    public settingsService: AppSettingsService,
    public accountsService: AccountsService,
    private fourtySeasPaymentService : FourtySeasPaymentService
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.subscribePaymentLoader();
  }

  onLoadingChange(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  onFiltersEvent(filters: any): void {
    this.filters = filters;
    this.isLoading = true;
    this.isClearFilters = false;
  }

  onSearchText(search: string): void {
    this.searchText = search;
  }

  onClearFilters(clearFilters: boolean): void {
    this.isLoading = true;
    this.isClearFilters = clearFilters;
  }

  private subscribePaymentLoader(){
    this.fourtySeasPaymentService.paymentLoaderBehaviorSubject.subscribe((value: boolean | undefined) => {
      if(value !== undefined){
        this.isLoading = value;
      }
    })
  }
}
