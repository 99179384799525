import { Component, Input } from '@angular/core';
import { PersonalNotificationType } from '../personal-notification.model';

@Component({
  selector: 'app-personal-notification-dot',
  templateUrl: './personal-notification-dot.component.html',
  styleUrls: ['./personal-notification-dot.component.scss']
})
export class PersonalNotificationDotComponent {
  @Input() showDot: boolean = true;
  @Input() notificationType: PersonalNotificationType = null;

  notificationTypes = PersonalNotificationType;
}
