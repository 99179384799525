export enum amplitudeFlags {
  quoteSearchFlagKey = 'quote-search-flow',
  quoteSearchRowFlagKey = 'quote-search-row',
  isWhatsAppEnabledFlagKey = 'is-whatsapp-enabled',
  quoteSearchRowDetailsFlagKey = 'quote-search-row-details',
  quoteSearchRowPriceBreakdownFlagKey = 'quote-search-row-price-breakdown',
  quoteBadge = 'quote-badge',
  recentQuotesWidget = 'recent-quotes-widget',
  heapService = 'heap-service',
  isQuotesBannerFlagKey = 'is-quotes-banner-enabled',
}

export const amplitudeFlagVariants = {
  [amplitudeFlags.quoteSearchRowFlagKey]: {
    newRowStyle: 'new-row-style',
    oldRowStyle: 'old-row-style'
  },
  [amplitudeFlags.quoteSearchFlagKey]: {
    newSearchStyle: 'new-search-style',
    oldSearchStyle: 'old-search-style'
  },
  [amplitudeFlags.isWhatsAppEnabledFlagKey]: {
    whatsAppEnabled: 'whatsapp-enabled',
    whatsAppDisabled: 'whatsapp-disabled'
  },
  [amplitudeFlags.quoteSearchRowDetailsFlagKey]: {
    newRowDetailsStyle: 'new-row-details-style',
    oldRowDetailsStyle: 'old-row-details-style'
  },
  [amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey]: {
    newRowPriceStyle: 'new-row-price-style',
    oldRowPriceStyle: 'old-row-price-style'
  },
  [amplitudeFlags.quoteBadge]: {
    withQuoteBadgeStyle: 'with-quote-badge-style',
    withoutRowPriceStyle: 'without-quote-badge-style'
  },
  [amplitudeFlags.recentQuotesWidget]: {
    enabled: 'enabled'
  },
  [amplitudeFlags.heapService]: {
    enabled: 'enabled'
  },
  [amplitudeFlags.isQuotesBannerFlagKey]: {
    enabled: 'enabled'
  }
} as const;

export const amplitudeFlagSettings = {
  [amplitudeFlags.quoteSearchRowFlagKey]: 'newQuoteRowEnabled',
  [amplitudeFlags.quoteSearchFlagKey]: 'newQuoteSearchFlowEnabled',
  [amplitudeFlags.isWhatsAppEnabledFlagKey]: 'isWhatsAppEnabled',
  [amplitudeFlags.quoteSearchRowDetailsFlagKey]: 'newQuoteRowDetailsEnabled',
  [amplitudeFlags.quoteSearchRowPriceBreakdownFlagKey]: 'newQuoteRowPriceEnabled',
  [amplitudeFlags.quoteBadge]: 'isQuoteBadgeEnabled',
  [amplitudeFlags.recentQuotesWidget]: "isRecentQuotesWidgetEnabled",
  [amplitudeFlags.heapService]: "heap.isEnabled",
  [amplitudeFlags.isQuotesBannerFlagKey]: 'isQuotesBannerEnabled',
}
