import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { CreditLinesService } from "../../../../shared/creditLines/creditLines.service";
import { FourtySeasPaymentService } from "../../../../shared/payments/fourty-seas-payment.service";
import { CreditLine, CreditLineStatusCode } from "../../../../shared/payments/payments.model";
import { PaymentsService } from "../../../../shared/payments/payments.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import { CreditLineStatusTransition } from "../../../../shared/shared.model";

@Component({
  selector: "app-credits-widget",
  templateUrl: "./credits-widget.component.html",
  styleUrls: ["./credits-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreditsWidgetComponent implements OnInit {
  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isError: boolean = false;
  isEmpty: boolean = false;
  organizationId: string;

  creditLine: CreditLine = null;
  errorMessage: string;
  CreditLineStatuses = CreditLineStatusCode;

  isCreditLineNew: boolean = false;
  isCreditLineRejected: boolean = false;
  isCreditLineApproved: boolean = false;
  isCreditLineApprovedOrPreapproved: boolean = false;
  isCreditLinePreapproved: boolean = false;
  isCreditLineRequested: boolean = false;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private paymentsService: PaymentsService,
    private creditLinesService: CreditLinesService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;

    this.getData();
    this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
      if (next) {
        this.isLoading = false;
      }
    });

    this.fourtySeasPaymentService.paymentLoaderBehaviorSubject.subscribe((value: boolean | undefined) => {
      if (value !== undefined) {
        this.isButtonLoading = value;

        this.fourtySeasPaymentService.paymentCancelBehaviorSubject.subscribe((isCanceled: boolean | undefined) => {
          if (isCanceled !== undefined) {
            if (!value && isCanceled !== true) {
              this.onGoTo();
            }
          }
        });
      }
    });
  }

  onGoTo(): void {
    this.router.navigate(["/creditLine"]);
  }

  onReload(): void {
    this.getData();
  }

  onEmptyAction(): void {
    this.isButtonLoading = true;
    this.router.navigate(["/creditLine"]);
  }

  getData(): void {
    this.isLoading = true;

    this.paymentsService
      .getCreditLineByOrganization(this.organizationId)
      .subscribe(
        (data: CreditLine) => {
          this.creditLine = data;
          this.setCreditLineStatus();
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error;
        }
      )
      .add(() => (this.isLoading = false));
  }

  initCreditLine(): void {
    this.isButtonLoading = true;
    var transaction = this.isCreditLineApproved
      ? CreditLineStatusTransition.increaseRequest
      : null;
    this.fourtySeasPaymentService
      .initCreditLine(this.organizationId, null, transaction)
      .subscribe(
        () => { },
        () => {
          this.openPopup(
            "Error. An error occurred while initialising your credit line, try again."
          );
        }
      )
      .add(() => (this.isButtonLoading = false));
  }

  claimCreditLine(): void {
    this.isButtonLoading = true;
    this.creditLinesService
      .updateCreditLine(this.organizationId, CreditLineStatusTransition.claim)
      .subscribe(
        () => {
          window.location.href = "/creditLine";
        },
        () => {
          this.openPopup(
            "Error. An error occurred while claiming your credit line, try again."
          );
        }
      )
      .add(() => (this.isButtonLoading = false));
  }

  private setCreditLineStatus(): void {
    switch (this.creditLine.statusId) {
      case CreditLineStatusCode.new:
        {
          this.isCreditLineNew = true;
          this.isEmpty = true;
        }
        break;
      case CreditLineStatusCode.rejected:
        {
          this.isCreditLineRejected = true;
          this.isEmpty = true;
        }
        break;
      case CreditLineStatusCode.preApproved:
        {
          this.isCreditLinePreapproved = true;
          this.isCreditLineApprovedOrPreapproved = true;
          this.isEmpty = true;
        }
        break;
      case CreditLineStatusCode.approved:
        this.isCreditLineApproved = true;
        this.isCreditLineApprovedOrPreapproved = true;
        break;
      case CreditLineStatusCode.requested:
        this.isCreditLineRequested = true;
        this.isEmpty = true;
        break;
      case CreditLineStatusCode.increaseRequested:
        this.isCreditLineApproved = true;
        break;
      default:
        break;
    }
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok",
      },
    });
  }
}
