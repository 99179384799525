import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { LayoutService } from "../layout/layout.service";
import { LayoutMode } from "../layout/layout.model";
import {
  EnvironmentsService,
  EnvironmentsServiceConfig,
} from "@ship4wd/ngx-common";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  @ViewChild("header") header: TemplateRef<ElementRef>;

  isMobileDashboardEnabled: boolean;

  constructor(private layoutService: LayoutService, public router: Router) {
    this.isMobileDashboardEnabled = this.getIsMobileDasboardEnabled();

    if (!this.isMobileDashboardEnabled) this.router.navigate(["/shipments"]);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.layoutService.setWrapperHeader();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setMode(LayoutMode.USER);
      this.layoutService.setWrapperHeader(this.header);
    });
  }

  private getIsMobileDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isMobileDashboardEnabled;
      case "sb":
        return environment.sb.isMobileDashboardEnabled;
      default:
        return environment.isMobileDashboardEnabled;
    }
  }
}
