import { Injectable } from "@angular/core";
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class DeviceDefService {
  constructor() {
  }

  isMobile(): boolean {
    return this.isMobileDevice() && DeviceDefService.getIsMobileEnabled();
  }

  private isMobileDevice(): boolean {
    const device = navigator.userAgent;
    if (window.innerWidth < 912 || device.includes("iPhone") || device.includes("Android")) {
      document.body.classList.add('s4d-mobile');
      return true;
    }

    return false;
  }

  static getIsMobileEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isMobileEnabled;
      case 'sb':
        return environment.sb.isMobileEnabled;
      default:
        return environment.isMobileEnabled;
    }
  }
}
