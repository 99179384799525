<div class="sort-options">
  <div class="option-wrapper" *ngFor="let option of sortOptions" [ngClass]="{'selected': selectedOption === option.key}"
    (click)="sort(option.key)">
    <div class="option">
      <div class="label">
        {{ option.label }}
        <ng-container *ngIf="getQuoteDuration(option.key)">
          (~{{ getQuoteDuration(option.key) }}D)
        </ng-container>
        <ng-container *ngIf="option.key === QuotesResultsSortBy.recommended">
          <i class="s4d-icon s4d-star"></i>
        </ng-container>
        <ng-container *ngIf="option.key === QuotesResultsSortBy.cheapestFirst">
          <i class="s4d-icon s4d-usd"></i>
        </ng-container>
        <ng-container *ngIf="option.key === QuotesResultsSortBy.fastestFirst">
          <i class="s4d-icon s4d-lightning"></i>
        </ng-container>
        <ng-container *ngIf="option.key === QuotesResultsSortBy.earliestFirst">
          <i class="s4d-icon custom-calendar-icon s4d-calendar"></i>
        </ng-container>
      </div>
      <!-- Display N/A if no duration (or duration is 0) and key is earliestFirst or fastestFirst -->
      <ng-container *ngIf="(option.key === QuotesResultsSortBy.earliestFirst || option.key === QuotesResultsSortBy.fastestFirst) &&
        (!getQuoteDuration(option.key) || getQuoteDuration(option.key) === 0)">
        <div class="price">
          N/A
        </div>
      </ng-container>
      <!-- Display actual price and charges in other cases -->
      <ng-container *ngIf="!((option.key === QuotesResultsSortBy.earliestFirst || option.key === QuotesResultsSortBy.fastestFirst) &&
        (!getQuoteDuration(option.key) || getQuoteDuration(option.key) === 0))">
        <div class="price" *ngIf="getQuoteFreightCharges(option.key) <= 0">
          N/A
        </div>
        <div class="price" *ngIf="getQuoteFreightCharges(option.key) > 0">
          ${{ getQuoteFreightCharges(option.key) | number: '1.0-0' }}
        </div>
        <div class="details"
          *ngIf="getQuoteFreightCharges(option.key) > 0 && getQuoteDangerousGoodsSurcharges(option.key) === 0">
          <span>+ Local Charges: ${{ getQuoteLocalCharges(option.key) | number: '1.0-0' }}</span>
        </div>
        <div class="details" *ngIf="getQuoteDangerousGoodsSurcharges(option.key) > 0">
          <span>+ Other Charges: ${{ (getQuoteDangerousGoodsSurcharges(option.key) + getQuoteLocalCharges(option.key)) |
            number: '1.0-0' }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>