<div class="w-100">
  <mat-form-field [floatLabel]="'never'" class="w-100">
    <input type="text" matInput [formControl]="autoCompletePackageTypeControl" [matAutocomplete]="autoPackageType"
      (change)="onAutocompleteChange()" placeholder="e.g. Boxes" appAutocompletePosition>
    <input type="text" matInput hidden [formControl]="packageTypeControl">
    <div class="button-spinner" matSuffix>
      <span class="button-inner">
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </div>
    <mat-autocomplete #autoPackageType="matAutocomplete" [displayWith]="displayFn">
      <mat-option class="package-type" *ngFor="let item of packageTypeResponse" [value]="item"
        (onSelectionChange)="onSelectPackageType(item)">
        <span class="package-type-option-wrapper">
          <span class="package-type-option">
            <span class="package-type-name font-weight-bold">{{ item.description }}
              ({{ item.code }})</span>
          </span>
        </span>
      </mat-option>
      <mat-option *ngIf="!isLoading && autoCompletePackageTypeControl?.value?.length >= minimumLength
          && packageTypeResponse?.length === 0" disabled>
        <div class="d-flex justify-content-center">
          No results found
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>