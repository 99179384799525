<div class="orders-list-grid-view-container" [class.show-splash-screen]="isNoBookings">
  <div *ngIf="isNoBookings" class="splash-screen">
    <div class="splash-screen-content">
      <app-quote-search-icons iconName="no-orders"></app-quote-search-icons>
      <span class="splash-screen-title">Get Your First Quote!</span>
      <span class="splash-screen-text mt-3">It looks like you don't have any shipments yet. But! It's a
        life-changing opportunity to check our brand-new quote search!</span>
      <button mat-raised-button class="splash-screen-button" (click)="onQuoteSearch()">
        Search New Quote
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="w-100" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <app-quote-search-icons [iconName]="getMainCarriageIcon(item)"></app-quote-search-icons>
      </td>
    </ng-container>
    <ng-container matColumnDef="bolOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip='Bill of Lading' matTooltipPosition="above">BOL/Order№</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.bolOrderNumber }}">
        <span (click)="navigateToBookingDetails(item)">{{
          item.bolOrderNumber
          }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="masterBillOfLading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Master BOL</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.masterBillOfLading }}">
        {{ item.masterBillOfLading }}
      </td>
    </ng-container>
    <ng-container matColumnDef="shipmentType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Shipment</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        {{ item.shipmentTypeCode | shipmentTypeName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.fromDisplayName }}">
        {{ item.fromDisplayName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }"
        matTooltip="{{ item.toDisplayName }}">
        {{ item.toDisplayName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="statusType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }" matTooltip="{{
          item.isCanceled ? 'Canceled' : item.statusType.ship4wdFrontendStatus
        }}">
        {{
        item.isCanceled ? "Canceled" : item.statusType.ship4wdFrontendStatus
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="etd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Estimated departure
      </th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        {{ checkDateNullOrEmpty(item?.etd) ? (item?.etd | date : "MMM dd, yy") : "Schedule Not Available" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createTimeUtc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        {{ item.createTimeUtc | date : "MMM dd, yy HH:mm a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updateTimeUtc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
      <td mat-cell *matCellDef="let item" [ngClass]="{ 'is-canceled': item.isCanceled }">
        {{ item.updateTimeUtc | date : "MMM dd, yy HH:mm a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <div class="button-spinner" matSuffix *ngIf="isSortLoading">
          <span class="button-inner">
            <mat-spinner diameter="25"></mat-spinner>
          </span>
        </div>
      </th>
      <td mat-cell *matCellDef="let item">
        <button *ngIf="showPayOrder(item)" mat-icon-button class="pay-button" (click)="navigateTo(item)">
          Pay Order
        </button>
        <button *ngIf="!showPayOrder(item)" mat-icon-button class="pay-button" (click)="navigateToBookingDetails(item)">
          Details
        </button>
        <div class="col-auto btn order-actions" [matMenuTriggerFor]="menu">
          <mat-icon class="material-symbols-outlined content-text"> more_vert </mat-icon>
        </div>
        <mat-menu #menu backdropClass="order-dropdown">
          <app-order-menu (canceledBookingEvent)="onCancelBooking($event)" [booking]="item"></app-order-menu>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
