<div class="row location-box mx-0">
  <div class="col-xl-6 col-12 location-from-wrapper" [ngClass]="isMobile ? 'px-0 mobile' : ''">
    <ng-container *ngIf="!isFromPostcodeCbSelected">
      <div class="row">
        <div class="col-12 location-field full-location-field">
          <mat-label class="field-title">From</mat-label>
          <mat-form-field class="w-100" [floatLabel]="'never'" appearance="fill">
            <span matprefix class="shipping-location-map"></span>
            <input type="text" matInput #fromTextInput #fromTrigger="matAutocompleteTrigger"
              [formControl]="fromControl" [matAutocomplete]="autoFrom" (change)="onChange('from')"
              (click)="checkReuse('from')"
              class="shipping-location-type" placeholder="Type city or port" appAutocompletePosition/>
            <div class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25" *ngIf="isFromLoading"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFnUnLocode"
              (optionSelected)="onSelectUnLocode($event.option.value, searchType.from, fromTrigger, fromTextInput)">
              <mat-option class="un-locode" *ngFor="let item of fromUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }}
                      ({{ item.country }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}</span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isFromLoading && fromControl?.value?.length >= minimumLength
                && fromUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">No results found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isFromPostcodeCbSelected">
      <div class="row">
        <div class="col-8 location-field">
          <mat-label class="field-title">From</mat-label>
          <mat-form-field class="w-100" [floatLabel]="'never'" appearance="fill">
            <span matprefix class="shipping-location-map"></span>
            <input type="text" matInput [formControl]="citiesFromControl" [matAutocomplete]="autoFrom" (click)="checkReuse('from')" class="shipping-location-type" placeholder="Type city" appAutocompletePosition/>
            <div class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25" *ngIf="isCitiesFromLoading"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFnCityName" panelWidth="fit-content" (optionSelected)="onSelectedCitiesUnlocodeByType($event.option.value,searchType.from)">
              <mat-option class="un-locode" *ngFor="let item of citiesFromUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName ">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }}
                      ({{item.city}}) ({{ item.country }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}</span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isCitiesFromLoading && citiesFromControl?.value?.length >= minimumLength
                && citiesFromUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">No results found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-4 location-post-code">
          <mat-label class="field-title">Postcode</mat-label>
          <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'">
            <input matInput placeholder="000000" [formControl]="postCodesFromControl"
              [matAutocomplete]="autoFromPostcode" (click)="checkReuse('from')" appAutocompletePosition/>
            <div *ngIf="isPostCodesFromLoading" class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoFromPostcode="matAutocomplete" [displayWith]="displayFnZipCode" panelWidth="fit-content" (optionSelected)="onSelectPostcodeUnLocode($event.option.value,searchType.from)">
              <mat-option class="un-locode" *ngFor="let item of postCodesFromUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName ">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }}
                      ({{ item.country }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}</span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isPostCodesFromLoading && postCodesFromControl?.value?.length >= shortestFromZipcode
                  && postCodesFromUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">
                  No results found
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-xl-6 col-12 mt-xl-0 mt-4 location-to-wrapper" [ngClass]="isMobile ? 'px-0 mobile' : ''">
    <ng-container *ngIf="!isToPostcodeCbSelected">
      <div class="row">
        <div class="col-12 location-field full-location-field">
          <mat-label class="field-title">To</mat-label>
          <mat-form-field class="w-100" [floatLabel]="'never'" appearance="fill">
            <span matprefix class="shipping-location-map"></span>
            <input type="text" matInput #toTextInput #toTrigger="matAutocompleteTrigger"
              [formControl]="toControl" [matAutocomplete]="autoTo"
              (click)="checkReuse('to')" (change)="onChange('to')"
              placeholder="Type city or port" appAutocompletePosition/>
            <div class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25" *ngIf="isToLoading"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoTo="matAutocomplete" [displayWith]="displayFnUnLocode"
              (optionSelected)="onSelectUnLocode($event.option.value, searchType.to, toTrigger, toTextInput)">
              <mat-option class="un-locode" *ngFor="let item of toUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName ">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }} ({{ item.country
                      }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}
                  </span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isToLoading && toControl?.value?.length >= minimumLength
                && toUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">No results found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isToPostcodeCbSelected">
      <div class="row">
        <div class="col-8 location-field">
          <mat-label class="field-title">To</mat-label>
          <mat-form-field class="w-100" [floatLabel]="'never'" appearance="fill">
            <span matprefix class="shipping-location-map"></span>
            <input type="text" matInput [formControl]="citiesToControl" [matAutocomplete]="autoTo" (click)="checkReuse('to')" class="shipping-location-type" placeholder="Type city" appAutocompletePosition/>
            <div class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25" *ngIf="isCitiesToLoading"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoTo="matAutocomplete" [displayWith]="displayFnCityName" panelWidth="fit-content" (optionSelected)="onSelectedCitiesUnlocodeByType($event.option.value,searchType.to)">
              <mat-option class="un-locode" *ngFor="let item of citiesToUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }} ({{item.city}}) ({{ item.country
                      }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}
                  </span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isCitiesToLoading && citiesToControl?.value?.length >= minimumLength
                && citiesToUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">No results found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-4 location-post-code">
          <mat-label class="field-title">Postcode</mat-label>
          <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'">
            <input matInput placeholder="000000" [formControl]="postCodesToControl"
              [matAutocomplete]="autoFromPostcode" (click)="checkReuse('to')" appAutocompletePosition/>
            <div *ngIf="isPostCodesToLoading" class="button-spinner" matSuffix>
              <span class="button-inner">
                <mat-spinner diameter="25"></mat-spinner>
              </span>
            </div>
            <mat-autocomplete #autoFromPostcode="matAutocomplete" [displayWith]="displayFnZipCode" panelWidth="fit-content" (optionSelected)="onSelectPostcodeUnLocode($event.option.value,searchType.to)">
              <mat-option class="un-locode" *ngFor="let item of postCodesToUnLocodeResponse?.tripsSearchList" [value]="item">
                <span class="un-locode-option-wrapper">
                  <span class="un-locode-location-icon" [ngClass]="item.locationType | locationTypeIcon"
                    [matTooltip]="item.locationType | locationTypeShortName"></span>
                  <span class="un-locode-option" [matTooltip]="item.displayName">
                    <span class="un-locode-name font-weight-bold">{{ item.displayName }}
                      ({{ item.country }})</span>
                    <span class="un-locode-country">{{ item.countryName }}</span>
                  </span>
                  <span *ngIf="item.locationType | locationTypeShortName" class="un-locode-location-type">
                    {{ item.locationType | locationTypeShortName | uppercase }}</span>
                </span>
              </mat-option>
              <mat-option *ngIf="!isPendingResult && !isPostCodesToLoading && postCodesToControl?.value?.length >= shortestFromZipcode
                  && postCodesToUnLocodeResponse?.tripsSearchList?.length === 0" disabled>
                <div class="d-flex justify-content-center">
                  No results found
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>
</div>