<div>
  <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
    <input type="text" matInput [formControl]="commodityControl" [matAutocomplete]="autoCommodity"
      placeholder="Start typing your product description" appAutocompletePosition>
    <div class="button-spinner" matSuffix>
      <span class="button-inner">
        <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
      </span>
    </div>
    <mat-autocomplete #autoCommodity="matAutocomplete">
      <mat-option class="commodity" *ngFor="let item of commodityResponse" [value]="item.description"
        (onSelectionChange)="onSelectCommodity(item)">
        <span class="commodity-option-wrapper">
          <span class="commodity-option">
            <span class="commodity-name font-weight-bold">{{ item.description }}</span>
          </span>
        </span>
      </mat-option>
      <mat-option *ngIf="!isLoading && commodityControl?.value?.length >= minimumLength
          && commodityResponse?.length === 0" disabled>
        <div class="d-flex justify-content-center">
          No results found
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
