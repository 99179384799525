import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';
import { NotificationService } from '@ship4wd/ngx-common';
import { Booking, BookingFile, BookingSummary } from '../../../../shared/bookings/bookings.model';
import { BookingFlowPanelState, BookingFlowPanels, DocumentTypeCode, ShipmentType, VolumeUnit, WeightUnit } from '../../../../shared/shared.model';
import { BookingFlowService } from '../../../../desktop/booking/booking-flow/booking-flow.service';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { StringCutterService } from '../../../../shared/services/text-cutter/stringCutter.service';
import { environment } from '../../../../../environments/environment';
import { RollupDialogService } from '../../../../mobile/rollup-dialog/rollup-dialog.service';
import { BookingFlowContainerDetailsComponent } from './container-details/container-details.component';
import { BookingFlowLoadDetailsComponent } from './load-details/load-details.component';
import { ChangeCargoConfirmComponent } from './change-cargo-confirm/change-cargo-confirm.component';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { FileUploaderMode, LayoutType, FileInfo } from '@ship4wd/ngx-manager-ui';
import { BookingDocumentsService } from 'src/app/shared/bookings/booking-documents/booking-documents.service';
import { FilesDocumentsService } from 'src/app/shared/files-documents/files-documents.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-booking-flow-cargo-details',
  templateUrl: 'booking-flow-cargo-details.component.html',
  styleUrls: ["./booking-flow-cargo-details.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowCargoDetailsComponent implements OnInit, AfterViewInit {
  @Input() booking: Booking;
  @Input() containers: FormArray;
  @Input() shipmentTypeCode: ShipmentType;
  @Input() bookingSummary: BookingSummary;
  @Output() next: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() setState: EventEmitter<BookingFlowPanelState> = new EventEmitter();
  @Output() isBookingAdditionalServiceUpdated: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  isNextLoading: boolean = false;
  isLclWeightAndVolumeValid = true;

  collectDateControl: FormControl = new FormControl('', Validators.required);
  minSelectableDate = new Date();
  maxSelectableDate = new Date();
  shipmentTypes = ShipmentType;
  containersWeightAndVolumeValidityMap = new Map<FormControl, boolean>();
  containersCubicAndWeight = new Map<string, any>();

  FileUploaderMode = FileUploaderMode;
  LayoutType = LayoutType;
  documents: FileInfo[] = [];
  isFileUploaded = false;

  get isValid(): boolean {
    let commoditiesValid = true;

    this.containers.controls.forEach((container: FormGroup) => {
      const commodities = container.controls["commodities"] as FormArray;
      commodities.controls.forEach((commodity: FormGroup) => {
        if (!commodity.valid) commoditiesValid = false;
      });
    });

    return commoditiesValid && this.collectDateControl.valid;
  }

  constructor(
    private bookingFlowService: BookingFlowService,
    private bookingsService: BookingsService,
    private rollupDialogService: RollupDialogService,
    private stringCutterService: StringCutterService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialogService: DialogService,
    private utilityService: UtilityService,
    private bookingDocumentsService: BookingDocumentsService,
    private filesDocumentsService: FilesDocumentsService
  ) { }

  ngOnInit(): void {
    if (this.containers) {
      this.collectDateControl.setValue(this.containers?.controls[0]?.value?.collectionDate);
    }

    this.bookingFlowService.panelState.subscribe(x => {
      if (x.panel === BookingFlowPanels.cargoDetails) this.isNextLoading = x.loading;
    });

    if (this.utilityService.isNotNullOrMinDateValue(this.booking.etd)) {
      this.maxSelectableDate = new Date(this.booking.etd)
      this.maxSelectableDate.setDate(this.maxSelectableDate.getDate() - this.getEnviromentMaxAllowedShippingDate());
    } else {
      this.maxSelectableDate = null;
    }

    if (this.maxSelectableDate != null && new Date(this.collectDateControl.value) > this.maxSelectableDate) {
      this.collectDateControl.setValue(this.maxSelectableDate);
    }

    if (this.shipmentTypeCode === ShipmentType.FCL) {
      this.containers.controls.forEach((containerControl: FormControl) => {
        this.containersWeightAndVolumeValidityMap.set(containerControl, true);

        this.bookingsService.getEquipment(containerControl.value.equipmentTypeCode).subscribe(response => {
          this.containersCubicAndWeight.set(response.equipment.isoCode, {
            maxCubic: response.equipment.cubicCapacity,
            maxWeight: response.equipment.maxWeight,
            maxWeightUnit: response.equipment.maxWeightUnit
          });
        });
      });
    }

    if (this.shipmentTypeCode == ShipmentType.AIR) {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      this.minSelectableDate = currentDate;
    }

    this.documents = this.booking.documents.filter(x => x.documentTypeCode === DocumentTypeCode.MSDS).map((x) => {
      return {
        id: x.id,
        name: x.name,
        url: x.url,
        progress: null,
        file: null,
      } as FileInfo;
    }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onSetState();
    }, 500);
  }

  onSetState(changed: boolean = false): void {
    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.cargoDetails;
    state.success = changed ? false : this.isValid;
    state.valid = this.isValid;
    state.loading = false;

    this.setState.emit(state);
  }

  onDateChanged(value: Moment): void {
    this.onSetState(true);
    const date = value?.utc().format();
    this.containers.controls.forEach((container) => {
      container.get('collectionDate').setValue(date);
    })
    this.collectDateControl.setValue(date);
  }

  onOpenContainer(index: number, containerForm: FormGroup): void {
    const data = {
      index: index,
      current: containerForm,
      containers: this.containers,
      containersCubicAndWeight: this.containersCubicAndWeight,
      booking: this.booking
    };

    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(BookingFlowContainerDetailsComponent, data);

      dialogRef.subscribe(data => {
        this.dialogService.setDialogOpen(false);
        if (data !== undefined && data != null) {
          this.containers = data.containers;
        }
      });
    }
  }

  onOpenCommodity(index: number, commodityForm: FormGroup): void {
    const data = {
      index: index,
      current: commodityForm,
      containers: this.containers,
      booking: this.booking
    };

    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(BookingFlowLoadDetailsComponent, data);

      dialogRef.subscribe(data => {
        this.dialogService.setDialogOpen(false);
        if (data !== undefined && data != null) {
          commodityForm = data.commodity;
        }
      });
    }
  }


  onNext(): void {
    this.containers.markAllAsTouched()

    if (this.isCargoChanged()) {
      this.openCargoConfirmDialog();
    } else {
      this.updateCargoDetails();
    }
  }

  getMinimumCollectionTime(): any {
    let cargoExpectedReady = this.containers[0]?.containerForm.get('cargoExpectedReady')?.value;
    return cargoExpectedReady ?? this.minSelectableDate;
  }

  getContainersTitle(container: FormGroup): string {
    if (this.shipmentTypeCode === ShipmentType.FCL) {
      return this.getFormControlValue(container, 'equipmentTypeDescription');
    }

    return '';
  }

  getContainersSubTitle(container: FormGroup): string {
    const defaultTitle = 'Add Cargo Details';

    if (this.shipmentTypeCode === ShipmentType.FCL) {
      const commodities = container?.controls["commodities"] as FormArray;

      if (commodities.length === 0) {
        return defaultTitle;
      }

      const firstCommodity = commodities.at(0) as FormGroup;
      const id = firstCommodity.controls['commodityTypeCode']?.value;
      const description = firstCommodity.controls['commodityDescription']?.value;

      if (id === null) {
        return defaultTitle;
      }

      return this.stringCutterService.cutter(`${id} ${description}`, 300);
    }

    return defaultTitle;
  }

  isContainerFilled(container: FormGroup): boolean {
    if (this.shipmentTypeCode !== ShipmentType.FCL) {
      return false;
    }

    const commodities = container?.controls["commodities"] as FormArray;
    const firstCommodity = commodities.at(0) as FormGroup;
    const firstCommodityId = firstCommodity.controls['commodityTypeCode']?.value;

    return firstCommodityId && firstCommodity.valid;
  }

  getLoadsSubTitle(commodityForm: FormGroup): string {
    const defaultTitle = 'Add Cargo Details';

    const id = commodityForm.get('commodityTypeCode')?.value;
    const description = commodityForm.get('commodityDescription')?.value;

    if (!id) {
      return defaultTitle;
    }

    return this.stringCutterService.cutter(`${id} ${description}`, 300);
  }

  isLoadFilled(commodityForm: FormGroup): boolean {
    const commodityId = commodityForm.get('commodityTypeCode')?.value;

    return commodityId && commodityForm.valid;
  }

  getFormControlValue(container: FormGroup, key: string): any {
    return container.get(key).value;
  }

  markAllInputsAsTouched(): void {
    this.collectDateControl.markAsTouched();
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Cargo Details",
      fieldName,
      errorValue
    })
  }

  getSecureFileUrl(fileId: string) {
    this.bookingDocumentsService
      .getSignedUrl(this.booking.id, fileId)
      .subscribe(
        (x) => {
          window.open(x.url, "_blank");
        },
        (error) => {
          this.notificationService.error(error);
        }
      );
  }

  onDocumentsChange(files: FileInfo[]) {
    const filesToAdd = files.filter((x) => !x.id);
    const filesToRemove = this.documents.filter(
      (x) => !files.some((y) => y === x)
    );
    this.documents = files;
    this.deleteFiles(filesToRemove);
    this.addFiles(filesToAdd);
  }

  private getEnviromentMaxAllowedShippingDate(): number {
    switch (this.shipmentTypeCode) {
      case ShipmentType.AIR:
        return environment.air.maxAllowedShippingDate;
      case ShipmentType.FCL:
        return environment.fcl.maxAllowedShippingDate;
      case ShipmentType.LCL:
        return environment.lcl.maxAllowedShippingDate;
      default:
        return 0;
    }
  }

  private checkWeightAndVolumeValidity(): Observable<boolean> {
    if (this.shipmentTypeCode == ShipmentType.FCL) {
      let condition = true;
      let count = 0;

      let observables: Observable<any>[] = [];
      this.containers.controls.forEach((container: FormControl) => {
        observables.push(this.bookingsService.getEquipment(container.value.equipmentTypeCode).pipe(
          map(response => {
            return { equipment: response.equipment, containerControl: container }
          })
        ));
      });

      return forkJoin(observables.map(obs => obs.pipe(
        map((equipmentContainer: any) => {
          if (equipmentContainer.equipment) {
            count++;
            const maxWeight = equipmentContainer.equipment.payloadCapacityWeight;
            const cubicCapacity = equipmentContainer.equipment.cubicCapacity;

            let selectedMaxWeight = 0;
            let selectedCubicCapacity = 0;
            equipmentContainer.containerControl.value.commodities.forEach(commodity => {
              selectedMaxWeight = selectedMaxWeight + Number(commodity.weight);
              selectedCubicCapacity = selectedCubicCapacity + Number(commodity.volume);
            });
            if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
              this.googleAnalyticsService.selectedTotalWeightAndVolumeError();
              condition = false;
              this.containersWeightAndVolumeValidityMap.set(equipmentContainer.containerControl, false);
            }
            else {
              this.containersWeightAndVolumeValidityMap.set(equipmentContainer.containerControl, true);
            }

            equipmentContainer.containerControl.value.cargoGrossWeight = selectedMaxWeight;

            if (count == this.containers.controls.length && condition) {
              return true;
            } else if (count == this.containers.controls.length && !condition) {
              const state = new BookingFlowPanelState();
              state.panel = BookingFlowPanels.cargoDetails;
              state.success = false;
              state.valid = false;
              state.loading = false;
              state.error = true;
              this.setState.emit(state);
              return false;
            }
          }
        })))).pipe(
          map(processedValues => {
            if (processedValues.some(value => value === false)) {
              return false;
            }
            else {
              return true;
            }
          })
        );
    } else {
      let maxWeight = environment.lclMaxWeight;
      let cubicCapacity = environment.lclMaxCapacity;
      var condition = true;

      if ((this.shipmentTypeCode == ShipmentType.LCL)) {
        this.containers.controls.forEach((container: FormControl) => {
          container.value.commodities.forEach(commodity => {
            maxWeight = commodity.weightUnitCode == WeightUnit.KG ? environment.lclMaxWeight : environment.lclMaxWeight * 2.20462;
            cubicCapacity = commodity.volumeUnitCode == VolumeUnit.CBM ? environment.lclMaxCapacity : environment.lclMaxCapacity * 61023.7441;

            const selectedMaxWeight = commodity.weight;
            const selectedCubicCapacity = commodity.volume;

            if (selectedMaxWeight > maxWeight || selectedCubicCapacity > cubicCapacity) {
              this.googleAnalyticsService.selectedTotalWeightAndVolumeError();
              condition = false;
              this.isLclWeightAndVolumeValid = false;
            }
          })
        });
      }

      if (condition) {
        return of(true);
      } else {
        const state = new BookingFlowPanelState();
        state.panel = BookingFlowPanels.cargoDetails;
        state.success = false;
        state.valid = false;
        state.loading = false;
        state.error = true;
        this.setState.emit(state);
        return of(false);
      }
    }
  }

  private openCargoConfirmDialog(): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.rollupDialogService.open(ChangeCargoConfirmComponent, {});

      dialogRef.subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result && result.isAllowSaveBookingCargoDetails) {
          this.updateCargoDetails();
          this.deleteAllBookingAdditionalServices();
        }
      });
    }
  }

  private isCargoChanged(): boolean {
    let isChanged = false;
    if (this.bookingSummary.bookingAdditionalServiceCharges.length > 0) {
      var initialValue = this.containers.getRawValue();
      if (initialValue[0].collectionDate !== this.booking.containers[0].collectionDate) {
        isChanged = true;
        return isChanged;
      }
      if (initialValue[0].commodities.length !== this.bookingSummary.containers[0].commodities.length) {
        isChanged = true;
        return isChanged;
      }
      for (let index in initialValue[0].commodities) {
        let isCommodityChanged = true;
        var formCommodity = initialValue[0].commodities[index];
        var bookingCommodity = this.bookingSummary.containers[0].commodities[index];
        if (bookingCommodity.commodityTypeCode === formCommodity.commodityTypeCode &&
          bookingCommodity.volume === formCommodity.volume &&
          bookingCommodity.weight === formCommodity.weight) {
          isCommodityChanged = false;
        }
        if (isCommodityChanged) {
          isChanged = true;
          break;
        }
      };
    }
    return isChanged;
  }

  private deleteAllBookingAdditionalServices(): void {
    this.bookingFlowService.deleteAllAdditionalServices(this.booking.id)
      .subscribe(
        () => {
          this.isBookingAdditionalServiceUpdated.emit({
            isBookingAdditionalServiceUpdated: true
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      );
  }

  private updateCargoDetails(): void {
    this.checkWeightAndVolumeValidity().subscribe(res => {
      if (res === true) {
        const state = new BookingFlowPanelState();
        state.panel = BookingFlowPanels.cargoDetails;
        state.error = false;
        state.success = false
        state.loading = true;
        state.valid = this.isValid;
        this.next.emit(state);
      }
    });
  }

  private deleteFiles(files: FileInfo[]) {
    this.isFileUploaded = false;
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.booking.id, fileToRemove.id)
        .subscribe((data) => {
          const index = this.booking.documents.findIndex(
            (x) => x.id === fileToRemove.id
          );
          if (index > -1) {
            this.booking.documents.splice(index, 1);
          }
        });
    }
  }

  private addFiles(files: FileInfo[]) {
    for (const fileToAdd of files) {
      this.filesDocumentsService
        .add(this.booking.id, fileToAdd, null, DocumentTypeCode.MSDS)
        .subscribe(
          (x) => {
            this.finalizeFileLoading(fileToAdd, x);
            this.isFileUploaded = true;
          },
          (error) => {
            this.notificationService.error(error);
          }
        );
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any) {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round((100 * response.loaded) / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.booking.documents.push(addedFile);
    }
  }
}
