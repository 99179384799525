<div class="quotes-widget-container" [ngClass]="{ 'is-empty-container': isEmpty, 'is-preview': preview }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div *ngIf="editable" class="col-auto me-2 widget-action">
        <mat-icon class="editable-icon">zoom_out_map</mat-icon>
      </div>
      <div class="col widget-title">
        <span *ngIf="!isEmpty">My recent quotes</span>
      </div>
      <div *ngIf="!editable && !isEmpty && !preview" class="col-auto widget-action">
        <span class="go-icon link-action" (click)="onGoTo()"></span>
      </div>
      <div *ngIf="editable" class="col-auto widget-action">
        <span class="remove-icon link-action" (click)="onRemove()"></span>
      </div>
      <div *ngIf="!editable && isEmpty" class="col-auto widget-action">
        <span class="info-icon link-action" matTooltip="Add missing details to complete your booking."
          matTooltipPosition="above"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading || preview }">
      <div *ngIf="isLoading || preview" class="col is-loader">
        <div *ngFor="let num of range(loaderElementsNumber)" class="content-wrapper loader-element"></div>
      </div>
      <div *ngIf="!isLoading && isEmpty && !preview" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="mt-3 is-empty-title">There is no quotes</span>
        <span class="mt-2 is-empty-message">Please search quote or contact support</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
          [disabled]="isButtonLoading" (click)="onEmptyAction()">
          <span class="button-inner">
            Search quote
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>
      <div *ngIf="!isLoading && isError && !preview" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>
      <div *ngIf="!isLoading && !isError && !isEmpty && !preview" class="col stripped-wrappers">
        <div class="row content-wrapper pe-3" *ngFor="let booking of bookings">
          <div class="col-auto"></div>
          <div class="col-auto submited-order-icon-date-wrapper"
            [matTooltip]="booking.shipmentTypeCode | shipmentTypeName" matTooltipPosition="above">
            <div class="transport-icon">
              <app-quote-search-icons [iconName]="getMainCarriageIcon(booking)"></app-quote-search-icons>
            </div>
          </div>
          <div class="col from-ordername-orderdate-wrapper">
            <span class="order-name">{{ booking.fromDisplayName }}</span>
            <span class="order-date">{{
              checkDateNotNullOrEmpty(booking.etd)
              ? (booking.etd | date : "MMM dd, yyyy")
              : "Schedule Not Available"
              }}</span>
          </div>
          <div class="col to-ordername-orderdate-wrapper">
            <span class="order-name">{{ booking.toDisplayName }}</span>
            <span class="order-date">{{
              checkDateNotNullOrEmpty(booking.eta)
              ? (booking.eta | date : "MMM dd, yyyy")
              : "Schedule Not Available"
              }}</span>
          </div>
          <div class="col d-none d-xl-block order-status-arrival-wrapper">
            <span *ngIf="booking.statusType.code === bookingStatusCodes.draft" class="arrival-time"
              matTooltip="Shipment quote drafts are valid for 7 days." matTooltipPosition="above">
              {{ getExpirationTime(booking) }}
              <span class="info-snack-icon" (click)="onShowSnack()"></span>
            </span>
          </div>
          <div class="col">
            <button *ngIf="isShowBookingButton(booking)" mat-raised-button color="primary" class="mt-3 quote-button"
              [ngClass]="{
                draft: booking.statusType.code === bookingStatusCodes.draft
              }" (click)="onResume(booking)">
              {{ getBookingButtonText(booking)}}
            </button>
          </div>
          <div *ngIf="booking.statusType.code === bookingStatusCodes.draft" class="col-auto align-self-center py-2 px-0"
            [matMenuTriggerFor]="menu">
            <mat-icon class="material-symbols-outlined content-text">
              more_vert
            </mat-icon>
          </div>
          <mat-menu #menu backdropClass="order-dropdown">
            <btn mat-menu-item class="dropdown-button" (click)="onCancelOrder(booking)">
              <span class="order-dropdown-text">Remove Draft</span>
            </btn>
          </mat-menu>
        </div>
      </div>
    </div>
    <div *ngIf="isSnackBarOpened" class="widget-snackbar" [ngClass]="isSnackBarOpened ? 'show' : 'hide'">
      <div class="widget-snackbar-container">
        <div class="row h-100 align-content-center">
          <div class="col-auto d-flex align-items-center">
            <span class="info-icon"></span>
          </div>
          <div class="col d-flex align-items-center">
            <span>Shipment quotes are valid for 7 days.</span>
          </div>
          <div class="col-auto d-flex align-items-center">
            <span class="close-icon" (click)="onCloseSnack()"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
