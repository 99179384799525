import { Component, ComponentFactoryResolver, ComponentRef, Input, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { AdditionalRate } from '../../../../../shared/shared.model';

@Component({
  selector: 'app-additional-service-dialog',
  template: '',
  styleUrls: ['./additional-service-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServiceDialogComponent {
  @Input() additionalRates: AdditionalRate[];
  @Input() bookingId: string;
  @Input() booking: Booking;

  component: any;
  private componentRef: ComponentRef<any>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<AdditionalServiceDialogComponent>,
  ) { }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.component
    );
    this.componentRef = componentFactory.create(this.viewContainerRef.injector);

    this.componentRef.instance.bookingId = this.bookingId;
    this.componentRef.instance.booking = this.booking;
    this.componentRef.instance.additionalRates = this.additionalRates;

    this.componentRef.instance.closeDialog.subscribe((e) => {
      this.dialogRef.close(e);
    });

    this.viewContainerRef.insert(this.componentRef.hostView);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
