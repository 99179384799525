import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { AppSettingsService } from './../../shared/services/app-settings/app-settings.service';
import { BookingOrganizationContactsService } from './../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { LayoutService } from '../layout/layout.service';
import { LayoutMode } from '../layout/layout.model';
import { NotificationService } from '@ship4wd/ngx-common';
import {
  OrganizationContact,
  OrganizationContactsQuery,
  OrganizationContactsQueryParameters,
  OrganizationContactViewModel
} from './../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { Page } from './../../shared/shared.model';
import { RemoveConfirmDialogComponent } from './../../shared/remove-confirm-dialog/remove-confirm-dialog.component';

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageContactsComponent implements OnInit, AfterViewInit {

  organizationContacts: OrganizationContact[];
  isNewContact: boolean = false;
  isManagedContact: boolean = false;
  isLoading: boolean = true;
  selectedContact: OrganizationContact;
  organizationId: string;
  data: any[];

  constructor(
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private appSettingsService: AppSettingsService,
    private notificationService: NotificationService,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private location: Location) { }

  ngOnInit(): void {
    this.getOrganizationContacts();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.CONTACTS);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(true);
      this.layoutService.setToolbarShowBack(false);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarTitle("Contacts");
      this.layoutService.setToolbarDescription("Manage organization contacts");
    }, 0);
  }

  getInitialName(contact: OrganizationContact): string {
    if (contact?.firstName && contact?.lastName) {
      return contact.firstName.charAt(0).toUpperCase() + contact.lastName.charAt(0).toUpperCase();
    }
    return '';
  }

  addContact(): void {
    this.isNewContact = true;
    this.isManagedContact = true;
  }

  selectCsvFile(): void {
    document.querySelector('input').click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const contents = e.target.result;
      this.data = this.parseCSV(contents);
    };

    reader.readAsText(file);
    setTimeout(() => {
      if (this.data != null) {
        this.readCSV();
      }
    }, 1500);
  }

  readCSV(): void {
    this.isLoading = true;
    var organizationContacts = this.data as OrganizationContactViewModel[];

    this.bookingOrganizationContactsService
      .addOrganizationContacts(organizationContacts, this.organizationId)
      .subscribe((x: OrganizationContact) => {
        this.notificationService.success("Contacts were added successfuly");
        location.reload();
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  parseCSV(contents: string): any[] {
    const lines = contents.split('\n');
    const result = [];

    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  }

  onUpdateContact(contact: OrganizationContact): void {
    this.selectedContact = contact;
    this.isNewContact = false;
    this.isManagedContact = true;
  }

  onDeleteContact(contact: OrganizationContact): void {
    const dialogRef = this.dialog.open(RemoveConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data:{
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Remove'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {

        this.deleteOrganizationContact(contact);
      }
    });
  }

  onBack(): void {
    this.isLoading = true;
    this.location.back();
  }

  private getOrganizationContacts(): void {
    const query = {
      pageNo: 1,
      pageSize: 500,
      sortBy: OrganizationContactsQueryParameters.companyName
    } as OrganizationContactsQuery;

    const settingData = this.appSettingsService.getSettings();
    this.organizationId = settingData.organizationId;

    this.isLoading = true;
    this.bookingOrganizationContactsService
      .getOrganizationContactsByQuery(query, this.organizationId)
      .subscribe((x: Page<OrganizationContact>) => {
        this.organizationContacts = x.items;
      },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private deleteOrganizationContact(organizationContact: OrganizationContact): void {
    this.isLoading = true;
    this.bookingOrganizationContactsService
      .deleteOrganizationContact(organizationContact.organizationId, organizationContact.id)
      .subscribe(
        () => {
          this.getOrganizationContacts();
        },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }
}
