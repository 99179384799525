import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StringCutterService {
  constructor() {}
  cutter(text: string, symb: number) {
    if (text) {
      if (text.length > symb) {
        text = text.substring(symb, 0) + "...";
        return text;
      } else {
        return text;
      }
    } else if (!text) {
      return "";
    }
    return null;
  }
}
