import { Pipe, PipeTransform } from '@angular/core';
import { HaulageType } from '../shared.model';

@Pipe({ name: 'haulageTypeS4DIcon' })
export class HaulageTypeS4DIconPipe implements PipeTransform {
  private haulageTypes = {};

  constructor() {
    this.haulageTypes[HaulageType.sea] = 's4d-ship';
    this.haulageTypes[HaulageType.air] = 's4d-ac';
    this.haulageTypes[HaulageType.rail] = 's4d-ship';
    this.haulageTypes[HaulageType.land] = 's4d-ship';
    this.haulageTypes[HaulageType.barge] = 's4d-ship';
  }

  transform(value?: HaulageType): string {
    return this.haulageTypes[value] ?? '';
  }
}
