<div class="booking-flow-details-overlay" [ngClass]="{'expanded': isExpanded, 'summary': isSummary}"></div>
<div class="booking-flow-details" #bottomSheet
  [ngClass]="{'expanded': isExpanded, 'summary': isSummary, 'view': isView}" *ngIf="bookingSummary">
  <div class="booking-flow-details-header" (click)="onClick()" (panstart)="onPanStart($event)"
    (panmove)="onPanMove($event)" (panend)="onPanEnd($event)">
    <div class="row m-0 w-100">
      <div class="col px-0"></div>
      <div class="col-auto px-0 d-flex justify-content-center align-items-center">
        <div class="header-indicator"></div>
      </div>
      <div class="col px-0 d-flex justify-content-end align-items-center">
        <div *ngIf="isExpanded" class="shipment-type-label">
          <i class="label-icon s4d-icon" [ngClass]="getShipmentIcon()"></i>
          <span class="label-text"> {{ bookingSummary?.shipmentTypeCode | shipmentTypeCargoName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-flow-details-content">
    <div class="locations-wrapper" *ngIf="isExpanded">
      <div class="locations-container"
        *ngFor="let location of uniqueLocations; let i = index; let isFirst = first; let isLast = last">
        <div class="row locations" [ngClass]="location.date || isFirst || isLast ? '' : 'empty-date'">
          <div class="col-auto px-0 d-flex align-items-start">
            <div class="haulage-icon-wrapper">
              <i class="haulage-icon s4d-icon" [ngClass]="getLocationTypeIcon(location.type, isFirst)"></i>
              <div class="lines" [ngClass]="{'d-none': isLast}"></div>
            </div>
          </div>
          <div class="col ps-3 pe-0 d-flex align-items-center">
            <div class="col-12 px-0">
              <span class="location-name">
                {{ location.name }}
              </span>
              <span class="location-date">
                <ng-container *ngIf="isFirst || isLast || location.date">
                  {{ checkDateNotNullOrEmpty(location.date) ?
                  (location.date | date: "MMM dd") : "Various Time Options" }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isExpanded">
      <div class="row m-0 px-4 pt-3 pb-2"
        *ngIf="shipmentType !== ShipmentTypes.AIR
                &&
                (bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage || bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName)">
        <div class="row m-0 p-0" *ngIf="bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName">
          <div class="col-6 p-0 detail-subtitle">Vessel Name:</div>
          <div class="col-6 p-0 detail-subtitle-value">
            {{ bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName }}
          </div>
        </div>
        <div class="row m-0 mt-3 p-0" *ngIf="bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage">
          <div class="col-6 p-0 detail-subtitle">Voyage</div>
          <div class="col-6 p-0 detail-subtitle-value">
            {{ bookingSummary?.quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage }}
          </div>
        </div>
        <div class="row m-0 mt-3 p-0">
          <div class="col-6 p-0 detail-subtitle">Cut-Off-Date:</div>
          <div class="col-6 p-0 detail-subtitle-value">
            {{ getCutOffDateTitle(bookingSummary?.quote) }}
          </div>
        </div>
      </div>
      <mat-divider class="my-2 divider"></mat-divider>
      <mat-expansion-panel class="mat-elevation-z0 booking-details-panel" [expanded]="true">
        <mat-expansion-panel-header class="py-3">
          <mat-panel-title class="section-header">
            Base services
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row m-0">
          <div class="col-8 p-0 detail-subtitle">International Freight</div>
          <div class="col-4 p-0 d-flex flex-column align-items-end">
            <span class="detail-subtitle-value" appSetRoleBasedPrice
              [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(mainCarriageTotal)}">
              {{ bookingSummary.quote.trip.currency | currencyTextIcon
              }}{{ mainCarriageTotal | number : "1.2-2" }}
            </span>
            <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
              *ngIf="isShowReverseDiscountPrice(mainCarriageTotal)">
              {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(mainCarriageTotal) | number: "1.2-2" }}
            </span>
          </div>
        </div>
        <div class="row m-0 py-3" *ngIf="preCarriageTotal">
          <div class="col-8 p-0 detail-subtitle text-capitalize">{{ bookingSummary.quote?.trip?.fromLocationTypeCode |
            locationTypeShortName }} Pickup</div>
          <div appSetRoleBasedPrice class="col-4 p-0 detail-subtitle-value">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon
            }}{{ preCarriageTotal | number : "1.2-2" }}
          </div>
        </div>
        <div class="row m-0" *ngIf="postCarriageTotal">
          <div class="col-8 p-0 detail-subtitle text-capitalize">{{ bookingSummary.quote?.trip?.toLocationTypeCode |
            locationTypeShortName }} Delivery</div>
          <div appSetRoleBasedPrice class="col-4 p-0 detail-subtitle-value">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon
            }}{{ postCarriageTotal | number : "1.2-2" }}
          </div>
        </div>
        <div button class="col-12 px-0 show-details" (click)="onShowDetails()">Show details</div>
      </mat-expansion-panel>
      <ng-container *ngIf="bookingSummary?.bookingAdditionalServiceCharges.length > 0 && isAdditionalServicesEnabled">
        <mat-divider class="my-2 divider"></mat-divider>
        <mat-expansion-panel class="mat-elevation-z0 booking-details-panel" [expanded]="false">
          <mat-expansion-panel-header class="py-3">
            <mat-panel-title class="section-header">
              Additional Services
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container
            *ngFor="let bookingAdditionalServiceCharge of bookingSummary?.bookingAdditionalServiceCharges; let i = index; let isFirst = first; let isLast = last">
            <div class="row m-0" [ngClass]="{ 'pt-3': !isFirst }">
              <div class="col-8 p-0 detail-subtitle">{{ bookingAdditionalServiceCharge?.additionalServiceCharge?.name }}
              </div>
              <div class="col-4 p-0 detail-subtitle-value"
                *ngIf="!getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
                <i class="s4d-icon s4d-info-light"
                  *ngIf="getAdditionalServiceTooltipText(bookingAdditionalServiceCharge)"
                  [matTooltip]="getAdditionalServiceTooltipText(bookingAdditionalServiceCharge)"
                  matTooltipPosition="above"></i>
                {{ bookingSummary.quote.trip.currency | currencyTextIcon }}
                {{ bookingAdditionalServiceCharge?.price | number : "1.2-2" }}
              </div>
              <div class="col-4 p-0 detail-subtitle-value detail-subtitle-value-manual"
                *ngIf="getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
                <span matTooltipClass="additional-service-manually-tooltip"
                  [matTooltip]="getAdditionalServiceManuallyDescription(bookingAdditionalServiceCharge)">
                  Post-Booking
                </span>
              </div>
            </div>
            <div class="row m-0"
              [ngClass]="{ 'py-3': i !== 1 && i !== bookingSummary?.bookingAdditionalServiceCharges.length - 1 }"
              *ngIf="getAdditionalServiceDescription(bookingAdditionalServiceCharge)">
              <div class="col-8 p-0 detail-subtitle"></div>
              <div class="col-4 p-0 detail-subtitle-value">
                <span class="additional">{{ getAdditionalServiceDescription(bookingAdditionalServiceCharge) }}</span>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
      <mat-divider class="my-2 divider"></mat-divider>
      <mat-expansion-panel class="mat-elevation-z0 booking-details-panel" [expanded]="false">
        <mat-expansion-panel-header class="py-3">
          <mat-panel-title class="section-header">
            Cargo details
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="bookingSummary.shipmentTypeCode === ShipmentTypes.FCL">
          <div class="row m-0" [ngClass]="{ 'py-3': i !== 1 && i !== groupedContainers.length - 1 }"
            *ngFor="let equipment of groupedContainers; let i = index">
            <div class="col-6 p-0 detail-subtitle">
              {{ equipment.description }}
            </div>
            <div class="col-6 p-0 detail-subtitle-value">
              x{{ equipment.count }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="bookingSummary.shipmentTypeCode !== ShipmentTypes.FCL">
          <ng-container
            *ngIf="bookingSummary.shipmentTypeCode === ShipmentTypes.AIR || bookingSummary.shipmentTypeCode === ShipmentTypes.LCL">
            <div class="row m-0">
              <div class="col-6 p-0 detail-subtitle">
                {{ "Chargeable Weight" }}
              </div>
              <span class="col p-0 detail-subtitle-value">
                {{ grandTotalChargeableWeightAmount | number : "1.2-2" }}
                {{ grandTotalWeightUnit | weightUnitName | uppercase }}
              </span>
            </div>
          </ng-container>
          <div class="row m-0 pt-3">
            <div class="col-6 p-0 detail-subtitle">
              {{ "Total Weight" }}
            </div>
            <div class="col-6 p-0 detail-subtitle-value">
              {{ grandTotalWeightAmount | number : "1.2-2" }}
              {{ grandTotalWeightUnit | weightUnitName | uppercase }}
            </div>
          </div>
          <div class="row m-0 pt-3">
            <div class="col-8 p-0 detail-subtitle">
              {{
              bookingSummary.shipmentTypeCode === ShipmentTypes.AIR
              ? "Volume"
              : "Total Volume"
              }}
            </div>
            <div class="col-4 p-0 detail-subtitle-value">
              {{ grandTotalVolumeAmount | number : "1.2-2" }}
              {{ grandTotalVolumeUnit | volumeUnitName | uppercase }}
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </div>
  <div class="booking-flow-details-footer">
    <div class="total-cost-wrapper">
      <div class="row m-0">
        <div class="col p-0 detail-subtitle submit">Total Cost</div>
        <div class="col p-0 align-items-end d-flex flex-column">
          <span appSetRoleBasedPrice class="detail-subtitle-value submit"
            [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(bookingSummary.totalPrice)}">
            {{ bookingSummary.quote.trip.currency | currencyTextIcon }}{{ bookingSummary.totalPrice | number : "1.2-2"
            }}
            {{ bookingSummary.quote.trip.currency | currencyName}}
          </span>
          <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
            *ngIf="isShowReverseDiscountPrice(bookingSummary.totalPrice)">
            {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(bookingSummary.totalPrice) | number:
            "1.2-2" }}
          </span>
        </div>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div *ngIf="!isSummary && !hideButtons" class="row m-0 px-3 pb-2 pt-0">
      <div class="col-auto px-0 w-100">
        <button mat-raised-button class="booking-pay-button button-spinner" (click)="onReviewAndPay()"
          [disabled]="!isValid">
          <span class="button-inner">
            Review {{ !isCreditOrganization && !isAgent ? "& Pay" : "" }}
            <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
          </span>
        </button>
      </div>
      <div class="col-12 px-0 w-100" *ngIf="!isExpanded">
        <button mat-flat-button class="booking-draft-button py-2" (click)="onClick()">
          Order Details
        </button>
      </div>
      <div class="col-12 px-0 w-100" *ngIf="isExpanded">
        <button mat-flat-button class="booking-draft-button py-2" (click)="onClick()">
          Close Details
        </button>
      </div>
    </div>
    <div *ngIf="isSummary && !hideButtons"
      class="checkbox-button-wrapper row mx-0 mt-2 justify-content-center px-3 pb-2 pt-0 gap-2">
      <div class="col-12 px-0 text-grey checkbox-content">
        <mat-checkbox [checked]="bookingSummary.isTermsAndConditionsConfirmed"
          [disabled]="bookingSummary?.statusType?.code !== bookingStatusCodes.draft"
          (change)="updateTermsAndConditions($event.checked)">
          <span class="confirmation-text">
            I agree to the <a href="https://ship4wd.com/legal/terms-and-conditions-transport-services.pdf"
              class="link-text" target="_blank">Ship4wd Transport Terms <br> and Conditions</a>.
          </span>
        </mat-checkbox>
      </div>
      <div class="col-12 px-0 w-100">
        <button appRoleBasedHideOrShow [isCredit]="isCreditOrganization" mat-raised-button
          class="booking-pay-button button-spinner" (click)="onPay()" color="primary"
          [disabled]="isSubmitLoading || isLoading || !isTermsConfirmed">
          <span class="button-inner">
            {{isCreditOrganization ? 'Submit' : 'Pay Now'}}
            <mat-spinner diameter="25" *ngIf="isSubmitLoading || isLoading"></mat-spinner>
          </span>
        </button>
      </div>
      <div class="col-12 px-0 w-100" *ngIf="isExpanded">
        <button mat-flat-button class="booking-draft-button py-2" (click)="onClick()">
          Close Details
        </button>
      </div>

      <div class="col-12 px-0 w-100" *ngIf="!isExpanded">
        <button mat-flat-button class="booking-draft-button py-2" (click)="onClick()">
          Order Details
        </button>
      </div>
    </div>
  </div>
</div>