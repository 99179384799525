import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfo } from '../features/user-info/user-info.model';
import { UserInfoService } from '../services/user-info/user-info.service';
import { AccessRoleType } from '../shared.model';

@Injectable({
  providedIn: 'root'
})
export class AccessRoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private userInfoSettings: UserInfoService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let settings: UserInfo = this.userInfoSettings.getSettings();
    const roles = settings.accessRoles;

    if (roles === undefined || roles === null) {
      this.router.navigate(["/dashboard"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    return this.checkAccessRoles(route.data.allowAccess, state.url);
  }

  checkAccessRoles(roles: AccessRoleType[], url: String) {
    const isSatisfied = this.userInfoSettings.isRolesSatisfied(roles);
    if (isSatisfied) {
      return true;
    }
    else {
      this.router.navigate(["/dashboard"], {
        queryParams: { returnUrl: url },
      });
      return false;
    }
  }
}