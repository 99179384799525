import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { NotificationService } from '@ship4wd/ngx-common';
import { TaxIdService } from '../tax-id-input.service';
import { TaxIdQuery } from '../../shared.model';

@Component({
  selector: 'app-tax-id-input',
  templateUrl: './tax-id-input.component.html',
  styleUrls: ['./tax-id-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaxIdInputComponent implements OnChanges {
  @Input() taxIdControl: FormControl = undefined;
  @Input() countryCode: string;
  @Input() placeholder: string = "TAX ID / VAT NUMBER";
  @Input() titleText: string = "Tax ID / VAT Number";
  @Input() appearance: string = "fill";
  @Input() floatLevel: string = "auto";
  @Input() class: string = "w-100";
  @Input() disabled: boolean = false;
  @Input() tooltip: string = "Please add your organization's tax identifier"

  initialValidator: ValidatorFn;
  isLoading = false;
  invalidError = { 'incorrect': true };

  constructor(
    private taxIdService: TaxIdService,
    private notificationService: NotificationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.disabled = changes.disabled?.currentValue != undefined ? changes.disabled?.currentValue : this.disabled;

    this.disabled ?
      this.taxIdControl.disable() :
      this.taxIdControl.enable();

    if (changes.countryCode?.currentValue != undefined) {
      this.taxIdControl.markAsTouched();
    }

    if (!this.disabled) {
      this.validateTaxId();
    }
  }

  validateTaxId(): void {
    if (!this.taxIdControl.value || !this.countryCode) {
      this.taxIdControl.markAsUntouched();
      return;
    }
    const query = new TaxIdQuery();
    query.countryCode = this.countryCode;
    query.taxId = this.taxIdControl.value;

    this.isLoading = true;
    this.taxIdService.isTaxIdValid(query)
      .subscribe((isTaxIdValid: boolean) => {
        if (isTaxIdValid) {
          this.taxIdControl.updateValueAndValidity();
          this.taxIdControl.setValue(query.taxId);
        }
        else {
          this.taxIdControl.setErrors(this.invalidError);
        }
      },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }
}
