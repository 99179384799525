<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="isOrganizationLoaded" [hidden]="isLoading" class="credit-line-main-content">
  <app-dashboard-header *ngIf="!isCreditLinePreapproved && !isCreditLineNew" [isOrdersGridViewMode]="isOrdersGridViewMode" [isCreditLine]="isCreditLine"
    (filters)="onFiltersEvent($event)" (searchText)="onSearchText($event)" (clearFilters)="onClearFilters($event)"
    [title]="'Your Credit'" [isCreditLineNew]="isCreditLineNew" [isCreditLineRejected]="isCreditLineRejected"
    [isCreditLineRequested]="isCreditLineRequested"
    [isCreditLineApprovedOrPreapproved]="isCreditLineApproved || isCreditLinePreapproved"></app-dashboard-header>

  <div *ngIf="isCreditLineNew">
    <app-apply-credit-line></app-apply-credit-line>
  </div>

  <div *ngIf="isCreditLineRequested">
    <app-requested-credit-line></app-requested-credit-line>
  </div>

  <div *ngIf="isCreditLineRejected">
    <app-rejected-credit-line></app-rejected-credit-line>
  </div>

  <div *ngIf="isCreditLinePreapproved">
    <app-eligible-credit-line></app-eligible-credit-line>
  </div>

  <div class="container" *ngIf="isCreditLineApproved">
    <app-claim-credit-line class="d-flex justify-content-center h-100" [isCreditLine]="isCreditLine" [creditLine]="creditLine"></app-claim-credit-line>
  </div>
</div>