import { Component, Input } from '@angular/core';
import { Quote } from '../../../../../../desktop/quotes/quotes.model';

@Component({
  selector: 'app-quote-search-flow-price',
  templateUrl: './flow-price.component.html',
  styleUrls: ['./flow-price.component.scss']
})
export class FlowPriceComponent {
  @Input() quote: Quote;
  @Input() isShowFullNames: boolean;

  get isPartialQuote(): boolean {
    return this.quote.trip.isPreCarriageMissingRate || this.quote.trip.isPostCarriageMissingRate;
  }

  get getPartialQuoteTooltipText(): string {
    const isPreMissing = this.quote.trip.isPreCarriageMissingRate;
    const isPostMissing = this.quote.trip.isPostCarriageMissingRate;

    let missingLegRate = 'pickup & delivery';
    if (isPreMissing && !isPostMissing) {
      missingLegRate = 'pickup';
    }
    else if (!isPreMissing && isPostMissing) {
      missingLegRate = 'delivery';
    }

    return `Door ${missingLegRate} costs are currently
    being processed and will be added to your 
    pricing shortly.`;
  }

  constructor() { }
}
