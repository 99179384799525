<div class="dialog-container">
  <div class="row dialog-message-wrapper">
    <div class="dialog-title-description-wrapper">
      <h4 class="dialog-title">
        Watch Our Short Tutorial To See How To Search For Shipping Quotes
      </h4>
    </div>
    <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/aZcFISt6ibw" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="tutorial-points-container-wrapper">
      <div class="tutorial-points d-flex w-100">
        <div class="row w-100 mt-4 px-0 mx-0 tutorial-points-option">
          <ul>
            <li><span class="material-icons">check</span> Quote, Compare, and Book in Minutes</li>
            <li><span class="material-icons">check</span> Get Real Support from Real People 24/7</li>
            <li><span class="material-icons">check</span> Pay with Credit Card and ACH Debit</li>
            <li><span class="material-icons">check</span> Pay up to 90 days after cargo delivery date</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="search-quote-wrapper">
      <button class="button-spinner button-inner search-quote-button" (click)="onQuoteSearch()">
        Search for a Quote
      </button>
    </div>
  </div>
</div>
