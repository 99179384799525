import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs";
import { ApplicationFileType, Dashboard, DashboardSettings } from "./widgets.model";
import { QuotesResultsSortBy } from "../shared.model";

@Injectable()
export class DashboardService {
  private readonly storageKey = 'dashboardsettings';

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  getByUserAndOrganization(
    userId: string,
    organizationId
  ): Observable<Dashboard> {
    return this.http.get<Dashboard>(
      `${this.internalUrlsService.getApiBaseUrl()}/dashboards/${userId}?organizationId=${organizationId}`
    );
  }

  setDashboard(dashboard: Dashboard): Observable<string | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<string>(
      `${this.internalUrlsService.getApiBaseUrl()}/dashboards`,
      dashboard,
      httpOptions
    );
  }

  getApplicationFile(imageType: ApplicationFileType): Observable<string> {
    return this.http.get(
      `${this.internalUrlsService.getApiBaseUrl()}/applicationFiles/${imageType}`, { responseType: 'text' });
  }

  setDashboardBanner(dashboardBannerUrl: string): void {
    let settings = this.getSettings();
    settings.dashboardBannerUrl = dashboardBannerUrl;
    this.addSettings(settings);
  }

  setQuotesResultsSortBy(quotesResultsSortBy: QuotesResultsSortBy): void {
    let settings = this.getSettings();
    settings.quotesResultsSortBy = quotesResultsSortBy;
    this.addSettings(settings);
  }

  getSettings(): DashboardSettings {
    const oldSettings = this.getSettingsFromStorage();
    return oldSettings || DashboardSettings.createDefault();
  }

  addSettings(settings: DashboardSettings) {
    if (settings) {
      localStorage.setItem(this.storageKey, JSON.stringify(settings));
    }
  }

  removeSettings() {
    const oldSettings = this.getSettingsFromStorage();
    if (oldSettings) {
      localStorage.removeItem(this.storageKey);
    }
  }

  removeDashboards(): void {
    for (let i = 0; i < localStorage.length; i++) {
      if(localStorage.key(i).startsWith("dashboard_"))
      {
        localStorage.removeItem(localStorage.key(i));
      }
    }
  }

  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": contentType ?? "application/json",
      }),
    };
  }

  private getSettingsFromStorage(): DashboardSettings {
    const oldSettingsString = localStorage.getItem(this.storageKey);
    if (!oldSettingsString) {
      return null;
    }

    const oldSettings = JSON.parse(oldSettingsString) as DashboardSettings;
    if (!oldSettings) {
      return null;
    }

    return oldSettings;
  }
}
