import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-quote-search-flow',
  templateUrl: './quote-search-flow-layout.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowLayoutComponent {
  constructor() { }
}
