<div class="row organization-line" *ngIf="data">
  <div class="col-auto">
    <img src="../../../assets/images/s4d.jpg" alt="" />
  </div>
  <div class="col ps-0" [ngClass]="data.kycStatusCode === kycStatusCodes.Pending ? 'line-is-pending' : ''">
    <div class="row">
      <div class="col">
        <span class="line-title" matTooltip="{{ data.organizationName }}">
          {{ data.organizationName }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="line-subtitle" matTooltip="Company Address">
          Company Address
        </span>
      </div>
    </div>
  </div>
  <div class="col-auto">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="listTypes.ACCEPTED">
        <button class="line-button select-button" mat-flat-button color="primary"
          (click)="selectOrganization(data.organizationId, data.organizationName, data.countryCode)" title="Select">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="listTypes.PENDING">
        <div class="row mx-0">
          <div class="col-auto px-0">
            <button class="line-button pending-button" mat-stroked-button color="primary"
              title="{{ data.kycStatusCode | kycStatusCodeName }}" (click)="getOrganizationKycStatus(data)"
              [disabled]="isLoading">
              <mat-spinner class="mx-auto" diameter="20" *ngIf="isLoading"></mat-spinner>
              <mat-icon *ngIf="!isLoading">{{data.kycStatusCode === kycStatusCodes.Pending ? 'av_timer' :
                data.kycStatusCode ===
                kycStatusCodes.Reject ? 'cancel' : 'done_all'}}</mat-icon>
            </button>
          </div>
          <div class="col-auto px-0 ms-2">
            <button class="line-button small-button chat-icon" mat-flat-button
              (click)="getConversation(data.organizationId)">
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="listTypes.INVITED">
        <div class="row mx-0">
          <div class="col-auto px-0">
            <button class="line-button invited-button" mat-flat-button color="primary" (click)="acceptInvite(data.id)"
              [disabled]="isLoading">
              Accept
            </button>
          </div>
          <div class="col-auto px-0 ms-2">
            <button class="line-button small-button close-icon" mat-flat-button (click)="rejectInvite(data.id)"
              [disabled]="isLoading"></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #organizationRejectedTemplate>
      Thank you for your interest in Ship4wd.
      Unfortunately, we are unable to approve your organization ({{data.organizationName}})
      application
      at this time.
      If you would like more information on why your application was unsuccessful, you can contact
      us (<a href="mailto:support@ship4wd.com">support@ship4wd.com</a>).
    </ng-template>
    <ng-template #organizationPendingTemplate>
      We are reviewing your account organization {{data.organizationName}}.
      This usually takes less than an hour.
      Once your account organization is approved, we’ll send you a confirmation email.
    </ng-template>
  </div>
</div>