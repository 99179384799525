import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NotificationDialogType, NotificationService } from '@ship4wd/ngx-common';
import { FileUploaderMode, LayoutType, FileInfo } from '@ship4wd/ngx-manager-ui';
import { BookingFile, BookingStatusCode, BookingSummary, CertificateSummary, Commodity } from '../../../shared/bookings/bookings.model';
import { BookingPaidStatusCode, Page, ShipmentType, SupportedFileFormats } from '../../../shared/shared.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { LayoutService } from '../../layout/layout.service';
import { LayoutMode } from '../../layout/layout.model';
import { AdditionalService, AdditionalServiceType, AdditionalServicesQueryParameters, AdditionalServicesQuery } from '../../../shared/additional-services/additional-services.model';
import { BookingDocumentsService } from '../../../shared/bookings/booking-documents/booking-documents.service';
import { BookingFlowService } from '../booking-flow/booking-flow.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { SupportRequestsService } from '../../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../support-requests/support-requests.model';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewBookingComponent implements OnInit, AfterViewInit {
  bookingId: string;
  bookingSummary: BookingSummary;
  shipmentTypes = ShipmentType;
  isLoading = false;
  additionalServices: AdditionalService[];
  bookingAdditionalServices: AdditionalService[] = [];
  additionalServiceType = AdditionalServiceType;
  certificateSummary: CertificateSummary;

  isCreditOrganization: boolean = false;
  isFinanceEnabled: boolean = false;
  isSubmitLoading: boolean = false;
  hideButtons: boolean = true;
  fileUploaderMode = FileUploaderMode;
  fileUploaderLayoutType = LayoutType;
  supportedFileFormats = SupportedFileFormats;

  constructor(
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private bookingDocumentsService: BookingDocumentsService,
    private bookingFlowService: BookingFlowService,
    private utilityService: UtilityService,
    private supportRequestsService: SupportRequestsService
  ) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params.id;
    this.getBookingSummaryById(this.bookingId);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.BOOKINGFLOW);
      this.layoutService.setToolbarTitle("Order Details");
      this.layoutService.setToolbarDescription("Review your order details");
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarBackUrl("/dashboard");
    }, 0);
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onPrint(id: string): void {
    window.open(
      `/booking/pdf/${id}?mode=print`,
      "",
      "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
    );
  }

  onGetSecureFileUrl(fileId: string): void {
    this.bookingDocumentsService
      .getSignedUrl(this.bookingSummary.id, fileId)
      .subscribe(
        (x) => {
          setTimeout(() => {
            window.open(x.url, "_blank");
          });
        },
        (error) => {
          this.notificationService.error(error);
        }
      );
  }

  onListingFilesChange(files: FileInfo[]): void {
    const filesToAdd = files.filter((x) => !x.id);
    this.removeExistedFile(files);
    this.addFiles(filesToAdd, files);
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  navigateToLink(link: string): void {
    window.open(link, "_blank");
  }

  getAdditionalServices(): void {
    if (this.bookingSummary.bookingAdditionalServiceCharges.length > 0) {
      const query = {
        bookingId: this.bookingId,
        includeProperties: true,
        sortBy: AdditionalServicesQueryParameters.name,
        pageNo: 1,
        pageSize: 10,
      } as AdditionalServicesQuery;

      this.bookingFlowService
        .getAdditionalServices(query)
        .subscribe(
          (x: Page<AdditionalService>) => {
            this.additionalServices = x.items;
          },
          (error: any) => {
            this.notificationService.error(error);
          }
        )
        .add(() => {
          const uniqueAdditionalService = [
            ...new Set(
              this.bookingSummary?.bookingAdditionalServiceCharges?.map(
                (x) => x.additionalServiceCharge.additionalServiceId
              )
            ),
          ];
          uniqueAdditionalService?.forEach((x) => {
            this.bookingAdditionalServices.push(
              ...this.additionalServices?.filter((y) => y.id === x)
            );
          });
        });
    }
  }

  getFormattedBookingAddress(bookingSearchType: 'from' | 'to'): string {
    const bookingSearch = bookingSearchType === 'from' ? this.bookingSummary.fromBookingSearch : this.bookingSummary.toBookingSearch;
    let addressParts: string[] = [];

    if (bookingSearch?.street) {
      addressParts.push(bookingSearch.street);
    }

    if (bookingSearch?.displayName) {
      addressParts.push(bookingSearch.displayName);
    }

    if (bookingSearch?.countryName) {
      addressParts.push(bookingSearch.countryName);
    }

    if (bookingSearch?.postcode) {
      addressParts.push(bookingSearch.postcode);
    }

    return addressParts.join(', ');
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }

  private getBookingSummaryById(id: string) {
    this.isLoading = true;
    this.bookingsService
      .getSummaryById(id)
      .subscribe(
        (bookingSummary: BookingSummary) => {
          this.bookingSummary = bookingSummary;
          this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData());
          this.hideButtons = this.isHideButtons(this.bookingSummary);
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.getAdditionalServices();
        this.getBookingInsuranceCertificate();
      });
  }

  private removeExistedFile(files: FileInfo[]): void {
    const filesToRemove = this.bookingSummary.documents.filter(
      (x) => !files.some((y) => y.id === x.id)
    );
    if (filesToRemove && filesToRemove.length > 0) {
      this.deleteFiles(filesToRemove);
    }
  }

  private isHideButtons(bookingSummary: BookingSummary): boolean {
    if (bookingSummary.statusType.code !== BookingStatusCode.draft &&
      bookingSummary.bookingPaidStatusCode === BookingPaidStatusCode.paid) {
      return true;
    }
    return false;
  }

  private deleteFiles(files: FileInfo[]): void {
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.bookingSummary.id, fileToRemove.id)
        .subscribe((data) => {
          const index = this.bookingSummary.documents.findIndex(
            (x) => x.id === fileToRemove.id
          );
          if (index > -1) {
            this.bookingSummary.documents =
              this.bookingSummary.documents.filter(
                (item) => item.id !== fileToRemove.id
              );
          }
        });
    }
  }

  private addFiles(files: FileInfo[], allFiles: FileInfo[]): void {
    if (files && files.length > 0) {
      for (const fileToAdd of files) {
        this.bookingDocumentsService
          .add(this.bookingSummary.id, fileToAdd)
          .subscribe(
            (x) => this.finalizeFileLoading(fileToAdd, x, allFiles),
            (error) => {
              this.notificationService.notifyDialog(
                error.error,
                NotificationDialogType.error
              );
            }
          );
      }
    }
  }

  private finalizeFileLoading(
    fileToAdd: FileInfo,
    response: any,
    allFiles: FileInfo[]
  ): void {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round((100 * response.loaded) / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.removeExistedFileFromFileArray(fileToAdd, allFiles);
      const existingDocument = this.bookingSummary.documents.find(
        (x) => x.id === fileToAdd.id
      );
      if (existingDocument === undefined) {
        this.bookingSummary.documents.push(addedFile);
      }
    }
  }

  private removeExistedFileFromFileArray(
    file: FileInfo,
    allFiles: FileInfo[]
  ): void {
    const filesToRemove = allFiles.filter((x) => file.name === x.name);
    if (filesToRemove && filesToRemove.length > 1) {
      const index = allFiles.indexOf(filesToRemove[0]);
      allFiles.splice(index, 1);
    }
  }

  private getBookingInsuranceCertificate(): void {
    const hasInsuranceService =
      this.bookingSummary.bookingAdditionalServiceCharges.some(
        (x) =>
          x.additionalServiceCharge.additionalServiceId ==
          AdditionalServiceType.insurance
      );

    if (
      hasInsuranceService &&
      this.bookingSummary.bookingPaidStatusCode == BookingPaidStatusCode.paid
    ) {
      this.getInsuranceCertificateByBookingId(this.bookingId);
    }
  }

  private getInsuranceCertificateByBookingId(id: string): void {
    this.bookingsService
      .getInsuranceCertificateById(id)
      .subscribe((response: CertificateSummary) => {
        if (response) {
          this.certificateSummary = response;
        }
      });
  }

  private createSupportRequestDialogData(): SupportRequestDialogData {
    return {
      isPopupAutoOpened: false,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: this.bookingSummary.bolOrderNumber,
      quoteSearchId: this.bookingSummary.quoteSearchId
    } as SupportRequestDialogData
  }
}
