import { Component, Input, TemplateRef, ViewContainerRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 's4d-tooltip',
  templateUrl: './s4d-tooltip.component.html',
  styleUrls: ['./s4d-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S4DTooltipComponent {
  @Input() content: string;
  @Input() template: TemplateRef<any>;
  @Input() viewContainerRef: ViewContainerRef;
}
