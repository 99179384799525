<div class="row organization-line">
  <div class="col-auto px-0 d-flex align-items-center">
    <button mat-icon-button aria-label="Organization info">
    </button>
  </div>
  <div class="col-auto px-3">
    <img src="../../../assets/images/s4d.jpg" alt="" />
  </div>
  <div class="col ps-0">
    <div class="row">
      <div class="col line-title">{{ data.organizationName }}</div>
    </div>
    <div class="row">
      <div class="col line-subtitle">Company Address</div>
    </div>
  </div>
  <div class="col-4 px-0">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="listTypes.ACCEPTED">
        <button class="line-button select-button button-spinner" mat-flat-button color="primary"
          (click)="selectOrganization(data.organizationId, data.organizationName, data.countryCode)">
          <span class="button-inner">
            <span>Select</span>
            <mat-spinner diameter="25"
              *ngIf="isSelectLoading && selectedOrganizationId === data.organizationId"></mat-spinner>
          </span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="listTypes.PENDING">
        <div class="row mx-0">
          <div class="col-auto px-0">
            <button class="line-button pending-button" mat-stroked-button color="primary"
              (click)="getOrganizationKycStatus(data)" [disabled]="isLoading">
              <span class="button-inner">
                {{ data.kycStatusCode | kycStatusCodeName }}
                <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
              </span>
            </button>
          </div>
          <div class="col-auto px-0 ms-2">
            <button class="line-button small-button chat-icon" mat-flat-button
              (click)="getConversation(data.organizationId)">
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="listTypes.INVITED">
        <div class="row mx-0">
          <div class="col-auto px-0">
            <button class="line-button invited-button" mat-flat-button color="primary" (click)="acceptInvite(data.id)"
              [disabled]="isLoading">
              Accept
            </button>
          </div>

          <div class="col-auto px-0 ml-2">
            <button class="line-button small-button close-icon" mat-flat-button (click)="rejectInvite(data.id)"
              [disabled]="isLoading"></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #organizationRejectedTemplate>
      Thank you for your interest in Ship4wd.
      Unfortunately, we are unable to approve your organization ({{data.organizationName}})
      application
      at this time.
      If you would like more information on why your application was unsuccessful, you can contact
      us (<a href="mailto:support@ship4wd.com">support@ship4wd.com</a>).
    </ng-template>
    <ng-template #organizationPendingTemplate>
      We are reviewing your account organization {{data.organizationName}}.
      This usually takes less than an hour.
      Once your account organization is approved, we’ll send you a confirmation email.
    </ng-template>
  </div>
</div>