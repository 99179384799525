import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Variant } from '@amplitude/experiment-js-client';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { AmplitudeService } from '../../../shared/amplitude/amplitude.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { amplitudeFlags } from '../../../shared/amplitude/amplitude.constants';
import { environment } from '../../../../environments/environment';
import { TeamMemberService } from '../../manage-organization/organization-members/team-member-dialog/team-member.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { WelcomeTutorialService } from '../../welcome-tutorial/welcome-tutorial.service';

@Component({
  selector: 'app-quote-search-flow',
  templateUrl: './quote-search-flow.component.html',
  styleUrls: ['./quote-search-flow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowComponent implements OnInit {
  variant: Variant;
  isOldSearchStyle: boolean = true;

  constructor(
    private amplitudeService: AmplitudeService,
    private organizationsService: OrganizationsService,
    private userInfoService: UserInfoService,
    private teamMemberService: TeamMemberService,
    private welcomeTutorialService: WelcomeTutorialService
  ) {}

  ngOnInit(): void {
    this.isOldSearchStyle = this.checkQuoteSearchFlow();
    this.initiateWelcomeTutorial();
    this.updateTeamMemberSettings();
  }

  private checkQuoteSearchFlow(): boolean {
    if (this.getNewQuoteSearchFlowEnabled()) {
      this.variant = this.amplitudeService.getFlag(amplitudeFlags.quoteSearchFlagKey);
      this.isOldSearchStyle = this.amplitudeService.checkQuoteSearchStyle(this.variant);
    }

    return this.isOldSearchStyle;
  }

  private getNewQuoteSearchFlowEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.newQuoteSearchFlowEnabled;
      case 'sb':
        return environment.sb.newQuoteSearchFlowEnabled;
      default:
        return environment.newQuoteSearchFlowEnabled;
    }
  }

  private initiateWelcomeTutorial(): void {
    const userInfo = this.userInfoService.getSettings();
    const teamMemberSettings = userInfo.userSettings;

    if (teamMemberSettings.isFirstLogin) {
      this.welcomeTutorialService.openWelcomeTutorialDialog();
    }
  }

  private updateTeamMemberSettings(): void {
    const userInfo = this.userInfoService.getSettings();
    const teamMemberSettings = userInfo.userSettings;

    if (teamMemberSettings.isFirstLogin) {
      teamMemberSettings.isFirstLogin = false;
      this.teamMemberService.updateTeamMemberSettings(userInfo.teamMemberId, teamMemberSettings).subscribe(() => {
        this.organizationsService.setUserSettings(teamMemberSettings);
      });
    }
  }
}
