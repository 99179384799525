<div class="files-documents-list-container" *ngIf="bookingId && bookingDocuments">
  <table mat-table [dataSource]="dataSource" class="w-100" *ngIf="dataSource.data.length">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>File Name</th>
      <td mat-cell *matCellDef="let item">
        <span class="file-icon"></span>
        <span class="file-title" matTooltip="{{ item.name }}">
          {{ item.name }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let item;">
        <div class="comment-wrapper">
          <div class="non-editable-comment-box">
            <span class="comment-text">{{ getCommentData(item.comments) }}</span>
            <button mat-menu-item class="edit-comment-button" (click)="onEditComment(item)"></button>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="documentType">
      <th mat-header-cell *matHeaderCellDef>Document Type</th>
      <td mat-cell *matCellDef="let item">
        {{ item.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="uploaded">
      <th mat-header-cell *matHeaderCellDef>Uploaded</th>
      <td mat-cell *matCellDef="let item">
        <span class="file-ypload-date">{{ item.createTimeUtc | date: "dd MMM, yyyy" }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <button *ngIf="pageType !== bookingFilesDocumentsPage.finance" mat-icon-button
          class="booking-contact-menu-button" [matMenuTriggerFor]="menu">
          <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
        </button>
        <mat-menu #menu="matMenu" class="files-documents-menu">
          <button mat-menu-item *ngIf="canView && isSecuredUrl" (click)="onViewFile(item)">
            <span class="view-file-icon"></span>
            <span class="file-text">View/Download</span>
          </button>
          <button mat-menu-item [hidden]="true">
            <span class="sign-file-icon"></span>
            <span class="file-text">Sign File</span>
          </button>
        </mat-menu>
        <button mat-button *ngIf="canView && isSecuredUrl && pageType === bookingFilesDocumentsPage.finance"
          class="booking-contact-menu-button" (click)="onViewFile(item)">
          <span class="view-file-icon"></span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>