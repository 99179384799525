import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode } from '../shared.model';

@Pipe({ name: 'currencyIcon' })
export class CurrencyIconPipe implements PipeTransform {
  private currencyIcons = {};

  constructor() {
    this.currencyIcons[CurrencyCode.USD] = 'attach_money';
    this.currencyIcons[CurrencyCode.EUR] = 'euro';
  }

  transform(value?: CurrencyCode): string {
    return this.currencyIcons[value] ?? '';
  }
}
