import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SelectLanguageComponent } from "./select-language/select-language";

@Injectable()
export class LanguagePopUpService {

  constructor(private dialog: MatDialog) { }

  openSelectLanguagePopUp(): void {
    this.dialog.open(SelectLanguageComponent);
  }
}
