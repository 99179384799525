import { Pipe, PipeTransform } from '@angular/core';
import { RateServiceType } from '../shared.model';

@Pipe({ name: 'rateServiceTypeName' })
export class RateServiceTypeNamePipe implements PipeTransform {
  private rateServiceTypes = {};
  private rateServiceShortTypes = {};

  constructor() {
    this.rateServiceTypes[RateServiceType.Standard] = 'Standard';
    this.rateServiceTypes[RateServiceType.Premium] = 'Premium';
    this.rateServiceTypes[RateServiceType.Express] = 'Express';
    this.rateServiceTypes[RateServiceType.Other] = 'Other';
    this.rateServiceTypes[RateServiceType.Basic] = 'Basic';
    this.rateServiceShortTypes[RateServiceType.Standard] = 'Std';
    this.rateServiceShortTypes[RateServiceType.Premium] = 'Prm';
    this.rateServiceShortTypes[RateServiceType.Express] = 'Exp';
    this.rateServiceShortTypes[RateServiceType.Basic] = 'Bas';
    this.rateServiceShortTypes[RateServiceType.Other] = '';
  }

  transform(value?: RateServiceType, isShortMode: boolean = false): string {
    if (isShortMode) {
      return this.rateServiceShortTypes[value] ?? '';
    }
    return this.rateServiceTypes[value] ?? '';
  }
}
