<div *ngIf="isLoading; else loaded" class="loading">
    <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
    <div *ngIf="bookings" class="orders" id="order-list"
        [ngClass]="!openOrders?.length && !submittedOrders?.length && !completedOrders?.length ? 'to-center' : ''">
        <h2 *ngIf="openOrders?.length" class="orders-title">
            Open Orders
        </h2>
        <ng-container *ngFor="let booking of openOrders">
            <app-order (canceledBookingEvent)="cancelBooking($event)" [booking]="booking" class="col-12" #order
                [id]="booking.id"></app-order>
        </ng-container>

        <h2 *ngIf="submittedOrders?.length" class="orders-title">
            Submitted Orders
        </h2>
        <ng-container *ngFor="let booking of submittedOrders">
            <app-order (canceledBookingEvent)="cancelBooking($event)" [booking]="booking" class="col-12" #order
                [id]="booking.id"></app-order>
        </ng-container>

        <h2 *ngIf="completedOrders?.length" class="orders-title">
            Complete Orders
        </h2>
        <ng-container *ngFor="let booking of completedOrders">
            <app-order (canceledBookingEvent)="cancelBooking($event)" [booking]="booking" class="col-12" #order
                [id]="booking.id"></app-order>
        </ng-container>

        <div *ngIf="totalBookings > bookings.length" class="row pb-4">
            <div class="col-12 pe-0 text-center">
                <a *ngIf="!isLoading" (click)="onLoadMore()">Load more data...</a>
                <span *ngIf="isLoading" class="material-icons load-icon">refresh</span>
            </div>
        </div>

        <ng-container *ngIf="!openOrders?.length && !submittedOrders?.length && !completedOrders?.length && !isCheckBookingsCount">
            <div class="bookings-warning">
                <div class="row">
                    <div class="col-auto d-flex align-items-center">
                        <mat-icon class="bookings-warning-icon">warning</mat-icon>
                    </div>
                    <div class="col d-flex align-items-center bookings-warning-border">
                        <span class="bookings-warning-description">
                            There is no booking{{searchText || filters ? ' for this filter' : ''}}.
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>