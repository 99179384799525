import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { OrganizationListType } from "../../../shared/shared.model";

@Component({
  selector: "app-organizations-list",
  templateUrl: "./organizations-list.component.html",
  styleUrls: ["./organizations-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationsListComponent implements OnInit {
  @Input() type: OrganizationListType = OrganizationListType.ACCEPTED;
  @Input() data: [] = [];
  @Output() refreshPage = new EventEmitter();

  title: string = "";

  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case OrganizationListType.ACCEPTED:
        this.title = "Accepted Organizations";
        break;
      case OrganizationListType.PENDING:
        this.title = "Pending approval organizations (KYC)";
        break;
      case OrganizationListType.INVITED:
        this.title = "Invites";
        break;
      default:
        this.title = "";
        break;
    }
  }

  refreshOrganizationsList(): void {
    this.refreshPage.emit();
  }
}
