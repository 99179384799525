import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@ship4wd/ngx-common';
import { FileInfo, FileUploaderMode, LayoutType } from '@ship4wd/ngx-manager-ui';
import { BookingDocumentsService } from '../../../shared/bookings/booking-documents/booking-documents.service';
import { Booking, BookingFile } from '../../../shared/bookings/bookings.model';
import { FilesDocumentsService } from '../../../shared/files-documents/files-documents.service';
import { UpdatePayment } from '../../../shared/payments/payments.model';
import { PaymentsService } from '../../../shared/payments/payments.service';
import { DocumentTypeCode, PaymentStatusTransition } from '../../../shared/shared.model';
import { LayoutService } from '../../layout/layout.service';
import { LayoutMode } from '../../layout/layout.model';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmPaymentComponent {
  booking: Booking;
  bookingId: string;
  paymentId: string;
  documentType: DocumentTypeCode;
  FileUploaderMode = FileUploaderMode;
  LayoutType = LayoutType;
  documentNameControl: FormControl = new FormControl();
  bookingDocuments: BookingFile[] = []
  filteredOptions: Observable<BookingFile[]>;
  listingFiles: FileInfo[] = [];
  isLoading = false;
  isFileUploaded = false;

  @ViewChild('header') header: TemplateRef<ElementRef>;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private bookingDocumentsService: BookingDocumentsService,
    private filesDocumentsService: FilesDocumentsService,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService) {
    this.bookingId = this.route.snapshot.params.bookingId;
    this.paymentId = route.snapshot.params.id;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.PAYMENT);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(true);
      this.layoutService.setToolbarTitle("Confirm Payment");
      this.layoutService.setToolbarDescription("Upload a payment verification");
      this.layoutService.setWrapperHeader(this.header);
    }, 0);
  }

  confirmPayment(): void {
    this.isLoading = true;
    var updatePayment = {
      id: this.paymentId,
      transition: PaymentStatusTransition.offlineDocumentsUploaded
    } as UpdatePayment;

    this.paymentsService.updatePayment(updatePayment).subscribe(() => {
      this.router.navigate(['/payments', this.bookingId]);
    }).add(() => (this.isLoading = false));
  }

  getBookingDocuments(): FileInfo[] {
    return this.listingFiles ?? [];
  }

  onListingFilesChange(files: FileInfo[]): void {
    const filesToAdd = files.filter(x => !x.id);
    const filesToRemove = this.listingFiles.filter(x => !files.some(y => y === x));
    this.listingFiles = files;
    this.deleteFiles(filesToRemove);
    this.addFiles(filesToAdd);
  }

  getSecureFileUrl(fileId: string) {
    this.bookingDocumentsService
      .getSignedUrl(this.bookingId, fileId)
      .subscribe(x => {
        window.open(x.url, "_blank");
      },
        error => {
          this.notificationService.error(error);
        });
  }

  private addFiles(files: FileInfo[]): void {
    for (const fileToAdd of files) {
      this.filesDocumentsService
        .add(this.bookingId, fileToAdd, null, DocumentTypeCode.PAYP)
        .subscribe(x => {
          this.finalizeFileLoading(fileToAdd, x);
          this.isFileUploaded = true;
        },
          error => {
            this.notificationService.error(error);
          });
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any): void {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round(100 * response.loaded / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
    }
  }

  private deleteFiles(files: FileInfo[]): void {
    this.isFileUploaded = false;
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.bookingId, fileToRemove.id)
        .subscribe(data => {
        });
    }
  }
}
