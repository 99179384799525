<div class="calendar-custom-header">
  <div class="mat-calendar-header pt-0">
    <div class="mat-calendar-header-nav">
      <button mat-icon-button [disabled]="hasPreviousDate" (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <div class="mat-calendar-header-period">
        <span class="mat-calendar-header-period-label">{{ periodLabel }}</span>
      </div>
      <button mat-icon-button [disabled]="hasNextDate" (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <div class="mat-calendar-header-dates">
      <span>Available Dates: {{ minDateLabel }} - {{ maxDateLabel }}</span>
    </div>
  </div>
</div>