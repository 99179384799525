import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NotificationService, QueryUtils } from '@ship4wd/ngx-common';
import { FourtySeasPaymentService } from '../../../../../shared/payments/fourty-seas-payment.service';
import { PaymentRequestAndPaymentsQueryParameters, RequestAndPayment, RequestAndPaymentQuery } from '../../../../../shared/requests-and-payments/requests-and-payments.model';
import { RequestAndPaymentService } from '../../../../../shared/requests-and-payments/requests-and-payments.service';
import { BookingFilesDocumentsPage, BookingViewDetailsPage, CurrencyCode, Page, PaymentReasonCode, PaymentStatusCode, PaymentStatusTransition, PaymentTermCode } from '../../../../../shared/shared.model';
import { UpdatePayment } from '../../../../../shared/payments/payments.model';
import { PaymentsService } from '../../../../../shared/payments/payments.service';
import { BookingsService } from '../../../../../shared/bookings/bookings.service';
import { PaymentConfirmationDialogComponent } from '../../../order/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { PaymentBankDetailsComponent } from '../../../../../shared/payments/payment-bank-details/payment-bank-details.component';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { TeamMember } from '../../../../../desktop/manage-organization/manage-organization.model';
import { OrganizationsService } from '../../../../../desktop/organizations/organizations.service';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { FourtySeasInitDialogService } from '../../../../../shared/payments/fourty-seas-init-dialog.service';

@Component({
  selector: 'app-requests-and-payments-list',
  templateUrl: './requests-and-payments-list.component.html',
  styleUrls: ['./requests-and-payments-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestsAndPaymentsListComponent implements OnInit {
  @Input() booking: Booking = null;
  @Input() organizationId: string = null;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  dataSource: MatTableDataSource<RequestAndPayment> = new MatTableDataSource<RequestAndPayment>();
  isLoading = true;
  isSortLoading: boolean = false;
  onPayIsLoading = false;
  payItemId: string = null;
  isCreditLineMode = false;
  isNoCreditLines = false;
  pageNo: number = 1;
  statusCodes = PaymentStatusCode;
  currency = CurrencyCode.USD;
  teamMember: TeamMember;
  requestsAndPayments: RequestAndPayment[] = [];
  totalRequestsAndPayments: number = 0;

  columns = [
    'paymentNumber',
    'duePaymentDate',
    'amount',
    'reason',
    'updateTimeUtc',
    'status',
    'progress',
    'action'
  ];

  sortParameters = [
    { key: PaymentRequestAndPaymentsQueryParameters.paymentNumber, name: 'paymentNumber' },
    { key: PaymentRequestAndPaymentsQueryParameters.duePaymentDate, name: 'duePaymentDate' },
    { key: PaymentRequestAndPaymentsQueryParameters.amount, name: 'amount' },
    { key: PaymentRequestAndPaymentsQueryParameters.reason, name: 'reason' },
    { key: PaymentRequestAndPaymentsQueryParameters.updateTimeUtc, name: 'updateTimeUtc' },
    { key: PaymentRequestAndPaymentsQueryParameters.status, name: 'status' },
    { key: PaymentRequestAndPaymentsQueryParameters.progress, name: 'progress' }
  ];

  constructor(
    public dialog: MatDialog,
    private requestAndPaymentService: RequestAndPaymentService,
    private bookingsService: BookingsService,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private router: Router,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private fourtySeasInitDialogService : FourtySeasInitDialogService
  ) { }

  ngOnInit(): void {
    this.getRequestsAndPayments();
    this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
      if (next) {
        this.getRequestsAndPayments();
        this.onPayIsLoading = false
        this.payItemId = null;
      }
    });
  }

  getRequestsAndPayments(): void {
    if (this.booking == null) {
      this.isCreditLineMode = true;
      this.getRequestsAndPaymentsByOrganization();
    }
    else {
      this.getRequestsAndPaymentsByBookingId();
    }
  }

  getIconColor(statusCode: PaymentStatusCode): any {
    switch (statusCode) {
      case PaymentStatusCode.toBePaid: return null;
      case PaymentStatusCode.inProgress: null;
      case PaymentStatusCode.failed: return false;
      case PaymentStatusCode.paid: return true;
      case PaymentStatusCode.draft: return null;
      case PaymentStatusCode.toPayOffline: return null;
      case PaymentStatusCode.toBeApproved: return null;
      case PaymentStatusCode.scheduled: return null;
      default: return false;

    }
  }

  onFilesDocuments(bookingId: string): void {
    const retunUrl = this.isCreditLineMode ? BookingFilesDocumentsPage.creditLine : BookingFilesDocumentsPage.finance;
    const retunMainUrl = this.isCreditLineMode ? BookingViewDetailsPage.creditLine : BookingViewDetailsPage.finance;
    this.router.navigate(['/files-documents', retunMainUrl, retunUrl, bookingId]);
  }

  onPay(id: string, paymentId: string): void {
    this.fourtySeasInitDialogService.openFourtySeasInitDialog();
    this.payItemId = id;
    this.onPayIsLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: paymentId,
            transition: PaymentStatusTransition.checkoutInitialized,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(async () => this.fourtySeasPaymentService.init(id, this.teamMember.id, this.booking,
          PaymentStatusTransition.checkoutInitialized).subscribe()
        ))
      .subscribe(() => { }, (error) => {
        this.openPopup('Error. Checkout initialization has been finished with failure');
        this.isLoading = false;
        this.onPayIsLoading = false;
      })
  }

  onRetryPay(id: string, paymentId: string): void {
    this.payItemId = id;
    this.onPayIsLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: paymentId,
            transition: PaymentStatusTransition.paymentRetried,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(async () => this.fourtySeasPaymentService.init(id, this.teamMember.id, this.booking,
          PaymentStatusTransition.paymentRetried).subscribe()
        ))
      .subscribe(() => { }, (error) => {
        this.openPopup('Error. Checkout initialization has been finished with failure');
        this.isLoading = false;
        this.onPayIsLoading = false;
      })
  }

  onConfirm(paymentId: string, paymentNumber: string): void {
    this.openPaymentConfirmDialog(paymentId, paymentNumber);
  }

  onQuoteSearch(): void {
    this.router.navigate(['/quote-search'])
  }

  onChangePaymentMethod(requestAndPayment: RequestAndPayment): void {
    this.onPayIsLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.organizationsService.getTeamMember(this.booking.organizationId)),
        tap((teamMember: TeamMember) => {
          this.teamMember = teamMember;
        }),
        switchMap(() => {
          var updatePayment = {
            id: requestAndPayment.paymentId,
            transition: PaymentStatusTransition.paymentMethodChanged,
            teamMemberId: this.teamMember.id
          } as UpdatePayment;

          return this.paymentsService.updatePayment(updatePayment);
        }),
        switchMap(async () => this.fourtySeasPaymentService.init(requestAndPayment.id, this.teamMember.id, this.booking,
          PaymentStatusTransition.paymentMethodChanged, 'finance').subscribe()
        ))
      .subscribe(() => { })
  }

  onShowDetailsMethod(requestAndPayment: RequestAndPayment): void {
    this.dialog.open(PaymentBankDetailsComponent, {
      autoFocus: false,
      data: {
        refId: requestAndPayment.paymentNumber,
      },
    });
  }

  showPayOrder(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toBePaid || statusCode === PaymentStatusCode.failed;
  }

  showConfirmButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  showChangePaymentMethodButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  showBankDetailsButton(statusCode: PaymentStatusCode): boolean {
    return statusCode === PaymentStatusCode.toPayOffline;
  }

  getTotalAmount(requestAndPayment: RequestAndPayment): number {
    return requestAndPayment.amount + (requestAndPayment.fees != null ? requestAndPayment.fees : 0);
  }

  navigateToBookingDetails(bookingId: Booking): void {
    const retunUrl = this.isCreditLineMode ? BookingViewDetailsPage.creditLine : BookingViewDetailsPage.finance;
    this.router.navigate(['/booking/view', retunUrl, bookingId]);
  }

  onSort(sort: MatSort): void {
    this.pageNo = 1;
    this.sort = sort;
    this.isSortLoading = true;
    this.getRequestsAndPaymentsByOrganization();
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok"
      },
    });
  }

  private openPaymentConfirmDialog(paymentId: string, paymentNumber: string): void {
    this.bookingsService
      .getById(this.booking.id).
      subscribe((booking) => {
        const dialogRef = this.dialog.open(PaymentConfirmationDialogComponent, {
          panelClass: 'payment-confirmation',
          autoFocus: false,
          data: {
            booking: booking,
            paymentId: paymentId,
            paymentNumber: paymentNumber
          }
        });

        dialogRef.afterClosed().subscribe(() => {
          this.isLoading = true;
          this.getRequestsAndPayments();
        });
      });

  }

  private getRequestsAndPaymentsByBookingId(): void {
    this.requestAndPaymentService.getAllByBookingId(this.booking.id).subscribe(
      (x: RequestAndPayment[]) => {
        this.dataSource.data = x;
      },
      (error) => this.notificationService.error(error)
    )
      .add(() => this.isLoading = false);
  }

  private getRequestsAndPaymentsByOrganization(): void {
    const query = {
      statusTypes: [PaymentStatusCode.inProgress, PaymentStatusCode.scheduled],
      paymentTerm: PaymentTermCode.net,
      pageNo: 1,
      pageSize: 500,
      sortDesc: true,
      sortBy: PaymentRequestAndPaymentsQueryParameters.updateTimeUtc
    } as RequestAndPaymentQuery;

    if (this.sort?.active != undefined) {
      query.sortBy = QueryUtils.getSortBy(this.sortParameters, this.sort?.active, PaymentRequestAndPaymentsQueryParameters.updateTimeUtc);
    }
    query.sortDesc = this.sort?.direction != undefined ? QueryUtils.getSortDesc(this.sort?.direction) : true;

    this.requestAndPaymentService.getByOrganizationQuery(query, this.organizationId)
      .subscribe((x: Page<RequestAndPayment>) => {
        this.dataSource.data = x.items;

        if (x.items.length === 0) {
          this.isNoCreditLines = true;
          this.setEmptyCreditLines();
        }
      },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
        this.isSortLoading = false;
      });
  }

  private setEmptyCreditLines(): void {
    let lines: RequestAndPayment[] = [];
    for (let index = 0; index < 5; index++) {
      let line = {
        id: index.toString(),
        paymentId: '000000',
        bookingId: '000000',
        paymentNumber: 0,
        dueDate: '1900-01-01',
        amount: 0,
        reasonCode: PaymentReasonCode.initialPayment,
        reasonDisplayName: '000000',
        statusCode: PaymentStatusCode.draft,
        statusDisplayName: '000000',
        fees: 0,
        externalStatus: '000000',
        transactionId: '000000',
        createTimeUtc: '1900-01-01',
        updateTimeUtc: '1900-01-01',
      } as RequestAndPayment;

      lines.push(line);
    }

    this.dataSource.data = lines;
  }
}
