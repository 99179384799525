<div class="order" #order>
  <div class="col order-left">
    <div class="row order-trip-wrapper">
      <div class="col order-trip">
        <p class="h1">
          <span matTooltip='{{ booking.fromDisplayName }}' matTooltipPosition="before">{{ fromDisplayName | titlecase
            }}</span>
        </p>
      </div>
      <div class="col order-trip">
        <p class="h1">
          <span matTooltip='{{ booking.toDisplayName }}' matTooltipPosition="after">{{ toDisplayName | titlecase
            }}</span>
        </p>
      </div>
    </div>
    <div class="row order-trip-wrapper">
      <div class="col order-trip">
        <p class="trip-date-title">Estimated departure</p>
        <p class="trip-date">{{ checkDateNotNullOrEmpty(booking.etd) ? (booking.etd | date) : "Schedule Not Available"
          }}</p>
      </div>
      <div class="col order-trip-status">
        <div *ngIf="booking.isCanceled" class="canceled"></div>
        <p class="transit-time">{{ !isTripDurationDefaultValue(booking.tripDuration) ?
          (booking.tripDuration | duration) : "~30d" }}</p>
        <p class="pre-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.preCarriage)"></p>
        <p class="main-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.mainCarriage)"
          [ngClass]="booking.shipmentTypeCode ? getMainCarriageIcon() : ''"></p>
        <p class="post-carriage-icon" [hidden]="!displayStatusIcon(CarriageTypes.postCarriage)"></p>
        <p class="trip-status-icon"></p>
        <p class="trip-status">
          {{ booking.statusType.ship4wdFrontendStatus }}
        </p>
      </div>
      <div class="col order-trip">
        <p class="trip-date-title">Estimated arrival</p>
        <p class="trip-date">{{ checkDateNotNullOrEmpty(booking.eta) ? (booking.eta | date) : "Schedule Not Available"
          }}</p>
      </div>
    </div>
    <div class="row order-shipper-info">
      <div class="col shipper-data">
        <div class="me-5 d-flex align-items-center">
          <span>Shipper: </span>
          <span class="shipper-name-text" matTooltip="{{
              getShipperName(booking) ? getShipperName(booking) : 'Not Selected'
              }}"> {{
            getShipperName(booking) ? getShipperName(booking) : "Not Selected"
            }}</span>
        </div>
        <div>
          <span>Type: </span>
          <span>{{ booking.shipmentTypeCode | shipmentTypeName }}</span>
        </div>
      </div>
      <div class="col shipper-data justify-content-end">
        <div>
          <span>Ref: </span>
          <span>{{ booking.customerReferenceId ?? "To Be Assigned" }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col order-right">
    <div class="row">
      <div class="circle"></div>
      <div class="rank" *ngIf="canBeRanked(booking) && false">
        <div>
          <span>RANK</span>
          <div>
            <span class="rank-star"></span>
            <span class="rank-title ms-4">Rank Order</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row action-buttons">
      <div class="col" [ngClass]="isShowPayButton(booking) ? 'btn submit-button' : ''">
        <div *ngIf="isShowPayButton(booking)" [ngClass]="booking.isCanceled ? 'canceled' : ''"
          (click)="onSubmitButton(booking)"><span>{{ getSubmitButtonText(booking) }}</span></div>
      </div>
      <div class="col-auto btn order-actions" [matMenuTriggerFor]="menu" [ngClass]="{ 'disabled': isLoading }">
        <mat-icon *ngIf="!isLoading" class="material-symbols-outlined"> more_vert </mat-icon>
        <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
      </div>

      <mat-menu #menu backdropClass="order-dropdown">
        <app-order-menu (canceledBookingEvent)="onCancelBooking($event)" (loadingEvent)="onLoadingChanged($event)"
          [booking]="booking"></app-order-menu>
      </mat-menu>
    </div>
  </div>
</div>