import {
  GlobalTradeTypeCode,
  LeadSourceCode,
  LeadStateCode,
  TeamAccessRole,
  TeamMemberStatusCode,
  TradeNeedsTypeCode
} from '../shared.model';

export class Organization {
  leadSourceId: LeadSourceCode;
  leadStateId: LeadStateCode;
  company: OrganizationCompany;
}

export class OrganizationSettingsData {
  organizationId: string;
  countryCode: string;
  organizationName: string;
  isCreditOrganization?: boolean;
}

export class OrganizationCompany {
  tradeNeeds?: TradeNeedsTypeCode;
  globalTradeTypeId: GlobalTradeTypeCode;
  name: string;
  legalName: string;
  country?: string;
  countryCode?: string;
  city: string;
  street: string;
  state: string;
  postalCode: string;
  taxId?: string;
  dunsNumber?: string;
  address2?: string;
  isTermsConfirmation: boolean;
  contact: CompanyContact;
  additionalInformation?: string;
  creditOrganization?: boolean;
  isInternalOrganization: boolean;
}

export class CompanyContact {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
}

export class TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  userId?: string;
  memberStatus: TeamMemberStatusCode;
  organizationId: string;
  organization: OrganizationCompany;
  teamAccessRoles: TeamAccessRole[];
}

export enum SignUpCreateOrganizationFields {
  fullAddress = 'full address',
  companyName = 'company name',
}
