import { Pipe, PipeTransform } from '@angular/core';
import { ExtenderTripLeg } from '../../shared.model';

@Pipe({ name: 'uniqueApplicableType' })
export class UniqueApplicableTypePipe implements PipeTransform {

  transform(value?: ExtenderTripLeg): string[] {
    const uniqueSurcharges = [];

    value?.rates?.forEach(rate => {
      if (rate.surcharges.length > 0) {
        rate.surcharges.map((x) => {
          if (uniqueSurcharges.indexOf(x.applicableType) === -1) {
            uniqueSurcharges.push(x.applicableType);
          }
        });
      }
    });
    
    return uniqueSurcharges;
  }
}