import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { RollupDialogComponent } from './rollup-dialog.component';

@Injectable({ providedIn: 'root' })
export class RollupDialogService {
  private rollupDialogs: RollupDialog[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.close();
      }
    });
  }

  open(component: Type<any>, data?: any, className?: string | string[]): Observable<any> {
    const rollupDialog = new RollupDialog();

    const factory = this.componentFactoryResolver.resolveComponentFactory(RollupDialogComponent);
    rollupDialog.componentRef = factory.create(this.injector);
    rollupDialog.componentRef.instance.dialogState = rollupDialog._dialogState.asObservable();
    rollupDialog.componentRef.instance.class = className;

    this.appRef.attachView(rollupDialog.componentRef.hostView);
    const domElem = (rollupDialog.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    const wrapperComponent = rollupDialog.componentRef.instance;
    wrapperComponent.setChildComponent(component, data);

    rollupDialog._dialogState.next('open');

    this.rollupDialogs.push(rollupDialog);
    return rollupDialog.afterClosedSubject.asObservable();
  }

  close(data?: any): void {
    if (this.rollupDialogs.length != 0) {
      const rollupDialog = this.rollupDialogs[this.rollupDialogs.length - 1];
      rollupDialog._dialogState.next('close');

      this.appRef.detachView(rollupDialog.componentRef.hostView);
      rollupDialog.componentRef.destroy();
      rollupDialog.componentRef = null;

      rollupDialog.afterClosedSubject.next(data);
      rollupDialog.afterClosedSubject.complete();

      this.rollupDialogs.pop();
    }
  }

  getIsMultipleDialogsOpened(): boolean {
    return this.rollupDialogs.length > 1;
  }
}

class RollupDialog {
  public componentRef: ComponentRef<RollupDialogComponent>;
  public afterClosedSubject: Subject<any> = new Subject<any>();
  public _dialogState = new Subject<'open' | 'close'>();
}
