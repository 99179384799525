<div class="flow-date">
  <div class="flow-date-content px-3">
    <mat-calendar [headerComponent]="customHeader" [selected]="control.value" (selectedChange)="onSelectDate($event)"
      [minDate]="minSelectableDate" [maxDate]="maxSelectableDate" [dateFilter]="itIsNotLimitedDays">
    </mat-calendar>
  </div>
  <div class="flow-date-footer">
    <button mat-button class="flow-date-confirm-button" (click)="onConfirm()">
      Confirm
    </button>
  </div>
</div>
