import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, expand, tap } from "rxjs/operators";
import * as uuid from 'uuid';
import {
  Booking,
  BookingsShowParameters,
  BookingsQuery,
  BookingsQueryParameters,
  BookingsReadMode,
} from "../../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../../shared/bookings/bookings.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import {
  BookingStatusCodeEnum,
  BookingPaidStatusCode,
  Page,
} from "../../../../shared/shared.model";

@Component({
  selector: "app-quotes-widget",
  templateUrl: "./quotes-widget.component.html",
  styleUrls: ["./quotes-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuotesWidgetComponent implements OnInit {
  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isError: boolean = false;
  isEmpty: boolean = false;

  bookings: Booking[] = [];
  errorMessage: string;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private bookingsService: BookingsService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  onGoTo(): void {
    this.router.navigate(["/shipments"], {
      queryParams: { show: BookingsShowParameters.draft },
    });
  }

  onReload(): void {
    this.getData();
  }

  onEmptyAction(): void {
    this.isButtonLoading = true;
    this.router.navigate(["/quote-search"]);
  }

  getData(): void {
    const query = this.prepareQueryModel();

    this.isLoading = true;

    this.bookingsService.getPage(query)
      .pipe(
        expand(x => this.hasMoreBookingsToLoad(x) ? this.loadMoreBookings(x.pageNo) : this.stopLoadBookings()),
        tap((bookings: Page<Booking>) => {
          const paymentsItems = bookings.items.filter(booking => booking.isCanceled == false);

          this.bookings.push(...paymentsItems);

          if (this.bookings?.length === 0) {
            this.isEmpty = true;
          }
          this.isLoading = false
        }),
        catchError((error) => {
          this.isError = true;
          this.errorMessage = error;

          return of([])
        })
      )
      .subscribe()
      .add(() => this.isLoading = false);
  }

  private prepareQueryModel(): BookingsQuery {
    const query = new BookingsQuery();
    query.organizationId = this.settingsService.getSettings().organizationId;
    query.pageNo = 1;
    query.pageSize = 10;
    query.sortBy = BookingsQueryParameters.createTimeUtc;
    query.sortDesc = true;
    query.readMode = BookingsReadMode.appDashboard;
    query.any = true;
    query.paidStatusCodes = [
      BookingPaidStatusCode.unPaid,
      BookingPaidStatusCode.partialyPaid,
    ];

    return query;
  }

  private hasMoreBookingsToLoad(response: Page<Booking>): boolean {
    if (!response || response.totalCount === 0 || !response.items) {
      return false;
    }

    return this.hasValidItem(response.items);
  }

  private hasValidItem(items: Booking[] | null): boolean {
    return items != null && items.length > 0 && items[0].id !== uuid.NIL;
  }

  private loadMoreBookings(responsePageNo: number): Observable<Page<Booking>> {
    const query = this.prepareQueryModel()
    query.pageNo = responsePageNo + 1;
    return this.bookingsService.getPage(query);
  }

  private stopLoadBookings(): Observable<never> {
    this.isLoading = false;
    return EMPTY;
  }
}
