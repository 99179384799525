import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe, Location } from '@angular/common';
import { animate, style, transition, trigger } from "@angular/animations";
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { NotificationService } from "@ship4wd/ngx-common";
import { GoogleAnalyticsService } from "../../../../../shared/google-analytics/google-analytics.service";
import { QuotesService } from "../../../quotes.service";
import {
  HaulageType,
  RateServiceType,
  ShipmentType
} from "../../../../../shared/shared.model";
import { CreateBooking } from "../../../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../../../shared/bookings/bookings.service";
import { Quote, QuotesQuery } from "../../../quotes.model";
import { UtilityService } from "../../../../../shared/helper/utility.service";
import { QuotePriceTiersComparisonComponent } from '../quote-price-tiers-comparison/quote-price-tiers-comparison.component';
import { EnvironmentService } from '../../../../../shared/services/environment.service';
import { amplitudeFlags } from '../../../../../shared/amplitude/amplitude.constants';
import { AmplitudeService } from '../../../../../shared/amplitude/amplitude.service';

@Component({
  selector: "app-quotes-row",
  templateUrl: "./quotes-row.component.html",
  styleUrls: ["./quotes-row.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class QuotesRowComponent implements OnInit {
  @Input() quote: Quote;
  @Input() index: number;
  @Input() selectedIndex: number;
  @Input() showD2dAndAir: boolean;
  @Input() shipmentType: ShipmentType;
  @Input() specialServices: string[] = null;
  @Input() quotesQuery: QuotesQuery;
  @Output() onChangeSelection = new EventEmitter();
  @Output() onChangeBadgeEnabled = new EventEmitter();

  @Input() quoteDetailsTemplate: TemplateRef<any>;
  @Input() quoteRowPriceTemplate: TemplateRef<any>;

  ShipmentTypes = ShipmentType;
  isLoading = false;
  isSpecialService = false;
  isShowFullNames = true;
  rateServiceType = RateServiceType;
  isBadgeEnabled: boolean = false;

  get isShowEstimatedTooltips(): boolean {
    return Boolean(this.quote?.departureDate) && Boolean(this.quote?.arrivalDate)
  }

  get getEstimationText(): string {
    if (!this.quote.estimatedDuration) {
      return "N/A"
    }

    switch (this.quotesQuery.shipmentType) {
      case ShipmentType.AIR:
        return `${this.quote.estimatedDuration} Business Days`
      default:
        return `${this.quote.estimatedDuration} Days`
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isShowFullNames = window.innerWidth >= 1120;
  }
  constructor(
    public router: Router,
    public bookingsService: BookingsService,
    public notificationService: NotificationService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public location: Location,
    private googleAnalyticsService: GoogleAnalyticsService,
    private quotesService: QuotesService,
    private utilityService: UtilityService,
    private readonly sso: ScrollStrategyOptions,
    private environmentService: EnvironmentService,
    private amplitudeService: AmplitudeService
  ) { }

  ngOnInit(): void {
    this.prepareRateId();
    if (this.specialServices != null) {
      this.isSpecialService = true;
    }
  }

  expandDetailRow(): void {
    this.onChangeSelection.emit(this.index);
  }

  onBookQuote(event: Event, quote: Quote): void {
    event.stopPropagation();
    this.quotesService.stopSearchQuote$.next(true);
    this.createBooking(quote);
  }

  isShowEstimatedDuration(): boolean {
    return this.quotesQuery.shipmentType == ShipmentType.AIR
      && (!this.quote.trip.fromEstimatedDuration != null && this.quote.trip.toEstimatedDuration != null)
  }

  getShipmentTypeIcon(): HaulageType {
    return this.quote.trip.mainCarriageTripLeg.legs[0].haulageType;
  }

  getPreShipmentTypeIcon(): HaulageType {
    if (this.quote.trip?.preCarriageTripLeg?.legs?.length > 0) {
      return this.quote.trip.preCarriageTripLeg.legs[
        this.quote.trip.preCarriageTripLeg.legs.length - 1
      ].haulageType;
    }
    return this.getShipmentTypeIcon();
  }

  getPostShipmentTypeIcon(): HaulageType {
    if (this.quote.trip?.postCarriageTripLeg?.legs?.length > 0) {
      return this.quote.trip.postCarriageTripLeg.legs[
        this.quote.trip.postCarriageTripLeg.legs.length - 1
      ].haulageType;
    }
    return this.getShipmentTypeIcon();
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.quotesQuery.shipmentType == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  isPartialQuote(quote: Quote): boolean {
    return quote.trip.isPreCarriageMissingRate || quote.trip.isPostCarriageMissingRate;
  }

  getPartialQuoteTooltipText(quote: Quote): string {
    const isPreMissing = quote.trip.isPreCarriageMissingRate;
    const isPostMissing = quote.trip.isPostCarriageMissingRate;

    let missingLegRate = 'pickup & delivery';
    if (isPreMissing && !isPostMissing) {
      missingLegRate = 'pickup';
    }
    else if (!isPreMissing && isPostMissing) {
      missingLegRate = 'delivery';
    }

    return `Door ${missingLegRate} costs are currently
    being processed and will be added to your
    pricing shortly.`;
  }

  originEstimatedDepartureTooltip(origin: string): string {
    return `Estimation of when the shipment
    will depart from origin ${origin.toLowerCase()}`
  }

  originEstimatedArrivalTooltip(origin: string): string {
    return `Estimation of when the shipment will
    arrive at the ${origin.toLowerCase()} of destination.`
  }

  getRateServiceTypeTooltip(): string {
    switch (this.getRateServiceType()) {
      case RateServiceType.Standard:
      case RateServiceType.Premium:
      case RateServiceType.Other:
        return `These rates are fixed, and valid for a longer period of time. They are best for planned or recurring shipments.`;
      case RateServiceType.Basic:
        return `These are one-time prices with limited availability based on the current market. These are best for urgent or immediate shipping needs.`;
      default:
        return ``;
    }
  }

  onRateServiceType(): void {
    const dialogRef = this.dialog.open(QuotePriceTiersComparisonComponent, {
      autoFocus: false,
      scrollStrategy: this.sso.noop(),
      data: {},
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });

    dialogRef.afterClosed().subscribe((result: any) => { });
  }

  isBasicRateServiceType(): boolean {
    if (this.environmentService.environment.isQuoteBadgeEnabled) {
      var variant = this.amplitudeService.getFlag(amplitudeFlags.quoteBadge);
      if (this.amplitudeService.checkQuoteBadgeStyle(variant)) {
        this.isBadgeEnabled = false;
        this.onChangeBadgeEnabled.emit(this.isBadgeEnabled);
        return false;
      }

      this.isBadgeEnabled = variant.payload[ShipmentType[this.quotesQuery.shipmentType]];
      this.onChangeBadgeEnabled.emit(this.isBadgeEnabled);

      if (!this.isBadgeEnabled) return false;

      return this.rateServiceType.Basic ===
        this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType;
    }
  }

  getRateServiceType(): RateServiceType {
    return this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType;
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }

  private createBooking(quote: Quote): void {
    this.isLoading = true;
    let booking = new CreateBooking();
    booking.quoteId = quote.id;
    booking.quoteSearchId = quote.quoteSearchId;
    booking.rateId = quote.accumulateRateId;
    booking.preCarriageAllowed = quote.trip.preCarriageTripLeg != null;
    booking.postCarriageAllowed = quote.trip.postCarriageTripLeg != null;

    this.bookingsService.createBooking(booking).subscribe(
      (bookingId: string) => {
        this.googleAnalyticsService.quoteBooked(quote, this.quotesQuery)
        if (bookingId) {
          this.router.navigate(["/booking", bookingId]);
        }
      },
      (error) => this.notificationService.error(error)
    ).add(() => this.isLoading = false);
  }

  private prepareRateId(): void {
    if (!this.quote.accumulateRateId) {
      this.quote.accumulateRateId = this.quote.trip.rateId;
    }
  }
}
