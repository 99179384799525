<div class="change-cargo-confirm-wrapper">
    <div class="change-cargo-confirm-content">
        <div class="box-icon-wrapper">
            <i class="panel-header-icon s4d-icon s4d-boxes-light"></i>
        </div>
        <div class="change-cargo-confirm-title">
            Change Cargo Details?
        </div>
        <div class="change-cargo-confirm-sub-title">
            Changes to Cargo Details may impact your selected services and total shipping cost. Please review thoroughly before confirming shipment.
        </div>
    </div>

    <div class="change-cargo-confirm-footer">
        <button mat-raised-button class="save-changes-button" (click)="onSave()">Save Changes</button>
        <button mat-button class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
</div>