import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { Booking, BookingPdfRequest, BookingStatusCode, BookingSummary, Commodity, Contact } from '../../../../shared/bookings/bookings.model';
import { BookingFilesDocumentsPage, BookingViewDetailsPage, CompletedOrderStatusType, ExtenderTrip, IncotermCode, ShipmentType } from '../../../../shared/shared.model';
import { NotificationService } from '@ship4wd/ngx-common';
import { UtilityService } from '../../../../shared/helper/utility.service';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingSummaryComponent implements OnInit {
  @Input() booking: Booking;
  @Input() bookingSummary: BookingSummary;
  @Input() isViewOnly = false;
  @Input() isEditBooking = false;
  @Output() viewBookingEvent = new EventEmitter<boolean>();

  shipmentTypes = ShipmentType;
  isFileReady: boolean = true;
  isShowCollectContact = false;
  isShowDropContact = false;
  allowedRebook: boolean = false;

  constructor(
    private router: Router,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.viewBookingEvent.emit(this.isViewOnly);
    if (this.booking?.serviceType > 0 || this.bookingSummary?.serviceType > 0) {
      this.isShowCollectContact = false;
      this.isShowDropContact = false;
      const doorPickup = this.utilityService.isDoorPickupService(this.booking?.serviceType ?? this.bookingSummary.serviceType);
      const doordelivery = this.utilityService.isDoorDeliveryService(this.booking?.serviceType ?? this.bookingSummary.serviceType);
      if (doorPickup)
        this.isShowCollectContact = true;
      if (doordelivery)
        this.isShowDropContact = true;
    }

    this.allowedRebook = (this.bookingSummary?.statusType.code != BookingStatusCode.draft ||
      this.getcompletedOrderStatusType().includes(this.bookingSummary?.statusType.code)) && !this.bookingSummary?.isCanceled;
  }

  renderContactData(contact: Contact) {
    let data = '';
    if (contact) {
      data = this.appendContactData(data, contact.companyName);
      data = this.appendContactData(data, contact.address1);
      data = this.appendContactData(data, contact.address2);
      data = this.appendContactData(data, contact.city);
      data = this.appendContactData(data, contact.zipCode);
      data = this.appendContactData(data, contact.state);
      data = this.appendContactData(data, contact.countryCode);
    }
    return data;
  }

  onClose() {
    this.navigateToBookingsDashboard();
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip) {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.preCarriageTripLeg);
    }
    if (trip?.mainCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.mainCarriageTripLeg);
    }
    if (trip?.postCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.postCarriageTripLeg);
    }
    return carriageTripLegs;
  }

  getIncotermsCodes() {
    if (this.bookingSummary?.quote?.trip?.incoterms?.length > 0) {
      let incotermCodes = '';
      this.bookingSummary.quote.trip.incoterms.forEach((x) => {
        incotermCodes += IncotermCode[x] + ', ';
      });
      return incotermCodes.trim().slice(0, -1);
    }
    return 'n/a';
  }

  print(id: string) {
    window.open(`/booking/pdf/${id}?mode=print`, '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
  }

  reuse(id: string){
    if (this.allowedRebook) {
      this.router.navigate(['/quote-search'], {queryParams: { reusedBookingId : id} });
    }
  }

  download(id: string) {
    this.isFileReady = false;

    const bookingPdfRequest = new BookingPdfRequest();
    bookingPdfRequest.bookingId = id;
    bookingPdfRequest.token = window.localStorage.getItem("accessToken");

    this.bookingsService.getPdfUrl(bookingPdfRequest).subscribe(
      (url: URL) => {
        if (url) {
          window.open(url.toString());
        }
      },
      (error) => {
        this.notificationService.error(error);
        this.isFileReady = true;
      },
      () => {
        this.isFileReady = true;
      }
    );
  }

  navigateToFilesDocuments(bookingId: string) {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.dashboard, BookingFilesDocumentsPage.orderDetails, bookingId]);
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }
  
  private getcompletedOrderStatusType() {
    return CompletedOrderStatusType;
  }

  private navigateToBookingsDashboard() {
    this.router.navigate(['/bookings/dashboard']);
  }

  private appendContactData(data: string, value: string) {
    if (value) {
      if (data !== '') {
        data += ', ';
      }
      data += value;
    }
    return data;
  }

}
