import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { HttpService } from "../../shared/services/http/http.service";
import { AppSettingsService } from "../../shared/services/app-settings/app-settings.service";
import { AccountsService } from "../../shared/accounts/accounts.service";
import { BookingsShowParameters } from "../../shared/bookings/bookings.model";
import { BookingPaidStatusCode, BookingStatusCodeEnum } from "../../shared/shared.model";

@Component({
  selector: "app-shipments",
  templateUrl: "./shipments.component.html",
})
export class ShipmentsComponent implements OnInit {
  isLoading = true;
  data: any;
  headerTitle: string;
  organizationId: string = null;
  filters: any;
  searchText: string;
  isClearFilters: boolean;
  isOrdersGridViewMode: boolean = false;
  showParameter: BookingsShowParameters = BookingsShowParameters.all;

  get isOrganizationLoaded() {
    return this.organizationId != null;
  }

  constructor(
    public http: HttpService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public settingsService: AppSettingsService,
    public accountsService: AccountsService
  ) { }

  ngOnInit(): void {
    this.setFilters();
    this.organizationId = this.settingsService.getSettings().organizationId;
  }

  onTitleChange(headerTitle: string): void {
    this.headerTitle = headerTitle;
  }

  onLoadingChange(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  onFiltersEvent(filters: any): void {
    this.filters = filters;
    this.isLoading = true;
    this.isClearFilters = false;
  }

  onSearchText(search: string): void {
    this.searchText = search;
  }

  onClearFilters(clearFilters: boolean): void {
    this.isLoading = true;
    this.isClearFilters = clearFilters;
  }

  onOrdersGridViewModeChange(): void {
    this.isOrdersGridViewMode = !this.isOrdersGridViewMode;
  }

  private setFilters(): void {
    if (this.route.snapshot.queryParams.show !== undefined) {
      this.filters = {
        fromCity: "",
        toCity: "",
        bolOrderNumber: "",
        masterBillOfLading: "",
        fromLastUpdateDate: null,
        orderStatuses: null,
        paidStatusCodes: null,
        isCanceled: null,
        orIsCanceled: null,
        bookingPaidStatusCode: null,
        atd: null,
        duePaymentDate: null,
        hasPayments: null,
        any: null
      };

      this.showParameter = Number(this.route.snapshot.queryParams.show) as BookingsShowParameters;

      if (this.showParameter === BookingsShowParameters.draft) {
        this.filters.orderStatuses = [BookingStatusCodeEnum.DRFT];
        this.filters.any = true;
        this.filters.paidStatusCodes = [BookingPaidStatusCode.unPaid, BookingPaidStatusCode.partialyPaid];
      } else if (this.showParameter === BookingsShowParameters.woDraft) {
        this.filters.orderStatuses = [
          BookingStatusCodeEnum.ORSB,
          BookingStatusCodeEnum.ORC,
          BookingStatusCodeEnum.BKD,
          BookingStatusCodeEnum.CRD,
          BookingStatusCodeEnum.DOC,
          BookingStatusCodeEnum.PKUP,
          BookingStatusCodeEnum.ONHD,
          BookingStatusCodeEnum.ACO,
          BookingStatusCodeEnum.CDO,
          BookingStatusCodeEnum.ONB,
          BookingStatusCodeEnum.DEP,
          BookingStatusCodeEnum.HBI,
          BookingStatusCodeEnum.ANS,
          BookingStatusCodeEnum.AAS,
          BookingStatusCodeEnum.ACD,
          BookingStatusCodeEnum.CDD,
          BookingStatusCodeEnum.TTR,
          BookingStatusCodeEnum.CTRA,
          BookingStatusCodeEnum.DEO,
          BookingStatusCodeEnum.OFD,
          BookingStatusCodeEnum.DLV,
          BookingStatusCodeEnum.POD,
          BookingStatusCodeEnum.CTRR,
          BookingStatusCodeEnum.INV,
          BookingStatusCodeEnum.TFD,
          BookingStatusCodeEnum.ACL,
          BookingStatusCodeEnum.CAU,
          BookingStatusCodeEnum.ARW,
          BookingStatusCodeEnum.AWB,
          BookingStatusCodeEnum.MAN,
          BookingStatusCodeEnum.UPA,
          BookingStatusCodeEnum.RCF,
          BookingStatusCodeEnum.LPA,
          BookingStatusCodeEnum.CCD,
        ];
      } else {
        this.filters.isCanceled = false;
      }
    }
  }
}
