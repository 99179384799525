<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="container-fluid" *ngIf="bookingForm" [hidden]="isLoading">
  <div class="row">
    <div class="col booking-tab-sidebar">
      <form class="booking-tab-title">
        <mat-tab-group animationDuration="0ms" disableRipple class="booking-tab-wrapper" #bookingTabGroup
          (selectedTabChange)="onTabChange(bookingTabGroup)">
          <mat-tab class="booking-tab-wrapper"
            [disabled]="tabs.CustomerReference < lastSelectedTabIndex ? false : true">
            <ng-template mat-tab-label>
              <div class="booking-tab-list"
                [ngClass]="{'booking-selected-fill-tab': bookingTabGroup.selectedIndex > 0}">
                <div class="booking-tab-icon" [ngClass]="{'booking-selected-tab': bookingTabGroup.selectedIndex > 0}">
                  <app-booking-icons [iconName]="'shippingType'"></app-booking-icons>
                </div>
                <div class="booking-tab-content">
                  <h3 class="booking-tab-content-title">
                    Reference
                  </h3>
                  <p class="booking-tab-content-desc">Customer Reference</p>
                </div>
              </div>
            </ng-template>
            <div class="booking-right-tab-content">
              <div class="booking-right-tab-content-top">
                <form [formGroup]="bookingForm">
                  <app-booking-reference (addAnotherReference)="onAddAnotherReference()"
                    (removeAnotherReference)="onRemoveAnotherReference($event)" (generatedRefID)="onGenerateRefID()">
                  </app-booking-reference>
                </form>
              </div>
              <div class="booking-right-tab-content-bottom">
                <button mat-raised-button class="back-button disable" disabled>Back</button>
                <button mat-raised-button class="next-button button-spinner" color="primary"
                  (click)="nextStep(bookingTabGroup)">
                  <span class="button-inner">
                    Next
                    <mat-spinner diameter="25" *ngIf="isNextStepLoading"></mat-spinner>
                  </span>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="booking-tab-wrapper" [disabled]="tabs.Contacts < lastSelectedTabIndex ? false : true">
            <ng-template mat-tab-label>
              <div class="booking-tab-list"
                [ngClass]="{'booking-selected-fill-tab': bookingTabGroup.selectedIndex > tabs.Contacts}">
                <div class="booking-tab-icon"
                  [ngClass]="{'booking-selected-tab': bookingTabGroup.selectedIndex > tabs.Contacts}">
                  <app-booking-icons [iconName]="'shippingLocation'"></app-booking-icons>
                </div>
                <div class="booking-tab-content">
                  <h3 class="booking-tab-content-title">Contacts</h3>
                  <p class="booking-tab-content-desc">Shipping Contacts</p>
                </div>
              </div>
            </ng-template>
            <div class="booking-right-tab-content">
              <div class="booking-right-tab-content-top">
                <app-booking-contacts *ngIf="booking" [booking]="booking" [shipperContact]="shipperContact"
                  [consigneeContact]="consigneeContact" [notifyPartyContact]="notifyPartyContact"
                  (backEvent)="onBack($event)" (selectedContact)="onSelectedContact($event)"
                  (removeBookingContact)="onRemoveBookingContact($event)"
                  [isStepContactsNextButtonPress]="isStepContactsNextButtonPress">
                </app-booking-contacts>
              </div>
              <div class="booking-right-tab-content-bottom" *ngIf="!isViewOrganizationContacts">
                <button mat-raised-button class="back-button" (click)="prevStep(bookingTabGroup)">Back</button>
                <button mat-raised-button class="next-button" color="primary"
                  (click)="nextStep(bookingTabGroup)">Next</button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="booking-tab-wrapper" [disabled]="tabs.Containers < lastSelectedTabIndex ? false : true">
            <ng-template mat-tab-label>
              <div class="booking-tab-list"
                [ngClass]="{'booking-selected-fill-tab': bookingTabGroup.selectedIndex > tabs.Containers}">
                <div class="booking-tab-icon"
                  [ngClass]="{'booking-selected-tab': bookingTabGroup.selectedIndex > tabs.Containers}">
                  <app-booking-icons [iconName]="'shippingDate'"></app-booking-icons>
                </div>
                <div class="booking-tab-content">
                  <h3 class="booking-tab-content-title">{{ getContainerTabTitle() }}</h3>
                  <p class="booking-tab-content-desc">{{ getContainerTabTitle() }} {{ getContainerTabSubtitle() }}</p>
                </div>
              </div>
            </ng-template>
            <div class="booking-right-tab-content">
              <div class="booking-right-tab-content-top">
                <form [formGroup]="bookingForm">
                  <app-booking-containers [shipmentTypeCode]="shipmentTypeCode" [booking]="booking"
                    (addCommodity)="onAddCommodity($event)" (removeCommodity)="onDeleteCommodity($event)"
                    (backEvent)="onBack($event)" (selectedContact)="onSelectedContact($event)"></app-booking-containers>
                </form>
              </div>
              <div class="booking-right-tab-content-bottom" *ngIf="!isViewOrganizationContacts">
                <button mat-raised-button class="back-button" (click)="prevStep(bookingTabGroup)">Back</button>
                <button mat-raised-button class="next-button button-spinner" color="primary"
                  (click)="nextStep(bookingTabGroup)">
                  <span class="button-inner">
                    Next
                    <mat-spinner diameter="25" *ngIf="isNextStepLoading"></mat-spinner>
                  </span>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="booking-tab-wrapper" [disabled]="tabs.Documents < lastSelectedTabIndex ? false : true">
            <ng-template mat-tab-label>
              <div class="booking-tab-list"
                [ngClass]="{'booking-selected-fill-tab': bookingTabGroup.selectedIndex > tabs.Documents}">
                <div class="booking-tab-icon"
                  [ngClass]="{'booking-selected-tab': bookingTabGroup.selectedIndex > tabs.Documents}">
                  <app-booking-icons [iconName]="'incoterm'"></app-booking-icons>
                </div>
                <div class="booking-tab-content">
                  <h3 class="booking-tab-content-title">Documents</h3>
                  <p class="booking-tab-content-desc">Order Documents</p>
                </div>
              </div>
            </ng-template>
            <div class="booking-right-tab-content">
              <div class="booking-right-tab-content-top">
                <app-booking-documents *ngIf="booking" [booking]="booking"></app-booking-documents>
              </div>
              <div class="booking-right-tab-content-bottom">
                <button mat-raised-button class="back-button" (click)="prevStep(bookingTabGroup)">Back</button>
                <button mat-raised-button class="next-button button-spinner" color="primary"
                  (click)="nextStep(bookingTabGroup)">
                  <span class="button-inner">
                    View Summary
                    <mat-spinner diameter="25" *ngIf="isNextStepLoading"></mat-spinner>
                  </span>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="booking-tab-wrapper" [disabled]="tabs.Summary < lastSelectedTabIndex ? false : true">
            <ng-template mat-tab-label>
              <div class="booking-tab-list"
                [ngClass]="{'booking-selected-fill-tab': bookingTabGroup.selectedIndex > tabs.Summary}">
                <div class="booking-tab-icon"
                  [ngClass]="{'booking-selected-tab': bookingTabGroup.selectedIndex > tabs.Summary}">
                  <app-booking-icons [iconName]="'shippingContainers'"></app-booking-icons>
                </div>
                <div class="booking-tab-content">
                  <h3 class="booking-tab-content-title">Order Summary</h3>
                  <p class="booking-tab-content-desc">Order Details</p>
                </div>
              </div>
            </ng-template>
            <div class="booking-right-tab-content booking-summary-tab col">
              <div class="booking-right-tab-content-top" *ngIf="bookingForm">
                <app-booking-summary [booking]="booking" [bookingSummary]="bookingSummary"
                  [isEditBooking]="isEditBooking" (viewBookingEvent)="onViewBooking($event)">
                </app-booking-summary>
              </div>
              <div class="row">
                <div class="col booking-right-tab-content-bottom booking-summary-confirm-button">
                  <div class="booking-summary-condition-checkbox">
                    <mat-checkbox color="primary" (change)="onChangeTermsAndConditions($event)"
                      [checked]="bookingSummary?.isTermsAndConditionsConfirmed" [disabled]="(isViewOnly || !isEditBooking)
                    || booking.statusType.code !== bookingStatuses.draft ? true : false">Tick
                      to confirm you have read the
                      <a href="HTTPS://WWW.SHIP4WD.COM/TERMS-AND-CONDITIONS/" target="_blank" class="condition-link">
                        terms & conditions
                      </a>
                      , goods include no personal effects,
                      no refrigerated goods in dry containers and all information is accurate.
                    </mat-checkbox>
                  </div>

                  <div class="row booking-right-tab-content-bottom-wrapper justify-content-md-center">
                    <button mat-raised-button class="col back-button" (click)="prevStep(bookingTabGroup)"
                      disabled="{{ !isNextStepLoading ? false : true }}">Back</button>
                    <button mat-raised-button class="col next-button button-spinner" color="primary"
                      (click)="onSubmitBooking(creditOrganization)"
                      disabled="{{ booking.isTermsAndConditionsConfirmed  && !isNextStepLoading ? false : true }}">
                      <span class="button-inner">
                        {{ creditOrganization ? 'Confirm' : (isFinanceEnabled ? 'Confirm & Pay Now' : 'Confirm') }}
                        <mat-spinner diameter="25" *ngIf="isNextStepLoading"></mat-spinner>
                      </span>
                    </button>
                  </div>
                  <div *ngIf="!creditOrganization" class="row text-center mt-4 justify-content-md-center"
                    color="primary">
                    <a (click)="onSaveDraft()" class="col save-draft-link"
                      [ngClass]="!isNextStepLoading ? 'enabled': 'disabled'">
                      <span color="primary">Save Draft</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </div>
</div>
