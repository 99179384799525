import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    AdditionalService,
    AdditionalServiceType,
    AdditionalServicesQuery,
    CreateBookingAdditionalServiceCharges,
    InsuranceRate,
    InsuranceRateQuery,
    SingleBondEntryCalculation
} from '../../../shared/additional-services/additional-services.model';
import { CustomsRate, CustomsRateQuery } from '../../../shared/customs/customs.model';
import { EncodeUrlCodec } from '../../../shared/services/encode-url-codec';
import { BookingFlowPanelState, Page } from '../../../shared/shared.model';

@Injectable()
export class BookingFlowService {
    private panelStateSubject: BehaviorSubject<BookingFlowPanelState> = new BehaviorSubject<BookingFlowPanelState>(new BookingFlowPanelState());
    public panelState = this.panelStateSubject.asObservable();

    private validStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public validState = this.validStateSubject.asObservable();

    constructor(
        private http: HttpClient,
        private internalUrlsService: InternalUrlsService
    ) {
    }

    setPanelState(state: BookingFlowPanelState): void {
        this.panelStateSubject.next(state);
    }

    setValidState(state: boolean): void {
        this.validStateSubject.next(state);
    }

    getAdditionalServices(query: AdditionalServicesQuery): Observable<Page<AdditionalService>> {
        const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
        return this.http.get<Page<AdditionalService>>(
            `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices`, { params });
    }

    getSingleBondEntryCalculation(model: SingleBondEntryCalculation): Observable<number | any> {
        const httpOptions = this.createContentTypeHttpOptions();
        return this.http.post<number>(
            `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/customs/rates/singleBondEntryCalculation`, model, httpOptions);
    }

    addAdditionalServices(bookingId: string, model: CreateBookingAdditionalServiceCharges): Observable<void | any> {
        const httpOptions = this.createContentTypeHttpOptions();
        return this.http.post<void>(
            `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/${bookingId}`, model, httpOptions);
    }

    deleteAdditionalServices(bookingId: string, additionalServiceType: AdditionalServiceType): Observable<void> {
        return this.http.delete<void>(`${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices`
            + `/${bookingId}/${additionalServiceType}`);
    }

    getCustomRateByQuery(customsRateQuery: CustomsRateQuery): Observable<CustomsRate> {
        return this.http.post<CustomsRate>(
            `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/customs/rates/getByQuery`, customsRateQuery);
    }

    getInsuranceRate(insuranceRateQuery: InsuranceRateQuery): Observable<InsuranceRate> {
        return this.http.post<InsuranceRate>(
            `${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices/insurance/rates/getByQuery`, insuranceRateQuery);
    }

    deleteAllAdditionalServices(bookingId: string): Observable<void> {
        return this.http.delete<void>(`${this.internalUrlsService.getApiBaseUrl()}/BookingAdditionalServices`
            + `/${bookingId}`);
    }

    private createContentTypeHttpOptions(contentType?: string): any {
        return {
            headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
        };
    }
}