<div class="flow-cargo">
  <div class="flow-cargo-header px-3">
    <div class="row m-0">
      <div class="col px-0">
        <span class="details-title">Cargo Details</span>
      </div>
      <div *ngIf="shipmentType !== shipmetTypes.FCL" class="col-auto">
        <mat-form-field class="details-unit">
          <mat-select (selectionChange)="onChangeUnitToggle(true)" panelClass="cargo-panel-popup"
            [formControl]="unitMeasurementType">
            <mat-option *ngFor="let unitMeasurementType of unitMeasurementTypes"
              matTooltip="{{ getToolTipData(unitMeasurementType.name) }}" [value]="unitMeasurementType.value">
              {{ unitMeasurementType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row m-0">
      <mat-button-toggle-group class="dimension-mode-toggle row  w-100 justify-content-center"
        *ngIf="shipmentType === shipmetTypes.LCL" name="isShowPackageDimensions" aria-label="Shipping type"
        (change)="onShowPackageDimensionsChanged()" [formControl]="isShowPackageDimensions">
        <mat-button-toggle class="dimension-mode-button col" *ngFor="let item of showPackageDimensionTypes"
          [value]="item.value" [aria-label]="item.description">
          <span class="dimension-mode-name">{{ item.name }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="row mt-3">
      <div *ngIf="shipmentType === shipmetTypes.AIR">
        <p><mat-slide-toggle (change)="onChange($event)">I don’t have the
            dimensions</mat-slide-toggle></p>
      </div>
    </div>
  </div>
  <div class="flow-cargo-content px-3">
    <ng-container *ngIf="shipmentType === shipmetTypes.FCL">
      <div class="row mx-0 mb-3 equipment-row" *ngFor="
          let equipmentForm of equipmentsFormArray?.controls;
          let i = index
        " [formGroup]="equipmentForm">

              <div class="col px-0 d-flex align-items-center">
                <span class="container-title">Container {{ i + 1 }}</span>
                <span *ngIf="equipmentsFormArray?.controls.length > 1">
                  <button mat-icon-button class="equipment-remove-button no-close"
                    (click)="onRemoveEquipment(equipmentForm, $event)"
                    [disabled]="equipmentsFormArray?.controls.length === 1">
                    <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
                  </button>
                </span>
              </div>
              <div class="col-auto ps-2 pe-0 d-flex justify-content-end">
                <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" class="clear-field" [ngClass]="{
                    'equipmet-invalid': onCheckValid(equipmentForm.get('quantity'))
                  }">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                    (click)="onMinusQuantity(equipmentForm)"></i>
                  <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity"
                    formControlName="quantity" appPreventNonNumeric />
                  <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                    (click)="onAddQuantity(equipmentForm)"></i>
                </mat-form-field>
              </div>

        <div class="col-12 px-0 mt-2">
          <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
              'equipmet-invalid': onCheckValid(
                equipmentForm.get('equipmentCode')
              )
            }">
            <div class="row mx-0 align-items-center">
              <div class="col-auto ps-0 input-icon-wrapper">
                <i class="input-icon s4d-icon s4d-fcl-light"></i>
              </div>
              <div class="col px-0">
                <mat-select class="equipment-type" placeholder="Equipment" panelClass="flow-cargo-select"
                  [compareWith]="onCompareObjects" [floatLabel]="'never'" (selectionChange)="
                    onSelectionChangeEquipment($event.value, equipmentForm)
                  " (openedChange)="onOpenedChanged($event, equipmentForm)"
                  [value]="equipmentForm.get('equipmentCode').value">
                  <mat-option *ngFor="let equipment of equipments" [value]="equipment" [disabled]="equipment.disabled">
                    <div class="row no-close">
                      <div class="col-auto no-close">
                        <div class="input-icon-wrapper no-close">
                          <i class="input-icon s4d-icon s4d-fcl-light no-close"></i>
                        </div>
                      </div>
                      <div class="col ps-0 no-close">
                        {{ equipment.description }}
                      </div>
                    </div>
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="shipmentType !== shipmetTypes.FCL">
      <div class="row mx-0 mb-3 equipment-row" *ngFor="
          let commodityForm of commoditiesFormArray?.controls;
          let i = index
        " [formGroup]="commodityForm">
        <ng-container *ngIf="isShowPackageTotalVolumeAmount  && !isNoDimensionEnable">
          <span class="container-title">Total Weight of shipment</span>
          <div class="col-12 px-0 my-2">
            <mat-form-field appearance="fill" class="w-100" [ngClass]="{
                'equipmet-invalid': onCheckValid(commodityForm.get('weightAmount'))
              }">
              <div class="row mx-0">
                <div class="col-auto ps-0 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-mass"></i>
                </div>
                <div class="col px-0">
                  <input matInput type="number" placeholder="0" formControlName="weightAmount"
                    (change)="onChangeWeightAmount(commodityForm)" appPreventNonNumeric />
                </div>
                <div class="col-auto pe-0">
                  <mat-select class="commodity-select" (selectionChange)="onChangeWeightUnit($event.value)"
                    formControlName="weightUnit" panelClass="cargo-panel-popup">
                    <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                      {{ weightUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-error *ngIf="onCheckValid(commodityForm.get('packageWeight'))">
                <i class="s4d-icon s4d-info-light"></i> {{getValidatorErrorKey(commodityForm.get('packageWeight')) |
                errorMessage}}
              </mat-error>
            </mat-form-field>
          </div>
          <span class="container-title mt-3">Total Volume of Shipment</span>
          <div class="col-12 px-0 my-2">
            <mat-form-field appearance="fill" matTooltip='Load total volume' class="w-100" matTooltipPosition="above"
              [floatLabel]="'never'" [ngClass]="{
                'equipmet-invalid': onCheckValid(commodityForm.get('volumeAmount'))
              }">
              <div class="row">
                <div class="col-auto ps-3 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-dimensions"></i>
                </div>
                <div class="col px-0">
                  <input matInput formControlName="volumeAmount" type="number" inputmode="numeric" maxlength="5"
                    size="5" placeholder="Volume" min="0.000001" appPreventNonNumeric
                    (change)="onChangeVolumeAmount(commodityForm)" />
                </div>
                <div class="col-3 px-2">
                  <input matInput class="text-end text-grey" value="{{
                      commodityForm.get('volumeUnit')?.value | volumeUnitName | lowercase
                    }}" disabled />
                </div>
              </div>
              <mat-error *ngIf="onCheckValid(commodityForm.get('volumeAmount'))">
                <i class="s4d-icon s4d-info-light"></i> {{getValidatorErrorKey(commodityForm.get('volumeAmount')) |
                errorMessage}}
              </mat-error>
            </mat-form-field>
          </div>
          <span class="container-title mt-3">Quantity</span>
          <div class="col-12 px-0 my-2">
            <mat-form-field appearance="fill" matTooltip='Load total volume' matTooltipPosition="above"
              [floatLabel]="'never'" class="col-12">
              <div class="row d-flex justify-content-between">
                <div class="col-auto ps-3 input-icon-wrapper">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                    (click)="onMinusQuantity(commodityForm)"></i>
                </div>
                <div class="col-auto px-0">
                  <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity"
                    formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                    appPreventNonNumeric />
                </div>
                <div class="col-auto ps-3 input-icon-wrapper">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-plus"
                    (click)="onAddQuantity(commodityForm)"></i>
                </div>
              </div>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="!isShowPackageTotalVolumeAmount && !isNoDimensionEnable">
          <div class="col px-0 d-flex align-items-center">
            <span class="container-title">Load {{ i + 1 }}</span>
          </div>
          <div class="col-auto ps-2 pe-0 d-flex justify-content-end">
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" class="clear-field" [ngClass]="{
              'equipmet-invalid': onCheckValid(
                commodityForm.get('numberOfPackages')
              )
            }">
              <i matPrefix class="equipment-quantity-minus-icon top s4d-icon s4d-minus"
                (click)="onMinusQuantity(commodityForm)"></i>
              <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity top"
                formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                appPreventNonNumeric />
              <i matSuffix class="equipment-quantity-plus-icon top s4d-icon s4d-plus"
                (click)="onAddQuantity(commodityForm)"></i>
            </mat-form-field>
            <button mat-icon-button class="equipment-remove-button no-close" (click)="onRemoveLoad(commodityForm)"
              [disabled]="commoditiesFormArray?.controls.length === 1">
              <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
            </button>
          </div>
          <div class="col-12 px-0 mb-3">
            <span class="label-text"> Weight of Piece </span>
            <mat-form-field appearance="fill" class="w-100" [ngClass]="{
              'equipmet-invalid': onCheckValid(commodityForm.get('packageWeight')),
              'mb-4': commodityForm.get('chargeableWeightAmount')?.value
            }">
              <div class="row mx-0">
                <div class="col-auto ps-0 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-mass"></i>
                </div>
                <div class="col px-0">
                  <input matInput type="number" placeholder="0" formControlName="packageWeight"
                    (change)="onChangeWeight(commodityForm)" appPreventNonNumeric />
                </div>
                <div class="col-auto pe-0">
                  <mat-select class="commodity-select" (selectionChange)="onChangeWeightUnit($event.value)"
                    formControlName="weightUnit" panelClass="cargo-panel-popup">
                    <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                      {{ weightUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <mat-hint *ngIf="commodityForm.get('chargeableWeightAmount')?.value" class="chargeable-weight">
                <i class="header-icon s4d-icon s4d-info-light"></i> Chargeable weight:
                {{ commodityForm.get('chargeableWeightAmount')?.value | number : '1.2-2' }}
                {{ commodityForm.get('weightUnit')?.value | weightUnitName | lowercase }}
                <span *ngIf="isShowChargeableVolumeAmount(commodityForm)">
                  ({{ commodityForm.get('chargeableVolumeAmount')?.value | number : '1.2-2' }}
                  {{ commodityForm.get('volumeUnit')?.value | volumeUnitName | lowercase }}) </span>
              </mat-hint>
              <mat-error *ngIf="onCheckValid(commodityForm.get('packageWeight'))">
                <i class="s4d-icon s4d-info-light"></i> {{getValidatorErrorKey(commodityForm.get('packageWeight')) |
                errorMessage}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 px-0 mb-3">
            <span class="label-text"> Dimensions of Piece </span>
            <mat-form-field appearance="fill" class="dimension-field w-100" [ngClass]="{
              'dimension-invalid': onCheckValid(commodityForm.get('dimensionValue'))
            }">
              <div class="row mx-0">
                <div class="col-auto ps-0 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-dimensions"></i>
                </div>
                <div class="col px-0">
                  <dimensions-input formControlName="dimensionValue"
                    (change)="onChangeDimensionValue(commodityForm)"></dimensions-input>
                </div>
                <div class="col-auto pe-2">
                  <mat-select class="commodity-select" formControlName="dimensionUnit" panelClass="cargo-panel-popup">
                    <mat-option *ngFor="let dimensionUnit of dimensionUnits" [value]="dimensionUnit.value">
                      {{ dimensionUnit.name | lowercase }}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="col-auto ps-0" *ngIf="!commodityForm.get('loadUnit').disabled">
                  <mat-select class="volume-select" formControlName="loadUnit" panelClass="cargo-panel-popup"
                    (selectionChange)="
                    onChangeLoadUnit($event?.value, commodityForm)
                  ">
                    <mat-option *ngFor="let loadUnit of loadUnits" [value]="loadUnit.value">
                      {{ loadUnit.name | titlecase }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </mat-form-field>
            <mat-error *ngIf="commodityForm.get('length')?.hasError('max') ||
            commodityForm.get('width')?.hasError('max') ||
            commodityForm.get('height')?.hasError('max') ||
            onCheckValid(commodityForm.get('dimensionValue'))" class="mt-1">
              <i class="s4d-icon s4d-info-light"></i> {{
              (commodityForm.get('length')?.hasError('max') ||
              commodityForm.get('width')?.hasError('max') ||
              commodityForm.get('height')?.hasError('max'))
              ? sizeErrorString :
              getValidatorErrorKey(commodityForm.get('dimensionValue')) | errorMessage }}
            </mat-error>
          </div>
          <div class="col-12 px-0 mb-3">
            <span class="label-text">Quantity</span>
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
              'equipmet-invalid': onCheckValid(commodityForm.get('numberOfPackages'))}">
              <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                (click)="onMinusQuantity(commodityForm)"></i>
              <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity"
                formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                appPreventNonNumeric />
              <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                (click)="onAddQuantity(commodityForm)"></i>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="isNoDimensionEnable && shipmentType === shipmetTypes.AIR">
          <div class="col px-0 d-flex align-items-center">
            <span class="container-title">Load {{ i + 1 }}</span>
          </div>
          <div class="col-auto ps-2 pe-0 d-flex justify-content-end">
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" class="clear-field" [ngClass]="{
              'equipmet-invalid': onCheckValid(
                commodityForm.get('numberOfPackages')
              )
            }">
              <i matPrefix class="equipment-quantity-minus-icon top s4d-icon s4d-minus"
                (click)="onMinusQuantity(commodityForm)"></i>
              <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity top"
                formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                appPreventNonNumeric />
              <i matSuffix class="equipment-quantity-plus-icon top s4d-icon s4d-plus"
                (click)="onAddQuantity(commodityForm)"></i>
            </mat-form-field>
            <button mat-icon-button class="equipment-remove-button no-close" (click)="onRemoveLoad(commodityForm)"
              [disabled]="commoditiesFormArray?.controls.length === 1">
              <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
            </button>
          </div>
          <div class="col-12 px-0 mt-2 mb-3">
            <span class="label-text"> Weight of Piece </span>
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'">
              <div class="row align-items-center">
                <div class="col-auto ps-3 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-mass"></i>
                </div>
                <div class="col ps-0 pe-3">
                  <mat-select class="equipment-type" placeholder="Select Weight of Piece" panelClass="cargo-panel-popup"
                    [compareWith]="compareWeightObjects" [floatLabel]="'never'"
                    (selectionChange)="onSelectionChangeweight($event.value, commodityForm)"
                    (openedChange)="onOpenedChanged($event, commodityForm)"
                    [formControl]="commodityForm.get('selectedValue')">
                    <mat-option *ngFor="let weight of weightRange" [value]="weight">
                      <div class="row no-close">
                        <div class="col-auto no-close">
                          <div class="input-icon-wrapper no-close">
                            <i class="input-icon s4d-icon s4d-mass no-close"></i>
                          </div>
                        </div>
                        <div class="col ps-0 no-close">{{ weight.range }}</div>
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </mat-form-field>
          </div>

          <div class="col-12 px-0 mt-2">
            <span class="label-text">Quantity</span>
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
              'equipmet-invalid': onCheckValid(commodityForm.get('numberOfPackages'))}">
              <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                (click)="onMinusQuantity(commodityForm)"></i>
              <input matInput type="number" min="0" max="50" placeholder="0" class="equipment-quantity"
                formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                appPreventNonNumeric />
              <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                (click)="onAddQuantity(commodityForm)"></i>
            </mat-form-field>
          </div>

        </ng-container>
      </div>
    </ng-container>
    <div class="row mt-2" *ngIf="shipmentType !== shipmetTypes.FCL && grandTotalChargeableWeight > 0">
      <div class="col-auto total-chargeable-weight-header">
        <span class="col-12 chargeable-message">Total Chargeable Weight:</span>
      </div>
      <div class="col bold text-end">
        <div class="row">
          <span class="col-12 total-chargeable-weight-value" *ngIf="!isShowPackageTotalVolumeAmount">{{totalPackages}}
            Package(s), </span>
          <span class="col-12 total-chargeable-weight-value">{{grandTotalChargeableWeight | number:'1.2-2'}}
            {{grandTotalWeightUnit |
            weightUnitName | lowercase}}
            <span *ngIf="isShowGrandTotalChargeableVolume">({{ grandTotalChargeableVolume | number : '1.2-2' }}
              {{ grandTotalVolumeUnit | volumeUnitName | lowercase}})</span>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-4 justify-content-center" *ngIf="isShowAddContainerOrLeadButton()">
      <div class="col-auto">
        <button mat-button class="details-button" (click)="onAdd($event)">
          <span>+</span>
          <span class="ms-2">{{
            shipmentType === shipmetTypes.FCL ? "Add Container" : "Add Load"
            }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="flow-cargo-footer">
    <div class="row m-0 text-grey">
      <div class="col-12 px-0 mb-3">
        <mat-checkbox [formControl]="isHazardous" (change)="onHazardousChanged($event)">
          Shipment contains batteries or dangerous goods
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isHazardous.value && shipmentType === shipmetTypes.FCL" class="col mb-3">
        <mat-radio-group class="d-flex flex-column gap-3" [(ngModel)]="selectedDangerousGoodsType"
          [disabled]="(dangerousGoodsWarning !== null && selectedDangerousGoodsType === undefined) || isCheckingBatteriesSupportInProgress"
          (change)="onDangerousGoodsTypeChanged($event)">
          <mat-radio-button *ngFor="let type of dangerousGoodsTypes" [value]="type.value">
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="isCheckingBatteriesSupportInProgress"
        class="col-auto d-flex justify-content-center align-items-center">
        <mat-spinner diameter="16"></mat-spinner>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="isHazardous.value && dangerousGoodsWarning">
        <div class="s4d-alert-warning mb-3">
          <span class="alert-icon"><i class="s4d-icon" [ngClass]="dangerousGoodsWarning.icon"></i></span>
          <span class="alert-text" [innerHTML]="dangerousGoodsWarning.description"></span>
        </div>
      </div>
      <div class="col-12 px-0">
        <button mat-button
          [disabled]="dangerousGoodsWarning !== null  || (isHazardous.value && !selectedDangerousGoodsType)"
          class="flow-cargo-confirm-button" (click)="onConfirm()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
