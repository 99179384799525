import { Pipe, PipeTransform } from '@angular/core';
import { DimensionUnit } from '../shared.model';

@Pipe({ name: 'dimensionUnitName' })
export class DimensionUnitNamePipe implements PipeTransform {
  private dimensionUnitNames = {};

  constructor() {
    this.dimensionUnitNames[DimensionUnit.CM] = 'CM';
    this.dimensionUnitNames[DimensionUnit.M] = 'M';
    this.dimensionUnitNames[DimensionUnit.IN] = 'IN';
    this.dimensionUnitNames[DimensionUnit.FT] = 'FT';
  }

  transform(value?: DimensionUnit): string {
    return this.dimensionUnitNames[value] ?? '';
  }
}
