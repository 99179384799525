import { Pipe, PipeTransform } from '@angular/core';
import { KycStatusCode } from '../accounts/accounts.model';

@Pipe({ name: 'kycStatusCodeName' })
export class KycStatusCodeNamePipe implements PipeTransform {
  private kycStatusCodeNames = {};

  constructor() {
    this.kycStatusCodeNames[KycStatusCode.Approve] = 'Approve';
    this.kycStatusCodeNames[KycStatusCode.Pending] = 'Pending';
    this.kycStatusCodeNames[KycStatusCode.Reject] = 'Reject';
  }

  transform(value?: KycStatusCode): string {
    return this.kycStatusCodeNames[value] ?? '';
  }
}
