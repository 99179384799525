<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="isOrganizationLoaded" [hidden]="isLoading">
  <div *ngIf="isCreditLineNew">
    <app-apply-credit-line></app-apply-credit-line>
  </div>

  <div *ngIf="isCreditLineRequested">
    <app-requested-credit-line></app-requested-credit-line>
  </div>

  <div *ngIf="isCreditLineRejected">
    <app-rejected-credit-line></app-rejected-credit-line>
  </div>

  <div *ngIf="isCreditLinePreapproved">
    <app-eligible-credit-line></app-eligible-credit-line>
  </div>

  <div *ngIf="isCreditLineApproved">
    <app-claim-credit-line [isCreditLine]="isCreditLine" [creditLine]="creditLine"></app-claim-credit-line>
  </div>
</div>

<app-dashboard-nav *ngIf="isMobileDashboardEnabled"></app-dashboard-nav>