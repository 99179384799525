import { QuotesQuery } from "../../../../../../desktop/quotes/quotes.model";
import { ShipmentType } from "../../../../../../shared/shared.model";
import { Quote } from "../../../../quotes.model";

export class RateDialogData {
  quote: Quote;
  shipmentType: ShipmentType;
  quotesQuery: QuotesQuery;
  cancelButtonText: string = "Cancel";
}
