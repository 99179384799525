import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { NotificationService } from '@ship4wd/ngx-common';
import { FilesService } from '../../../../../shared/services/files.service';
import { DashboardService } from '../../../../../shared/widgets/dashboard.service';
import { Country } from '../../../../../shared/countries/country.model';
import { FileUrl, PreShipmentDetailPanels } from '../../../../../shared/shared.model';
import { ApplicationFileType } from '../../../../../shared/widgets/widgets.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';

@Component({
  selector: 'app-additional-service-pre-shipment',
  templateUrl: './additional-service-pre-shipment.component.html',
  styleUrls: ['./additional-service-pre-shipment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalServicePreShipmentComponent {
  isLoading: boolean;
  fileUrl: FileUrl = null;
  preShipmentDetailPanels = PreShipmentDetailPanels;

  isExpanded: boolean[] = [true, false, false, false];

  isServiceAlreadyAdded = false;

  inspections: string[] = [
    'Quantity',
    'Product appearance',
    'Performance & function tests',
    'Workmanship',
    'Assembly',
    'Accessories',
    'Material',
    'Colours & logo',
    'Size & measurements',
    'Weight',
    'Assortment',
    'Carton condition',
    'Barcode scan test',
    'Packing & marking',
    'Shipping marks',
    'Warehouse condition'
  ];

  valuesInclude: string[] = [
    'No extra charge for travel cost',
    'No extra charge for inspections on Saturday or Sunday',
    'Full-time experienced inspectors only',
    'A detailed inspection report issued within 24 hours',
    'Free consultancy before and after inspections'
  ];

  supportedCountries: Country[] = [
    { code: "CN", name: "China" },
    { code: "VN", name: "Vietnam" },
    { code: "IN", name: "India" },
    { code: "BD", name: "Bangladesh" },
    { code: "TH", name: "Thailand" },
    { code: "MY", name: "Malaysia" },
    { code: "ID", name: "Indonesia" },
    { code: "PK", name: "Pakistan" },
    { code: "KH", name: "Cambodia" },
    { code: "MM", name: "Myanmar" }
  ];

  constructor(
    private filesService: FilesService,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.isServiceAlreadyAdded = data.isServiceAlreadyAdded;
  }

  onSubmit(): void {
    this.dialogRef.close({
      isAddPreshipment: true
    });
  }

  onRemove(): void {
    this.dialogRef.close({
      isRemove: true
    });
  }

  onSwipe(): void {
    this.dialogRef.close({});
  }

  onClickPanel(state: boolean, panel: PreShipmentDetailPanels): void {
    this.isExpanded[panel] = state;
  }

  onShowExample(): void {
    if (this.fileUrl == null) {
      this.getPreShipmentExampleFile();
    }
    else {
      this.filesService.downloadFileUrl(this.fileUrl);
    }
  }

  onLinkClick(): void {
    const url = 'https://www.v-trust.com/en/our-services/inspection-standards/aql-table';
    window.open(url, "_blank");
  }

  private getPreShipmentExampleFile(): void {
    this.isLoading = true;

    this.dashboardService
      .getApplicationFile(ApplicationFileType.preShipmentExample)
      .subscribe(
        url => {
          this.fileUrl = new FileUrl();
          this.fileUrl.url = url;
          this.filesService.downloadFileUrl(this.fileUrl);
        },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }
}
