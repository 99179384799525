import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import {
  CarriageStatusType,
  ExtenderTrip,
  ExtenderTripRate,
  LocationType,
  ShipmentType,
  UnitMeasurementType,
  VolumeUnit,
  WeightUnit
} from '../../../../shared/shared.model';
import { LocationTypeS4DIconPipe } from '../../../../shared/pipes/location-type-s4d-icon.pipe';
import { BookingSummary, Commodity, Container, SummaryQuote } from '../../../../shared/bookings/bookings.model';
import { BookingFlowService } from '../booking-flow.service';
import { RateDetailsDialogComponent } from '../../../quotes/quote-search-flow/rate-details/rate-details-dialog.component';
import { environment } from '../../../../../environments/environment';
import { AdditionalServiceType, BookingAdditionalServiceCharges } from '../../../../shared/additional-services/additional-services.model';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { SupportRequestsService } from '../../../../desktop/support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../../../desktop/support-requests/support-requests.model';
import { EnvironmentService } from '../../../../shared/services/environment.service';

@Component({
  selector: 'app-booking-flow-sidebar',
  templateUrl: './booking-flow-sidebar.component.html',
  styleUrls: ["./booking-flow-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BookingFlowSidebarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() bookingSummary: BookingSummary;
  @Input() isCreditOrganization: boolean;
  quote: SummaryQuote;
  ShipmentTypes = ShipmentType;
  bookingId: string;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  carriageTripLegs = [];
  uniqueLocations = [];
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  groupedContainers: Array<{ code: string; description: string; count: number }>;
  isValid: boolean = false;
  grandTotalChargeableWeightAmount = 0;
  unitMeasurement = UnitMeasurementType[UnitMeasurementType.metric];
  isAdditionalServicesEnabled: boolean;
  isAgent: boolean = false;
  hasScroll: boolean = false;
  isLiveAssistancePopupEnabled: boolean;

  @ViewChild('scrollableDiv') scrollableDiv: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScrollBar();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private bookingFlowService: BookingFlowService,
    private locationTypeIconPipe: LocationTypeS4DIconPipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userInfoService: UserInfoService,
    private dialogService: DialogService,
    private utilityService: UtilityService,
    private supportRequestsService: SupportRequestsService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.bookingSummary.quote.trip);

    this.bookingFlowService.validState.subscribe(x => {
      this.isValid = x;
    });

    this.quote = this.bookingSummary.quote;

    this.extendUniqueLocations();
    this.isAdditionalServicesEnabled = this.getIsAdditionalServicesEnabled();
    this.isAgent = this.userInfoService.checkIsAgent();
    this.isLiveAssistancePopupEnabled = this.environmentService.environment.isShowSupportPopupAutoEnabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.bookingSummary) {
      this.getCargoDetails();
    }
  }

  ngAfterViewInit(): void {
    this.checkScrollBar();
  }

  onReviewAndPay(): void {
    this.googleAnalyticsService.reviewAndPaySelected();

    this.router.navigate(["booking/summary/", this.bookingId]);
  }

  getLocationTypeIcon(type: LocationType, isFirst: boolean): string {
    return this.locationTypeIconPipe.transform(
      type !== null ? type : isFirst ? this.quote.trip.fromLocationTypeCode : this.quote.trip.toLocationTypeCode
    );
  }

  onShowDetails(): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(RateDetailsDialogComponent, {
        autoFocus: true,
        scrollStrategy: this.sso.noop(),
        data: {
          quote: this.quote,
          containers: this.bookingSummary?.containers,
          shipmentType: this.bookingSummary?.shipmentTypeCode
        },
        backdropClass: 'backdropBackground',
        panelClass: 'dialog-padding-0'
      });

      dialogRef.afterClosed().subscribe(data => {
        this.dialogService.setDialogOpen(false);
      });
    }
  }

  getAdditionalServiceTooltipText(model: BookingAdditionalServiceCharges): string | null {
    if (model.additionalServiceCharge.chargeCode === 'ISE') {
      return 'The Importer Security Filing (ISF) also referred to as 10+2, is a customs import requirement of the United States Customs and Border Protection (CBP) ; which requires containerized cargo information, for security purposes, to be transmitted to the agency at least 24 hours before goods are loaded onto an ocean vessel headed to the U.S';
    }

    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.preShipment:
        return 'One visit is sufficient for most per-shipping inspections. '
          + 'After you finish your booking, a shipping expert will call you to schedule.';
      default:
        return null;
    }
  }

  getAdditionalServiceDescription(model: BookingAdditionalServiceCharges): string {
    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.preShipment:
        return 'per visit';
      default:
        return null;
    }
  }

  getAdditionalServiceManuallyDescription(model: BookingAdditionalServiceCharges): string {
    switch (model.additionalServiceCharge.additionalServiceId) {
      case AdditionalServiceType.customsManually:
      case AdditionalServiceType.insuranceManually:
        return `Our customer service team will contact
         you after booking to finalize this pricing
          and provide further details.`;
      default:
        return null;
    }
  }

  getShipmentIcon(): string {
    switch (this.bookingSummary?.shipmentTypeCode) {
      case ShipmentType.FCL:
        return "s4d-fcl";
      case ShipmentType.LCL:
        return "s4d-lcl";
      case ShipmentType.AIR:
        return "s4d-ac";
      default:
        break;
    }
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  checkScrollBar(): void {
    const scrollableDiv = this.scrollableDiv.nativeElement;
    this.hasScroll = scrollableDiv.scrollHeight > scrollableDiv.clientHeight;
  }

  detectSizeChanges(): void {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          this.checkScrollBar();
        }
      });
    });

    const config = { attributes: true, childList: true, subtree: true, characterData: true };
    observer.observe(this.scrollableDiv.nativeElement, config);
  }

  openSupportDialog(isPopupAutoOpened: boolean): void {
    this.googleAnalyticsService.chooseLiveBookingAssistance("book with an expert");
    this.supportRequestsService.openSupportDialogIfNeeded(0, this.createSupportRequestDialogData(isPopupAutoOpened));
  }

  private getCargoDetails(): void {
    if (this.bookingSummary.shipmentTypeCode !== ShipmentType.FCL) {
      this.totalLoadCalculation();
    }
    else {
      this.groupedContainers = this.getEquipmentTypeSummary();
    }

    setTimeout(() => {
      this.detectSizeChanges();
    }, 0);
  }

  isShowReverseDiscountPrice(charge: number): boolean {
    return this.quote.trip?.reverseDiscount > 0 && charge > 0;
  }

  getReverseDiscountPrice(charge: number): number {
    return charge + this.quote.trip.reverseDiscount;
  }

  private getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage, carriage: trip.preCarriageTripLeg
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage, carriage: trip.mainCarriageTripLeg
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage, carriage: trip.postCarriageTripLeg
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element?.shipmentRate?.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    return carriageTripLegs;
  }

  private extendUniqueLocations(): void {
    const seenLocations = new Map();

    this.carriageTripLegs.forEach(item => {
      item.carriage.legs?.forEach(leg => {
        const fromKey = `${leg.fromLocationName}-${leg.fromLocationType}`;
        const toKey = `${leg.toLocationName}-${leg.toLocationType}`;

        const fromValue = {
          name: leg.fromLocationName,
          type: leg.fromLocationType,
          date: leg.departureDate
        };

        const toValue = {
          name: leg.toLocationName,
          type: leg.toLocationType,
          date: leg.arrivalDate
        };

        // Check and update for 'From' location
        if (!seenLocations.has(fromKey)) {
          this.uniqueLocations.push(fromValue);
          seenLocations.set(fromKey, fromValue);
        } else if (!seenLocations.get(fromKey).date && leg.departureDate) {
          // Update if the existing entry doesn't have a date but the new entry does
          const index = this.uniqueLocations.findIndex(l => l.name === leg.fromLocationName && l.type === leg.fromLocationType);
          this.uniqueLocations[index] = fromValue;
          seenLocations.set(fromKey, fromValue);
        }

        // Check and update for 'To' location
        if (!seenLocations.has(toKey)) {
          this.uniqueLocations.push(toValue);
          seenLocations.set(toKey, toValue);
        } else if (!seenLocations.get(toKey).date && leg.arrivalDate) {
          // Update if the existing entry doesn't have a date but the new entry does
          const index = this.uniqueLocations.findIndex(l => l.name === leg.toLocationName && l.type === leg.toLocationType);
          this.uniqueLocations[index] = toValue;
          seenLocations.set(toKey, toValue);
        }
      });
    });
  }

  private getTotalFreightRate(rates: ExtenderTripRate[]): number {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;
    this.grandTotalWeightAmount = 0;
    this.grandTotalVolumeAmount = 0;
    this.grandTotalChargeableWeightAmount = 0;

    this.bookingSummary.containers.forEach((container: Container) => {
      container.commodities.forEach((commodity: Commodity) => {
        packagesCount = commodity.numberOfPackages ? commodity.numberOfPackages : 0;
        totalVolumeAmount = commodity.volume ? commodity.volume : 0;
        totalWeightAmount = commodity.weight ? commodity.weight : 0;

        this.grandTotalVolumeUnit = commodity.volumeUnitCode;
        this.grandTotalWeightUnit = commodity.weightUnitCode;
        this.unitMeasurement = commodity.weightUnitCode === WeightUnit.LB ? UnitMeasurementType[UnitMeasurementType.imperial] : UnitMeasurementType[UnitMeasurementType.metric];

        this.grandTotalVolumeAmount += totalVolumeAmount;
        this.grandTotalWeightAmount += totalWeightAmount;

        this.grandTotalChargeableWeightAmount = this.calculateTotalChargeableWeight(this.grandTotalVolumeAmount);
        this.grandTotalChargeableWeightAmount = this.grandTotalWeightAmount > this.grandTotalChargeableWeightAmount ? this.grandTotalWeightAmount : this.grandTotalChargeableWeightAmount;
      });
    });
  }

  private getEquipmentTypeSummary(): Array<{ code: string; description: string; count: number }> {
    const summaryObj = this.bookingSummary.containers.reduce((accumulator, container) => {
      if (!accumulator[container.equipmentTypeCode]) {
        accumulator[container.equipmentTypeCode] = {
          code: container.equipmentTypeCode,
          description: container.equipmentTypeDescription,
          count: 0
        };
      }

      accumulator[container.equipmentTypeCode].count += 1;
      return accumulator;
    }, {} as Record<string, { code: string; description: string; count: number }>);

    return Object.values(summaryObj);
  }

  private calculateTotalChargeableWeight(totalVolume: number): number {
    if (this.unitMeasurement === UnitMeasurementType[UnitMeasurementType.metric]) {
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (this.bookingSummary.shipmentTypeCode === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private getIsAdditionalServicesEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isAdditionalServicesEnabled;
      case 'sb':
        return environment.sb.isAdditionalServicesEnabled;
      default:
        return environment.isAdditionalServicesEnabled;
    }
  }

  private createSupportRequestDialogData(isPopupAutoOpened: boolean): SupportRequestDialogData {
    return {
      isPopupAutoOpened: isPopupAutoOpened,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: null,
      quoteSearchId: this.bookingSummary.quoteSearchId
    } as SupportRequestDialogData
  }
}