import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";
import { BookingsShowParameters } from "../../shared/bookings/bookings.model";
import { BookingPaidStatusCode, BookingStatusCodeEnum } from "../../shared/shared.model";
import { environment } from "../../../environments/environment";
import { LayoutMode } from "../layout/layout.model";
import { LayoutService } from "../layout/layout.service";
import { SalesforceService } from "../../shared/services/salesforce.service";

@Component({
  selector: "app-shipments",
  templateUrl: "./shipments.component.html",
  styleUrls: ["./shipments.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShipmentsComponent implements OnInit, OnDestroy, AfterViewInit {
  filters: any;
  searchText: string;
  filterForm: FormGroup;
  appliedFilters: any;
  isLoading = true;
  isSearchActive: boolean = false;
  isFiltersActive: boolean = false;
  isClearFilters: boolean;
  isCanceled?: boolean = false;
  isFilterApplied = false;

  isMobileDashboardEnabled: boolean;

  orderStatuses: any[] = [
    {
      statusName: 'Open Orders',
      value: this.getStatusValues('Open Orders'),
      isChecked: false,
      isChild: false,
      allComplete: false,
      childStatuses: []
    },
    {
      statusName: 'Submitted Orders',
      value: this.getStatusValues('Submitted Orders'),
      isChecked: false,
      isChild: true,
      allComplete: false,
      childStatuses: [
        {
          statusName: 'Booked with Carrier',
          value: this.getStatusValues('Booked with Carrier'),
          isChecked: false
        },
        { statusName: 'On Transit', value: this.getStatusValues('On Transit'), isChecked: false },
        { statusName: 'Out to Delivery', value: this.getStatusValues('Out to Delivery'), isChecked: false }
      ]
    },
    {
      statusName: 'Complete Orders',
      value: this.getStatusValues('Complete Orders'),
      isChecked: false,
      isChild: true,
      allComplete: false,
      childStatuses: [
        { statusName: 'Cancelled', value: this.getStatusValues('Cancelled'), isChecked: false },
        { statusName: 'Invoiced', value: this.getStatusValues('Invoiced'), isChecked: false },
        { statusName: 'Delivered', value: this.getStatusValues('Delivered'), isChecked: false }
      ]
    }
  ];

  showParameter: BookingsShowParameters = BookingsShowParameters.all;

  @ViewChild('header') header: TemplateRef<ElementRef>;

  constructor(
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private salesforceService: SalesforceService
  ) {
    this.isMobileDashboardEnabled = this.getIsMobileDasboardEnabled();
  }

  ngOnInit(): void {
    this.createFilterForm();
    this.setFilters();
  }

  ngOnDestroy(): void {
    this.layoutService.setWrapperHeader();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setMode(LayoutMode.USER);
      this.layoutService.setWrapperHeader(this.header);
    })
  }

  async onChatClick(): Promise<void> {
    await this.salesforceService.openChat();
  }

  onClearFilter(): void {
    this.clearFilter(true);
  }

  onSearchText(search: any): void {
    this.searchText = search.target.value;
  }

  onFiltersEvent(filters: any): void {
    this.filters = filters;
    this.isLoading = true;
    this.isClearFilters = false;
  }

  onLoadingChange(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  onSearchClick(): void {
    this.isSearchActive = !this.isSearchActive;
    this.isFiltersActive = false;
  }

  onFiltersClick(): void {
    this.isFiltersActive = !this.isFiltersActive;
  }

  updateAllComplete(orderStatus: any): void {
    orderStatus.allComplete = orderStatus.childStatuses != null &&
      orderStatus.childStatuses.every((t) => t.isChecked);
    orderStatus.isChecked = orderStatus.allComplete;
  }

  someComplete(orderStatus: any): boolean {
    if (orderStatus?.childStatuses.length === 0) {
      return false;
    }

    return orderStatus.childStatuses.filter(t => t.isChecked).length > 0 && !orderStatus.allComplete;
  }

  setAll(completed: boolean, orderStatus: any): any {
    orderStatus.allComplete = completed;
    if (orderStatus.childStatuses.length === 0) {
      return false;
    }
    orderStatus.childStatuses.forEach((t) => (t.isChecked = completed));
  }

  applyFilter(): void {
    this.isFilterApplied = false;
    const filters = this.filterForm.getRawValue();
    filters.orderStatuses = this.getOrderStatuses(filters);
    filters.isCanceled = this.isCanceled;
    this.appliedFilters = filters;

    Object.entries(this.appliedFilters).forEach(([key, value]) => {
      if (key !== "orderStatuses" && key !== "isCanceled") {
        if (this.appliedFilters[key] !== "" && this.appliedFilters[key] !== null) {
          this.isFilterApplied = true;
        }
      }
    });

    if (this.appliedFilters.orderStatuses.length > 0) {
      this.isFilterApplied = true;
    }

    if (this.appliedFilters.isCanceled === true) {
      this.isFilterApplied = true;
    }

    this.onSearchClick();
    this.filters = filters;
    this.isLoading = true;
    this.isClearFilters = false;
  }

  clearFilter(fromDashboard: boolean = false): void {
    if (this.isFilterApplied || fromDashboard) {
      this.isFilterApplied = false;
      this.orderStatuses.map(e => {
        e.isChecked = false;
        if (e.isChild === true) {
          e.childStatuses.map(e => {
            e.isChecked = false;
          });
        }
      });
      this.filterForm.reset();
      this.appliedFilters = null;
      if (!fromDashboard) this.onSearchClick();
      this.searchText = '';
      this.isLoading = true;
      this.isClearFilters = true;
    }
  }

  private getStatusValues(statusName: string): BookingStatusCodeEnum[] {
    const bookingStatusCodes: BookingStatusCodeEnum[] = [];
    if (statusName === 'Open Orders') {
      bookingStatusCodes.push(BookingStatusCodeEnum.DRFT);
    }
    else if (statusName === 'Submitted Orders') {
      bookingStatusCodes.push(BookingStatusCodeEnum.ORSB);
      bookingStatusCodes.push(BookingStatusCodeEnum.ORC);
    }
    else if (statusName === 'Booked with Carrier') {
      bookingStatusCodes.push(BookingStatusCodeEnum.BKD);
      bookingStatusCodes.push(BookingStatusCodeEnum.CRD);
      bookingStatusCodes.push(BookingStatusCodeEnum.DOC);
    }
    else if (statusName === 'On Transit') {
      bookingStatusCodes.push(BookingStatusCodeEnum.PKUP);
      bookingStatusCodes.push(BookingStatusCodeEnum.ONHD);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACO);
      bookingStatusCodes.push(BookingStatusCodeEnum.CDO);
      bookingStatusCodes.push(BookingStatusCodeEnum.ONB);
      bookingStatusCodes.push(BookingStatusCodeEnum.DEP);
      bookingStatusCodes.push(BookingStatusCodeEnum.HBI);
      bookingStatusCodes.push(BookingStatusCodeEnum.ANS);
      bookingStatusCodes.push(BookingStatusCodeEnum.AAS);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACD);
      bookingStatusCodes.push(BookingStatusCodeEnum.CDD);
      bookingStatusCodes.push(BookingStatusCodeEnum.TTR);
      bookingStatusCodes.push(BookingStatusCodeEnum.CTRA);
      bookingStatusCodes.push(BookingStatusCodeEnum.DEO);
      bookingStatusCodes.push(BookingStatusCodeEnum.TFD);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACL);
      bookingStatusCodes.push(BookingStatusCodeEnum.CAU);
      bookingStatusCodes.push(BookingStatusCodeEnum.ARW);
      bookingStatusCodes.push(BookingStatusCodeEnum.AWB);
      bookingStatusCodes.push(BookingStatusCodeEnum.MAN);
      bookingStatusCodes.push(BookingStatusCodeEnum.UPA);
      bookingStatusCodes.push(BookingStatusCodeEnum.RCF);
      bookingStatusCodes.push(BookingStatusCodeEnum.LPA);
      bookingStatusCodes.push(BookingStatusCodeEnum.CCD);
    }
    else if (statusName === 'Out to Delivery') {
      bookingStatusCodes.push(BookingStatusCodeEnum.OFD);
    }
    else if (statusName === 'Cancelled') {
      this.isCanceled = true;
    }
    else if (statusName === 'Invoiced') {
      bookingStatusCodes.push(BookingStatusCodeEnum.INV);
    }
    else if (statusName === 'Delivered') {
      bookingStatusCodes.push(BookingStatusCodeEnum.DLV);
    }
    return bookingStatusCodes;
  }

  private createFilterForm(): void {
    this.filterForm = this.fb.group({
      fromCity: [''],
      toCity: [''],
      bolOrderNumber: [''],
      masterBillOfLading: [''],
      fromLastUpdateDate: [null],
      orderStatuses: [],
      isCanceled: [null],
      orIsCanceled: [null],
    });
  }

  private setFilters(): void {
    if (this.route.snapshot.queryParams.show !== undefined) {
      this.filters = {
        fromCity: "",
        toCity: "",
        bolOrderNumber: "",
        masterBillOfLading: "",
        fromLastUpdateDate: null,
        orderStatuses: null,
        paidStatusCodes: null,
        isCanceled: null,
        orIsCanceled: null,
        bookingPaidStatusCode: null,
        atd: null,
        duePaymentDate: null,
        hasPayments: null,
        any: null
      };

      this.showParameter = Number(this.route.snapshot.queryParams.show) as BookingsShowParameters;

      if (this.showParameter === BookingsShowParameters.draft) {
        this.filters.orderStatuses = [BookingStatusCodeEnum.DRFT];
        this.filters.any = true;
        this.filters.paidStatusCodes = [BookingPaidStatusCode.unPaid, BookingPaidStatusCode.partialyPaid];
      } else if (this.showParameter === BookingsShowParameters.woDraft) {
        this.filters.orderStatuses = [
          BookingStatusCodeEnum.ORSB,
          BookingStatusCodeEnum.ORC,
          BookingStatusCodeEnum.BKD,
          BookingStatusCodeEnum.CRD,
          BookingStatusCodeEnum.DOC,
          BookingStatusCodeEnum.PKUP,
          BookingStatusCodeEnum.ONHD,
          BookingStatusCodeEnum.ACO,
          BookingStatusCodeEnum.CDO,
          BookingStatusCodeEnum.ONB,
          BookingStatusCodeEnum.DEP,
          BookingStatusCodeEnum.HBI,
          BookingStatusCodeEnum.ANS,
          BookingStatusCodeEnum.AAS,
          BookingStatusCodeEnum.ACD,
          BookingStatusCodeEnum.CDD,
          BookingStatusCodeEnum.TTR,
          BookingStatusCodeEnum.CTRA,
          BookingStatusCodeEnum.DEO,
          BookingStatusCodeEnum.OFD,
          BookingStatusCodeEnum.DLV,
          BookingStatusCodeEnum.POD,
          BookingStatusCodeEnum.CTRR,
          BookingStatusCodeEnum.INV,
          BookingStatusCodeEnum.TFD,
          BookingStatusCodeEnum.ACL,
          BookingStatusCodeEnum.CAU,
          BookingStatusCodeEnum.ARW,
          BookingStatusCodeEnum.AWB,
          BookingStatusCodeEnum.MAN,
          BookingStatusCodeEnum.UPA,
          BookingStatusCodeEnum.RCF,
          BookingStatusCodeEnum.LPA,
          BookingStatusCodeEnum.CCD,
        ];
      } else {
        this.filters.isCanceled = false;
      }
    }
  }

  private getOrderStatuses(filters): BookingStatusCodeEnum[] {
    let orderStatusesArray: BookingStatusCodeEnum[] = [];

    this.orderStatuses.forEach(e => {
      if (e.isChecked) {
        orderStatusesArray = orderStatusesArray.concat(e.value);
      }

      if (e.isChild === true) {
        e.childStatuses.forEach(e => {
          if (e.isChecked === true) {
            orderStatusesArray = orderStatusesArray.concat(e.value);
            if (e.statusName === 'Cancelled') {
              this.isCanceled = true;
            }
          }
          else {
            if (e.statusName === 'Cancelled') {
              this.isCanceled = false;
            }
          }
        });
      }
    });

    if (orderStatusesArray.length > 0 && this.isCanceled === true) {
      filters.orIsCanceled = true;
      this.isCanceled = false;
    }
    else if (orderStatusesArray.length === 0) {
      this.isCanceled = this.isCanceled === true ? this.isCanceled : null;
    }
    return orderStatusesArray;
  }

  private getIsMobileDasboardEnabled(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );

    switch (environmentName) {
      case "qa":
        return environment.qa.isMobileDashboardEnabled;
      case "sb":
        return environment.sb.isMobileDashboardEnabled;
      default:
        return environment.isMobileDashboardEnabled;
    }
  }
}
