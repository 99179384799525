<div class="flow-filter">
  <div class="flow-filter-content px-3">
    <div class="row m-0 filter-group" *ngIf="isQuoteBadgeEnabled">
      <div class="col-12 px-0 mb-3">
        <span class="filter-group-title">Show quotes</span>
      </div>
      <div class="col-12 px-0">
        <mat-radio-group [(ngModel)]="selectedFilteredBy">
          <div class="row m-0 mb-3 filter" *ngFor="let option of filteredByOptions">
            <label class="d-flex w-100 mb-0 p-0" [ngClass]="{'selected': selectedFilteredBy === option.value}">
              <div class="col px-0">
                <span class="filter-name">{{ option.name }}</span>
              </div>
              <div class="col-auto px-0 filter-option">
                <mat-radio-button [value]="option.value"></mat-radio-button>
              </div>
            </label>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="row m-0 filter-group" *ngIf="!isQuoteBadgeEnabled">
      <div class="col-12 px-0 mb-3">
        <span class="filter-group-title">Sort by</span>
      </div>
      <div class="col-12 px-0">
        <mat-radio-group [(ngModel)]="sortBy">
          <div class="row m-0 mb-3 filter" *ngFor="let option of sortByOptions">
            <div class="col px-0">
              <span class="filter-name">{{ option.name }}</span>
            </div>
            <div class="col-auto px-0 filter-option">
              <mat-radio-button [value]="option.value"></mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="row m-0 filter-group">
      <div class="col-12 px-0 mb-3">
        <span class="filter-group-title">Importing/Exporting</span>
      </div>
      <div class="col-12 px-0">
        <mat-radio-group [(ngModel)]="shipperType">
          <div class="row m-0 mb-3 filter" *ngFor="let option of shipperTypes">
            <div class="col px-0">
              <span class="filter-name">{{ option.name }}</span>
            </div>
            <div class="col-auto px-0 filter-option">
              <mat-radio-button [value]="option.value"></mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="row m-0 filter-group">
      <div class="col-12 px-0 mb-3">
        <span class="filter-group-title">Base Services</span>
      </div>
      <div class="col-12 px-0">
        <div class="row m-0 mb-3 filter">
          <div class="col-auto px-0">
            <div class="filter-icon-wrapper">
              <i class="filter-icon s4d-icon s4d-globe"></i>
            </div>
          </div>
          <div class="col px-2">
            <span class="filter-name">Freight Shipping</span>
          </div>
          <div class="col-auto px-0 filter-option">
            <mat-slide-toggle [(ngModel)]="isFreightShipping" disabled="true"></mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="isPickupAtOrigin" class="row m-0 mb-3 filter">
          <div class="col-auto px-0">
            <div class="filter-icon-wrapper">
              <i class="filter-icon s4d-icon s4d-shipping-box"></i>
            </div>
          </div>
          <div class="col px-2">
            <span class="filter-name">Door Pickup</span>
          </div>
          <div class="col-auto px-0 filter-option">
            <mat-slide-toggle [(ngModel)]="isDoorPickup"></mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="isDeliveryOnDestination" class="row m-0 mb-3 filter">
          <div class="col-auto px-0">
            <div class="filter-icon-wrapper">
              <i class="filter-icon s4d-icon s4d-shipping-box"></i>
            </div>
          </div>
          <div class="col px-2">
            <span class="filter-name">Door Delivery</span>
          </div>
          <div class="col-auto px-0 filter-option">
            <mat-slide-toggle [(ngModel)]="isDoorDelivery"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flow-filter-footer">
    <div class="row m-0">
      <div class="col-12 px-0">
        <button mat-button class="flow-filter-confirm-button" (click)="onConfirm()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
