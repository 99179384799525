import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { InternalUrlsService } from "@ship4wd/ngx-common";

@Injectable()
export class HttpService {
  access_token: string;

  constructor(
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService,
    private dailog: MatDialog,
    private router: Router
  ) {
  }

  public get<TViewModel>(
    controller: string,
    action?: string,
    id?: string,
    params?: {},
    host?: string
  ) {

    const url = this.getUrl(controller, action, id, host);
    const watcher = new Observable<any>((observer) => {
      const response = this.http
        .get(url, this.createOptions(params))
        .pipe(map(this.onMap));
      this.handleResponse(observer, response);
    });

    return watcher;
  }

  public put<TForm>(
    controller: string,
    action?: string,
    id?: string,
    params?: any,
    formData?: TForm,
    host?: string
  ) {

    const url = this.getUrl(controller, action, id, host);
    const watcher = new Observable<any>((observer) => {
      const response = this.http
        .put(url, formData, this.createOptions(params))
        .pipe(map(this.onMap));
      this.handleResponse(observer, response);
    });

    return watcher;
  }

  public post<TForm>(
    controller: string,
    action: string,
    id?: string,
    form?: TForm,
    params?: {},
    host?: string
  ) {
    const url = this.getUrl(controller, action, id, host);
    const watcher = new Observable<any>((observer) => {
      const response = this.http
        .post(url, form, this.createOptions(params))
        .pipe(map(this.onMap));
      this.handleResponse(observer, response);
    });

    return watcher;
  }

  public delete(
    controller: string,
    action: string,
    params?: any,
    host?: string
  ) {
    const url = this.getUrl(controller, action, null, host);
    const watcher = new Observable<any>((observer) => {
      const response = this.http
        .delete(url, this.createOptions(params))
        .pipe(map(this.onMap));
      this.handleResponse(observer, response);
    });

    return watcher;
  }

  public setBearerToken(token) {
    this.access_token = token;
  }

  public clearBearerToken() {
    this.access_token = "";
  }

  private getUrl(controller: string, action: string, id?: string, host?: string): string {
    const baseUrl = this.getBaseUrl(host);
    if (controller && action && id) {
      return `${baseUrl}/${controller}/${action}/${id}`;
    } else if (controller && action) {
      return `${baseUrl}/${controller}/${action}`;
    } else {
      return `${baseUrl}/${controller}`;
    }
  }

  private getBaseUrl(host?: string): string {
    return host ? this.internalUrlsService.getCustomUrl(host) : this.internalUrlsService.getApiBaseUrl();
  }

  private handleResponse(observer: Subscriber<any>, response: Observable<any>) {
    let hasResponse = false;
    const subscription = response.subscribe(
      (data) => {
        hasResponse = true;
        observer.next(data);
      },
      (error) => {
        if (error.status === 401) {
          this.dailog.closeAll();
          this.router.navigate(["/", "application", "logout"]);
        }

        hasResponse = true;

        if (error.error) {
          error._body = error.error;
        }

        observer.error(error);
      },
      () => {
        subscription.unsubscribe();
        observer.complete();
      }
    );
  }

  private createOptions(params?): {
    headers?: HttpHeaders;
    observe: 'response';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  } {
    
    let headers = new HttpHeaders();
    if (this.access_token) {
      headers = headers.append('Authorization', this.access_token);
    }

    params = params ?? new HttpParams();
    const options = {
      params,
      headers,
      observe: 'response',
      responseType: 'text',
      reportProgress: false,
      withCredentials: undefined
    };

    return options as any;
  }

  private onMap(response: HttpResponse<string>) {
    try {
      return JSON.parse(response.body);
    } catch (ex) {
      return response.body;
    }
  }
}
