import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { RateServiceType, ShipmentType } from '../../../../../../shared/shared.model';
import { QuotePriceTiersComparisonComponent } from '../../../quote-row-variants/quote-price-tiers-comparison/quote-price-tiers-comparison.component';
import { RateDetailPanels } from '../quote-rate-detail-row.model';

@Component({
  selector: 'app-quote-rate-detail-row-service',
  templateUrl: './quote-rate-detail-row-service.component.html'
})
export class QuoteRateDetailRowServiceComponent implements OnInit {
  @Input() quoteRateServiceType: RateServiceType;
  @Input() shipmentType: ShipmentType;
  @Input() currentPanelName: string;
  @Output() onIsExpand = new EventEmitter<boolean>();

  rateServiceType = RateServiceType;
  rateDetailsPanels = RateDetailPanels;
  isExpanded: boolean = false;
  ShipmentType = ShipmentType;

  constructor(public dialog: MatDialog, private readonly sso: ScrollStrategyOptions) { }

  ngOnInit(): void {
    this.updatePanel();
  }

  isBasicRateServiceType(): boolean {
    return this.rateServiceType.Basic === this.quoteRateServiceType;
  }

  getRateServiceTypeTooltip(): string {
    switch (this.quoteRateServiceType) {
      case RateServiceType.Premium:
      case RateServiceType.Standard:
        return `These rates are fixed, and valid for a longer period of time. They are best for planned or recurring shipments.`;
      case RateServiceType.Basic:
        return `These are one-time prices with limited availability based on the current market. These are best for urgent or immediate shipping needs.`;
      default:
        return ``;
    }
  }

  onRateServiceType(): void {
    const dialogRef = this.dialog.open(QuotePriceTiersComparisonComponent, {
      autoFocus: false,
      scrollStrategy: this.sso.noop(),
      data: {},
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });

    dialogRef.afterClosed().subscribe((result: any) => { });
  }

  onClickPanel(state: boolean): void {
    this.isExpanded = state;
    this.onIsExpand.emit(this.isExpanded);
  }

  updatePanel(): void {
    if (this.currentPanelName === this.rateDetailsPanels.SERVICES.toString()) {
      this.isExpanded = true;
    }
  }
}
