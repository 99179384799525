import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WidgetItem, WidgetType } from "../../../../shared/widgets/widgets.model";

@Component({
  selector: "app-widget-review",
  templateUrl: "./widget-review.component.html",
  styleUrls: ["./widget-review.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetReviewComponent implements OnInit {
  widgetTypes = WidgetType;

  constructor(
    public dialogRef: MatDialogRef<WidgetReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetItem
  ) { }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close();
  }
}
