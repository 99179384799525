<div *ngIf="isLoading; else loaded" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
  <div class="container finance-page-wrapper">
    <div class="title bold">Orders</div>
    <div class="row payment-content-wrapper" *ngFor="let booking of bookings">
      <div class="col-auto main-carriage-icon">
        <img *ngIf="booking.shipmentTypeCode" [src]="
            getMainCarriageIcon(booking.shipmentTypeCode) === 'ship'
              ? '../../../assets/images/ship.svg'
              : '../../../assets/images/air.svg'
          " alt="Shipment Icon" />
      </div>

      <div class="col">
        <div class="row">
          <div class="col title">{{ booking.fromDisplayName }}</div>
        </div>
        <div class="row">
          <div class="col text-container-size">{{ booking.toDisplayName }}</div>
        </div>
        <div class="row">
          <div class="col-3 text-date">{{ booking.updateTimeUtc | date }}</div>
        </div>
      </div>

      <div class="col-auto px-0 icons">
        <span class="status-icon" [ngClass]="
            booking.bookingPaidStatusCode === bookingStatusCodes.paid
              ? 'paid'
              : booking.bookingPaidStatusCode === bookingStatusCodes.partialyPaid
              ? 'partialyPaid'
              : 'failed'
          "></span>
        <span class="arrow-back-icon rotate" (click)="openPayments(booking)"></span>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="this.bookings.length <= 0" class="quote-content-wrapper">
  <div class="quote-content-container">
    <h3 class="quote-title">Get Your first quote!</h3>
    <p class="quote-content">It looks like you don't have any shipments yet. But! It's a life-changing opportunity
      to check our brand-new quote search!</p>
    <div class="quote-button">
      <button type="button" (click)="onSearchQuote()">Search New Quote</button>
    </div>
  </div>
</div>

<app-dashboard-nav *ngIf="isMobileDashboardEnabled"></app-dashboard-nav>
<app-navbar *ngIf="!isMobileDashboardEnabled">
  <div class="col px-3 h-100 align-self-center bottom-navigation-wrapper">
    <div class="row">
      <div class="col px-2">
        <a [routerLink]="['/shipments']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon shipment"></span>
            <span class="nav-link-text">Shipments</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a [routerLink]="['/quote-search']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon search-quote"></span>
            <span class="nav-link-text">Get Quote</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a [routerLink]="['/finance']" [routerLinkActive]="'is-active'" class="nav-link">
          <div class="d-flex flex-column">
            <span class="nav-link-icon finance"></span>
            <span class="nav-link-text">Finance</span>
          </div>
        </a>
      </div>
      <div class="col px-2">
        <a class="nav-link" (click)="onChatClick()">
          <div class="d-flex flex-column">
            <span class="nav-link-icon chat"></span>
            <span class="nav-link-text">Chat</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</app-navbar>