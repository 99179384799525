import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService, ValidationService } from '@ship4wd/ngx-common';
import { AppSettingsService } from '../../../../shared/services/app-settings/app-settings.service';
import { AccessRoleType, InviteStatusCode, SelectItem } from '../../../../shared/shared.model';
import { OrganizationInvitationViewModel, TeamMembersInvite } from '../../manage-organization.model';
import { InviteMemberService } from './invite-member.service';

@Component({
  selector: 'app-invite-member-dialog',
  templateUrl: './invite-member-dialog.component.html',
  styleUrls: ['./invite-member-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteMemberDialogComponent implements OnInit {
  inviteMember: TeamMembersInvite;
  inviteMemberForm: FormGroup;
  isLoading = false;
  mode: string;

  inviteStatusCodes: SelectItem[] = [
    {
      name: InviteStatusCode[InviteStatusCode.pending],
      value: InviteStatusCode.pending
    },
    {
      name: InviteStatusCode[InviteStatusCode.accepted],
      value: InviteStatusCode.accepted
    },
    {
      name: InviteStatusCode[InviteStatusCode.canceled],
      value: InviteStatusCode.canceled
    },
    {
      name: InviteStatusCode[InviteStatusCode.expired],
      value: InviteStatusCode.expired
    },
    {
      name: InviteStatusCode[InviteStatusCode.rejected],
      value: InviteStatusCode.rejected
    }
  ];

  teamAccessRoles: SelectItem[] = [
    {
      name: AccessRoleType[AccessRoleType.owner],
      value: AccessRoleType.owner
    },
    {
      name: AccessRoleType[AccessRoleType.agent],
      value: AccessRoleType.agent
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<InviteMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    private inviteMemberService: InviteMemberService,
    private settingsService: AppSettingsService
  ) { }

  ngOnInit() {
    this.inviteMember = this.data?.inviteMember;
    this.mode = this.data?.mode;
    this.createInviteMemberForm();
  }

  getInitialName() {
    if (this.inviteMember && this.inviteMember.firstName) {
      return this.inviteMember.firstName.charAt(0).toUpperCase();
    }
    return '';
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.validationService.validate(this.inviteMemberForm);
    if (!this.inviteMemberForm.valid) {
      this.notificationService
        .error('Can\'t save changes because of an input errors, please check out the form');
      return;
    }

    const inviteMember = this.prepareInviteMemberViewModel();
    this.updateInviteMember(inviteMember);
  }

  private updateInviteMember(inviteMember: OrganizationInvitationViewModel) {
    this.isLoading = true;
    this.inviteMemberService
      .addEditInviteMember(inviteMember)
      .subscribe((x: any) => {
        let createAccountMessage = this.getCreateAccountMessage(x.isTeamMemberExist);
        if (this.mode === 'add') {
          this.notificationService.success(`Invitation has been successfully sent. ${createAccountMessage}`);
        }
        else {
          this.notificationService.success(`Invite member has been successfully changed. ${createAccountMessage}`);
        }
        this.dialogRef.close({
          isSuccess: true
        });
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false)
  }

  private prepareInviteMemberViewModel() {
    const inviteMember = this.inviteMemberForm.getRawValue();
    let inviteMemberViewModel = new OrganizationInvitationViewModel();
    inviteMemberViewModel.organizationId = this.settingsService.getSettings()?.organizationId;
    inviteMemberViewModel.email = inviteMember.email;
    inviteMemberViewModel.firstName = inviteMember.firstName;
    inviteMemberViewModel.lastName = inviteMember.lastName;
    inviteMemberViewModel.nickName = inviteMember.nickName;
    if (inviteMember.roles) {
      let roles = '';
      inviteMember.roles.forEach((x) => {
        roles += x + ',';
      });
      inviteMemberViewModel.roles = roles.slice(0, -1);
    }
    return inviteMemberViewModel;
  }

  private createInviteMemberForm() {
    this.inviteMemberForm = this.fb.group({
      firstName: [
        { value: this.getPropertyValue('firstName'), disabled: this.isDisabled() },
        this.isRequired() ? Validators.required : [],
      ],
      lastName: [
        { value: this.getPropertyValue('lastName'), disabled: this.isDisabled() },
        this.isRequired() ? Validators.required : [],
      ],
      nickName: [this.getPropertyValue('nickName')],
      roles: [this.getPropertyValue('roles'), Validators.required],
      email: [
        { value: this.getPropertyValue('email'), disabled: this.isDisabled() },
        this.isRequired() ? [Validators.required, Validators.email] : [],
      ],
      inviteStatusCode: [
        { value: this.getPropertyValue('inviteStatusCode'), disabled: this.isDisabled() }
      ],
      invitationCreationDate: [this.getPropertyValue('nickName')],
      expirationDate: [this.getPropertyValue('expirationDate')],
      invitedBy: [this.getPropertyValue('createdByName')],
    });
  }

  private getPropertyValue(propertyName: string) {
    if (this.inviteMember) {
      if (propertyName === 'roles') {
        if (this.inviteMember[propertyName]) {
          const roles: AccessRoleType[] = [];
          this.inviteMember[propertyName].split(',').map(Number).forEach((x: AccessRoleType) => {
            roles.push(x);
          });
          return roles;
        }
      }
      else {
        return this.inviteMember[propertyName] ?? '';
      }
    }
    else {
      return '';
    }
  }

  private isDisabled() {
    return this.mode === 'add' ? false : true;
  }

  private isRequired() {
    return this.mode === 'add' ? true : false;
  }

  private getCreateAccountMessage(isTeamMemberExist: boolean): string{
    return !isTeamMemberExist ? 'Please, ask the user to create an account.' : ''
  }
}
