import { Pipe, PipeTransform } from '@angular/core';
import { BookingPaidStatusCode } from '../shared.model';


@Pipe({ name: 'bookingPaidStatusName' })
export class BookingPaidStatusNamePipe implements PipeTransform {
  private bookingPaidStatusNames = {};

  constructor() {
    this.bookingPaidStatusNames[BookingPaidStatusCode.paid] = 'Paid';
    this.bookingPaidStatusNames[BookingPaidStatusCode.partialyPaid] = 'Partialy paid';
    this.bookingPaidStatusNames[BookingPaidStatusCode.unPaid] = 'Unpaid';
  }

  transform(value?: BookingPaidStatusCode): string {
    return this.bookingPaidStatusNames[value] ?? '';
  }
}
