<div class="country-auto-complete-container w-100">
  <ng-container *ngIf="floatLabel==='never'">
    <mat-form-field [floatLabel]="floatLabel" class="country-auto-complete-field w-100">
      <mat-label class="form-label">{{ title }}</mat-label>
      <input type="text" matInput [formControl]="autoCompleteCountryControl" [matAutocomplete]="autoCountry"
        (change)="onAutocompleteChange()" placeholder="{{title}}" (blur)="onBlur()" appAutocompletePosition
        trackInputErrors (triggerError)="handleError($event)" [controlName]="controlName">
      <input type="text" matInput hidden [formControl]="countryControl">
      <div class="button-spinner" matSuffix>
        <span class="button-inner">
          <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
        </span>
        <i *ngIf="!isLoading && countryControl.disabled" class="s4d-icon s4d-lock"></i>
      </div>
      <mat-autocomplete #autoCountry="matAutocomplete" class="country-autocomplete" [displayWith]="displayFn">
        <mat-option class="country" *ngFor="let item of filteredCountries | async" [value]="item"
          (onSelectionChange)="onSelectCountry(item)">
          <span class="country-option-wrapper">
            <span class="country-option">
              <span class="country-name font-weight-bold">
                {{ item.name }}</span>
            </span>
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="floatLabel==='auto'">
    <mat-form-field appearance="fill" class="w-100 organization-form-field float-label" [floatLabel]="'never'">
      <mat-label class="form-label">{{ title }}</mat-label>
      <div class="row h-100 country-detailes-content">
        <div class="col d-flex align-items-end">
          <input type="text" matInput [formControl]="autoCompleteCountryControl" [matAutocomplete]="autoCountry"
            (change)="onAutocompleteChange()" placeholder="{{title}}" (blur)="onBlur()" appAutocompletePosition
            trackInputErrors (triggerError)="handleError($event)" [controlName]="controlName">
          <input type="text" matInput hidden [formControl]="countryControl">
          <div class="button-spinner" matSuffix>
            <span class="button-inner">
              <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
            </span>
            <i *ngIf="!isLoading && countryControl.disabled" class="s4d-icon s4d-lock"></i>
          </div>
          <mat-autocomplete #autoCountry="matAutocomplete" class="country-autocomplete-float" [displayWith]="displayFn">
            <mat-option class="country" *ngFor="let item of filteredCountries | async" [value]="item"
              (onSelectionChange)="onSelectCountry(item)">
              <span class="country-option-wrapper">
                <span class="country-option">
                  <span class="country-name font-weight-bold">
                    {{ item.name }}</span>
                </span>
              </span>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </mat-form-field>
  </ng-container>

</div>