<div class="payment-status-container" *ngIf="!isLoading" [ngClass]="{'mobile': isMobile === true}">
  <div class="payment-status-header">
    <div class="payment-status-header-background">
      <div class="background-sprite sprite-1"></div>
      <div class="background-sprite sprite-2"></div>
      <div class="background-sprite sprite-3"></div>
    </div>
    <div class="container payment-status-header-content">
      <div class="row d-flex justify-content-between py-4 h-100">
        <div class="col d-flex justify-content-center align-items-center">
          <h1 class="status-title">{{ status ? 'Your payment was successful' :
            'We are awaiting your payment' }}</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="payment-status-content">
    <div class="container h-100">
      <div class="row h-100">
        <div *ngIf="!isMobile" class="col-1">
        </div>
        <div class="col px-3">
          <span class="content-subtitle">Your order is being processed</span>
          <h3 class="content-title">Here's a quick overview of what to expect next:</h3>
          <ul class="content-list mt-4">
            <li *ngFor="let step of filteredSteps" class="content-list-item">
              <p class="list-item-title">{{ step.title }}</p>
              <p class="list-item-content">{{ step.description }}
                <a *ngIf="step.type === paymentResultTypes.WAITING" class="content-link" (click)="onBankDetails()">
                  Show bank details</a>
              </p>
            </li>
          </ul>
        </div>
        <div *ngIf=" !isMobile" class="col-3">
          <div class="wp-content-wrapper" (click)="onWpClick()">
            <div class="wp-content">
              <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <div class="image-wrapper wp-logo">
                    <div class="image-content"></div>
                  </div>
                </div>
                <div class="col-12 mt-4 d-flex justify-content-center align-items-center">
                  <h4 class="wp-title">Have a question for us?</h4>
                </div>
                <div class="col-12 mt-2 d-flex flex-column justify-content-center align-items-center">
                  <p class="wp-details">We're available when you need us on</p>
                  <p class="wp-details colored">WhatsApp +1 (757) 904-9600</p>
                </div>
                <div class="col-12 my-4 d-flex flex-column justify-content-center align-items-center">
                  <div class="wp-divider">or use QR code</div>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <div class="image-wrapper wp-qr">
                    <div class="image-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isMobile" class="col-12 px-3 pb-3">
          <div class="wp-content-wrapper" (click)="onWpClick()">
            <div class="wp-content">
              <div class="row">
                <div class="col">
                  <h4 class="wp-title mb-1">Have a question for us?</h4>
                  <p class="wp-details">We're available when you need us on</p>
                  <p class="wp-details colored">WhatsApp +1 (757) 904-9600</p>
                </div>
                <div class="col-auto d-flex justify-content-end align-items-start">
                  <div class="image-wrapper wp-logo">
                    <div class="image-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isMobile" class="col-1">
        </div>
      </div>
    </div>
  </div>
  <div class="payment-status-footer">
    <div class="container">
      <div class="row justify-content-center">
        <div [ngClass]="isMobile === true ? 'col-12 order-1 mt-1': 'col-auto'">
          <button mat-flat-button class="footer-button dashboard-button" [ngClass]="{'w-100': isMobile === true}"
            (click)="onDashboard()">
            Go to Dashboard
          </button>
        </div>
        <div [ngClass]="isMobile === true ? 'col-12 order-0': 'col-auto'">
          <button mat-flat-button class="footer-button instruction-button" [ngClass]="{'w-100': isMobile === true}"
            (click)="onInstruction()">
            View full instructions
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
