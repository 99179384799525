import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { CreditLineSession, InitCreditLineSession, UpdateCreditLine } from './creditLines.model';
import { CreditLineStatusTransition } from '../shared.model';

@Injectable()
export class CreditLinesService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  initCreditLineSession(organizationId: string): Observable<CreditLineSession | any> {
    const httpOptions = this.createContentTypeHttpOptions();
    var initCreditLine = {
      organizationId: organizationId
    } as InitCreditLineSession;

    return this.http.post<CreditLineSession>(
      `${this.internalUrlsService.getApiBaseUrl()}/creditLines/initCreditLineSession`, initCreditLine, httpOptions);
  }
  
  updateCreditLine(organizationId: string, transition: CreditLineStatusTransition): Observable<any> {
    const httpOptions = this.createContentTypeHttpOptions();
    var initCreditLine = {
      organizationId: organizationId,
      transition: transition
    } as UpdateCreditLine;

    return this.http.put(
      `${this.internalUrlsService.getApiBaseUrl()}/creditLines`, initCreditLine, httpOptions);
  }


  private createContentTypeHttpOptions(contentType?: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}