import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NotificationService } from "@ship4wd/ngx-common";
import { Organization } from "../../../../../desktop/manage-organization/manage-organization.model";
import { OrganizationsService } from "../../../../../desktop/organizations/organizations.service";
import {
  AdditionalServiceType,
  CreateBookingAdditionalServiceCharge,
  CreateBookingAdditionalServiceCharges,
  ExcludedCommoditiesPanels,
  InsurancePanels,
  InsuranceRate,
} from "../../../../../../app/shared/additional-services/additional-services.model";
import { Booking } from "../../../../../../app/shared/bookings/bookings.model";
import { AppSettingsService } from "../../../../../../app/shared/services/app-settings/app-settings.service";
import {
  AdditionalRate,
  CurrencyCode,
  VendorsCode,
} from "../../../../../../app/shared/shared.model";
import { BookingFlowService } from "../../booking-flow.service";

@Component({
  selector: "app-additional-service-insurance-manually",
  templateUrl: "./additional-service-insurance-manually.component.html",
  styleUrls: ["./additional-service-insurance-manually.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AdditionalServiceInsuranceManuallyComponent implements OnInit {
  @Input() additionalRates: AdditionalRate[];
  @Input() bookingId: string;
  @Input() booking: Booking;
  @Output() closeDialog = new EventEmitter<any>();

  isExpanded: boolean[] = [true, false, false, false];
  isExpandedExcludedCommodities: boolean[] = [];
  insurancePanels = InsurancePanels;
  excludedCommoditiesPanels = ExcludedCommoditiesPanels;
  isShowCalculateInsurance: boolean = false;
  cargoInvoiceValueControl: FormControl = new FormControl(
    "",
    Validators.required
  );
  organization: Organization;
  organizationId: string;
  insuranceRate: InsuranceRate;
  calculateInsuranceBtnLoading: boolean = false;
  insurancePrice: number;
  isLoading: boolean = false;
  isTermsAndConditionChecked: boolean = false;
  currencyCodes = CurrencyCode;
  errorMessages: string[] = [];

  constructor(
    private settingsService: AppSettingsService,
    private organizationsService: OrganizationsService,
    private bookingFlowService: BookingFlowService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.organizationsService
      .getOrganization(this.organizationId)
      .subscribe((x: Organization) => {
        this.organization = x;
      });
  }

  onClickPanel(state: boolean, panel: InsurancePanels): void {
    this.isExpanded[panel] = state;
  }

  onClickExcludedCommoditiesPanel(
    state: boolean,
    panel: ExcludedCommoditiesPanels
  ): void {
    this.isExpandedExcludedCommodities[panel] = state;
  }

  onLinkClick(): void {
    window.open(
      this.insuranceRate?.insuranceProduct?.termsAndConditionsLink,
      "_blank"
    );
  }

  onAddInsurance(): void {
    const charges = this.prepareCreateBookingAdditionalServiceCharges();
    const createBookingAdditionalServiceChargesModel = {
      additionalServiceType: AdditionalServiceType.insuranceManually,
      charges: charges,
    } as CreateBookingAdditionalServiceCharges;
    this.saveBookingCustomAdditionalServiceCharges(
      createBookingAdditionalServiceChargesModel
    );
  }

  checkValid(control: FormControl): boolean {
    return !control?.valid && control?.touched;
  }

  onClose(): void {
    this.closeDialog.emit();
  }

  onChangeTermsAndConditions(termsAndCondition: any): void {
    this.isTermsAndConditionChecked = termsAndCondition.checked;
  }

  private prepareCreateBookingAdditionalServiceCharges(): CreateBookingAdditionalServiceCharge[] {
    let charges = new Array<CreateBookingAdditionalServiceCharge>();

    this.additionalRates.forEach((x) => {
      const charge = this.prepareBookingAdditionalServiceCharge(x);
      charges.push(charge);
    });

    return charges;
  }

  private prepareBookingAdditionalServiceCharge(
    additionalRate: AdditionalRate
  ): CreateBookingAdditionalServiceCharge {
    return {
      bookingId: this.bookingId,
      additionalServiceChargeId: additionalRate
        ? additionalRate.additionalType
        : null,
      vendorCode: VendorsCode.default,
      price: 0,
      pricePer: 1,
      currencyCode: 1,
    } as CreateBookingAdditionalServiceCharge;
  }

  private saveBookingCustomAdditionalServiceCharges(
    model: CreateBookingAdditionalServiceCharges
  ): void {
    this.isLoading = true;
    this.bookingFlowService
      .addAdditionalServices(this.bookingId, model)
      .subscribe(
        () => {
          this.closeDialog.emit({
            isBookingAdditionalServiceUpdated: true,
            additonalServiceType: model.additionalServiceType,
          });
        },
        (error: any) => {
          this.notificationService.error(error);
        }
      )
      .add(() => {
        this.isLoading = false;
      });
  }
}