import { Injectable } from '@angular/core';

@Injectable()
export class FormatDateService {

  toISODateString(date) {
    if (this.isIsoDate(date)) {
      return date;
    }
    date = new Date(date);
    date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return date.toISOString();
  }

  private isIsoDate(date) {
    return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(date);
  }
}
