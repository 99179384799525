import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { PriceTrend, PriceTrendModelQuery } from './price-history-chart.model';
import { EncodeUrlCodec } from '../services/encode-url-codec';

@Injectable()
export class PriceHistoryChartService {

  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  getPriceTrendsByQuery(query: PriceTrendModelQuery): Observable<PriceTrend> {
    return this.http.post<PriceTrend>(
      `${this.internalUrlsService.getApiBaseUrl()}/priceTrends/getPriceTrendsByQuery`, query);
  }
}