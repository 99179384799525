<div class="flow-location">
  <div class="flow-location-header">
    <div class="row m-0">
      <div class="col-12">
        <mat-form-field class="w-100" [floatLabel]="'never'" appearance="fill">
          <div class="row m-0">
            <div class="col px-0">
              <input id="searchTerm" [formControl]="searchTerm" type="text" matInput
                placeholder="Type city or postcode" />
            </div>
            <div class="col-auto pe-0">
              <div class="input-icon-wrapper" (click)="
                  !isLoading && !control.value ? onClear() : onClear(true)">
                <i *ngIf="!isLoading && !control.value" class="input-icon s4d-icon s4d-search"></i>
                <i *ngIf="!isLoading && control.value" class="input-icon s4d-icon s4d-close"></i>
                <mat-spinner *ngIf="isLoading" class="input-icon location-loader" diameter="22"></mat-spinner>
              </div>
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="flow-location-content px-3">
    <div *ngIf="sortedMap?.length !== 0 && !isLoading" class="locations">
      <ng-container *ngIf="!isPostCode">
        <div *ngFor="let locationGroup of sortedMap" class="locations-group">
          <div class="locations-group-title">
            {{ locationGroup.key | locationTypeGroupName : type }}
          </div>
          <div *ngFor="let location of locationGroup.value" class="row locations-group-item"
            (click)="onSelect(location, locationGroup.key, true)">
            <div class="col-auto">
              <i class="contact-country-flag-icon fi fi-{{
                  location.country | lowercase
                }}"></i>
            </div>
            <div class="col px-0 location-wrapper">
              <div class="location-content">
                <span class="location-content-strong">{{ location?.cuttedDisplayName }},</span>
                {{ location?.countryName }}
              </div>
              <div class="location-content" *ngIf="getLocationDoorAdress(location, true) && !isDomesticRoute(location)"
                [matTooltip]="getLocationDoorAdress(location, false)">
                <span class="door-location-full-address">{{getLocationDoorAdress(location, true)}}</span>
              </div>
              <div class="location-content" *ngIf="isDomesticRoute(location)">
                <span class="door-location-full-address domestic-error">Destination cannot be within the same
                  country</span>
              </div>
            </div>
            <div class="col-auto">
              <span *ngIf="location.locationType | locationTypeShortName" class="location-type">
                {{ location.locationType | locationTypeShortName | uppercase }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isPostCode">
        <div class="locations-group">
          <div class="locations-group-title">
            {{ selectedGroupForPostcode | locationTypeGroupName : type }}
          </div>
          <div class="row locations-group-item" (click)="
              onSelect(
                selectedLocationForPostcode,
                selectedGroupForPostcode,
                false
              )
            ">
            <div class="col-auto">
              <i class="contact-country-flag-icon fi fi-{{
                  selectedLocationForPostcode.country | lowercase
                }}"></i>
            </div>
            <div class="col px-0 location-wrapper">
              <div class="location-content">
                <span class="location-content-strong">{{ selectedLocationForPostcode?.cuttedDisplayName }},</span>
                {{ selectedLocationForPostcode?.countryName }}
              </div>
              <div class="location-content" *ngIf="getLocationDoorAdress(location, true) && !isDomesticRoute(location)"
                [matTooltip]="getLocationDoorAdress(location, false)">
                <span class="door-location-full-address">{{getLocationDoorAdress(location, true)}}</span>
              </div>
              <div class="location-content" *ngIf="isDomesticRoute(location)">
                <span class="door-location-full-address">Destination cannot be within the same country</span>
              </div>
            </div>
            <div class="col-auto">
              <span *ngIf="
                  selectedLocationForPostcode.locationType
                    | locationTypeShortName
                " class="location-type">
                {{
                selectedLocationForPostcode.locationType
                | locationTypeShortName
                | uppercase
                }}
              </span>
            </div>
          </div>
          <div class="row flow-location-footer">
            <div class="col px-0">
              <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
                  invalid: fullAddress.touched && !postcode.valid
                }">
                <input matInput #postCodeInput ngx-google-places-autocomplete [options]="placesOptions"
                  (onAddressChange)="onAddressChange($event)" (keydown)="$event.stopPropagation()"
                  [formControl]="fullAddress" type="text" placeholder="Address or Postcode" autofocus />
              </mat-form-field>
            </div>
            <div class="col-auto pe-0 ps-3 d-flex justify-content-center align-items-center">
              <button mat-button class="flow-location-footer-accept-button" (click)="onConfirmPostCode()"
                [disabled]="!postcode.valid && isMustVerifyPostCode(selectedLocation)">
                <i class="flow-location-footer-accept-icon s4d-icon s4d-arrow-right"></i>
              </button>
            </div>
            <mat-error class="booking-flow-error mt-1"
              *ngIf="fullAddress.touched && (!postcode.valid || !fullAddress.valid)">
              <i class="error-icon s4d-icon s4d-info-light"></i> Address has no valid postcode
            </mat-error>
          </div>
        </div>
      </ng-container>
    </div>
    <app-quote-search-flow-loader *ngIf="isLoading" [isLocations]="true"></app-quote-search-flow-loader>
    <div *ngIf="sortedMap?.length === 0 && locationsResponse && !isLoading"
      class="d-flex justify-content-center align-items-center h-100">
      <span>No results found</span>
    </div>
  </div>
</div>