import { CurrencyCode, PaymentReasonCode, PaymentStatusCode, PaymentStatusTransition } from "../shared.model";

export class PaymentRequest {
  id: string;
  bookingId: string;
  duePaymentDate: string;
  amount: number;
  paymentReasonCode: PaymentReasonCode;
  currencyCode: CurrencyCode;
  payment: Payment;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class Payment {
  id: string;
  organizationId: string;
  paymentNumber: number;
  paymentRequestId: string;
  transactionId: string;
  fees: number;
  externalStatus: string;
  paymentStatusCode: PaymentStatusCode;
  createTimeUtc: string;
  updateTimeUtc: string;
  teamMemberId: string;
}

export class UpdatePayment {
  id: string;
  transition: PaymentStatusTransition;
  teamMemberId: string;
}

export class BankDetails {
  id: string;
  countryCode: string;
  accountName: string;
  companyCode: string;
  bankName: string;
  bankAddress: string;
  bankAccountNumber: string;
  routingNumber: string;
  swiftCode: string;
  currencyCode: CurrencyCode;
  createTimeUtc: string;
  updateTimeUtc: string;
}

export class CreditLine {
    statusId: CreditLineStatusCode;
    totalLimit: number;
    availableLimit: number;
}

export enum CreditLineStatusCode {
  new = 1,
  preApproved = 2,
  approved = 3,
  rejected = 4,
  requested = 5,
  increaseRequested = 6
}