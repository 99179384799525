<div class="widget-review">
  <div class="row justify-content-end">
    <div class="col-auto widget-action">
      <span class="close-icon link-action" (click)="onClose()"></span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-quote-search-widget *ngIf="data.item.type === widgetTypes.quoteSearch"
        class="widget"></app-quote-search-widget>
      <app-credits-widget *ngIf="data.item.type === widgetTypes.credits" class="widget"></app-credits-widget>
      <app-quotes-widget *ngIf="data.item.type === widgetTypes.quotes" class="widget"></app-quotes-widget>
      <app-orders-widget *ngIf="data.item.type === widgetTypes.orders" class="widget"></app-orders-widget>
      <app-ads-widget *ngIf="data.item.type === widgetTypes.ads" class="widget"></app-ads-widget>
      <app-recent-quotes-widget *ngIf="data.item.type === widgetTypes.recentSearches"
        class="widget"></app-recent-quotes-widget>
    </div>
  </div>
</div>