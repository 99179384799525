import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from "@ship4wd/ngx-common";
import { Booking, BookingStatusCode, UpdateBookingIsCanceled } from "../../../../shared/bookings/bookings.model";
import { BookingsService } from "../../../../shared/bookings/bookings.service";
import { UtilityService } from "../../../../shared/helper/utility.service";
import {
  BookingFilesDocumentsPage,
  BookingPaidStatusCode,
  BookingViewDetailsPage,
  CarriageStatusType,
  CompletedOrderStatusType,
  ShipmentType
} from "../../../../shared/shared.model";
import * as uuid from 'uuid';
import { environment } from "../../../../../environments/environment";
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { RollupDialogService } from '../../../../mobile/rollup-dialog/rollup-dialog.service';
import { SubOrderViewDashboardComponent } from './sub-order-view-dashboard/sub-order-view-dashboard.component';
import { SalesforceService } from "../../../../shared/services/salesforce.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"]
})
export class OrderComponent implements OnInit {
  @Input() booking: Booking;
  @Input() isOnlyView: boolean = false;
  @Input() isExpandable: boolean = false;
  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  allowedRebook: boolean = false;
  isLoading = false;
  isFinanceEnabled: boolean;
  BookingStatus = BookingStatusCode;
  CarriageTypes = CarriageStatusType;
  BookingPaidStatusCode = BookingPaidStatusCode;
  shipmentType = ShipmentType;
  bookingStatusCode = BookingStatusCode;

  preCarriageStatuses = [
    BookingStatusCode.orderSubmitted
  ] as BookingStatusCode[];
  mainCarriageStatuses = [
    BookingStatusCode.draft
  ] as BookingStatusCode[];

  get fromDisplayName(): string {
    return (this.booking.fromBookingSearch?.value ?? this.booking.from)?.toUpperCase()
  }

  get toDisplayName(): string {
    return (this.booking.toBookingSearch?.value ?? this.booking.to)?.toUpperCase();
  }

  get getCompletedOrderStatusType() {
    return CompletedOrderStatusType;
  }

  constructor(
    public router: Router,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
    private userInfoService: UserInfoService,
    private rollupDialogService: RollupDialogService,
    private salesforceService: SalesforceService
  ) {
    this.isFinanceEnabled = this.getIsFinanceEnabled();
  }

  ngOnInit(): void {
    this.allowedRebook = (this.booking.statusType.code != BookingStatusCode.draft ||
      this.getcompletedOrderStatusType().includes(this.booking.statusType.code)) && !this.booking.isCanceled;
  }

  onGetSteps(): void {
    window.open('https://ship4wd.com/support-category/post-booking-cargo-fulfillment/', "_blank")
  }

  onRebook(booking: Booking): void {
    if (this.allowedRebook) {
      this.router.navigate(['/quote-search'], { queryParams: { reusedBookingId: booking.id } });
    }
  }

  onCancelOrder(booking: Booking): void {
    this.cancelBooking(booking);
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getSubmitButtonText(booking: Booking): string {
    if (booking.isCanceled) {
      return 'Canceled'
    }

    if (!this.isSubmitted(booking)) {
      return 'Resume Order'
    }

    if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled && booking.hasPayments) {
      return 'Pay Order'
    }

    return 'Details'
  }

  isShowPayButton(booking: Booking): boolean {
    return !(this.isSubmitted(booking) &&
      booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid &&
      this.isFinanceEnabled &&
      booking.hasPayments &&
      this.userInfoService.checkIsAgent());
  }

  isSubmitted(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft;
  }

  isHigherOrderReceived(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft &&
      booking.statusType.code !== BookingStatusCode.orderSubmitted &&
      booking.statusType.code !== BookingStatusCode.orderReceived
  }

  onFilesDocuments(bookingId: string): void {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.dashboard, BookingFilesDocumentsPage.dashboard, bookingId]);
  }

  isShowShippingInstructions(booking: Booking): boolean {
    return this.utilityService.isBookingSubmitted(booking);
  }

  isShowViewDetails(booking: Booking): boolean {
    return booking.isCanceled || this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && booking.hasPayments;
  }

  getShipperName(booking: Booking): string {
    return booking?.shipperContact?.companyName;
  }

  onSubmitButton(booking: Booking): void {
    if (!this.isSubmitted(booking)) {
      this.router.navigate(['/booking', booking.id, true]);
    }
    else if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled && booking.hasPayments) {
      this.openPayments(booking);
    }
    else {
      this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, booking.id]);
    }
  }

  isTripDurationDefaultValue(tripDuration: string): boolean {
    return tripDuration == '00:00:00' || tripDuration == null;
  }

  onShowOrderDetails(booking: Booking): void {
    const data = {
      booking: booking
    };

    const dialogRef = this.rollupDialogService.open(SubOrderViewDashboardComponent, data);

    dialogRef.subscribe(data => { });
  }

  getShipmentIcon(): string {
    switch (this.booking?.shipmentTypeCode) {
      case ShipmentType.FCL:
        return "s4d-fcl";
      case ShipmentType.LCL:
        return "s4d-lcl";
      case ShipmentType.AIR:
        return "s4d-ac";
      default:
        break;
    }
  }

  async getConversation(): Promise<void> {
    await this.salesforceService.openChat();
  }

  displayStatusIcon(carriageType: CarriageStatusType): boolean {
    if (this.mainCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return this.booking.statusType.carriageType === carriageType;
  }

  getMainCarriageIcon(): string {
    switch (this.booking.shipmentTypeCode) {
      case ShipmentType.AIR: {
        return 'plane';
      }
      default: {
        return 'ship';
      }
    }
  }

  getDraftValid(expirationTime: string): string {
    const expirationDate = new Date(expirationTime);
    const currentDate = new Date();
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const expirationHours = Math.floor(timeDifference / (1000 * 60 * 60)).toString();
    return `${expirationHours} h left`;
  }

  private openPayments(booking: Booking): void {
    this.router.navigate(['/payments', booking.id]);
  }

  private getcompletedOrderStatusType(): BookingStatusCode[] {
    return CompletedOrderStatusType;
  }

  private cancelBooking(booking: Booking): void {
    const updateBookingIsCanceledModel = this.prepareUpdateBookingIsCanceled(booking);

    this.isLoading = true;
    this.bookingsService
      .updateBookingIsCanceled(booking.id, updateBookingIsCanceledModel)
      .subscribe((x: Booking) => {
        this.notificationService.success('Order has been cancelled successfully');
        this.canceledBookingEvent.emit(booking.id);
        this.navigateToOrderList();
      },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  private prepareUpdateBookingIsCanceled(booking: Booking): UpdateBookingIsCanceled {
    const updateBookingIsCanceled = new UpdateBookingIsCanceled();
    updateBookingIsCanceled.isCanceled = true;
    updateBookingIsCanceled.userId = uuid.v4();
    updateBookingIsCanceled.organizationId = booking.organizationId;
    updateBookingIsCanceled.isAgent = !booking.isAgentLocked;
    return updateBookingIsCanceled;
  }

  private navigateToOrderList(): void {
    this.router.navigate(['/dashboard']);
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }
}
