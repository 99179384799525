import { Component, Input } from '@angular/core';
import { Quote } from '../../../quotes.model';

@Component({
  selector: 'app-quote-row-price-redesigned',
  templateUrl: './quote-row-price.component.html',
  styleUrls: ['./quote-row-price.component.scss']
})
export class QuoteRowPriceRedesignedComponent {
  @Input() quote: Quote;

  get isPartialQuote(): boolean {
    return this.quote.trip.isPreCarriageMissingRate || this.quote.trip.isPostCarriageMissingRate;
  }

  get getPartialQuoteTooltipText(): string {
    const isPreMissing = this.quote.trip.isPreCarriageMissingRate;
    const isPostMissing = this.quote.trip.isPostCarriageMissingRate;

    let missingLegRate = 'pickup & delivery';
    if (isPreMissing && !isPostMissing) {
      missingLegRate = 'pickup';
    }
    else if (!isPreMissing && isPostMissing) {
      missingLegRate = 'delivery';
    }

    return `Door ${missingLegRate} costs are currently
      being processed and will be added to your
      pricing shortly.`;
  }

  get getFreightCharges(): number {
    return [
      this.quote.trip?.mainCarriageTripLeg,
    ].reduce((accumulatedPrice, carriage) => {
      if (carriage?.legs?.length > 0) {
        return accumulatedPrice + carriage?.legs?.reduce(
          (freightCharges, leg) =>
            freightCharges + leg.rates?.reduce(
              (legPrice, rate) =>
              (
                legPrice +
                (rate.freightRate?.shipmentRate?.totalAmount || 0)
                + rate.surcharges.reduce(
                  (ratePrice, charge) => {
                    switch (charge.applicableType?.toUpperCase()) {
                      case "FREIGHT":
                        return ratePrice + charge?.shipmentRate.totalAmount;
                      default:
                        return ratePrice + 0;
                    }
                  }, 0
                )
              ), 0
            ), 0)
      }

      return accumulatedPrice + 0
    }, 0);
  }

  get getDangerousGoodsSurcharges(): number {
    return [
      this.quote.trip?.mainCarriageTripLeg,
    ].reduce((accumulatedPrice, carriage) => {
      if (carriage?.legs?.length > 0) {
        return accumulatedPrice + carriage?.legs?.reduce(
          (freightCharges, leg) =>
            freightCharges + leg.rates?.reduce(
              (legPrice, rate) =>
              (
                legPrice +
                rate.surcharges.reduce(
                  (ratePrice, charge) => {
                    if (charge.applicableType?.toUpperCase() === "DANGEROUS") {
                      return ratePrice + charge?.shipmentRate.totalAmount;
                    }
                    return ratePrice;
                  }, 0
                )
              ), 0
            ), 0)
      }

      return accumulatedPrice + 0
    }, 0);
  }

  get getLocalCharges(): number {
    return this.quote.accumulatePrice - this.getFreightCharges - this.getDangerousGoodsSurcharges;
  }

  constructor() { }

  isShowReverseDiscountPrice(): boolean {
    return this.quote.trip?.reverseDiscount > 0 && this.getFreightCharges > 0;
  }

  getReverseDiscountPrice(): number {
    return this.getFreightCharges + this.quote.trip.reverseDiscount;
  }
}
