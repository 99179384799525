<form [formGroup]="organizationForm" class="organization-create-form">
  <div class="row organization-create-form-content">
    <div class="col-12">
      <div class="org-field">
        <span class="field-title">Full address</span>
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'never'" S4DPlaces>
          <input matInput type="text" placeholder="Full address" ngx-google-places-autocomplete
            (onAddressChange)="onAddressChange($event)" formControlName="address" />
        </mat-form-field>
      </div>
      <div class="org-field">
        <span class="field-title">Company name</span>
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'never'">
          <input matInput type="text" placeholder="Start type your company name" formControlName="company"
            [matAutocomplete]="autoCompany" appAutocompletePosition/>
          <div class="button-spinner autocomplete-spinner" matSuffix>
            <span class="button-inner">
              <mat-spinner diameter="25" *ngIf="isCompaniesLoading"></mat-spinner>
              <span class="company-selected" *ngIf="isCompanySelected"></span>
            </span>
          </div>
          <mat-autocomplete class="organization-autocomplete" #autoCompany="matAutocomplete"
            [displayWith]="displayCompanyFn">
            <mat-option class="city" *ngFor="let item of filteredCompanies | async" [value]="item.companyName"
              (onSelectionChange)="onSelectCompany(item)">
              <span class="option-main-color">{{ item.companyName }}</span>, <span class="option-secondary-color">{{
                item.postalCode }}</span>,
              <span class="option-secondary-color">{{ item.streetAddress }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

<app-navbar>
  <div class="col px-3 align-self-center organizations-buttons-wrapper">
    <div class="row">
      <div class="col">
        <button mat-stroked-button class="back-button" (click)="onCancel()">Cancel</button>
      </div>
      <div class="col">
        <button mat-raised-button class="next-button button-spinner" [disabled]="isSubmit" (click)="onSubmit()">
          <span class="button-inner">
            Submit
            <mat-spinner diameter="25" *ngIf="isSubmit"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</app-navbar>