<mat-dialog-content tabindex="-1" class="additional-service-details-dialog-container-content">
  <div class="d-flex flex-column justify-content-between h-100 model-content-detailes-wrapper">
    <div class="additional-service-details-dialog-container">
      <div class="additional-service-container">
        <div class="additional-service-container-wrapper mb-4">
          <div class="row m-0">
            <div class="col-12 px-0 title">
              <h2 class="additional-service-title">
                Insurance
              </h2>
            </div>
            <div class="col-12 px-0 subtitle">
              All goods will be picked up from your doorstep.
            </div>
          </div>
        </div>
        <div class="additional-service-wrapper" *ngIf="!isShowCalculateInsurance">
          <div class="divider-container mb-4">
            <div class="text-wrapper">
              <span class="text">INFORMATION</span>
            </div>
          </div>
          <div class="col-12 px-0 subtitle mb-4">
            Approximate 60% of shipments in transit are under or uninsured, this can represent a massive liability to
            your cash flow. We provide all-risk insurance that gives you:
          </div>
          <div>
            <mat-accordion>
              <div class="col-12 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.peaceOfMind]"
                  (opened)="onClickPanel(true, insurancePanels.peaceOfMind)"
                  (closed)="onClickPanel(false, insurancePanels.peaceOfMind)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-verified"></i>
                        </div>
                        <div class="accordion-title  d-flex align-items-center p-0">Peace of Mind</div>
                        <div class="panel-arrow-icond-flex align-items-center">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.peaceOfMind]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.peaceOfMind]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content">
                    <span>
                      Our Door-to-door coverage includes damage or loss (including
                      general average, mechanical breakdowns, inside theft, fraudulent pick-up, loading
                      and unloading damage, and Acts of God) up to $2M USD limit
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-3 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.costEffective]"
                  (opened)="onClickPanel(true, insurancePanels.costEffective)"
                  (closed)="onClickPanel(false, insurancePanels.costEffective)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-coins"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Cost-Effective</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.costEffective]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.costEffective]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      We help you cut coverage costs by as much as 80% with our Al driven dynamic pricing that match
                      your cargo's specifications, quote given in under a minute. claims settled with full invoice
                      value of your cargo - even import duties
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-3 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.flexibility]"
                  (opened)="onClickPanel(true, insurancePanels.flexibility)"
                  (closed)="onClickPanel(false, insurancePanels.flexibility)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-directions"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Flexibility</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.flexibility]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.flexibility]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      Per shipment Pay as you go insurance
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="col-12 mt-3 mb-4 accordion-main-wrapper">
                <mat-expansion-panel class="additional-service-details-expansion-panel"
                  [expanded]="isExpanded[insurancePanels.assurance]"
                  (opened)="onClickPanel(true, insurancePanels.assurance)"
                  (closed)="onClickPanel(false, insurancePanels.assurance)" hideToggle>
                  <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title class="panel-title">
                      <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                        <div class="panel-icon">
                          <i class="panel-header-icon s4d-icon s4d-quality"></i>
                        </div>
                        <div class="accordion-title d-flex align-items-center">Assurance</div>
                        <div class="panel-arrow-icond-flex d-flex align-items-center pe-0">
                          <span class="panel-header-icon-wrapper">
                            <i *ngIf="!this.isExpanded[insurancePanels.assurance]"
                              class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                            <i *ngIf="isExpanded[insurancePanels.assurance]"
                              class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                          </span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="panel-content pt-0">
                    <span>
                      Accelerate claims processing, digitally, from weeks or months to days- even minutes
                    </span>
                  </div>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row dialog-footer">
    <button mat-button class="dialog-button-close col-4" (click)="onClose()">Close</button>
    <button mat-raised-button class="dialog-button col-4 confirm-button-wrapper button-spinner" (click)="onAddInsurance()">
      <span class="button-inner">Add Insurance</span>
      <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
    </button>
  </div>
</mat-dialog-content>