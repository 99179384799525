import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { DocumentComment } from '../../shared/bookings/booking-documents/booking-documents.model';
import { BookingFile } from '../../shared/bookings/bookings.model';

@Injectable()
export class BookingFilesDocumentsService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  addDocumentComment(bookingId: string, documentId: string, comment: DocumentComment) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.post<BookingFile>(
      `${this.internalUrlsService.getApiBaseUrl()}/bookingDocuments/${bookingId}/${documentId}`,
      comment, httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}