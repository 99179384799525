<div class="quote-search-flow-header" [ngClass]="{'is-compact': isCompact}">
  <div class="quote-search-flow-header-background">
    <div class="background-sprite sprite-1"></div>
    <div class="background-sprite sprite-2"></div>
    <div class="background-sprite sprite-3"></div>
  </div>
  <div class="container quote-search-flow-header-container">
    <ng-container *ngIf="!isCompact">
      <div class="row">
        <div class="col-12 d-flex justify-content-center" *ngIf="isOldSearchStyle">
          <span class="header-title">Search for quotes</span>
        </div>
        <div class="col-12 d-flex justify-content-center" *ngIf="!isOldSearchStyle">
          <span class="header-title">Search quotes</span>
        </div>
        
        <div class="col-12 d-flex justify-content-center" *ngIf="isOldSearchStyle">
          <span class="header-subtitle">Find and book your international shipping quotes</span>
        </div>
        <div class="col-12 d-flex justify-content-center" *ngIf="!isOldSearchStyle">
          <span class="header-subtitle">Find and book your international ocean and air shipment quotes for your
            business</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isCompact">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto d-flex align-items-center" *ngIf="isOldSearchStyle">
          <span class="header-title">Search for quotes</span>
        </div>
        <div class="col-auto d-flex align-items-center" *ngIf="!isOldSearchStyle">
          <span class="header-title">Search quotes</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>