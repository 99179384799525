<div class="booking-organization-contacts-container" *ngIf="!isOpenNewContact">
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div [hidden]="isLoading">
    <div class="booking-organization-contacts-wrapper" *ngIf="organizationContacts">
      <div class="row mb-3" *ngFor="let contact of organizationContacts">
        <div class="col">
          <div class="row">
            <div class="col pe-0">
              <div class="booking-organization-contact-wrapper" (click)="onSelectContact(contact)">
                <div class="row">
                  <div class="col-auto d-flex align-items-center">
                    <div class="organization-contact-profile-content">
                      <span class="organization-contact-profile-name">
                        {{ getInitialName(contact) }}
                      </span>
                      <span class="contact-country-flag-icon fi fi-{{contact.countryCode | lowercase}}"></span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row justify-content-between">
                      <div class="col organization-contact-name-wrapper">
                        <span class="organization-contact-name">
                          {{ contact.firstName + ' ' + contact.lastName }}
                        </span>
                      </div>
                      <div class="col-auto" *ngIf="!hideUnsupportedIcons">
                        <mat-chip-list>
                          <mat-chip class="organization-contact-type" color="primary">Shipper</mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                    <div class="row justify-content-between" *ngIf="contact?.companyName">
                      <div class="col h-100 d-flex align-items-end organization-contact-company-name-wrapper">
                        <span class="organization-contact-company-name">
                          {{ contact.companyName }}</span>
                      </div>
                      <div class="col-auto">
                        <div class="organization-contact-rating-wrapper">
                          <span class="organization-contact-rating-icon"></span>
                          <span class="organization-contact-rating-text">5.0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto ps-1 d-flex align-items-center">
              <div class="booking-contact-menu-wrapper">
                <button mat-icon-button class="booking-contact-menu-button" [matMenuTriggerFor]="menu">
                  <app-booking-icons [iconName]="'menu-icon'"></app-booking-icons>
                </button>
                <mat-menu #menu="matMenu" backdropClass="contact-dropdown">
                  <btn mat-menu-item (click)="onViewContact(contact)" class="dropdown-button">
                    <span class="view-icon"></span>
                    <span class="dropdown-text">View</span>
                  </btn>
                  <btn mat-menu-item (click)="onUpdateContact(contact)" class="dropdown-button">
                    <span class="update-icon"></span>
                    <span class="dropdown-text">Update</span>
                  </btn>
                  <btn mat-menu-item (click)="onDeleteContact(contact)" class="dropdown-button">
                    <span class="remove-icon"></span>
                    <span class="dropdown-text">Remove</span>
                  </btn>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-navbar>
    <div class="col px-3 align-self-center booking-organization-contacts-button-wrapper">
      <div class="row">
        <div class="col-auto">
          <button mat-stroked-button class="back-button" type="button" (click)="onBackToBooking()">
            <div class="back-icon"></div>
          </button>
        </div>
        <div class="col">
          <button mat-raised-button class="next-button" (click)="onOpenNewContact()">
            New Contact
          </button>
        </div>
      </div>
    </div>
  </app-navbar>
</div>
<div *ngIf="isOpenNewContact">
  <app-create-booking-organization-contact (backToContact)="onBackToContact()" [booking]="booking"
    (selectedContact)="onSelectedContact($event)" [contactType]="contactType"
    [organizationContact]="organizationContact" [organizationContactAction]="organizationContactAction">
  </app-create-booking-organization-contact>
</div>
