<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="apply-credit-line-wrapper">
  <div class="apply-credit-line-container">
    <div class="apply-credit-line-image">
      <img src="../../../../assets/images/credit-line/credit.svg" alt="" />
    </div>
    <div class="apply-credit-limit-content">
      <h3 class="apply-credit-limit-eligible-title">Apply for Credit Line</h3>
      <p class="apply-credit-limit-content">
        Seize global growth with our credit loan for shipping, order now and pay
        later up to 90 days after cargo arrival.
      </p>
      <div class="apply-credit-limit-eligible-content">
        <a class="apply-credit-limit-eligible-term">How to be eligible?</a>
        <div class="tootltip-content">
          <ol class="tootltip-content-list">
            <li class="tootltip-content-list-item">
              You will need to connect your bank account at payout
            </li>
            <li class="tootltip-content-list-item">
              You have positive balance
            </li>
            <li class="tootltip-content-list-item">
              Your monthly revenue is more than 10,000 USD
            </li>
          </ol>
        </div>
      </div>
      <button type="button" class="apply-credit-limit-claim" (click)="initCreditLine()">
        Apply Now
      </button>
    </div>
  </div>
</div>