<app-quote-search-widget *ngIf="widget.type === widgetTypes.quoteSearch" [widget]="widget" [editable]="editable"
  [data]="data" [preview]="preview" (remove)="onRemove($event)"></app-quote-search-widget>
<app-quotes-widget *ngIf="widget.type === widgetTypes.quotes" [widget]="widget" [editable]="editable" [data]="data"
  [preview]="preview" (remove)="onRemove($event)"></app-quotes-widget>
<app-orders-widget *ngIf="widget.type === widgetTypes.orders" [widget]="widget" [editable]="editable" [data]="data"
  [preview]="preview" (remove)="onRemove($event)"></app-orders-widget>
<app-credits-widget *ngIf="widget.type === widgetTypes.credits" [widget]="widget" [editable]="editable" [data]="data"
  [preview]="preview" (remove)="onRemove($event)"></app-credits-widget>
<app-recent-quotes-widget *ngIf="widget.type === widgetTypes.recentSearches" [widget]="widget" [editable]="editable"
  [data]="data" [preview]="preview" (remove)="onRemove($event)"
  (isEnabled)="onEnabled($event)"></app-recent-quotes-widget>
<app-ads-widget *ngIf="widget.type === widgetTypes.ads" [widget]="widget" [editable]="editable" [data]="data"
  (remove)="onRemove($event)"></app-ads-widget>