<div class="booking-flow-cargo-details">
  <div class="row m-0">
    <div class="col-12 px-0 custom-date-field">
      <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
        <mat-label>Collection Date</mat-label>
        <div class="row m-0 w-100">
          <div class="col px-0 d-flex align-items-end">
            <input matInput [formControl]="collectDateControl" [matDatepicker]="picker"
              [min]="getMinimumCollectionTime()" [max]="maxSelectableDate" placeholder="mm/dd/yyyy"
              (dateChange)="onDateChanged($event.value)" trackInputErrors readonly (focus)="picker.open()"
              (click)="picker.open()" (triggerError)="handleError('Collection Date', $event)"
              controlName="Collection Date" />
          </div>
          <div class="col-auto px-0">
            <mat-datepicker-toggle matSuffix [for]="picker">
              <i matDatepickerToggleIcon class="custom-calendar-icon s4d-icon s4d-calendar"></i>
            </mat-datepicker-toggle>
          </div>
        </div>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error class="booking-flow-error" *ngIf="
            collectDateControl.touched &&
            collectDateControl.hasError('required')
          ">
          <i class="error-icon s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Collection Date & Time'}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="shipmentTypeCode === shipmentTypes.FCL">
    <div class="row mx-0 mt-4">
      <div class="col px-0">
        <span class="section-title">Your containers</span>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 px-0 mt-3" *ngFor="let containerForm of containers?.controls; let i = index">
        <div class="container-card" [ngClass]="{
            'container-card--highlighted': !containerForm.touched && !isContainerFilled(containerForm)
          }" (click)="onOpenContainer(i + 1, containerForm)">
          <div class="row m-0">
            <div class="col-auto d-flex align-items-center ps-0">
              <div class="container-icon-wrapper">
                <i class="s4d-icon s4d-fcl-light container-icon"></i>
                <div class="container-order-wrapper">
                  <span class="container-order">{{ i + 1 }}</span>
                </div>
              </div>
            </div>
            <div class="col px-0">
              <div class="row m-0">
                <div class="col-12 px-0 d-flex">
                  <span class="container-title">{{
                    !containerForm.touched && !isContainerFilled(containerForm) ?
                    getContainersSubTitle(containerForm) :
                    getContainersTitle(containerForm)
                    }}</span>
                </div>
                <div class="col-12 px-0 mt-1 d-flex">
                  <div class="container-subtitle" [ngClass]="{
                    invalid: containerForm.touched && !isContainerFilled(containerForm)
                  }">
                    <span>{{ !containerForm.touched && !isContainerFilled(containerForm) ?
                      getContainersTitle(containerForm) :
                      getContainersSubTitle(containerForm) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto d-flex align-items-center ps-2 pe-0">
              <div class="action-icon-wrapper">
                <i class="s4d-icon s4d-chevron-right action-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-files mt-4" *ngIf="booking?.bookingAdditionalInformation?.isBatteries">
      <div class="row">
        <div class="col-12">
          <span class="container-files-title">Dangerous Goods Shipping</span>
        </div>
        <div class="col-12 mt-2">
          <span class="container-files-subtitle">Please upload the MSDS document to continue. <a
              href="https://ship4wd.com/support-category/dangerous-goods/" target="_blank"
              class="container-files-link">Learn More</a></span>
        </div>
        <div class="col-12">
          <lib-drag-drop [mode]="FileUploaderMode.all" [count]="7" [files]="documents" [canDownload]="false"
            (filesChange)="onDocumentsChange($event)" [maxFileSize]="5242880" [layoutType]="LayoutType.client"
            [isSecuredUrl]="true" (getSecureFileUrl)="getSecureFileUrl($event)" class="container-files-content"
            [title]="'Upload Document'">
          </lib-drag-drop>
        </div>
        <div class="col-12">
          <div class="s4d-alert-warning mt-3">
            <span class="alert-icon"><i class="s4d-icon s4d-info"></i></span>
            <span class="alert-text">All battery shipments are subject to carrier approval. <a
                href="https://ship4wd.com/support-category/dangerous-goods/" target="_blank">Learn
                More</a></span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="shipmentTypeCode !== shipmentTypes.FCL">
    <div class="row mx-0 mt-4">
      <div class="col px-0">
        <span class="section-title">Your loads</span>
      </div>
    </div>
    <div class="row m-0">
      <ng-container *ngFor="let containerForm of containers?.controls">
        <div class="col-12 px-0 mt-3"
          *ngFor="let commodityForm of containerForm.get('commodities')?.controls; let i = index">
          <div class="container-card" [ngClass]="{
              'container-card--highlighted': !commodityForm.touched && !isLoadFilled(commodityForm)
            }" (click)="onOpenCommodity(i + 1, commodityForm)">
            <div class="row m-0">
              <div class="col-auto d-flex align-items-center ps-0">
                <div class="container-icon-wrapper">
                  <i class="s4d-icon s4d-lcl-light container-icon"></i>
                  <div class="container-order-wrapper">
                    <span class="container-order">{{ i + 1 }}</span>
                  </div>
                </div>
              </div>
              <div class="col px-0">
                <div class="row m-0">
                  <div class="col-12 px-0 d-flex">
                    <span class="container-title">{{
                      !commodityForm.touched && !isLoadFilled(commodityForm) ?
                      getLoadsSubTitle(commodityForm) :
                      "Load " + (i + 1)
                      }}</span>
                  </div>
                  <div class="col-12 px-0 mt-1 d-flex">
                    <div class="container-subtitle" [ngClass]="{
                      invalid: commodityForm.touched && !isLoadFilled(commodityForm)
                    }">
                      <span>{{ !commodityForm.touched && !isLoadFilled(commodityForm) ?
                        "Load " + (i + 1) :
                        getLoadsSubTitle(commodityForm) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center ps-2 pe-0">
                <div class="action-icon-wrapper">
                  <i class="s4d-icon s4d-chevron-right action-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="row m-0 mt-4">
    <div class="col px-0">
      <button mat-raised-button class="confirm-button button-spinner" (click)="onNext()"
        [disabled]="isNextLoading || !isValid || (booking?.bookingAdditionalInformation?.isBatteries && documents.length === 0)">
        <span class="button-inner">
          Next
          <mat-spinner diameter="25" *ngIf="isNextLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>