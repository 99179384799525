import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentType } from '../shared.model';

@Pipe({ name: 'shipmentTypeCargoName' })
export class ShipmentTypeCargoNamePipe implements PipeTransform {
  private shipmentTypeNames = {};

  constructor() {
    this.shipmentTypeNames[ShipmentType.LCL] = 'LCL';
    this.shipmentTypeNames[ShipmentType.FCL] = 'FCL';
    this.shipmentTypeNames[ShipmentType.AIR] = 'Air Cargo';
  }

  transform(value?: ShipmentType): string {
    return this.shipmentTypeNames[value] ?? '';
  }
}
