import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { BookingPaidStatusCode, BookingStatusCodeEnum, SelectItem } from '../../../shared/shared.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: "app-dashboard-header",
  templateUrl: "./dashboard-header.component.html",
  styleUrls: ["./dashboard-header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DashboardHeaderComponent implements OnInit {
  isLoading = true;
  bookings: any;
  appliedFilters: any;
  isFilterApplied = false;
  isFilterDialogShow: boolean = false;
  isCanceled?: boolean = false;
  orIsCanceled?: boolean;
  filterForm: FormGroup;
  isShowSearch: boolean = true;

  @Input()
  title: string;
  @Input()
  isOrdersGridViewMode: boolean;
  @Input()
  isFinance: boolean = false;
  @Input() isCreditLine: boolean = false;
  @Input() isCreditLineNew: boolean = false;
  @Input() isCreditLineApprovedOrPreapproved: boolean = false;
  @Input() isCreditLineRejected: boolean = false;
  @Input() isCreditLineRequested: boolean = false;

  @Output() filters = new EventEmitter<any>();

  @Output() searchText = new EventEmitter<string>();
  @Output() clearFilters = new EventEmitter<boolean>();
  @Output() ordersGridViewModeChange = new EventEmitter<boolean>();

  orderStatuses: any[] = [
    {
      statusName: 'Open Orders',
      value: this.getStatusValues('Open Orders'),
      isChecked: false,
      isChild: false,
      allComplete: false,
      childStatuses: []
    },
    {
      statusName: 'Submitted Orders',
      value: this.getStatusValues('Submitted Orders'),
      isChecked: false,
      isChild: true,
      allComplete: false,
      childStatuses: [
        {
          statusName: 'Booked with Carrier',
          value: this.getStatusValues('Booked with Carrier'),
          isChecked: false
        },
        { statusName: 'On Transit', value: this.getStatusValues('On Transit'), isChecked: false },
        { statusName: 'Out to Delivery', value: this.getStatusValues('Out to Delivery'), isChecked: false }
      ]
    },
    {
      statusName: 'Complete Orders',
      value: this.getStatusValues('Complete Orders'),
      isChecked: false,
      isChild: true,
      allComplete: false,
      childStatuses: [
        { statusName: 'Cancelled', value: this.getStatusValues('Cancelled'), isChecked: false },
        { statusName: 'Invoiced', value: this.getStatusValues('Invoiced'), isChecked: false },
        { statusName: 'Delivered', value: this.getStatusValues('Delivered'), isChecked: false }
      ]
    }
  ];

  bookingPaidStatusCodes: SelectItem[] = [
    { name: 'Paid', value: BookingPaidStatusCode.paid },
    { name: 'Partialy paid', value: BookingPaidStatusCode.partialyPaid },
    { name: 'Unpaid', value: BookingPaidStatusCode.unPaid }
  ];

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @HostListener('window:scroll', ['$event'])
  onScroll({ event }: { event; }) {
    let header = document.getElementById("dashboard-header");
    let toolbar = document.getElementById("toolbar-header");
    let orderList = document.getElementById("order-list");
    if (window.pageYOffset > toolbar.offsetTop) {
      header.classList.add("sticky-dashboard-header");
      orderList.classList.add("sticky-order-list");
    } else {
      header.classList.remove("sticky-dashboard-header");
      orderList.classList.remove("sticky-order-list");
    }
  }

  get ordersViewModeIcon(): string {
    return this.isOrdersGridViewMode ? 'view_list' : 'grid_on';
  }

  get ordersViewModeTooltip(): string {
    return this.isOrdersGridViewMode ? 'Click here to switch to Card view mode' : 'Click here to switch to Grid view mode';
  }

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.matIconRegistry.addSvgIcon(
      "menu",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/dashboard/menu-icon.svg")
    );
  }

  ngOnInit() {
    this.createFilterForm();
  }

  openFilterDialog() {
    this.isFilterDialogShow = true;
  }

  onSearchText(searchEvent: any) {
    this.searchText.emit(searchEvent.target.value);
  }

  onMenuClick(event) {
    event.stopPropagation();
  }

  applyFilter() {
    this.isFilterApplied = false;
    const filters = this.filterForm.getRawValue();
    filters.orderStatuses = this.getOrderStatuses(filters);
    filters.isCanceled = this.isCanceled;

    if (this.isFinance || this.isCreditLine) {
      filters.hasFinancialDocuments = true;
    }

    this.appliedFilters = filters;

    Object.entries(this.appliedFilters).forEach(([key, value]) => {
      if (key !== "orderStatuses" && key !== "isCanceled") {
        if (this.appliedFilters[key] !== "" && this.appliedFilters[key] !== null) {
          this.isFilterApplied = true;
        }
      }
    });

    if (this.appliedFilters.orderStatuses.length > 0) {
      this.isFilterApplied = true;
    }

    if (this.appliedFilters.isCanceled === true) {
      this.isFilterApplied = true;
    }

    this.trigger.closeMenu();
    this.filters.emit(filters);
  }

  clearFilter() {
    if (this.isFilterApplied) {
      this.isFilterApplied = false;
      this.orderStatuses.map(e => {
        e.isChecked = false;
        if (e.isChild === true) {
          e.childStatuses.map(e => {
            e.isChecked = false;
          });
        }
      });
      this.filterForm.reset();
      this.appliedFilters = null;
      this.trigger.closeMenu();
      this.clearFilters.emit(true);
    }
  }

  updateAllComplete(orderStatus: any) {
    orderStatus.allComplete = orderStatus.childStatuses != null &&
      orderStatus.childStatuses.every((t) => t.isChecked);
    orderStatus.isChecked = orderStatus.allComplete;
  }

  someComplete(orderStatus: any): boolean {
    if (orderStatus?.childStatuses.length === 0) {
      return false;
    }

    return orderStatus.childStatuses.filter(t => t.isChecked).length > 0 && !orderStatus.allComplete;
  }

  setAll(completed: boolean, orderStatus: any) {
    orderStatus.allComplete = completed;
    if (orderStatus.childStatuses.length === 0) {
      return false;
    }
    orderStatus.childStatuses.forEach((t) => (t.isChecked = completed));
  }

  changeOrdersViewMode(): void {
    this.ordersGridViewModeChange.emit();
  }

  isShow(): boolean {
    if (this.isCreditLineNew === true || this.isCreditLineRejected === true
      || this.isCreditLineApprovedOrPreapproved === true || this.isCreditLineRequested) {
      return false
    }
    return true;
  }

  private getStatusValues(statusName: string) {
    const bookingStatusCodes: BookingStatusCodeEnum[] = [];
    if (statusName === 'Open Orders') {
      bookingStatusCodes.push(BookingStatusCodeEnum.DRFT);
    }
    else if (statusName === 'Submitted Orders') {
      bookingStatusCodes.push(BookingStatusCodeEnum.ORSB);
      bookingStatusCodes.push(BookingStatusCodeEnum.ORC);
    }
    else if (statusName === 'Booked with Carrier') {
      bookingStatusCodes.push(BookingStatusCodeEnum.BKD);
      bookingStatusCodes.push(BookingStatusCodeEnum.CRD);
      bookingStatusCodes.push(BookingStatusCodeEnum.DOC);
    }
    else if (statusName === 'On Transit') {
      bookingStatusCodes.push(BookingStatusCodeEnum.PKUP);
      bookingStatusCodes.push(BookingStatusCodeEnum.ONHD);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACO);
      bookingStatusCodes.push(BookingStatusCodeEnum.CDO);
      bookingStatusCodes.push(BookingStatusCodeEnum.ONB);
      bookingStatusCodes.push(BookingStatusCodeEnum.DEP);
      bookingStatusCodes.push(BookingStatusCodeEnum.HBI);
      bookingStatusCodes.push(BookingStatusCodeEnum.ANS);
      bookingStatusCodes.push(BookingStatusCodeEnum.AAS);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACD);
      bookingStatusCodes.push(BookingStatusCodeEnum.CDD);
      bookingStatusCodes.push(BookingStatusCodeEnum.TTR);
      bookingStatusCodes.push(BookingStatusCodeEnum.CTRA);
      bookingStatusCodes.push(BookingStatusCodeEnum.DEO);
      bookingStatusCodes.push(BookingStatusCodeEnum.TFD);
      bookingStatusCodes.push(BookingStatusCodeEnum.ACL);
      bookingStatusCodes.push(BookingStatusCodeEnum.CAU);
      bookingStatusCodes.push(BookingStatusCodeEnum.ARW);
      bookingStatusCodes.push(BookingStatusCodeEnum.AWB);
      bookingStatusCodes.push(BookingStatusCodeEnum.MAN);
      bookingStatusCodes.push(BookingStatusCodeEnum.UPA);
      bookingStatusCodes.push(BookingStatusCodeEnum.RCF);
      bookingStatusCodes.push(BookingStatusCodeEnum.LPA);
      bookingStatusCodes.push(BookingStatusCodeEnum.CCD);
    }
    else if (statusName === 'Out to Delivery') {
      bookingStatusCodes.push(BookingStatusCodeEnum.OFD);
    }
    else if (statusName === 'Cancelled') {
      this.isCanceled = true;
    }
    else if (statusName === 'Invoiced') {
      bookingStatusCodes.push(BookingStatusCodeEnum.INV);
    }
    else if (statusName === 'Delivered') {
      bookingStatusCodes.push(BookingStatusCodeEnum.DLV);
    }
    return bookingStatusCodes;
  }

  private getOrderStatuses(filters) {
    let orderStatusesArray: BookingStatusCodeEnum[] = [];

    this.orderStatuses.forEach(e => {
      if (e.isChecked) {
        orderStatusesArray = orderStatusesArray.concat(e.value);
      }

      if (e.isChild === true) {
        e.childStatuses.forEach(e => {
          if (e.isChecked === true) {
            orderStatusesArray = orderStatusesArray.concat(e.value);
            if (e.statusName === 'Cancelled') {
              this.isCanceled = true;
            }
          }
          else {
            if (e.statusName === 'Cancelled') {
              this.isCanceled = false;
            }
          }
        });
      }
    });

    if (orderStatusesArray.length > 0 && this.isCanceled === true) {
      filters.orIsCanceled = true;
      this.isCanceled = false;
    }
    else if (orderStatusesArray.length === 0) {
      this.isCanceled = this.isCanceled === true ? this.isCanceled : null;
    }
    return orderStatusesArray;
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      fromCity: [''],
      toCity: [''],
      bolOrderNumber: [''],
      masterBillOfLading: [''],
      fromLastUpdateDate: [null],
      orderStatuses: [],
      isCanceled: [null],
      orIsCanceled: [null],
      bookingPaidStatusCode: [null],
      atd: [null],
      duePaymentDate: [null],
    });
  }
}
