<div class="booking-flow-container-details" [formGroup]="commodityForm">
  <div class="container-details-header mb-4">
    <div class="row m-0 gap-2 g-0">
      <div class="col-auto d-flex align-items-center">
        <div class="container-order-wrapper">
          <span class="container-order">{{ index }}</span>
        </div>
      </div>
      <div class="col d-flex flex-column justify-content-center gap-1">
        <span class="container-title">Load {{ index }}</span>
        <span class="container-description">{{ getDimensions(commodityForm) }}</span>
      </div>
      <div *ngIf="isToIsrael" class="hts-guide-link-wrapper col-12 d-flex justify-content-end ms-auto px-0 mt-4">
        <a href="https://shaarolami-query.customs.mof.gov.il/CustomspilotWeb/he/CustomsBook/Import/CustomsTaarifEntry"
          target="_blank">
          HTS Commodity Guide
        </a>
      </div>
    </div>
  </div>
  <div class="container-details-content">
    <div class="row m-0 container-details pb-4 mb-4 g-0"
      [ngClass]="
      (commodityForm.get('commodityObject')?.touched && onCheckValid(commodityForm.get('commodityObject'))) ? 'error-box' : '' ">
      <div class="col-12 row mx-0 gap-3 g-0">
        <div class="col-12" [ngClass]="{
            'mb-4': commodityForm.get('commodityObject')?.touched &&
                  commodityForm.get('commodityObject')?.hasError('required')
          }">
          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
            <mat-label>What are you shipping?</mat-label>
            <div class="row h-100 commodity-input-container">
              <div class="col d-flex align-items-end">
                <mat-select #commodityselect class="commodity-select" panelClass="commodity-input-container-panel"
                  [ngClass]="{'mat-select-opened':commodityselect.panelOpen}" [compareWith]="compareObjects"
                  [formControl]="commodityForm.get('commodityObject')"
                  (focus)="onFocus('Commodity Code', index + 1, 'commodityObject')"
                  (selectionChange)="onSelectCommodity($event, commodityForm, index + 1)" disableOptionCentering
                  trackInputErrors (triggerError)="handleError('Commodity details', $event)"
                  controlName="Commodity details">
                  <mat-option class="commodity" *ngFor="let commodity of filteredCommodities" [value]="commodity"
                    title="{{commodity.description}}">
                    {{ commodity.description }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <mat-error *ngIf="
                  commodityForm.get('commodityObject')?.touched &&
                  commodityForm.get('commodityObject')?.hasError('required')
                " class="booking-flow-error">
              <i class="error-icon s4d-icon s4d-info-light"></i>
              Commodity details are required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="{
            'mb-4': commodityForm.get('numberOfPackages')?.touched &&
                  commodityForm.get('numberOfPackages')?.hasError('required')
          }">
          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
            <mat-label>Quantity</mat-label>
            <div class="row h-100">
              <div class="col d-flex align-items-end">
                <input matInput type="number" class="input-readonly" formControlName="numberOfPackages"
                  (focus)="onFocus('Quantity', index + 1, 'numberOfPackages')" placeholder="0" readonly
                  appPreventNonNumeric (change)="onValueChanged('Quantity', index + 1)" trackInputErrors
                  (triggerError)="handleError('Commodity details', $event)" />
              </div>
            </div>
            <mat-error *ngIf="
                  commodityForm.get('numberOfPackages')?.touched &&
                  commodityForm.get('numberOfPackages')?.hasError('required')
                ">
              <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="{
            'mb-4': commodityForm.get('packageTypeCode')?.touched &&
                  commodityForm.get('packageTypeCode')?.hasError('required')
          }">
          <mat-form-field class="w-100" appearance="fill" [floatLabel]="'auto'">
            <mat-label>Type of Packaging</mat-label>
            <div class="row h-100">
              <div class="col d-flex align-items-end">
                <package-type-input formControlName="packageTypeCode" placeholder="e.g. Box" class="w-100"
                  (onFocus)="onFocus('Type of Packaging', index + 1, 'packageTypeCode')" (onSelectionChanged)="
                      onSelectPackageType($event, commodityForm, index + 1)
                    " (triggerError)="handleError('Type of Packaging', $event)"></package-type-input>
              </div>
            </div>
            <mat-error *ngIf="
                  commodityForm.get('packageTypeCode')?.touched &&
                  commodityForm.get('packageTypeCode')?.hasError('required')
                ">
              <i class="s4d-icon s4d-info-light"></i> {{'required' | errorMessage: 'Type of Packaging'}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="container-details-footer">
    <div class="row m-0">
      <div class="col px-0">
        <button mat-raised-button class="confirm-button button-spinner" [disabled]="!isValid" (click)="onConfirm()">
          <span class="button-inner">
            Confirm
            <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>