<div class="dialog-container">
  <div class="row">
    <div class="col-12 text-center">
      <mat-dialog-content class="dialog-content">
        <div *ngIf="data.title" class="dialog-message-wrapper">
          <span class="dialog-title">{{ data.title }}</span>
        </div>
        <div class="dialog-message-wrapper">
          <span class="dialog-message">
            <ng-container *ngIf="!data.initialTemplate">
              {{ data.message }}
            </ng-container>
            <ng-container *ngIf="data.initialTemplate" [ngTemplateOutlet]="data.initialTemplate">
            </ng-container>
          </span>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  <div class="row text-center mt-2 justify-content-md-center dialog-footer">
    <button *ngIf="data.showCancelButton" mat-raised-button class="dialog-button cancel-button col-4"
      (click)="action(false)">
      {{ data.cancelButtonText }}
    </button>
    <div *ngIf="data.showCancelButton" class="col-1"></div>
    <button *ngIf="data.showConfirmButton" mat-raised-button class="dialog-button confirm-button col-4"
    (click)="action(true)">
      {{ data.confirmButtonText }}
    </button>
  </div>
</div>