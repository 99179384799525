<div *ngIf="!isManagedContact">
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="container manage-contacts-container pt-3" [hidden]="isLoading">
    <div class="row manage-contacts-container-title">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <h2>
              Manage Contact
              <input type="file" class="form-control" id="fileUpload" (change)="onFileChange($event)"
                style="display: none;" #fileInput
                title="You should add csv file to upload contacts. Csv file should has columns which are same with input fields in contact add page." />
              <button mat-icon-button color="primary" class="ml-2 mr-2 import-contacts-csv"
                matTooltip="Import contacts from CSV" (click)="openCsvDialog()">
                <mat-icon>note_add</mat-icon>
              </button>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row manage-contacts-container-subtitle">
      <div class="col-12">
        <p>Edit contact or create new</p>
      </div>
    </div>
    <div class="row booking-organization-contacts-wrapper" *ngIf="organizationContacts">
      <div class="col-12">
        <div class="booking-organization-contact-wrapper" *ngFor="let contact of organizationContacts">
          <div class="booking-organization-contact-left-content">
            <div class="organization-contact-profile-content">
              <span class="organization-contact-profile-name">{{ getInitialName(contact) }}</span>
              <span class="contact-country-flag-icon fi fi-{{contact.countryCode | lowercase}}"></span>
            </div>
            <div class="organization-contact-detail-wrapper">
              <div class="organization-contact-detail-information">
                <div class="organization-contact-name-wrapper" *ngIf="contact?.firstName && contact?.lastName">
                  <span class="organization-contact-name">
                    {{ contact.firstName + ' ' + contact.lastName }}
                  </span>
                </div>
                <div class="organization-contact-rating-wrapper">
                  <span class="organization-contact-rating-icon"></span>
                  <span class="organization-contact-rating-text">5.0</span>
                </div>
              </div>
              <div class="organization-contact-company-information" *ngIf="contact?.companyName">
                <span class="organization-contact-company-name">{{ contact.companyName }}</span>
              </div>
            </div>
          </div>
          <div class="booking-organization-contact-right-content">
            <div class="booking-contact-menu-wrapper">
              <button mat-icon-button class="booking-contact-menu-button s4d-icon s4d-menu" [matMenuTriggerFor]="menu">
              </button>
              <mat-menu #menu="matMenu" class="manage-contact-menu">
                <button mat-menu-item (click)="onUpdateContact(contact)">
                  <span>Update</span>
                </button>
                <button mat-menu-item (click)="onDeleteContact(contact)">
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="booking-organization-contacts-bottom">
      <button mat-raised-button class="next-button" (click)="addContact()">Add Contact</button>
    </div>
  </div>
</div>

<div *ngIf="isManagedContact">
  <app-manage-contact [isNewContact]="isNewContact" [organizationContact]="selectedContact"
    [organizationId]="organizationId"></app-manage-contact>
</div>