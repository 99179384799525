import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentsService, EnvironmentsServiceConfig } from '@ship4wd/ngx-common';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppSettingsService } from '../services/app-settings/app-settings.service';
import {
  IncreaseUnlocodeSearchCountViewModel,
  LocationType,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchBaseViewModel,
  TripsSearchFromViewModel,
  TripsSearchViewModelRequest,
  TripsSearchViewModelResponse
} from '../shared.model';
import { TripUnLocodeAutocompletePostcodeDialogComponent }
  from './trip-un-locode-autocomplete-postcode-dialog/trip-un-locode-autocomplete-postcode-dialog.component';
import { TripUnLocodeAutocompleteService } from './trip-un-locode-autocomplete.service';
import { SupportedCountry } from '../supported-countries/supported-countries.model';

@Component({
  selector: 'app-trip-un-locode-autocomplete',
  templateUrl: './trip-un-locode-autocomplete.component.html',
  styleUrls: ['./trip-un-locode-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TripUnLocodeAutocompleteComponent implements OnInit {
  @Input() reuse: false;
  @Input() pageSize: 10;
  @Input() minimumLength: 3;
  @Input() shipmentType = ShipmentType.LCL;
  @Input() isMobile: boolean = false;
  isFromPostcodeCbSelected: boolean;
  isToPostcodeCbSelected: boolean;
  lclShipmentType = ShipmentType.LCL;

  @Input() fromControl: FormControl = undefined;
  @Input() fromLocationTypeControl: FormControl = undefined;
  fromUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedFromUnLocode: TripsSearchFromViewModel = null;
  isFromUnLocodeSelected = false;
  isFromLoading = false;

  @Input() toControl: FormControl = undefined;
  @Input() toLocationTypeControl: FormControl = undefined;
  toUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedToUnLocode: TripsSearchFromViewModel = null;
  isToUnLocodeSelected = false;
  isToLoading = false;

  @Input() citiesToControl: FormControl = undefined;
  @Input() citiesToLocationTypeControl: FormControl = undefined;
  citiesToUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedCitiesToUnLocode: TripsSearchFromViewModel = null;
  isCitiesToUnLocodeSelected = false;
  isCitiesToLoading = false;
  shortestToZipcode = this.selectedCitiesToUnLocode != null ? this.selectedCitiesToUnLocode.zipCodeShortest : 5;
  citiesPageSize = 6;

  @Input() citiesFromControl: FormControl = undefined;
  @Input() citiesFromLocationTypeControl: FormControl = undefined;
  citiesFromUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedCitiesFromUnLocode: TripsSearchFromViewModel = null;
  isCitiesFromUnLocodeSelected = false;
  isCitiesFromLoading = false;
  shortestFromZipcode = this.selectedCitiesFromUnLocode != null ? this.selectedCitiesFromUnLocode.zipCodeShortest : 5;

  @Input() postCodesToControl: FormControl = undefined;
  postCodesToUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedPostcodesToUnLocode: TripsSearchFromViewModel = null;
  isPostCodesToUnLocodeSelected = false;
  isPostCodesToLoading = false;

  @Input() postCodesFromControl: FormControl = undefined;
  postCodesFromUnLocodeResponse: TripsSearchViewModelResponse = null;
  selectedPostcodesFromUnLocode: TripsSearchFromViewModel = null;
  isPostCodesFromUnLocodeSelected = false;
  isPostCodesFromLoading = false;

  @Input() supportedCountries: SupportedCountry[];

  searchType = SearchType;
  isPendingResult = true;

  fromReuse: boolean;
  toReuse: boolean;

  isLocationAutocompleteSearchEnable: boolean;

  @Output() toShipperType = new EventEmitter<ShipperType>();

  @Output() clearFromAndToLabels = new EventEmitter<SearchType>();

  @Output() setFromByPostcodeUnLocode = new EventEmitter<any>();
  @Output() setToByPostcodeUnLocode = new EventEmitter<any>();

  @Output() setSearchId = new EventEmitter<string>();
  @Input() searchId = '';

  constructor(
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private settingsService: AppSettingsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initializeFromAndToUnLocodes();
    this.isLocationAutocompleteSearchEnable = TripUnLocodeAutocompleteComponent.getIsLocationAutocompleteSearch();
  }

  onSelectUnLocode(tripsSearchFromViewModel: TripsSearchFromViewModel, searchType: SearchType, autoform: any, textInput: any) {
    var increaseUnlocodeSearchCountViewModel = {
      unLocode: tripsSearchFromViewModel.unLocode,
      searchType: searchType,
      shipmentType: this.shipmentType
    } as IncreaseUnlocodeSearchCountViewModel;

    this.tripUnLocodeAutocompleteService.increaseUnlocodeSearchCount(increaseUnlocodeSearchCountViewModel).subscribe();

    if (searchType === SearchType.from) {
      this.isFromUnLocodeSelected = true;
    }
    if (searchType === SearchType.to) {
      this.isToUnLocodeSelected = true;
    }
    if (this.isMustVerifyPostCode(tripsSearchFromViewModel)) {
      this.openPostCodeDialog(tripsSearchFromViewModel, searchType, autoform, textInput);
    }
    else {
      this.selectUnLocode(tripsSearchFromViewModel, searchType);
      textInput.blur();
    }
  }

  onSelectedCitiesUnlocodeByType(tripsSearchFromViewModel: TripsSearchFromViewModel, searchType: SearchType) {
    if (searchType === SearchType.from) {
      this.clearPostcodeFromUnLocodes(false);
      this.isCitiesFromUnLocodeSelected = true;
      this.selectedCitiesFromUnLocode = tripsSearchFromViewModel;
      this.selectedCitiesFromUnLocode.searchId = this.citiesFromUnLocodeResponse?.searchId;
      this.postCodesFromControl.enable();
    }
    if (searchType === SearchType.to) {
      this.clearPostcodeToUnLocodes(false);
      this.isCitiesToUnLocodeSelected = true;
      this.selectedCitiesToUnLocode = tripsSearchFromViewModel;
      this.selectedCitiesToUnLocode.searchId = this.citiesToUnLocodeResponse?.searchId;
      this.postCodesToControl.enable();
    }
  }

  onSelectPostcodeUnLocode(tripsSearchFromViewModel: TripsSearchFromViewModel, searchType: SearchType) {
    if (searchType === SearchType.from) {
      this.isPostCodesFromUnLocodeSelected = true;
      this.selectedPostcodesFromUnLocode = tripsSearchFromViewModel;
      this.selectedPostcodesFromUnLocode.searchId = this.postCodesFromUnLocodeResponse?.searchId;
      this.toControl.enable();
      this.citiesToControl.enable();
      this.clearToUnlocode(false);
      this.clearCitiesToUnLocodes(false);
      this.setFromByPostcodeUnLocode.emit({
        isFromPostcodeCbSelected: this.isFromPostcodeCbSelected,
        selectedPostcodesFromUnLocode: this.selectedPostcodesFromUnLocode
      });
    }
    if (searchType === SearchType.to) {
      this.isPostCodesToUnLocodeSelected = true;
      this.selectedPostcodesToUnLocode = tripsSearchFromViewModel;
      this.selectedPostcodesToUnLocode.searchId = this.postCodesToUnLocodeResponse?.searchId;
      this.toShipperType.emit(this.postCodesToUnLocodeResponse.shipperType);
      this.setToByPostcodeUnLocode.emit({
        isToPostcodeCbSelected: this.isToPostcodeCbSelected,
        selectedPostcodesToUnLocode: this.selectedPostcodesToUnLocode
      });
    }
  }

  displayFnUnLocode(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.unLocode ? tripsSearchBaseViewModel.displayName : null;
  }

  displayFnCityName(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.displayName ? tripsSearchBaseViewModel.displayName : null;
  }

  displayFnZipCode(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.zipCode ? tripsSearchBaseViewModel.zipCode : null;
  }

  onSearchByPostcodeChange(event: MatCheckboxChange, searchType: SearchType) {
    if (event.checked) {
      searchType === SearchType.from ? this.isFromPostcodeCbSelected = true : this.isToPostcodeCbSelected = true;
    }
    else {
      searchType === SearchType.from ? this.isFromPostcodeCbSelected = false : this.isToPostcodeCbSelected = false;
    }
    searchType === SearchType.from ? this.clearFromAndToLabels.emit(SearchType.from) : this.clearFromAndToLabels.emit(SearchType.to);
  }

  checkReuse(trip: string) {
    if (this.reuse) {
      if (trip === 'from' && this.fromReuse) {
        this.fromReuse = false;
        this.loadFromUnLocodes();
        this.loadCitiesFromUnLocodes();
        this.loadPostcodeFromUnLocodes();
      }

      if (trip === 'to' && this.toReuse) {
        this.toReuse = false;
        this.loadToUnLocodes();
        this.loadCitiesToUnLocodes();
        this.loadPostcodeToUnLocodes();
      }
    }
  }

  onChange(value: string): void {
    if (this.fromControl.touched) { this.fromControl.markAsUntouched(); }
    if (this.toControl.touched) { this.toControl.markAsUntouched(); }
    if (value === 'from') {
      if (this.fromControl.value && this.fromControl.touched !== true) {
        this.fromControl.setValue('');
        this.fromControl.markAsTouched();
      }
    }

    if (value === 'to') {
      if (this.toControl.value && this.toControl.touched !== true) {
        this.toControl.setValue('');
        this.toControl.markAsTouched();
      }
    }
  }

  private initializeFromAndToControls() {
    this.fromControl = (this.fromControl === undefined ? new FormControl() : this.fromControl);
    this.citiesFromControl = (this.citiesFromControl === undefined ? new FormControl() : this.citiesFromControl);
    this.postCodesFromControl = (this.postCodesFromControl === undefined
      ? new FormControl({ value: '', disabled: true }) : this.postCodesFromControl);
    this.toControl = (this.toControl === undefined
      ? new FormControl({ value: '', disabled: true }) : this.toControl);
    this.citiesToControl = (this.citiesToControl === undefined
      ? new FormControl({ value: '', disabled: true }) : this.citiesToControl);
    this.postCodesToControl = (this.postCodesToControl === undefined
      ? new FormControl({ value: '', disabled: true }) : this.postCodesToControl);
  }

  private initializeFromAndToUnLocodes() {
    this.initializeFromAndToControls();

    if (!this.reuse && !this.fromControl.value) {
      this.loadFromUnLocodes();
      this.loadToUnLocodes();
      this.loadCitiesFromUnLocodes();
      this.loadCitiesToUnLocodes();
      this.loadPostcodeToUnLocodes();
      this.loadPostcodeFromUnLocodes();
    }
    else {
      this.fromReuse = true;
      this.toReuse = true;
      this.isFromUnLocodeSelected = true;
      this.isToUnLocodeSelected = true;
      this.selectedFromUnLocode = this.fromControl.value;
      this.selectedToUnLocode = this.toControl.value;
      this.fromUnLocodeResponse = new TripsSearchViewModelResponse();
      this.fromUnLocodeResponse.tripsSearchList = [];
      this.fromUnLocodeResponse.tripsSearchList.push(this.selectedFromUnLocode);
      this.toUnLocodeResponse = new TripsSearchViewModelResponse();
      this.toUnLocodeResponse.tripsSearchList = [];
      this.toUnLocodeResponse.tripsSearchList.push(this.selectedToUnLocode);
    }
  }

  private loadFromUnLocodes() {
    this.fromControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkFromOrToBeforeSearch(val, SearchType.from);
          return (isSearch === true ? this.search(val || '', null, SearchType.from, false, false) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        if (this.isFromUnLocodeSelected) {
          this.isFromUnLocodeSelected = false;
        } else {
          this.fromUnLocodeResponse = x;
          this.clearToUnlocode(true);
          this.clearCitiesToUnLocodes(true);
          this.clearPostcodeToUnLocodes(true);
          this.clearPostcodeFromUnLocodes(true);
        }
        this.setPendingResultFlag();
        this.setLoader(SearchType.from, false, false, false);
      });
  }

  private loadCitiesFromUnLocodes() {
    this.citiesFromControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkFromOrToBeforeSearch(val, SearchType.from);
          return (isSearch === true ? this.searchCity(val || '', SearchType.from) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        if (this.isCitiesFromUnLocodeSelected) {
          this.isCitiesFromUnLocodeSelected = false;
        } else {
          this.citiesFromUnLocodeResponse = x;
          if (this.citiesFromControl.touched) {
            this.clearToUnlocode(true);
            this.clearCitiesToUnLocodes(true);
            this.clearPostcodeToUnLocodes(true);
          }
        }
        this.setPendingResultFlag();
        this.setLoader(SearchType.from, false, true, false);
      });
  }

  private loadPostcodeFromUnLocodes() {
    this.postCodesFromControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkPostcodesBeforeSearch(val, SearchType.from);
          if (this.isLocationAutocompleteSearchEnable === true && isSearch === true) {
            const countryCode = this.citiesFromControl?.value?.country;
            if (countryCode != null) {
              this.setLoader(SearchType.from, true, false, true);
              return this.verifyPostcode(countryCode, val);
            } else {
              return of(val);
            }
          }
          else {
            return (isSearch === true ?
              this.search(this.selectedCitiesFromUnLocode.unLocode, val || '', SearchType.from, this.isFromPostcodeCbSelected, true)
              : of(isSearch));
          }
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse | string) => {
        if (this.isPostCodesFromUnLocodeSelected) {
          this.isPostCodesFromUnLocodeSelected = false;
        } else {
          if (typeof (x) === "string" && x.trim() !== "") {
            this.selectedCitiesFromUnLocode.zipCode = x;
            this.selectedPostcodesFromUnLocode = this.selectedCitiesFromUnLocode;
            this.selectedPostcodesFromUnLocode.searchId = this.citiesFromUnLocodeResponse?.searchId;
            this.toControl.enable();
            this.citiesToControl.enable();
            this.clearToUnlocode(false);
            this.clearCitiesToUnLocodes(false);
            this.setFromByPostcodeUnLocode.emit({
              isFromPostcodeCbSelected: this.isFromPostcodeCbSelected,
              selectedPostcodesFromUnLocode: this.selectedCitiesFromUnLocode
            });
          }
          else {
            this.postCodesFromUnLocodeResponse = x as TripsSearchViewModelResponse;
            if (this.postCodesFromControl.touched) {
              this.clearCitiesToUnLocodes(true);
              this.clearPostcodeToUnLocodes(true);
              this.clearToUnlocode(true);
            }
            this.postCodesFromControl.setErrors({ 'incorrect': true });
          }
        }
        this.setPendingResultFlag();
        this.setLoader(SearchType.from, false, false, true);
      });
  }

  private loadToUnLocodes() {
    this.toControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkFromOrToBeforeSearch(val, SearchType.to);
          return (isSearch === true ? this.search(val || '', null, SearchType.to, this.isToPostcodeCbSelected, false) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        this.toUnLocodeResponse = x;
        this.setPendingResultFlag();
        this.setLoader(SearchType.to, false, false, false);
      });
  }

  private loadPostcodeToUnLocodes() {
    this.postCodesToControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkPostcodesBeforeSearch(val, SearchType.to);

          if (this.isLocationAutocompleteSearchEnable === true && isSearch === true) {
            const countryCode = this.citiesToControl?.value?.country;
            if (countryCode != null) {
              this.setLoader(SearchType.to, true, false, true);
              return this.verifyPostcode(countryCode, val);
            } else {
              return of(val);
            }
          }
          else {
            return (isSearch === true ? this.search(this.selectedCitiesToUnLocode.unLocode, val || '', SearchType.to, this.isToPostcodeCbSelected, true)
              : of(isSearch));
          }
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse | string) => {
        if (x != null) {
          if (typeof (x) === "string" && x.trim() !== "") {
            this.selectedCitiesToUnLocode.zipCode = x;
            this.selectedPostcodesToUnLocode = this.selectedCitiesToUnLocode;
            this.selectedPostcodesToUnLocode.searchId = this.citiesToUnLocodeResponse?.searchId;
            this.toShipperType.emit(this.citiesToUnLocodeResponse.shipperType);
            this.setToByPostcodeUnLocode.emit({
              isToPostcodeCbSelected: this.isToPostcodeCbSelected,
              selectedPostcodesToUnLocode: this.selectedCitiesToUnLocode
            });
          }
          else {
            this.postCodesToUnLocodeResponse = x as TripsSearchViewModelResponse;
          }
        }
        else {
          this.setToByPostcodeUnLocode.emit({
            isToPostcodeCbSelected: this.isToPostcodeCbSelected,
            selectedPostcodesToUnLocode: null
          });
          this.postCodesToControl.setErrors({ 'incorrect': true });
        }
        this.setPendingResultFlag();
        this.setLoader(SearchType.to, false, false, true);
      });
  }

  private loadCitiesToUnLocodes() {
    this.citiesToControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        switchMap(val => {
          this.isPendingResult = true;
          const isSearch = this.checkFromOrToBeforeSearch(val, SearchType.to);
          return (isSearch === true ? this.searchCity(val || '', SearchType.to) : of(isSearch));
        }),
        startWith('')
      ).subscribe((x: TripsSearchViewModelResponse) => {
        if (this.isCitiesToUnLocodeSelected) {
          this.isCitiesToUnLocodeSelected = false;
        } else {
          this.citiesToUnLocodeResponse = x;
        }
        this.setPendingResultFlag();
        this.setLoader(SearchType.to, false, true, false);
      });
  }

  private search(searchValue: string, postcodeSearchValue: string, searchType: SearchType, isPostcodeSearch: boolean, isAutoCompleteSearch: boolean): Observable<TripsSearchViewModelResponse> {
    let selectedUnlocode = null;
    if (searchType == SearchType.to) {
      selectedUnlocode = this.selectedFromUnLocode;
      if (this.isFromPostcodeCbSelected) {
        selectedUnlocode = this.selectedPostcodesFromUnLocode;
      }
    }

    this.setLoader(searchType, true, false, isPostcodeSearch);

    if (this.isLocationAutocompleteSearchEnable === true) {
      return this.tripUnLocodeAutocompleteService.getLocationsUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          searchType,
          this.pageSize,
          this.shipmentType,
          searchType == SearchType.to ? this.isToPostcodeCbSelected : this.isFromPostcodeCbSelected,
          selectedUnlocode))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);
            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getTripUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          searchType,
          this.pageSize,
          this.shipmentType,
          searchType == SearchType.to ? this.isToPostcodeCbSelected : this.isFromPostcodeCbSelected,
          selectedUnlocode))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);
            return response;
          }));
    }
  }

  private searchCity(searchValue: string, type: SearchType): Observable<TripsSearchViewModelResponse> {
    let selectedCities = null;
    if (type == SearchType.to) {
      selectedCities = this.selectedFromUnLocode;

      if (this.isFromPostcodeCbSelected) {
        selectedCities = this.selectedPostcodesFromUnLocode;
      }
    }

    const isPostcodesFromTo = type == SearchType.from ? this.isFromPostcodeCbSelected : this.isToPostcodeCbSelected;
    this.setLoader(type, true, true, false);

    if (this.isLocationAutocompleteSearchEnable === true) {
      return this.tripUnLocodeAutocompleteService.getLocationsUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          '',
          type,
          this.pageSize,
          this.shipmentType,
          isPostcodesFromTo,
          selectedCities))
        .pipe(
          map(response => {
            let checkSearchId = this.searchId != '' && this.searchId != null ? this.searchId : response.searchId;
            this.setSearchId.emit(checkSearchId);
            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getCitiesUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          '',
          type,
          this.citiesPageSize,
          this.shipmentType,
          isPostcodesFromTo,
          selectedCities))
        .pipe(
          map(response => response));
    }
  }

  private prepareUnLocodeRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel
  ): TripsSearchViewModelRequest {
    return {
      type: searchType,
      pageSize,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private setLoader(searchType: SearchType, value: boolean, isCitySearch: boolean, isPostodesSearch: boolean) {
    if (isPostodesSearch) {
      switch (searchType) {
        case SearchType.from: {
          this.isPostCodesFromLoading = value
          break;
        }
        case SearchType.to: {
          this.isPostCodesToLoading = value;
          break;
        }
      }
      return;
    }

    if (isCitySearch) {
      switch (searchType) {
        case SearchType.from: {
          this.isCitiesFromLoading = value
          break;
        }
        case SearchType.to: {
          this.isCitiesToLoading = value;
          break;
        }
      }
      return;
    }
    switch (searchType) {
      case SearchType.from: {
        this.isFromLoading = value;
        break;
      }
      case SearchType.to: {
        this.isToLoading = value;
        break;
      }
    }
  }

  private clearToUnlocode(includeDisable: boolean) {
    this.toUnLocodeResponse = null;
    this.toControl.patchValue('');
    if (includeDisable) {
      this.toControl.disable();
    }
    this.toShipperType.emit(null);
  }

  private clearCitiesToUnLocodes(includeDisable: boolean) {
    this.citiesToUnLocodeResponse = null;
    this.citiesToControl.patchValue('');
    if (includeDisable) {
      this.citiesToControl.disable();
    }
  }

  private clearPostcodeFromUnLocodes(includeDisable: boolean) {
    this.postCodesFromUnLocodeResponse = null;
    this.postCodesFromControl.patchValue('');
    if (includeDisable) {
      this.postCodesFromControl.disable();
    }
  }

  private clearPostcodeToUnLocodes(includeDisable: boolean) {
    this.postCodesToUnLocodeResponse = null;
    this.postCodesToControl.patchValue('');
    if (includeDisable) {
      this.postCodesToControl.disable();
    }
    this.toShipperType.emit(null);
  }

  private checkMinimumLength(value: string, minimumLength: number) {
    return !(value.length >= minimumLength);
  }

  private checkFromOrToBeforeSearch(value: string, searchType: SearchType) {
    if (searchType === SearchType.from) {
      if (this.isFromUnLocodeSelected) {
        return this.fromUnLocodeResponse;
      }
      if (this.selectedFromUnLocode) {
        this.selectedFromUnLocode = null;
      }
      if (typeof value == 'string' && this.checkMinimumLength(value, this.minimumLength)) {
        this.clearToUnlocode(true);
        this.clearCitiesToUnLocodes(true);
        this.clearPostcodeToUnLocodes(false);
        this.clearPostcodeFromUnLocodes(false);

        return this.fromUnLocodeResponse = null;
      }
      if (this.isCitiesFromUnLocodeSelected) {
        this.isCitiesFromUnLocodeSelected = false;
        return this.citiesFromUnLocodeResponse;
      }
      if (this.selectedCitiesFromUnLocode) {
        this.selectedCitiesFromUnLocode = null;
      }

      if (this.checkMinimumLength(value, this.minimumLength)) {
        this.clearToUnlocode(true);
        this.clearCitiesToUnLocodes(true);
        this.clearPostcodeToUnLocodes(false);
        this.clearPostcodeFromUnLocodes(false);
        return this.citiesFromUnLocodeResponse = null;
      }
      return true;
    }
    if (searchType === SearchType.to) {
      if (this.isToUnLocodeSelected) {
        this.isToUnLocodeSelected = false;
        return this.toUnLocodeResponse;
      }
      if (this.selectedToUnLocode) {
        this.selectedToUnLocode = null;
      }

      if (typeof value == 'string' && this.checkMinimumLength(value, this.minimumLength)) {
        this.clearPostcodeToUnLocodes(false);
        this.toShipperType.emit(null);
        return this.toUnLocodeResponse = null;
      }
      if (this.isCitiesToUnLocodeSelected) {
        this.isCitiesToUnLocodeSelected = false;
        return this.citiesToUnLocodeResponse;
      }
      if (this.selectedCitiesToUnLocode) {
        this.selectedCitiesToUnLocode = null;
      }

      if (this.checkMinimumLength(value, this.minimumLength)) {
        this.clearPostcodeToUnLocodes(false);
        return this.citiesToUnLocodeResponse = null;
      }
      return true;
    }
  }

  private checkPostcodesBeforeSearch(value: string, searchType: SearchType) {
    switch (searchType) {
      case SearchType.from: {
        if (this.isPostCodesFromUnLocodeSelected) {
          return this.postCodesFromUnLocodeResponse;
        }
        if (this.selectedPostcodesFromUnLocode) {
          this.selectedPostcodesFromUnLocode = null;
        }
        if (this.checkMinimumLength(value, this.shortestFromZipcode)) {
          this.clearCitiesToUnLocodes(true);
          this.clearPostcodeToUnLocodes(false);
          this.clearToUnlocode(true);
          return this.postCodesFromUnLocodeResponse = null;
        }
        if (this.isPostCodesFromUnLocodeSelected) {
          this.isPostCodesFromUnLocodeSelected = false;
          return this.postCodesFromUnLocodeResponse;
        }
        if (this.selectedPostcodesFromUnLocode) {
          this.selectedPostcodesFromUnLocode = null;
        }

        if (this.checkMinimumLength(value, this.shortestFromZipcode)) {
          this.clearCitiesToUnLocodes(true);
          this.clearPostcodeToUnLocodes(false);
          this.clearToUnlocode(true);
          return this.postCodesFromUnLocodeResponse = null;
        }
        return true;
      }
      case SearchType.to: {
        if (this.isPostCodesToUnLocodeSelected) {
          this.isPostCodesToUnLocodeSelected = false;
          return this.postCodesToUnLocodeResponse;
        }
        if (this.selectedPostcodesToUnLocode) {
          this.selectedPostcodesToUnLocode = null;
        }
        if (this.checkMinimumLength(value, this.shortestToZipcode)) {
          this.toShipperType.emit(null);
          return this.postCodesToUnLocodeResponse = null;
        }
        if (this.isPostCodesToUnLocodeSelected) {
          this.isPostCodesToUnLocodeSelected = false;
          return this.postCodesToUnLocodeResponse;
        }
        if (this.selectedPostcodesToUnLocode) {
          this.selectedPostcodesToUnLocode = null;
        }

        if (this.checkMinimumLength(value, this.shortestToZipcode)) {
          this.toShipperType.emit(null);
          return this.postCodesToUnLocodeResponse = null;
        }
        return true;
      }
    }
  }

  private setPendingResultFlag() {
    if (this.isFromLoading ||
      this.isToLoading ||
      this.isCitiesToLoading ||
      this.isCitiesFromLoading ||
      this.isPostCodesFromLoading ||
      this.isPostCodesToLoading) {
      this.isPendingResult = false;
    }
  }

  private openPostCodeDialog(tripsSearchFromViewModel: TripsSearchFromViewModel, searchType: SearchType,
    autoform: any, textInput: any) {
    const dialogRef = this.dialog.open(TripUnLocodeAutocompletePostcodeDialogComponent, {
      data: {
        countryCode: tripsSearchFromViewModel.country,
        postCode: null
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      autoform.closePanel()
      if (result && result.postCode) {
        tripsSearchFromViewModel.zipCode = this.formatUnLocode(tripsSearchFromViewModel.country, result.postCode);
        this.selectUnLocode(tripsSearchFromViewModel, searchType);

        setTimeout(() => {
          textInput.blur();
        }, 0);
      }
      else {
        if (searchType === SearchType.from) {
          this.fromControl.patchValue('');
          this.selectedFromUnLocode = null;
        }
        if (searchType === SearchType.to) {
          this.toControl.patchValue('');
          this.selectedToUnLocode = null;
        }
      }
    });
  }

  private selectUnLocode(tripsSearchFromViewModel: TripsSearchFromViewModel, searchType: SearchType) {
    if (searchType === SearchType.from) {
      this.selectedFromUnLocode = tripsSearchFromViewModel;
      this.selectedFromUnLocode.searchId = this.fromUnLocodeResponse?.searchId;
      this.toControl.enable();
      this.citiesToControl.enable();
      this.clearToUnlocode(false);
      this.clearCitiesToUnLocodes(false);
    }
    if (searchType === SearchType.to) {
      this.selectedToUnLocode = tripsSearchFromViewModel;
      this.selectedToUnLocode.searchId = this.toUnLocodeResponse?.searchId;
      this.toShipperType.emit(this.toUnLocodeResponse.shipperType);
    }
  }

  private formatUnLocode(country: string, unLocode: string) {
    if (country == 'CA') {
      unLocode = unLocode.toUpperCase();
      if (unLocode.charAt(3) !== ' ') {
        unLocode = unLocode.substring(0, 3) + " " + unLocode.substring(3, unLocode.length);
      }
    }

    return unLocode;
  }

  private verifyPostcode(countryCode: string, postcode: string): Observable<string> {
    return this.tripUnLocodeAutocompleteService.verifyPostcode(countryCode, postcode)
      .pipe(
        map(response => response.isValid ? response.postcode : null));
  }

  static getIsLocationAutocompleteSearch(): boolean {
    const environmentsService = new EnvironmentsService({
      companySubdomain: "ship4wd",
    } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(
      window.location.hostname
    );
    switch (environmentName) {
      case "qa":
        return environment.qa.isLocationAutocompleteSearch;
      case "sb":
        return environment.sb.isLocationAutocompleteSearch;
      default:
        return environment.isLocationAutocompleteSearch;
    }
  }

  private isMustVerifyPostCode(tripsSearchFromViewModel: TripsSearchFromViewModel): boolean {
    if (tripsSearchFromViewModel.locationType === LocationType.townCity) {
      if (this.shipmentType === ShipmentType.LCL || this.shipmentType === ShipmentType.AIR) {
        return true;
      }
      else {
        return this.isCountryTruckEnable(tripsSearchFromViewModel.country);
      }
    }

    return false;
  }

  private isCountryTruckEnable(countryCode: string): boolean {
    const found = this.supportedCountries.find(x => x.countryCode == countryCode && x.isTruckEnabled);
    return found != undefined;
  }
}
