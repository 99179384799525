<div *ngIf="quote.trip" class="trip-expanded-details">
  <div class="expanded-detail">
    <div class="row mx-0 mb-3">
      <div class="col-12 px-0 d-flex align-items-center justify-content-start">
        <span class="expanded-detail-title">Base Services</span>
      </div>
    </div>
    <div class="row mx-0 mb-3">
      <div class="col-8 px-0 d-flex align-items-center justify-content-start">
        <span class="detail-title">International Freight</span>
      </div>
      <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
        <div appSetRoleBasedPrice class="detail-result">
          {{ quote.trip.currency | currencyTextIcon
          }}{{ mainCarriageTotal | number : "1.2-2" }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="preCarriageTotal">
      <div class="row mx-0 mb-3">
        <div class="col-8 px-0 d-flex align-items-center justify-content-start">
          <span class="detail-title text-capitalize">{{ quote?.trip?.fromLocationTypeCode | locationTypeShortName }} Pickup</span>
        </div>
        <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
          <div appSetRoleBasedPrice class="detail-result">
            {{ quote.trip.currency | currencyTextIcon
            }}{{ preCarriageTotal | number : "1.2-2" }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="postCarriageTotal">
      <div class="row mx-0 mb-3">
        <div class="col-8 px-0 d-flex align-items-center justify-content-start">
          <span class="detail-title text-capitalize">{{ quote?.trip?.toLocationTypeCode | locationTypeShortName }} Delivery</span>
        </div>
        <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
          <div appSetRoleBasedPrice class="detail-result">
            {{ quote.trip.currency | currencyTextIcon
            }}{{ postCarriageTotal | number : "1.2-2" }}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row m-0">
      <div class="col px-0 d-flex align-items-center justify-content-end">
        <div button class="detail-button" (click)="onShowDetails()">
          Show details
        </div>
      </div>
    </div>
  </div>

  <mat-divider class="mx-3 my-2 trip-divider"></mat-divider>

  <div class="expanded-detail">
    <div class="row mx-0 mb-3">
      <div class="col-12 px-0 d-flex align-items-center justify-content-start">
        <span class="expanded-detail-title">Cargo Details</span>
      </div>
    </div>
    <ng-container *ngIf="shipmentType === ShipmentType.FCL">
      <div *ngFor="let equipment of quotesQuery?.equipments" class="row mx-0 detail-row">
        <div class="col-8 px-0 d-flex align-items-center justify-content-start">
          <span class="detail-title">{{
            equipment.equipmentTypeDescription
            }}</span>
        </div>
        <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
          <div class="detail-result">x{{ equipment.quantity }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="shipmentType !== ShipmentType.FCL && commodities">
      <div class="row mx-0 mb-3">
        <div class="col-8 px-0 d-flex align-items-center justify-content-start">
          <span class="detail-title">Chargeable Weight (Total Weight)</span>
        </div>
        <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
          <div class="detail-result">
            {{ grandTotalChargeableWeight | number : "1.2-2" }}
            {{ grandTotalWeightUnit | weightUnitName | uppercase }}
            <span class="additional" matTooltip="Total Weight">({{ grandTotalWeightAmount | number : "1.2-2" }}
              {{ grandTotalWeightUnit | weightUnitName | uppercase }})</span>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-8 px-0 d-flex align-items-center justify-content-start">
          <span class="detail-title">Total Volume</span>
        </div>
        <div class="col-4 ps-2 pe-0 d-flex align-items-center justify-content-end">
          <div class="detail-result">
            {{ grandTotalVolumeAmount | number : "1.2-2" }}
            {{ grandTotalVolumeUnit | volumeUnitName | lowercase }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
