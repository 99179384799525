import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { InternalUrlsService, NotificationService } from '@ship4wd/ngx-common';
import { Observable, Subject } from 'rxjs';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { AccessRoleType } from '../../shared/shared.model';
import { UserInfoService } from '../../shared/services/user-info/user-info.service';
import { Organization, OrganizationSettingsData } from '../../shared/organizations/organization.model';
import { TeamMemberOrganization, UserProfile } from '../../shared/accounts/accounts.model';
import { UserInfoOrganization } from '../../shared/features/user-info/user-info.model';
import { NotificationType } from './organizations.model';
import { TeamMember, TeamMemberSettings } from '../manage-organization/manage-organization.model';
import { HeapService } from '../../shared/heap/heap.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private returnUrl: string = '/';
  dataSubject: Subject<null> = new Subject<null>();
  acronymName = new EventEmitter();

  constructor(
    private http: HttpClient,
    private internalUrlsService: InternalUrlsService,
    private appSettings: AppSettingsService,
    private notificationService: NotificationService,
    private router: Router,
    private userInfoService: UserInfoService,
    private heapService: HeapService
  ) { }

  setReturnUrl(url: string) {
    this.returnUrl = url;
  }

  setOrganization(organizationSettingsData: OrganizationSettingsData) {
    let settings = this.appSettings.getSettings();
    settings.organizationId = organizationSettingsData.organizationId;
    settings.countryCode = organizationSettingsData.countryCode;
    settings.organizationName = organizationSettingsData.organizationName;
    settings.isCreditOrganization = organizationSettingsData.isCreditOrganization;
    this.appSettings.addSettings(settings);
    this.updateAnalyticsServicesIdentity();
  }

  returnBack(): void {
    this.router.navigateByUrl(
      this.userInfoService.getSettings().userSettings.isFirstLogin ? 'quote-search' : this.returnUrl
    );
  }

  notify(message: string, type: NotificationType, duration?: number) {
    switch (type) {
      case NotificationType.NOTIFY:
        this.notificationService.notify(message, duration);
        break;
      case NotificationType.SUCCESS:
        this.notificationService.success(message, duration);
        break;
      case NotificationType.ERROR:
        this.notificationService.error(message, duration);
        break;
    }
  }

  getTeamMember(organizationId): Observable<TeamMember> {
    const params = new HttpParams().set('organizationId', organizationId);
    return this.http.get<TeamMember>(`${this.internalUrlsService.getApiBaseUrl()}/teammembers`, { params });
  }

  notifyChange() {
    this.dataSubject.next();
  }

  getOrganization(id: string): any {
    return this.http.get<any>(`${this.internalUrlsService.getApiBaseUrl()}/Organizations/${id}`);
  }

  getOrganizationKycStatus(organizationId: string): Observable<any> {
    return this.http.get<any>(`${this.internalUrlsService.getApiBaseUrl()}/Organizations/${organizationId}/kycStatus`);
  }

  getHomeProfile(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.internalUrlsService.getApiBaseUrl()}/accounts/homeProfile`, { params });
  }

  acceptInvitation(invitationId: string, userId: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/OrganizationInvitations/${invitationId}/accept`,
      JSON.stringify(userId),
      options
    );
  }

  rejectInvitation(invitationId: string, userId: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/OrganizationInvitations/${invitationId}/reject`,
      JSON.stringify(userId),
      options
    );
  }

  addOrganization(body: Organization): Observable<string> {
    return this.http.post<string>(`${this.internalUrlsService.getApiBaseUrl()}/organizations`, body);
  }

  setUserRoles(roles: AccessRoleType[]) {
    let settings = this.userInfoService.getSettings();
    settings.accessRoles = roles;
    this.userInfoService.addSettings(settings);
  }

  setUserOrganizations(acceptedOrganizations: TeamMemberOrganization[]) {
    let settings = this.userInfoService.getSettings();
    let organizations = [];
    acceptedOrganizations.forEach(organization => {
      organizations.push({
        id: organization.organizationId,
        name: organization.organizationName
      } as UserInfoOrganization);
    });
    settings.acceptedOrganizations = organizations;
    this.userInfoService.addSettings(settings);
  }

  setUserFullName(fullName: string) {
    let settings = this.userInfoService.getSettings();
    settings.fullName = fullName;
    this.userInfoService.addSettings(settings);
  }

  setUserAcronymName(acronymName: string) {
    let settings = this.userInfoService.getSettings();
    settings.acronymName = acronymName;
    this.userInfoService.addSettings(settings);
  }

  setUserSettingsAndInfo(userProfile: UserProfile): void {
    let settings = this.userInfoService.getSettings();
    settings.userSettings = userProfile.teamMemberSettings;
    settings.teamMemberId = userProfile.accessRoles[0].memberId;
    settings.phoneNumber = userProfile.phoneNumber;
    this.userInfoService.addSettings(settings);
  }

  setUserSettings(teamMemberSettings: TeamMemberSettings): void {
    let settings = this.userInfoService.getSettings();
    settings.userSettings = teamMemberSettings;
    this.userInfoService.addSettings(settings);
  }

  showUserAcronymName(acronymName: string) {
    this.acronymName.emit(acronymName);
  }

  setUserId(userId: string) {
    let settings = this.userInfoService.getSettings();
    settings.userId = userId;
    this.userInfoService.addSettings(settings);
  }

  private updateAnalyticsServicesIdentity(): void {
    this.heapService.init();
  }
}
