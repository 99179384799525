import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-quote-price-tiers-comparison',
  templateUrl: './quote-price-tiers-comparison.component.html',
  styleUrls: ['./quote-price-tiers-comparison.component.scss']
})
export class QuotePriceTiersComparisonComponent implements OnInit {
  learnMoreUrl = environment.priceTiersComparisonExternalUrl;

  constructor(public dialogRef: MatDialogRef<QuotePriceTiersComparisonComponent>) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
