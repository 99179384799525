<div class="live-assistance-container">
  <div class="live-assistance-wrapper">
    <button class="live-assistance-button" (click)="openSupportDialog()">
      <div class="live-assistance-icon-wrapper">
        <i class="s4d-icon s4d-support"></i>
        <div class="live-assistance-status-color"></div>
      </div>
      <span class="live-assistance-button-text">Live Assistance</span>
    </button>
    <mat-divider *ngIf="chatStatus" [vertical]="true" class="h-100"></mat-divider>
    <div *ngIf="chatStatus" class="live-assistance-chat-button">
      <div *ngIf="!chatOpenedStatus && !chatLoading" class="icon-container">
        <svg focusable="false" aria-hidden="true" viewBox="0 0 100 100" class="icon">
          <path
            d="M50 0c27.614 0 50 20.52 50 45.833S77.614 91.667 50 91.667c-8.458 0-16.425-1.925-23.409-5.323-13.33 6.973-21.083 9.839-23.258 8.595-2.064-1.18.114-8.436 6.534-21.767C3.667 65.54 0 56.08 0 45.833 0 20.52 22.386 0 50 0zm4.583 61.667H22.917a2.917 2.917 0 000 5.833h31.666a2.917 2.917 0 000-5.833zm12.5-15.834H22.917a2.917 2.917 0 000 5.834h44.166a2.917 2.917 0 000-5.834zM79.583 30H22.917a2.917 2.917 0 000 5.833h56.666a2.917 2.917 0 000-5.833z">
          </path>
        </svg>
      </div>
    </div>
  </div>
</div>