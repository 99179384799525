import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';
import { BookingOrganizationContactsService } from './../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import {
  OrganizationContact,
  OrganizationContactsQuery,
  OrganizationContactsQueryParameters,
  OrganizationContactViewModel
} from './../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { Page } from '../../shared/shared.model';
import { RemoveConfirmDialogComponent } from '../../shared/remove-confirm-dialog/remove-confirm-dialog.component';

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageContactsComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  isLoading: boolean = false;
  organizationContacts: OrganizationContact[];
  selectedContact: OrganizationContact;
  data: any[];
  isManagedContact: boolean = false;
  isNewContact: boolean = true;
  organizationId: string;
  selectedFileName: string = '';

  constructor(
    private dialog: MatDialog,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private appSettingsService: AppSettingsService) { }

  ngOnInit(): void {
    this.getOrganizationContacts();
  }

  getInitialName(contact: OrganizationContact): string {
    if (contact?.firstName && contact?.lastName) {
      return contact.firstName.charAt(0).toUpperCase() + contact.lastName.charAt(0).toUpperCase();
    }

    return '';
  }

  openCsvDialog(): void {
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    this.selectedFileName = file.name;

    reader.onload = (e: any) => {
      const contents = e.target.result;
      this.data = this.parseCSV(contents);

      this.readCSV();
    };

    reader.readAsText(file);
  }

  parseCSV(contents: string): any[] {
    const lines = contents.split('\n');
    const result = [];

    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  }

  readCSV(): void {

    if (!this.selectedFileName) {
      this.notificationService.error("Please select .csv file");
      return;
    }

    this.isLoading = true;
    var organizationContacts = this.data as OrganizationContactViewModel[];

    this.bookingOrganizationContactsService
      .addOrganizationContacts(organizationContacts, this.organizationId)
      .subscribe((x: OrganizationContact) => {
        this.notificationService.success("Contacts were added successfuly");
        location.reload();
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }

  addContact(): void {
    this.isNewContact = true;
    this.isManagedContact = true;
  }

  onUpdateContact(contact: OrganizationContact): void {
    this.selectedContact = contact;
    this.isNewContact = false;
    this.isManagedContact = true;
  }

  onDeleteContact(contact: OrganizationContact): void {
    const dialogRef = this.dialog.open(RemoveConfirmDialogComponent, {
      panelClass: 'on-popup-container',
      autoFocus: false,
      data:{
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Remove Contact'
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.result === true) {
        this.deleteOrganizationContact(contact);
      }
    });
  }

  private getOrganizationContacts(): void {
    const query = {
      pageNo: 1,
      pageSize: 500,
      sortBy: OrganizationContactsQueryParameters.companyName
    } as OrganizationContactsQuery;

    const settingData = this.appSettingsService.getSettings();
    this.organizationId = settingData.organizationId;

    this.isLoading = true;
    this.bookingOrganizationContactsService
      .getOrganizationContactsByQuery(query, settingData.organizationId)
      .subscribe((x: Page<OrganizationContact>) => {
        this.organizationContacts = x.items;
      },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private deleteOrganizationContact(organizationContact: OrganizationContact): void {
    this.isLoading = true;
    this.bookingOrganizationContactsService
      .deleteOrganizationContact(organizationContact.organizationId, organizationContact.id)
      .subscribe(
        () => {
          this.getOrganizationContacts();
        },
        error => this.notificationService.error(error))
      .add(() => this.isLoading = false);
  }
}
