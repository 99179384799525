<div class="quote-search-cargo-param" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <div class="quote-search-cargo-param-wrapper"
    [ngClass]="{ 'is-active': isOpened, 'is-error': !isSelected && !isOpened && hasError }" (click)="onOpenClick()">
    <div class="row m-0 align-items-center">
      <div class="col-auto cargo-icon-wrapper px-0">
        <i class="cargo-icon s4d-icon s4d-fcl-light"></i>
      </div>
      <div class="col pe-0">
        <div class="cargo-param" [ngClass]="{ 'is-selected': isSelected && !isOpened }">
          <div class="row flex-column m-0 g-0">
            <div class="col-auto" [ngClass]="{ 'd-none': !isSelected || isOpened }">
              <span class="cargo-param-title">{{ cargoDetails }}</span>
            </div>
            <div class="col-auto">
              <div class="cargo-param-subtitle">Cargo Details</div>

              <mat-error class="pt-1" *ngIf="!isSelected && !isOpened && hasError">
                <i class="s4d-icon s4d-info-light"></i>
                Required
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayFlexibleDimensions]="true" cdkConnectedOverlayPanelClass="new-flow-cargo-details-panel"
  [cdkConnectedOverlayPositions]="getOverlayPosition" (overlayOutsideClick)="onClickedOutsideOverlay($event)">
  <div class="quote-search-cargo-details-wrapper d-flex flex-column no-close" [ngClass]="{
      'is-active': isOpened,
      wide: (shipmentType !== shipmetTypes.FCL && isNoDimensionEnable === false)
    }">
    <div class="row mb-3">
      <div class="col d-flex align-items-center">
        <span class="details-title">Cargo Details</span>
      </div>
      <div *ngIf="shipmentType !== shipmetTypes.FCL" class="col-auto">
        <div class="row">
          <mat-form-field class="details-unit d-flex">
            <mat-select (selectionChange)="onChangeUnitToggle()" panelClass="cargo-panel-popup unit-measurement"
              [formControl]="unitMeasurementType">
              <mat-option *ngFor="let unitMeasurementType of unitMeasurementTypes"
                matTooltip="{{ getToolTipData(unitMeasurementType.name) }}" [value]="unitMeasurementType.value">
                {{ unitMeasurementType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div #scrollableArea class="overflow-y-auto cargodetails-content-wrapper">
      <ng-container *ngIf="shipmentType === shipmetTypes.FCL">
        <div class="row m-0 g-0 gap-3 mb-3 equipment-row"
          *ngFor="let equipmentForm of equipmentsFormArray?.controls; let i = index" [formGroup]="equipmentForm">
          <div class="col">
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
                'equipmet-invalid': checkValid(equipmentForm.get('equipmentCode'))
              }">
              <div class="row align-items-center">
                <div class="col-auto ps-3 input-icon-wrapper">
                  <i class="input-icon s4d-icon s4d-fcl-light"></i>
                </div>
                <div class="col ps-0 pe-3">
                  <mat-select class="equipment-type" placeholder="Equipment" panelClass="cargo-panel-popup"
                    [compareWith]="compareObjects" [floatLabel]="'never'"
                    (selectionChange)="onSelectionChangeEquipment($event.value, equipmentForm)"
                    (openedChange)="onOpenedChanged($event, equipmentForm)"
                    [value]="equipmentForm.get('equipmentCode').value">
                    <mat-option *ngFor="let equipment of equipments" [value]="equipment"
                      [disabled]="equipment.disabled">
                      <div class="row no-close">
                        <div class="col-auto no-close">
                          <div class="input-icon-wrapper no-close">
                            <i class="input-icon s4d-icon s4d-fcl-light no-close"></i>
                          </div>
                        </div>
                        <div class="col ps-0 no-close">{{ equipment.description }}</div>
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
                'equipmet-invalid': checkValid(equipmentForm.get('quantity'))
              }">
              <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                matTooltip="Remove 1 {{ getEquipmentName(equipmentForm.get('equipmentCode').value) }}"
                matTooltipPosition="above" (click)="onMinusQuantity(equipmentForm)"></i>
              <input matInput type="number" min="0" placeholder="0" class="equipment-quantity"
                formControlName="quantity" appPreventNonNumeric />
              <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                matTooltip="Add 1 {{ getEquipmentName(equipmentForm.get('equipmentCode').value) }}"
                matTooltipPosition="above" (click)="onAddQuantity(equipmentForm)"></i>
            </mat-form-field>
          </div>
          <div class="col-auto ps-2 d-flex justify-content-center align-items-center">
            <button mat-icon-button class="equipment-remove-button no-close"
              (click)="onRemoveEquipment(equipmentForm, $event)" [disabled]="equipmentsFormArray?.controls.length === 1"
              matTooltip="Remove commodity">
              <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
            </button>
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-between">
          <div class="col-auto">
            <button mat-button class="details-button" (click)="onAdd($event)" *ngIf="isShowAddContainerOrLeadButton()">
              <span>+</span>
              <span class="ms-2">Add Container</span>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="shipmentType !== shipmetTypes.FCL">
        <div class="col-12 mb-3 mt-4" *ngIf="shipmentType === shipmetTypes.LCL">
          <mat-button-toggle-group class="dimension-mode-toggle col-auto" name="isShowPackageDimensions"
            aria-label="Shipping type" (change)="onShowPackageDimensionsChanged()"
            [formControl]="isShowPackageDimensions">
            <mat-button-toggle class="dimension-mode-button col-auto" *ngFor="let item of showPackageDimensionTypes"
              [value]="item.value" [aria-label]="item.description">
              <span class="dimension-mode-name">{{ item.name }}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="shipmentType === shipmetTypes.AIR">
          <p><mat-slide-toggle [checked]="form.isWithoutDimension.value" (change)="onChange($event)">I don’t have the
              dimensions</mat-slide-toggle></p>
        </div>

        <div class="dimension-enable-info" *ngIf="isNoDimensionEnable && shipmentType === shipmetTypes.AIR">
          <i class="input-icon s4d-icon s4d-info-light"></i>
          <p class="charges-description">Additional charges may apply later if the shipment dimensions exceed the
            estimated values.</p>
        </div>

        <div class="row m-0 g-0 gap-2 mb-3 equipment-row"
          *ngFor="let commodityForm of commoditiesFormArray?.controls; let i = index; let isFirst = first"
          [formGroup]="commodityForm">
          <ng-container *ngIf="isShowPackageTotalVolumeAmount && !isNoDimensionEnable">
            <div class="col row m-0 g-0 row-gap-2 column-gap-3">
              <div class="col">
                <span class="equipment-header">Total Weight of Shipment</span>
                <mat-form-field appearance="fill" class="equipmet-first" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('weightAmount'))
                  }" matTooltip="Load total weight" matTooltipPosition="above">
                  <div class="row">
                    <div class="col-auto ps-3 input-icon-wrapper">
                      <i class="input-icon s4d-icon s4d-mass"></i>
                    </div>
                    <div class="col px-0">
                      <input matInput type="number" min="0.000001" placeholder="Weight" formControlName="weightAmount"
                        (change)="onChangeWeightAmount(commodityForm)" appPreventNonNumeric />
                    </div>
                    <div class="col-auto pe-2">
                      <mat-select class="commodity-select" (selectionChange)="onChangeWeightUnit($event.value)"
                        formControlName="weightUnit" panelClass="cargo-panel-popup weight-unit">
                        <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                          {{ weightUnit.name | lowercase }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </mat-form-field>
                <mat-error *ngIf="checkValid(commodityForm.get('weightAmount'))" class="mt-1">
                  <i class="s4d-icon s4d-info-light"></i>
                  {{ getValidatorErrorKey(commodityForm.get('weightAmount')) | errorMessage }}
                </mat-error>
              </div>
              <div class="col">
                <span class="equipment-header">Total Volume of Shipment</span>
                <mat-form-field appearance="fill" matTooltip="Load total volume" matTooltipPosition="above"
                  [floatLabel]="'never'" class="equipmet-first" [ngClass]="{
                  'equipmet-invalid': checkValid(commodityForm.get('volumeAmount'))
                }">
                  <div class="row">
                    <div class="col-auto ps-3 input-icon-wrapper">
                      <i class="input-icon s4d-icon s4d-dimensions"></i>
                    </div>
                    <div class="col px-0">
                      <input matInput formControlName="volumeAmount" class="text-center" type="number"
                        inputmode="numeric" maxlength="5" min="0.000001" size="5" placeholder="Volume"
                        appPreventNonNumeric (change)="onChangeVolumeAmount(commodityForm)" />
                    </div>
                    <div class="col-3 px-2">
                      <input matInput class="text-end text-grey"
                        value="{{ commodityForm.get('volumeUnit')?.value | volumeUnitName | lowercase }}" disabled />
                    </div>
                  </div>
                </mat-form-field>
                <mat-error *ngIf="checkValid(commodityForm.get('volumeAmount'))" class="mt-1">
                  <i class="s4d-icon s4d-info-light"></i>
                  {{ getValidatorErrorKey(commodityForm.get('volumeAmount')) | errorMessage }}
                </mat-error>
              </div>
              <div class="col-3">
                <span class="equipment-header">Quantity</span>
                <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" class="equipmet-first" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('numberOfPackages'))
                  }">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                    matTooltip="Remove {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onMinusQuantity(commodityForm)"></i>
                  <input matInput type="number" min="0" placeholder="0" class="equipment-quantity"
                    formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                    appPreventNonNumeric />
                  <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                    matTooltip="Add {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onAddQuantity(commodityForm)"></i>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isShowPackageTotalVolumeAmount && !isNoDimensionEnable">
            <div class="col-auto mt-3 me-2">
              <span class="commodity-number" [ngClass]="{ 'equipmet-first': isFirst }">{{ i + 1 }}</span>
            </div>
            <div class="col row m-0 g-0 row-gap-2 column-gap-3">
              <div class="col">
                <span class="equipment-header" *ngIf="isFirst">Weight of Piece</span>
                <mat-form-field appearance="fill" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('packageWeight')),
                    'equipmet-first': isFirst
                  }" matTooltip="Load total weight" matTooltipPosition="above">
                  <div class="row">
                    <div class="col-auto ps-3 input-icon-wrapper">
                      <i class="input-icon s4d-icon s4d-mass"></i>
                    </div>
                    <div class="col px-0">
                      <input matInput type="number" placeholder="0" formControlName="packageWeight"
                        (change)="onChangeWeight(commodityForm)" appPreventNonNumeric />
                    </div>
                    <div class="col-auto pe-2">
                      <mat-select class="commodity-select" (selectionChange)="onChangeWeightUnit($event.value)"
                        formControlName="weightUnit" panelClass="cargo-panel-popup weight-unit">
                        <mat-option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.value">
                          {{ weightUnit.name | lowercase }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </mat-form-field>
                <mat-error *ngIf="checkValid(commodityForm.get('packageWeight'))" class="mt-1">
                  <i class="s4d-icon s4d-info-light"></i>
                  {{ getValidatorErrorKey(commodityForm.get('packageWeight')) | errorMessage }}
                </mat-error>
              </div>
              <div class="col">
                <span class="equipment-header" *ngIf="isFirst">Dimensions/Volume of Piece</span>
                <mat-form-field appearance="fill" class="dimension-field" [floatLabel]="'never'" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('dimensionValue')),
                    'equipmet-first': isFirst
                  }" matTooltip="Load total dimensions" matTooltipPosition="above">
                  <div class="row">
                    <div class="col-auto ps-3 input-icon-wrapper">
                      <i class="input-icon s4d-icon s4d-dimensions"></i>
                    </div>
                    <div class="col px-0">
                      <dimensions-input formControlName="dimensionValue"
                        (change)="onChangeDimensionValue(commodityForm)"></dimensions-input>
                    </div>
                    <div class="col-auto pe-2">
                      <mat-select class="commodity-select" formControlName="dimensionUnit"
                        panelClass="cargo-panel-popup">
                        <mat-option *ngFor="let dimensionUnit of dimensionUnits" [value]="dimensionUnit.value">
                          {{ dimensionUnit.name | lowercase }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </mat-form-field>
                <mat-error *ngIf="
                    commodityForm.get('length')?.hasError('max') ||
                    commodityForm.get('width')?.hasError('max') ||
                    commodityForm.get('height')?.hasError('max') ||
                    checkValid(commodityForm.get('dimensionValue'))
                  " class="mt-1">
                  <i class="s4d-icon s4d-info-light"></i>
                  {{
                  commodityForm.get('length')?.hasError('max') ||
                  commodityForm.get('width')?.hasError('max') ||
                  commodityForm.get('height')?.hasError('max')
                  ? sizeErrorString
                  : (getValidatorErrorKey(commodityForm.get('dimensionValue')) | errorMessage)
                  }}
                </mat-error>
              </div>
              <div class="col-2">
                <span class="equipment-header" *ngIf="isFirst">Quantity</span>
                <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('numberOfPackages')),
                    'equipmet-first': isFirst
                  }">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                    matTooltip="Remove {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onMinusQuantity(commodityForm)"></i>
                  <input matInput type="number" min="0" placeholder="0" class="equipment-quantity"
                    formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                    appPreventNonNumeric />
                  <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                    matTooltip="Add {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onAddQuantity(commodityForm)"></i>
                </mat-form-field>
              </div>
              <div class="col-12" *ngIf="commodityForm.get('chargeableWeightAmount')?.value">
                <span class="chargeable-weight">
                  <i class="header-icon s4d-icon s4d-info-light"></i> Chargeable weight:
                  {{ commodityForm.get('chargeableWeightAmount')?.value | number : '1.2-2' }}
                  {{ commodityForm.get('weightUnit')?.value | weightUnitName | lowercase }}
                  <span *ngIf="isShowChargeableVolumeAmount(commodityForm)">
                    ({{ commodityForm.get('chargeableVolumeAmount')?.value | number : '1.2-2' }}
                    {{ commodityForm.get('volumeUnit')?.value | volumeUnitName | lowercase }})
                  </span>
                </span>
              </div>
            </div>
            <div class="col-auto px-2 d-flex mt-2">
              <button mat-icon-button class="equipment-remove-button no-close" [ngClass]="{ 'equipmet-first': isFirst }"
                (click)="onRemoveLoad(commodityForm)" [disabled]="commoditiesFormArray?.controls.length === 1"
                matTooltip="Remove Load">
                <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
              </button>
            </div>
          </ng-container>

          <ng-container class="dimension-enable" *ngIf="isNoDimensionEnable && shipmentType === shipmetTypes.AIR">
            <div class="row m-0 g-0 gap-3  equipment-row">
              <div class="col">
                <span class="equipment-header" *ngIf="isFirst">Weight of Piece</span>
                <mat-form-field appearance="fill" class="w-100 " [floatLabel]="'never'" [ngClass]="{
                'equipmet-invalid': checkValid(commodityForm.get('weightAmount')),
                'equipmet-first': isFirst
              }">
                  <div class="row align-items-center">
                    <div class="col-auto ps-3 input-icon-wrapper">
                      <i class="input-icon s4d-icon s4d-mass"></i>
                    </div>
                    <div class="col ps-0 pe-3">
                      <mat-select class="equipment-type" placeholder="Select Weight of Piece"
                        panelClass="cargo-panel-popup" [compareWith]="compareWeightObjects" [floatLabel]="'never'"
                        (selectionChange)="onSelectionChangeweight($event.value, commodityForm)"
                        (openedChange)="onOpenedChanged($event, commodityForm)"
                        [formControl]="commodityForm.get('selectedValue')">
                        <mat-option *ngFor="let weight of weightRange" [value]="weight">
                          <div class="row no-close">
                            <div class="col-auto no-close">
                              <div class="input-icon-wrapper no-close">
                                <i class="input-icon s4d-icon s4d-mass no-close"></i>
                              </div>
                            </div>
                            <div class="col ps-0 no-close">{{ weight.range }}</div>
                          </div>
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="col-3">
                <span class="equipment-header" *ngIf="isFirst">Quantity</span>
                <mat-form-field appearance="fill" class="w-100" [floatLabel]="'never'" [ngClass]="{
                    'equipmet-invalid': checkValid(commodityForm.get('numberOfPackages')),
                    'equipmet-first': isFirst
                  }">
                  <i matPrefix class="equipment-quantity-minus-icon s4d-icon s4d-minus"
                    matTooltip="Remove {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onMinusQuantity(commodityForm)"></i>
                  <input matInput type="number" min="0" placeholder="0" class="equipment-quantity"
                    formControlName="numberOfPackages" (change)="onChangeNumberOfPackages(commodityForm)"
                    appPreventNonNumeric />
                  <i matSuffix class="equipment-quantity-plus-icon s4d-icon s4d-plus"
                    matTooltip="Add {{ i + 1 | ordinal }} Package" matTooltipPosition="above"
                    (click)="onAddQuantity(commodityForm)"></i>
                </mat-form-field>
              </div>
              <div class="col-auto ps-2 d-flex justify-content-center align-items-center">
                <button mat-icon-button class="equipment-remove-button  no-close"
                  [ngClass]="{ 'equipmet-first': isFirst }" (click)="onRemoveLoad(commodityForm, $event)"
                  [disabled]="commoditiesFormArray?.controls.length === 1" matTooltip="Remove commodity">
                  <i class="equipment-remove-icon s4d-icon s4d-trash no-close"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row mt-4 d-flex justify-content-between">
          <div class="col-auto">
            <button mat-button class="details-button" (click)="onAdd($event)" *ngIf="isShowAddContainerOrLeadButton()">
              <span>+</span>
              <span class="ms-2">Add Load</span>
            </button>
          </div>
          <div class="col-auto" [matTooltip]="totalChargeableWeightTooltip">
            <span *ngIf="grandTotalChargeableWeight > 0">
              Total Chargeable Weight:
              <span class="bold">
                <span *ngIf="!isShowPackageTotalVolumeAmount">{{ totalPackages }} Package(s), </span>
                {{ grandTotalChargeableWeight | number : '1.2-2' }} {{ grandTotalWeightUnit | weightUnitName | lowercase
                }}
                <span *ngIf="isShowGrandTotalChargeableVolume">({{ grandTotalChargeableVolume | number : '1.2-2' }}
                  {{ grandTotalVolumeUnit | volumeUnitName | lowercase}})</span>
              </span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mt-4 text-grey">
      <div class="col">
        <div class="row">
          <div class="col">
            <mat-checkbox [formControl]="isHazardous" (change)="onHazardousChanged($event)">
              Shipment contains batteries or dangerous goods
            </mat-checkbox>
          </div>
          <div *ngIf="isCheckingBatteriesSupportInProgress"
            class="col-auto d-flex justify-content-center align-items-center px-0">
            <mat-spinner diameter="16"></mat-spinner>
          </div>
        </div>
        <div class="row">
          <div *ngIf="isHazardous.value && shipmentType === shipmetTypes.FCL" class="col-12 mt-3">
            <mat-radio-group class="d-flex gap-3" [(ngModel)]="selectedDangerousGoodsType"
              [disabled]="(dangerousGoodsWarning !== null && selectedDangerousGoodsType === undefined) || isCheckingBatteriesSupportInProgress"
              (change)="onDangerousGoodsTypeChanged($event)">
              <mat-radio-button *ngFor="let type of dangerousGoodsTypes" [value]="type.value">
                {{ type.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>
      <div class="col-auto d-flex align-items-end">
        <button mat-button s4dButton class="confirm-button small ms-auto" (click)="onConfirm()"
          [disabled]="dangerousGoodsWarning !== null || (isHazardous.value && !selectedDangerousGoodsType)">Confirm</button>
      </div>
      <div class="col-12" *ngIf="isHazardous.value && dangerousGoodsWarning">
        <div class="s4d-alert-warning mt-4">
          <span class="alert-icon"><i class="s4d-icon" [ngClass]="dangerousGoodsWarning.icon"></i></span>
          <span class="alert-text" [innerHTML]="dangerousGoodsWarning.description"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
