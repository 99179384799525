<div class="language-menu-button-container">
  <button mat-flat-button class="language-menu-button" [style.background-color]="backgroudColor"
    [matMenuTriggerFor]="languageMenu">
    <span>{{ currentLanguage.languageCode | uppercase }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</div>

<mat-menu #languageMenu="matMenu" color="primary" class="language-menu" xPosition="after">
  <button mat-menu-item *ngFor="let language of allLanguages" (click)="onLanguageSelect(language?.languageCode)">
    <span>{{ language?.languageName }}</span>
  </button>
</mat-menu>
