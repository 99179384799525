import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    transform(value: string, isDisplayShortForm: boolean = true): string {
        let totalMinutes = moment.duration(value).asMinutes();
        let days = Math.floor(totalMinutes / 1440);
        let hours = 0;
        let minutes = 0;
        const dayRem = totalMinutes % 1440;

        if (dayRem > 0) {
            hours = Math.floor(dayRem / 60);
            minutes = Math.floor(dayRem % 60);
        }

        var response = '';
        response += days !== 0 ? isDisplayShortForm ? `${days}d ` : `${days} Days ` : '';
        response += hours !== 0 ? isDisplayShortForm ? `${hours}h ` : `${hours} Hours ` : '';
        response += minutes !== 0 ? isDisplayShortForm ? `${minutes}m ` : `${minutes} Minutes ` : '';

        return response;
    }

}