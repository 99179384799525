import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { UserInfoService } from '../services/user-info/user-info.service';

@Directive({
  selector: '[appRoleBasedHideOrShow]'
})
export class RoleBasedHideOrShowDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private userInfoService: UserInfoService
  ) { }

  ngAfterViewInit(): void {
    if (this.userInfoService.checkIsAgent())
      this.hideOrShowElement();
  }

  hideOrShowElement(): void {
    this.elementRef.nativeElement.remove();
  }
}
