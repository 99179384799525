import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class HubspotService {
    constructor() { }

    loadBodyScript(id: string): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.src = `//js.hs-scripts.com/${id}.js`;
        document.body.appendChild(script);
    }
}