import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { NotificationService } from '@ship4wd/ngx-common';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { HarmonizedSystemCode, HarmonizedSystemCodeQuery, HarmonizedSystemCodeQueryParameters, Page, SelectItem, VolumeUnit, WeightUnit } from '../../../../../shared/shared.model';
import { BookingFlowService } from '../../booking-flow.service';
import { Booking } from '../../../../../shared/bookings/bookings.model';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { CommodityService } from '../../../../../shared/services/commodity.service';

@Component({
  selector: 'app-booking-flow-container-details',
  templateUrl: 'container-details.component.html',
  styleUrls: ["./container-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'copy-container-overlay' }
    }
  ]
})

export class BookingFlowContainerDetailsComponent implements OnInit {
  index: number = 0;
  booking: Booking = null;
  containerForm: FormGroup = null;
  containers: FormArray = null;
  containersCubicAndWeight = new Map<string, any>();
  volumeWeightError: boolean;
  filteredCommodities: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];
  commodityResponse: HarmonizedSystemCode[] = [] as HarmonizedSystemCode[];

  weightUnits: SelectItem[] = [
    { name: WeightUnit[WeightUnit.KG], value: WeightUnit.KG },
    { name: WeightUnit[WeightUnit.LB], value: WeightUnit.LB }
  ];

  volumeUnits: SelectItem[] = [
    { name: VolumeUnit[VolumeUnit.CBM], value: VolumeUnit.CBM },
    { name: VolumeUnit[VolumeUnit.CFT], value: VolumeUnit.CFT },
    { name: VolumeUnit[VolumeUnit.CUI], value: VolumeUnit.CUI }
  ];

  isLoading: boolean = false;
  isToIsrael: boolean = false;

  get isValid(): boolean {
    let commoditiesValid = true;

    const commodities = this.containerForm.controls["commodities"] as FormArray;
    commodities.controls.forEach((commodity: FormGroup) => {
      if (!commodity.valid) commoditiesValid = false;
    });

    return commoditiesValid;
  }

  constructor(
    public dialogRef: RollupDialogService,
    private bookingFlowService: BookingFlowService,
    private commodityService: CommodityService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationService: NotificationService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.booking = data.booking;
    this.index = data.index;
    this.containerForm = data.current;
    this.containers = data.containers;
    this.containersCubicAndWeight = data.containersCubicAndWeight;
    this.bookingFlowService.booking = data.booking;

    if (this.booking.to.substring(0, 2) == "IL") {
      this.isToIsrael = true;
    }

    this.getCommodities();
  }

  ngOnInit(): void { }

  onCheckValid(control: FormControl): boolean {
    return !control?.valid && control?.touched
  }

  onSelectCommodity(event: MatSelectChange, commodityForm: FormGroup, index: number): void {
    commodityForm.get('commodityObject').setValue(event?.value);
    commodityForm.get('commodityTypeCode').setValue(event?.value?.id);
    commodityForm.get('commodityDescription').setValue(event?.value?.description);

    this.onValueChanged('Commodity Code', index);
  }

  onUnitSelect(event: any): void {
    event.stopPropagation();
  }

  onAddCommodity(container: FormGroup): void {
    const commodities = container.get("commodities") as FormArray;
    commodities.push(this.bookingFlowService.addCommodity());
  }

  onDeleteCommodity(container: FormGroup, index: number): void {
    const commodities = container.get("commodities") as FormArray;
    commodities.removeAt(index);
  }

  onCopyContainerDetails(sIndex: number, dIndex: number, targetContainer: FormGroup): void {
    let sourceCommodities = this.containers.controls[sIndex]?.get('commodities') as FormArray;
    let targetCommodities = this.containers.controls[dIndex]?.get('commodities') as FormArray;
    targetCommodities.clear();
    sourceCommodities.value.forEach(commodity => {
      commodity.id = null;
      targetCommodities.push(this.bookingFlowService.addCommodity(commodity))
    });
    targetCommodities.updateValueAndValidity();
  }

  onConfirm(): void {
    this.containerForm.markAllAsTouched();

    if (this.isValid) {
      const data = {
        containers: this.containers
      }

      this.dialogRef.close(data);
    }
  }

  getContainerTitle(container: FormGroup): any {
    return this.getFormControlValue(container, 'equipmentTypeDescription');
  }

  getFormControlValue(container: FormGroup, key: string): any {
    return container.get(key).value;
  }

  getVolumeWeightError(): void {
    const maxVolume = this.containersCubicAndWeight.get(this.containerForm.value.equipmentTypeCode)?.maxCubic;
    const maxWeight = this.containersCubicAndWeight.get(this.containerForm.value.equipmentTypeCode)?.maxWeight;

    const commodities = this.containerForm.controls["commodities"] as FormArray;
    let totalWeight = 0;
    let totalVolume = 0;

    commodities.controls.forEach((commodity: FormGroup) => {
      const weight = commodity.controls['weight'].value;
      if (!isNaN(weight)) {
        totalWeight += weight;
      }
      const volume = commodity.controls['volume'].value;
      if (!isNaN(volume)) {
        totalVolume += volume;
      }
    });

    const weightExceedsLimit = totalWeight > maxWeight;
    const volumeExceedsLimit = totalVolume > maxVolume;

    this.volumeWeightError = weightExceedsLimit || volumeExceedsLimit;
    if (this.volumeWeightError) {
      this.googleAnalyticsService.selectedTotalWeightAndVolumeError();
    }
  }

  onValueChanged(fieldName: string, index: number): void {
    this.googleAnalyticsService.cargoDetailValueChanged(fieldName, index);
  }

  onFocus(fieldName: string, index: number, formControlName: string): void {
    if (!this.isFieldAlreadyTouched(formControlName))
      this.googleAnalyticsService.cargoDetailsTouched(fieldName, index);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Cargo Details",
      fieldName,
      errorValue
    })
  }

  private isFieldAlreadyTouched(formControlName: string): boolean {
    return this.containers.controls.some((container: FormGroup) => {
      const commodities = container.controls["commodities"] as FormArray;
      return commodities.controls.some((commodity: FormGroup) => commodity.get(formControlName)?.touched);
    });
  }

  private getCommodities(): void {
    this.isLoading = true;
    let query = new HarmonizedSystemCodeQuery();
    query.pageNo = 1;
    query.pageSize = 500;
    query.any = true;
    query.sortBy = HarmonizedSystemCodeQueryParameters.description;
    this.commodityService
      .getPage(query)
      .subscribe(
        (x: Page<HarmonizedSystemCode>) => {
          this.commodityResponse = x.items;
          this.filteredCommodities = this.commodityResponse;
          const otherCommodities = this.filteredCommodities.filter(item => item.description === 'Other Commodities');
          const restOfItems = this.filteredCommodities.filter(item => item.description !== 'Other Commodities');
          this.filteredCommodities = [...restOfItems, ...otherCommodities];
          return this.filteredCommodities;
        },
        error => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }
}
