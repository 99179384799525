import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { OrganizationsService } from '../../../desktop/organizations/organizations.service';
import { SupportRequestsService } from '../support-requests.service';
import { UserInfo } from '../../../shared/features/user-info/user-info.model';
import { AppSettings } from '../../../shared/features/app-settings/app-settings.model';
import { SupportRequest, SupportRequestDialogData, SupportRequestTypeCode } from '../support-requests.model';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { ApplicationNotificationService } from '../../../shared/application-notification/application-notification.service';
import { TeamMemberSettings } from '../../manage-organization/manage-organization.model';
import { NotificationData } from '../../../shared/application-notification/application-notification.model';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';
import { SalesforceService } from '../../../shared/services/salesforce.service';

@Component({
  selector: 'support-request-dialog',
  templateUrl: './support-request-dialog.component.html',
  styleUrls: ['./support-request-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupportRequestDialogComponent implements OnInit {
  teamMemberSettingsForm: FormGroup;
  supportRequestForm: FormGroup;
  userInfo: UserInfo;
  appSettings: AppSettings;
  isLoading = false;
  isButtonLoading = false;
  isWhatsAppMode = false;
  isFromBookingsPage: boolean = false;

  private hasGAErrorMessageBeenCalled = false;

  constructor(
    public dialogRef: MatDialogRef<SupportRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SupportRequestDialogData,
    private fb: FormBuilder,
    private userInfoService: UserInfoService,
    private appSettingsService: AppSettingsService,
    private organizationsService: OrganizationsService,
    private supportRequestsService: SupportRequestsService,
    private notificationService: NotificationService,
    private applicationNotificationService: ApplicationNotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private salesforceService: SalesforceService) { }

  ngOnInit(): void {
    this.userInfo = this.userInfoService.getSettings();
    this.appSettings = this.appSettingsService.getSettings();
    this.createTeamMemberSettingsForm();
    this.createSupportRequestForm();

    this.googleAnalyticsService.exposedLiveBookingAssistancePopup();
    this.isFromBookingsPage = this.data?.isFromBookingsPage;
  }

  onClose(analiticsMessage: string): void {
    this.googleAnalyticsService.noNeedHelpFromLiveBookingAssistancePopup(analiticsMessage);
    this.teamMemberSettingsForm.controls['disableHelpPopup'].setValue(true);
    this.updateTeamMemberSettings(this.teamMemberSettingsForm.value);
    this.dialogRef.close(true);
  }

  onChangeDisableHelpPopup(): void {
    this.updateTeamMemberSettings(this.teamMemberSettingsForm.value);
  }

  async onClickChat(): Promise<void> {
    this.googleAnalyticsService.chatFromLiveBookingAssistancePopup();

    await this.salesforceService.openChat();

    this.dialogRef.close(true);
  }

  onClickWhatsApp(): void {
    this.isWhatsAppMode = true;
    this.googleAnalyticsService.whatsAppFromLiveBookingAssistancePopup();
    this.googleAnalyticsService.whatsAppScreenFromLiveBookingAssistancePopup();
  }

  onWpClick(): void {
    window.open('https://api.whatsapp.com/send?phone=17579049600', "_blank")
  }

  onBack(isPopupClose?: boolean): void {
    this.isWhatsAppMode = false;
    this.googleAnalyticsService.backToLiveBookingAssistancePopupFromWhatsApp();

    if (isPopupClose)
      this.onClose('X');
  }

  onClickAddSupportRequest(): void {
    this.googleAnalyticsService.callFromLiveBookingAssistancePopup();
    this.supportRequestForm.markAllAsTouched();

    if (this.supportRequestForm.valid) {
      this.addSupportRequest(this.supportRequestForm.value);
    }
  }

  onErrorMessage(message: string): void {
    if (!this.hasGAErrorMessageBeenCalled) {
      this.googleAnalyticsService.errorFromLiveBookingAssistancePopup(message);
      this.hasGAErrorMessageBeenCalled = true;
    }
  }

  onPhoneInputFirstFocus(): void {
    this.googleAnalyticsService.startTypingPhoneLiveBookingAssistancePopup();
  }

  showCallWithExpert(): boolean {
    return this.data?.supportRequestType == SupportRequestTypeCode.booking;
  }

  private updateTeamMemberSettings(teamMemberSettings: TeamMemberSettings): void {
    this.organizationsService.setUserSettings(teamMemberSettings);
  }

  private addSupportRequest(supportRequest: SupportRequest): void {
    this.isButtonLoading = true;
    this.supportRequestsService
      .addSupportRequest(supportRequest)
      .subscribe(() => {
        this.showAssistantWillCallBackMessage();
        this.dialogRef.close(true);
      },
        (error) => this.notificationService.error(error))
      .add(() => this.isButtonLoading = false)
  }

  private createTeamMemberSettingsForm(): void {
    this.teamMemberSettingsForm = this.fb.group({
      disableHelpPopup: [this.userInfo.userSettings.disableHelpPopup]
    });
  }

  private createSupportRequestForm(): void {
    this.supportRequestForm = this.fb.group({
      teamMemberId: [this.userInfo.teamMemberId],
      organizationId: [this.appSettings.organizationId],
      organizationName: [this.appSettings.organizationName],
      fullName: [this.userInfo.fullName],
      phoneNumber: [this.userInfo.phoneNumber, [Validators.required, Validators.pattern('^[0-9\-\+]{9,20}$')]],
      bolOrderNumber: [this.data?.bolOrderNumber],
      supportRequestType: [this.data?.supportRequestType ?? SupportRequestTypeCode.general],
      quoteSearchId: [this.data?.quoteSearchId]
    });
  }

  private showAssistantWillCallBackMessage(): void {
    let notificationData = new NotificationData();
    notificationData.message = 'The booking assistant will call you in less than 10 minutes.';
    notificationData.isShowCloseButton = true;
    notificationData.rightIconClass = 's4d-headphones';
    notificationData.panelClass = 'support-request-message';

    this.applicationNotificationService.notify(notificationData);
    this.googleAnalyticsService.dispalyMessageBookingAssistanceWillCall();
  }
}
