import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { DatepickerCustomHeaderComponent } from '../flow-cargo-date/datepicker-custom-header/datepicker-custom-header.component';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { NotificationService } from '@ship4wd/ngx-common';
import {
  IncreaseUnlocodeSearchCountViewModel,
  LocationType,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  ShipperType,
  TripsSearchBaseViewModel,
  TripsSearchFromViewModel,
  TripsSearchViewModelResponse
} from '../../../../../shared/shared.model';
import { SupportedCountriesSearch, SupportedCountry } from '../../../../../shared/supported-countries/supported-countries.model';
import { QuotesService } from '../../../quotes.service';
import { StringCutterService } from '../../../../../shared/services/text-cutter/stringCutter.service';
import { TripUnLocodeAutocompleteService } from '../../../../../shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { UtilityService } from '../../../../../shared/helper/utility.service';
import { SupportedCountriesService } from '../../../../../shared/supported-countries/supported-countries.service';

@Component({
  selector: 'app-new-flow-shipping-location',
  templateUrl: './new-flow-shipping-location.component.html',
  styleUrls: ['./new-flow-shipping-location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewFlowShippingLocationComponent implements OnInit, OnChanges, AfterViewInit {
  todayDate: Date = new Date();
  minSelectableDate: Date = new Date();
  maxSelectableDate: Date = null;
  customHeader = DatepickerCustomHeaderComponent;
  @Input() control: FormControl;
  @Input() type: SearchType = SearchType.from;
  @Input() pageSize: number = 10;
  @Input() minimumLength: number = 3;
  @Input() shipmentType = ShipmentType.FCL;
  @Input() searchId = '';
  @Input() from = null;
  @Input() to = null;
  @Input() isOpened: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() quoteSearchParameter: QuoteSearchParameters | undefined;
  @Input() isReuse: boolean = false;
  @Input() hasError: boolean = false;

  @Output() setSearchId = new EventEmitter<string>();
  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() setShipperType: EventEmitter<ShipperType> = new EventEmitter();
  @Output() next: EventEmitter<number> = new EventEmitter();
  @Output() opened: EventEmitter<boolean> = new EventEmitter();

  searchType = SearchType;
  selectedLocation: TripsSearchFromViewModel = null;
  locationsResponse: TripsSearchViewModelResponse = null;
  locations: Map<LocationType, TripsSearchBaseViewModel[]> = new Map<LocationType, TripsSearchBaseViewModel[]>();
  sortedMap: any = [];
  postcodeOptionTitle: string = null;
  suggestedLocality: string = null;
  isLoading: boolean = false;
  isPostcode: boolean = false;
  isLocationAutocompleteSearchEnable: boolean;
  supportedCountries: SupportedCountry[];
  searchParamChangeSubscription!: Subscription;
  isSearchFromPredefined: boolean = false;
  toLocationsLoadedSubscription: Subscription;
  searchLocationsOnShipmentTypeChangeSubscription: Subscription;
  recentQuotes: any = [];
  isExpanded: boolean = false;

  get getOverlayPosition(): ConnectedPosition[] {
    switch (this.type) {
      case SearchType.from:
        return [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: window.innerHeight <= 790 ? -176 : 0
          }
        ]
      case SearchType.to:
        return [
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: window.innerHeight <= 790 ? -176 : 0
          }
        ]
    }
  }

  constructor(
    private quotesService: QuotesService,
    private stringCutterService: StringCutterService,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilityService: UtilityService,
    private supportedCountriesService: SupportedCountriesService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getSupportedCountries();
    this.fillRecentQuotesFromStorage();
    this.subscribeActiveMenuOption();
  }

  ngAfterViewInit(): void {
    this.subscribeActiveMenuOption();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentType && !changes.shipmentType.firstChange
      && changes.shipmentType.previousValue !== null && !(changes.to?.currentValue || changes.from?.currentValue)) {
      this.isOpened = false;
      this.isSelected = false;
      this.selectedLocation = null;
      this.control.setValue(null)
    }

    if (changes.shipmentType) {
      this.getSupportedCountries();
    }

    if (changes.isOpened?.currentValue === true) {
      this.isOpened = changes.isOpened?.currentValue;
      this.fillRecentQuotesFromStorage();
      this.setOverlayVisibility(this.isOpened);
      this.quotesService.setActiveMenuOption(this.quoteSearchParameter);
      this.newSearchLocationTouched();
    }

    if (changes.isSelected?.currentValue === true) {
      this.selectedLocation = this.control.value;
      if (this.selectedLocation)
        this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(this.selectedLocation?.displayName, 20);
    }
  }

  onOpenClick(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.fillRecentQuotesFromStorage();
    this.isOpened = !this.isOpened;
    this.setOverlayVisibility(this.isOpened);
    this.quotesService.setActiveMenuOption(this.quoteSearchParameter);
    this.newSearchLocationTouched();

    if (!this.selectedLocation) {
      this.setIsClearControls({ isClear: true, type: this.type });
    }
  }

  onConfirm(): void {
    this.isSelected = true;
    this.isOpened = false;
    this.setOverlayVisibility(this.isOpened);
    this.next.emit(this.type === SearchType.from ? QuoteSearchParameters.from : QuoteSearchParameters.to);
    this.setSelected.next(this.isSelected);
    this.opened.emit(this.isOpened)
  }

  onSetSelectedLocation(event: { selectedLocation: TripsSearchFromViewModel, emitNext?: boolean }): void {
    this.selectedLocation = event?.selectedLocation;
    this.isSelected = true;

    if (event.emitNext ?? true) {
      this.onConfirm();
    }
    else {
      this.tripUnLocodeAutocompleteService.increaseUnlocodeSearchCount(this.getIncreaseUnlocodeSearchCountModel()).subscribe();
    }
  }

  onExpanded(event: boolean): void {
    this.isExpanded = event;
  }

  onClickedOutsideOverlay(event: MouseEvent): void {
    if (!this.utilityService.isEventTargetHasClass(event.target, "no-close")) {
      this.isOpened = false;
      this.setOverlayVisibility(this.isOpened);
    }
  }

  private setOverlayVisibility(state: boolean): void {
    this.opened.emit(state)
    document.getElementById("search-flow-overlay").style.visibility = state ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = state ? '1' : '0';
  }

  private subscribeActiveMenuOption(): void {
    this.quotesService.activeMenuOption.subscribe((option: QuoteSearchParameters | null) => {
      if (option !== null) {
        if (this.quoteSearchParameter === QuoteSearchParameters.from && option === QuoteSearchParameters.to) {
          this.isOpened = false;
        }

        if (this.quoteSearchParameter === QuoteSearchParameters.to && option === QuoteSearchParameters.from) {
          this.isOpened = false;
        }

        if ([QuoteSearchParameters.cargo, QuoteSearchParameters.date, QuoteSearchParameters.shipmentType].includes(option)) {
          this.isOpened = false;
        }
      }
    })
  }

  private fillRecentQuotesFromStorage(): void {
    let cachelist = this.tripUnLocodeAutocompleteService.getTripsSearchsFromStorage(this.type, this.shipmentType);
    this.recentQuotes = cachelist.reverse();
  }

  private setIsClearControls(control: any): void {
    this.quotesService.isClearControls.next(control);
  }

  private getIncreaseUnlocodeSearchCountModel(): IncreaseUnlocodeSearchCountViewModel {
    return {
      unLocode: this.selectedLocation?.unLocode,
      searchType: this.type,
      shipmentType: this.shipmentType
    } as IncreaseUnlocodeSearchCountViewModel;
  }

  private newSearchLocationTouched(): void {
    this.googleAnalyticsService.newSearchLocationLocationTouched(
      this.shipmentType,
      (this.type === SearchType.from ? QuoteSearchParameters.from : QuoteSearchParameters.to) + 1,
      `where do you want to ship ${this.type === SearchType.from ? 'from' : 'to'}?`
    )
  }

  private async getSupportedCountries(): Promise<void> {
    try {
      if (!this.shipmentType) return;

      const query = this.prepareSupportedCountriesQuery();
      this.isLoading = true;
      this.supportedCountries = await this.supportedCountriesService.getSupportedCountriesByCountryUsability(query).toPromise();

      this.isLoading = false
    } catch (e) {
      this.notificationService.error(e);
    }
  }

  private prepareSupportedCountriesQuery(): SupportedCountriesSearch {
    return {
      shipmentType: this.shipmentType,
      searchType: this.type
    } as SupportedCountriesSearch;
  }
}
