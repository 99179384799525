import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { UserInfoService } from '../services/user-info/user-info.service';

@Directive({
  selector: '[appSetRoleBasedPrice]'
})
export class SetRoleBasedPriceDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private userInfoService: UserInfoService
  ) { }

  ngAfterViewInit(): void {
    if (this.userInfoService.checkIsAgent())
      this.getPrice()
  }

  getPrice(): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', '*.**');
  }
}
