import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe, DecimalPipe, Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FileInfo, FileUploaderMode, LayoutType } from '@ship4wd/ngx-manager-ui';
import {
  EnvironmentsService,
  EnvironmentsServiceConfig,
  NotificationDialogType,
  NotificationService
} from '@ship4wd/ngx-common';
import {
  Booking,
  BookingFile,
  BookingNoteAccessType,
  BookingSummary,
  Commodity,
  Note
} from '../../../../shared/bookings/bookings.model';
import { PaymentStatusTransition, ShipmentType, SupportedFileFormats } from '../../../../shared/shared.model';
import { LayoutService } from '../../../../mobile/layout/layout.service';
import { LayoutMode } from '../../../../mobile/layout/layout.model';
import { BookingsService } from '../../../../shared/bookings/bookings.service';
import { Organization, TeamMember } from '../../../../desktop/manage-organization/manage-organization.model';
import { OrganizationsService } from '../../../../desktop/organizations/organizations.service';
import { environment } from '../../../../../environments/environment';
import { FourtySeasPaymentService } from '../../../../shared/payments/fourty-seas-payment.service';
import { BookingDocumentsService } from '../../../../shared/bookings/booking-documents/booking-documents.service';
import { LegalNameRollupDialogComponent } from '../../../../shared/legal-name-rollup-dialog/legal-name-rollup-dialog.component';
import { Payment, PaymentRequest } from '../../../../shared/payments/payments.model';
import { PaymentsService } from '../../../../shared/payments/payments.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { GoogleAnalyticsService } from '../../../../shared/google-analytics/google-analytics.service';
import { RollupDialogService } from '../../../rollup-dialog/rollup-dialog.service';
import { UserInfo } from '../../../../shared/features/user-info/user-info.model';
import { UserInfoService } from '../../../../shared/services/user-info/user-info.service';
import { UtilityService } from '../../../../shared/helper/utility.service';
import { ContainerCommodity, QuotesEquipment, QuotesQuery } from '../../../../desktop/quotes/quotes.model';
import { QuotesService } from '../../../../desktop/quotes/quotes.service';
import { SupportRequestsService } from '../../../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../../support-requests/support-requests.model';

@Component({
  selector: 'app-booking-flow-summary',
  templateUrl: 'booking-flow-summary.component.html',
  styleUrls: ["./booking-flow-summary.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class BookingFlowSummaryComponent implements OnInit, AfterViewInit {
  booking: Booking;
  bookingSummary: BookingSummary | any;
  organization: Organization;
  bookingId: string;
  generatedReferenceId: string;
  paymentRequestId: string;
  customerReferenceControl: FormControl = new FormControl({ value: "", disabled: true });
  bookingCustomerNote: FormGroup;
  shipmentTypes = ShipmentType;
  fileUploaderMode = FileUploaderMode;
  supportedFileFormats = SupportedFileFormats;
  fileUploaderLayoutType = LayoutType;
  teamMember: TeamMember;
  isCreditOrganization: boolean = false;
  isFinanceEnabled: boolean = false;
  isSubmitLoading: boolean = false;
  isLoading: boolean = false;
  userInfo: UserInfo;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private layoutService: LayoutService,
    private bookingsService: BookingsService,
    private organizationsService: OrganizationsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private bookingDocumentsService: BookingDocumentsService,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService,
    private datePipe: DatePipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private rollupDialogService: RollupDialogService,
    private userInfoService: UserInfoService,
    private utilityService: UtilityService,
    private decimalPipe: DecimalPipe,
    private quotesService: QuotesService,
    private supportRequestsService: SupportRequestsService
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;

    if (this.bookingId != null) {
      this.getBookingById(this.bookingId);
    }

    this.isFinanceEnabled = this.getIsFinanceEnabled();
    this.subscribePaymentLoader();
    this.isSubmitLoading = false;
    this.userInfo = this.userInfoService.getSettings();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setMode(LayoutMode.BOOKINGFLOW);
      this.layoutService.setToolbarShowUser(true);
      this.layoutService.setToolbarShowHome(false);
      this.layoutService.setToolbarShowBack(true);
      this.layoutService.setHideImage(false);
      this.layoutService.setToolbarTitle("Summary");
      this.layoutService.setToolbarDescription("Review & Pay your order");
      this.layoutService.setToolbarBackUrl(
        this.bookingId != null ? `/booking/${this.bookingId}` : "/shipments"
      );
    }, 0);
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onPrint(id: string): void {
    window.open(`/booking/pdf/${id}?mode=print`, '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
  }

  onGetSecureFileUrl(fileId: string): void {
    this.bookingDocumentsService
      .getSignedUrl(this.booking.id, fileId)
      .subscribe(x => {
        setTimeout(() => {
          window.open(x.url, "_blank");
        })
      },
        error => {
          this.notificationService.error(error);
        });
  }

  onListingFilesChange(files: FileInfo[]): void {
    const filesToAdd = files.filter(x => !x.id);
    this.removeExistedFile(files);
    this.addFiles(filesToAdd, files);
  }

  onSubmitBooking(): void {
    if (this.hasOrganizationMissingData()) {
      this.rollupDialogService.open(LegalNameRollupDialogComponent, {
        organization: this.organization
      }).subscribe(
        (data) => {
          if (data?.result === true) {
            if (this.isFinanceEnabled)
              this.submitBooking(this.isCreditOrganization);
            else
              this.submitBookingWithoutFinance();
          }
          else {
            return;
          }
        });
    }
    else {
      if (this.isFinanceEnabled)
        this.submitBooking(this.isCreditOrganization);
      else
        this.submitBookingWithoutFinance();
    }
  }

  onSaveDraft(): void {
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    this.bookingsService.updateBooking(this.bookingId, this.booking)
      .subscribe(() => {
        this.navigateToDashboard();
      },
        (error) => this.notificationService.error(error))
      .add(() => (this.isSubmitLoading = false));
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getFormattedBookingAddress(bookingSearchType: 'from' | 'to'): string {
    const bookingSearch = bookingSearchType === 'from' ? this.bookingSummary.fromBookingSearch : this.bookingSummary.toBookingSearch;
    let addressParts: string[] = [];

    if (bookingSearch?.street) {
      addressParts.push(bookingSearch.street);
    }

    if (bookingSearch?.displayName) {
      addressParts.push(bookingSearch.displayName);
    }

    if (bookingSearch?.countryName) {
      addressParts.push(bookingSearch.countryName);
    }

    if (bookingSearch?.postcode) {
      addressParts.push(bookingSearch.postcode);
    }

    return addressParts.join(', ');
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }

  private getBookingById(bookingId: string): void {
    this.bookingsService.getById(bookingId)
      .subscribe(bookings => {
        this.booking = bookings;
        this.generatedReferenceId = this.generateReferenceId();
        this.customerReferenceControl.setValue(!this.booking.customerReferenceId ?
          this.generatedReferenceId :
          this.booking.customerReferenceId);
      })

    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getById(bookingId)),
        tap((bookings) => {
          this.booking = bookings;
          this.generatedReferenceId = this.generateReferenceId();
          this.customerReferenceControl.setValue(!this.booking.customerReferenceId ?
            this.generatedReferenceId :
            this.booking.customerReferenceId);
        }),
        switchMap(() => this.getOrganizationById(this.booking.organizationId)),
        tap((x: Organization) => {
          this.isCreditOrganization = x.creditOrganization;
          this.organization = x;
        }),
        switchMap(() => this.bookingsService.getSummaryById(bookingId)))
      .subscribe((bookingSummary) => {
        this.bookingSummary = bookingSummary;
        this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData());
        this.createNotesForm(this.bookingSummary.notes[0]);
      }, () => { })
      .add(() => (this.isLoading = false));
  }

  private generateReferenceId(): string {
    const date = new Date();
    return `${this.booking.from}_${this.booking.to}_${this.datePipe.transform(date, 'dd')}_` +
      `${this.datePipe.transform(date, 'MM')}_${this.datePipe.transform(date, 'yyyy')}_` +
      `${this.datePipe.transform(date, 'hh')}_${this.datePipe.transform(date, 'mm')}`;
  }

  private getOrganizationById(organizationId: string): Observable<Organization | any> {
    return this.organizationsService.getOrganization(organizationId);
  }

  private createNotesForm(note?: Note): void {
    this.bookingCustomerNote = this.fb.group({
      id: [note ? note.id : null],
      bookingId: [note ? note.bookingId : ''],
      value: [note ? note.value : ''],
      createTimeUtc: [note ? note.createTimeUtc : ''],
      updateTimeUtc: [note ? note.updateTimeUtc : ''],
      noteAccessType: [note ? note.noteAccessType : BookingNoteAccessType.customer],
      userId: [note ? note.userId : null],
      userName: [note ? note.userName : '']
    });
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private subscribePaymentLoader(): void {
    this.fourtySeasPaymentService.paymentLoaderBehaviorSubject.subscribe((value: boolean | undefined) => {
      if (value !== undefined) {
        this.isSubmitLoading = !value;
      }
    })
  }

  private removeExistedFile(files: FileInfo[]): void {
    const filesToRemove = this.booking.documents.filter(x => !files.some(y => y.id === x.id));
    if (filesToRemove && filesToRemove.length > 0) {
      this.deleteFiles(filesToRemove);
    }
  }

  private deleteFiles(files: FileInfo[]): void {
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.booking.id, fileToRemove.id)
        .subscribe(data => {
          const index = this.booking.documents.findIndex(x => x.id === fileToRemove.id);
          if (index > -1) {
            this.booking.documents = this.booking.documents.filter(item => item.id !== fileToRemove.id);
          }
        });
    }
  }

  private addFiles(files: FileInfo[], allFiles: FileInfo[]): void {
    if (files && files.length > 0) {
      for (const fileToAdd of files) {
        this.bookingDocumentsService
          .add(this.booking.id, fileToAdd)
          .subscribe(x => this.finalizeFileLoading(fileToAdd, x, allFiles),
            (error) => {
              this.notificationService.notifyDialog(error.error, NotificationDialogType.error)
            });
      }
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any, allFiles: FileInfo[]): void {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round(100 * response.loaded / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.removeExistedFileFromFileArray(fileToAdd, allFiles);
      const existingDocument = this.booking.documents.find(x => x.id === fileToAdd.id);
      if (existingDocument === undefined) {
        this.booking.documents.push(addedFile);
      }
    }
  }

  private removeExistedFileFromFileArray(file: FileInfo, allFiles: FileInfo[]): void {
    const filesToRemove = allFiles.filter(x => file.name === x.name);
    if (filesToRemove && filesToRemove.length > 1) {
      const index = allFiles.indexOf(filesToRemove[0])
      allFiles.splice(index, 1);
    }
  }

  private hasOrganizationMissingData(): boolean {
    return this.organization?.legalName?.length <= 0
      || this.isInvalidTaxId(this.organization?.taxId ?? null);
  }

  private isInvalidTaxId(taxId: string): boolean {
    return !taxId || taxId.toLowerCase() == 'taxid';
  }

  private submitBooking(isCreditOrganization: boolean): void {
    this.booking.customerReferenceId = this.customerReferenceControl?.value;
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.updateBookingAndStatusAsync()),
        tap(() => {
          this.removeSpecificQuoteResultFromStorage();
        }),
        switchMap(() => this.createPaymentAndPaymentRequest(isCreditOrganization)),
        switchMap((paymentRequestId) => {
          return !isCreditOrganization ?
            this.fourtySeasPaymentService.init(paymentRequestId as string, this.userInfo?.teamMemberId, this.bookingSummary as Booking,
              PaymentStatusTransition.checkoutInitialized, "dashboard") :
            of(null);
        }))
      .subscribe(() => {
        if (isCreditOrganization) {
          this.navigateToDashboard();
        }
      },
        (error) => {
          if (error.error == "Payment not enabled.") {
            this.notificationService.error(error);
            this.navigateToDashboard();
          } else {
            this.googleAnalyticsService.submitBookingFailed();
            this.sendFunnelBookingError()
            this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
          }
        }).add(() => (this.isSubmitLoading = false));
  }

  private navigateToDashboard(): void {
    this.router.navigate(["/dashboard"]);
  }

  private openPopup(title: string, message: string, isSuccess: boolean): void {
    const url = document.location.href.replace(window.location.origin, "");

    if (isSuccess) {
      const successUrl = this.getUrl(true, url);
      this.location.go(successUrl);
    } else {
      const failedUrl = this.getUrl(false, url);
      this.location.go(failedUrl);
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {
        title: title,
        message: message,
        showConfirmButton: true,
        confirmButtonText: "Close"
      },
    }).afterClosed().subscribe(() => {
      if (isSuccess) {
        this.navigateToDashboard();
      }

      const combined = this.removeParamFromUrl(url);
      this.location.go(combined);
    });
  }

  private getUrl(isSuccess: boolean, url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];

    if (!isSuccess) {
      return firstPart + '&p=failed#' + secondPart;
    } else {
      return firstPart + '&p=submitted#' + secondPart;
    }
  }

  private removeParamFromUrl(url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];
    const combined = firstPart + '#' + secondPart;
    return combined;
  }

  private submitBookingWithoutFinance(): void {
    this.booking.customerReferenceId = this.customerReferenceControl?.value;
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.updateBookingAndStatusAsync()),
        tap(() => {
          this.removeSpecificQuoteResultFromStorage();
        }))
      .subscribe(() => { this.navigateToDashboard() },
        (error) => {
          this.googleAnalyticsService.submitBookingFailed();
          this.sendFunnelBookingError()
          this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
        }).add(() => (this.isSubmitLoading = false));
  }

  private updateBookingAndStatusAsync(): Observable<Booking | any> {
    if (this.booking.notes.length > 0) {
      this.booking.notes[0].userId = this.userInfo?.userId;
      this.booking.notes[0].userName = this.userInfo?.fullName;
    }
    return this.bookingsService.updateBookingAndStatus(this.booking.id, this.booking, true);
  }

  private createPaymentAndPaymentRequest(isCreditOrganization: boolean): Observable<any> {
    const payment = {
      organizationId: this.booking.organizationId,
      teamMemberId: this.userInfo?.teamMemberId
    } as Payment;

    const paymentRequest = {
      bookingId: this.booking.id,
      payment: payment
    } as PaymentRequest;

    return this.paymentsService.createPaymentAndPaymentRequest(paymentRequest, isCreditOrganization);
  }

  private sendFunnelBookingError(): void {
    this.googleAnalyticsService.errorFunnelBooking({
      actionName: "payment didn’t accept",
      fieldName: "Pay Now",
      errorValue: 'An error occurred while submitting your booking, try again.',
      shipmentType: this.booking.shipmentTypeCode
    })
  }

  private removeSpecificQuoteResultFromStorage(): void {
    var containers = [];
    var quoteQuery = {
      shipmentType: this.bookingSummary?.shipmentTypeCode,
      from: this.bookingSummary?.fromBookingSearch,
      to: this.bookingSummary?.toBookingSearch,
      fromDate: this.datePipe.transform(this.bookingSummary?.containers[0]?.collectionDate, 'yyyy-MM-dd'),
      isHazardous: this.bookingSummary?.bookingAdditionalInformation?.isHazardous
    } as QuotesQuery;

    if (this.bookingSummary?.shipmentTypeCode === ShipmentType.FCL) {
      this.bookingSummary?.containers?.forEach((container) => {
        if (!containers.find(x => x.equipmentCode === container.equipmentTypeCode)) {
          containers.push({
            equipmentCode: container.equipmentTypeCode,
            quantity: this.getContainerQuantity(container.equipmentTypeCode)
          } as QuotesEquipment);
        }
      })
      quoteQuery.equipments = containers;
    } else {
      this.bookingSummary?.containers?.forEach((container) => {
        container?.commodities?.forEach((commodity) => {
          containers.push({
            numberOfPackages: commodity.numberOfPackages,
            weightAmount: parseFloat(this.decimalPipe.transform(commodity.weight, '1.2-2').replace(',', '')),
            totalVolumeAmount: parseFloat(this.decimalPipe.transform(commodity.volume, '1.2-2')),
            volumeAmount: parseFloat(this.decimalPipe.transform(commodity.volume, '1.2-2')),
            weightUnit: commodity.weightUnitCode,
            volumeUnit: commodity.volumeUnitCode,
            height: commodity.dimension.height,
            width: commodity.dimension.width,
            length: commodity.dimension.length
          } as ContainerCommodity)
        })
      })
      quoteQuery.containerCommodities = containers;
    }

    const quoteStorageKey = this.quotesService.prepareKeyForQuoteStorage(quoteQuery);
    const oldQuotesResult = localStorage.getItem(quoteStorageKey);

    if (oldQuotesResult) {
      localStorage.removeItem(quoteStorageKey);
    }
  }

  private getContainerQuantity(equipmentTypeCode: String): Number {
    var matchEquipment = this.bookingSummary?.containers.filter(x => x.equipmentTypeCode === equipmentTypeCode);
    return matchEquipment.length;
  }

  private createSupportRequestDialogData(): SupportRequestDialogData {
    return {
      isPopupAutoOpened: false,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: this.booking.bolOrderNumber,
      quoteSearchId: this.booking.quoteSearchId
    } as SupportRequestDialogData
  }
}
