import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import * as moment from 'moment';
import { DepartureDateSettings, QuoteSearchParameters, ShipmentType } from '../../../../../shared/shared.model';
import { UtilityService } from '../../../../../shared/helper/utility.service';
import { DatepickerCustomHeaderComponent } from './datepicker-custom-header/datepicker-custom-header.component';
import { QuotesQuery } from '../../../quotes.model';
import { QuotesService } from '../../../quotes.service';
import { UserInfoService } from '../../../../../shared/services/user-info/user-info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-search-flow-cargo-date',
  templateUrl: './quote-search-flow-cargo-date.component.html',
  styleUrls: ['./quote-search-flow-cargo-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowCargoDateComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() shipmentType = ShipmentType.FCL;
  @Input() isOpened: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() hasError: boolean = false;
  @Input() quoteSearchParameter: QuoteSearchParameters | undefined;

  @Output() setSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() opened: EventEmitter<boolean> = new EventEmitter();
  @Output() next: EventEmitter<QuoteSearchParameters> = new EventEmitter();

  todayDate: Date = new Date();
  minSelectableDate: Date = new Date();
  maxSelectableDate: Date = null;
  departureDateSettings: DepartureDateSettings;

  customHeader = DatepickerCustomHeaderComponent;

  get getOverlayPosition(): ConnectedPosition[] {
    return [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: window.innerHeight <= 790 ? -176 : 0
      },
    ]
  }

  constructor(
    private quotesService: QuotesService,
    private utilityService: UtilityService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit(): void {
    this.getDepartureDateSettings();
    if (this.departureDateSettings) {
      this.setMinSelectableDate();
      this.setMaxSelectableDate();
    }

    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (quotesQueryString) {
      const quotesQuery = JSON.parse(quotesQueryString) as QuotesQuery;
      this.onSelectDate(new Date(quotesQuery.fromDate));
    }
    else {
      this.control.setValue(this.minSelectableDate);
    }

    this.subscribeActiveMenuOption();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpened?.currentValue === true) setTimeout(() => {
      this.isOpened = false;
      this.onOpenClick()
    }, 100);

    this.setMinSelectableDate();
    this.setMaxSelectableDate();

    if (changes.shipmentType) {
      this.control.setValue(this.minSelectableDate);
    }
  }

  onOpenClick(): void {
    this.isOpened = !this.isOpened;
    this.setOverlayVisibility();

    this.setSecondClickListener();
    this.quotesService.setActiveMenuOption(this.quoteSearchParameter);
  }

  onConfirm(): void {
    let value = this.control.value;

    if (moment.isMoment(this.control.value)) value = (this.control.value as moment.Moment).toDate()

    if (value.toDateString() === this.minSelectableDate.toDateString()) {
      this.onSelectDate(this.minSelectableDate);
    }
    if (this.isSelected) this.isOpened = false;
    this.setOverlayVisibility()
  }

  onSelectDate(value: any): void {
    this.control.setValue(value);
    this.isSelected = true;
    this.setSelected.next(this.isSelected);
    this.setSecondClickListener();
  }

  getTitle(): string {
    return 'Choose A Departure Date';
  }

  itIsNotLimitedDays = (d: any): boolean => {
    const date = new Date(d._d);
    const day = date.getDay();
    return this.shipmentType == ShipmentType.LCL ? (day !== 0 && day !== 6) : (day !== 0);
  }

  onClickedOutsideOverlay(event: MouseEvent): void {
    if (!this.utilityService.isEventTargetHasClass(event.target, "no-close")) {
      this.isOpened = false;
      this.setOverlayVisibility();
    }
  }

  private setMinSelectableDate(): void {
    this.minSelectableDate = new Date();
    this.minSelectableDate.setDate(this.todayDate.getDate() + this.getEnviromentMinAllowedShippingDate());
    if (this.minSelectableDate.getDay() == 0) {
      this.minSelectableDate.setDate(this.minSelectableDate.getDate() + 1);
    }
    else if (this.shipmentType == ShipmentType.LCL && this.minSelectableDate.getDay() == 6) {
      this.minSelectableDate.setDate(this.minSelectableDate.getDate() + 2);
    }
  }

  private setMaxSelectableDate(): void {
    this.maxSelectableDate = null;
    let enviromentMaxSelectableDate = this.getEnviromentMaxAllowedShippingDate();
    if (enviromentMaxSelectableDate != null) {
      this.maxSelectableDate = new Date();
      this.maxSelectableDate.setDate(this.todayDate.getDate() + enviromentMaxSelectableDate);
    }
  }

  private getEnviromentMinAllowedShippingDate(): number {
    switch (this.shipmentType) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMinAllowedDepartureDate ?? environment.air.minAllowedShippingDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMinAllowedDepartureDate ?? environment.fcl.minAllowedShippingDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMinAllowedDepartureDate ?? environment.lcl.minAllowedShippingDate);
      default:
        return 0;
    }
  }

  private getEnviromentMaxAllowedShippingDate(): number {
    switch (this.shipmentType) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMaxAllowedDepartureDate ?? environment.air.maxAllowedDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMaxAllowedDepartureDate ?? environment.fcl.maxAllowedDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMaxAllowedDepartureDate ?? environment.lcl.maxAllowedDate);
      default:
        return null;
    }
  }

  private setOverlayVisibility(): void {
    this.opened.emit(this.isOpened);
    document.getElementById("search-flow-overlay").style.visibility = this.isOpened ? 'visible' : 'hidden';
    document.getElementById("search-flow-overlay").style.opacity = this.isOpened ? '1' : '0';
  }

  private setSecondClickListener(): void {
    setTimeout(() => {
      let elem = document.querySelector('.mat-calendar-body-selected');
      elem?.addEventListener('click', () => {
        this.onConfirm();
      });
    }, 0);
  }

  private subscribeActiveMenuOption(): void {
    this.quotesService.activeMenuOption.subscribe((option: QuoteSearchParameters | null) => {
      if (option !== null) {
        if (this.quoteSearchParameter === QuoteSearchParameters.date && option !== QuoteSearchParameters.date) {
          this.isOpened = false;
        }
      }
    })
  }

  private getDepartureDateSettings(): void {
    let settings = this.userInfoService.getSettings();
    this.departureDateSettings = settings.departureDateSettings;
  }
  private convertToNumber(value: string | number | null | undefined): number | null {
    const num = value ? Number(value) : null;
    return isNaN(num) ? null : num;
  }
}
