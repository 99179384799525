<mat-sidenav-container *ngIf="isDashboardEnabled" [hidden]="isLoading" class="dashboard-container">
  <mat-sidenav #drawer mode="side" position="end" opened="false" class="dashboard-sidebar">
    <div class="row sidebar-sticky">
      <div class="col sidebar-title">
        <div>Widgets</div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-12 mt-3" *ngFor="let item of availableWidgets">
        <app-dashboard-widget [widget]="item" [preview]="true" draggable="true" id="{{ item.type }}"
          (dragstart)="onDrag($event)"></app-dashboard-widget>
        <div class="row mt-3">
          <div class="col">
            <button mat-stroked-button color="primary" class="sidebar-button" (click)="onPreview(item)">
              <mat-icon>visibility</mat-icon>
              Preview
            </button>
          </div>
          <div class="col">
            <button mat-stroked-button color="primary" class="sidebar-button" (click)="onAdd(item)">
              <mat-icon>add</mat-icon>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="dashboard-content container">
      <div *ngIf="isWidgetsEditingEnabled" class="edit-wrapper">
        <button mat-mini-fab class="edit-button" aria-label="Edit layout" (click)="onEdit()"
          [matTooltip]="isEdit ? 'Close' : 'Edit'" matTooltipPosition="above">
          <mat-icon>{{ isEdit ? "close" : "edit" }}</mat-icon>
        </button>
        <button *ngIf="isEdit" mat-mini-fab class="edit-button" aria-label="Save layout" (click)="onSave()"
          matTooltip="Save" matTooltipPosition="above">
          <mat-icon>save</mat-icon>
        </button>
        <button *ngIf="isEdit" mat-mini-fab class="edit-button" aria-label="Restore layout" (click)="onRestore()"
          matTooltip="Restore" matTooltipPosition="above">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="row">
        <div class="col">
          <gridster id="dashboard-grid" [options]="options" class="dashboard-grid" [ngClass]="{'is-not-edit': !isEdit}">
            <gridster-item #gridster [item]="item" *ngFor="let item of dashboardWidgets">
              <app-dashboard-widget [widget]="item" [editable]="isEdit" (remove)="onRemove($event)"
                (isEnabled)="onEnabled($event)"></app-dashboard-widget>
            </gridster-item>
          </gridster>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>