<mat-dialog-content tabindex="-1" class="rate-details-dialog-container-content">
  <div class="d-flex flex-column h-100">
    <div class="carriage-container new-design d-flex flex-column h-100 p-4 pe-0">
      <div class="row pe-4 g-0 m-0 mb-2 gap-2 header-title-subtitle-wrapper">
        <div class="col-12 detail-title m-0">Price Breakdown</div>
        <div class="col-12 detail-subtitle">
          The price below includes charges and fees for your cargo based on the selected origin and destination.
        </div>
      </div>
      <div class="rate-details-dialog-container-wrapper pe-4 overflow-y-auto">
        <div class="row g-0 m-0 mb-3">
          <div class="col-12">
            <mat-accordion>
              <div class="row gap-3 accordion-main-wrapper">
                <div *ngIf="isBadgeEnabled">
                  <app-quote-rate-detail-row-service [shipmentType]="shipmentType"
                    [quoteRateServiceType]="quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType"></app-quote-rate-detail-row-service>
                </div>
                <ng-container *ngFor="let item of rateDetails | keyvalue">
                  <div class="col-12" *ngIf="item.value.charges.length">
                    <mat-expansion-panel class="detail-expansion-panel"
                      [expanded]="isExpanded[rateDetailsPanels[item.key]]"
                      (opened)="onClickPanel(true, rateDetailsPanels[item.key])"
                      (closed)="onClickPanel(false, rateDetailsPanels[item.key])" hideToggle>
                      <mat-expansion-panel-header class="panel-header">
                        <mat-panel-title class="panel-title">
                          <div
                            class="panel-title-content-wrapper d-flex gap-2 justify-content-between align-items-center">
                            <div class="d-flex">
                              <div *ngIf="rateDetailsPanels[item.key] !== 'DANGEROUS'"
                                class="accordion-title d-flex text-capitalize align-items-center p-0">
                                {{(rateDetailsPanels[item.key] | titlecase) }} Charges
                              </div>
                              <div *ngIf="rateDetailsPanels[item.key] === 'DANGEROUS'"
                                class="accordion-title d-flex text-capitalize align-items-center p-0">
                                Dangerous Goods Battery surcharges
                              </div>
                            </div>
                            <div class="d-flex align-items-center gap-1 ms-auto">
                              <div class="accordion-title d-flex align-items-center p-0"
                                [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(item.value.totalAmount || 0, item.key)}">
                                {{ quote.trip.currency | currencyTextIcon }}{{ item.value.totalAmount ?
                                (item.value.totalAmount| number:'1.2-2') : "-.--" }}
                              </div>
                              <span appSetRoleBasedPrice class="estimated-subtitle discount-price accordion"
                                *ngIf="isShowReverseDiscountPrice(item.value.totalAmount || 0, item.key)">
                                {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(item.value.totalAmount
                                ||
                                0) |
                                number: "1.2-2" }}
                              </span>
                            </div>
                            <div>
                              <span class="panel-icon panel-header-icon-wrapper">
                                <i class="panel-header-icon s4d-icon s4d-info-light is-hover"
                                  [s4dTooltip]="item.value.tooltip" tooltipPosition="above"></i>
                              </span>
                            </div>
                            <div>
                              <span class="panel-icon panel-header-icon-wrapper">
                                <i *ngIf="!isExpanded[rateDetailsPanels[item.key]]"
                                  class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                                <i *ngIf="isExpanded[rateDetailsPanels[item.key]]"
                                  class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                              </span>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-template [ngTemplateOutlet]="charges"
                        [ngTemplateOutletContext]="{ item: item.value, chargesType: item.key }"></ng-template>
                    </mat-expansion-panel>
                  </div>
                </ng-container>
              </div>
            </mat-accordion>
          </div>
        </div>
        <div class="row m-0 py-3 justify-content-between">
          <div class="col-auto detail-subtitle submit">Total Cost</div>
          <div class="col-auto">
            <div appSetRoleBasedPrice class="detail-subtitle-value submit"
              [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(accumulatePrice)}">
              {{ quote.trip.currency | currencyTextIcon }}{{accumulatePrice | number:'1.2-2'}} {{(quote.currency |
              currencyName)}}
            </div>
            <div appSetRoleBasedPrice class="text-end estimated-subtitle discount-price submit"
              *ngIf="isShowReverseDiscountPrice(accumulatePrice || 0)">
              {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(accumulatePrice ||
              0) |
              number: "1.2-2" }}
            </div>
            <div class="text-end">
              <span class="detail-subtitle-description">The quoted price is protected for {{ priceValidHours
                }}{{ isBasicRateServiceType() ? 'm' : 'h' }}</span>
            </div>
          </div>
        </div>
        <div class="row mx-0 my-3">
          <div class="col">
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="row m-0 g-0 mb-4 inclusions-wrapper">
          <div class="col-12">
            <mat-expansion-panel class="inclusion-details-expansion-panel position-relative"
              [expanded]="isExpanded[rateDetailsPanels.INCLUSIONS]"
              (click)="onClickPanel(!isExpanded[rateDetailsPanels.INCLUSIONS], rateDetailsPanels.INCLUSIONS)"
              hideToggle>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  <div class="panel-title-content-wrapper d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <div class="accordion-title d-flex align-items-center p-0">Inclusions/exclusions</div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template [ngTemplateOutlet]="inclusionsExclusions"></ng-template>
              <div
                class="panel-show-details-overlay-wrapper position-absolute d-flex align-items-center justify-content-center"
                *ngIf="!isExpanded[rateDetailsPanels.INCLUSIONS]">
                <div class="show-details-text d-flex gap-2">
                  <div class="text-capitalize">
                    {{ !isExpanded[rateDetailsPanels.INCLUSIONS] ? 'Show More' : 'Show Less' }}
                  </div>
                  <span class="panel-icon-wrapper d-flex">
                    <i *ngIf="!isExpanded[rateDetailsPanels.INCLUSIONS]"
                      class="panel-icon s4d-icon s4d-chevron-down is-hover"></i>
                    <i *ngIf="isExpanded[rateDetailsPanels.INCLUSIONS]"
                      class="panel-icon s4d-icon s4d-chevron-up is-hover"></i>
                  </span>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
      <div class="rate-details-dialog-footer">
        <div class="row m-0 pt-4 pe-4">
          <div class="col-12 px-0">
            <button mat-raised-button class="dialog-button back-button mx-100" (click)="action(false)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #beingCalculated>
  <span class="being-calculated">Being calculated</span>
</ng-template>

<ng-template #charges let-item="item" let-chargesType="chargesType">
  <div class="row g-0 m-0 panel-content gap-3">
    <ng-container *ngFor="let charge of item.charges; let isFirst = first">
      <div class="col-12 d-flex justify-content-between">
        <div *ngIf="rateDetailsPanels[chargesType] !== 'DANGEROUS'" class="rate-subtitle">
          {{
          charge?.additionalType || charge?.description || (rateDetailsPanels[chargesType] | titlecase) + ' Charges'
          | titlecase
          }}
        </div>
        <div *ngIf="rateDetailsPanels[chargesType] === 'DANGEROUS'" class="rate-subtitle">
          {{
          charge?.additionalType || charge?.description || rateDetailsPanels[chargesType] + ' Charges'
          }}
        </div>
        <div class="d-flex align-items-center gap-1">
          <ng-container *ngIf="!charge?.isMissingRate; else beingCalculated">
            <span appSetRoleBasedPrice class="rate-subtitle rate-subtitle-value"
              [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(getChargeAmount(charge), chargesType)}">
              {{ quote.trip.currency | currencyTextIcon }}{{getChargeAmountToDisplay(charge)}}
            </span>
            <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
              *ngIf="isShowReverseDiscountPrice(getChargeAmount(charge), chargesType)">
              {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(getChargeAmount(charge)) |
              number: "1.2-2" }}
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template [ngTemplateOutlet]="cargoDetails" [ngTemplateOutletContext]="{ cargoDetails: charge?.cargoDetails }"
        *ngIf="charge?.cargoDetails"></ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #cargoDetails let-cargoDetails="cargoDetails">
  <ng-container *ngIf="shipmentType === ShipmentType.FCL">
    <div class="col-12 ps-3 d-flex justify-content-between" *ngFor="let equipment of cargoDetails | keyvalue">
      <div class="rate-subtitle">
        {{ equipment.key | titlecase }}
      </div>
      <div class="rate-subtitle rate-subtitle-value">x{{ equipment.value?.length }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="shipmentType !== ShipmentType.FCL && cargoDetails">
    <div class="col-12 ps-3 d-flex justify-content-between" *ngFor="let commodity of cargoDetails; let i = index">
      <div class="rate-subtitle">Load {{ i + 1 }}</div>
      <div class="rate-subtitle rate-subtitle-value">
        {{ getDimensions(commodity) }}
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #inclusionsExclusions>
  <ul class="ps-3 m-0">
    <ng-container *ngIf="shipmentType === ShipmentType.AIR">
      <li>
        The quote is valid from its issue date and for a maximum period of 48 hours from the time of its issuance.
      </li>
      <li>
        The quote is an estimate, dependent on the information provided by the Customer. The final shipment price may be
        adjusted based on the actual or cubic dimensional weight, as determined by the carrier, as well as any changes
        in the weight, whether volumetric or actual.
      </li>
      <li>
        Cargo requiring special handling such as, hazardous goods, perishable cargo, oversized cargo, and non-stackable
        cargo, may be subject to additional charges.
      </li>
      <li>
        Cargo pickup and/or delivery which requires after hours delivery, liftgate delivery, appointment delivery, hand
        load/unload, inside delivery, residential delivery, rush delivery, multiple stops, diversion miles, and
        deliveries requiring special equipment, may be subject to additional charges.
      </li>
      <li>
        Air freight shipments for Unknown Shippers as defined by the Transportation Security Administration may require
        additional charges.
      </li>
    </ng-container>
    <ng-container *ngIf="shipmentType !== ShipmentType.AIR">
      <li>
        The quote is valid from its issue date and for a maximum period of 72 hours from the time of its issuance.
      </li>
      <li>The quote is based on standard live load/unload (2 hours free for loading/unloading container).</li>
      <li>
        Transit times are estimated by the carriers, and may vary depending on sailing dates of the specific capacity,
        port/CFS congestion delay, carrier rolling, vessel change, chassis shortage or customs delays etc. Some areas in
        China are considered remote pickup addresses and may take an additional 2-3 days.
      </li>
      <li *ngIf="shipmentType === ShipmentType.LCL">
        An oversize surcharge will apply if any side of any unit in the shipment is above 200 cm.
      </li>
      <li *ngIf="shipmentType === ShipmentType.FCL && isDoorDelivery">
        Drayage rate remarks/additional charges:
        <ul>
          <li>Including 1 hr live load (for drop & pick there will be additional charges)</li>
          <li>$45 chassis per day (minimum 2 days)</li>
          <li>$95 driver detention per hour (1 hour free)</li>
          <li>$200 pre-pull (if required)</li>
          <li>$110 per chassis split</li>
          <li>In case of stage there will be extra costs of $60 yard stage per day</li>
          <li>Rate are restricted to legal weight limit. Weight above legal limit may result in additional fees</li>
          <li>
            Trucking issues: please note that we will try to use the most reliable trucking companies but we cannot
            guarantee their availability & work for it. There can be demurrage/detention charges added if applicable
          </li>
        </ul>
      </li>
      <li>85 USD Export License fee will be applied if applicable. (only for export)</li>
      <li>Sensitive and DG goods surcharge will be applied if applicable.</li>
      <li *ngIf="isDoorService">Storage/CFS charges rates are subject to that location’s terms, if applicable.</li>
      <li>
        The quote does not include: detention, per diem, demurrage, storage, port congestion fee or any requested
        additional services.
      </li>
      <li>
        This quotation is provided by the Company as an estimate, dependent on information provided by the Customer.
      </li>
      <li *ngIf="isDoorService">
        Cargo requiring special handling such as, hazardous goods, perishable cargo, oversized cargo, overweight
        containers, and non-stackable cargo, may be subject to approval and additional charges.
      </li>
      <li *ngIf="!isDoorService">
        Cargo requiring special handling such as, hazardous goods, perishable cargo, oversized cargo, overweight
        containers, and non-stackable cargo, may be subject to additional charges.
      </li>
      <li>
        Cargo pickup and/or delivery which requires after hours delivery, tailgate delivery, appointment delivery, hand
        load/unload, inside delivery, residential delivery, rush delivery, multiple stops, diversion miles, and
        deliveries requiring special equipment, may be subject to additional charges.
      </li>
    </ng-container>
    <li>
      Any taxes, including export and import customs duties, excise taxes, VAT, and other fees levied on the cargo being
      exported / imported; fees for the processing of letters of credit; & fees in connection with any legalization,
      notarization, or other certified attestation required, are all excluded from the quotation.
    </li>
    <li *ngIf="shipmentType === ShipmentType.AIR">
      There can be changes in cost due to space availability, cargo acceptance, and confirmation at time of booking.
    </li>
    <li *ngIf="shipmentType !== ShipmentType.AIR">
      There can be changes in cost due to empty equipment availability, space availability, cargo acceptance, and
      confirmation at time of booking may affect the final shipment price.
    </li>
    <li>
      At the Company’s discretions, this quotation will become void due to cargo that is mis-declared at the time of
      this quotation, and any costs / fines relating to the unsuitable nature of the cargo will borne by the customer.
    </li>
  </ul>
</ng-template>