<div class="quotes-detail-main-wrapper">
  <div class="quotes-containers">
    <ng-container *ngFor="let quote of quotes; let i = index">
      <div class="quotes-list">
        <ng-container [ngTemplateOutlet]="quoteRowTemplate" [ngTemplateOutletContext]="{quote, i}"></ng-container>
      </div>
      <app-quotes-banner class="d-block mb-4" *ngIf="isQuotesBannerEnabled && i === 0"></app-quotes-banner>
    </ng-container>
  </div>
</div>

<ng-template #quoteRowTemplate let-quote="quote" let-i="i">
  <app-quotes-row *ngIf="(quote.firstSearch && allFirstSearch && !isListLoading) || !quote.firstSearch" [quote]="quote"
    [quotesQuery]="quotesQuery" [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType" [index]="i"
    [selectedIndex]="selectedIndex" (onChangeSelection)="onChangeSelection($event)"
    (onChangeBadgeEnabled)="onChangeBadgeEnabled($event)" [specialServices]="getSpecialService(quote)"
    [quoteDetailsTemplate]="quoteRowRateDetailsTemplate" [quoteRowPriceTemplate]="quoteRowPriceTemplate">
  </app-quotes-row>
</ng-template>

<ng-template #quoteRowRateDetailsTemplate let-quote="quote">
  <ng-container *ngIf="quoteRowDetailsSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngSwitch]="quoteRowDetailsVariant.value">
    <ng-container *ngSwitchCase="quoteRowDetailsVariants.newRowDetailsStyle">
      <app-quote-rate-detail-row-redesigned *ngIf="quote.trip" [quote]="quote" [quotesQuery]="quotesQuery"
        [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType" [isBadgeEnabled]="isBadgeEnabled"
        [currentPanelName]="currentPanelName"
        (setPanelName)="setPanelName($event)"></app-quote-rate-detail-row-redesigned>
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="quoteRowRateDetailsTemplateDefault"
      [ngTemplateOutletContext]="{quote}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!quoteRowDetailsSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngTemplateOutlet]="quoteRowRateDetailsTemplateDefault" [ngTemplateOutletContext]="{quote}"></ng-container>
</ng-template>

<ng-template #quoteRowRateDetailsTemplateDefault let-quote="quote">
  <app-quote-rate-detail-row *ngIf="quote.trip" [quote]="quote" [quotesQuery]="quotesQuery"
    [showD2dAndAir]="showD2dAndAir" [shipmentType]="shipmentType"></app-quote-rate-detail-row>
</ng-template>

<ng-template #quoteRowPriceTemplate let-quote="quote" let-isShowFullNames="isShowFullNames">
  <ng-container *ngIf="quoteRowPriceSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngSwitch]="quoteRowPriceVariant?.value">
    <ng-container *ngSwitchCase="quoteRowPriceVariants.newRowPriceStyle">
      <app-quote-row-price-redesigned [quote]="quote"></app-quote-row-price-redesigned>
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="quoteRowPriceTemplateDefault"
      [ngTemplateOutletContext]="{quote}">
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!quoteRowPriceSupportedShipmentTypes.includes(quotesQuery.shipmentType)"
    [ngTemplateOutlet]="quoteRowPriceTemplateDefault" [ngTemplateOutletContext]="{quote}">
  </ng-container>
</ng-template>

<ng-template #quoteRowPriceTemplateDefault let-quote="quote" let-isShowFullNames="isShowFullNames">
  <app-quote-row-price [quote]="quote" [isShowFullNames]="isShowFullNames"></app-quote-row-price>
</ng-template>
