<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="booking-flow" [hidden]="isLoading" [formGroup]="bookingForm" *ngIf="bookingForm">
  <ng-container *ngIf="!isManageContacts">
    <div class="booking-flow-content-wrapper">
      <div class="booking-flow-content">
        <div class="bookexpert-icon-text-wrapper" (click)="openSupportDialog()">
          <div class="expert-icon-wrapper">
            <i class="s4d-icon s4d-support"></i>
          </div>
          <span class="bookexpert-text">Book with an expert</span>
        </div>
        <mat-accordion class="row m-0">
          <div class="col-12 px-0">
            <mat-expansion-panel class="booking-expansion-panel" [expanded]="isExpanded[bookingFlowPanels.cargoDetails]"
              (opened)="onClickPanel(true, bookingFlowPanels.cargoDetails)"
              (closed)="onClickPanel(false, bookingFlowPanels.cargoDetails)" hideToggle>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  <div class="row w-100 m-0">
                    <div class="col px-0">Cargo Details</div>
                    <div class="col-auto d-flex align-items-center pe-0">
                      <span class="panel-header-icon-wrapper" [ngClass]="{
                                        interactive:
                                          isSuccess[bookingFlowPanels.cargoDetails] ||
                                          isError[bookingFlowPanels.cargoDetails] ||
                                          isProgress[bookingFlowPanels.cargoDetails]
                                      }">
                        <i *ngIf="isShowSuccessIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-check success"></i>
                        <i *ngIf="isShowErrorIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-info-light close"></i>
                        <i *ngIf="isShowProgressIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                        <i *ngIf=" isShowCevronDownHoverIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                        <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                          matTooltip="Mandatory fields are missing"></i>
                        <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.cargoDetails)"
                          class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                      </span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <app-booking-flow-cargo-details *ngIf="booking" [booking]="booking"
                  [containers]="bookingForm.get('containers')" [shipmentTypeCode]="shipmentTypeCode"
                  (addCommodity)="onAddCommodity($event)" (removeCommodity)="onDeleteCommodity($event)"
                  (copyContainerDetails)="onCopyContainerDetails($event)" (setState)="onSetState($event)"
                  (next)="onNext($event)" [bookingSummary]="bookingSummary"
                  (isBookingAdditionalServiceUpdated)="onBookingAdditionalServiceUpdated($event)"></app-booking-flow-cargo-details>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="col-12 px-0 mt-4">
            <mat-expansion-panel class="booking-expansion-panel" [expanded]="isExpanded[bookingFlowPanels.contacts] &&
                    isSuccess[bookingFlowPanels.cargoDetails]" [disabled]="!isSuccess[bookingFlowPanels.cargoDetails]"
              (opened)="onClickPanel(true, bookingFlowPanels.contacts)"
              (closed)="onClickPanel(false, bookingFlowPanels.contacts)" hideToggle>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  <div class="row w-100 m-0">
                    <div class="col px-0">Contact Details</div>
                    <div class="col-auto d-flex align-items-center pe-0">
                      <span class="panel-header-icon-wrapper" [ngClass]="{
                                interactive:
                                  (isSuccess[bookingFlowPanels.contacts] ||
                                  isError[bookingFlowPanels.contacts] ||
                                  isProgress[bookingFlowPanels.contacts]) &&
                                  isSuccess[bookingFlowPanels.cargoDetails]
                              }">
                        <i *ngIf="isShowSuccessIcon(bookingFlowPanels.contacts) &&
                                  isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-check success"></i>
                        <i *ngIf="isShowErrorIcon(bookingFlowPanels.contacts) &&
                                isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-info-light close"></i>
                        <i *ngIf="isShowProgressIcon(bookingFlowPanels.contacts) &&
                                isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                        <i *ngIf="isShowCevronDownHoverIcon(bookingFlowPanels.contacts) &&
                                isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                        <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.contacts) &&
                                isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                          matTooltip="Mandatory fields are missing"></i>
                        <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.contacts) &&
                                isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        <i *ngIf="!isSuccess[bookingFlowPanels.cargoDetails]"
                          class="panel-header-icon s4d-icon s4d-lock"></i>
                      </span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content mt-0">
                <app-booking-flow-contacts *ngIf="booking" [booking]="booking" [shipperContact]="shipperContact"
                  [consigneeContact]="consigneeContact" [notifyPartyContact]="notifyPartyContact"
                  (selectedContact)="onSelectedContact($event)" (unselectedContact)="onUnselectedContact($event)"
                  (isLoading)="onLoading($event)" (setState)="onSetState($event)"
                  (manageContacts)="onManageContacts($event)" (next)="onNext($event)"></app-booking-flow-contacts>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="col-12 px-0 mt-4" *ngIf="isAdditionalServicesEnabled" [style.display]="displayAdditionalServices">
            <mat-expansion-panel class="booking-expansion-panel" [expanded]="isExpanded[bookingFlowPanels.services] &&
            isSuccess[bookingFlowPanels.cargoDetails] &&
            isSuccess[bookingFlowPanels.contacts]"
              [disabled]="!isSuccess[bookingFlowPanels.cargoDetails] || !isSuccess[bookingFlowPanels.contacts]"
              (opened)="onClickPanel(true, bookingFlowPanels.services)"
              (closed)="onClickPanel(false, bookingFlowPanels.services)" hideToggle>
              <mat-expansion-panel-header class="panel-header">
                <mat-panel-title class="panel-title">
                  <div class="row w-100 m-0">
                    <div class="col px-0">Additional Services</div>
                    <div class="col-auto d-flex align-items-center pe-0">
                      <span class="panel-header-icon-wrapper" [ngClass]="{
                                interactive:
                                (isSuccess[bookingFlowPanels.services] ||
                              isError[bookingFlowPanels.services] ||
                              isProgress[bookingFlowPanels.services])  &&
                              isSuccess[bookingFlowPanels.cargoDetails] &&
                              isSuccess[bookingFlowPanels.contacts]
                              }">
                        <i *ngIf="isShowSuccessIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-check success"></i>
                        <i *ngIf="isShowErrorIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-info-light close"></i>
                        <i *ngIf="isShowProgressIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-refresh loading"></i>
                        <i *ngIf=" isShowCevronDownHoverIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                        <i *ngIf="isShowErrorHoverIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-info-light close is-hover"
                          matTooltip="Mandatory fields are missing"></i>
                        <i *ngIf="isShowCevronUpHoverIcon(bookingFlowPanels.services) &&
                        isSuccess[bookingFlowPanels.cargoDetails] &&
                          isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        <i *ngIf="!isSuccess[bookingFlowPanels.cargoDetails] || !isSuccess[bookingFlowPanels.contacts]"
                          class="panel-header-icon s4d-icon s4d-lock"></i>
                      </span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <app-booking-flow-services [booking]="booking" [selectedAdditionalService]="selectedAdditionalService"
                  [isCreditOrganization]="isCreditOrganization" (isCostEstimator)="onCostEstimator($event)"
                  (hasAdditionalServices)="onUpdateHasAdditionalServices($event)"
                  (isBookingAdditionalServiceUpdated)="onBookingAdditionalServiceUpdated($event)"
                  (isLoading)="onLoading($event)" (setState)="onSetState($event)" (next)="onNext($event)"
                  [bookingSummary]="bookingSummary" [organization]="organization"></app-booking-flow-services>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
    </div>
    <div class="booking-flow-footer-wrapper">
      <div class="booking-flow-footer">
        <app-booking-flow-details *ngIf="bookingSummary && this.organization" [isValid]="isBookingValid"
          [bookingSummary]="bookingSummary" [isCreditOrganization]="isCreditOrganization"
          (onSubmitDraft)="onSaveDraft()" [isManualExpand]="isManualExpand"
          [isSubmitLoading]="isSubmitLoading"></app-booking-flow-details>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isManageContacts">
    <app-flow-contacts-manage class="d-flex h-100" [booking]="booking" [contactType]="contactType"
      (organizationContactandAction)="onOrganizationContactandAction($event)" (backToBooking)="onBack()"
      (selectedContact)="onSelectedContact($event)" (isOpenNewContact)="onOpenNewContact($event)"
      (isLoading)="onLoading($event)"></app-flow-contacts-manage>
  </ng-container>
</div>