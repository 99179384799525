<div class="rollup-dialog-overlay {{_class}}" [ngClass]="{'no-dark-background': isMultipleDialogsOpened}"
  (click)="onOverlayClick($event)">
  <div class="rollup-dialog-container" [@slideFromBottom]="animationState" (swipedown)="onSwipe()">
    <div class="rollup-dialog-header">
      <div class="header-indicator"></div>
    </div>

    <div class="rollup-dialog-content">
      <ng-template #target></ng-template>
    </div>

    <div class="rollup-dialog-footer">
      <ng-container select=".dialog-footer"></ng-container>
    </div>
  </div>
</div>