<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="eligible-credit-line-wrapper">
  <div class="eligible-credit-line-container">
    <div class="eligible-credit-line-image">
      <span class="is-rocket-icon"></span>
    </div>
    <div class="eligible-credit-limit-content">
      <h3 class="eligible-credit-limit-eligible-title">
        You are eligible to the get
        <span class="eligible-credit-limit-title-text">$10,000</span> credit
        line
      </h3>
      <p class="eligible-credit-limit-content">
        If you're in need of a financial boost, you'll be pleased to know that
        you're now eligible for a credit line of $10,000.
      </p>
      <div class="eligible-credit-limit-eligible-content">
        <a class="eligible-credit-limit-eligible-term">How to be eligible?</a>
        <div class="tootltip-content">
          <ol class="tootltip-content-list">
            <li class="tootltip-content-list-item">
              You will need to connect your bank account at payout
            </li>
            <li class="tootltip-content-list-item">
              You have positive balance
            </li>
            <li class="tootltip-content-list-item">
              Your monthly revenue is more than 10,000 USD
            </li>
          </ol>
        </div>
      </div>
      <button type="button" class="eligible-credit-limit-claim" (click)="claimCreditLine()">
        Claim Now
      </button>
    </div>
  </div>
</div>