import { Pipe, PipeTransform } from '@angular/core';
import { LocationType } from '../shared.model';

@Pipe({ name: 'locationTypeIcon' })
export class LocationTypeIconPipe implements PipeTransform {
  private locationTypeIcons = {};

  constructor() {
    this.locationTypeIcons[LocationType.townCity] = 'emoji_transportation';
    this.locationTypeIcons[LocationType.airPort] = 'local_airport';
    this.locationTypeIcons[LocationType.seaPort] = 'directions_boat';
    this.locationTypeIcons[LocationType.railwayTerminal] = 'directions_railway';
    this.locationTypeIcons[LocationType.containerTerminal] = 'terminal_port';
    this.locationTypeIcons[LocationType.inlandPort] = 'store_mall_directory';
    this.locationTypeIcons[LocationType.industrialZone] = 'location_city';
    this.locationTypeIcons[LocationType.busStation] = 'directions_bus';
    this.locationTypeIcons[LocationType.island] = 'landscape';
    this.locationTypeIcons[LocationType.heliport] = 'add_road';
    this.locationTypeIcons[LocationType.dryPort] = 'local_shipping';
    this.locationTypeIcons[LocationType.administrativeDivision] = 'admin_panel_settings';
    this.locationTypeIcons[LocationType.other] = 'dynamic_feed';
  }

  transform(value?: LocationType): string {
    return this.locationTypeIcons[value] ?? '';
  }
}
