<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="requested-credit-line-wrapper">
  <div class="requested-credit-line-container">
    <div class="requested-credit-line-image">
      <img src="../../../../assets/images/credit-line/credit.svg" alt="" />
    </div>
    <div class="requested-credit-limit-content">
      <h3 class="requested-credit-limit-eligible-title">
        Your Credit Application Is Being Processed
      </h3>
      <p class="requested-credit-limit-content">
        Your credit application is in review. Your will get an answer soon.
      </p>
    </div>
  </div>
</div>