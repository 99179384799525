import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { BookingsService } from '../../shared/bookings/bookings.service';
import { Booking, BookingFile } from '../../shared/bookings/bookings.model';
import { BookingFilesDocumentsPage, BookingViewDetailsPage, ShipmentType } from '../../shared/shared.model';
import { map, startWith } from 'rxjs/operators';
import { LayoutService } from '../layout/layout.service';
import { LayoutMode } from '../layout/layout.model';

@Component({
  selector: 'app-booking-files-documents',
  templateUrl: './booking-files-documents.component.html',
  styleUrls: ['./booking-files-documents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingFilesDocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
  bookingId: string;
  filesDocumentsPageType: BookingFilesDocumentsPage;
  viewDetailsPageType: BookingViewDetailsPage;
  bookingFilesDocumentsPage = BookingFilesDocumentsPage;
  booking: Booking;
  isLoading = false;
  documentNameControl: FormControl = new FormControl();
  bookingDocuments: BookingFile[] = []
  filteredOptions: Observable<BookingFile[]>;

  @ViewChild('header') header: TemplateRef<ElementRef>;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private layoutService: LayoutService,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private location: Location
  ) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params.id;
    this.filesDocumentsPageType = Number(this.route.snapshot.params.filesPageType) as BookingFilesDocumentsPage;
    this.viewDetailsPageType = Number(this.route.snapshot.params.pageType) as BookingViewDetailsPage;
    this.getBookingById();
  }

  ngOnDestroy(): void {
    this.layoutService.setWrapperHeader();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setDefault();
      this.layoutService.setToolbarTitle("Files and Documents");
      this.layoutService.setToolbarShowUser(false);
      this.layoutService.setToolbarShowHome(true);
      this.layoutService.setWrapperHeader(this.header);
    }, 0);
  }

  onBack() {
    this.navigateToPage();
  }

  onAddedDocumentEvent(newDocument: BookingFile) {
    let existingBooking = { ...this.booking };
    let documents = existingBooking.documents;
    if (documents === undefined || documents === null) {
      documents = [];
    }
    documents.push(newDocument);
    existingBooking.documents = [...documents];
    this.booking = { ...existingBooking };
    this.setBookingDocumentsList();
  }

  displayFn(bookingDocument: BookingFile): string {
    return bookingDocument && bookingDocument.name ? bookingDocument.name : '';
  }

  onSelectDocument(): void {
    const documentId = this.documentNameControl.value.id;
    const bookingDocument = this.booking.documents.find(x => x.id === documentId);
    this.bookingDocuments = [bookingDocument];
  }

  onChangeText(): void {
    if (!this.documentNameControl.value) {
      this.setBookingDocumentsList();
    }
  }

  clearSearchField(): void {
    this.documentNameControl.reset();
    this.setBookingDocumentsList();
  }

  getReturnToPageText(): string {
    switch (this.filesDocumentsPageType) {
      case BookingFilesDocumentsPage.finance:
        return 'Finance';
      case BookingFilesDocumentsPage.dashboard:
        return 'Dashboard';
      case BookingFilesDocumentsPage.orderDetails:
        return 'Order details';
      case BookingFilesDocumentsPage.creditLine:
        return 'Credit Line';
      default:
        return null;
    }
  }

  private setBookingDocumentsFiltered(): void {
    this.filteredOptions = this.documentNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterDocuments(name as string) : this.booking.documents?.slice();
      }),
    );
  }

  private filterDocuments(documentName: string): BookingFile[] {
    const filterValue = documentName.toLowerCase();
    return this.booking.documents?.filter(x => x.name.toLowerCase().includes(filterValue));
  }

  private setBookingDocumentsList(): void {
    this.bookingDocuments = this.booking?.documents;
    this.setBookingDocumentsFiltered();
  }

  private getBookingById() {
    this.isLoading = true;
    this.bookingsService
      .getById(this.bookingId)
      .subscribe(
        (x: Booking) => {
          this.booking = x;
          this.setBookingDocumentsList();

          this.layoutService.setToolbarDescription(`ORDER NUMBER: ${this.booking.bolOrderNumber ?? 'n/a'}`);

          if (this.booking.shipmentTypeCode === ShipmentType.AIR) {
            this.layoutService.setMode(LayoutMode.AIR);
          } else if (this.booking.shipmentTypeCode === ShipmentType.LCL) {
            this.layoutService.setMode(LayoutMode.LCL);
          } else {
            this.layoutService.setMode(LayoutMode.FCL);
          }
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading = false));
  }

  private navigateToPage(): void {
    switch (this.filesDocumentsPageType) {
      case BookingFilesDocumentsPage.finance:
        window.location.href = '/finance';
        break;
      case BookingFilesDocumentsPage.dashboard:
        window.location.href = '/dashboard';
        break;
      case BookingFilesDocumentsPage.orderDetails:
        window.location.href = `/booking/view/${this.viewDetailsPageType}/${this.bookingId}`;
        break;
      case BookingFilesDocumentsPage.creditLine:
        window.location.href = '/creditLine';
        break;
      default:
        break;
    }
  }
}
