import { Pipe, PipeTransform } from "@angular/core";
import { ErrorMessages } from "../shared.model";

@Pipe({ name: 'errorMessage' })
export class ErrorMessagePipe implements PipeTransform {
  private errorMessages = {};

  constructor() {
    this.errorMessages[ErrorMessages[ErrorMessages.required]] = '{This field} is required.';
    this.errorMessages[ErrorMessages[ErrorMessages.minLength]] = '{The input} is too short.';
    this.errorMessages[ErrorMessages[ErrorMessages.maxLength]] = '{The input} is too long.';
    this.errorMessages[ErrorMessages[ErrorMessages.max]] = '{The value} exceeds maximum.';
    this.errorMessages[ErrorMessages[ErrorMessages.min]] = '{The value} is below minimum.';
    this.errorMessages[ErrorMessages[ErrorMessages.pattern]] = 'Invalid format. Please follow the specified pattern';
  }

  transform(value?: ErrorMessages, placeholder?: string): string {
    let errorMessage = this.errorMessages[this.normalizeValue(value)] ?? null;
    if (errorMessage == null) return errorMessage;

    if (placeholder) {
      errorMessage = errorMessage.replace(/{[^}]+}/g, '');
      errorMessage = `${placeholder} ${errorMessage}`;
    } else {
      errorMessage = errorMessage.replace(/{|}/g, '');
    }

    return errorMessage;
  }

  private normalizeValue(key: ErrorMessages | string): string {
    if (typeof key == 'string') return key;
    return ErrorMessages[key];
  }
}