<div *ngIf="isLoading; else loaded" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
  <div class="payment-page-wrapper">
    <div class="payment-page-header">
      <div class="row">
        <div class="col ps-3">
          <div class="title bold">Request</div>
          <div class="reason bold">{{ requestAndPayment?.reasonDisplayName }}</div>

          <div class="row status">
            <div class="col-1">
              <div class="status-rectangle" [ngClass]="getPaymentStatusColor(requestAndPayment?.statusCode)"></div>
            </div>
            <div class="col-11">
              <div class="status-title">
                {{ requestAndPayment?.statusDisplayName }}
              </div>
              <div class="status-title pt-2">
                {{ requestAndPayment?.externalStatus }}
              </div>
            </div>
          </div>

          <div class="square">
            <div class="title bold">Details</div>
            <div class="row inner-row">
              <div class="col-5 inner-element">TransactionId</div>
              <div class="col-7 value">{{ requestAndPayment?.paymentNumber }}</div>
            </div>
            <div class="row inner-row">
              <div class="col-5 inner-element">Pay Due</div>
              <div class="col-7 value">
                {{ requestAndPayment?.dueDate | date }}
              </div>
            </div>
            <div class="row inner-row">
              <div class="col-5 inner-element">Total Amount</div>
              <div class="col-7 value d-flex align-center justify-content-end">
                <mat-icon class="currency-icon">
                  {{ (requestAndPayment?.currency ?? currencies.USD) | currencyIcon }}
                </mat-icon>
                <span>{{ roundAmount(requestAndPayment?.amount + requestAndPayment?.fees) }}</span>
              </div>
            </div>
            <div class="row inner-row">
              <div class="col-5 inner-element">Updated</div>
              <div class="col-7 value">
                {{ requestAndPayment?.updateTimeUtc | date }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-navbar>
    <div class="col px-3 align-self-center booking-steps-button-wrapper">
      <div class="back-continue-button-wrapper">
        <div class="continue-wrapper">
          <button mat-raised-button class="next-button button-spinner"
            *ngIf="showPayOrder(requestAndPayment.statusCode)" (click)="on40seasPayment(requestAndPayment)">
            Pay
          </button>
          <button mat-raised-button class="next-button button-spinner"
            *ngIf="showConfirmButton(requestAndPayment.statusCode)" (click)="onConfirm(requestAndPayment.paymentId)">
            Upload
          </button>
        </div>
      </div>
    </div>
  </app-navbar>
</ng-template>

<ng-template #moreMenu>
  <ng-container *ngIf="requestAndPayment">
      <btn *ngIf="showChangePaymentMethodButton(requestAndPayment.statusCode)" mat-menu-item class="btn-profile change-payment"
      (click)="onChangePaymentMethod(requestAndPayment)">
      <span>Change Payment Method</span>
    </btn>
    <btn *ngIf="showBankDetailsButton(requestAndPayment.statusCode)" mat-menu-item class="btn-profile bank-icon"
      (click)="onShowDetailsMethod(requestAndPayment)">
      <span>Show Bank Details</span>
    </btn>
    <btn mat-menu-item class="btn-profile files-docs-icon" (click)="onFilesDocuments(bookingId)">
      <span>Files & Documents</span>
    </btn>
  </ng-container>
</ng-template>

