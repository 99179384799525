import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Booking } from '../../../../shared/bookings/bookings.model';
import { CreditLine } from '../../../../shared/payments/payments.model';
import { BookingFilesDocumentsPage, BookingPaidStatusCode, BookingViewDetailsPage, ShipmentType } from '../../../../shared/shared.model';

@Component({
  selector: 'app-order-list-grid-view-finance',
  templateUrl: './order-list-grid-view-finance.component.html',
  styleUrls: ['./order-list-grid-view-finance.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', opacity: '0', padding: '0rem' })),
      state('expanded', style({ height: 'auto', padding: '1rem' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),],
  encapsulation: ViewEncapsulation.None
})
export class OrderListGridViewFinanceComponent {
  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  @Input()
  dataSource: MatTableDataSource<Booking>;
  @Input()
  sortParameters: Array<any>;
  @Input()
  isLoading: boolean = false;
  @Input() creditLine: CreditLine = null;
  @Input() isCreditLine: boolean = false;

  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  @Output() sortBookingEvent: EventEmitter<MatSort> = new EventEmitter();
  @Output() exportBookingsEvent: EventEmitter<string> = new EventEmitter();

  public columns = [
    'icon',
    'bolOrderNumber',
    'masterBillOfLading',
    'from',
    'to',
    'atd',
    'updateTimeUtc',
    'bookingPaidStatusCode',
    'action',
  ];

  paidStatuses = BookingPaidStatusCode;

  expandedElement: Booking | null;

  get isEmptyList(): boolean {
    return this.dataSource.data.length < 1
  }

  constructor(private router: Router) { }

  getMainCarriageIcon(booking: Booking): string {
    switch (booking.shipmentTypeCode) {
      case ShipmentType.AIR:
        return 'local_airport';

      default:
        return 'directions_boat';
    }
  }

  onSort(): void {
    this.sortBookingEvent.emit(this.sort);
  }

  onFilesDocuments(bookingId: string): void {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.finance, BookingFilesDocumentsPage.finance, bookingId]);
  }

  exportBookingsCsv(): void {
    this.exportBookingsEvent.emit();
  }

  getIconColor(statusCode: BookingPaidStatusCode): any {
    return statusCode === BookingPaidStatusCode.paid ? true : statusCode === BookingPaidStatusCode.unPaid ? false : null;
  }

  onShowDetails(item: Booking): void {
    this.expandedElement = this.expandedElement === item ? null : item;
  }

  navigateToBookingDetails(booking: Booking): void {
    this.router.navigate(['/booking/view', BookingViewDetailsPage.finance, booking.id]);
  }

  onQuoteSearch(): void {
    this.router.navigate(['/quote-search'])
  }
}
