<div *ngIf="document">
  <div class="document-comments-container">
    <div class="document-comments-header">
      <div class="row mb-3 justify-content-between">
        <div class="col d-flex align-items-center">
          <h4 class="document-comments-title">Comments</h4>
        </div>
        <div class="col-auto d-flex align-items-center">
          <button mat-icon-button class="close-button" (click)="onCancel()" matTooltip="Close"></button>
        </div>
      </div>
    </div>
    <div class="document-comments-content-container">
      <div class="row">
        <div class="col">
          <div class="row document-comment-row" *ngFor="let comment of document.comments">
            <div class="col">
              <div class="document-comment-content">
                <div class="row">
                  <div class="col">
                    <div class="document-comment-name-content">
                      <span class="comment-name">
                        {{ comment.userName | titlecase }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="document-comment-date-content">
                      <span class="comment-date">
                        {{ comment.createTimeUtc | date : "dd MMM, yyyy, hh:mm a" }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="document-comment-bottom-content">
                      <span class="comment-text">{{ comment.comment }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-document-comment-wrapper">
      <div class="row">
        <div class="col-9 comment-input-text">
          <div class="comment-field w-100" [ngClass]="{
              'invalid-error-box':
                !commentControl?.value && commentControl?.touched
            }">
            <mat-form-field floatLabel="never" class="w-100">
              <input matInput placeholder="Type your comments" maxlength="2000" [formControl]="commentControl" />
            </mat-form-field>
          </div>
        </div>
        <div class="col-3 ps-0 comment-submit-button">
          <button mat-raised-button class="next-button send-button button-spinner" (click)="onSendComment()">
            <span class="button-inner">
              Send
              <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>