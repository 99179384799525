import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  ContainerCommodity,
  ExtenderTrip,
  ExtenderTripLegBase,
  ExtenderTripRate,
  Quote,
  QuotesQuery
} from '../../../quotes.model';
import {
  CarriageStatusType,
  LocationType,
  RateServiceType,
  ShipmentType,
  VolumeUnit,
  WeightUnit
} from '../../../../../shared/shared.model';
import { DimensionUnitNamePipe } from '../../../../../shared/pipes/dimension-unit-name.pipe';
import { VolumeUnitNamePipe } from '../../../../../shared/pipes/volume-unit-name.pipe';
import { RateDetailsDialogComponent } from '../../../quote-search-flow/rate-details/rate-details-dialog.component'
import { RateDetailPanels } from './quote-rate-detail-row.model';
import { UtilityService } from '../../../../../shared/helper/utility.service';
import { QuotePriceTiersComparisonComponent } from '../../quote-row-variants/quote-price-tiers-comparison/quote-price-tiers-comparison.component';

@Component({
  selector: 'app-quote-rate-detail-row-redesigned',
  templateUrl: './quote-rate-detail-row.component.html',
  styleUrls: ['./quote-rate-detail-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteRateDetailRowRedesignedComponent implements OnInit {
  @Input() quote: Quote;
  @Input() shipmentType: ShipmentType;
  @Input() showD2dAndAir: boolean;
  @Input() quotesQuery: QuotesQuery;
  @Input() isBadgeEnabled: boolean;
  @Input() currentPanelName: string;
  @Output() setPanelName = new EventEmitter<string>();
  CarriageStatusType = CarriageStatusType;
  ShipmentType = ShipmentType;
  grandTotalWeightAmount = 0;
  grandTotalVolumeAmount = 0;
  grandTotalChargeableWeight = 0;
  grandTotalWeightUnit = WeightUnit.KG;
  grandTotalVolumeUnit = VolumeUnit.CBM;
  showDetails = false;
  preCarriageTotal = null;
  mainCarriageTotal = null;
  postCarriageTotal = null;
  carriageTripLegs = [];
  lastLegCarriage = null;
  isExpanded: boolean[] = [false, false, false, false, false];
  rateDetailsPanels = RateDetailPanels;
  rateServiceType = RateServiceType;
  isShowTooltip: boolean = false;
  rateDetails = {
    [RateDetailPanels.FREIGHT]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Freight charges include the carrier's base rate in addition to
      other charges and fees, as seen in the quote breakdown. Freight
      charges are calculated based on your search criteria, such as
      cargo details, selected origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.DESTINATION]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Destination charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.ORIGIN]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Origin charges are calculated
      based on your search criteria, such
      as cargo details, selected origin
      and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.OTHER]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Additional charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
    [RateDetailPanels.DANGEROUS]: {
      totalAmount: 0,
      charges: [],
      tooltip: `Dangerous charges below are
      calculated based on your search
      criteria, such as cargo details, selected
      origin and destination, among others. <a href="https://ship4wd.com/support-category/shipping-quotes#support-5995" class="link" target="_blank">Learn more</a>`
    },
  }

  get priceValidHours(): number {
    return this.quotesQuery.shipmentType == ShipmentType.AIR
      ? 48
      : (this.isBasicRateServiceType() ? 30 : 72);
  }

  get accumulatePrice(): number {
    return this.quote?.accumulatePrice;
  }

  get isDoorService(): boolean {
    return this.quote.trip?.fromLocationTypeCode == LocationType.townCity || this.isDoorDelivery
  }

  get isDoorDelivery(): boolean {
    return this.quote.trip?.toLocationTypeCode == LocationType.townCity
  }

  constructor(public dialog: MatDialog,
    public datePipe: DatePipe,
    private readonly sso: ScrollStrategyOptions,
    private dimensionUnitNamePipe: DimensionUnitNamePipe,
    private volumeUnitNamePipe: VolumeUnitNamePipe,
    private decimalPipe: DecimalPipe,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.carriageTripLegs = this.getCarriageWiseTripLeg(this.quote.trip);
    this.getRateDetails();
    this.lastLegCarriage = this.carriageTripLegs[this.carriageTripLegs.length - 1]?.carriageType;

    if (this.shipmentType !== ShipmentType.FCL) {
      this.totalLoadCalculation();
    }

    this.updatePanel();
  }

  getTotalFreightRate(rates: ExtenderTripRate[]) {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.freightRate?.shipmentRate.totalAmount || 0;
    }, 0);
  }

  getRatesByApplicableType(applicableType: string, rates: ExtenderTripRate[]) {
    return rates.reduce((accumulator, current) => {
      return accumulator + current.surcharges.reduce((accumulator, charge) => {
        if (charge.applicableType == applicableType) {
          return accumulator + charge.shipmentRate.amount
        }
        return accumulator
      }, 0)
    }, 0)
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip) {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.preCarriage, carriage: trip.preCarriageTripLeg
      });
    }
    if (trip?.mainCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.mainCarriage, carriage: trip.mainCarriageTripLeg
      });
    }
    if (trip?.postCarriageTripLeg) {
      carriageTripLegs.push({
        carriageType: CarriageStatusType.postCarriage, carriage: trip.postCarriageTripLeg
      });
    }

    this.preCarriageTotal = trip?.preCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.mainCarriageTotal = trip?.mainCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element.shipmentRate.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    this.postCarriageTotal = trip?.postCarriageTripLeg?.legs?.reduce((accumulator, current) => {
      if (current.rates) {
        current.rates.forEach(x => x.surcharges.forEach(element => {
          accumulator += element?.shipmentRate?.totalAmount;
        }));

        return accumulator + this.getTotalFreightRate(current.rates);
      }
      else return 0;
    }, 0);

    return carriageTripLegs;
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.shipmentType == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  onShowDetails(): void {
    this.dialog.open(RateDetailsDialogComponent, {
      autoFocus: true,
      scrollStrategy: this.sso.noop(),
      data: {
        quote: this.quote,
        containers: this.quotesQuery.shipmentType == ShipmentType.FCL ? this.quotesQuery?.equipments : this.quotesQuery?.containerCommodities,
        shipmentType: this.shipmentType
      },
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });
  }

  onClickPanel(state: boolean, panel: RateDetailPanels): void {
    if (state) {
      this.setPanelName.emit(panel.toString());
    }
    this.isExpanded[panel] = state;
  }

  onRateServiceType(): void {
    const dialogRef = this.dialog.open(QuotePriceTiersComparisonComponent, {
      autoFocus: false,
      scrollStrategy: this.sso.noop(),
      data: {},
      backdropClass: 'backdropBackground',
      panelClass: 'dialog-padding-0'
    });

    dialogRef.afterClosed().subscribe((result: any) => { });
  }

  isBasicRateServiceType(): boolean {
    return this.rateServiceType.Basic ===
      this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType;
  }

  onChangeExpand(value: boolean): void {
    this.onClickPanel(value, this.rateDetailsPanels.SERVICES)
  }

  updatePanel(): void {
    const panelName = this.currentPanelName;
    this.isExpanded[panelName] = true;
  }

  private totalLoadCalculation(): void {
    let packagesCount = 0;
    let totalVolumeAmount = 0;
    let totalWeightAmount = 0;

    this.quotesQuery.containerCommodities.forEach((e: any) => {
      packagesCount += e.numberOfPackages ? e.numberOfPackages : 0;
      totalVolumeAmount += e.totalVolumeAmount ? e.totalVolumeAmount : e.volumeAmount * packagesCount ? e.volumeAmount * packagesCount : 0;
      totalWeightAmount += e.totalWeightAmount ? e.totalWeightAmount : e.volumeAmount * packagesCount ? e.weightAmount * packagesCount : 0;
      this.grandTotalVolumeUnit = e.volumeUnit;
      this.grandTotalWeightUnit = e.weightUnit;
      this.grandTotalVolumeAmount += e.volumeAmount;
      this.grandTotalWeightAmount += e.weightAmount;

      var chargableWeight = this.calculateTotalChargeableWeight(e.volumeAmount, e.volumeUnit);
      var commodityChargeableWeight = e.weightAmount > chargableWeight ? e.weightAmount : chargableWeight;
      this.grandTotalChargeableWeight += commodityChargeableWeight;
    });
  }

  getFreightCharges(): number {
    return this.quote.trip?.mainCarriageTripLeg.legs.reduce(
      (freightCharges, leg) =>
        freightCharges + leg.rates?.reduce(
          (legPrice, rate) =>
          (
            legPrice +
            rate.freightRate?.shipmentRate?.totalAmount +
            rate.surcharges.reduce((surchargePrice, surcharge) => surchargePrice + surcharge?.shipmentRate?.totalAmount, 0)
          ), 0
        ), 0);
  }

  getDimensions(commodity: ContainerCommodity): string {
    let dimension = '';

    if (commodity !== null) {
      if (commodity.width != null
        && commodity.height !== null
        && commodity.length !== null) {
        dimension += `${commodity?.width} x `;

        dimension += `${commodity?.height} x `;

        dimension += `${commodity?.length}

      ${this.dimensionUnitNamePipe.transform(
          commodity?.dimensionUnit
        )} • `;
      }
      dimension += `${this.decimalPipe.transform(commodity.totalVolumeAmount, '1.2-2')}

      ${this.volumeUnitNamePipe.transform(
        commodity.volumeUnit
      )}`;
    }

    return dimension;
  }

  isShowReverseDiscountPrice(charge: number, panel: RateDetailPanels = RateDetailPanels.FREIGHT): boolean {
    if (panel != RateDetailPanels.FREIGHT) return false;

    return this.quote.trip?.reverseDiscount > 0 && charge > 0;
  }

  getReverseDiscountPrice(charge: number): number {
    return charge + this.quote.trip.reverseDiscount;
  }

  getChargeAmountToDisplay(charge: any): string {
    if (this.getChargeAmount(charge) != null)
      return this.decimalPipe.transform(this.getChargeAmount(charge), "1.2-2")

    return "-.--"
  }

  getChargeAmount(charge: any): number {
    if (charge?.totalFreightRate) return charge?.totalFreightRate

    if (charge?.shipmentRate.totalAmount != null) return charge?.shipmentRate.totalAmount || 0

    return null
  }

  private calculateTotalChargeableWeight(totalVolume: number, volumeUnit: VolumeUnit): number {
    if (volumeUnit === VolumeUnit.CBM) {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume * 1000;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume * 1000 / 6;
      }
    } else {
      if (this.shipmentType === ShipmentType.LCL) {
        return totalVolume / 1728 * 36;
      }
      if (this.shipmentType === ShipmentType.AIR) {
        return totalVolume / 166;
      }
    }
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }

  private getRateDetails() {
    let isCargoDetailsAdded = false;
    let cargoDetailsIndex = 0

    this.carriageTripLegs.forEach(({ carriageType, carriage }: { carriageType: CarriageStatusType, carriage: ExtenderTripLegBase }) => {
      carriage.legs.forEach(leg => {
        let rateDetailPanel = RateDetailPanels.FREIGHT;
        switch (carriageType) {
          case CarriageStatusType.preCarriage:
            rateDetailPanel = RateDetailPanels.ORIGIN
            if (this.quote.trip.isPreCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.postCarriage:
            rateDetailPanel = RateDetailPanels.DESTINATION
            if (this.quote.trip.isPostCarriageMissingRate) {
              this.rateDetails[rateDetailPanel].charges.push(this.getMissedFreightRate(carriageType));
            }
            break;
          case CarriageStatusType.mainCarriage:
          default:
            rateDetailPanel = RateDetailPanels.FREIGHT
            break;
        }

        leg.rates.forEach(rate => {
          const freightRate = rate.freightRate as any;
          this.rateDetails[rateDetailPanel].totalAmount += freightRate?.shipmentRate.totalAmount || 0;
          if (rateDetailPanel === RateDetailPanels.FREIGHT) {
            if (!isCargoDetailsAdded) {
              freightRate.cargoDetails = this.quotesQuery.shipmentType == ShipmentType.FCL ? this.quotesQuery?.equipments : this.quotesQuery?.containerCommodities;
              isCargoDetailsAdded = true;
              freightRate.totalFreightRate = freightRate?.shipmentRate.totalAmount || 0;
              this.rateDetails[rateDetailPanel].charges.push(freightRate);
              cargoDetailsIndex = this.rateDetails[rateDetailPanel].charges.length - 1;
            } else {
              this.rateDetails[rateDetailPanel].charges[cargoDetailsIndex].totalFreightRate += freightRate?.shipmentRate.totalAmount || 0;
            }
          } else {
            this.rateDetails[rateDetailPanel].charges.push(freightRate);
          }

          if (rate.surcharges?.length) {
            rate.surcharges.forEach(charge => {
              switch (charge.applicableType?.toUpperCase()) {
                case "FREIGHT":
                  this.rateDetails[RateDetailPanels.FREIGHT].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.FREIGHT].charges.push(charge);
                  break;
                case "ORIGIN":
                  this.rateDetails[RateDetailPanels.ORIGIN].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.ORIGIN].charges.push(charge);
                  break;
                case "DESTINATION":
                  this.rateDetails[RateDetailPanels.DESTINATION].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DESTINATION].charges.push(charge);
                  break;
                case "DANGEROUS":
                  this.rateDetails[RateDetailPanels.DANGEROUS].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.DANGEROUS].charges.push(charge);
                  break;
                default:
                  this.rateDetails[RateDetailPanels.OTHER].totalAmount += charge?.shipmentRate.totalAmount;
                  this.rateDetails[RateDetailPanels.OTHER].charges.push(charge);
                  break;
              }
            })
          }
        })
      })
    })
  }

  private getMissedFreightRate(carriageType: CarriageStatusType): unknown {
    switch (carriageType) {
      case CarriageStatusType.preCarriage:
        return {
          description: "Door pickup",
          isMissingRate: true
        }
      case CarriageStatusType.postCarriage:
        return {
          description: "Door delivery",
          isMissingRate: true
        }
    }
  }
}
