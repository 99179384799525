import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs";
import { RequestAndPayment, RequestAndPaymentQuery } from "./requests-and-payments.model";
import { EncodeUrlCodec } from "../services/encode-url-codec";
import { Page } from "../shared.model";

@Injectable()
export class RequestAndPaymentService {

    constructor(
        private internalUrlsService: InternalUrlsService,
        private http: HttpClient
    ) { }

    getAllByBookingId(id: string): Observable<RequestAndPayment[]> {
        return this.http.get<RequestAndPayment[]>(
            `${this.internalUrlsService.getApiBaseUrl()}/requestsAndPayments/${id}`);
    }

    getByOrganizationQuery(query: RequestAndPaymentQuery, organizationId: string): Observable<Page<RequestAndPayment>> {
        const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
        return this.http.get<Page<RequestAndPayment>>(
            `${this.internalUrlsService.getApiBaseUrl()}/requestsAndPayments/organization/${organizationId}`, { params });
    }

    getById(bookingId: string, id: string): Observable<RequestAndPayment> {
        return this.http.get<RequestAndPayment>(
            `${this.internalUrlsService.getApiBaseUrl()}/requestsAndPayments/${bookingId}/${id}`);
    }
}