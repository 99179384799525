import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][appIsShowTooltip]'
})
export class IsShowTooltipDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef,
    private matTooltip: MatTooltip
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.children[0]?.offsetWidth <= element.offsetWidth) {
        this.matTooltip.disabled = true;
      }
    }, 500);
  }
}
