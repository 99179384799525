<div class="orders-widget-container" [ngClass]="{ 'is-empty-container': isEmpty, 'is-preview': preview }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div *ngIf="editable" class="col-auto me-2 widget-action">
        <mat-icon class="editable-icon">zoom_out_map</mat-icon>
      </div>
      <div class="col widget-title">
        <span *ngIf="!isEmpty">My submitted orders</span>
      </div>
      <div *ngIf="!editable && !isEmpty && !preview" class="col-auto widget-action">
        <span class="go-icon link-action" (click)="onGoTo()"></span>
      </div>
      <div *ngIf="editable" class="col-auto widget-action">
        <span class="remove-icon link-action" (click)="onRemove()"></span>
      </div>
      <div *ngIf="!editable && isEmpty" class="col-auto widget-action">
        <span class="info-icon link-action" matTooltip="Track the statuses of your shipments."
          matTooltipPosition="above"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading || preview }">
      <div *ngIf="isLoading || preview" class="col is-loader">
        <div *ngFor="let num of range(loaderElementsNumber)" class="content-wrapper loader-element"></div>
      </div>

      <div *ngIf="!isLoading && isEmpty && !preview" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="mt-3 is-empty-title">You haven't had orders yet</span>
        <span class="mt-2 is-empty-message">Please search quote or contact support.</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
          [disabled]="isButtonLoading" (click)="onEmptyAction()">
          <span class="button-inner">
            Search quote
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="!isLoading && isError && !preview" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>

      <div *ngIf="!isLoading && !isError && !isEmpty && !preview" class="col stripped-wrappers">
        <div class="row content-wrapper pe-3" *ngFor="let booking of bookings">
          <div class="col d-flex flex-column justify-content-center h-100" (click)="onBookingDetails(booking.id)">
            <div class="row">
              <div class="col-auto"></div>
              <div class="col-auto submited-order-icon-date-wrapper"
                [matTooltip]="booking.shipmentTypeCode | shipmentTypeName" matTooltipPosition="above">
                <div class="transport-icon">
                  <app-quote-search-icons [iconName]="getMainCarriageIcon(booking)"></app-quote-search-icons>
                </div>
              </div>
              <div class="col from-ordername-orderdate-wrapper">
                <span class="order-name">{{ booking.fromDisplayName }}</span>
                <span class="order-date">{{
                  checkDateNotNullOrEmpty(booking.etd)
                  ? (booking.etd | date : "MMM dd, yyyy")
                  : "Schedule Not Available"
                  }}</span>
              </div>
              <div class="col to-ordername-orderdate-wrapper">
                <span class="order-name">{{ booking.toDisplayName }}</span>
                <span class="order-date">{{
                  checkDateNotNullOrEmpty(booking.eta)
                  ? (booking.eta | date : "MMM dd, yyyy")
                  : "Schedule Not Available"
                  }}</span>
              </div>
              <div class="col d-none d-xl-block order-status-arrival-wrapper">
                <span [class]="getIconColor(booking)">{{
                  booking.isCanceled === true
                  ? "Cancelled"
                  : booking.statusType?.ship4wdShortFrontendStatus
                  }}</span>
                <span class="arrival-time">{{ getArriveDays(booking) }}</span>
              </div>
            </div>
          </div>
          <div class="col-auto align-self-center py-2" [matMenuTriggerFor]="menu">
            <mat-icon class="material-symbols-outlined content-text">
              more_vert
            </mat-icon>
          </div>
          <mat-menu #menu backdropClass="order-dropdown">
            <btn mat-menu-item class="dropdown-button" (click)="onBookingDetails(booking.id)">
              <span class="view-order-icon"></span>
              <span class="order-dropdown-text">Show Booking Details</span>
            </btn>
            <btn mat-menu-item class="dropdown-button" (click)="onFilesDocuments(booking.id)">
              <span class="files-docs-icon"></span>
              <span class="order-dropdown-text">Files & Documents</span>
            </btn>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
