import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode } from '../shared.model';

@Pipe({ name: 'applicableType' })
export class ApplicableTypePipe implements PipeTransform {
  private currencyIcons = {};

  constructor() {
    this.currencyIcons['PART_OF_FREIGHT'] = 'Part of freight';
    this.currencyIcons['DESTINATION'] = 'Destination';
    this.currencyIcons['ORIGIN'] = 'Origin';
  }

  transform(value?: CurrencyCode): string {
    return this.currencyIcons[value] ?? value;
  }
}
