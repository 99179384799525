<div *ngIf="show" class="container-fluid dashboard-navbar-mobile">
  <div class="row h-100">
    <div class="col">
      <div class="row mobile-navbar-wrapper">
        <div class="col navbar-item">
          <a [routerLink]="['/dashboard']" [routerLinkActive]="'active'" class="navbar-item-content">
            <span class="navbar-item-icon home"></span>
            <span class="navbar-item-text">Home</span>
          </a>
        </div>
        <div class="col navbar-item">
          <a [routerLink]="['/shipments']" [routerLinkActive]="'active'" class="navbar-item-content">
            <span class="navbar-item-icon shipments"></span>
            <span class="navbar-item-text">Shipments</span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-auto navbar-center">
      <span class="navbar-item-content">
        <span class="navbar-item-icon-container">
          <a [routerLink]="['/quote-search']" class="navbar-item-icon-wrapper">
            <span class="navbar-item-icon quote"></span>
          </a>
        </span>
        <span class="navbar-item-text">Get Quote</span>
      </span>
    </div>
    <div class="col">
      <div class="row mobile-navbar-wrapper">
        <div class="col navbar-item">
          <a [routerLink]="['/finance']" [routerLinkActive]="'active'" class="navbar-item-content">
            <span class="navbar-item-icon finance"></span>
            <span class="navbar-item-text">Finance</span>
          </a>
        </div>
        <div class="col navbar-item">
          <a [routerLink]="['/creditLine']" [routerLinkActive]="'active'" class="navbar-item-content">
            <span class="navbar-item-icon credit"></span>
            <span class="navbar-item-text">Credit Line</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>