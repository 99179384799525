<div class="row m-0 g-0">
  <div class="col" *ngIf="isOldSearchStyle">
    <app-quote-search-flow-old class="search-flow-params" [quoteSearchForm]="quoteSearchForm" [isReuse]="isReuse"
      [isLoading]="isListLoading" [reset]="result" [searchId]="searchId" isEnabled="isSearchEnabled"
      (searchQuotesEvent)='onSearchQuotes($event)'></app-quote-search-flow-old>
  </div>
  <div class="col" *ngIf="!isOldSearchStyle">
    <app-quote-search-flow-new class="search-flow-params" [quoteSearchForm]="quoteSearchForm" [isReuse]="isReuse"
      [isLoading]="isListLoading" [reset]="result" [searchId]="searchId" isEnabled="isSearchEnabled"
      (searchQuotesEvent)='onSearchQuotes($event)'></app-quote-search-flow-new>
  </div>
</div>