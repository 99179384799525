<div class="no-booking-container">
  <div class="row">
    <div class="col-12 text-center">
      <mat-dialog-content class="no-booking-content">
        <div class="no-booking-message-wrapper">
          <span class="no-booking-title-span">Submit failed</span>
        </div>
        <div class="no-booking-message-wrapper">
          <span class="no-booking-message-span">
            Shipping status prevents shipping instruction modification.
          </span>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  <div class="row text-center mt-2 justify-content-md-center">
    <mat-dialog-actions class="no-booking-footer text-center">
      <button mat-raised-button [mat-dialog-close]="true" class="get-booking-button">
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>