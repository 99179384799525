import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, ValidationService } from '@ship4wd/ngx-common';
import { Observable } from 'rxjs';
import { BookingFlowService } from '../../booking-flow.service';
import { CommodityService } from '../../../../../shared/services/commodity.service';
import { StringCutterService } from '../../../../../shared/services/text-cutter/stringCutter.service';
import { OrganizationContactAction } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import {
  Booking,
  BookingViewModel,
  Commodity
} from '../../../../../shared/bookings/bookings.model';
import {
  BookingFlowPanelState,
  BookingFlowPanels,
  CustomsChargeCodes,
  HarmonizedSystemCode,
  ShipmentType,
  VendorsCode
} from '../../../../../shared/shared.model';
import { CustomsBondType, CustomsCommodity, CustomsFee, CustomsRate, CustomsRateQuery, CustomsShippingMethod } from '../../../../../shared/customs/customs.model';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-service-cost-estimator',
  templateUrl: './service-cost-estimator.component.html',
  styleUrls: ['./service-cost-estimator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceCostEstimatorComponent implements OnInit {
  booking: Booking;
  containers: FormArray;
  shipmentTypeCode: ShipmentType;
  existingServiceCostEstimatorForm: FormGroup | undefined = undefined;
  existingCalculaterResponse: CustomsRate = undefined;
  vendorCode: VendorsCode;
  selectedCountry: string;
  @Output() setState: EventEmitter<BookingFlowPanelState> = new EventEmitter();

  bookingForm: FormGroup;
  serviceCostEstimatorForm: FormGroup;
  collectDateControl: FormControl = new FormControl('', Validators.required);
  countryAutocompleteValue: string = '';
  organizationContactAction: OrganizationContactAction = OrganizationContactAction.new;
  isCalculatorBtnLoading: boolean = false;
  calculatedSummaries: CustomsFee[] = [];
  isShowCalculateSummaries: boolean = false;
  isShowCalculationResults: boolean = false;
  calculaterResponse: CustomsRate;
  customsChargeCodes = CustomsChargeCodes;
  vendorsCodes = VendorsCode;

  get isValid(): boolean {
    let commoditiesValid = true;

    this.containers.controls.forEach((container: FormGroup) => {
      const commodities = container.controls["commodities"] as FormArray;
      commodities.controls.forEach((commodity: FormGroup) => {
        if (!commodity.valid) commoditiesValid = false;
      });
    });

    return commoditiesValid && this.collectDateControl.valid;
  }

  get containerCommodityFormArray(): FormArray {
    return this.serviceCostEstimatorForm.get("commodities") as FormArray;
  }

  get totalAmount(): number | string {
    return this.isTotalAmountZero() ? 'Currently unavailable' : this.calculaterResponse.totalAmount;
  }

  constructor(
    private fb: FormBuilder,
    private bookingFlowService: BookingFlowService,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    private commodityService: CommodityService,
    private stringCutterService: StringCutterService,
    public dialogRef: RollupDialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any
  ) {
    this.bookingForm = data.bookingForm;
    this.booking = data.booking;
    this.containers = data.containers;
    this.shipmentTypeCode = data.shipmentTypeCode;
    this.existingServiceCostEstimatorForm = data.existingServiceCostEstimatorForm;
    this.existingCalculaterResponse = data.existingCalculaterResponse;
    this.vendorCode = data.vendorCode;
    this.selectedCountry = data.selectedCountry;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.existingServiceCostEstimatorForm) {
      const commodities = this.getBookingCommodities(this.bookingForm);
      this.getUnitOfMeasureByCommodityCodes(commodities, true, null);
    }
    else {
      this.serviceCostEstimatorForm = this.existingServiceCostEstimatorForm;
      const commodities = this.getCommoditiesFromServiceCostEstimatorForm();
      this.getUnitOfMeasureByCommodityCodes(commodities, false, null);
    }

    if (this.existingCalculaterResponse) {
      this.calculaterResponse = this.existingCalculaterResponse ?? this.existingCalculaterResponse;
      this.isShowCalculationResults = true;
    }
  }

  onSelectHarmonizedSystemCode(harmonizedSystemCode: HarmonizedSystemCode, commodityForm: FormGroup): void {
    this.onSetState(true);
    commodityForm.get('commodityObject').setValue(harmonizedSystemCode);
    commodityForm.get('code').setValue(harmonizedSystemCode?.id);
    commodityForm.get('description').setValue(harmonizedSystemCode?.description);
    commodityForm.get('unitOfMeasure').setValue(harmonizedSystemCode?.unitOfMeasure1);
  }

  onSetState(changed: boolean): void {
    const state = new BookingFlowPanelState();

    state.panel = BookingFlowPanels.contacts;
    state.success = changed ? false : this.isValid;
    state.valid = this.isValid;
    state.loading = false;

    this.setState.emit(state);
  }

  onAddCommodity(): void {
    this.containerCommodityFormArray.push(this.createCommodityForm(null, null));
  }

  onDeleteCommodity(containerForm: FormGroup, index: number): void {
    this.containerCommodityFormArray.removeAt(index);
  }

  onCalculate(): void {
    this.serviceCostEstimatorForm.markAllAsTouched();
    this.validationService.validate(this.serviceCostEstimatorForm);

    if (this.serviceCostEstimatorForm.valid) {
      this.isShowCalculationResults = false;
      this.isShowCalculateSummaries = false;
      this.calculatedSummaries = [];
      const preparedCustomsRateQueryModel = this.prepareCustomsRateQueryModel();

      this.getCustomRates(preparedCustomsRateQueryModel);
    }
  }

  onReset(): void {
    this.containerCommodityFormArray.controls.forEach(control => {
      const commodityFormGroup = control as FormGroup;
      commodityFormGroup.get('price').setValue(null);
      commodityFormGroup.get('totalUnits').setValue(null);
    });
  }

  onHideCalculateSummaries(): void {
    this.calculatedSummaries = [];
    this.isShowCalculateSummaries = false;
  }

  onShowCalculateSummaries(): void {
    this.onSummaryArray();
    this.isShowCalculateSummaries = true;
  }

  onClose(): void {
    this.dialogRef.close({
      serviceCostEstimatorForm: this.serviceCostEstimatorForm,
      calculaterResponse: this.calculaterResponse
    });
  }

  onIsTouched(event: any): void {
    if (event?.isTouched === true) {
      event?.countryControl.markAsTouched();
    }
  }

  getDescription(description: string): string {
    return this.stringCutterService.cutter(description, 70);
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Additional services",
      fieldName,
      errorValue
    })
  }

  isTotalAmountZero(): boolean {
    return this.calculaterResponse?.totalAmount === '$ 0.00' ||
      this.calculaterResponse?.totalAmount === undefined ||
      this.calculaterResponse?.totalAmount === null;
  }

  restrictNumeric(event: Event | ClipboardEvent | any, index: number, fieldName: string): void {
    const restrictChars = [".", "-", "_", " ", ","];

    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    const isRestrictedChar: boolean = restrictChars.some(x => {
      return event?.data === x
    });

    if (isRestrictedChar) {
      value = value.replace(/[^0-9]/g, '');
    }

    if (restrictChars.some(x => { return event?.data.includes(x) })) {
      value = !isRestrictedChar ? "" : value;
      inputElement.value = value;

      var control = this.containerCommodityFormArray.at(index).get(fieldName);

      if (control) {
        control.setValue(value);
        event.target.setSelectionRange(value.length, value.length);
      }
    }
  }

  handlePaste(event: ClipboardEvent, index: number, fieldName: string): void {
    const pastedText = event.clipboardData?.getData('text') ?? '';
    if (pastedText.includes('.')) {
      var control = this.containerCommodityFormArray.at(index).get(fieldName);
      if (control) {
        control.setValue("");
      }
    }
  }

  private getCustomShippingMethod(shipmentTypeCode: ShipmentType): CustomsShippingMethod {
    let customShippingMethod = CustomsShippingMethod.Ocean;
    switch (shipmentTypeCode) {
      case ShipmentType.FCL:
      case ShipmentType.LCL:
        customShippingMethod = CustomsShippingMethod.Ocean;
        break;
      case ShipmentType.AIR:
        customShippingMethod = CustomsShippingMethod.Air;
      default:
        break;
    }
    return customShippingMethod;
  }

  private prepareServiceCostEstimatorForm(commodities: Commodity[]): void {
    this.serviceCostEstimatorForm = this.fb.group({
      commodities: this.fb.array([]),
    });

    commodities.forEach(commodity => {
      if (commodity?.commodityTypeCode) {
        this.getHarmonizedSystemCode(commodity?.commodityTypeCode).subscribe(
          (harmonizedSystemCode: HarmonizedSystemCode) => {
            this.containerCommodityFormArray.push(this.createCommodityForm(commodity, harmonizedSystemCode))
          })
      }
      else {
        this.containerCommodityFormArray.push(this.createCommodityForm(commodity, null));
      }
    });
  }

  private getBookingCommodities(bookingForm: FormGroup): Commodity[] {
    const booingViewModel = bookingForm.getRawValue() as BookingViewModel;
    let commodities: Commodity[] = [];

    booingViewModel.containers.forEach(container => {
      if (container.commodities && container.commodities.length > 0) {
        container.commodities.forEach(commodity => {
          if (commodity.commodityTypeCode !== "" && commodity.commodityTypeCode !== null) {
            commodities.push(commodity);
          }
        });
      }
    });
    return commodities;
  }

  private getCommoditiesFromServiceCostEstimatorForm(): Commodity[] {
    let commodities: Commodity[] = [];

    this.containerCommodityFormArray.controls.forEach((element: AbstractControl) => {
      let commodity = new Commodity();
      commodity.commodityTypeCode = element.get('code').value;
      commodities.push(commodity);
    });

    return commodities;
  }

  private createCommodityForm(commodity?: Commodity, harmonizedSystemCode?: HarmonizedSystemCode): FormGroup {
    return this.fb.group({
      commodityObject: [null, Validators.required],
      description: [commodity ? commodity.commodityDescription : null],
      code: [commodity ? commodity.commodityTypeCode : "", Validators.required],
      totalUnits: [commodity && commodity.numberOfPackages > 0 ? commodity.numberOfPackages : null, Validators.required],
      unitOfMeasure: [{ value: harmonizedSystemCode ? harmonizedSystemCode.unitOfMeasure1 : "", disabled: true }],
      price: ["", Validators.required],
      countryManufacture: [this.selectedCountry ?? "", Validators.required],
    });
  }

  private getUnitOfMeasureByCommodityCodes(commodities: Commodity[], isFormPrepare: boolean, commodityForm: FormGroup): void {
    if (commodities.length > 0) {
      if (isFormPrepare) {
        this.prepareServiceCostEstimatorForm(commodities);
      }
    } else {
      this.prepareServiceCostEstimatorForm([]);
      this.onAddCommodity();
    }
  }

  private onSummaryArray(): void {
    this.calculaterResponse.customFee.forEach((fee: CustomsFee) => {
      this.calculatedSummaries.push(fee);
    })

    this.calculaterResponse.clearitFee.forEach((fee: CustomsFee) => {
      this.calculatedSummaries.push(fee);
    })
  }

  private getCustomBondType(): CustomsBondType {
    let customBoundType: CustomsBondType = CustomsBondType.SingleEntryBond;

    if (this.booking.bookingAdditionalServiceCharges && this.booking.bookingAdditionalServiceCharges.length > 0) {
      const clearItAdditionalServiceFeatures = this.booking.bookingAdditionalServiceCharges.filter(x => x.vendorCode === VendorsCode.clearit);
      if (clearItAdditionalServiceFeatures && clearItAdditionalServiceFeatures.length > 0) {
        const chargeCodes = [CustomsChargeCodes.ABF, CustomsChargeCodes.SGB];
        chargeCodes.every(chargeCode => {
          const isFound = clearItAdditionalServiceFeatures.some(x => x.additionalServiceCharge.chargeCode.toLocaleLowerCase() === this.customsChargeCodes[chargeCode].toString().toLocaleLowerCase());
          if (isFound) {
            customBoundType = this.getCustomBoundTypeByChargeCode(chargeCode);
            return;
          }
        });
      }
    }
    return customBoundType;
  }

  private getCustomBoundTypeByChargeCode(chargeCode: CustomsChargeCodes): CustomsBondType {
    let boundType: CustomsBondType = CustomsBondType.SingleEntryBond;
    switch (chargeCode) {
      case CustomsChargeCodes.ABF:
        boundType = CustomsBondType.AnnualBondRequested;
        break;
      case CustomsChargeCodes.SGB:
        boundType = CustomsBondType.SingleEntryBond;
        break;
      default:
        break;
    }
    return boundType;
  }

  private getTotalFreightCharges(commodities: CustomsCommodity[]): number {
    let totalFreightCharges: number = 0;

    if (commodities && commodities.length > 0) {
      commodities.forEach(commodity => {
        commodity.price = isNaN(commodity.price) ? 0 : commodity.price;
        totalFreightCharges += commodity.price * commodity.totalUnits;
      });
    }

    return totalFreightCharges;
  }

  private prepareCustomsRateQueryModel(): CustomsRateQuery {
    const commodities = [...this.containerCommodityFormArray.getRawValue() as CustomsCommodity[]];

    let customsRateQueryModel = new CustomsRateQuery();
    customsRateQueryModel.organizationId = this.booking.organizationId;
    customsRateQueryModel.bookingId = this.booking.id;
    customsRateQueryModel.shippingFrom = this.booking.fromBookingSearch.country;
    customsRateQueryModel.shippingMethod = this.getCustomShippingMethod(this.booking.shipmentTypeCode);
    customsRateQueryModel.bondType = this.getCustomBondType();
    customsRateQueryModel.freightCharge = this.getTotalFreightCharges(commodities);

    commodities.forEach((x: CustomsCommodity) => {
      x.price = isNaN(x.price) ? 0 : x.price;
      x.dutyUnits = x.totalUnits;
      delete (x as any)?.commodityObject;
    });

    customsRateQueryModel.commodities = commodities;

    return customsRateQueryModel;
  }

  private getCustomRates(preparedCustomsRateQueryModel: CustomsRateQuery): void {
    this.isCalculatorBtnLoading = true;

    this.bookingFlowService.getCustomRateByQuery(preparedCustomsRateQueryModel)
      .subscribe(
        (customsRate: CustomsRate) => {
          this.calculaterResponse = customsRate;
          this.isShowCalculationResults = true;
        },
        (error: any) => this.notificationService.error(error),
      )
      .add(() => this.isCalculatorBtnLoading = false);
  }

  private getHarmonizedSystemCode(commodityCode: string): Observable<HarmonizedSystemCode | null> {
    return this.commodityService.getById(commodityCode);
  }
}
