<div class="quote-search-flow" [ngClass]="{'is-overflow-hidden': isLoading && isStarted}">
  <div class="search-flow-header-wrapper">
    <app-quote-search-flow-header [quoteSearchForm]="quoteSearchForm"
      [isCompact]="isFilled"></app-quote-search-flow-header>
  </div>
  <div class="search-flow-content-wrapper" [ngClass]="{'is-loaded': !isLoading && isStarted}">
    <div id="search-flow-overlay" class="search-flow-overlay"></div>
    <div class="row">
      <div class="col">
        <app-quote-search-flow-params class="search-flow-params" [quoteSearchForm]="quoteSearchForm" [isReuse]="isReuse"
          [isLoading]="isListLoading" [reset]="result" [shippingTypes]="shippingTypes" [searchId]="searchId"
          isEnabled="isSearchEnabled" (searchQuotesEvent)='onSearchQuotes($event)'></app-quote-search-flow-params>
      </div>
    </div>
    <div class="search-flow-main-wrapper">
      <div *ngIf="isStarted" class="search-flow-main-container">
        <div *ngIf="isLoading && isStarted" class="row can-transform" [ngClass]="{'is-active': isLoading && isStarted}">
          <div class="col d-flex justify-content-center">
            <app-loader-with-images class="search-flow-loader-with-image"></app-loader-with-images>
          </div>
        </div>
        <div *ngIf="isLoading && isStarted" class="row can-transform" [ngClass]="{'is-active': isLoading && isStarted}">
          <div class="col d-flex justify-content-center">
            <app-quote-search-flow-loader class="search-flow-loader mt-4"></app-quote-search-flow-loader>
          </div>
        </div>
        <div *ngIf="!isLoading && isStarted" class="row h-100 can-transform"
          [ngClass]="{'is-active': !isLoading && isStarted}">
          <app-quotes-results [quotes]="quotes" [isListLoading]="isLoading"
            [shipmentType]="quoteSearchForm.get('shipmentType').value" [from]="from" [to]="to"
            [quotesQuery]="quotesQuery" [noQuotesFound]="noQuotesFound" [quoteSearchId]="quoteSearchId"
            (shipperTypeChanged)="onChangeShipperType($event)"> </app-quotes-results>
        </div>
        <div *ngIf="!(isLoading && isStarted) && isListLoading" class="row can-transform"
          [ngClass]="{'is-active': !(isLoading && isStarted) && isListLoading}">
          <div class="col d-flex justify-content-center">
            <app-quote-search-flow-loader [isFull]="false" class="search-flow-loader"></app-quote-search-flow-loader>
          </div>
        </div>
      </div>
      <div *ngIf="!isStarted" class="row quote-search-banner-content-wrapper">
        <div class="col-12 quote-search-banner-title"><span class="main">100% Delivery Success</span> in 2022</div>
        <div class="col-12 quote-search-banner-subtitle">
          Despite all eventualities worldwide, we had zero cargo loss in the past year.
        </div>

        <div class="quote-search-banner-line-text-line">
          <span class="quote-search-banner-line"></span>
          <span class="quote-search-banner-text">Why us?</span>
          <span class="quote-search-banner-line"></span>
        </div>

        <div class="row">
          <div class="col"></div>
          <div class="col-1 quote-search-banner-info">
            <div class="certificate-img mb-2"></div>
            Federal Maritime Commission Registration No. 029468
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="iata-cargo-img mb-2"></div>
            IATA Cargo Agent
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="furnishings-img mb-2"></div>
            Member of the American Home Furnishings Alliance
          </div>
          <div class="col-1 quote-search-banner-info">
            <div class="prizes-img mb-2"></div>
            Best Freight Forwarder for SMBs U.S. and Canada
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</div>