import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NotificationService } from "@ship4wd/ngx-common";
import { BookingContactViewModel } from "../../../../../shared/bookings/booking-contacts/booking-contacts.model";
import { BookingContactsService } from "../../../../../shared/bookings/booking-contacts/booking-contacts.service";
import {
  OrganizationContact,
  OrganizationContactAction,
  OrganizationContactViewModel,
} from "../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model";
import { BookingOrganizationContactsService } from "../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service";
import {
  Booking,
  ContactType,
} from "../../../../../shared/bookings/bookings.model";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { ValidatorsService } from "../../../../../shared/helper/validators.service";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { GoogleAnalyticsService } from "../../../../../shared/google-analytics/google-analytics.service";
import { PhoneInputComponent } from '../../../../../shared/phone-input/phone-input.component';

@Component({
  selector: "app-booking-flow-create-contact",
  templateUrl: "./booking-flow-create-contact.component.html",
  styleUrls: ["./booking-flow-create-contact.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BookingFlowCreateContactComponent implements OnInit {
  booking: Booking;
  contactType: ContactType;
  organizationContact: OrganizationContact = undefined;
  organizationContactAction: OrganizationContactAction =
    OrganizationContactAction.new;
  isBookingContactPage: boolean = true;
  organizationContactForm: FormGroup;
  countryAutocompleteValue: string;
  isLoading = false;
  organizationContactActions = OrganizationContactAction;
  contactCountryCode: string = null;
  contactCountryName: string = null;
  selectedContactTitle: string = null;
  countryAutoCompleteAction: OrganizationContactAction =
    OrganizationContactAction.new;
  placesOptions = {
    types: [],
    componentRestrictions: {},
  };

  @ViewChild("placeRef") placeRef: GooglePlaceDirective;
  @ViewChild("phoneInputComponent") phoneInputComponent: PhoneInputComponent;

  constructor(
    private fb: FormBuilder,
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private bookingContactsService: BookingContactsService,
    private validatorsService: ValidatorsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BookingFlowCreateContactComponent>,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private elements: ElementRef
  ) {
    this.booking = data.booking;
    this.contactType = data.contactType;
    this.organizationContact = data.organizationContact;
    this.organizationContactAction = data.organizationContactAction;
    this.isBookingContactPage = data.isBookingContactPage;
  }

  ngOnInit(): void {
    this.countryAutoCompleteAction = this.organizationContactAction;
    this.contactCountryCode = this.getContactCountryCode();
    this.contactCountryName = this.getContactCountryName();
    this.selectedContactTitle = this.getContactTitle();
    this.organizationContactForm = this.createOrganizationContactForm();
    this.countryAutocompleteValue = this.getPropertyValue("countryCode");
    this.setCountryCode();
    this.onChanges();
  }

  onBackToContact(): void {
    this.dialogRef.close(null);
  }

  onSaveAndSelect(): void {
    this.organizationContactForm.markAllAsTouched();
    this.onFocusInvalidControl();

    if (!this.organizationContactForm.valid) {
      this.notificationService.error(
        "Can't save changes because of an input errors, please check out the form"
      );
      this.handleError(null, "Can't save changes because of an input errors, please check out the form")
      return;
    }
    const organizationContact =
      this.organizationContactForm.getRawValue() as OrganizationContactViewModel;

    if (
      !this.compareLocations(
        this.organizationContactForm.getRawValue() as OrganizationContact
      )
    )
      return;

    organizationContact.organizationId = this.booking.organizationId;
    this.saveOrganizationContact(organizationContact);
  }

  setAutocompleteCountry(): void {
    this.placeRef.options = {
      componentRestrictions: {
        country: this.organizationContactForm.get("countryCode").value,
      },
    } as Options;
    this.placeRef.reset();
  }

  handleError(fieldName: string, errorValue: string): void {
    this.googleAnalyticsService.errorFunnelBooking({
      shipmentType: this.booking.shipmentTypeCode,
      actionName: "Contact Details",
      fieldName,
      errorValue
    })
  }

  private compareLocations(organizationContact: OrganizationContact): boolean {
    if (this.contactType === ContactType.shipper) {
      let from = this.booking.fromBookingSearch;

      if (
        from.country.toUpperCase().trim() !==
        organizationContact.countryCode.toUpperCase().trim()
      ) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]
              }" should be the same as stated in the booking ${from.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok",
          },
        });

        return false;
      }
    } else if (this.contactType === ContactType.consignee) {
      let to = this.booking.toBookingSearch;

      if (
        to.country.toUpperCase().trim() !==
        organizationContact.countryCode.toUpperCase().trim()
      ) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: `The contact country for "${ContactType[this.contactType]
              }" should be the same as stated in the booking ${to.countryName}`,
            showConfirmButton: true,
            confirmButtonText: "Ok",
          },
        });

        return false;
      }
    }

    return true;
  }

  private createOrganizationContactForm(): FormGroup {
    return this.fb.group({
      id: [this.organizationContact?.id ?? null],
      firstName: [
        {
          value: this.getPropertyValue("firstName"),
          disabled: this.isDisabled(),
        },
        this.isRequired()
          ? [Validators.required, this.validatorsService.whiteSpaceValidator]
          : [],
      ],
      lastName: [
        {
          value: this.getPropertyValue("lastName"),
          disabled: this.isDisabled(),
        },
        this.isRequired()
          ? [Validators.required, this.validatorsService.whiteSpaceValidator]
          : [],
      ],
      companyName: [
        {
          value: this.getPropertyValue("companyName"),
          disabled: this.isDisabled(),
        },
        this.isRequired()
          ? [Validators.required, this.validatorsService.whiteSpaceValidator]
          : [],
      ],
      email: [
        { value: this.getPropertyValue("email"), disabled: this.isDisabled() },
        this.isRequired()
          ? [
            Validators.required,
            Validators.email,
            this.validatorsService.emailValidator,
          ]
          : [],
      ],
      countryCode: [
        {
          value: this.getPropertyValue("countryCode"),
          disabled: this.contactType != ContactType.notifyParty,
        },
        this.isRequired() ? Validators.required : [],
      ],
      phoneNumber: [
        {
          value: this.getPropertyValue("phoneNumber"),
          disabled: this.isDisabled(),
        },
        [this.isRequired() ? Validators.required : []],
      ],
    });
  }

  private getPropertyValue(propertyName: string): any {
    if (
      this.organizationContactAction === OrganizationContactAction.view ||
      this.organizationContactAction === OrganizationContactAction.edit
    ) {
      return this.organizationContact[propertyName] ?? "";
    } else {
      return "";
    }
  }

  private setCountryCode(): void {
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.organizationContactForm
        .get("countryCode")
        .patchValue(this.contactCountryCode ?? "");
      this.countryAutocompleteValue =
        this.organizationContactForm.get("countryCode").value;
      this.countryAutoCompleteAction =
        this.contactType !== ContactType.notifyParty
          ? OrganizationContactAction.view
          : this.organizationContactAction;
    }

    if (this.contactCountryCode) {
      this.placesOptions = {
        types: ["address"],
        componentRestrictions: { country: [this.contactCountryCode] },
      };
    }
  }

  private isRequired(): boolean {
    return this.organizationContactAction === OrganizationContactAction.view
      ? false
      : true;
  }

  private isDisabled(): boolean {
    return this.organizationContactAction === OrganizationContactAction.view
      ? true
      : false;
  }

  private prepareBookingContactModel(
    organizationContact: OrganizationContact
  ): BookingContactViewModel {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = this.contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private saveOrganizationContact(
    organizationContact: OrganizationContactViewModel
  ): void {
    this.isLoading = true;
    if (this.organizationContactAction === OrganizationContactAction.new) {
      this.bookingOrganizationContactsService
        .addOrganizationContact(organizationContact)
        .subscribe(
          (x: OrganizationContact) => {
            if (this.isBookingContactPage) {
              this.setBookingContact(x);
            } else {
              this.dialogRef.close(null);
            }
          },
          (error) => this.notificationService.error(error)
        )
        .add(() => {
          this.isLoading = false;
        });
    } else {
      this.bookingOrganizationContactsService
        .updateOrganizationContact(organizationContact)
        .subscribe(
          (x: OrganizationContact) => {
            this.setBookingContact(x);
          },
          (error) => this.notificationService.error(error)
        )
        .add(() => (this.isLoading = false));
    }
  }

  private setBookingContact(
    organizationContact: OrganizationContact,
    isSelectOnly: boolean = false
  ): void {
    const bookingContact = this.prepareBookingContactModel(organizationContact);
    if (
      this.contactType === ContactType.collect ||
      this.contactType === ContactType.drop
    ) {
      this.notificationService.success(
        "Address has been successfully changed."
      );

      this.dialogRef.close({
        bookingContact: bookingContact,
        contactType: this.contactType,
      });
    } else {
      this.addBookingContact(bookingContact, isSelectOnly);
    }
  }

  private addBookingContact(
    bookingContact: BookingContactViewModel,
    isSelectOnly: boolean
  ): void {
    if (!isSelectOnly) {
      this.isLoading = true;
    }
    this.bookingContactsService
      .addBookingContact(bookingContact)
      .subscribe(
        (bookingContactId: string) => {
          this.notificationService.success(`Contact has been successfully
            ${this.organizationContactAction === OrganizationContactAction.new
              ? "added"
              : "updated"
            }.`);

          this.dialogRef.close({
            bookingContactId: bookingContactId,
            contactType: this.contactType,
          });
        },
        (error) => this.notificationService.error(error)
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  private onChanges(): void {
    this.organizationContactForm
      .get("countryCode")
      .valueChanges.subscribe((val) => {
        this.organizationContactForm
          .get("phoneNumber")
          .updateValueAndValidity();
      });
  }

  private getContactCountryCode(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.country;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.country;
      default:
        return null;
    }
  }

  private getContactTitle(): string {
    switch (this.contactType) {
      case ContactType.notifyParty:
        return "Notify contact";
      case ContactType.consignee:
        return "contact at Delivery (Importer)";
      case ContactType.shipper:
        return "contact at Pickup (Exporter/Supplier)";
      default:
        return "contact";
    }
  }

  private getContactCountryName(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.countryName;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.countryName;
      default:
        return null;
    }
  }

  private onFocusInvalidControl(): void {
    const key = Object.keys(this.organizationContactForm.controls)
      .find(key => this.organizationContactForm.controls[key].invalid);

    if (key) {
      if (key === "phoneNumber") {
        this.phoneInputComponent.focusPhoneInputElement();
      } else {
        this.elements.nativeElement.querySelector(`[formcontrolname="${key}"]`)?.focus();
      }
    }
  }
}
