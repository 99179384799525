<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div [hidden]="isLoading" *ngIf="!isViewOrganizationContacts && shipmentInstructionBookingForm && isBookingSubmitted"
  [formGroup]="shipmentInstructionBookingForm">
  <div class="booking-shipping-instructions-container">
    <div class="row booking-shipping-instructions-content-container" *ngIf="shipmentInstructionBookingForm">
      <app-booking-instructions-detail [shipmentInstructionBookingForm]="shipmentInstructionBookingForm"
        [shipmentInstruction]="shipmentInstruction" (addCommodity)="onAddCommodity($event)"
        (removeCommodity)="onDeleteCommodity($event)" [shipperContact]="shipperContact"
        [consigneeContact]="consigneeContact" [notifyPartyContact]="notifyPartyContact"
        (addAnotherReference)="onAddAnotherReference()" (removeAnotherReference)="onRemoveAnotherReference($event)"
        (generatedRefID)="onGenerateRefID()" (viewOrganizationContactEvent)="onViewOrganizationContact($event)"
        (removeBookingContactEvent)="onRemoveBookingContact($event)" [isShipperContactDisable]="isShipperContactDisable"
        [isConsigneeContactDisable]="isConsigneeContactDisable"
        [isNotifyPartyContactDisable]="isNotifyPartyContactDisable"
        [isAddCommodityButtonDisable]="isAddCommodityButtonDisable"
        [isAddAnotherRefButtonDisable]="isAddAnotherRefButtonDisable">
      </app-booking-instructions-detail>
    </div>

    <div class="row">
      <div class="col">
        <div class="booking-shipping-instructions-terms-condition-checkbox">
          <mat-checkbox [disabled]="isShippingInstructionsSubmitted" [checked]="isTermsAndConditionsConfirmed"
            (change)="onConfirmTermsChanged($event.checked)">
            Tick to confirm you have the terms & conditions, goods include no personal effects,
            no refrigerated goods in dry conrainers and all information is accurate.
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <app-navbar>
    <div class="col px-3 align-self-center cancel-submit-button-wrapper">
      <div class="cancel-button">
        <button mat-stroked-button class="back-button" type="button" (click)="onClose()">
          <div class="row">
            <div class="col-auto back-arrow">
              <div class="back-icon"></div>
            </div>
            <div class="col back-to-contact">
              {{isShippingInstructionsSubmitted ? 'Close' : 'Cancel changes'}}
            </div>
          </div>
        </button>
      </div>
      <div class="submit-button">
        <button [disabled]="isSubmitLoading" mat-raised-button class="next-button button-spinner"
          *ngIf="!isShippingInstructionsSubmitted" (click)="onSubmit()">
          <span class="button-inner">
            Submit
            <mat-spinner diameter="25" *ngIf="isSubmitLoading"></mat-spinner>
          </span>
        </button>
      </div>
    </div>
  </app-navbar>
</div>
<div class="select-contact">
  <div class="booking-shipping-instructions-container" *ngIf="isViewOrganizationContacts">
    <app-booking-organization-contacts (BackToBooking)="onBack()" (selectedContact)="onSelectedContact($event)"
      [booking]="shipmentInstruction" [contactType]="selectedContactType" [backTitle]="'Back to Shipping Instructions'"
      [containerId]="containerId">
    </app-booking-organization-contacts>
  </div>
</div>