export class PersonalNotification {
    notificationType: PersonalNotificationType;
    notificationCode: PersonalNotificationCode;
    message?: string;

    constructor(notificationCode: PersonalNotificationCode, notificationType: PersonalNotificationType, message?: string) {
        this.notificationCode = notificationCode;
        this.notificationType = notificationType;
        this.message = message;
    }
}

export enum PersonalNotificationType {
    alert = 1,
    requestForAction = 2,
    informational = 3
}

export enum PersonalNotificationCode {
    missingTaxIdNotification = 1
}