import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@ship4wd/ngx-common';
import { CreditLine, CreditLineStatusCode } from '../../shared/payments/payments.model';
import { PaymentsService } from '../../shared/payments/payments.service';
import { AppSettingsService } from '../../shared/services/app-settings/app-settings.service';

@Component({
  selector: 'app-credit-line',
  templateUrl: './credit-line.component.html',
  styleUrls: ['./credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreditLineComponent implements OnInit {
  isLoading = true;
  organizationId: string = null;
  isOrdersGridViewMode: boolean = true;
  filters: any;
  isClearFilters: boolean;
  searchText: string;
  creditLine: CreditLine = null;
  isCreditLineNew: boolean = false;
  isCreditLineRejected: boolean = false;
  isCreditLineApproved: boolean = false;
  isCreditLinePreapproved: boolean = false;
  isCreditLineRequested: boolean = false;
  isCreditLine = true;

  get isOrganizationLoaded() {
    return this.organizationId != null;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public settingsService: AppSettingsService,
    private paymentsService: PaymentsService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.getCreditLine();
  }

  onFiltersEvent(filters: any): void {
    this.filters = filters;
    this.isLoading = true;
    this.isClearFilters = false;
  }

  onSearchText(search: string): void {
    this.searchText = search;
  }

  onClearFilters(clearFilters: boolean): void {
    this.isLoading = true;
    this.isClearFilters = clearFilters;
  }

  onLoadingChange(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  private getCreditLine(): void {
    if (this.organizationId) {
      this.isLoading = true;
      this.paymentsService
        .getCreditLineByOrganization(this.organizationId)
        .subscribe(
          (data: CreditLine) => {
            this.creditLine = data;
            this.setCreditLineStatus();

            if (this.mainCreditLineUrl() && this.isCreditLinePreapproved) {
              this.router.navigate(['/creditLine/preapproved']);
            }
            else {
              this.isLoading = false;
            }
          },
          (error) => {
            this.notificationService.error(error);
            this.isLoading = false;
          }
        );
    }
  }

  private setCreditLineStatus(): void {
    switch (this.creditLine.statusId) {
      case CreditLineStatusCode.new:
        this.isCreditLineNew = true;
        break;
      case CreditLineStatusCode.rejected:
        this.isCreditLineRejected = true;
        break;
      case CreditLineStatusCode.approved:
        this.isCreditLineApproved = true;
        break;
      case CreditLineStatusCode.preApproved:
        this.isCreditLinePreapproved = true;
        break;
      case CreditLineStatusCode.requested:
        this.isCreditLineRequested = true;
        break;
      case CreditLineStatusCode.increaseRequested:
        this.isCreditLineApproved = true;
        break;
      default:
        break;
    }
  }

  private mainCreditLineUrl(): boolean {
    const segments = this.route.snapshot.url.map(segment => segment.path);

    if (segments.length === 1 && segments[0] === 'creditLine') {
      return true;
    } else {
      return false;
    }
  }
}