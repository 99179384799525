import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  GlobalTradeTypeCode,
  SelectItem,
  TradeNeedsTypeCode
} from '../../../shared/shared.model';

@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationDetailComponent {
  @Input() organizationForm: FormGroup = undefined;
  @Input() organizationRoleTypeCodes: any[] = [];
  @Input() countryAutocompleteValue: string = undefined;
  @Output() roles = new EventEmitter<any>();
  organizationRoleTypes = GlobalTradeTypeCode;

  tradeNeedsTypeCodes: SelectItem[] = [
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.tbd], value: TradeNeedsTypeCode.tbd },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.ocean], value: TradeNeedsTypeCode.ocean },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.air], value: TradeNeedsTypeCode.air },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.customs], value: TradeNeedsTypeCode.customs },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.packaging], value: TradeNeedsTypeCode.packaging },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.warehousing], value: TradeNeedsTypeCode.warehousing },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.brokerage], value: TradeNeedsTypeCode.brokerage },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.trucking], value: TradeNeedsTypeCode.trucking },
    { name: TradeNeedsTypeCode[TradeNeedsTypeCode.all], value: TradeNeedsTypeCode.all }
  ];

  get isTaxIdDisabled(): boolean {
    return !this.organizationForm.get('countryCode').value;
  }

  constructor() { }

  onChangeRole(checked: boolean, organizationRoleTypeCode: GlobalTradeTypeCode) {
    this.roles.emit({ checked, organizationRoleTypeCode });
  }
}
