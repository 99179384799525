import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { Booking, BookingStatusCode } from '../../../../../shared/bookings/bookings.model';
import { UtilityService } from '../../../../../shared/helper/utility.service';
import { UserInfoService } from '../../../../../shared/services/user-info/user-info.service';
import {
  BookingFilesDocumentsPage,
  BookingPaidStatusCode,
  BookingViewDetailsPage,
  CarriageStatusType,
  CompletedOrderStatusType,
  ShipmentType
} from '../../../../../shared/shared.model';
import { SalesforceService } from '../../../../../shared/services/salesforce.service';

@Component({
  selector: 'app-sub-order-view-dashboard',
  templateUrl: './sub-order-view-dashboard.component.html',
  styleUrls: ['./sub-order-view-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubOrderViewDashboardComponent implements OnInit {
  booking: Booking;
  allowedRebook: boolean = false;
  isLoading = false;
  isFinanceEnabled: boolean;
  carriageTypes = CarriageStatusType;
  shipmentType = ShipmentType;
  bookingStatusCode = BookingStatusCode;

  preCarriageStatuses = [
    BookingStatusCode.orderSubmitted
  ] as BookingStatusCode[];
  mainCarriageStatuses = [
    BookingStatusCode.draft
  ] as BookingStatusCode[];

  constructor(public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private utilityService: UtilityService,
    public router: Router,
    private userInfoService: UserInfoService,
    private salesforceService: SalesforceService) { }

  ngOnInit(): void {
    this.booking = this.data?.booking;
    this.allowedRebook = (this.booking.statusType.code != BookingStatusCode.draft ||
      this.getcompletedOrderStatusType().includes(this.booking.statusType.code)) && !this.booking.isCanceled;
  }

  isHigherOrderReceived(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft &&
      booking.statusType.code !== BookingStatusCode.orderSubmitted &&
      booking.statusType.code !== BookingStatusCode.orderReceived
  }

  isShowShippingInstructions(booking: Booking): boolean {
    return this.utilityService.isBookingSubmitted(booking);
  }

  onFilesDocuments(bookingId: string): void {
    this.router.navigate(['/files-documents', BookingViewDetailsPage.dashboard, BookingFilesDocumentsPage.dashboard, bookingId]);
  }

  async getConversation(): Promise<void> {
    await this.salesforceService.openChat();
  }

  isSubmitted(booking: Booking): boolean {
    return booking.statusType.code !== BookingStatusCode.draft;
  }

  isShowPayButton(booking: Booking): boolean {
    return !(this.isSubmitted(booking) &&
      booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid &&
      this.isFinanceEnabled &&
      booking.hasPayments &&
      this.userInfoService.checkIsAgent());
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  isTripDurationDefaultValue(tripDuration: string): boolean {
    return tripDuration == '00:00:00' || tripDuration == null;
  }

  displayStatusIcon(carriageType: CarriageStatusType): boolean {
    if (this.mainCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.mainCarriage;
    }

    if (this.preCarriageStatuses.includes(this.booking.statusType.code)) {
      return carriageType === CarriageStatusType.preCarriage;
    }

    return this.booking.statusType.carriageType === carriageType;
  }

  getSubmitButtonText(booking: Booking): string {
    if (booking.isCanceled) {
      return 'Canceled'
    }

    if (!this.isSubmitted(booking)) {
      return 'Resume Order'
    }

    if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled && booking.hasPayments) {
      return 'Pay Order'
    }

    return 'Details'
  }

  getShipmentIcon(): string {
    switch (this.booking?.shipmentTypeCode) {
      case ShipmentType.FCL:
        return "s4d-fcl";
      case ShipmentType.LCL:
        return "s4d-lcl";
      case ShipmentType.AIR:
        return "s4d-ac";
      default:
        break;
    }
  }

  onGetSteps(): void {
    window.open('https://ship4wd.com/support-category/post-booking-cargo-fulfillment/', "_blank")
  }

  onSubmitButton(booking: Booking): void {
    if (!this.isSubmitted(booking)) {
      this.router.navigate(['/booking', booking.id, true]);
    }
    else if (this.isSubmitted(booking) && booking.bookingPaidStatusCode !== BookingPaidStatusCode.paid && this.isFinanceEnabled) {
      this.openPayments(booking);
    }
    else {
      this.router.navigate(['/booking/view', BookingViewDetailsPage.dashboard, booking.id]);
    }
  }

  private getcompletedOrderStatusType(): BookingStatusCode[] {
    return CompletedOrderStatusType;
  }

  private openPayments(booking: Booking): void {
    this.router.navigate(['/payments', booking.id]);
  }
}
