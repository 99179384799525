import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationNotificationComponent } from './application-notification.component';
import { NotificationData } from './application-notification.model';

export const NOTIFICATION_DURATION = new InjectionToken<number>('RENTIGO_COMMON_NOTIFICATION_DURATION');

@Injectable({
  providedIn: 'root',
})
export class ApplicationNotificationService {
  private readonly DEFAULT_NOTIFICATION_DURATION: number = 3000;

  constructor(
    private snackBar: MatSnackBar,
    @Optional()
    @Inject(NOTIFICATION_DURATION)
    notificationDuration: number) {
    this.DEFAULT_NOTIFICATION_DURATION = notificationDuration || this.DEFAULT_NOTIFICATION_DURATION;
  }

  notify(data: NotificationData) {
    this.snackBar.openFromComponent(ApplicationNotificationComponent, {
      data,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      politeness: 'polite',
      panelClass: [data.panelClass, 'text-white'],
      duration: data.duration || this.DEFAULT_NOTIFICATION_DURATION
    });
  }
}
