<div class="booking-summary-container row" *ngIf="bookingSummary">
  <div class="booking-summary-header">
    <div class="row justify-content-between">
      <div class="col d-flex align-items-center">
        <h4 class="booking-summary-header-title" *ngIf="bookingSummary.customerReferenceId">
          REF: {{ bookingSummary.customerReferenceId }}
        </h4>
      </div>
      <div class="col-auto">
        <button mat-flat-button class="booking-summary-header-action"
          (click)="navigateToFilesDocuments(bookingSummary.id)">
          <app-booking-icons [iconName]="'file-icon'"></app-booking-icons>
        </button>
        <button mat-flat-button class="booking-summary-header-action" (click)="print(bookingSummary.id)">
          <img src="../../../../../assets/images/bookings/print.svg" alt="Print">
        </button>
        <button *ngIf="false" mat-flat-button class="booking-summary-header-action"
          (click)="download(bookingSummary.id)">
          <img *ngIf="isFileReady; else waitFile" src="../../../../../assets/images/bookings/download.svg"
            alt="Download">
          <ng-template #waitFile>
            <span class="material-icons load-icon">refresh</span>
          </ng-template>
        </button>
        <button mat-flat-button class="booking-summary-header-action" hidden>
          <img src="../../../../../assets/images/bookings/share.svg" alt="Share">
        </button>
        <button mat-flat-button class="booking-summary-header-action" (click)="reuse(bookingSummary.id)"
          *ngIf="allowedRebook">
          <img src="../../../../../assets/images/bookings/reuse.svg" alt="Rebook autopilot">
        </button>
      </div>
    </div>
    <div class="row mt-3" *ngIf="bookingSummary.quote?.trip?.incoterms?.length > 0">
      <div class="col">
        <div class="incoterms-container">
          <div class="row">
            <div class="col">
              <div class="section-title">
                <span>Incoterms</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="line-text">
                <span>{{ getIncotermsCodes() }}</span>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="customer-details-container">
          <div class="row">
            <div class="col">
              <div class="section-title">
                <span>Customer Details</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>SHIPPER</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary?.shipperContact?.companyName }}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>CONSIGNEE</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary?.consigneeContact?.companyName }}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>NOTIFY PARTY</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary?.notifyPartyContact?.companyName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="basic-ocean-freight-container">
          <div class="row">
            <div class="col">
              <div class="section-title">
                <span>Basic {{ bookingSummary.shipmentTypeCode === shipmentTypes.AIR ? 'Air' : 'Ocean' }} Freight</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>{{ bookingSummary.shipmentTypeCode === shipmentTypes.AIR ? 'Airline' : 'VESSEL NAME' }} </span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.vesselCode }}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>CARRIER</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.quote?.trip?.carrierScac }}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>CUT OFF</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.cutOffDate ? (bookingSummary.cutOffDate | date: "MMM dd, yyyy") : 'n/a'
                  }}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>ESTIMATED DEPARTURE</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.quote?.trip?.departureDate | date: "MMM dd, yyyy"}}</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>ESTIMATED ARRIVAL</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.quote?.trip?.arrivalDate | date: "MMM dd, yyyy" }}</span>
              </div>
            </div>
          </div>
          <div class="row line" *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL">
            <div class="col">
              <div class="line-title">
                <span>DOCUMENT CUT OFF DATE</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>n/a</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="references-container">
          <div class="row">
            <div class="col">
              <div class="section-title">
                <span>Reference(s)</span>
              </div>
            </div>
          </div>
          <div class="row line">
            <div class="col">
              <div class="line-title">
                <span>Order</span>
              </div>
            </div>
            <div class="col">
              <div class="line-text">
                <span>{{ bookingSummary.bolOrderNumber ?? 'n/a' }}</span>
              </div>
            </div>
          </div>
          <ng-container *ngIf="bookingSummary.anotherReferences">
            <div class="row line" *ngFor="let anotherReference of bookingSummary.anotherReferences; let i = index">
              <div class="col">
                <div class="line-title">
                  <span>{{ anotherReference.name }}</span>
                </div>
              </div>
              <div class="col">
                <div class="line-text">
                  <span>{{ anotherReference.value }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.FCL">
      <div *ngFor="let container of bookingSummary.containers;let i = index" class="row mt-3">
        <div class="col">
          <div class="row mt-3 justify-content-center">
            <div class="col">
              <div class="separator"></div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <h4 class="booking-summary-header-title">
                Container {{ i + 1 }}
              </h4>
            </div>
            <div class="col">
              <div class="section-title text-right">
                <span>{{ container.equipmentTypeDescription }}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="containers-container">
                <div class="row">
                  <div class="col">
                    <div class="section-title">
                      <span>Container</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Container Contents</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ container.cargoName }}</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Gross Weight</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>
                        {{ container.cargoGrossWeight }} {{ container.cargoGrossWeightUnitCode | weightUnitName }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Commodity Code</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text" *ngFor="let commodity of container.commodities; last as isLast">
                      <span>{{ commodity.commodityTypeCode }}</span>
                      <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Commodity Description</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text" *ngFor="let commodity of container.commodities; last as isLast">
                      <span>{{ commodity.commodityDescription}}</span>
                      <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Collection Date</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>
                        {{ container.collectionDate ? (container.collectionDate | date: "dd MMMM, yyyy") : 'n/a' }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Collection Time</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>
                        {{ container.collectionDate ? (container.collectionDate | date: "shortTime") : 'n/a' }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>From</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ renderContactData(container.collectContact) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="containers-container">
                <div class="row">
                  <div class="col">
                    <div class="section-title">
                      <span>Delivery</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Delivery Date</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>n/a</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Delivery Time</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>n/a</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>To</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ renderContactData(container.dropContact) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL || bookingSummary.shipmentTypeCode === shipmentTypes.AIR">
      <div *ngFor="let container of bookingSummary.containers;let i = index" class="row mt-3">
        <div class="col">
          <div class="row mt-3 justify-content-center">
            <div class="col">
              <div class="separator"></div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <h4 class="booking-summary-header-title" *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.LCL">
                LCL Shipment {{ i + 1 }}
              </h4>
              <h4 class="booking-summary-header-title" *ngIf="bookingSummary.shipmentTypeCode === shipmentTypes.AIR">
                AIR Shipment {{ i + 1 }}
              </h4>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="containers-container">
                <div class="row">
                  <div class="col">
                    <div class="section-title">
                      <span>Cargo</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Cargo Details</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ container.cargoName }}</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Commodity Code</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text" *ngFor="let commodity of container.commodities; last as isLast">
                      <span>{{ commodity.commodityTypeCode }}</span>
                      <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Commodity Description</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text" *ngFor="let commodity of container.commodities; last as isLast">
                      <span>{{ commodity.commodityDescription}}</span>
                      <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let commodity of container.commodities;let i = index">
                  <div class="row line">
                    <div class="col">
                      <div class="line-title">
                        <span>Load {{ i+1 }} Details</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="line-text">
                        <span>
                          {{ commodity.numberOfPackages }} {{ commodity.packageTypeCode | packageTypeName }}
                        </span>
                        <div *ngIf="isShowCommodityDimension(commodity)">
                          Dimensions (W) {{ commodity.dimension.width }}
                          {{ commodity.dimension.widthUnitCode | dimensionUnitName | lowercase }} x
                          (H) {{ commodity.dimension.height }}
                          {{ commodity.dimension.heightUnitCode | dimensionUnitName | lowercase }} x
                          (L) {{ commodity.dimension.length }}
                          {{ commodity.dimension.lengthUnitCode | dimensionUnitName | lowercase }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row line">
                    <div class="col">
                      <div class="line-title" *ngIf="commodity.volume && commodity.weight">
                        <span>Volume: {{ commodity.volume }}
                          {{ commodity.volumeUnitCode | volumeUnitName | titlecase }},
                          Weight: {{ commodity.weight }}
                          {{ commodity.weightUnitCode | weightUnitName | lowercase }}</span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="line-text" *ngFor="let commodity of container.commodities; last as isLast">
                        <span>{{ commodity.commodityDescription}}</span>
                        <span *ngIf="container.commodities.length > 1 && !isLast">,</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="containers-container">
                <div class="row">
                  <div class="col">
                    <div class="section-title">
                      <span>Collection</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Collection Date</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>
                        {{ container.collectionDate ? (container.collectionDate | date: "dd MMMM, yyyy") : 'n/a' }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Collection Time</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>
                        {{ container.collectionDate ? (container.collectionDate | date: "shortTime") : 'n/a' }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>From</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ renderContactData(container.collectContact) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="containers-container">
                <div class="row">
                  <div class="col">
                    <div class="section-title">
                      <span>Delivery</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Delivery Date</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>n/a</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>Delivery Time</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>n/a</span>
                    </div>
                  </div>
                </div>
                <div class="row line">
                  <div class="col">
                    <div class="line-title">
                      <span>To</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="line-text">
                      <span>{{ renderContactData(container.dropContact) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="row mt-3 justify-content-center">
          <div class="col">
            <div class="separator"></div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <h4 class="booking-summary-header-title">
              Summary
            </h4>
          </div>
        </div>
        <ng-container *ngFor="let carriage of getCarriageWiseTripLeg(bookingSummary.quote?.trip)">
          <ng-container *ngFor="let leg of carriage.legs; let i = index">
            <ng-container *ngIf="leg.rates && leg.rates.length !== 0">
              <ng-container *ngIf="(leg | checkFreightRates)">
                <div class="row mt-3">
                  <div class="col">
                    <div class="summary-container">
                      <div class="row">
                        <div class="col">
                          <div class="section-title">
                            <span>Freight Charges</span>
                          </div>
                        </div>
                      </div>
                      <div class="row line freight-rate-wrapper">
                        <div class="col-8">
                          <div class="line-title">
                            <span>{{ (leg | freightRate)?.freightRate.description }}</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="line-text d-flex align-items-center">
                            <mat-icon class="currency-icon">
                              {{ bookingSummary.quote?.currency | currencyIcon }}
                            </mat-icon>
                            <span>{{ leg.rates | totalFreightRate | number:'1.2-2' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="(leg | checkSurcharges)">
                <div class="row mt-3" *ngFor="let applicableType of leg | uniqueApplicableType">
                  <div class="col">
                    <div class="summary-container">
                      <div class="row">
                        <div class="col">
                          <div class="section-title">
                            <span>{{ applicableType | titlecase }} Charges</span>
                          </div>
                        </div>
                      </div>
                      <div class="row line"
                        *ngFor="let additionalType of leg | surchargesByApplicableType:applicableType;let i = index">
                        <div class="col-8">
                          <div class="line-title">
                            <span><span>{{ additionalType }}</span></span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="line-text d-flex align-items-center">
                            <mat-icon class="currency-icon">
                              {{ bookingSummary.quote?.currency | currencyIcon }}
                            </mat-icon>
                            <span>{{ leg.rates | totalAdditionalTypeRate:applicableType:additionalType | number:'1.2-2'
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <div class="row mt-3 justify-content-center">
          <div class="col">
            <div class="separator"></div>
          </div>
        </div>
        <div class="row mt-4 total-wrapper">
          <div class="col-7 booking-summary-header-title-wrapper">
            <h4 class="booking-summary-header-title">
              Total
            </h4>
          </div>
          <div class="col-5">
            <div class="section-title d-flex align-items-center">
              <mat-icon class="currency-icon">
                {{ bookingSummary.quote?.currency | currencyIcon }}
              </mat-icon>
              <span>{{ bookingSummary.totalPrice | number:'1.2-2' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
