<div class="credits-widget-container" [ngClass]="{ 'is-empty-container': isEmpty, 'is-preview': preview }">
  <div class="widget-wrapper">
    <div class="row widget-header">
      <div *ngIf="editable" class="col-auto me-2 widget-action">
        <mat-icon class="editable-icon">zoom_out_map</mat-icon>
      </div>
      <div class="col widget-title">
        <span *ngIf="!isEmpty">Credit line</span>
      </div>
      <div *ngIf="!editable && !isEmpty && !preview" class="col-auto widget-action">
        <span class="go-icon link-action" (click)="onGoTo()"></span>
      </div>
      <div *ngIf="editable" class="col-auto widget-action">
        <span class="remove-icon link-action" (click)="onRemove()"></span>
      </div>
      <div *ngIf="!editable && isEmpty" class="col-auto widget-action">
        <span class="info-icon link-action" [matTooltip]="toolTipMessage" matTooltipPosition="below"></span>
      </div>
    </div>
    <div class="row widget-content" [ngClass]="{ 'is-loading': isLoading || preview }">
      <div *ngIf="isLoading || preview" class="col d-flex flex-column flex-grow-1 justify-content-between is-loader">
        <div class="row">
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
          <div class="col">
            <div class="content-wrapper loader-element"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <div class="content-button loader-element"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && isError && !preview" class="col is-error">
        <span class="refresh-icon" (click)="onReload()"></span>
        <span class="mt-3">{{ errorMessage }}</span>
      </div>

      <div *ngIf="isCreditLineNew && !preview" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="is-empty-title">Apply for Credit Line</span>
        <span class="is-empty-message">Pay up to 90 days after cargo delivery​.</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
          [disabled]="isButtonLoading" (click)="initCreditLine()">
          <span class="button-inner">
            Apply Now
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="isCreditLineRequested && !preview" class="col is-empty">
        <span class="is-empty-icon"></span>
        <span class="is-empty-title">Your Credit Application Is Being Processed</span>
        <span class="is-empty-message">Your credit application is in review. Your will get an answer
          soon.</span>
      </div>

      <div *ngIf="isCreditLinePreapproved && !preview" class="col is-empty">
        <span class="is-rocket-icon"></span>
        <span class="is-empty-title">Get <span class="credit-price">$10,000</span> credit line</span>
        <span class="is-empty-message">You are pre-approved​ to the get $10,000 credit line.</span>
        <button mat-raised-button color="primary" class="mt-3 is-empty-button button-spinner"
          [disabled]="isButtonLoading" (click)="claimCreditLine()">
          <span class="button-inner">
            Claim Now
            <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="isCreditLineRejected && !preview" class="col is-empty">
        <span class="is-rejected-icon"></span>
        <span class="is-empty-title">Your credit application was rejected</span>
        <span class="is-empty-message">You may contact our support team with any inqueries</span>
      </div>

      <div *ngIf="isCreditLineApproved && !preview"
        class="col d-flex flex-column flex-grow-1 justify-content-between p-4 pt-0">
        <div class="row w-100 justify-content-between">
          <div class="col-auto">
            <div class="content-wrapper">
              <span class="content-subtitle"> Approved credit </span>
              <span class="content-title mt-1">
                {{ creditLine.totalLimit | currency : "USD" : true : "1.0-0" }}
              </span>
            </div>
          </div>
          <div class="col-auto">
            <div class="content-wrapper">
              <span class="content-subtitle"> Utilised funds </span>
              <span class="content-title mt-1">
                -{{
                creditLine.totalLimit - creditLine.availableLimit
                | currency : "USD" : true : "1.0-0"
                }}
              </span>
            </div>
          </div>
          <div class="col-auto">
            <div class="content-wrapper">
              <span class="content-subtitle"> Total available </span>
              <span class="content-title mt-1">
                {{
                creditLine.availableLimit | currency : "USD" : true : "1.0-0"
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <button *ngIf="creditLine.statusId === CreditLineStatuses.approved" mat-stroked-button color="primary"
              class="content-button" (click)="initCreditLine()">
              Ask to Increase Limit
            </button>
            <button *ngIf="
                creditLine.statusId === CreditLineStatuses.increaseRequested
              " mat-stroked-button color="primary" class="content-button disabled">
              Increase Limit Requested
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>