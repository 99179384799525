<div class="claim-credit-line-container">
  <div class="claim-credit-line-wrapper">
    <div class="claim-credit-line-list-row">
      <mat-card>
        <mat-card-content class="claim-credit-line-type">
          <div class="claim-credit-line-top">
            <div class="claim-credit-line-label">
              Active
            </div>
          </div>
          <div class="claim-credit-line-detail-top">
            <div class="claim-credit-line-detail">
              <div class="approved-credit">
                <div class="approved-credit-wrapper">
                  <span class="credit-line-title-text">Approved credit</span>
                  <span class="credit-icon"></span>
                </div>
                <div>
                  <h5 class="amount">{{ creditLine?.totalLimit | currency: 'USD':true:'1.0-0' }}</h5>
                </div>
              </div>
              <div class="utilised-fund-wrapper">
                <span class="credit-line-title-text">utilised funds</span>
                <div>
                  <h5 class="amount">-{{ creditLine?.totalLimit - creditLine?.availableLimit | currency: 'USD':true:'1.0-0' }}</h5>
                </div>
              </div>
              <div class="total-available-wrapper">
                <span class="credit-line-title-text">total available</span>
                <h5 class="amount"> {{ creditLine?.availableLimit | currency: 'USD':true:'1.0-0' }} </h5>
              </div>
            </div>
            <div class="claim-credit-line">
              <div class="claim-credit-line-button-wrapper">
                <button *ngIf="creditLine.statusId === CreditLineStatuses.approved" mat-raised-button class="claim-credit-line-button button-spinner" [disabled]="isLoading"
                  (click)="initCreditLine()">
                  <span class="button-inner">
                    Ask to Increase Limit
                    <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
                  </span>
                </button>
                <button *ngIf="creditLine.statusId === CreditLineStatuses.increaseRequested" mat-raised-button class="claim-credit-line-button disabled button-spinner">
                  <span class="button-inner">
                    Increase Limit Requested
                    <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="claim-credit-line-booking">
    <app-requests-and-payments-list [organizationId]="organizationId"></app-requests-and-payments-list>
  </div>
</div>