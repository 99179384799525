import { Component, OnInit, TemplateRef, ElementRef, ViewEncapsulation, ViewChild } from "@angular/core";
import { LayoutService } from "../layout.service";
import { LayoutMode } from "../layout.model";
import { ContentService } from "./content.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
  header: TemplateRef<ElementRef> = null;
  mode: LayoutMode;

  modes: any = LayoutMode;
  @ViewChild('mobileWrapperContent') mobileWrapperContent: ElementRef<Element>;

  constructor(
    private layoutService: LayoutService,
    private contentService: ContentService
  ) {
  }

  ngOnInit(): void {
    this.layoutService.wrapperHeader.subscribe(x => this.header = x);

    this.layoutService.mode.subscribe(x => {
      this.mode = x;
    });

    this.contentService.scrollToOptions.subscribe(options => {
      if (options) {
        this.mobileWrapperContent.nativeElement.scrollTo(options)
      }
    })
  }
}
