import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../shared/google-analytics/google-analytics.service';
import {
  Booking,
  BookingNoteAccessType,
  BookingSummary,
  Commodity,
  Note,
  SummaryQuote
} from '../../../shared/bookings/bookings.model';
import {
  PaymentStatusTransition,
  ShipmentType
} from '../../../shared/shared.model';
import { BookingsService } from '../../../shared/bookings/bookings.service';
import { ContainerCommodity, ExtenderTrip, QuotesEquipment, QuotesQuery } from '../../quotes/quotes.model';
import { Organization, TeamMember } from '../../manage-organization/manage-organization.model';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { Payment, PaymentRequest } from '../../../shared/payments/payments.model';
import { LegalNameDialogComponent } from '../../../shared/legal-name-dialog/legal-name-dialog.component';
import { PaymentsService } from '../../../shared/payments/payments.service';
import { FourtySeasPaymentService } from '../../../shared/payments/fourty-seas-payment.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { FourtySeasInitDialogService } from '../../../shared/payments/fourty-seas-init-dialog.service';
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { UserInfo } from '../../../shared/features/user-info/user-info.model';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { UtilityService } from '../../../shared/helper/utility.service';
import { QuotesService } from '../../quotes/quotes.service';
import { SupportRequestsService } from '../../support-requests/support-requests.service';
import { SupportRequestDialogData, SupportRequestTypeCode } from '../../support-requests/support-requests.model';

@Component({
  selector: 'app-booking-flow-summary',
  templateUrl: './booking-flow-summary.component.html',
  styleUrls: ["./booking-flow-summary.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BookingFlowSummaryComponent implements OnInit {
  bookingSummary: BookingSummary | any;
  booking: Booking;
  shipmentTypes = ShipmentType;
  bookingId: string;
  isLoading = true;
  isSubmitLoading = false;
  bookingCustomerNote: FormGroup;
  customerReferenceControl: FormControl = new FormControl();
  isFinanceEnabled: boolean;
  paymentRequestId: string;
  generatedReferenceId: string;
  teamMember: TeamMember;
  organization: Organization;
  isCreditOrganization = false;
  userInfo: UserInfo;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private datePipe: DatePipe,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    private organizationsService: OrganizationsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private fourtySeasInitDialogService: FourtySeasInitDialogService,
    private userInfoService: UserInfoService,
    private appSettingsService: AppSettingsService,
    private utilityService: UtilityService,
    private decimalPipe: DecimalPipe,
    private quotesService: QuotesService,
    private supportRequestsService: SupportRequestsService
  ) { }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    if (this.bookingId != null) {
      this.getBookingById(this.bookingId);
    }
    this.isFinanceEnabled = this.getIsFinanceEnabled();
    this.subscribePaymentLoader();
    this.isSubmitLoading = false;
    this.userInfo = this.userInfoService.getSettings();
  }

  ngOnDestroy(): void {
    this.supportRequestsService.updateSupportRequestDialogData(null);
  }

  onBackBooking(): void {
    if (this.bookingId != null) {
      this.router.navigate(["booking/", this.bookingId]);
    }
  }

  getBookingById(bookingId: string): void {
    this.bookingsService.getById(bookingId)
      .subscribe(bookings => {
        this.booking = bookings;
        this.generatedReferenceId = this.generateReferenceId();
        this.customerReferenceControl.setValue(!this.booking.customerReferenceId ?
          this.generatedReferenceId :
          this.booking.customerReferenceId);
      })
    of(null)
      .pipe(
        switchMap(() => this.bookingsService.getById(bookingId)),
        tap((bookings) => {
          this.booking = bookings;
          this.generatedReferenceId = this.generateReferenceId();
          this.customerReferenceControl.setValue(!this.booking.customerReferenceId ?
            this.generatedReferenceId :
            this.booking.customerReferenceId);
        }),
        switchMap(() => this.getOrganizationById(this.booking.organizationId)),
        tap((x: Organization) => {
          this.isCreditOrganization = x.creditOrganization;
          this.organization = x;
        }),
        switchMap(() => this.bookingsService.getSummaryById(bookingId)))
      .subscribe((bookingSummary) => {
        this.bookingSummary = bookingSummary;
        this.supportRequestsService.updateSupportRequestDialogData(this.createSupportRequestDialogData());
        this.createNotesForm(this.bookingSummary?.notes[0]);
      }, () => { })
      .add(() => {
        this.isLoading = false;
        this.customerReferenceControl.disable();
      });
  }

  getCarriageWiseTripLeg(trip: ExtenderTrip): any[] {
    const carriageTripLegs = [];
    if (trip?.preCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.preCarriageTripLeg);
    }
    if (trip?.mainCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.mainCarriageTripLeg);
    }
    if (trip?.postCarriageTripLeg !== null) {
      carriageTripLegs.push(trip.postCarriageTripLeg);
    }
    return carriageTripLegs;
  }

  onSubmitBooking(): void {
    if (this.hasOrganizationMissingData()) {
      const dialogRef = this.dialog.open(LegalNameDialogComponent, {
        autoFocus: false,
        data: {
          organization: this.organization
        },
        panelClass: 'legal-name-dialog-container'
      }).afterClosed().subscribe(
        (data) => {
          if (data?.result === true) {
            if (this.isFinanceEnabled)
              this.submitBooking(this.isCreditOrganization);
            else
              this.submitBookingWithoutFinance();
          }
          else {
            return;
          }
        });
    }
    else {
      if (this.isFinanceEnabled)
        this.submitBooking(this.isCreditOrganization);
      else
        this.submitBookingWithoutFinance();
    }
  }

  onSaveDraft(): void {
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    this.bookingsService.updateBooking(this.bookingId, this.booking)
      .subscribe(() => {
        this.navigateToDashboard();
      },
        (error) => this.notificationService.error(error))
      .add(() => (this.isSubmitLoading = false));
  }

  print(id: string): void {
    window.open(`/booking/pdf/${id}?mode=print`, '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
  }

  checkDateNotNullOrEmpty(date: string): boolean {
    return this.utilityService.isNotNullOrMinDateValue(date);
  }

  getFormattedBookingAddress(bookingSearchType: 'from' | 'to'): string {
    const bookingSearch = bookingSearchType === 'from' ? this.bookingSummary.fromBookingSearch : this.bookingSummary.toBookingSearch;
    let addressParts: string[] = [];

    if (bookingSearch?.street) {
      addressParts.push(bookingSearch.street);
    }

    if (bookingSearch?.displayName) {
      addressParts.push(bookingSearch.displayName);
    }

    if (bookingSearch?.countryName) {
      addressParts.push(bookingSearch.countryName);
    }

    if (bookingSearch?.postcode) {
      addressParts.push(bookingSearch.postcode);
    }

    return addressParts.join(', ');
  }

  isShowCommodityDimension(commodity: Commodity): boolean {
    return commodity.dimension != null
      && commodity.dimension?.width != null
      && commodity.dimension?.height != null
      && commodity.dimension?.length != null;
  }

  getCutOffDateTitle(quote: SummaryQuote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.bookingSummary?.shipmentTypeCode == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.datePipe.transform(cutOffDate, dateFormat) || 'n/a' : defaultTitle;
  }

  private submitBooking(isCreditOrganization: boolean): void {
    if (!isCreditOrganization)
      this.fourtySeasInitDialogService.openFourtySeasInitDialog();

    this.booking.customerReferenceId = this.customerReferenceControl?.value;
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.updateBookingAndStatusAsync()),
        tap(() => {
          this.removeSpecificQuoteResultFromStorage();
        }),
        switchMap(() => this.createPaymentAndPaymentRequest(isCreditOrganization)),
        switchMap((paymentRequestId) => {
          return !isCreditOrganization ?
            this.fourtySeasPaymentService.init(paymentRequestId as string, this.userInfo?.teamMemberId, this.booking,
              PaymentStatusTransition.checkoutInitialized, "dashboard") :
            of(null);
        }))
      .subscribe(() => {
        if (isCreditOrganization) {
          this.navigateToDashboard();
        }
      },
        (error) => {
          this.fourtySeasInitDialogService.closeFourtySeasInitDialog();
          if (error.error == "Payment not enabled.") {
            this.notificationService.error(error);
            this.navigateToDashboard();
          } else {
            this.googleAnalyticsService.submitBookingFailed();
            this.sendFunnelBookingError()
            this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
          }
        }).add(() => (this.isSubmitLoading = false));
  }

  private submitBookingWithoutFinance(): void {
    this.booking.customerReferenceId = this.customerReferenceControl?.value;
    this.booking.notes = [this.bookingCustomerNote.value];
    this.isSubmitLoading = true;

    of(null)
      .pipe(
        switchMap(() => this.updateBookingAndStatusAsync()),
        tap(() => {
          this.removeSpecificQuoteResultFromStorage();
        }))
      .subscribe(() => { this.navigateToDashboard() },
        (error) => {
          this.googleAnalyticsService.submitBookingFailed();
          this.sendFunnelBookingError()
          this.openPopup('Error', 'An error occurred while submitting your booking, try again.', false);
        }).add(() => (this.isSubmitLoading = false));
  }

  private updateBookingAndStatusAsync(): Observable<Booking | any> {
    if (this.booking.notes.length > 0) {
      this.booking.notes[0].userId = this.userInfo?.userId;
      this.booking.notes[0].userName = this.userInfo?.fullName;
    }

    const settingData = this.appSettingsService.getSettings();
    this.booking.organizationName = settingData.organizationName;
    return this.bookingsService.updateBookingAndStatus(this.booking.id, this.booking, true);
  }

  private createPaymentAndPaymentRequest(isCreditOrganization: boolean): Observable<any> {
    const payment = {
      organizationId: this.booking.organizationId,
      teamMemberId: this.userInfo?.teamMemberId
    } as Payment;

    const paymentRequest = {
      bookingId: this.booking.id,
      payment: payment
    } as PaymentRequest;

    return this.paymentsService.createPaymentAndPaymentRequest(paymentRequest, isCreditOrganization);
  }

  public openPopup(title: string, message: string, isSuccess: boolean): void {
    const url = document.location.href.replace(window.location.origin, "");

    if (isSuccess) {
      const successUrl = this.getUrl(true, url);
      this.location.go(successUrl);
    } else {
      const failedUrl = this.getUrl(false, url);
      this.location.go(failedUrl);
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'no-booking-dialog-container',
      autoFocus: false,
      data: {
        title: title,
        message: message,
        showConfirmButton: true,
        confirmButtonText: "Close"
      },
    }).afterClosed().subscribe(() => {
      if (isSuccess) {
        this.navigateToDashboard();
      }

      const combined = this.removeParamFromUrl(url);
      this.location.go(combined);
    });
  }

  private generateReferenceId(): string {
    const date = new Date();
    return `${this.booking.from}_${this.booking.to}_${this.datePipe.transform(date, 'dd')}_` +
      `${this.datePipe.transform(date, 'MM')}_${this.datePipe.transform(date, 'yyyy')}_` +
      `${this.datePipe.transform(date, 'hh')}_${this.datePipe.transform(date, 'mm')}`;
  }

  public removeParamFromUrl(url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];
    const combined = firstPart + '#' + secondPart;
    return combined;
  }

  private getOrganizationById(organizationId: string): Observable<Organization | any> {
    return this.organizationsService.getOrganization(organizationId);
  }

  private hasOrganizationMissingData(): boolean {
    return this.organization?.legalName == null
      || this.organization?.legalName?.length <= 0
      || this.isInvalidTaxId(this.organization?.taxId ?? null);
  }

  private isInvalidTaxId(taxId: string): boolean {
    return !taxId || taxId.toLowerCase() == 'taxid';
  }

  private navigateToDashboard(): void {
    this.router.navigate(["/dashboard"]);
  }

  public getUrl(isSuccess: boolean, url: string): string {
    const firstPart = url.split('#')[0];
    const secondPart = url.split('#')[1];

    if (!isSuccess) {
      return firstPart + '&p=failed#' + secondPart;
    } else {
      return firstPart + '&p=submitted#' + secondPart;
    }
  }

  private getIsFinanceEnabled(): boolean {
    const environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

    switch (environmentName) {
      case 'qa':
        return environment.qa.isFinanceEnabled;
      case 'sb':
        return environment.sb.isFinanceEnabled;
      default:
        return environment.isFinanceEnabled;
    }
  }

  private subscribePaymentLoader(): void {
    this.fourtySeasPaymentService.paymentLoaderBehaviorSubject.subscribe((value: boolean | undefined) => {
      if (value !== undefined) {
        this.isSubmitLoading = !value;
      }
    })
  }

  private createNotesForm(note?: Note): void {
    this.bookingCustomerNote = this.fb.group({
      id: [note ? note.id : null],
      bookingId: [note ? note.bookingId : ''],
      value: [note ? note.value : ''],
      createTimeUtc: [note ? note.createTimeUtc : ''],
      updateTimeUtc: [note ? note.updateTimeUtc : ''],
      noteAccessType: [note ? note.noteAccessType : BookingNoteAccessType.customer],
      userId: [note ? note.userId : null],
      userName: [note ? note.userName : '']
    });
  }

  private sendFunnelBookingError(): void {
    this.googleAnalyticsService.errorFunnelBooking({
      actionName: "payment didn’t accept",
      fieldName: "Pay Now",
      errorValue: 'An error occurred while submitting your booking, try again.',
      shipmentType: this.booking.shipmentTypeCode
    })
  }

  private removeSpecificQuoteResultFromStorage(): void {
    var containers = [];
    var quoteQuery = {
      shipmentType: this.bookingSummary?.shipmentTypeCode,
      from: this.bookingSummary?.fromBookingSearch,
      to: this.bookingSummary?.toBookingSearch,
      fromDate: this.datePipe.transform(this.bookingSummary?.containers[0]?.collectionDate, 'yyyy-MM-dd'),
      isHazardous: this.bookingSummary?.bookingAdditionalInformation?.isHazardous
    } as QuotesQuery;

    if (this.bookingSummary?.shipmentTypeCode === ShipmentType.FCL) {
      this.bookingSummary?.containers?.forEach((container) => {
        if (!containers.find(x => x.equipmentCode === container.equipmentTypeCode)) {
          containers.push({
            equipmentCode: container.equipmentTypeCode,
            quantity: this.getContainerQuantity(container.equipmentTypeCode)
          } as QuotesEquipment);
        }
      })
      quoteQuery.equipments = containers;
    } else {
      this.bookingSummary?.containers?.forEach((container) => {
        container?.commodities?.forEach((commodity) => {
          containers.push({
            numberOfPackages: commodity.numberOfPackages,
            weightAmount: parseFloat(this.decimalPipe.transform(commodity.weight, '1.2-2').replace(',', '')),
            totalVolumeAmount: parseFloat(this.decimalPipe.transform(commodity.volume, '1.2-2')),
            volumeAmount: parseFloat(this.decimalPipe.transform(commodity.volume, '1.2-2')),
            weightUnit: commodity.weightUnitCode,
            volumeUnit: commodity.volumeUnitCode,
            height: commodity.dimension.height,
            width: commodity.dimension.width,
            length: commodity.dimension.length
          } as ContainerCommodity)
        })
      })
      quoteQuery.containerCommodities = containers;
    }

    const quoteStorageKey = this.quotesService.prepareKeyForQuoteStorage(quoteQuery);
    const oldQuotesResult = localStorage.getItem(quoteStorageKey);

    if (oldQuotesResult) {
      localStorage.removeItem(quoteStorageKey);
    }
  }

  private getContainerQuantity(equipmentTypeCode: String): Number {
    var matchEquipment = this.bookingSummary?.containers.filter(x => x.equipmentTypeCode === equipmentTypeCode);
    return matchEquipment.length;
  }

  private createSupportRequestDialogData(): SupportRequestDialogData {
    return {
      isPopupAutoOpened: false,
      supportRequestType: SupportRequestTypeCode.booking,
      bolOrderNumber: this.booking.bolOrderNumber,
      quoteSearchId: this.booking.quoteSearchId
    } as SupportRequestDialogData
  }
}
