import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavComponent implements OnInit {
  @Input() show: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }
}
