import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EnvironmentsService, EnvironmentsServiceConfig, NotificationService } from '@ship4wd/ngx-common';
import * as uuid from "uuid";
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { NewFlowShippingLocationFieldsComponent } from '../../../../../desktop/quotes/quote-search-flow/flow-params/new-flow-shipping-location/new-flow-shipping-location-fields/new-flow-shipping-location-fields.component';
import { ROLLUP_DIALOG_DATA } from '../../../../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../../../../mobile/rollup-dialog/rollup-dialog.service';
import { AppSettingsService } from '../../../../../shared/services/app-settings/app-settings.service';
import { StringCutterService } from '../../../../../shared/services/text-cutter/stringCutter.service';
import {
  AddressTypeCode,
  GoogleAddress,
  LocationType,
  SearchType,
  ShipmentType,
  ShipperType,
  SupportedCountriesSearchResponse,
  TripsSearchBaseViewModel,
  TripsSearchFromViewModel,
  TripsSearchLocationAnalyticsViewModel,
  TripsSearchLocationsAddressRequest,
  TripsSearchLocationsRequest,
  TripsSearchViewModelRequest,
  TripsSearchViewModelResponse
} from '../../../../../shared/shared.model';
import { SupportedCountriesSearch, SupportedCountry } from '../../../../../shared/supported-countries/supported-countries.model';
import { SupportedCountriesService } from '../../../../../shared/supported-countries/supported-countries.service';
import { TripUnLocodeAutocompleteService } from '../../../../../shared/trip-un-locode-autocomplete/trip-un-locode-autocomplete.service';
import { environment } from '../../../../../../environments/environment';
import { QuotesService } from '../../../../../desktop/quotes/quotes.service';
import { GoogleAnalyticsService } from '../../../../../shared/google-analytics/google-analytics.service';
import { SalesforceService } from '../../../../../shared/services/salesforce.service';

@Component({
  selector: 'app-flow-new-location',
  templateUrl: './flow-new-location.component.html',
  styleUrls: ['./flow-new-location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowNewLocationComponent implements OnInit {
  searchTerm: FormControl = new FormControl();
  searchTermTemp: FormControl = new FormControl();
  selectedAddressType: AddressTypeCode | string = "";
  filteredCountries: SupportedCountriesSearchResponse[] = [];
  selectedSupportedCountry: string = "";
  doorAddressControl = new FormControl();
  isDomesticCountry: boolean = false;
  selectedLocation: TripsSearchFromViewModel = null;
  isMissingAddress: boolean = false;
  supportedCountries: SupportedCountry[];
  addressTypeCode = AddressTypeCode;
  addressType = AddressTypeCode;
  googleAddresses: GoogleAddress[] = [];
  googleSessionToken: google.maps.places.AutocompleteSessionToken = undefined;
  addressTypeCollections: string[] = [];
  isLoading: boolean = false;
  isCountryLoading: boolean = false;
  isCountrySelected: boolean = false;
  locationsResponse: TripsSearchViewModelResponse = null;
  isPostcode: boolean = false;
  suggestedLocality: string = null;
  sortedMap: any = [];
  sortedMap$: any = new Observable<any>();
  isLocationAutocompleteSearchEnable: boolean;
  isSearchFromPredefined: boolean = false;
  locations: Map<LocationType, TripsSearchBaseViewModel[]> = new Map<LocationType, TripsSearchBaseViewModel[]>();
  shipperType: ShipperType;
  selectedGoogleAddress: GoogleAddress;
  isFindingAddressLoader: boolean = false;
  missingAddressText: string = '';
  currentShippingType: ShipmentType;
  isExpanded: boolean = true;
  searchType = SearchType;
  isHide: boolean = false;
  fromOldShipmentType: boolean = false;
  toOldShipmentType: boolean = false;
  googleAddressChange = new Subject<string>();
  control: FormControl;
  isSelected: boolean = false;
  shipmentType = ShipmentType.FCL;
  type: SearchType = SearchType.from;
  isReuse: boolean = false;
  from = null;
  to = null;
  searchId = '';
  pageSize: number = 10;
  minimumLength: number = 1;
  selectedLocationFromParent: TripsSearchFromViewModel;
  recentQuotes: any = [];
  isOpen: boolean = false;
  isCountryFocused: boolean = false;
  isLocationFocused: boolean = false;
  isStartedTyping: boolean;
  searchedLocation: string;
  environmentsService: EnvironmentsService;
  environmentName: string;
  mostPopularStates: any;
  isMostPopularActive: boolean = false;
  isMostPopularMoreLoading: boolean = false;
  mostPopularItemsPageSize: number = 5;
  mostPopularItemsPageNo: number = 1;
  isControlEmpty: boolean = false;

  @ViewChild('trigger', { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  get selectedCountry(): SupportedCountriesSearchResponse {
    return this.filteredCountries.find(x => x.countryCode == this.selectedSupportedCountry)
  }

  constructor(public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private supportedCountriesService: SupportedCountriesService,
    private notificationService: NotificationService,
    private tripUnLocodeAutocompleteService: TripUnLocodeAutocompleteService,
    private settingsService: AppSettingsService,
    private stringCutterService: StringCutterService,
    private location: Location,
    private quotesService: QuotesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private salesforceService: SalesforceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.type = data.type;
    this.searchId = data.searchId;
    this.shipmentType = data.shipmentType;
    if (this.type === SearchType.from) {
      this.fromOldShipmentType = this.data?.oldShipmentType;
    }
    else if (this.type === SearchType.to) {
      this.toOldShipmentType = this.data?.oldShipmentType;
    }

    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname)
    this.mostPopularStates = this.getIsMostPopularEnabled();
  }

  ngOnInit(): void {
    this.getSupportedCountries();
    this.fillRecentQuotesFromStorage();
    this.onValueChanged();
    this.getGoogleAddress();
    this.searchTerm.setValue("");
    this.doorAddressControl.setValue("");

    if (this.data.control !== undefined) {
      this.control = this.data.control;
      this.selectedLocation = this.data.control.value;
      this.selectedGoogleAddress = this.data.selectedGoogleAddress;
      this.from = this.data?.from;
      this.to = this.data?.to;

      if ((this.fromOldShipmentType || this.toOldShipmentType)
        && this.data?.oldShipmentType && this.data?.oldShipmentType !== this.shipmentType) {
        this.clearCountry();
        this.clearLocation();
        this.control.patchValue("");

        this.fromOldShipmentType = false;
        this.toOldShipmentType = false;
      }
    }

    if (this.data.control.value !== "") {
      this.addressTypeCollectionsBasedOnShipmentType();
      this.selectedAddressType = '';
      this.control = this.data.control;
      this.selectedLocation = this.control.value;
      this.isLocationAutocompleteSearchEnable = NewFlowShippingLocationFieldsComponent.getIsLocationAutocompleteSearch();
      this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(this.selectedLocation?.displayName, 20);

      if (this.type === SearchType.from) {
        this.selectedAddressType = this.getAddressType(this.control.value?.locationType);
      } else if (this.type === SearchType.to) {
        this.selectedAddressType = this.getAddressType(this.control.value?.locationType);
      }

      if (this.selectedAddressType !== AddressTypeCode.door.toString() &&
        this.selectedAddressType !== AddressTypeCode.warehouse.toString()) {
        this.searchTerm.setValue(this.data.control.value?.cuttedDisplayName);
      } else {
        this.doorAddressControl.setValue(this.data.control.value?.fullAddress);
      }

      if (this.selectedLocation?.fullAddress) {
        this.doorAddressControl.patchValue(this.selectedLocation?.fullAddress);
      } else {
        this.doorAddressControl.patchValue(this.selectedGoogleAddress.description);
      }
    } else {
      this.addressTypeCollectionsBasedOnShipmentType();
      this.control.disable();
      this.searchTerm.disable();
      this.doorAddressControl.disable();
      this.isLocationAutocompleteSearchEnable = NewFlowShippingLocationFieldsComponent.getIsLocationAutocompleteSearch();
    }

    this.checkIfSearchFromPredefinedRequired();
    this.fillReuseQuote();
  }

  onSelectAddressType(addressType: AddressTypeCode, isClearDependentControl: boolean = true): void {
    if (isClearDependentControl) {
      this.clearCountry();
      this.clearLocation();
    }

    this.getFilterSupportedCountries(addressType);
  }

  setFlagIconBasedOnSelectedCountry(): SupportedCountriesSearchResponse {
    return this.filteredCountries?.find(x => x.countryCode == this.selectedSupportedCountry)
  }

  onAutocompleteChange(event: any): void {
    if (!this.isCountrySelected) {
      this.clearLocation();
      this.control.disable();
      this.searchTerm.disable();
      this.doorAddressControl.disable();
      this.filteredCountries = this.getFilterSupportedCountries(Number(this.selectedAddressType))
        .filter(x => x.countryName.toLocaleLowerCase().includes(event.toLocaleLowerCase()) ||
          x.countryCode.toLocaleLowerCase().includes(event.toLocaleLowerCase()) ||
          x.description?.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    }

    this.isCountrySelected = false;
  }

  displayFn(tripsSearchBaseViewModel: TripsSearchBaseViewModel): string {
    return tripsSearchBaseViewModel && tripsSearchBaseViewModel.displayName ? tripsSearchBaseViewModel.displayName : null;
  }

  displaySupportedCountryFn = (countryCode: string | null) =>
    countryCode && this.filteredCountries.find(x => x.countryCode === countryCode)?.countryName;

  displayAddressFn(googleAddress: any): string {
    return googleAddress && googleAddress.description ? googleAddress.description : '';
  }

  onSelectSupportedCountry(event: MatOptionSelectionChange, value: SupportedCountriesSearchResponse): void {
    if (!event.isUserInput) {
      return;
    }

    this.mostPopularItemsPageNo = 1;
    this.isCountrySelected = true;
    if (this.isDomesticRoute(value.countryCode)) {
      this.isDomesticCountry = true;
      this.clearLocation();
      this.control.disable();
      this.searchTerm.disable();
      this.doorAddressControl.disable();
      this.onAddressAnalyticsCountryError(`complete choose from list`, "Destination cannot be within the same country")
    } else {
      this.isDomesticCountry = false;
      this.clearLocation();
      this.control.enable();
      this.searchTerm.enable();
      this.doorAddressControl.enable();
      this.selectedSupportedCountry = value.countryCode;

      this.mostPopularItemsPageSize = 5;
      this.getMostPopularItems();
    }

    this.countryChanged(false)
  }

  onSelect(value: TripsSearchFromViewModel, emitNext: boolean = true): void {
    if (this.isDomesticRoute(value.country)) {
      return;
    }

    this.searchTerm.setValue(' ')
    this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(value, this.type, this.shipmentType);
    this.isSelected = true;
    this.selectedLocation = value;
    this.selectedLocation.searchId = this.locationsResponse?.searchId;
    this.control.setValue(this.selectedLocation);
    this.isHide = false;

    if (!emitNext) {
      return;
    }

    if (this.type == SearchType.from) {
      this.googleAnalyticsService.newSearchLocationOriginAddressCodeSelect(this.getNewSearchLocationAnalytics())
    } else if (this.type == SearchType.to) {
      this.googleAnalyticsService.newSearchLocationDestinationAddressCodeSelect(this.getNewSearchLocationAnalytics())
    }
  }

  onAddressTypeSelectOpen(event: any): void {
    this.isOpen = event ? true : false;
  }

  onInputFocus(input: string): void {
    switch (input) {
      case 'country':
        this.isCountryFocused = true;
        break;
      case 'location':
        this.isLocationFocused = true;
        break;
    }
  }

  onInputBlur(input: string): void {
    switch (input) {
      case 'country':
        this.isCountryFocused = false;
        break;
      case 'location':
        this.isLocationFocused = false;
        break;
    }
  }

  isDomesticRoute(countryCode: string): boolean {
    if ((this.type == SearchType.from && this.to != null && this.to != '' && countryCode == this.to.country)
      || (this.type == SearchType.to && this.from != null && countryCode == this.from.country)) {
      return true;
    }
    return false;
  }

  getLocationTooltipText(location: TripsSearchFromViewModel): string {
    return this.isDomesticRoute(location.country) ? 'Destination cannot be within the same country' : location.displayName;
  }

  disableDomesticOption(event: any, location: TripsSearchBaseViewModel): void {
    if (this.isDomesticRoute(location.country)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  setGoogleLocation(googleAddress: GoogleAddress): void {
    this.getGoogleAddressLocation(googleAddress);
  }

  getGoogleAddressLocation(googleAddress: GoogleAddress): void {
    this.doorAddressControl.patchValue(googleAddress?.description);
    this.selectedGoogleAddress = googleAddress;
    this.isMissingAddress = false;
    this.isFindingAddressLoader = true;
    this.selectedLocation = null;
    let map = new google.maps.Map(document.createElement('div'));
    const placeService = new google.maps.places.PlacesService(map);

    var request = {
      placeId: googleAddress.place_id,
      fields: ['address_components', "formatted_address"],
      types: ["address"],
      sessionToken: this.googleSessionToken
    };

    placeService.getDetails(request, (place, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      let address1, postcode, city, sublocality, state, country = null;
      address1 = place.formatted_address;
      for (const address of place.address_components) {
        const componentType = address.types[0];

        switch (componentType) {
          case "postal_code": {
            postcode = `${address.long_name}`;
            break;
          }
          case "locality":
            city = address.long_name;
            break;
          case "sublocality_level_1":
            sublocality = address.long_name;
            break;
          case "administrative_area_level_1": {
            state = address.short_name;
            break;
          }
          case "country":
            country = address.short_name;
            break;
        }
      }

      const query = new TripsSearchLocationsAddressRequest();
      query.country = country;
      query.state = state;
      query.city = city;
      query.sublocality = sublocality;
      query.postcode = postcode;
      query.fullAddress = address1;
      query.shipmentType = this.shipmentType;
      query.from = this.from === '' ? new TripsSearchFromViewModel : this.from;
      query.type = this.type;
      query.addressTypeCode = Number(this.selectedAddressType) as AddressTypeCode;
      query.profile = {
        organizationId: this.settingsService.getSettings().organizationId,
        countryCode: this.settingsService.getSettings().countryCode
      }

      this.tripUnLocodeAutocompleteService.getTripSearchLocationByAddressAsync(query)
        .subscribe((response: TripsSearchViewModelResponse) => {
          if (response.tripsSearchList.length > 0) {
            const locationResult = response.tripsSearchList[0] as TripsSearchFromViewModel;
            if (locationResult.zipCode != null) {
              this.isSelected = true;
              this.control.setValue(locationResult);
              this.selectedLocation = locationResult;
              this.selectedLocation.searchId = response.searchId;
              this.selectedLocation.fullAddress = address1;
              this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(locationResult?.displayName, 20);
              if (city != null) {
                this.selectedLocation.cityName = city;
              }
              this.tripUnLocodeAutocompleteService.addSelectedTripsSearchToStorage(locationResult, this.type, this.shipmentType);
              this.isHide = false;

              if (this.type == SearchType.from) {
                this.googleAnalyticsService.newSearchLocationOriginAddressCodeSelect(this.getNewSearchLocationAnalytics())
              } else if (this.type == SearchType.to) {
                this.googleAnalyticsService.newSearchLocationDestinationAddressCodeSelect(this.getNewSearchLocationAnalytics())
              }
            } else {
              this.isMissingAddress = true;
              this.missingAddressText = 'Zip code missing for the selected address, please choose another address';
              this.onAddressAnalyticsError(`select city or port/airport city`, this.missingAddressText)
            }
          } else {
            this.isMissingAddress = true;
            this.missingAddressText = 'Not providing service at selected address. Please choose another address.';
            this.onAddressAnalyticsError(`select city or port/airport city`, this.missingAddressText)
          }
        })
        .add(() => this.isFindingAddressLoader = false);
    });
  }

  async openEmbeddedChat(): Promise<void> {
    await this.salesforceService.openChat();
  }

  onClicked(event: Event, isMain: boolean = false): void {
    if (isMain) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.isSelected && this.locationsResponse?.tripsSearchList?.length == undefined) {
      this.locations.clear();
      this.sortedMap = [];
    }
    else {
      this.getMostPopularItems();
    }

    setTimeout(() => {
      this.isSelected = false;
    }, 0);
  }

  onClickPanel(state: boolean): void {
    this.isExpanded = state;

    if (this.type == SearchType.from) {
      if (this.isExpanded) {
        this.googleAnalyticsService.newSearchLocationRecentOriginsStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationRecentOriginsComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (this.isExpanded) {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onRecentQuoteSelect(quote): void {
    if (!this.isDomesticRoute(quote.country)) {
      this.isSelected = true;
      this.isExpanded = false;
      this.selectedLocation = quote as TripsSearchFromViewModel;
      this.selectedLocation.searchId = uuid.v4();
      this.selectedLocation.cuttedDisplayName = this.stringCutterService.cutter(this.selectedLocation?.displayName, 20);
      this.control.setValue(quote);
      this.searchTerm.setValue(this.selectedLocation?.cuttedDisplayName);
      this.doorAddressControl.patchValue(this.selectedLocation?.fullAddress);
      this.selectedAddressType = this.getAddressType(quote?.locationType);
      this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
      this.selectedSupportedCountry = quote?.country;
      this.control.enable();
      this.searchTerm.enable();
      this.doorAddressControl.enable();
      this.onConfirm(true);
      this.isDomesticCountry = false;
    } else {
      this.isDomesticCountry = true;
      this.clearLocation();
      this.control.disable();
      this.searchTerm.disable();
      this.doorAddressControl.disable();
      this.onAddressAnalyticsCountryError(`complete choose from list`, "Destination cannot be within the same country");
      this.selectedAddressType = this.getAddressType(quote?.locationType);
      this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
      this.selectedSupportedCountry = quote?.country;
    }
  }

  onLocationTypeClick(value: boolean): void {
    if (value) {
      this.isHide = this.selectedAddressType && this.selectedSupportedCountry && !this.isDomesticCountry ? value : !value;
    } else {
      this.isHide = value;
    }

    this.searchTerm.setValue('');
    this.isControlEmpty = true;
  }

  onConfirm(isFromRecent: boolean = false): void {
    const data = {
      control: this.control,
      searchId: this.searchId,
      shipperType: this.shipperType,
      selectedGoogleAddress: this.selectedGoogleAddress
    }

    this.onSubmitAddress(isFromRecent);
    this.dialogRef.close(data);
  }

  onGoogleAddressChange(value: any): void {
    this.googleAddressChange.next(value);
    this.onAutocompleteAddressChange(value);
  }

  getGoogleAddress(): void {
    this.googleAddressChange
      .pipe(
        startWith(''),
        debounceTime(environment.holdTypingTimeMiliseconds),
        distinctUntilChanged(),
        startWith('')
      ).subscribe((value: any) => {
        this.googleAddresses = [];
        this.selectedGoogleAddress = undefined;
        if (value !== "") {
          this.getPlaceAutocomplete(value);
        }
      });
  }

  addressTypeChanged(isOpened: boolean): void {
    if (this.type == SearchType.from) {
      if (isOpened) {
        this.googleAnalyticsService.newSearchLocationOriginAddressTypeStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginAddressTypeComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isOpened) {
        this.googleAnalyticsService.newSearchLocationDestinationAddressTypeStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationAddressTypeComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  countryChanged(isStarted: boolean): void {
    if (this.type == SearchType.from) {
      if (isStarted) {
        this.googleAnalyticsService.newSearchLocationOriginCountryStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginCountryComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isStarted) {
        this.googleAnalyticsService.newSearchLocationDestinationCountryStart(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationCountryComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onAutocompleteAddressChange(event: string): void {
    if (!this.isStartedTyping) {
      if (this.type == SearchType.from) {
        this.googleAnalyticsService.newSearchLocationOriginAddressCodeStartTyping(this.getNewSearchLocationAnalytics())
      } else if (this.type == SearchType.to) {
        this.googleAnalyticsService.newSearchLocationDestinationAddressCodeStartTyping(this.getNewSearchLocationAnalytics())
      }
    }

    if (event.length === 0) {
      this.getMostPopularItems();
    }

    this.isStartedTyping = event?.length > 0;
    this.searchedLocation = event;
  }

  onSubmitAddress(isFromRecent: boolean = false): void {
    if (this.type == SearchType.from) {
      if (isFromRecent) {
        this.googleAnalyticsService.newSearchLocationRecentOriginsComplete(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationOriginAddressComplete(this.getNewSearchLocationAnalytics())
      }
    } else if (this.type == SearchType.to) {
      if (isFromRecent) {
        this.googleAnalyticsService.newSearchLocationRecentDestinationsComplete(this.getNewSearchLocationAnalytics())
      } else {
        this.googleAnalyticsService.newSearchLocationDestinationAddressComplete(this.getNewSearchLocationAnalytics())
      }
    }
  }

  onAddressAnalyticsError(actionName: string, error: string): void {
    const newSearchAnalytics = this.getNewSearchLocationAnalytics();
    newSearchAnalytics.actionName = actionName
    if (this.type == SearchType.from) {
      newSearchAnalytics.originCity = this.searchedLocation;
      this.googleAnalyticsService.newSearchLocationOriginAddressError(newSearchAnalytics, error)
    } else if (this.type == SearchType.to) {
      newSearchAnalytics.destinationCity = this.searchedLocation;
      this.googleAnalyticsService.newSearchLocationDestinationAddressError(newSearchAnalytics, error)
    }
  }

  onAddressAnalyticsCountryError(actionName: string, error: string): void {
    const newSearchAnalytics = this.getNewSearchLocationAnalytics();
    newSearchAnalytics.actionName = actionName
    if (this.type == SearchType.from) {
      this.googleAnalyticsService.newSearchLocationOriginAddressCountryError(newSearchAnalytics, error)
    } else if (this.type == SearchType.to) {
      this.googleAnalyticsService.newSearchLocationDestinationAddressCountryError(newSearchAnalytics, error)
    }
  }

  onLoadMore(): void {
    this.isMostPopularMoreLoading = true;
    this.mostPopularItemsPageNo++;
    this.searchTermTemp.setValue(this.searchTermTemp.value + '*');
  }

  isShowFullAddress(locationType: LocationType): boolean {
    return locationType === LocationType.townCity;
  }

  removeLocation(): void {
    this.clearLocation();

    if (this.selectedSupportedCountry)
      this.searchTermTemp.setValue('*' + this.selectedSupportedCountry);
  }

  private clearCountry(): void {
    this.filteredCountries = [];
    this.selectedSupportedCountry = "";
    this.control.disable();
    this.doorAddressControl.disable();
    this.searchTerm.disable();
    this.isDomesticCountry = false;
  }

  private clearLocation(): void {
    this.isSelected = false;
    this.selectedLocation = null;
    this.searchTerm.setValue("");
    this.doorAddressControl.patchValue("");
    this.isMissingAddress = false;
  }

  private getFilterSupportedCountries(addressType: AddressTypeCode): SupportedCountriesSearchResponse[] {
    this.filteredCountries = this.supportedCountries;
    return this.filteredCountries;
  }

  private getPlaceAutocomplete(value: string): void {
    this.googleSessionToken = new google.maps.places.AutocompleteSessionToken();
    const service = new google.maps.places.AutocompleteService();
    var request =
    {
      input: value,
      componentRestrictions: { country: this.getCountryCodes() },
      fields: ["address_components"],
      sessionToken: this.googleSessionToken
    };

    service.getPlacePredictions(request, (predictions, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }
      this.isMissingAddress = false;
      this.googleAddresses = [];
      predictions.forEach((prediction) => {
        let googleAddress = new GoogleAddress();
        googleAddress.description = prediction.description;
        googleAddress.place_id = prediction.place_id;
        googleAddress.type = this.selectedAddressType as AddressTypeCode;
        this.googleAddresses.push(googleAddress);
      });
    });
  }

  private getSupportedCountries(): void {
    if (this.shipmentType) {
      const query = this.prepareSupportedCountriesQuery();
      this.supportedCountriesService.getSupportedCountriesByCountryUsability(query)
        .subscribe((x: SupportedCountry[]) => {
          this.supportedCountries = x;
          if (this.isReuse) {
            this.getFilterSupportedCountries(Number(this.selectedAddressType));
          }
        },
          (error) => this.notificationService.error(error),
        ).add(() => {
          const model = this.control.value as any;
          this.getFilterSupportedCountries(Number(this.selectedAddressType));
          this.filteredCountries = [...this.filteredCountries];
          this.selectedSupportedCountry = model.country;
          if (model) this.isSelected = true;
        });
    }
  }

  private prepareSupportedCountriesQuery(): SupportedCountriesSearch {
    return {
      shipmentType: this.shipmentType,
      searchType: this.type
    } as SupportedCountriesSearch;
  }

  private addressTypeCollectionsBasedOnShipmentType(): void {
    this.addressTypeCollections = Object.keys(this.addressTypeCode).filter((k: string) => !isNaN(Number(k)));

    switch (this.shipmentType) {
      case ShipmentType.FCL:
      case ShipmentType.LCL:
        this.addressTypeCollections = this.addressTypeCollections.filter(x => x !== this.addressTypeCode.airport.toString());
        break;
      case ShipmentType.AIR:
        this.addressTypeCollections = this.addressTypeCollections.filter(x => x !== this.addressTypeCode.port.toString() && x !== this.addressTypeCode.warehouse.toString());
      default:
        break;
    }
  }

  private onValueChanged(): void {
    this.onSearchValueChanges(this.searchTerm.valueChanges)
      .subscribe((x: TripsSearchViewModelResponse) => {
        this.processTripsResponse(x);
        if (this.isSearchFromPredefined) {
          this.isSearchFromPredefined = false;
        }
      });

    this.onSearchValueChanges(this.searchTermTemp.valueChanges)
      .subscribe((x: TripsSearchViewModelResponse) => {
        this.processTripsResponse(x);
        if (this.isSearchFromPredefined) {
          this.isSearchFromPredefined = false;
        }
      });
  }

  private processTripsResponse(x: TripsSearchViewModelResponse): void {
    this.locations.clear();
    this.sortedMap = [];
    this.locationsResponse = x;

    if (this.type === SearchType.to && x?.shipperType) this.shipperType = x?.shipperType;

    if (x?.suggestedLocality != undefined) this.suggestedLocality = x.suggestedLocality

    if (x?.tripsSearchList?.length > 0) {
      x.tripsSearchList.forEach((trip: TripsSearchBaseViewModel) => {
        trip.cuttedDisplayName = this.stringCutterService.cutter(trip.displayName, 20);
        this.sortedMap.push(trip);
      })

      if (this.isMostPopularMoreLoading) {
        this.sortedMap$.subscribe((dataArray: any) => {
          let mergeArray = [...dataArray, ...this.sortedMap];
          this.sortedMap$ = of(mergeArray);
        });

        this.isMostPopularMoreLoading = false;
      }
      else {
        this.sortedMap$ = of(this.sortedMap);
      }

    } else if (x && !this.isSearchFromPredefined) {
      this.onAddressAnalyticsError(`select city or port/airport city`, "No results found")
    }

    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  private search(searchValue: string, postcodeSearchValue: string): Observable<TripsSearchViewModelResponse> {
    if (this.isLocationAutocompleteSearchEnable === true) {
      this.isMostPopularActive = searchValue.includes('*');

      if (this.selectedSupportedCountry === '') return of(null);

      return this.tripUnLocodeAutocompleteService.getTripSearchLocations(
        this.prepareTripSearchLocationRequest(
          this.isMostPopularActive ? this.selectedSupportedCountry : searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.isMostPopularActive ? this.mostPopularItemsPageSize : this.pageSize,
          this.mostPopularItemsPageNo,
          this.shipmentType,
          false, this.from,
          (Number(this.selectedAddressType) as AddressTypeCode),
          this.selectedSupportedCountry))
        .pipe(
          map(response => {
            this.isMostPopularMoreLoading = false;
            if (response?.tripsSearchList?.length > 0) {
              this.sortedMap$ = of(...response?.tripsSearchList);
            }
            return response;
          }));
    }
    else {
      return this.tripUnLocodeAutocompleteService.getTripUnLocodes(
        this.prepareUnLocodeRequest(
          searchValue.trim(),
          postcodeSearchValue,
          this.type,
          this.pageSize,
          this.shipmentType,
          false,
          this.from))
        .pipe(
          map(response => {
            return response;
          }));
    }
  }

  private prepareUnLocodeRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel
  ): TripsSearchViewModelRequest {
    return {
      type: searchType,
      pageSize,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private prepareTripSearchLocationRequest(
    searchVal: string,
    postcodeSearchVal: string,
    searchType: SearchType,
    pageSize: number,
    pageNo: number,
    shipmentType: ShipmentType,
    isPostcode: boolean,
    from: TripsSearchFromViewModel | string,
    addressTypeCode: AddressTypeCode,
    countryCode: string
  ): TripsSearchLocationsRequest {
    return {
      type: searchType,
      pageSize,
      pageNo,
      searchValue: searchVal,
      postcodeSearchValue: postcodeSearchVal,
      shipmentType,
      isPostcode,
      from: (from === '' ? null : from) as TripsSearchFromViewModel,
      addressTypeCode,
      selectedCountryCode: countryCode,
      profile: { organizationId: this.settingsService.getSettings().organizationId, countryCode: this.settingsService.getSettings().countryCode }
    };
  }

  private checkBeforeSearch(value: string): boolean | TripsSearchViewModelResponse {
    if (this.isSelected) {
      return this.locationsResponse;
    }

    if (this.selectedLocation) {
      this.selectedLocation = null;
    }

    if (this.checkMinimumLength(value)) {
      return null;
    }

    return true
  }

  private checkMinimumLength(value: string): boolean {
    return value?.length < this.minimumLength;
  }

  private getAddressType(locationType: LocationType): string {
    return this.getAddressTypeCode(locationType).toString()
  }

  private getAddressTypeCode(locationType: LocationType): AddressTypeCode {
    const port = [LocationType.seaPort, LocationType.railwayTerminal, LocationType.containerTerminal];
    const airport = [LocationType.airPort];
    const door = [LocationType.townCity];

    if (port.includes(locationType)) {
      return AddressTypeCode.port;
    }
    else if (airport.includes(locationType)) {
      return AddressTypeCode.airport;
    }
    else if (door.includes(locationType)) {
      return AddressTypeCode.door;
    }
    else {
      return AddressTypeCode.port;
    }
  }

  private fillRecentQuotesFromStorage(): void {
    let cachelist = this.tripUnLocodeAutocompleteService.getTripsSearchsFromStorage(this.type, this.shipmentType);
    this.recentQuotes = cachelist.reverse();
  }

  private checkIfSearchFromPredefinedRequired(): void {
    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (this.location.path().includes('reused') || !!quotesQueryString) {
      this.isSearchFromPredefined = true;
      this.isReuse = this.isSearchFromPredefined;
    }
  }

  private fillReuseQuote(): void {
    this.quotesService.sendQuoteData.subscribe((quote: any) => {
      if (this.isReuse && quote) {
        if (this.type === SearchType.from) {
          this.selectedAddressType = this.getAddressType(quote?.from?.locationType);
        } else if (this.type === SearchType.to) {
          this.selectedAddressType = this.getAddressType(quote?.to?.locationType);
        }

        if (this.selectedAddressType === AddressTypeCode.door.toString() ||
          this.selectedAddressType === AddressTypeCode.warehouse.toString()) {
          this.doorAddressControl.patchValue({ description: this.selectedLocation?.fullAddress });
        }
        this.onSelectAddressType(Number(this.selectedAddressType) as AddressTypeCode, false);
        this.control.enable();
        this.doorAddressControl.enable();
      }
    })
  }

  private normalizeAddressTypeCode(): AddressTypeCode {
    return this.selectedAddressType != "" ? Number(this.selectedAddressType) as AddressTypeCode : null;
  }

  private getNewSearchLocationAnalytics(): TripsSearchLocationAnalyticsViewModel {
    let newSearchLocationAnalytics;
    if (this.type == SearchType.from) {
      newSearchLocationAnalytics = {
        shipmentType: this.shipmentType,
        pickupType: this.normalizeAddressTypeCode(),
        originCountry: this.selectedCountry?.countryName,
        originCity: this.selectedLocation?.cityName,
      } as TripsSearchLocationAnalyticsViewModel;
    } else if (this.type == SearchType.to) {
      newSearchLocationAnalytics = {
        shipmentType: this.shipmentType,
        pickupType: this.getAddressTypeCode(this.from?.locationType),
        originCountry: this.from?.countryName,
        originCity: this.from?.cityName,
        deliveryType: this.normalizeAddressTypeCode(),
        destinationCountry: this.selectedCountry?.countryName,
        destinationCity: this.selectedLocation?.cityName,
      } as TripsSearchLocationAnalyticsViewModel;
    }

    return newSearchLocationAnalytics;
  }

  private getIsMostPopularEnabled(): any {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa.isMostPopularEnabled;
      case 'sb':
        return environment.sb.isMostPopularEnabled;
      default:
        return environment.isMostPopularEnabled;
    }
  }

  private getMostPopularItems(): void {
    switch (this.shipmentType) {
      case ShipmentType.FCL:
        if (!this.mostPopularStates.fcl) return; break;
      case ShipmentType.LCL:
        if (!this.mostPopularStates.lcl) return; break;
      case ShipmentType.AIR:
        if (!this.mostPopularStates.air) return; break;
    }

    this.autocompleteTrigger._onChange('*' + this.selectedSupportedCountry);
    this.searchTerm.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    this.searchTermTemp.setValue('*' + this.selectedSupportedCountry);
  }

  private onSearchValueChanges(valueChanges: Observable<any>): Observable<string | any | TripsSearchViewModelResponse> {
    return valueChanges.pipe(
      startWith(''),
      debounceTime(environment.holdTypingTimeMiliseconds),
      distinctUntilChanged(),
      switchMap(val => {
        this.isControlEmpty = false;
        if (val === ' ' || val === '') {
          this.isControlEmpty = true;
          return this.sortedMap$;
        }

        if (this.isSearchFromPredefined && this.control.value?.value && this.selectedAddressType === AddressTypeCode.port.toString()) {
          return this.search((this.control.value as TripsSearchBaseViewModel).value, null);
        }
        const isSearch = this.checkBeforeSearch(val);
        if (isSearch) {
          this.isLoading = true;
          this.locations.clear();
          this.sortedMap = [];
          this.suggestedLocality = null;
        }

        if (typeof val === "object") {
          val = (this.control.value as TripsSearchBaseViewModel).value;
        }

        return (isSearch === true ? this.search(val || '', null) : of(isSearch));
      }),
      startWith('')
    );
  }

  private getCountryCodes(): Array<string> {
    const countryCode = this.selectedSupportedCountry.toLocaleLowerCase();   
    if (countryCode == 'cn') {
      return [countryCode, 'hk'];
    }
    return [countryCode];
  }
}
