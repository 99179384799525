import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentType } from '../../shared.model';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentSuccessfulComponent implements OnInit {
  id: string;
  type = ShipmentType.FCL;
  shipmentType = ShipmentType;
  isMobile = false;
  
  constructor(private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.isMobile = data.isMobile ?? false;
    });

    this.route.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.type = params.type == 1 ? ShipmentType.LCL : 
                    params.type == 2 ? ShipmentType.FCL :
                    params.type == 3 ? ShipmentType.AIR :
                    ShipmentType.FCL;
      }
    );
  }

  onDashboard() {
    window.location.href = '/dashboard';
  }

  onFinanceDashboard(): void {
    window.location.href = '/finance';
  }
}
