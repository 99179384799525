<div class="dialog-container">
  <div class="box-icon-wrapper">
    <i class="panel-header-icon s4d-icon s4d-boxes-light"></i>
  </div>
  <div class="row dialog-message-wrapper">
    <span class="dialog-title">
      Change Cargo Details?
    </span>
    <span class="dialog-message">
      This changes will remove all the selected additional services and need to be redefined,
      do you want to save this changes?
    </span>
  </div>
  <div class="dialog-footer d-flex">
    <button mat-button class="dialog-button-cancel" (click)="onCancel()">Cancel</button>
    <button mat-raised-button class="dialog-button-save" (click)="onSave()">Save Changes</button>
  </div>
</div>