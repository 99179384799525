import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { DatepickerCustomHeaderComponent } from "../../../../../desktop/quotes/quote-search-flow/flow-params/flow-cargo-date/datepicker-custom-header/datepicker-custom-header.component";
import { QuotesQuery } from "../../../../../desktop/quotes/quotes.model";
import { ROLLUP_DIALOG_DATA } from "../../../../../mobile/rollup-dialog/rollup-dialog.model";
import { RollupDialogService } from "../../../../../mobile/rollup-dialog/rollup-dialog.service";
import { DepartureDateSettings, ShipmentType } from "../../../../../shared/shared.model";
import { UserInfoService } from '../../../../../shared/services/user-info/user-info.service';

@Component({
  selector: 'app-quote-search-flow-date',
  templateUrl: './flow-date.component.html',
  styleUrls: ['./flow-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowDateComponent implements OnInit {
  control: FormControl = new FormControl();
  shipmentType: ShipmentType = ShipmentType.FCL;

  customHeader = DatepickerCustomHeaderComponent;

  todayDate: Date = new Date();
  minSelectableDate: Date = new Date();
  maxSelectableDate: Date = null;

  get departureDateSettings(): DepartureDateSettings {
    let settings = this.userInfoService.getSettings();
    return settings.departureDateSettings;
  }

  isLoading: boolean = false;

  constructor(public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private userInfoService: UserInfoService) {
    this.control = data.control;
    this.shipmentType = data.shipmentType;
  }

  ngOnInit(): void {
    if (this.departureDateSettings) {
      this.setMinSelectableDate();
      this.setMaxSelectableDate();
    }

    const quotesQueryString = localStorage.getItem('quotesQuery');
    if (quotesQueryString) {
      const quotesQuery = JSON.parse(quotesQueryString) as QuotesQuery;
      this.onSelectDate(new Date(quotesQuery.fromDate));
    }
    else {
      this.control.setValue(this.minSelectableDate);
    }
  }

  itIsNotLimitedDays = (d: any): boolean => {
    const date = new Date(d._d);
    const day = date.getDay();
    return this.shipmentType == ShipmentType.LCL ? (day !== 0 && day !== 6) : (day !== 0);
  }

  onSelectDate(value: any): void {
    this.control.setValue(value);
  }

  onConfirm(): void {
    let value = this.control.value;

    if (moment.isMoment(this.control.value)) value = (this.control.value as moment.Moment).toDate()

    if (value.toDateString() === this.minSelectableDate.toDateString()) {
      this.control.setValue(this.minSelectableDate);
    }

    const data = {
      control: this.control,
    }

    this.dialogRef.close(data);
  }

  private setMinSelectableDate(): void {
    this.minSelectableDate = new Date();
    this.minSelectableDate.setDate(this.todayDate.getDate() + (this.getEnviromentMinAllowedShippingDate() + 1));
    if (this.minSelectableDate.getDay() == 0) {
      this.minSelectableDate.setDate(this.minSelectableDate.getDate() + 1);
    }
    else if (this.shipmentType == ShipmentType.LCL && this.minSelectableDate.getDay() == 6) {
      this.minSelectableDate.setDate(this.minSelectableDate.getDate() + 2);
    }
  }

  private setMaxSelectableDate(): void {
    this.maxSelectableDate = null;
    let enviromentMaxSelectableDate = this.getEnviromentMaxAllowedShippingDate();
    if (enviromentMaxSelectableDate != null) {
      this.maxSelectableDate = new Date();
      this.maxSelectableDate.setDate(this.todayDate.getDate() + enviromentMaxSelectableDate);
    }
  }

  private getEnviromentMinAllowedShippingDate(): number {
    switch (this.shipmentType) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMinAllowedDepartureDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMinAllowedDepartureDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMinAllowedDepartureDate);
      default:
        return 0;
    }
  }

  private getEnviromentMaxAllowedShippingDate(): number {
    switch (this.shipmentType) {
      case ShipmentType.AIR:
        return this.convertToNumber(this.departureDateSettings?.airMaxAllowedDepartureDate);
      case ShipmentType.FCL:
        return this.convertToNumber(this.departureDateSettings?.fclMaxAllowedDepartureDate);
      case ShipmentType.LCL:
        return this.convertToNumber(this.departureDateSettings?.lclMaxAllowedDepartureDate);
      default:
        return null;
    }
  }

  private convertToNumber(value: string | null | undefined): number | null {
    const num = value ? Number(value) : null;
    return isNaN(num) ? null : num;
  }
}
