import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { CreditLinesService } from "../../../../shared/creditLines/creditLines.service";
import { FourtySeasPaymentService } from "../../../../shared/payments/fourty-seas-payment.service";
import {
  CreditLine,
  CreditLineStatusCode
} from "../../../../shared/payments/payments.model";
import { PaymentsService } from "../../../../shared/payments/payments.service";
import { AppSettingsService } from "../../../../shared/services/app-settings/app-settings.service";
import { CreditLineStatusTransition } from "../../../../shared/shared.model";

@Component({
  selector: "app-credits-widget",
  templateUrl: "./credits-widget.component.html",
  styleUrls: ["./credits-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreditsWidgetComponent implements OnInit {
  @Input() widget;
  @Input() editable: boolean;
  @Input() preview: boolean = false;
  @Input() data?: any;
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isError: boolean = false;
  isEmpty: boolean = false;
  organizationId: string;

  creditLine: CreditLine = null;
  errorMessage: string;
  toolTipMessage: string;
  CreditLineStatuses = CreditLineStatusCode;

  isCreditLineNew: boolean = false;
  isCreditLineRejected: boolean = false;
  isCreditLineApproved: boolean = false;
  isCreditLineApprovedOrPreapproved: boolean = false;
  isCreditLinePreapproved: boolean = false;
  isCreditLineRequested: boolean = false;

  isEmptyTooltipMessage: string = `1. You will need to connect your bank account at payout.
  2. You have positive balance.
  3. Your monthly revenue is more than 10,000 USD.`;

  isPreApprovedTooltipMessage: string = `To be eligible you need:
  • To Connect your business bank account​
  • A bank account that was opened more than 6 months ago​
  • A monthly revenue above 10,000 USD`;

  isApprovedTooltipMessage: string = `Gain access to an instant $10,000 credit line
  and pay for shipments up to 90 days from the cargo delivery date.`;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private paymentsService: PaymentsService,
    private creditLinesService: CreditLinesService,
    private fourtySeasPaymentService: FourtySeasPaymentService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;

    if (!this.preview) {
      this.getData();
      this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
        if (next) {
          this.isLoading = false;
        }
      });

      this.fourtySeasPaymentService.paymentLoaderBehaviorSubject.subscribe((value: boolean | undefined) => {
        if (value !== undefined) {
          this.isButtonLoading = value;

          this.fourtySeasPaymentService.paymentCancelBehaviorSubject.subscribe((isCanceled: boolean | undefined) => {
            if (isCanceled !== undefined) {
              if (!value && isCanceled !== true) {
                this.onGoTo();
              }
            }
          });
        }
      });
    }
  }

  onGoTo(): void {
    this.router.navigate(["/creditLine"]);
  }

  onRemove(): void {
    this.remove.next(this.widget.id);
  }

  onReload(): void {
    this.getData();
  }

  onEmptyAction(): void {
    this.router.navigate(["/creditLine"]);
  }

  getData(): void {
    this.isLoading = true;

    this.paymentsService
      .getCreditLineByOrganization(this.organizationId)
      .subscribe(
        (data: CreditLine) => {
          this.creditLine = data;
          this.setCreditLineStatus();
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error;
        }
      )
      .add(() => (this.isLoading = false));
  }

  initCreditLine(): void {
    var transaction = this.isCreditLineApproved ? CreditLineStatusTransition.increaseRequest : null;
    this.fourtySeasPaymentService
      .initCreditLine(this.organizationId, null, transaction)
      .subscribe(
        () => { },
        () => {
          this.openPopup(
            "Error. An error occurred while initialising your credit line, try again."
          );
        }
      )
      .add(() => (this.isButtonLoading = false));
  }

  claimCreditLine(): void {
    this.isButtonLoading = true;
    this.creditLinesService
      .updateCreditLine(this.organizationId, CreditLineStatusTransition.claim)
      .subscribe(
        () => {
          window.location.href = "/creditLine";
        },
        () => {
          this.openPopup(
            "Error. An error occurred while claiming your credit line, try again."
          );
        }
      )
      .add(() => (this.isButtonLoading = false));
  }

  private setCreditLineStatus(): void {
    switch (this.creditLine.statusId) {
      case CreditLineStatusCode.new:
        {
          this.isCreditLineNew = true;
          this.isEmpty = true;
          this.toolTipMessage = this.isEmptyTooltipMessage;
        }
        break;
      case CreditLineStatusCode.rejected:
        {
          this.isCreditLineRejected = true;
          this.isEmpty = true;
          this.toolTipMessage = this.isEmptyTooltipMessage;
        }
        break;
      case CreditLineStatusCode.preApproved:
        {
          this.isCreditLinePreapproved = true;
          this.isCreditLineApprovedOrPreapproved = true;
          this.isEmpty = true;
          this.toolTipMessage = this.isPreApprovedTooltipMessage;
        }
        break;
      case CreditLineStatusCode.approved:
        this.isCreditLineApproved = true;
        this.isCreditLineApprovedOrPreapproved = true;
        this.toolTipMessage = this.isApprovedTooltipMessage;
        break;
      case CreditLineStatusCode.requested:
        this.isCreditLineRequested = true;
        this.isEmpty = true;
        this.toolTipMessage = this.isApprovedTooltipMessage;
        break;
      case CreditLineStatusCode.increaseRequested:
        this.isCreditLineApproved = true;
        this.toolTipMessage = this.isApprovedTooltipMessage;
        break;
      default:
        break;
    }
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok"
      },
    });
  }
}
