import { Injectable } from "@angular/core";
import { EnvironmentsService, EnvironmentsServiceConfig } from "@ship4wd/ngx-common";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  environmentsService: EnvironmentsService;
  environmentName: string;

  constructor() {
    this.environmentsService = new EnvironmentsService({ companySubdomain: 'ship4wd' } as EnvironmentsServiceConfig);
    this.environmentName = this.environmentsService.getEnvironmentNameByHostname(window.location.hostname);
  }

  get environment() {
    switch (this.environmentName) {
      case 'qa':
        return environment.qa;
      case 'sb':
        return environment.sb;
      default:
        return environment;
    }
  }
}