<div class="flow-rate-details">
  <div class="flow-rate-details-header px-3 pt-2 title-subtitle-wrapper">
    <div class="row g-0 m-0">
      <div class="col-12 d-flex justify-content-between mb-2 details-title">
        <span>Price Breakdown</span>
        <span class="header-icon-wrapper" (click)="openInfoDialog()">
          <i class="header-icon s4d-icon s4d-info-light is-hover"></i>
        </span>
      </div>
      <div class="col-12 details-subtitle">
        The price below includes charges and fees for your cargo based on the selected origin and destination.
      </div>
    </div>
  </div>
  <div class="flow-rate-details-content px-3 row g-0 gap-3 m-0 align-content-start align-items-flex-start">
    <div class="col-12">
      <mat-accordion>
        <div class="row gap-3 accordion-main-wrapper">
          <div *ngIf="isBadgeEnabled">
            <app-flow-result-details-service [shipmentType]="shipmentType"
              [quoteRateServiceType]="quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType"></app-flow-result-details-service>
          </div>
          <ng-container *ngFor="let item of rateDetails | keyvalue">
            <div class="col-12" *ngIf="item.value.charges.length">
              <mat-expansion-panel class="details-expansion-panel" [expanded]="isExpanded[rateDetailsPanels[item.key]]"
                (opened)="onClickPanel(true, rateDetailsPanels[item.key])"
                (closed)="onClickPanel(false, rateDetailsPanels[item.key])" hideToggle>
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title class="panel-title">
                    <div class="panel-title-content-wrapper d-flex gap-2 justify-content-between align-items-center">
                      <div class="d-flex">
                        <div *ngIf="rateDetailsPanels[item.key] !== 'DANGEROUS'"
                          class="accordion-title d-flex text-capitalize align-items-center p-0">
                          {{(rateDetailsPanels[item.key] | titlecase) }} Charges
                        </div>
                        <div *ngIf="rateDetailsPanels[item.key] === 'DANGEROUS'"
                          class="accordion-title d-flex text-capitalize align-items-center p-0">
                          Dangerous Goods Battery surcharges
                        </div>
                      </div>
                      <div class="d-flex flex-column align-items-end ms-auto">
                        <div class="accordion-title d-flex align-items-center p-0"
                          [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(item.value.totalAmount || 0, item.key)}">
                          {{ quote.trip.currency | currencyTextIcon }}{{ item.value.totalAmount ?
                          (item.value.totalAmount| number:'1.2-2') : "-.--" }}
                        </div>
                        <span appSetRoleBasedPrice class="estimated-subtitle discount-price accordion"
                          *ngIf="isShowReverseDiscountPrice(item.value.totalAmount || 0, item.key)">
                          {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(item.value.totalAmount
                          ||
                          0) |
                          number: "1.2-2" }}
                        </span>
                      </div>
                      <div>
                        <span class="panel-icon panel-header-icon-wrapper">
                          <i *ngIf="!isExpanded[rateDetailsPanels[item.key]]"
                            class="panel-header-icon s4d-icon s4d-chevron-down is-hover"></i>
                          <i *ngIf="isExpanded[rateDetailsPanels[item.key]]"
                            class="panel-header-icon s4d-icon s4d-chevron-up is-hover"></i>
                        </span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template [ngTemplateOutlet]="charges"
                  [ngTemplateOutletContext]="{item: item.value, chargesType: item.key}"></ng-template>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </div>
      </mat-accordion>
    </div>
    <div class="col-12 row g-0 gap-1">
      <div class="col details-subtitle submit mb-0">
        Total Cost
      </div>
      <div class="col d-flex flex-column align-items-end">
        <div appSetRoleBasedPrice class="detail-subtitle-value submit"
          [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(accumulatePrice)}">
          {{ quote.trip.currency | currencyTextIcon }}{{accumulatePrice | number:'1.2-2'}} {{(quote.currency |
          currencyName)}}
        </div>
        <div appSetRoleBasedPrice class="estimated-subtitle discount-price submit"
          *ngIf="isShowReverseDiscountPrice(accumulatePrice || 0)">
          {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(accumulatePrice ||
          0) |
          number: "1.2-2" }}
        </div>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-end">
        <span class="details-subtitle-description">This quote is valid for {{priceValidHours}} {{
          isBasicRateServiceType() ? 'mins' : 'hrs' }}</span>
      </div>
    </div>
  </div>
  <div class="flow-rate-details-footer">
    <button mat-button class="flow-rate-details-button back-button" (click)="onClose()">
      Back
    </button>
  </div>
</div>

<ng-template #beingCalculated>
  <span class="being-calculated">Being calculated</span>
</ng-template>


<ng-template #charges let-item="item" let-chargesType="chargesType">
  <div class="row g-0 m-0 panel-content gap-3">
    <ng-container *ngFor="let charge of item.charges; let isFirst = first">
      <div class="col-12 d-flex justify-content-between">
        <div *ngIf="rateDetailsPanels[chargesType] !== 'DANGEROUS'" class="rate-subtitle">
          {{
          charge?.additionalType || charge?.description || (rateDetailsPanels[chargesType] | titlecase) + ' Charges'
          | titlecase
          }}
        </div>
        <div *ngIf="rateDetailsPanels[chargesType] === 'DANGEROUS'" class="rate-subtitle">
          {{
          charge?.additionalType || charge?.description || rateDetailsPanels[chargesType] + ' Charges'
          }}
        </div>
        <div class="d-flex flex-column align-items-end">
          <ng-container *ngIf="!charge?.isMissingRate; else beingCalculated">
            <span appSetRoleBasedPrice class="rate-subtitle rate-subtitle-value"
              [ngClass]="{'include-discount-price': isShowReverseDiscountPrice(getChargeAmount(charge), chargesType)}">
              {{ quote.trip.currency | currencyTextIcon }}{{ getChargeAmountToDisplay(charge)}}
            </span>
            <span appSetRoleBasedPrice class="estimated-subtitle discount-price"
              *ngIf="isShowReverseDiscountPrice(getChargeAmount(charge), chargesType)">
              {{ quote.currency | currencyTextIcon }}{{ getReverseDiscountPrice(getChargeAmount(charge)) |
              number: "1.2-2" }}
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template [ngTemplateOutlet]="cargoDetails" [ngTemplateOutletContext]="{cargoDetails: charge?.cargoDetails}"
        *ngIf="charge?.cargoDetails"></ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #cargoDetails let-cargoDetails="cargoDetails">
  <ng-container *ngIf="shipmentType === ShipmentType.FCL">
    <div class="col-12 ps-3 d-flex justify-content-between" *ngFor="let equipment of cargoDetails | keyvalue">
      <div class="rate-subtitle">
        {{ equipment.key | titlecase }}
      </div>
      <div class="rate-subtitle rate-subtitle-value">
        x{{equipment.value?.length}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="shipmentType !== ShipmentType.FCL && cargoDetails">
    <div class="col-12 ps-3 d-flex justify-content-between" *ngFor="let commodity of cargoDetails; let i = index">
      <div class="rate-subtitle">
        Load {{ i + 1 }}
      </div>
      <div class="rate-subtitle rate-subtitle-value">
        {{getDimensions(commodity)}}
      </div>
    </div>
  </ng-container>
</ng-template>