import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NotificationDialogType, NotificationService } from '@ship4wd/ngx-common';
import { FileInfo, FileUploaderMode, LayoutType } from '@ship4wd/ngx-manager-ui';
import { BookingDocumentsService } from '../../../../shared/bookings/booking-documents/booking-documents.service';
import { Booking, BookingFile } from '../../../../shared/bookings/bookings.model';
import { SupportedFileFormats } from '../../../../shared/shared.model';

@Component({
  selector: 'app-booking-flow-summary-document',
  templateUrl: './booking-flow-summary-document.component.html',
  styleUrls: ['./booking-flow-summary-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingFlowSummaryDocumentComponent {
  @Input() booking: Booking;
  FileUploaderMode = FileUploaderMode;
  SupportedFileFormats = SupportedFileFormats;
  LayoutType = LayoutType;

  constructor(
    private bookingDocumentsService: BookingDocumentsService,
    private notificationService: NotificationService
  ) { }

  getSecureFileUrl(fileId: string): void {
    this.bookingDocumentsService
      .getSignedUrl(this.booking.id, fileId)
      .subscribe(x => {
        window.open(x.url, "_blank");
      },
        error => {
          this.notificationService.error(error);
        });
  }

  onListingFilesChange(files: FileInfo[]): void {
    const filesToAdd = files.filter(x => !x.id);
    this.removeExistedFile(files);
    this.addFiles(filesToAdd, files);
  }

  private deleteFiles(files: FileInfo[]): void {
    for (const fileToRemove of files) {
      this.bookingDocumentsService
        .delete(this.booking.id, fileToRemove.id)
        .subscribe(data => {
          const index = this.booking.documents.findIndex(x => x.id === fileToRemove.id);
          if (index > -1) {
            this.booking.documents = this.booking.documents.filter(item => item.id !== fileToRemove.id);
          }
        });
    }
  }

  private addFiles(files: FileInfo[], allFiles: FileInfo[]): void {
    if (files && files.length > 0) {
      for (const fileToAdd of files) {
        this.bookingDocumentsService
          .add(this.booking.id, fileToAdd)
          .subscribe(x => this.finalizeFileLoading(fileToAdd, x, allFiles),
            (error) => {
              this.booking.documents = this.booking.documents.filter(x => x.id != fileToAdd.id);
              this.notificationService.notifyDialog(error.error, NotificationDialogType.error);
            });
      }
    }
  }

  private finalizeFileLoading(fileToAdd: FileInfo, response: any, allFiles: FileInfo[]): void {
    if (response.type === HttpEventType.UploadProgress) {
      fileToAdd.progress = Math.round(100 * response.loaded / response.total);
    } else if (response instanceof HttpResponse) {
      fileToAdd.progress = null;
      fileToAdd.file = null;
      const addedFile = response.body as BookingFile;
      fileToAdd.name = addedFile.name;
      fileToAdd.url = addedFile.url;
      fileToAdd.id = addedFile.id;
      this.removeExistedFileFromFileArray(fileToAdd, allFiles);
      const existingDocument = this.booking.documents.find(x => x.id === fileToAdd.id);
      if (existingDocument === undefined) {
        this.booking.documents.push(addedFile);
      }
    }
  }

  private removeExistedFileFromFileArray(file: FileInfo, allFiles: FileInfo[]): void {
    const filesToRemove = allFiles.filter(x => file.name === x.name);
    if (filesToRemove && filesToRemove.length > 1) {
      const index = allFiles.indexOf(filesToRemove[0])
      allFiles.splice(index, 1);
    }
  }

  private removeExistedFile(files: FileInfo[]): void {
    const filesToRemove = this.booking.documents.filter(x => !files.some(y => y.id === x.id));
    if (filesToRemove && filesToRemove.length > 0) {
      this.deleteFiles(filesToRemove);
    }
  }
}
