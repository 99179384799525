import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalUrlsService } from '@ship4wd/ngx-common';
import { TeamMemberSettings, TeamMemberViewModel } from '../../manage-organization.model';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient
  ) { }

  updateTeamMember(id: string, teamMember: TeamMemberViewModel) {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put(
      `${this.internalUrlsService.getApiBaseUrl()}/teamMembers/${id}`,
      teamMember, httpOptions);
  }

  deleteTeamMember(id: string) {
    return this.http.delete(
      `${this.internalUrlsService.getApiBaseUrl()}/teamMembers/${id}`);
  }

  updateTeamMemberSettings(id: string, teamMemberSettings: TeamMemberSettings): Observable<any> {
    const httpOptions = this.createContentTypeHttpOptions();
    return this.http.put<any>(
      `${this.internalUrlsService.getApiBaseUrl()}/teamMembers/${id}/teamMemberSettings`,
      teamMemberSettings, httpOptions);
  }

  private createContentTypeHttpOptions(contentType?: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType ?? 'application/json' })
    };
  }
}
