import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettingsService } from '../../../shared/services/app-settings/app-settings.service';
import { FourtySeasPaymentService } from '../../../shared/payments/fourty-seas-payment.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CreditLine, CreditLineStatusCode } from '../../../shared/payments/payments.model';

@Component({
  selector: 'app-claim-credit-line',
  templateUrl: './claim-credit-line.component.html',
  styleUrls: ['./claim-credit-line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimCreditLineComponent implements OnInit {
  @Input() creditLine: CreditLine = null;
  CreditLineStatuses = CreditLineStatusCode;

  isLoading = false;
  organizationId: string;

  constructor(
    public dialog: MatDialog,
    private settingsService: AppSettingsService,
    private fourtySeasPaymentService: FourtySeasPaymentService
  ) { }

  ngOnInit(): void {
    this.organizationId = this.settingsService.getSettings().organizationId;
    this.fourtySeasPaymentService.checkoutEnd.subscribe((next) => {
      if (next) {
        this.isLoading = false
      }
    });
  }

  initCreditLine(): void {
    this.isLoading = true;
    this.fourtySeasPaymentService.initCreditLine(this.organizationId)
      .subscribe(() => { },
        () => {
          this.openPopup('Error. An error occurred while initialising your credit line, try again.');
        }).add(() => (this.isLoading = false));
  }

  private openPopup(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message,
        showConfirmButton: true,
        confirmButtonText: "Ok"
      },
    });
  }
}