<div class="container organization-empty-container">
  <div class="organization-empty-container-title">
    <h1 class="text-center m-0 text-white text-capitalize">Add your First Organization</h1>
  </div>
  <div class="organization-empty-content">
    <div class="row">
      <div class="col-12">
        <div class="organization-empty-icon-wrapper">
          <div class="organization-empty-icon"></div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center my-3">
        <span class="organization-empty-content-title">
          Organizations
        </span>
      </div>
      <div class="col-12">
        <span class="organization-empty-content-description">You are not a member of any organization, ask your
          organization administrator to invite you, or alternatively click on the button below to register new
          company.</span>
      </div>
      <div class="col-12 mt-4">
        <button mat-raised-button class="next-button" (click)="onRegister()">
          Register Your Company
        </button>
      </div>
    </div>
  </div>
</div>
