import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@ship4wd/ngx-common';
import { environment } from '../../../../../../environments/environment';
import { BookingContactViewModel } from '../../../../../shared/bookings/booking-contacts/booking-contacts.model';
import { BookingContactsService } from '../../../../../shared/bookings/booking-contacts/booking-contacts.service';
import { OrganizationContact, OrganizationContactAction, OrganizationContactsQuery, OrganizationContactsQueryParameters } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.model';
import { BookingOrganizationContactsService } from '../../../../../shared/bookings/booking-organization-contacts/booking-organization-contacts.service';
import { Booking, ContactType } from '../../../../../shared/bookings/bookings.model';
import { Page } from '../../../../../shared/shared.model';
import { RemoveConfirmDialogComponent } from '../../../../../shared/remove-confirm-dialog/remove-confirm-dialog.component';
import { DialogService } from '../../../../../shared/services/dialog.service';

@Component({
  selector: 'app-booking-flow-contact',
  templateUrl: './booking-flow-contact.component.html',
  styleUrls: ['./booking-flow-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingFlowContactComponent implements OnInit {
  @Output() BackToBooking = new EventEmitter();
  @Output() selectedContact = new EventEmitter();
  @Output() isOpenNewContact: EventEmitter<any> = new EventEmitter();
  @Output() organizationContactandAction: EventEmitter<any> = new EventEmitter();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
  @Input() booking: Booking;
  @Input() contactType: ContactType;
  @Input() backTitle: string = "Back to Booking";
  @Input() containerId: string | null = null;
  @Input() organizationContacts: OrganizationContact[];
  filteredContacts: OrganizationContact[];
  selectedContactTitle: string;
  organizationContact: OrganizationContact;
  organizationContactAction: OrganizationContactAction = OrganizationContactAction.new;
  isSelectLoading = false;
  hideUnsupportedIcons: boolean;
  selectedContactId: string;
  contactCountryCode: string = null;

  constructor(
    private bookingOrganizationContactsService: BookingOrganizationContactsService,
    private notificationService: NotificationService,
    private bookingContactsService: BookingContactsService,
    public dialog: MatDialog,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.selectedContactTitle = this.getContactTitle();
    this.contactCountryCode = this.getContactCountryCode();
    this.getOrganizationContacts();
    this.hideUnsupportedIcons = environment.hideUnsupportedIcons;
  }

  onBackToBooking(): void {
    this.BackToBooking.emit();
  }

  getInitialName(contact: OrganizationContact): string {
    if (contact?.firstName && contact?.lastName) {
      return contact.firstName.charAt(0).toUpperCase() + contact.lastName.charAt(0).toUpperCase();
    }
    return '';
  }

  onOpenNewContact(): void {
    this.openNewContactPage(true, null, OrganizationContactAction.new);
  }

  onBackToContact(): void {
    this.openNewContactPage(false);
    this.organizationContactAction = OrganizationContactAction.new;
  }

  onSelectedContact(selectedContact: any): void {
    this.openNewContactPage(false);
    this.selectedContact.emit({
      bookingContact: selectedContact.bookingContact,
      contactType: selectedContact.contactType,
      containerId: this.containerId,
      bookingContactId: selectedContact.bookingContactId
    });
  }

  onSelectContact(organizationContact: OrganizationContact): void {
    this.selectedContactId = organizationContact.id;
    this.isSelectLoading = true;
    if (organizationContact) {

      organizationContact.organizationId = this.booking.organizationId;

      this.setBookingContact(organizationContact);
    }
  }

  onCancel(): void {
    this.onBackToBooking();
  }

  onViewContact(organizationContact: OrganizationContact): void {
    this.prePareContactPage(organizationContact, OrganizationContactAction.view);
  }

  onUpdateContact(organizationContact: OrganizationContact): void {
    this.prePareContactPage(organizationContact, OrganizationContactAction.edit);
  }

  onDeleteContact(contact: OrganizationContact): void {
    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      const dialogRef = this.dialog.open(RemoveConfirmDialogComponent, {
        panelClass: 'on-popup-container',
        autoFocus: false,
        data: {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Remove Contact'
        }
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        this.dialogService.setDialogOpen(false);
        if (result.result === true) {
          this.deleteOrganizationContact(contact);
        }
      });
    }
  }

  onContactSearch(event: any): void {
    const searchValue = event?.target?.value;
    if (searchValue) {
      this.filteredContacts = this.organizationContacts.filter(contact =>
        Object.values(contact).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      this.filteredContacts = [...this.organizationContacts];
    }
  }

  private prePareContactPage(organizationContact: OrganizationContact,
    organizationContactAction: OrganizationContactAction): void {
    this.openNewContactPage(true, organizationContact, organizationContactAction);
  }

  private openNewContactPage(isOpen: boolean, organizationContact: OrganizationContact = null,
    organizationContactAction: OrganizationContactAction = null): void {
    this.isOpenNewContact.emit({ isOpen: isOpen, isBookingContactPage: true, contactType: this.contactType, organizationContact: organizationContact, organizationContactAction: organizationContactAction });
  }

  private setBookingContact(organizationContact: OrganizationContact): void {
    const bookingContact = this.prepareBookingContactModel(organizationContact);
    if (this.contactType === ContactType.collect || this.contactType === ContactType.drop) {
      this.selectedContact.emit({
        bookingContact: bookingContact,
        contactType: this.contactType,
        containerId: this.containerId
      });
    }
    else {
      this.addBookingContact(bookingContact);
    }
  }

  private prepareBookingContactModel(organizationContact: OrganizationContact): BookingContactViewModel {
    let bookingContactViewModel = new BookingContactViewModel();
    bookingContactViewModel.bookingId = this.booking.id;
    bookingContactViewModel.organizationId = organizationContact.organizationId;
    bookingContactViewModel.originId = organizationContact.id ?? null;
    bookingContactViewModel.contactTypeCode = this.contactType;
    bookingContactViewModel.companyName = organizationContact.companyName;
    bookingContactViewModel.firstName = organizationContact.firstName;
    bookingContactViewModel.lastName = organizationContact.lastName;
    bookingContactViewModel.email = organizationContact.email;
    bookingContactViewModel.address1 = organizationContact.address1;
    bookingContactViewModel.address2 = organizationContact.address2;
    bookingContactViewModel.countryCode = organizationContact.countryCode;
    bookingContactViewModel.state = organizationContact.state;
    bookingContactViewModel.city = organizationContact.city;
    bookingContactViewModel.zipCode = organizationContact.zipCode;
    bookingContactViewModel.phoneNumber = organizationContact.phoneNumber;
    bookingContactViewModel.fax = organizationContact.fax;
    bookingContactViewModel.taxId = organizationContact.taxId;
    bookingContactViewModel.customerCode = organizationContact.customerCode;
    return bookingContactViewModel;
  }

  private addBookingContact(bookingContact: BookingContactViewModel): void {
    this.isSelectLoading = true;
    this.bookingContactsService
      .addBookingContact(bookingContact)
      .subscribe(
        (bookingContactId: string) => {
          this.selectedContact.emit({
            bookingContactId: bookingContactId,
            contactType: this.contactType
          });
        },
        error => this.notificationService.error(error))
      .add(() => this.isSelectLoading = false);
  }

  private getOrganizationContacts(): void {
    const query = {
      pageNo: 1,
      pageSize: 500,
      sortBy: OrganizationContactsQueryParameters.companyName,
    } as OrganizationContactsQuery;

    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        query.countryCode = this.booking?.fromBookingSearch?.country;
        break;
      case ContactType.consignee:
      case ContactType.drop:
        query.countryCode = this.booking?.toBookingSearch?.country;
        break;
      default:
        break;
    }

    this.bookingOrganizationContactsService
      .getOrganizationContactsByQuery(query, this.booking.organizationId)
      .subscribe((x: Page<OrganizationContact>) => {
        this.organizationContacts = x.items;
        this.filteredContacts = this.organizationContacts;
      },
        (error) => this.notificationService.error(error)
      )
      .add(() => (this.isLoading.emit(false)));
  }

  private getContactTitle(): string {
    switch (this.contactType) {
      case ContactType.notifyParty:
        return 'Notify contact'
      case ContactType.consignee:
        return 'contact at Delivery (Importer)';
      case ContactType.shipper:
        return 'contact at Pickup (Exporter/Supplier)';
      default:
        return 'contact';
    }
  }

  private getContactCountryCode(): string {
    switch (this.contactType) {
      case ContactType.shipper:
      case ContactType.collect:
        return this.booking.fromBookingSearch.countryName;
      case ContactType.consignee:
      case ContactType.notifyParty:
      case ContactType.drop:
        return this.booking.toBookingSearch.countryName;
      default:
        return null;
    }
  }

  private deleteOrganizationContact(organizationContact: OrganizationContact): void {
    this.isSelectLoading = true;
    this.bookingOrganizationContactsService
      .deleteOrganizationContact(organizationContact.organizationId, organizationContact.id)
      .subscribe(
        () => {
          this.getOrganizationContacts();
        },
        error => this.notificationService.error(error))
      .add(() => this.isSelectLoading = false);
  }
}
