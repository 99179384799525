import { Pipe, PipeTransform } from '@angular/core';
import { PackageTypeCode } from '../package-types/package-types.model';

@Pipe({ name: 'packageTypeName' })
export class PackageTypeNamePipe implements PipeTransform {
  private packageTypeNames = {};

  constructor() {
    this.packageTypeNames[PackageTypeCode.BAG] = 'Bag';
    this.packageTypeNames[PackageTypeCode.BAGS] = 'Bags';
    this.packageTypeNames[PackageTypeCode.BALE] = 'Bale';
    this.packageTypeNames[PackageTypeCode.BALES] = 'Bales';
    this.packageTypeNames[PackageTypeCode.BARREL] = 'Barrel';
    this.packageTypeNames[PackageTypeCode.BARRELS] = 'Barrels';
    this.packageTypeNames[PackageTypeCode.BUNDLE] = 'Bundle';
    this.packageTypeNames[PackageTypeCode.BUNDLES] = 'Bundles';
    this.packageTypeNames[PackageTypeCode.BING] = 'Bing';
    this.packageTypeNames[PackageTypeCode.BINGS] = 'Bings';
    this.packageTypeNames[PackageTypeCode.BIN] = 'Bin';
    this.packageTypeNames[PackageTypeCode.BINS] = 'Bins';
    this.packageTypeNames[PackageTypeCode.BLKBAG] = 'Blkbag';
    this.packageTypeNames[PackageTypeCode.BLKBAGS] = 'Blkbags';
    this.packageTypeNames[PackageTypeCode.BULK] = 'Bulk';
    this.packageTypeNames[PackageTypeCode.BULKS] = 'Bulks';
    this.packageTypeNames[PackageTypeCode.BOBBIN] = 'Bobbin';
    this.packageTypeNames[PackageTypeCode.BOBBINS] = 'Bobbins';
    this.packageTypeNames[PackageTypeCode.BOTTLE] = 'Bottle';
    this.packageTypeNames[PackageTypeCode.BOTTLES] = 'Bottles';
    this.packageTypeNames[PackageTypeCode.BOX] = 'Box';
    this.packageTypeNames[PackageTypeCode.BOXES] = 'Boxes';
    this.packageTypeNames[PackageTypeCode.BASKET] = 'Basket';
    this.packageTypeNames[PackageTypeCode.BASKETS] = 'Baskets';
    this.packageTypeNames[PackageTypeCode.BUCKET] = 'Bucket';
    this.packageTypeNames[PackageTypeCode.BUCKETS] = 'Buckets';
    this.packageTypeNames[PackageTypeCode.CAN] = 'Can';
    this.packageTypeNames[PackageTypeCode.CANS] = 'Cans';
    this.packageTypeNames[PackageTypeCode.CASE] = 'Case';
    this.packageTypeNames[PackageTypeCode.CASES] = 'Cases';
    this.packageTypeNames[PackageTypeCode.CBC] = 'Cbc';
    this.packageTypeNames[PackageTypeCode.CARBOY] = 'Carboy';
    this.packageTypeNames[PackageTypeCode.CARBOYS] = 'Carboys';
    this.packageTypeNames[PackageTypeCode.CANCASE] = 'Cancase';
    this.packageTypeNames[PackageTypeCode.CHEST] = 'Chest';
    this.packageTypeNames[PackageTypeCode.CHESTS] = 'Chests';
    this.packageTypeNames[PackageTypeCode.WOODCNT] = 'Woodcnt';
    this.packageTypeNames[PackageTypeCode.CNTR] = 'Cntr';
    this.packageTypeNames[PackageTypeCode.CNTRS] = 'Cntrs';
    this.packageTypeNames[PackageTypeCode.COIL] = 'Coil';
    this.packageTypeNames[PackageTypeCode.COILS] = 'Coils';
    this.packageTypeNames[PackageTypeCode.CONE] = 'Cone';
    this.packageTypeNames[PackageTypeCode.CONES] = 'Cones';
    this.packageTypeNames[PackageTypeCode.CRADLE] = 'Cradle';
    this.packageTypeNames[PackageTypeCode.CRADLES] = 'Cradles';
    this.packageTypeNames[PackageTypeCode.CRATE] = 'Crate';
    this.packageTypeNames[PackageTypeCode.CRATES] = 'Crates';
    this.packageTypeNames[PackageTypeCode.CASK] = 'Cask';
    this.packageTypeNames[PackageTypeCode.CASKS] = 'Casks';
    this.packageTypeNames[PackageTypeCode.CARTON] = 'Carton';
    this.packageTypeNames[PackageTypeCode.CARTONS] = 'Cartons';
    this.packageTypeNames[PackageTypeCode.CUBE] = 'Cube';
    this.packageTypeNames[PackageTypeCode.CUBES] = 'Cubes';
    this.packageTypeNames[PackageTypeCode.CYL] = 'Cyl';
    this.packageTypeNames[PackageTypeCode.CYLS] = 'Cyls';
    this.packageTypeNames[PackageTypeCode.DRYBULK] = 'Drybulk';
    this.packageTypeNames[PackageTypeCode.DRUM] = 'Drum';
    this.packageTypeNames[PackageTypeCode.DRUMS] = 'Drums';
    this.packageTypeNames[PackageTypeCode.DUFFLE] = 'Duffle';
    this.packageTypeNames[PackageTypeCode.DUFFLES] = 'Duffles';
    this.packageTypeNames[PackageTypeCode.FIRKIN] = 'Firkin';
    this.packageTypeNames[PackageTypeCode.FIRKINS] = 'Firkins';
    this.packageTypeNames[PackageTypeCode.LIQLINR] = 'Liqlinr';
    this.packageTypeNames[PackageTypeCode.FRAME] = 'Frame';
    this.packageTypeNames[PackageTypeCode.FRAMES] = 'Frames';
    this.packageTypeNames[PackageTypeCode.FLASK] = 'Flask';
    this.packageTypeNames[PackageTypeCode.FLASKS] = 'Flasks';
    this.packageTypeNames[PackageTypeCode.GOH] = 'Goh';
    this.packageTypeNames[PackageTypeCode.HEDBEEF] = 'Hedbeef';
    this.packageTypeNames[PackageTypeCode.HOGSHED] = 'Hogshed';
    this.packageTypeNames[PackageTypeCode.HAMPER] = 'Hamper';
    this.packageTypeNames[PackageTypeCode.HAMPERS] = 'Hampers';
    this.packageTypeNames[PackageTypeCode.JAR] = 'Jar';
    this.packageTypeNames[PackageTypeCode.JARS] = 'Jars';
    this.packageTypeNames[PackageTypeCode.JUG] = 'Jug';
    this.packageTypeNames[PackageTypeCode.JUGS] = 'Jugs';
    this.packageTypeNames[PackageTypeCode.KEG] = 'Keg';
    this.packageTypeNames[PackageTypeCode.KEGS] = 'Kegs';
    this.packageTypeNames[PackageTypeCode.LIQBULK] = 'Liqbulk';
    this.packageTypeNames[PackageTypeCode.LOG] = 'Log';
    this.packageTypeNames[PackageTypeCode.LOGS] = 'Logs';
    this.packageTypeNames[PackageTypeCode.LOOSE] = 'Loose';
    this.packageTypeNames[PackageTypeCode.LUG] = 'Lug';
    this.packageTypeNames[PackageTypeCode.LUGS] = 'Lugs';
    this.packageTypeNames[PackageTypeCode.LIFTVAN] = 'Liftvan';
    this.packageTypeNames[PackageTypeCode.MIXED] = 'Mixed';
    this.packageTypeNames[PackageTypeCode.NOIL] = 'Noil';
    this.packageTypeNames[PackageTypeCode.NOILS] = 'Noils';
    this.packageTypeNames[PackageTypeCode.OVRWRAP] = 'Ovrwrap';
    this.packageTypeNames[PackageTypeCode.PAIL] = 'Pail';
    this.packageTypeNames[PackageTypeCode.PAILS] = 'Pails';
    this.packageTypeNames[PackageTypeCode.PIECE] = 'Piece';
    this.packageTypeNames[PackageTypeCode.PIECES] = 'Pieces';
    this.packageTypeNames[PackageTypeCode.PIM] = 'Pim';
    this.packageTypeNames[PackageTypeCode.PIMS] = 'Pims';
    this.packageTypeNames[PackageTypeCode.PKG] = 'Pkg';
    this.packageTypeNames[PackageTypeCode.PKGS] = 'Pkgs';
    this.packageTypeNames[PackageTypeCode.PLTFORM] = 'Pltform';
    this.packageTypeNames[PackageTypeCode.PALLET] = 'Pallet';
    this.packageTypeNames[PackageTypeCode.PALLETS] = 'Pallets';
    this.packageTypeNames[PackageTypeCode.VEHICLE] = 'Vehicle';
    this.packageTypeNames[PackageTypeCode.PIPRACK] = 'Piprack';
    this.packageTypeNames[PackageTypeCode.QTRBEEF] = 'Qtrbeef';
    this.packageTypeNames[PackageTypeCode.RACK] = 'Rack';
    this.packageTypeNames[PackageTypeCode.RACKS] = 'Racks';
    this.packageTypeNames[PackageTypeCode.REEL] = 'Reel';
    this.packageTypeNames[PackageTypeCode.REELS] = 'Reels';
    this.packageTypeNames[PackageTypeCode.ROLL] = 'Roll';
    this.packageTypeNames[PackageTypeCode.ROLLS] = 'Rolls';
    this.packageTypeNames[PackageTypeCode.RVR] = 'Rvr';
    this.packageTypeNames[PackageTypeCode.SACK] = 'Sack';
    this.packageTypeNames[PackageTypeCode.SACKS] = 'Sacks';
    this.packageTypeNames[PackageTypeCode.DRYLINR] = 'Drylinr';
    this.packageTypeNames[PackageTypeCode.SUITCAS] = 'Suitcas';
    this.packageTypeNames[PackageTypeCode.SHEET] = 'Sheet';
    this.packageTypeNames[PackageTypeCode.SHEETS] = 'Sheets';
    this.packageTypeNames[PackageTypeCode.SIDBEEF] = 'Sidbeef';
    this.packageTypeNames[PackageTypeCode.SKID] = 'Skid';
    this.packageTypeNames[PackageTypeCode.SKIDS] = 'Skids';
    this.packageTypeNames[PackageTypeCode.SLPSHT] = 'Slpsht';
    this.packageTypeNames[PackageTypeCode.SLPSHTS] = 'Slpshts';
    this.packageTypeNames[PackageTypeCode.SLEEVE] = 'Sleeve';
    this.packageTypeNames[PackageTypeCode.SLEEVES] = 'Sleeves';
    this.packageTypeNames[PackageTypeCode.SPINCYL] = 'Spincyl';
    this.packageTypeNames[PackageTypeCode.SPOOL] = 'Spool';
    this.packageTypeNames[PackageTypeCode.SPOOLS] = 'Spools';
    this.packageTypeNames[PackageTypeCode.TUBE] = 'Tube';
    this.packageTypeNames[PackageTypeCode.TUBES] = 'Tubes';
    this.packageTypeNames[PackageTypeCode.TOTEBIN] = 'Totebin';
    this.packageTypeNames[PackageTypeCode.TANK] = 'Tank';
    this.packageTypeNames[PackageTypeCode.TANKS] = 'Tanks';
    this.packageTypeNames[PackageTypeCode.TIERCE] = 'Tierce';
    this.packageTypeNames[PackageTypeCode.TIERCES] = 'Tierces';
    this.packageTypeNames[PackageTypeCode.TRIWBOX] = 'Triwbox';
    this.packageTypeNames[PackageTypeCode.TRUNK] = 'Trunk';
    this.packageTypeNames[PackageTypeCode.TRUNKS] = 'Trunks';
    this.packageTypeNames[PackageTypeCode.TRAY] = 'Tray';
    this.packageTypeNames[PackageTypeCode.TRAYS] = 'Trays';
    this.packageTypeNames[PackageTypeCode.TOTECAN] = 'Totecan';
    this.packageTypeNames[PackageTypeCode.TUB] = 'Tub';
    this.packageTypeNames[PackageTypeCode.TUBS] = 'Tubs';
    this.packageTypeNames[PackageTypeCode.UNPACKD] = 'Unpackd';
    this.packageTypeNames[PackageTypeCode.UNIT] = 'Unit';
    this.packageTypeNames[PackageTypeCode.UNITS] = 'Units';
    this.packageTypeNames[PackageTypeCode.VANPACK] = 'Vanpack';
    this.packageTypeNames[PackageTypeCode.WHEELED] = 'Wheeled';
    this.packageTypeNames[PackageTypeCode.WLC] = 'Wlc';
    this.packageTypeNames[PackageTypeCode.WRAPPED] = 'Wrapped';
  }

  transform(value?: PackageTypeCode): string {
    return this.packageTypeNames[value] ?? '';
  }
}
