import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FourtySeasInitDialogComponent } from './fourty-seas-init-dialog/fourty-seas-init-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FourtySeasInitDialogService {
  fourtySeasDialogRef: any;
  constructor(public dialog: MatDialog) { }

  openFourtySeasInitDialog(): any {
    this.fourtySeasDialogRef = this.dialog.open(FourtySeasInitDialogComponent, {
      panelClass: 'waiting-fourty-seas-dialog-container'
    });
    return this.fourtySeasDialogRef;
  }

  closeFourtySeasInitDialog(): void {
    this.fourtySeasDialogRef?.close();
  }
}
