import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogOpen: boolean = false;

  constructor() { }

  isDialogOpen(): boolean {
    return this.dialogOpen;
  }

  setDialogOpen(open: boolean): void {
    this.dialogOpen = open;
  }
}
