import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { User } from "@sentry/browser";
import { UserService } from "@ship4wd/ngx-authorization";
import { NotificationType } from "../../../desktop/organizations/organizations.model";
import { OrganizationsService } from "../../../desktop/organizations/organizations.service";
import {
  ChatProfile,
  KycStatusCode,
  UserProfile,
} from "../../../shared/accounts/accounts.model";
import { AccountsService } from "../../../shared/accounts/accounts.service";
import {
  AccessRoleType,
  OrganizationListType,
  TeamAccessRole,
} from "../../../shared/shared.model";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";

import { OrganizationSettingsData } from "../../../shared/organizations/organization.model";
import { SalesforceService } from "../../../shared/services/salesforce.service";
import { QuotesService } from '../../../desktop/quotes/quotes.service';
import { AppcuesService } from "../../../shared/appcues/appcues.service";
import { UserInfoService } from '../../../shared/services/user-info/user-info.service';
import { GoogleAnalyticsService } from "../../../shared/google-analytics/google-analytics.service";

@Component({
  selector: "app-organization-line",
  templateUrl: "./organization-line.component.html",
  styleUrls: ["./organization-line.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationLineComponent implements OnInit {
  @Input() type: OrganizationListType = OrganizationListType.ACCEPTED;
  @Input() data: any = null;
  @Output() refreshPage = new EventEmitter();

  @ViewChild("organizationRejectedTemplate")
  organizationRejectedTemplate: TemplateRef<ElementRef>;
  @ViewChild("organizationPendingTemplate")
  organizationPendingTemplate: TemplateRef<ElementRef>;

  isLoading: boolean = false;
  user: User;
  listTypes = OrganizationListType;
  kycStatusCodes = KycStatusCode;
  userProfile: UserProfile;

  constructor(
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private salesforceService: SalesforceService,
    private accountsService: AccountsService,
    private location: Location,
    public dialog: MatDialog,
    private quotesService: QuotesService,
    private appcuesService: AppcuesService,
    private userInfoService: UserInfoService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getUser();
  }

  selectOrganization(id: string, name: string, countryCode: string): void {
    this.accountsService
      .getUserProfile(id)
      .subscribe((userProfile: UserProfile) => {
        if (userProfile.accessRoles.length > 0) {
          const roles: AccessRoleType[] = [];
          userProfile.accessRoles.map((x: TeamAccessRole) => {
            roles.push(x.accessRoleType);
          });
          this.organizationsService.setUserRoles(roles);
        }
        else {
          this.organizationsService.setUserRoles(null);
        }

        if (userProfile && userProfile.teamMemberSettings) {
          const teamMemberSettings = userProfile.teamMemberSettings;
          teamMemberSettings.disableHelpPopup = false;
        }

        this.organizationsService.setUserSettingsAndInfo(userProfile);

        let chatUser: ChatProfile = {
          fullName: userProfile.fullName,
          email: userProfile.email,
          phoneNumber: userProfile.phoneNumber,
          companyName: name,
          userId: userProfile.id,
          organizationId: id
        };
        localStorage.setItem("chatUserInfo", JSON.stringify(chatUser));
        this.salesforceService.onUserLogout();
        this.salesforceService.addUserChat();

        const organizationSettingsData: OrganizationSettingsData = {
          organizationId: id,
          countryCode: countryCode,
          organizationName: name,
          isCreditOrganization: this.data.isCreditOrganization
        };

        this.organizationsService.setOrganization(organizationSettingsData);
        this.quotesService.removeQuotesResultsFromStorage();
        this.appcuesService.identify()
        this.organizationsService.returnBack();
      },
        (error) => {
          this.organizationsService.notify(error, NotificationType.ERROR);
        });

    this.userInfoService.setDepartureDateFlexibilitySettings();

    this.googleAnalyticsService.organizationsClick("select");
  }

  async getConversation(id: string): Promise<void> {
    await this.salesforceService.openChat();
  }

  acceptInvite(id: string): void {
    this.isLoading = true;
    this.organizationsService.acceptInvitation(id, this.user.userId).subscribe(
      (success) => {
        this.organizationsService.notify("Accepted", NotificationType.SUCCESS);
        this.organizationsService.notifyChange();
      },
      (error) => {
        this.organizationsService.notify(error, NotificationType.ERROR);
      }
    ).add(() => this.isLoading = false);
  }

  rejectInvite(id: string): void {
    this.isLoading = true;
    this.organizationsService.rejectInvitation(id, this.user.userId).subscribe(
      (success) => {
        this.organizationsService.notify("Rejected", NotificationType.ERROR);
        this.organizationsService.notifyChange();
      },
      (error) => {
        this.organizationsService.notify(error, NotificationType.ERROR);
      }
    ).add(() => this.isLoading = false);;
  }

  getOrganizationKycStatus(data: any): void {
    const url = document.location.href.replace(window.location.origin, "");
    this.location.go(this.getUrl(url));

    this.isLoading = true;
    this.organizationsService
      .getOrganizationKycStatus(data.organizationId)
      .subscribe(
        (response) => {
          if (response.kycStatus == KycStatusCode[KycStatusCode.Approve]) {
            this.selectOrganization(data.organizationId, data.organizationName, data.countryCode);
          } else {
            this.showMessage(response.kycStatus);
          }
        },
        (error) => {
          this.organizationsService.notify(error, NotificationType.ERROR);
        }
      )
      .add(() => {
        this.isLoading = false;
        const combined = this.removeParamFromUrl(url);
        this.location.go(combined);
      });
  }

  private showMessage(kycStatus: string): void {
    let initialTemplate: TemplateRef<any> = null;
    switch (kycStatus) {
      case KycStatusCode[KycStatusCode.Pending]:
        initialTemplate = this.organizationPendingTemplate;
        break;
      case KycStatusCode[KycStatusCode.Reject]:
        initialTemplate = this.organizationRejectedTemplate;
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: "on-popup-container",
      autoFocus: false,
      data: {
        initialTemplate,
        showConfirmButton: true,
        confirmButtonText: "Close",
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (
        result.result === true &&
        kycStatus == KycStatusCode[KycStatusCode.Reject]
      ) {
        this.refreshOrganizationsList();
      }
    });
  }

  private refreshOrganizationsList(): void {
    this.refreshPage.emit();
  }

  public removeParamFromUrl(url: string): string {
    const firstPart = url.split("#")[0];
    return firstPart;
  }

  public getUrl(url: string): string {
    const firstPart = url.split("#")[0];
    return firstPart + "&pendingclick=companyname#";
  }
}
