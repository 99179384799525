import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GooglePlaceService {

    constructor() { }

    loadScript(apiKey: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`;
        document.head.appendChild(script);
    }
}

