export const environment = {
  production: true,
  apiBaseUrl: 'https://api-prod.ship4wd.com/application-server/v1',
  authorization: {
    endpoint: 'https://sso-portal.ship4wd.com/login',
    applicationId: 'fcc1fd99-54b1-44e0-dc0d-08da380f6323',
    isCookies: false,
    isLocal: false
  },
  appSettingsExpirationDays: 2,
  appInsightsInstrumentationKey: "b5945c5d-d058-4520-8db0-f701a0a43b9c",
  companySignUpUrl: 'https://sso-portal-dev.ship4wd.com/create-account',
  googleAnalyticsID: 'GTM-PRWQBT3',
  googlePlaceApiKey: 'AIzaSyBUnzTclUzZs24Mi0fyWBbabOmWQpajm10',
  hubspotScriptId: '43761477',
  lclMaxWeight: 8000,
  lclMaxCapacity: 14.1584,
  lclWeightPackageLimit: 11500,
  lclLengthPackageLimit: 366,
  lclHeightPackageLimit: 254,
  lclWidthPackageLimit: 231,
  pageSize: 30,
  quotesQueryPageSize: 5,
  quotesResultsExpirationTime: 10 * 60 * 1000,
  amplitude: {
    apiKey: "91118701773f6823175a1e15d79c3feb",
    deployKey: 'client-cgIMB7qyt3h2WOXQNmrZA4ZVBx69ulkw'
  },
  appcues: {
    appId: "212840",
    isEnabled: true
  },
  heap: {
    appId: "3469137695",
    isEnabled: true
  },
  lcl: {
    minAllowedShippingDate: 1,
    maxAllowedShippingDate: 4,
    maxAllowedDate: 25,
    bookingExpirationDays: 3
  },
  fcl: {
    minAllowedShippingDate: 7,
    maxAllowedShippingDate: 4,
    maxAllowedDate: 45,
    bookingExpirationDays: 3
  },
  air: {
    minAllowedShippingDate: 2,
    maxAllowedShippingDate: 1,
    maxAllowedDate: null,
    bookingExpirationDays: 2
  },
  hideUnsupportedIcons: true,
  isMobileEnabled: true,
  isFinanceEnabled: true,
  holdTypingTimeMiliseconds: 1000,
  isLocationAutocompleteSearch: true,
  isCreditLineEnabled: true,
  isAdditionalServicesEnabled: true,
  isDashboardEnabled: true,
  isMobileDashboardEnabled: true,
  isWidgetsEditingEnabled: false,
  isShowSupportPopupAutoEnabled: true,
  newQuoteSearchFlowEnabled: true,
  isWhatsAppEnabled: false,
  whatsAppPhoneNumber: "972549611900",
  newQuoteRowDetailsEnabled: true,
  newQuoteRowPriceEnabled: true,
  isDnbEnabled: false,
  isPriceTrendsEnabled: true,
  isQuoteBadgeEnabled: true,
  isQuotesBannerEnabled: true,
  isPriceTagsEnabled: true,
  isRecentQuotesWidgetEnabled: true,
  priceTiersComparisonExternalUrl: "https://ship4wd.com/support-category/shipping-quotes/#support-5145",
  isMostPopularEnabled: {
    fcl: true,
    lcl: true,
    air: true
  },
  isSmartlookEnabled: true,
  smartloookProjectKey: "ca42de5e88fc4f12b26b9366d39edf081324d675",
  salesforce: {
    isNewSfChatEnabled: true,
    newChat: {
      id: "00D4K000004gwqK",
      name: "Messaging_In_Web_Chat",
      url: "https://ship4wd.my.site.com/ESWMessagingInWebChat1710770464096",
      scriptUrl: "https://ship4wd.my.salesforce-scrt.com",
      embededUrl: "https://ship4wd.my.site.com/ESWMessagingInWebChat1710770464096/assets/js/bootstrap.min.js",
      headerId: "c4a17cf9-051e-4e78-9f55-7bc285065a7b",
      privateKey: `-----BEGIN PRIVATE KEY-----
      MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDNyU8F11CMQk+i
      xQmWVoU77Xf33IPBn4xBjX/soFQqOMwokFgN4xUdpG9Fe4NZtPy0yQNvACcaxmcq
      9bbbBsLFcy0aBIgwvIoCef/sEBKzuecFiIRH8oRaVTgpYyiGFtDSHU4Z+A1cGAK7
      jtYSalLTyNiTFiK2pUcRurqEGJBmdmKdBiK+bJasICOBTpC2sFz+jy1uDqdf10X4
      +As2V/QAIc/IrJrP/Qx4kNQCrf6m06wvDu4+aE4VhUGUH4YEpKpZRElUrvwyzKlY
      oOeAQ+Z+53faSV7X/Wu0OvpUBXdHwNfZ+7aQXtdXzwaEk/e6tzJAxACr/CJnn9Vr
      3B58qTk/AgMBAAECggEBAIhtRW5h6IyUCaenaUu54GpybG5uSws6cwmifYSqZiH3
      taHO0BGk0oLsWLh/wfze+AoCmbwlJceicRMSA0ABo26zhNsS1hc5Z2Vs7DXcEgoE
      3RvV/ZyVMxmQFcOacWs0BQzHUNf2kyTS5jzh3RooUvEE3k/lBO6BdDRUzwJ0JodG
      FFMG7ExcFXY7VWvh95aSShKV6KqaIi+8oOpEJPi/I6a4M9lF0OvIfYemCAj3KShB
      zadKf6JO0W/XvgVU4gxk6S2PBAkm7+uTx5f9EpFrEcxu/FVyfdSOFT1FPy+d1G44
      iTFlO5d/UyWo4veV4J1jL/zTdjsKYv2M2uN523b52wECgYEA7zGulcdxv0pZs491
      t+tQ/8aQPrP0W3ZS5YFwytnVBwr6PfXShx4GOSY9squTTcFNo/CsYhevxARcMZvG
      skugSAnNoH8tNc6xn+3QL079ya+EObo4FVlvFVNJtNzZJfX66o2q79ryR2BDyR/l
      iEQmLwqsaPBY6WPCGuCyztMyurECgYEA3D67P7w+wTbIHNjeP0REcfozGAQLO08a
      mRWwjDkKUuFJikXnKwS6Y2sA4NzuLmziDjk/AzzWTsbdp/TXfL9vEaUQFJZ3rm5D
      7s9Uo+67Cj269bJvKg9KQuQ0Lepkn06V8fQJngyEBVr6ksFY7ZT+ku5sg+WuE2Z6
      LFR0aAbbTu8CgYA1jng6zSdo32rqS4reHXBLdNhDUeO6vumcvbjVkqSiKRES4oG7
      Azv9e9SAtu4KkOejd3YBmLAgQaGq8egxb8F5n8hM0Wz8CUW4O3VLwnIMwKHokwuP
      rDC1I6rzNifg2gzOi93kSq/Iz4Md+2hXdHrgOZGVBYvC5jVF813V9W/8YQKBgQDD
      1INqCFdENRF5lNsIJgs1zOHhWLcj5RIhS8fO5mjniKl66OzCs1GHVH1Tcx3U6buW
      KhB9MHrdULWFwY5YvV5ksf+6To/b6O8+R9taZ8qnMHwsOpvdKfs9vusmaGNPwvBQ
      Uky62yYIHPzvwDryBKjWvGiAXpiJZ3T6NZ3LYU1ewQKBgBtfJtkY47IaXZv4dTAE
      hifKKAHL3CuKzZuDJ4kkFodUJP25oqzq2GWPuldiSQZ7SDB/G/D+Y9Skwu6/t+XW
      2rxHRA5wThARaqFy/7JojFE1o+Gp5I9l9kiB76jjk7rBIFPkWGxJrZ7y3vPDpRKi
      uFdaZNtr58QR7CRRb3VabHSg
      -----END PRIVATE KEY-----`
    },
    url: 'https://ship4wd.my.salesforce.com',
    id: '00D4K000004gwqK',
    setupFlow: 'https://allcloud4.secure.force.com/liveAgentSetupFlow',
    chatName: 'Chat_Team',
    embeddedUrl: 'https://ship4wd.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    baseLiveAgentContentURL: 'https://c.la1-c2-lo3.salesforceliveagent.com/content',
    baseLiveAgentURL: 'https://d.la1-c2-lo3.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'Chat_Team',
  },
  tripUnLocodeAutocompleteMaxItems: 5,
  qa: {
    apiBaseUrl: 'https://qa.ship4wd.com/application-server/v1',
    authorization: {
      endpoint: 'https://sso-portal-qa.ship4wd.com/login',
      applicationId: 'fcc1fd99-54b1-44e0-dc0d-08da380f6323',
      isCookies: false,
      isLocal: false
    },
    appSettingsExpirationDays: 2,
    appInsightsInstrumentationKey: "f0599bc0-3143-4c08-8ebe-ec3185d7f957",
    hideUnsupportedIcons: true,
    isMobileEnabled: true,
    isFinanceEnabled: true,
    googleAnalyticsID: null,
    googlePlaceApiKey: 'AIzaSyBUnzTclUzZs24Mi0fyWBbabOmWQpajm10',
    hubspotScriptId: null,
    isLocationAutocompleteSearch: true,
    isLoadClarityScript: false,
    isSmartlookEnabled: false,
    smartloookProjectKey: "ca42de5e88fc4f12b26b9366d39edf081324d675",
    isCreditLineEnabled: true,
    isAdditionalServicesEnabled: true,
    isLoadGoogleAnalyticsScript: false,
    isLoadHubspotScript: false,
    isDashboardEnabled: true,
    isMobileDashboardEnabled: true,
    isShowSupportPopupAutoEnabled: true,
    isWidgetsEditingEnabled: true,
    quotesResultsExpirationTime: 10 * 60 * 1000,
    newQuoteSearchFlowEnabled: true,
    isWhatsAppEnabled: true,
    whatsAppPhoneNumber: "972549611900",
    newQuoteRowDetailsEnabled: true,
    newQuoteRowPriceEnabled: true,
    isDnbEnabled: false,
    isPriceTrendsEnabled: true,
    isQuoteBadgeEnabled: true,
    isQuotesBannerEnabled: true,
    isPriceTagsEnabled: true,
    isRecentQuotesWidgetEnabled: true,
    priceTiersComparisonExternalUrl: "https://ship4wd.com/support-category/shipping-quotes/#support-5145",
    amplitude: {
      apiKey: "91118701773f6823175a1e15d79c3feb",
      deployKey: 'client-ZksN2blinsCwTcUn1mMPhlUZU5jcUpLL'
    },
    appcues: {
      appId: "212840",
      isEnabled: true
    },
    heap: {
      appId: "4184291908",
      isEnabled: true
    },
    isMostPopularEnabled: {
      fcl: true,
      lcl: true,
      air: true
    },
    salesforce: {
      isNewSfChatEnabled: true,
      newChat: {
        id: "00DAe000001QL3R",
        name: "Messaging_In_Web_Chat",
        url: "https://ship4wd--staging.sandbox.my.site.com/ESWMessagingInWebChat1705853257395",
        scriptUrl: "https://ship4wd--staging.sandbox.my.salesforce-scrt.com",
        embededUrl: "https://ship4wd--staging.sandbox.my.site.com/ESWMessagingInWebChat1705853257395/assets/js/bootstrap.min.js",
        headerId: "f00edb60-c2ec-3c50-959d-ea97bf08b42d",
        privateKey: `-----BEGIN PRIVATE KEY-----
        MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2Fx69nH262ygF
        XTZKED6lSpQ4aOHEExPq4WKPBYZ1sXqZxSAY12g7chYYPO6Qa6z5iFX1RbDxA4YV
        gImPnWbBWrZC2JnJ+5kBz1Ceqoy8B+73L/mpAXZ9gzhnNpk9RieEP9P4bbKvzrXB
        svZQDi8uxx30VRPP2Swx/lCOMxatBeShUsWMYzemh/A4KhR3EFi0wefLaCmA0hqE
        0p/ZcMvKUyI2CJneYYt+B3XfERN1gAtqZZeCNGqt63Ex3+TanW2AHHtqSXw2QQj/
        Z3wRe3SL+1ho2ZeAveFr36sc2TmVh7AVL/nS7t247vqBnlY2A1Eb5pMEc3dJ+8GG
        7ylxhc7xAgMBAAECggEAWzJTJBXXZplSfvVDNm7SYUhr2aC1ydTgOdft28GhqRpd
        U9xFiguAWZ128Wa6+Lvk4wjkPDZOOzdPlT9OJg5oI1jCRdJjm6SV6jEhqIW0fIvg
        pA50FK/130C0JAVVVDnpmrO4/wg54RE/OKbf9BJWkTAKkY92n7eeCd/cCowNxFOB
        7TAVRRNHWPmqgOEwBg7teG/DMKe7+edHo1zPZlfe+L77HudEu6GTRKyl6bPFpdf3
        iUN4uLm/dSJkMEPTuoXYdOrzAFhVYEk5JOIRtGen0yaL/A8niTvJ1tKa3XFaCiZP
        LpIATSYfb4Xty6ETocmv/FT780xRJuDIwaMp1cXckQKBgQDdFSVO9GWUt0QXJ80T
        yIR+71lDwGXixw9LEQUMFWxHSJrYRxLHA5h/p65jv/qD/bAjL0r5XHSgWPMwH4i4
        Mz1NvVWN9DdOkKt+SKerSEUC3k2sdjozF4MPvpGkm8X0Qm4Pe8+9yWNb8Y2HyCUj
        tn/sWOPKQ3vIQS1ikZz2zuzbhQKBgQDS2W4/1zNg/xcFXjwo/LxZ36LnwRQxw34d
        4tD6x3KAnigqINua2Q0sw7NAMpbPqP+LALOyZqb2oitidK7i+lVUcJ1rdVfSR5q5
        oGEAWdOVspbRf0atVx3z71zAzO4+RSDuzAQb4RANp/w1lqBtHphmMFvb6itx5LAE
        uz9PzjDTfQKBgAVBeW+VE80jRjUetxtfSvcRjZe0vTdU8CIit3IAGGDhcvztQ58D
        kN6IB/dLGxOENmm/uBKRhhxBwRZFyaRHY4+zjpMfWSk++zS3WXUS9MEKs+IDtYJ9
        bpozN5coNN6HrcIkeV3Tqc8HwQuXUHq23feqbU/jps+t7PxI5ehgJvVZAoGAHUyv
        oFl0P09TUKCmtX9hYQ/NoGzZNI0Y8mg5aA/dHnhb1nbYbW0qAoVzXEFraZHvaM6B
        KhJPs0gXxUe3obztrJ01kuAsgwEGRDNAJ39UAqJdiOEp3h6T//jMJ9KSbL7Ic8aP
        75vX0j38symqQ1+UB+dCbI3e2aXQu+eVRa6+ZDUCgYEA0DAZt/ErFKyaxpUcl3ci
        3r+pfdQeAxM0R67zIxQzYblpRzkuBJRYnTzpBuWWLmDpPu4vVcvyV2Q2wqKjieCi
        KgoB+UWChvWodXLe6UHX/NMlvVvl1YeWfUpQgh+DiPHa9cZXUe5wobLQYR/vLPk0
        xXy65/dDIg+JNrAwl8ty4Ts=
        -----END PRIVATE KEY-----`
      },
      url: 'https://ship4wd--cloudtech.sandbox.lightning.force.com',
      id: '00D0C0000008foy',
      setupFlow: 'https://ship4wd--cloudtech.sandbox.my.salesforce-sites.com/liveAgentSetupFlow',
      chatName: 'test_for_chat_to_leads',
      embeddedUrl: 'https://ship4wd--cloudtech.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseLiveAgentContentURL: 'https://c.la1-c1cs-lo2.salesforceliveagent.com/content',
      baseLiveAgentURL: 'https://d.la1-c1cs-lo2.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0C000000CatYUAS_18c1564c569',
    },
    tripUnLocodeAutocompleteMaxItems: 5,
  },
  sb: {
    apiBaseUrl: 'https://api-sb.ship4wd.com/application-server/v1',
    authorization: {
      endpoint: 'https://sso-portal-sb.ship4wd.com/login',
      applicationId: 'fcc1fd99-54b1-44e0-dc0d-08da380f6323',
      isCookies: false,
      isLocal: false
    },
    appSettingsExpirationDays: 2,
    appInsightsInstrumentationKey: "bd749be0-157c-42d5-aa5e-f8a0df64c076",
    hideUnsupportedIcons: true,
    isMobileEnabled: true,
    isFinanceEnabled: true,
    salesforceUrl: 'https://ship4wd--cloudtech.sandbox.lightning.force.com',
    googleAnalyticsID: 'GTM-5VRVKVL',
    googlePlaceApiKey: 'AIzaSyBUnzTclUzZs24Mi0fyWBbabOmWQpajm10',
    hubspotScriptId: '44203394',
    isLocationAutocompleteSearch: true,
    isLoadClarityScript: false,
    isSmartlookEnabled: false,
    smartloookProjectKey: "ca42de5e88fc4f12b26b9366d39edf081324d675",
    isCreditLineEnabled: true,
    isAdditionalServicesEnabled: true,
    isLoadGoogleAnalyticsScript: true,
    isLoadHubspotScript: true,
    isDashboardEnabled: true,
    isMobileDashboardEnabled: true,
    isShowSupportPopupAutoEnabled: true,
    isWidgetsEditingEnabled: false,
    quotesResultsExpirationTime: 10 * 60 * 1000,
    newQuoteSearchFlowEnabled: true,
    isWhatsAppEnabled: true,
    whatsAppPhoneNumber: "972549611900",
    newQuoteRowDetailsEnabled: true,
    newQuoteRowPriceEnabled: true,
    isDnbEnabled: false,
    isPriceTrendsEnabled: true,
    isQuoteBadgeEnabled: true,
    isQuotesBannerEnabled: true,
    isPriceTagsEnabled: true,
    isRecentQuotesWidgetEnabled: true,
    priceTiersComparisonExternalUrl: "https://ship4wd.com/support-category/shipping-quotes/#support-5145",
    amplitude: {
      apiKey: "91118701773f6823175a1e15d79c3feb",
      deployKey: 'client-jhmcI7XnqiR2lLZ8sZEjLYa9YgEdUMnH'
    },
    appcues: {
      appId: "212840",
      isEnabled: true
    },
    heap: {
      appId: "4184291908",
      isEnabled: true
    },
    isMostPopularEnabled: {
      fcl: true,
      lcl: true,
      air: true
    },
    salesforce: {
      isNewSfChatEnabled: true,
      newChat: {
        id: "00DAe000001QL3R",
        name: "Messaging_In_Web_Chat",
        url: "https://ship4wd--staging.sandbox.my.site.com/ESWMessagingInWebChat1705853257395",
        scriptUrl: "https://ship4wd--staging.sandbox.my.salesforce-scrt.com",
        embededUrl: "https://ship4wd--staging.sandbox.my.site.com/ESWMessagingInWebChat1705853257395/assets/js/bootstrap.min.js",
        headerId: "f00edb60-c2ec-3c50-959d-ea97bf08b42d",
        privateKey: `-----BEGIN PRIVATE KEY-----
        MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2Fx69nH262ygF
        XTZKED6lSpQ4aOHEExPq4WKPBYZ1sXqZxSAY12g7chYYPO6Qa6z5iFX1RbDxA4YV
        gImPnWbBWrZC2JnJ+5kBz1Ceqoy8B+73L/mpAXZ9gzhnNpk9RieEP9P4bbKvzrXB
        svZQDi8uxx30VRPP2Swx/lCOMxatBeShUsWMYzemh/A4KhR3EFi0wefLaCmA0hqE
        0p/ZcMvKUyI2CJneYYt+B3XfERN1gAtqZZeCNGqt63Ex3+TanW2AHHtqSXw2QQj/
        Z3wRe3SL+1ho2ZeAveFr36sc2TmVh7AVL/nS7t247vqBnlY2A1Eb5pMEc3dJ+8GG
        7ylxhc7xAgMBAAECggEAWzJTJBXXZplSfvVDNm7SYUhr2aC1ydTgOdft28GhqRpd
        U9xFiguAWZ128Wa6+Lvk4wjkPDZOOzdPlT9OJg5oI1jCRdJjm6SV6jEhqIW0fIvg
        pA50FK/130C0JAVVVDnpmrO4/wg54RE/OKbf9BJWkTAKkY92n7eeCd/cCowNxFOB
        7TAVRRNHWPmqgOEwBg7teG/DMKe7+edHo1zPZlfe+L77HudEu6GTRKyl6bPFpdf3
        iUN4uLm/dSJkMEPTuoXYdOrzAFhVYEk5JOIRtGen0yaL/A8niTvJ1tKa3XFaCiZP
        LpIATSYfb4Xty6ETocmv/FT780xRJuDIwaMp1cXckQKBgQDdFSVO9GWUt0QXJ80T
        yIR+71lDwGXixw9LEQUMFWxHSJrYRxLHA5h/p65jv/qD/bAjL0r5XHSgWPMwH4i4
        Mz1NvVWN9DdOkKt+SKerSEUC3k2sdjozF4MPvpGkm8X0Qm4Pe8+9yWNb8Y2HyCUj
        tn/sWOPKQ3vIQS1ikZz2zuzbhQKBgQDS2W4/1zNg/xcFXjwo/LxZ36LnwRQxw34d
        4tD6x3KAnigqINua2Q0sw7NAMpbPqP+LALOyZqb2oitidK7i+lVUcJ1rdVfSR5q5
        oGEAWdOVspbRf0atVx3z71zAzO4+RSDuzAQb4RANp/w1lqBtHphmMFvb6itx5LAE
        uz9PzjDTfQKBgAVBeW+VE80jRjUetxtfSvcRjZe0vTdU8CIit3IAGGDhcvztQ58D
        kN6IB/dLGxOENmm/uBKRhhxBwRZFyaRHY4+zjpMfWSk++zS3WXUS9MEKs+IDtYJ9
        bpozN5coNN6HrcIkeV3Tqc8HwQuXUHq23feqbU/jps+t7PxI5ehgJvVZAoGAHUyv
        oFl0P09TUKCmtX9hYQ/NoGzZNI0Y8mg5aA/dHnhb1nbYbW0qAoVzXEFraZHvaM6B
        KhJPs0gXxUe3obztrJ01kuAsgwEGRDNAJ39UAqJdiOEp3h6T//jMJ9KSbL7Ic8aP
        75vX0j38symqQ1+UB+dCbI3e2aXQu+eVRa6+ZDUCgYEA0DAZt/ErFKyaxpUcl3ci
        3r+pfdQeAxM0R67zIxQzYblpRzkuBJRYnTzpBuWWLmDpPu4vVcvyV2Q2wqKjieCi
        KgoB+UWChvWodXLe6UHX/NMlvVvl1YeWfUpQgh+DiPHa9cZXUe5wobLQYR/vLPk0
        xXy65/dDIg+JNrAwl8ty4Ts=
        -----END PRIVATE KEY-----`
      },
      url: 'https://ship4wd--cloudtech.sandbox.lightning.force.com',
      id: '00D0C0000008foy',
      setupFlow: 'https://ship4wd--cloudtech.sandbox.my.salesforce-sites.com/liveAgentSetupFlow',
      chatName: 'test_for_chat_to_leads',
      embeddedUrl: 'https://ship4wd--cloudtech.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseLiveAgentContentURL: 'https://c.la1-c1cs-lo2.salesforceliveagent.com/content',
      baseLiveAgentURL: 'https://d.la1-c1cs-lo2.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0C000000CatYUAS_18c1564c569',
    },
    tripUnLocodeAutocompleteMaxItems: 5,
  },
  isLoadClarityScript: true,
  isLoadGoogleAnalyticsScript: true,
  isLoadHubspotScript: true
};
