import {
  EventEmitter,
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  TripsSearchFromViewModel,
  LocationType,
  ShipmentType,
} from "../../../../../../shared/shared.model";
import { StringCutterService } from "../../../../../../shared/services/text-cutter/stringCutter.service";
import { SupportedCountry } from "../../../../../../shared/supported-countries/supported-countries.model";

@Component({
  selector: "app-quote-search-flow-shipping-location-option",
  templateUrl: "./quote-search-flow-shipping-location-option.component.html",
  styleUrls: ["./quote-search-flow-shipping-location-option.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuoteSearchFlowShippingLocationOptionComponent implements OnInit {
  @Input() location: TripsSearchFromViewModel = null;
  @Input() extended: boolean = false;
  @Input() title: string = null;
  @Input() shipmentType: ShipmentType;
  @Input() supportedCountries: SupportedCountry[];
  @Input() isDisabled: boolean = false;
  @Output() isSelected: EventEmitter<any> = new EventEmitter();
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() isConfirmed: EventEmitter<TripsSearchFromViewModel> =
    new EventEmitter();
  @ViewChild("postCodeInput") postCodeInput: ElementRef;
  postcode: FormControl;
  fullAddress: FormControl;
  placesOptions = null;

  constructor(
    private stringCutterService: StringCutterService
  ) { }

  ngOnInit(): void {
    if (this.location?.country) {
      this.placesOptions = {
        componentRestrictions: { country: [this.location.country] },
      };
    }

    this.fullAddress = new FormControl("", Validators.required);
    this.postcode = new FormControl("", Validators.required);
    setTimeout(() => {
      this.postCodeInput?.nativeElement?.focus();
    }, 500);
  }

  onSelect(event): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDisabled) {
      this.isSelected.next({ location: this.location, title: this.title });
    }
  }

  onClose(): void {
    this.isClosed.next(true);
  }

  onConfirm(): void {
    if (this.fullAddress?.valid) {
      this.location.zipCode = this.postcode.value;
      this.location.fullAddress = this.fullAddress.value;
      this.isConfirmed.next(this.location);
    }
  }

  onAddressChange(address: any): void {
    const fullAddressValue = address.address_components
      .filter(
        (component) =>
          !component.types.includes("postal_code") &&
          !component.types.includes("plus_code") &&
          !component.types.includes("country")
      )
      .map((component) => component.short_name || component.long_name)
      .join(", ");

    this.fullAddress.patchValue(fullAddressValue);
    for (let i in address.address_components) {
      const item = address.address_components[i];
      if (item["types"].indexOf("postal_code") > -1) {
        this.postcode.patchValue(item["long_name"]);

        return;
      } else {
        this.postcode.patchValue(this.location.zipCode);
      }
    }
  }

  isMustVerifyPostCode(
    tripsSearchFromViewModel: TripsSearchFromViewModel | any
  ): boolean {
    if (tripsSearchFromViewModel.locationType === LocationType.townCity) {
      if (
        this.shipmentType === ShipmentType.LCL ||
        this.shipmentType === ShipmentType.AIR
      ) {
        return true;
      } else {
        return this.isCountryTruckEnable(tripsSearchFromViewModel?.country);
      }
    }

    return false;
  }

  getLocationDoorAdress(location: TripsSearchFromViewModel, shortName: boolean): string | null {
    if (!location) {
      return null;
    }

    if (location.locationType == LocationType.townCity && location.fullAddress != null && !this.isDisabled) {
      return this.geLocationFullAddress(location, shortName);
    }

    return null;
  }

  getLocationTooltipText(location: TripsSearchFromViewModel): string {
    return this.isDisabled ? 'Destination cannot be within the same country' : location.displayName;
  }

  private isCountryTruckEnable(countryCode: string): boolean {
    const found = this.supportedCountries?.find(x => x.countryCode == countryCode && x.isTruckEnabled);
    return found != undefined;
  }

  private geLocationFullAddress(location: TripsSearchFromViewModel, shortName: boolean): string | null {
    const doorAddress = `${location.zipCode}, ${location.fullAddress}`;
    return shortName ? this.stringCutterService.cutter(doorAddress, 35) : doorAddress;
  }
}
