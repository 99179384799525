<div *ngIf="quote" class="quotes-list-item default">
  <div class="rate-service-type-tag-wrapper" *ngIf="isBadgeEnabled">
    <div class="custom-rate-service-type-tag-wrapper">
      <span class="rate-service-type-text" [ngClass]="isBasicRateServiceType() ? 'basic-rate-type' : ''"
        (click)="onRateServiceType()" [s4dTooltip]="tooltipTemplate" tooltipPosition="above">
        {{ isBasicRateServiceType() ? "Basic" : "Standard" }}
      </span>
    </div>
    <div *ngIf="specialServices && specialServices.length > 0" class="special-services-container">
      <div class="d-flex">
        <div *ngFor="let service of specialServices" class="special-label" [ngClass]="service">
          {{ service | titlecase }}
        </div>
      </div>
    </div>
  </div>
  <div class="row quote-details" (click)="expandDetailRow()">
    <div class="col px-0 estimated-details estimated-departure">
      <div class="row"
        [matTooltip]="isShowEstimatedTooltips ? originEstimatedDepartureTooltip(quote.trip.fromLocationTypeCode | locationTypeShortName) : null"
        matTooltipPosition="above" matTooltipClass="text-center">
        <div class="col-12">
          <span class="estimated-type-title">{{isShowFullNames ? 'Estimated Departure' : 'Estimated departure'}}</span>
        </div>
        <div *ngIf="quote.departureDate && quote.arrivalDate" class="col-12">
          <span class="estimated-title">{{ quote.departureDate | date : isShowFullNames ? 'MMM dd, yy' :
            'dd.MM.yyyy'}}</span>
        </div>
        <div *ngIf="!quote.departureDate || !quote.arrivalDate" class="col-12 pe-0">
          <span class="estimated-tbd">Schedule Not Available</span>
        </div>
        <div *ngIf="!quote.departureDate || !quote.arrivalDate" class="col-12 position-relative">
          <span class="estimated-subtitle">&nbsp;</span>
        </div>
      </div>
    </div>
    <div class="col estimated-details estimated-status">
      <div class="row">
        <div class="col-12 text-center">
          <span *ngIf="!isShowEstimatedDuration(); else estimatedDurationTemplate" class="estimated-type-title">{{
            getEstimationText }}</span>
        </div>
        <div class="col-12">
          <div class="status-wrapper">
            <div class="status-line">
              <div class="from-icon-wrapper">
                <i class="status-icon s4d-icon" [ngClass]="quote?.trip?.fromLocationTypeCode | locationTypeS4DIcon"></i>
              </div>
              <div class="main-icon-wrapper">
                <i class="status-icon s4d-icon" [ngClass]="getShipmentTypeIcon() | haulageTypeS4DIcon"></i>
              </div>
              <div class="to-icon-wrapper">
                <i class="status-icon s4d-icon" [ngClass]="quote?.trip?.toLocationTypeCode | locationTypeS4DIcon"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-end">
          <span class="estimated-subtitle">&nbsp;</span>
        </div>
      </div>

    </div>
    <div class="col px-0 estimated-details estimated-arrival">
      <div class="row"
        [matTooltip]="isShowEstimatedTooltips ? originEstimatedArrivalTooltip(quote.trip.toLocationTypeCode | locationTypeShortName) : null"
        matTooltipPosition="above" matTooltipClass="text-center">
        <div class="col-12">
          <span class="estimated-type-title">{{isShowFullNames ? 'Estimated Arrival' : 'Estimated arrival'}}</span>
        </div>
        <div *ngIf="quote.arrivalDate" class="col-12">
          <span class="estimated-title">{{ quote.arrivalDate | date : isShowFullNames ? 'MMM dd, yy' :
            'dd.MM.yyyy'}}</span>
        </div>
        <div *ngIf="!quote.arrivalDate" class="col-12 pe-0">
          <span class="estimated-tbd">Schedule Not Available</span>
        </div>
        <div *ngIf="!quote.arrivalDate" class="col-12">
          <span class="estimated-subtitle">&nbsp;</span>
        </div>
      </div>
    </div>
    <div class="col estimated-details estimated-total">
      <ng-container *ngIf="quoteRowPriceTemplate" [ngTemplateOutlet]="quoteRowPriceTemplate"
        [ngTemplateOutletContext]="{quote, isShowFullNames}">
      </ng-container>
      <ng-content *ngIf="!quoteRowPriceTemplate" select="quotePrice"></ng-content>
    </div>
    <div class="col-auto pe-0 estimated-actions">
      <div class="row">
        <div class="col d-flex flex-column align-items-center">
          <button mat-raised-button class="booking-button button-spinner" (click)="onBookQuote($event, quote)"
            [disabled]="isLoading">
            <span class="button-inner">
              <span *ngIf="!isLoading">{{ isBasicRateServiceType() ? "Select" : "Lock in Price!" }}</span>
              <mat-spinner diameter="25" *ngIf="isLoading"></mat-spinner>
            </span>
          </button>

          <div class="detail-charge mt-2">
            <div class="detail-subtitle d-flex">{{index === selectedIndex ? 'Hide Price Details':'Show Price Details'}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="quotes-detail-content" *ngIf="index === selectedIndex">
    <div class="row m-0 flex-column">
      <div class="col px-0">
        <ng-container *ngIf="quoteDetailsTemplate" [ngTemplateOutlet]="quoteDetailsTemplate"
          [ngTemplateOutletContext]="{quote}">
        </ng-container>
        <ng-content *ngIf="!quoteDetailsTemplate" select="quoteDetails"></ng-content>
      </div>
    </div>
  </div>
  <ng-container *ngIf="quotesQuery.shipmentType !== ShipmentTypes.AIR && quote.arrivalDate && quote.departureDate">
    <div class="row m-0 quote-detail-footer justify-content-between flex-nowrap">
      <div class="col-auto d-flex flex-nowrap detail-item">
        <div class="detail-subtitle col-auto">Vessel Name</div>
        <div class="ml-2 detail-subtitle-value">{{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.vesselName || 'n/a' }}
        </div>
      </div>
      <div class="col-auto d-flex flex-wrap detail-item">
        <div class="detail-subtitle">Voyage</div>
        <div class="ml-2 detail-subtitle-value">{{ quote?.trip?.mainCarriageTripLeg?.legs[0]?.voyage || 'n/a' }}</div>
      </div>
      <div class="col-auto d-flex flex-wrap detail-item">
        <div class="detail-subtitle">Cut-Off Date</div>
        <div class="ml-2 detail-subtitle-value">{{ getCutOffDateTitle(quote) }}</div>
      </div>
      <div class="col-auto d-flex flex-wrap detail-item">
        <div class="detail-subtitle">{{ isBasicRateServiceType() ? "Limited Availability" : "Price Guarantee"}}</div>
        <div class="ml-2 d-flex align-items-center justify-content-center detail-subtitle-icon">
          <i class="s4d-icon" [ngClass]="isBasicRateServiceType() ? 's4d-stop-watch' : 's4d-verified-filled'"></i>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!quote.arrivalDate || !quote.departureDate">
    <div class="row m-0 quote-detail-footer justify-content-between flex-nowrap">
      <div class="col-12 d-flex justify-content-center flex-nowrap detail-item">
        <div class="detail-subtitle col-auto">Lock in Price!</div>
        <div class="ml-2 detail-subtitle-value">Lock in your price and our team will help coordinate your exact details
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #estimatedDurationTemplate>
  <span class="estimated-type-title">{{ quote.trip.fromEstimatedDuration === quote.trip.toEstimatedDuration ? 1 :
    quote.trip.fromEstimatedDuration }} - {{ quote.trip.toEstimatedDuration }} Business Days</span>
</ng-template>

<ng-template #tooltipTemplate>
  <span> {{ getRateServiceTypeTooltip() }}
    <span class="link" (click)="onRateServiceType()">Learn More & Compare</span>
  </span>
</ng-template>