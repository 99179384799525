import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { UserService } from '@ship4wd/ngx-authorization';
import { NotificationService } from '@ship4wd/ngx-common';
import { FileInfo, FileUploaderMode, LayoutType } from '@ship4wd/ngx-manager-ui';
import { Subscription } from 'rxjs';
import { DocumentComment } from '../bookings/booking-documents/booking-documents.model';
import { Booking, BookingFile } from '../bookings/bookings.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { StringCutterService } from '../services/text-cutter/stringCutter.service';
import { UserInfoService } from '../services/user-info/user-info.service';
import { DocumentTypeCode, SelectItem, SupportedFileFormats, UserTypeCode } from '../shared.model';
import { FilesDocumentsService } from './files-documents.service';

@Component({
  selector: 'app-files-documents',
  templateUrl: './files-documents.component.html',
  styleUrls: ['./files-documents.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'files-documents-file-type-overlay' }
    }
  ]
})
export class FilesDocumentsComponent implements OnInit {
  @Input() booking: Booking;
  @Input() autoload: boolean;
  @Input() autoloadDocumentType: DocumentTypeCode = DocumentTypeCode.OTH;
  @Output() addedDocumentEvent = new EventEmitter<BookingFile>();
  listingFiles: FileInfo[] = [];
  LayoutType = LayoutType;
  SupportedFileFormats = SupportedFileFormats;
  FileUploaderMode = FileUploaderMode;
  filesDocumentsForm: FormGroup;
  private _subscription: any[] = [];
  disabledUploadButton: boolean = false;

  documentTypes: SelectItem[] = [
    { name: 'Other document', value: DocumentTypeCode.OTH },
    { name: 'Forwarder cargo receipt', value: DocumentTypeCode.FCR },
    { name: 'HBL HAWB', value: DocumentTypeCode.HBL },
    { name: 'MBL MAWB', value: DocumentTypeCode.MBL },
    { name: 'Sales invoice', value: DocumentTypeCode.INV },
    { name: 'Booking confirmation', value: DocumentTypeCode.BKF },
    { name: 'Pre alert', value: DocumentTypeCode.PRE },
    { name: 'Manifest', value: DocumentTypeCode.MFT },
    { name: 'Payment request', value: DocumentTypeCode.PRQ },
    { name: 'Commercial invoice', value: DocumentTypeCode.CINV },
    { name: 'Packing list', value: DocumentTypeCode.PACK },
    { name: 'Certificate of origin', value: DocumentTypeCode.COO },
    { name: 'Advance shipment notice', value: DocumentTypeCode.ASN },
    { name: 'Vendor invoice', value: DocumentTypeCode.VINV },
    { name: 'FCR upload by user', value: DocumentTypeCode.FCRU },
    { name: 'HBL upload by user', value: DocumentTypeCode.HBLU },
    { name: 'MBL upload by user', value: DocumentTypeCode.MBLU },
    { name: 'Sales invoice upload by user', value: DocumentTypeCode.INVU },
    { name: 'Sales invoice draft', value: DocumentTypeCode.INVD },
    { name: 'Arrival notice', value: DocumentTypeCode.ARR },
    { name: 'Delivery order', value: DocumentTypeCode.DON },
    { name: 'Proof of delivery', value: DocumentTypeCode.POD },
    { name: 'Photo', value: DocumentTypeCode.PHO },
    { name: 'Arrival notice upload by user', value: DocumentTypeCode.ARRU },
    { name: 'Delivery order upload by user', value: DocumentTypeCode.DONU },
    { name: 'Payment proof document', value: DocumentTypeCode.PAYP }
  ]

  get documentsFormArray(): FormArray {
    return this.filesDocumentsForm.get('documents') as FormArray;
  }

  constructor(
    private filesDocumentsService: FilesDocumentsService,
    private notificationService: NotificationService,
    private userService: UserService,
    private fb: FormBuilder,
    private userInfoService: UserInfoService,
    private stringCutterService: StringCutterService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.filesDocumentsForm = this.createFilesDocumentsForm();
  }

  onListingFilesChange(files: FileInfo[]) {
    let exists = -1;

    files.forEach(file => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
      exists = SupportedFileFormats.indexOf(fileExtension);
    });

    if (exists == -1) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `File format is not supported. Supported formats: ${SupportedFileFormats.join(', ')}`,
          showConfirmButton: true,
          confirmButtonText: "Ok"
        },
      });
      return;
    }

    this.listingFiles = files.filter(x => !x.id);

    if (!this.autoload) {
      this.setDocument()
    }
    else {
      var documentForm = this.createDocumentForm(this.listingFiles.pop(), this.autoloadDocumentType);
      this.onUpload(documentForm, 0);
    };
  }

  getBookingDocuments(): FileInfo[] {
    return [];
  }

  onUpload(documentForm: FormGroup, index: number) {
    this.disabledUploadButton = true;
    if (documentForm.get('documentType')?.value === '') {
      documentForm.get('documentType').markAsTouched();
      return;
    }
    const file = documentForm?.get('fileData').value;
    file.isStart = true;

    const comments = this.prepareDocumentCommentModel(documentForm.get('comment').value);
    const id = documentForm.get('id').value;
    this.addFile(file, comments, documentForm.get('documentType').value, index, id);
  }

  isUploaded(file: FileInfo): boolean {
    return file.url && (file.progress === null || file.progress === undefined);
  }

  onCancel(documentForm: FormGroup, index: number) {
    const id = documentForm.get('id').value;
    this.remvoeSubscriptionOfUploadFile(id);
    this.documentsFormArray.removeAt(index);
  }

  private addFile(file: FileInfo, comments: DocumentComment[],
    documentTypeCode: DocumentTypeCode, index: number, id: number) {
    const observable = this.filesDocumentsService
      .add(this.booking.id, file, comments, documentTypeCode)
      .subscribe(x => {
        this.finalizeFileLoading(file, x, id)
      },
        error => {
          this.notificationService.error(error);
        }).add(() => this.disabledUploadButton = false);
    this.addSubscriptionOfUploadFile(id, observable);
  }

  private finalizeFileLoading(file: FileInfo, response: any, id: number) {
    if (response.type === HttpEventType.UploadProgress) {
      file.progress = Math.round(100 * response.loaded / response.total);
    } else if (response instanceof HttpResponse) {
      file.progress = null;
      file.file = null;
      const addedFile = response.body as BookingFile;
      file.name = addedFile.name;
      file.url = addedFile.url;
      file.id = addedFile.id;
      const control = this.documentsFormArray.controls.find(x => x.get('id').value === id);
      if (control !== undefined) {
        const index = this.documentsFormArray.controls.indexOf(control);
        this.documentsFormArray.removeAt(index);
      }
      this.addedDocumentEvent.emit(addedFile);
    }
  }

  private prepareDocumentCommentModel(comment: string) {
    const documentCommentModel: DocumentComment[] = [];
    documentCommentModel.push({
      createTimeUtc: this.toIsoDateTimeString(),
      userName: this.userInfoService.getSettings().fullName,
      userType: UserTypeCode.user,
      userId: this.userService.getUser()?.userId,
      comment: comment
    });
    return documentCommentModel;
  }

  private toIsoDateTimeString() {
    let date = new Date();
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = date.toISOString() as string;
    return dateString;
  }

  private createFilesDocumentsForm() {
    return this.fb.group({
      documents: this.fb.array([]),
    });
  }

  private setDocument() {
    if (this.listingFiles) {
      this.listingFiles.map((file: FileInfo) => {
        this.documentsFormArray.push(this.createDocumentForm(file));
      });
    }
  }

  private createDocumentForm(file: FileInfo, documentType?: DocumentTypeCode) {
    return this.fb.group({
      id: [this.getMaxId()],
      fileData: [file ?? ''],
      comment: [''],
      documentType: [documentType ?? '', Validators.required]
    });
  }

  private addSubscriptionOfUploadFile(id: number, observable: any) {
    this._subscription.push({
      id: id,
      observable: observable
    });
  }

  private remvoeSubscriptionOfUploadFile(id: number) {
    const subscription = this._subscription.find((x: any) => x.id === id);
    if (subscription !== undefined) {
      const i = this._subscription.indexOf(subscription);
      (subscription.observable as Subscription).unsubscribe();
      this._subscription.splice(i, 1);
    }
  }

  private getMaxId() {
    const ids: number[] = this.documentsFormArray.controls.map((x) => {
      return x.get('id').value
    });
    if (ids !== null && ids !== undefined && ids.length > 0) {
      const maxId = Math.max(...ids);
      return maxId + 1;
    }
    return 1;
  }
}
