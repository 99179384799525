import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptorService, Ship4wdNgxAuthorizationModule } from '@ship4wd/ngx-authorization';
import { EnvironmentsService, EnvironmentsServiceConfig, Ship4wdNgxCommonModule } from '@ship4wd/ngx-common';
import { environment } from '../../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    Ship4wdNgxAuthorizationModule.forRoot({
      applicationId: environment.authorization.applicationId,
      isCookies: environment.authorization.isCookies,
      isLocal: environment.authorization.isLocal,
      loginUrl: '/login',
      returnUrl: '/organizations',
    }),
    Ship4wdNgxCommonModule.forRoot({
      companySubdomain: 'ship4wd',
      apiBaseUrl: CoreModule.getApiBaseUrl(),
      authorizationUrl: CoreModule.getAuthorizationUrl()
    })
  ],
  declarations: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class CoreModule {
    static companySubdomain = 'ship4wd';

    static getApiBaseUrl(): string {
        const environmentsService = new EnvironmentsService({ companySubdomain: this.companySubdomain } as EnvironmentsServiceConfig);
        const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

        switch (environmentName) {
            case 'qa':
                return environment.qa.apiBaseUrl;
            case 'sb':
                return environment.sb.apiBaseUrl;
            default:
                return environment.apiBaseUrl;
        }
    }

    static getAuthorizationUrl(): string {
        const environmentsService = new EnvironmentsService({ companySubdomain: this.companySubdomain } as EnvironmentsServiceConfig);
        const environmentName = environmentsService.getEnvironmentNameByHostname(window.location.hostname);

        switch (environmentName) {
            case 'qa':
                return environment.qa.authorization.endpoint;
            case 'sb':
                return environment.sb.authorization.endpoint;
            default:
                return environment.authorization.endpoint;
        }
    }
}
