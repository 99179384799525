<div class="booking-flow-contacts-select">
  <ng-container *ngIf="!isAddActive">
    <div class="contacts-select-header">
      <div class="row m-0">
        <div class="col-12 px-0">
          <mat-form-field class="w-100" [floatLabel]="'auto'" appearance="fill">
            <mat-label>{{ title }}</mat-label>
            <div matPrefix class="input-icon-wrapper">
              <i *ngIf="!isLoading" class="input-icon s4d-icon s4d-person"></i>
              <mat-spinner *ngIf="isLoading" class="input-icon search-loader" diameter="22"></mat-spinner>
            </div>
            <input id="searchTerm" [(ngModel)]="searchTerm" type="text" (keyup)="onSearchTermChanged()" matInput />
            <div matSuffix *ngIf="searchTerm.length > 0">
              <div class="input-icon-wrapper" (click)="onClear()">
                <i class="input-icon s4d-icon s4d-close"></i>
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="contacts-select-content mt-2">
      <div class="row m-0">
        <div class="col px-3">
          <span class="content-title">Recent suitable contacts</span>
        </div>
      </div>
      <div class="row contact-line" (click)="onAddContact()">
        <div class="col-auto px-0 d-flex align-items-center">
          <div class="line-icon-wrapper">
            <i class="s4d-icon s4d-plus line-icon"></i>
          </div>
        </div>
        <div class="col px-0 d-flex align-items-center">
          <span class="line-text">Add New Contact</span>
        </div>
      </div>
      <div class="row contact-line" (click)="onSelectContact(null)">
        <div class="col-auto px-0 d-flex align-items-center">
          <div class="line-icon-wrapper">
            <i class="s4d-icon s4d-person line-icon"></i>
          </div>
        </div>
        <div class="col px-0 d-flex align-items-center">
          <span class="line-text">Select {{ title }}</span>
        </div>
      </div>
      <ng-container *ngIf="filtered">
        <div class="row contact-line" *ngFor="let contact of filtered" (click)="onSelectContact(contact)">
          <div class="col-auto px-0 d-flex align-items-center">
            <div class="line-icon-wrapper">
              <i class="line-icon fi fi-{{ contact.countryCode | lowercase }}"></i>
            </div>
          </div>
          <div class="col px-0 d-flex align-items-center">
            <span class="line-text"><span class="line-text-strong">{{ contact.firstName }} {{ contact.lastName}}</span>
              {{ contact.address1 ? ', ' + contact.address1 : '' }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isAddActive">
    <app-booking-flow-contacts-create [booking]="data.booking" [contactType]="contactType" [isDialog]="false"
      [isBookingContactPage]="true" [organizationContactAction]="organizationContactActions.new"
      (onCanceled)="onAddCanceled()" (onAdded)="onAdded($event)"></app-booking-flow-contacts-create>
  </ng-container>
</div>
