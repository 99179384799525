<div class="booking-commodity-container d-flex flex-column justify-content-between">
  <div>
    <h4 mat-dialog-title class="booking-commodity-title">Commodity Code Lookup</h4>
    <mat-dialog-content class="mat-typography booking-commodity-content">
      <div class="pt-2 booking-commodity-text">
        <div class="row row-description">
          <div class="col d-flex align-items-center">
            <span class="section-title">PRODUCT DESCRIPTION</span>
          </div>
          <div class="col-auto">
            <span class="quotes-incoterm-box-title-icon" matTooltipPosition="above" mat-raised-button
              matTooltip="Write the name of your product in the code look up and pick the one that nearest describes your commodity"
              aria-label="Button that displays a tooltip when focused or hovered over"></span>
          </div>
        </div>
      </div>
      <div class="booking-commodity-auto-complete">
        <app-commodity-autocomplete [pageSize]="200" [minimumLength]="3"
          (selectedCommodity)="onSelectedCommodity($event)">
        </app-commodity-autocomplete>
      </div>
      <div class="pt-2 booking-commodity-text">
        <div class="row row-description">
          <div class="col d-flex align-items-center">
            <span class="section-title">COMMODITY CODE</span>
          </div>
          <div class="col-auto">
            <span class="quotes-incoterm-box-title-icon" matTooltipPosition="above" mat-raised-button
              matTooltip="...or if you know the commodity code type it below."
              aria-label="Button that displays a tooltip when focused or hovered over"></span>
          </div>
        </div>
      </div>
      <div class="booking-commodity-auto-complete">
        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
          <input type="text" matInput placeholder="e.g. SCTG 37210" [formControl]="commodityCode"
            (change)="onChangeValue($event.target.value)" maxlength="12" />
        </mat-form-field>
      </div>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions align="center" class="booking-commodity-action-button">
    <div class="cancel-continue-button-wrapper">
      <div class="cancel-button">
        <button mat-raised-button mat-dialog-close class="booking-commodity-cancel-button">Cancel</button>
      </div>
      <div class="continue-button">
        <button mat-raised-button cdkFocusInitial color="primary" (click)="saveCommodityCode()"
          class="booking-commodity-continue-button">
          CONTINUE
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>