<div class="booking-flow-services" [formGroup]="bookingForm" *ngIf="bookingForm && additionalServices">
  <div class="row mb-3 booking-flow-services-container"
    *ngFor="let additionalService of additionalServices; let i = index"
    [ngClass]="{ 'customs-additional-service': additionalService.id === additionalServiceType.customs }">
    <div class="col booking-flow-services-container-wrapper">
      <div class="d-flex align-items-center booking-flow-services-section">
        <div class="icon-flow-services-content-wrapper">
          <div class="booking-flow-services-import-icon d-flex align-items-center justify-content-center"
            [ngSwitch]="additionalService.id">
            <i class="panel-header-icon s4d-icon s4d-import" *ngSwitchCase="additionalServiceType.customs"></i>
            <i class="panel-header-icon s4d-icon s4d-import" *ngSwitchCase="additionalServiceType.customsManually"></i>
            <i class="panel-header-icon s4d-icon s4d-shield" *ngSwitchCase="additionalServiceType.insurance"></i>
            <i class="panel-header-icon s4d-icon s4d-shield"
              *ngSwitchCase="additionalServiceType.insuranceManually"></i>
            <i class="panel-header-icon s4d-icon s4d-search" *ngSwitchCase="additionalServiceType.preShipment"></i>
          </div>
          <div class="booking-flow-services-fields">
            <div class="booking-flow-services-title">
              <span>{{ additionalService?.name }}</span>
            </div>
            <div class="booking-flow-services-description">
              <span>{{ additionalService?.description }}</span>
            </div>
          </div>
        </div>
        <div class="booking-additional-service-wrapper">
          <div class="booking-additional-service-amount" *ngIf="getAdditionalServiceAmount(additionalService)">
            <div class="booking-flow-services-fees">
              <div
                *ngIf="(selectedAdditionalService && additionalService.id === additionalServiceType.customs && selectedAdditionalService === additionalServiceType.customs); else priceTemplate">
                <mat-spinner diameter="25"></mat-spinner>
              </div>
              <ng-template #priceTemplate [ngSwitch]="additionalService.id">
                <ng-container *ngSwitchCase="additionalServiceTypes.customs">
                  <div class="booking-flow-services-title">
                    <span [matTooltip]="getAdditionalServiceTooltipText(additionalService)"
                      matTooltipPosition="above">{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                      getAdditionalServiceAmount(additionalService) : '$' +
                      getAdditionalServiceAmount(additionalService)
                      }}</span>
                    <span [matTooltip]="getAdditionalServiceTooltipText(additionalService, true)"
                      matTooltipPosition="above">{{ getAdditionalServiceAmount(additionalService, true) }}</span>
                    <div class="booking-flow-services-description">
                      <span>{{getAdditionalServiceDescription(additionalService)}}</span>
                    </div>
                    <div class="booking-flow-services-details" *ngIf="isShowServiceDetailsButton(additionalService)">
                      <span (click)="onShowServiceDetails(additionalService)">Service Details</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="additionalServiceTypes.customsManually">
                  <div class="booking-flow-services-title">
                    <span [matTooltip]="getAdditionalServiceTooltipText(additionalService)"
                      matTooltipPosition="above">{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                      getAdditionalServiceAmount(additionalService) : '$' +
                      getAdditionalServiceAmount(additionalService)
                      }}</span>
                    <span [matTooltip]="getAdditionalServiceTooltipText(additionalService, true)"
                      matTooltipPosition="above">{{ getAdditionalServiceAmount(additionalService, true) }}</span>
                    <div class="booking-flow-services-description">
                      <span>{{getAdditionalServiceDescription(additionalService)}}</span>
                    </div>
                    <div class="booking-flow-services-details" *ngIf="isShowServiceDetailsButton(additionalService)">
                      <span (click)="onShowServiceDetails(additionalService)">Service Details</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="additionalServiceTypes.preShipment">
                  <div class="booking-flow-services-title">
                    <span>{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                      getAdditionalServiceAmount(additionalService) : '$' +
                      getAdditionalServiceAmount(additionalService)
                      }}</span>
                    <div class="booking-flow-services-description"
                      [matTooltip]="getAdditionalServiceTooltipText(additionalService)" matTooltipPosition="above"
                      matTooltipClass="additional-service-price-tooltip">
                      <span>{{getAdditionalServiceDescription(additionalService)}}</span>
                    </div>
                    <div class="booking-flow-services-details" *ngIf="isShowServiceDetailsButton(additionalService)">
                      <span (click)="onShowServiceDetails(additionalService)">Service Details</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="additionalServiceTypes.insurance">
                  <div class="booking-flow-services-title">
                    <span>{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                      getAdditionalServiceAmount(additionalService) : '$' +
                      getAdditionalServiceAmount(additionalService) | number : "1.2-2"
                      }}</span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="additionalServiceTypes.insuranceManually">
                  <div class="booking-flow-services-title">
                    <span>{{ getAdditionalServiceAmount(additionalService) !== 0 ?
                      getAdditionalServiceAmount(additionalService) : '$' +
                      getAdditionalServiceAmount(additionalService) | number : "1.2-2"
                      }}</span>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>
          <div class="booking-flow-services-modify"
            *ngIf="additionalService.id === additionalServiceType.insurance ? !insuranceErrors.length : true">
            <div class="booking-flow-services-add">
              <button class="booking-flow-services-add-button" *ngIf="!isShowServiceButton(additionalService)"
                (click)="onAddServices(additionalService)">Add</button>
              <button class="booking-flow-services-add-button remove"
                *ngIf="booking?.bookingAdditionalServiceCharges?.length !== 0 && isShowServiceButton(additionalService)"
                (click)="onRemoveServices(additionalService)">
                <span class="button-inner"
                  *ngIf="!(removeAddServiceBtnLoading && selectedAdditionalService === additionalService.id)">
                  Remove
                </span>
                <span>
                  <mat-spinner diameter="25"
                    *ngIf="removeAddServiceBtnLoading && selectedAdditionalService === additionalService.id"></mat-spinner>
                </span>
              </button>
            </div>
            <div class="booking-flow-services-calculator" *ngIf="booking?.bookingAdditionalServiceCharges?.length > 0 && isShowServiceButton(additionalService)
              && isShowChangeButton(additionalService)">
              <span (click)="onAddServices(additionalService)">Change Features</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center booking-flow-calculate-duties-section"
        *ngIf="additionalService.id === additionalServiceType.customs" (click)="onCostEstimator(additionalService)">
        <div class="booking-flow-services-calculator-icon d-flex align-items-center justify-content-center">
        </div>
        <div class="booking-flow-services-calculator-fields">
          <div class="booking-flow-services-calculator-title">
            <span>Import Cost Table</span>
          </div>
          <div class="booking-flow-services-calculator-description">
            <span>See the costs for your import, including purchase tax, duties & fees.</span>
          </div>
        </div>
        <div class="booking-flow-services-calculator-arrow-icon">
          <i class="panel-header-icon s4d-icon s4d-arrow-right"></i>
        </div>
      </div>
      <a href="https://www.gov.il/apps/taxes/importTable/" target="_blank"
        class="d-flex align-items-center mt-3 booking-flow-calculate-duties-section booking-flow-calculate-duties-link"
        *ngIf="additionalService.id === additionalServiceType.customsManually">
        <div class="booking-flow-services-calculator-icon d-flex align-items-center justify-content-center">
        </div>
        <div class="booking-flow-services-calculator-fields">
          <div class="booking-flow-services-calculator-title">
            <span>Import Cost Table</span>
          </div>
          <div class="booking-flow-services-calculator-description">
            <span>See the costs for your import, including purchase tax, duties & fees.</span>
          </div>
        </div>
        <div class="booking-flow-services-calculator-arrow-icon">
          <i class="panel-header-icon s4d-icon s4d-arrow-right"></i>
        </div>
      </a>
      <div class="booking-flow-insurance-errors row m-0 mt-3 "
        *ngIf="additionalService.id === additionalServiceType.insurance && insuranceErrors.length">
        <div class="booking-flow-insurance-error-icon col-auto p-0">
          <i class="panel-header-icon s4d-icon s4d-info"></i>
        </div>
        <div class="booking-flow-services-description col">
          <div class="error-text-wrapper">
            <span class="insurance-text" *ngFor="let error of insuranceErrors">{{ error }}</span>
            <span *ngIf="isOrganizationPostalCodeMissing()" class="zip-code-update" (click)="onUpdateZipCode()">Update Information</span>
         </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-4">
    <div class="col-auto">
      <button mat-raised-button class="booking-next-button button-spinner" (click)="onReviewAndPay()"
        [disabled]="btnLoading || !isValid">
        <span class="button-inner">
          Review {{ !isCreditOrganization && !isAgent ? "& Pay" : "" }}
          <mat-spinner diameter="25" *ngIf="btnLoading"></mat-spinner>
        </span>
      </button>
    </div>
  </div>
</div>