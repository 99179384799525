import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RollupDialogService } from '../../rollup-dialog/rollup-dialog.service';

@Component({
  selector: 'app-welcome-tutorial-dialog',
  templateUrl: './welcome-tutorial-dialog.component.html',
  styleUrls: ['./welcome-tutorial-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeTutorialDialogComponent {

  constructor(private router: Router,
    public dialogRef: RollupDialogService) {}

  onQuoteSearch() {
    this.dialogRef.close(true);
    this.router.navigate(['/quote-search']);
  }
}
