export enum RateDetailPanels {
  STANDARD = 0,
  BASIC = 1,
  FREIGHT = 2,
  ORIGIN = 3,
  DESTINATION = 4,
  OTHER = 5,
  INCLUSIONS = 6,
  DANGEROUS = 7,
}
