<div class="order-list container" id="order-list">
  <div class="row" *ngIf="bookings">
    <ng-container *ngIf="!isFinance && !isCreditLine; else financeViewMode">
      <ng-container *ngIf="isOrdersGridViewMode || isNoBookings; else cardViewMode">
        <app-order-list-grid-view [dataSource]="dataSource" [sortParameters]="sortParameters"
          (canceledBookingEvent)="cancelBooking()" [isLoading]="isLoading" [isSortLoading]="isSortLoading"
          [isNoBookings]="isNoBookings" (sortBookingEvent)="onSortBooking($event)"></app-order-list-grid-view>
      </ng-container>
      <ng-template #cardViewMode>
        <app-order-list-card-view [openOrders]="openOrders" [submittedOrders]="submittedOrders"
          [completedOrders]="completedOrders" [headerTitle]="headerTitle" (canceledBookingEvent)="cancelBooking()"
          (ordersLoaded)="onOrdersLoaded($event)"></app-order-list-card-view>
      </ng-template>
    </ng-container>
    <ng-template #financeViewMode>
      <app-order-list-grid-view-finance [dataSource]="dataSource" [sortParameters]="sortParameters"
        (canceledBookingEvent)="cancelBooking()" [isLoading]="isLoading" (exportBookingsEvent)="exportBookings()"
        (sortBookingEvent)="onSortBooking($event)" [isCreditLine]="isCreditLine"></app-order-list-grid-view-finance>
    </ng-template>
  </div>
  <div *ngIf="totalBookings > bookings.length" class="row pb-4">
    <div class="col-12 pe-0 text-center"><a *ngIf="!isLoading" (click)="onLoadMore()">Load
        more data...</a><span *ngIf="isLoading" class="material-icons load-icon">refresh</span></div>
  </div>
</div>