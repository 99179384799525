import { Pipe, PipeTransform } from '@angular/core';
import { LinearUnit } from '../../shared.model';

@Pipe({ name: 'linearUnitName' })
export class LinearUnitNamePipe implements PipeTransform {
  private linearUnitNames = {};

  constructor() {
    this.linearUnitNames[LinearUnit.CM] = 'CM';
    this.linearUnitNames[LinearUnit.M] = 'M';
    this.linearUnitNames[LinearUnit.IN] = 'IN';
    this.linearUnitNames[LinearUnit.FT] = 'FT';
  }

  transform(value?: LinearUnit): string {
    return this.linearUnitNames[value] ?? '';
  }
}
