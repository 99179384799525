import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { InternalUrlsService } from "@ship4wd/ngx-common";
import { Observable } from "rxjs";
import { EncodeUrlCodec } from "../services/encode-url-codec";
import { TaxIdQuery } from "../shared.model";

@Injectable()
export class TaxIdService {
  constructor(
    private internalUrlsService: InternalUrlsService,
    private http: HttpClient) {
  }

  isTaxIdValid(query: TaxIdQuery): Observable<boolean> {
    const params = new HttpParams({ fromObject: query as any, encoder: new EncodeUrlCodec() });
    return this.http.get<boolean>(
      `${this.internalUrlsService.getApiBaseUrl()}/taxId`, { params });
  }
}