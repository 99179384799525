import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommodityService } from '../../../../shared/services/commodity.service';
import { HarmonizedSystemCode } from '../../../../shared/shared.model';

@Component({
  selector: 'app-booking-commodity-dialog',
  templateUrl: './booking-commodity-dialog.component.html',
  styleUrls: ['./booking-commodity-dialog.component.scss']
})
export class BookingCommodityDialogComponent implements OnInit {
  commodityCode = new FormControl('',[Validators.required]);
  originalValue: string;
  commodityForm: FormGroup;
  commodityTypeCodeControlName: string;
  selectedCommodity: HarmonizedSystemCode;
  close:boolean = true;

  constructor(
    public dialogRef: MatDialogRef<BookingCommodityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commodityService: CommodityService,
  ) {
  }

  ngOnInit() {
    this.commodityForm = this.data?.commodityForm;
    this.commodityTypeCodeControlName = this.data?.commodityTypeCodeControlName;
  }

  onSelectedCommodity(commodity: HarmonizedSystemCode) {
    if (commodity) {
      this.originalValue = commodity.id;
      this.commodityCode.setValue(commodity.id);
      this.selectedCommodity = commodity;
    }
  }

  onChangeValue(changeValue: string) {
    const regexp = new RegExp(/^\d{4}\.\d{2}\.\d{2,4}/g);

    if(regexp.test(changeValue)){
      this.commodityCode.setErrors(null);
      this.commodityCode.markAsTouched();

      this.commodityService.resetCommodityAutoComplete.next(true);
      if (this.selectedCommodity === undefined) {
        this.selectedCommodity = new HarmonizedSystemCode();
      }
      this.selectedCommodity.id = changeValue;
      this.selectedCommodity.description = '';

      this.close = true;
    }
    else{
      this.commodityCode.setErrors({'incorrect': true});
      this.commodityCode.markAsTouched();

      this.close = false;
    }
  }

  saveCommodityCode() {
    this.commodityCode.markAsTouched();

    if(this.commodityCode.status === 'VALID'){
      const commodityCode = this.commodityCode.value;
      this.dialogRef.close({
        form: this.commodityForm,
        value: this.selectedCommodity
      });
    }
  }
}
