import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentResultStepType } from '../../shared.model';
import { MatDialog } from '@angular/material/dialog';
import { PaymentBankDetailsComponent } from '../payment-bank-details/payment-bank-details.component';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentStatusComponent implements OnInit {
  id: string;
  status: boolean = false;
  isMobile: boolean = false;
  isLoading: boolean = true;
  paymentResultTypes: any = PaymentResultStepType;

  steps: any[] = [
    {
      title: "Wire Transfer and Confirmation",
      description: "Please make a bank transfer using the provided details below and provide the bank transfer confirmation. Payment processing may take up to 3 business days.",
      type: PaymentResultStepType.WAITING
    },
    {
      title: "Connecting with suppliers",
      description: "We’ll reach out to you and your suppliers to get missing documentation (commercial invoice, packing list, etc) and to coordinate cargo handover.",
      type: PaymentResultStepType.BOTH
    },
    {
      title: "Booking Confirmation and Sailing / Flight Schedule",
      description: "Once the shipment is booked with the carrier, we’ll send you the confirmation along with the routing schedule.",
      type: PaymentResultStepType.BOTH
    },
    {
      title: "Reviewing Documents",
      description: "You’ll receive shipping documents (Air Waybill or Bill of Lading) for review, if required, and final versions for your records.",
      type: PaymentResultStepType.BOTH
    },
    {
      title: "Coordinating Delivery",
      description: "We’ll let you or your designated contact person know when your cargo is ready for delivery.",
      type: PaymentResultStepType.BOTH
    }
  ];

  filteredSteps: any[] = [];

  constructor(public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.isMobile = data.isMobile ?? false;
    });

    this.route.queryParams
      .subscribe(params => {
        this.id = params.id ?? 0;
        this.status = params.status === 'true' ? true : false;
        this.filteredSteps = [...this.steps.filter(step => {
          return step.type === (this.status ? PaymentResultStepType.SUCCESS : PaymentResultStepType.WAITING) || step.type === PaymentResultStepType.BOTH
        })]

        this.isLoading = false;
      }
      );
  }

  onWpClick(): void {
    window.open('https://api.whatsapp.com/send?phone=17579049600', "_blank")
  }

  onDashboard(): void {
    window.location.href = '/dashboard';
  }

  onInstruction(): void {
    window.open('https://ship4wd.com/support-category/post-booking-cargo-fulfillment/', "_blank")
  }

  onBankDetails(): void {
    this.dialog.open(PaymentBankDetailsComponent, {
      panelClass: 'bank-details-mobile-wrapper',
      autoFocus: false,
      data: {
        refId: this.id,
        isMobile: this.isMobile
      },
    });
  }
}
