<div class="row m-0 p-0 commodity-input-ungrouped-container" role="group" [attr.aria-describedby]="userAriaDescribedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <div class="col px-0">
    <input type="text" matInput [formControl]="commodityControl" [matAutocomplete]="autoCommodity"
      [placeholder]="placeholder" [matTooltip]="valueTooltip" appAutocompletePosition trackInputErrors
      (triggerError)="handleError($event)" [controlName]="controlName">
    <mat-autocomplete #autoCommodity="matAutocomplete" class="commodity-autocomplete-ungrouped"
      (optionSelected)="onSelectCommodity($event.option.value)" [displayWith]="displayFn">
      <mat-option class="commodity" *ngFor="let commodity of commodityResponse" [value]="commodity"
        [matTooltip]="!isMobile ? commodity.description : ''">
        <div class="row flex-nowrap">
          <div class="commodity-code col">{{ commodity.id }}</div>
          <div class="commodity-name col-8">
            {{ getHarmonizedSystemCodeDescription(commodity.description) }}
          </div>
        </div>
      </mat-option>
      <mat-option *ngIf="!isLoading && commodityControl?.value?.length >= minimumLength
          && commodityResponse?.length === 0" disabled>
        <div class="d-flex justify-content-center no-results-width">
          No results found
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
  <div *ngIf="isLoading || !empty" class="col-auto d-flex gap-2 ps-2 pe-0" matSuffix>
    <div *ngIf="isLoading" class="button-spinner">
      <span class="button-inner">
        <mat-spinner diameter="25"></mat-spinner>
      </span>
    </div>
    <div *ngIf="!empty" class="clear-button d-flex" (click)="clearHtsCodeInput()">
      <i class="clear-icon s4d-icon s4d-close"></i>
    </div>
  </div>
</div>