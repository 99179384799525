<div *ngIf="isLoading" class="loading small-loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div class="transaction-payment-container d-flex flex-column justify-content-between"
  *ngIf="requestAndPayments && !isLoading">
  <div>
    <h4 mat-dialog-title class="transaction-payment-title">Select the Transaction to Pay</h4>
    <mat-dialog-content class="mat-typography transaction-payment-content">
      <div class="transaction-payment-content-wrapper align-items-center" *ngFor="let requestAndPayment of requestAndPayments;">
        <div class="transaction-payment-detail-wrapper">
          <div class="transaction-payment-currency-wrapper">
            <span class="transaction-payment-currency-logo"></span>
          </div>
          <div class="icon-date-wrapper">
            <div class="transaction-payment-reason-content" *ngIf="requestAndPayment?.reasonDisplayName">
              <span class="transaction-payment-reason-display-name">
                {{ requestAndPayment?.reasonDisplayName }}
              </span>
            </div>
            <div class="transaction-payment-due-date-content" *ngIf="requestAndPayment?.dueDate">
              <span class="transaction-payment-due-date-icon"></span>
              <span class="transaction-payment-due-date">{{ requestAndPayment?.dueDate | date: 'MMMdd, yy' }}</span>
            </div>
          </div>
        </div>
        <div class="paymentpay-button" *ngIf="showPayOrder(requestAndPayment.statusCode)">
          <button (click)="on40seasPayment(requestAndPayment)">Pay ${{ requestAndPayment?.amount | number : "1.2-2"
            }}</button>
        </div>
        <div class="actions-container" *ngIf="showConfirmButton(requestAndPayment.statusCode)">
          <div class="paymentpay-button">
            <button *ngIf="(requestAndPayment.statusCode)"
              (click)="onConfirm(requestAndPayment.paymentId)">Upload</button>
          </div>
          <div *ngIf="showChangePaymentMethodButton(requestAndPayment.statusCode)">
            <div class="col-auto btn order-actions" [matMenuTriggerFor]="menu">
              <mat-icon class="material-symbols-outlined"> more_horiz </mat-icon>
            </div>
            <mat-menu #menu backdropClass="order-dropdown">
              <btn *ngIf="showChangePaymentMethodButton(requestAndPayment.statusCode)" mat-menu-item
                class="dropdown-button" (click)="onChangePaymentMethod(requestAndPayment)">
                <span class="left-right-icon"></span>
                <span class="order-dropdown-text">Change Method</span>
              </btn>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions align="center" class="transaction-payment-action-button">
    <button mat-raised-button mat-dialog-close class="transaction-payment-cancel-button">Cancel</button>
    <button mat-stroked-button class="view-order-details-button" (click)="viewOrderDetails()">
      View Order Details
    </button>
  </mat-dialog-actions>
</div>
