import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@ship4wd/ngx-common';
import { Quote, QuotesQuery } from '../../../../../../desktop/quotes/quotes.model';
import { ShipmentType, HaulageType, RateServiceType } from '../../../../../../shared/shared.model';
import { BookingsService } from '../../../../../../shared/bookings/bookings.service';
import { CreateBooking } from '../../../../../../shared/bookings/bookings.model';
import { GoogleAnalyticsService } from '../../../../../../shared/google-analytics/google-analytics.service';
import { QuotesService } from '../../../../../../desktop/quotes/quotes.service';
import { UtilityService } from '../../../../../../shared/helper/utility.service';
import { EnvironmentService } from '../../../../../../shared/services/environment.service';
import { QuotePriceTiersComparisonComponent } from '../quote-price-tiers-comparison/quote-price-tiers-comparison.component';
import { RollupDialogService } from '../../../../../../mobile/rollup-dialog/rollup-dialog.service';

@Component({
  selector: 'app-quote-search-flow-result',
  templateUrl: './flow-result.component.html',
  styleUrls: ['./flow-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowResultComponent implements OnInit {
  @Input() quote: Quote;
  @Input() index: number;
  @Input() selectedIndex: number;
  @Input() showD2dAndAir: boolean;
  @Input() shipmentType: ShipmentType;
  @Input() specialServices: string[] = null;
  @Input() quotesQuery: QuotesQuery;
  @Output() onChangeSelection = new EventEmitter();

  @Input() quoteDetailsTemplate: TemplateRef<any>;
  @Input() quoteRowPriceTemplate: TemplateRef<any>;

  ShipmentTypes = ShipmentType;
  isLoading = false;
  isSpecialService = false;
  rateServiceType = RateServiceType;

  get getEstimationText(): string {
    if (!this.quote.estimatedDuration) {
      return "N/A"
    }

    switch (this.quotesQuery.shipmentType) {
      case ShipmentType.AIR:
        return `${this.quote.estimatedDuration} Business Days`
      default:
        return `${this.quote.estimatedDuration} Days`
    }
  }

  constructor(
    private router: Router,
    private bookingsService: BookingsService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private quotesService: QuotesService,
    private utilityService: UtilityService,
    private environmentService: EnvironmentService,
    private rollupDialogService: RollupDialogService
  ) { }

  ngOnInit(): void {
    this.prepareRateId();
    if (this.specialServices != null) {
      this.isSpecialService = true;
    }
  }

  getShipmentTypeIcon(): HaulageType {
    return this.quote.trip.mainCarriageTripLeg.legs[0].haulageType;
  }

  isPriceTagsEnabled(): boolean {
    return this.environmentService.environment.isPriceTagsEnabled;
  }

  isShowEstimatedDuration(): boolean {
    return this.shipmentType == ShipmentType.AIR
      && (!this.quote.trip.fromEstimatedDuration != null && this.quote.trip.toEstimatedDuration != null);
  }

  onBookQuote(event: Event, quote: Quote): void {
    event.stopPropagation();
    this.quotesService.stopSearchQuote$.next(true);
    this.createBooking(quote);
  }

  onExpandDetailRow(): void {
    this.onChangeSelection.emit(this.index);
  }

  getCutOffDateTitle(quote: Quote): string {
    const defaultTitle = 'n/a';
    const dateFormat = 'dd-MMM-yyyy';
    const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;

    if (!quote?.trip) {
      return defaultTitle;
    }

    let cutOffDate: Date | null = quote.trip.cutOffDate ? new Date(quote.trip.cutOffDate) : null;

    if (this.shipmentType == ShipmentType.FCL) {

      if (!cutOffDate && this.utilityService.isNotNullOrMinDateValue(quote.trip.departureDate)) {
        const departureDate = new Date(quote.trip.departureDate);
        cutOffDate = new Date(departureDate.getTime() - fourDaysInMilliseconds);
      }
    }

    return cutOffDate ? this.formatDate(cutOffDate, dateFormat) : defaultTitle;
  }

  getFreightCharges(): number {
    return [
      this.quote.trip?.mainCarriageTripLeg,
    ].reduce((accumulatedPrice, carriage) => {
      if (carriage?.legs?.length > 0) {
        return accumulatedPrice + carriage?.legs?.reduce(
          (freightCharges, leg) =>
            freightCharges + leg.rates?.reduce(
              (legPrice, rate) =>
              (
                legPrice +
                rate.freightRate?.shipmentRate?.totalAmount || 0
              ), 0
            ), 0)
      }

      return accumulatedPrice + 0
    }, 0);
  }

  getLocalCharges(): number {
    return this.quote.accumulatePrice - this.getFreightCharges()
  }

  isPartialQuote(quote: Quote): boolean {
    return quote.trip.isPreCarriageMissingRate || quote.trip.isPostCarriageMissingRate;
  }

  getPartialQuoteTooltipText(quote: Quote): string {
    const isPreMissing = quote.trip.isPreCarriageMissingRate;
    const isPostMissing = quote.trip.isPostCarriageMissingRate;

    let missingLegRate = 'pickup & delivery';
    if (isPreMissing && !isPostMissing) {
      missingLegRate = 'pickup';
    }
    else if (!isPreMissing && isPostMissing) {
      missingLegRate = 'delivery';
    }

    return `Door ${missingLegRate} costs are currently
		being processed and will be added to your
		pricing shortly.`;
  }

  isBasicRateServiceType(): boolean {
    return this.rateServiceType.Basic ===
      this.quote?.trip?.mainCarriageTripLeg?.legs[0]?.rates[0]?.rateServiceType;
  }

  onRateServiceType(): void {
    this.rollupDialogService.open(QuotePriceTiersComparisonComponent);
  }

  private createBooking(quote: Quote): void {
    this.isLoading = true;
    let booking = new CreateBooking();
    booking.quoteId = quote.id;
    booking.quoteSearchId = quote.quoteSearchId;
    booking.rateId = quote.accumulateRateId;
    booking.preCarriageAllowed = quote.trip.preCarriageTripLeg != null;
    booking.postCarriageAllowed = quote.trip.postCarriageTripLeg != null;

    this.bookingsService.createBooking(booking).subscribe(
      (bookingId: string) => {
        if (bookingId) {
          this.googleAnalyticsService.quoteBooked(quote, this.quotesQuery)
          this.router.navigate(["/booking", bookingId]);
        }
      },
      (error) => this.notificationService.error(error)
    ).add(() => this.isLoading = false);
  }

  private prepareRateId(): void {
    if (!this.quote.accumulateRateId) {
      this.quote.accumulateRateId = this.quote.trip.rateId;
    }
  }

  private formatDate(date: Date, format: string): string {
    return this.datePipe.transform(date, format) || 'n/a';
  }
}
