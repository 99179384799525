import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode } from '../shared.model';

@Pipe({ name: 'currencyName' })
export class CurrencyNamePipe implements PipeTransform {
  private currencyNames = Object.keys(CurrencyCode).filter((v) => isNaN(Number(v)));

  constructor() {
  }

  transform(value?: CurrencyCode): string {
    return this.currencyNames[value - 1] ?? '';
  }
}
