import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as uuid from 'uuid';
import { DateUtils, NotificationService } from '@ship4wd/ngx-common';
import {
  IncotermCode,
  QuoteSearchParameters,
  SearchType,
  ShipmentType,
  ShipperType
} from '../../../../shared/shared.model';
import { Incoterm, IncotermsMappingsQuery, QuotesQuery } from '../../quotes.model';
import { QuotesIncotermService } from '../../quotes-incoterm.service';

@Component({
  selector: 'app-quote-search-flow-params',
  templateUrl: './quote-search-flow-params.component.html',
  styleUrls: ['./quote-search-flow-params.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchFlowParamsComponent implements OnInit, OnChanges {
  @Input() quoteSearchForm: FormGroup = undefined;
  @Input() searchId: string = '';
  @Input() reset: any = null;
  @Input() isReuse: boolean;
  @Input() isLoading: boolean = false;
  @Input() shippingTypes: any[] = [];

  @Output() searchQuotesEvent = new EventEmitter<QuotesQuery>();

  isOpened: boolean[] = [false, false, false, false, false];
  isSelected: boolean[] = [true, false, false, false, false];

  quoteSearchParameters = QuoteSearchParameters;

  searchType = SearchType;
  incoterms: Incoterm[] = null;

  get form(): any {
    return this.quoteSearchForm.controls;
  }

  get shipmentype(): any {
    return this.quoteSearchForm.get('shipmentType')?.value;
  }

  constructor(
    private quotesIncotermService: QuotesIncotermService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    (this.form.shipmentType as FormControl).valueChanges.subscribe(() => {
      this.isOpened = [false, false, false, false, false];
      this.isSelected = [
        true,
        false,
        false,
        this.isSelected[QuoteSearchParameters.cargo],
        this.isSelected[QuoteSearchParameters.date]
      ];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReuse?.currentValue) {
      this.isSelected = [true, true, true, true, true];
      this.onViewQuotes();
      this.isReuse = false;
    }

    if (changes.reset) {
      this.isSelected = [true, false, false, false, false];
      this.isOpened = [false, false, false, false, false];
    }
  }

  onNext(step: QuoteSearchParameters): void {
    switch (step) {
      case QuoteSearchParameters.shipmentType:
        {
          if (!this.isSelected[QuoteSearchParameters.from]) {
            this.isOpened[QuoteSearchParameters.from] = true;
          }
        }
        break;
      case QuoteSearchParameters.from:
        {
          if (!this.isSelected[QuoteSearchParameters.to]) {
            this.isOpened[QuoteSearchParameters.to] = true;
          }

          this.getIncoterms();
        }
        break;
      case QuoteSearchParameters.to:
        {
          if (!this.isSelected[QuoteSearchParameters.cargo]) {
            this.isOpened[QuoteSearchParameters.cargo] = true;
          }

          this.getIncoterms();
        }
        break;
      case QuoteSearchParameters.cargo:
        {
          if (!this.isSelected[QuoteSearchParameters.date]) {
            this.isOpened[QuoteSearchParameters.date] = true;
          }
        }
        break;
      case QuoteSearchParameters.date:
        {
        }
        break;
      default: {
      }
    }
  }

  onSetSearchId(searchId: string): void {
    if (searchId !== undefined && searchId !== '' && searchId !== null) {
      this.searchId = searchId;
    }
  }

  onViewQuotes(): void {
    if ((!this.validateParams() && !this.quoteSearchForm.valid) || !this.checkAllSelected()) {
      this.notificationService.error(`Can't search quotes because of an input errors, please check out the form`);
      return;
    }

    const quote = this.quoteSearchForm.getRawValue() as QuotesQuery;
    quote.fromDate = DateUtils.toIsoDateString(quote.fromDate);

    if (this.quoteSearchForm.get('incoterms').value) {
      let incotermsCodes: IncotermCode[] = [];
      incotermsCodes.push(this.quoteSearchForm.get('incoterms').value);
      quote.incoterms = incotermsCodes;
    }

    if (this.quoteSearchForm.get('from').value) {
      quote.from.zipCode = quote.from.zipCode ? quote.from.zipCode : this.quoteSearchForm.get('from').value.postcode;
    }

    if (this.quoteSearchForm.get('to').value) {
      quote.to.zipCode = quote.to.zipCode ? quote.to.zipCode : this.quoteSearchForm.get('to').value.postcode;
    }
    quote.searchId = uuid.v4();

    this.searchQuotesEvent.emit(quote);
  }

  onSetSelected(state: boolean, param: QuoteSearchParameters): void {
    this.isSelected[param] = state;
  }

  onSetShipperType(type: ShipperType): void {
    this.quoteSearchForm.get('shipperType').setValue(type === undefined ? null : type);
  }

  private validateParams(): boolean {
    this.quoteSearchForm.markAllAsTouched();

    return (
      this.form.from.valid &&
      this.form.to.valid &&
      this.form.fromDate.valid &&
      this.quotesSearchStepContainers() &&
      this.quoteSearchForm.get('incoterms').value != null
    );
  }

  private checkAllSelected(): boolean {
    return this.isSelected.every(x => x === true);
  }

  private quotesSearchStepContainers(): boolean {
    let valid = true;
    if (this.form.shipmentType?.value === ShipmentType.LCL || this.form.shipmentType?.value === ShipmentType.AIR) {
      if (!this.requiredIf('containerCommodities')) {
        valid = false;
      }
    }
    if (this.form.shipmentType?.value === ShipmentType.FCL) {
      if (!this.requiredIf('equipments')) {
        valid = false;
      }
    }
    return valid;
  }

  private requiredIf(controlName: string): boolean {
    const isValid = this.quoteSearchForm.controls[controlName].valid;
    if (this.quoteSearchForm.controls[controlName] instanceof FormControl) {
      if (!isValid) {
        this.quoteSearchForm.controls[controlName].markAsTouched();
      }
    } else if (this.quoteSearchForm.controls[controlName] instanceof FormArray) {
      (this.quoteSearchForm.controls[controlName] as FormArray).controls.forEach(element => {
        if (element instanceof FormGroup) {
          Object.keys(element.controls).forEach(key => {
            const childControl = element.get(key);
            if (childControl instanceof FormControl) {
              if (!childControl.valid) {
                childControl.markAsTouched();
              }
            } else if (childControl instanceof FormArray) {
              (childControl as FormArray).controls.forEach(subElement => {
                if (subElement instanceof FormGroup) {
                  Object.keys(subElement.controls).forEach(subKey => {
                    const subChildControl = subElement.get(subKey);
                    if (!subChildControl.valid) {
                      subChildControl.markAsTouched();
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    return isValid;
  }

  private getIncoterms(): void {
    if (
      this.quoteSearchForm.get('shipmentType').value &&
      this.quoteSearchForm.get('shipperType').value &&
      this.quoteSearchForm.get('from').value?.locationType !== undefined &&
      this.quoteSearchForm.get('to').value?.locationType !== undefined
    ) {
      const query = {
        shipmentType: this.quoteSearchForm.get('shipmentType').value,
        shipperType: this.quoteSearchForm.get('shipperType').value,
        fromLocationType: this.quoteSearchForm.get('from').value.locationType,
        toLocationType: this.quoteSearchForm.get('to').value.locationType
      } as IncotermsMappingsQuery;

      this.quotesIncotermService.getIncotremsByLocationTypes(query).subscribe(
        (x: Incoterm[]) => {
          this.incoterms = x;
          if (this.incoterms.length === 1) {
            this.quoteSearchForm.get('incoterms').setValue(this.incoterms[0].id);
          }
        },
        error => this.notificationService.error(error)
      );
    } else {
      this.incoterms = null;
    }
  }
}
