import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RollupDialogService } from '../rollup-dialog/rollup-dialog.service';
import { DialogService } from '../../shared/services/dialog.service';
import { WelcomeTutorialDialogComponent } from './welcome-tutorial-dialog/welcome-tutorial-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WelcomeTutorialService {
  dialogRef: any = null;

  constructor(
    public dialog: MatDialog,
    private rollupDialogService: RollupDialogService,
    private dialogService: DialogService
  ) {}

  openWelcomeTutorialDialog(): void {
    if (this.dialogRef != null) return;

    if (!this.dialogService.isDialogOpen()) {
      this.dialogService.setDialogOpen(true);

      this.dialogRef = this.rollupDialogService.open(WelcomeTutorialDialogComponent);
      this.dialogRef.subscribe(data => {
        this.dialogService.setDialogOpen(false);

        this.dialogRef = null;
      });
    }
  }
}
