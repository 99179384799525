import { Pipe, PipeTransform } from '@angular/core';
import { WeightUnit } from '../shared.model';

@Pipe({ name: 'weightUnitName' })
export class WeightUnitNamePipe implements PipeTransform {
  private weightUnitNames = {};

  constructor() {
    this.weightUnitNames[WeightUnit.KG] = 'KG';
    this.weightUnitNames[WeightUnit.LB] = 'LB';
  }

  transform(value?: WeightUnit): string {
    return this.weightUnitNames[value] ?? '';
  }
}
