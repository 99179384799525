<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="container manage-organization-container" [hidden]="isLoading">
  <div class="row manage-organization-wrapper">
    <div class="col-xxl-8 col-6 organization-detail-container">
      <app-organization-detail [organizationForm]="organizationForm"
      [organizationRoleTypeCodes]="organizationRoleTypeCodes"
      (roles)="onChangeRoles($event)"
      [countryAutocompleteValue]="countryAutocompleteValue"></app-organization-detail>
    </div>
    <div class="col-xxl-4 col-6 organization-members-wrapper">
      <div class="organization-members-container">
        <app-organization-members [organizationMembers]="organizationMembers"
        (teamMemberEvent)="onTeamMemberEvent()"></app-organization-members>
      </div>
    </div>
  </div>
  <div class="manage-organization-footer">
    <button mat-raised-button class="back-button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button class="next-button" (click)="onSaveChanges()">Save Changes</button>
  </div>
</div>