import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { NotificationService } from '@ship4wd/ngx-common';
import { ROLLUP_DIALOG_DATA } from '../../mobile/rollup-dialog/rollup-dialog.model';
import { RollupDialogService } from '../../mobile/rollup-dialog/rollup-dialog.service';
import { ManageOrganizationService } from '../../desktop/manage-organization/manage-organization.service';
import { LegalNamePopupErrorMessages, LegalNamePopupFields } from '../google-analytics/google-analytics.constants';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';

@Component({
  selector: 'legal-name-rollup-dialog',
  templateUrl: './legal-name-rollup-dialog.component.html',
  styleUrls: ['./legal-name-rollup-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'legal-name-overlay-panel' }
    }
  ]
})
export class LegalNameRollupDialogComponent implements OnInit {
  organizationForm: FormGroup;
  showLegalNameInput: boolean = false;
  showTaxIdInput: boolean = false;
  isLoading = false;
  isCompaniesLoading: boolean = false;
  isCompanySelected: boolean = false;
  result: boolean = false;
  LegalNamePopupFields = LegalNamePopupFields;
  errorStatuses: Record<LegalNamePopupFields, boolean> = {
    [LegalNamePopupFields.taxId]: false,
    [LegalNamePopupFields.legalName]: false,
  };
  isStartedTyping: Record<LegalNamePopupFields, boolean> = {
    [LegalNamePopupFields.taxId]: false,
    [LegalNamePopupFields.legalName]: false,
  };

  constructor(
    public dialogRef: RollupDialogService,
    @Inject(ROLLUP_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private manageOrganizationService: ManageOrganizationService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.createOrganizationForm();
    this.googleAnalyticsService.exposureLegalNamePopup();

    this.showTaxIdInput = this.isInvalidTaxId();
  }

  onCurrent(): void {
    this.organizationForm.controls['legalName'].setValue(this.data.organization.name);
    this.showLegalNameInput = true;
  }

  onAnother(): void {
    this.showLegalNameInput = true;
  }

  isInvalidTaxId(): boolean {
    return !this.data?.organization?.taxId || this.data.organization.taxId.toLowerCase() == 'taxid';
  }

  onSubmit(): void {
    this.isLoading = true;
    let organization = this.data.organization;
    organization.legalName = this.organizationForm.controls['legalName'].value;
    organization.taxId = this.organizationForm.controls['taxId'].value;
    this.googleAnalyticsService.submitLegalNamePopup();

    this.manageOrganizationService.updateOrganization(organization.id, organization)
      .subscribe(() => {
        this.result = true;
      },
        error => {
          this.notificationService.error(error)
          this.googleAnalyticsService.errorLegalNamePopup(error, "submit");
        })
      .add(() => {
        this.isLoading = false;
        this.googleAnalyticsService.successfulAddedLegalNamePopup();
        this.dialogRef.close({
          result: this.result
        });
      });
  }

  focusInCheckError(control: AbstractControl | null, field: LegalNamePopupFields) {
    this.errorStatuses[field] = Boolean(control?.invalid && control.touched);
  }

  focusOutCheckError(control: AbstractControl | null, field: LegalNamePopupFields): void {
    if (control?.invalid && !this.errorStatuses[field]) {
      const errorMessage = this.getErrorMessage(control.errors, field)
      this.googleAnalyticsService.errorLegalNamePopup(errorMessage, field.toString());
    }
  }

  onInputCheckIsStartedTyping(control: AbstractControl | null, field: LegalNamePopupFields): void {
    if (!this.isStartedTyping[field]) {
      this.googleAnalyticsService.startTypingFieldLegalNamePopup(field.toString());
    }

    this.isStartedTyping[field] = control?.value.length > 0;
  }

  private createOrganizationForm(): void {
    this.organizationForm = this.fb.group({
      legalName: new FormControl(this.data.organization.legalName, [Validators.required]),
      taxId: new FormControl(this.getExistTaxId(), Validators.required)
    });
  }

  private getExistTaxId(): string {
    return this.data.organization.taxId?.toLowerCase() != 'taxid' ? this.data.organization?.taxId : null;
  }

  private getErrorMessage(errors: ValidationErrors, field: LegalNamePopupFields): string {
    let errorMessage = null;
    Object.keys(errors).forEach(key => {
      if (LegalNamePopupErrorMessages[field][key]) {
        errorMessage = LegalNamePopupErrorMessages[field][key]
        return;
      }
    })
    return errorMessage;
  }
}
