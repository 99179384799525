<div *ngIf="organizationForm">
  <div class="organization-container" [formGroup]="organizationForm">
    <div class="organization-field organization-logo-box">
      <div class="organization-logo">
        <img class="organization-logo-image" src="../../../../assets/images/s4d.jpg" alt="site-logo" width="80"
          height="80" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Name</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Name" formControlName="name" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Legal Name</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Legal Name" formControlName="legalName" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Fax</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Fax" formControlName="fax" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="organization-field-checkbox">
        <span class="field-title">Role</span>
        <div class="organization-field-box">
          <div *ngFor="let item of organizationRoleTypeCodes;" class="organization-role-check-button"
            [ngClass]="{'organization-role-check-icon': item.isChecked === true}">
            <mat-checkbox class="organization-role-checkbox" [value]="item.value" [checked]="item.isChecked"
              (change)="onChangeRole($event.checked, item.value)">
              <span class="organization-role-check"></span>
              {{ item.name | titlecase }}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Website</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Website" formControlName="website" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Industry</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Industry" formControlName="industry" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Trade Needs</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <mat-select placeholder="Trade Needs" formControlName="tradeNeeds" panelClass="trade-input-panel">
                <mat-option *ngFor="let tradeNeedsTypeCode of tradeNeedsTypeCodes" [value]="tradeNeedsTypeCode.value">
                  {{ tradeNeedsTypeCode.name | titlecase }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <app-tax-id-input class="w-100 tax-input" [taxIdControl]="organizationForm.get('taxId')"
          [countryCode]="organizationForm.get('countryCode').value" floatLevel="never"
          [disabled]="isTaxIdDisabled"></app-tax-id-input>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Street</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput placeholder="Type your Street address or P.O. Box" formControlName="street" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Address 2</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Apt, suite, unit, building, floor, etc."
                formControlName="address2" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-8 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>City</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="City" formControlName="city" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>Zip/Postcode</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="Zip/Postcode" formControlName="postalCode" />
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-country-autocomplete [countryControl]="organizationForm.get('countryCode')" [floatLabel]="'auto'"
          [countryAutocompleteValue]="countryAutocompleteValue">
        </app-country-autocomplete>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <mat-form-field appearance="fill" class="w-100 organization-form-field" [floatLabel]="'auto'">
          <mat-label>State</mat-label>
          <div class="row h-100">
            <div class="col d-flex align-items-end">
              <input matInput type="text" placeholder="State" formControlName="state" />
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>