<div class="dialog-container">
  <div class="close-button" (click)="!isWhatsAppMode ? onClose('X') : onBack(true)">
    <i class="panel-header-icon s4d-icon s4d-close"></i>
  </div>
  <div class="back-arrow" (click)="onBack()" *ngIf="isWhatsAppMode">
    <i class="panel-header-icon s4d-icon s4d-arrow-left"></i>
  </div>
  <div class="row dialog-message-wrapper" *ngIf="!isWhatsAppMode">
    <span class="dialog-title">
      Need help with your booking?
    </span>
    <div class="support-requests-container-wrapper">
      <div class="support-requests-option whatsapp cursor-pointer" (click)="onClickWhatsApp()">
        <div class="d-flex support-requests-section">
          <div class="icon-support-requests-content-wrapper">
            <div class="support-requests-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-whatsapp"></i>
            </div>
          </div>
          <div class="support-requests-fields">
            <div class="support-requests-title">
              <span>WhatsApp</span>
            </div>
            <div class="support-requests-description">
              <span>Talk to us via WhatsApp</span>
            </div>
          </div>
        </div>
      </div>
      <div class="support-requests-option cursor-pointer" (click)="onClickChat()">
        <div class="d-flex support-requests-section">
          <div class="icon-support-requests-content-wrapper">
            <div class="support-requests-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-comments-light"></i>
            </div>
          </div>
          <div class="support-requests-fields">
            <div class="support-requests-title">
              <span>Chat with a live Expert</span>
            </div>
            <div class="support-requests-description">
              <span>Chat with Ship4wd Expert</span>
            </div>
          </div>
        </div>
      </div>
      <div class="support-requests-option" *ngIf="showCallWithExpert() && !isFromBookingsPage">
        <div class="d-flex support-requests-section">
          <div class="icon-support-requests-content-wrapper">
            <div class="support-requests-icon d-flex align-items-center justify-content-center">
              <i class="panel-header-icon s4d-icon s4d-phone"></i>
            </div>
          </div>
          <div class="support-requests-fields">
            <div class="support-requests-title">
              <span>Call with an Expert</span>
            </div>
            <div class="support-requests-description">
              <span>Leave your phone number and a Ship4wd Expert will call you back within 5 minutes.</span>
            </div>
            <div class="row mt-3 support-requests-phone">
              <div class="booking-contact-field col-10 p-0">
                <div class="contact-field-wrapper">
                  <app-phone-input class="h-100 w-100" [fromControl]="supportRequestForm.get('phoneNumber')"
                    [selectedCountry]="appSettings.countryCode" (errorMessage)="onErrorMessage($event)"
                    (firstFocus)="onPhoneInputFirstFocus()"></app-phone-input>
                </div>
              </div>
              <div class="search-button-wrapper col-2">
                <button mat-button class="button-spinner button-inner" (click)="onClickAddSupportRequest()"
                  [disabled]="isButtonLoading || !supportRequestForm.valid">
                  <i class="search-icon s4d-icon s4d-arrow-right" *ngIf="!isButtonLoading"></i>
                  <mat-spinner diameter="25" *ngIf="isButtonLoading"></mat-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row dialog-message-wrapper" *ngIf="isWhatsAppMode">
    <span class="dialog-title whatsapp mt-0">
      WhatsApp
    </span>
    <div class="wp-content-wrapper">
      <div class="wp-content">
        <div class="row">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <div class="image-wrapper wp-qr" (click)="onWpClick()">
              <div class="image-content d-flex align-items-center justify-content-center"></div>
            </div>
          </div>
          <div class="col-12 mt-4 d-flex justify-content-center align-items-center">
            <h4 class="wp-title">Scan QR code to open WhatsApp on your mobile</h4>
          </div>
          <div class="col-12 my-4 d-flex justify-content-center align-items-center">
            <div class="wp-divider">or</div>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center">
            <div class="image-wrapper wp-button" (click)="onWpClick()">
              <div class="image-content d-flex align-items-center justify-content-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>