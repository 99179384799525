import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Booking } from '../../../../shared/bookings/bookings.model';

@Component({
  selector: 'app-order-list-card-view',
  templateUrl: './order-list-card-view.component.html'
})
export class OrderListCardViewComponent implements AfterViewInit{
  @Input()
  openOrders: Booking[] = [];
  @Input()
  submittedOrders: Booking[] = [];
  @Input()
  completedOrders: Booking[] = [];
  @Input()
  headerTitle: string;

  @Output() canceledBookingEvent: EventEmitter<string> = new EventEmitter();
  @Output() ordersLoaded: EventEmitter<QueryList<ElementRef>> = new EventEmitter();

  @ViewChildren('order', { read: ElementRef }) orders: QueryList<ElementRef>;

  constructor() { }

  ngAfterViewInit(): void {
    this.ordersLoaded.emit(this.orders);
  }

  onCancelBooking(bookingId: string): void {
    this.canceledBookingEvent.emit(bookingId);
  }
}
