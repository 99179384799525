<div class="on-quote-search-container">
    <div class="row">
        <div class="col-12 text-center">
            <mat-dialog-content class="on-quote-search-content">
                <div class="on-quote-search-message-wrapper">
                    <span class="on-quote-search-title-span">Start New Quote</span>
                </div>
                <div class="on-quote-search-message-wrapper">
                    <span class="on-quote-search-message-span">
                        Are you sure you want to start the quote search again?
                    </span>
                </div>
            </mat-dialog-content>
        </div>
    </div>
    <div class="row text-center mt-2 justify-content-md-center on-quote-search-footer ">
        <button mat-raised-button [mat-dialog-close]="true" class="get-booking-button col-4">
            No
        </button>
        <div class="col-1"></div>
        <button mat-raised-button class="get-booking-button col-4" (click)="navigateToQuoteSearch()">
            Yes
        </button>
    </div>
</div>