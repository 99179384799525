import { ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatCalendarHeader } from '@angular/material/datepicker';
import { MatDatepickerIntl } from '@angular/material/datepicker';

@Component({
  selector: 'app-datepicker-custom-header',
  templateUrl: 'datepicker-custom-header.component.html',
  styleUrls: ['./datepicker-custom-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerCustomHeaderComponent<D> extends MatCalendarHeader<D> {
  private monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  constructor(
    public calendar: MatCalendar<D>,
    @Inject(DateAdapter) public dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) public dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
    public intl: MatDatepickerIntl
  ) {
    super(intl, calendar, dateAdapter, dateFormats, changeDetectorRef);
  }

  get hasPreviousDate(): boolean {
    return this.calendar.activeDate <= this.calendar.minDate
  }

  get hasNextDate(): boolean {
    if (!this.calendar?.maxDate) {
      return false;
    }

    return this.calendar.activeDate >= this.calendar.maxDate;
  }

  get periodLabel(): string {
    const year = this.dateAdapter.getYear(this.calendar.activeDate);
    const month = this.dateAdapter.getMonth(this.calendar.activeDate);
    return `${this.monthNames[month]} ${year}`;
  }

  get minDateLabel(): string {
    return this.dateAdapter.format(this.calendar.minDate, 'MMM DD');
  }

  get maxDateLabel(): string {
    if (!this.calendar?.maxDate) {
      return 'any';
    }

    return this.dateAdapter.format(this.calendar.maxDate, 'MMM DD');
  }

  previousClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  nextClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }
}
